import { Stack, Typography } from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { CollapseSection, getHumanReadableDuration, Modal } from "@msys/ui";
import { AvailableReplacementsTable } from "../../documents/AvailableReplacementsTable.js";
import { useSalutation } from "../../users/useSalutation.js";

interface Props {
  handleClose(): void;
}

export function QuoteTextsAvailableReplacementsModal({ handleClose }: Props) {
  const { t } = useTranslate(["Global", "OrganisationSettings"]);

  const {
    projectReplacements,
    documentReplacements,
    calculationReplacements,
    actorsReplacements,
  } = useDocumentReplacements();

  return (
    <Modal
      title={t("Using placeholders in texts", {
        ns: "OrganisationSettings",
      })}
      handleClose={handleClose}
      notInStack
      actionButtons={[
        { label: t("Close", { ns: "Global" }), handleClick: handleClose },
      ]}
      maxWidth="md"
    >
      <Typography variant="body1" gutterBottom>
        {t(
          "You can use placeholders to fill in information automatically in different parts of your documents.",
          {
            ns: "OrganisationSettings",
          }
        )}
      </Typography>
      <Typography variant="body1">
        {t(
          "The following placeholders are available for use in the opening and closing texts of the documents listed below.",
          {
            ns: "OrganisationSettings",
          }
        )}
      </Typography>

      <Stack direction="column" spacing={2} pt={2}>
        <CollapseSection
          title={t("Project", { ns: "OrganisationSettings" })}
          isInitiallyExpanded={false}
        >
          <AvailableReplacementsTable replacements={projectReplacements} />
        </CollapseSection>
        <CollapseSection
          title={t("Document", { ns: "OrganisationSettings" })}
          isInitiallyExpanded={false}
        >
          <AvailableReplacementsTable replacements={documentReplacements} />
        </CollapseSection>
        {actorsReplacements.map(({ field, label, replacements }) => (
          <CollapseSection
            key={field}
            title={label}
            isInitiallyExpanded={false}
          >
            <AvailableReplacementsTable replacements={replacements} />
          </CollapseSection>
        ))}
        <CollapseSection
          title={t("Calculation", { ns: "OrganisationSettings" })}
          isInitiallyExpanded={false}
        >
          <AvailableReplacementsTable replacements={calculationReplacements} />
        </CollapseSection>
      </Stack>
    </Modal>
  );
}

export function useDocumentReplacements() {
  const { t } = useTranslate("DocumentReplacements");
  const language = useTolgee(["language"]).getLanguage()!;

  const { salutationLabels } = useSalutation();

  const actorsReplacements = [
    { field: "contractee", label: t("Contractee") },
    { field: "contractor", label: t("Contractor") },
    { field: "endClient", label: t("End client") },
  ].map(({ field, label }) => ({
    field,
    label,
    replacements: [
      {
        name: `${field}.name`,
        description: t("Name of person or Company name"),
        value: t("{option1} or {option2}", {
          ns: "DocumentReplacements",
          option1: "'Max Mustermann'",
          option2: "'MeisterSystems GmbH'",
        }),
      },
      {
        name: `${field}.formalName`,
        description: t("Name of person with salutation, or Company name"),
        value: t("{option1} or {option2}", {
          ns: "DocumentReplacements",
          option1: `'${salutationLabels.mr} Max Mustermann'`,
          option2: "'MeisterSystems GmbH'",
        }),
      },
      {
        name: `${field}.shortFormalName`,
        description: t(
          "Name of person with salutation and family name only, or Company name"
        ),
        value: t("{option1} or {option2}", {
          ns: "DocumentReplacements",
          option1: `'${salutationLabels.mr} Mustermann'`,
          option2: "'MeisterSystems GmbH'",
        }),
      },
      [
        {
          name: `${field}.salutation.formatted`,
          description: t("Salutation"),
          value: t("{option1} or {option2}", {
            ns: "DocumentReplacements",
            option1: `'${salutationLabels.mr}'`,
            option2: `'${salutationLabels.ms}'`,
          }),
        },
        {
          name: `${field}.salutation.value`,
          description: null,
          value: t("{option1} or {option2}", {
            ns: "DocumentReplacements",
            option1: "'mr'",
            option2: "'ms'",
          }),
        },
      ],
      {
        name: `${field}.firstName`,
        description: t("First name"),
        value: "Max",
      },
      {
        name: `${field}.familyName`,
        description: t("Family name"),
        value: "Mustermann",
      },
      {
        name: `${field}.phone`,
        description: t("Phone"),
        value: "+49 176 11122233",
      },
      {
        name: `${field}.email`,
        description: t("Email"),
        value: "m.mustermann@example.com",
      },
      {
        name: `${field}.number`,
        description: t("Number"),
        value: "O-25-0458",
      },
      [
        {
          name: `${field}.address.formatted`,
          description: t("Address"),
          value: "Hackescher Markt 4, 10178 Berlin",
        },
        {
          name: `${field}.address.streetAndNumber`,
          description: null,
          value: "Hackescher Markt 4",
        },
        {
          name: `${field}.address.city`,
          description: null,
          value: "Berlin",
        },
        {
          name: `${field}.address.postalCode`,
          description: null,
          value: "10178",
        },
        {
          name: `${field}.address.countryCode`,
          description: null,
          value: "DE",
        },
      ],
      [
        {
          name: `${field}.contactPerson.salutation.formatted`,
          description: t("Contact person salutation"),
          value: t("{option1} or {option2}", {
            ns: "DocumentReplacements",
            option1: `'${salutationLabels.mr}'`,
            option2: `'${salutationLabels.ms}'`,
          }),
        },
        {
          name: `${field}.contactPerson.salutation.value`,
          description: null,
          value: t("{option1} or {option2}", {
            ns: "DocumentReplacements",
            option1: "'mr'",
            option2: "'ms'",
          }),
        },
      ],
      {
        name: `${field}.contactPerson.firstName`,
        description: t("Contact person first name"),
        value: "Max",
      },
      {
        name: `${field}.contactPerson.familyName`,
        description: t("Contact person family name"),
        value: "Mustermann",
      },
      {
        name: `${field}.contactPerson.name`,
        description: t("Contact person name"),
        value: "Max Mustermann",
      },
      {
        name: `${field}.contactPerson.formalName`,
        description: t("Contact person name with salutation"),
        value: `${salutationLabels.mr} Max Mustermann`,
      },
      {
        name: `${field}.contactPerson.shortFormalName`,
        description: t(
          "Contact person name with salutation and family name only"
        ),
        value: `${salutationLabels.mr} Mustermann`,
      },
    ],
  }));

  const documentReplacements = [
    {
      name: "document.name",
      description: t("Document name"),
      value: t("My kitchen renovation - Quote"),
    },
    {
      name: "document.number",
      description: t("Document number"),
      value: "Q-25-0035",
    },
    [
      {
        name: "document.publishDate.formatted",
        description: t("Document publish date"),
        value: "14/02/2025",
      },
      {
        name: "document.publishDate.value",
        description: null,
        value: "2025-02-14T00:00:00.000Z",
      },
    ],
    [
      {
        name: "document.validUntil.formatted",
        description: t("Document valid until"),
        value: "24/02/2025",
      },
      {
        name: "document.validUntil.value",
        description: null,
        value: "2025-02-24T00:00:00.000Z",
      },
    ],
    [
      {
        name: "document.earliestWorkStart.formatted",
        description: t("Document earliest work start"),
        value: "03/03/2025",
      },
      {
        name: "document.earliestWorkStart.value",
        description: null,
        value: "2025-03-03T00:00:00.000Z",
      },
    ],
    [
      {
        name: "document.workDuration.formatted",
        description: t("Document work duration"),
        value: getHumanReadableDuration(1814400, language),
      },
      {
        name: "document.workDuration.value",
        description: null,
        value: 1814400,
      },
    ],
    [
      {
        name: "rootItem.properties.[<prop_key>].formatted",
        description: t("Root item property"),
        value: "1,000cm³",
      },
      {
        name: "rootItem.properties.[<prop_key>].value",
        description: null,
        value: 1000,
      },
    ],
  ];

  const projectReplacements = [
    {
      name: "project.name",
      description: t("Project name"),
      value: t("My kitchen renovation"),
    },
    {
      name: "project.number",
      description: t("Project number"),
      value: "6501",
    },
    [
      {
        name: "project.address.formatted",
        description: t("Project address"),
        value: "Hackescher Markt 4, 10178 Berlin",
      },
      {
        name: "project.address.streetAndNumber",
        description: null,
        value: "Hackescher Markt 4",
      },
      {
        name: "project.address.city",
        description: null,
        value: "Berlin",
      },
      {
        name: "project.address.postalCode",
        description: null,
        value: "10178",
      },
      {
        name: "project.address.countryCode",
        description: null,
        value: "DE",
      },
    ],
    [
      {
        name: "project.budget.formatted",
        description: t("Project budget"),
        value: "€10,000.00",
      },
      {
        name: "project.budget.value",
        description: null,
        value: "10000",
      },
    ],
    [
      {
        name: "project.earliestStart.formatted",
        description: t("Project earliest start"),
        value: "03/03/2025",
      },
      {
        name: "project.earliestStart.value",
        description: null,
        value: "2025-03-03T00:00:00.000Z",
      },
    ],
    [
      {
        name: "project.deadline.formatted",
        description: t("Project deadline"),
        value: "14/04/2025",
      },
      {
        name: "project.deadline.value",
        description: null,
        value: "2025-04-14T00:00:00.000Z",
      },
    ],
  ];

  const calculationReplacements = [
    [
      {
        name: "calculation.subTotal.formatted",
        description: t("Document subtotal"),
        value: "€345.00",
      },
      {
        name: "calculation.subTotal.value",
        description: null,
        value: "345",
      },
    ],
    [
      {
        name: "calculation.netTotal.formatted",
        description: t("Document net price"),
        value: "€310.50",
      },
      {
        name: "calculation.netTotal.value",
        description: null,
        value: "310.5",
      },
    ],
    [
      {
        name: "calculation.total.formatted",
        description: t("Document total"),
        value: "€369.50",
      },
      {
        name: "calculation.total.value",
        description: null,
        value: "369.5",
      },
    ],
    [
      {
        name: "calculation.discount.formatted",
        description: t("Document discount"),
        value: "€34.50",
      },
      {
        name: "calculation.discount.value",
        description: null,
        value: "34.5",
      },
    ],
    [
      {
        name: "calculation.discountRate.formatted",
        description: t("Document discount percentage"),
        value: "10%",
      },
      {
        name: "calculation.discountRate.value",
        description: null,
        value: "0.1",
      },
    ],
    [
      {
        name: "calculation.vat.formatted",
        description: t("Document VAT"),
        value: "€59.00",
      },
      {
        name: "calculation.vat.value",
        description: null,
        value: "59",
      },
    ],
    [
      {
        name: "calculation.vatRate.formatted",
        description: t("Document VAT percentage"),
        value: "19%",
      },
      {
        name: "calculation.vatRate.value",
        description: null,
        value: "0.19",
      },
    ],
  ];

  return {
    projectReplacements,
    documentReplacements,
    calculationReplacements,
    actorsReplacements,
  };
}
