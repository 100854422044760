import { gql, useApolloClient } from "@apollo/client";
import {
  DebouncedSearchInput,
  ErrorMessage,
  ListHeader,
  LoadingSpinner,
  ModalOpenButton,
} from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useUserData } from "../../auth/useUserData.js";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton.js";
import { FilterButton } from "../../commons/filters/FilterButton.js";
import { FilterModal } from "../../commons/filters/FilterModal.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView.js";
import { useStateWithUrlParams } from "../../commons/hooks/useStateWithUrlParams.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { usePaginationParams } from "../../commons/pagination/usePaginationParams.js";
import { ShopItemsList } from "../../features/shop/components/ShopItemsList.js";
import {
  FilterCreatedAfter,
  FilterCreatedBefore,
  FilterMaxPrice,
  FilterMinPrice,
  QuoteTemplatesSorting,
} from "../../features/templates/filters/index.js";
import {
  TemplatesQuoteFilterChips,
  canShowChips,
} from "../../features/templates/quote/TemplatesQuoteFilterChips.js";
import { OrganisationQuoteTemplatesSorting } from "../../../clients/graphqlTypes.js";
import { ShopQueryVariables, useShopQuery } from "./Shop.generated.js";

type QuickFilterValue = "ALL" | "SAVED";
type Filters = Omit<ShopQueryVariables, "offset" | "limit" | "sorting">;

const ALLOWED_VIEWS: CollectionView[] = ["gallery", "list"];
const DEFAULT_SORTING: OrganisationQuoteTemplatesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

interface Props {
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  quickFilter?: QuickFilterValue;
}

export const Shop = ({
  submenuItems,
  prefixBreadcrumbs,
  quickFilter = "ALL",
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Shop", "Global", "PageNotFound"]);

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>("shop", "gallery", "list");

  const quickFilterSettings: Record<
    QuickFilterValue,
    {
      label: string;
      values: Filters;
    }
  > = React.useMemo(
    () => ({
      ALL: {
        label: t("Shop", {
          ns: "Shop",
        }),
        values: {},
      },
      SAVED: {
        label: t("Saved", {
          ns: "Global",
        }),
        values: {
          likedByMe: true,
        },
      },
    }),
    [t]
  );

  const quickFilters: Filters = React.useMemo(
    () => ({
      shoppableTemplatesOfOrganisationIds: [viewer.organisation.id],
      ...quickFilterSettings[quickFilter].values,
    }),
    [viewer.organisation.id, quickFilterSettings, quickFilter]
  );

  const {
    limit,
    offset,
    page,
    perPage,
    setPerPage,
    setPage,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
  } = useTemplateQuoteFilter();

  const client = useApolloClient();
  const query = useShopQuery({
    client,
    variables: {
      offset,
      limit,
      sorting: DEFAULT_SORTING,
      ...quickFilters,
      ...filters,
      createdAfter:
        quickFilters?.createdAfter?.format("YYYY-MM-DD") ??
        filters?.createdAfter?.format("YYYY-MM-DD"),
      createdBefore:
        quickFilters?.createdBefore?.format("YYYY-MM-DD") ??
        filters?.createdBefore?.format("YYYY-MM-DD"),
    },
  });
  const viewerOrganisation = query.data?.viewer?.organisation;

  const [items, totalCount] = React.useMemo(() => {
    if (!viewerOrganisation) return [[], 0];

    const items = viewerOrganisation.shoppableTemplates.edges.map(e => e.node);
    const totalCount = viewerOrganisation.shoppableTemplates.totalCount;

    return [items, totalCount];
  }, [viewerOrganisation]);

  if (!query.data && query.loading) return <LoadingSpinner />;
  if (query.error) return <ErrorMessage message={query.error.message} />;
  if (!query.data)
    return (
      <ErrorMessage
        message={t("Page Not Found", {
          ns: "PageNotFound",
        })}
      />
    );

  const listHeader = (
    <ListHeader
      // hideTitleOnMobile
      // title={
      //   quickFilter === "SAVED"
      //     ? t("Saved", {
      //         ns: "Global",
      //       })
      //     : t("Shop", {
      //         ns: "Shop",
      //       })
      // }
      SwitchViewButton={
        <SwitchCollectionViewButton
          allowedViews={ALLOWED_VIEWS}
          activeView={activeView}
          setActiveView={setActiveView}
        />
      }
      FilterButton={
        <ModalOpenButton
          Modal={ShopFilterModal}
          modalProps={{
            resetFilters,
            sorting,
            setSorting,
            filters,
            setFilters,
          }}
        >
          <FilterButton />
        </ModalOpenButton>
      }
      FilterChips={
        canShowChips(filters) ? (
          <TemplatesQuoteFilterChips
            filters={filters}
            setFilters={setFilters}
          />
        ) : undefined
      }
      SearchField={
        <DebouncedSearchInput
          placeholder={t("Search", {
            ns: "Global",
          })}
          defaultValue={filters.searchTerm}
          onChangeSearchTerm={newValue =>
            setFilters(filters => ({
              ...filters,
              searchTerm: newValue,
            }))
          }
        />
      }
      mb={2}
    />
  );

  return (
    <Page
      title={
        quickFilter === "SAVED"
          ? t("Saved", {
              ns: "Global",
            })
          : t("Shop", {
              ns: "Shop",
            })
      }
      submenuItems={submenuItems}
      // breadcrumbs={prefixBreadcrumbs}
    >
      <PageContainer>
        {listHeader}
        <ShopItemsList
          activeView={activeView}
          items={items}
          totalCount={totalCount}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
        />
      </PageContainer>
    </Page>
  );
};

type TemplateQuoteFilters = Omit<
  ShopQueryVariables,
  "limit" | "offset" | "sorting"
>;
type TemplateQuoteSorting = Exclude<
  ShopQueryVariables["sorting"],
  null | undefined
>[0];

const useTemplateQuoteFilter = () => {
  const { limit, offset, page, perPage, setPerPage, setPage, pageUrlParam } =
    usePaginationParams({
      pageUrlParam: "shopPage",
      perPageUrlParam: "shopPageSize",
    });

  const [filters, setFilters] = useStateWithUrlParams<TemplateQuoteFilters>(
    "shopFilters",
    {},
    [pageUrlParam]
  );

  const resetFilters = React.useCallback(() => {
    setFilters({});
  }, [setFilters]);

  const [sorting, setSorting] = useStateWithUrlParams<TemplateQuoteSorting[]>(
    "shopSortings",
    DEFAULT_SORTING,
    [pageUrlParam]
  );

  return {
    limit,
    offset,
    page,
    perPage,
    setPerPage,
    setPage,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
  };
};

const ShopFilterModal = ({
  filters,
  setFilters,
  resetFilters,
  sorting,
  setSorting,
  handleClose,
}: {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  resetFilters: () => void;
  sorting: OrganisationQuoteTemplatesSorting[];
  setSorting: (newSorting: OrganisationQuoteTemplatesSorting[]) => void;
  handleClose: () => void;
}) => {
  const { t } = useTranslate(["Templates", "Global"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  return (
    <FilterModal
      title={t("Quote Template Filters", {
        ns: "Templates",
      })}
      handleApply={() => {
        setFilters(filtersState);
        setSorting(sortingState);
      }}
      handleClose={handleClose}
      handleReset={resetFilters}
    >
      <Typography variant="h3">
        {t("Sort by", {
          ns: "Global",
        })}
      </Typography>
      <QuoteTemplatesSorting
        sorting={sortingState[0]}
        setSorting={setSortingState}
        defaultSorting={DEFAULT_SORTING}
      />
      <Typography variant="h3">
        {t("Filter by", {
          ns: "Global",
        })}
      </Typography>
      <Stack direction="row" spacing={1}>
        <FilterMinPrice
          value={filtersState.minPrice ?? 0}
          setValue={newValue =>
            setFiltersState(state => ({
              ...state,
              minPrice: newValue,
            }))
          }
        />
        <FilterMaxPrice
          value={filtersState.maxPrice ?? 0}
          setValue={newValue =>
            setFiltersState(state => ({
              ...state,
              maxPrice: newValue,
            }))
          }
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <FilterCreatedAfter
          value={filtersState.createdAfter}
          setValue={newValue => {
            setFiltersState(state => ({
              ...state,
              createdAfter: newValue,
            }));
          }}
        />
        <FilterCreatedBefore
          value={filtersState.createdBefore}
          setValue={newValue =>
            setFiltersState(state => ({
              ...state,
              createdBefore: newValue,
            }))
          }
        />
      </Stack>
    </FilterModal>
  );
};
