import { ItemChildrenVisibility } from "../../../clients/graphqlTypes.js";
import { ItemTreeItem } from "../../trees/types.js";

export function shouldRenderRequirementCreateInput<
  T extends ItemTreeItem & {
    childrenVisibility: ItemChildrenVisibility;
  },
>(item: T): boolean {
  return item.type !== "paid" && item.childrenVisibility !== "HIDE_CHILDREN";
}
