import { assertNever } from "@msys/common";
import { Box, BoxProps, Chip, ChipProps, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isNil } from "lodash-es";
import React from "react";
import { Props2AllFragment } from "../doc-items/properties.generated.js";

interface Props {
  props: Props2AllFragment[];
  wrap?: boolean;
  boxProps?: BoxProps;
  highlightedKeys?: string[];
}

export const PropertyValueChips = ({
  props,
  wrap = true,
  boxProps,
  highlightedKeys,
}: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="baseline"
      flexWrap={wrap ? "wrap" : "nowrap"}
      sx={{ ml: -0.5, mt: -0.25 }}
      {...boxProps}
    >
      {props.map(prop => (
        <PropertyValueChip
          key={prop.key}
          prop={prop}
          sx={{ ml: 0.5, mt: 0.25 }}
          clickable={false}
          variant={highlightedKeys?.includes(prop.key) ? "filled" : "outlined"}
        />
      ))}
    </Box>
  );
};

export function PropertyValueChip({
  prop,
  ...chipProps
}: { prop: Props2AllFragment } & ChipProps) {
  const { t } = useTranslate(["Global"]);

  const value = React.useMemo(() => {
    switch (prop.__typename) {
      case "Props2Bool": {
        return isNil(prop.valueBool)
          ? "-"
          : prop.valueBool === true
            ? t("Yes", { ns: "Global" })
            : t("No", { ns: "Global" });
      }
      case "Props2BoolComputed": {
        return isNil(prop.valueBoolComputed)
          ? "-"
          : prop.valueBoolComputed === true
            ? t("Yes", { ns: "Global" })
            : t("No", { ns: "Global" });
      }
      case "Props2Number": {
        return isNil(prop.valueNumber)
          ? "-"
          : [prop.valueNumber, prop.unit].filter(Boolean).join("");
      }
      case "Props2NumberArray": {
        return isNil(prop.valueNumberArray)
          ? "-"
          : prop.valueNumberArray
              .map(v => [v.toString(), prop.unit].filter(Boolean).join(""))
              .join(", ");
      }
      case "Props2NumberArrayComputed": {
        return isNil(prop.valueNumberArrayComputed)
          ? "-"
          : prop.valueNumberArrayComputed
              .map(v => [v.toString(), prop.unit].filter(Boolean).join(""))
              .join(", ");
      }
      case "Props2NumberComputed": {
        return isNil(prop.valueNumberComputed)
          ? "-"
          : [prop.valueNumberComputed, prop.unit].filter(Boolean).join("");
      }
      case "Props2Text": {
        return isNil(prop.valueText) ? "-" : prop.valueText;
      }
      case "Props2TextArray": {
        return isNil(prop.valueTextArray)
          ? "-"
          : prop.valueTextArray.join(", ");
      }
      case "Props2TextArrayComputed": {
        return isNil(prop.valueTextArrayComputed)
          ? "-"
          : prop.valueTextArrayComputed.join(",");
      }
      case "Props2TextComputed": {
        return isNil(prop.valueTextComputed) ? "-" : prop.valueTextComputed;
      }
      default:
        assertNever(prop);
    }
  }, [prop, t]);

  return (
    <Tooltip title={prop.label} key={prop.key}>
      <Chip
        size={"small"}
        variant="outlined"
        color="primary"
        {...chipProps}
        label={
          prop.__typename === "Props2Text" ||
          prop.__typename === "Props2TextComputed" ||
          prop.__typename === "Props2TextArray" ||
          prop.__typename === "Props2TextArrayComputed"
            ? value
            : `${prop.label}: ${value}`
        }
      />
    </Tooltip>
  );
}
