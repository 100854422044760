import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import React, { useCallback, useImperativeHandle, useState } from "react";
import { useCounter } from "react-use";
import {
  AddTaskWorkSessionsProcess_ItemFragment,
  useAddTaskWorkSessionsProcessLazyQuery,
} from "./AddTaskWorkSessionsProcess.generated.js";
import { useTranslate } from "@tolgee/react";
import { getAllItemChildren } from "../../trees/helpers.js";
import { AddTaskWorkSessionsModal } from "./modals/AddTaskWorkSessionsModal.js";

export interface AddTaskWorkSessionsProcessRef {
  addWorkSessions: (
    itemId: string,
    docId: string,
    projectId: string
  ) => Promise<void>;
}

interface Props {}

export const AddTaskWorkSessionsProcess = React.forwardRef<
  AddTaskWorkSessionsProcessRef,
  Props
>((_, forwardedRef) => {
  const { t } = useTranslate(["Task", "Global"]);

  const [current, { inc }] = useCounter(0);
  const [
    {
      isOpen,
      isOpenChildren,
      itemId,
      itemChildrenIds,
      itemChildrenIndex,
      allDocItems,
      projectId,
      onComplete,
    },
    setState,
  ] = useState<{
    isOpen: boolean;
    isOpenChildren: boolean;
    itemId: string | null;
    itemChildrenIds: string[];
    itemChildrenIndex: number;
    allDocItems: AddTaskWorkSessionsProcess_ItemFragment[] | null;
    projectId: string | null;
    onComplete?: () => void;
  }>({
    itemId: null,
    itemChildrenIds: [],
    itemChildrenIndex: 0,
    allDocItems: null,
    projectId: null,
    isOpen: false,
    isOpenChildren: false,
  });

  const client = useApolloClient();
  const [fetch, query] = useAddTaskWorkSessionsProcessLazyQuery({ client });

  const addWorkSessions = useCallback(
    async (itemId: string, docId: string, projectId: string) => {
      const result = await fetch({ variables: { projectId, docId } });
      const allDocItems = getDataOrNull(result.data?.project)?.project?.tasks[0]
        ?.allDocItems;

      return new Promise<void>(resolve => {
        if (!allDocItems) {
          resolve();
          return;
        }
        const currentItem = allDocItems.find(i => i.id === itemId);
        if (currentItem) {
          const childItems = getAllItemChildren(currentItem, allDocItems);
          const childItemsToAddWorkSessions = childItems.filter(
            item =>
              item.timeTrackingRequired && item.canBeWorkedOn && !item.deletedAt
          );
          const needApprove =
            currentItem.timeTrackingRequired &&
            currentItem.canBeWorkedOn &&
            !currentItem.deletedAt;
          if (needApprove || childItemsToAddWorkSessions.length > 0) {
            inc();
            setState({
              isOpen: needApprove,
              isOpenChildren:
                !needApprove && childItemsToAddWorkSessions.length > 0,
              itemId,
              itemChildrenIds: childItemsToAddWorkSessions.map(i => i.id),
              itemChildrenIndex: 0,
              allDocItems,
              projectId,
              onComplete: resolve,
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    [fetch, inc]
  );

  useImperativeHandle(forwardedRef, () => ({ addWorkSessions }));

  return isOpen && itemId && projectId && allDocItems ? (
    <AddTaskWorkSessionsModal
      key={`add-task-work-sessions-modal-${current}`}
      projectId={projectId}
      itemId={itemId}
      allDocItems={allDocItems}
      handleClose={() => {
        if (itemChildrenIds.length > 0) {
          inc();
          setState(s => ({
            ...s,
            isOpen: false,
            isOpenChildren: true,
            itemChildrenIndex: 0,
          }));
        } else {
          setState(s => ({
            ...s,
            isOpen: false,
          }));
          onComplete?.();
        }
      }}
    />
  ) : isOpenChildren &&
    itemChildrenIds.length > 0 &&
    projectId &&
    allDocItems ? (
    <AddTaskWorkSessionsModal
      key={`add-subtask-work-sessions-modal-${current}`}
      projectId={projectId}
      itemId={itemChildrenIds[itemChildrenIndex]!}
      allDocItems={allDocItems}
      title={`${t("Add subtask work sessions", {
        ns: "Task",
      })} ${itemChildrenIndex + 1}/${itemChildrenIds.length}`}
      description={t(
        "{number} subtasks of this item require adding spent time.",
        {
          ns: "Task",
          number: `${itemChildrenIds.length}`,
        }
      )}
      handleClose={() => {
        const nextItemChildrenIndex = itemChildrenIndex + 1;
        if (itemChildrenIds[nextItemChildrenIndex]) {
          inc();
          setState(s => ({
            ...s,
            itemChildrenIndex: nextItemChildrenIndex,
          }));
        } else {
          setState(s => ({
            ...s,
            isOpenChildren: false,
          }));
          onComplete?.();
        }
      }}
    />
  ) : (
    <></>
  );
});
