import { ButtonInputWithPopover } from "@msys/ui";
import { Box, Checkbox, Divider, FormControlLabel, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  FilterByOrganisationUsers,
  FilterProps,
} from "../../../commons/filters/index.js";
import { FilterChipGroupProps } from "../../../commons/filters/FilterChip.js";
import { FilterOrganisationUsersChips } from "../../organisations/OrganisationUsersSelect.js";
import { autocompleteProps } from "../ProjectAssigneeEditPopover.js";

export function FilterAssigneesChips(
  props: Omit<FilterChipGroupProps<string>, "label" | "getValueLabel">
) {
  const { t } = useTranslate("ProjectMembers");
  return <FilterOrganisationUsersChips {...props} label={t("Assignee")} />;
}

export const FilterAssignees = (props: FilterProps<string[]>) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByOrganisationUsers
      label={t("Assignees")}
      placeholder={t("Select assignees")}
      {...props}
    />
  );
};

function FilterProjectAssigneesForm({
  unassigned,
  setUnassigned,
  ...props
}: FilterProps<string[]> & {
  unassigned: boolean;
  setUnassigned: (value: boolean) => void;
}) {
  const { t } = useTranslate("Projects");
  return (
    <Stack direction="column" spacing={0}>
      <Box p={0.5} pl={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={unassigned}
              onChange={e => {
                setUnassigned(e.target.checked);
              }}
            />
          }
          label={t("Unassigned", { ns: "Projects" })}
        />
      </Box>
      <Divider />
      <FilterByOrganisationUsers
        label={t("Assignees")}
        placeholder={t("Search assignees")}
        autocompleteProps={{ ...autocompleteProps, renderTags: () => null }}
        {...props}
      />
    </Stack>
  );
}

export function FilterProjectAssigneesButton(
  props: FilterProps<string[]> & {
    unassigned: boolean;
    setUnassigned: (value: boolean) => void;
  }
) {
  const { t } = useTranslate("Projects");
  return (
    <ButtonInputWithPopover
      label={t("Assignees")}
      counter={
        (props.value && props.value.length > 0) || props.unassigned
          ? (props.value?.length ?? 0) + (props.unassigned ? 1 : 0)
          : undefined
      }
      content={<FilterProjectAssigneesForm {...props} />}
      popoverWidth="xl"
    />
  );
}
