import { useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useModifyOrganisationProfile__AboutMutation } from "../Organisations.generated.js";
import { EditOrganisationProfileAboutModal } from "../modals/EditOrganisationProfileAboutModal.js";
import { ModifyOrganisationProfileAboutButton_OrganisationProfileFragment } from "./ModifyOrganisationProfileAboutButton.generated.js";

interface Props {
  slug: string;
  organisationProfile: ModifyOrganisationProfileAboutButton_OrganisationProfileFragment;
}

export const ModifyOrganisationProfileAboutButton = ({
  slug,
  organisationProfile,
}: Props) => {
  const client = useApolloClient();
  const [modifyProfile] = useModifyOrganisationProfile__AboutMutation({
    client,
  });

  return (
    <ModalOpenButton
      Modal={EditOrganisationProfileAboutModal}
      modalProps={{
        organisationProfile,
        handleComplete: async values => {
          await modifyProfile({
            variables: {
              input: {
                slug,
                about: values.about,
                yearFounded: values.yearFounded || null,
                employeesNumber: values.employeesNumber || null,
                isMeister: values.isMeister === "yes",
              },
            },
          });
        },
      }}
    >
      <IconButton color="primary" size="small">
        <EditIcon />
      </IconButton>
    </ModalOpenButton>
  );
};
