import { useApolloClient } from "@apollo/client";
import {
  CardContainer,
  ListItem,
  LoadingSpinner as LoadingContainer,
  Tabs,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import { useUserData } from "../../auth/useUserData.js";
import { getActiveQuickFilterValue } from "../../commons/filters/getActiveQuickFilterValue.js";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam.js";
import { SHORT_LIST_RESULTS_PER_PAGE } from "../../constants.js";
import {
  TasksListQueryVariables,
  useTasksListQuery,
  useToggleTodoItemDoneMutation,
} from "../../main-routes/tasks/TasksList.generated.js";
import { TaskIcon } from "../tasks/TaskIcon.js";
import { CreateTodoItemButton } from "./CreateTodoItemButton.js";
import { EditTodoItemModal } from "./EditTodoItemModal.js";
import { TodoListItem } from "./TodoListItem.js";
import {
  TodosBox_LinkedCrmOrganisationFragment,
  TodosBox_LinkedCrmUserFragment,
  TodosBox_LinkedProjectFragment,
} from "./TodosBox.generated.js";

interface Props {
  limit?: number;
  fixedFilters?: Partial<
    Omit<TasksListQueryVariables, "limit" | "offset" | "sorting">
  >;
  linkedCrmOrganisation?: TodosBox_LinkedCrmOrganisationFragment;
  linkedCrmUser?: TodosBox_LinkedCrmUserFragment;
  linkedProject?: TodosBox_LinkedProjectFragment;
  renderSeeMoreButton?: (
    activeQuickFilter: string | undefined
  ) => React.ReactElement;
}

export function TodosBox(props: Props) {
  const { t } = useTranslate(["Tasks", "Global"]);
  const viewer = useUserData().currentUser!;

  type QuickFilterValue = "ALL_OPEN" | "MINE" | "OVERDUE" | "COMPLETED";
  const quickFilterSettings: Record<
    QuickFilterValue,
    {
      label: string;
      values: Omit<TasksListQueryVariables, "limit" | "offset" | "sorting">;
    }
  > = React.useMemo(
    () => ({
      ALL_OPEN: {
        label: t("All open", {
          ns: "Global",
        }),
        values: {
          filterIsDone: false,
        },
      },
      MINE: {
        label: t("Mine", {
          ns: "Global",
        }),
        values: {
          filterIsDone: false,
          filterAssigneeId: viewer.id,
        },
      },
      OVERDUE: {
        label: t("Overdue", {
          ns: "Global",
        }),
        values: {
          filterIsDone: false,
          filterTimeBefore: moment(),
        },
      },
      COMPLETED: {
        label: t("Completed", {
          ns: "Global",
        }),
        values: {
          filterIsDone: true,
        },
      },
    }),
    [t, viewer.id]
  );

  return (
    <TodosBoxComponent
      quickFilterSettings={quickFilterSettings}
      defaultQuickFilter="ALL_OPEN"
      {...props}
    />
  );
}

export function MyTodosBox(props: Props) {
  const { t } = useTranslate(["Tasks", "Global"]);
  const viewer = useUserData().currentUser!;

  type QuickFilterValue = "ALL_OPEN" | "OVERDUE" | "COMPLETED";
  const quickFilterSettings: Record<
    QuickFilterValue,
    {
      label: string;
      disabled?: boolean;
      values: Omit<TasksListQueryVariables, "limit" | "offset" | "sorting">;
    }
  > = React.useMemo(
    () => ({
      ALL_OPEN: {
        label: t("All open", {
          ns: "Global",
        }),
        values: {
          filterIsDone: false,
        },
      },
      OVERDUE: {
        label: t("Overdue", {
          ns: "Global",
        }),
        values: {
          filterIsDone: false,
          filterTimeBefore: moment(),
        },
      },
      COMPLETED: {
        label: t("Completed", {
          ns: "Global",
        }),
        values: {
          filterIsDone: true,
        },
      },
    }),
    [t]
  );

  return (
    <TodosBoxComponent
      title={t("My to-do list", {
        ns: "Tasks",
      })}
      fixedFilters={{ filterAssigneeId: viewer.id }}
      quickFilterSettings={quickFilterSettings}
      defaultQuickFilter="ALL_OPEN"
      defaultAssigneeId={viewer.id}
      disableAssigneeInput
      {...props}
    />
  );
}

function TodosBoxComponent<T extends string>({
  title,
  limit: passedLimit = SHORT_LIST_RESULTS_PER_PAGE,
  fixedFilters,
  quickFilterSettings,
  defaultQuickFilter,
  defaultAssigneeId,
  disableAssigneeInput,
  linkedCrmOrganisation,
  linkedCrmUser,
  linkedProject,
  renderSeeMoreButton,
}: Props & {
  title?: string;
  quickFilterSettings: Record<
    T,
    {
      label: string;
      values: Omit<TasksListQueryVariables, "limit" | "offset" | "sorting">;
    }
  >;
  defaultQuickFilter: T;
  defaultAssigneeId?: string;
  disableAssigneeInput?: boolean;
}) {
  const { t } = useTranslate(["Tasks", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const [limit, setLimit] = React.useState(passedLimit);

  const [quickFilter, setQuickFilter] =
    useState<keyof typeof quickFilterSettings>(defaultQuickFilter);

  const queryVariables: TasksListQueryVariables = {
    limit,
    sorting: [
      {
        column: "time",
        direction: "asc",
      },
    ],
    ...fixedFilters,
    filterLinkedCrmOrganisationId: linkedCrmOrganisation?.id,
    filterLinkedCrmUserId: linkedCrmUser?.id,
    filterLinkedProjectId: linkedProject?.id,
  };

  const client = useApolloClient();
  const query = useTasksListQuery({
    client,
    variables: {
      ...queryVariables,
      ...quickFilterSettings[quickFilter]?.values,
    },
  });
  const activeQuickFilter = getActiveQuickFilterValue(
    quickFilterSettings,
    Object.keys(queryVariables),
    query.variables
  );

  const [selectedTaskId, setSelectedTaskId] = useStateWithUrlParam<
    string | null
  >("taskId", null);

  const [toggleTodoDone] = useToggleTodoItemDoneMutation({
    client,
    refetchQueries: [namedOperations.Query.TasksList],
  });
  const tasks =
    (query.data ?? query.previousData)?.todoItems?.edges.map(e => e.node) ?? [];
  const total = (query.data ?? query.previousData)?.todoItems?.totalCount ?? 0;

  const handleToggle = async (task: { id: string; isDone: boolean }) => {
    await toggleTodoDone({
      variables: {
        input: { id: task.id, isDone: !task.isDone },
      },
    });
    enqueueSnackbar(
      task.isDone
        ? t("To-do marked as undone", { ns: "Tasks" })
        : t("To-do marked as done", { ns: "Tasks" })
    );
  };

  return (
    <CardContainer
      isExpandable
      Icon={<TaskIcon />}
      title={title ?? t("To-do list", { ns: "Tasks" })}
      ActionButton={
        <CreateTodoItemButton
          assigneeId={defaultAssigneeId}
          disableAssigneeInput={disableAssigneeInput}
          linkedProjectId={linkedProject?.id}
          linkedCrmCompanyId={linkedCrmOrganisation?.id}
          linkedCrmPersonId={linkedCrmUser?.id}
          refetchQueries={[namedOperations.Query.TasksList]}
        />
      }
    >
      <Stack direction="column" flex={1} width="100%" spacing={0}>
        <Box width="100%" pb={1} px={1} pt={1}>
          <Tabs
            condensed
            options={Object.entries<(typeof quickFilterSettings)[T]>(
              quickFilterSettings
            ).map(([key, value]) => {
              return { value: key as T, label: value.label };
            })}
            value={activeQuickFilter}
            onChange={(newQuickFilter: T) => {
              setQuickFilter(newQuickFilter);
            }}
          />
        </Box>

        {query.loading && tasks.length === 0 ? (
          <LoadingContainer />
        ) : tasks.length === 0 ? (
          <Stack
            direction="column"
            alignItems={"center"}
            padding={1}
            spacing={1}
          >
            <Typography variant="caption" color="gray">
              {t("There are no items to display", {
                ns: "Global",
              })}
            </Typography>

            <CreateTodoItemButton
              assigneeId={defaultAssigneeId}
              disableAssigneeInput={disableAssigneeInput}
              linkedProjectId={linkedProject?.id}
              linkedCrmCompanyId={linkedCrmOrganisation?.id}
              linkedCrmPersonId={linkedCrmUser?.id}
              refetchQueries={[namedOperations.Query.TasksList]}
              Button={
                <Button color="secondary" startIcon={<AddIcon />}>
                  {t("Add to-do item", {
                    ns: "Tasks",
                  })}
                </Button>
              }
            />
          </Stack>
        ) : (
          <Stack
            direction="column"
            m={-1}
            flex={1}
            style={{ overflow: "auto" }}
          >
            <Stack
              direction="column"
              spacing={0}
              style={{ overflow: "auto" }}
              p={1}
            >
              {tasks.map(task => (
                <ListItem
                  key={task.id}
                  onClick={() => setSelectedTaskId(task.id)}
                >
                  <TodoListItem task={task} onToggle={handleToggle} />
                </ListItem>
              ))}
            </Stack>
          </Stack>
        )}

        {total > limit && (
          <Box mt={1}>
            {renderSeeMoreButton ? (
              renderSeeMoreButton(activeQuickFilter)
            ) : (
              <Button
                fullWidth
                sx={{ borderRadius: 0 }}
                color="secondary"
                variant="text"
                onClick={() => setLimit(limit => limit + passedLimit)}
              >
                {t("See more", { ns: "Global" })}
              </Button>
            )}
          </Box>
        )}

        {selectedTaskId !== null && (
          <EditTodoItemModal
            key={selectedTaskId}
            id={selectedTaskId}
            refetchQueries={[namedOperations.Query.TasksList]}
            disableAssigneeInput={disableAssigneeInput}
            handleClose={() => setSelectedTaskId(null)}
          />
        )}
      </Stack>
    </CardContainer>
  );
}
