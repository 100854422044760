import { useApolloClient } from "@apollo/client";
import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import { Button } from "@mui/material";
import React from "react";
import uploadcare from "uploadcare-widget";
import { dataURLtoFile } from "../../attachments/helpers.js";
import { AddSignatureModal } from "../../../commons/modals/AddSignatureModal.js";
import { useAcceptChangesMutation } from "../Quotes.generated.js";
import { useTranslate } from "@tolgee/react";

export const AcceptChangesButton = ({
  docId,
  projectId,
  defaultPlace,
  handleComplete,
}: {
  docId: string;
  projectId: string;
  defaultPlace?: string;
  handleComplete?: () => Promise<unknown> | unknown;
}) => {
  const { t } = useTranslate(["QuoteEdit", "SignatureModal", "Global"]);
  const client = useApolloClient();
  const { isMaxPhone } = useScreenWidth();
  const [acceptChanges] = useAcceptChangesMutation({ client });

  return (
    <ModalOpenButton
      Modal={AddSignatureModal}
      modalProps={{
        defaultPlace,
        description: t("Please sign here to accept the quote.", {
          ns: "SignatureModal",
        }),
        handleComplete: async ({ signature, date, place }) => {
          let fileToUpload = dataURLtoFile(signature, "signature");
          let upload = uploadcare.fileFrom("object", fileToUpload);

          await new Promise<void>(resolve => {
            upload.done(async (fileInfo: any) => {
              await acceptChanges({
                variables: {
                  input: {
                    projectId,
                    docId,
                    signature: {
                      place: place,
                      date: date.format("YYYY-MM-DD"),
                      signature: {
                        url: fileInfo.cdnUrl,
                        title: fileInfo.name,
                        mimeType: fileInfo.mimeType,
                      },
                    },
                  },
                },
              });

              resolve();
            });
          });
          await handleComplete?.();
        },
      }}
    >
      <Button color="success" variant="contained">
        {isMaxPhone
          ? t("Accept", { ns: "Global" })
          : t("Accept changes", { ns: "QuoteEdit" })}
      </Button>
    </ModalOpenButton>
  );
};
