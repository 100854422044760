import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import { ArrayField } from "../../../commons/form-fields/ArrayField.js";
import { FormattedPercentageField } from "../../../commons/form-fields/FormattedPercentageField.js";
import { Stack } from "../../../commons/layout/Stack.js";

export interface Props {
  name: string;
  addButtonLabel: string;
  addButtonRef?: React.RefObject<HTMLButtonElement>;
  value: { priceFrom: number; priceTo: number; materialMargin: number }[];
  disabled?: boolean;
  hideCreateButton?: boolean;
  hideDeleteButton?: boolean;
  autoSubmit?: boolean;
  defaultMaterialMargin?: number;
}

export const MaterialMarginRangesField = ({
  addButtonLabel,
  addButtonRef,
  disabled,
  name,
  value,
  hideCreateButton,
  hideDeleteButton,
  autoSubmit,
  defaultMaterialMargin,
}: Props) => {
  // const renderItem = React.useCallback(
  //   (_: unknown, index: number) => (

  //   ),
  //   [disabled, name, t]
  // );

  return (
    <ArrayField
      name={name}
      value={value}
      disabled={disabled}
      renderItem={MaterialMarginRangesFieldItem}
      autoSubmit={autoSubmit}
      addButtonLabel={addButtonLabel}
      addButtonRef={addButtonRef}
      createNewItem={() =>
        value?.length > 0
          ? {
              priceFrom: value[value.length - 1].priceTo,
              priceTo: value[value.length - 1].priceTo + 1000,
              materialMargin: value[value.length - 1].materialMargin,
            }
          : {
              priceFrom: 0,
              priceTo: 1000,
              materialMargin: defaultMaterialMargin ?? 0.2,
            }
      }
      hideCreateButton={hideCreateButton}
      hideDeleteButton={hideDeleteButton}
    />
  );
};

const MaterialMarginRangesFieldItem = ({
  name,
  index,
  disabled,
}: {
  name: string;
  index: number;
  disabled: boolean | undefined;
}) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  return (
    <Stack justifyContent="stretch" flex={1}>
      <Box flex={1}>
        <Field
          component={TextField}
          type="number"
          step="1"
          name={`${name}.${index}.priceFrom`}
          label={`${t("Price from", {
            ns: "OrganisationSettings",
          })} (${t("included", {
            ns: "Global",
          })})`}
          disabled={disabled}
          fullWidth
        />
      </Box>
      <Box flex={1}>
        <Field
          component={TextField}
          type="number"
          step="1"
          name={`${name}.${index}.priceTo`}
          label={`${t("Price up to", {
            ns: "OrganisationSettings",
          })} (${t("excluded", {
            ns: "Global",
          })})`}
          disabled={disabled}
          fullWidth
        />
      </Box>
      <Box flex={1}>
        <FormattedPercentageField
          name={`${name}.${index}.materialMargin`}
          label={t("Material margin", {
            ns: "OrganisationSettings",
          })}
          disabled={disabled}
        />
      </Box>
    </Stack>
  );
};
