import { Modal } from "@msys/ui";
import { DialogContentText } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { AddressInput as AddressInputGQLType } from "../../../clients/graphqlTypes.js";
import { AddressDetails__AddressFragment } from "./Addresses.generated.js";
import { AddressInput } from "./AddressInput.js";
import { getAddressFromDetails } from "./AddressSearch.js";

interface Props {
  id?: string;
  inputLabel: string;
  value?: AddressInputGQLType | AddressDetails__AddressFragment | null;
  title?: string;
  description?: string;
  handleClose: () => void;
  handleComplete: (
    address: AddressInputGQLType,
    handleClose: () => void
  ) => Promise<void>;
  allowCustom?: boolean;
}

export const GetAddressModal = ({
  id,
  inputLabel,
  value,
  title,
  description,
  handleClose,
  handleComplete,
  allowCustom = false,
}: Props) => {
  const { t } = useTranslate(["Address", "Global"]);
  const [address, setAddress] = React.useState<AddressInputGQLType | null>(
    value ? getAddressFromDetails(value) : null
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  return (
    <Modal
      id={id}
      title={title ?? t("Address", { ns: "Address" })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Confirm", { ns: "Global" }),
          buttonProps: {
            disabled: !address,
            onClick: async () => {
              if (!address) return;
              setIsSubmitting(true);
              await handleComplete(address, handleClose);
              setIsSubmitting(false);
            },
            loading: isSubmitting,
          },
        },
      ]}
    >
      {description && <DialogContentText>{description}</DialogContentText>}
      <AddressInput
        label={inputLabel}
        // options={options}
        value={address}
        onChange={value => {
          setAddress(value);
        }}
        allowCustom={allowCustom}
      />
    </Modal>
  );
};
