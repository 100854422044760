import {
  getFormattedDuration,
  getFormattedFloat,
  getFormattedPrice,
} from "@msys/ui";
import { QuantityUnit } from "../../../clients/graphqlTypes.js";
import { isAnyParentMatching } from "../../trees/helpers.js";
import { gql } from "@apollo/client";
import {
  GetItemPriceData_InvoiceExtraItemFragment,
  GetItemPriceData_InvoiceItemFragment,
  GetItemPriceData_InvoicePaymentItemFragment,
  GetItemPriceData_QuoteItemFragment,
  ShouldHidePrice_InvoiceItemFragment,
  ShouldHidePrice_QuoteItemFragment,
} from "./helpers.generated.js";

interface PriceRow {
  quantity?: string | undefined;
  unitPrice?: string | undefined;
  subtotal?: string | undefined;
}

export interface PriceData {
  rows?: PriceRow[];
  total?: string | undefined;
}

export const getItemPriceData = (
  quantityUnitLabels: Record<QuantityUnit, string>,
  item:
    | GetItemPriceData_InvoiceItemFragment
    | GetItemPriceData_InvoiceExtraItemFragment
    | GetItemPriceData_InvoicePaymentItemFragment,
  quoteItems: GetItemPriceData_QuoteItemFragment[],
  isTimeAndMaterialContract: boolean,
  locale: string
): PriceData => {
  if (item.__typename === "InvoiceExtraItem") {
    return {
      rows: [
        {
          quantity: `${item.quantity} ${quantityUnitLabels[item.quantityUnit]}`,
          unitPrice: getFormattedPrice(item.pricePerUnit, locale),
          subtotal: getFormattedPrice(
            item.pricePerUnit * item.quantity,
            locale
          ),
        },
      ],
      total: getFormattedPrice(item.pricePerUnit * item.quantity, locale),
    };
  }
  if (item.__typename === "InvoicePaymentItem") {
    return {
      rows: [
        {
          quantity: `1`,
          unitPrice: getFormattedPrice(item.price, locale),
          subtotal: getFormattedPrice(item.price, locale),
        },
      ],
      total: getFormattedPrice(item.price, locale),
    };
  }

  if (item.__typename === "InvoiceItem") {
    const hidePrices = shouldHidePrice(item, quoteItems);

    if (item.item.isRootItem) {
      // nothing for root item
      return {};
    } else {
      if (item.item.type === "section") {
        const quantity =
          item.calculation.quantity && item.calculation.quantity > 1
            ? `${item.calculation.quantity}`
            : undefined;
        if (hidePrices) {
          return {
            rows: [
              {
                quantity,
              },
            ],
          };
        }

        if (item.item.level === 1)
          return {
            rows: [
              {
                quantity,
              },
            ],
            total: getFormattedPrice(item.calculation.priceNetTotal, locale),
          };

        return {
          rows: [
            {
              quantity,
              subtotal: getFormattedPrice(
                item.calculation.priceNetTotal,
                locale
              ),
            },
          ],
        };
      }

      if (item.item.type === "decision") {
        if (hidePrices) return {};

        if (item.item.level === 1) {
          return {
            total: getFormattedPrice(item.calculation.priceNetTotal, locale),
          };
        }

        return {
          rows: [
            {
              subtotal: getFormattedPrice(
                item.calculation.priceNetTotal,
                locale
              ),
            },
          ],
        };
      }

      if (item.item.type === "paid") {
        const quantity =
          item.calculation.quantity !== undefined
            ? `${item.calculation.quantity} ${
                item.calculation.quantityUnit in quantityUnitLabels
                  ? quantityUnitLabels[
                      item.calculation.quantityUnit as QuantityUnit
                    ]
                  : item.calculation.quantityUnit
              }`
            : undefined;

        if (hidePrices) {
          return {
            rows: [
              {
                quantity,
              },
            ],
          };
        }

        if (isTimeAndMaterialContract) {
          const totalHours =
            item.calculation.timeTotal && item.calculation.timeTotal / 60;

          return {
            rows: [
              {
                quantity,
                unitPrice: getFormattedPrice(
                  item.calculation.materialPricePerUnit,
                  locale
                ),

                subtotal: getFormattedPrice(
                  item.calculation.materialPriceSubTotal,
                  locale
                ),
              },
              {
                quantity:
                  item.calculation.timeTotal !== undefined
                    ? `${getFormattedDuration(item.calculation.timeTotal)} h`
                    : undefined,

                unitPrice: getFormattedPrice(
                  item.calculation.workSellingRate,
                  locale
                ),

                subtotal:
                  totalHours !== undefined
                    ? getFormattedPrice(
                        totalHours * item.calculation.workSellingRate,
                        locale
                      )
                    : undefined,
              },
            ],
            total:
              item.item.level === 1
                ? getFormattedPrice(item.calculation.priceSubTotal, locale)
                : undefined,
          };
        }

        return {
          rows: [
            {
              quantity,
              unitPrice: getFormattedPrice(
                item.calculation.pricePerUnit,
                locale
              ),

              subtotal: getFormattedPrice(
                item.calculation.priceSubTotal,
                locale
              ),
            },
          ],
          total:
            item.item.level === 1
              ? getFormattedPrice(item.calculation.priceSubTotal, locale)
              : undefined,
        };
      }
    }
  }

  throw new Error("Unknown item type");
};

const shouldHidePrice = (
  item: ShouldHidePrice_InvoiceItemFragment,
  quoteItems: ShouldHidePrice_QuoteItemFragment[]
) => {
  if (item.__typename !== "InvoiceItem") return false;

  const quoteItem = item.item;
  const hidePrice =
    quoteItem.isExplicitHidden ||
    isAnyParentMatching(
      quoteItem,
      quoteItems,
      parentItem => parentItem.childrenVisibility === "SHOW_CHILDREN_HIDE_PRICE"
    );

  return hidePrice;
};
