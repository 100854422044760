import React from "react";
import { CellMeasurerCacheInterface } from "react-virtualized/dist/es/CellMeasurer.js";

export const useHeightsCache = <I>(
  items: I[],
  defaultHeight: number,
  getItemId: (item: I) => string
) => {
  const itemsRef = React.useRef(items);
  itemsRef.current = items;
  const getItemIdRef = React.useRef(getItemId);
  getItemIdRef.current = getItemId;

  const [heightsById, setHeightsById] = React.useState<Record<string, number>>(
    {}
  );

  const totalHeight = React.useMemo(
    () =>
      items.reduce(
        (acc, i) =>
          acc + (heightsById[getItemIdRef.current(i)] ?? defaultHeight),
        0
      ),
    [heightsById, items, defaultHeight]
  );

  const getHeightForIndex = React.useCallback(
    ({ index }: { index: number }) => {
      const item = itemsRef.current[index];
      const itemId = item ? getItemIdRef.current(item) : null;
      return itemId ? (heightsById[itemId] ?? defaultHeight) : defaultHeight;
    },
    [heightsById, defaultHeight]
  );
  const setHeightForId = React.useCallback(
    (id: string, height: number) => {
      const oldHeightById = heightsById[id];
      setHeightsById(state => ({ ...state, [id]: height }));
      return oldHeightById !== height;
    },
    [heightsById]
  );

  const cache = React.useMemo(
    (): CellMeasurerCacheInterface => ({
      hasFixedWidth() {
        return true;
      },
      hasFixedHeight() {
        return true;
      },
      has(rowIndex: number, columnIndex: number) {
        // important here!
        return true; // heightsByIndex.current[rowIndex] !== undefined;
      },
      set(
        rowIndex: number,
        columnIndex: number,
        width: number,
        height: number
      ) {
        const item = itemsRef.current[rowIndex];
        const itemId = item ? getItemIdRef.current(item) : null;
        if (itemId !== null) {
          setHeightForId(itemId, height);
        }
      },
      getHeight(rowIndex: number, columnIndex?: number) {
        return getHeightForIndex({ index: rowIndex });
      },
      getWidth(rowIndex: number, columnIndex?: number) {
        return 0;
      },
    }),
    [getHeightForIndex, setHeightForId]
  );

  return {
    totalHeight,
    getHeightForIndex,
    setHeightForId,
    cache,
  };
};
