import { gql, useApolloClient } from "@apollo/client";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { CircularProgress } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { FileInput } from "../../../commons/inputs/FileInput.js";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { usePimRequestImportationUploadUrlMutation } from "../PimImportations.generated.js";
import {
  PimSupplierDiscountGroupsImportationMappingProcess,
  PimSupplierDiscountGroupsImportationMappingProcessRef,
} from "../PimSupplierDiscountGroupsImportationMappingProcess.js";
import { useAddPimSupplierDiscountGroupsImportationButton_CreatePimSupplierDiscountGroupsImportationMutation } from "./AddPimSupplierDiscountGroupsImportationButton.generated.js";

export const AddPimSupplierDiscountGroupsImportationButton = ({
  supplierDiscountGroupVariantId,
  supplierId,
}: {
  supplierDiscountGroupVariantId: string;
  supplierId: string;
}) => {
  const client = useApolloClient();

  const { t } = useTranslate(["SupplierDiscountGroups"]);

  const [status, setStatus] = React.useState<"idle" | "loading" | "error">(
    "idle"
  );
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const pimMappingProcessRef =
    React.useRef<PimSupplierDiscountGroupsImportationMappingProcessRef>();

  const [pimRequestImportationUploadUrlMutation] =
    usePimRequestImportationUploadUrlMutation({
      client,
    });

  const [addPimImport] =
    useAddPimSupplierDiscountGroupsImportationButton_CreatePimSupplierDiscountGroupsImportationMutation(
      {
        client,
        refetchQueries: [namedOperations.Query.PimImportations],
      }
    );

  return (
    <>
      <MenuItemWithIcon
        onClick={() => {
          fileInputRef.current!.click();
        }}
        icon={
          status === "loading" ? (
            <CircularProgress size={"1.25rem"} />
          ) : (
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          )
        }
      >
        {t("Import data", { ns: "SupplierDiscountGroups" })}
      </MenuItemWithIcon>
      <FileInput
        innerRef={fileInputRef}
        accept={".csv"}
        multiple={false}
        onStart={() => {
          setStatus("loading");
        }}
        onCancel={() => {
          setStatus("idle");
        }}
        onComplete={async (file: File) => {
          const uploadFile = async (file: File) => {
            const result = await pimRequestImportationUploadUrlMutation({
              variables: {
                input: {
                  supplierId,
                  filename: file.name,
                },
              },
            });

            await fetch(result.data!.pimRequestImportationUploadUrl.uploadUrl, {
              method: "PUT",
              body: file,
            }).catch(err => {
              setStatus("error");
              console.info(err);
            });

            return { filename: file.name };
          };

          try {
            const { filename } = await uploadFile(file);
            const csvConfigurations = await pimMappingProcessRef.current?.start(
              {
                filename,
              }
            );
            if (csvConfigurations) {
              await addPimImport({
                variables: {
                  input: {
                    filename: filename,
                    supplierId,
                    supplierDiscountGroupVariantId:
                      supplierDiscountGroupVariantId,
                    csvConfigurations: csvConfigurations,
                  },
                },
              });
            }

            // TODO: fix
            await client.reFetchObservableQueries();

            setStatus("idle");
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
            setStatus("error");
            console.error(e);
            Sentry.captureException(e);
          }
        }}
      />
      <PimSupplierDiscountGroupsImportationMappingProcess
        ref={pimMappingProcessRef}
        supplierId={supplierId}
      />
    </>
  );
};
