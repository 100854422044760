import { Attachment3dModel, AttachmentImage } from "@msys/ui";
import { Box, BoxProps } from "@mui/material";
import React from "react";
import { Attachment } from "../../attachments/helpers.js";
import { GalleryRow } from "../../../commons/images/GalleryRow.js";

interface Props extends BoxProps {
  pictures: (AttachmentImage | Attachment3dModel)[];
  imageHeight?: number;
  aspectRatio?: React.CSSProperties["aspectRatio"];
  handleAdd?: () => void;
  handleDelete?: (image: Attachment) => void;
  handleRotate?: (image: Attachment, direction: "right" | "left") => void;
  disabled?: boolean;
  /*
   * Use Modal as a standalone modal which is not pushing something to stack which involves closing other modals
   * Useful to show small modal on top of existing one without closin it
   */
  notInStack?: boolean;
}

export const PropertyGalleryBox = ({
  pictures,
  imageHeight,
  aspectRatio,
  notInStack,
  handleAdd,
  handleDelete,
  handleRotate,
  disabled,
  ...props
}: Props) => {
  const hasContent = pictures.length > 0;
  if (!hasContent) return null;

  return (
    <Box {...props}>
      <GalleryRow
        images={pictures}
        maxItems={1}
        imageHeight={imageHeight}
        aspectRatio={aspectRatio}
        notInStack={notInStack}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        handleRotate={handleRotate}
        disabled={disabled}
      />
    </Box>
  );
};
