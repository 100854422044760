import { ellipsisStyle, getFormattedDateWithoutYear } from "@msys/ui";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import { Done as DoneIcon } from "@mui/icons-material";
import { EventAvailable as EventAvailableIcon } from "@mui/icons-material";
import { PhotoCamera as PhotoCameraIcon } from "@mui/icons-material";
import { QueryBuilder as CalendarIcon } from "@mui/icons-material";
import { ThumbDown as ThumbDownIcon } from "@mui/icons-material";
import { ThumbUp as ThumbUpIcon } from "@mui/icons-material";
import { ThumbUpOutlined as ThumbsUpOutlinedIcon } from "@mui/icons-material";
import { Timer as TimerIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTolgee } from "@tolgee/react";
import moment from "moment";
import React from "react";
import {
  Agreement,
  ItemType,
  PermissionName,
} from "../../../clients/graphqlTypes.js";
import { color, px } from "../../../common/MuiThemeProvider.js";
import { ReactComponent as HourglassBottomIcon } from "../../assets/icons/icon-hourglass-bottom.svg";
import { ReactComponent as HourglassTopIcon } from "../../assets/icons/icon-hourglass-top.svg";
import { ReactComponent as QAIcon } from "../../assets/icons/icon-qa.svg";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { VirtualItem } from "../../trees-virtual/types.js";
import { BareTreeItem, RIGHT_ICON_STYLE } from "../../trees/BareTreeItem.js";
import {
  TreeDataContainer,
  TreeDataIcon,
} from "../../trees/components/TreeDataContainer.js";
import { getParentItem } from "../../trees/helpers.js";
import {
  InputComponentProps,
  ItemComponent,
  ItemComponentProps,
} from "../../trees/types.js";
import { durationInMinutesToTime, entriesAmount } from "../../utils.js";
import { CreateItemInput } from "../doc-items/CreateItemInput.js";
import { isItemGreyedOut } from "../doc-items/helpers.js";
import { getSubcontractPath } from "../quotes/helpers.js";
import { QuoteStatusBadge } from "../quotes/QuoteStatusBadge.js";
import { UserIcon } from "../users/UserIcon.js";
import { AddWorkSessionButton } from "../work-sessions/buttons/AddWorkSessionButton.js";
import { AddPhotoApprovalIconButton } from "./AddPhotoApprovalIconButton.js";
import { ToggleApprovalStatusButton } from "./ToggleApprovalStatusButton.js";
import { ToggleQaApprovalStatusButton } from "./ToggleQaApprovalStatusButton.js";
import { ToggleTaskCompletionIconButton } from "./ToggleTaskCompletionIconButton.js";
import {
  TasksTree_DocumentFragment,
  TaskTreeItem_Data123Fragment,
  TaskTreeItem_EstimatedDurationFragment,
  TaskTreeItem_ItemLeftButtonFragment,
  TaskTreeItem_ItemRightButtonFragment,
  TaskTreeItem_ItemRightIconsFragment,
  TaskTreeItem_TreeItemFragment,
  TaskTreeItem_TreeListItemFragment,
  TaskTreeItem_TreeRootItemFragment,
  TaskTreeItem_TreeSimpleItemFragment,
} from "./trees.generated.js";

export function createTreeItemInput<
  Item extends VirtualItem & {
    docId: string;
    docViewerPermissions: PermissionName[];
  },
  ChildItem extends VirtualItem,
>({
  createItem,
  createItemByType,
}: Pick<
  React.ComponentProps<typeof CreateItemInput>,
  "createItem" | "createItemByType"
>) {
  return (itemProps: InputComponentProps<Item, ChildItem>) => {
    const documentItemTypes: ItemType[] = [
      ...(itemProps.item.docViewerPermissions.includes("EXECUTE_TASK")
        ? (["unpaid"] as ItemType[])
        : []),
      "defect",
    ];

    return (
      <CreateItemInput
        doc={{
          id: itemProps.item.docId,
          docType: "QUOTE",
          templateIsDraft: false,
        }}
        documentItemTypes={documentItemTypes}
        createItem={createItem}
        createItemByType={createItemByType}
        createItemFromTemplate={null}
        createItemsFromTemplates={null}
        createItemsWithProducts={null}
        pasteItem={null}
        isAllowedToPasteItem={() => false}
        {...itemProps}
      />
    );
  };
}

export const createTreeItem =
  <
    T extends TaskTreeItem_TreeItemFragment & {
      docAgreement: Agreement;
      docViewerPermissions: PermissionName[];
    },
  >({
    pathToDocPage,
    projectId,
    onBeforeTaskDone,
    onAfterTaskDone,
    checkPhotoApprove,
    addWorkSession,
    navigateToItem,
    setItemExpanded,
  }: {
    pathToDocPage: string | null;
    projectId: string;
    onBeforeTaskDone: (
      itemId: string,
      docId: string,
      projectId: string,
      newIsDone: boolean
    ) => boolean | Promise<boolean>;
    onAfterTaskDone: (
      itemId: string,
      docId: string,
      projectId: string,
      newIsDone: boolean
    ) => void | Promise<void>;
    checkPhotoApprove: (
      itemId: string,
      docId: string,
      checkChildren: boolean
    ) => void;
    addWorkSession: (itemId: string, projectId: string, docId: string) => void;
    navigateToItem: (id: string) => void;
    setItemExpanded: (id: string, expanded: boolean) => void;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const theme: Theme = useTheme();
    const language = useTolgee(["language"]).getLanguage()!;

    const [Data123Left, Data123Right] = React.useMemo(
      () =>
        getItemData123(
          itemProps.item.docViewerPermissions,
          itemProps.item,
          theme,
          language
        ),
      [language, itemProps.item, theme]
    );

    const IconLeftButtons = React.useMemo(
      () =>
        getItemLeftButton(
          projectId,
          itemProps.item.docViewerPermissions,
          itemProps.item,
          onBeforeTaskDone,
          onAfterTaskDone,
          theme
        ),
      [itemProps.item, theme]
    );

    const IconRightButtons = React.useMemo(
      () =>
        getItemRightButton(
          projectId,
          itemProps.item,
          itemProps.item.docId,
          itemProps.item.docViewerPermissions,
          checkPhotoApprove,
          addWorkSession
        ),
      [itemProps.item]
    );

    const RightIcons = React.useMemo(
      () =>
        getItemRightIcons(
          itemProps.item.docViewerPermissions,
          itemProps.item,
          theme
        ),
      [itemProps.item, theme]
    );

    return (
      <BareTreeItem
        docAgreement={itemProps.item.docAgreement}
        RightIcons={RightIcons}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconLeftButtons={IconLeftButtons}
        IconRightButtons={IconRightButtons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        collapseButton={itemProps.collapseButton}
        subcontractPath={itemProps.subcontractPath}
        onClick={() => {
          if (!pathToDocPage) {
            navigateToItem(itemProps.item.id);
          }
          setItemExpanded(itemProps.item.id, true);
        }}
        to={
          pathToDocPage
            ? `${pathToDocPage}/${itemProps.item.docId}/items/${itemProps.item.id}`
            : undefined
        }
      />
    );
  };

export const createTreeRootItem =
  <
    T extends TaskTreeItem_TreeRootItemFragment & {
      docAgreement: Agreement;
      docViewerPermissions: PermissionName[];
    },
  >({
    projectId,
    tasks,
    checkPhotoApprove,
    addWorkSession,
    navigateToItem,
    setItemExpanded,
  }: {
    projectId: string;
    tasks: TasksTree_DocumentFragment[];
    checkPhotoApprove: (
      itemId: string,
      docId: string,
      checkChildren: boolean
    ) => void;
    addWorkSession: (itemId: string, projectId: string, docId: string) => void;
    navigateToItem: (id: string) => void;
    setItemExpanded: (id: string, expanded: boolean) => void;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const theme: Theme = useTheme();
    const language = useTolgee(["language"]).getLanguage()!;

    const [Data123Left, Data123Right] = React.useMemo(
      () =>
        getItemData123(
          itemProps.item.docViewerPermissions,
          itemProps.item,
          theme,
          language
        ),
      [language, itemProps.item, theme]
    );

    const docViewerPermissions = React.useMemo(
      () =>
        tasks.find(t => t.id === itemProps.item?.docId)?.viewerPermissions ??
        [],
      [itemProps]
    );

    const IconRightButtons = React.useMemo(
      () =>
        getItemRightButton(
          projectId,
          itemProps.item,
          itemProps.item.docId,
          itemProps.item.docViewerPermissions,
          checkPhotoApprove,
          addWorkSession
        ),
      [itemProps.item]
    );

    const RightIcons = React.useMemo(
      () =>
        getItemRightIcons(
          itemProps.item.docViewerPermissions,
          itemProps.item,
          theme
        ),
      [itemProps.item, theme]
    );

    // const RightNumber = React.useMemo(
    //   () => <EntityNumber id={itemProps.item.docId} />,
    //   [itemProps.item]
    // );

    const doc = tasks.find(task => task.id === itemProps.item.docId);

    const LeftStatus = React.useMemo(
      () => doc && <QuoteStatusBadge quote={doc} small />,
      [doc]
    );

    if (!doc) return null;

    return (
      <BareTreeItem
        docAgreement={doc.agreement}
        RightIcons={RightIcons}
        LeftStatus={LeftStatus}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        showPath={false}
        item={itemProps.item}
        isRootItem={true}
        depth={0}
        isGreyedOut={false}
        isHidden={false}
        isPriceHidden={false}
        collapseButton={itemProps.collapseButton}
        subcontractPath={itemProps.subcontractPath}
        onClick={() => {
          navigateToItem(itemProps.item.id);
          setItemExpanded(itemProps.item.id, true);
        }}
      />
    );
  };

export type IconButtonFactory<T> = (item: T) => JSX.Element | null;

export const createTreeSimpleItem =
  <
    T extends TaskTreeItem_TreeSimpleItemFragment & {
      docAgreement: Agreement;
      docViewerPermissions: PermissionName[];
    },
  >({
    pathToDocPage,
    docAgreement,
    navigateToItem,
    setItemExpanded,
    getIconRightButton,
  }: {
    pathToDocPage: string | null;
    docAgreement: Agreement;
    navigateToItem: (id: string) => void;
    setItemExpanded: (id: string, expanded: boolean) => void;
    getIconRightButton?: IconButtonFactory<T>;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const theme: Theme = useTheme();
    const language = useTolgee(["language"]).getLanguage()!;

    const IconLeftButtons = React.useMemo(() => {
      if (!itemProps.item.isDone) return null;
      return (
        <DoneIcon
          fontSize="medium"
          style={{ marginRight: px.xs, color: theme.palette.success.main }}
        />
      );
    }, [itemProps.item, theme]);

    const IconRightButtons = React.useMemo(
      () => (getIconRightButton ? getIconRightButton(itemProps.item) : null),
      [itemProps.item]
    );

    const [Data123Left, Data123Right] = React.useMemo(
      () =>
        getItemData123(
          itemProps.item.docViewerPermissions,
          itemProps.item,
          theme,
          language
        ),
      [itemProps.item, theme, language]
    );
    const RightIcons = React.useMemo(
      () =>
        getItemRightIcons(
          itemProps.item.docViewerPermissions,
          itemProps.item,
          theme
        ),
      [itemProps.item, theme]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        RightIcons={RightIcons}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconLeftButtons={IconLeftButtons}
        IconRightButtons={IconRightButtons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        collapseButton={itemProps.collapseButton}
        subcontractPath={itemProps.subcontractPath}
        onClick={() => {
          if (!pathToDocPage) {
            navigateToItem(itemProps.item.id);
          }
          setItemExpanded(itemProps.item.id, true);
        }}
        to={
          pathToDocPage
            ? `${pathToDocPage}/${itemProps.item.docId}/items/${itemProps.item.id}`
            : undefined
        }
      />
    );
  };

export const TaskListItem = <T extends TaskTreeItem_TreeListItemFragment>({
  item,
  allDocItems,
  itemComponent: TreeItem,
}: {
  item: T;
  allDocItems: T[];
  itemComponent: ItemComponent<T>;
}) => {
  const parentItem = getParentItem(item, allDocItems) ?? null;

  return (
    <TreeItem
      item={item}
      allItems={allDocItems}
      isRootItem={item.isRootItem}
      depth={entriesAmount(item.pathForPdf, ".")}
      parentItem={parentItem}
      isGreyedOut={isItemGreyedOut(item)}
      isHidden={item.isHidden}
      isPriceHidden={item.isPriceHidden}
      subcontractPath={getSubcontractPath(item)}
    />
  );
};

const getEstimatedDuration = (item: TaskTreeItem_EstimatedDurationFragment) => {
  switch (item.type) {
    case "paid":
    case "section": {
      return item.proposedCalculation?.timePerUnit || 0;
    }
  }
  return 0;
};

export const getItemData123 = <T extends TaskTreeItem_Data123Fragment>(
  docViewerPermissions: Array<PermissionName>,
  item: T,
  theme: Theme,
  locale: string
): [JSX.Element | null, JSX.Element | null] => {
  const estimatedDuration = getEstimatedDuration(item);
  const workDurationColor =
    item.workSessionsDuration > 0
      ? estimatedDuration > 0 && estimatedDuration >= item.workSessionsDuration
        ? color.green
        : estimatedDuration > 0 && estimatedDuration < item.workSessionsDuration
          ? color.orange
          : color.green
      : undefined;
  const dueDateColor =
    item.dueDate && moment(item.dueDate).endOf("day") < moment()
      ? theme.palette.error.main
      : undefined;
  return [
    <TreeDataContainer key={"left"}>
      {item.assignee ? (
        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={{ viewerPermissions: docViewerPermissions }}
        >
          <div style={{ ...ellipsisStyle, minWidth: 32 }}>
            <TreeDataIcon>
              <UserIcon fontSize="inherit" />
            </TreeDataIcon>{" "}
            {`${
              item.assignee?.firstname ? item.assignee?.firstname[0] + "." : ""
            } ${item.assignee?.familyname}`}
          </div>
        </RestrictedByDocumentPermissionWithDebug>
      ) : null}
      {item.isDone && item.doneDate ? (
        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={{ viewerPermissions: docViewerPermissions }}
        >
          <div style={{ color: theme.palette.success.main }}>
            <TreeDataIcon>
              <EventAvailableIcon fontSize="inherit" />
            </TreeDataIcon>{" "}
            {getFormattedDateWithoutYear(item.doneDate, locale)}
            {item.priority > 0 ? " " + Array(item.priority + 1).join("!") : ""}
          </div>
        </RestrictedByDocumentPermissionWithDebug>
      ) : item.dueDate ? (
        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={{ viewerPermissions: docViewerPermissions }}
        >
          <div style={{ color: dueDateColor }}>
            <TreeDataIcon>
              <CalendarIcon fontSize="inherit" />
            </TreeDataIcon>{" "}
            {getFormattedDateWithoutYear(item.dueDate, locale)}
            {item.priority > 0 ? " " + Array(item.priority + 1).join("!") : ""}
          </div>
        </RestrictedByDocumentPermissionWithDebug>
      ) : null}
      {estimatedDuration > 0 && (
        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={{ viewerPermissions: docViewerPermissions }}
        >
          <div>
            <TreeDataIcon style={{ left: 1 }}>
              <HourglassTopIcon style={{ height: 12 }} />
            </TreeDataIcon>{" "}
            {durationInMinutesToTime(estimatedDuration)}
          </div>
        </RestrictedByDocumentPermissionWithDebug>
      )}
      {item.workSessionsDuration > 0 && (
        <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
          <RestrictedByDocumentPermissionWithDebug
            permission="EXECUTE_TASK"
            document={{ viewerPermissions: docViewerPermissions }}
          >
            <div style={{ color: workDurationColor }}>
              <TreeDataIcon style={{ left: 1 }}>
                <HourglassBottomIcon style={{ height: 12 }} />
              </TreeDataIcon>{" "}
              {durationInMinutesToTime(item.workSessionsDuration)}
            </div>
          </RestrictedByDocumentPermissionWithDebug>
        </RestrictedByCapabilityWithDebug>
      )}
    </TreeDataContainer>,
    null,
  ];
};

export const getItemRightIcons = <
  T extends TaskTreeItem_ItemRightIconsFragment,
>(
  docViewerPermissions: Array<PermissionName>,
  item: T,
  theme: Theme
) => (
  <>
    <RestrictedByDocumentPermissionWithDebug
      permission="EXECUTE_TASK"
      document={{ viewerPermissions: docViewerPermissions }}
    >
      {item.timeTrackingRequired ? (
        item.workSessionsDuration > 0 ? (
          <TimerIcon
            style={{
              ...RIGHT_ICON_STYLE,
              color: theme.palette.success.main,
            }}
          />
        ) : (
          <TimerIcon
            style={{
              ...RIGHT_ICON_STYLE,
              color: theme.palette.grey[400],
            }}
          />
        )
      ) : null}
    </RestrictedByDocumentPermissionWithDebug>
    {item.photoApprovalRequired ? (
      item.photoApproved ? (
        <PhotoCameraIcon
          style={{
            ...RIGHT_ICON_STYLE,
            color: theme.palette.success.main,
          }}
        />
      ) : (
        <AddAPhotoIcon
          style={{
            ...RIGHT_ICON_STYLE,
            color: theme.palette.grey[400],
          }}
        />
      )
    ) : null}
    <RestrictedByDocumentPermissionWithDebug
      permission="EXECUTE_TASK"
      document={{ viewerPermissions: docViewerPermissions }}
    >
      {item.qaApprovalRequired ? (
        item.isQaApproved === true ? (
          <QAIcon
            style={{
              ...RIGHT_ICON_STYLE,
              color: theme.palette.success.main,
            }}
          />
        ) : item.isQaApproved === false ? (
          <QAIcon
            style={{
              ...RIGHT_ICON_STYLE,
              color: theme.palette.error.main,
            }}
          />
        ) : (
          <QAIcon
            style={{
              ...RIGHT_ICON_STYLE,
              color: theme.palette.grey[400],
            }}
          />
        )
      ) : null}
    </RestrictedByDocumentPermissionWithDebug>
    {item.approvalRequired
      ? item.isVisibleToOtherSide &&
        (item.isApproved === true ? (
          <ThumbUpIcon
            style={{
              ...RIGHT_ICON_STYLE,
              color: theme.palette.success.main,
            }}
          />
        ) : item.isApproved === false ? (
          <ThumbDownIcon
            style={{
              ...RIGHT_ICON_STYLE,
              color: theme.palette.error.main,
            }}
          />
        ) : (
          <ThumbsUpOutlinedIcon
            style={{
              ...RIGHT_ICON_STYLE,
              color: theme.palette.grey[400],
            }}
          />
        ))
      : null}
  </>
);

const getItemLeftButton = (
  projectId: string,
  docViewerPermissions: Array<PermissionName>,
  item: TaskTreeItem_ItemLeftButtonFragment,
  onBeforeTaskDone: (
    itemId: string,
    docId: string,
    projectId: string,
    newIsDone: boolean
  ) => boolean | Promise<boolean>,
  onAfterTaskDone: (
    itemId: string,
    docId: string,
    projectId: string,
    newIsDone: boolean
  ) => void | Promise<void>,
  theme: Theme
) => {
  if (!item.canBeWorkedOn || item.deletedAt) return null;

  return (
    <RestrictedByDocumentPermissionWithDebug
      permission="EXECUTE_TASK"
      document={{ viewerPermissions: docViewerPermissions }}
      otherwise={
        !item.isDone ? undefined : (
          <DoneIcon
            fontSize="medium"
            style={{ marginRight: px.xs, color: theme.palette.success.main }}
          />
        )
      }
    >
      <ToggleTaskCompletionIconButton
        disabled={Boolean(item.deletedAt)}
        buttonType="icon"
        projectId={projectId}
        docId={item.docId}
        itemId={item.id}
        itemIsDone={item.isDone}
        onBeforeTaskDone={onBeforeTaskDone}
        onAfterTaskDone={onAfterTaskDone}
      />
    </RestrictedByDocumentPermissionWithDebug>
  );
};

const getItemRightButton = (
  projectId: string,
  item: TaskTreeItem_ItemRightButtonFragment,
  docId: string,
  docViewerPermissions: Array<PermissionName>,
  checkPhotoApprove: (
    itemId: string,
    docId: string,
    checkChildren: boolean
  ) => void,
  addWorkSession: (itemId: string, projectId: string, docId: string) => void
) => {
  if (!item.canBeWorkedOn || item.deletedAt) return null;

  if (!item.isDone) {
    if (item.timeTrackingRequired) {
      return (
        <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
          <RestrictedByDocumentPermissionWithDebug
            permission="EXECUTE_TASK"
            document={{ viewerPermissions: docViewerPermissions }}
          >
            <AddWorkSessionButton
              projectId={projectId}
              docId={docId}
              itemId={item.id}
              type="icon-button"
              addWorkSession={addWorkSession}
            />
          </RestrictedByDocumentPermissionWithDebug>
        </RestrictedByCapabilityWithDebug>
      );
    }
    return <></>;
  }

  if (item.photoApprovalRequired && !item.photoApproved) {
    return (
      <RestrictedByDocumentPermissionWithDebug
        permission="EXECUTE_TASK"
        document={{ viewerPermissions: docViewerPermissions }}
      >
        <AddPhotoApprovalIconButton
          itemId={item.id}
          item={item}
          docId={docId}
          checkPhotoApprove={checkPhotoApprove}
        />
      </RestrictedByDocumentPermissionWithDebug>
    );
  }

  if (
    item.qaApprovalRequired &&
    (!item.isQaApproved || !item.approvalRequired)
  ) {
    return (
      <RestrictedByDocumentPermissionWithDebug
        permission="MANAGE_TASK"
        document={{ viewerPermissions: docViewerPermissions }}
      >
        <ToggleQaApprovalStatusButton
          docId={docId}
          projectId={projectId}
          itemId={item.id}
          item={item}
        />
      </RestrictedByDocumentPermissionWithDebug>
    );
  }

  if (item.approvalRequired) {
    return (
      <RestrictedByDocumentPermissionWithDebug
        permission="DECIDE_QUOTES"
        document={{ viewerPermissions: docViewerPermissions }}
      >
        <ToggleApprovalStatusButton
          docId={docId}
          projectId={projectId}
          itemId={item.id}
          item={item}
          docViewerPermissions={docViewerPermissions}
        />
      </RestrictedByDocumentPermissionWithDebug>
    );
  }

  return <></>;
};
