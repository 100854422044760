import { InfoMessage } from "@msys/ui";
import { Box } from "@mui/material";
import { PageMiddleColumn } from "../../../commons/layout/PageMiddleColumn.js";
import { useTranslate } from "@tolgee/react";

export const SelectInfoMessageItem = () => {
  const { t } = useTranslate(["Global"]);
  return (
    <PageMiddleColumn hasRightBorder hasBackground>
      <Box display="flex" flex={1} justifyContent="center" alignItems="center">
        <InfoMessage
          message={t("Select item in a tree", {
            ns: "Global",
          })}
        />
      </Box>
    </PageMiddleColumn>
  );
};
