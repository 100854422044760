import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Box, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { pick } from "lodash-es";
import React from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import {
  Agreement,
  ItemType,
  namedOperations,
  PermissionName,
} from "../../../clients/graphqlTypes.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { useUserData } from "../../auth/useUserData.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageHeader } from "../../commons/layout/PageHeader.js";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess.js";
import { QUOTE_ITEM_TYPES } from "../../constants.js";
import { ProfitabilityBox } from "../../features/doc-items/boxes/ProfitabilityBox.js";
import { isAllowedToPaste } from "../../features/doc-items/constraints.js";
import {
  getViewerDecisionRole,
  isTreePreviewItemVisible,
} from "../../features/doc-items/helpers.js";
import {
  CreateItemFromTemplateArgs,
  CreateItemsFromTemplatesArgs,
  CreateItemsWithProductsArgs,
  useCreateItem,
} from "../../features/doc-items/hooks/useCreateItem.js";
import { useDecisionProcess } from "../../features/doc-items/hooks/useDecisionProcess.js";
import { useDocItemsClipboard } from "../../features/doc-items/hooks/useDocItemsClipboard.js";
import { DecisionWizardModalWithDefaultActions } from "../../features/doc-items/modals/DecisionWizardModal.js";
import { DecisionsInBulkProcessRef } from "../../features/doc-items/modals/DecisionsInBulkProcess.js";
import {
  EstimatedCalculationEmptyRow,
  EstimatedCalculationHeaderRow,
} from "../../features/doc-items/tables/EstimatedCalculationRow.js";
import {
  createTreeItemInput,
  createTreeTableItem,
} from "../../features/projects/quote-trees.js";
import { QuoteAction } from "../../features/quotes/QuoteAction.js";
import { QuoteDecisionsInBulkProcess } from "../../features/quotes/QuoteDecisionsInBulkProcess.js";
import { QuotePriceSummaryBox } from "../../features/quotes/QuotePriceSummaryBox.js";
import { useQuoteQuickFilters } from "../../features/quotes/QuoteQuickFilters.js";
import { QuoteSubHeader } from "../../features/quotes/QuoteSubHeader.js";
import { QuoteTimeSummaryBox } from "../../features/quotes/QuoteTimeSummaryBox.js";
import {
  allowHaveChildren,
  shouldRenderCreateInput,
} from "../../features/quotes/helpers.js";
import { VanMarckeProfitability } from "../../features/van-marcke-profitability/VanMarckeProfitability.js";
import { VirtualTableTree } from "../../trees-virtual/VirtualTableTree.js";
import { ROOT_ITEM_ID } from "../../trees-virtual/helpers.js";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../trees-virtual/hooks/useExpandedStore.js";
import {
  AdditionalItemInput,
  TreeViewMode,
} from "../../trees-virtual/types.js";
import { TreeToggleAllExpandedButton } from "../../trees/components/TreeToggleButton.js";
import {
  ProjectQuoteCalculation_ItemFragment,
  useProjectQuoteCalculation_CreateItemsFromTemplatesMutation,
  useProjectQuoteCalculation_CreateItemsMutation,
  useProjectQuoteCalculation_PasteItemFromClipboardMutation,
  useProjectQuoteCalculationQuery,
} from "./ProjectQuoteCalculation.generated.js";
import { useHasSapS4HanaIntegration } from "../../features/sap-s4-hana/hooks.js";
import {
  createRecursiveTryFocusElementFunction,
  isInTestMode,
} from "../../utils.js";
import { useUpdateEffect } from "react-use";

const REFETCH_QUERIES = [namedOperations.Query.ProjectQuoteCalculation];

interface Props {
  projectId: string;
  quoteId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectQuoteCalculation = ({
  projectId,
  quoteId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) => {
  const pathToDocList = `/projects/${projectId}/quotes`;
  const pathToDoc = `/projects/${projectId}/quotes/${quoteId}`;
  const pathToDocPage = `${pathToDoc}/calculation`;
  const match = useMatch(`${pathToDocPage}/items/:itemId`);
  const selectedItemId = match?.params?.itemId ?? null;
  const navigate = useNavigate();

  const [additionalItemInput, setAdditionalItemInput] =
    React.useState<AdditionalItemInput | null>(null);

  const onAdditionalItemInputChange = React.useCallback(
    (input: AdditionalItemInput) => {
      setAdditionalItemInput(input);
      setTimeout(() => {
        const inputRank =
          input.itemRank + (input.position === "below" ? 0.5 : -0.5);
        if (!isInTestMode())
          createRecursiveTryFocusElementFunction(
            `input#msys-table-input-${input.itemParentType}-${input.itemParentId}[data-node-rank="${inputRank}"]:enabled`
          )();
      });
    },
    []
  );

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["QuoteEdit", "Global", "Quote"]);

  const isClientVisibilityEnabled = useFeature("ClientVisibility");

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);

  const { isMinTablet } = useScreenWidth();

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote",
    quoteId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useProjectQuoteCalculationQuery({
    client,
    variables: {
      projectId,
      quoteId,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const doc = getDataOrNull(query.data?.quote)?.quote;
  const docTitle = doc?.title ?? "";

  const navigateToItem = React.useCallback(
    (id: string) => navigate(`${pathToDocPage}/items/${id}`, { replace: true }),
    [navigate, pathToDocPage]
  );

  const [QuickFiltersTabs, quickFilter] =
    useQuoteQuickFilters<ProjectQuoteCalculation_ItemFragment>(
      doc?.agreement === "YES"
    );

  const quote = getDataOrNull(query.data?.quote)?.quote;
  const viewerPermissions: PermissionName[] = React.useMemo(
    () => quote?.viewerPermissions ?? [],
    [quote?.viewerPermissions]
  );

  const agreement: Agreement | undefined = doc?.agreement;
  const contractType = doc?.contractType;

  const viewerDecisionRole = getViewerDecisionRole(doc?.actors ?? []);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const decisionsInBulkProcessRef =
    React.useRef<DecisionsInBulkProcessRef>(null);
  const startDecisionsInBulkProcess = React.useCallback(() => {
    decisionsInBulkProcessRef.current?.startDecisionsInBulkProcess();
  }, []);

  const decisionProcess = useDecisionProcess({
    projectId,
    docId: quoteId,
    embeddedMode: false,
    itemUuidSeed: "",
    viewerDecisionRole,
    decisionContext: "onQuoteRefinement",
  });

  const {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  } = useCreateQuoteItems({
    projectId,
    quoteId,
    expandedItemIds: expandedStore.expandedItemIds,
  });

  const TreeItem = React.useMemo(
    () =>
      agreement !== undefined && contractType && quote?.rootItem.id
        ? createTreeTableItem({
            pathToDocPage,
            projectId,
            doc: {
              id: quoteId,
              rootItemId: quote.rootItem.id,
              contractType: contractType,
              viewerPermissions,
            },
            docAgreement: agreement,
            startConfirmProcess,
            navigateToItem,
            expandedItemIds: expandedStore.expandedItemIds,
            setItemExpanded: expandedStore.setItemExpanded,
            pasteItem,
            isAllowedToPasteItem,
            viewerDecisionRole,
            onAdditionalItemInputChange,
            visibleWizardButtons: [],
            isNewRefinementWizardEnabled:
              viewer.organisation.capabilities.includes(
                "NEW_REFINEMENT_WIZARD"
              ),
          })
        : () => null,
    [
      agreement,
      contractType,
      pathToDocPage,
      projectId,
      quoteId,
      quote?.rootItem.id,
      viewerPermissions,
      startConfirmProcess,
      navigateToItem,
      expandedStore.expandedItemIds,
      expandedStore.setItemExpanded,
      pasteItem,
      isAllowedToPasteItem,
      viewerDecisionRole,
      onAdditionalItemInputChange,
    ]
  );

  const TreeItemInput = React.useMemo(
    () =>
      createTreeItemInput({
        viewMode: TreeViewMode.Table,
        quoteId,
        createItem,
        createItemByType,
        createItemFromTemplate,
        createItemsFromTemplates,
        createItemsWithProducts,
        pasteItem,
        isAllowedToPasteItem,
      }),
    [
      createItem,
      createItemByType,
      createItemFromTemplate,
      createItemsFromTemplates,
      createItemsWithProducts,
      isAllowedToPasteItem,
      pasteItem,
      quoteId,
    ]
  );

  const [filteredTreeItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!doc) return [[], undefined, []];
    const allDocItems = doc.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems
      .filter(item => item.authorOrganisationId === viewer.organisation.id)
      .filter(item => !item.isAnyParentItemEliminated);
    const filteredItems = quickFilter(treeItems).map(item => {
      if (!item.isRootItem) return item;
      return { ...item, parentId: ROOT_ITEM_ID };
    });
    return [filteredItems, rootItem, allDocItems];
  }, [doc, quickFilter, viewer.organisation.id]);

  useUpdateEffect(() => {
    setAdditionalItemInput(null);
  }, [selectedItemId, allDocItems]);

  // redirect to calculation route when selected item is filtered out
  React.useEffect(() => {
    if (
      !query.loading &&
      selectedItemId &&
      !filteredTreeItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredTreeItems,
    pathToDocPage,
    rootItem,
    query.loading,
    navigate,
  ]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  const filterItem = React.useCallback(
    (i: ProjectQuoteCalculation_ItemFragment) =>
      isTreePreviewItemVisible(true, i, allDocItems, doc?.isBinding ?? true),
    [allDocItems, doc?.isBinding]
  );

  const isRootSelected = selectedItemId === rootItem?.id;
  const isAgreed =
    (doc?.isPublished && doc?.isBinding && doc?.agreement === "YES") ?? false;
  const isViewersQuote =
    doc?.owningSystemOrganisationId === viewer.organisation.id;

  const EmptyRow = React.useMemo(() => <EstimatedCalculationEmptyRow />, []);
  const HeaderRow = React.useMemo(() => <EstimatedCalculationHeaderRow />, []);

  const enableCreating = isViewersQuote ? true : false;
  const enableDragging = isViewersQuote ? !isAgreed : false;
  const enableNavigating = isViewersQuote ? true : false;

  const hasSapS4HanaIntegration = useHasSapS4HanaIntegration();

  return (
    <Page
      subtitle={project?.title}
      title={docTitle}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      header={
        <PageHeader
          breadcrumbs={prefixBreadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && doc ? (
          <QuoteSubHeader
            project={project}
            quote={doc}
            activeView="calculation"
            canEdit={isViewersQuote}
            isReadOnly={isAgreed}
            pathToDocList={pathToDocList}
            pathToDoc={pathToDoc}
            hasSapS4HanaIntegration={hasSapS4HanaIntegration}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
            expandedItemIds={expandedStore.expandedItemIds}
          />
        ) : undefined
      }
      action={
        project &&
        doc && (
          <QuoteAction
            doc={doc}
            project={project}
            onDiscardPendingChangesRefetchQueries={REFETCH_QUERIES}
            onDeclineChangesRefetchQueries={REFETCH_QUERIES}
            startDecisionsInBulkProcess={startDecisionsInBulkProcess}
            expandedItemIds={expandedStore.expandedItemIds}
            viewerDecisionRole={viewerDecisionRole}
          />
        )
      }
    >
      {doc && project && (
        <PageContainer
          $display="block"
          ref={isMinTablet ? setTreeContainer : undefined}
          $noPadding
        >
          <Stack direction="column" py={2} spacing={2}>
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              spacing={1}
              px={2}
            >
              <TreeToggleAllExpandedButton
                areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                setAllItemsExpanded={expandedStore.setAllItemsExpanded}
              />
              <QuickFiltersTabs />
            </Stack>

            {filteredTreeItems.length > 0 ? (
              <VirtualTableTree<
                ProjectQuoteCalculation_ItemFragment,
                typeof enableDragging
              >
                minWidth={1540}
                docId={enableDragging ? doc.id : null}
                projectId={enableDragging ? projectId : null}
                items={filteredTreeItems}
                allItems={allDocItems}
                rootItemId={ROOT_ITEM_ID}
                filterItem={isClientVisibilityEnabled ? filterItem : undefined}
                selectedItemId={selectedItemId}
                enableCreating={enableCreating}
                enableDragging={enableDragging}
                documentItemTypes={QUOTE_ITEM_TYPES}
                container={treeContainer}
                itemComponent={TreeItem}
                inputComponent={TreeItemInput}
                EmptyRow={EmptyRow}
                HeaderRow={HeaderRow}
                headerTitle={t("Title", {
                  ns: "QuoteEdit",
                })}
                allowHaveChildren={allowHaveChildren}
                shouldRenderCreateInput={shouldRenderCreateInput}
                expandedStore={expandedStore}
                additionalItemInput={additionalItemInput}
              />
            ) : (
              <Box height={2} />
            )}

            <DecisionWizardModalWithDefaultActions
              processState={decisionProcess.state}
              projectId={projectId}
              docType="QUOTE"
              docId={quoteId}
              viewerDecisionRole={viewerDecisionRole}
              decisionContext={decisionProcess.decisionContext}
              expandedItemIds={expandedStore.expandedItemIds}
              handleClose={decisionProcess.close}
              handleNextItem={decisionProcess.next}
              handlePreviousItem={decisionProcess.previous}
            />

            <QuoteDecisionsInBulkProcess
              ref={decisionsInBulkProcessRef}
              projectId={projectId}
              quoteId={doc.id}
              canFinalize={true}
              externalExpandedItemIds={expandedStore.expandedItemIds}
            />

            {doc.proposedCalculation && (
              <Box px={2}>
                <QuoteTimeSummaryBox
                  key={`quote_time_summary-${isAgreed}`}
                  calculated={doc.proposedCalculation}
                />
              </Box>
            )}

            {doc.proposedCalculation && (
              <Box px={2}>
                <QuotePriceSummaryBox
                  key={`quote_price_summary-${isAgreed}`}
                  isManualSave={isAgreed}
                  quoteId={quoteId}
                  quote={doc}
                  project={project}
                  discountPercentage={doc.discountPercentage}
                  calculated={doc.proposedCalculation}
                />
              </Box>
            )}

            {doc.proposedCalculation && (
              <Box px={2}>
                <ProfitabilityBox calculated={doc.proposedCalculation} />
              </Box>
            )}

            <VanMarckeProfitability
              projectId={projectId}
              docId={quoteId}
              stackProps={{ px: 2 }}
            />
          </Stack>
        </PageContainer>
      )}
      <ConfirmProcess ref={confirmProcessRef} />
      <Outlet
        context={{
          expandedItemIds: expandedStore.expandedItemIds,
          CreateChildComponent: TreeItemInput,
          pasteItem,
          isAllowedToPasteItem,
          viewerDecisionRole,
        }}
      />
    </Page>
  );
};

function useCreateQuoteItems({
  projectId,
  quoteId,
  expandedItemIds,
}: {
  projectId: string;
  quoteId: string;
  expandedItemIds: string[] | undefined;
}) {
  const client = useApolloClient();
  const [createItems] = useProjectQuoteCalculation_CreateItemsMutation({
    client,
  });

  const { createItem, createItemByType } = useCreateItem({
    projectId,
    createDocumentItems: createItems,
    expandedItemIds,
  });

  const [createItemsFromTemplatesMutation] =
    useProjectQuoteCalculation_CreateItemsFromTemplatesMutation({
      client,
    });

  const createItemFromTemplate = React.useCallback(
    async ({
      parentItemId,
      templateQuoteId,
      newItemRank,
    }: CreateItemFromTemplateArgs) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: quoteId,
            parentItemId,
            templates: [{ templateQuoteId }],
            expandedItemIds,
            rank: newItemRank,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items[0];
    },
    [createItemsFromTemplatesMutation, expandedItemIds, projectId, quoteId]
  );

  const createItemsFromTemplates = React.useCallback(
    async ({
      parentItemId,
      templates,
      newItemRank,
    }: CreateItemsFromTemplatesArgs) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: quoteId,
            parentItemId,
            templates: templates.map(tpl => ({
              templateQuoteId: tpl.template.id,
              templateEstimatedQuantity: tpl.quantity,
            })),
            expandedItemIds,
            rank: newItemRank,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items;
    },
    [createItemsFromTemplatesMutation, expandedItemIds, projectId, quoteId]
  );

  const createItemsWithProducts = React.useCallback(
    async ({
      parentItemId,
      products,
      newItemRank,
    }: CreateItemsWithProductsArgs) => {
      const result = await createItems({
        variables: {
          input: {
            projectId,
            docId: quoteId,
            parentItemId,
            items: products.map(opts =>
              "__typename" in opts.product
                ? {
                    title: opts.product.texts?.title ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    product: pick(opts.product, "supplierId", "articleNumber"),
                  }
                : {
                    title: opts.product.productTitle ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    productValues: opts.product,
                  }
            ),
            expandedItemIds,
            rank: newItemRank,
          },
        },
      });

      return result.data?.createItems.items;
    },
    [createItems, expandedItemIds, projectId, quoteId]
  );

  const [copiedItem] = useDocItemsClipboard();
  const [pasteItemFromClipboardMutation] =
    useProjectQuoteCalculation_PasteItemFromClipboardMutation({ client });
  const pasteItem = React.useCallback(
    async (docId: string, parentItemId: string) => {
      if (!copiedItem) throw new Error("No item copied");

      const result = await pasteItemFromClipboardMutation({
        variables: {
          input: {
            sourceProjectId: copiedItem.projectId,
            sourceDocId: copiedItem.docId,
            sourceItemId: copiedItem.itemId,
            destProjectId: projectId,
            destDocId: docId,
            destItemId: parentItemId,
            expandedItemIds,
          },
        },
      });
    },
    [copiedItem, pasteItemFromClipboardMutation, projectId, expandedItemIds]
  );
  const isAllowedToPasteItem = React.useCallback(
    (parentItem: { type: ItemType }) => {
      if (!copiedItem?.itemType || !parentItem.type) return false;

      return isAllowedToPaste({
        documentItemTypes: QUOTE_ITEM_TYPES,
        copiedItemType: copiedItem.itemType,
        parentItemType: parentItem.type,
      });
    },
    [copiedItem?.itemType]
  );

  return {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  };
}
