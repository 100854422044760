import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isArray } from "lodash-es";
import { LinesWithBreaks } from "@msys/ui";
import { notNull } from "@msys/common";

type Replacement = {
  name: React.ReactNode;
  description: React.ReactNode;
  value: React.ReactNode;
};

interface Props {
  replacements: (Replacement | Replacement[])[];
}

export const AvailableReplacementsTable = ({ replacements }: Props) => {
  const { t } = useTranslate(["Global", "OrganisationSettings"]);
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            {t("Placeholder", { ns: "OrganisationSettings" })}
          </TableCell>
          <TableCell>
            {t("Description", { ns: "OrganisationSettings" })}
          </TableCell>
          <TableCell>{t("Example", { ns: "OrganisationSettings" })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {replacements.map((replacement, index) => {
          const _replacement = isArray(replacement)
            ? replacement
            : [replacement];
          return (
            <TableRow key={index}>
              <TableCell>
                <LinesWithBreaks
                  lines={_replacement.filter(notNull).map(r => `{{${r.name}}}`)}
                />
              </TableCell>
              <TableCell>
                <LinesWithBreaks
                  lines={_replacement.filter(notNull).map(r => r.description)}
                />
              </TableCell>
              <TableCell>
                <LinesWithBreaks
                  lines={_replacement.filter(notNull).map(r => (
                    <code>{r.value}</code>
                  ))}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
