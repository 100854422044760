import { useFormatting } from "@msys/ui";
import { useFormikContext } from "formik";
import { clamp } from "lodash-es";
import React from "react";
import { FormattedField } from "./FormattedField.js";

export const FormattedIntegerField = ({
  name,
  unit,
  min,
  max,
  multiplier = 1,
  ...props
}: {
  name: string;
  unit?: string;
  min?: number;
  max?: number;
  multiplier?: number;
} & Omit<
  React.ComponentProps<typeof FormattedField<number>>,
  "getFormattedValue" | "getActualValue" | "getEditableValue"
>) => {
  const { getFieldProps } = useFormikContext<{
    [key: string]: unknown;
  }>();
  const fieldProps = getFieldProps<number>(name);
  const value = fieldProps.value;

  const { getFormattedInteger, getEditableInteger, getFloat } = useFormatting();

  function getFormattedValue(value: any) {
    return getFormattedUnit(
      getFormattedInteger((value ?? 0) * multiplier),
      unit
    );
  }

  function getActualValue(value: string) {
    const newValue = clamp(
      getFloat(value) / multiplier,
      min ?? Number.MIN_SAFE_INTEGER,
      max ?? Number.MAX_SAFE_INTEGER
    );

    return Math.floor(newValue);
  }

  function getEditableValue() {
    return getEditableInteger((value ?? 0) * multiplier);
  }

  return (
    <FormattedField
      {...props}
      name={name}
      getFormattedValue={getFormattedValue}
      getActualValue={getActualValue}
      getEditableValue={getEditableValue}
    />
  );
};

function getFormattedUnit(value: string, unit?: string) {
  return `${value}${unit ?? ""}`.trim();
}
