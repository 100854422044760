import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, LoadingSpinner } from "@msys/ui";
import { List, ListItem, ListItemText, Switch } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useCallback } from "react";
import { Stack } from "../../../commons/layout/Stack.js";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import {
  CrmCompanySupplierCataloguesBox__SupplierCatalogueFragment,
  useCrmCompanySupplierCataloguesBoxQuery,
  useCrmCompanySupplierCataloguesBox__SubscribeToMutation,
  useCrmCompanySupplierCataloguesBox__UnsubscribeFromMutation,
} from "./CrmCompanySupplierCataloguesBox.generated.js";

export const CrmCompanySupplierCataloguesBox = ({
  supplierId,
}: {
  supplierId: string;
}) => {
  const client = useApolloClient();
  // const { getFormattedDate } = useFormatting();

  const { t } = useTranslate(["SupplierCatalogue"]);

  const crmCompanySupplierCataloguesBoxQuery =
    useCrmCompanySupplierCataloguesBoxQuery({
      client,
      variables: { supplierId: supplierId },
    });

  const [
    subscribeToSupplierCatalogue,
    { loading: subscribeToSupplierCatalogueLoading },
  ] = useCrmCompanySupplierCataloguesBox__SubscribeToMutation({
    client,
    refetchQueries: [namedOperations.Query.CrmCompanySupplierCataloguesBox],
  });

  const [
    unsubscribeFromSupplierCatalogue,
    { loading: unsubscribeFromSupplierCatalogueLoading },
  ] = useCrmCompanySupplierCataloguesBox__UnsubscribeFromMutation({
    client,
    refetchQueries: [namedOperations.Query.CrmCompanySupplierCataloguesBox],
  });

  const supplierCatalogues = getDataOrNull(
    crmCompanySupplierCataloguesBoxQuery.data?.pimGetSupplierCatalogues
  )?.supplierCatalogues;

  const changeSupplierCatalogueSubscription = useCallback(
    async (
      supplierCatalogue: CrmCompanySupplierCataloguesBox__SupplierCatalogueFragment
    ) => {
      if (supplierCatalogue.supplierCatalogueSubscription) {
        await unsubscribeFromSupplierCatalogue({
          variables: {
            input: {
              supplierId: supplierId,
              supplierCatalogueId: supplierCatalogue.id,
            },
          },
        });
      } else {
        await subscribeToSupplierCatalogue({
          variables: {
            input: {
              supplierId: supplierId,
              supplierCatalogueId: supplierCatalogue.id,
            },
          },
        });
      }
    },
    [subscribeToSupplierCatalogue, unsubscribeFromSupplierCatalogue, supplierId]
  );

  return supplierCatalogues && supplierCatalogues.length > 0 ? (
    <CardContainer
      title={t("Subscribable catalogues", { ns: "SupplierCatalogue" })}
      ActionButton={
        unsubscribeFromSupplierCatalogueLoading ||
        subscribeToSupplierCatalogueLoading ? (
          <LoadingSpinner size="s" />
        ) : undefined
      }
      itemCount={supplierCatalogues.length}
    >
      <List disablePadding>
        {supplierCatalogues.map((supplierCatalogue, index, array) => (
          <ListItem
            key={supplierCatalogue.id}
            divider={index < array.length - 1}
          >
            <ListItemText
              primary={
                supplierCatalogue.title
                  ? supplierCatalogue.title
                  : supplierCatalogue.isDefault
                    ? t("Default catalogue", { ns: "SupplierCatalogue" })
                    : "-"
              }
            />

            <Stack flexDirection="row" justifyContent={"flex-end"}>
              <Switch
                checked={!!supplierCatalogue.supplierCatalogueSubscription}
                onClick={async () => {
                  await changeSupplierCatalogueSubscription(supplierCatalogue);
                }}
                color="primary"
              />
            </Stack>
          </ListItem>
        ))}
      </List>
    </CardContainer>
  ) : null;
};
