import { notNull } from "@msys/common";
import { MenuItemWithIcon } from "@msys/ui";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ConfirmModalProps } from "../../../commons/modals/ConfirmProcess.js";
import { useDeleteItemMutation } from "../hooks/useDeleteItemMutation.js";
import { useDocItemsClipboard } from "../hooks/useDocItemsClipboard.js";

interface Props {
  projectId: string | null;
  docId: string;
  itemId: string;
  disabled?: boolean;
  startConfirmProcess: (
    props: ConfirmModalProps
  ) => Promise<boolean | undefined>;
  onItemDeleted: () => Promise<void> | void;
  expandedItemIds: string[] | undefined;
}

export const DeleteItemMenuItem = ({
  docId,
  projectId,
  itemId,
  disabled,
  startConfirmProcess,
  onItemDeleted,
  expandedItemIds,
}: Props) => {
  const { t } = useTranslate(["Global", "QuoteItem"]);

  const {
    deleteItem,
    deleteItemLoading,
    checkItemBeforeDelete,
    checkItemCouldBeDeletedLoading,
  } = useDeleteItemMutation(expandedItemIds);

  const [content, setContent] = useDocItemsClipboard();
  const isRemovingItemFromClipboard =
    content?.projectId === projectId &&
    content?.docId === docId &&
    content?.itemId === itemId;

  return (
    <MenuItemWithIcon
      disabled={disabled || deleteItemLoading || checkItemCouldBeDeletedLoading}
      onClick={async () => {
        const { itemOrSubItemsPropsUsedInOtherItems } =
          await checkItemBeforeDelete({
            projectId,
            docId,
            itemId,
          });

        const messages = [
          t("Deleting an item cannot be undone.", { ns: "QuoteItem" }),
          isRemovingItemFromClipboard
            ? t(
                "You have copied this item to the clipboard. You will not be able to paste it if you delete it.",
                { ns: "QuoteItem" }
              )
            : null,
          itemOrSubItemsPropsUsedInOtherItems
            ? t("Some formulas will be lost when deleting this item.", {
                ns: "QuoteItem",
              })
            : null,
        ].filter(notNull);

        const result = await startConfirmProcess({
          title: t("Confirm deletion", { ns: "Global" }),
          content: handleClose => (
            <Stack direction="column" spacing={1}>
              {messages.map((m, index) => (
                <Typography variant="body1" key={index}>
                  {m}
                </Typography>
              ))}
              <Typography variant="body1">
                {t("Would you like to continue?", { ns: "QuoteItem" })}
              </Typography>
            </Stack>
          ),
          confirmButtonLabel: t("Delete item", { ns: "QuoteItem" }),
        });
        if (!result) return;

        await deleteItem({
          projectId,
          docId,
          itemId,
          onItemDeleted,
        });
      }}
      icon={<DeleteIcon />}
    >
      {t("Delete", {
        ns: "Global",
      })}
    </MenuItemWithIcon>
  );
};
