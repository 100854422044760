import { gql, useApolloClient } from "@apollo/client";
import React from "react";
import { useRequestPimCreateThreedModelUploadUrlMutation } from "./ThreedModelDataUploader.generated.js";

export interface UploadFile {
  filename: string;
}

export function ThreedModelDataUploader(props: {
  innerRef: any;
  onUploadFile: (uploadFile: UploadFile) => void;
  onCancel?: () => void;
  onStart?: () => void;
  onError?: () => void;
}) {
  const client = useApolloClient();
  const showThreeD = import.meta.env.VITE_THREED_PLANNER_SHOW === "true";

  const [requestPimCreateThreedModelUploadUrl] =
    useRequestPimCreateThreedModelUploadUrlMutation({
      client,
    });

  return (
    <>
      {showThreeD && (
        <input
          type="file"
          style={{ display: "none" }}
          multiple={false}
          accept={".zip"}
          ref={props.innerRef}
          onChange={async e => {
            console.info(e.target.files);
            if (e.target.files && e.target.files[0]) {
              if (props.onStart) props.onStart();

              try {
                let file = e.target.files[0];

                let filename = file.name;

                let requestPimCreateThreedModelUploadUrlResult =
                  await requestPimCreateThreedModelUploadUrl({
                    variables: {
                      input: {
                        filename: filename,
                      },
                    },
                  });

                await fetch(
                  requestPimCreateThreedModelUploadUrlResult.data!
                    .requestPimCreateThreedModelUploadUrl.uploadUrl,
                  { method: "PUT", body: file }
                ).catch(err => {
                  console.info(err);
                });

                if (props.innerRef.current) {
                  props.innerRef.current.value = "";
                }
                props.onUploadFile({ filename });
              } catch (err) {
                if (props.onError) props.onError();
                throw err;
              }
            } else {
              if (props.onCancel) props.onCancel();
            }
          }}
        />
      )}
    </>
  );
}
