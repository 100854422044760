/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAbsenceMutationVariables = Types.Exact<{
  input: Types.CreateAbsenceInput;
}>;


export type CreateAbsenceMutation = { createAbsence: { __typename: 'CreateAbsenceResult', absence: { __typename: 'Absence', id: string } } };


export const CreateAbsenceDocument = gql`
    mutation CreateAbsence($input: CreateAbsenceInput!) {
  createAbsence(input: $input) {
    absence {
      id
    }
  }
}
    `;
export type CreateAbsenceMutationFn = Apollo.MutationFunction<CreateAbsenceMutation, CreateAbsenceMutationVariables>;

/**
 * __useCreateAbsenceMutation__
 *
 * To run a mutation, you first call `useCreateAbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAbsenceMutation, { data, loading, error }] = useCreateAbsenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAbsenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateAbsenceMutation, CreateAbsenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAbsenceMutation, CreateAbsenceMutationVariables>(CreateAbsenceDocument, options);
      }
export type CreateAbsenceMutationHookResult = ReturnType<typeof useCreateAbsenceMutation>;
export type CreateAbsenceMutationResult = Apollo.MutationResult<CreateAbsenceMutation>;
export type CreateAbsenceMutationOptions = Apollo.BaseMutationOptions<CreateAbsenceMutation, CreateAbsenceMutationVariables>;