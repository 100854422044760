import { gql, useApolloClient } from "@apollo/client";
import { MenuItemWithIcon } from "@msys/ui";
import { Sync as SyncIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTemplateBulkUpdateProductsMutation } from "./TemplateBulkUpdateProductsButton.generated.js";
import { useTranslate } from "@tolgee/react";
import React from "react";

interface Props {
  docId: string;
  refetchQueries?: string[];
}

export function TemplateBulkUpdateProductsButton({
  docId,
  refetchQueries,
}: Props) {
  const { t } = useTranslate("Templates");

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [
    templateBulkUpdateProducts,
    { loading: templateBulkUpdateProductsLoading },
  ] = useTemplateBulkUpdateProductsMutation({
    client,
    refetchQueries,
  });

  return (
    <MenuItemWithIcon
      icon={<SyncIcon />}
      onClick={async () => {
        try {
          await templateBulkUpdateProducts({
            variables: {
              input: {
                docId: docId,
              },
            },
          });
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, {
              variant: "error",
            });
        }
      }}
      disabled={templateBulkUpdateProductsLoading}
    >
      {t("Sync products", { ns: "Templates" })}
    </MenuItemWithIcon>
  );
}
