import { useApolloClient } from "@apollo/client";
import {
  ModalOpenProcess,
  ModalOpenProcessRef,
  useScreenWidth,
} from "@msys/ui";
import { LoadingButton } from "@mui/lab";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  AddressInput,
  QuotePdfCalculationType,
} from "../../../../clients/graphqlTypes.js";
import { GetAddressModal } from "../../addresses/GetAddressModal.js";
import { PublishQuoteAndSendViaEmailModal } from "../modals/PublishQuoteAndSendViaEmailModal.js";
import { PublishQuoteAndSendViaEmailModal_ProjectFragment } from "../modals/PublishQuoteAndSendViaEmailModal.generated.js";
import {
  PublishQuoteButton_QuoteFragment,
  usePublishQuoteButton_SetContractorAddressMutation,
} from "./PublishQuoteButton.generated.js";

interface Props {
  docId: string;
  projectId: string;
  disabled?: boolean;
  project: PublishQuoteAndSendViaEmailModal_ProjectFragment;
  doc: PublishQuoteButton_QuoteFragment;
  handleComplete?: () => Promise<unknown> | unknown;
  calculationType: QuotePdfCalculationType;
  pdfTemplateId?: string | null;
}

export const PublishQuoteButton = ({
  docId,
  projectId,
  disabled = false,
  project,
  doc,
  handleComplete,
  calculationType,
  pdfTemplateId,
}: Props) => {
  const { t } = useTranslate([
    "QuoteEdit",
    "QuoteSend",
    "OrganisationProfile",
    "Global",
  ]);
  const { isMaxPhone } = useScreenWidth();
  const resolveAddressRef =
    React.useRef<(address: AddressInput | null) => void>();

  const [isOpenAddressModal, setOpenAddressModal] =
    React.useState<boolean>(false);

  const client = useApolloClient();
  const [setContractorAddress] =
    usePublishQuoteButton_SetContractorAddressMutation({ client });

  const handleSetBillingAddress = () =>
    new Promise<AddressInput | null>(resolve => {
      resolveAddressRef.current = resolve;
      setOpenAddressModal(true);
    });

  const handlePublishQuote = async () => {
    if (!doc.contractor.address) {
      const address = await handleSetBillingAddress();
      if (!address) return;
    }
    processRef.current?.open();
  };

  const processRef = React.useRef<ModalOpenProcessRef>();

  return (
    <>
      <LoadingButton
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={handlePublishQuote}
      >
        {isMaxPhone
          ? t("Publish", { ns: "Global" })
          : t("Publish doc", { ns: "QuoteEdit" })}
      </LoadingButton>
      {isOpenAddressModal && (
        <GetAddressModal
          id="system-organisation-billing-address-modal"
          inputLabel={t("Billing address", {
            ns: "OrganisationProfile",
          })}
          description={t(
            "Please provide your organisation billing address in order to publish a quote",
            {
              ns: "QuoteEdit",
            }
          )}
          handleComplete={async address => {
            await setContractorAddress({
              variables: {
                input: {
                  projectId,
                  docId,
                  docActorType: "CONTRACTOR",
                  docActorId: doc.contractor.id,
                  docActor: { address },
                },
              },
            });
            setOpenAddressModal(false);
            setTimeout(() => {
              resolveAddressRef.current?.(address);
            });
          }}
          handleClose={() => {
            setOpenAddressModal(false);
            setTimeout(() => {
              resolveAddressRef.current?.(null);
            });
          }}
        />
      )}
      <ModalOpenProcess
        ref={processRef}
        Modal={PublishQuoteAndSendViaEmailModal}
        modalProps={{
          quote: doc,
          projectId,
          project,
          quoteId: docId,
          calculationType,
          pdfTemplateId,
          handleComplete: async handleClose => {
            await handleComplete?.();
            handleClose();
          },
        }}
      />
    </>
  );
};
