import {
  createTolgeeBrowserInstance,
  getSupportedSystemLocale,
} from "@msys/tolgee";
import moment from "moment";
import * as Yup from "yup";
import { loadCalendarTranslation } from "./syncfusion.js";
import { localeToYupLocale, yupTranslations } from "./yupTranslations.js";

export const tolgee = createTolgeeBrowserInstance(
  import.meta.env.VITE_TOLGEE_CDN_URL
);

// `newLocale` here has only supported languages, but can also have sub-locales, like: en-GB, en-US, de-CH, fr-BE and so on
tolgee.on("language", ({ value: newLocale }) => {
  const locale = getSupportedSystemLocale(newLocale);
  moment.locale(locale);
  Yup.setLocale(yupTranslations[localeToYupLocale[locale]]);
  loadCalendarTranslation(locale);
});
