/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type BillOfMaterialsItemFragment = { __typename: 'BillOfMaterialsItem', id: string, itemType: Types.BillOfMaterialsItemType, title: string, itemStatus: Types.BillOfMaterialsItemStatus, quantityRequired: number, quantityRequiredAccepted: number, quantityRequiredPending: number, quantityRequiredChange: number, quantityRequiredDraft: number, quantityRequiredTotal: number, quantityOrdered: number, discountValue?: number | null, minInstallationTime?: number | null, maxInstallationTime?: number | null, listPrice?: number | null, netPrice?: number | null, quantityUnit: Types.QuantityUnit, quoteItemId?: string | null, productArticleNumber?: string | null, productManufacturerArticleNumber?: string | null, product?: { __typename: 'PimCustomSupplierProduct', id: string, articleNumber: string, supplierId: string } | { __typename: 'PimSupplierProduct', id: string, articleNumber: string, supplierId: string } | null, productSupplier?: { __typename: 'Company', id: string, title: string } | null };

export const BillOfMaterialsItemFragmentDoc = gql`
    fragment BillOfMaterialsItem on BillOfMaterialsItem {
  id
  itemType
  title
  itemStatus
  quantityRequired
  quantityRequiredAccepted
  quantityRequiredPending
  quantityRequiredChange
  quantityRequiredDraft
  quantityRequiredTotal
  quantityOrdered
  discountValue
  minInstallationTime
  maxInstallationTime
  listPrice
  netPrice
  quantityUnit
  product {
    ... on PimCustomSupplierProduct {
      id
      articleNumber
      supplierId
    }
    ... on PimSupplierProduct {
      id
      articleNumber
      supplierId
    }
  }
  productSupplier {
    id
    title
  }
  quoteItemId
  productArticleNumber
  productManufacturerArticleNumber
}
    `;