/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type ToggleQuoteBindingMenuItem_QuoteFragment = { __typename: 'Quote', id: string, isBinding: boolean };

export const ToggleQuoteBindingMenuItem_QuoteFragmentDoc = gql`
    fragment ToggleQuoteBindingMenuItem_Quote on Quote {
  id
  isBinding
}
    `;