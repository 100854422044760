/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated.js';
export type ProjectOverviewParticipantsBox_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, canInviteContractee: boolean, crmOrganisation?: { __typename: 'CrmCompany', id: string, email: any, title: string, members: Array<{ __typename: 'CrmPerson', id: string, firstname: string, familyname: string, fullname: string, email: any, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } | null, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, roles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> }>, contracteeInvitations: Array<{ __typename: 'ProjectContracteeInvitation', id: string, status: Types.InvitationState, contact: { __typename: 'InvitationContact', id: string } }>, contractors: Array<{ __typename: 'CrmCompany', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, invitingContractor?: { __typename: 'CrmCompany', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } | null };

export type RowMember_ProjectMembershipFragment = { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, roles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> };

export type RowContractee_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName> };

export type RowContractee_CrmPersonFragment = { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, fullname: string, email: any, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export type RowContractee_ProjectContracteeInvitationFragment = { __typename: 'ProjectContracteeInvitation', id: string, status: Types.InvitationState };

export const RowContractee_CrmPersonFragmentDoc = gql`
    fragment RowContractee_CrmPerson on CrmPerson {
  id
  firstname
  familyname
  fullname
  email
  avatar {
    ...AttachmentSnapshot
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const RowMember_ProjectMembershipFragmentDoc = gql`
    fragment RowMember_ProjectMembership on ProjectMembership {
  id
  user {
    id
    firstname
    familyname
    fullname
    email
    phones {
      id
      number
    }
    avatar {
      ...AttachmentSnapshot
    }
  }
  roles {
    id
    internalName
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const RowContractee_ProjectContracteeInvitationFragmentDoc = gql`
    fragment RowContractee_ProjectContracteeInvitation on ProjectContracteeInvitation {
  id
  status
}
    `;
export const ProjectOverviewParticipantsBox_ProjectFragmentDoc = gql`
    fragment ProjectOverviewParticipantsBox_Project on Project {
  id
  viewerPermissions
  canInviteContractee
  crmOrganisation {
    id
    email
    members {
      id
      ...RowContractee_CrmPerson
    }
    title
    logo {
      ...AttachmentSnapshot
    }
  }
  internalStakeholders {
    id
    ...RowMember_ProjectMembership
  }
  contracteeInvitations {
    id
    contact {
      id
    }
    ...RowContractee_ProjectContracteeInvitation
  }
  contractors {
    id
    title
    logo {
      ...AttachmentSnapshot
    }
  }
  invitingContractor {
    id
    title
    logo {
      ...AttachmentSnapshot
    }
  }
}
    ${RowContractee_CrmPersonFragmentDoc}
${AttachmentSnapshotFragmentDoc}
${RowMember_ProjectMembershipFragmentDoc}
${RowContractee_ProjectContracteeInvitationFragmentDoc}`;
export const RowContractee_ProjectFragmentDoc = gql`
    fragment RowContractee_Project on Project {
  id
  viewerPermissions
}
    `;