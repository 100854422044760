import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageNotFound } from "../commons/PageNotFound.js";
import { OrganisationPublicProfile } from "./OrganisationPublicProfile.js";
import { OrganisationThreeDFinish } from "./OrganisationThreeDFinish.js";
import { OrganisationThreeDProcess } from "./OrganisationThreeDProcess.js";
import { OrganisationThreeDList } from "./OrganisationThreeDList.js";

export const OrganisationPublicRoutes = () => {
  return (
    <Routes>
      <Route
        path=":slug/3d/:templateId/finish"
        element={<OrganisationThreeDFinish />}
      />
      <Route
        path=":slug/3d/:templateId"
        element={<OrganisationThreeDProcess />}
      />
      <Route path=":slug/3d" element={<OrganisationThreeDList />} />
      <Route path=":slug" element={<OrganisationPublicProfile />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
