import { useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal.js";
import { useDeletePimSupplierImportationsMutation } from "../PimImportations.generated.js";
import { useTranslate } from "@tolgee/react";

export const DeletePimSupplierImportationsButton = ({
  supplierId,
}: {
  supplierId: string;
}) => {
  const { t } = useTranslate(["Global"]);

  const client = useApolloClient();

  const [
    deletePimSupplierImportations,
    { loading: deletePimSupplierImportationsIsLoading },
  ] = useDeletePimSupplierImportationsMutation({ client });

  return (
    <ModalOpenButton
      Modal={ConfirmModal}
      modalProps={{
        handleConfirm: async () => {
          await deletePimSupplierImportations({
            variables: {
              input: {
                supplierId,
              },
            },
          });
        },
        title: t("Confirm deletion", {
          ns: "Global",
        }),
        text: t("Deletion cannot be undone. Do you want to continue?", {
          ns: "Global",
        }),
      }}
      disabled={deletePimSupplierImportationsIsLoading}
    >
      <IconButton color="primary" size="small">
        {!deletePimSupplierImportationsIsLoading ? (
          <DeleteIcon />
        ) : (
          <CircularProgress size={"1.25rem"} />
        )}
      </IconButton>
    </ModalOpenButton>
  );
};
