import {
  CardContainer,
  isImageOr3dModel,
  LabeledDateValue,
  LabeledSelectValue,
  LabeledSpecializedValue,
  ModalOpenButton,
  processAttachment,
  TruncatedTextValue,
} from "@msys/ui";
import { CalendarToday as CalendarTodayIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { Euro as EuroIcon } from "@mui/icons-material";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import {
  namedOperations,
  PermissionName,
} from "../../../../clients/graphqlTypes.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { PictureGallery } from "../../../commons/images/PictureGallery.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { AddressDetails__AddressFragment } from "../../addresses/Addresses.generated.js";
import { LabeledAddressValue } from "../../addresses/LabeledAddressValue.js";
import { useRequestAttachments } from "../../attachments/useAttachments.js";
import { useProjectTypes } from "../../projects/useProjectTypes.js";
import { LabeledCategoriesValue } from "../../skill-categories/LabeledCategoriesValue.js";
import { RequestProjectInfoModal } from "../modals/RequestProjectInfoModal.js";
import { RequestProjectInfoModalFragment } from "../modals/RequestProjectInfoModal.generated.js";
import { RequestHeaderBoxFragment } from "./RequestHeaderBox.generated.js";

export const RequestHeaderBox = ({
  request,
  address,
  project,
}: {
  request: RequestHeaderBoxFragment & RequestProjectInfoModalFragment;
  address: AddressDetails__AddressFragment | undefined | null;
  project: { id: string; viewerPermissions: PermissionName[] };
}) => {
  const { t } = useTranslate(["Requests", "Global"]);

  const { projectTypeOptions: opportunityTypeOptions } = useProjectTypes();

  const attachments = request.attachments;
  const pictures = attachments.map(processAttachment).filter(isImageOr3dModel);

  const { addAttachments, modifyAttachment, removeAttachment, loading } =
    useRequestAttachments(request.id, [namedOperations.Query.ProjectRequest]);

  return (
    <CardContainer
      title={t("Quote request overview", {
        ns: "Requests",
      })}
      ActionButton={
        request.status === "OPEN" ? (
          <RestrictedByProjectPermissionWithDebug
            permission="SHARE_REQUIREMENTS"
            project={project}
          >
            <ModalOpenButton
              Modal={RequestProjectInfoModal}
              modalProps={{
                request,
                address,
                projectId: project.id,
                refetchQueries: [namedOperations.Query.ProjectRequest],
              }}
            >
              <IconButton color="primary" size="small">
                <EditIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByProjectPermissionWithDebug>
        ) : undefined
      }
    >
      <Stack p={1} flexDirection="column" spacing={1}>
        <PictureGallery
          pictures={pictures}
          loading={loading}
          showDelete={true}
          showAdd={true}
          showRotate={true}
          showUpload={false}
          addAttachments={addAttachments}
          modifyAttachment={modifyAttachment}
          removeAttachment={removeAttachment}
        />

        {request.description && (
          <Box>
            <TruncatedTextValue
              text={request.description}
              lines={3}
              showMoreLabel={t("Show more", { ns: "Global" })}
              showLessLabel={t("Show less", { ns: "Global" })}
              notSetLabel={t("Not set", { ns: "Global" })}
            />
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LabeledCategoriesValue
              label={t("Project categories", {
                ns: "Requests",
              })}
              value={request.categories}
              chipSize="small"
            />
          </Grid>

          <Grid item xs={6}>
            <LabeledSelectValue
              label={t("Project type", {
                ns: "Requests",
              })}
              options={opportunityTypeOptions}
              value={request.type}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <LabeledSpecializedValue
              label={t("Budget", {
                ns: "Requests",
              })}
              value={request.budget}
              type="money"
              icon={EuroIcon}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          </Grid>

          <Grid item xs={6}>
            <LabeledDateValue
              label={t("Earliest project start", {
                ns: "Requests",
              })}
              value={request.earliestStart}
              icon={CalendarTodayIcon}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <LabeledDateValue
              label={t("Work deadline", {
                ns: "Requests",
              })}
              value={request.deadline}
              icon={CalendarTodayIcon}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <LabeledAddressValue
              label={t("Project location", {
                ns: "Requests",
              })}
              address={request.address}
              variant="body2"
              icon={LocationOnIcon}
            />
          </Grid>
        </Grid>
      </Stack>
    </CardContainer>
  );
};
