import { assertNever, notNull, valuesByKeys } from "@msys/common";
import { isNil } from "lodash-es";
import {
  Maybe,
  OrganisationQuoteTemplatesSortBy,
  OrganisationQuoteTemplatesSorting,
  Quote,
  TemplateSearchFilterPropertyFilterInput,
  TemplateSearchSortBy,
  TemplateSearchSortingInput,
} from "../../../clients/graphqlTypes.js";
import { ItemTreeItem } from "../../trees/types.js";
import {
  Props2AllFragment,
  Props2NonComputedAllFragment,
} from "../doc-items/properties.generated.js";

export function shouldRenderTemplateCreateInput<
  T extends ItemTreeItem & {
    templateQuote?: Maybe<{ __typename?: "Quote" } & Pick<Quote, "id">>;
  },
>(item: T): boolean {
  return !item.templateQuote;
}

export function allowHaveTemplateChildren<
  T extends ItemTreeItem & {
    templateQuote?: Maybe<{ __typename?: "Quote" } & Pick<Quote, "id">>;
  },
>(item: T): boolean {
  return !item.templateQuote;
}

export function isSearchTemplatesPropertyFilterNotEmpty(
  p: TemplateSearchFilterPropertyFilterInput
) {
  return (
    (p.boolFilter && p.boolFilter.operatorBool === "eq") ||
    (p.boolInFilter && p.boolInFilter.valueBoolIn.length > 0) ||
    (p.numberFilter && p.numberFilter.operatorNumber === "eq") ||
    (p.numberInFilter && p.numberInFilter.valueNumberIn.length > 0) ||
    (p.numberArrayOfFilter &&
      p.numberArrayOfFilter.valueNumberArrayOf.length > 0) ||
    (p.textFilter && p.textFilter.operatorText === "eq") ||
    (p.textInFilter && p.textInFilter.valueTextIn.length > 0) ||
    (p.textArrayOfFilter && p.textArrayOfFilter.valueTextArrayOf.length > 0)
  );
}

/**
 * templateTypeProps ordering is providing the relevance of props
 * searchTemplatesFilters defines which properties are uninteresting because already specified
 */
export function computeTemplateRelevantProperties({
  rootItemProps,
  templateTypeProps,
}: {
  rootItemProps: Props2AllFragment[];
  templateTypeProps: Props2NonComputedAllFragment[];
}) {
  if (templateTypeProps.length === 0) {
    return [];
  }
  return valuesByKeys(
    rootItemProps,
    templateTypeProps.map(p => p.key),
    p => p.key
  ).flatMap(e => {
    if (!e) {
      return [];
    }
    switch (e.__typename) {
      case "Props2Bool": {
        return !isNil(e.valueBool) ? e : [];
      }
      case "Props2BoolComputed": {
        return !isNil(e.valueBoolComputed) ? e : [];
      }
      case "Props2Number": {
        return !isNil(e.valueNumber) ? e : [];
      }
      case "Props2NumberArray": {
        return !isNil(e.valueNumberArray) ? e : [];
      }
      case "Props2NumberArrayComputed": {
        return !isNil(e.valueNumberArrayComputed) ? e : [];
      }
      case "Props2NumberComputed": {
        return !isNil(e.valueNumberComputed) ? e : [];
      }
      case "Props2Text": {
        return !isNil(e.valueText) ? e : [];
      }
      case "Props2TextArray": {
        return !isNil(e.valueTextArray) ? e : [];
      }
      case "Props2TextArrayComputed": {
        return !isNil(e.valueTextArrayComputed) ? e : [];
      }
      case "Props2TextComputed": {
        return !isNil(e.valueTextComputed) ? e : [];
      }
      default: {
        assertNever(e);
      }
    }
  });
}

export const quoteTemplateSortByToSearchQuotesSortBy = (
  sortBy: OrganisationQuoteTemplatesSortBy
): TemplateSearchSortBy => {
  return {
    createdAt: "createdAt" as const,
    discountedPrice: "priceNetTotal" as const,
    title: "title" as const,
    totalPrice: "priceTotal" as const,
  }[sortBy];
};

export const searchQuotesSortByToQuoteTemplatesSortBy = (
  sortBy: TemplateSearchSortBy
): OrganisationQuoteTemplatesSortBy => {
  return {
    createdAt: "createdAt" as const,
    priceNetTotal: "discountedPrice" as const,
    title: "title" as const,
    priceTotal: "totalPrice" as const,
  }[sortBy];
};

export const quoteTemplateSortingToSearchQuotesSorting = (
  sorting: OrganisationQuoteTemplatesSorting[] | undefined | null
): TemplateSearchSortingInput[] => {
  return (sorting ?? []).map(s => ({
    fieldSorting: {
      field: quoteTemplateSortByToSearchQuotesSortBy(s.column),
      direction: s.direction,
    },
  }));
};

export const searchQuotesSortingToQuoteTemplatesSorting = (
  sorting: TemplateSearchSortingInput[] | undefined | null
): OrganisationQuoteTemplatesSorting[] => {
  return (sorting ?? [])
    .map(s =>
      s.fieldSorting
        ? {
            column: searchQuotesSortByToQuoteTemplatesSortBy(
              s.fieldSorting.field
            ),
            direction: s.fieldSorting.direction,
          }
        : null
    )
    .filter(notNull);
};
