/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeneratePurchaseOrderPdfQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;


export type GeneratePurchaseOrderPdfQuery = { orderGeneratePdf: { __typename: 'MissingPermissions' } | { __typename: 'OrderGeneratePdfResult', url: string } };


export const GeneratePurchaseOrderPdfDocument = gql`
    query GeneratePurchaseOrderPdf($orderId: ID!) {
  orderGeneratePdf(orderId: $orderId) {
    ... on OrderGeneratePdfResult {
      url
    }
  }
}
    `;

/**
 * __useGeneratePurchaseOrderPdfQuery__
 *
 * To run a query within a React component, call `useGeneratePurchaseOrderPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePurchaseOrderPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePurchaseOrderPdfQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGeneratePurchaseOrderPdfQuery(baseOptions: Apollo.QueryHookOptions<GeneratePurchaseOrderPdfQuery, GeneratePurchaseOrderPdfQueryVariables> & ({ variables: GeneratePurchaseOrderPdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeneratePurchaseOrderPdfQuery, GeneratePurchaseOrderPdfQueryVariables>(GeneratePurchaseOrderPdfDocument, options);
      }
export function useGeneratePurchaseOrderPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeneratePurchaseOrderPdfQuery, GeneratePurchaseOrderPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeneratePurchaseOrderPdfQuery, GeneratePurchaseOrderPdfQueryVariables>(GeneratePurchaseOrderPdfDocument, options);
        }
export function useGeneratePurchaseOrderPdfSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GeneratePurchaseOrderPdfQuery, GeneratePurchaseOrderPdfQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GeneratePurchaseOrderPdfQuery, GeneratePurchaseOrderPdfQueryVariables>(GeneratePurchaseOrderPdfDocument, options);
        }
export type GeneratePurchaseOrderPdfQueryHookResult = ReturnType<typeof useGeneratePurchaseOrderPdfQuery>;
export type GeneratePurchaseOrderPdfLazyQueryHookResult = ReturnType<typeof useGeneratePurchaseOrderPdfLazyQuery>;
export type GeneratePurchaseOrderPdfSuspenseQueryHookResult = ReturnType<typeof useGeneratePurchaseOrderPdfSuspenseQuery>;
export type GeneratePurchaseOrderPdfQueryResult = Apollo.QueryResult<GeneratePurchaseOrderPdfQuery, GeneratePurchaseOrderPdfQueryVariables>;