import { RichTextValue } from "@msys/ui";
import { Typography } from "@mui/material";
import React from "react";
import { Stack } from "../../../commons/layout/Stack.js";
import { PropertiesValue } from "../../../commons/PropertiesValue.js";
import { px } from "../../../../common/MuiThemeProvider.js";
import { PurchaseOrder__ItemFragment } from "../../../main-routes/projects/ProjectPurchaseOrderPreview.generated.js";
import { useOrderItemProperties } from "../../item-properties/itemProperties.js";

interface Props {
  item: Pick<
    PurchaseOrder__ItemFragment,
    | "id"
    | "productTitle"
    | "productDescription"
    | "productProps2"
    | "productBrand"
    | "productBrandLine"
  >;
}

export const PurchaseOrderItemDescriptionColumn = ({ item }: Props) => {
  const productProperties = useOrderItemProperties(item);

  return (
    <Stack flexDirection="column" spacing={1} maxWidth="100%">
      <Typography
        variant="body1"
        component="div"
        sx={{ wordBreak: "break-word" }}
      >
        {item.productTitle}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        sx={{ wordBreak: "break-word" }}
      >
        {item.productDescription ? (
          <RichTextValue
            key={item.productDescription ?? ""}
            htmlContent={item.productDescription ?? ""}
            style={{ marginTop: px.xs }}
          />
        ) : null}
        <PropertiesValue properties={productProperties} />
      </Typography>
    </Stack>
  );
};
