import { Modal } from "@msys/ui";
import { Box, styled } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { noop, omit } from "lodash-es";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import {
  Agreement,
  AttachmentInput,
  PermissionName,
} from "../../../../clients/graphqlTypes.js";
import { useGlobalLoadingCounter } from "../../../../common/global-loading-state/useGlobalLoadingCounter.js";
import { VirtualBareTreeStandaloneItem } from "../../../trees-virtual/components/VirtualBareTreeStandaloneItem.js";
import { AttachmentPhotoDragAndDropField } from "../../attachments/AttachmentPhotoDragAndDropField.js";
import { uploadFileToUploadCare } from "../../attachments/helpers.js";
import { createTreeSimpleItem } from "../trees.js";
import { PhotoApprovalModalFragment } from "./PhotoApprovalModal.generated.js";

interface Props {
  item:
    | (PhotoApprovalModalFragment & {
        docAgreement: Agreement;
        docViewerPermissions: PermissionName[];
      })
    | null;
  allDocItems:
    | (PhotoApprovalModalFragment & {
        docAgreement: Agreement;
        docViewerPermissions: PermissionName[];
      })[]
    | null;
  photoApproveTaskItem: (
    itemId: string,
    docId: string,
    photo: AttachmentInput
  ) => void;
  loading?: boolean;
  handleClose: () => void;
  handleComplete: (isPhotoAdded: boolean) => void;
}

export const PhotoApprovalModal = ({
  item,
  allDocItems,
  loading = false,
  handleClose,
  handleComplete,
  photoApproveTaskItem,
}: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const { t } = useTranslate(["QuoteItemTaskManagementBox", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const { increment, decrement } = useGlobalLoadingCounter();

  const TreeItem = React.useMemo(
    () =>
      createTreeSimpleItem({
        pathToDocPage: null,
        docAgreement: "YES",
        navigateToItem: noop,
        setItemExpanded: noop,
      }),
    []
  );

  const onSave = async () => {
    if (!file || !item) return;
    try {
      setUploading(true);
      increment();
      const attachment = await uploadFileToUploadCare(file);
      await photoApproveTaskItem(
        item.id,
        item.docId,
        omit(attachment, "id", "__type")
      );
      handleComplete(true);
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setUploading(false);
      decrement();
    }
  };

  return item && allDocItems ? (
    <Modal
      title={t("Add a completion photo", {
        ns: "QuoteItemTaskManagementBox",
      })}
      dialogProps={{ maxWidth: "xs" }}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
            disabled: loading || uploading,
          },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          handleClick: onSave,
          buttonProps: {
            loading: loading || uploading,
            disabled: !file || loading || uploading,
          },
        },
      ]}
    >
      <Container>
        <Box mb={1}>
          <VirtualBareTreeStandaloneItem
            item={item}
            items={allDocItems}
            depth={0}
            itemComponent={TreeItem}
          />
        </Box>
        <AttachmentPhotoDragAndDropField onFile={setFile} />
      </Container>
    </Modal>
  ) : null;
};

const Container = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
});
