import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, useScreenWidth } from "@msys/ui";
import { List } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageColumn } from "../../commons/layout/PageColumn.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageFullScreenModal } from "../../commons/layout/PageFullScreenModal.js";
import { PageHeader } from "../../commons/layout/PageHeader.js";
import { Stack } from "../../commons/layout/Stack.js";
import { Channel } from "../../features/channels/Channel.js";
import { ProjectChannelListItem } from "../../features/channels/ProjectChannelListItem.js";
import {
  ProjectChatProjectFragment,
  useProjectChatSidebarQuery,
} from "./ProjectChats.generated.js";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
  tabs: React.ReactElement | null;
}

export const ProjectChats = ({
  projectId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
  tabs,
}: Props) => {
  const { t } = useTranslate("ProjectPageTopbar");

  const client = useApolloClient();
  const query = useProjectChatSidebarQuery({
    client,
    variables: { projectId },
    fetchPolicy: "cache-and-network",
  });

  const project = getDataOrNull(query.data?.project)?.project;

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Chats"),
        to: `/projects/${projectId}/communication/chats`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  return (
    <Page
      subtitle={project?.title}
      title={t("Chats")}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={tabs}
    >
      <PageColumn $hasBackground $hasRightBorder $display="block">
        {project && <ProjectChatSidebar project={project} />}
      </PageColumn>
      <Outlet />
    </Page>
  );
};

interface ProjectChatSidebarProps {
  project: ProjectChatProjectFragment;
}

const ProjectChatSidebar = ({ project }: ProjectChatSidebarProps) => {
  const match1 = useMatch(
    `/projects/:projectId/communication/chats/:channelId`
  );
  const match2 = useMatch(
    `/projects/:projectId/communication/chats/u2u/:channelId`
  );
  const channelId = match1?.params?.channelId ?? match2?.params.channelId;

  const { t } = useTranslate("Channel");

  const channels = project.channels ?? [];

  const groupChats = channels.filter(e =>
    ["PRJ", "PRJGLOBAL"].includes(e.type)
  );

  const channelChats = channels.filter(e => ["PRJ_COMMENTS"].includes(e.type));

  return (
    <Stack flexDirection={"column"} spacing={2}>
      <CardContainer
        title={t("Channels")}
        isExpandable
        itemCount={channelChats.length}
        isInitiallyClosed={!channelChats.length}
      >
        <List disablePadding>
          {channelChats.map((channel, index, array) => (
            <ProjectChannelListItem
              channelId={channelId}
              divider={index < array.length - 1}
              channel={channel}
              key={channel.id}
            />
          ))}
        </List>
      </CardContainer>

      <CardContainer
        title={t("Project group chats")}
        isExpandable
        itemCount={groupChats.length}
        isInitiallyClosed={!groupChats.length}
      >
        <List disablePadding>
          {groupChats.map((channel, index, array) => (
            <ProjectChannelListItem
              channelId={channelId}
              divider={index < array.length - 1}
              channel={channel}
              key={channel.id}
            />
          ))}
        </List>
      </CardContainer>
    </Stack>
  );
};

export function ProjectChatChannel({ projectId }: { projectId: string }) {
  const { channelId } = useParams();
  if (!channelId) throw new Error("Channel id is missing");

  const navigate = useNavigate();
  const { t } = useTranslate("ProjectPageTopbar");
  const { isMinTablet } = useScreenWidth();

  if (isMinTablet) {
    return (
      <PageContainer $noPadding $fullHeight>
        <Channel key={channelId} channelId={channelId} />
      </PageContainer>
    );
  }

  return (
    <PageFullScreenModal
      title={t("Chat")}
      onCloseButtonClick={
        () => navigate(`/projects/${projectId}/communication/chats`) // FIXME: don't hardcode path
      }
      noPadding
    >
      <Channel key={channelId} channelId={channelId} />
    </PageFullScreenModal>
  );
}
