import { gql, useApolloClient } from "@apollo/client";
import { CollapseSection, Modal } from "@msys/ui";
import { DialogContentText } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { omit, uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import * as Yup from "yup";
import { EmailField } from "../../commons/form-fields/EmailField.js";
import { PHONE_REG_EXP } from "../../commons/form-fields/helpers.js";
import { Stack } from "../../commons/layout/Stack.js";
import { PhoneInput, Salutation } from "../../../clients/graphqlTypes.js";
import { useCreateCrmPersonMutation } from "./CrmPersonCreateModal.generated.js";
import { useTranslate } from "@tolgee/react";
import {
  useEmailAddressValidation,
  useEmailAddressValidationSchema,
} from "../email/validateEmailAddress.js";
import { PhonesField } from "../phones/PhonesField.js";
import { defaultPhoneType } from "../phones/usePhoneTypes.js";
import { UserTitleWithNameField } from "../users/UserTitleWithNameField.js";

interface FormValues {
  title: Salutation;
  firstName: string;
  familyName: string;
  email: string;
  jobTitle: string;
  phones: PhoneInput[];
  notices: string;
}

interface Props {
  crmCompanyId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (
    crmUser: {
      id: string;
      fullname: string;
      email: string | undefined | null;
    },
    handleClose: () => void
  ) => Promise<void>;
  refetchQueries?: string[];
  isEmailRequired?: boolean;
}

export const CrmPersonCreateModal = ({
  crmCompanyId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
  isEmailRequired = false,
}: Props) => {
  const { t } = useTranslate(["OrganisationInvite", "UserProfile", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const { createEmailAddressValidationSchema } =
    useEmailAddressValidationSchema();

  const client = useApolloClient();
  const [createCrmUser, { loading: isLoading }] = useCreateCrmPersonMutation({
    client,
  });

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const result = await createCrmUser({
        variables: {
          input: [
            {
              organisationId: crmCompanyId,
              title: values.title,
              firstname: values.firstName,
              familyname: values.familyName,
              email: values.email.trim(),
              jobTitle: values.jobTitle,
              phones: values.phones
                .filter(p => p.number.trim())
                .map(p => omit(p, "__typename", "id")),
              notices: values.notices,
            },
          ],
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });

      if (!result) return;

      if (handleComplete) {
        await handleComplete(result.data!.createCrmUser.users[0], handleClose);
      } else {
        handleClose();
        enqueueSnackbar(
          t("Member added", {
            ns: "OrganisationInvite",
          })
        );
      }
    },
    [
      crmCompanyId,
      createCrmUser,
      handleClose,
      handleComplete,
      enqueueSnackbar,
      refetchQueries,
      t,
    ]
  );

  const initialValues: FormValues = {
    title: "mr",
    firstName: "",
    familyName: "",
    email: "",
    jobTitle: "",
    phones: [defaultPhoneType],
    notices: "",
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      title: Yup.string()
        .label(
          t("Salutation", {
            ns: "Global",
          })
        )
        .required(),
      firstName: Yup.string().label(
        t("First name", {
          ns: "UserProfile",
        })
      ),
      familyName: Yup.string()
        .label(
          t("Last name", {
            ns: "UserProfile",
          })
        )
        .required(),
      email: createEmailAddressValidationSchema(
        t("Email", {
          ns: "OrganisationInvite",
        }),
        isEmailRequired
      ),
      jobTitle: Yup.string().label(
        t("Job title", {
          ns: "UserProfile",
        })
      ),
      phones: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          main: Yup.boolean(),
          type: Yup.string(),
          number: Yup.string().matches(
            PHONE_REG_EXP,
            t("Phone number is not valid", {
              ns: "UserProfile",
            })
          ),
          // .required(t("UserProfile::Phone number is required")),
        })
      ),
      notices: Yup.string(),
    });
  }, [createEmailAddressValidationSchema, isEmailRequired, t]);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Modal
          title={
            title ??
            t("Add contact", {
              ns: "OrganisationInvite",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Add", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={isLoading}
        >
          <DialogContentText>
            {t(
              "By adding a contact to your client, you are adding the contact information for one person related to that client",
              {
                ns: "OrganisationInvite",
              }
            )}
          </DialogContentText>
          <Form id={formId}>
            <Stack flexDirection="column" width="100%">
              <UserTitleWithNameField />
              <EmailField
                name="email"
                label={t("Email", {
                  ns: "OrganisationInvite",
                })}
                required={false}
              />
              <CollapseSection
                title={t("More options", {
                  ns: "Global",
                })}
                isInitiallyExpanded={false}
              >
                <Stack flexDirection="column">
                  <Field
                    component={TextField}
                    name="jobTitle"
                    label={t("Job title", {
                      ns: "UserProfile",
                    })}
                    disabled={false}
                  />
                  <PhonesField
                    name="phones"
                    buttonLabel={t("Add phone number", {
                      ns: "UserProfile",
                    })}
                    value={formikProps.values.phones}
                    hideCreateButton
                    hideDeleteButton
                  />
                  <Field
                    component={TextField}
                    multiline
                    name="notices"
                    label={t("Notes", {
                      ns: "UserProfile",
                    })}
                    disabled={false}
                  />
                </Stack>
              </CollapseSection>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
