import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  Autocomplete,
  Modal,
  useLocalStorageAsState,
  useScreenWidth,
} from "@msys/ui";
import { Input as InputIcon } from "@mui/icons-material";
import {
  darken,
  IconButton,
  lighten,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { intersection, isEqual } from "lodash-es";
import React from "react";
import { Props2 } from "../../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";
import {
  getEnhancedPropertyLabel,
  isComputedProp,
  isPropMissingValue,
} from "../properties.js";
import {
  ExpressionModal_DocumentItemFragment,
  useExpressionModalQuery,
} from "./ExpressionModal.generated.js";
import { FullScreenToggleIconButton } from "../../../commons/button/FullScreenToggleIconButton.js";

export interface PropertyOption {
  value: string;
  key: string;
  label: string;
  itemId: string;
  isComputed: boolean;
  hasValue: boolean;
}

export interface VariableInputState {
  expression: string;
  cursorIndex: number | null;
}

interface Props {
  projectId: string | null;
  docId: string;
  itemId: string;
  expression: string | undefined;
  expressionError?: Error | null;
  handleClose: () => void;
  handleSave: (
    expression: string,
    handleClose: () => void
  ) => Promise<void> | void;
  handleDelete: (handleClose: () => void) => Promise<void> | void;
  filterOptions?: (option: PropertyOption) => boolean;
  isFullScreenLocalStorageKey?: string;
}

export function ExpressionModal({
  projectId,
  docId,
  itemId,
  expression: passedExpression,
  expressionError,
  handleClose,
  handleSave,
  handleDelete,
  filterOptions,
  isFullScreenLocalStorageKey,
}: Props) {
  const { t } = useTranslate(["Global", "QuoteItem"]);

  const { isMaxPhone } = useScreenWidth();
  const [isFullScreen, setIsFullScreen] = useLocalStorageAsState<boolean>(
    `msys-expression-modal-fullscreen-${isFullScreenLocalStorageKey ?? "common"}`,
    false,
    true
  );

  const [inputState, setInputState] = React.useState<VariableInputState>({
    expression: passedExpression ?? "",
    cursorIndex: null,
  });

  return (
    <Modal
      title={t("Build your formula", { ns: "QuoteItem" })}
      dialogProps={
        !isMaxPhone && isFullScreen ? { fullScreen: true } : undefined
      }
      headerActions={
        !isMaxPhone ? (
          <FullScreenToggleIconButton
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
          />
        ) : undefined
      }
      alwaysVisible
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
          },
        },
        {
          label: t("Delete", {
            ns: "Global",
          }),
          handleClick: () => handleDelete(handleClose),
          buttonProps: {
            color: "secondary",
            disabled: !passedExpression,
          },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          handleClick: async () => {
            await handleSave(inputState.expression, handleClose);
          },
          buttonProps: {
            disabled: inputState.expression.length < 1,
          },
        },
      ]}
      handleClose={handleClose}
    >
      <ExpressionInputWithVariables
        label={t("Formula", { ns: "QuoteItem" })}
        itemId={itemId}
        docId={docId}
        projectId={projectId}
        expressionError={expressionError}
        inputState={inputState}
        onInputStateChange={setInputState}
        filterOptions={filterOptions}
        autoFocus={true}
        direction="column"
      />
    </Modal>
  );
}

export type ExpressionItemSource = "self" | "parent" | "root";

export function ExpressionInputWithVariables({
  itemId,
  docId,
  projectId,
  expressionError,
  inputState,
  onInputStateChange,
  label,
  placeholder,
  filterOptions,
  autoFocus = false,
  direction,
  allowedSources,
}: {
  itemId: string;
  docId: string;
  projectId?: string | null;
  expressionError?: Error | null;
  inputState: VariableInputState;
  onInputStateChange: (state: VariableInputState) => void;
  label?: string;
  placeholder?: string;
  filterOptions?: (option: PropertyOption) => boolean;
  autoFocus?: boolean;
  direction: "row" | "column";
  allowedSources?: ExpressionItemSource[];
}) {
  const { t } = useTranslate(["Global", "QuoteItem"]);

  const expressionFieldRef = React.useRef<HTMLTextAreaElement>(null);

  const [selectedVariable, setSelectedVariable] =
    React.useState<PropertyOption | null>(null);

  const client = useApolloClient();
  const query = useExpressionModalQuery({
    client,
    variables: {
      docId,
      projectId,
    },
  });
  const items = React.useMemo(
    () => getDataOrNull(query.data?.items)?.items ?? [],
    [query.data?.items]
  );
  const self = React.useMemo(
    () => items.find(i => i.id === itemId),
    [itemId, items]
  );

  const propertyOptions = React.useMemo(() => {
    function sortItems(
      item1: ExpressionModal_DocumentItemFragment,
      item2: ExpressionModal_DocumentItemFragment
    ) {
      if (item1.id === self?.id) return -1;
      if (item2.id === self?.id) return 1;
      if (item1.id === self?.parentId) return -1;
      if (item2.id === self?.parentId) return 1;
      if (item1.isRootItem) return -1;
      if (item2.isRootItem) return 1;
      return 0;
    }

    function sortProps(a: Props2, b: Props2) {
      return a.key.localeCompare(b.key);
    }

    const options = items
      .filter(item => {
        if (!allowedSources) return true;
        const sources: ExpressionItemSource[] = [];
        if (item.id === self?.id) sources.push("self");
        if (item.id === self?.parentId) sources.push("parent");
        if (item.isRootItem) sources.push("root");
        return intersection(sources, allowedSources).length > 0;
      })
      .sort(sortItems)
      .flatMap(item =>
        [...item.props2].sort(sortProps).map(prop => ({
          itemId: item.id,
          value: `prop($item_${item.id.replaceAll("-", "_")}, "${prop.key}")`,
          key: prop.key,
          label: getEnhancedPropertyLabel(prop, false),
          isComputed: isComputedProp(prop),
          hasValue: !isPropMissingValue(prop),
        }))
      );
    return filterOptions ? options.filter(filterOptions) : options;
  }, [filterOptions, allowedSources, items, self?.id, self?.parentId]);

  return (
    <Stack
      spacing={1}
      direction={direction}
      alignItems={direction === "row" ? "flex-start" : "stretch"}
    >
      <Stack direction={"row"} spacing={1} alignItems={"center"} flex={1}>
        <ExpressionInput
          inputRef={expressionFieldRef}
          autoFocus={autoFocus}
          label={label ?? t("Formula", { ns: "QuoteItem" })}
          placeholder={placeholder}
          value={inputState.expression}
          onChange={event =>
            onInputStateChange({
              ...inputState,
              expression: event.target.value,
            })
          }
          onBlur={event =>
            onInputStateChange({
              ...inputState,
              cursorIndex: event.target.selectionStart,
            })
          }
          onFocus={event => {
            event.target.setSelectionRange(
              inputState.cursorIndex,
              inputState.cursorIndex
            );
          }}
          error={Boolean(expressionError)}
          helperText={expressionError?.message}
        />
      </Stack>
      <Stack direction={"row"} spacing={1} alignItems={"center"} flex={1}>
        <Autocomplete
          inputLabel={t("Variable for formula", { ns: "QuoteItem" })}
          options={propertyOptions}
          groupBy={option => option.itemId}
          renderGroup={params => {
            const item = items.find(item => item.id === params.group);
            const pseudoReferences = [];
            if (params.group === itemId) pseudoReferences.push("self");
            if (item?.id === self?.parentId) pseudoReferences.push("parent");
            if (item?.isRootItem) pseudoReferences.push("root");
            return (
              <li key={params.group}>
                <GroupHeader direction="row" spacing={1}>
                  <Typography fontWeight={"bold"}>
                    {`${item?.pathForPdf} ${item?.title}`}
                  </Typography>
                  <Typography color={"gray"}>
                    {pseudoReferences.length > 0
                      ? `(${pseudoReferences.join(", ")})`
                      : null}
                  </Typography>
                </GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            );
          }}
          renderOption={(props, option) => {
            return (
              <Stack
                component={"li"}
                direction={"row"}
                spacing={1}
                key={option.value}
                {...props}
              >
                <Typography>{option.label}</Typography>
                {option.isComputed && (
                  <Typography color="secondary">{"(computed)"}</Typography>
                )}
                {!option.hasValue && (
                  <Typography color="error">{"(missing value)"}</Typography>
                )}
              </Stack>
            );
          }}
          value={selectedVariable}
          isOptionEqualToValue={(option, value) => isEqual(option, value)}
          onChange={value => setSelectedVariable(value)}
        />
        <IconButton
          color={"secondary"}
          disabled={!selectedVariable}
          onClick={() => {
            if (!selectedVariable) return;

            const { expression, cursorIndex } = inputState;
            const index = cursorIndex ?? expression.length;

            onInputStateChange({
              expression: `${expression.substring(0, index)}${
                selectedVariable.value
              }${expression.substring(index)}`,
              cursorIndex: (cursorIndex ?? 0) + selectedVariable.value.length,
            });

            setTimeout(() => expressionFieldRef.current?.focus());
          }}
        >
          <InputIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}

const GroupHeader = styled(Stack)(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

function ExpressionInput({ ...props }: React.ComponentProps<typeof TextField>) {
  return <TextField {...props} multiline />;
}
