/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type CrmPersonListItemFragment = { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, email: any };

export const CrmPersonListItemFragmentDoc = gql`
    fragment CrmPersonListItem on CrmPerson {
  id
  firstname
  familyname
  email
}
    `;