import { getFormattedNumber, isImage, processAttachment } from "@msys/ui";
import { Warning as WarningIcon } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { useFormikContext } from "formik";
import React from "react";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField.js";
import { PropertyGalleryBox } from "../boxes/PropertyGalleryBox.js";
import {
  AllowedValue,
  AllowedValuesField,
  AllowedValuesFormValues,
} from "./PropertyAllowedValuesField.js";

interface Props {
  type: "TEXT" | "NUMBER";
  disabled?: boolean;
  templateTypeAllowedValues: AllowedValue[];
  excludedType: "TEXT" | "NUMBER" | "BOOLEAN";
  excludedAllowedValues: AllowedValue[];
}

export const PropertyAllowedValuesFieldFromTemplateType = ({
  type,
  disabled,
  templateTypeAllowedValues,
  excludedType,
  excludedAllowedValues,
}: Props) => {
  const { t } = useTranslate(["Global", "QuoteItem"]);
  const language = useTolgee(["language"]).getLanguage()!;
  const formikProps = useFormikContext<AllowedValuesFormValues>();

  if (templateTypeAllowedValues.length === 0) {
    return <AllowedValuesField type={type} disabled={disabled} />;
  }

  return (
    <>
      <CheckboxField
        name={"defineAllowedValues"}
        label={t("Define allowed values", { ns: "QuoteItem" })}
        disabled
      />
      {formikProps.values.defineAllowedValues && (
        <FormGroup sx={{ pl: 4 }}>
          {templateTypeAllowedValues.map(ttAllowedValue => {
            const checked = formikProps.values.allowedValues.some(
              av => av.value === ttAllowedValue.value
            );
            return (
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={checked}
                    onChange={e => {
                      formikProps.setFieldValue(
                        "allowedValues",
                        templateTypeAllowedValues.filter(ttValue => {
                          if (ttValue.value === ttAllowedValue.value) {
                            return e.target.checked;
                          }
                          return formikProps.values.allowedValues.some(
                            av => av.value === ttValue.value
                          );
                        })
                      );
                    }}
                  />
                }
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    {ttAllowedValue.media && (
                      <PropertyGalleryBox
                        pictures={[ttAllowedValue.media]
                          .map(processAttachment)
                          .filter(isImage)}
                        flexGrow={0}
                        flexShrink={0}
                        width="80px"
                        height="60px"
                        imageHeight={60}
                        aspectRatio="80 / 60"
                        notInStack
                      />
                    )}
                    {type === "NUMBER"
                      ? getFormattedNumber(
                          ttAllowedValue.value as number,
                          language
                        )
                      : ttAllowedValue.value}
                  </Stack>
                }
              />
            );
          })}
          {excludedType !== "BOOLEAN" && excludedAllowedValues.length > 0
            ? excludedAllowedValues.map(excludedAllowedValue => (
                <FormControlLabel
                  disabled={true}
                  control={
                    <Checkbox readOnly={true} disabled={true} checked={false} />
                  }
                  label={
                    <Stack direction="row" spacing={1} alignItems="center">
                      {excludedAllowedValue.media && (
                        <PropertyGalleryBox
                          pictures={[excludedAllowedValue.media]
                            .map(processAttachment)
                            .filter(isImage)}
                          flexGrow={0}
                          flexShrink={0}
                          width="80px"
                          height="60px"
                          imageHeight={60}
                          aspectRatio="80 / 60"
                          notInStack
                        />
                      )}
                      <s>
                        {excludedType === "NUMBER"
                          ? getFormattedNumber(
                              excludedAllowedValue.value as number,
                              language
                            )
                          : excludedAllowedValue.value}
                      </s>
                      <Tooltip
                        title={t("No longer available", { ns: "Global" })}
                      >
                        <WarningIcon color="disabled" />
                      </Tooltip>
                    </Stack>
                  }
                />
              ))
            : null}
        </FormGroup>
      )}
    </>
  );
};
