import { SelectOption, useScreenWidth } from "@msys/ui";
import { Box, Grid, Slider, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useField } from "formik";
import React from "react";
import { AttachmentSnapshot } from "../../../clients/graphqlTypes.js";
import { FormattedFloatOptionalField } from "../../commons/form-fields/FormattedFloatOptionalField.js";
import {
  SelectBooleanField,
  SelectField,
  SelectNumberField,
} from "../../commons/form-fields/SelectField.js";
import {
  SelectMultipleField,
  SelectMultipleNumberField,
} from "../../commons/form-fields/SelectMultipleField.js";
import { TextField } from "../../commons/form-fields/TextField.js";
import { getPropertyDisplayedUnit } from "./properties.js";
import {
  Props2NonComputedAll_Props2Bool_Fragment,
  Props2NonComputedAll_Props2Number_Fragment,
  Props2NonComputedAll_Props2NumberArray_Fragment,
  Props2NonComputedAll_Props2Text_Fragment,
  Props2NonComputedAll_Props2TextArray_Fragment,
  Props2NonComputedAllFragment,
} from "./properties.generated.js";
import { PropertyOptionItem } from "./PropertyOptionItem.js";
import { usePropertyHelpers } from "./usePropertyHelpers.js";
import { keyBy } from "lodash-es";

interface Props {
  name: string;
  property: Props2NonComputedAllFragment;
  required?: boolean;
  disabled?: boolean;
  valueLabels?: { value: string; label: string }[];
  showSelectThreshold?: number;
}

export const WizardPropertyField = ({
  name,
  property,
  required = false,
  disabled = false,
  valueLabels,
  showSelectThreshold,
}: Props) => {
  switch (property.__typename) {
    case "Props2Bool":
      return (
        <PropertyBoolField
          key={property.key}
          name={name}
          property={property}
          required={required}
          disabled={disabled}
        />
      );
    case "Props2Text":
      if (property.allowedValuesText && property.allowedValuesText.length > 0) {
        return (
          <PropertyTextWithAllowedValuesField
            key={property.key}
            name={name}
            property={property}
            required={required}
            disabled={disabled}
            valueLabels={valueLabels}
            showSelectThreshold={showSelectThreshold}
          />
        );
      }
      return (
        <PropertyTextField
          key={property.key}
          name={name}
          property={property}
          required={required}
          disabled={disabled}
        />
      );
    case "Props2Number":
      if (
        property.allowedValuesNumber &&
        property.allowedValuesNumber.length > 0
      ) {
        return (
          <PropertyNumberWithAllowedValuesField
            key={property.key}
            name={name}
            property={property}
            required={required}
            disabled={disabled}
            showSelectThreshold={showSelectThreshold}
          />
        );
      }
      return (
        <PropertyNumberField
          key={property.key}
          name={name}
          property={property}
          disabled={disabled}
          required={required}
        />
      );
    case "Props2TextArray":
      if (property.allowedValuesText && property.allowedValuesText.length > 0) {
        return (
          <PropertyTextArrayWithAllowedValuesField
            key={property.key}
            name={name}
            property={property}
            required={required}
            disabled={disabled}
            showSelectThreshold={showSelectThreshold}
          />
        );
      }
      return null;
    case "Props2NumberArray":
      if (
        property.allowedValuesNumber &&
        property.allowedValuesNumber.length > 0
      ) {
        return (
          <PropertyNumberArrayWithAllowedValuesField
            key={property.key}
            name={name}
            property={property}
            required={required}
            disabled={disabled}
            showSelectThreshold={showSelectThreshold}
          />
        );
      }
      return null;
  }
};

const PropertyTextField = ({
  name,
  property,
  required,
  disabled,
}: {
  name: string;
  property: Props2NonComputedAll_Props2Text_Fragment;
  required?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslate(["Global", "Properties"]);
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {`${property.prompt || property.label}${required ? "*" : ""}`}
      </Typography>
      <TextField
        label={t("Enter the value", {
          ns: "Properties",
        })}
        type="text"
        name={`${name}.valueText`}
        disabled={disabled}
      />
    </Stack>
  );
};

const PropertyNumberField = ({
  name,
  property,
  required,
  disabled,
}: {
  name: string;
  property: Props2NonComputedAll_Props2Number_Fragment;
  required?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslate(["Global", "Properties"]);
  const [{ value }, { touched, error: formError }, { setValue: setFormValue }] =
    useField<number | null>(`${name}.valueNumber`);
  const { getUnitLabel, getNumberLabel } = usePropertyHelpers();

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {`${property.prompt || property.label}${required ? "*" : ""}`}
      </Typography>
      <Stack direction="row" spacing={1}>
        <FormattedFloatOptionalField
          label={t("Enter the value", {
            ns: "Properties",
          })}
          name={`${name}.valueNumber`}
          type="float"
          min={property.range?.min ?? undefined}
          max={property.range?.max ?? undefined}
          // unit={property.unit ? "\u00A0" + property.unit : undefined}
          sx={{ maxWidth: "150px", flexShrink: 0, flexGrow: 0 }}
          disabled={disabled}
        />
        {property.unit && (
          <Box display="flex" alignItems="center" height="48px">
            <Typography variant="body2" color="secondary">
              {getUnitLabel(property.unit)}
            </Typography>
          </Box>
        )}
      </Stack>
      {property.range?.min !== null &&
      property.range?.min !== undefined &&
      property.range?.max !== null &&
      property.range?.max !== undefined ? (
        <Stack direction="row" spacing={3} pt={1} alignItems="center">
          <Box>
            <Typography variant="body2">
              {getNumberLabel(property.range.min)}
            </Typography>
          </Box>
          <Slider
            color="secondary"
            value={value ?? property.range.min}
            onChange={(event, newValue) => {
              if (typeof newValue === "number") setFormValue(newValue);
            }}
            valueLabelDisplay="auto"
            min={property.range.min}
            max={property.range.max}
            disabled={disabled}
          />
          <Box>
            <Typography variant="body2">
              {getNumberLabel(property.range.max)}
            </Typography>
          </Box>
        </Stack>
      ) : null}
    </Stack>
  );
};

const PropertyBoolField = ({
  name,
  property,
  required,
  disabled,
  showSelectThreshold,
}: {
  name: string;
  property: Props2NonComputedAll_Props2Bool_Fragment;
  required?: boolean;
  disabled?: boolean;
  showSelectThreshold?: number;
}) => {
  const { getBoolLabel } = usePropertyHelpers();
  const options = React.useMemo(
    () =>
      [true, false].map(value => ({
        value,
        label: getBoolLabel(value),
      })),
    [getBoolLabel]
  );

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {`${property.prompt || property.label}${required ? "*" : ""}`}
      </Typography>
      <OptionsSelect<boolean>
        name={`${name}.valueBool`}
        options={options}
        type="bool"
        disabled={disabled}
        showSelectThreshold={showSelectThreshold}
      />
    </Stack>
  );
};

const PropertyTextWithAllowedValuesField = ({
  name,
  property,
  required,
  disabled,
  valueLabels,
  showSelectThreshold,
}: {
  name: string;
  property: Props2NonComputedAll_Props2Text_Fragment;
  required?: boolean;
  disabled?: boolean;
  valueLabels?: { value: string; label: string }[];
  showSelectThreshold?: number;
}) => {
  const options = React.useMemo(() => {
    const valueLabelsByValue = keyBy(valueLabels ?? [], e => e.value);

    return property.allowedValuesText!.map(({ allowedText, media }) => {
      const valueLabel: string | undefined =
        valueLabelsByValue[allowedText]?.label;
      return {
        value: allowedText,
        label: valueLabel ?? allowedText,
        image: media ?? null,
      };
    });
  }, [property.allowedValuesText, valueLabels]);

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {`${property.prompt || property.label}${required ? "*" : ""}`}
      </Typography>
      <OptionsSelect<string>
        name={`${name}.valueText`}
        options={options}
        type="text"
        disabled={disabled}
        showSelectThreshold={showSelectThreshold}
      />
    </Stack>
  );
};

const PropertyNumberWithAllowedValuesField = ({
  name,
  property,
  required,
  disabled,
  showSelectThreshold,
}: {
  name: string;
  property: Props2NonComputedAll_Props2Number_Fragment;
  required?: boolean;
  disabled?: boolean;
  showSelectThreshold?: number;
}) => {
  const { getUnitLabel, getNumberLabel } = usePropertyHelpers();
  const options = React.useMemo(
    () =>
      property.allowedValuesNumber!.map(({ allowedNumber, media }) => ({
        value: allowedNumber,
        label: `${getNumberLabel(allowedNumber)}${getPropertyDisplayedUnit(
          property,
          getUnitLabel
        )}`,
        image: media ?? null,
      })),
    [property, getNumberLabel, getUnitLabel]
  );

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {`${property.prompt || property.label}${required ? "*" : ""}`}
      </Typography>
      <OptionsSelect<number>
        name={`${name}.valueNumber`}
        options={options}
        type="number"
        disabled={disabled}
        showSelectThreshold={showSelectThreshold}
      />
    </Stack>
  );
};

const PropertyTextArrayWithAllowedValuesField = ({
  name,
  property,
  required,
  disabled,
  showSelectThreshold,
}: {
  name: string;
  property: Props2NonComputedAll_Props2TextArray_Fragment;
  required?: boolean;
  disabled?: boolean;
  showSelectThreshold?: number;
}) => {
  const options = React.useMemo(
    () =>
      property.allowedValuesText!.map(({ allowedText, media }) => ({
        value: allowedText,
        label: allowedText,
        image: media ?? null,
      })),
    [property.allowedValuesText]
  );

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {`${property.prompt || property.label}${required ? "*" : ""}`}
      </Typography>
      <OptionsSelectMultiple<string>
        type="text"
        name={`${name}.valueTextArray`}
        options={options}
        disabled={disabled}
        showSelectThreshold={showSelectThreshold}
      />
    </Stack>
  );
};

const PropertyNumberArrayWithAllowedValuesField = ({
  name,
  property,
  required,
  disabled,
  showSelectThreshold,
}: {
  name: string;
  property: Props2NonComputedAll_Props2NumberArray_Fragment;
  required?: boolean;
  disabled?: boolean;
  showSelectThreshold?: number;
}) => {
  const { getUnitLabel, getNumberLabel } = usePropertyHelpers();
  const options = React.useMemo(
    () =>
      property.allowedValuesNumber!.map(({ allowedNumber, media }) => ({
        value: allowedNumber,
        label: `${getNumberLabel(allowedNumber)}${getPropertyDisplayedUnit(
          property,
          getUnitLabel
        )}`,
        image: media ?? null,
      })),
    [property, getNumberLabel, getUnitLabel]
  );

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {`${property.prompt || property.label}${required ? "*" : ""}`}
      </Typography>
      <OptionsSelectMultiple<number>
        type="number"
        name={`${name}.valueNumberArray`}
        options={options}
        disabled={disabled}
        showSelectThreshold={showSelectThreshold}
      />
    </Stack>
  );
};

const OptionsSelect = <V,>({
  type,
  name,
  options,
  disabled,
  showSelectThreshold = 10,
}: {
  name: string;
  options: { value: V; label: string; image?: AttachmentSnapshot | null }[];
  type: "number" | "text" | "bool";
  disabled?: boolean;
  showSelectThreshold?: number;
}) => {
  const { t } = useTranslate(["Global", "Properties"]);
  const [{ value }, , { setValue: setFormValue }] = useField<V | null>(name);
  const { isMinTablet } = useScreenWidth();
  const withImage = options.some(o => o.image);

  return options.length <= showSelectThreshold || withImage ? (
    <Box>
      <Grid container spacing={1} columns={isMinTablet ? 3 : 2}>
        {options.map((option, index) => (
          <Grid item xs={1} key={index}>
            <PropertyOptionItem
              label={option.label}
              isPreselected={value === option.value}
              isGreyedOut={value !== option.value}
              image={option.image ?? null}
              withImage={withImage}
              onClick={async () => {
                setFormValue(value === option.value ? null : option.value);
              }}
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : type === "number" ? (
    <SelectNumberField
      name={name}
      options={options as SelectOption<number>[]}
      label={t("Select the value", {
        ns: "Properties",
      })}
      clearable={!disabled}
      onClear={() => setFormValue(null)}
      disabled={disabled}
    />
  ) : type === "bool" ? (
    <SelectBooleanField
      name={name}
      options={options as SelectOption<boolean>[]}
      label={t("Select the value", {
        ns: "Properties",
      })}
      clearable={!disabled}
      onClear={() => setFormValue(null)}
      disabled={disabled}
    />
  ) : (
    <SelectField
      name={name}
      options={options}
      label={t("Select the value", {
        ns: "Properties",
      })}
      clearable={!disabled}
      onClear={() => setFormValue(null)}
      disabled={disabled}
    />
  );
};

const OptionsSelectMultiple = <V,>({
  type,
  name,
  options,
  disabled,
  showSelectThreshold = 10,
}: {
  name: string;
  options: { value: V; label: string; image?: AttachmentSnapshot | null }[];
  type: "number" | "text";
  disabled?: boolean;
  showSelectThreshold?: number;
}) => {
  const { t } = useTranslate(["Global", "Properties"]);
  const [{ value }, , { setValue: setFormValue }] = useField<V[] | null>(name);
  const { isMinTablet } = useScreenWidth();
  const withImage = options.some(o => o.image);

  return options.length <= showSelectThreshold || withImage ? (
    <Box>
      <Grid container spacing={1} columns={isMinTablet ? 3 : 2}>
        {options.map((option, index) => (
          <Grid item xs={1} key={index}>
            <PropertyOptionItem
              multiple
              label={option.label}
              isPreselected={value?.includes(option.value) ?? false}
              isGreyedOut={!value?.includes(option.value)}
              image={option.image ?? null}
              withImage={withImage}
              onClick={() => {
                setFormValue(
                  value?.includes(option.value)
                    ? value.filter(v => v !== option.value)
                    : [...(value ?? []), option.value]
                );
              }}
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : type === "number" ? (
    <SelectMultipleNumberField
      name={name}
      options={options as SelectOption<number>[]}
      label={t("Select the value", {
        ns: "Properties",
      })}
      disabled={disabled}
    />
  ) : (
    <SelectMultipleField
      name={name}
      options={options}
      label={t("Select the value", {
        ns: "Properties",
      })}
      disabled={disabled}
    />
  );
};
