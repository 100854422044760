import { useTranslate } from "@tolgee/react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { PageTopbarItem } from "../../commons/layout/Page.js";
import {
  ProjectIcon,
  ProjectPastIcon,
} from "../../features/projects/ProjectIcon.js";
import { ProjectRoutes } from "./ProjectRoutes.js";
import { ProjectsCurrent } from "./ProjectsCurrent.js";
import { ProjectsPast } from "./ProjectsPast.js";

type PageSubmenuItem = PageTopbarItem;

export const ProjectsRoutes = () => {
  const { t } = useTranslate(["Projects", "Tickets"]);

  const submenuItems: PageSubmenuItem[] = React.useMemo(() => {
    return [
      {
        name: "projects-current",
        label: t("Current projects", { ns: "Projects" }),
        icon: <ProjectIcon />,
        path: "/projects/current",
      },
      {
        name: "projects-past",
        label: t("Past projects", { ns: "Projects" }),
        icon: <ProjectPastIcon />,
        path: "/projects/past",
      },
    ];
  }, [t]);

  return (
    <Routes>
      <Route index element={<Navigate to="current" replace />} />
      <Route
        path={"current"}
        element={<ProjectsCurrent submenuItems={submenuItems} />}
      />
      <Route
        path={"past"}
        element={<ProjectsPast submenuItems={submenuItems} />}
      />
      <Route path={`:projectId/*`} element={<ProjectRoutes />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
