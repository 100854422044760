import { getSupportedSystemLocale, Locale } from "@msys/tolgee";
import { useTolgee } from "@tolgee/react";
import { FC, useEffect, useState } from "react";
import { useUserData } from "../auth/useUserData.js";

type EmptyScreenLocale = "en" | "de";

export const localeToEmptyScreenLocale: Record<Locale, EmptyScreenLocale> = {
  "en-GB": "en",
  de: "de",
  fr: "en",
  ru: "en",
  pl: "en",
  nl: "en",
};

interface Props {
  slug: string;
}

const EmptyScreen: FC<Props> = ({ slug }) => {
  const viewer = useUserData().currentUser!;
  const language = useTolgee(["language"]).getLanguage()!;
  const [content, setContent] = useState("");

  const locale = localeToEmptyScreenLocale[getSupportedSystemLocale(language)];

  const viewerType = viewer.organisation.isCraftsmanOrganisation
    ? "contractor"
    : "homeowner";

  useEffect(() => {
    async function loadScreen() {
      const response = await fetch(
        `/empty-screens/${slug}/${viewerType}/${locale}.json`
      );
      let json: any = await response.json();
      setContent(json.content.rendered);
    }
    loadScreen();
  }, [locale, slug, viewerType]);

  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export default EmptyScreen;
