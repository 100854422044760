/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteSapS4HanaDataQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type QuoteSapS4HanaDataQuery = { quoteSapS4HanaData?: { __typename: 'QuoteSapS4HanaData', id: string, xStatus?: string | null, sapS4HanaDataFieldMetadata: Array<{ __typename: 'EntityFieldMetadata', key: string, required: boolean, editable: boolean, allowedValues?: Array<{ __typename: 'EntityFieldAllowedValue', value: string, label: string }> | null }> } | null };

export type SetQuoteSapS4HanaStatusMutationVariables = Types.Exact<{
  input: Types.ModifySapS4HanaSalesQuoteDataInput;
}>;


export type SetQuoteSapS4HanaStatusMutation = { modifySapS4HanaSalesQuoteData: { __typename: 'ModifySapS4HanaSalesQuoteDataResult', quote: { __typename: 'Quote', id: string, xStatus?: string | null }, quoteSapS4HanaData: { __typename: 'QuoteSapS4HanaData', id: string, xStatus?: string | null } } };


export const QuoteSapS4HanaDataDocument = gql`
    query QuoteSapS4HanaData($projectId: ID!, $quoteId: ID!) {
  quoteSapS4HanaData(projectId: $projectId, docId: $quoteId) {
    id
    xStatus
    sapS4HanaDataFieldMetadata {
      key
      required
      editable
      allowedValues {
        value
        label
      }
    }
  }
}
    `;

/**
 * __useQuoteSapS4HanaDataQuery__
 *
 * To run a query within a React component, call `useQuoteSapS4HanaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteSapS4HanaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteSapS4HanaDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useQuoteSapS4HanaDataQuery(baseOptions: Apollo.QueryHookOptions<QuoteSapS4HanaDataQuery, QuoteSapS4HanaDataQueryVariables> & ({ variables: QuoteSapS4HanaDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteSapS4HanaDataQuery, QuoteSapS4HanaDataQueryVariables>(QuoteSapS4HanaDataDocument, options);
      }
export function useQuoteSapS4HanaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteSapS4HanaDataQuery, QuoteSapS4HanaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteSapS4HanaDataQuery, QuoteSapS4HanaDataQueryVariables>(QuoteSapS4HanaDataDocument, options);
        }
export function useQuoteSapS4HanaDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuoteSapS4HanaDataQuery, QuoteSapS4HanaDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuoteSapS4HanaDataQuery, QuoteSapS4HanaDataQueryVariables>(QuoteSapS4HanaDataDocument, options);
        }
export type QuoteSapS4HanaDataQueryHookResult = ReturnType<typeof useQuoteSapS4HanaDataQuery>;
export type QuoteSapS4HanaDataLazyQueryHookResult = ReturnType<typeof useQuoteSapS4HanaDataLazyQuery>;
export type QuoteSapS4HanaDataSuspenseQueryHookResult = ReturnType<typeof useQuoteSapS4HanaDataSuspenseQuery>;
export type QuoteSapS4HanaDataQueryResult = Apollo.QueryResult<QuoteSapS4HanaDataQuery, QuoteSapS4HanaDataQueryVariables>;
export const SetQuoteSapS4HanaStatusDocument = gql`
    mutation SetQuoteSapS4HanaStatus($input: ModifySapS4HanaSalesQuoteDataInput!) {
  modifySapS4HanaSalesQuoteData(input: $input) {
    quote {
      id
      xStatus
    }
    quoteSapS4HanaData {
      id
      xStatus
    }
  }
}
    `;
export type SetQuoteSapS4HanaStatusMutationFn = Apollo.MutationFunction<SetQuoteSapS4HanaStatusMutation, SetQuoteSapS4HanaStatusMutationVariables>;

/**
 * __useSetQuoteSapS4HanaStatusMutation__
 *
 * To run a mutation, you first call `useSetQuoteSapS4HanaStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetQuoteSapS4HanaStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setQuoteSapS4HanaStatusMutation, { data, loading, error }] = useSetQuoteSapS4HanaStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetQuoteSapS4HanaStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetQuoteSapS4HanaStatusMutation, SetQuoteSapS4HanaStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetQuoteSapS4HanaStatusMutation, SetQuoteSapS4HanaStatusMutationVariables>(SetQuoteSapS4HanaStatusDocument, options);
      }
export type SetQuoteSapS4HanaStatusMutationHookResult = ReturnType<typeof useSetQuoteSapS4HanaStatusMutation>;
export type SetQuoteSapS4HanaStatusMutationResult = Apollo.MutationResult<SetQuoteSapS4HanaStatusMutation>;
export type SetQuoteSapS4HanaStatusMutationOptions = Apollo.BaseMutationOptions<SetQuoteSapS4HanaStatusMutation, SetQuoteSapS4HanaStatusMutationVariables>;