import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageGrid, splitStrategy } from "../../commons/layout/PageGrid.js";
import { OrganisationProjectSettingsBox } from "../../features/organisations/boxes/OrganisationProjectSettingsBox.js";
import { OrganisationScheduleSettingsBox } from "../../features/organisations/boxes/OrganisationScheduleSettingsBox.js";
import { OrganisationTaskSettingsBox } from "../../features/organisations/boxes/OrganisationTaskSettingsBox.js";
import { useOrganisationSettingsPlanningQuery } from "./OrganisationSettingsPlanning.generated.js";

interface Props {
  submenuItems: PageTopbarItem[];
}

export const OrganisationSettingsPlanning = ({ submenuItems }: Props) => {
  const { t } = useTranslate("OrganisationPageTopbar");

  const client = useApolloClient();
  const query = useOrganisationSettingsPlanningQuery({
    client,
    fetchPolicy: "cache-and-network",
  });
  const organisation = query.data?.viewer?.organisation;
  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);
  const organisationSettings = query.data?.organisationSettings;

  return (
    <Page title={t("Planning & Execution")} submenuItems={submenuItems}>
      {organisation && organisationDefaults && organisationSettings && (
        <PageContainer>
          <PageGrid columns={{ xs: 1, md: 2, xl: 3 }} strategy={splitStrategy}>
            <>
              <OrganisationScheduleSettingsBox
                organisationId={organisation.id}
                organisation={organisation}
                organisationDefaults={organisationDefaults}
              />
            </>
            <>
              <OrganisationTaskSettingsBox
                organisationId={organisation.id}
                organisationDefaults={organisationDefaults}
              />
              <OrganisationProjectSettingsBox
                organisationId={organisation.id}
                organisationSettings={organisationSettings}
              />
            </>
          </PageGrid>
        </PageContainer>
      )}
    </Page>
  );
};
