import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { browserHasFlexGap } from "../../featureDetection.js";
import { transientOptions } from "../../styles.js";

// @ts-ignore `css` props are not compatible
export interface Props {
  spacing?: number;
}

export const Stack = React.forwardRef<unknown, Props & Omit<BoxProps, "title">>(
  ({ spacing = 1, ...otherProps }, forwardedRef) => {
    if (!browserHasFlexGap) {
      return (
        <StackBaseNoGap
          // @ts-ignore
          ref={forwardedRef}
          display="flex"
          $spacing={spacing}
          {...otherProps}
        />
      );
    }
    return (
      <StackBaseGap
        // @ts-ignore
        ref={forwardedRef}
        display="flex"
        $spacing={spacing}
        {...otherProps}
      />
    );
  }
);

const StackBaseGap = styled(Box, transientOptions)<{
  $spacing: number;
}>`
  ${({ $spacing, theme }) => `gap: ${theme.spacing($spacing)};`};
`;

// TODO: polyfill gap properly
// Polyfilling gap like this is not doing it properly and only covers some use-cases
// When flexDirection=row and flexWrap=wrap, it adds margin after last element in row and does not add margin between rows
const StackBaseNoGap = styled(Box, transientOptions)<{
  $spacing: number;
}>`
  ${({ $spacing, theme, flexDirection }) => `
    --gap: ${theme.spacing($spacing)};
    > * {
      &:not(:last-child) {
        ${
          flexDirection === "column"
            ? `margin-bottom: ${theme.spacing($spacing)};`
            : `margin-right: ${theme.spacing($spacing)};`
        }}
      }
    }
  `};
`;
