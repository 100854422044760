/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectBudgetQueryVariables = Types.Exact<{
  filterType?: Types.InputMaybe<Array<Types.ProjectType>>;
  filterCategories?: Types.InputMaybe<Array<Types.Skill>>;
  filterWithinRadiusKm?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  filterSearchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterByCustomFields?: Types.InputMaybe<Array<Types.CustomFieldFilter>>;
  filterByCrmOrganisationId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterByCrmLinkIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterBySourceCrmOrganisationIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterBySourceUserIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterBySources?: Types.InputMaybe<Array<Types.ProjectSourceType>>;
  filterIncludeState?: Types.InputMaybe<Array<Types.ProjectStateMachineStatus>>;
  filterPhaseIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterKind?: Types.InputMaybe<Types.ProjectFilterKind>;
  filterPlanned?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterOverdue?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterUrgent?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterAssigneeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterUnassigned?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterRelevantToUserIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterBuildingZip?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ProjectBudgetQuery = { projectsHighest: { __typename: 'ProjectConnection', edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, budget?: { __typename: 'Money', currency: string, amount: number } | null } }> }, projectsLowest: { __typename: 'ProjectConnection', edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, budget?: { __typename: 'Money', currency: string, amount: number } | null } }> } };

export type ProjectsBudget_ProjectFragment = { __typename: 'Project', id: string, budget?: { __typename: 'Money', currency: string, amount: number } | null };

export const ProjectsBudget_ProjectFragmentDoc = gql`
    fragment ProjectsBudget_Project on Project {
  id
  budget {
    currency
    amount
  }
}
    `;
export const ProjectBudgetDocument = gql`
    query ProjectBudget($filterType: [ProjectType!], $filterCategories: [Skill!], $filterWithinRadiusKm: Float, $filterSearchTerm: String, $filterByCustomFields: [CustomFieldFilter!], $filterByCrmOrganisationId: ID, $filterByCrmLinkIds: [ID!], $filterBySourceCrmOrganisationIds: [ID!], $filterBySourceUserIds: [ID!], $filterBySources: [ProjectSourceType!], $filterIncludeState: [ProjectStateMachineStatus!], $filterPhaseIds: [ID!], $filterKind: ProjectFilterKind, $filterPlanned: Boolean, $filterOverdue: Boolean, $filterUrgent: Boolean, $filterAssigneeIds: [ID!], $filterUnassigned: Boolean, $filterRelevantToUserIds: [ID!], $filterBuildingZip: String) {
  projectsHighest: projects(
    offset: 0
    limit: 1
    sorting: [{column: budget, direction: desc}]
    filters: {type: $filterType, categories: $filterCategories, withinRadiusKm: $filterWithinRadiusKm, customFields: $filterByCustomFields, crmOrganisationId: $filterByCrmOrganisationId, crmLinkIds: $filterByCrmLinkIds, sourceCrmOrganisationIds: $filterBySourceCrmOrganisationIds, sourceUserIds: $filterBySourceUserIds, sources: $filterBySources, includeState: $filterIncludeState, phaseIds: $filterPhaseIds, kind: $filterKind, planned: $filterPlanned, overdue: $filterOverdue, urgent: $filterUrgent, assigneeIds: $filterAssigneeIds, unassigned: $filterUnassigned, relevantToUserIds: $filterRelevantToUserIds, buildingZip: $filterBuildingZip}
    search: $filterSearchTerm
  ) {
    ... on ProjectConnection {
      edges {
        node {
          id
          ...ProjectsBudget_Project
        }
      }
    }
  }
  projectsLowest: projects(
    offset: 0
    limit: 1
    sorting: [{column: budget, direction: asc}]
    filters: {type: $filterType, categories: $filterCategories, withinRadiusKm: $filterWithinRadiusKm, minBudget: 0.01, customFields: $filterByCustomFields, crmOrganisationId: $filterByCrmOrganisationId, crmLinkIds: $filterByCrmLinkIds, sourceCrmOrganisationIds: $filterBySourceCrmOrganisationIds, sourceUserIds: $filterBySourceUserIds, sources: $filterBySources, includeState: $filterIncludeState, phaseIds: $filterPhaseIds, kind: $filterKind, planned: $filterPlanned, overdue: $filterOverdue, urgent: $filterUrgent, assigneeIds: $filterAssigneeIds, unassigned: $filterUnassigned, relevantToUserIds: $filterRelevantToUserIds, buildingZip: $filterBuildingZip}
    search: $filterSearchTerm
  ) {
    ... on ProjectConnection {
      edges {
        node {
          id
          ...ProjectsBudget_Project
        }
      }
    }
  }
}
    ${ProjectsBudget_ProjectFragmentDoc}`;

/**
 * __useProjectBudgetQuery__
 *
 * To run a query within a React component, call `useProjectBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectBudgetQuery({
 *   variables: {
 *      filterType: // value for 'filterType'
 *      filterCategories: // value for 'filterCategories'
 *      filterWithinRadiusKm: // value for 'filterWithinRadiusKm'
 *      filterSearchTerm: // value for 'filterSearchTerm'
 *      filterByCustomFields: // value for 'filterByCustomFields'
 *      filterByCrmOrganisationId: // value for 'filterByCrmOrganisationId'
 *      filterByCrmLinkIds: // value for 'filterByCrmLinkIds'
 *      filterBySourceCrmOrganisationIds: // value for 'filterBySourceCrmOrganisationIds'
 *      filterBySourceUserIds: // value for 'filterBySourceUserIds'
 *      filterBySources: // value for 'filterBySources'
 *      filterIncludeState: // value for 'filterIncludeState'
 *      filterPhaseIds: // value for 'filterPhaseIds'
 *      filterKind: // value for 'filterKind'
 *      filterPlanned: // value for 'filterPlanned'
 *      filterOverdue: // value for 'filterOverdue'
 *      filterUrgent: // value for 'filterUrgent'
 *      filterAssigneeIds: // value for 'filterAssigneeIds'
 *      filterUnassigned: // value for 'filterUnassigned'
 *      filterRelevantToUserIds: // value for 'filterRelevantToUserIds'
 *      filterBuildingZip: // value for 'filterBuildingZip'
 *   },
 * });
 */
export function useProjectBudgetQuery(baseOptions?: Apollo.QueryHookOptions<ProjectBudgetQuery, ProjectBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectBudgetQuery, ProjectBudgetQueryVariables>(ProjectBudgetDocument, options);
      }
export function useProjectBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectBudgetQuery, ProjectBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectBudgetQuery, ProjectBudgetQueryVariables>(ProjectBudgetDocument, options);
        }
export function useProjectBudgetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectBudgetQuery, ProjectBudgetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectBudgetQuery, ProjectBudgetQueryVariables>(ProjectBudgetDocument, options);
        }
export type ProjectBudgetQueryHookResult = ReturnType<typeof useProjectBudgetQuery>;
export type ProjectBudgetLazyQueryHookResult = ReturnType<typeof useProjectBudgetLazyQuery>;
export type ProjectBudgetSuspenseQueryHookResult = ReturnType<typeof useProjectBudgetSuspenseQuery>;
export type ProjectBudgetQueryResult = Apollo.QueryResult<ProjectBudgetQuery, ProjectBudgetQueryVariables>;