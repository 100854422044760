import moment from "moment";
import { WorkSession__EntityFragment } from "../projects/boxes/ProjectOverviewWorkSessionsBox.generated.js";

export const processWorkSessions = (
  workSessions: WorkSession__EntityFragment[],
  isPast: boolean = false
) => {
  const sessions = !isPast
    ? workSessions
    : workSessions.map(s => {
        if (s.from && !s.till)
          return { ...s, till: moment(s.from).endOf("day").format() };
        return s;
      });

  const activeBreakSession = sessions.find(s => s.isBreak && s.from && !s.till);
  const totalBreakDurationMs = sessions
    .filter(s => s.isBreak && ((s.from && s.till) || s.duration))
    .reduce((acc: number, s: WorkSession__EntityFragment) => {
      if (s.isCorrection) {
        acc += (s.duration || 0) * 60 * 1000;
      } else {
        acc += moment(s.till).valueOf() - moment(s.from).valueOf();
      }
      return acc;
    }, 0);

  const activeWorkSession = sessions.find(s => !s.isBreak);
  const activeWorkDurationMs =
    activeWorkSession && activeWorkSession.till
      ? moment(activeWorkSession.till).valueOf() -
        moment(activeWorkSession.from).valueOf()
      : activeWorkSession &&
          !activeWorkSession.till &&
          activeBreakSession &&
          moment(activeWorkSession.from) < moment(activeBreakSession.from)
        ? moment(activeBreakSession.from).valueOf() -
          moment(activeWorkSession.from).valueOf()
        : 0;

  const hasActiveBreakSession = !!activeBreakSession;
  const noActiveWorkSession = !!(
    !activeWorkSession ||
    activeBreakSession ||
    activeWorkSession.till
  );
  const noActiveSession = !!(
    activeWorkSession &&
    activeWorkSession.till &&
    !activeBreakSession
  );

  return {
    activeBreakSession,
    totalBreakDurationMs,
    activeWorkSession,
    activeWorkDurationMs,
    noActiveSession,
    noActiveWorkSession,
    hasActiveBreakSession,
  };
};
