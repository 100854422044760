import { StatusChip, StatusLabel } from "@msys/ui";
import { useTheme } from "@mui/material";
import React, { FC } from "react";
import { InvoiceStatus } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

interface Props {
  small?: boolean;
  status: InvoiceStatus;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}

export const InvoiceStatusBadge: FC<Props> = ({ status, align, small }) => {
  if (status === "paid") {
    return <InvoicePaidBadge align={align} small={small} />;
  }

  if (status === "open") {
    return <InvoiceOpenBadge align={align} small={small} />;
  }

  if (status === "cancelled") {
    return <InvoiceCancelledBadge align={align} small={small} />;
  }
  return <InvoiceDraftBadge align={align} small={small} />;
};

const InvoicePaidBadge = (props: Omit<Props, "status">) => {
  const theme = useTheme();
  const { t } = useTranslate("ProjectOverview");

  return (
    <StatusLabel color={theme.palette.success.main} {...props}>
      {t("Paid")}
    </StatusLabel>
  );
};

const InvoiceOpenBadge = (props: Omit<Props, "status">) => {
  const theme = useTheme();
  const { t } = useTranslate("ProjectOverview");

  return (
    <StatusLabel color={theme.palette.info.light} {...props}>
      {t("Open")}
    </StatusLabel>
  );
};

const InvoiceCancelledBadge = (props: Omit<Props, "status">) => {
  const theme = useTheme();
  const { t } = useTranslate("ProjectOverview");

  return (
    <StatusLabel color={theme.palette.error.main} {...props}>
      {t("Cancelled")}
    </StatusLabel>
  );
};

const InvoiceDraftBadge = (props: Omit<Props, "status">) => {
  const theme = useTheme();
  const { t } = useTranslate("ProjectOverview");

  return (
    <StatusLabel color={theme.palette.grey[600]} {...props}>
      {t("Draft")}
    </StatusLabel>
  );
};

export const InvoiceStatusChip = ({
  status,
  size,
}: {
  status: InvoiceStatus;
  size?: React.ComponentProps<typeof StatusChip>["size"];
}) => {
  const { t } = useTranslate("ProjectOverview");

  if (status === "paid") {
    return <StatusChip label={t("Paid")} color={"success"} size={size} />;
  }

  if (status === "open") {
    return <StatusChip label={t("Open")} color={"info"} size={size} />;
  }

  if (status === "cancelled") {
    return <StatusChip label={t("Cancelled")} color={"error"} size={size} />;
  }

  return <StatusChip label={t("Draft")} color={"default"} size={size} />;
};
