import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EditTodoItemModal } from "../../features/todos/EditTodoItemModal.js";
import { namedOperations } from "../../../clients/graphqlTypes.js";

export function TaskItem() {
  const { taskId } = useParams();
  if (!taskId) throw new Error("Task id is missing");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <EditTodoItemModal
      id={taskId}
      handleClose={() => {
        navigate({
          pathname: "/tasks",
          search: searchParams.toString(),
        });
      }}
      refetchQueries={[namedOperations.Query.TasksList]}
    />
  );
}
