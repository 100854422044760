import { Business as BusinessIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "../../../commons/Avatar.js";
import { ProfileListItem } from "../../../commons/ProfileListItem.js";
import { BuildingRow__BuildingFragment } from "../Buildings.generated.js";
import { useTranslate } from "@tolgee/react";
import { getAddressLabel } from "../../addresses/helpers.js";

interface Props {
  building: BuildingRow__BuildingFragment;
  divider?: boolean;
}

const BuildingRow: FC<Props> = ({ building, divider = true }) => {
  const { t } = useTranslate("BuildingRow");
  return (
    <ProfileListItem
      button
      component={Link}
      divider={divider}
      to={`/buildings/${building.id}`}
      avatar={<Avatar size="m" icon={<BusinessIcon />} type="square" />}
      primary={building.title}
      secondary={
        building.buildingAddress
          ? getAddressLabel(building.buildingAddress)
          : t("Address not set")
      }
    />
  );
};

export default BuildingRow;
