import { useScreenWidth } from "@msys/ui";
import {
  Box,
  Button,
  createTheme,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Stack } from "../../../commons/layout/Stack.js";
import { color, inverseTheme } from "../../../../common/MuiThemeProvider.js";
import { makeStyles } from "../../../styles.js";

// TODO: re-do to `sx` props
const inverseLargeTheme = createTheme(inverseTheme, {
  palette: {
    secondary: {
      main: color.danger,
      light: color.dangerLight,
      dark: color.dangerDark,
      contrastText: color.white,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "10px 16px",
          fontSize: "1rem",
        },
      },
    },
  },
});

const useStyles = makeStyles()(theme => ({
  mainTitle: {
    fontSize: "1.375rem",
    lineHeight: 1.2,
    "& > b, & > strong": {
      color: color.danger,
    },
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
      fontSize: "1.75rem",
      lineHeight: 1.2,
    },
  },
  container: {
    backgroundColor: color.blue1,
    backgroundImage: "url('/auth/auth-3d-bg.svg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      height: 340,
    },
  },
}));

const Organisation3dTemplatesBoxComponent: FC<{
  slug: string;
}> = ({ slug }) => {
  const { isMinTablet } = useScreenWidth();
  const { classes } = useStyles();
  return (
    <Paper className={classes.container}>
      <Stack
        flexGrow={1}
        flexShrink={1}
        flexDirection={isMinTablet ? "row" : "column"}
        alignItems="stretch"
        justifyContent="flex-start"
      >
        <Box
          flexGrow={1}
          flexShrink={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box py={2} px={isMinTablet ? 4 : 2}>
            <Typography variant="h3" className={classes.mainTitle} gutterBottom>
              Find inspiration & plan your dream project in <b>3D</b>
            </Typography>
            <Typography variant="body2">
              Choose your ideal base design, customize to your needs in 3D and
              get a quote in real-time
            </Typography>
            <Box mt={3}>
              <Button
                fullWidth={!isMinTablet}
                component={Link}
                to={`/org/${slug}/3d`}
                color="secondary"
                variant="contained"
              >
                {"3D plan in 5 min"}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          p={2}
          maxWidth={isMinTablet ? 400 : 360}
          alignSelf="center"
          position="relative"
        >
          <img
            alt="Bathroom"
            src="/auth/auth-bath.svg"
            style={{
              minHeight: 0,
              minWidth: 0,
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

export const Organisation3dTemplatesBox: FC<{
  slug: string;
}> = ({ slug }) => {
  return (
    <ThemeProvider theme={inverseLargeTheme}>
      <Organisation3dTemplatesBoxComponent slug={slug} />
    </ThemeProvider>
  );
};
