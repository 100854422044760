import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import { LabeledValue, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Button, IconButton, Link as MuiLink, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link } from "react-router-dom";
import { useTemplateTypeInput_SearchTemplateTypesQuery } from "./TemplateTypeInput.generated.js";
import { TemplateTypesSearchModal } from "./TemplateTypesSearchModal.js";
import { TemplateTypesSearchModal_TemplateTypeFragment } from "./TemplateTypesSearchModal.generated.js";

interface Props {
  templateTypeId: string | null;
  label?: string;
  selectButtonLabel?: string;
  leftIcon?: React.ReactNode;
  rightButton?: React.ReactNode;
  onChange: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment
  ) => Promise<void> | void;
  onClear?: () => Promise<void> | void;
  required?: boolean;
  disabled?: boolean;
  canEdit?: boolean;
}

export const TemplateTypeInput = ({
  templateTypeId,
  label,
  selectButtonLabel,
  leftIcon,
  rightButton,
  required = false,
  disabled = false,
  canEdit = true,
  onChange,
  onClear,
}: Props) => {
  const { t } = useTranslate(["TemplateTypes", "Global"]);
  const client = useApolloClient();
  const query = useTemplateTypeInput_SearchTemplateTypesQuery({
    client,
    variables: { templateTypeIds: [templateTypeId].filter(notNull) },
    skip: !templateTypeId,
  });
  const templateType = templateTypeId
    ? (getDataOrNull(query.data?.searchTemplateTypes)?.edges?.[0]?.node ?? null)
    : null;
  return !templateTypeId ? (
    <Box display="flex">
      <ModalOpenButton
        Modal={TemplateTypesSearchModal}
        modalProps={{
          handleSelectTemplateType: async (templateType, handleClose) => {
            await onChange(templateType);
            handleClose();
          },
        }}
        disabled={disabled}
      >
        <Button
          size="small"
          color="secondary"
          variant="text"
          startIcon={<AddIcon />}
          disabled={disabled}
          sx={{ textWrap: "nowrap" }}
        >
          {selectButtonLabel ?? t("Add template type", { ns: "TemplateTypes" })}
        </Button>
      </ModalOpenButton>
    </Box>
  ) : (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      width="100%"
    >
      <Stack
        direction="row"
        alignItems="center"
        minWidth={0}
        spacing={1}
        flex={1}
      >
        <LabeledValue
          label={
            label ??
            t("Template type", { ns: "TemplateTypes" }) + (required ? " *" : "")
          }
        >
          <MuiLink component={Link} to={`/templates/types/${templateTypeId}`}>
            {templateType?.title ?? ""}
          </MuiLink>
        </LabeledValue>
        {leftIcon}
      </Stack>
      {rightButton ? (
        <Box display="flex">{rightButton}</Box>
      ) : (
        canEdit &&
        templateTypeId && (
          <ModalOpenButton
            Modal={TemplateTypesSearchModal}
            modalProps={{
              handleSelectTemplateType: async (templateType, handleClose) => {
                await onChange(templateType);
                handleClose();
              },
              handleClearTemplateType: onClear
                ? async handleClose => {
                    await onClear();
                    handleClose();
                  }
                : undefined,
            }}
            disabled={disabled}
          >
            <IconButton size="small" color="primary" disabled={disabled}>
              <EditIcon />
            </IconButton>
          </ModalOpenButton>
        )
      )}
    </Stack>
  );
};
