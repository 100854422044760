import { gql, useApolloClient } from "@apollo/client";
import { useTranslate } from "@tolgee/react";
import { omit } from "lodash-es";
import { useSnackbar } from "notistack";
import { useUserData } from "../../auth/useUserData.js";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageGrid } from "../../commons/layout/PageGrid.js";
import { Stack } from "../../commons/layout/Stack.js";
import {
  OrganisationBrandingSlotBox,
  SlotFormValues,
} from "../../features/organisations/boxes/OrganisationBrandingSlotBox.js";
import {
  OrganisationBrandingThemeBox,
  ThemeFormValues,
} from "../../features/organisations/boxes/OrganisationBrandingThemeBox.js";
import {
  useOrganisationBrandingAd_ModifyOrganisationSettingsMutation,
  useOrganisationBrandingLogo_ModifyOrganisationSettingsMutation,
  useOrganisationBrandingQuery,
  useOrganisationBrandingTheme_ModifyOrganisationSettingsMutation,
} from "./OrganisationBranding.generated.js";

export type BrandingSection = "own" | "referral" | "sponsoring";

type ThemeFieldName =
  | "brandingThemeOwnSidebar"
  | "brandingThemeOwnTopbar"
  | "brandingThemeReferralSidebar"
  | "brandingThemeReferralTopbar"
  | "brandingThemeSponsoringSidebar"
  | "brandingThemeSponsoringTopbar";

type LogoFieldName =
  | "brandingLogoOwnSidebarTop"
  | "brandingLogoOwnSidebarBottom"
  | "brandingLogoOwnTopbarLeft"
  | "brandingLogoOwnTopbarRight"
  | "brandingLogoReferralSidebarTop"
  | "brandingLogoReferralSidebarBottom"
  | "brandingLogoReferralTopbarLeft"
  | "brandingLogoReferralTopbarRight"
  | "brandingLogoSponsoringSidebarTop"
  | "brandingLogoSponsoringSidebarBottom"
  | "brandingLogoSponsoringTopbarLeft"
  | "brandingLogoSponsoringTopbarRight";

type AdFieldName =
  | "brandingAdOwnDashboardLeftTop"
  | "brandingAdOwnDashboardRightTop"
  | "brandingAdOwnDashboardSideTop"
  | "brandingAdReferralDashboardLeftTop"
  | "brandingAdReferralDashboardRightTop"
  | "brandingAdReferralDashboardSideTop"
  | "brandingAdSponsoringDashboardLeftTop"
  | "brandingAdSponsoringDashboardRightTop"
  | "brandingAdSponsoringDashboardSideTop";

const THEME_FIELD_NAMES: Record<
  BrandingSection,
  { sidebar: ThemeFieldName; topbar: ThemeFieldName }
> = {
  own: {
    sidebar: "brandingThemeOwnSidebar",
    topbar: "brandingThemeOwnTopbar",
  },
  referral: {
    sidebar: "brandingThemeReferralSidebar",
    topbar: "brandingThemeReferralTopbar",
  },
  sponsoring: {
    sidebar: "brandingThemeSponsoringSidebar",
    topbar: "brandingThemeSponsoringTopbar",
  },
};

const LOGO_FIELD_NAMES: Record<
  BrandingSection,
  {
    sidebarTop: LogoFieldName;
    sidebarBottom: LogoFieldName;
    topbarLeft: LogoFieldName;
    topbarRight: LogoFieldName;
  }
> = {
  own: {
    sidebarTop: "brandingLogoOwnSidebarTop",
    sidebarBottom: "brandingLogoOwnSidebarBottom",
    topbarLeft: "brandingLogoOwnTopbarLeft",
    topbarRight: "brandingLogoOwnTopbarRight",
  },
  referral: {
    sidebarTop: "brandingLogoReferralSidebarTop",
    sidebarBottom: "brandingLogoReferralSidebarBottom",
    topbarLeft: "brandingLogoReferralTopbarLeft",
    topbarRight: "brandingLogoReferralTopbarRight",
  },
  sponsoring: {
    sidebarTop: "brandingLogoSponsoringSidebarTop",
    sidebarBottom: "brandingLogoSponsoringSidebarBottom",
    topbarLeft: "brandingLogoSponsoringTopbarLeft",
    topbarRight: "brandingLogoSponsoringTopbarRight",
  },
};

const AD_FIELD_NAMES: Record<
  BrandingSection,
  {
    dashboardLeftTop: AdFieldName;
    dashboardRightTop: AdFieldName;
    dashboardSideTop: AdFieldName;
  }
> = {
  own: {
    dashboardLeftTop: "brandingAdOwnDashboardLeftTop",
    dashboardRightTop: "brandingAdOwnDashboardRightTop",
    dashboardSideTop: "brandingAdOwnDashboardSideTop",
  },
  referral: {
    dashboardLeftTop: "brandingAdReferralDashboardLeftTop",
    dashboardRightTop: "brandingAdReferralDashboardRightTop",
    dashboardSideTop: "brandingAdReferralDashboardSideTop",
  },
  sponsoring: {
    dashboardLeftTop: "brandingAdSponsoringDashboardLeftTop",
    dashboardRightTop: "brandingAdSponsoringDashboardRightTop",
    dashboardSideTop: "brandingAdSponsoringDashboardSideTop",
  },
};

interface Props {
  submenuItems: PageTopbarItem[];
  section: BrandingSection;
}

export const OrganisationBranding = ({ submenuItems, section }: Props) => {
  const viewer = useUserData().currentUser!;

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate([
    "OrganisationProfile",
    "OrganisationBranding",
    "OrganisationPageTopbar",
  ]);

  const client = useApolloClient();
  const query = useOrganisationBrandingQuery({
    client,
    fetchPolicy: "cache-and-network",
  });

  const [updateTheme] =
    useOrganisationBrandingTheme_ModifyOrganisationSettingsMutation({
      client,
    });
  const [updateLogo] =
    useOrganisationBrandingLogo_ModifyOrganisationSettingsMutation({
      client,
    });
  const [updateAd] =
    useOrganisationBrandingAd_ModifyOrganisationSettingsMutation({
      client,
    });

  const organisationSettings = query.data?.organisationSettings;

  const getThemeSubmitHandler = (field: ThemeFieldName) => {
    return async (values: ThemeFormValues) => {
      try {
        await updateTheme({
          variables: {
            input: {
              [field]: {
                enabled: values.enabled,
                type: values.type,
                colorBackground: values.colorBackground,
                colorText: values.colorText,
              },
            },
          },
        });
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    };
  };

  const getLogoSubmitHandler = (field: LogoFieldName) => {
    return async (values: SlotFormValues) => {
      try {
        await updateLogo({
          variables: {
            input: {
              [field]: {
                enabled: values.enabled,
                attachment: values.attachment
                  ? omit(values.attachment, "id", "__typename", "__type")
                  : null,
                title: values.title,
                url: values.url,
              },
            },
          },
        });
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    };
  };

  const getAdSubmitHandler = (field: AdFieldName) => {
    return async (values: SlotFormValues) => {
      try {
        await updateAd({
          variables: {
            input: {
              [field]: {
                enabled: values.enabled,
                attachment: values.attachment
                  ? omit(values.attachment, "id", "__typename", "__type")
                  : null,
                title: values.title,
                url: values.url,
              },
            },
          },
        });
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    };
  };

  const { sidebar, topbar } = THEME_FIELD_NAMES[section];
  const { sidebarTop, sidebarBottom, topbarLeft, topbarRight } =
    LOGO_FIELD_NAMES[section];
  const { dashboardLeftTop, dashboardRightTop, dashboardSideTop } =
    AD_FIELD_NAMES[section];

  const pageTitle: Record<BrandingSection, string> = {
    own: t("Own", { ns: "OrganisationBranding" }),
    referral: t("Referral", { ns: "OrganisationBranding" }),
    sponsoring: t("Sponsoring", { ns: "OrganisationBranding" }),
  };

  return (
    <Page
      title={pageTitle[section]}
      subtitle={t("Branding", { ns: "OrganisationPageTopbar" })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <Stack flexDirection="column">
          <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
            <>
              <OrganisationBrandingThemeBox
                title={t("Topbar colors", {
                  ns: "OrganisationBranding",
                })}
                defaultType={
                  viewer.organisation.isCraftsmanOrganisation ? "dark" : "light"
                }
                theme={organisationSettings?.[topbar] ?? null}
                onSubmit={getThemeSubmitHandler(topbar)}
              />
              <OrganisationBrandingThemeBox
                title={t("Sidebar colors", {
                  ns: "OrganisationBranding",
                })}
                defaultType={"light"}
                theme={organisationSettings?.[sidebar] ?? null}
                onSubmit={getThemeSubmitHandler(sidebar)}
              />
            </>
            <>
              <OrganisationBrandingSlotBox
                title={t("Topbar left logo", {
                  ns: "OrganisationBranding",
                })}
                slot={organisationSettings?.[topbarLeft] ?? null}
                onSubmit={getLogoSubmitHandler(topbarLeft)}
                hideUrl
              />
              <OrganisationBrandingSlotBox
                title={t("Topbar right logo", {
                  ns: "OrganisationBranding",
                })}
                slot={organisationSettings?.[topbarRight] ?? null}
                onSubmit={getLogoSubmitHandler(topbarRight)}
                hideUrl
              />
            </>
            <>
              <OrganisationBrandingSlotBox
                title={t("Sidebar top logo", {
                  ns: "OrganisationBranding",
                })}
                slot={organisationSettings?.[sidebarTop] ?? null}
                onSubmit={getLogoSubmitHandler(sidebarTop)}
                hideUrl
              />
              <OrganisationBrandingSlotBox
                title={t("Sidebar bottom logo", {
                  ns: "OrganisationBranding",
                })}
                slot={organisationSettings?.[sidebarBottom] ?? null}
                onSubmit={getLogoSubmitHandler(sidebarBottom)}
                hideUrl
              />
            </>
            <>
              <OrganisationBrandingSlotBox
                title={t("Dashboard side top ad", {
                  ns: "OrganisationBranding",
                })}
                slot={organisationSettings?.[dashboardSideTop] ?? null}
                onSubmit={getAdSubmitHandler(dashboardSideTop)}
                hideTitle
              />
              <OrganisationBrandingSlotBox
                title={t("Dashboard left top ad", {
                  ns: "OrganisationBranding",
                })}
                slot={organisationSettings?.[dashboardLeftTop] ?? null}
                onSubmit={getAdSubmitHandler(dashboardLeftTop)}
                hideTitle
              />
              <OrganisationBrandingSlotBox
                title={t("Dashboard right top ad", {
                  ns: "OrganisationBranding",
                })}
                slot={organisationSettings?.[dashboardRightTop] ?? null}
                onSubmit={getAdSubmitHandler(dashboardRightTop)}
                hideTitle
              />
            </>
          </PageGrid>
        </Stack>
      </PageContainer>
    </Page>
  );
};
