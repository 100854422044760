import { CardContainer } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import * as Yup from "yup";
import { useMemo } from "react";

export interface FormValues {
  priceUnit: string | null;
  priceUnitAmount: number;
}

export function useValidationSchema() {
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      priceUnit: Yup.string().nullable(),
      priceUnitAmount: Yup.number().required().moreThan(0),
    });
  }, []);

  return validationSchema;
}

export function ProductEditInstallationBox() {
  const { t } = useTranslate("ProductOverview");

  return (
    <CardContainer
      title={t("Installation and Ordering")}
      isExpandable
      isInitiallyClosed={false}
    >
      <Stack p={1} flexDirection="column">
        <Stack flexDirection="row">
          <FormattedFloatField
            disabled={false}
            label={t("Price Unit Amount", { ns: "ProductOverview" })}
            name={`priceUnitAmount`}
            type="float"
            min={0}
          />
          <Field
            component={TextField}
            label={t("Price Unit", { ns: "ProductOverview" })}
            name="priceUnit"
          />
        </Stack>
      </Stack>
    </CardContainer>
  );
}
