import { FormattedPrice } from "@msys/ui";
import React from "react";
import {
  Agreement,
  InvoiceType,
  PermissionName,
} from "../../../clients/graphqlTypes.js";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { InvoiceQuoteFragment } from "./Invoices.generated.js";
import { BareTreeItem } from "../../trees/BareTreeItem.js";
import { TreeDataContainer } from "../../trees/components/TreeDataContainer.js";
import { ItemComponentProps } from "../../trees/types.js";
import { assertNever } from "../../utils.js";
import { InvoiceSelectButton } from "./buttons/InvoiceSelectButton.js";
import { InvoiceFormItem } from "./types.js";

export const createTreeItem =
  (
    projectId: string,
    invoiceId: string,
    invoiceType: InvoiceType,
    docId: string,
    docAgreement: Agreement,
    project: { viewerPermissions: PermissionName[] },
    quote: InvoiceQuoteFragment,
    invoiceableQuoteItemIds: string[],
    invoiceableInInvoiceQuoteItemIds: string[],
    navigateToItem: (id: string) => void
  ) =>
  (itemProps: ItemComponentProps<InvoiceFormItem>) => {
    const [Data123Left, Data123Right] = React.useMemo((): [
      JSX.Element | null,
      JSX.Element | null,
    ] => {
      switch (itemProps.item.type) {
        case "paid": {
          const calculationInvoice = itemProps.item.calculationInvoice;
          if (!calculationInvoice) return [null, null];

          return [
            <TreeDataContainer key={"left"}>
              <span>{calculationInvoice.quantity}x</span>
              {calculationInvoice.quantity !== 1 && (
                <span>
                  <FormattedPrice value={calculationInvoice.pricePerUnit} />
                </span>
              )}
              <span style={{ fontWeight: "bold" }}>
                <FormattedPrice value={calculationInvoice.priceSubTotal} />
              </span>
            </TreeDataContainer>,
            null,
          ];
        }
        case "section": {
          const calculationInvoice = itemProps.item.calculationInvoice;
          if (!calculationInvoice) return [null, null];

          if (
            calculationInvoice.pricePerUnit === 0 &&
            calculationInvoice.quantity === 1
          ) {
            return [null, null];
          }

          return [
            null,
            <TreeDataContainer key={"right"}>
              {calculationInvoice.quantity !== 1 && (
                <>
                  <span>{calculationInvoice.quantity}x</span>
                  <span>
                    <FormattedPrice value={calculationInvoice.pricePerUnit} />
                  </span>
                </>
              )}
              <span style={{ fontWeight: "bold" }}>
                <FormattedPrice value={calculationInvoice.priceSubTotal} />
              </span>
            </TreeDataContainer>,
          ];
        }
        case "unpaid":
        case "defect":
        case "decision": {
          return [null, null];
        }
        default:
          throw assertNever(itemProps.item.type);
      }
    }, [itemProps.item]);

    const IconRightButtons = React.useMemo(
      () =>
        invoiceType === "partial_invoice" || invoiceType === "final_invoice" ? (
          <RestrictedByProjectPermissionWithDebug
            project={project}
            permission="MANAGE_INVOICES"
          >
            <InvoiceSelectButton
              projectId={projectId}
              docId={docId}
              itemId={itemProps.item.id}
              invoiceId={invoiceId}
              doc={quote}
              disabled={invoiceType === "final_invoice"}
              invoiceableQuoteItemIds={invoiceableQuoteItemIds}
              invoiceableInInvoiceQuoteItemIds={
                invoiceableInInvoiceQuoteItemIds
              }
            />
          </RestrictedByProjectPermissionWithDebug>
        ) : null,
      [itemProps.item.id]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        collapseButton={itemProps.collapseButton}
        subcontractPath={null}
        onClick={() => navigateToItem(itemProps.item.id)}
      />
    );
  };
