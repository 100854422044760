import { MutationFunctionOptions } from "@apollo/client";
import { FormattedPrice, FormattedTime } from "@msys/ui";
import React from "react";
import {
  Agreement,
  Exact,
  PermissionName,
  SetUnnestedTemplateItemDecisionContingentItemPreselectionInput,
  SetUnnestedTemplateItemDecisionSubitemsPreselectionInput,
} from "../../../../clients/graphqlTypes.js";
import { ConfirmModalProps } from "../../../commons/modals/ConfirmProcess.js";
import { QUOTE_TEMPLATE_ITEM_TYPES } from "../../../constants.js";
import { isItemVisibleToOtherSide } from "../../../trees-virtual/helpers.js";
import {
  AdditionalItemInput,
  VirtualItem,
} from "../../../trees-virtual/types.js";
import { BareTreeItem } from "../../../trees/BareTreeItem.js";
import { TreeDataContainer } from "../../../trees/components/TreeDataContainer.js";
import { TreeRightIcons } from "../../../trees/components/TreeRightIcons.js";
import {
  InputComponentProps,
  ItemComponentProps,
} from "../../../trees/types.js";
import { assertNever, buildDocPath } from "../../../utils.js";
import { CreateItemInput } from "../../doc-items/CreateItemInput.js";
import { DecisionContingencyCheckbox } from "../../doc-items/fields/DecisionContingencyCheckbox.js";
import { DecisionOptionRadioOrCheckbox } from "../../doc-items/fields/DecisionOptionRadioOrCheckbox.js";
import { UnnestedTemplateContingencyCheckbox } from "../../doc-items/fields/UnnestedTemplateContingencyCheckbox.js";
import { UnnestedTemplateDecisionOptionCheckboxOrRadio } from "../../doc-items/fields/UnnestedTemplateDecisionOptionCheckboxOrRadio.js";
import { DocItemContextMenuItems } from "../../doc-items/menus/DocItemContextMenuItems.js";
import { TemplateLinkIconButton } from "../buttons/TemplateLinkIconButton.js";
import {
  CreateQuoteTemplateProcess,
  CreateQuoteTemplateProcessRef,
} from "../CreateQuoteTemplateModal.js";
import {
  SetUnnestedTemplateDecisionPreselectionMutation,
  SetUnnestedTemplateItemDecisionContingentItemPreselectionMutation,
} from "./TemplateQuotes.generated.js";
import {
  QuoteTemplateTreeItem_Data123Fragment,
  QuoteTemplateTreeItem_PublicFlowTreeItemFragment,
  TemplateTreeItem_TreeItemFragment,
  TemplateTreeItem_UnnestedTreeItemFragment,
} from "./trees.generated.js";

export function createTreeItemInput<
  Item extends VirtualItem,
  ChildItem extends VirtualItem,
>({
  docId,
  isDraft,
  createQuoteTemplateRef,
  createItem,
  createItemByType,
  createItemFromTemplate,
  createItemsFromTemplates,
  createItemsWithProducts,
  createItemWithLinkedTemplate,
  pasteItem,
  isAllowedToPasteItem,
}: {
  docId: string;
  isDraft: boolean;
  createQuoteTemplateRef: React.RefObject<CreateQuoteTemplateProcessRef>;
} & Pick<
  React.ComponentProps<typeof CreateItemInput>,
  | "createItem"
  | "createItemByType"
  | "createItemFromTemplate"
  | "createItemsFromTemplates"
  | "createItemsWithProducts"
  | "createItemWithLinkedTemplate"
  | "pasteItem"
  | "isAllowedToPasteItem"
>) {
  return (itemProps: InputComponentProps<Item, ChildItem>) => {
    const areAnyTemplatesAllowed = ["section", "decision"].includes(
      itemProps.item.type
    );

    return (
      <>
        <CreateItemInput
          doc={{ id: docId, docType: "TEMPLATE", templateIsDraft: isDraft }}
          documentItemTypes={QUOTE_TEMPLATE_ITEM_TYPES}
          createItem={createItem}
          createItemByType={createItemByType}
          createItemFromTemplate={createItemFromTemplate}
          createItemsFromTemplates={createItemsFromTemplates}
          createItemsWithProducts={createItemsWithProducts}
          createItemWithLinkedTemplate={createItemWithLinkedTemplate}
          pasteItem={pasteItem}
          isAllowedToPasteItem={isAllowedToPasteItem}
          {...itemProps}
        />
        {areAnyTemplatesAllowed && (
          <CreateQuoteTemplateProcess
            ref={createQuoteTemplateRef}
            title={undefined}
          />
        )}
      </>
    );
  };
}

export const createTreeItem =
  <T extends TemplateTreeItem_TreeItemFragment>({
    pathToDocList,
    pathToDocPage,
    projectId,
    document,
    docId,
    docAgreement,
    startConfirmProcess,
    canEdit,
    navigateToItem,
    expandedItemIds,
    setItemExpanded,
    pasteItem,
    isAllowedToPasteItem,
    onAdditionalItemInputChange,
  }: {
    pathToDocList: string;
    pathToDocPage: string;
    projectId: string | null;
    document: {
      viewerPermissions: PermissionName[];
    };
    docId: string;
    docAgreement: Agreement;
    startConfirmProcess: (
      props: ConfirmModalProps
    ) => Promise<boolean | undefined>;
    canEdit: boolean;
    navigateToItem: (id: string) => void;
    expandedItemIds: string[] | undefined;
    setItemExpanded: (id: string, expanded: boolean) => void;
    pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
    isAllowedToPasteItem: (parentItem: T) => boolean;
    onAdditionalItemInputChange: ((input: AdditionalItemInput) => void) | null;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item),
      [itemProps.item]
    );

    const IconRightButtons = React.useMemo(
      () => (
        <>
          {"linkedQuoteTemplate" in itemProps.item &&
            itemProps.item.linkedQuoteTemplate && (
              <TemplateLinkIconButton
                pathToTemplateDoc={buildDocPath(
                  `${pathToDocList}/${itemProps.item.linkedQuoteTemplate.id}`,
                  itemProps.item.linkedQuoteTemplateAtVersionNumber ?? null
                )}
                iconButtonProps={{ size: "extra-small" }}
                svgIconProps={{ fontSize: "small" }}
              />
            )}
          {itemProps.item.decisionIsContingentItem && (
            <DecisionContingencyCheckbox
              docId={docId}
              projectId={projectId}
              itemId={itemProps.item.id}
              item={itemProps.item}
              disabled={!canEdit}
            />
          )}
          {itemProps.parentItem?.type === "decision" &&
            !itemProps.item.decisionOptionElimination && (
              <DecisionOptionRadioOrCheckbox
                docId={docId}
                projectId={projectId}
                item={itemProps.item}
                disabled={!canEdit}
                expandedItemIds={expandedItemIds}
              />
            )}
        </>
      ),
      [itemProps.item, itemProps.parentItem]
    );

    const ContextMenu = React.useMemo(
      () =>
        canEdit ? (
          <DocItemContextMenuItems
            pathToDocPage={pathToDocPage}
            docId={docId}
            docType="TEMPLATE"
            projectId={projectId}
            item={itemProps.item}
            itemParentType={itemProps.parentItem?.type ?? null}
            startConfirmProcess={startConfirmProcess}
            expandedItemIds={expandedItemIds}
            pasteItem={isAllowedToPasteItem(itemProps.item) ? pasteItem : null}
            onAdditionalItemInputChange={onAdditionalItemInputChange}
          />
        ) : null,
      [itemProps.item, itemProps.parentItem?.type]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={document}
          item={itemProps.item}
          isVisibleToOtherSide={isItemVisibleToOtherSide(
            itemProps.item,
            itemProps.allItems
          )}
        />
      ),
      [itemProps.item, itemProps.allItems]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        ContextMenu={ContextMenu}
        RightIcons={RightIcons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        collapseButton={itemProps.collapseButton}
        subcontractPath={null}
        onClick={() => {
          setItemExpanded(itemProps.item.id, true);
        }}
        to={`${pathToDocPage}/items/${itemProps.item.id}`}
      />
    );
  };

export const createUnnestedTemplateTreeItem =
  <T extends TemplateTreeItem_UnnestedTreeItemFragment>(
    pathToDocPage: string,
    pathToDocList: string,
    document: {
      viewerPermissions: PermissionName[];
    },
    docId: string,
    navigateToItem: (id: string) => void,
    setItemExpanded: (id: string, expanded: boolean) => void
  ) =>
  (itemProps: ItemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item),
      [itemProps.item]
    );

    const IconRightButtons = React.useMemo(
      () => (
        <>
          {itemProps.item.decisionIsContingentItem && (
            <UnnestedTemplateContingencyCheckbox
              docId={docId}
              itemId={itemProps.item.id}
              item={itemProps.item}
            />
          )}
          {itemProps.parentItem?.type === "decision" && (
            <UnnestedTemplateDecisionOptionCheckboxOrRadio
              docId={docId}
              itemId={itemProps.item.id}
              item={itemProps.item}
              parentItem={itemProps.parentItem}
            />
          )}
        </>
      ),
      [itemProps.item, itemProps.parentItem]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={document}
          item={itemProps.item}
          isVisibleToOtherSide={isItemVisibleToOtherSide(
            itemProps.item,
            itemProps.allItems
          )}
        />
      ),
      [itemProps.item, itemProps.allItems]
    );

    return (
      <BareTreeItem
        docAgreement={"NONE"}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        RightIcons={RightIcons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        collapseButton={itemProps.collapseButton}
        subcontractPath={null}
        onClick={() => {
          setItemExpanded(itemProps.item.id, true);
        }}
        to={`${pathToDocPage}/items/${itemProps.item.id}`}
      />
    );
  };

export const createThreeDPublicFlowTreeItem =
  <T extends QuoteTemplateTreeItem_PublicFlowTreeItemFragment>(
    pathToDocPage: string,
    docId: string,
    modifyItemContingencyPreselection: (
      docId: string,
      itemId: string,
      decisionContingentItemPreselection: boolean | null
    ) => void,
    modifyItemDecisionPreselection: (
      docId: string,
      itemId: string,
      decisionSubitemsPreselection: string[]
    ) => void
  ) =>
  (itemProps: ItemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item),
      [itemProps.item]
    );

    const modifyItemContingencyPreselectionHandler = React.useCallback(
      async (
        options?:
          | MutationFunctionOptions<
              SetUnnestedTemplateItemDecisionContingentItemPreselectionMutation,
              Exact<{
                input: SetUnnestedTemplateItemDecisionContingentItemPreselectionInput;
              }>
            >
          | undefined
      ) => {
        const docId = options?.variables?.input?.templateDocId;
        const itemId = options?.variables?.input?.unnestedItemId;
        const decisionContingentItemPreselection =
          options?.variables?.input?.decisionContingentItemPreselection;
        if (docId && itemId && decisionContingentItemPreselection) {
          modifyItemContingencyPreselection(
            docId,
            itemId,
            decisionContingentItemPreselection
          );
        }

        return undefined as any;
      },
      []
    );

    const modifyItemDecisionPreselectionHandler = React.useCallback(
      async (
        options?:
          | MutationFunctionOptions<
              SetUnnestedTemplateDecisionPreselectionMutation,
              Exact<{
                input: SetUnnestedTemplateItemDecisionSubitemsPreselectionInput;
              }>
            >
          | undefined
      ) => {
        const docId = options?.variables?.input?.templateDocId;
        const itemId = options?.variables?.input?.unnestedItemId;
        const decisionSubitemsPreselection =
          options?.variables?.input?.decisionSubitemsPreselection;
        if (docId && itemId && decisionSubitemsPreselection) {
          modifyItemDecisionPreselection(
            docId,
            itemId,
            decisionSubitemsPreselection
          );
        }

        return undefined as any;
      },
      []
    );

    const IconRightButtons = React.useMemo(
      () => (
        <>
          {itemProps.item.decisionIsContingentItem && (
            <UnnestedTemplateContingencyCheckbox
              docId={docId}
              itemId={itemProps.item.id}
              item={itemProps.item}
              modifyItemContingencyPreselection={
                modifyItemContingencyPreselectionHandler
              }
            />
          )}
          {itemProps.parentItem?.type === "decision" && (
            <UnnestedTemplateDecisionOptionCheckboxOrRadio
              docId={docId}
              itemId={itemProps.item.id}
              item={itemProps.item}
              parentItem={itemProps.parentItem}
              modifyItemDecisionPreselection={
                modifyItemDecisionPreselectionHandler
              }
            />
          )}
        </>
      ),
      [
        itemProps.item,
        itemProps.parentItem,
        modifyItemContingencyPreselectionHandler,
        modifyItemDecisionPreselectionHandler,
      ]
    );

    return (
      <BareTreeItem
        docAgreement={"NONE"}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        RightIcons={null}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        collapseButton={itemProps.collapseButton}
        subcontractPath={null}
      />
    );
  };

export const getData123 = (
  item: QuoteTemplateTreeItem_Data123Fragment
): [JSX.Element | null, JSX.Element | null] => {
  switch (item.type) {
    case "paid": {
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      return [
        <TreeDataContainer key={1}>
          <div>{calculateForQuote.quantity}x</div>
          {calculateForQuote.timePerUnit > 0 && (
            <div>
              <FormattedTime value={calculateForQuote.timePerUnit} />
            </div>
          )}
          {calculateForQuote.quantity !== 1 && (
            <div>
              <FormattedPrice value={calculateForQuote.pricePerUnit} />
            </div>
          )}
          <div style={{ fontWeight: "bold" }}>
            <FormattedPrice value={calculateForQuote.priceSubTotal} />
          </div>
        </TreeDataContainer>,
        null,
      ];
    }
    case "section": {
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      if (
        calculateForQuote.timePerUnit === 0 &&
        calculateForQuote.pricePerUnit === 0 &&
        calculateForQuote.quantity === 1
      ) {
        return [null, null];
      }
      return [
        null,
        <TreeDataContainer key={2}>
          {calculateForQuote.quantity !== 1 && (
            <>
              <div>{calculateForQuote.quantity}x</div>
              {calculateForQuote.timePerUnit !== 0 && (
                <div>
                  <FormattedTime value={calculateForQuote.timePerUnit} />
                </div>
              )}
              {calculateForQuote.pricePerUnit !== 0 && (
                <div>
                  <FormattedPrice value={calculateForQuote.pricePerUnit} />
                </div>
              )}
            </>
          )}
          {calculateForQuote.timeTotal !== 0 && (
            <div style={{ fontWeight: "bold" }}>
              <FormattedTime value={calculateForQuote.timeTotal} />
            </div>
          )}
          {calculateForQuote.priceSubTotal !== 0 && (
            <div style={{ fontWeight: "bold" }}>
              <FormattedPrice value={calculateForQuote.priceSubTotal} />
            </div>
          )}
        </TreeDataContainer>,
      ];
    }
    case "decision": {
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      if (
        calculateForQuote.timePerUnit === 0 &&
        calculateForQuote.pricePerUnit === 0 &&
        calculateForQuote.quantity === 1
      ) {
        return [null, null];
      }
      return [
        null,
        <TreeDataContainer key={2}>
          {calculateForQuote.quantity !== 1 && (
            <>
              <div>{calculateForQuote.quantity}x</div>
              {calculateForQuote.timePerUnit !== 0 && (
                <div>
                  <FormattedTime value={calculateForQuote.timePerUnit} />
                </div>
              )}
              {calculateForQuote.pricePerUnit !== 0 && (
                <div>
                  <FormattedPrice value={calculateForQuote.pricePerUnit} />
                </div>
              )}
            </>
          )}
          {calculateForQuote.timeTotal !== 0 && (
            <div style={{ fontWeight: "bold" }}>
              <FormattedTime value={calculateForQuote.timeTotal} />
            </div>
          )}
          {calculateForQuote.priceSubTotal !== 0 && (
            <div style={{ fontWeight: "bold" }}>
              <FormattedPrice value={calculateForQuote.priceSubTotal} />
            </div>
          )}
        </TreeDataContainer>,
      ];
    }
    case "unpaid":
    case "defect": {
      return [null, null];
    }
    default:
      throw assertNever(item.type);
  }
};
