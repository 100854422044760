import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import {
  CardContainer,
  CollapseSection,
  LabeledValue,
  LabeledValueWithButton,
  MenuButton,
  ModalOpenButton,
  Select,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Functions as FunctionsIcon } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, useFormik } from "formik";
import { differenceWith } from "lodash-es";
import React from "react";
import {
  DefineItemTemplateSearchFilterDefinitionsInput,
  EntitySearchArrayOfFilterOperator,
  EntitySearchBoolFilterOperator,
  EntitySearchBoolInFilterOperator,
  EntitySearchNumberBetweenFilterOperator,
  EntitySearchNumberFilterOperator,
  EntitySearchNumberInFilterOperator,
  EntitySearchTextFilterOperator,
  EntitySearchTextInFilterOperator,
} from "../../../../clients/graphqlTypes.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import {
  ViewMode,
  ViewModeMenuItem,
} from "../../../commons/ViewModeMenuItem.js";
import {
  ArrayFilterOperatorOptions,
  BoolFilterOperatorOptions,
  NumberFilterOperatorOptions,
  TextFilterOperatorOptions,
} from "../../products/ProductSearchFilterOperatorOptions.js";
import {
  templateFiltersDefaultValue,
  TemplateFiltersFormValues,
  templateFiltersValidationSchema,
} from "../../templates/filters/TemplateFilterFields.js";
import { TemplateFilterFields_AvailableFiltersQueryVariables } from "../../templates/filters/TemplateFilterFields.generated.js";
import { TemplateTypeSearchFilterModal } from "../../templates/filters/TemplateTypeSearchFilterModal.js";
import {
  ItemTemplateSearchFilterPropertyFilterComputedFragment,
  ItemTemplateSearchFilterPropertyFilterFragment,
} from "../../templates/templateSearchFilters.generated.js";
import { TemplateTypeAtVersionWarningButton } from "../../templateTypes/TemplateTypeAtVersionWarningButton.js";
import { TemplateTypeInput } from "../../templateTypes/TemplateTypeInput.js";
import { TemplateTypesSearchModal } from "../../templateTypes/TemplateTypesSearchModal.js";
import { TemplateTypesSearchModal_TemplateTypeFragment } from "../../templateTypes/TemplateTypesSearchModal.generated.js";
import { ProductSearchFilterExpressionModal } from "../modals/ProductSearchFilterExpressionModal.js";
import { getEnhancedPropertyLabel } from "../properties.js";
import {
  QuestionControlSection,
  QuestionControlSectionFormValues,
} from "../QuestionControlSection.js";
import { adjustTemplateSearchFilterPropertyFilterExpressionsToTemplateTypeProps2 } from "../templateSearchFilterExpressionUtils.js";
import {
  templateSearchFilterPropertyFilterExpression2Input,
  useTemplateSearchFiltersMutations,
} from "../useTemplateSearchFiltersMutations.js";
import { useTemplateSearchSortingsMutations } from "../useTemplateSearchSortingsMutations.js";
import {
  TemplateSearchFilterDefinitionsFragment,
  TemplateTypePlaceholderBox_ItemFragment,
  TemplateTypePlaceholderBox_QuoteFragment,
  TemplateTypePlaceholderBox_QuoteTemplateFragment,
  useSetItemPlaceholderForTemplateTypeMutation,
  useTemplateTypePlacerholderQuestionControlForm_ModifyWizardSettingsMutation,
} from "./TemplateTypePlaceholderBox.generated.js";

interface Props {
  projectId: string | null;
  doc:
    | TemplateTypePlaceholderBox_QuoteTemplateFragment
    | TemplateTypePlaceholderBox_QuoteFragment;
  item: TemplateTypePlaceholderBox_ItemFragment;
  onUpdateDataRefetchQueries?: string[];
  isEditable?: boolean;
}

export const TemplateTypePlaceholderBox = ({
  projectId,
  doc,
  item,
  onUpdateDataRefetchQueries,
  isEditable = false,
}: Props) => {
  const { t } = useTranslate([
    "QuoteItem",
    "ItemPropertyField",
    "Global",
    "TemplateTypes",
  ]);

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const client = useApolloClient();

  const [setPlaceholderForTemplateType, { loading: setTypeLoading }] =
    useSetItemPlaceholderForTemplateTypeMutation({ client });

  const { propertyFilters: propertySearchFilterDefinitions } =
    item.templateSearchFilterDefinitions;
  const { propertyFilters: propertySearchFiltersExpressions } =
    item.templateSearchFilterExpressions;

  const searchSortingDefinitions = item.templateSearchSortingDefinitions;

  const {
    defineItemTemplateSearchFilterDefinitions,
    handleDeleteTemplateSearchFilterPropertyDefinition,
    handleSetTemplateSearchFilterPropertyExpression,
    handleDeleteTemplateSearchFilterPropertyExpression,
    defineItemTemplateSearchFilterExpressions,
  } = useTemplateSearchFiltersMutations({
    projectId: projectId,
    docId: doc.id,
    itemId: item.id,
    propertySearchFiltersDefinitions: propertySearchFilterDefinitions,
    propertySearchFiltersExpressions,
    refetchQueries: onUpdateDataRefetchQueries,
  });

  const {
    handleDeleteTemplateSearchPropertySortingDefinition,
    handleSetTemplateSearchPropertySortingDefinition,
  } = useTemplateSearchSortingsMutations({
    projectId: projectId,
    docId: doc.id,
    itemId: item.id,
    searchSortingDefinitions: searchSortingDefinitions,
    refetchQueries: onUpdateDataRefetchQueries,
  });

  const unusedFilterProperties = differenceWith(
    item.placeholderForTemplateType?.templateType?.props2 ?? [],
    propertySearchFiltersExpressions,
    (p, f) => p.key === f.key
  );

  const unusedSortingProperties = differenceWith(
    item.placeholderForTemplateType?.templateType?.props2 ?? [],
    searchSortingDefinitions.filter(
      d => d.__typename === "EntitySearchSortingPropertyValueSorting"
    ),
    (p, f) => p.key === f.key
  ).flatMap(p => (p.__typename === "Props2Number" ? p : []));

  const atLeastOneSearchFilterExists =
    item.placeholderForTemplateType &&
    (propertySearchFilterDefinitions.length > 0 ||
      propertySearchFiltersExpressions.length > 0 ||
      searchSortingDefinitions.length > 0);

  const handleAddTemplateType = async (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment
  ) => {
    await setPlaceholderForTemplateType({
      variables: {
        input: {
          itemId: item.id,
          docId: doc.id,
          projectId: projectId,
          templateTypeId: templateType.id,
        },
      },
    });
  };
  const handleClearTemplateType = async () => {
    await setPlaceholderForTemplateType({
      variables: {
        input: {
          itemId: item.id,
          docId: doc.id,
          projectId: projectId,
          templateTypeId: null,
        },
      },
    });
  };

  if (
    item.isRootItem ||
    !(item.type === "section" || item.placeholderForTemplateType)
  )
    return null;

  return (
    <CardContainer
      title={t("Template type placeholder", { ns: "QuoteItem" })}
      isExpandable
      ActionButton={
        isEditable ? (
          !item.placeholderForTemplateType ? (
            <ModalOpenButton
              Modal={TemplateTypesSearchModal}
              modalProps={{
                handleSelectTemplateType: async (templateType, handleClose) => {
                  await handleAddTemplateType(templateType);
                  handleClose();
                },
              }}
            >
              <IconButton color="primary" size="small">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          ) : (
            <>
              {item.placeholderForTemplateType.atRevision !==
              item.placeholderForTemplateType.templateType.revision ? (
                <TemplateTypeAtVersionWarningButton
                  templateTypeRevision={
                    item.placeholderForTemplateType.templateType.revision
                  }
                  atRevision={item.placeholderForTemplateType.atRevision}
                  onResolve={async () => {
                    if (item.placeholderForTemplateType) {
                      await setPlaceholderForTemplateType({
                        variables: {
                          input: {
                            projectId: projectId,
                            itemId: item.id,
                            docId: doc.id,
                            templateTypeId:
                              item.placeholderForTemplateType.templateType.id,
                          },
                        },
                      });

                      await defineItemTemplateSearchFilterExpressions({
                        variables: {
                          input: {
                            docId: doc.id,
                            projectId: projectId,
                            itemId: item.id,
                            templateSearchFilterExpressions: {
                              propertyFilterExpressions:
                                adjustTemplateSearchFilterPropertyFilterExpressionsToTemplateTypeProps2(
                                  {
                                    filters: propertySearchFiltersExpressions,
                                    templateTypeProps:
                                      item.placeholderForTemplateType
                                        .templateType.props2,
                                  }
                                ).map(
                                  templateSearchFilterPropertyFilterExpression2Input
                                ),
                            },
                          },
                        },
                      });
                    }
                  }}
                />
              ) : undefined}
              {atLeastOneSearchFilterExists && (
                <MenuButton>
                  <ViewModeMenuItem
                    viewMode={viewMode}
                    onViewModeChange={setViewMode}
                    allowedModes={[null, "delete"]}
                  />
                </MenuButton>
              )}
            </>
          )
        ) : undefined
      }
    >
      {item.placeholderForTemplateType ? (
        <Stack direction="column" spacing={1} p={1}>
          <TemplateTypeInput
            templateTypeId={item.placeholderForTemplateType.templateType.id}
            canEdit={isEditable}
            onChange={async templateType => {
              await handleAddTemplateType(templateType);
            }}
            onClear={async () => {
              await handleClearTemplateType();
              setViewMode(null);
            }}
          />
          <CollapseSection
            title={t("Search filters", { ns: "TemplateTypes" })}
            itemCount={propertySearchFilterDefinitions.length}
            ActionButtons={
              isEditable ? (
                <ModalOpenButton
                  Modal={AddTemplateSearchFilterDefinitionsModal}
                  modalProps={{
                    handleComplete: async values => {
                      await defineItemTemplateSearchFilterDefinitions({
                        variables: {
                          input: {
                            projectId,
                            docId: doc.id,
                            itemId: item.id,
                            ...filterFormValuesToDefinitionInput(values),
                          },
                        },
                      });
                    },
                    templateTypeIdFilter:
                      item.placeholderForTemplateType.templateType.id,
                    templateSearchFilterDefinitions:
                      item.templateSearchFilterDefinitions,
                  }}
                >
                  <IconButton size={"small"} color={"primary"}>
                    <AddIcon />
                  </IconButton>
                </ModalOpenButton>
              ) : undefined
            }
          >
            <Grid
              container
              columns={2}
              rowSpacing={1}
              columnSpacing={2}
              padding={1}
            >
              {propertySearchFilterDefinitions.map(
                propertySearchFilterDefinition => {
                  return (
                    <Grid key={propertySearchFilterDefinition.key} item xs={1}>
                      <Stack
                        direction={"row"}
                        spacing={0.5}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <LabeledValue
                          label={propertySearchFilterDefinition.key}
                        >
                          {getValueWithOperator(propertySearchFilterDefinition)}
                        </LabeledValue>

                        {isEditable && viewMode === "delete" && (
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={async () => {
                              await handleDeleteTemplateSearchFilterPropertyDefinition(
                                propertySearchFilterDefinition.key
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </CollapseSection>
          <CollapseSection
            title={t("Expressions", { ns: "TemplateTypes" })}
            itemCount={propertySearchFiltersExpressions.length}
            ActionButtons={
              isEditable ? (
                <MenuButton
                  Icon={<AddIcon />}
                  buttonProps={{
                    disabled: unusedFilterProperties.length === 0,
                  }}
                >
                  {unusedFilterProperties.map(prop => {
                    switch (prop.__typename) {
                      case "Props2Bool": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<
                                | EntitySearchBoolFilterOperator
                                | EntitySearchBoolInFilterOperator
                              >
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "eq",
                              allowedSearchFilterOperators:
                                BoolFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  value.operator === "in"
                                    ? {
                                        boolInFilter: {
                                          key: prop.key,
                                          operatorBoolIn: value.operator,
                                          expr: value.expr,
                                        },
                                      }
                                    : value.operator === "eq"
                                      ? {
                                          boolFilter: {
                                            operatorBool: value.operator,
                                            expr: value.expr,
                                            key: prop.key,
                                          },
                                        }
                                      : assertNever(value.operator)
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }
                      case "Props2Number": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<
                                | EntitySearchNumberFilterOperator
                                | EntitySearchNumberInFilterOperator
                                | EntitySearchNumberBetweenFilterOperator
                              >
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "eq",
                              allowedSearchFilterOperators:
                                NumberFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  value.operator === "in"
                                    ? {
                                        numberInFilter: {
                                          key: prop.key,
                                          operatorNumberIn: value.operator,
                                          expr: value.expr,
                                        },
                                      }
                                    : value.operator === "between"
                                      ? {
                                          numberBetweenFilter: {
                                            key: prop.key,
                                            operatorNumberBetween:
                                              value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "eq" ||
                                          value.operator === "lt" ||
                                          value.operator === "lte" ||
                                          value.operator === "gt" ||
                                          value.operator === "gte"
                                        ? {
                                            numberFilter: {
                                              key: prop.key,
                                              operatorNumber: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : assertNever(value.operator)
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }
                      case "Props2NumberArray": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "anyOf",
                              allowedSearchFilterOperators:
                                ArrayFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  {
                                    numberArrayOfFilter: {
                                      key: prop.key,
                                      operatorNumberArrayOf: value.operator,
                                      expr: value.expr,
                                    },
                                  }
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }

                      case "Props2Text": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<
                                | EntitySearchTextFilterOperator
                                | EntitySearchTextInFilterOperator
                              >
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "eq",
                              allowedSearchFilterOperators:
                                TextFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  value.operator === "in"
                                    ? {
                                        textInFilter: {
                                          key: prop.key,
                                          operatorTextIn: value.operator,
                                          expr: value.expr,
                                        },
                                      }
                                    : value.operator === "eq"
                                      ? {
                                          textFilter: {
                                            key: prop.key,
                                            operatorText: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : assertNever(value.operator)
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }
                      case "Props2TextArray": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "anyOf",
                              allowedSearchFilterOperators:
                                ArrayFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  {
                                    textArrayOfFilter: {
                                      key: prop.key,
                                      operatorTextArrayOf: value.operator,
                                      expr: value.expr,
                                    },
                                  }
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }
                      default:
                        assertNever(prop);
                    }
                  })}
                </MenuButton>
              ) : undefined
            }
          >
            <Grid
              container
              columns={2}
              rowSpacing={1}
              columnSpacing={2}
              padding={1}
            >
              {propertySearchFiltersExpressions.map(
                (propertySearchFilter, index) => {
                  switch (propertySearchFilter.__typename) {
                    case "EntitySearchPropertyFilterBoolFilterComputed":
                    case "EntitySearchPropertyFilterBoolInFilterComputed": {
                      return (
                        <Grid key={propertySearchFilter.key} item xs={1}>
                          <Stack
                            direction={"row"}
                            spacing={0.5}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <ModalOpenButton
                              Modal={
                                ProductSearchFilterExpressionModal<
                                  | EntitySearchBoolFilterOperator
                                  | EntitySearchBoolInFilterOperator
                                >
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: propertySearchFilter.key,
                                operator:
                                  propertySearchFilter.__typename ===
                                  "EntitySearchPropertyFilterBoolInFilterComputed"
                                    ? propertySearchFilter.operatorBoolIn
                                    : propertySearchFilter.operatorBool,
                                allowedSearchFilterOperators:
                                  BoolFilterOperatorOptions,
                                expression: propertySearchFilter.expr,
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key,
                                    value.operator === "in"
                                      ? {
                                          boolInFilter: {
                                            key: propertySearchFilter.key,
                                            operatorBoolIn: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "eq"
                                        ? {
                                            boolFilter: {
                                              operatorBool: value.operator,
                                              expr: value.expr,
                                              key: propertySearchFilter.key,
                                            },
                                          }
                                        : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                              >
                                {getValueWithOperator(propertySearchFilter)}
                              </LabeledValueWithButton>
                            </ModalOpenButton>
                            {isEditable && viewMode === "delete" && (
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={async () => {
                                  await handleDeleteTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      );
                    }
                    case "EntitySearchPropertyFilterNumberFilterComputed":
                    case "EntitySearchPropertyFilterNumberInFilterComputed":
                    case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
                      return (
                        <Grid key={propertySearchFilter.key} item xs={1}>
                          <Stack
                            direction={"row"}
                            spacing={0.5}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <ModalOpenButton
                              Modal={
                                ProductSearchFilterExpressionModal<
                                  | EntitySearchNumberFilterOperator
                                  | EntitySearchNumberInFilterOperator
                                  | EntitySearchNumberBetweenFilterOperator
                                >
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: propertySearchFilter.key,
                                operator:
                                  propertySearchFilter.__typename ===
                                  "EntitySearchPropertyFilterNumberFilterComputed"
                                    ? propertySearchFilter.operatorNumber
                                    : propertySearchFilter.__typename ===
                                        "EntitySearchPropertyFilterNumberInFilterComputed"
                                      ? propertySearchFilter.operatorNumberIn
                                      : propertySearchFilter.operatorNumberBetween,
                                allowedSearchFilterOperators:
                                  NumberFilterOperatorOptions,
                                expression: propertySearchFilter.expr,
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key,
                                    value.operator === "in"
                                      ? {
                                          numberInFilter: {
                                            key: propertySearchFilter.key,
                                            operatorNumberIn: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "between"
                                        ? {
                                            numberBetweenFilter: {
                                              key: propertySearchFilter.key,
                                              operatorNumberBetween:
                                                value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : value.operator === "eq" ||
                                            value.operator === "lt" ||
                                            value.operator === "lte" ||
                                            value.operator === "gt" ||
                                            value.operator === "gte"
                                          ? {
                                              numberFilter: {
                                                key: propertySearchFilter.key,
                                                operatorNumber: value.operator,
                                                expr: value.expr,
                                              },
                                            }
                                          : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                              >
                                {getValueWithOperator(propertySearchFilter)}
                              </LabeledValueWithButton>
                            </ModalOpenButton>
                            {isEditable && viewMode === "delete" && (
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={async () => {
                                  await handleDeleteTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      );
                    }
                    case "EntitySearchPropertyFilterTextFilterComputed":
                    case "EntitySearchPropertyFilterTextInFilterComputed": {
                      return (
                        <Grid key={propertySearchFilter.key} item xs={1}>
                          <Stack
                            direction={"row"}
                            spacing={0.5}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <ModalOpenButton
                              Modal={
                                ProductSearchFilterExpressionModal<
                                  | EntitySearchTextFilterOperator
                                  | EntitySearchTextInFilterOperator
                                >
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: propertySearchFilter.key,
                                operator:
                                  propertySearchFilter.__typename ===
                                  "EntitySearchPropertyFilterTextFilterComputed"
                                    ? propertySearchFilter.operatorText
                                    : propertySearchFilter.operatorTextIn,
                                allowedSearchFilterOperators:
                                  TextFilterOperatorOptions,
                                expression: propertySearchFilter.expr,
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key,
                                    value.operator === "in"
                                      ? {
                                          textInFilter: {
                                            key: propertySearchFilter.key,
                                            operatorTextIn: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "eq"
                                        ? {
                                            textFilter: {
                                              key: propertySearchFilter.key,
                                              operatorText: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                              >
                                {getValueWithOperator(propertySearchFilter)}
                              </LabeledValueWithButton>
                            </ModalOpenButton>
                            {isEditable && viewMode === "delete" && (
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={async () => {
                                  await handleDeleteTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      );
                    }
                    case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
                      return (
                        <Grid key={propertySearchFilter.key} item xs={1}>
                          <Stack
                            direction={"row"}
                            spacing={0.5}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <ModalOpenButton
                              Modal={
                                ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: propertySearchFilter.key,
                                operator:
                                  propertySearchFilter.operatorNumberArrayOf,
                                allowedSearchFilterOperators:
                                  ArrayFilterOperatorOptions,
                                expression: propertySearchFilter.expr,
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key,
                                    value.operator === "allOf" ||
                                      value.operator === "anyOf"
                                      ? {
                                          numberArrayOfFilter: {
                                            key: propertySearchFilter.key,
                                            operatorNumberArrayOf:
                                              value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                              >
                                {getValueWithOperator(propertySearchFilter)}
                              </LabeledValueWithButton>
                            </ModalOpenButton>
                            {isEditable && viewMode === "delete" && (
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={async () => {
                                  await handleDeleteTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      );
                    }
                    case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
                      return (
                        <Grid key={propertySearchFilter.key} item xs={1}>
                          <Stack
                            direction={"row"}
                            spacing={0.5}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <ModalOpenButton
                              Modal={
                                ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: propertySearchFilter.key,
                                operator:
                                  propertySearchFilter.operatorTextArrayOf,
                                allowedSearchFilterOperators:
                                  ArrayFilterOperatorOptions,
                                expression: propertySearchFilter.expr,
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key,
                                    value.operator === "allOf" ||
                                      value.operator === "anyOf"
                                      ? {
                                          textArrayOfFilter: {
                                            key: propertySearchFilter.key,
                                            operatorTextArrayOf: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                              >
                                {getValueWithOperator(propertySearchFilter)}
                              </LabeledValueWithButton>
                            </ModalOpenButton>
                            {isEditable && viewMode === "delete" && (
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={async () => {
                                  await handleDeleteTemplateSearchFilterPropertyExpression(
                                    propertySearchFilter.key
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      );
                    }
                    default:
                      assertNever(propertySearchFilter);
                  }
                }
              )}
            </Grid>
          </CollapseSection>
          <CollapseSection
            title={t("Sorting", { ns: "TemplateTypes" })}
            itemCount={searchSortingDefinitions.length}
            ActionButtons={
              isEditable ? (
                <MenuButton
                  Icon={<AddIcon />}
                  buttonProps={{
                    disabled: unusedSortingProperties.length === 0,
                  }}
                >
                  {unusedSortingProperties.map(prop => {
                    switch (prop.__typename) {
                      case "Props2Number": {
                        return (
                          <MenuItem
                            key={prop.key}
                            onClick={async () => {
                              await handleSetTemplateSearchPropertySortingDefinition(
                                prop.key,
                                "number",
                                "asc"
                              );
                            }}
                          >
                            {getEnhancedPropertyLabel(prop, false)}
                          </MenuItem>
                        );
                      }
                      default:
                        assertNever(prop.__typename);
                    }
                  })}
                </MenuButton>
              ) : undefined
            }
          >
            <Grid
              container
              columns={2}
              rowSpacing={1}
              columnSpacing={2}
              padding={1}
            >
              {searchSortingDefinitions.map((searchSorting, index) => {
                switch (searchSorting.__typename) {
                  case "EntitySearchSortingPropertyValueSorting": {
                    return (
                      <Grid key={searchSorting.key} item xs={1}>
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Select
                            key={`${searchSorting.key}-input`}
                            label={searchSorting.key}
                            options={[
                              {
                                label: "asc",
                                value: "asc" as const,
                              },
                              {
                                label: "desc",
                                value: "desc" as const,
                              },
                            ]}
                            value={searchSorting.direction}
                            onChange={async value => {
                              await handleSetTemplateSearchPropertySortingDefinition(
                                searchSorting.key,
                                searchSorting.kind,
                                value
                              );
                            }}
                            disabled={!isEditable}
                          />
                          {isEditable && viewMode === "delete" && (
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={async () => {
                                await handleDeleteTemplateSearchPropertySortingDefinition(
                                  searchSorting.key
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    );
                  }
                  default:
                    assertNever(searchSorting.__typename);
                }
              })}
            </Grid>
          </CollapseSection>
          <TemplateTypeQuestionControlForm
            projectId={projectId}
            docId={item.docId}
            item={item}
            canEdit={isEditable}
          />
        </Stack>
      ) : isEditable ? (
        <Stack padding={1} alignItems="center" justifyContent="center">
          <ModalOpenButton
            Modal={TemplateTypesSearchModal}
            modalProps={{
              handleSelectTemplateType: async (templateType, handleClose) => {
                await handleAddTemplateType(templateType);
                handleClose();
              },
            }}
          >
            <Button startIcon={<AddIcon />} color={"secondary"} size="small">
              {t("Add template type", { ns: "TemplateTypes" })}
            </Button>
          </ModalOpenButton>
        </Stack>
      ) : (
        <Stack padding={1} alignItems="center" justifyContent={"center"}>
          <Typography color="grey.600" variant="body2">
            {t("No template type defined", { ns: "QuoteItem" })}
          </Typography>
        </Stack>
      )}
    </CardContainer>
  );
};

export function getValueWithOperator(
  filter:
    | ItemTemplateSearchFilterPropertyFilterFragment
    | ItemTemplateSearchFilterPropertyFilterComputedFragment
    | undefined
    | null,
  unit?: string | null | undefined
) {
  const placeholder = "-";
  if (!filter) return placeholder;

  switch (filter.__typename) {
    case "EntitySearchPropertyFilterNumberFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumber
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumber}` + (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberInFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberIn.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberBetweenFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberBetween
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberBetween.min}-${filter.valueNumberBetween.max}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberFilterComputed": {
      if (
        filter.valueNumberComputed === null ||
        filter.valueNumberComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumber
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberComputed}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberInFilterComputed": {
      if (
        filter.valueNumberInComputed === null ||
        filter.valueNumberInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberInComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilter": {
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberArrayOf
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberArrayOf.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
      if (
        filter.valueNumberArrayOfComputed === null ||
        filter.valueNumberArrayOfComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberArrayOf
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberArrayOfComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
      if (
        filter.valueNumberBetweenComputed === null ||
        filter.valueNumberBetweenComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberBetween
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberBetweenComputed.min}-${filter.valueNumberBetweenComputed.max}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterTextFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorText
      )?.label;
      return (
        `${operatorLabel} "${filter.valueText}"` + (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterTextFilterComputed": {
      if (
        filter.valueTextComputed === null ||
        filter.valueTextComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorText
      )?.label;
      return (
        `${operatorLabel} "${filter.valueTextComputed}"` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterTextInFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextIn
      )?.label;
      return `${operatorLabel} ${filter.valueTextIn.join(", ")}`;
    }
    case "EntitySearchPropertyFilterTextInFilterComputed": {
      if (
        filter.valueTextInComputed === null ||
        filter.valueTextInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextIn
      )?.label;
      return `${operatorLabel} ${filter.valueTextInComputed.join(", ")}`;
    }
    case "EntitySearchPropertyFilterTextArrayOfFilter": {
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextArrayOf
      )?.label;
      return `${operatorLabel} ${filter.valueTextArrayOf.join(", ")}`;
    }
    case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
      if (
        filter.valueTextArrayOfComputed === null ||
        filter.valueTextArrayOfComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextArrayOf
      )?.label;
      return `${operatorLabel} ${filter.valueTextArrayOfComputed.join(", ")}`;
    }
    case "EntitySearchPropertyFilterBoolFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBool
      )?.label;
      return `${operatorLabel} ${filter.valueBool}` + (unit ? ` ${unit}` : "");
    }
    case "EntitySearchPropertyFilterBoolInFilter": {
      const operatorLabel = BoolFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBoolIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolIn.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterBoolFilterComputed": {
      if (
        filter.valueBoolComputed === null ||
        filter.valueBoolComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBool
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolComputed}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterBoolInFilterComputed": {
      if (
        filter.valueBoolInComputed === null ||
        filter.valueBoolInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBoolIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolInComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    default:
      assertNever(filter);
  }
}

const AddTemplateSearchFilterDefinitionsModal = ({
  handleClose,
  handleComplete,
  templateTypeIdFilter,
  templateSearchFilterDefinitions,
}: {
  handleClose: () => void;
  handleComplete: (values: TemplateFiltersFormValues) => Promise<void>;
  templateTypeIdFilter: string;
  templateSearchFilterDefinitions: TemplateSearchFilterDefinitionsFragment;
}) => {
  const { t } = useTranslate(["TemplateTypes"]);

  const fixedFilters = React.useMemo(
    (): Partial<TemplateFiltersFormValues> => ({
      templateTypeIds: [templateTypeIdFilter],
    }),
    [templateTypeIdFilter]
  );

  const initialValues = React.useMemo((): TemplateFiltersFormValues => {
    return {
      ...templateFiltersDefaultValue,
      ...definitionsToFormValues(templateSearchFilterDefinitions),
      ...fixedFilters,
    };
  }, [fixedFilters, templateSearchFilterDefinitions]);

  const formikProps = useFormik({
    initialValues,
    validationSchema: templateFiltersValidationSchema,
    validateOnMount: true,
    enableReinitialize: false,
    onSubmit: handleComplete,
  });

  const templateSearchVariables = React.useMemo(
    (): TemplateFilterFields_AvailableFiltersQueryVariables => ({
      filters: { sources: ["available"] },
    }),
    []
  );

  return (
    <TemplateTypeSearchFilterModal
      title={t("Search filters", { ns: "TemplateTypes" })}
      handleClose={handleClose}
      formikProps={formikProps}
      templateSearchVariables={templateSearchVariables}
      fixedFilters={fixedFilters}
    />
  );
};

function definitionsToFormValues(
  definitions: TemplateSearchFilterDefinitionsFragment
): Partial<TemplateFiltersFormValues> {
  return {
    properties: definitions.propertyFilters.map(propertyFilter => {
      switch (propertyFilter.__typename) {
        case "EntitySearchPropertyFilterBoolFilter": {
          return {
            key: propertyFilter.key,
            boolFilter: {
              operatorBool: propertyFilter.operatorBool,
              valueBool: propertyFilter.valueBool,
            },
          };
        }
        case "EntitySearchPropertyFilterBoolInFilter": {
          return {
            key: propertyFilter.key,
            boolInFilter: {
              operatorBoolIn: propertyFilter.operatorBoolIn,
              valueBoolIn: propertyFilter.valueBoolIn,
            },
          };
        }
        case "EntitySearchPropertyFilterNumberBetweenFilter": {
          return {
            key: propertyFilter.key,
            numberBetweenFilter: {
              operatorNumberBetween: propertyFilter.operatorNumberBetween,
              valueNumberBetween: propertyFilter.valueNumberBetween,
            },
          };
        }
        case "EntitySearchPropertyFilterNumberFilter": {
          return {
            key: propertyFilter.key,
            numberFilter: {
              operatorNumber: propertyFilter.operatorNumber,
              valueNumber: propertyFilter.valueNumber,
            },
          };
        }
        case "EntitySearchPropertyFilterNumberInFilter": {
          return {
            key: propertyFilter.key,
            numberInFilter: {
              operatorNumberIn: propertyFilter.operatorNumberIn,
              valueNumberIn: propertyFilter.valueNumberIn,
            },
          };
        }
        case "EntitySearchPropertyFilterTextFilter": {
          return {
            key: propertyFilter.key,
            textFilter: {
              operatorText: propertyFilter.operatorText,
              valueText: propertyFilter.valueText,
            },
          };
        }
        case "EntitySearchPropertyFilterTextInFilter": {
          return {
            key: propertyFilter.key,
            textInFilter: {
              operatorTextIn: propertyFilter.operatorTextIn,
              valueTextIn: propertyFilter.valueTextIn,
            },
          };
        }
        case "EntitySearchPropertyFilterTextArrayOfFilter": {
          return {
            key: propertyFilter.key,
            textArrayOfFilter: {
              operatorTextArrayOf: propertyFilter.operatorTextArrayOf,
              valueTextArrayOf: propertyFilter.valueTextArrayOf,
            },
          };
        }
        case "EntitySearchPropertyFilterNumberArrayOfFilter": {
          return {
            key: propertyFilter.key,
            numberArrayOfFilter: {
              operatorNumberArrayOf: propertyFilter.operatorNumberArrayOf,
              valueNumberArrayOf: propertyFilter.valueNumberArrayOf,
            },
          };
        }
        default:
          assertNever(propertyFilter);
      }
    }),
  };
}

function filterFormValuesToDefinitionInput(
  values: TemplateFiltersFormValues
): Omit<
  DefineItemTemplateSearchFilterDefinitionsInput,
  "projectId" | "docId" | "itemId"
> {
  return {
    templateSearchFilterDefinitions: {
      propertyFilterDefinitions: (values.properties ?? []).flatMap(filter => {
        if (filter.boolFilter) {
          return {
            boolFilter: {
              key: filter.key,
              operatorBool: filter.boolFilter.operatorBool,
              valueBool: filter.boolFilter.valueBool,
            },
          };
        }
        if (filter.boolInFilter) {
          return {
            boolInFilter: {
              key: filter.key,
              operatorBoolIn: filter.boolInFilter.operatorBoolIn,
              valueBoolIn: filter.boolInFilter.valueBoolIn,
            },
          };
        }
        if (filter.numberBetweenFilter) {
          return {
            numberBetweenFilter: {
              key: filter.key,
              operatorNumberBetween:
                filter.numberBetweenFilter.operatorNumberBetween,
              valueNumberBetween: filter.numberBetweenFilter.valueNumberBetween,
            },
          };
        }
        if (filter.numberFilter) {
          return {
            numberFilter: {
              key: filter.key,
              operatorNumber: filter.numberFilter.operatorNumber,
              valueNumber: filter.numberFilter.valueNumber,
            },
          };
        }
        if (filter.numberInFilter) {
          return {
            numberInFilter: {
              key: filter.key,
              operatorNumberIn: filter.numberInFilter.operatorNumberIn,
              valueNumberIn: filter.numberInFilter.valueNumberIn,
            },
          };
        }
        if (filter.textFilter) {
          return {
            textFilter: {
              key: filter.key,
              operatorText: filter.textFilter.operatorText,
              valueText: filter.textFilter.valueText,
            },
          };
        }
        if (filter.textInFilter) {
          return {
            textInFilter: {
              key: filter.key,
              operatorTextIn: filter.textInFilter.operatorTextIn,
              valueTextIn: filter.textInFilter.valueTextIn,
            },
          };
        }

        if (filter.textArrayOfFilter) {
          return {
            textArrayOfFilter: {
              key: filter.key,
              operatorTextArrayOf: filter.textArrayOfFilter.operatorTextArrayOf,
              valueTextArrayOf: filter.textArrayOfFilter.valueTextArrayOf,
            },
          };
        }

        if (filter.numberArrayOfFilter) {
          return {
            numberArrayOfFilter: {
              key: filter.key,
              operatorNumberArrayOf:
                filter.numberArrayOfFilter.operatorNumberArrayOf,
              valueNumberArrayOf: filter.numberArrayOfFilter.valueNumberArrayOf,
            },
          };
        }

        throw new Error("Unknown filter type");
      }),
    },
  };
}

export function TemplateTypeQuestionControlForm({
  projectId,
  docId,
  item,
  canEdit = true,
}: {
  projectId: string | null;
  docId: string;
  item: TemplateTypePlaceholderBox_ItemFragment;
  canEdit?: boolean;
}) {
  const client = useApolloClient();
  const [modifyWizardSettings] =
    useTemplateTypePlacerholderQuestionControlForm_ModifyWizardSettingsMutation(
      {
        client,
      }
    );

  return (
    <Formik<QuestionControlSectionFormValues>
      initialValues={{
        askWhen: item.wizardSettings.askWhenTemplateType,
        askWhom: item.wizardSettings.askWhomTemplateType,
        prompt: item.wizardSettings.promptTemplateType,
      }}
      onSubmit={async values => {
        await modifyWizardSettings({
          variables: {
            input: {
              projectId,
              docId,
              itemId: item.id,
              values: {
                askWhenTemplateType: values.askWhen,
                askWhomTemplateType: values.askWhom,
                promptTemplateType: values.prompt,
              },
            },
          },
        });
      }}
    >
      <Form>
        <QuestionControlSection
          readOnly={!canEdit}
          isInitiallyExpanded
          disabled={false}
        />
        <AutoSave />
      </Form>
    </Formik>
  );
}
