import { gql, useApolloClient } from "@apollo/client";
import { ModalOpenButton, TextWithBreaks } from "@msys/ui";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { ConfirmModal } from "../../commons/modals/ConfirmModal.js";
import { useRequestAccountDeletionMutation } from "./UserDeleteAccountButton.generated.js";

export const UserDeleteAccountButton = () => {
  const { t } = useTranslate(["Global", "UserProfile"]);
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const [requestDeletion] = useRequestAccountDeletionMutation({ client });
  return (
    <ModalOpenButton
      Modal={ConfirmModal}
      modalProps={{
        title: t("Delete profile?", { ns: "UserProfile" }),
        content: () => (
          <Stack direction="column" spacing={2}>
            <Typography variant="body1">
              <TextWithBreaks
                text={t(
                  "If you do not think you will use MeisterSystems again and would like your profile deleted, we can take care of this for you.",
                  { ns: "UserProfile" }
                )}
              />
            </Typography>
          </Stack>
        ),
        confirmButtonLabel: t("Send deletion request", { ns: "UserProfile" }),
        confirmButtonProps: {
          color: "error",
        },
        handleConfirm: async () => {
          await requestDeletion();
          enqueueSnackbar(
            t("Request for deletion was sent", { ns: "UserProfile" })
          );
        },
      }}
    >
      <Button color="error" variant="text" fullWidth>
        {t("Delete profile", { ns: "UserProfile" })}
      </Button>
    </ModalOpenButton>
  );
};
