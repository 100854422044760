/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ThreeD_CreateLeadInNewProjectMutationVariables = Types.Exact<{
  input: Types._3d_CreateLeadInNewProjectInput;
}>;


export type ThreeD_CreateLeadInNewProjectMutation = { _3d_createLeadInNewProject: { __typename: '_3d_CreateLeadInNewProjectResult', refToken: string } };


export const ThreeD_CreateLeadInNewProjectDocument = gql`
    mutation threeD_CreateLeadInNewProject($input: _3d_CreateLeadInNewProjectInput!) {
  _3d_createLeadInNewProject(input: $input) {
    refToken
  }
}
    `;
export type ThreeD_CreateLeadInNewProjectMutationFn = Apollo.MutationFunction<ThreeD_CreateLeadInNewProjectMutation, ThreeD_CreateLeadInNewProjectMutationVariables>;

/**
 * __useThreeD_CreateLeadInNewProjectMutation__
 *
 * To run a mutation, you first call `useThreeD_CreateLeadInNewProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThreeD_CreateLeadInNewProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [threeDCreateLeadInNewProjectMutation, { data, loading, error }] = useThreeD_CreateLeadInNewProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThreeD_CreateLeadInNewProjectMutation(baseOptions?: Apollo.MutationHookOptions<ThreeD_CreateLeadInNewProjectMutation, ThreeD_CreateLeadInNewProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ThreeD_CreateLeadInNewProjectMutation, ThreeD_CreateLeadInNewProjectMutationVariables>(ThreeD_CreateLeadInNewProjectDocument, options);
      }
export type ThreeD_CreateLeadInNewProjectMutationHookResult = ReturnType<typeof useThreeD_CreateLeadInNewProjectMutation>;
export type ThreeD_CreateLeadInNewProjectMutationResult = Apollo.MutationResult<ThreeD_CreateLeadInNewProjectMutation>;
export type ThreeD_CreateLeadInNewProjectMutationOptions = Apollo.BaseMutationOptions<ThreeD_CreateLeadInNewProjectMutation, ThreeD_CreateLeadInNewProjectMutationVariables>;