import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  CardItem,
  isImageOr3dModel,
  processAttachment,
  TypographyWithMaxNumberOfLines,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import { Phone as PhoneIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { partition } from "lodash-es";
import moment from "moment";
import React from "react";
import { namedOperations } from "../../clients/graphqlTypes.js";
import { useUserData } from "../auth/useUserData.js";
import { Avatar, getInitials } from "../commons/Avatar.js";
import { GalleryGrid } from "../commons/images/GalleryGrid.js";
import { Page, PageTopbarItem } from "../commons/layout/Page.js";
import { PageContainer } from "../commons/layout/PageContainer.js";
import { PageHeader } from "../commons/layout/PageHeader.js";
import { ProfileListItem } from "../commons/ProfileListItem.js";
import { AddressBox } from "../features/addresses/AddressBox.js";
import { AttachmentUploader } from "../features/attachments/AttachmentUploader.js";
import { FileBoxRow } from "../features/attachments/FileBoxRow.js";
import { Attachment } from "../features/attachments/helpers.js";
import { useProjectAttachments } from "../features/attachments/useAttachments.js";
import { CustomFieldsBox } from "../features/custom-fields/CustomFieldsBox.js";
import { PlanningIcon } from "../features/planning/PlanningIcon.js";
import { ProjectStatusChip } from "../features/projects/badges/ProjectStatusBadge.js";
import { ProjectOverviewWorkSessionsBox } from "../features/projects/boxes/ProjectOverviewWorkSessionsBox.js";
import { TicketStatusChip } from "../features/projects/TicketStatusBadge.js";
import { useSalutation } from "../features/users/useSalutation.js";
import { useInstallerProjectOverviewQuery } from "./ProjectOverview.generated.js";

interface Props {
  projectId: string;
  submenuItems: React.ComponentProps<typeof Page>["submenuItems"];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectOverview = ({
  projectId,
  submenuItems,
  activeSubmenuItem,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Global", "ProjectOverview"]);

  const client = useApolloClient();
  const query = useInstallerProjectOverviewQuery({
    client,
    variables: {
      projectId,
    },
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const contractee = project?.crmOrganisation;
  const mySessions = project?.planSessions
    .filter(sessions => sessions.who.id === viewer.id)
    .sort((session1, session2) =>
      moment(session1.from).isBefore(session2.from) ? -1 : 1
    );
  const attachments = project?.attachments;
  const customFields = project?.customFields;

  const contracteeMembership = contractee?.contactPerson;

  const { getNameWithSalutation } = useSalutation();

  return (
    <Page
      subtitle={project?.title}
      title={t("Overview", {
        ns: "ProjectOverview",
      })}
      submenuItems={submenuItems}
      header={
        <PageHeader
          title={project?.title}
          status={
            project && (
              <Stack direction="row" spacing={1}>
                <TicketStatusChip ticket={project} />
                <ProjectStatusChip projectState={project.state} />
              </Stack>
            )
          }
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
    >
      <PageContainer>
        <Stack direction="column" spacing={1}>
          <Stack direction="column" spacing={1}>
            <Typography variant="h1">{project?.title}</Typography>
            <Description text={project?.description} />
          </Stack>

          {project?.building?.buildingAddress && (
            <Paper sx={{ p: 1 }}>
              <AddressBox address={project.building.buildingAddress} />
            </Paper>
          )}

          <CardItem>
            <List disablePadding>
              <ProfileListItem
                avatar={
                  <Avatar
                    type="circle"
                    size="m"
                    initials={getInitials(
                      contractee?.contactPerson?.firstname,
                      contractee?.contactPerson?.familyname
                    )}
                  />
                }
                primary={getNameWithSalutation(
                  contractee?.contactPerson?.title ?? "mr",
                  contractee?.contactPerson?.firstname ?? "",
                  contractee?.contactPerson?.familyname ?? ""
                )}
                secondary={t("Client contact", {
                  ns: "Global",
                })}
                ActionButton={
                  contracteeMembership?.phones?.length ? (
                    <IconButton
                      color="primary"
                      component="a"
                      href={`tel:${contracteeMembership.phones[0].number}`}
                      size="large"
                    >
                      <PhoneIcon />
                    </IconButton>
                  ) : undefined
                }
              />
            </List>
          </CardItem>

          <CardContainer
            Icon={<PlanningIcon />}
            title={t("Your schedule", {
              ns: "Global",
            })}
            isExpandable
          >
            <List disablePadding>
              {mySessions?.map((session, index, array) => (
                <ListItem key={session.id} divider={index < array.length - 1}>
                  <Typography>
                    <Stack direction="row" spacing={0.5}>
                      <Box fontWeight="bold">
                        {moment(session.from).format("l")}
                      </Box>
                      <Box>{moment(session.from).format("LT")}</Box>
                      <Box>–</Box>
                      {!moment(session.till).isSame(session.from, "day") && (
                        <Box fontWeight="bold">
                          {moment(session.till).format("l")}
                        </Box>
                      )}
                      <Box>{moment(session.till).format("LT")}</Box>
                    </Stack>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </CardContainer>

          {project && <ProjectOverviewWorkSessionsBox project={project} />}

          <AttachmentsBox
            projectId={projectId}
            city={project?.building?.buildingAddress?.city}
            attachments={attachments}
          />

          {customFields && (
            <CustomFieldsBox
              source={project}
              objectType={"Project"}
              title={t("Other", {
                ns: "Global",
              })}
            />
          )}
        </Stack>
      </PageContainer>
    </Page>
  );
};

const Description: React.FC<{ text: string | undefined }> = ({ text = "" }) => {
  const { t } = useTranslate("Global");
  const [isExpanded, setIsExpanded] = React.useState(false);

  if (!text) return null;

  if (isExpanded) {
    return (
      <Stack direction="column" spacing={1} alignItems="flex-end">
        <Box alignSelf="stretch">
          <Typography variant="body1">{text}</Typography>
        </Box>
        <Button
          size="small"
          onClick={() => {
            setIsExpanded(value => !value);
          }}
        >
          {t("Less")}
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={0.5} justifyContent="space-between">
      <TypographyWithMaxNumberOfLines variant="body1" $maxLines={2}>
        {text}
      </TypographyWithMaxNumberOfLines>
      <Button
        size="small"
        onClick={() => {
          setIsExpanded(value => !value);
        }}
      >
        {t("More")}
      </Button>
    </Stack>
  );
};

const AttachmentsBox = ({
  projectId,
  city,
  attachments = [],
}: {
  projectId: string;
  city?: string;
  attachments: Attachment[] | undefined;
}) => {
  const { t } = useTranslate("Global");

  const uploaderRef = React.useRef<HTMLInputElement>(null);

  const [images, files] = partition(
    attachments.map(processAttachment),
    isImageOr3dModel
  );

  const { addAttachments, loading } = useProjectAttachments(projectId, [
    namedOperations.Query.InstallerProjectOverview,
  ]);

  return (
    <CardContainer
      key={attachments.length}
      itemCount={attachments.length}
      Icon={<AttachFileIcon />}
      title={t("Attachments")}
      isExpandable
      ActionButton={
        <IconButton
          color="primary"
          size="small"
          disabled={loading}
          onClick={async () => {
            if (uploaderRef.current) {
              uploaderRef.current.click();
            }
          }}
        >
          <AddIcon />
        </IconButton>
      }
    >
      {loading && <LinearProgress />}
      <Stack direction="column" spacing={0}>
        {attachments.length > 0 && (
          <Box padding={1}>
            <GalleryGrid images={images} />
          </Box>
        )}
        <List disablePadding>
          {files.map(file => (
            <FileBoxRow
              key={file.id}
              file={file}
              canSign
              defaultPlace={city}
              defaultDate={moment()}
              onSignedPdfAttachment={async attachment => {
                await addAttachments([attachment]);
              }}
              viewMode={null}
            />
          ))}
        </List>
      </Stack>
      <AttachmentUploader
        innerRef={uploaderRef}
        accept="*"
        multiple={true}
        onComplete={async attachments => {
          await addAttachments(attachments);
        }}
      />
    </CardContainer>
  );
};
