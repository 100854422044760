import { gql, useApolloClient } from "@apollo/client";
import { ellipsisStyle, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { Chip, IconButton, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal.js";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import {
  OrganisationContractingPdfFragment,
  useRemoveOrganisationContractingPdfsMutation,
} from "./OrganisationContractingPdfsSection.generated.js";
import { useTranslate } from "@tolgee/react";
import { AddOrganisationContractingPdfModal } from "../modals/AddOrganisationContractingPdfModal.js";
import { ModifyOrganisationContractingPdfModal } from "../modals/ModifyOrganisationContractingPdfModal.js";

export const OrganisationContractingPdfsSection = ({
  organisationId,
  contractingPdfs,
}: {
  organisationId: string;
  contractingPdfs: OrganisationContractingPdfFragment[];
}) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  const { enqueueSnackbar } = useSnackbar();

  const title = t("Contracting PDF templates", {
    ns: "OrganisationSettings",
  });

  const modalModifyTitle = t("Customize contracting quote PDF template", {
    ns: "OrganisationSettings",
  });
  const modalAddTitle = t("Add new contracting quote PDF template", {
    ns: "OrganisationSettings",
  });

  const client = useApolloClient();

  const [removeContractingPdf, { loading: removing }] =
    useRemoveOrganisationContractingPdfsMutation({
      client,
      refetchQueries: [namedOperations.Query.OrganisationSettingsDocuments],
      awaitRefetchQueries: true,
    });

  const handleRemove = async (contractingPdfId: string) => {
    try {
      await removeContractingPdf({
        variables: {
          input: {
            contractingPdfId: contractingPdfId,
          },
        },
      });
      enqueueSnackbar(
        t("Contracting quote PDF template removed", {
          ns: "OrganisationSettings",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Stack direction="column" spacing={1} width="100%">
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography>{title}</Typography>
        <ModalOpenButton
          Modal={AddOrganisationContractingPdfModal}
          modalProps={{ organisationId, title: modalAddTitle }}
        >
          <IconButton color="secondary" size="small">
            <AddIcon />
          </IconButton>
        </ModalOpenButton>
      </Stack>
      {contractingPdfs.map(contractingPdf => (
        <Stack
          key={contractingPdf.id}
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            style={ellipsisStyle}
          >
            <Typography variant="body2" style={ellipsisStyle} component="div">
              {contractingPdf.title}
            </Typography>
            {contractingPdf.isEnabled && (
              <Chip
                size={"small"}
                variant="outlined"
                color="success"
                label={t("Enabled", {
                  ns: "Global",
                })}
              />
            )}
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            style={ellipsisStyle}
          >
            <ModalOpenButton
              Modal={ModifyOrganisationContractingPdfModal}
              modalProps={{
                organisationId,
                title: modalModifyTitle,
                contractingPdf,
              }}
            >
              <IconButton color="secondary" size="small">
                <EditIcon />
              </IconButton>
            </ModalOpenButton>

            <ModalOpenButton
              Modal={ConfirmModal}
              modalProps={{
                title: t("Confirm deletion", {
                  ns: "Global",
                }),
                text: t("Deletion cannot be undone. Do you want to continue?", {
                  ns: "Global",
                }),
                handleConfirm: async () => {
                  await handleRemove(contractingPdf.id);
                },
              }}
            >
              <IconButton disabled={removing} color="secondary" size="small">
                <DeleteIcon />
              </IconButton>
            </ModalOpenButton>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
