import { getImageUrl } from "@msys/common";
import { ellipsisStyle, TypographyWithMaxNumberOfLines } from "@msys/ui";
import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import defaultImageUrl from "../../assets/images/no-image.png";
import { isPredefinedTopic, useContentTopics } from "./useContentTopics.js";

interface Props extends Pick<ButtonBaseProps, "onClick"> {
  title: string;
  group: string;
  previewImageUrl?: string;
}

export const ArticleTeaserItem = ({
  title,
  group,
  previewImageUrl,
  onClick,
}: Props) => {
  const { t } = useTranslate("Contents");
  const { topicIcons } = useContentTopics();
  const Icon = group && isPredefinedTopic(group) ? topicIcons[group] : null;

  return (
    <ButtonBase
      onClick={onClick}
      sx={theme => ({
        textAlign: "left",
        transition: "background-color 0.2s ease-out",
        backgroundColor: theme.palette.primary.main,
        ["&:hover:not(:has(button:hover)):not(:has(a:hover))"]: {
          backgroundColor: theme.palette.primary.light,
        },
        p: 0.5,
        borderRadius: "4px",
        width: "100%",
        display: "flex",
        justifyContent: "stretch",
      })}
      focusRipple
    >
      <Stack spacing={1.5} direction="row" width="100%" flex={1}>
        <Box
          flexShrink="0"
          flexGrow="0"
          height={72}
          width={100}
          overflow="hidden"
          display="flex"
        >
          <img
            draggable={false}
            src={getImageUrl({
              url: previewImageUrl || defaultImageUrl,
              width: 240,
            })}
            alt={t("Content cover photo")}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "2px",
            }}
          />
        </Box>
        <Stack
          flex={1}
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={0.5}
          minWidth={0}
          py={0.5}
        >
          {group ? (
            <Typography
              variant="caption"
              color="warning.light"
              fontWeight="bold"
              style={ellipsisStyle}
              component="div"
            >
              {Icon ? (
                <Stack direction="row" spacing={0.5} style={ellipsisStyle}>
                  <Icon fontSize="extra-small" />
                  <span style={ellipsisStyle}>{group}</span>
                </Stack>
              ) : (
                group
              )}
            </Typography>
          ) : (
            <Typography
              variant="caption"
              color="grey.300"
              fontWeight="bold"
              style={ellipsisStyle}
              component="div"
            >
              {t("Other")}
            </Typography>
          )}
          <TypographyWithMaxNumberOfLines
            variant="body2"
            $maxLines={2}
            color="common.white"
          >
            {title}
          </TypographyWithMaxNumberOfLines>
        </Stack>
      </Stack>
    </ButtonBase>
  );
};
