import { Drawer } from "@mui/material";
import React from "react";
import { CSSObject, withStyles } from "../../styles.js";

const RightHandDrawer = withStyles(Drawer, theme => ({
  modal: {
    position: "absolute",
  } as CSSObject,
  paper: {
    position: "absolute",
    maxWidth: "100%",
    pointerEvents: "all",
    zIndex: 0,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: "100vw",
    },
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.layout.columnWidth.lg,
    },
  } as CSSObject,
}));

export function RightHandSidebar({ children }: React.PropsWithChildren<{}>) {
  return (
    <RightHandDrawer
      open
      anchor="right"
      variant="persistent"
      PaperProps={{
        variant: "elevation",
        elevation: 0,
      }}
    >
      {children}
    </RightHandDrawer>
  );
}
