/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PimSearchSupplierCatalogues__OrganisationWriteAccessQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PimSearchSupplierCatalogues__OrganisationWriteAccessQuery = { pimSearchSupplierCatalogues: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchSupplierCataloguesResult', supplierCatalogues: Array<{ __typename: 'PimSupplierCatalogue', id: string }> } };

export type PimGetSupplierCatalogue__OrganisationWriteAccessQueryVariables = Types.Exact<{
  supplierCatalogueId: Types.Scalars['ID']['input'];
  supplierId: Types.Scalars['ID']['input'];
}>;


export type PimGetSupplierCatalogue__OrganisationWriteAccessQuery = { pimGetSupplierCatalogue: { __typename: 'MissingCapabilities' } | { __typename: 'PimGetSupplierCatalogueResult', supplierCatalogue: { __typename: 'PimSupplierCatalogue', id: string, supplierCatalogueAccess: { __typename: 'PimSupplierCatalogueAccess', organisationCanWrite: boolean } } } };

export type SupplierCatalogueAccess__OrganisationWriteAccessFragment = { __typename: 'PimSupplierCatalogue', id: string, supplierCatalogueAccess: { __typename: 'PimSupplierCatalogueAccess', organisationCanWrite: boolean } };

export const SupplierCatalogueAccess__OrganisationWriteAccessFragmentDoc = gql`
    fragment SupplierCatalogueAccess__OrganisationWriteAccess on PimSupplierCatalogue {
  id
  supplierCatalogueAccess {
    organisationCanWrite
  }
}
    `;
export const PimSearchSupplierCatalogues__OrganisationWriteAccessDocument = gql`
    query PimSearchSupplierCatalogues__OrganisationWriteAccess {
  pimSearchSupplierCatalogues(
    filters: {organisationCanWrite: true, organisationIsSubscribed: true}
    limit: 1
  ) {
    ... on PimSearchSupplierCataloguesResult {
      supplierCatalogues {
        id
      }
    }
  }
}
    `;

/**
 * __usePimSearchSupplierCatalogues__OrganisationWriteAccessQuery__
 *
 * To run a query within a React component, call `usePimSearchSupplierCatalogues__OrganisationWriteAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `usePimSearchSupplierCatalogues__OrganisationWriteAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePimSearchSupplierCatalogues__OrganisationWriteAccessQuery({
 *   variables: {
 *   },
 * });
 */
export function usePimSearchSupplierCatalogues__OrganisationWriteAccessQuery(baseOptions?: Apollo.QueryHookOptions<PimSearchSupplierCatalogues__OrganisationWriteAccessQuery, PimSearchSupplierCatalogues__OrganisationWriteAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PimSearchSupplierCatalogues__OrganisationWriteAccessQuery, PimSearchSupplierCatalogues__OrganisationWriteAccessQueryVariables>(PimSearchSupplierCatalogues__OrganisationWriteAccessDocument, options);
      }
export function usePimSearchSupplierCatalogues__OrganisationWriteAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PimSearchSupplierCatalogues__OrganisationWriteAccessQuery, PimSearchSupplierCatalogues__OrganisationWriteAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PimSearchSupplierCatalogues__OrganisationWriteAccessQuery, PimSearchSupplierCatalogues__OrganisationWriteAccessQueryVariables>(PimSearchSupplierCatalogues__OrganisationWriteAccessDocument, options);
        }
export function usePimSearchSupplierCatalogues__OrganisationWriteAccessSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PimSearchSupplierCatalogues__OrganisationWriteAccessQuery, PimSearchSupplierCatalogues__OrganisationWriteAccessQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PimSearchSupplierCatalogues__OrganisationWriteAccessQuery, PimSearchSupplierCatalogues__OrganisationWriteAccessQueryVariables>(PimSearchSupplierCatalogues__OrganisationWriteAccessDocument, options);
        }
export type PimSearchSupplierCatalogues__OrganisationWriteAccessQueryHookResult = ReturnType<typeof usePimSearchSupplierCatalogues__OrganisationWriteAccessQuery>;
export type PimSearchSupplierCatalogues__OrganisationWriteAccessLazyQueryHookResult = ReturnType<typeof usePimSearchSupplierCatalogues__OrganisationWriteAccessLazyQuery>;
export type PimSearchSupplierCatalogues__OrganisationWriteAccessSuspenseQueryHookResult = ReturnType<typeof usePimSearchSupplierCatalogues__OrganisationWriteAccessSuspenseQuery>;
export type PimSearchSupplierCatalogues__OrganisationWriteAccessQueryResult = Apollo.QueryResult<PimSearchSupplierCatalogues__OrganisationWriteAccessQuery, PimSearchSupplierCatalogues__OrganisationWriteAccessQueryVariables>;
export const PimGetSupplierCatalogue__OrganisationWriteAccessDocument = gql`
    query PimGetSupplierCatalogue__OrganisationWriteAccess($supplierCatalogueId: ID!, $supplierId: ID!) {
  pimGetSupplierCatalogue(
    supplierCatalogueId: $supplierCatalogueId
    supplierId: $supplierId
  ) {
    ... on PimGetSupplierCatalogueResult {
      supplierCatalogue {
        ...SupplierCatalogueAccess__OrganisationWriteAccess
      }
    }
  }
}
    ${SupplierCatalogueAccess__OrganisationWriteAccessFragmentDoc}`;

/**
 * __usePimGetSupplierCatalogue__OrganisationWriteAccessQuery__
 *
 * To run a query within a React component, call `usePimGetSupplierCatalogue__OrganisationWriteAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `usePimGetSupplierCatalogue__OrganisationWriteAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePimGetSupplierCatalogue__OrganisationWriteAccessQuery({
 *   variables: {
 *      supplierCatalogueId: // value for 'supplierCatalogueId'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function usePimGetSupplierCatalogue__OrganisationWriteAccessQuery(baseOptions: Apollo.QueryHookOptions<PimGetSupplierCatalogue__OrganisationWriteAccessQuery, PimGetSupplierCatalogue__OrganisationWriteAccessQueryVariables> & ({ variables: PimGetSupplierCatalogue__OrganisationWriteAccessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PimGetSupplierCatalogue__OrganisationWriteAccessQuery, PimGetSupplierCatalogue__OrganisationWriteAccessQueryVariables>(PimGetSupplierCatalogue__OrganisationWriteAccessDocument, options);
      }
export function usePimGetSupplierCatalogue__OrganisationWriteAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PimGetSupplierCatalogue__OrganisationWriteAccessQuery, PimGetSupplierCatalogue__OrganisationWriteAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PimGetSupplierCatalogue__OrganisationWriteAccessQuery, PimGetSupplierCatalogue__OrganisationWriteAccessQueryVariables>(PimGetSupplierCatalogue__OrganisationWriteAccessDocument, options);
        }
export function usePimGetSupplierCatalogue__OrganisationWriteAccessSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PimGetSupplierCatalogue__OrganisationWriteAccessQuery, PimGetSupplierCatalogue__OrganisationWriteAccessQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PimGetSupplierCatalogue__OrganisationWriteAccessQuery, PimGetSupplierCatalogue__OrganisationWriteAccessQueryVariables>(PimGetSupplierCatalogue__OrganisationWriteAccessDocument, options);
        }
export type PimGetSupplierCatalogue__OrganisationWriteAccessQueryHookResult = ReturnType<typeof usePimGetSupplierCatalogue__OrganisationWriteAccessQuery>;
export type PimGetSupplierCatalogue__OrganisationWriteAccessLazyQueryHookResult = ReturnType<typeof usePimGetSupplierCatalogue__OrganisationWriteAccessLazyQuery>;
export type PimGetSupplierCatalogue__OrganisationWriteAccessSuspenseQueryHookResult = ReturnType<typeof usePimGetSupplierCatalogue__OrganisationWriteAccessSuspenseQuery>;
export type PimGetSupplierCatalogue__OrganisationWriteAccessQueryResult = Apollo.QueryResult<PimGetSupplierCatalogue__OrganisationWriteAccessQuery, PimGetSupplierCatalogue__OrganisationWriteAccessQueryVariables>;