import { gql, useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import { assign } from "lodash-es";
import React from "react";
import {
  DefineItemProductSearchFilterExpressionsInput,
  ItemProductSearchFilterBrandFilterComputed,
  ItemProductSearchFilterBrandLineFilterComputed,
  ItemProductSearchFilterPriceFilterComputed,
  ItemProductSearchFilterPropertyFilterComputed,
  ItemProductSearchFilterPropertyFilterExpressionInput,
} from "../../../clients/graphqlTypes.js";
import { ItemProductSearchFilterPropertyFilterComputedFragment } from "../products/productSearchFilters.generated.js";
import { useProductSearchFiltersSection_DefineProductSearchFilterExpressionsMutation } from "./useProductSearchFilterExpressionMutations.generated.js";

type AllowedProductSearchFilterExpressionKey = keyof Omit<
  DefineItemProductSearchFilterExpressionsInput,
  "projectId" | "docId" | "itemId"
>;
type ProductSearchFilterExpressionsValue =
  DefineItemProductSearchFilterExpressionsInput[AllowedProductSearchFilterExpressionKey];
export type HandleSetProductSearchFilterExpression = (
  key: AllowedProductSearchFilterExpressionKey,
  value: ProductSearchFilterExpressionsValue
) => Promise<void>;
export type HandleSetProductSearchFilterPropertyExpression = (
  propertyKey: string,
  value: DefineItemProductSearchFilterExpressionsInput["itemProductSearchFilterPropertyFilterExpressions"][number]
) => Promise<void>;

export function useProductSearchFilterExpressionMutations({
  projectId,
  docId,
  itemId,
  listPriceSearchFilterExpression,
  brandSearchFilterExpression,
  brandLineSearchFilterExpression,
  propertySearchFiltersExpressions,
  refetchQueries,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  listPriceSearchFilterExpression:
    | ItemProductSearchFilterPriceFilterComputed
    | undefined
    | null;
  brandSearchFilterExpression:
    | ItemProductSearchFilterBrandFilterComputed
    | undefined
    | null;
  brandLineSearchFilterExpression:
    | ItemProductSearchFilterBrandLineFilterComputed
    | undefined
    | null;
  propertySearchFiltersExpressions: ItemProductSearchFilterPropertyFilterComputed[];
  refetchQueries?: string[];
}) {
  const client = useApolloClient();
  const [defineItemProductSearchFilterExpressions] =
    useProductSearchFiltersSection_DefineProductSearchFilterExpressionsMutation(
      {
        client,
        refetchQueries,
      }
    );

  const handleSetProductSearchFilterExpression = React.useCallback(
    async (
      key: AllowedProductSearchFilterExpressionKey,
      value: ProductSearchFilterExpressionsValue
    ) => {
      const existingExpressions: Pick<
        DefineItemProductSearchFilterExpressionsInput,
        AllowedProductSearchFilterExpressionKey
      > = {
        itemProductSearchFilterBrandFilterExpression:
          brandSearchFilterExpression
            ? brandSearchFilterExpression.__typename ===
              "EntitySearchTextFilterComputed"
              ? {
                  textFilter: {
                    expr: brandSearchFilterExpression.expr,
                    operatorText: brandSearchFilterExpression.operatorText,
                  },
                }
              : brandSearchFilterExpression.__typename ===
                  "EntitySearchTextInFilterComputed"
                ? {
                    textInFilter: {
                      expr: brandSearchFilterExpression.expr,
                      operatorTextIn:
                        brandSearchFilterExpression.operatorTextIn,
                    },
                  }
                : assertNever(brandSearchFilterExpression)
            : undefined,
        itemProductSearchFilterBrandLineFilterExpression:
          brandLineSearchFilterExpression
            ? brandLineSearchFilterExpression.__typename ===
              "EntitySearchTextFilterComputed"
              ? {
                  textFilter: {
                    expr: brandLineSearchFilterExpression.expr,
                    operatorText: brandLineSearchFilterExpression.operatorText,
                  },
                }
              : brandLineSearchFilterExpression.__typename ===
                  "EntitySearchTextInFilterComputed"
                ? {
                    textInFilter: {
                      expr: brandLineSearchFilterExpression.expr,
                      operatorTextIn:
                        brandLineSearchFilterExpression.operatorTextIn,
                    },
                  }
                : assertNever(brandLineSearchFilterExpression)
            : undefined,
        itemProductSearchFilterListPriceFilterExpression:
          listPriceSearchFilterExpression
            ? listPriceSearchFilterExpression.__typename ===
              "EntitySearchNumberBetweenFilterComputed"
              ? {
                  numberBetweenFilter: {
                    expr: listPriceSearchFilterExpression.expr,
                    operatorNumberBetween:
                      listPriceSearchFilterExpression.operatorNumberBetween,
                  },
                }
              : listPriceSearchFilterExpression.__typename ===
                  "EntitySearchNumberFilterComputed"
                ? {
                    numberFilter: {
                      expr: listPriceSearchFilterExpression.expr,
                      operatorNumber:
                        listPriceSearchFilterExpression.operatorNumber,
                    },
                  }
                : assertNever(listPriceSearchFilterExpression)
            : undefined,
        itemProductSearchFilterPropertyFilterExpressions:
          propertySearchFiltersExpressions.map(
            productSearchFilterPropertyFilterExpression2Input
          ),
      };

      await defineItemProductSearchFilterExpressions({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            ...assign(existingExpressions, { [key]: value }),
          },
        },
      });
    },
    [
      brandLineSearchFilterExpression,
      brandSearchFilterExpression,
      defineItemProductSearchFilterExpressions,
      docId,
      itemId,
      listPriceSearchFilterExpression,
      projectId,
      propertySearchFiltersExpressions,
    ]
  );
  const handleSetProductSearchFilterPropertyExpression = (
    propertyKey: string,
    value: DefineItemProductSearchFilterExpressionsInput["itemProductSearchFilterPropertyFilterExpressions"][number]
  ) => {
    return handleSetProductSearchFilterExpression(
      "itemProductSearchFilterPropertyFilterExpressions",
      [
        ...propertySearchFiltersExpressions
          .filter(filter => {
            return filter.key !== propertyKey;
          })
          .map(productSearchFilterPropertyFilterExpression2Input),
        value,
      ]
    );
  };
  const handleDeleteProductSearchFilterExpression = async (
    key: Exclude<
      AllowedProductSearchFilterExpressionKey,
      "EntitySearchFilterPropertyFilterExpressions"
    >
  ) => {
    return handleSetProductSearchFilterExpression(key, null);
  };
  const handleDeleteProductSearchFilterPropertyExpression = async (
    propertyKey: string
  ) => {
    return handleSetProductSearchFilterExpression(
      "itemProductSearchFilterPropertyFilterExpressions",
      propertySearchFiltersExpressions
        .filter(filter => filter.key !== propertyKey)
        .map(productSearchFilterPropertyFilterExpression2Input)
    );
  };

  return {
    handleSetProductSearchFilterExpression,
    handleSetProductSearchFilterPropertyExpression,
    handleDeleteProductSearchFilterExpression,
    handleDeleteProductSearchFilterPropertyExpression,
    defineItemProductSearchFilterExpressions,
  };
}

export function productSearchFilterPropertyFilterExpression2Input(
  filter: ItemProductSearchFilterPropertyFilterComputedFragment
): ItemProductSearchFilterPropertyFilterExpressionInput {
  switch (filter.__typename) {
    case "EntitySearchPropertyFilterBoolInFilterComputed": {
      return {
        boolInFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorBoolIn: filter.operatorBoolIn,
        },
      };
    }
    case "EntitySearchPropertyFilterBoolFilterComputed": {
      return {
        boolFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorBool: filter.operatorBool,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberInFilterComputed": {
      return {
        numberInFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumberIn: filter.operatorNumberIn,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
      return {
        numberBetweenFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumberBetween: filter.operatorNumberBetween,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberFilterComputed": {
      return {
        numberFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumber: filter.operatorNumber,
        },
      };
    }
    case "EntitySearchPropertyFilterTextInFilterComputed": {
      return {
        textInFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorTextIn: filter.operatorTextIn,
        },
      };
    }
    case "EntitySearchPropertyFilterTextFilterComputed": {
      return {
        textFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorText: filter.operatorText,
        },
      };
    }
    default: {
      assertNever(filter);
    }
  }
}
