/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type WorkSession__ItemEntityFragment = { __typename: 'WorkSession', id: string, till?: any | null, from?: any | null, date?: any | null, duration?: number | null, isCorrection: boolean, assignee?: { __typename: 'User', id: string, firstname: string, familyname: string } | null, author: { __typename: 'User', id: string, firstname: string, familyname: string } };

export const WorkSession__ItemEntityFragmentDoc = gql`
    fragment WorkSession__ItemEntity on WorkSession {
  id
  till
  from
  date
  duration
  assignee {
    id
    firstname
    familyname
  }
  author {
    id
    firstname
    familyname
  }
  isCorrection
}
    `;