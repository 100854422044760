import { RadioGroupOption } from "@msys/ui";
import { RadioGroupProps } from "@mui/material";
import React from "react";
import { ALL_CONTRACT_TYPES } from "../../constants.js";
import {
  useContractTypeDescriptionLabels,
  useContractTypeNameLabels,
} from "./ContractTypeField.js";
import { RadioGroupField } from "./RadioGroupField.js";

export const ContractTypeRadioGroupField = (
  props: { disabled?: boolean; name: string } & RadioGroupProps
) => {
  const nameLabels = useContractTypeNameLabels();
  const descriptionLabels = useContractTypeDescriptionLabels();
  const options: RadioGroupOption[] = React.useMemo(
    () =>
      ALL_CONTRACT_TYPES.map(contractType => ({
        label: nameLabels[contractType],
        subLabel: descriptionLabels[contractType],
        value: contractType,
      })),
    [nameLabels, descriptionLabels]
  );
  return <RadioGroupField {...props} options={options} condensed={false} />;
};
