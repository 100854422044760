import { useTranslate } from "@tolgee/react";
import moment from "moment";
import { EntityNumber } from "../../../commons/EntityNumber.js";
import { TimeReport__ContractorFragment } from "../../../main-routes/projects/ProjectTimeReportPreview.generated.js";
import { DocumentPreviewHeader } from "../../documents/DocumentPreviewHeader.js";
import { TimeReportHeader__ProjectFragment } from "./TimeReportHeader.generated.js";

interface Props {
  project: TimeReportHeader__ProjectFragment;
  contractor: TimeReport__ContractorFragment;
  reportNumber: string;
  createdAt?: any;
}

export const TimeReportHeader = ({
  project: { number: projectNumber, crmOrganisation },
  contractor,
  reportNumber,
  createdAt,
}: Props) => {
  const { t } = useTranslate("TimeReports");

  if (!contractor) throw new Error("Contractor is missing");

  if (!crmOrganisation) throw new Error("Crm organisation is missing");

  const client = crmOrganisation;

  const documentData = [
    {
      label: t("Project No"),
      value: <EntityNumber noLabel number={projectNumber} />,
    },
    {
      label: t("Client No"),
      value: <EntityNumber noLabel number={client?.number} />,
    },
    {
      label: t("Report No"),
      value: <EntityNumber noLabel number={reportNumber} />,
    },
    {
      label: t("Issued on"),
      value: moment(createdAt).format("L"),
    },
  ];

  return (
    <DocumentPreviewHeader
      recipient={{
        contactName: client.contactPerson?.fullname ?? null,
        organisationName: client.title,
        address: client.billingAddress ?? client.branchAddress ?? null,
      }}
      sender={{
        organisationName: contractor?.title,
        contactName: contractor?.representive?.fullname ?? null,
        contactPhones: contractor?.representive?.phones ?? null,
        contactEmail: contractor?.representive?.email ?? null,
        address:
          contractor?.billingAddress ?? contractor?.branchAddress ?? null,
      }}
      documentData={documentData}
    />
  );
};
