import { useApolloClient } from "@apollo/client";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { App, AppInfo } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Dialog } from "@capacitor/dialog";
import { SplashScreen } from "@capacitor/splash-screen";
import { BundleInfo, CapacitorUpdater } from "@capgo/capacitor-updater";
import { CardContainer, MenuItemWithIcon, ellipsisStyle } from "@msys/ui";
import { Flag as FlagIcon } from "@mui/icons-material";
import { PhoneIphone as PhoneIphoneIcon } from "@mui/icons-material";
import {
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { useFeatureWithToggle } from "../../../common/FeatureFlags.js";
import { VERSION } from "../../../environment.js";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { useUserData } from "../../auth/useUserData.js";
import { Page } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageGrid } from "../../commons/layout/PageGrid.js";
import { PageTopbarItem } from "../../commons/layout/PageSubmenu.js";
import { MOBILE_APP_ANDROID_URL, MOBILE_APP_IOS_URL } from "../../constants.js";
import { UserCalendarBox } from "../../features/users/boxes/UserCalendarBox.js";
import { UserNotificationSettingsBox } from "../../features/users/boxes/UserNotificationSettingsBox.js";
import { UserDeleteAccountButton } from "../../features/users/UserDeleteAccountButton.js";
import { useUserSettingsQuery } from "./UserSettings.generated.js";

interface Props {
  submenuItems: PageTopbarItem[];
}

export const UserSettings = ({ submenuItems }: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["UserProfile", "Global"]);

  const client = useApolloClient();
  const query = useUserSettingsQuery({
    client,
    variables: { userId: viewer.id },
  });
  const user = query.data?.user;

  return (
    <Page
      title={t("My settings", { ns: "UserProfile" })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
          <>
            {user && <UserNotificationSettingsBox user={user} />}
            <RestrictedByCapabilityWithDebug capability="FEATURE_FLAGS">
              <UserFeatureFlagsBox />
            </RestrictedByCapabilityWithDebug>
            {Capacitor.isNativePlatform() && <MobileAppVersionBox />}
          </>
          <>
            <RestrictedByCapabilityWithDebug capability="PLANNING">
              <UserCalendarBox userId={viewer.id} />
            </RestrictedByCapabilityWithDebug>
            <Box>
              <UserDeleteAccountButton />
            </Box>
          </>
        </PageGrid>
      </PageContainer>
    </Page>
  );
};

function UserFeatureFlagsBox() {
  const { t } = useTranslate(["UserProfile"]);
  const { currentUser } = useUserData();

  const debugOutputFeature = useFeatureWithToggle("DebugOutput");
  const clientVisibilityFeature = useFeatureWithToggle("ClientVisibility");
  const shopFeature = useFeatureWithToggle("Shop");
  const marketplaceFeature = useFeatureWithToggle("Marketplace");
  const threeDDevelopFeature = useFeatureWithToggle("ThreeDDevelop");
  const animationsFeature = useFeatureWithToggle("Animations");
  const crmEmailContentDebug = useFeatureWithToggle("CrmEmailContentDebug");
  const articleListsFeature = useFeatureWithToggle("ArticleLists");
  const templateDebugFeature = useFeatureWithToggle("TemplateDebug");
  const quoteExternalIdentifierFeature = useFeatureWithToggle(
    "QuoteExternalIdentifier"
  );
  const supplierDiscountGroupVariantsFeature = useFeatureWithToggle(
    "SupplierDiscountGroupVariants"
  );
  const docSharingFeature = useFeatureWithToggle("DocSharing");
  const viewerPermissionDetailsFeature = useFeatureWithToggle(
    "ViewerPermissionDetails"
  );

  const menuItems: React.ReactNode[] = [];

  if (currentUser) {
    menuItems.push(
      ...[
        <Box key="tree-label" color={"gray"} mx={1} my={1 / 2}>
          <Typography variant="body2" gutterBottom>
            Tree
          </Typography>
        </Box>,
        <ToggleMenuItem
          id="client-visibility-checkbox"
          key="client-visibility-checkbox"
          label={"Client Visibility"}
          isChecked={clientVisibilityFeature.enabled}
          handleToggle={clientVisibilityFeature.toggle}
        />,
        <Divider key="tree-divider" />,
        <Box key="features-label" color={"gray"} mx={1} my={1 / 2}>
          <Typography variant="body2" gutterBottom>
            Features
          </Typography>
        </Box>,
        <ToggleMenuItem
          id="shop-checkbox"
          key="shop-checkbox"
          label={"Shop"}
          isChecked={shopFeature.enabled}
          handleToggle={shopFeature.toggle}
        />,
        <ToggleMenuItem
          id="marketplace-checkbox"
          key="marketplace-checkbox"
          label={"Marketplace"}
          isChecked={marketplaceFeature.enabled}
          handleToggle={marketplaceFeature.toggle}
        />,
        <ToggleMenuItem
          id="animations-checkbox"
          key="animations-checkbox"
          label={"Animations"}
          isChecked={animationsFeature.enabled}
          handleToggle={animationsFeature.toggle}
        />,
        <ToggleMenuItem
          id="article-lists"
          key="article-lists"
          label={"Article lists"}
          isChecked={articleListsFeature.enabled}
          handleToggle={articleListsFeature.toggle}
        />,
        <ToggleMenuItem
          id="quote-external-identifier"
          key="quote-external-identifier"
          label={"Quote external identifier"}
          isChecked={quoteExternalIdentifierFeature.enabled}
          handleToggle={quoteExternalIdentifierFeature.toggle}
        />,
        <ToggleMenuItem
          id="supplier-discount-group-variants"
          key="supplier-discount-group-variants"
          label={"Supplier discount group variants"}
          isChecked={supplierDiscountGroupVariantsFeature.enabled}
          handleToggle={supplierDiscountGroupVariantsFeature.toggle}
        />,
        <ToggleMenuItem
          id="docSharing"
          key="docSharing"
          label={"Doc Sharing"}
          isChecked={docSharingFeature.enabled}
          handleToggle={docSharingFeature.toggle}
        />,
        <ToggleMenuItem
          id="viewerPermissionDetailsFeature"
          key="viewerPermissionDetailsFeature"
          label={"Viewer Permission Details"}
          isChecked={viewerPermissionDetailsFeature.enabled}
          handleToggle={viewerPermissionDetailsFeature.toggle}
        />,
        <Divider key="debug-divider" />,
        <Box key="debug-label" color={"gray"} mx={1} my={1 / 2}>
          <Typography variant="body2" gutterBottom>
            Debug
          </Typography>
        </Box>,
        <ToggleMenuItem
          id="debug-output-checkbox"
          key="debug-output-checkbox"
          label={"Debug Output"}
          isChecked={debugOutputFeature.enabled}
          handleToggle={debugOutputFeature.toggle}
        />,
        <ToggleMenuItem
          id="template-debug"
          key="template-debug"
          label={"Template Debug"}
          isChecked={templateDebugFeature.enabled}
          handleToggle={templateDebugFeature.toggle}
        />,
        <ToggleMenuItem
          id="mail"
          key="mail"
          label={"CRM email content debug"}
          isChecked={crmEmailContentDebug.enabled}
          handleToggle={crmEmailContentDebug.toggle}
        />,
      ]
    );

    const apiUrl = import.meta.env.VITE_GRAPHQL_URL as string;
    if (
      !apiUrl ||
      apiUrl?.startsWith("https://app") ||
      apiUrl?.startsWith("https://www.dev") ||
      apiUrl?.startsWith("https://www.stg") ||
      apiUrl === "/api/graphql"
    ) {
      menuItems.push(
        ...[
          <ToggleMenuItem
            id="threeD-develop-checkbox"
            key="threeD-develop-checkbox"
            label={"ThreeD Develop"}
            isChecked={threeDDevelopFeature.enabled}
            handleToggle={threeDDevelopFeature.toggle}
          />,
        ]
      );
    }
  }

  return (
    <CardContainer
      Icon={<FlagIcon />}
      isExpandable
      title={t("Feature flags", {
        ns: "UserProfile",
      })}
    >
      {menuItems}
    </CardContainer>
  );
}

export function ToggleMenuItem({
  id,
  label,
  isChecked,
  handleToggle,
}: {
  id: string;
  label: string;
  isChecked: boolean;
  handleToggle: () => void;
}) {
  return (
    <MenuItem key={`features-${id}`} component="label" htmlFor={id}>
      <ListItemIcon>
        <span style={{ marginLeft: -16 }}>
          <Switch id={id} checked={isChecked} onChange={handleToggle} />
        </span>
      </ListItemIcon>
      <Typography color="textPrimary">{label}</Typography>
    </MenuItem>
  );
}

function MobileAppVersionBox() {
  const { t } = useTranslate(["UserProfile"]);

  return (
    <CardContainer
      Icon={<PhoneIphoneIcon />}
      isExpandable
      title={t("Mobile app", {
        ns: "UserProfile",
      })}
    >
      <MobileAppVersionMenuItem key="app-version" />
    </CardContainer>
  );
}

const MobileAppVersionMenuItem = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [info, setInfo] = React.useState<AppInfo | null>(null);
  const navigate = useNavigate();
  useEffectOnce(() => {
    if (!Capacitor.isNativePlatform()) return;
    (async () => {
      const info = await App.getInfo();
      setInfo(info);
    })();
  });
  return (
    <>
      <MenuItemWithIcon
        icon={<PhoneIphoneIcon color="primary" />}
        onClick={(event: React.MouseEvent<HTMLLIElement>) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <Typography color="textPrimary" style={ellipsisStyle}>
          Version: {info?.version ?? "–"}; build: {VERSION}
        </Typography>
      </MenuItemWithIcon>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={e => {
          if (anchorEl) setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={async () => {
            try {
              const result = await FirebaseAnalytics.getAppInstanceId();
              console.log(result);
              await Dialog.alert({
                title: "Instance info",
                message: [`ID: ${result.appInstanceId}`].join("\n"),
              });
            } catch (e) {
              if (e instanceof Error)
                enqueueSnackbar(e.message, { variant: "error" });
            }
          }}
        >
          Get instance info
        </MenuItem>
        <MenuItem
          onClick={async () => {
            const current = await CapacitorUpdater.current();
            const { bundles } = await CapacitorUpdater.list();
            console.log({ current, bundles });
            await Dialog.alert({
              title: "Version",
              message: [
                `Current: ${JSON.stringify(current)}`,
                `Bundles: ${JSON.stringify(bundles)}`,
              ].join("\n"),
            });
          }}
        >
          Get bundles info
        </MenuItem>
        <MenuItem
          onClick={async () => {
            const { value, cancelled } = await Dialog.prompt({
              title: "Download and set bundle",
              message: `Please enter zip-archive url`,
              inputPlaceholder: `Zip-archive url`,
              okButtonTitle: `Continue`,
            });

            if (cancelled || !value) return;

            let bundle: BundleInfo | null = null;

            try {
              const url = new URL(value);
              if (!url) throw new Error(`Wrong zip-archive url provided`);

              const [, fileName] = url.pathname.match(/\/([^/]+)\.zip$/) ?? [];
              if (!fileName) throw new Error(`No file name could be defined`);

              enqueueSnackbar(
                <div>
                  Downloading the update...
                  <span id="msys-version-update-percent"></span>
                </div>,
                {
                  variant: "default",
                  key: "msys-version-update",
                  persist: true,
                }
              );

              console.log("[Capacitor]", "Start downloading...", {
                url: value,
                version: fileName,
              });

              bundle = await CapacitorUpdater.download({
                url: value,
                version: fileName,
              });

              console.log(
                "[Capacitor]",
                "Download done!",
                "Bundle:",
                bundle.version
              );
            } catch (e) {
              if (e instanceof Error) {
                console.error("[Capacitor] download failed", e.message, e);
                enqueueSnackbar(e.message, { variant: "error" });
              }
            } finally {
              closeSnackbar("msys-version-update");
            }

            if (bundle) {
              try {
                // show the splashscreen to let the update happen
                await SplashScreen.show();

                console.log(
                  "[Capacitor]",
                  "Setting new bundle:",
                  bundle.version
                );

                await CapacitorUpdater.set(bundle);
              } catch (e) {
                if (e instanceof Error) {
                  console.error("[Capacitor] set failed", e.message, e);
                  enqueueSnackbar(e.message, { variant: "error" });
                }
              } finally {
                await SplashScreen.hide(); // in case the set fail, otherwise the new app will have to hide it
              }
            }
          }}
        >
          Download and set bundle
        </MenuItem>
        <MenuItem
          onClick={async () => {
            const { value, cancelled } = await Dialog.prompt({
              title: "Set bundle",
              message: `Please enter bundle ID`,
              inputPlaceholder: `Bundle ID`,
              okButtonTitle: `Continue`,
            });

            if (cancelled || !value) return;

            try {
              // show the splashscreen to let the update happen
              await SplashScreen.show();
              await CapacitorUpdater.set({ id: value });
            } catch (e) {
              if (e instanceof Error)
                enqueueSnackbar(e.message, { variant: "error" });
            } finally {
              await SplashScreen.hide(); // in case the set fail, otherwise the new app will have to hide it
            }
          }}
        >
          Set bundle
        </MenuItem>
        <MenuItem
          onClick={async () => {
            const { value, cancelled } = await Dialog.prompt({
              title: "Remove bundle",
              message: `Please enter bundle ID`,
              inputPlaceholder: `Bundle ID`,
              okButtonTitle: `Continue`,
            });

            if (cancelled || !value) return;

            try {
              await CapacitorUpdater.delete({ id: value });
            } catch (e) {
              if (e instanceof Error)
                enqueueSnackbar(e.message, { variant: "error" });
            }
          }}
        >
          Remove bundle
        </MenuItem>
        <MenuItem
          onClick={async () => {
            const { value } = await Dialog.confirm({
              title: "Reset bundle",
              message: `Reset app to a built-in bundle?`,
              okButtonTitle: `Yes, continue`,
            });

            if (!value) return;

            try {
              await CapacitorUpdater.reset();
            } catch (e) {
              if (e instanceof Error)
                enqueueSnackbar(e.message, { variant: "error" });
            }
          }}
        >
          Reset bundle
        </MenuItem>
        <MenuItem
          onClick={async () => {
            try {
              const { platform } = await Device.getInfo();

              if (platform === "ios") {
                window.location.href = MOBILE_APP_IOS_URL;
              }

              if (platform === "android") {
                window.location.href = MOBILE_APP_ANDROID_URL;
              }
            } catch (e) {
              if (e instanceof Error)
                enqueueSnackbar(e.message, { variant: "error" });
            }
          }}
        >
          Open App Store / Play Market
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/keycloak-login");
          }}
        >
          Keycloak login
        </MenuItem>
      </Menu>
    </>
  );
};
