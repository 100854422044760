import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import {
  Button,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { isNull, omit } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  PhoneInput,
  Skill,
  namedOperations,
} from "../../../../clients/graphqlTypes.js";
import { useFeature } from "../../../../common/FeatureFlags.js";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import {
  PHONE_REG_EXP,
  SITE_REG_EXP,
  prepareUrl,
} from "../../../commons/form-fields/helpers.js";
import { AddressComboField } from "../../addresses/AddressComboField.js";
import { AddressDetails__AddressFragment } from "../../addresses/Addresses.generated.js";
import { LabeledAddressValue } from "../../addresses/LabeledAddressValue.js";
import { Attachment } from "../../attachments/helpers.js";
import { LabeledPhoneValue } from "../../phones/LabeledPhoneValue.js";
import { PhonesField } from "../../phones/PhonesField.js";
import { defaultPhoneType } from "../../phones/usePhoneTypes.js";
import CategoriesField from "../../skill-categories/CategoriesField.js";
import { LabeledCategoriesValue } from "../../skill-categories/LabeledCategoriesValue.js";
import { OrganisationAvatar } from "../OrganisationAvatar.js";
import { AddOrganisationPublicProfileModal } from "../modals/AddOrganisationPublicProfileModal.js";
import {
  OrganisationDetailsBox_OrganisationFragment,
  OrganisationDetailsBox_ViewerOrganisationFragment,
  useModifyOrganisationAvatarMutation,
  useOrganisationDetailsForm_ModifyOrganisationMutation,
} from "./OrganisationDetailsBox.generated.js";

type Organisation =
  | OrganisationDetailsBox_OrganisationFragment
  | OrganisationDetailsBox_ViewerOrganisationFragment;

interface Props {
  organisation: Organisation;
  isViewerOrganisation: boolean;
}

export function OrganisationDetailsBox({
  organisation,
  isViewerOrganisation,
}: Props) {
  const viewer = useUserData().currentUser!;
  const debug = useFeature("DebugOutput");

  const client = useApolloClient();
  const [modifyOrganisation, { loading: modifyOrganisationLoading }] =
    useModifyOrganisationAvatarMutation({
      client,
      refetchQueries: [namedOperations.Query.OrganisationProfile],
    });

  async function onAttachment(attachment: Attachment | null) {
    await modifyOrganisation({
      variables: {
        input: {
          organisationId: organisation.id,
          logo: attachment
            ? {
                url: attachment.url,
                title: attachment.title,
                mimeType: attachment.mimeType,
              }
            : null,
        },
      },
    });
  }

  return (
    <CardContainer>
      <Stack direction={"column"} spacing={1} p={3 / 2}>
        <Stack direction={"row"} justifyContent="center" spacing={1} mb={1}>
          <RestrictedByOrganisationPermissionWithDebug
            permission="MANAGE_ORG"
            otherwise={
              <OrganisationAvatar organisationAvatar={organisation} size="l" />
            }
          >
            <OrganisationAvatar
              organisationAvatar={organisation}
              size="l"
              disabled={modifyOrganisationLoading}
              onAttachment={isViewerOrganisation ? onAttachment : undefined}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        </Stack>
        <Stack direction={"column"} spacing={1 / 2}>
          <Typography variant="h2" align="center">
            {organisation.title}
          </Typography>
          {debug && (
            <Typography variant="body2" align="center">
              {organisation.id}
            </Typography>
          )}
        </Stack>
        <RestrictedByOrganisationPermissionWithDebug
          permission="MANAGE_ORG"
          otherwise={
            <OrganisationDetailsData
              organisation={organisation}
              isViewerOrganisation={isViewerOrganisation}
            />
          }
        >
          {viewer.organisation.id === organisation.id &&
          !!viewer.roles.find(r => r.internalName === "ORG_ADMIN") ? (
            <OrganisationDetailsForm
              organisation={organisation}
              isViewerOrganisation={isViewerOrganisation}
            />
          ) : (
            <OrganisationDetailsData
              organisation={organisation}
              isViewerOrganisation={isViewerOrganisation}
            />
          )}
        </RestrictedByOrganisationPermissionWithDebug>
      </Stack>
    </CardContainer>
  );
}

function OrganisationDetailsData({
  organisation,
  isViewerOrganisation,
}: {
  organisation: Organisation;
  isViewerOrganisation: boolean;
}) {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);

  return (
    <Stack direction={"column"} spacing={1}>
      <LabeledValue
        label={
          isViewerOrganisation
            ? t("Name", {
                ns: "OrganisationProfile",
              })
            : t("Account Name", {
                ns: "OrganisationProfile",
              })
        }
      >
        {organisation.title ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Description", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.description ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <RestrictedByCapabilityWithDebug capability="PUBLIC_PROFILE">
        <Stack
          direction={"row"}
          justifyContent="space-between"
          spacing={1}
          alignItems="center"
        >
          <LabeledValue
            label={t("Public profile", {
              ns: "OrganisationProfile",
            })}
          >
            {organisation.profile ? (
              <MuiLink
                component={Link}
                to={`/org/${organisation.profile.slug}`}
              >
                /{organisation.profile.slug}
              </MuiLink>
            ) : (
              t("Not created", {
                ns: "Global",
              })
            )}
          </LabeledValue>
          {organisation.profile && (
            <Button
              component={Link}
              to={`/org/${organisation.profile.slug}`}
              endIcon={<OpenInNewIcon />}
              color="secondary"
              variant="text"
              size="extra-small"
              // @ts-ignore
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("View", {
                ns: "Global",
              })}
            </Button>
          )}
        </Stack>
      </RestrictedByCapabilityWithDebug>
      <Divider />
      <LabeledValue
        label={t("Website", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.website ? (
          <MuiLink
            href={prepareUrl(organisation.website)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {organisation.website}
          </MuiLink>
        ) : (
          t("Not set", {
            ns: "Global",
          })
        )}
      </LabeledValue>
      <LabeledValue
        label={t("Email", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.email ? (
          <MuiLink href={`mailto:${organisation.email}`}>
            {organisation.email}
          </MuiLink>
        ) : (
          t("Not set", {
            ns: "Global",
          })
        )}
      </LabeledValue>
      <LabeledPhoneValue
        label={t("Phone", {
          ns: "Global",
        })}
        phones={organisation.phones}
      />
      {organisation.isCraftsmanOrganisation && (
        <>
          <Divider />
          <LabeledCategoriesValue
            value={organisation.skillset}
            label={t("Services", {
              ns: "OrganisationProfile",
            })}
          />
        </>
      )}
      <Divider />
      <LabeledAddressValue
        label={t("Billing address", {
          ns: "OrganisationProfile",
        })}
        address={organisation.billingAddress}
      />
      <LabeledAddressValue
        label={t("Branch address", {
          ns: "OrganisationProfile",
        })}
        address={organisation.branchAddress}
      />
      {organisation.organisationType === "SUPPLIER" && (
        <LabeledAddressValue
          label={t("Pick-up location", {
            ns: "OrganisationProfile",
          })}
          address={organisation.pickupAddress}
        />
      )}
    </Stack>
  );
}

interface FormValues {
  title: string;
  description: string;
  billingAddress: AddressDetails__AddressFragment | null;
  branchAddress: AddressDetails__AddressFragment | null;
  pickupAddress: AddressDetails__AddressFragment | null;
  phones: PhoneInput[];
  website: string;
  email: string;
  skillset: Skill[];
}

function OrganisationDetailsForm({
  organisation,
  isViewerOrganisation,
}: {
  organisation: Organisation;
  isViewerOrganisation: boolean;
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["OrganisationProfile", "Global"]);
  const viewer = useUserData().currentUser!;

  const client = useApolloClient();
  const [modifyOrganisation] =
    useOrganisationDetailsForm_ModifyOrganisationMutation({
      client,
      refetchQueries: [namedOperations.Query.OrganisationProfile],
    });

  const isSupplier = organisation.organisationType === "SUPPLIER";

  const initialValues: FormValues = {
    title: organisation.title,
    description: organisation.description,
    email: organisation.email,
    website: organisation.website,
    billingAddress: organisation.billingAddress
      ? organisation.billingAddress
      : null,
    branchAddress: organisation.branchAddress
      ? organisation.branchAddress
      : null,
    pickupAddress: organisation.pickupAddress
      ? organisation.pickupAddress
      : null,
    phones:
      organisation.phones?.length > 0
        ? organisation.phones
        : [defaultPhoneType],
    skillset: organisation ? organisation.skillset : [],
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(
        isViewerOrganisation
          ? t("Name", {
              ns: "OrganisationProfile",
            })
          : t("Account Name", {
              ns: "OrganisationProfile",
            })
      )
      .required(),
    email: Yup.string()
      .label(
        t("Email", {
          ns: "OrganisationProfile",
        })
      )
      .email()
      .trim(),
    skillset: organisation.isCraftsmanOrganisation
      ? Yup.array()
          .label(
            t("Services", {
              ns: "OrganisationProfile",
            })
          )
          .required()
      : Yup.array().label(
          t("Services", {
            ns: "OrganisationProfile",
          })
        ),
    website: Yup.string()
      .label(
        t("Website", {
          ns: "OrganisationProfile",
        })
      )
      .matches(
        SITE_REG_EXP,
        t("Website is not valid", {
          ns: "OrganisationProfile",
        })
      ),
    phones: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        main: Yup.boolean(),
        type: Yup.string(),
        number: Yup.string().matches(
          PHONE_REG_EXP,
          t("Phone number is not valid", {
            ns: "OrganisationProfile",
          })
        ),
        // .required(t("Phone number is required")),
      })
    ),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      if (!isViewerOrganisation) {
        await modifyOrganisation({
          variables: {
            input: {
              organisationId: organisation.id,
              title: values.title,
              description: values.description,
              billingAddress: isNull(values.billingAddress)
                ? null
                : values.billingAddress
                  ? {
                      ...omit(values.billingAddress, "__typename", "id"),
                      location: values.billingAddress.location
                        ? omit(values.billingAddress.location, "__typename")
                        : null,
                    }
                  : undefined,
              branchAddress: isNull(values.branchAddress)
                ? null
                : values.branchAddress
                  ? {
                      ...omit(values.branchAddress, "__typename", "id"),
                      location: values.branchAddress.location
                        ? omit(values.branchAddress.location, "__typename")
                        : null,
                    }
                  : undefined,
              pickupAddress: isNull(values.pickupAddress)
                ? null
                : values.pickupAddress
                  ? {
                      ...omit(values.pickupAddress, "__typename", "id"),
                      location: values.pickupAddress.location
                        ? omit(values.pickupAddress.location, "__typename")
                        : null,
                    }
                  : undefined,
              website: values.website,
              email: values.email,
              phones: values.phones
                .filter(p => p.number.trim())
                .map(p => omit(p, "__typename", "id")),
              skillset: values.skillset,
            },
          },
        });
      } else {
        await modifyOrganisation({
          variables: {
            input: {
              title: values.title,
              description: values.description,
              billingAddress: isNull(values.billingAddress)
                ? null
                : values.billingAddress
                  ? {
                      ...omit(values.billingAddress, "__typename", "id"),
                      location: values.billingAddress.location
                        ? omit(values.billingAddress.location, "__typename")
                        : null,
                    }
                  : undefined,
              branchAddress: isNull(values.branchAddress)
                ? null
                : values.branchAddress
                  ? {
                      ...omit(values.branchAddress, "__typename", "id"),
                      location: values.branchAddress.location
                        ? omit(values.branchAddress.location, "__typename")
                        : null,
                    }
                  : undefined,
              pickupAddress: isNull(values.pickupAddress)
                ? null
                : values.pickupAddress
                  ? {
                      ...omit(values.pickupAddress, "__typename", "id"),
                      location: values.pickupAddress.location
                        ? omit(values.pickupAddress.location, "__typename")
                        : null,
                    }
                  : undefined,
              website: values.website,
              email: values.email,
              phones: values.phones
                .filter(p => p.number.trim())
                .map(p => omit(p, "__typename", "id")),
              skillset: values.skillset,
            },
          },
        });
      }
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack direction={"column"} spacing={1}>
            {isSupplier ? (
              <LabeledValue
                label={
                  isViewerOrganisation
                    ? t("Name", {
                        ns: "OrganisationProfile",
                      })
                    : t("Account Name", {
                        ns: "OrganisationProfile",
                      })
                }
              >
                {organisation.title ||
                  t("Not set", {
                    ns: "Global",
                  })}
              </LabeledValue>
            ) : (
              <Field
                component={TextField}
                name="title"
                label={
                  isViewerOrganisation
                    ? t("Name", {
                        ns: "OrganisationProfile",
                      })
                    : t("Account Name", {
                        ns: "OrganisationProfile",
                      })
                }
                required
                disabled={false}
              />
            )}
            <Field
              component={TextField}
              name="description"
              label={t("Description", {
                ns: "OrganisationProfile",
              })}
              disabled={false}
              multiline
            />
            <RestrictedByCapabilityWithDebug capability="PUBLIC_PROFILE">
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems="center"
                spacing={1}
              >
                <LabeledValue
                  label={t("Public profile", {
                    ns: "OrganisationProfile",
                  })}
                  boxProps={{ width: "auto" }}
                >
                  {organisation.profile ? (
                    <MuiLink
                      component={Link}
                      to={`/org/${organisation.profile.slug}`}
                    >
                      /{organisation.profile.slug}
                    </MuiLink>
                  ) : (
                    t("Not created", {
                      ns: "Global",
                    })
                  )}
                </LabeledValue>
                {organisation.profile ? (
                  <Button
                    component={Link}
                    to={`/org/${organisation.profile.slug}`}
                    endIcon={<OpenInNewIcon />}
                    color="secondary"
                    variant="text"
                    size="extra-small"
                    // @ts-ignore
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("View", {
                      ns: "Global",
                    })}
                  </Button>
                ) : (
                  <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
                    {viewer.organisation.id === organisation.id &&
                      viewer.organisation.isCraftsmanOrganisation && (
                        <ModalOpenButton
                          Modal={AddOrganisationPublicProfileModal}
                          modalProps={{
                            handleComplete: slug => {
                              if (slug) navigate(`/org/${slug}`);
                            },
                          }}
                        >
                          <Button
                            size="extra-small"
                            color="secondary"
                            variant="text"
                            startIcon={<AddIcon />}
                          >
                            {t("Create public profile", {
                              ns: "OrganisationProfile",
                            })}
                          </Button>
                        </ModalOpenButton>
                      )}
                  </RestrictedByOrganisationPermissionWithDebug>
                )}
              </Stack>
            </RestrictedByCapabilityWithDebug>
            <Divider />
            <Field
              component={TextField}
              disabled={false}
              name="website"
              label={t("Website", {
                ns: "OrganisationProfile",
              })}
            />
            <Field
              component={TextField}
              disabled={false}
              name="email"
              label={t("Email", {
                ns: "OrganisationProfile",
              })}
              type="email"
            />
            <PhonesField
              name="phones"
              buttonLabel={t("Add phone number", {
                ns: "OrganisationProfile",
              })}
              value={formikProps.values.phones}
              disabled={false}
            />
            {(organisation.isCraftsmanOrganisation || isSupplier) && (
              <>
                <Divider />
                <CategoriesField
                  name="skillset"
                  label={t("Services", {
                    ns: "OrganisationProfile",
                  })}
                  disabled={false}
                />
              </>
            )}
            <Divider />
            <AddressComboField
              label={t("Billing address", {
                ns: "OrganisationProfile",
              })}
              address={formikProps.values.billingAddress}
              handleChange={address =>
                formikProps.setFieldValue("billingAddress", address)
              }
            />
            <AddressComboField
              label={t("Branch address", {
                ns: "OrganisationProfile",
              })}
              address={formikProps.values.branchAddress}
              handleChange={address =>
                formikProps.setFieldValue("branchAddress", address)
              }
            />
            {isSupplier && (
              <AddressComboField
                label={t("Pick-up location", {
                  ns: "OrganisationProfile",
                })}
                address={formikProps.values.pickupAddress}
                handleChange={address =>
                  formikProps.setFieldValue("pickupAddress", address)
                }
              />
            )}
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
