import { gql, useApolloClient } from "@apollo/client";
import React from "react";
import { AvailableUsersContext } from "./AvailableUsersContext.js";
import {
  AvailableUsersQuery,
  useAvailableUsersQuery,
} from "./AvailableUsersProvider.generated.js";
import { useAuth } from "./useAuth.js";

export const AvailableUsersProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { isAuthenticated } = useAuth();

  const { availableUsers, isLoading, refetch } = useAvailableUsers({
    skip: !isAuthenticated,
  });

  return (
    <AvailableUsersContext.Provider
      value={{ availableUsers, isLoading, refetch }}
    >
      {children}
    </AvailableUsersContext.Provider>
  );
};

const EMPTY_ARRAY: AvailableUsersQuery["availableUsers"] = [];
function useAvailableUsers({ skip }: { skip: boolean }) {
  const client = useApolloClient();
  const query = useAvailableUsersQuery({ client, skip });

  const availableUsers = query.data?.availableUsers ?? EMPTY_ARRAY;

  return {
    availableUsers,
    isLoading: query.loading,
    refetch: query.refetch,
  };
}
