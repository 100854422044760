import { useApolloClient } from "@apollo/client";
import { CardContainer, ModalOpenButton } from "@msys/ui";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { Add as AddIcon } from "@mui/icons-material";
import { Phone as PhoneIcon } from "@mui/icons-material";
import { Divider, IconButton, List, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission.js";
import { ProfileListItem } from "../../../commons/ProfileListItem.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { ContactsIcon } from "../../crm-persons/ContactsIcon.js";
import { CrmPersonCreateModal } from "../../crm-persons/CrmPersonCreateModal.js";
import { UserAvatar } from "../../users/UserAvatar.js";
import { getMemberOptions } from "../../users/helpers.js";
import {
  CrmCompanyMemberRow__CrmPersonFragment,
  CrmCompanyMembersBox_CrmCompanyFragment,
  useModifyCrmOrganisationContactPersonMutation,
} from "./CrmCompanyMembersBox.generated.js";

interface FormValues {
  contactPersonId: string;
}

interface Props {
  crmCompany: CrmCompanyMembersBox_CrmCompanyFragment;
}

export function CrmCompanyMembersBox({ crmCompany }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate([
    "OrganisationInvite",
    "OrganisationRole",
    "OrganisationProfile",
  ]);

  const client = useApolloClient();
  const [modifyCrmOrganisation] = useModifyCrmOrganisationContactPersonMutation(
    {
      client,
    }
  );

  const initialValues: FormValues = {
    contactPersonId: crmCompany.contactPerson?.id ?? "",
  };

  const validationSchema = Yup.object().shape({
    contactPersonId: Yup.string()
      .label(
        t("Organisation representative", {
          ns: "OrganisationProfile",
        })
      )
      .required(),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyCrmOrganisation({
        variables: {
          organisations: [
            {
              id: crmCompany.id,
              contactPersonId: values.contactPersonId,
            },
          ],
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <CardContainer
        Icon={<ContactsIcon />}
        title={t("Contacts", {
          ns: "OrganisationInvite",
        })}
        itemCount={crmCompany.members.length}
        ActionButton={
          <ModalOpenButton
            Modal={CrmPersonCreateModal}
            modalProps={{
              crmCompanyId: crmCompany.id,
              refetchQueries: [namedOperations.Query.CrmCompanyProfile],
            }}
          >
            <IconButton
              color="primary"
              aria-label={t("Add contact", {
                ns: "OrganisationInvite",
              })}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </ModalOpenButton>
        }
      >
        <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_CRM">
          <Form>
            <Stack p={1} flexDirection="column">
              <SelectField
                name="contactPersonId"
                options={getMemberOptions(crmCompany.members)}
                label={t("Organisation representative", {
                  ns: "OrganisationProfile",
                })}
                disabled={crmCompany.members.length === 0}
              />
              <AutoSave />
            </Stack>
            <Divider />
          </Form>
        </RestrictedByOrganisationPermissionWithDebug>
        <List disablePadding>
          {[...crmCompany.members].sort(sortMemberByFirstname).map(member => (
            <CrmCompanyMemberRow
              key={member.id}
              member={member}
              organisationId={crmCompany.id}
              organisationContactPersonId={crmCompany.contactPerson!.id}
            />
          ))}
        </List>
      </CardContainer>
    </Formik>
  );
}

function CrmCompanyMemberRow({
  member,
  organisationId,
  organisationContactPersonId,
}: {
  member: CrmCompanyMemberRow__CrmPersonFragment;
  organisationId: string;
  organisationContactPersonId: string;
}) {
  const { t } = useTranslate(["OrganisationProfile"]);

  let primaryLabel = `${member.firstname} ${member.familyname}`.trim();
  if (primaryLabel.trim().length === 0) primaryLabel = member.email;
  if (primaryLabel.trim().length === 0) primaryLabel = member.email;

  return (
    <ProfileListItem
      button
      component={Link}
      to={`/crm/organisations/${organisationId}/users/${member.id}`}
      avatar={<UserAvatar userAvatar={member} size="m" />}
      primary={primaryLabel}
      ActionButtons={[
        ...(member.id === organisationContactPersonId
          ? [
              <Tooltip
                key="representive-icon"
                title={t("Organisation representative", {
                  ns: "OrganisationProfile",
                })}
              >
                <AccountCircleIcon fontSize="small" />
              </Tooltip>,
            ]
          : []),
        ...(member.phones.length > 0
          ? [
              <IconButton
                key="phone-button"
                size="small"
                color="primary"
                component="a"
                href={`tel:${member.phones[0].number}`}
              >
                <PhoneIcon />
              </IconButton>,
            ]
          : []),
      ]}
    />
  );
}

interface SortableMembership {
  firstname: string;
}

function sortMemberByFirstname(
  member1: SortableMembership,
  member2: SortableMembership
) {
  return member1.firstname.localeCompare(member2.firstname);
}
