/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type QuoteListItem_IncomingQuoteFragment = { __typename: 'IncomingQuote', id: string, title: string, createdAt: any, docActorContractorName: string, proposedTotalPrice: number, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean };

export type QuoteListItem_OutgoingQuoteFragment = { __typename: 'OutgoingQuote', id: string, title: string, createdAt: any, docActorContracteeName: string, docActorClientName: string, proposedTotalPrice: number, isPublished: boolean, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean };

export const QuoteListItem_IncomingQuoteFragmentDoc = gql`
    fragment QuoteListItem_IncomingQuote on IncomingQuote {
  id
  title
  createdAt
  docActorContractorName
  proposedTotalPrice
  isBinding
  agreement
  needsAgreement
}
    `;
export const QuoteListItem_OutgoingQuoteFragmentDoc = gql`
    fragment QuoteListItem_OutgoingQuote on OutgoingQuote {
  id
  title
  createdAt
  docActorContracteeName
  docActorClientName
  proposedTotalPrice
  isPublished
  isBinding
  agreement
  needsAgreement
}
    `;