import { gql, useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { ConfirmModal } from "../../../../commons/modals/ConfirmModal.js";
import { useUpdateAllLinksToLatestPublishedVersionMutation } from "./QuoteTemplateUpdateAllLinkingVersionsButton.generated.js";

interface Props {
  templateId: string;
  pathToDocList: string;
}

export function QuoteTemplateUpdateAllLinkingVersionsButton({
  templateId,
  pathToDocList,
}: Props) {
  const { t } = useTranslate(["Templates"]);

  return (
    <ModalOpenButton
      Modal={QuoteTemplateUpdateAllLinkingVersionsModal}
      modalProps={{ templateId, pathToDocList }}
    >
      <Button color="inherit">
        {t("Update all links", { ns: "Templates" })}
      </Button>
    </ModalOpenButton>
  );
}

function QuoteTemplateUpdateAllLinkingVersionsModal({
  templateId,
  handleClose,
  pathToDocList,
}: {
  templateId: string;
  handleClose: () => void;
  pathToDocList: string;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Templates", "Global"]);

  const client = useApolloClient();
  const [update, query] = useUpdateAllLinksToLatestPublishedVersionMutation({
    client,
    variables: { input: { docId: templateId } },
  });

  return (
    <ConfirmModal
      title={t("Update all links to latest published version?", {
        ns: "Templates",
      })}
      handleClose={handleClose}
      handleConfirm={async () => {
        const result = await update();

        result.data?.updateAllLinkingItemsToTemplateToLatestAvailableVersion.templates.forEach(
          templateLink => {
            if (
              templateLink.__typename ===
              "UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateError"
            ) {
              const message = `Failed to update ${templateLink.template.title}: ${templateLink.errorMessage}`; // FIXME: translate
              enqueueSnackbar(message, {
                variant: "error",
                action: (
                  <Button
                    component={Link}
                    to={`${pathToDocList}/${templateLink.template.id}/latest/edit`}
                    variant={"outlined"}
                    color="inherit"
                    size="small"
                  >
                    {t("Open", { ns: "Global" })}
                  </Button>
                ),
                persist: true,
              });
            } else {
              templateLink.linkingItems.forEach(linkingItem => {
                if (
                  linkingItem.result.__typename ===
                  "UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultValidationError"
                ) {
                  const message = `Failed to update ${linkingItem.item.title} in ${templateLink.template.title}`; // FIXME: translate
                  enqueueSnackbar(message, {
                    variant: "error",
                    action: (
                      <Button
                        component={Link}
                        to={`${pathToDocList}/${templateLink.template.id}/latest/edit/${linkingItem.item.id}`}
                        variant={"outlined"}
                        color="inherit"
                        size="small"
                      >
                        {t("Open", { ns: "Global" })}
                      </Button>
                    ),
                    persist: true,
                  });
                } else {
                  const message = `Sucessfully updated ${linkingItem.item.pathForPdf} ${linkingItem.item.title} in ${templateLink.template.title} `; // FIXME: translate
                  enqueueSnackbar(message);
                }
              });
            }
          }
        );
      }}
    />
  );
}
