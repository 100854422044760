import {
  makeProjectStateMachine,
  ProjectStateEvents,
  ProjectStateMachineOptions,
} from "@msys/statemachines";
import { StatusChip } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { createActor } from "xstate";
import { useUserData } from "../../auth/useUserData.js";
import { ProjectStateMachineStatus } from "../../../clients/graphqlTypes.js";

export type ProjectStateMachineEvent = ProjectStateEvents["type"];

export const ALL_PROJECT_STATES: ProjectStateMachineStatus[] = [
  "opportunity",
  "contracted",
  "completed",
  "cancelled",
  "contractee_declined",
  "contractor_declined",
  "legacy_closed",
];

export const ALL_PROJECT_EVENTS: ProjectStateMachineEvent[] = [
  "WIN",
  "COMPLETE",
  "CONTRACTEE_DECLINE",
  "CONTRACTOR_DECLINE",
  "CANCEL",
];

export function isProjectStateMachineStatus(
  status: string
): status is ProjectStateMachineStatus {
  return ALL_PROJECT_STATES.some(s => s === status);
}

export function isProjectStateMachineEvent(
  event: string
): event is ProjectStateMachineEvent {
  return ALL_PROJECT_EVENTS.some(e => e === event);
}

export const projectStateTextColors: Record<ProjectStateMachineStatus, string> =
  {
    cancelled: "error.main",
    completed: "success.main",
    contracted: "warning.main",
    contractee_declined: "common.black",
    contractor_declined: "common.black",
    legacy_closed: "grey.600",
    opportunity: "grey.600",
  };

export const projectEventTextColors: Record<ProjectStateMachineEvent, string> =
  {
    CANCEL: "error.main",
    COMPLETE: "success.main",
    WIN: "warning.main",
    CONTRACTEE_DECLINE: "common.black",
    CONTRACTOR_DECLINE: "common.black",
  };

export const projectStateChipColors: Record<
  ProjectStateMachineStatus,
  React.ComponentProps<typeof StatusChip>["color"]
> = {
  cancelled: "error",
  completed: "success",
  contracted: "warning",
  contractee_declined: "primary",
  contractor_declined: "primary",
  legacy_closed: "default",
  opportunity: "default",
};

export const projectEventChipColors: Record<
  ProjectStateMachineEvent,
  React.ComponentProps<typeof StatusChip>["color"]
> = {
  CANCEL: "error",
  COMPLETE: "success",
  WIN: "warning",
  CONTRACTEE_DECLINE: "primary",
  CONTRACTOR_DECLINE: "primary",
};

export function useProjectStates() {
  const { t } = useTranslate("ProjectState");
  const viewer = useUserData().currentUser!;

  const projectStateLabels = React.useMemo(
    (): Record<ProjectStateMachineStatus, string> => ({
      cancelled: t("Contract cancelled"),
      completed: t("Completed"),
      contracted: t("Contracted"),
      contractee_declined:
        viewer.type === "PROUSER"
          ? t("Lost lead")
          : t("Potential project cancelled"),
      contractor_declined:
        viewer.type === "PROUSER"
          ? t("Declined lead")
          : t("Potential project declined"),
      legacy_closed: t("Closed"),
      opportunity:
        viewer.type === "PROUSER"
          ? t("Open opportunity")
          : t("Potential project"),
    }),
    [t, viewer]
  );
  const projectStateEventLabels = React.useMemo(
    (): Record<ProjectStateMachineEvent, string> => ({
      CANCEL: t("Contract cancelled"),
      COMPLETE: t("Completed"),
      WIN: t("Opportunity won"),
      CONTRACTEE_DECLINE: t("Opportunity withdrawn by client"),
      CONTRACTOR_DECLINE: t("Opportunity declined"),
    }),
    [t]
  );
  const projectEventNextStateLabels = React.useMemo(
    (): Record<ProjectStateMachineEvent, string> => ({
      CANCEL: t("Contract cancelled"),
      COMPLETE: t("Completed"),
      WIN: t("Contracted"),
      CONTRACTEE_DECLINE:
        viewer.type === "PROUSER"
          ? t("Lost lead")
          : t("Potential project cancelled"),
      CONTRACTOR_DECLINE:
        viewer.type === "PROUSER"
          ? t("Declined lead")
          : t("Potential project declined"),
    }),
    [t, viewer]
  );

  const projectStateOptions = ALL_PROJECT_STATES.map(state => ({
    label: projectStateLabels[state],
    value: state,
  }));

  return {
    projectStateLabels,
    projectStateOptions,
    projectStateEventLabels,
    projectEventNextStateLabels,
  };
}

export function getPossibleProjectStateEvents(
  initialStatus: ProjectStateMachineStatus,
  options: ProjectStateMachineOptions
): ProjectStateMachineEvent[] {
  const projectStateMachine = makeProjectStateMachine(initialStatus, options);
  const actor = createActor(projectStateMachine);
  actor.start();
  const nextEvents = actor.getSnapshot()._nodes.flatMap(sn => sn.ownEvents);
  actor.stop();
  return nextEvents.filter(isProjectStateMachineEvent);
}
