/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { RequestStatusBadgeFragmentDoc } from './RequestStatusBadge.generated.js';
export type IncomingRequestListItemFragment = { __typename: 'Request', id: string, overdue: boolean, earliestStart?: any | null, deadline?: any | null, urgent: boolean, title: string, viewerRecipientNumPublishedQuotes: number, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string, budget?: { __typename: 'Money', amount: number, currency: string } | null };

export type OutgoingRequestListItemFragment = { __typename: 'Request', id: string, overdue: boolean, earliestStart?: any | null, deadline?: any | null, urgent: boolean, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string, budget?: { __typename: 'Money', amount: number, currency: string } | null, quotes: Array<{ __typename: 'Quote', id: string, agreement: Types.Agreement }> };

export const IncomingRequestListItemFragmentDoc = gql`
    fragment IncomingRequestListItem on Request {
  id
  overdue
  earliestStart
  deadline
  urgent
  budget {
    amount
    currency
  }
  title
  viewerRecipientNumPublishedQuotes
  ...RequestStatusBadge
}
    ${RequestStatusBadgeFragmentDoc}`;
export const OutgoingRequestListItemFragmentDoc = gql`
    fragment OutgoingRequestListItem on Request {
  id
  overdue
  earliestStart
  deadline
  urgent
  budget {
    amount
    currency
  }
  title
  quotes {
    id
    agreement
  }
  ...RequestStatusBadge
}
    ${RequestStatusBadgeFragmentDoc}`;