/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type OrganisationAvatar_Company_Fragment = { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } };

export type OrganisationAvatar_CrmCompaniesRecord_Fragment = { __typename: 'CrmCompaniesRecord', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } };

export type OrganisationAvatar_CrmCompany_Fragment = { __typename: 'CrmCompany', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } };

export type OrganisationAvatar_Organisation_Fragment = { __typename: 'Organisation', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } };

export type OrganisationAvatar_ReceivedLinkingInvitation_Fragment = { __typename: 'ReceivedLinkingInvitation', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } };

export type OrganisationAvatar_ReferralInfo_Fragment = { __typename: 'ReferralInfo', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } };

export type OrganisationAvatar_ViewerOrganisation_Fragment = { __typename: 'ViewerOrganisation', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } };

export type OrganisationAvatarFragment = OrganisationAvatar_Company_Fragment | OrganisationAvatar_CrmCompaniesRecord_Fragment | OrganisationAvatar_CrmCompany_Fragment | OrganisationAvatar_Organisation_Fragment | OrganisationAvatar_ReceivedLinkingInvitation_Fragment | OrganisationAvatar_ReferralInfo_Fragment | OrganisationAvatar_ViewerOrganisation_Fragment;

export const OrganisationAvatarFragmentDoc = gql`
    fragment OrganisationAvatar on WithLogo {
  id
  title
  logo {
    id
    url
    title
  }
}
    `;