import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { throttle } from "lodash-es";
import React from "react";
import { AddressInput, Exact } from "../../../clients/graphqlTypes.js";
import { AddressDetails__AddressFragment } from "./Addresses.generated.js";
import { getAddressLabel } from "./helpers.js";

interface PassedOption {
  value: AddressDetails__AddressFragment;
  label: string;
}

interface GoogleMapSuggestion {
  label: string;
  getAddress: () => Promise<AddressInput | undefined>;
}

type AddressOption = PassedOption | GoogleMapSuggestion;

interface Props {
  id?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  label: string;
  options?: PassedOption[];
  value?: Exact<AddressInput> | null;
  onChange: (value: Exact<AddressInput> | null) => void;
  error?: String;
  variant?: TextFieldProps["variant"];
}

export const AddressSearch = ({
  autoFocus = false,
  disabled,
  required,
  label,
  options: passedOptions = [],
  value,
  onChange,
  error,
  variant,
}: Props) => {
  const { t } = useTranslate("Global");

  const hasChangedRef = React.useRef<boolean>(false);

  // TODO: is there a better way to fill/preselect when value is passed?
  const [inputValue, setInputValue] = React.useState<string>(
    getAddressLabel(value) ?? ""
  );
  const { suggestions } = useGoogleMapsAutocomplete(inputValue);

  // updates initial value passed from outside
  React.useEffect(() => {
    if (hasChangedRef.current) return;
    const newInputValue = getAddressLabel(value) ?? "";
    setInputValue(newInputValue);
  }, [value]);

  return (
    <Autocomplete<AddressOption, false, boolean, true>
      autoComplete
      autoHighlight
      openOnFocus
      selectOnFocus
      freeSolo
      disabled={disabled}
      options={!inputValue ? passedOptions : suggestions}
      filterOptions={option => option}
      getOptionLabel={option => {
        if (typeof option === "string") return option;
        return option.label;
      }}
      noOptionsText={t("No existing entry")}
      value={inputValue}
      onChange={async (event, value, reason) => {
        event.stopPropagation();
        if (value === null) return;
        if (typeof value === "string") return;
        hasChangedRef.current = true;
        if (isPassedOption(value)) {
          onChange(value.value ? getAddressFromDetails(value.value) : null);
        } else if (value.getAddress) {
          onChange((await value.getAddress()) ?? null);
        }
      }}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={label}
            autoFocus={autoFocus}
            required={required}
            error={Boolean(error)}
            helperText={error}
            variant={variant}
          />
        );
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "clear") {
          setInputValue("");
          onChange(null);
        }
        if (reason === "input") {
          setInputValue(newInputValue);
        }
      }}
    />
  );
};

function useGoogleMapsAutocomplete(inputValue: string) {
  const autoCompleteService = React.useRef(
    new google.maps.places.AutocompleteService()
  );
  const geocoder = React.useRef(new google.maps.Geocoder());
  const [suggestions, setSuggestions] = React.useState<AddressOption[]>([]);
  const sessionToken = React.useMemo(
    () => new google.maps.places.AutocompleteSessionToken(),
    []
  );

  const throttledGetPlacePredictions = React.useMemo(
    () =>
      throttle(
        (
          request: google.maps.places.AutocompletionRequest,
          callback: (
            results?: google.maps.places.AutocompletePrediction[] | null
          ) => void
        ) => {
          if (!autoCompleteService.current)
            throw new Error("Autocomplete service is not instanciated");

          autoCompleteService.current.getPlacePredictions(request, callback);
        },
        200
      ),
    []
  );

  const geocode = React.useCallback(async function (
    value: google.maps.places.AutocompletePrediction
  ) {
    if (!geocoder.current)
      throw new Error("Geocoder service is not instanciated");

    const geocodeResult = await new Promise(
      (
        resolve: (results: google.maps.GeocoderResult | undefined) => void,
        reject
      ) => {
        geocoder.current.geocode(
          { placeId: value.place_id },
          (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
              return reject(status);
            }
            return resolve(results?.[0]);
          }
        );
      }
    );

    return geocodeResult;
  }, []);

  React.useEffect(() => {
    if (inputValue === "") {
      setSuggestions([]);
      return;
    }

    throttledGetPlacePredictions(
      {
        input: inputValue,
        // No restrictions at the moment!
        // restriction to max of 5 countries: https://developers.google.com/maps/documentation/javascript/releases#2017-01-10
        // if more is needed we have to do unrestricted search!
        // componentRestrictions: {
        //   country: ["de", "at", "be", "nl", "fr"],
        // },
        types: ["address"],
        sessionToken,
      },
      results => {
        if (results) {
          const suggestions = results.map(result => ({
            label: result.description,
            getAddress: async () => {
              const geocodeResult = await geocode(result);
              return geocodeResult
                ? getAddressFromGeocoderResult(geocodeResult)
                : undefined;
            },
          }));
          setSuggestions(suggestions);
        }
      }
    );

    return () => {
      throttledGetPlacePredictions.cancel();
    };
  }, [inputValue, throttledGetPlacePredictions, geocode, sessionToken]);

  return { suggestions };
}

export const getAddressFromGeocoderResult = (
  place: google.maps.GeocoderResult
): AddressInput => {
  const route = getPlaceValue(place, "route");
  const streetNumber = getPlaceValue(place, "street_number");
  const postalCode = getPlaceValue(place, "postal_code");
  const city = getPlaceValue(place, "locality");
  const countryCode = getPlaceValue(place, "country", true);

  if (!place.geometry || !route)
    throw new Error("GeocoderResult is missing some required fields");

  const address: AddressInput = {
    city,
    countryCode,
    location: {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    },
    postalCode,
    streetLines1: `${route} ${streetNumber}`.trim(),
  };

  return address;
};

export const getAddressFromDetails = (
  details: AddressDetails__AddressFragment | AddressInput
): AddressInput => {
  const address: AddressInput = {
    city: details.city,
    countryCode: details.countryCode,
    location: details.location
      ? {
          lat: details.location.lat,
          lng: details.location.lng,
        }
      : null,
    postalCode: details.postalCode,
    streetLines1: details.streetLines1,
  };
  return address;
};

const getPlaceValue = (
  place: google.maps.GeocoderResult,
  key: string,
  short?: boolean
) => {
  if (place.address_components) {
    const component = place.address_components.find(address =>
      address.types.includes(key)
    );

    if (component) {
      return short ? component.short_name : component.long_name;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

function isPassedOption(option: AddressOption): option is PassedOption {
  // @ts-ignore
  return option.value;
}
