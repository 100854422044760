/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutationVariables = Types.Exact<{
  input: Types.CreateTemplateFromItemInput;
}>;


export type QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation = { createTemplateFromItem: { __typename: 'CreateTemplateFromItemResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null }, templateDoc: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } } } };


export const QuoteTemplateCreateFromItemButton_CreateTemplateFromItemDocument = gql`
    mutation QuoteTemplateCreateFromItemButton_CreateTemplateFromItem($input: CreateTemplateFromItemInput!) {
  createTemplateFromItem(input: $input) {
    item {
      id
      originVersionNumber
    }
    templateDoc {
      id
      resolvedAsReadModelVersionNumber
      rootItem {
        id
        originVersionNumber
      }
    }
  }
}
    `;
export type QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutationFn = Apollo.MutationFunction<QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation, QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutationVariables>;

/**
 * __useQuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation__
 *
 * To run a mutation, you first call `useQuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteTemplateCreateFromItemButtonCreateTemplateFromItemMutation, { data, loading, error }] = useQuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation(baseOptions?: Apollo.MutationHookOptions<QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation, QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation, QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutationVariables>(QuoteTemplateCreateFromItemButton_CreateTemplateFromItemDocument, options);
      }
export type QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutationHookResult = ReturnType<typeof useQuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation>;
export type QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutationResult = Apollo.MutationResult<QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation>;
export type QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutationOptions = Apollo.BaseMutationOptions<QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation, QuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutationVariables>;