import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import { SwitchField } from "../../commons/form-fields/SwitchField.js";
import { VisibilityStore } from "./useVisibilityStore.js";

interface Props<Keys extends string> {
  visibilityStore: VisibilityStore<Keys>;
  labels: Record<Keys, string>;
  title?: string;
  handleClose(): void;
}

interface FormValues<Keys extends string> {
  flags: Record<Keys, boolean>;
}

export const VisibilityEditModal = <Keys extends string>({
  title,
  visibilityStore,
  labels,
  handleClose,
}: Props<Keys>) => {
  const { t } = useTranslate(["UserDetails", "Global"]);
  const initialValues = {
    flags: Object.fromEntries(
      (Object.keys(labels) as Keys[]).map(field => [
        field,
        visibilityStore.value[field] ?? true,
      ])
    ) as Record<Keys, boolean>,
  };
  const validationSchema = Yup.object().shape({
    flags: Yup.object()
      .shape(
        Object.fromEntries(
          (Object.keys(labels) as Keys[]).map(field => [
            field,
            Yup.boolean().label(labels[field]).required(),
          ])
        )
      )
      .required(),
  });
  const handleSubmit = (values: FormValues<Keys>) => {
    visibilityStore.saveValue(values.flags);
    handleClose();
  };
  const formId = React.useMemo(() => uniqueId(), []);
  return (
    <Formik<FormValues<Keys>>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={false}
    >
      {formikProps => (
        <Modal
          title={title ?? t("Select visible elements", { ns: "UserDetails" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", { ns: "Global" }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: formikProps.isSubmitting,
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
          maxWidth="xs"
        >
          <Form id={formId}>
            <Stack direction="column" spacing={0}>
              {(Object.entries(labels) as [Keys, string][]).map(
                ([field, label]) => (
                  <SwitchField
                    key={field}
                    label={label}
                    name={`flags.${field}`}
                    disabled={formikProps.isSubmitting}
                  />
                )
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
