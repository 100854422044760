import React from "react";
import { BillOfMaterialsItemType } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

const ALL_ITEM_TYPES: BillOfMaterialsItemType[] = [
  "product",
  "custom_product",
  "quote_item",
];

export function useItemGroups() {
  const { t } = useTranslate("Materials");

  const groupLabels: Record<BillOfMaterialsItemType, string> = React.useMemo(
    () => ({
      product: t("Catalog products"),
      custom_product: t("Manually entered products"),
      quote_item: t("Quote items"),
    }),
    [t]
  );

  const groups = React.useMemo(
    () =>
      ALL_ITEM_TYPES.map(group => ({ id: group, label: groupLabels[group] })),
    [groupLabels]
  );

  return { groupLabels, groups };
}
