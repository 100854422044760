import { useScreenWidth } from "@msys/ui";
import { ShoppingBasket as ShoppingBasketIcon } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useShop } from "../../shop/hooks/useShopCart.js";

interface Props {
  docId: string;
}

export const ShopButton = ({ docId }: Props) => {
  const navigate = useNavigate();
  const { isMaxPhone } = useScreenWidth();
  const { setCartId } = useShop();
  const handleClick = () => {
    setCartId(docId);
    navigate(`/shop`);
  };
  return isMaxPhone ? (
    <IconButton color="secondary" onClick={handleClick}>
      <ShoppingBasketIcon />
    </IconButton>
  ) : (
    <Button color="secondary" onClick={handleClick} variant="outlined">
      Shop
    </Button>
  );
};
