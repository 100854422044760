import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React, { useImperativeHandle, useState } from "react";
import { useGlobalLoadingCounter } from "../../../common/global-loading-state/useGlobalLoadingCounter.js";
import {
  PhotoEditorModal,
  combineImages,
  getImage,
} from "./PhotoEditorModal.js";
import { Attachment, uploadFileToUploadCare } from "./helpers.js";

export interface AttachmentPhotoUploaderWithEditorRef {
  start: () => Promise<void>;
}

interface Props {
  skipEdit?: boolean;
  skipUpload?: boolean;
  onAttachment?: (attachment: Attachment) => void | Promise<void>;
  onStart?: () => void;
  onError?: () => void;
  onFile?: (file: File) => void | Promise<void>;
}

/**
 * Photo uploader with editor in modal
 */
export const AttachmentPhotoUploaderWithEditor = React.forwardRef<
  AttachmentPhotoUploaderWithEditorRef,
  Props
>((props, forwardedRef) => {
  const { t } = useTranslate("AttachmentField");
  const { enqueueSnackbar } = useSnackbar();
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);

  const { increment, decrement } = useGlobalLoadingCounter();
  const [photo, setPhoto] = useState<Photo | null>(null);

  const handleComplete = async (file: File) => {
    try {
      props.onStart?.();
      setIsUploadingFile(true);
      increment();
      await props.onFile?.(file);
      if (!props.skipUpload) {
        const attachment = await uploadFileToUploadCare(file, false);
        await props.onAttachment?.(attachment);
      }
    } catch (e) {
      props.onError?.();
      throw e;
    } finally {
      decrement();
      setPhoto(null);
      setIsUploadingFile(false);
    }
  };

  const takePhotoOrSelectFromGallery = async () => {
    let permissions = await Camera.checkPermissions();
    if (
      !["granted", "limited"].includes(permissions.camera) ||
      !["granted", "limited"].includes(permissions.photos)
    ) {
      await Camera.requestPermissions({
        permissions: ["camera", "photos"],
      });
      permissions = await Camera.checkPermissions();
    }
    if (
      !["granted", "limited"].includes(permissions.camera) ||
      !["granted", "limited"].includes(permissions.photos)
    ) {
      enqueueSnackbar(t("Please give required permissions to upload photo"), {
        variant: "error",
      });
      return;
    }

    const photo = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      saveToGallery: false,
      width: 1200,
      correctOrientation: true,
      source: CameraSource.Prompt,
      direction: CameraDirection.Rear,
      resultType: CameraResultType.DataUrl,
      presentationStyle: "popover",
      promptLabelCancel: t("Cancel"),
      promptLabelPhoto: t("From Photos"),
      promptLabelPicture: t("Take Picture"),
    });

    if (!photo.dataUrl) return;

    if (!props.skipEdit) {
      // normal flow
      setPhoto(photo);
    } else {
      // no edit flow
      const image = await getImage(photo.dataUrl);
      const width = image.naturalWidth ?? 0;
      const height = image.naturalHeight ?? 0;
      const file = await combineImages(width, height, photo.dataUrl);
      await handleComplete(file);
    }
  };

  useImperativeHandle(forwardedRef, () => ({
    start: takePhotoOrSelectFromGallery,
  }));

  return (
    <>
      {photo ? (
        <PhotoEditorModal
          photo={photo}
          isLoading={isUploadingFile}
          handleClose={() => setPhoto(null)}
          handleComplete={handleComplete}
        />
      ) : null}
    </>
  );
});
