/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { TemplateQuoteSubscriptionStatus_QuoteTemplateFragmentDoc } from './TemplateQuoteSubscriptionStatus.generated.js';
import { TemplateQuotePublishingStatus_QuoteTemplateFragmentDoc } from './TemplateQuotePublishingStatus.generated.js';
import { TemplateQuoteAddedToShopStatus_QuoteTemplateFragmentDoc } from './TemplateQuoteAddedToShopStatus.generated.js';
export type TemplateQuoteStatuses_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, accessType: Types.TemplateAccessType, resolvedAsReadModelVersionNumber?: number | null, sharedPublicly: boolean, addedToShop: boolean, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null, sharedWithOrganisations: Array<{ __typename: 'Company', id: string }> };

export const TemplateQuoteStatuses_QuoteTemplateFragmentDoc = gql`
    fragment TemplateQuoteStatuses_QuoteTemplate on QuoteTemplate {
  id
  owningSystemOrganisationId
  hasAnyPublishedVersion
  accessType
  ...TemplateQuoteSubscriptionStatus_QuoteTemplate
  ...TemplateQuotePublishingStatus_QuoteTemplate
  ...TemplateQuoteAddedToShopStatus_QuoteTemplate
}
    ${TemplateQuoteSubscriptionStatus_QuoteTemplateFragmentDoc}
${TemplateQuotePublishingStatus_QuoteTemplateFragmentDoc}
${TemplateQuoteAddedToShopStatus_QuoteTemplateFragmentDoc}`;