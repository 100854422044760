/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated.js';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuildingRow__BuildingFragment = { __typename: 'Building', id: string, title: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } };

export type BuildingDetails__BuildingFragment = { __typename: 'Building', id: string, description: string, title: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, projectsInBuilding: Array<{ __typename: 'Project', id: string, title: string, state: Types.ProjectStateMachineStatus }>, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null }, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type BuildingDetailsQueryVariables = Types.Exact<{
  buildingId: Types.Scalars['ID']['input'];
}>;


export type BuildingDetailsQuery = { building: { __typename: 'Building', id: string, description: string, title: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, projectsInBuilding: Array<{ __typename: 'Project', id: string, title: string, state: Types.ProjectStateMachineStatus }>, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null }, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } };

export type CreateBuildingMutationVariables = Types.Exact<{
  input: Types.BuildingCreateInput;
}>;


export type CreateBuildingMutation = { createBuilding: { __typename: 'BuildingCreateResult', building: { __typename: 'Building', id: string, title: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } } } };

export type ModifyBuildingMutationVariables = Types.Exact<{
  input: Types.ModifyBuildingInput;
}>;


export type ModifyBuildingMutation = { modifyBuilding: { __typename: 'ModifyBuildingResult', building: { __typename: 'Building', id: string, description: string, title: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } } } };

export type DeleteBuildingMutationVariables = Types.Exact<{
  input: Types.DeleteBuildingInput;
}>;


export type DeleteBuildingMutation = { deleteBuilding: { __typename: 'DeleteBuildingResult', ok: boolean } };

export const BuildingRow__BuildingFragmentDoc = gql`
    fragment BuildingRow__Building on Building {
  id
  title
  buildingAddress {
    ...AddressDetails__Address
  }
  rootItem {
    id
    originVersionNumber
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export const BuildingDetails__BuildingFragmentDoc = gql`
    fragment BuildingDetails__Building on Building {
  id
  ...BuildingRow__Building
  description
  attachments {
    ...Attachment
  }
  projectsInBuilding {
    id
    title
    state
  }
  rootItem {
    id
    originVersionNumber
  }
}
    ${BuildingRow__BuildingFragmentDoc}
${AttachmentFragmentDoc}`;
export const BuildingDetailsDocument = gql`
    query BuildingDetails($buildingId: ID!) {
  building(id: $buildingId) {
    id
    ...BuildingDetails__Building
  }
}
    ${BuildingDetails__BuildingFragmentDoc}`;

/**
 * __useBuildingDetailsQuery__
 *
 * To run a query within a React component, call `useBuildingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingDetailsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingDetailsQuery(baseOptions: Apollo.QueryHookOptions<BuildingDetailsQuery, BuildingDetailsQueryVariables> & ({ variables: BuildingDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingDetailsQuery, BuildingDetailsQueryVariables>(BuildingDetailsDocument, options);
      }
export function useBuildingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingDetailsQuery, BuildingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingDetailsQuery, BuildingDetailsQueryVariables>(BuildingDetailsDocument, options);
        }
export function useBuildingDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BuildingDetailsQuery, BuildingDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BuildingDetailsQuery, BuildingDetailsQueryVariables>(BuildingDetailsDocument, options);
        }
export type BuildingDetailsQueryHookResult = ReturnType<typeof useBuildingDetailsQuery>;
export type BuildingDetailsLazyQueryHookResult = ReturnType<typeof useBuildingDetailsLazyQuery>;
export type BuildingDetailsSuspenseQueryHookResult = ReturnType<typeof useBuildingDetailsSuspenseQuery>;
export type BuildingDetailsQueryResult = Apollo.QueryResult<BuildingDetailsQuery, BuildingDetailsQueryVariables>;
export const CreateBuildingDocument = gql`
    mutation CreateBuilding($input: BuildingCreateInput!) {
  createBuilding(input: $input) {
    building {
      id
      ...BuildingRow__Building
    }
  }
}
    ${BuildingRow__BuildingFragmentDoc}`;
export type CreateBuildingMutationFn = Apollo.MutationFunction<CreateBuildingMutation, CreateBuildingMutationVariables>;

/**
 * __useCreateBuildingMutation__
 *
 * To run a mutation, you first call `useCreateBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuildingMutation, { data, loading, error }] = useCreateBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBuildingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBuildingMutation, CreateBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBuildingMutation, CreateBuildingMutationVariables>(CreateBuildingDocument, options);
      }
export type CreateBuildingMutationHookResult = ReturnType<typeof useCreateBuildingMutation>;
export type CreateBuildingMutationResult = Apollo.MutationResult<CreateBuildingMutation>;
export type CreateBuildingMutationOptions = Apollo.BaseMutationOptions<CreateBuildingMutation, CreateBuildingMutationVariables>;
export const ModifyBuildingDocument = gql`
    mutation ModifyBuilding($input: ModifyBuildingInput!) {
  modifyBuilding(input: $input) {
    building {
      id
      ...BuildingRow__Building
      description
    }
  }
}
    ${BuildingRow__BuildingFragmentDoc}`;
export type ModifyBuildingMutationFn = Apollo.MutationFunction<ModifyBuildingMutation, ModifyBuildingMutationVariables>;

/**
 * __useModifyBuildingMutation__
 *
 * To run a mutation, you first call `useModifyBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyBuildingMutation, { data, loading, error }] = useModifyBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyBuildingMutation(baseOptions?: Apollo.MutationHookOptions<ModifyBuildingMutation, ModifyBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyBuildingMutation, ModifyBuildingMutationVariables>(ModifyBuildingDocument, options);
      }
export type ModifyBuildingMutationHookResult = ReturnType<typeof useModifyBuildingMutation>;
export type ModifyBuildingMutationResult = Apollo.MutationResult<ModifyBuildingMutation>;
export type ModifyBuildingMutationOptions = Apollo.BaseMutationOptions<ModifyBuildingMutation, ModifyBuildingMutationVariables>;
export const DeleteBuildingDocument = gql`
    mutation DeleteBuilding($input: DeleteBuildingInput!) {
  deleteBuilding(input: $input) {
    ok
  }
}
    `;
export type DeleteBuildingMutationFn = Apollo.MutationFunction<DeleteBuildingMutation, DeleteBuildingMutationVariables>;

/**
 * __useDeleteBuildingMutation__
 *
 * To run a mutation, you first call `useDeleteBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuildingMutation, { data, loading, error }] = useDeleteBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBuildingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBuildingMutation, DeleteBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBuildingMutation, DeleteBuildingMutationVariables>(DeleteBuildingDocument, options);
      }
export type DeleteBuildingMutationHookResult = ReturnType<typeof useDeleteBuildingMutation>;
export type DeleteBuildingMutationResult = Apollo.MutationResult<DeleteBuildingMutation>;
export type DeleteBuildingMutationOptions = Apollo.BaseMutationOptions<DeleteBuildingMutation, DeleteBuildingMutationVariables>;