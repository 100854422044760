import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  ErrorMessage,
  ListHeader,
  LoadingSpinner,
  useFormatting,
} from "@msys/ui";
import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { EntityNumber } from "../../commons/EntityNumber.js";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageHeader } from "../../commons/layout/PageHeader.js";
import { RequirementListItem } from "../../features/requirements/RequirementListItem.js";
import {
  RequirementDraftBadge,
  RequirementPublishedBadge,
} from "../../features/requirements/RequirementStatusBadge.js";
import { CreateRequirementButton } from "../../features/requirements/buttons/CreateRequirementButton.js";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore.js";
import {
  RequirementRowFragment,
  useProjectRequirementsQuery,
} from "./ProjectRequirements.generated.js";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

export const ProjectRequirements = ({
  projectId,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: Props) => {
  const navigate = useNavigate();
  const { getFormattedDateTime } = useFormatting();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "project-requirements",
      "table",
      "list"
    );

  const { t } = useTranslate(["Requirements", "Global"]);

  const client = useApolloClient();
  const query = useProjectRequirementsQuery({
    client,
    variables: {
      projectId,
    },
  });

  const breadcrumbs = useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Requirements", {
          ns: "Requirements",
        }),
        to: `/projects/${projectId}/requirements`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  const project = getDataOrNull(query.data?.project)?.project;
  const requirements =
    getDataOrNull(query.data?.projectRequirements)?.edges?.map(e => e.node) ??
    [];
  const totalCount =
    getDataOrNull(query.data?.projectRequirements)?.totalCount ?? 0;

  const stateStore = useDataGridStateStore("ProjectRequirements");

  return (
    <Page
      subtitle={project?.title}
      title={t("Requirements", {
        ns: "Requirements",
      })}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
    >
      {project && (
        <PageContainer>
          <ListHeader
            // title={t("Requirements", {
            //   ns: "Requirements",
            // })}
            SwitchViewButton={
              <SwitchCollectionViewButton
                allowedViews={ALLOWED_VIEWS}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            }
            CreateButton={
              <RestrictedByProjectPermissionWithDebug
                permission="MANAGE_REQUIREMENTS"
                project={project}
              >
                <CreateRequirementButton
                  projectId={project.id}
                  refetchQueries={[namedOperations.Query.ProjectRequirements]}
                />
              </RestrictedByProjectPermissionWithDebug>
            }
            mb={2}
          />
          {/* <ShowBox
                box={{ name: "project-requirements-list-item", project }}
              > */}

          {(() => {
            switch (activeView) {
              case "table":
                return (
                  <DataGrid
                    stateStore={stateStore}
                    loading={query.loading}
                    hideFooter={true}
                    columns={
                      [
                        {
                          field: "number",
                          headerName: t("No.", {
                            ns: "Global",
                          }),
                          flex: 1,
                          minWidth: 100,
                          sortable: false,
                          renderCell: ({ row: requirement }) => (
                            <EntityNumber noLabel number={requirement.number} />
                          ),
                        },
                        {
                          field: "status",
                          headerName: t("Status", {
                            ns: "Requirements",
                          }),
                          flex: 1,
                          minWidth: 100,
                          sortable: false,
                          renderCell: ({ row: requirement }) => {
                            return requirement.isPublished ? (
                              <RequirementPublishedBadge />
                            ) : (
                              <RequirementDraftBadge />
                            );
                          },
                        },
                        {
                          field: "title",
                          headerName: t("Title", {
                            ns: "Requirements",
                          }),
                          flex: 3,
                          minWidth: 100,
                          sortable: false,
                          renderCell: ({ row: requirement }) =>
                            requirement.title,
                        },
                        {
                          field: "createdAt",
                          headerName: t("Created at", {
                            ns: "Requirements",
                          }),
                          flex: 1,
                          minWidth: 100,
                          sortable: false,
                          valueGetter: ({ row: requirement }) =>
                            requirement.createdAt
                              ? new Date(requirement.createdAt)
                              : null,
                          renderCell: ({ row: requirement }) =>
                            requirement.createdAt
                              ? getFormattedDateTime(requirement.createdAt)
                              : "-",
                        },
                      ] as GridColDef<RequirementRowFragment>[]
                    }
                    rows={requirements}
                    onRowClick={requirement =>
                      navigate(
                        `/projects/${project.id}/requirements/${requirement.id}`
                      )
                    }
                    rowCount={totalCount}
                    disableColumnFilter
                  />
                );
              case "list":
                if (query.loading) return <LoadingSpinner />;
                if (totalCount === 0)
                  return (
                    <ErrorMessage
                      message={t("There are no items to display", {
                        ns: "Global",
                      })}
                    />
                  );
                return (
                  <Stack width="100%" direction="column" spacing={1}>
                    {requirements.map(requirement => (
                      <CardItem
                        key={requirement.id}
                        //@ts-ignore
                        component={Link}
                        to={`/projects/${project.id}/requirements/${requirement.id}`}
                      >
                        <RequirementListItem requirement={requirement} />
                      </CardItem>
                    ))}
                  </Stack>
                );
            }
          })()}

          {/* </ShowBox> */}
        </PageContainer>
      )}
    </Page>
  );
};
