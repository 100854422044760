import { ApplyItemAction } from "@msys/apply-item-action-isomorphic";
import { assert } from "@msys/common";
import { question, wizard } from "@msys/question-isomorphic";
import { omit } from "lodash-es";
import React from "react";
import { ApplyItemActionInput } from "../../../clients/graphqlTypes.js";
import { EMBEDDABLE_WIZARD_URL } from "../../../environment.js";
import { useAuth } from "../../auth/useAuth.js";
import { assertNever } from "../../utils.js";
import { useTolgee } from "@tolgee/react";

export function QuoteCreateWizardSection({
  onComplete,
  onClose,
  onAnswer,
  initialAnswers,
  wizardConfiguration,
}: {
  onComplete?: (
    result: Array<question.AnsweredQuestion>
  ) => Promise<void> | void;
  onClose?: () => void;
  onAnswer?: (result: Array<question.AnsweredQuestion>) => Promise<void> | void;
  initialAnswers: Array<ApplyItemAction>;
  wizardConfiguration: wizard.WizardConfigurationSchemaInput;
}) {
  const { auth } = useAuth();

  const tolgee = useTolgee(["language"]);
  const language = tolgee.getLanguage()!;

  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = React.useState<number>(384);

  const latest = React.useRef({
    onClose,
    onComplete,
    onAnswer,
    initialAnswers,
  });
  latest.current = { onClose, onComplete, onAnswer, initialAnswers };

  React.useLayoutEffect(() => {
    async function messageListener(event: MessageEvent) {
      if (event.source !== iframeRef.current?.contentWindow) {
        return;
      }

      const data: wizard.WindowPostMessages = event.data;

      switch (data.type) {
        case "resize":
          setIframeHeight(data.contentHeight);
          break;
        case "closed":
          latest.current.onClose?.();
          break;
        case "completed": {
          const answers: Array<question.AnsweredQuestion> = data.answers;

          await latest.current.onComplete?.(answers);

          const msg: wizard.WindowPostMessages = {
            type: "completedDone",
          };

          data.messagePort.postMessage(msg);

          data.messagePort.close();

          break;
        }
        case "answered": {
          const answers: Array<question.AnsweredQuestion> = data.answers;

          await latest.current.onAnswer?.(answers);

          const msg: wizard.WindowPostMessages = {
            type: "answeredDone",
          };

          data.messagePort.postMessage(msg);

          data.messagePort.close();

          break;
        }
        case "requestAuthHeaders": {
          assert(data.messagePort, `No message port provided`);

          await auth.updateToken(5);

          const port = data.messagePort as MessagePort;
          port.postMessage({
            type: "responseAuthHeaders",
            headers: auth.token
              ? { authorization: `Bearer ${auth.token}` }
              : {},
          });
          port.close();
          break;
        }
        case "requestInitialAnswers": {
          assert(data.messagePort, `No message port provided`);
          const port = data.messagePort as MessagePort;

          const msg: wizard.WindowPostMessages = {
            type: "responseInitialAnswers",
            answers: latest.current.initialAnswers,
          };
          port.postMessage(msg);
          port.close();
          break;
        }
      }
    }

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [latest, auth]);

  const src = React.useMemo(() => {
    return (
      EMBEDDABLE_WIZARD_URL +
      "?" +
      wizard.wizardConfigurationToUrlSearchQuery({
        ...wizardConfiguration,
        language,
      })
    );
  }, [wizardConfiguration, language]);

  return (
    <iframe
      ref={iframeRef}
      src={src}
      style={{
        border: "none",
        width: "100%",
        height: iframeHeight,
        transition: "height 0.2s ease-out",
        overflowY: "hidden",
      }}
      scrolling="no"
    ></iframe>
  );
}

export const applyItemActionToApplyItemActionInput = (
  answer: ApplyItemAction
): ApplyItemActionInput => ({
  [answer.type]:
    answer.type === "setEnterPropsValueEntries"
      ? {
          itemId: answer.itemId,
          entries: answer.entries.map(entry =>
            entry.kind === "Props2Bool"
              ? { bool: { key: entry.key, valueBool: entry.value } }
              : entry.kind === "Props2Number"
                ? {
                    number: {
                      key: entry.key,
                      valueNumber: entry.value,
                    },
                  }
                : entry.kind === "Props2NumberArray"
                  ? {
                      numberArray: {
                        key: entry.key,
                        valueNumberArray: entry.value,
                      },
                    }
                  : entry.kind === "Props2Text"
                    ? {
                        text: {
                          key: entry.key,
                          valueText: entry.value,
                        },
                      }
                    : entry.kind === "Props2TextArray"
                      ? {
                          textArray: {
                            key: entry.key,
                            valueTextArray: entry.value,
                          },
                        }
                      : assertNever(entry)
          ),
        }
      : omit(answer, ["type", "questionId"]),
});
