/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { PlanningAbsenceFragmentDoc } from '../../main-routes/planning/PlanningAbsences.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbsenceScheduleQueryVariables = Types.Exact<{
  rangeStart?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  rangeEnd?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  filterByWhoIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterByRoleIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type AbsenceScheduleQuery = { absences: { __typename: 'AbsenceConnection', totalCount: number, edges: Array<{ __typename: 'AbsenceEdge', node: { __typename: 'Absence', id: string, from: any, till: any, note: string, reason: Types.AbsenceReason, who: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } }> } | { __typename: 'MissingCapabilities' } };


export const AbsenceScheduleDocument = gql`
    query AbsenceSchedule($rangeStart: Date, $rangeEnd: Date, $filterByWhoIds: [ID!], $filterByRoleIds: [ID!]) {
  absences(
    limit: 0
    filters: {rangeStart: $rangeStart, rangeEnd: $rangeEnd, whoIds: $filterByWhoIds, roleIds: $filterByRoleIds}
  ) {
    ... on AbsenceConnection {
      totalCount
      edges {
        node {
          id
          ...PlanningAbsence
        }
      }
    }
  }
}
    ${PlanningAbsenceFragmentDoc}`;

/**
 * __useAbsenceScheduleQuery__
 *
 * To run a query within a React component, call `useAbsenceScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAbsenceScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAbsenceScheduleQuery({
 *   variables: {
 *      rangeStart: // value for 'rangeStart'
 *      rangeEnd: // value for 'rangeEnd'
 *      filterByWhoIds: // value for 'filterByWhoIds'
 *      filterByRoleIds: // value for 'filterByRoleIds'
 *   },
 * });
 */
export function useAbsenceScheduleQuery(baseOptions?: Apollo.QueryHookOptions<AbsenceScheduleQuery, AbsenceScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AbsenceScheduleQuery, AbsenceScheduleQueryVariables>(AbsenceScheduleDocument, options);
      }
export function useAbsenceScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AbsenceScheduleQuery, AbsenceScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AbsenceScheduleQuery, AbsenceScheduleQueryVariables>(AbsenceScheduleDocument, options);
        }
export function useAbsenceScheduleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbsenceScheduleQuery, AbsenceScheduleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AbsenceScheduleQuery, AbsenceScheduleQueryVariables>(AbsenceScheduleDocument, options);
        }
export type AbsenceScheduleQueryHookResult = ReturnType<typeof useAbsenceScheduleQuery>;
export type AbsenceScheduleLazyQueryHookResult = ReturnType<typeof useAbsenceScheduleLazyQuery>;
export type AbsenceScheduleSuspenseQueryHookResult = ReturnType<typeof useAbsenceScheduleSuspenseQuery>;
export type AbsenceScheduleQueryResult = Apollo.QueryResult<AbsenceScheduleQuery, AbsenceScheduleQueryVariables>;