import { useApolloClient } from "@apollo/client";
import {
  FormattedPrice,
  getFormattedFloat,
  getFormattedPercentage,
  PopoverOpenButton,
  useFormatting,
} from "@msys/ui";
import { Help as HelpIcon } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTolgee, useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { isUndefined } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { DurationField } from "../../../commons/form-fields/DurationField.js";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField.js";
import { FormattedPriceField } from "../../../commons/form-fields/FormattedPriceField.js";
import { size } from "../../../../common/MuiThemeProvider.js";
import {
  ContractType,
  QuantityUnit,
} from "../../../../clients/graphqlTypes.js";
import {
  EstimatedCalculationtBox_AllDocItemFragment,
  EstimatedCalculation__ItemFragment,
} from "../boxes/EstimatedCalculationBox.generated.js";
import { useModifyItemEstimatedCalculationMutation } from "../CalculationMutations.generated.js";
import { WorkRatesField } from "../fields/WorkRatesField.js";
import { FormattedFloatWithExpressionField } from "../FormattedFloatWithExpressionField.js";
import { QuantityUnitField } from "../QuantityUnitField.js";

type FormDetailedValues = {
  materialBuyingPrice: number;
  materialMargin: number;
  estimatedTime: number;
  workSellingRate: number;
  workBuyingRate: number;
  estimatedQuantity: number;
  quantityUnit: QuantityUnit;
};

type FormRestrictedValues = {
  estimatedQuantity: number;
  quantityUnit: QuantityUnit;
};

interface Props {
  item: EstimatedCalculation__ItemFragment;
  itemId: string;
  projectId: string;
  docId: string;
  contractType: ContractType;
}

export const EstimatedCalculationRow = ({
  item,
  itemId,
  docId,
  projectId,
  contractType,
}: Props) => {
  const showDetailedCalculation = item.type === "paid";
  return showDetailedCalculation ? (
    <EstimatedDetailedCalculationRow
      item={item}
      itemId={itemId}
      projectId={projectId}
      docId={docId}
      contractType={contractType}
    />
  ) : (
    <EstimatedRestrictedCalculationRow
      item={item}
      itemId={itemId}
      projectId={projectId}
      docId={docId}
    />
  );
};

export const EstimatedRestrictedCalculationRow = ({
  item,
  itemId,
  docId,
  projectId,
}: Omit<Props, "contractType">) => {
  const { t } = useTranslate(["QuoteItem"]);

  const { enqueueSnackbar } = useSnackbar();
  const { getFormattedPrice, getFormattedDuration } = useFormatting();

  const client = useApolloClient();
  const [modifyItem] = useModifyItemEstimatedCalculationMutation({
    client,
  });

  const onSubmit = async (values: FormRestrictedValues) => {
    try {
      await modifyItem({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            values: {
              estimatedQuantity: values.estimatedQuantity,
              quantityUnit: values.quantityUnit,
            },
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const attributeExpressions = item.attributeExpressions;
  const expression = attributeExpressions.find(
    expr => expr.attribute === "estimatedQuantity"
  );

  const itemCalculations = item.proposedCalculation;
  if (!itemCalculations) throw new Error("Item calculations missing");

  const initialValues = React.useMemo(
    (): FormRestrictedValues => ({
      estimatedQuantity: itemCalculations.quantity,
      quantityUnit: itemCalculations.quantityUnit,
    }),
    [itemCalculations.quantity, itemCalculations.quantityUnit]
  );

  const validationSchema = Yup.object().shape({
    estimatedQuantity: Yup.number()
      .label(
        t("Quantity", {
          ns: "QuoteItem",
        })
      )
      .min(0),
  });

  return (
    <Formik<FormRestrictedValues>
      key={`form-${itemId}`}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form style={{ marginLeft: 8 }}>
        <FormRow
          onMouseDown={e => {
            e.stopPropagation();
          }}
          onTouchStart={e => {
            e.stopPropagation();
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Quantity */}
          <div style={{ width: 160 }}>
            <FormattedFloatWithExpressionField
              label=""
              name={"estimatedQuantity"}
              placeholder={t("Quantity", {
                ns: "QuoteItem",
              })}
              disabled={false}
              size="extra-small"
              projectId={projectId}
              docId={docId}
              itemId={itemId}
              expression={expression}
              hideExpressionIcon
            />
          </div>
          <div style={{ width: 100, textAlign: "right", paddingRight: 4 }}>
            <FormattedPrice value={itemCalculations.pricePerUnit} />
          </div>

          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Material */}
          <div style={{ width: 302 }}></div>

          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Work */}
          <div style={{ width: 338 }}></div>

          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Totals */}
          <div
            style={{
              width: 80,
              fontWeight:
                (itemCalculations.timeTotal ?? 0) > 0 ? "bold" : "normal",
            }}
          >
            {getFormattedDuration(itemCalculations.timeTotal ?? 0)}h
          </div>
          <div
            style={{
              width: 100,
              fontWeight:
                (itemCalculations.priceSubTotal ?? 0) > 0 ? "bold" : "normal",
              textAlign: "right",
            }}
          >
            {getFormattedPrice(itemCalculations.priceSubTotal ?? 0)}
          </div>

          <AutoSave
            type="icon"
            enableReinitialize
            initialValues={initialValues}
          />
        </FormRow>
      </Form>
    </Formik>
  );
};

export const EstimatedDetailedCalculationRow = ({
  item,
  itemId,
  docId,
  projectId,
  contractType,
}: Props) => {
  const { t } = useTranslate(["QuoteItem"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const { enqueueSnackbar } = useSnackbar();
  const { getFormattedPrice, getFormattedDuration } = useFormatting();

  const client = useApolloClient();
  const [modifyItem] = useModifyItemEstimatedCalculationMutation({
    client,
  });

  const onSubmit = async (values: FormDetailedValues) => {
    try {
      await modifyItem({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            values: {
              materialBuyingPrice: values.materialBuyingPrice,
              materialMargin: values.materialMargin / 100,
              estimatedTime: values.estimatedTime,
              workSellingRate: values.workSellingRate,
              workBuyingRate: values.workBuyingRate,
              estimatedQuantity: values.estimatedQuantity,
              quantityUnit: values.quantityUnit,
            },
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const itemCalculations = item.proposedCalculation;
  if (!itemCalculations) throw new Error("Item calculations missing");

  const initialValues = React.useMemo(
    (): FormDetailedValues => ({
      materialBuyingPrice: itemCalculations.materialBuyingPricePerUnit,
      materialMargin: itemCalculations.materialMargin * 100,
      estimatedTime: itemCalculations.timePerUnit,
      workSellingRate: itemCalculations.workSellingRate,
      workBuyingRate: itemCalculations.workBuyingRate,
      estimatedQuantity: itemCalculations.quantity,
      quantityUnit: itemCalculations.quantityUnit,
    }),
    [
      itemCalculations.materialBuyingPricePerUnit,
      itemCalculations.materialMargin,
      itemCalculations.quantity,
      itemCalculations.quantityUnit,
      itemCalculations.timePerUnit,
      itemCalculations.workBuyingRate,
      itemCalculations.workSellingRate,
    ]
  );

  const validationSchema = Yup.object().shape({
    materialBuyingPrice: Yup.number()
      .label(
        t("Price / Unit", {
          ns: "QuoteItem",
        })
      )
      .min(0)
      .nullable(),
    materialMargin: Yup.number()
      .label(
        t("Margin", {
          ns: "QuoteItem",
        })
      )
      .min(-100)
      .nullable(),
    estimatedTime: Yup.number()
      .label(
        t("Time", {
          ns: "QuoteItem",
        })
      )
      .min(0)
      .nullable(),
    workSellingRate: Yup.number()
      .label(
        t("Work rate", {
          ns: "QuoteItem",
        })
      )
      .min(0)
      .nullable(),
    workBuyingRate: Yup.number()
      .label(
        t("Work cost", {
          ns: "QuoteItem",
        })
      )
      .min(0)
      .nullable(),
    estimatedQuantity: Yup.number()
      .label(
        t("Quantity", {
          ns: "QuoteItem",
        })
      )
      .min(0),
  });

  const attributeExpressions = item.attributeExpressions;
  const expression = attributeExpressions.find(
    expr => expr.attribute === "estimatedQuantity"
  );

  const vertical = item.vertical ?? item.inheritedVertical;
  const canEditWorkRate = !vertical;

  return (
    <Formik<FormDetailedValues>
      key={`form-${itemId}`}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form style={{ marginLeft: 8 }}>
        <FormRow
          onMouseDown={e => {
            e.stopPropagation();
          }}
          onTouchStart={e => {
            e.stopPropagation();
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Quantity */}
          <div style={{ width: 160 }}>
            <Stack direction={"row"} spacing={1}>
              <Box flex={1}>
                <FormattedFloatWithExpressionField
                  label=""
                  name={"estimatedQuantity"}
                  placeholder={t("Quantity", {
                    ns: "QuoteItem",
                  })}
                  disabled={false}
                  size="extra-small"
                  projectId={projectId}
                  docId={docId}
                  itemId={itemId}
                  expression={expression}
                  hideExpressionIcon
                />
              </Box>
              <Box flex={1}>
                <QuantityUnitField
                  name={"quantityUnit"}
                  usePlaceholder
                  disabled={false}
                  size="extra-small"
                />
              </Box>
            </Stack>
          </div>
          <div style={{ width: 100, textAlign: "right", paddingRight: 4 }}>
            <FormattedPrice value={itemCalculations.pricePerUnit} />
          </div>

          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Material */}
          <div style={{ width: 100 }}>
            <FormattedPriceField
              disabled={false}
              name="materialBuyingPrice"
              label=""
              placeholder={t("Price / Unit", {
                ns: "QuoteItem",
              })}
              type="price"
              size="extra-small"
            />
          </div>
          <div style={{ width: 60 }}>
            <FormattedFloatField
              disabled={contractType === "fmbp_fr"}
              name="materialMargin"
              label=""
              unit="%"
              multiplier={1}
              placeholder={t("Margin", {
                ns: "QuoteItem",
              })}
              size="extra-small"
            />
          </div>
          <div style={{ width: 40, textAlign: "right", paddingRight: 4 }}>
            {(itemCalculations.materialFactor ?? 1) !== 1 && (
              <Tooltip
                title={t("Factor", {
                  ns: "QuoteItem",
                })}
              >
                <span>
                  ×
                  {getFormattedFloat(
                    itemCalculations.materialFactor ?? 1,
                    language
                  )}
                </span>
              </Tooltip>
            )}
          </div>
          <div style={{ width: 90, textAlign: "right", paddingRight: 4 }}>
            <FormattedPrice value={itemCalculations.materialPricePerUnit} />
          </div>

          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Work */}
          <div style={{ width: 80 }}>
            <DurationField
              disabled={false}
              name="estimatedTime"
              label=""
              placeholder={t("Time", {
                ns: "QuoteItem",
              })}
              size="extra-small"
            />
          </div>
          <div style={{ width: 80 }}>
            <FormattedPriceField
              disabled={false}
              name="workSellingRate"
              label=""
              placeholder={t("Rate", {
                ns: "QuoteItem",
              })}
              type="price"
              size="extra-small"
            />
          </div>
          <div style={{ width: 32, paddingRight: 4 }}>
            <PopoverOpenButton
              content={
                <WorkRatesField
                  vertical={vertical ?? null}
                  isEditable={true}
                  isWorkRateEditable={canEditWorkRate}
                  workBuyingRate={itemCalculations.workBuyingRate ?? 0}
                  workSellingRate={itemCalculations.workSellingRate ?? 0}
                />
              }
            >
              <IconButton
                color="secondary"
                size="extra-small"
                sx={{ flexGrow: 0, flexShrink: 0 }}
              >
                <Tooltip
                  title={t("Work margin: {percentage}", {
                    ns: "QuoteItem",
                    percentage: getFormattedPercentage(
                      itemCalculations.workBuyingRate !== 0
                        ? itemCalculations.workSellingRate /
                            itemCalculations.workBuyingRate -
                            1
                        : 0,
                      language
                    ),
                  })}
                >
                  <HelpIcon />
                </Tooltip>
              </IconButton>
            </PopoverOpenButton>
          </div>
          <div style={{ width: 40, textAlign: "right", paddingRight: 4 }}>
            {(itemCalculations.workFactor ?? 1) !== 1 && (
              <Tooltip
                title={t("Factor", {
                  ns: "QuoteItem",
                })}
              >
                <span>
                  ×
                  {getFormattedFloat(
                    itemCalculations.workFactor ?? 1,
                    language
                  )}
                </span>
              </Tooltip>
            )}
          </div>
          <div style={{ width: 90, textAlign: "right", paddingRight: 4 }}>
            <FormattedPrice value={itemCalculations.workPricePerUnit} />
          </div>

          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Totals */}
          <div
            style={{
              width: 80,
              fontWeight:
                (itemCalculations.timeTotal ?? 0) > 0 ? "bold" : "normal",
            }}
          >
            {getFormattedDuration(itemCalculations.timeTotal ?? 0)}h
          </div>
          <div
            style={{
              width: 100,
              fontWeight:
                (itemCalculations.priceSubTotal ?? 0) > 0 ? "bold" : "normal",
              textAlign: "right",
            }}
          >
            {getFormattedPrice(itemCalculations.priceSubTotal ?? 0)}
          </div>

          <AutoSave
            type="icon"
            enableReinitialize
            initialValues={initialValues}
          />
        </FormRow>
      </Form>
    </Formik>
  );
};

export const EstimatedCalculationHeaderRow = () => (
  <FormRow style={{ marginLeft: 8 }}>
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Quantity */}
    <div style={{ width: 264 }}>
      <Typography>Quantity</Typography>
    </div>
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Material */}
    <div style={{ width: 302 }}>
      <Typography>Material</Typography>
    </div>
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Work */}
    <div style={{ width: 338 }}>
      <Typography>Work</Typography>
    </div>
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Total */}
    <div style={{ width: 184 }}>
      <Typography style={{ fontWeight: "bold" }}>Total</Typography>
    </div>
    {/* AutoSave icon */}
    <div style={{ width: 24 }} />
  </FormRow>
);

export const EstimatedCalculationEmptyRow = () => (
  <FormRow style={{ marginLeft: 8 }}>
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Quantity */}
    <div style={{ width: 264 }} />
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Material */}
    <div style={{ width: 302 }} />
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Work */}
    <div style={{ width: 338 }} />
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Total */}
    <div style={{ width: 184 }} />
    {/* AutoSave icon */}
    <div style={{ width: 24 }} />
  </FormRow>
);

const FormRow = styled("div")`
  height: ${size.tableItemFullHeight}px;

  & {
    position: relative;
    display: flex;
    align-items: center;
  }

  & > * {
    flex-shrink: 0;
    flex-grow: 0;
  }

  & > * + * {
    margin-left: 4px;
  }
`;
