/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated.js';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCrmOrganisationMutationVariables = Types.Exact<{
  input: Array<Types.CrmOrganisationCreateInput>;
}>;


export type CreateCrmOrganisationMutation = { createCrmOrganisation: { __typename: 'CreateCrmOrganisationResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, title: string, email: any, number: string, abcCategory: Types.CrmOrganisationAbcCategory, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any }> }> } };

export type CreateCrmOrganisation_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, title: string, email: any, number: string, abcCategory: Types.CrmOrganisationAbcCategory, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any }> };

export type ModifyCrmOrganisationMutationVariables = Types.Exact<{
  organisations: Array<Types.CrmOrganisationModifyInput>;
}>;


export type ModifyCrmOrganisationMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, title: string, description: string, website: string, isMeister: boolean, email: any, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> } };

export type OrganisationRouter__CrmCompanyFragment = { __typename: 'CrmCompany', id: string, title: string, description: string, website: string, isMeister: boolean, email: any, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export const CreateCrmOrganisation_CrmCompanyFragmentDoc = gql`
    fragment CreateCrmOrganisation_CrmCompany on CrmCompany {
  id
  title
  email
  number
  abcCategory
  contactPerson {
    id
    fullname
  }
  billingAddress {
    ...AddressDetails__Address
  }
  logo {
    ...AttachmentSnapshot
  }
  customFields {
    key
    value
  }
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;
export const OrganisationRouter__CrmCompanyFragmentDoc = gql`
    fragment OrganisationRouter__CrmCompany on CrmCompany {
  id
  title
  description
  website
  isMeister
  contactPerson {
    id
    title
    description
    website
    isMeister
    familyname
    firstname
    fullname
    locale
    skillset
  }
  email
  phones {
    id
    main
    type
    number
  }
  logo {
    ...AttachmentSnapshot
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const CreateCrmOrganisationDocument = gql`
    mutation CreateCrmOrganisation($input: [CrmOrganisationCreateInput!]!) {
  createCrmOrganisation(input: $input) {
    crmOrganisations {
      id
      ...CreateCrmOrganisation_CrmCompany
    }
  }
}
    ${CreateCrmOrganisation_CrmCompanyFragmentDoc}`;
export type CreateCrmOrganisationMutationFn = Apollo.MutationFunction<CreateCrmOrganisationMutation, CreateCrmOrganisationMutationVariables>;

/**
 * __useCreateCrmOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateCrmOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCrmOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCrmOrganisationMutation, { data, loading, error }] = useCreateCrmOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCrmOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCrmOrganisationMutation, CreateCrmOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCrmOrganisationMutation, CreateCrmOrganisationMutationVariables>(CreateCrmOrganisationDocument, options);
      }
export type CreateCrmOrganisationMutationHookResult = ReturnType<typeof useCreateCrmOrganisationMutation>;
export type CreateCrmOrganisationMutationResult = Apollo.MutationResult<CreateCrmOrganisationMutation>;
export type CreateCrmOrganisationMutationOptions = Apollo.BaseMutationOptions<CreateCrmOrganisationMutation, CreateCrmOrganisationMutationVariables>;
export const ModifyCrmOrganisationDocument = gql`
    mutation ModifyCrmOrganisation($organisations: [CrmOrganisationModifyInput!]!) {
  modifyCrmOrganisation(input: $organisations) {
    crmOrganisations {
      id
      ...OrganisationRouter__CrmCompany
    }
  }
}
    ${OrganisationRouter__CrmCompanyFragmentDoc}`;
export type ModifyCrmOrganisationMutationFn = Apollo.MutationFunction<ModifyCrmOrganisationMutation, ModifyCrmOrganisationMutationVariables>;

/**
 * __useModifyCrmOrganisationMutation__
 *
 * To run a mutation, you first call `useModifyCrmOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCrmOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCrmOrganisationMutation, { data, loading, error }] = useModifyCrmOrganisationMutation({
 *   variables: {
 *      organisations: // value for 'organisations'
 *   },
 * });
 */
export function useModifyCrmOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<ModifyCrmOrganisationMutation, ModifyCrmOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyCrmOrganisationMutation, ModifyCrmOrganisationMutationVariables>(ModifyCrmOrganisationDocument, options);
      }
export type ModifyCrmOrganisationMutationHookResult = ReturnType<typeof useModifyCrmOrganisationMutation>;
export type ModifyCrmOrganisationMutationResult = Apollo.MutationResult<ModifyCrmOrganisationMutation>;
export type ModifyCrmOrganisationMutationOptions = Apollo.BaseMutationOptions<ModifyCrmOrganisationMutation, ModifyCrmOrganisationMutationVariables>;