import React from "react";
import { useUserPreference } from "../../features/users/useUserPreference.js";

export function useKanbanColumnCollapseStore({
  key,
  columns,
}: {
  key: string;
  columns: { id: string }[] | undefined;
}) {
  const {
    value: collapsedState,
    saveValue: setCollapsedState,
    loading: collapseStateLoading,
  } = useUserPreference<Record<string, boolean>>(key, {});

  const setPhaseCollapsed = React.useCallback(
    (phaseId: string, collapsed: boolean) => {
      if (!columns) throw new Error("Columns are missing");

      setCollapsedState({
        ...columns.reduce((acc, column) => {
          const previousValue = collapsedState?.[column.id];

          if (previousValue !== undefined) {
            return { ...acc, [column.id]: previousValue };
          }

          return acc;
        }, {}),

        [phaseId]: collapsed,
      });
    },
    [collapsedState, columns, setCollapsedState]
  );

  return {
    collapsedState,
    setCollapsedState,
    setPhaseCollapsed,
    collapseStateLoading,
  };
}
