import { ApolloCache, FetchResult, gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Typography } from "@mui/material";
import { T, useTranslate } from "@tolgee/react";
import React from "react";
import { ConfirmProcessRef } from "../../commons/modals/ConfirmProcess.js";
import { ProjectStateMachineStatus } from "../../../clients/graphqlTypes.js";
import { ProjectEventNextStatusChip } from "./badges/ProjectEventNextStatusBadge.js";
import {
  SendProjectStateEventMutation,
  UseProjectChangeStateDocument,
  UseProjectChangeStateQuery,
  useSendProjectStateEventMutation,
} from "./useProjectChangeState.generated.js";
import { ProjectStateMachineEvent } from "./useProjectStates.js";

export const useProjectChangeConfirm = ({
  confirmProcessRef,
}: {
  confirmProcessRef: React.MutableRefObject<ConfirmProcessRef | null>;
}) => {
  const { t } = useTranslate(["ProjectState", "Global"]);

  const client = useApolloClient();

  const handleProjectChangeStateConfirm = React.useCallback(
    async (projectId: string, stateEvent: ProjectStateMachineEvent) => {
      const outgoingQuotesQuery =
        await client.query<UseProjectChangeStateQuery>({
          query: UseProjectChangeStateDocument,
          variables: { projectId },
        });
      const outgoingQuotesCount =
        getDataOrNull(outgoingQuotesQuery.data?.projectOutgoingQuotes)
          ?.totalCount ?? 0;

      const isConfirmed = await confirmProcessRef.current?.startConfirmProcess({
        title: t("Are you sure you want to continue?", { ns: "ProjectState" }),
        content: () =>
          outgoingQuotesCount > 0 &&
          ["CONTRACTEE_DECLINE", "CONTRACTOR_DECLINE"].includes(stateEvent) ? (
            <Typography variant="body1">
              <T
                ns="ProjectState"
                keyName="Project state will be changed to {state} and mark published quotes as declined"
                params={{
                  state: () => (
                    <ProjectEventNextStatusChip
                      projectEvent={stateEvent}
                      style={{ pointerEvents: "none" }}
                      size="small"
                    />
                  ),
                }}
              />
            </Typography>
          ) : (
            <Typography variant="body1">
              <T
                ns="ProjectState"
                keyName="Project state will be changed to {state}"
                params={{
                  state: () => (
                    <ProjectEventNextStatusChip
                      projectEvent={stateEvent}
                      style={{ pointerEvents: "none" }}
                      size="small"
                    />
                  ),
                }}
              />
            </Typography>
          ),
        maxWidth: "xs",
      });

      return isConfirmed;
    },
    [client, confirmProcessRef, t]
  );

  return { handleProjectChangeStateConfirm };
};

export const useProjectChangeState = ({
  projectId,
  projectState,
  confirmProcessRef,
  refetchQueries,
  changeProjectStateUpdate,
}: {
  projectId: string;
  projectState: ProjectStateMachineStatus;
  confirmProcessRef: React.MutableRefObject<ConfirmProcessRef | null>;
  refetchQueries?: string[];
  changeProjectStateUpdate?(
    oldState: ProjectStateMachineStatus,
    newStateEvent: ProjectStateMachineEvent,
    cache: ApolloCache<any>,
    result: Omit<FetchResult<SendProjectStateEventMutation>, "context">
  ): void;
}) => {
  const client = useApolloClient();
  const [sendProjectStateEvent, { loading }] = useSendProjectStateEventMutation(
    { client, refetchQueries, awaitRefetchQueries: true }
  );

  const { handleProjectChangeStateConfirm } = useProjectChangeConfirm({
    confirmProcessRef,
  });

  const handleStateChange = async (stateEvent: ProjectStateMachineEvent) => {
    const isConfirmed = await handleProjectChangeStateConfirm(
      projectId,
      stateEvent
    );
    if (!isConfirmed) return;

    await sendProjectStateEvent({
      variables: {
        input: {
          projectId: projectId,
          event: { [stateEvent]: {} },
        },
      },
      update: (cache, result) =>
        changeProjectStateUpdate?.(projectState, stateEvent, cache, result),
    });
  };

  return { handleStateChange, loading };
};
