import { LabeledValue, LabeledValueProps } from "@msys/ui";
import { QuantityUnit } from "../../../clients/graphqlTypes.js";
import { useQuantityUnits } from "./useQuantityUnits.js";

interface Props extends LabeledValueProps {
  value?: number;
  unit?: QuantityUnit;
}

export const LabeledQuantityValue = ({ value, unit, ...restProps }: Props) => {
  const { quantityUnitLabels } = useQuantityUnits();
  return (
    <LabeledValue {...restProps}>
      {value || 0}
      {unit ? "\u00A0" + quantityUnitLabels[unit] : ""}
    </LabeledValue>
  );
};
