import { ellipsisStyle, useScreenWidth } from "@msys/ui";
import { Box, BoxProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactNode } from "react";
import { Stack } from "./Stack.js";

export interface PageHeaderProps {
  /**
   * Whether title should only take one line
   */
  restricted?: boolean;
  /**
   * Main status label
   */
  status?: React.ReactNode;
  /**
   * Kicker title before page title
   */
  kickerTitle?: ReactNode;
  /**
   * Number title for displaying id/no.
   */
  numberTitle?: ReactNode;
  /**
   * Page header title
   */
  title: string | React.ReactElement;
  /**
   * Page header title variant
   */
  titleVariant?: React.ComponentProps<typeof Typography>["variant"];
  /**
   * Create button element
   */
  CreateButton?: JSX.Element;
  /**
   * Menu button element
   */
  MenuButton?: JSX.Element;
  /**
   * Action button element
   */
  ActionButton?: JSX.Element;
  /**
   * Action button elements array
   */
  ActionButtons?: JSX.Element[];
  /**
   * Banner element
   */
  Banner?: JSX.Element;
}

const StyledTitle = styled(Typography)`
  margin: 0;
  padding: 0;
`;

const StatusContainer = styled("div")`
  margin-bottom: -2px;
`;

export const PageSectionHeader = ({
  restricted = false,
  title,
  titleVariant = "h1",
  kickerTitle,
  numberTitle,
  status,
  CreateButton,
  MenuButton,
  ActionButton,
  ActionButtons,
  Banner,
  ...props
}: PageHeaderProps & Omit<BoxProps, "title">) => {
  const { isMaxPhone } = useScreenWidth();
  return (
    <Box width="100%" {...props}>
      <Stack
        flexDirection={isMaxPhone ? "column" : "row"}
        justifyContent={isMaxPhone ? "flex-start" : "flex-start"}
        alignItems={isMaxPhone ? "stretch" : "center"}
        spacing={isMaxPhone ? 1 : 2}
      >
        <Stack flexDirection="column" spacing={0} flex={1}>
          <Stack justifyContent="space-between" alignItems="center">
            <div>
              {status ? <StatusContainer>{status}</StatusContainer> : null}
              {kickerTitle ? (
                <Typography variant="caption" component="div" color="primary">
                  {kickerTitle}
                </Typography>
              ) : null}
            </div>
            <StatusContainer>
              {numberTitle ? (
                <Typography variant="body2" component="div" color="primary">
                  {numberTitle}
                </Typography>
              ) : null}
            </StatusContainer>
          </Stack>
          <Stack justifyContent="space-between" alignItems="center">
            <StyledTitle
              style={restricted ? ellipsisStyle : undefined}
              variant={titleVariant}
            >
              {title}
            </StyledTitle>
            {CreateButton ||
            MenuButton ||
            ActionButton ||
            ActionButtons?.length ? (
              <Stack
                justifyContent="flex-end"
                alignItems="center"
                alignSelf="flex-start"
              >
                {ActionButton}
                {ActionButtons}
                {CreateButton}
                {MenuButton}
              </Stack>
            ) : null}
          </Stack>
        </Stack>
        {Banner}
      </Stack>
    </Box>
  );
};
