import { CardContainer, StatusLabel, useScreenWidth } from "@msys/ui";
import { Box, Container, Grid, Paper, useTheme } from "@mui/material";
import { FC, useMemo } from "react";
import { Link, Outlet, useMatch, useNavigate } from "react-router-dom";
import { color } from "../../../common/MuiThemeProvider.js";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageColumn } from "../../commons/layout/PageColumn.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageMiddleColumn } from "../../commons/layout/PageMiddleColumn.js";
import { PageSectionHeader } from "../../commons/layout/PageSectionHeader.js";
import { ProjectIcon } from "../../features/projects/ProjectIcon.js";
import { TicketIcon } from "../../features/projects/TicketIcon.js";

type PageSubmenuItem = PageTopbarItem;

export const ExampleLayoutLeftSidebarPreview: FC = () => {
  const { isMinTablet } = useScreenWidth();
  return (
    <Page>
      <PageColumn $hasBackground $hasRightBorder>
        <CardContainer title="Tree">
          <Box height={2000} />
        </CardContainer>
      </PageColumn>
      {isMinTablet && (
        <PageContainer>
          <Container maxWidth="md">
            <CardContainer title="Preview">
              <Box height={2000} />
            </CardContainer>
          </Container>
        </PageContainer>
      )}
    </Page>
  );
};

export const ExampleLayoutLeftSidebarPreviewFluid: FC = () => {
  const { isMinTablet } = useScreenWidth();
  return (
    <Page>
      <PageColumn $hasBackground $hasRightBorder>
        <CardContainer title="Tree">
          <Box height={2000} />
        </CardContainer>
      </PageColumn>
      {isMinTablet && (
        <PageContainer $noPadding>
          <Page>
            <PageContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardContainer title="Preview">
                    <Box height={500} />
                  </CardContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CardContainer title="Settings">
                    <Box height={250} />
                  </CardContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CardContainer title="Preferences">
                    <Box height={250} />
                  </CardContainer>
                </Grid>
              </Grid>
            </PageContainer>
          </Page>
        </PageContainer>
      )}
    </Page>
  );
};

export const ExampleLayoutLeftSidebarList: FC = () => {
  const theme = useTheme();
  const { isMinDesktop } = useScreenWidth();

  const submenuItems = useMemo((): PageSubmenuItem[] => {
    return [
      {
        name: "planning-projects",
        label: "Tickets",
        icon: <TicketIcon />,
        path: `/planning/tickets`,
      },
      {
        name: "planning-projects",
        label: "Projects",
        icon: <ProjectIcon />,
        path: `/planning/projects`,
      },
    ];
  }, []);

  return (
    <Page submenuItems={submenuItems}>
      {isMinDesktop && (
        <PageColumn $hasBackground $hasRightBorder>
          <CardContainer title="Tree">
            <Box height={2000} />
          </CardContainer>
        </PageColumn>
      )}
      <PageContainer $noPadding>
        <Page>
          <PageContainer>
            <PageSectionHeader
              status={
                <StatusLabel color={theme.palette.grey[600]}>
                  Example
                </StatusLabel>
              }
              title="Page title"
              mb={2}
            />
            <CardContainer title="List">
              <Box height={2000} />
            </CardContainer>
          </PageContainer>
        </Page>
      </PageContainer>
    </Page>
  );
};

export const ExampleLayoutLeftSidebarPreviewWithEditing: FC = () => {
  const { isMinDesktop, isMinLargeDesktop } = useScreenWidth();
  return (
    <Page>
      <PageColumn $hasRightBorder $hasBackground>
        <CardContainer title="Tree">
          <Box height={2000} />
        </CardContainer>
      </PageColumn>
      <PageMiddleColumn
        hasRightBorder
        hasLeftBorder
        hasBackground
        isAbsolute={!isMinLargeDesktop}
        title="Edit item"
      >
        <CardContainer title="Header">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Properties">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Files">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Subitems">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Buildings">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Task management">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Time management">
          <Box height={200} />
        </CardContainer>
      </PageMiddleColumn>
      {isMinDesktop && (
        <PageContainer $noPadding>
          <Page>
            <PageContainer>
              <CardContainer title="Preview">
                <Box height={2000} />
              </CardContainer>
            </PageContainer>
          </Page>
        </PageContainer>
      )}
    </Page>
  );
};

export const ExampleLayoutLeftSidebarExampleWithEditing: FC = () => {
  const match = useMatch(":section");
  const { isMinDesktop } = useScreenWidth();

  return (
    <Page>
      <PageColumn $hasRightBorder $hasBackground $display="block">
        <CardContainer title="Tree">
          <Box p={1}>
            {Array(40)
              .fill(null)
              .map((v, index) => (
                <Paper
                  key={index}
                  // @ts-ignore
                  component={Link}
                  to={`/examples/layout-left-sidebar-example-editing/${index}`}
                  style={{
                    display: "block",
                    margin: "2px 0",
                    backgroundColor:
                      Number(match?.params?.section) === index
                        ? color.blue7
                        : "unset",
                  }}
                >
                  <Box height={50} />
                </Paper>
              ))}
          </Box>
        </CardContainer>
      </PageColumn>
      <Outlet />
      {isMinDesktop && (
        <PageContainer $noPadding>
          <Page>
            <PageContainer>
              <CardContainer title="Preview">
                <Box height={2000} />
              </CardContainer>
            </PageContainer>
          </Page>
        </PageContainer>
      )}
    </Page>
  );
};
export const ExampleLayoutLeftSidebarExampleWithEditingSection: FC = () => {
  const navigate = useNavigate();
  const { isMinLargeDesktop } = useScreenWidth();

  return (
    <PageMiddleColumn
      hasRightBorder
      hasBackground
      isAbsolute={!isMinLargeDesktop}
      title="Edit item"
      onCloseButtonClick={() =>
        navigate(`/examples/layout-left-sidebar-example-editing`)
      }
    >
      <CardContainer title="Header">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Properties">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Files">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Subitems">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Buildings">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Task management">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Time management">
        <Box height={200} />
      </CardContainer>
    </PageMiddleColumn>
  );
};

export const ExampleLayoutLeftSidebarExampleWithTimesheet: FC = () => {
  const match = useMatch(":section");

  return (
    <Page>
      <PageColumn $hasRightBorder $hasBackground $display="block">
        <CardContainer title="Tree">
          <Box p={1}>
            {Array(40)
              .fill(null)
              .map((v, index) => (
                <Paper
                  key={index}
                  // @ts-ignore
                  component={Link}
                  to={`/examples/layout-left-sidebar-example-timesheet/${index}`}
                  style={{
                    display: "block",
                    margin: "2px 0",
                    backgroundColor:
                      Number(match?.params?.section) === index
                        ? color.blue7
                        : "unset",
                  }}
                >
                  <Box height={50} />
                </Paper>
              ))}
          </Box>
        </CardContainer>
      </PageColumn>
      <Outlet />
      <PageColumn $hasBackground>
        <CardContainer title="Timesheet">
          <Box height={800} />
        </CardContainer>
      </PageColumn>
    </Page>
  );
};
export const ExampleLayoutLeftSidebarExampleWithTimesheetSection: FC = () => {
  const navigate = useNavigate();
  const { isMinDesktop } = useScreenWidth();

  return (
    <PageMiddleColumn
      hasRightBorder
      hasBackground
      isAbsolute={!isMinDesktop}
      title="Edit item"
      onCloseButtonClick={() =>
        navigate(`/examples/layout-left-sidebar-example-timesheet`)
      }
    >
      <CardContainer title="Header">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Properties">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Files">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Subitems">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Buildings">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Task management">
        <Box height={200} />
      </CardContainer>
      <CardContainer title="Time management">
        <Box height={200} />
      </CardContainer>
    </PageMiddleColumn>
  );
};
