/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationTaskSettingsBox_DefaultsFragment = { __typename: 'OrganisationDefaults', id: string, defaultTaskPhotoIsRequired: boolean };

export type OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutationVariables = Types.Exact<{
  input: Types.OrganisationDefaultsUpdateInput;
}>;


export type OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation = { updateOrganisationDefaults: { __typename: 'OrganisationDefaultsUpdateResult', organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultTaskPhotoIsRequired: boolean } } };

export const OrganisationTaskSettingsBox_DefaultsFragmentDoc = gql`
    fragment OrganisationTaskSettingsBox_Defaults on OrganisationDefaults {
  id
  defaultTaskPhotoIsRequired
}
    `;
export const OrganisationTaskSettingsForm_ModifyOrganisationDefaultsDocument = gql`
    mutation OrganisationTaskSettingsForm_ModifyOrganisationDefaults($input: OrganisationDefaultsUpdateInput!) {
  updateOrganisationDefaults(input: $input) {
    organisationDefaults {
      id
      ...OrganisationTaskSettingsBox_Defaults
    }
  }
}
    ${OrganisationTaskSettingsBox_DefaultsFragmentDoc}`;
export type OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutationFn = Apollo.MutationFunction<OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutationVariables>;

/**
 * __useOrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation__
 *
 * To run a mutation, you first call `useOrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationTaskSettingsFormModifyOrganisationDefaultsMutation, { data, loading, error }] = useOrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutationVariables>(OrganisationTaskSettingsForm_ModifyOrganisationDefaultsDocument, options);
      }
export type OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutationHookResult = ReturnType<typeof useOrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation>;
export type OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutationResult = Apollo.MutationResult<OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation>;
export type OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutationOptions = Apollo.BaseMutationOptions<OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutationVariables>;