import * as React from "react";
import { useUpdateEffect } from "react-use";
import { useDebounce } from "use-debounce";
import {
  LoadingBar,
  LoadingBarRef,
} from "../../app/commons/loading/LoadingBar.js";
import { useApolloNetworkStatus } from "../../clients/ApolloClientProvider.js";
import { GlobalLoadingStateContext } from "./GlobalLoadingStateContext.js";

export function GlobalLoadingBar() {
  const loadingRef = React.useRef<LoadingBarRef>(null);
  const { numPendingQueries, numPendingMutations } = useApolloNetworkStatus();
  const { numUploadingFiles } = useGlobalLoadingStatus();
  const [numQueries] = useDebounce(
    numPendingQueries + numPendingMutations + numUploadingFiles,
    100
  );

  useUpdateEffect(() => {
    if (numQueries > 0) {
      const timeoutId = setTimeout(() => {
        loadingRef.current?.start();
      }, 600);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      loadingRef.current?.complete();
    }
  }, [numQueries]);

  return (
    <LoadingBar
      ref={loadingRef}
      spinner={numPendingMutations > 0 || numUploadingFiles > 0}
    />
  );
}

const useGlobalLoadingStatus = () => {
  const { counters } = React.useContext(GlobalLoadingStateContext);
  const numUploadingFiles = React.useMemo<number>(
    () => Object.keys(counters).reduce((acc, key) => acc + counters[key], 0),
    [counters]
  );

  return { numUploadingFiles };
};
