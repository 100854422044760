import { gql, useApolloClient } from "@apollo/client";
import {
  Attachment,
  CollapseSection,
  isImageOr3dModel,
  Modal,
  processAttachment,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Description as DescriptionIcon } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Link as MuiLink,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { omit, partition, uniqueId } from "lodash-es";
import { Moment } from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { DateAndTimePickersField } from "../../commons/form-fields/DateAndTimePickersField.js";
import { SelectField } from "../../commons/form-fields/SelectField.js";
import { GalleryGrid } from "../../commons/images/GalleryGrid.js";
import { Stack } from "../../commons/layout/Stack.js";
import { AttachmentInput } from "../../../clients/graphqlTypes.js";
import { AttachmentUploader } from "../attachments/AttachmentUploader.js";
import { getMemberOptions } from "../users/helpers.js";
import {
  useCreateTodoItemModalQuery,
  useCreateTodoItemMutation,
} from "./CreateTodoItemModal.generated.js";
import { RelatedToBox } from "./RelatedToBox.js";

interface FormValues {
  title: string;
  description?: string;
  time?: Moment;
  assigneeId: string;
  attachments: Attachment[];
  linkedProjectId: string | null;
  linkedCrmCompanyId: string | null;
  linkedCrmPersonId: string | null; // deprecated
}

interface Props {
  assigneeId?: string;
  disableAssigneeInput?: boolean;
  linkedProjectId?: string;
  linkedCrmCompanyId?: string;
  linkedCrmPersonId?: string; // deprecated
  title?: string;
  handleClose: () => void;
  handleComplete?: (todoItemId: string) => Promise<void> | void;
  refetchQueries?: string[];
}

export const CreateTodoItemModal = ({
  title,
  handleClose,
  handleComplete,
  assigneeId,
  disableAssigneeInput = false,
  linkedProjectId,
  linkedCrmCompanyId,
  linkedCrmPersonId,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["Tasks", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const query = useCreateTodoItemModalQuery({
    client,
  });
  const assigneeOptions = getMemberOptions(query.data?.orgMembers ?? []);
  const [createTodoItem] = useCreateTodoItemMutation({ client });

  async function onSubmit({
    attachments,
    assigneeId,
    linkedProjectId,
    linkedCrmCompanyId,
    linkedCrmPersonId,
    ...values
  }: FormValues) {
    const res = await createTodoItem({
      variables: {
        input: {
          ...values,
          assigneeId: assigneeId || undefined,
          attachments: attachments
            .map(a => omit(a, ["id", "__typename", "__type"]))
            .filter(a => a.url) as AttachmentInput[],
          linkedCrmOrganisationId: linkedCrmCompanyId,
          linkedProjectId: linkedProjectId,
          linkedCrmUserId: linkedCrmPersonId,
        },
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });

    const todoItemId = res.data?.createTodoItem.todoItem.id;
    if (!todoItemId)
      throw new Error(
        t("Failed to create a new task item", {
          ns: "Tasks",
        })
      );

    await handleComplete?.(todoItemId);
    handleClose();
    enqueueSnackbar(
      t("New task created", {
        ns: "Tasks",
      })
    );
  }

  const attachmentUploaderRef = React.useRef<HTMLInputElement>(null);
  const onAddAttachment = () => {
    if (attachmentUploaderRef.current) {
      attachmentUploaderRef.current.click();
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .label(
            t("Title", {
              ns: "Tasks",
            })
          )
          .required(),
        description: Yup.string().label(
          t("Description", {
            ns: "Tasks",
          })
        ),
        time: Yup.date().label(
          t("Time", {
            ns: "Tasks",
          })
        ),
        assigneeId: Yup.string().label(
          t("Assignee", {
            ns: "Tasks",
          })
        ),
      }),
    [t]
  );

  const isLinked = !!(
    linkedProjectId ||
    linkedCrmCompanyId ||
    linkedCrmPersonId
  );

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
      initialValues={{
        title: "",
        description: "",
        assigneeId: assigneeId ?? "",
        attachments: [],
        linkedProjectId: linkedProjectId ?? null,
        linkedCrmCompanyId: linkedCrmCompanyId ?? null,
        linkedCrmPersonId: linkedCrmPersonId ?? null,
      }}
    >
      {({
        isValid,
        isSubmitting,
        setFieldValue,
        values,
        setValues,
        setFieldTouched,
      }) => (
        <Modal
          title={
            title ??
            t("New task", {
              ns: "Tasks",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text", disabled: isSubmitting },
            },
            {
              label: t("Create", {
                ns: "Global",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                loading: isSubmitting,
                disabled: !isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack flexDirection="column">
              <RelatedToBox
                linkedProjectId={values.linkedProjectId}
                linkedCrmCompanyId={values.linkedCrmCompanyId}
                linkedCrmPersonId={values.linkedCrmPersonId}
                handleDelete={() =>
                  setValues(values => ({
                    ...values,
                    linkedProjectId: null,
                    linkedCrmCompanyId: null,
                    linkedCrmUserId: null,
                  }))
                }
                handleChange={(crmCompanyId, projectId) => {
                  setValues(values => ({
                    ...values,
                    linkedProjectId: projectId,
                    linkedCrmCompanyId: crmCompanyId,
                    linkedCrmUserId: null,
                  }));
                }}
              />
              <Field
                component={TextField}
                autoFocus
                label={t("Title", {
                  ns: "Tasks",
                })}
                name="title"
                required
              />
              <Field
                component={TextField}
                label={t("Description", {
                  ns: "Tasks",
                })}
                name="description"
                multiline
                rows={4}
              />
              <DateAndTimePickersField
                name="time"
                dateLabel={t("Date", {
                  ns: "Tasks",
                })}
                timeLabel={t("Time", {
                  ns: "Tasks",
                })}
              />
              <SelectField
                label={t("Assignee", {
                  ns: "Tasks",
                })}
                name="assigneeId"
                options={assigneeOptions}
                disabled={disableAssigneeInput}
              />

              <CollapseSection
                title={t("Files", {
                  ns: "Tasks",
                })}
                itemCount={values.attachments.length}
                ActionButtons={
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      onAddAttachment();
                    }}
                  >
                    <AddIcon />
                    <AttachmentUploader
                      innerRef={attachmentUploaderRef}
                      accept={"*"}
                      multiple={true}
                      onComplete={attachments => {
                        setFieldValue("attachments", [
                          ...values.attachments,
                          ...attachments,
                        ]);
                      }}
                    />
                  </IconButton>
                }
              >
                <FieldArray
                  name="attachments"
                  render={arrayHelpers => {
                    const typedAttachments =
                      values.attachments.map(processAttachment);
                    const [images, files] = partition(
                      typedAttachments,
                      isImageOr3dModel
                    );
                    return (
                      <>
                        <Box>
                          <GalleryGrid
                            images={images}
                            handleClick={null}
                            handleDelete={image => {
                              arrayHelpers.remove(
                                typedAttachments.indexOf(image)
                              );
                            }}
                            columns={4}
                            showDeleteButton={true}
                          />
                        </Box>
                        {files.length > 0 && (
                          <List disablePadding>
                            {files.map((file, index) => (
                              <Stack
                                key={index}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <ListItem>
                                  <MuiLink href={file.url} target="_blank">
                                    <Stack>
                                      <DescriptionIcon fontSize="small" />
                                      {file.title}
                                    </Stack>
                                  </MuiLink>
                                </ListItem>
                                <Tooltip
                                  title={t("Delete File", {
                                    ns: "Tasks",
                                  })}
                                >
                                  <IconButton
                                    aria-label={t("Delete File", {
                                      ns: "Tasks",
                                    })}
                                    color="primary"
                                    onClick={() => {
                                      arrayHelpers.remove(
                                        typedAttachments.indexOf(file)
                                      );
                                    }}
                                    size="small"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            ))}
                          </List>
                        )}
                      </>
                    );
                  }}
                />
              </CollapseSection>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
