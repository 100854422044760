import React from "react";
import { useTranslate } from "@tolgee/react";
import { Props2 } from "../../../clients/graphqlTypes.js";
import { PurchaseOrder__ItemFragment } from "../../main-routes/projects/ProjectPurchaseOrderPreview.generated.js";
import {
  ItemProductFieldsFragment,
  WithItemPropertiesFragment,
} from "../doc-items/Fragments.generated.js";
import { notSetSymbol } from "../doc-items/helpers.js";
import { getPropValueWithUnit } from "../doc-items/properties.js";
import { usePropertyHelpers } from "../doc-items/usePropertyHelpers.js";

export const useItemProps2 = (
  properties: Props2[]
): { key: string; value: string }[] => {
  const { getUnitLabel, getBoolLabel, getNumberLabel } = usePropertyHelpers();
  return React.useMemo(
    () =>
      properties
        .filter(property => property.clientVisibility === true)
        .map(property => {
          return {
            key: property.label,
            value: getPropValueWithUnit(
              property,
              getUnitLabel,
              getBoolLabel,
              getNumberLabel,
              notSetSymbol
            ),
          };
        }),
    [properties, getUnitLabel, getBoolLabel, getNumberLabel]
  );
};

type ItemWithProps2 = Extract<WithItemPropertiesFragment, { props2: Props2[] }>;

export const useQuoteItemProperties = (
  item?:
    | (Pick<ItemWithProps2, "props2"> & {
        product?: Pick<
          ItemProductFieldsFragment,
          "props2" | "brand" | "brandLine" | "brandClientVisibility"
        > | null;
      })
    | null
): [
  properties: { key: string; value: string }[],
  productProperties: { key: string; value: string }[],
] => {
  const { t } = useTranslate(["Product"]);

  const properties = useItemProps2(item && "props2" in item ? item.props2 : []);
  const productProperties = useItemProps2(
    item && item.product && "props2" in item.product ? item.product.props2 : []
  );

  return React.useMemo(
    () => [
      properties,
      [
        ...(item &&
        item.product &&
        "brand" in item.product &&
        item.product.brand &&
        item.product.brandClientVisibility !== "hide"
          ? [
              {
                key: t("Brand", {
                  ns: "Product",
                }),
                value: item.product.brand,
              },
            ]
          : []),
        ...(item &&
        item.product &&
        "brandLine" in item.product &&
        item.product.brandLine &&
        item.product.brandClientVisibility !== "hide"
          ? [
              {
                key: t("Brand line", {
                  ns: "Product",
                }),
                value: item.product.brandLine,
              },
            ]
          : []),
        ...productProperties,
      ],
    ],
    [t, properties, productProperties, item]
  );
};

export const isDefinedProperty = (property: { key: string; value: string }) =>
  property.value !== notSetSymbol;

export const itemHasProperties = (
  item?:
    | (Pick<ItemWithProps2, "props2"> & {
        product?: Pick<
          ItemProductFieldsFragment,
          "props2" | "brand" | "brandLine" | "brandClientVisibility"
        > | null;
      })
    | Pick<ItemWithProps2, "props2">
    | {
        product?: Pick<
          ItemProductFieldsFragment,
          "props2" | "brand" | "brandLine" | "brandClientVisibility"
        > | null;
      }
    | null
): boolean => {
  return Boolean(
    item &&
      (("props2" in item &&
        item.props2.filter(property => property.clientVisibility === true)
          .length > 0) ||
        ("product" in item &&
          item.product &&
          (("props2" in item.product &&
            item.product.props2.filter(property => property.clientVisibility)
              .length > 0) ||
            ("brand" in item.product &&
              item.product.brand &&
              item.product.brandClientVisibility !== "hide") ||
            ("brandLine" in item.product &&
              item.product.brandLine &&
              item.product.brandClientVisibility !== "hide"))))
  );
};

export const useOrderItemProperties = (
  item?: Pick<
    PurchaseOrder__ItemFragment,
    "productProps2" | "productBrand" | "productBrandLine"
  > | null
): { key: string; value: string }[] => {
  const { t } = useTranslate(["Product"]);

  const productProperties = useItemProps2(item?.productProps2 ?? []);

  return React.useMemo(
    () =>
      item
        ? [
            ...(item.productBrand
              ? [
                  {
                    key: t("Brand", {
                      ns: "Product",
                    }),
                    value: item.productBrand,
                  },
                ]
              : []),
            ...(item.productBrandLine
              ? [
                  {
                    key: t("Brand line", {
                      ns: "Product",
                    }),
                    value: item.productBrandLine,
                  },
                ]
              : []),
            ...productProperties,
          ]
        : [],
    [t, productProperties, item]
  );
};
