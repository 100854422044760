import React, { FC, useState } from "react";
import { Page } from "../../commons/layout/Page.js";
import { Box, Grid } from "@mui/material";
import { CardContainer } from "@msys/ui";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { Tabs } from "@msys/ui";
import { PageSectionHeader } from "../../commons/layout/PageSectionHeader.js";

export const ExampleLayoutSettings: FC = () => {
  const [quickFilter, setQuickFilter] = useState<string>("General");
  return (
    <>
      <Page>
        <PageContainer>
          <PageSectionHeader title="Account settings" mb={1} />
          <Box mb={2}>
            <Tabs
              value={quickFilter}
              onChange={setQuickFilter}
              options={[
                { value: "General", label: "General" },
                { value: "Account", label: "Account" },
                { value: "Print", label: "Print" },
                { value: "Shop", label: "Shop" },
              ]}
            />
          </Box>
          {quickFilter === "General" && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <CardContainer title="Services">
                  <Box height={400} />
                </CardContainer>
              </Grid>
              <Grid item xs={12} lg={8}>
                <CardContainer title="About">
                  <Box height={600} />
                </CardContainer>
              </Grid>
            </Grid>
          )}
          {quickFilter === "Account" && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CardContainer title="Settings">
                  <Box height={400} />
                </CardContainer>
              </Grid>
            </Grid>
          )}
          {quickFilter === "Print" && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <CardContainer title="Column #1">
                  <Box height={200} />
                </CardContainer>
              </Grid>
              <Grid item xs={12} lg={4}>
                <CardContainer title="Column #2">
                  <Box height={400} />
                </CardContainer>
              </Grid>
              <Grid item xs={12} lg={4}>
                <CardContainer title="Column #3">
                  <Box height={300} />
                </CardContainer>
              </Grid>
            </Grid>
          )}
          {quickFilter === "Shop" && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <CardContainer title="Merchant">
                  <Box height={500} />
                </CardContainer>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CardContainer title="Templates">
                  <Box height={300} />
                </CardContainer>
              </Grid>
            </Grid>
          )}
        </PageContainer>
      </Page>
    </>
  );
};
