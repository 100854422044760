import { MutationFunctionOptions, useApolloClient } from "@apollo/client";
import { Radio } from "@mui/material";
import { Checkbox } from "@mui/material";
import { ChangeEvent } from "react";
import {
  DecisionBehaviorOfSubitems,
  Exact,
  SetUnnestedTemplateItemDecisionSubitemsPreselectionInput,
} from "../../../../clients/graphqlTypes.js";
import {
  SetUnnestedTemplateDecisionPreselectionMutation,
  SetUnnestedTemplateDecisionPreselectionMutationFn,
  useSetUnnestedTemplateDecisionPreselectionMutation,
} from "../../templates/quote/TemplateQuotes.generated.js";

interface Props {
  docId: string;
  itemId: string;
  item: {
    id: string;
  };
  parentItem: {
    id: string;
    decisionBehaviorOfSubitems: DecisionBehaviorOfSubitems;
    decisionSubitemsPreselection: string[];
  };
  disabled?: boolean;
  modifyItemDecisionPreselection?:
    | SetUnnestedTemplateDecisionPreselectionMutationFn
    | ((
        options?:
          | MutationFunctionOptions<
              SetUnnestedTemplateDecisionPreselectionMutation,
              Exact<{
                input: SetUnnestedTemplateItemDecisionSubitemsPreselectionInput;
              }>
            >
          | undefined
      ) => Promise<void>);
}

export const UnnestedTemplateDecisionOptionCheckboxOrRadio = ({
  docId,
  itemId,
  item,
  parentItem,
  disabled,
  modifyItemDecisionPreselection: modifyItemDecisionPreselectionProp,
}: Props) => {
  const client = useApolloClient();

  const [
    modifyItemDecisionPreselection,
    { loading: modifyItemDecisionPreselectionLoading },
  ] = useSetUnnestedTemplateDecisionPreselectionMutation({
    client,
  });

  if (!item) return <div>item not found</div>;

  const selected = parentItem?.decisionSubitemsPreselection?.includes(item.id);

  const onChange = async (event: ChangeEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();

    let decisionSubitemsPreselection: string[] =
      parentItem?.decisionSubitemsPreselection
        ? [...(parentItem?.decisionSubitemsPreselection ?? [])]
        : [];

    if (!selected) {
      if (parentItem!.decisionBehaviorOfSubitems === "SELECT_MANY") {
        decisionSubitemsPreselection.push(item.id);
      } else {
        decisionSubitemsPreselection = [item.id];
      }
    }

    if (selected) {
      decisionSubitemsPreselection = decisionSubitemsPreselection.filter(
        id => id !== item.id
      );
    }

    await (
      modifyItemDecisionPreselectionProp || modifyItemDecisionPreselection
    )({
      variables: {
        input: {
          templateDocId: docId,
          unnestedItemId: parentItem!.id,
          decisionSubitemsPreselection,
        },
      },
    });
  };

  return parentItem!.decisionBehaviorOfSubitems === "SELECT_MANY" ? (
    <Checkbox
      checked={Boolean(selected)}
      onClick={onChange}
      disabled={disabled || modifyItemDecisionPreselectionLoading}
      size="small"
    />
  ) : (
    <Radio
      checked={selected}
      onClick={onChange}
      disabled={disabled || modifyItemDecisionPreselectionLoading}
      size="small"
    />
  );
};
