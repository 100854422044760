import { BuildingRow__BuildingFragment } from "./Buildings.generated.js";
import { getAddressLabel } from "../addresses/helpers.js";

export function selectLabelForBuilding(building: {
  title: string;
  buildingAddress?: Parameters<typeof getAddressLabel>[0] | null | undefined;
}): string {
  const { title, buildingAddress } = building;
  if (!buildingAddress) return title;
  if (title) return getAddressLabel(buildingAddress, ", ", false, title) ?? "";
  else return getAddressLabel(buildingAddress)!;
}
