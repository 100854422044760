import { LabeledSpecializedValue } from "@msys/ui";
import { Box, Grid, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { FormattedIntegerField } from "../../../commons/form-fields/FormattedIntegerField.js";
import { FormattedPriceField } from "../../../commons/form-fields/FormattedPriceField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { px } from "../../../../common/MuiThemeProvider.js";
import { PurchaseOrderQuery } from "../../../main-routes/projects/ProjectPurchaseOrderPreview.generated.js";
import { useTranslate } from "@tolgee/react";
import { PurchaseOrderItemDescriptionColumn } from "./PurchaseOrderItemDescriptionColumn.js";

type Order = Exclude<
  Exclude<
    Extract<
      PurchaseOrderQuery["project"],
      { __typename: "ProjectResult" }
    >["project"],
    null | undefined
  >["order"],
  null | undefined
>;

interface Props {
  isEditable?: boolean;
  item: Order["items"][0];
  index: number;
}

export const PurchaseOrderItemMobileRow = ({
  isEditable = true,
  item,
  index,
}: Props) => {
  const { t } = useTranslate(["PurchaseOrders", "Quote", "Global"]);
  const { values } = useFormikContext<{
    [key: string]: {
      quantityOrdered: number;
    };
  }>();
  const quantity = (values?.[item.id]?.quantityOrdered ?? 0) as number;
  return (
    <Box p={1} marginBottom={2}>
      <Typography style={{ minWidth: "32px" }} variant="body2">
        {index + 1}.
      </Typography>
      <Stack flexDirection="column">
        <div>
          <PurchaseOrderItemDescriptionColumn item={item} />
        </div>

        <Stack>
          <Typography component="div">
            {isEditable ? (
              <Field
                label={t("Article no.", {
                  ns: "Quote",
                })}
                component={TextField}
                name={`${item.id}.productArticleNumber`}
                disabled={false}
                size={"small"}
              />
            ) : (
              `${item.productArticleNumber ?? ""}`
            )}
          </Typography>
        </Stack>
        <Grid container direction="row">
          <Grid
            container
            xs={4}
            justifyContent="flex-start"
            alignItems="center"
          >
            {isEditable ? (
              <FormattedIntegerField
                disabled={false}
                label={t("Order quantity", {
                  ns: "PurchaseOrders",
                })}
                name={`${item.id}.quantityOrdered`}
                type="integer"
                min={0}
                unit={"\u00A0" + item.quantityUnit}
              />
            ) : (
              <LabeledSpecializedValue
                label={t("Order quantity", {
                  ns: "PurchaseOrders",
                })}
                value={item.quantityOrdered}
                unit={"\u00A0" + item.quantityUnit}
                type="integer"
                notSetText={t("Not set", {
                  ns: "Global",
                })}
              />
            )}
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            item
            style={{ width: px.l }}
          >
            x
          </Grid>
          <Grid
            container
            xs={4}
            justifyContent="center"
            alignItems="center"
            style={{
              flexBasis: `calc(33.333333% - ${px.l})`,
              maxWidth: `calc(33.333333% - ${px.l})`,
            }}
          >
            {isEditable ? (
              <FormattedPriceField
                label={t("Unit price", {
                  ns: "PurchaseOrders",
                })}
                disabled={false}
                name={`${item.id}.unitPrice`}
                type="price"
                min={0}
                size={"small"}
              />
            ) : (
              <LabeledSpecializedValue
                label={t("Unit price", {
                  ns: "PurchaseOrders",
                })}
                value={item.unitPrice ?? 0}
                type="price"
                notSetText={t("Not set", {
                  ns: "Global",
                })}
              />
            )}
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            item
            style={{ width: px.l }}
          >
            =
          </Grid>
          <Grid
            container
            xs={4}
            justifyContent="flex-end"
            alignItems="center"
            style={{
              flexBasis: `calc(33.333333% - ${px.l})`,
              maxWidth: `calc(33.333333% - ${px.l})`,
            }}
          >
            <LabeledSpecializedValue
              label={t("Total price", {
                ns: "PurchaseOrders",
              })}
              labelProps={{ style: { textAlign: "right" } }}
              value={(item.unitPrice ?? 0) * quantity}
              type="price"
              align="right"
              style={{ fontWeight: "bold" }}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
