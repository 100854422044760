import { Box, BoxProps, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { color } from "../../common/MuiThemeProvider.js";

interface Props {
  createdAt?: string;
  authorLabel?: string | null;
  backgroundColor?: string;
  alignSelf?: BoxProps["alignSelf"];
  children: React.ReactNode;
}

export const ChatBubble = ({
  createdAt,
  authorLabel,
  backgroundColor = color.blue8,
  alignSelf = "flex-start",
  children,
}: Props) => {
  return (
    <Box
      alignSelf={alignSelf}
      marginLeft={alignSelf === "flex-end" ? 4 : undefined}
      marginRight={alignSelf === "flex-start" ? 4 : undefined}
      sx={{
        minHeight: "32px",
        borderRadius: "6px",
        padding: 1,
        position: "relative",
        display: "block",
        textAlign: "left",
        maxWidth: "100%",
        paddingRight: createdAt ? 8 : 1,
        backgroundColor,
      }}
    >
      {authorLabel && (
        <Typography variant="h4" gutterBottom>
          {authorLabel}
        </Typography>
      )}
      <div>{children}</div>
      {createdAt && <CreatedTime time={createdAt} />}
    </Box>
  );
};

const CreatedTime = ({ time }: { time: string }) => {
  return (
    <Typography
      variant="caption"
      component="span"
      sx={{
        position: "absolute",
        bottom: theme => theme.spacing(1),
        right: theme => theme.spacing(1),
        color: theme => theme.palette.grey[600],
        margin: 0,
      }}
    >
      {moment(time).format("HH:mm")}
    </Typography>
  );
};
