import { inverseTheme, useScreenWidth } from "@msys/ui";
import { Check as CheckIcon } from "@mui/icons-material";
import { Home as HomeIcon } from "@mui/icons-material";
import { Person as PersonIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { T, useTranslate } from "@tolgee/react";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-use";
import { useLanguages } from "../common/translations/useLanguages.js";
import { ReactComponent as CraftsmanIcon } from "./assets/icons/icon-craftsman.svg";
import type { CreateableOrganisationType } from "./auth/isValidOrganisationType.js";
import { useAuth } from "./auth/useAuth.js";
import { LanguageSelector } from "./commons/LanguageSelector.js";
import { useUrlSearchParams } from "./commons/hooks/useUrlSearchParams.js";
import { Page } from "./commons/layout/Page.js";
import { ORGANISATION_TYPE_URL_SEARCH_PARAM } from "./constants.js";

export const LandingPage = () => {
  const { auth, isAuthenticated } = useAuth();
  const { languages, selectLanguage, currentLanguage } = useLanguages();
  const { isMinTablet, isMinDesktop, isMinLargeDesktop } = useScreenWidth();
  const { t } = useTranslate(["LoginForm", "PageSignup"]);
  const { search } = useLocation();
  const { setUrlSearchParams } = useUrlSearchParams();

  if (isAuthenticated) {
    return (
      <Navigate to={{ pathname: "/next", search: search }} replace={true} />
    );
  }

  const organisationTypes: Record<string, CreateableOrganisationType> = {
    craftsman: "CRAFTSMAN",
    client: "CLIENT",
  };

  return (
    <Page
      hideNavigation
      topbarItems={
        <>
          <Button
            color="inherit"
            size="small"
            variant="outlined"
            startIcon={<PersonIcon />}
            sx={{ whiteSpace: "nowrap" }}
            onClick={() => {
              auth.login();
            }}
          >
            {t("Log in", {
              ns: "LoginForm",
            })}
          </Button>
          <LanguageSelector
            languages={languages}
            selectLanguage={selectLanguage}
            currentLanguage={currentLanguage}
          />
        </>
      }
    >
      <ThemeProvider theme={inverseLargeTheme}>
        <Stack
          flexGrow={1}
          flexShrink={1}
          flexDirection={isMinTablet ? "row" : "column"}
          alignItems="stretch"
          justifyContent="flex-start"
          style={{
            backgroundColor: inverseLargeTheme.palette.primary.contrastText,
          }}
        >
          <Box
            flexGrow={1}
            flexShrink={1}
            my={4}
            px={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
                  marginBottom: 7.5,
                  maxWidth: 680,
                },
                [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
                  marginBottom: 15,
                  maxWidth: 720,
                },
              })}
            >
              <Typography
                variant="h1"
                color="primary"
                gutterBottom
                sx={theme => ({
                  "& > b, & > strong": {
                    color: theme.palette.orange.main,
                  },
                })}
              >
                <T
                  ns="PageSignup"
                  keyName={"Bringing back the fun to <bold>Construction</bold>"}
                  params={{ bold: <strong /> }}
                />
              </Typography>
              <Typography variant="body2" color="primary">
                {t(
                  "The #1 collaborative App for truly enjoyable construction projects",
                  {
                    ns: "PageSignup",
                  }
                )}
              </Typography>
              <Box mt={4} mb={4}>
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body1" color="primary">
                          {t("Make building project as enjoyable", {
                            ns: "PageSignup",
                          })}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body1" color="primary">
                          {t("More efficiency, less delays, lower cost", {
                            ns: "PageSignup",
                          })}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body1" color="primary">
                          {t("Free trial usage", {
                            ns: "PageSignup",
                          })}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    startIcon={
                      <Icon>
                        <CraftsmanIcon />
                      </Icon>
                    }
                    size="extra-large"
                    onClick={() => {
                      setUrlSearchParams({
                        [ORGANISATION_TYPE_URL_SEARCH_PARAM]:
                          organisationTypes.craftsman,
                      });
                      setTimeout(() => {
                        auth.register({
                          locale: currentLanguage.locale,
                        });
                      });
                    }}
                  >
                    {t("Sign up as craftsman", {
                      ns: "LoginForm",
                    })}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    startIcon={<HomeIcon />}
                    size="extra-large"
                    onClick={() => {
                      setUrlSearchParams({
                        [ORGANISATION_TYPE_URL_SEARCH_PARAM]:
                          organisationTypes.client,
                      });
                      setTimeout(() => {
                        auth.register({
                          locale: currentLanguage.locale,
                        });
                      });
                    }}
                  >
                    {t("Sign up as homeowner", {
                      ns: "LoginForm",
                    })}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            width={
              isMinLargeDesktop
                ? 920
                : isMinDesktop
                  ? 660
                  : isMinTablet
                    ? 420
                    : "100%"
            }
            flexGrow={0}
            flexShrink={0}
            position="relative"
          >
            <Box
              sx={theme => ({
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
                  left: 120,
                  backgroundImage: "url('/auth/auth-bg.jpeg')",
                  backgroundSize: "cover",
                },
                [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
                  left: 60,
                },
                [theme.breakpoints.up(theme.breakpoints.values.xxl)]: {
                  left: 40,
                },
              })}
            />
            <Box
              component={"img"}
              src="/auth/auth-demo.png"
              alt="Demo"
              sx={theme => ({
                [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
                  display: "block",
                  margin: "12px auto 24px",
                  width: "calc(100% - 24px)",
                  maxWidth: 520,
                },
                [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
                  position: "absolute",
                  top: "65%",
                  left: -20,
                  transform: "translateY(-50%)",
                  width: 420,
                },
                [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
                  left: -40,
                  width: 540,
                },
                [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
                  left: -120,
                  width: 720,
                },
                [theme.breakpoints.up(theme.breakpoints.values.xxl)]: {
                  left: -160,
                  width: 820,
                },
              })}
            />
          </Box>
        </Stack>
      </ThemeProvider>
    </Page>
  );
};

// TODO: re-do to `sx` props
const inverseLargeTheme = createTheme(inverseTheme, {
  palette: {
    secondary: inverseTheme.palette.orange,
  },
  typography: {
    h1: {
      fontWeight: 600,
      [inverseTheme.breakpoints.down(inverseTheme.breakpoints.values.tablet)]: {
        fontSize: "2.5rem",
        lineHeight: 1.2,
      },
      [inverseTheme.breakpoints.up(inverseTheme.breakpoints.values.tablet)]: {
        fontSize: "3.25rem",
        lineHeight: 1.2,
      },
      [inverseTheme.breakpoints.up(inverseTheme.breakpoints.values.xxl)]: {
        fontSize: "3.75rem",
        lineHeight: 1.15,
      },
    },
    body1: {
      fontWeight: 400,
      [inverseTheme.breakpoints.down(inverseTheme.breakpoints.values.tablet)]: {
        fontSize: "1.25rem",
        lineHeight: 1.3333,
      },
      [inverseTheme.breakpoints.up(inverseTheme.breakpoints.values.tablet)]: {
        fontSize: "1.45rem",
        lineHeight: 1.3333,
      },
    },
    body2: {
      fontWeight: 400,
      [inverseTheme.breakpoints.down(inverseTheme.breakpoints.values.tablet)]: {
        fontSize: "1.15rem",
        lineHeight: 1.3333,
      },
      [inverseTheme.breakpoints.up(inverseTheme.breakpoints.values.tablet)]: {
        fontSize: "1.25rem",
        lineHeight: 1.3333,
      },
    },
  },
});
