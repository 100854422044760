import { useApolloClient } from "@apollo/client";
import { getDataOrNull, getImageUrl } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Person as PersonIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  AddressInput,
  AttachmentInput,
  Salutation,
  TemplateVariantItemConfigurationInput,
  _3d_ShapeInput,
} from "../../clients/graphqlTypes.js";
import { ReactComponent as SuccessIcon } from "../assets/icons/icon-success.svg";
import { useUserData } from "../auth/useUserData.js";
import { PhoneField } from "../commons/form-fields/PhoneField.js";
import { PHONE_REG_EXP } from "../commons/form-fields/helpers.js";
import { Page } from "../commons/layout/Page.js";
import { Stack } from "../commons/layout/Stack.js";
import { AddressField } from "../features/addresses/AddressField.js";
import { UserTitleWithNameField } from "../features/users/UserTitleWithNameField.js";
import { useThreeD_CreateLeadInNewProjectMutation } from "./OrganisationThreeDFinish.generated.js";
import { useThreeDStart_OrganisationProfileQuery } from "./OrganisationThreeDList.generated.js";
import { HeaderTitle, SideContainer, SideTitle } from "./styled.js";

export interface ThreeDProcessData {
  templateId: string;
  templateVariantConfiguration: TemplateVariantItemConfigurationInput[];
  dataShape?: _3d_ShapeInput;
  snapshots?: AttachmentInput[];
}

interface FormValues {
  title: Salutation;
  firstname: string;
  familyname: string;
  email: string;
  phone: string;
  address: AddressInput | null;
}

export const OrganisationThreeDFinish = () => {
  const { slug, templateId } = useParams();
  if (!templateId) throw new Error("Template id is missing");
  if (!slug) throw new Error("Slug is missing");

  const threeDProcessData = useLocation().state as ThreeDProcessData;
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate(["PageSignup", "ProjectMembers"]);
  const { currentUser } = useUserData();
  const { isMinTablet, isMinDesktop, isMinLargeDesktop } = useScreenWidth();

  const [refToken, setRefToken] = useState<string | null>(null);

  const client = useApolloClient();
  const [createLead] = useThreeD_CreateLeadInNewProjectMutation({ client });
  const organisationQuery = useThreeDStart_OrganisationProfileQuery({
    client,
    variables: { slug },
  });

  const organisationProfile = getDataOrNull(
    organisationQuery?.data?.organisationProfile
  )?.profile;
  const organisation = organisationProfile?.organisation;

  if (
    threeDProcessData.templateId !== templateId ||
    !threeDProcessData.dataShape
  ) {
    return <Navigate to={`/org/${slug}/3d`} />;
  }

  if (organisationProfile && !organisationProfile.has3DShop) {
    return <Navigate to={`/org/${slug}`} replace />;
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      const { data } = await createLead({
        variables: {
          input: {
            organisationId: organisation!.id,
            templateQuoteId: templateId,
            leadInfo: {
              title: values.title,
              firstname: values.firstname,
              familyname: values.familyname,
              email: values.email,
              phones: [{ main: true, number: values.phone, type: "MOBILE" }],
              projectSiteAddress: values.address!,
            },
            leadInvitation: {
              messageSubject: t(
                "You are invited to join {projectName} on MeisterSystems!",
                {
                  ns: "ProjectMembers",
                  projectName: "[projectName]",
                }
              ),
              messageBody: (
                t(
                  "Dear [NAME], Join our project {projectName} on MeisterSystems. Kind Regards, {authorName}",
                  {
                    ns: "ProjectMembers",
                    projectName: "[projectName]",
                    authorName: "[authorName]",
                  }
                ) as string
              ).replace("[NAME]", values.firstname + " " + values.familyname),
              attachments: [],
            },
            shape: threeDProcessData.dataShape!,
            templateVariantConfiguration:
              threeDProcessData.templateVariantConfiguration,
            snapshots: threeDProcessData.snapshots ?? [],
          },
        },
      });
      const refToken = data?._3d_createLeadInNewProject?.refToken;
      if (refToken) {
        setRefToken(refToken);
        enqueueSnackbar(
          t("Quote was sent to craftsman", {
            ns: "PageSignup",
          })
        );
      } else {
        throw new Error(
          t("Cannot send quote, please try again later", {
            ns: "PageSignup",
          })
        );
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Page
      subtitle={organisation?.title}
      title={"Get a quote"} // FIXME: add translation
    >
      <Stack
        flexGrow={1}
        flexShrink={1}
        flexDirection={isMinTablet ? "row" : "column"}
        alignItems="stretch"
        justifyContent="flex-start"
      >
        <Stack
          flexGrow={1}
          flexShrink={1}
          my={4}
          px={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="100%" maxWidth={420}>
            {refToken ? (
              <Stack flexDirection="column">
                <Box display="flex" width="100%" alignItems="center">
                  <Box width={81} height={81} flexGrow={0} flexShrink={0}>
                    <SuccessIcon />
                  </Box>
                  <Box ml={isMinTablet ? 3 : 2} flexGrow={1} flexShrink={1}>
                    <Typography variant="h2" gutterBottom>
                      Your request was sent!
                    </Typography>
                    <Typography variant="body1">
                      Thank you for sending your request for a quote.{" "}
                      {organisation?.title ?? ""} will contact you shortly.
                    </Typography>
                  </Box>
                </Box>

                {!currentUser && (
                  <>
                    <Box pt={4}>
                      <Divider />
                    </Box>
                    <Box alignSelf="center" pb={2} pt={4}>
                      <HeaderTitle variant="h1" align="center">
                        Want to monitor your project in real time?
                      </HeaderTitle>
                    </Box>
                    <Box alignSelf="center" pb={3}>
                      <Typography variant="body1" align="center">
                        Create a MeisterSystems account and stay on top of your
                        projects with the click of your thumb.
                      </Typography>
                    </Box>
                    <Box alignSelf="center">
                      <Button
                        component={Link}
                        to={{
                          pathname: "/authenticate/signup/client",
                          search: `refToken=${refToken}`,
                        }}
                        color="primary"
                        variant="contained"
                        startIcon={<PersonIcon />}
                      >
                        {t("Sign up for free", {
                          ns: "PageSignup",
                        })}
                      </Button>
                    </Box>
                  </>
                )}
              </Stack>
            ) : (
              <ThreeDSubmitForm
                handleSubmit={handleSubmit}
                currentUser={currentUser}
              />
            )}
          </Box>
        </Stack>
        <SideContainer
          flexDirection="column"
          justifyContent="center"
          width={
            isMinLargeDesktop
              ? 680
              : isMinDesktop
                ? 540
                : isMinTablet
                  ? 420
                  : "100%"
          }
          flexGrow={0}
          flexShrink={0}
          py={3}
        >
          <Box
            px={2}
            width={
              isMinLargeDesktop
                ? 540
                : isMinDesktop
                  ? 460
                  : isMinTablet
                    ? 360
                    : "100%"
            }
            alignSelf="center"
          >
            <SideTitle
              color={theme => theme.palette.common.white}
              variant="h2"
              gutterBottom
            >
              Take your <b>3D</b> project from conception to reality!
            </SideTitle>
            <Typography
              color={theme => theme.palette.common.white}
              variant="body2"
            >
              Please provide us with a few basic details and a professional will
              contact you shortly with a non-binding quote
            </Typography>
            {threeDProcessData.snapshots?.[0] && (
              <Box mt={3}>
                <img
                  style={{ maxWidth: "100%", width: "100%" }}
                  src={getImageUrl({
                    url: threeDProcessData.snapshots[0].url,
                    width: 600,
                  })}
                  srcSet={`${getImageUrl({
                    url: threeDProcessData.snapshots[0].url,
                    width: 600,
                    scaling: 2,
                  })} 2x`}
                  alt="3D plan"
                />
              </Box>
            )}
          </Box>
        </SideContainer>
      </Stack>
    </Page>
  );
};

function ThreeDSubmitForm({
  handleSubmit,
  currentUser,
}: {
  handleSubmit: any;
  currentUser: any;
}) {
  const { t } = useTranslate(["PageSignup", "Global"]);
  const { isMinTablet } = useScreenWidth();

  const initialValues: FormValues =
    currentUser && currentUser.organisation.isClientOrganisation
      ? {
          title: currentUser.title,
          firstname: currentUser.firstname,
          familyname: currentUser.familyname,
          email: currentUser.email,
          phone: "",
          address: null,
        }
      : {
          title: "mr",
          firstname: "",
          familyname: "",
          email: "",
          phone: "",
          address: null,
        };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .label(
        t("First name", {
          ns: "PageSignup",
        })
      )
      .required(),
    familyname: Yup.string()
      .label(
        t("Family name", {
          ns: "PageSignup",
        })
      )
      .required(),
    email: Yup.string()
      .label(
        t("Email", {
          ns: "PageSignup",
        })
      )
      .email()
      .trim()
      .required(),
    phone: Yup.string()
      .label(
        t("Phone number", {
          ns: "PageSignup",
        })
      )
      .matches(
        PHONE_REG_EXP,
        t("Phone number is not valid", {
          ns: "PageSignup",
        })
      )
      .required(),
    address: Yup.object()
      .nullable()
      .required(t("Address is mandatory", { ns: "Global" }))
      .shape({
        streetLine1: Yup.string(),
        postalCode: Yup.string(),
        city: Yup.string(),
      })
      .label(t("Project address", { ns: "PageSignup" })),
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form style={{ width: "100%" }} autoComplete="off">
          <Stack flexDirection="column">
            <Box alignSelf="center" pb={3}>
              <HeaderTitle variant="h1" align="center">
                Get a <b>FREE</b> quote for your Plan!
              </HeaderTitle>
            </Box>

            <UserTitleWithNameField
              firstnameField="firstname"
              familynameField="familyname"
              isRequiredOption={"BOTH"}
            />

            <Field
              component={TextField}
              label={t("Email", {
                ns: "PageSignup",
              })}
              name="email"
              type="email"
              required
              autoCompleteDisabled
            />

            <PhoneField
              label={t("Phone number", {
                ns: "PageSignup",
              })}
              name="phone"
              required
            />

            <AddressField
              name="address"
              label={t("Project address", {
                ns: "PageSignup",
              })}
              required
            />

            <Box
              pt={3}
              display="flex"
              justifyContent={isMinTablet ? "center" : "stretch"}
            >
              <LoadingButton
                fullWidth
                type="submit"
                color="orange"
                variant="contained"
                size="extra-large"
                loading={isSubmitting}
              >
                Get a free quote
              </LoadingButton>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
