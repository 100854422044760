/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../features/attachments/Attachments.generated.js';
import { UserInformationBox_ViewerFragmentDoc } from '../features/users/UserInformationBox.generated.js';
import { UserRolesBox_ViewerFragmentDoc } from '../features/users/UserRolesBox.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyUserProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyUserProfileQuery = { viewer?: { __typename: 'Viewer', id: string, firstname: string, familyname: string, fullname: string, jobTitle: string, email: any, organisation: { __typename: 'ViewerOrganisation', id: string, title: string }, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, phones: Array<{ __typename: 'Phone', id: string, type: Types.PhoneType, main: boolean, number: string }>, roles: Array<{ __typename: 'OrganisationRole', id: string, internalName: string }>, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> } | null };

export type ModifyMyUserAvatarMutationVariables = Types.Exact<{
  user: Types.UserModifyInput;
}>;


export type ModifyMyUserAvatarMutation = { updateUser: { __typename: 'UpdateUserResult', user: { __typename: 'Viewer', id: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } };


export const MyUserProfileDocument = gql`
    query MyUserProfile {
  viewer {
    id
    firstname
    familyname
    fullname
    organisation {
      id
      title
    }
    avatar {
      ...AttachmentSnapshot
    }
    ...UserInformationBox_Viewer
    ...UserRolesBox_Viewer
  }
}
    ${AttachmentSnapshotFragmentDoc}
${UserInformationBox_ViewerFragmentDoc}
${UserRolesBox_ViewerFragmentDoc}`;

/**
 * __useMyUserProfileQuery__
 *
 * To run a query within a React component, call `useMyUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyUserProfileQuery, MyUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyUserProfileQuery, MyUserProfileQueryVariables>(MyUserProfileDocument, options);
      }
export function useMyUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyUserProfileQuery, MyUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyUserProfileQuery, MyUserProfileQueryVariables>(MyUserProfileDocument, options);
        }
export function useMyUserProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyUserProfileQuery, MyUserProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyUserProfileQuery, MyUserProfileQueryVariables>(MyUserProfileDocument, options);
        }
export type MyUserProfileQueryHookResult = ReturnType<typeof useMyUserProfileQuery>;
export type MyUserProfileLazyQueryHookResult = ReturnType<typeof useMyUserProfileLazyQuery>;
export type MyUserProfileSuspenseQueryHookResult = ReturnType<typeof useMyUserProfileSuspenseQuery>;
export type MyUserProfileQueryResult = Apollo.QueryResult<MyUserProfileQuery, MyUserProfileQueryVariables>;
export const ModifyMyUserAvatarDocument = gql`
    mutation ModifyMyUserAvatar($user: UserModifyInput!) {
  updateUser(user: $user) {
    user {
      id
      avatar {
        ...AttachmentSnapshot
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type ModifyMyUserAvatarMutationFn = Apollo.MutationFunction<ModifyMyUserAvatarMutation, ModifyMyUserAvatarMutationVariables>;

/**
 * __useModifyMyUserAvatarMutation__
 *
 * To run a mutation, you first call `useModifyMyUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyMyUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyMyUserAvatarMutation, { data, loading, error }] = useModifyMyUserAvatarMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useModifyMyUserAvatarMutation(baseOptions?: Apollo.MutationHookOptions<ModifyMyUserAvatarMutation, ModifyMyUserAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyMyUserAvatarMutation, ModifyMyUserAvatarMutationVariables>(ModifyMyUserAvatarDocument, options);
      }
export type ModifyMyUserAvatarMutationHookResult = ReturnType<typeof useModifyMyUserAvatarMutation>;
export type ModifyMyUserAvatarMutationResult = Apollo.MutationResult<ModifyMyUserAvatarMutation>;
export type ModifyMyUserAvatarMutationOptions = Apollo.BaseMutationOptions<ModifyMyUserAvatarMutation, ModifyMyUserAvatarMutationVariables>;