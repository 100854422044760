/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationUserSelectQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganisationUserSelectQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> };


export const OrganisationUserSelectDocument = gql`
    query OrganisationUserSelect {
  organisationMemberships(filters: {active: true}) {
    id
    firstname
    familyname
    fullname
    avatar {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;

/**
 * __useOrganisationUserSelectQuery__
 *
 * To run a query within a React component, call `useOrganisationUserSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationUserSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationUserSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationUserSelectQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationUserSelectQuery, OrganisationUserSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationUserSelectQuery, OrganisationUserSelectQueryVariables>(OrganisationUserSelectDocument, options);
      }
export function useOrganisationUserSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationUserSelectQuery, OrganisationUserSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationUserSelectQuery, OrganisationUserSelectQueryVariables>(OrganisationUserSelectDocument, options);
        }
export function useOrganisationUserSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrganisationUserSelectQuery, OrganisationUserSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationUserSelectQuery, OrganisationUserSelectQueryVariables>(OrganisationUserSelectDocument, options);
        }
export type OrganisationUserSelectQueryHookResult = ReturnType<typeof useOrganisationUserSelectQuery>;
export type OrganisationUserSelectLazyQueryHookResult = ReturnType<typeof useOrganisationUserSelectLazyQuery>;
export type OrganisationUserSelectSuspenseQueryHookResult = ReturnType<typeof useOrganisationUserSelectSuspenseQuery>;
export type OrganisationUserSelectQueryResult = Apollo.QueryResult<OrganisationUserSelectQuery, OrganisationUserSelectQueryVariables>;