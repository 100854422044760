import { SelectOption } from "@msys/ui";
import { UserAvatarFragment } from "./UserAvatar.generated.js";

export const getPersonLabel = (
  person: { firstname: string; familyname: string },
  compact: boolean
) =>
  compact
    ? `${
        person.firstname && person.firstname.length > 0
          ? `${person.firstname[0]}.`
          : ""
      } ${person.familyname}`.trim()
    : `${person.firstname} ${person.familyname}`.trim();

export const getMemberOptions = (
  members: Array<{ id: string; firstname: string; familyname: string }>,
  compact: boolean = false
): SelectOption[] => {
  return members
    .map(m => ({
      label: getPersonLabel(m, compact),
      value: m.id,
    }))
    .sort((a, b) =>
      a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())
    );
};

export const getMemberOptionsWithUserAvatar = (
  members: Array<{
    id: string;
    firstname: string;
    familyname: string;
    avatar: {
      id: string;
      url: string;
    };
  }>,
  compact: boolean = false
): (SelectOption & { userAvatar: UserAvatarFragment })[] => {
  return members
    .map(m => ({
      label: getPersonLabel(m, compact),
      value: m.id,
      userAvatar: {
        __typename: "User" as const,
        ...m,
        avatar: { __typename: "AttachmentSnapshot" as const, ...m.avatar },
      },
    }))
    .sort((a, b) =>
      a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())
    );
};
