import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { useUserData } from "../../auth/useUserData.js";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageGrid, splitStrategy } from "../../commons/layout/PageGrid.js";
import { OrganisationBillOfMaterialsSettingsBox } from "../../features/organisations/boxes/OrganisationBillOfMaterialsSettingsBox.js";
import { OrganisationInvoicePdfSettingsBox } from "../../features/organisations/boxes/OrganisationInvoicePdfSettingsBox.js";
import { OrganisationInvoiceSettingsBox } from "../../features/organisations/boxes/OrganisationInvoiceSettingsBox.js";
import { OrganisationPdfSettingsBox } from "../../features/organisations/boxes/OrganisationPdfSettingsBox.js";
import { OrganisationPurchaseOrderSettingsBox } from "../../features/organisations/boxes/OrganisationPurchaseOrderSettingsBox.js";
import { OrganisationQuotePdfSettingsBox } from "../../features/organisations/boxes/OrganisationQuotePdfSettingsBox.js";
import { OrganisationQuoteSettingsBox } from "../../features/organisations/boxes/OrganisationQuoteSettingsBox.js";
import { OrganisationTimeReportSettingsBox } from "../../features/organisations/boxes/OrganisationTimeReportSettingsBox.js";
import { useOrganisationSettingsDocumentsQuery } from "./OrganisationSettingsDocuments.generated.js";

interface Props {
  submenuItems: PageTopbarItem[];
}

export const OrganisationSettingsDocuments = ({ submenuItems }: Props) => {
  const { t } = useTranslate("OrganisationPageTopbar");

  const client = useApolloClient();
  const query = useOrganisationSettingsDocumentsQuery({
    client,
  });
  const viewer = useUserData().currentUser!;

  const organisation = query.data?.viewer?.organisation;
  const organisationSettings = query.data?.organisationSettings;
  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);
  const organisationPdfSettings = getDataOrNull(
    query.data?.organisationPdfSettings
  );
  const organisationPdfLetterhead =
    organisationPdfSettings?.pdfLetterhead ?? null;
  const organisationContractingPdfs = getDataOrNull(
    query.data?.organisationContractingPdfs
  )?.contractingPdfs;

  return (
    <Page title={t("Documents")} submenuItems={submenuItems}>
      {organisation &&
        organisationSettings &&
        organisationDefaults &&
        organisationContractingPdfs && (
          <PageContainer>
            <PageGrid
              columns={{ xs: 1, md: 2, xl: 3 }}
              strategy={splitStrategy}
            >
              {viewer.organisation.capabilities.includes("QUOTING") ||
              viewer.organisation.capabilities.includes("INVOICING") ? (
                <>
                  <RestrictedByCapabilityWithDebug capability="QUOTING">
                    <OrganisationQuoteSettingsBox
                      organisationId={organisation.id}
                      organisationDefaults={organisationDefaults}
                    />
                  </RestrictedByCapabilityWithDebug>
                  <RestrictedByCapabilityWithDebug capability="INVOICING">
                    <OrganisationInvoiceSettingsBox
                      organisationId={organisation.id}
                      organisationDefaults={organisationDefaults}
                    />
                  </RestrictedByCapabilityWithDebug>
                </>
              ) : null}
              <>
                <OrganisationPdfSettingsBox
                  organisationPdfLetterhead={organisationPdfLetterhead}
                />
                <RestrictedByCapabilityWithDebug capability="QUOTING">
                  <OrganisationQuotePdfSettingsBox
                    organisationId={organisation.id}
                    organisationSettings={organisationSettings}
                    organisationContractingPdfs={organisationContractingPdfs}
                  />
                </RestrictedByCapabilityWithDebug>
                <RestrictedByCapabilityWithDebug capability="INVOICING">
                  <OrganisationInvoicePdfSettingsBox
                    organisationId={organisation.id}
                  />
                </RestrictedByCapabilityWithDebug>
                <RestrictedByCapabilityWithDebug capability="ORDERING">
                  <OrganisationPurchaseOrderSettingsBox
                    organisationId={organisation.id}
                  />
                </RestrictedByCapabilityWithDebug>
                <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
                  <OrganisationTimeReportSettingsBox
                    organisationId={organisation.id}
                  />
                </RestrictedByCapabilityWithDebug>
              </>
              <>
                <OrganisationBillOfMaterialsSettingsBox
                  organisationId={organisation.id}
                  organisationSettings={organisationSettings}
                />
              </>
            </PageGrid>
          </PageContainer>
        )}
    </Page>
  );
};
