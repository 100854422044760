/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type InvoiceExtraTreeItem_InvoiceFragment = { __typename: 'Invoice', id: string, quote: { __typename: 'Quote', id: string }, project: { __typename: 'Project', id: string } };

export type InvoiceExtraTreeItem_InvoiceExtraItemFragment = { __typename: 'InvoiceExtraItem', id: string, title: string, quantity: number, quantityUnit: Types.QuantityUnit, pricePerUnit: number };

export const InvoiceExtraTreeItem_InvoiceFragmentDoc = gql`
    fragment InvoiceExtraTreeItem_Invoice on Invoice {
  id
  quote {
    id
  }
  project {
    id
  }
}
    `;
export const InvoiceExtraTreeItem_InvoiceExtraItemFragmentDoc = gql`
    fragment InvoiceExtraTreeItem_InvoiceExtraItem on InvoiceExtraItem {
  id
  title
  quantity
  quantityUnit
  pricePerUnit
}
    `;