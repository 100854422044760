import { useApolloClient } from "@apollo/client";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { CircularProgress, Icon } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useGlobalLoadingCounter } from "../../../../common/global-loading-state/useGlobalLoadingCounter.js";
import { useDocExportAsM1ImpexJsonMutation } from "./DocM1ImpexJsonExport.generated.js";

interface Props {
  docId: string;
  projectId: string;
  title?: string;
}

export const DocExportAsM1ImpexJsonMenuItem = React.forwardRef<
  HTMLLIElement,
  Props
>((props, ref) => {
  const { t } = useTranslate("QuoteItem");

  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

  const { enqueueSnackbar } = useSnackbar();
  const docExportAsM1ImpexJson = useDocExportAsM1ImpexJson();

  const { increment, decrement } = useGlobalLoadingCounter();

  return (
    <MenuItemWithIcon
      ref={ref}
      icon={
        status !== "loading" ? (
          <Icon>
            <FontAwesomeIcon icon={faFileExport} />
          </Icon>
        ) : (
          <CircularProgress size={"1rem"} />
        )
      }
      onClick={async () => {
        try {
          increment();
          setStatus("loading");
          await docExportAsM1ImpexJson(props.projectId, props.docId);
          setStatus("idle");
        } catch (e) {
          if (e instanceof Error)
            enqueueSnackbar(e.message, { variant: "error" });
          Sentry.captureException(e);
          setStatus("error");
        } finally {
          decrement();
        }
      }}
      disabled={status === "loading"}
    >
      {props.title ?? `${t("Export as")} M1ImpexJson`}
    </MenuItemWithIcon>
  );
});

const useDocExportAsM1ImpexJson = () => {
  const client = useApolloClient();
  const [docExportAsM1ImpexJsonMutation] = useDocExportAsM1ImpexJsonMutation({
    client,
  });

  return useCallback(
    async (projectId: string, docId: string) => {
      const docExportAsM1ImpexJsonResult = await docExportAsM1ImpexJsonMutation(
        {
          variables: {
            input: { docId, projectId },
          },
        }
      );

      if (docExportAsM1ImpexJsonResult) {
        download(
          docExportAsM1ImpexJsonResult.data?.docExportAsM1ImpexJson
            .downloadUrl ?? ""
        );
      }
    },
    [docExportAsM1ImpexJsonMutation]
  );
};

function download(url: string) {
  const elem = window.document.createElement("a");
  elem.href = url;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}
