import { EditableTitle, useScreenWidth } from "@msys/ui";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslate } from "@tolgee/react";

export type HeaderType = "template";

type PageSubHeaderTitle =
  | {
      title: React.ReactNode;
      handleTitleChange?: undefined;
      titleEditTooltip?: undefined;
    }
  | {
      title?: string;
      handleTitleChange?: (value: string) => void;
      titleEditTooltip?: string;
    };

interface Props {
  // Always next to the title
  status?: React.ReactNode;
  // This menu buttons always stays on the right
  actionMenu?: React.ReactNode;
  // Those buttons are always stays on the right, before menu
  actionButtons?: React.ReactNode;
  // This line is going down on mobile (separate line)
  entityNumber?: React.ReactNode;
  // This line is going down on mobile - left & right buttons/icons (separate line)
  leftButtons?: React.ReactNode;
  rightButtons?: React.ReactNode;
  hideTitleOnMobile?: boolean;
  headerType?: HeaderType | null;
}

// These props are getting injected in Page component
export interface PageSubHeaderInjectedProps {
  isHeaderVisible: boolean;
  setHeaderVisible: (isVisible: boolean) => void;
}

export function PageSubHeader({
  title,
  handleTitleChange,
  titleEditTooltip,
  status,
  actionButtons,
  actionMenu,
  entityNumber,
  leftButtons,
  rightButtons,
  isHeaderVisible,
  setHeaderVisible,
  hideTitleOnMobile = false,
  headerType,
}: Props & PageSubHeaderTitle & PageSubHeaderInjectedProps) {
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate("Global");
  const toggleButton = isMinTablet && (
    <Tooltip title={isHeaderVisible ? t("Hide top menu") : t("Show top menu")}>
      <IconButton
        color="primary"
        onClick={() => setHeaderVisible(!isHeaderVisible)}
        size="small"
      >
        {isHeaderVisible ? (
          <ExpandLessIcon fontSize="medium" />
        ) : (
          <ExpandMoreIcon fontSize="medium" />
        )}
      </IconButton>
    </Tooltip>
  );

  const buttonsAtRight: React.ReactNode[] = [
    isMinTablet && rightButtons,
    actionButtons,
    actionMenu,
    toggleButton,
  ].filter(Boolean);

  const buttonsAtLeft: React.ReactNode[] = [
    leftButtons,
    !isMinTablet && rightButtons,
  ].filter(Boolean);

  const ButtonsAtRight =
    buttonsAtRight.length > 0 ? (
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        justifySelf="flex-end"
      >
        {buttonsAtRight.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <Box py={1} alignSelf="stretch">
                <Divider orientation="vertical" />
              </Box>
            )}
            {item}
          </React.Fragment>
        ))}
      </Stack>
    ) : null;

  const ButtonsAtLeft =
    buttonsAtLeft.length > 0 ? (
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        justifySelf="flex-end"
      >
        {buttonsAtLeft.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <Box py={1} alignSelf="stretch">
                <Divider orientation="vertical" />
              </Box>
            )}
            {item}
          </React.Fragment>
        ))}
      </Stack>
    ) : null;

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={theme => ({
        ...(headerType === "template"
          ? { backgroundColor: theme.custom.colors.templateHeaderBackground }
          : undefined),
      })}
    >
      <Toolbar sx={{ minHeight: "unset !important" }}>
        {isMinTablet ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={2}
            flex={1}
            py={0.5}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={2} flex={1}>
              {title &&
                (handleTitleChange ? (
                  <EditableTitle
                    title={title}
                    tooltip={titleEditTooltip}
                    handleChange={handleTitleChange}
                  />
                ) : (
                  <Typography variant={"h1"}>{title}</Typography>
                ))}
              {status}
              {ButtonsAtLeft}
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              {entityNumber}
              {ButtonsAtRight}
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction={"column"}
            alignItems={"stretch"}
            spacing={0.5}
            flex={1}
            py={0.5}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              justifyContent={"space-between"}
              flex={1}
            >
              <div>
                {!hideTitleOnMobile &&
                  title &&
                  (handleTitleChange ? (
                    <EditableTitle
                      title={title}
                      tooltip={titleEditTooltip}
                      handleChange={handleTitleChange}
                    />
                  ) : (
                    <Typography variant={"h1"}>{title}</Typography>
                  ))}
                {entityNumber && <div>{entityNumber}</div>}
              </div>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                justifyContent={"flex-end"}
              >
                {status && <div>{status}</div>}
                {!ButtonsAtLeft && ButtonsAtRight && (
                  <div>{ButtonsAtRight}</div>
                )}
              </Stack>
            </Stack>
            {ButtonsAtLeft || (ButtonsAtLeft && ButtonsAtRight) ? (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={1}
                flex={1}
              >
                <div>{ButtonsAtLeft}</div>
                <div>{ButtonsAtRight}</div>
              </Stack>
            ) : null}
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
}
