import { gql, useApolloClient } from "@apollo/client";
import { notNull } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  GridColDef,
  ListHeader,
  ModalOpenButton,
  Tabs,
  useFormatting,
} from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ButtonCreate } from "../../commons/button/Button.js";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination.js";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam.js";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants.js";
import { SearchItemInputModal } from "../../features/doc-items/SearchItemInputModal.js";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore.js";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import {
  ArticleListsList_QuoteProductsDistributionFragment,
  ArticleListsListQueryVariables,
  useArticleLists_CreateQuoteProductsDistributionMutation,
  useArticleListsListQuery,
} from "./OpportunitiesArticleLists.generated.js";

interface Props {
  submenuItems: PageTopbarItem[];
}

export const OpportunitiesArticleLists = ({ submenuItems }: Props) => {
  const { t } = useTranslate("Opportunities");

  return (
    <Page
      title={t("Article lists")}
      subtitle={t("Opportunities")}
      submenuItems={submenuItems}
    >
      <ArticleListsList
        title={t("Article lists")}
        pathToPage="/opportunities/article-lists"
      />
    </Page>
  );
};

export type QuickFilterValue = "UNAPPLIED" | "APPLIED";
type Filters = Pick<
  ArticleListsListQueryVariables,
  "filterApplied" | "filterSearchTerm"
>;

export function ArticleListsList({
  title,
  pathToPage,
}: {
  title: string;
  pathToPage: string;
}) {
  const navigate = useNavigate();
  const { t } = useTranslate(["Opportunities", "Global", "Projects"]);
  const { getFormattedDate } = useFormatting();

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    // sorting,
    // setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<{}, Filters>({}, [], PAGE_LIST_RESULTS_PER_PAGE);

  const quickFilterSettings: Record<
    QuickFilterValue,
    {
      label: string;
      values: Filters;
    }
  > = React.useMemo(
    () => ({
      UNAPPLIED: {
        label: t("Unapplied", {
          ns: "Opportunities",
        }),
        values: {
          filterApplied: false,
        },
      },
      APPLIED: {
        label: t("Applied", {
          ns: "Opportunities",
        }),
        values: {
          filterApplied: true,
        },
      },
    }),
    [t]
  );

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<
    keyof typeof quickFilterSettings
  >("quickfilter", "UNAPPLIED", toRemoveParams);

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    // sorting: DEFAULT_SORTING,
  };

  const client = useApolloClient();
  const query = useArticleListsListQuery({
    client,
    variables: {
      ...QUERY_BASE_VARIABLES,
      ...quickFilterSettings?.[quickFilter]?.values,
      ...filters,
      // sorting,
    },
  });

  const [createQuoteProductsDistributionMutation] =
    useArticleLists_CreateQuoteProductsDistributionMutation({
      client,
      refetchQueries: [namedOperations.Query.ArticleListsList],
    });

  const columns: GridColDef<ArticleListsList_QuoteProductsDistributionFragment>[] =
    [
      // {
      //   field: "id",
      //   headerName: "ID",
      //   editable: false,
      //   sortable: false,
      //   flex: 2,
      //   minWidth: 100,
      //   valueGetter: ({ row }) => row.id,
      // },
      {
        field: "clientEmail",
        headerName: t("Email", {
          ns: "Global",
        }),
        editable: false,
        sortable: false,
        flex: 2,
        minWidth: 100,
        valueGetter: ({ row }) => row.clientEmail,
      },
      {
        field: "skus",
        headerName: t("SKUs", {
          ns: "Opportunities",
        }),
        editable: false,
        sortable: false,
        flex: 3,
        minWidth: 100,
        valueGetter: ({ row }) => {
          return row.quoteProductsDistributionProducts
            .slice(0, 3)
            .map(product => product.productArticleNumber)
            .join(", ")
            .concat("...");
        },
      },
      {
        field: "createdAt",
        headerName: t("Created at", {
          ns: "Opportunities",
        }),
        editable: false,
        sortable: false,
        flex: 1,
        minWidth: 100,
        valueGetter: ({ row }) => getFormattedDate(row.createdAt),
      },
      ...(quickFilter === "APPLIED"
        ? [
            {
              field: "projects",
              headerName: t("Projects", {
                ns: "Projects",
              }),
              editable: false,
              flex: 3,
              minWidth: 100,
              align: "right",
              headerAlign: "right",
              valueGetter: ({
                row,
              }: {
                row: ArticleListsList_QuoteProductsDistributionFragment;
              }) =>
                row.applications.map(application => application.project?.title),
            } as const,
          ]
        : []),
    ];

  const opportunities =
    query.data?.searchQuoteProductsDistributions.quoteProductsDistributions ??
    [];
  const total = query.data?.searchQuoteProductsDistributions.totalCount ?? 0;

  const stateStore = useDataGridStateStore("OpportunitiesArticleLists");

  return (
    <PageContainer>
      <ListHeader
        // hideTitleOnMobile
        // title={title}
        QuickFilter={
          quickFilterSettings && Object.keys(quickFilterSettings).length > 0 ? (
            <Tabs
              useSelectOnMobile
              options={Object.entries(quickFilterSettings).map(
                ([key, value]) => {
                  return {
                    value: key as keyof Exclude<
                      typeof quickFilterSettings,
                      undefined
                    >,
                    label: value.label,
                  };
                }
              )}
              value={quickFilter}
              onChange={(
                newQuickFilter: keyof Exclude<
                  typeof quickFilterSettings,
                  undefined
                >
              ) => {
                setQuickFilter(newQuickFilter);
              }}
            />
          ) : undefined
        }
        FilterButton={undefined}
        SearchField={
          <DebouncedSearchInput
            placeholder={t("Search", { ns: "Global" })}
            defaultValue={filters.filterSearchTerm}
            onChangeSearchTerm={newValue =>
              setFilters(filters => ({
                ...filters,
                filterSearchTerm: newValue,
              }))
            }
          />
        }
        FilterChips={undefined}
        ActionButtons={
          <ModalOpenButton
            Modal={SearchItemInputModal}
            modalProps={{
              initialSearchTab: "products",
              initialSearchValue: "",
              allowedSearchTabs: ["products"],
              handleSelect: async ({ products }) => {
                await createQuoteProductsDistributionMutation({
                  variables: {
                    input: {
                      products: products
                        .map(({ product, quantity }) => {
                          const articleNumber =
                            "__typename" in product
                              ? product.articleNumber
                              : product.productArticleNumber;

                          const supplierId =
                            "__typename" in product
                              ? product.supplierId
                              : product.productSupplierId;

                          if (!(articleNumber && supplierId)) {
                            return null;
                          }

                          return {
                            articleNumber,
                            supplierId,
                            quantity: quantity,
                          };
                        })
                        .filter(notNull),
                    },
                  },
                });
              },
            }}
          >
            <ButtonCreate
              title={t("Article list", { ns: "Opportunities" })}
              sx={{ flexShrink: 0, flexGrow: 0 }}
            />
          </ModalOpenButton>
        }
        mb={2}
      />
      <DataGrid<ArticleListsList_QuoteProductsDistributionFragment>
        stateStore={stateStore}
        loading={query.loading}
        hideFooter={total === 0}
        columns={columns}
        rows={opportunities}
        onRowClick={({ row }) => {
          navigate(`${pathToPage}/${row.id}`);
        }}
        disableColumnFilter
        // sortModel={sortModel}
        // onSortModelChange={newModel => {
        //   setSorting(
        //     newModel.map(({ field, sort }) => ({
        //       column: field as OrganisationOpportunitiesSortBy,
        //       direction: sortDirectionMapping[sort ?? "asc"],
        //     }))
        //   );
        // }}
        paginationModel={paginationModel}
        onPaginationModelChange={newPaginationModel => {
          setPaginationModel(newPaginationModel);
        }}
        pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
        rowCount={total}
      />
      <Outlet />
    </PageContainer>
  );
}
