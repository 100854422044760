import React, { FC } from "react";
import moment from "moment";
import { Field, Form, Formik } from "formik";
import { AbsenceReason } from "../../../clients/graphqlTypes.js";
import { PlanningAbsenceFragment } from "../../main-routes/planning/PlanningAbsences.generated.js";
import { PlanningResourcesFragment } from "../planning/PlanningSchedule.generated.js";
import { useModifyAbsenceMutation } from "./EditAbsenceModal.generated.js";
import * as Yup from "yup";
import { useTranslate } from "@tolgee/react";
import { Modal } from "@msys/ui";
import { uniqueId } from "lodash-es";
import { Stack } from "../../commons/layout/Stack.js";
import { SelectField } from "../../commons/form-fields/SelectField.js";
import { useAbsenceReasons } from "./useAbsenceReasons.js";
import { TextField } from "formik-mui";
import { Grid } from "@mui/material";
import { DatePickerField } from "../../commons/form-fields/DatePickerField.js";
import { gql, useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";

interface FormValues {
  whoId: string | null;
  from: moment.Moment | null;
  till: moment.Moment | null;
  reason: AbsenceReason;
  note: string;
}

export const EditAbsenceModal: FC<{
  absence: PlanningAbsenceFragment;
  craftsmen: PlanningResourcesFragment[];
  refetchQueries?: string[];
  handleClose: () => void;
  handleComplete?: () => void;
}> = ({ absence, craftsmen, refetchQueries, handleClose, handleComplete }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Global", "Absences"]);

  const client = useApolloClient();
  const [modifyAbsence] = useModifyAbsenceMutation({ client });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyAbsence({
        variables: {
          input: {
            id: absence.id,
            whoId: values.whoId!,
            from: values.from!.format("YYYY-MM-DD"),
            till: values.till!.format("YYYY-MM-DD"),
            reason: values.reason,
            note: values.note,
          },
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
      enqueueSnackbar(
        t("Absence updated", {
          ns: "Absences",
        })
      );
      handleComplete?.();
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const initialValues: FormValues = {
    whoId: absence.who.id,
    from: moment(absence.from),
    till: moment(absence.till),
    reason: absence.reason,
    note: absence.note,
  };

  const { absenceReasonOptions } = useAbsenceReasons();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        whoId: Yup.string()
          .label(
            t("Team member", {
              ns: "Absences",
            })
          )
          .required(),
        from: Yup.date()
          .label(
            t("From", {
              ns: "Absences",
            })
          )
          .required(),
        till: Yup.date()
          .label(
            t("Until", {
              ns: "Absences",
            })
          )
          .test(
            "laterThan",
            t("Until must be later than from", {
              ns: "Absences",
            }),
            function (till: Date | null | undefined) {
              const from: Date | null | undefined = this.resolve(
                Yup.ref("from")
              );
              return !till || !from || from <= till;
            }
          )
          .required(),
        note: Yup.string().label(
          t("Note", {
            ns: "Absences",
          })
        ),
        reason: Yup.string()
          .label(
            t("Reason", {
              ns: "Absences",
            })
          )
          .required(),
      }),
    [t]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  const craftsmenOptions = React.useMemo(
    () =>
      craftsmen.map(u => ({
        value: u.id,
        label: u.fullname,
      })),
    [craftsmen]
  );

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting, dirty, isValid }) => (
        <Modal
          title={t("Edit absence", {
            ns: "Absences",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                loading: isSubmitting,
                form: formId,
                type: "submit",
                disabled: !dirty || !isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack flexDirection="column">
              <SelectField
                name="whoId"
                required
                options={craftsmenOptions}
                label={t("Team member", {
                  ns: "Absences",
                })}
              />
              <SelectField
                name="reason"
                required
                options={absenceReasonOptions}
                label={t("Reason", {
                  ns: "Absences",
                })}
              />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <DatePickerField
                    required
                    name="from"
                    label={t("From", {
                      ns: "Absences",
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePickerField
                    required
                    name="till"
                    label={t("Until", {
                      ns: "Absences",
                    })}
                  />
                </Grid>
              </Grid>
              <Field
                component={TextField}
                label={t("Note", {
                  ns: "Absences",
                })}
                name="note"
                multiline
                rows={3}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
