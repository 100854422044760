import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  ErrorMessage,
  LoadingSpinner,
  useFormatting,
} from "@msys/ui";
import { InsertDriveFile as InsertDriveFileIcon } from "@mui/icons-material";
import { ReportProblemOutlined as ReportProblemOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  List,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Avatar } from "../../commons/Avatar.js";
import { ProfileListItem } from "../../commons/ProfileListItem.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { PimImportationStatus } from "../../../clients/graphqlTypes.js";
import { usePimImportationsQuery } from "./PimImportations.generated.js";
import { useTranslate } from "@tolgee/react";
import { AddDatanormOnlineImportationButton } from "./buttons/AddDatanormOnlineImportationButton.js";
import { AddPimImportationButton } from "./buttons/AddPimImportationButton.js";
import { CancelPimImportationButton } from "./buttons/CancelPimImportationButton.js";
import { DeletePimSupplierImportationsButton } from "./buttons/DeletePimSupplierImportationsButton.js";
import { ceil, floor } from "lodash-es";

interface Props {
  supplierId: string;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box width="100%" display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box width={35}>
        <Typography variant="body2" color="textSecondary">
          {`${
            floor(props.value) === 0 ? ceil(props.value) : floor(props.value)
          }%`}
        </Typography>
      </Box>
    </Box>
  );
}

export const PimImportations = ({ supplierId }: Props) => {
  const client = useApolloClient();
  const { getFormattedDate } = useFormatting();

  const { t } = useTranslate("PimImportations");

  const pimImportationStatusLabels: Record<PimImportationStatus, string> = {
    to_index: t("indexing"),
    indexing: t("indexing"),
    indexed: t("indexing"),
    failed: t("failed"),
    has_duplicate: t("has_duplicate"),
    to_delete: t("deleting"),
    deleting: t("deleting"),
    deleted: t("deleted"),
  };

  const query = usePimImportationsQuery({
    client,
    fetchPolicy: "no-cache",
    variables: {
      supplierId,
    },
  });

  const pimImportations = getDataOrNull(
    query.data?.pimImportations
  )?.importations;

  React.useEffect(() => {
    if (pimImportations?.some(importation => importation.isProcessing)) {
      query.startPolling(5000);
    } else {
      query.stopPolling();
    }

    return () => query.stopPolling();
  }, [pimImportations, query]);

  const showDebug = useFeature("DebugOutput");

  if (query.loading) return <LoadingSpinner />;
  if (query.error) return <ErrorMessage message={query.error.message} />;

  return (
    <CardContainer
      isExpandable
      isInitiallyClosed={pimImportations ? pimImportations.length === 0 : true}
      itemCount={pimImportations?.length}
      title={t("Imported catalogues")}
      ActionButton={
        <Stack
          direction="row"
          spacing={0.5}
          flexGrow={0}
          flexShrink={0}
          alignItems="center"
        >
          <AddDatanormOnlineImportationButton supplierId={supplierId} />
          <AddPimImportationButton supplierId={supplierId} />
          <DeletePimSupplierImportationsButton supplierId={supplierId} />
        </Stack>
      }
    >
      <List disablePadding>
        {pimImportations?.map(importation => {
          const warning = ["failed", "has_duplicate"].includes(
            importation.status
          );

          return (
            <React.Fragment key={importation.id}>
              <ProfileListItem
                avatar={
                  <Avatar
                    type="circle"
                    size="m"
                    icon={<InsertDriveFileIcon />}
                  />
                }
                primary={importation.filename}
                secondary={
                  importation.status !== "failed" &&
                  importation.status !== "to_delete" &&
                  importation.status !== "deleted" &&
                  importation.status !== "deleting" &&
                  importation.isProcessing ? (
                    <LinearProgressWithLabel
                      value={importation.completionPercentage * 100}
                    />
                  ) : importation.status === "indexed" ? (
                    `${getFormattedDate(importation.createdAt)}, ${
                      importation?.totalProcessedProducts ?? 0
                    } ${t("products")}`
                  ) : (
                    pimImportationStatusLabels[importation.status]
                  )
                }
                button={false}
                secondaryTypographyProps={{
                  color: warning ? "error" : "secondary",
                }}
                badge={
                  warning ? (
                    <ReportProblemOutlinedIcon color="error" />
                  ) : undefined
                }
                ActionButton={
                  !warning &&
                  importation.isProcessing &&
                  importation.status !== "to_delete" &&
                  importation.status !== "deleting" ? (
                    <CancelPimImportationButton pimImportation={importation} />
                  ) : undefined
                }
              />

              {showDebug && (
                <Stack direction="column" spacing={1}>
                  <textarea
                    value={JSON.stringify(importation)}
                    style={{ height: "200px" }}
                    readOnly
                  ></textarea>
                </Stack>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </CardContainer>
  );
};
