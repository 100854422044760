/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { QuoteListItem_OutgoingQuoteFragmentDoc, QuoteListItem_IncomingQuoteFragmentDoc } from '../../features/quotes/QuoteListItem.generated.js';
import { RequestStatusBadgeFragmentDoc } from '../../features/requests/RequestStatusBadge.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectQuotesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectQuotesQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus, incomingQuoteRequests: Array<{ __typename: 'Request', id: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null }> } | null }, projectOutgoingQuotes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'ProjectOutgoingQuoteConnection', totalCount: number, edges: Array<{ __typename: 'OutgoingQuoteEdge', node: { __typename: 'OutgoingQuote', id: string, title: string, number: string, isPublished: boolean, createdAt: any, publishedAt?: any | null, acceptedAt?: any | null, isBinding: boolean, needsAgreement: boolean, agreement: Types.Agreement, createdBy: string, docActorClientName: string, proposedTotalPrice: number, agreedTotalPrice?: number | null, xStatus?: string | null, docActorContracteeName: string, request?: { __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } | null } }> }, projectIncomingQuotes: { __typename: 'MissingPermissions' } | { __typename: 'ProjectIncomingQuoteConnection', totalCount: number, edges: Array<{ __typename: 'IncomingQuoteEdge', node: { __typename: 'IncomingQuote', id: string, title: string, number: string, isPublished: boolean, createdAt: any, publishedAt?: any | null, acceptedAt?: any | null, isBinding: boolean, needsAgreement: boolean, agreement: Types.Agreement, docActorContractorName: string, proposedTotalPrice: number, agreedTotalPrice?: number | null, request?: { __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } | null } }> } };

export type ProjectQuote_OutgoingQuoteFragment = { __typename: 'OutgoingQuote', id: string, title: string, number: string, isPublished: boolean, createdAt: any, publishedAt?: any | null, acceptedAt?: any | null, isBinding: boolean, needsAgreement: boolean, agreement: Types.Agreement, createdBy: string, docActorClientName: string, proposedTotalPrice: number, agreedTotalPrice?: number | null, xStatus?: string | null, docActorContracteeName: string, request?: { __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } | null };

export type ProjectQuote_IncomingQuoteFragment = { __typename: 'IncomingQuote', id: string, title: string, number: string, isPublished: boolean, createdAt: any, publishedAt?: any | null, acceptedAt?: any | null, isBinding: boolean, needsAgreement: boolean, agreement: Types.Agreement, docActorContractorName: string, proposedTotalPrice: number, agreedTotalPrice?: number | null, request?: { __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } | null };

export const ProjectQuote_OutgoingQuoteFragmentDoc = gql`
    fragment ProjectQuote_OutgoingQuote on OutgoingQuote {
  id
  title
  number
  isPublished
  createdAt
  publishedAt
  acceptedAt
  isBinding
  needsAgreement
  agreement
  createdBy
  docActorClientName
  proposedTotalPrice
  agreedTotalPrice
  request {
    id
    title
    ...RequestStatusBadge
  }
  xStatus
  ...QuoteListItem_OutgoingQuote
}
    ${RequestStatusBadgeFragmentDoc}
${QuoteListItem_OutgoingQuoteFragmentDoc}`;
export const ProjectQuote_IncomingQuoteFragmentDoc = gql`
    fragment ProjectQuote_IncomingQuote on IncomingQuote {
  id
  title
  number
  isPublished
  createdAt
  publishedAt
  acceptedAt
  isBinding
  needsAgreement
  agreement
  docActorContractorName
  proposedTotalPrice
  agreedTotalPrice
  request {
    id
    title
    ...RequestStatusBadge
  }
  ...QuoteListItem_IncomingQuote
}
    ${RequestStatusBadgeFragmentDoc}
${QuoteListItem_IncomingQuoteFragmentDoc}`;
export const ProjectQuotesDocument = gql`
    query ProjectQuotes($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        viewerPermissions
        state
        incomingQuoteRequests {
          id
          status
          wonBySystemOrganisationId
        }
      }
    }
  }
  projectOutgoingQuotes(limit: 0, projectId: $projectId) {
    ... on ProjectOutgoingQuoteConnection {
      edges {
        node {
          ...ProjectQuote_OutgoingQuote
          ...QuoteListItem_OutgoingQuote
        }
      }
      totalCount
    }
  }
  projectIncomingQuotes(limit: 0, projectId: $projectId) {
    ... on ProjectIncomingQuoteConnection {
      edges {
        node {
          ...ProjectQuote_IncomingQuote
          ...QuoteListItem_IncomingQuote
        }
      }
      totalCount
    }
  }
}
    ${ProjectQuote_OutgoingQuoteFragmentDoc}
${QuoteListItem_OutgoingQuoteFragmentDoc}
${ProjectQuote_IncomingQuoteFragmentDoc}
${QuoteListItem_IncomingQuoteFragmentDoc}`;

/**
 * __useProjectQuotesQuery__
 *
 * To run a query within a React component, call `useProjectQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuotesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectQuotesQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuotesQuery, ProjectQuotesQueryVariables> & ({ variables: ProjectQuotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuotesQuery, ProjectQuotesQueryVariables>(ProjectQuotesDocument, options);
      }
export function useProjectQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuotesQuery, ProjectQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuotesQuery, ProjectQuotesQueryVariables>(ProjectQuotesDocument, options);
        }
export function useProjectQuotesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectQuotesQuery, ProjectQuotesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectQuotesQuery, ProjectQuotesQueryVariables>(ProjectQuotesDocument, options);
        }
export type ProjectQuotesQueryHookResult = ReturnType<typeof useProjectQuotesQuery>;
export type ProjectQuotesLazyQueryHookResult = ReturnType<typeof useProjectQuotesLazyQuery>;
export type ProjectQuotesSuspenseQueryHookResult = ReturnType<typeof useProjectQuotesSuspenseQuery>;
export type ProjectQuotesQueryResult = Apollo.QueryResult<ProjectQuotesQuery, ProjectQuotesQueryVariables>;