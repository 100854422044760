import React from "react";
import { AvailableUsersQuery } from "./AvailableUsersProvider.generated.js";

type AvailableUsersContextType = {
  availableUsers: AvailableUsersQuery["availableUsers"];
  isLoading: boolean;
  refetch: () => Promise<unknown>;
};
export const AvailableUsersContext =
  React.createContext<AvailableUsersContextType>({
    availableUsers: [],
    isLoading: false,
    refetch: asyncNoop,
  });

async function asyncNoop() {}
