/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../../doc-items/Fragments.generated.js';
export type ShopItemsList_ShoppableTemplateFragment = { __typename: 'ShoppableTemplate', id: string, title: string, description: string, isLikedByMe: boolean, customTags: Array<string>, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null };

export const ShopItemsList_ShoppableTemplateFragmentDoc = gql`
    fragment ShopItemsList_ShoppableTemplate on ShoppableTemplate {
  id
  title
  description
  proposedCalculation {
    ...ItemCalculation
  }
  shopCatalogProductImage {
    id
    url
  }
  isLikedByMe
  customTags
}
    ${ItemCalculationFragmentDoc}`;