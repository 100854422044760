import { assertNever } from "@msys/common";
import { CardContainer } from "@msys/ui";
import { Group as GroupIcon } from "@mui/icons-material";
import { List } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Avatar, getInitials } from "../../../commons/Avatar.js";
import { ProfileListItem } from "../../../commons/ProfileListItem.js";
import { useSalutation } from "../../users/useSalutation.js";
import { RequestContactBoxFragment } from "./RequestContactBox.generated.js";

interface Props {
  request: RequestContactBoxFragment;
}

export const RequestContactBox = ({ request }: Props) => {
  const { t } = useTranslate("Requests");
  const { getNameWithSalutation } = useSalutation();

  const contractee = request.requestorDoc.contractee;

  if (!contractee.contact) return null;

  return (
    <CardContainer title={t("Contact person")} Icon={<GroupIcon />}>
      <List disablePadding>
        <ProfileListItem
          avatar={
            <Avatar
              type="circle"
              size="m"
              initials={getInitials(
                contractee.contact.firstname,
                contractee.contact.familyname
              )}
            />
          }
          primary={getNameWithSalutation(
            contractee.contact.salutation,
            contractee.contact.firstname,
            contractee.contact.familyname
          )}
          secondary={
            contractee.companyOrIndividual === "COMPANY"
              ? contractee.companyName
              : contractee.companyOrIndividual === "INDIVIDUAL"
                ? `${contractee.firstname} ${contractee.familyname}`
                : assertNever(contractee.companyOrIndividual)
          }
        />
      </List>
    </CardContainer>
  );
};
