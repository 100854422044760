/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type ToggleTaskCompletionIconButton__TaskDocumentItemFragment = { __typename: 'Item', id: string, isDone: boolean, deletedAt?: any | null };

export const ToggleTaskCompletionIconButton__TaskDocumentItemFragmentDoc = gql`
    fragment ToggleTaskCompletionIconButton__TaskDocumentItem on Item {
  id
  isDone
  deletedAt
}
    `;