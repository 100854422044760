import { gql, useApolloClient } from "@apollo/client";
import { Star as StarIcon } from "@mui/icons-material";
import { StarOutline as StarOutlineIcon } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";
import { useOrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation } from "./OrganisationSupplierProductFavouriteButton.generated.js";

interface Product {
  id: string;
  articleNumber: string;
  supplierId: string;
  organisationFavourite: boolean;
}

interface Props {
  product: Product;
  canEdit?: boolean;
  iconButtonProps?: IconButtonProps;
  refetchQueries?: string[];
}

export function OrganisationSupplierProductFavouriteButton({
  product,
  canEdit = true,
  refetchQueries,
  iconButtonProps,
}: Props) {
  const client = useApolloClient();

  const [setSupplierProductsFavouritMutation, { loading }] =
    useOrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation(
      { client, refetchQueries }
    );

  return (
    <IconButton
      size="small"
      color="primary"
      {...iconButtonProps}
      onClick={
        canEdit
          ? async () => {
              await setSupplierProductsFavouritMutation({
                variables: {
                  input: {
                    isFavourite: !product.organisationFavourite,
                    supplierProductArticleNumbers: [
                      {
                        articleNumber: product.articleNumber,
                        supplierId: product.supplierId,
                      },
                    ],
                  },
                },
              });
            }
          : undefined
      }
      disabled={loading || !canEdit}
    >
      {product.organisationFavourite ? (
        <StarIcon fontSize="small" />
      ) : (
        <StarOutlineIcon fontSize="small" />
      )}
    </IconButton>
  );
}
