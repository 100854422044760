import { StyledComponent } from "@emotion/styled";
import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import React from "react";
import { px } from "../../../common/MuiThemeProvider.js";

export const TreeIconRightContainer: StyledComponent<
  MUIStyledCommonProps<Theme>,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled("div")(`
  display: flex;
  align-items: center;
  margin-left: ${px.xxs};
  & > *:not(:empty) + *:not(:empty) {
    margin-left: ${px.xs};
  }
`);
