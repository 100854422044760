import { useApolloClient } from "@apollo/client";
import { MenuItemWithIcon } from "@msys/ui";
import { FindReplace as FindReplaceIcon } from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import { useTranslate } from "@tolgee/react";
import { pick } from "lodash-es";
import React, { useState } from "react";
import {
  CreateItemsValues,
  ModifyItemProductValuesInput,
} from "../../../../clients/graphqlTypes.js";
import { ConfirmModalProps } from "../../../commons/modals/ConfirmProcess.js";
import { ProductSearchItem__ProductSearchResultFragment } from "../../products/Product.generated.js";
import { TemplatesQuoteSelectMultipleComponent_TemplateFragment } from "../../templates/quote/TemplatesQuoteSelectMultipleModal.generated.js";
import { useDeleteItemMutation } from "../hooks/useDeleteItemMutation.js";
import { useDocItemsClipboard } from "../hooks/useDocItemsClipboard.js";
import { SearchItemInputModal } from "../SearchItemInputModal.js";
import { SearchTab } from "../SearchProductsAndTemplates.js";
import {
  useCreateItemsFromTemplatesMutation,
  useQuoteItemCreateItemsMutation,
} from "./ReplaceWithTemplatesMenuItem.generated.js";

interface Props {
  docId: string;
  projectId: string;
  itemId: string;
  itemRank: number;
  parentItemId: string;
  disabled?: boolean;
  onItemCreated?: () => Promise<void> | void;
  onItemDeleted?: () => Promise<void> | void;
  startConfirmProcess: (
    props: ConfirmModalProps
  ) => Promise<boolean | undefined>;
  expandedItemIds: string[] | undefined;
}

export const ReplaceWithTemplatesMenuItem = ({
  docId,
  projectId,
  itemId,
  itemRank,
  parentItemId,
  disabled,
  onItemCreated,
  onItemDeleted,
  startConfirmProcess,
  expandedItemIds,
}: Props) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);
  const client = useApolloClient();
  const [createQuoteItems] = useQuoteItemCreateItemsMutation({ client });
  const [createItemsFromTemplates] = useCreateItemsFromTemplatesMutation({
    client,
  });
  const {
    deleteItem,
    deleteItemLoading,
    checkItemBeforeDelete,
    checkItemCouldBeDeletedLoading,
  } = useDeleteItemMutation(expandedItemIds);

  const [content, setContent] = useDocItemsClipboard();
  const isRemovingItemFromClipboard =
    content?.projectId === projectId &&
    content?.docId === docId &&
    content?.itemId === itemId;

  const [creatingItem, setCreatingItem] = useState<boolean>(false);

  const [searchModalState, setSearchModalState] = React.useState<{
    searchValue: string;
    searchTab: SearchTab;
  } | null>(null);

  const createItemsFromTemplatesWithUnnesting = async (
    templates: {
      quantity: number;
      template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
    }[]
  ) => {
    await createItemsFromTemplates({
      variables: {
        input: {
          parentProjectId: projectId,
          parentDocId: docId,
          parentItemId,
          templates: templates.map(tpl => ({
            templateQuoteId: tpl.template.id,
            templateEstimatedQuantity: tpl.quantity,
          })),
          rank: itemRank + 1,
        },
      },
    });
  };

  const createItemsWithProducts = async (
    products: {
      quantity: number;
      product:
        | ProductSearchItem__ProductSearchResultFragment
        | ModifyItemProductValuesInput;
    }[]
  ) => {
    const items: CreateItemsValues[] = products.map(opts =>
      "__typename" in opts.product
        ? {
            title: opts.product.texts?.title ?? "",
            type: "paid",
            decisionBehaviorOfSubitems: "NONE",
            estimatedQuantity: opts.quantity,
            product: pick(opts.product, "supplierId", "articleNumber"),
          }
        : {
            title: opts.product.productTitle ?? "",
            type: "paid",
            decisionBehaviorOfSubitems: "NONE",
            estimatedQuantity: opts.quantity,
            productValues: opts.product,
          }
    );
    const result = await createQuoteItems({
      variables: {
        input: {
          projectId,
          docId,
          parentItemId,
          items,
          rank: itemRank + 1,
        },
      },
    });
  };

  const checkItemCanBeDeleted = async () => {
    if (isRemovingItemFromClipboard) {
      const result = await startConfirmProcess({
        title: t("Confirm replacement", {
          ns: "Global",
        }),
        text: t(
          "You have copied this item to the clipboard. You will not be able to paste it if you replace it. Would you like to continue?",
          {
            ns: "QuoteItem",
          }
        ),
        confirmButtonLabel: t("Replace item", {
          ns: "QuoteItem",
        }),
      });
      if (!result) return false;
    }

    const { itemOrSubItemsPropsUsedInOtherItems } = await checkItemBeforeDelete(
      { projectId, docId, itemId }
    );
    if (itemOrSubItemsPropsUsedInOtherItems) {
      const result = await startConfirmProcess({
        title: t("Confirm replacement", {
          ns: "Global",
        }),
        text: t(
          "Some formulas will be lost when replacing this item. Would you like to continue?",
          {
            ns: "QuoteItem",
          }
        ),
        confirmButtonLabel: t("Replace item", {
          ns: "QuoteItem",
        }),
      });
      if (!result) return false;
    }

    return true;
  };

  return (
    <>
      <MenuItemWithIcon
        icon={<FindReplaceIcon />}
        disabled={
          disabled ||
          creatingItem ||
          deleteItemLoading ||
          checkItemCouldBeDeletedLoading
        }
        onClick={() => {
          setSearchModalState({
            searchValue: "",
            searchTab: "templates",
          });
        }}
      >
        {t("Replace with template", {
          ns: "QuoteItem",
        })}
      </MenuItemWithIcon>
      {searchModalState && (
        <SearchItemInputModal
          initialSearchValue={searchModalState.searchValue}
          initialSearchTab={searchModalState.searchTab}
          handleClose={async () => {
            setSearchModalState(null);
          }}
          excludeTemplateIds={[docId]}
          handleSelect={async ({ products, templates }) => {
            if (creatingItem) return;
            if (!(await checkItemCanBeDeleted())) return;
            try {
              setCreatingItem(true);
              if (products.length > 0) await createItemsWithProducts(products);
              if (templates.length > 0)
                await createItemsFromTemplatesWithUnnesting(templates);
              await onItemCreated?.();
              if (deleteItemLoading) return;
              await deleteItem({
                projectId,
                docId,
                itemId,
                onItemDeleted,
              });
            } catch (e) {
              console.error(e);
              Sentry.captureException(e);
            } finally {
              setCreatingItem(false);
            }
          }}
        />
      )}
    </>
  );
};
