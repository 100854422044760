import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useLatest } from "react-use";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { InviteContracteeField_ProjectFragment } from "./InviteContracteeField.generated.js";

interface Props {
  project: InviteContracteeField_ProjectFragment;
  checked: boolean;
  handleChange: (checked: boolean) => void;
  disabled?: boolean;
}

export const InviteContracteeField = ({
  project,
  checked,
  handleChange,
  disabled,
}: Props) => {
  const { t } = useTranslate(["QuoteSend"]);

  const handleChangeLatest = useLatest(handleChange);

  React.useEffect(() => {
    if (disabled) {
      handleChangeLatest.current(false);
    }
  }, [disabled, handleChangeLatest]);

  return (
    <RestrictedByProjectPermissionWithDebug
      permission="READ_PROJECT_MEMBERS"
      project={project}
    >
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_PROJECT"
        project={project}
      >
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={checked}
              onChange={(event, checked) => {
                handleChange(checked);
              }}
              disabled={disabled}
            />
          }
          label={t("Invite customer to project", { ns: "QuoteSend" })}
        />
      </RestrictedByProjectPermissionWithDebug>
    </RestrictedByProjectPermissionWithDebug>
  );
};
