import { CardContainer, ListItem, Tabs } from "@msys/ui";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { ProjectStateMachineStatus } from "../../../../clients/graphqlTypes.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission.js";
import { useStateWithUrlParam } from "../../../commons/hooks/useStateWithUrlParam.js";
import { getUrlSearchParamsByParamsName } from "../../../commons/hooks/useStateWithUrlParams.js";
import { CreateOpportunityButton } from "../../opportunities/CreateOpportunityButton.js";
import { OpportunityIcon } from "../../opportunities/OpportunityIcon.js";
import { ProjectListItem } from "../../projects/components/ProjectListItem.js";
import { ProjectListItemFragment } from "../../projects/components/ProjectListItem.generated.js";

interface Props {
  crmCompanyId: string;
  opportunitiesCurrent: ProjectListItemFragment[];
  opportunitiesCurrentTotalCount: number;
  opportunitiesPast: ProjectListItemFragment[];
  opportunitiesPastTotalCount: number;
  opportunitiesCurrentStates: ProjectStateMachineStatus[];
  opportunitiesPastStates: ProjectStateMachineStatus[];
  isLoading: boolean;
}

type QuickFilterValue = "CURRENT" | "PAST";

export const CrmCompanyOpportunitiesBox = ({
  crmCompanyId,
  opportunitiesCurrent,
  opportunitiesCurrentTotalCount,
  opportunitiesPast,
  opportunitiesPastTotalCount,
  opportunitiesCurrentStates,
  opportunitiesPastStates,
  isLoading,
}: Props) => {
  const { t } = useTranslate(["Opportunities", "Global"]);
  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "opportunitiesBoxQuickFilter",
    "CURRENT"
  );
  const opportunities =
    quickFilter === "CURRENT"
      ? opportunitiesCurrent
      : quickFilter === "PAST"
        ? opportunitiesPast
        : [];

  const opportunitiesPath = `/opportunities${
    quickFilter === "CURRENT"
      ? "/current"
      : quickFilter === "PAST"
        ? "/past"
        : ""
  }`;

  return (
    <CardContainer
      Icon={<OpportunityIcon />}
      title={t("Opportunities", { ns: "Opportunities" })}
      ActionButton={
        <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
          <CreateOpportunityButton
            type="icon"
            size="small"
            organisationId={crmCompanyId}
            pageName="CrmCompany"
          />
        </RestrictedByOrganisationPermissionWithDebug>
      }
      isLoading={isLoading}
      isExpandable
    >
      <Box p={1}>
        <Tabs
          condensed
          useSelectOnMobile
          options={[
            {
              value: "CURRENT",
              label: t("Current", { ns: "Opportunities" }),
              count: opportunitiesCurrentTotalCount,
            },
            {
              value: "PAST",
              label: t("Past", { ns: "Opportunities" }),
              count: opportunitiesPastTotalCount,
            },
          ]}
          value={quickFilter}
          onChange={(newQuickFilter: QuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
        />
      </Box>
      {opportunities.length === 0 && (
        <Box p={1} py={3}>
          <Typography align="center" sx={{ color: "grey.500" }} variant="body2">
            {t("There are no items to display", { ns: "Global" })}
          </Typography>
        </Box>
      )}
      {opportunities.length > 0 && (
        <Stack width="100%" direction="column" spacing={0}>
          {opportunities.map(project => (
            <ListItem
              key={project.id}
              component={Link}
              //@ts-ignore
              to={`/projects/${project.id}`}
            >
              <ProjectListItem
                project={project}
                showAssignee
                showStatus={
                  quickFilter === "CURRENT"
                    ? opportunitiesCurrentStates.length > 1
                    : quickFilter === "PAST"
                      ? opportunitiesPastStates.length > 1
                      : false
                }
              />
            </ListItem>
          ))}
        </Stack>
      )}
      {opportunities.length > 0 && (
        <Box>
          <Button
            component={Link}
            to={{
              pathname: opportunitiesPath,
              search: getUrlSearchParamsByParamsName(
                { filterByCrmOrganisationId: crmCompanyId },
                "filters"
              ).toString(),
            }}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See all", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </CardContainer>
  );
};
