import { Moment } from "moment";
import React from "react";
import {
  FilterByBoolean,
  FilterByDate,
  FilterByNumber,
  FilterProps,
} from "../../../commons/filters/index.js";
import { useTranslate } from "@tolgee/react";

export {
  FilterProjectCategory,
  FilterProjectCategoryChips,
} from "./ProjectCategory.js";
export { QuoteTemplatesSorting } from "./QuoteTemplatesSorting.js";

export const FilterMinPrice: React.FC<FilterProps<number>> = props => {
  const { t } = useTranslate("Templates");
  return (
    <FilterByNumber
      label={t("Minimum price")}
      placeholder={t("e.g. 10000")}
      helperText={t("in EUR; 0 = any")}
      {...props}
    />
  );
};

export const FilterMaxPrice: React.FC<FilterProps<number>> = props => {
  const { t } = useTranslate("Templates");
  return (
    <FilterByNumber
      label={t("Maximum price")}
      placeholder={t("e.g. 10000")}
      helperText={t("in EUR; 0 = any")}
      {...props}
    />
  );
};

export const FilterCreatedAfter: React.FC<
  FilterProps<Moment | null>
> = props => {
  const { t } = useTranslate("Templates");
  return <FilterByDate label={t("Created after")} {...props} />;
};

export const FilterCreatedBefore: React.FC<
  FilterProps<Moment | null>
> = props => {
  const { t } = useTranslate("Templates");
  return <FilterByDate label={t("Created before")} {...props} />;
};

export const FilterAddedToShop: React.FC<
  FilterProps<boolean | undefined>
> = props => {
  const { t } = useTranslate("Templates");
  return <FilterByBoolean label={t("Added to Shop")} {...props} />;
};
