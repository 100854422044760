/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../features/doc-items/Fragments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ThreeDStart_OrganisationProfileQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;


export type ThreeDStart_OrganisationProfileQuery = { organisationProfile: { __typename: 'OrganisationProfileResult', profile?: { __typename: 'OrganisationProfile', id: string, has3DShop: boolean, organisation: { __typename: 'Organisation', id: string, title: string } } | null } };

export type ThreeDStart_TemplatesListQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
}>;


export type ThreeDStart_TemplatesListQuery = { organisationProfile: { __typename: 'OrganisationProfileResult', profile?: { __typename: 'OrganisationProfile', id: string, shoppableTemplates: { __typename: 'ShoppableTemplateConnection', totalCount: number, edges: Array<{ __typename: 'ShoppableTemplateEdge', node: { __typename: 'ShoppableTemplate', id: string, title: string, description: string, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null } }> } } | null } };

export type ThreeDStart__ShoppableTemplateFragment = { __typename: 'ShoppableTemplate', id: string, title: string, description: string, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null };

export const ThreeDStart__ShoppableTemplateFragmentDoc = gql`
    fragment ThreeDStart__ShoppableTemplate on ShoppableTemplate {
  id
  title
  description
  proposedCalculation {
    ...ItemCalculation
  }
  shopCatalogProductImage {
    id
    url
  }
}
    ${ItemCalculationFragmentDoc}`;
export const ThreeDStart_OrganisationProfileDocument = gql`
    query ThreeDStart_OrganisationProfile($slug: String!) {
  organisationProfile(slug: $slug) {
    ... on OrganisationProfileResult {
      profile {
        id
        has3DShop
        organisation {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useThreeDStart_OrganisationProfileQuery__
 *
 * To run a query within a React component, call `useThreeDStart_OrganisationProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDStart_OrganisationProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDStart_OrganisationProfileQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useThreeDStart_OrganisationProfileQuery(baseOptions: Apollo.QueryHookOptions<ThreeDStart_OrganisationProfileQuery, ThreeDStart_OrganisationProfileQueryVariables> & ({ variables: ThreeDStart_OrganisationProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDStart_OrganisationProfileQuery, ThreeDStart_OrganisationProfileQueryVariables>(ThreeDStart_OrganisationProfileDocument, options);
      }
export function useThreeDStart_OrganisationProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDStart_OrganisationProfileQuery, ThreeDStart_OrganisationProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDStart_OrganisationProfileQuery, ThreeDStart_OrganisationProfileQueryVariables>(ThreeDStart_OrganisationProfileDocument, options);
        }
export function useThreeDStart_OrganisationProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ThreeDStart_OrganisationProfileQuery, ThreeDStart_OrganisationProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThreeDStart_OrganisationProfileQuery, ThreeDStart_OrganisationProfileQueryVariables>(ThreeDStart_OrganisationProfileDocument, options);
        }
export type ThreeDStart_OrganisationProfileQueryHookResult = ReturnType<typeof useThreeDStart_OrganisationProfileQuery>;
export type ThreeDStart_OrganisationProfileLazyQueryHookResult = ReturnType<typeof useThreeDStart_OrganisationProfileLazyQuery>;
export type ThreeDStart_OrganisationProfileSuspenseQueryHookResult = ReturnType<typeof useThreeDStart_OrganisationProfileSuspenseQuery>;
export type ThreeDStart_OrganisationProfileQueryResult = Apollo.QueryResult<ThreeDStart_OrganisationProfileQuery, ThreeDStart_OrganisationProfileQueryVariables>;
export const ThreeDStart_TemplatesListDocument = gql`
    query ThreeDStart_TemplatesList($slug: String!, $offset: Int, $limit: Int!) {
  organisationProfile(slug: $slug) {
    ... on OrganisationProfileResult {
      profile {
        id
        shoppableTemplates(offset: $offset, limit: $limit, filters: {has3dShape: true}) {
          edges {
            node {
              id
              ...ThreeDStart__ShoppableTemplate
            }
          }
          totalCount
        }
      }
    }
  }
}
    ${ThreeDStart__ShoppableTemplateFragmentDoc}`;

/**
 * __useThreeDStart_TemplatesListQuery__
 *
 * To run a query within a React component, call `useThreeDStart_TemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDStart_TemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDStart_TemplatesListQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useThreeDStart_TemplatesListQuery(baseOptions: Apollo.QueryHookOptions<ThreeDStart_TemplatesListQuery, ThreeDStart_TemplatesListQueryVariables> & ({ variables: ThreeDStart_TemplatesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDStart_TemplatesListQuery, ThreeDStart_TemplatesListQueryVariables>(ThreeDStart_TemplatesListDocument, options);
      }
export function useThreeDStart_TemplatesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDStart_TemplatesListQuery, ThreeDStart_TemplatesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDStart_TemplatesListQuery, ThreeDStart_TemplatesListQueryVariables>(ThreeDStart_TemplatesListDocument, options);
        }
export function useThreeDStart_TemplatesListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ThreeDStart_TemplatesListQuery, ThreeDStart_TemplatesListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThreeDStart_TemplatesListQuery, ThreeDStart_TemplatesListQueryVariables>(ThreeDStart_TemplatesListDocument, options);
        }
export type ThreeDStart_TemplatesListQueryHookResult = ReturnType<typeof useThreeDStart_TemplatesListQuery>;
export type ThreeDStart_TemplatesListLazyQueryHookResult = ReturnType<typeof useThreeDStart_TemplatesListLazyQuery>;
export type ThreeDStart_TemplatesListSuspenseQueryHookResult = ReturnType<typeof useThreeDStart_TemplatesListSuspenseQuery>;
export type ThreeDStart_TemplatesListQueryResult = Apollo.QueryResult<ThreeDStart_TemplatesListQuery, ThreeDStart_TemplatesListQueryVariables>;