import { gql } from "@apollo/client";
import { getPictures } from "@msys/common";
import {
  CollapseSection,
  isImageOr3dModel,
  Modal,
  ModalOpenButton,
  processAttachment,
  RichTextValue,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import { Check as CheckIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isUndefined } from "lodash-es";
import React, { CSSProperties } from "react";
import { PreviousAndNextButtons } from "../../../commons/button/PreviousAndNextButtons.js";
import { PictureGallery } from "../../../commons/images/PictureGallery.js";
import { PropertiesLabeledValue } from "../../../commons/PropertiesLabeledValue.js";
import { cssValue } from "../../../../common/MuiThemeProvider.js";
import { ArticleTeaserItem } from "../../contents/ArticleTeaserItem.js";
import { ContentsModal } from "../../contents/ContentsModal.js";
import { useQuoteItemProperties } from "../../item-properties/itemProperties.js";
import { isItemGreyedOut } from "../helpers.js";
import {
  DecisionOptionButtons_ItemFragment,
  DecisionOptionButtons_ParentItemFragment,
  DecisionOptionDescription_ItemFragment,
  DecisionOptionModal_DecisionItemFragment,
  DecisionOptionModal_OptionItemFragment,
  DecisionRestoreButton_ItemFragment,
} from "./DecisionOptionModal.generated.js";

interface Props
  extends React.ComponentProps<typeof DecisionOptionModalDialogContent> {
  optionItems: DecisionOptionModal_OptionItemFragment[];
  optionItemId: string;
  loading?: boolean;
  onSelectItem(itemId: string | null): void;
  handleClose(): void;
  isEditable?: boolean;
}

export const DecisionOptionModal = ({
  handleClose,
  onSelectItem,
  isEditable = true,
  ...props
}: Props) => {
  const { t } = useTranslate(["Decisions", "Global"]);

  const { optionItems, optionItemId, loading } = props;

  const item = optionItems.find(i => i.id === optionItemId);

  // select next item when current item is being removed (eg. discarded)
  const lastItemIndexRef = React.useRef<number | undefined>(undefined);
  React.useEffect(() => {
    if (item) {
      // save last index
      lastItemIndexRef.current = optionItems.indexOf(item);
    } else {
      // try to show next item after removal
      if (optionItems.length > 0) {
        if (
          lastItemIndexRef.current !== undefined &&
          lastItemIndexRef.current < optionItems.length
        ) {
          onSelectItem(optionItems[lastItemIndexRef.current]!.id);
        } else {
          onSelectItem(optionItems[0]!.id);
        }
      } else {
        onSelectItem(null);
      }
    }
  }, [item, optionItems, onSelectItem]);

  if (!item) return null;

  return (
    <Modal
      fixedHeight
      maxWidth="sm"
      handleClose={handleClose}
      title={item.title}
      dialogActions={
        <DialogActions>
          <PreviousAndNextButtons
            onPreviousClick={
              optionItems.length <= 1
                ? null
                : () => {
                    const newIndex = (lastItemIndexRef.current || 0) - 1;
                    if (newIndex >= 0) {
                      onSelectItem(optionItems[newIndex]!.id);
                    } else {
                      onSelectItem(optionItems[optionItems.length - 1]!.id);
                    }
                  }
            }
            onNextClick={
              optionItems.length <= 1
                ? null
                : () => {
                    const newIndex = (lastItemIndexRef.current || 0) + 1;
                    if (newIndex < optionItems.length) {
                      onSelectItem(optionItems[newIndex]!.id);
                    } else {
                      onSelectItem(optionItems[0]!.id);
                    }
                  }
            }
          />
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              onClick={handleClose}
              variant="text"
              color="primary"
              disabled={loading}
            >
              {t("Close", {
                ns: "Global",
              })}
            </Button>
          </Stack>
        </DialogActions>
      }
    >
      <DecisionOptionModalDialogContent
        {...props}
        disabled={!isEditable}
        containerStyle={{
          ...(item.decisionOptionIsPreselected
            ? {
                boxShadow: cssValue.preselectedBorderShadow,
                borderColor: "transparent",
                paddingTop: "2px",
                paddingBottom: "2px",
              }
            : undefined),
        }}
      />
    </Modal>
  );
};

export function DecisionOptionModalDialogContent({
  decisionItem,
  optionItems,
  optionItemId,
  disabled,
  loading,
  handleDecisionPreselectionChange,
  eliminateDecisionOption,
  resetEliminateDecisionOption,
  hideDiscard,
  showItemTitle,
  containerStyle,
}: {
  decisionItem: DecisionOptionModal_DecisionItemFragment;
  optionItems: DecisionOptionModal_OptionItemFragment[];
  optionItemId: string;
  disabled?: boolean;
  loading?: boolean;
  handleDecisionPreselectionChange: (
    itemId: string,
    subItemIds: string[]
  ) => void | Promise<void>;
  eliminateDecisionOption: (itemId: string) => Promise<void>;
  resetEliminateDecisionOption: (itemId: string) => Promise<void>;
  hideDiscard?: boolean;
} & Pick<
  React.ComponentProps<typeof DecisionOptionDescription>,
  "showItemTitle" | "containerStyle"
>) {
  const { isMinTablet } = useScreenWidth();

  const item = optionItems.find(i => i.id === optionItemId);

  if (!item) return null;

  const articleTeaser = item.contents[0];

  return (
    <>
      <Stack direction="column" spacing={2} overflow="hidden" height="100%">
        {articleTeaser && (
          <Box maxWidth="400px">
            <ModalOpenButton
              Modal={ContentsModal}
              modalProps={{
                itemId: item.id,
                content: item.contents,
                canEdit: false,
              }}
            >
              <ArticleTeaserItem
                title={articleTeaser.title}
                group={articleTeaser.group}
                previewImageUrl={articleTeaser.previewImageUrl}
              />
            </ModalOpenButton>
          </Box>
        )}

        <DecisionOptionDescription
          columns={isMinTablet ? 4 : 2}
          item={item}
          Button={
            item.decisionOptionElimination ? (
              <DecisionRestoreButton
                disabled={disabled}
                item={item}
                loading={loading}
                resetEliminateDecisionOption={resetEliminateDecisionOption}
              />
            ) : (
              <DecisionOptionButtons
                type={isMinTablet ? "button" : "icon"}
                parentItem={decisionItem}
                item={item}
                loading={loading}
                disabled={disabled}
                handleDecisionPreselectionChange={
                  handleDecisionPreselectionChange
                }
                eliminateDecisionOption={eliminateDecisionOption}
                resetEliminateDecisionOption={resetEliminateDecisionOption}
                hideDiscard={hideDiscard}
              />
            )
          }
          showItemTitle={showItemTitle}
          containerStyle={containerStyle}
        />
      </Stack>
    </>
  );
}

const DecisionOptionDescription = ({
  item,
  columns = 4,
  Button,
  containerStyle,
  showItemTitle = false,
}: {
  item: DecisionOptionDescription_ItemFragment;
  columns?: 1 | 2 | 3 | 4 | 6 | 12;
  Button: React.ReactNode;
  containerStyle?: CSSProperties;
  showItemTitle?: boolean;
}) => {
  const { t } = useTranslate(["Global"]);
  const { isMinTablet } = useScreenWidth();
  const theme = useTheme();
  const { getFormattedPrice } = useFormatting();

  const [selfProperties, productProperties] = useQuoteItemProperties(item);

  const properties = [...selfProperties, ...productProperties];

  const title = !isUndefined(item.pendingChangeAttributes["title"])
    ? item.pendingChangeAttributes["title"]
    : item.title;

  const pathForPdf = !isUndefined(item.pendingChangeAttributes["pathForPdf"])
    ? item.pendingChangeAttributes["pathForPdf"]
    : item.pathForPdf;

  const description = !isUndefined(item.pendingChangeAttributes["description"])
    ? item.pendingChangeAttributes["description"]
    : item.description;

  const productDescription = !isUndefined(
    item.pendingChangeAttributes["productDescription"]
  )
    ? item.pendingChangeAttributes.productDescriptionClientVisibility !== "hide"
      ? item.pendingChangeAttributes.productDescription
      : null
    : item.product && item.product.descriptionClientVisibility !== "hide"
      ? item.product.description
      : null;

  const productTitle = !isUndefined(
    item.pendingChangeAttributes["productTitle"]
  )
    ? item.pendingChangeAttributes.productTitleClientVisibility !== "hide"
      ? item.pendingChangeAttributes.productTitle
      : null
    : item.product && item.product.titleClientVisibility !== "hide"
      ? item.product.title
      : null;

  const pictures = item.attachments
    .map(processAttachment)
    .filter(isImageOr3dModel);

  const priceSubTotal = item.proposedCalculation?.priceSubTotal || 0;

  const isGreyedOut = isItemGreyedOut(item);
  const isEliminated = !!item.decisionOptionElimination;

  return (
    <Paper
      sx={theme => ({
        textAlign: "left",
        px: 1,
        borderRadius: "4px",
        width: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        ...containerStyle,
        ...getContainerStyle({
          theme,
          isGreyedOut,
          isEliminated,
        }),
      })}
    >
      {(showItemTitle && title) ||
      pictures.length > 0 ||
      description ||
      productTitle ||
      productDescription ||
      properties.length > 0 ? (
        <Stack
          direction={"column"}
          justifyContent="flex-start"
          spacing={1}
          width="100%"
          flex={1}
          pt={1}
        >
          {showItemTitle && <Typography variant="h2">{title}</Typography>}

          {pictures.length > 0 && (
            <PictureGallery
              pictures={pictures}
              layout="cols"
              showDelete={false}
              showAdd={false}
              showRotate={false}
              showUpload={false}
              height={
                isMinTablet
                  ? theme.layout.galleryHeight.md
                  : theme.layout.galleryHeight.sm
              }
              useSlider={false}
            />
          )}

          {description && (
            <Box>
              <RichTextValue htmlContent={description} />
            </Box>
          )}

          {productTitle && (
            <Typography variant={"h3"}>{productTitle}</Typography>
          )}

          {productDescription && (
            <Box>
              <RichTextValue htmlContent={productDescription} />
            </Box>
          )}

          {properties.length > 0 ? (
            <CollapseSection
              isInitiallyExpanded={true}
              title={t("Properties", {
                ns: "Global",
              })}
              itemCount={properties.length}
            >
              <PropertiesLabeledValue
                properties={properties}
                columns={columns}
                collapsedLines={isMinTablet ? 2 : 3}
              />
            </CollapseSection>
          ) : null}
        </Stack>
      ) : null}
      <Stack
        direction="row"
        minWidth={0}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        bottom={0}
        py={1}
        sx={{
          backgroundColor: getContainerBackgroundColor({ theme, isGreyedOut }),
        }}
        zIndex={10}
      >
        <Box>
          {priceSubTotal > 0 && (
            <Typography variant="h2" component="div" fontWeight="bold">
              {getFormattedPrice(priceSubTotal)}
            </Typography>
          )}
        </Box>
        <Box>{Button}</Box>
      </Stack>
    </Paper>
  );
};

const getContainerStyle = ({
  theme,
  isGreyedOut,
  isEliminated,
}: {
  theme: Theme;
  isGreyedOut: boolean;
  isEliminated: boolean;
}) => ({
  backgroundColor: getContainerBackgroundColor({ theme, isGreyedOut }),
  color: isEliminated ? theme.palette.grey[600] : "inherit",
});

const getContainerBackgroundColor = ({
  theme,
  isGreyedOut,
}: {
  theme: Theme;
  isGreyedOut: boolean;
}) => (isGreyedOut ? theme.palette.grey[100] : theme.palette.common.white);

const DecisionRestoreButton = ({
  item,
  disabled,
  loading,
  resetEliminateDecisionOption,
  hideDiscard,
}: {
  item: DecisionRestoreButton_ItemFragment;
  disabled?: boolean;
  loading?: boolean;
  resetEliminateDecisionOption: (itemId: string) => Promise<void>;
  hideDiscard?: boolean;
}) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);

  const isEliminated = !!item.decisionOptionElimination;

  if (!isEliminated || hideDiscard) return null;

  return (
    <Button
      size="medium"
      color="secondary"
      variant={"outlined"}
      disabled={loading || disabled}
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();
        await resetEliminateDecisionOption(item.id);
      }}
    >
      {t("Restore", {
        ns: "QuoteItem",
      })}
    </Button>
  );
};

const DecisionOptionButtons = ({
  type,
  parentItem,
  item,
  disabled,
  loading,
  handleDecisionPreselectionChange,
  eliminateDecisionOption,
  resetEliminateDecisionOption,
  hideDiscard,
}: {
  type: "icon" | "button";
  parentItem: DecisionOptionButtons_ParentItemFragment;
  item: DecisionOptionButtons_ItemFragment;
  disabled?: boolean;
  loading?: boolean;
  handleDecisionPreselectionChange: (
    itemId: string,
    subItemIds: string[]
  ) => void | Promise<void>;
  eliminateDecisionOption: (itemId: string) => Promise<void>;
  resetEliminateDecisionOption: (itemId: string) => Promise<void>;
  hideDiscard?: boolean;
}) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);

  const isPreselected = parentItem.decisionSubitemsPreselection.includes(
    item.id
  );
  const isEliminated = !!item.decisionOptionElimination;

  const handlePreselectionClick = async () => {
    if (parentItem.decisionBehaviorOfSubitems === "SELECT_ONE") {
      if (isPreselected) {
        await handleDecisionPreselectionChange(parentItem.id, []);
      } else {
        await handleDecisionPreselectionChange(parentItem.id, [item.id]);
      }
    } else if (parentItem.decisionBehaviorOfSubitems === "SELECT_MANY") {
      if (isPreselected) {
        await handleDecisionPreselectionChange(
          parentItem.id,
          parentItem.decisionSubitemsPreselection.filter(id => id !== item.id)
        );
      } else {
        await handleDecisionPreselectionChange(parentItem.id, [
          ...parentItem.decisionSubitemsPreselection,
          item.id,
        ]);
      }
    }
  };

  const SelectButton = (
    <Button
      size="medium"
      color="success"
      variant={isPreselected ? "contained" : "outlined"}
      disabled={loading || disabled || isEliminated}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        handlePreselectionClick();
      }}
      startIcon={
        type === "icon" ? (
          <Tooltip
            title={
              isPreselected
                ? t("Unselect", {
                    ns: "Global",
                  })
                : t("Select", {
                    ns: "Global",
                  })
            }
          >
            <CheckIcon />
          </Tooltip>
        ) : (
          <CheckIcon />
        )
      }
      sx={type === "icon" ? getButtonIconStyle : undefined}
    >
      {type === "button"
        ? isPreselected
          ? t("Selected", {
              ns: "Global",
            })
          : t("Select", {
              ns: "Global",
            })
        : ""}
    </Button>
  );

  const DiscardButton = !hideDiscard ? (
    <Button
      size="medium"
      color="error"
      variant={isEliminated ? "contained" : "outlined"}
      disabled={loading || disabled}
      startIcon={
        type === "icon" ? (
          <Tooltip
            title={
              isEliminated
                ? t("Restore", {
                    ns: "QuoteItem",
                  })
                : t("Discard", {
                    ns: "QuoteItem",
                  })
            }
          >
            <CloseIcon />
          </Tooltip>
        ) : (
          <DeleteOutlineIcon />
        )
      }
      sx={type === "icon" ? getButtonIconStyle : undefined}
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();
        if (isEliminated) {
          await resetEliminateDecisionOption(item.id);
        } else {
          await eliminateDecisionOption(item.id);
        }
      }}
    >
      {type === "button"
        ? isEliminated
          ? t("Discarded", {
              ns: "QuoteItem",
            })
          : t("Discard", {
              ns: "QuoteItem",
            })
        : ""}
    </Button>
  ) : null;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {DiscardButton}
      {SelectButton}
    </Stack>
  );
};

const getButtonIconStyle = (theme: Theme) => ({
  minWidth: "auto",
  padding: "7px 7px",
  borderRadius: "50%",
  [".MuiButton-startIcon"]: {
    marginLeft: 0,
    marginRight: 0,
  },
  [".MuiSvgIcon-root"]: {
    fontSize: "1.5rem",
  },
  boxShadow: (theme.shadows as string[])[1],
});
