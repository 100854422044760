import { gql } from "@apollo/client";
import {
  ellipsisStyle,
  FormattedPrice,
  StatusLabel,
  useFormatting,
} from "@msys/ui";
import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import React from "react";
import {
  ActionLine,
  DeadlineDateLine,
  DocumentLine,
  EarliestStartDateLine,
} from "../../commons/DataItem.js";
import {
  IncomingRequestListItemFragment,
  OutgoingRequestListItemFragment,
} from "./RequestListItem.generated.js";
import { RequestStatusBadge } from "./RequestStatusBadge.js";

export const IncomingRequestListItem = ({
  request,
  Action,
}: {
  request: IncomingRequestListItemFragment;
  Action?: React.ReactElement;
}) => {
  const { t } = useTranslate(["DataItem", "RequestOverview"]);
  const theme = useTheme();
  const { getFormattedDate } = useFormatting();

  const deadlineDate = request.deadline
    ? getFormattedDate(request.deadline)
    : undefined;
  const earliestStartDate = request.earliestStart
    ? getFormattedDate(request.earliestStart)
    : undefined;

  const overdue = request.deadline
    ? moment(request.deadline).endOf("day").valueOf() < moment().valueOf()
    : undefined;

  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>{request.title}</span>
          <span
            style={{
              display: "inline-flex",
              marginLeft: "8px",
              verticalAlign: "middle",
            }}
          >
            <RequestStatusBadge request={request} small />
          </span>
          {request.urgent && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              <StatusLabel small color={theme.palette.warning.main}>
                {t("Urgent", { ns: "DataItem" })}
              </StatusLabel>
            </span>
          )}
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
        style={ellipsisStyle}
      >
        <Box
          gap={0.5}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          <DocumentLine>
            {t(
              "{count, plural, =0 {No submitted quotes} one {One submitted quote} other {# submitted quotes}}",
              {
                ns: "RequestOverview",
                count: request.viewerRecipientNumPublishedQuotes,
              }
            )}
          </DocumentLine>
          {earliestStartDate && (
            <Tooltip
              title={t("Earliest start", { ns: "DataItem" })}
              arrow
              placement="bottom"
            >
              <div>
                <EarliestStartDateLine>
                  {earliestStartDate}
                </EarliestStartDateLine>
              </div>
            </Tooltip>
          )}
          {deadlineDate && (
            <Tooltip
              title={t("Deadline", { ns: "DataItem" })}
              arrow
              placement="bottom"
            >
              <div>
                <DeadlineDateLine overdue={overdue}>
                  {deadlineDate}
                </DeadlineDateLine>
              </div>
            </Tooltip>
          )}
        </Box>

        {request.budget ? (
          <Typography
            variant={"body2"}
            style={{
              float: "right",
              alignItems: "flex-end",
              display: "flex",
              shapeOutside: "inset(calc(100% - 20px) 0 0)",
              marginBottom: "-4px",
            }}
            component="div"
          >
            <FormattedPrice value={request.budget} />
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

export const OutgoingRequestListItem = ({
  request,
  Action,
}: {
  request: OutgoingRequestListItemFragment;
  Action?: React.ReactElement;
}) => {
  const { t } = useTranslate(["DataItem", "ProjectOverview"]);
  const theme = useTheme();
  const { getFormattedDate } = useFormatting();

  const deadlineDate = request.deadline
    ? getFormattedDate(request.deadline)
    : undefined;
  const earliestStartDate = request.earliestStart
    ? getFormattedDate(request.earliestStart)
    : undefined;

  const overdue = request.deadline
    ? moment(request.deadline).endOf("day").valueOf() < moment().valueOf()
    : undefined;

  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>{request.title}</span>
          <span
            style={{
              display: "inline-flex",
              marginLeft: "8px",
              verticalAlign: "middle",
            }}
          >
            <RequestStatusBadge request={request} small />
          </span>
          {request.urgent && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              <StatusLabel small color={theme.palette.warning.main}>
                {t("Urgent", { ns: "DataItem" })}
              </StatusLabel>
            </span>
          )}
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
        style={ellipsisStyle}
      >
        <Box
          gap={0.5}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          <DocumentLine>
            {t(
              "{count, plural, =0 {No submissions} one {One submission} other {# submissions}}",
              {
                ns: "ProjectOverview",
                count: request.quotes.length,
              }
            )}
          </DocumentLine>
          {earliestStartDate && (
            <Tooltip
              title={t("Earliest start", { ns: "DataItem" })}
              arrow
              placement="bottom"
            >
              <div>
                <EarliestStartDateLine>
                  {earliestStartDate}
                </EarliestStartDateLine>
              </div>
            </Tooltip>
          )}
          {deadlineDate && (
            <Tooltip
              title={t("Deadline", { ns: "DataItem" })}
              arrow
              placement="bottom"
            >
              <div>
                <DeadlineDateLine overdue={overdue}>
                  {deadlineDate}
                </DeadlineDateLine>
              </div>
            </Tooltip>
          )}
        </Box>

        {request.budget ? (
          <Typography
            variant={"body2"}
            style={{
              float: "right",
              alignItems: "flex-end",
              display: "flex",
              shapeOutside: "inset(calc(100% - 20px) 0 0)",
              marginBottom: "-4px",
            }}
            component="div"
          >
            <FormattedPrice value={request.budget} />
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};
