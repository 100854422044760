import {
  EmailTextReplacements,
  ProjectEmailTextReplacements,
  RequirementEmailTextReplacements,
} from "@msys/textutils";
import { Modal } from "@msys/ui";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { EmailTemplateContext } from "../../../../clients/graphqlTypes.js";

interface Props {
  context?: EmailTemplateContext;
  handleClose(): void;
}

export function OrganisationEmailTemplateAvailableReplacementsModal({
  context,
  handleClose,
}: Props) {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const emailReplacements: EmailTextReplacements = {
    "{{client name}}": t(
      'The first and last name of the client, e.g. "John Smith"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{client firstname}}": t('The first name of the client, e.g. "John"', {
      ns: "OrganisationSettings",
    }),
    "{{client lastname}}": t('The last name of the client, e.g. "Smith"', {
      ns: "OrganisationSettings",
    }),
    "{{client salutation}}": t('Salutation, e.g. "Mr"', {
      ns: "OrganisationSettings",
    }),
    "{{client email address}}": t("Email address of the client", {
      ns: "OrganisationSettings",
    }),
    "{{client phone number}}": t("Phone number of the client", {
      ns: "OrganisationSettings",
    }),
    "{{organisation name}}": t("The name of your organisation", {
      ns: "OrganisationSettings",
    }),
    "{{your name}}": t('Your full name, e.g. "John Doe"', {
      ns: "OrganisationSettings",
    }),
  };
  const projectEmailReplacements: ProjectEmailTextReplacements = {
    ...emailReplacements,
    "{{project contractee input page url}}": t(
      "URL to the page for providing project info & uploading images",
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{project building address}}": t("Address of the project building", {
      ns: "OrganisationSettings",
    }),
    "{{project lead id}}": t("Lead identifier of the project", {
      ns: "OrganisationSettings",
    }),
  };
  const requirementEmailReplacements: RequirementEmailTextReplacements = {
    ...emailReplacements,
    "{{requirement contractee input page url}}": t(
      "URL to the page for providing project info & uploading images",
      {
        ns: "OrganisationSettings",
      }
    ),
  };

  return (
    <Modal
      title={t("Using placeholders in texts", {
        ns: "OrganisationSettings",
      })}
      handleClose={handleClose}
      notInStack
      actionButtons={[
        {
          label: t("Close", {
            ns: "Global",
          }),
          handleClick: handleClose,
        },
      ]}
      maxWidth="md"
    >
      <Typography variant="body1" gutterBottom>
        {t(
          "You can use placeholders to fill in information automatically in your emails.",
          {
            ns: "OrganisationSettings",
          }
        )}
      </Typography>
      <Typography variant="body1">
        {t(
          "The following placeholders are available for use in the subject and text of the emails.",
          {
            ns: "OrganisationSettings",
          }
        )}
      </Typography>
      <Box pt={2}>
        <Typography variant="h4" gutterBottom>
          {t("For Emails:", {
            ns: "OrganisationSettings",
          })}
        </Typography>
        <Table size="small">
          <colgroup>
            <col width="35%" />
            <col width="65%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                {t("Placeholder", {
                  ns: "OrganisationSettings",
                })}
              </TableCell>
              <TableCell>
                {t("Description", {
                  ns: "OrganisationSettings",
                })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(
              context === "PROJECT"
                ? projectEmailReplacements
                : context === "REQUIREMENT"
                  ? requirementEmailReplacements
                  : emailReplacements
            )
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([name, description], index) => (
                <TableRow key={index}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{description}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Modal>
  );
}
