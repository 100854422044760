import { gql, useApolloClient } from "@apollo/client";
import { MenuItemWithIcon } from "@msys/ui";
import { Replay as ReplayIcon } from "@mui/icons-material";
import {
  useHeaderBox_RunNonProjectDocumentAttributeExpressionsMutation,
  useHeaderBox_RunProjectDocumentAttributeExpressionsMutation,
} from "./RunAttributeExpressionsMenuItem.generated.js";

interface Props {
  projectId: string | null;
  docId: string;
}

export function RunAttributeExpressionsMenuItem({ projectId, docId }: Props) {
  const client = useApolloClient();

  const [runProjectDocumentAttributeExpressions] =
    useHeaderBox_RunProjectDocumentAttributeExpressionsMutation({ client });

  const [runNonProjectDocumentAttributeExpressions] =
    useHeaderBox_RunNonProjectDocumentAttributeExpressionsMutation({ client });

  const handleClick = async () => {
    if (projectId) {
      await runProjectDocumentAttributeExpressions({
        variables: {
          input: {
            projectId,
            docId,
          },
        },
      });
    } else {
      await runNonProjectDocumentAttributeExpressions({
        variables: {
          input: {
            docId,
          },
        },
      });
    }
    await client.reFetchObservableQueries();
  };

  return (
    <MenuItemWithIcon icon={<ReplayIcon />} onClick={handleClick}>
      Re-run all expressions in document
    </MenuItemWithIcon>
  );
}
