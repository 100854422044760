import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, DatePicker, Select } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import { useMemo, useState } from "react";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { browserHasInputDate } from "../../../featureDetection.js";
import { TasksTimesheetForm } from "../components/TasksTimesheetForm.js";
import { getAssigneeOptions } from "../helpers.js";
import {
  TasksTimesheetBox_ItemFragment,
  useTasksTimesheetBoxQuery,
} from "./TasksTimesheetBox.generated.js";

interface Props {
  selectedItemId?: string;
  projectId: string;
  tasksItems: TasksTimesheetBox_ItemFragment[];
}

export const TasksTimesheetBox = ({
  selectedItemId,
  projectId,
  tasksItems,
}: Props) => {
  const { t } = useTranslate("WorkSessions");

  const client = useApolloClient();
  const viewer = useUserData().currentUser!;
  const now = useMemo(() => moment(), []);

  const [assigneeId, setAssigneeId] = useState<string>(viewer.id);
  const [date, setDate] = useState<moment.Moment>(moment(now).startOf("day"));

  const query = useTasksTimesheetBoxQuery({
    client,
    variables: {
      projectId,
    },
    fetchPolicy: "network-only",
  });

  const project = getDataOrNull(query.data?.project)?.project;

  if (!project) return null;

  const filteredTasksItems = tasksItems.filter(
    i => i.canBeWorkedOn && !i.deletedAt
  );

  return (
    <CardContainer isExpandable title={t("Timesheet")}>
      <Stack p={1} width="100%" flexDirection="column">
        <DatePicker
          browserHasInputDate={browserHasInputDate}
          label={t("Date")}
          value={date}
          disableFuture
          onChange={(e, newValue) => {
            if (newValue) setDate(newValue);
          }}
        />
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_PROJECT"
          project={project}
        >
          <Select
            label={t("Team member")}
            value={assigneeId}
            options={getAssigneeOptions(project)}
            onChange={value => {
              setAssigneeId(value);
            }}
          />
        </RestrictedByProjectPermissionWithDebug>
        <TasksTimesheetForm
          selectedItemId={selectedItemId}
          projectId={projectId}
          assigneeId={assigneeId}
          date={date}
          tasksItems={filteredTasksItems}
        />
      </Stack>
    </CardContainer>
  );
};
