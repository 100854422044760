/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteTemplatePublishButtonQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type QuoteTemplatePublishButtonQuery = { quoteTemplateLatest: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'QuoteTemplateLatestResult', quoteTemplate?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, linkedQuoteTemplate?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null } | null }> } | null } };

export type PublishQuoteTemplateMutationVariables = Types.Exact<{
  input: Types.PublishTemplateVersionInput;
}>;


export type PublishQuoteTemplateMutation = { publishTemplateVersion: { __typename: 'PublishTemplateVersionResultSuccess', quoteTemplate: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, latestPublishedVersionNumber?: number | null, hasAnyPublishedVersion: boolean, containsUnpublishedChanged: boolean } } | { __typename: 'PublishTemplateVersionResultValidationError', errorMessage: string } };


export const QuoteTemplatePublishButtonDocument = gql`
    query QuoteTemplatePublishButton($id: ID!) {
  quoteTemplateLatest(id: $id) {
    ... on QuoteTemplateLatestResult {
      quoteTemplate {
        id
        resolvedAsReadModelVersionNumber
        items {
          id
          originVersionNumber
          linkedQuoteTemplate {
            id
            resolvedAsReadModelVersionNumber
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuoteTemplatePublishButtonQuery__
 *
 * To run a query within a React component, call `useQuoteTemplatePublishButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteTemplatePublishButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteTemplatePublishButtonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuoteTemplatePublishButtonQuery(baseOptions: Apollo.QueryHookOptions<QuoteTemplatePublishButtonQuery, QuoteTemplatePublishButtonQueryVariables> & ({ variables: QuoteTemplatePublishButtonQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteTemplatePublishButtonQuery, QuoteTemplatePublishButtonQueryVariables>(QuoteTemplatePublishButtonDocument, options);
      }
export function useQuoteTemplatePublishButtonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteTemplatePublishButtonQuery, QuoteTemplatePublishButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteTemplatePublishButtonQuery, QuoteTemplatePublishButtonQueryVariables>(QuoteTemplatePublishButtonDocument, options);
        }
export function useQuoteTemplatePublishButtonSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuoteTemplatePublishButtonQuery, QuoteTemplatePublishButtonQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuoteTemplatePublishButtonQuery, QuoteTemplatePublishButtonQueryVariables>(QuoteTemplatePublishButtonDocument, options);
        }
export type QuoteTemplatePublishButtonQueryHookResult = ReturnType<typeof useQuoteTemplatePublishButtonQuery>;
export type QuoteTemplatePublishButtonLazyQueryHookResult = ReturnType<typeof useQuoteTemplatePublishButtonLazyQuery>;
export type QuoteTemplatePublishButtonSuspenseQueryHookResult = ReturnType<typeof useQuoteTemplatePublishButtonSuspenseQuery>;
export type QuoteTemplatePublishButtonQueryResult = Apollo.QueryResult<QuoteTemplatePublishButtonQuery, QuoteTemplatePublishButtonQueryVariables>;
export const PublishQuoteTemplateDocument = gql`
    mutation PublishQuoteTemplate($input: PublishTemplateVersionInput!) {
  publishTemplateVersion(input: $input) {
    ... on PublishTemplateVersionResultSuccess {
      quoteTemplate {
        id
        resolvedAsReadModelVersionNumber
        latestPublishedVersionNumber
        hasAnyPublishedVersion
        containsUnpublishedChanged
      }
    }
    ... on PublishTemplateVersionResultValidationError {
      errorMessage
    }
  }
}
    `;
export type PublishQuoteTemplateMutationFn = Apollo.MutationFunction<PublishQuoteTemplateMutation, PublishQuoteTemplateMutationVariables>;

/**
 * __usePublishQuoteTemplateMutation__
 *
 * To run a mutation, you first call `usePublishQuoteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishQuoteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishQuoteTemplateMutation, { data, loading, error }] = usePublishQuoteTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishQuoteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<PublishQuoteTemplateMutation, PublishQuoteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishQuoteTemplateMutation, PublishQuoteTemplateMutationVariables>(PublishQuoteTemplateDocument, options);
      }
export type PublishQuoteTemplateMutationHookResult = ReturnType<typeof usePublishQuoteTemplateMutation>;
export type PublishQuoteTemplateMutationResult = Apollo.MutationResult<PublishQuoteTemplateMutation>;
export type PublishQuoteTemplateMutationOptions = Apollo.BaseMutationOptions<PublishQuoteTemplateMutation, PublishQuoteTemplateMutationVariables>;