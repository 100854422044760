/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { ChannelMessages__ChannelFragmentDoc, ChannelMessages__MessageFragmentDoc } from './ChannelMessages.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelQueryVariables = Types.Exact<{
  channelId: Types.Scalars['ID']['input'];
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  after?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type ChannelQuery = { channel?: { __typename: 'Channel', id: string, messagesSinceLastRead: number, messages: { __typename: 'MessageConnection', pageInfo: { __typename: 'PageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename: 'MessageEdge', node: { __typename: 'Message', id: string, createdAt: any, text: string, extraAttributes: any, author?: { __typename: 'User', id: string, title: Types.Salutation, firstname: string, familyname: string } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, quote?: { __typename: 'Quote', id: string, projectId: string, title: string } | null, project?: { __typename: 'Project', id: string, title: string } | null, invoice?: { __typename: 'Invoice', id: string, title: string, file?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null } | null } }> } } | null };

export type SendChannelMessageMutationVariables = Types.Exact<{
  channelId: Types.Scalars['ID']['input'];
  text: Types.Scalars['String']['input'];
  attachment?: Types.InputMaybe<Types.AttachmentInput>;
  attachments?: Types.InputMaybe<Array<Types.AttachmentInput>>;
}>;


export type SendChannelMessageMutation = { sendChannelMessage?: { __typename: 'SendChannelMessageResult', message: { __typename: 'Message', id: string } } | null };


export const ChannelDocument = gql`
    query Channel($channelId: ID!, $last: Int, $first: Int, $before: ID, $after: ID) {
  channel(id: $channelId) {
    id
    ...ChannelMessages__Channel
    messages(last: $last, first: $first, before: $before, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ...ChannelMessages__Message
        }
      }
    }
  }
}
    ${ChannelMessages__ChannelFragmentDoc}
${ChannelMessages__MessageFragmentDoc}`;

/**
 * __useChannelQuery__
 *
 * To run a query within a React component, call `useChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChannelQuery(baseOptions: Apollo.QueryHookOptions<ChannelQuery, ChannelQueryVariables> & ({ variables: ChannelQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelQuery, ChannelQueryVariables>(ChannelDocument, options);
      }
export function useChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelQuery, ChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelQuery, ChannelQueryVariables>(ChannelDocument, options);
        }
export function useChannelSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChannelQuery, ChannelQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChannelQuery, ChannelQueryVariables>(ChannelDocument, options);
        }
export type ChannelQueryHookResult = ReturnType<typeof useChannelQuery>;
export type ChannelLazyQueryHookResult = ReturnType<typeof useChannelLazyQuery>;
export type ChannelSuspenseQueryHookResult = ReturnType<typeof useChannelSuspenseQuery>;
export type ChannelQueryResult = Apollo.QueryResult<ChannelQuery, ChannelQueryVariables>;
export const SendChannelMessageDocument = gql`
    mutation SendChannelMessage($channelId: ID!, $text: String!, $attachment: AttachmentInput, $attachments: [AttachmentInput!]) {
  sendChannelMessage(
    message: {channelId: $channelId, text: $text, attachment: $attachment, attachments: $attachments}
  ) {
    message {
      id
    }
  }
}
    `;
export type SendChannelMessageMutationFn = Apollo.MutationFunction<SendChannelMessageMutation, SendChannelMessageMutationVariables>;

/**
 * __useSendChannelMessageMutation__
 *
 * To run a mutation, you first call `useSendChannelMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChannelMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChannelMessageMutation, { data, loading, error }] = useSendChannelMessageMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      text: // value for 'text'
 *      attachment: // value for 'attachment'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useSendChannelMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendChannelMessageMutation, SendChannelMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendChannelMessageMutation, SendChannelMessageMutationVariables>(SendChannelMessageDocument, options);
      }
export type SendChannelMessageMutationHookResult = ReturnType<typeof useSendChannelMessageMutation>;
export type SendChannelMessageMutationResult = Apollo.MutationResult<SendChannelMessageMutation>;
export type SendChannelMessageMutationOptions = Apollo.BaseMutationOptions<SendChannelMessageMutation, SendChannelMessageMutationVariables>;