import { FormattedPercentage, FormattedPrice } from "@msys/ui";
import { Alert, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import { Stack } from "../../../commons/layout/Stack.js";
import { underlineDoubleStyle } from "@msys/ui";
import { useTranslate } from "@tolgee/react";

interface Props {
  calculation: {
    priceOrdered: number;
    priceVatOrdered: number;
    priceTotalOrdered: number;
  };
  vatRate: number;
}

export const PurchaseOrderSum = ({ calculation, vatRate }: Props) => {
  const { t } = useTranslate("PurchaseOrders");

  return (
    <Paper>
      <Alert severity="info" variant="outlined" style={{ border: "none" }}>
        <Typography variant="caption" color="secondary">
          {t(
            "Final price of the order might be different depending on the supplier."
          )}
        </Typography>
      </Alert>
      <Stack
        flexDirection="column"
        marginLeft={2}
        marginRight={2}
        marginBottom={2}
      >
        <Typography variant="h3" component="div">
          <Stack justifyContent="space-between">
            <span>{t("Total net price")}</span>
            <span style={{ textDecoration: "underline" }}>
              <FormattedPrice value={calculation.priceOrdered} />
            </span>
          </Stack>
        </Typography>
        <Stack justifyContent="space-between">
          <span>
            {t("VAT")} (<FormattedPercentage value={vatRate} />)
          </span>
          <span>
            + <FormattedPrice value={calculation.priceVatOrdered} />
          </span>
        </Stack>
        <Divider />
        <Typography variant="h1" component="div">
          <Stack justifyContent="space-between">
            <span>{t("Total gross price")}</span>
            <span style={underlineDoubleStyle}>
              <FormattedPrice value={calculation.priceTotalOrdered} />
            </span>
          </Stack>
        </Typography>
      </Stack>
    </Paper>
  );
};
