import { useApolloClient } from "@apollo/client";
import { Capacitor } from "@capacitor/core";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import { Send as SendIcon } from "@mui/icons-material";
import { Box, Divider, IconButton, LinearProgress, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { FormikHelpers } from "formik/dist/types.js";
import { omit } from "lodash-es";
import React, { useRef, useState } from "react";
import {
  AttachmentPhotoUploaderWithEditor,
  AttachmentPhotoUploaderWithEditorRef,
} from "../attachments/AttachmentPhotoUploaderWithEditor.js";
import { AttachmentUploader } from "../attachments/AttachmentUploader.js";
import { Attachment } from "../attachments/helpers.js";
import { useSendChannelMessageMutation } from "./Channel.generated.js";

interface Props {
  channelId: string;
  onMessageSent?: () => void | Promise<void>;
  inputPlaceholder?: string;
}

interface FormValues {
  text: string;
}

export const ChannelForm = ({
  onMessageSent,
  channelId,
  inputPlaceholder,
}: Props) => {
  const client = useApolloClient();
  const [sendMessage] = useSendChannelMessageMutation({ client });
  const attachmentInputRef = useRef<HTMLInputElement>(null);
  const attachmentPhotoUploaderRef =
    React.useRef<AttachmentPhotoUploaderWithEditorRef>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accept, setAccepts] = useState("*");
  const { t } = useTranslate("Channel");

  const textFieldRef = useRef<HTMLInputElement>();
  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  const handleSendTextMessage = async (
    { text }: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    if (!text.trim()) return;

    setIsSubmitting(true);
    resetForm();

    await sendMessage({
      variables: {
        channelId,
        text,
      },
      // refetchQueries: [namedOperations.Query.Channel],
      // awaitRefetchQueries: true,
      // optimisticResponse: {
      //   sendChannelMessage: {
      //     __typename: "SendChannelMessageResult",
      //     message: {
      //       __typename: "Message",
      //       id: Math.round(Math.random() * -1000000).toString(),
      //       author: viewer,
      //       text,
      //       createdAt: new Date().toISOString(),
      //       extraAttributes: {},
      //       attachment: null,
      //       quote: null,
      //       project: null,
      //       invoice: null,
      //     },
      //   },
      // },
    });

    setIsSubmitting(false);
    onMessageSent?.();
  };

  const handleSendAttachment = async (attachment: Attachment) => {
    await sendMessage({
      variables: {
        channelId,
        text: "",
        attachment: omit(attachment, "id", "__typename"),
      },
      // refetchQueries: [namedOperations.Query.Channel],
      // awaitRefetchQueries: true,
      // optimisticResponse: {
      //   sendChannelMessage: {
      //     __typename: "SendChannelMessageResult",
      //     message: {
      //       __typename: "Message",
      //       id: Math.round(Math.random() * -1000000).toString(),
      //       author: { ...viewer, __typename: "User" },
      //       text: "",
      //       createdAt: new Date().toISOString(),
      //       extraAttributes: {},
      //       attachment: {
      //         __typename: "AttachmentSnapshot",
      //         id,
      //         ...attachment,
      //         group: "",
      //       },
      //       quote: null,
      //       project: null,
      //       invoice: null,
      //     },
      //   },
      // },
    });
    setIsSubmitting(false);
    onMessageSent?.();
  };

  const handleSendAttachments = async (attachments: Attachment[]) => {
    await sendMessage({
      variables: {
        channelId,
        text: "",
        attachments: attachments.map(attachment =>
          omit(attachment, "id", "__typename")
        ),
      },
      // refetchQueries: [namedOperations.Query.Channel],
      // awaitRefetchQueries: true,
      // optimisticResponse: {
      //   sendChannelMessage: {
      //     __typename: "SendChannelMessageResult",
      //     message: {
      //       __typename: "Message",
      //       id: Math.round(Math.random() * -1000000).toString(),
      //       author: { ...viewer, __typename: "User" },
      //       text: "",
      //       createdAt: new Date().toISOString(),
      //       extraAttributes: {},
      //       attachment: {
      //         __typename: "AttachmentSnapshot",
      //         id,
      //         ...attachment,
      //         group: "",
      //       },
      //       quote: null,
      //       project: null,
      //       invoice: null,
      //     },
      //   },
      // },
    });
    setIsSubmitting(false);
    onMessageSent?.();
  };

  return (
    <Box>
      {isSubmitting ? <LinearProgress /> : <Divider />}
      <Stack direction="row" alignItems="center" py={1} px={1} spacing={1}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0}
          height="40px"
          alignSelf="flex-start"
        >
          <IconButton
            color="primary"
            size="small"
            disabled={isSubmitting}
            onClick={() => {
              if (Capacitor.isNativePlatform()) {
                attachmentPhotoUploaderRef.current?.start();
              } else {
                setAccepts("image/*,.heic,.heif");
                setTimeout(() => attachmentInputRef.current?.click());
              }
            }}
          >
            <AddAPhotoIcon />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            disabled={isSubmitting}
            onClick={() => {
              setAccepts("*");
              setTimeout(() => attachmentInputRef.current?.click());
            }}
          >
            <AttachFileIcon />
          </IconButton>
        </Stack>
        <Formik<FormValues>
          innerRef={formikRef}
          initialValues={{
            text: "",
          }}
          onSubmit={handleSendTextMessage}
        >
          <Form style={{ flex: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Field
                component={TextField}
                multiline
                minRows={1}
                maxRows={10}
                name="text"
                placeholder={inputPlaceholder ?? t("Write a message...")}
                inputRef={textFieldRef}
                variant="outlined"
                disabled={false}
                InputProps={{
                  inputProps: {
                    onKeyDown: (e: React.KeyboardEvent | KeyboardEvent) => {
                      // force submit on Enter
                      if (
                        e.key === "Enter" &&
                        !(e.shiftKey || e.ctrlKey || e.altKey || e.metaKey)
                      ) {
                        e.preventDefault();
                        formikRef.current?.submitForm();
                      }
                    },
                  },
                }}
              />
              <Stack
                direction="row"
                height="40px"
                alignSelf="flex-start"
                alignItems="center"
              >
                <IconButton
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    textFieldRef.current?.focus();
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        </Formik>
        {Capacitor.isNativePlatform() && (
          <AttachmentPhotoUploaderWithEditor
            ref={attachmentPhotoUploaderRef}
            onStart={() => setIsSubmitting(true)}
            onError={() => setIsSubmitting(false)}
            onAttachment={handleSendAttachment}
          />
        )}
        <AttachmentUploader
          innerRef={attachmentInputRef}
          accept={accept}
          multiple
          onStart={() => setIsSubmitting(true)}
          onError={() => setIsSubmitting(false)}
          onCancel={() => setIsSubmitting(false)}
          // onComplete={handleSendAttachment}
          onComplete={handleSendAttachments}
        />
      </Stack>
    </Box>
  );
};
