import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import * as Sentry from "@sentry/react";
import Keycloak, { KeycloakAdapter, PromiseObject } from "keycloak-js";
import React from "react";
import { SplashScreen } from "../../common/SplashScreen.js";
import { AuthContext } from "./AuthContext.js";

interface Props extends React.PropsWithChildren<{}> {
  keycloak: Keycloak;
}

export const AuthProvider = ({ children, keycloak }: Props) => {
  const [isReady, setIsReady] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  React.useEffect(() => {
    function setIsAuthenticatedState() {
      setIsAuthenticated(keycloak.authenticated ?? false);
    }

    try {
      keycloak.onReady = () => {
        setIsReady(true);
      };
      keycloak.onAuthError = setIsAuthenticatedState;
      keycloak.onAuthLogout = setIsAuthenticatedState;
      keycloak.onAuthRefreshError = setIsAuthenticatedState;
      keycloak.onTokenExpired = setIsAuthenticatedState;
      keycloak.onAuthSuccess = setIsAuthenticatedState;
      keycloak.onAuthRefreshSuccess = setIsAuthenticatedState;
      keycloak.init(
        // reference: https://github.com/jy95/keycloak-capacitor/blob/e0b3b4095826864e4d72f5cd061a20bce2a9341f/src/keycloak.js#L75
        Capacitor.isNativePlatform()
          ? {
              adapter: getKeycloakCapacitorAdapter(keycloak),
              checkLoginIframe: false,
              responseMode: "query",
              onLoad: "check-sso",
              // flow: "hybrid", // 'implicit', 'hybrid',
              redirectUri: "de.meistersystems:/keycloak",
            }
          : {
              onLoad: "check-sso",
              silentCheckSsoRedirectUri: `${location.origin}/${
                import.meta.env.VITE_ROUTER_BASENAME
                  ? import.meta.env.VITE_ROUTER_BASENAME + "/"
                  : ""
              }silent-check-sso.html`,
            }
      );
    } catch (e) {
      if (e instanceof Error)
        console.error("Failed to initialize keycloak: ", e.message);
      Sentry.captureException(e);
    }
  }, [keycloak]);

  if (!isReady) return <SplashScreen />;

  return (
    <AuthContext.Provider
      value={{
        isReady,
        isAuthenticated,
        auth: keycloak,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

async function openBrowserWithCallback(
  promise: PromiseObject<void>,
  url: string,
  onDataCallback: (data: URLOpenListenerEvent) => void | Promise<void>
) {
  const addUrlListener = await App.addListener("appUrlOpen", onDataCallback);

  promise.promise.finally(() => {
    addUrlListener.remove();
    Browser.close();
  });

  try {
    await Browser.open({ url, presentationStyle: "popover" });
  } catch (e) {
    if (e instanceof Error) {
      Sentry.withScope(scope => {
        scope.setTag("service", "Capacitor");
        scope.setExtra("method", "openBrowserWithCallback");
        scope.setExtra("url", url);
        Sentry.captureException(e);
      });
      promise.setError(e);
    }
  }
}

async function openBrowser(promise: PromiseObject<void>, url: string) {
  try {
    await Browser.open({ url, presentationStyle: "popover" });
    promise.setSuccess();
  } catch (e) {
    if (e instanceof Error) {
      Sentry.withScope(scope => {
        scope.setTag("service", "Capacitor");
        scope.setExtra("method", "openBrowser");
        scope.setExtra("url", url);
        Sentry.captureException(e);
      });
      promise.setError(e);
    }
  }
}

// references: https://github.com/edgeflare/keycloak-angular-capacitor/blob/master/projects/keycloak-angular-capacitor/src/lib/keycloak-angular-capacitor.service.ts
// taken from: https://github.com/jy95/keycloak-capacitor/blob/e0b3b4095826864e4d72f5cd061a20bce2a9341f/src/keycloak.js#L1668
const getKeycloakCapacitorAdapter = (keycloak: Keycloak): KeycloakAdapter => ({
  login: options => {
    const promise = keycloak.createPromise<void>();
    const loginUrl = keycloak.createLoginUrl(options);

    openBrowserWithCallback(promise, loginUrl, data => {
      const oauth = keycloak.parseCallback(data.url);
      keycloak.processCallback(oauth, promise);
    });

    return promise.promise;
  },

  logout: options => {
    const promise = keycloak.createPromise<void>();
    const logoutUrl = keycloak.createLogoutUrl(options);

    openBrowserWithCallback(promise, logoutUrl, data => {
      keycloak.clearToken();
      promise.setSuccess();
    });

    return promise.promise;
  },

  register: options => {
    const promise = keycloak.createPromise<void>();
    const registerUrl = keycloak.createRegisterUrl(options);

    openBrowserWithCallback(promise, registerUrl, data => {
      const oauth = keycloak.parseCallback(data.url);
      keycloak.processCallback(oauth, promise);
    });

    return promise.promise;
  },

  accountManagement: () => {
    const promise = keycloak.createPromise<void>();
    const accountUrl = keycloak.createAccountUrl();
    if (!accountUrl) {
      throw new Error("Not supported by the OIDC server");
    }

    openBrowser(promise, accountUrl);

    return promise.promise;
  },

  redirectUri: options => {
    if (options && options.redirectUri) {
      return options.redirectUri;
    } else if (keycloak.redirectUri) {
      return keycloak.redirectUri;
    } else {
      return "http://localhost";
    }
  },
});
