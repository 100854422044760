/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { InvoiceCalculationFragmentDoc } from '../Fragments.generated.js';
export type InvoiceReferenceFragment = { __typename: 'InvoiceReference', id: string, isPaid: boolean, invoiceNumber: string, invoiceOpenedAt: any, invoiceTitle: string, invoiceCalculation: { __typename: 'InvoiceCalculation', discountRate: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, vatRate: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workRate: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, extraItemsPriceSubTotal: number, extraItemsPriceNetTotal: number, extraItemsPriceVatTotal: number, extraItemsPriceTotal: number, paymentItemsPriceSubTotal: number, paymentItemsPriceNetTotal: number, paymentItemsPriceVatTotal: number, paymentItemsPriceTotal: number, openPriceNetTotal: number, openPriceVatTotal: number, openPriceTotal: number, finalPriceNetTotal: number, finalPriceVatTotal: number, finalPriceTotal: number } };

export const InvoiceReferenceFragmentDoc = gql`
    fragment InvoiceReference on InvoiceReference {
  id
  isPaid
  invoiceCalculation {
    ...InvoiceCalculation
  }
  invoiceNumber
  invoiceOpenedAt
  invoiceTitle
}
    ${InvoiceCalculationFragmentDoc}`;