import { gql } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { Send as SendIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { QuotePdfCalculationType } from "../../../../clients/graphqlTypes.js";
import {
  SendQuoteViaEmailButton_ProjectFragment,
  SendQuoteViaEmailButton_QuoteFragment,
} from "./SendQuoteViaEmailButton.generated.js";
import { SendQuoteViaEmailModal } from "./SendQuoteViaEmailModal.js";

interface Props {
  projectId: string;
  project: SendQuoteViaEmailButton_ProjectFragment;
  quoteId: string;
  quote: SendQuoteViaEmailButton_QuoteFragment;
  calculationType: QuotePdfCalculationType;
  pdfTemplateId?: string | null;
}

export function SendQuoteViaEmailButton({
  projectId,
  project,
  quote,
  quoteId,
  calculationType,
  pdfTemplateId,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["QuoteSend", "Global"]);

  return (
    <ModalOpenButton
      Modal={SendQuoteViaEmailModal}
      modalProps={{
        quoteId,
        quote,
        projectId,
        project,
        calculationType,
        pdfTemplateId,
        handleComplete: async handleClose => {
          enqueueSnackbar(
            t("Quote was sent to client", {
              ns: "QuoteSend",
            })
          );
          handleClose();
        },
      }}
    >
      <Button color="primary" variant="contained" endIcon={<SendIcon />}>
        {t("Send", { ns: "Global" })}
      </Button>
    </ModalOpenButton>
  );
}
