/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type TemplateQuoteSubscriptionStatus_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null };

export const TemplateQuoteSubscriptionStatus_QuoteTemplateFragmentDoc = gql`
    fragment TemplateQuoteSubscriptionStatus_QuoteTemplate on QuoteTemplate {
  id
  subscribedToTemplate {
    subscribedVersionNumber
    updateAvailable
  }
}
    `;