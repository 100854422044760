/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTodoItemModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateTodoItemModalQuery = { orgMembers: Array<{ __typename: 'User', id: string, firstname: string, familyname: string }> };

export type CreateTodoItemMutationVariables = Types.Exact<{
  input: Types.CreateTodoItemInput;
}>;


export type CreateTodoItemMutation = { createTodoItem: { __typename: 'CreateTodoItemResult', todoItem: { __typename: 'TodoItem', id: string } } };


export const CreateTodoItemModalDocument = gql`
    query CreateTodoItemModal {
  orgMembers: organisationMemberships {
    id
    firstname
    familyname
  }
}
    `;

/**
 * __useCreateTodoItemModalQuery__
 *
 * To run a query within a React component, call `useCreateTodoItemModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateTodoItemModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateTodoItemModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateTodoItemModalQuery(baseOptions?: Apollo.QueryHookOptions<CreateTodoItemModalQuery, CreateTodoItemModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateTodoItemModalQuery, CreateTodoItemModalQueryVariables>(CreateTodoItemModalDocument, options);
      }
export function useCreateTodoItemModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateTodoItemModalQuery, CreateTodoItemModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateTodoItemModalQuery, CreateTodoItemModalQueryVariables>(CreateTodoItemModalDocument, options);
        }
export function useCreateTodoItemModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CreateTodoItemModalQuery, CreateTodoItemModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateTodoItemModalQuery, CreateTodoItemModalQueryVariables>(CreateTodoItemModalDocument, options);
        }
export type CreateTodoItemModalQueryHookResult = ReturnType<typeof useCreateTodoItemModalQuery>;
export type CreateTodoItemModalLazyQueryHookResult = ReturnType<typeof useCreateTodoItemModalLazyQuery>;
export type CreateTodoItemModalSuspenseQueryHookResult = ReturnType<typeof useCreateTodoItemModalSuspenseQuery>;
export type CreateTodoItemModalQueryResult = Apollo.QueryResult<CreateTodoItemModalQuery, CreateTodoItemModalQueryVariables>;
export const CreateTodoItemDocument = gql`
    mutation CreateTodoItem($input: CreateTodoItemInput!) {
  createTodoItem(input: $input) {
    todoItem {
      id
    }
  }
}
    `;
export type CreateTodoItemMutationFn = Apollo.MutationFunction<CreateTodoItemMutation, CreateTodoItemMutationVariables>;

/**
 * __useCreateTodoItemMutation__
 *
 * To run a mutation, you first call `useCreateTodoItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTodoItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTodoItemMutation, { data, loading, error }] = useCreateTodoItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTodoItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateTodoItemMutation, CreateTodoItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTodoItemMutation, CreateTodoItemMutationVariables>(CreateTodoItemDocument, options);
      }
export type CreateTodoItemMutationHookResult = ReturnType<typeof useCreateTodoItemMutation>;
export type CreateTodoItemMutationResult = Apollo.MutationResult<CreateTodoItemMutation>;
export type CreateTodoItemMutationOptions = Apollo.BaseMutationOptions<CreateTodoItemMutation, CreateTodoItemMutationVariables>;