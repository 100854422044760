/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuildingPlacementBox_ItemFragment = { __typename: 'Item', id: string, placement?: { __typename: 'ItemRef', id: string, docId: string, title: string, pathForPdf: string } | null };

export type BuildingPlacementBox_ProjectFragment = { __typename: 'Project', id: string, owningSystemOrganisationId: string, building?: { __typename: 'Building', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, pathForPdf: string }> } | null, buildingItem?: { __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, pathForPdf: string } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string } | null };

export type BuildingPlacementBoxSetMutationVariables = Types.Exact<{
  input: Types.SetItemPlacementInput;
}>;


export type BuildingPlacementBoxSetMutation = { setItemPlacement: { __typename: 'SetItemPlacementResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, placement?: { __typename: 'ItemRef', id: string, title: string, pathForPdf: string, docId: string } | null } } };

export type AssignBuildingButton_ProjectFragment = { __typename: 'Project', id: string, owningSystemOrganisationId: string, crmOrganisation?: { __typename: 'CrmCompany', id: string } | null };

export const BuildingPlacementBox_ItemFragmentDoc = gql`
    fragment BuildingPlacementBox_Item on Item {
  id
  placement {
    id
    docId
    title
    pathForPdf
  }
}
    `;
export const AssignBuildingButton_ProjectFragmentDoc = gql`
    fragment AssignBuildingButton_Project on Project {
  id
  owningSystemOrganisationId
  crmOrganisation {
    id
  }
}
    `;
export const BuildingPlacementBox_ProjectFragmentDoc = gql`
    fragment BuildingPlacementBox_Project on Project {
  id
  building {
    id
    items {
      id
      originVersionNumber
      title
      pathForPdf
    }
  }
  buildingItem {
    id
    originVersionNumber
    title
    pathForPdf
  }
  ...AssignBuildingButton_Project
}
    ${AssignBuildingButton_ProjectFragmentDoc}`;
export const BuildingPlacementBoxSetDocument = gql`
    mutation BuildingPlacementBoxSet($input: SetItemPlacementInput!) {
  setItemPlacement(input: $input) {
    item {
      id
      originVersionNumber
      placement {
        id
        title
        pathForPdf
        docId
      }
    }
  }
}
    `;
export type BuildingPlacementBoxSetMutationFn = Apollo.MutationFunction<BuildingPlacementBoxSetMutation, BuildingPlacementBoxSetMutationVariables>;

/**
 * __useBuildingPlacementBoxSetMutation__
 *
 * To run a mutation, you first call `useBuildingPlacementBoxSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingPlacementBoxSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingPlacementBoxSetMutation, { data, loading, error }] = useBuildingPlacementBoxSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildingPlacementBoxSetMutation(baseOptions?: Apollo.MutationHookOptions<BuildingPlacementBoxSetMutation, BuildingPlacementBoxSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingPlacementBoxSetMutation, BuildingPlacementBoxSetMutationVariables>(BuildingPlacementBoxSetDocument, options);
      }
export type BuildingPlacementBoxSetMutationHookResult = ReturnType<typeof useBuildingPlacementBoxSetMutation>;
export type BuildingPlacementBoxSetMutationResult = Apollo.MutationResult<BuildingPlacementBoxSetMutation>;
export type BuildingPlacementBoxSetMutationOptions = Apollo.BaseMutationOptions<BuildingPlacementBoxSetMutation, BuildingPlacementBoxSetMutationVariables>;