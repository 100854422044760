import { ImageWithPreview } from "@msys/ui";
import { AddPhotoAlternate as AddPhotoAlternateIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { cssValue } from "../../../common/MuiThemeProvider.js";
import { useGlobalLoadingCounter } from "../../../common/global-loading-state/useGlobalLoadingCounter.js";
import { AttachmentPhotoDragAndDropField } from "../../features/attachments/AttachmentPhotoDragAndDropField.js";
import {
  Attachment,
  uploadFileToUploadCare,
} from "../../features/attachments/helpers.js";

interface Props {
  skipEdit?: boolean;
  loading?: boolean;
  disabled?: boolean;
  value: Attachment | null;
  onChange?: (newValue: Attachment | null) => void;
  onAttachment?: (attachment: Attachment) => void | Promise<void>;
}

export const AttachmentImageDragAndDropInput = ({
  skipEdit,
  loading,
  disabled,
  value,
  onChange,
  onAttachment,
}: Props) => {
  const { t } = useTranslate("AttachmentField");
  const [uploading, setUploading] = useState<boolean>(false);
  const { increment, decrement } = useGlobalLoadingCounter();
  const { enqueueSnackbar } = useSnackbar();

  const onFile = async (file: File | null) => {
    if (!file) {
      onChange?.(null);
    } else {
      try {
        setUploading(true);
        increment();
        const attachment = await uploadFileToUploadCare(file);
        await onAttachment?.(attachment);
        onChange?.(attachment);
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      } finally {
        setUploading(false);
        decrement();
      }
    }
  };

  return value ? (
    <ImageWithPreview
      src={value.url}
      title={value.title}
      loading={loading}
      disabled={disabled}
      borderRadius={cssValue.boxBorderRadius}
      handleDelete={() => onChange?.(null)}
    />
  ) : (
    <AttachmentPhotoDragAndDropField
      noPreview={true}
      title={t("Add image")}
      iconComponent={AddPhotoAlternateIcon}
      uploading={uploading}
      disabled={uploading || disabled}
      skipEdit={skipEdit}
      onFile={onFile}
    />
  );
};
