import { StatusLabel } from "@msys/ui";
import { useTheme } from "@mui/material";
import React from "react";
import {
  RequestRecipientSource,
  RequestRecipientStatus,
} from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

interface Props {
  status: RequestRecipientStatus;
  source: RequestRecipientSource;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}

export const RequestRecipientStatusBadge = ({
  status,
  source,
  align,
}: Props) => {
  const { t } = useTranslate("RequestRecipients");
  const theme = useTheme();
  switch (status) {
    case "INITIAL": {
      if (source === "MARKETPLACE") {
        return (
          <StatusLabel color={theme.palette.grey[600]} align={align}>
            {t("From marketplace")}
          </StatusLabel>
        );
      }

      return (
        <StatusLabel color={theme.palette.grey[600]} align={align}>
          {t("Invited to bid")}
        </StatusLabel>
      );
    }
    case "YES": {
      return (
        <StatusLabel color={theme.palette.secondary.main} align={align}>
          {t("Interested")}
        </StatusLabel>
      );
    }
    case "MAYBE": {
      return (
        <StatusLabel color={theme.palette.secondary.main} align={align}>
          {t("Maybe interested")}
        </StatusLabel>
      );
    }
    case "NO": {
      return (
        <StatusLabel color={theme.palette.primary.dark} align={align}>
          {t("Not interested")}
        </StatusLabel>
      );
    }
  }
  return <></>;
};

export const RequestRecipientQuoteWonBadge = ({
  align,
}: {
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}) => {
  const { t } = useTranslate("RequestRecipients");
  const theme = useTheme();
  return (
    <StatusLabel color={theme.palette.success.main} align={align}>
      {t("Quote won")}
    </StatusLabel>
  );
};

export const RequestRecipientQuoteSubmittedBadge = ({
  align,
}: {
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}) => {
  const { t } = useTranslate("RequestRecipients");
  const theme = useTheme();
  return (
    <StatusLabel color={theme.palette.success.main} align={align}>
      {t("Quote submitted")}
    </StatusLabel>
  );
};

export const RequestRecipientQuoteRejectedBadge = ({
  align,
}: {
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}) => {
  const { t } = useTranslate("RequestRecipients");
  const theme = useTheme();
  return (
    <StatusLabel color={theme.palette.error.main} align={align}>
      {t("Quote rejected")}
    </StatusLabel>
  );
};
