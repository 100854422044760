import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  GridColDef,
  isImage,
  isImageOr3dModel,
  ListHeader,
  LoadingSpinner,
  MediaCardItem,
  MediaListItem,
  ModalOpenButton,
  processAttachment,
  TagChips,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TablePagination,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormik } from "formik";
import React from "react";
import { useLatest } from "react-use";
import {
  TemplateSearchFiltersInput,
  TemplateSearchSortBy,
  TemplateSearchSortingInput,
} from "../../../../clients/graphqlTypes.js";
import { ButtonCircleWrapper } from "../../../commons/button/ButtonCircleWrapper.js";
import { SwitchCollectionViewButton } from "../../../commons/button/SwitchCollectionViewButton.js";
import { FilterButton } from "../../../commons/filters/FilterButton.js";
import { useAutoSave } from "../../../commons/hooks/useAutoSave.js";
import {
  Columns,
  useCardsNumber,
} from "../../../commons/hooks/useCardsNumber.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../../commons/hooks/useCollectionView.js";
import { PaginationModel } from "../../../commons/pagination/usePaginationParams.js";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../constants.js";
import { assertNever } from "../../../utils.js";
import { useOrganisationTags } from "../../organisations/useOrganisationTags.js";
import { PropertyValueChips } from "../../properties/PropertyValueChips.js";
import {
  templateFiltersDefaultValue,
  TemplateFiltersFormValues,
  templateFiltersValidationSchema,
} from "../filters/TemplateFilterFields.js";
import { TemplateFilters } from "../filters/TemplateFilters.js";
import {
  computeTemplateRelevantProperties,
  quoteTemplateSortingToSearchQuotesSorting,
  searchQuotesSortingToQuoteTemplatesSorting,
} from "../helpers.js";
import { TemplateQuotePublishingStatus } from "./TemplateQuotePublishingStatus.js";
import {
  TemplatesDisplay_QuoteTemplate_GalleryFragment,
  TemplatesDisplay_QuoteTemplate_ListFragment,
  TemplatesDisplay_QuoteTemplate_TableFragment,
  useTemplatesDisplay_SearchTemplatesQuery,
} from "./TemplatesDisplay.generated.js";
import {
  canShowChips,
  TemplatesQuoteFilterChips,
} from "./TemplatesQuoteFilterChips.js";
import { TemplateQuotesFilterWithTemplateTypeModal } from "./TemplatesQuoteFilterModal.js";
import { TemplatesQuoteOverviewModal } from "./TemplatesQuoteOverviewModal.js";
import {
  hasTemplateTypeOrPropsInfo,
  TemplateTypeAndPropsInfo,
} from "./TemplateTypeAndPropsInfo.js";
import { useStateWithUrlParams } from "../../../commons/hooks/useStateWithUrlParams.js";

export type View = Exclude<CollectionView, "swipe" | "kanban">;
const DEFAULT_ALLOWED_VIEWS: View[] = ["gallery", "list", "table"];

export interface TemplateBase {
  id: string;
  title: string;
  resolvedAsReadModelVersionNumber?: number | null;
}

export interface TemplatesDisplayProps {
  allowedViews?: View[];
  defaultCollectionViewDesktop?: View;
  defaultCollectionViewMobile?: View;
  collectionViewPersistenceKey?: string;

  initialSearchValue?: string;
  initialFilters?: TemplateSearchFiltersInput;
  initialSorting?: TemplateSearchSortingInput[];
  fixedFilters?: TemplateSearchFiltersInput;
}

export const TemplatesDisplay = ({
  allowedViews = DEFAULT_ALLOWED_VIEWS,
  collectionViewPersistenceKey = "templatesDisplay",
  defaultCollectionViewDesktop = "table",
  defaultCollectionViewMobile = "list",
  initialSearchValue,
  initialFilters,
  initialSorting,
  fixedFilters,
  onInspectedTemplateClick,
  onTemplateClick,
  selectedTemplate,
  setSelectedTemplate,
}: TemplatesDisplayProps & {
  onInspectedTemplateClick?: (template: TemplateBase) => void;
  onTemplateClick?: (template: TemplateBase) => void;
  selectedTemplate?: TemplateBase | null;
  setSelectedTemplate?: (template: TemplateBase | null) => void;
}) => {
  const { t } = useTranslate(["Templates", "Global"]);
  const { isMinTablet } = useScreenWidth();

  const { customTags } = useOrganisationTags("TEMPLATE");

  const {
    templateFilterFormikProps,
    templateSearchVariables,
    resetTemplateFilters,
    isLoading,
    searchTerm,
    setSearchTerm,
    filters,
    setFilters,
    templateFilters,
    setTemplateFilters,
    resetFilters,
    clearFilters,
    sorting,
    setSorting,
    totalCount,
    templates,
    paginationModel,
    setPaginationModel,
  } = useTemplateSearch({
    initialSearchValue,
    initialFilters,
    initialSorting,
    fixedFilters,
  });

  const [activeView, setActiveView] = useCollectionViewWithMobile<View>(
    collectionViewPersistenceKey,
    defaultCollectionViewDesktop,
    defaultCollectionViewMobile
  );

  const [inspectedTemplate, setInspectedTemplate] =
    React.useState<TemplateBase | null>(null);

  const { spacing, sizeMeasureRef, columns } = useCardsNumber();

  return (
    <Stack
      alignItems={"stretch"}
      height="100%"
      spacing={2}
      direction="row"
      minHeight={0}
      flex={1}
    >
      <Stack
        direction={"column"}
        height="100%"
        spacing={1}
        flex={1}
        minWidth={0}
        ref={sizeMeasureRef}
      >
        <ListHeader
          elementsOrder="separate-rows"
          SwitchViewButton={
            <SwitchCollectionViewButton<View>
              allowedViews={allowedViews}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          SearchField={
            <Stack direction={"row"} flex={1} spacing={1}>
              <DebouncedSearchInput
                defaultValue={searchTerm}
                placeholder={t("Search", {
                  ns: "Global",
                })}
                onChangeSearchTerm={setSearchTerm}
                autoFocus
                style={{ flexGrow: 1 }}
              />
            </Stack>
          }
          QuickFilter={
            isMinTablet ? (
              <TemplateFilters
                formikProps={templateFilterFormikProps}
                templateSearchVariables={templateSearchVariables}
                fixedFilters={fixedFilters}
                viewType="buttons"
              />
            ) : undefined
          }
          FilterButton={
            <ModalOpenButton
              Modal={TemplateQuotesFilterWithTemplateTypeModal}
              modalProps={{
                resetFilters,
                sorting: searchQuotesSortingToQuoteTemplatesSorting(sorting),
                setSorting: sorting =>
                  setSorting(
                    quoteTemplateSortingToSearchQuotesSorting(sorting)
                  ),
                filters,
                setFilters,
                tags: customTags,
                formikProps: templateFilterFormikProps,
                templateSearchVariables,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          FilterChips={
            canShowChips(filters, templateFilters) ? (
              <TemplatesQuoteFilterChips
                filters={filters}
                setFilters={setFilters}
                templateFilters={templateFilters}
                setTemplateFilters={setTemplateFilters}
                fixedFilters={fixedFilters}
                onReset={async () => {
                  clearFilters();
                  await resetTemplateFilters();
                }}
              />
            ) : undefined
          }
        />
        {/* {query.error && <ErrorMessage message={query.error.message} />} */}
        {activeView === "table" ? (
          <TemplatesTable
            isLoading={isLoading}
            totalCount={totalCount}
            templates={templates}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            setInspectedTemplate={setInspectedTemplate}
            onTemplateClick={onTemplateClick}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            propertyFilters={filters.properties}
            sorting={sorting}
            setSorting={setSorting}
          />
        ) : activeView === "list" ? (
          <TemplatesList
            isLoading={isLoading}
            totalCount={totalCount}
            templates={templates}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            setInspectedTemplate={setInspectedTemplate}
            onTemplateClick={onTemplateClick}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            propertyFilters={filters.properties}
          />
        ) : activeView === "gallery" ? (
          <TemplatesGallery
            isLoading={isLoading}
            totalCount={totalCount}
            templates={templates}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            setInspectedTemplate={setInspectedTemplate}
            onTemplateClick={onTemplateClick}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            propertyFilters={filters.properties}
            spacing={spacing}
            columns={columns}
          />
        ) : (
          assertNever(activeView)
        )}
      </Stack>
      {inspectedTemplate && (
        <TemplatesQuoteOverviewModal
          docId={inspectedTemplate.id}
          versionNumber={inspectedTemplate.resolvedAsReadModelVersionNumber}
          handleClose={() => {
            setInspectedTemplate(null);
          }}
          dialogActionButton={
            onInspectedTemplateClick ? (
              <Button
                onClick={e => {
                  e.stopPropagation();
                  setInspectedTemplate(null);
                  onInspectedTemplateClick(inspectedTemplate);
                }}
                color="primary"
                variant="contained"
              >
                {t("Use this template", { ns: "Templates" })}
              </Button>
            ) : setSelectedTemplate ? (
              <Button
                onClick={e => {
                  e.stopPropagation();
                  setInspectedTemplate(null);
                  setSelectedTemplate(inspectedTemplate);
                }}
                color="primary"
                variant="contained"
              >
                {t("Select", { ns: "Global" })}
              </Button>
            ) : undefined
          }
          notInStack
        />
      )}
    </Stack>
  );
};

const TemplatesTable = ({
  isLoading,
  templates,
  totalCount,
  setInspectedTemplate,
  onTemplateClick,
  selectedTemplate,
  setSelectedTemplate,
  paginationModel,
  setPaginationModel,
  propertyFilters,
  sorting,
  setSorting,
}: {
  isLoading: boolean;
  templates: TemplatesDisplay_QuoteTemplate_TableFragment[];
  totalCount: number;
  setInspectedTemplate: (template: TemplateBase) => void;
  onTemplateClick?: (template: TemplateBase) => void;
  selectedTemplate?: TemplateBase | null;
  setSelectedTemplate?: (template: TemplateBase | null) => void;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  propertyFilters: TemplateSearchFiltersInput["properties"];
  sorting: TemplateSearchSortingInput[];
  setSorting: (sorting: TemplateSearchSortingInput[]) => void;
}) => {
  const { t } = useTranslate(["Templates", "Global"]);
  const { getFormattedDate, getFormattedPrice } = useFormatting();

  const setInspectedTemplateRef = useLatest(setInspectedTemplate);

  const columns = React.useMemo(
    (): GridColDef<TemplatesDisplay_QuoteTemplate_TableFragment>[] => [
      {
        field: "title",
        headerName: t("Title", { ns: "Templates" }),
        flex: 2,
        minWidth: 100,
        sortable: true,
        renderCell: ({ row: template }) => template.title,
      },
      {
        field: "templateType",
        headerName: t("Template type", { ns: "Templates" }),
        flex: 1,
        minWidth: 50,
        sortable: false,
        renderCell: ({ row: template }) =>
          template.implementsTemplateType?.templateType?.title ?? "",
      },
      {
        field: "templateTypeProperties",
        headerName: t("Template type properties", { ns: "Templates" }),
        flex: 3,
        minWidth: 150,
        sortable: false,
        renderCell: ({ row: template }) => {
          const props = computeTemplateRelevantProperties({
            rootItemProps: template.rootItem.props2,
            templateTypeProps:
              template.implementsTemplateType?.templateType?.props2 ?? [],
          });
          return (
            <PropertyValueChips
              highlightedKeys={propertyFilters?.map(p => p.key)}
              props={props}
              wrap={false}
            />
          );
        },
      },
      {
        field: "tags",
        headerName: t("Tags", { ns: "Templates" }),
        flex: 1.5,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: template }) => (
          <TagChips tags={template.customTags} wrap={false} />
        ),
      },
      {
        field: "publishingStatus",
        headerName: t("Publishing", { ns: "Templates" }),
        flex: 0.75,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: template }) => (
          <TemplateQuotePublishingStatus
            doc={template}
            variant="body2"
            IconProps={{ fontSize: "small" }}
          />
        ),
      },
      {
        field: "createdAt",
        headerName: t("Created", { ns: "Templates" }),
        width: 120,
        sortable: true,
        renderCell: ({ row: template }) => getFormattedDate(template.createdAt),
      },
      {
        field: "priceNetTotal",
        headerName: t("Price", { ns: "Templates" }),
        width: 120,
        headerAlign: "right",
        align: "right",
        sortable: true,
        renderCell: ({ row: template }) =>
          getFormattedPrice(template.proposedCalculation?.priceNetTotal || 0),
      },
      {
        field: "actions",
        headerName: "",
        width: 50,
        sortable: false,
        resizable: false,
        renderCell: ({ row: template }) => (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setInspectedTemplateRef.current(template);
            }}
          >
            <InfoOutlinedIcon color="secondary" />
          </IconButton>
        ),
      },
    ],
    [
      getFormattedDate,
      getFormattedPrice,
      propertyFilters,
      setInspectedTemplateRef,
      t,
    ]
  );

  return (
    <DataGrid<TemplatesDisplay_QuoteTemplate_TableFragment>
      density="compact"
      loading={isLoading}
      hideFooter={totalCount === 0}
      localeText={{
        noRowsLabel: t("There are no items to display", {
          ns: "Global",
        }),
        MuiTablePagination: {
          labelRowsPerPage: t("Rows per page", {
            ns: "Global",
          }),
        },
      }}
      sortModel={sorting
        .map(s =>
          s.fieldSorting
            ? {
                field: s.fieldSorting.field,
                sort: s.fieldSorting.direction,
              }
            : null
        )
        .filter(notNull)}
      onSortModelChange={newModel => {
        setSorting(
          newModel.map(({ field, sort }) => ({
            fieldSorting: {
              field: field as TemplateSearchSortBy,
              direction: sort ?? "asc",
            },
          }))
        );
      }}
      columns={columns}
      rowSelectionModel={selectedTemplate ? [selectedTemplate.id] : []}
      rows={templates}
      onRowClick={({ row: template }) => {
        if (onTemplateClick) {
          onTemplateClick(template);
        } else {
          setSelectedTemplate?.(
            selectedTemplate?.id === template.id ? null : template
          );
        }
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={newPaginationModel => {
        setPaginationModel(newPaginationModel);
      }}
      disableColumnFilter
      pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
      rowCount={totalCount}
    />
  );
};

const TemplatesList = ({
  isLoading,
  templates,
  totalCount,
  setInspectedTemplate,
  onTemplateClick,
  selectedTemplate,
  setSelectedTemplate,
  paginationModel,
  setPaginationModel,
  propertyFilters,
}: {
  isLoading: boolean;
  templates: Array<TemplatesDisplay_QuoteTemplate_ListFragment>;
  totalCount: number;
  setInspectedTemplate: (template: TemplateBase) => void;
  onTemplateClick?: (template: TemplateBase) => void;
  selectedTemplate?: TemplateBase | null;
  setSelectedTemplate?: (template: TemplateBase | null) => void;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  propertyFilters: TemplateSearchFiltersInput["properties"];
}) => {
  const { t } = useTranslate(["Global"]);
  const { getFormattedPrice } = useFormatting();

  if (isLoading) return <LoadingSpinner />;
  if (!templates.length)
    return (
      <Box padding={2} display="flex" justifyContent="center">
        {t("There seems to be nothing here", {
          ns: "Global",
        })}
      </Box>
    );

  return (
    <Stack
      direction="column"
      overflow="auto"
      spacing={1}
      flex={1}
      minHeight={0}
    >
      <Stack
        direction="column"
        spacing={1}
        minHeight={0}
        overflow={"auto"}
        flex={1}
      >
        {templates.map((template, index) => {
          const attachments = template.attachments.map(processAttachment);

          const props = computeTemplateRelevantProperties({
            rootItemProps: template.rootItem.props2,
            templateTypeProps:
              template.implementsTemplateType?.templateType?.props2 ?? [],
          });

          const Info = hasTemplateTypeOrPropsInfo({
            templateTypeTitle:
              template.implementsTemplateType?.templateType.title,
            props,
          }) ? (
            <TemplateTypeAndPropsInfo
              templateTypeTitle={
                template.implementsTemplateType?.templateType.title
              }
              props={props}
            />
          ) : null;

          return (
            <MediaListItem
              key={template.id}
              selected={template.id === selectedTemplate?.id}
              onClick={() => {
                if (onTemplateClick) {
                  onTemplateClick(template);
                } else {
                  setSelectedTemplate?.(
                    selectedTemplate?.id === template.id ? null : template
                  );
                }
              }}
              title={template.title}
              description={template.description}
              price={getFormattedPrice(
                template.proposedCalculation?.priceNetTotal ?? 0
              )}
              imageUrl={attachments.find(isImage)?.url}
              images={attachments.filter(isImageOr3dModel)}
              ActionButton={
                <IconButton
                  onClick={event => {
                    event.stopPropagation();
                    setInspectedTemplate(template);
                  }}
                >
                  <InfoOutlinedIcon color="secondary" />
                </IconButton>
              }
              Info={Info}
              Tags={
                template.customTags.length > 0 ? (
                  <TagChips tags={template.customTags} wrap={false} />
                ) : null
              }
            />
          );
        })}
      </Stack>
      <TablePagination
        component="div"
        count={totalCount}
        page={paginationModel.page}
        onPageChange={(event, newPage) => {
          setPaginationModel({
            pageSize: paginationModel.pageSize,
            page: newPage,
          });
        }}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={event => {
          setPaginationModel({
            pageSize: parseInt(event.target.value, 10),
            page: 0,
          });
        }}
        labelRowsPerPage={t("Rows per page:", {
          ns: "Global",
        })}
      />
    </Stack>
  );
};

const TemplatesGallery = ({
  isLoading,
  templates,
  totalCount,
  setInspectedTemplate,
  onTemplateClick,
  selectedTemplate,
  setSelectedTemplate,
  paginationModel,
  setPaginationModel,
  propertyFilters,
  spacing,
  columns,
}: {
  isLoading: boolean;
  templates: Array<TemplatesDisplay_QuoteTemplate_GalleryFragment>;
  totalCount: number;
  setInspectedTemplate: (template: TemplateBase) => void;
  onTemplateClick?: (template: TemplateBase) => void;
  selectedTemplate?: TemplateBase | null;
  setSelectedTemplate?: (template: TemplateBase | null) => void;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  propertyFilters: TemplateSearchFiltersInput["properties"];
  spacing: number;
  columns: Columns;
}) => {
  const { t } = useTranslate(["Global"]);
  const { getFormattedPrice } = useFormatting();

  if (isLoading) return <LoadingSpinner />;
  if (!templates.length)
    return (
      <Box padding={2} display="flex" justifyContent="center">
        {t("There seems to be nothing here", {
          ns: "Global",
        })}
      </Box>
    );

  return (
    <Stack direction="column" overflow="auto" spacing={1} flex={1}>
      <Box flex={1} minHeight={0} overflow="auto">
        <Grid container spacing={spacing}>
          {templates.map((template, index) => {
            const attachments = template.attachments.map(processAttachment);

            const props = computeTemplateRelevantProperties({
              rootItemProps: template.rootItem.props2,
              templateTypeProps:
                template.implementsTemplateType?.templateType?.props2 ?? [],
            });

            const Info = hasTemplateTypeOrPropsInfo({
              templateTypeTitle:
                template.implementsTemplateType?.templateType.title,
              props,
            }) ? (
              <TemplateTypeAndPropsInfo
                templateTypeTitle={
                  template.implementsTemplateType?.templateType.title
                }
                props={props}
              />
            ) : null;

            return (
              <Grid key={template.id} item {...columns}>
                <MediaCardItem
                  key={template.id}
                  selected={template.id === selectedTemplate?.id}
                  onClick={() => {
                    if (onTemplateClick) {
                      onTemplateClick(template);
                    } else {
                      setSelectedTemplate?.(
                        selectedTemplate?.id === template.id ? null : template
                      );
                    }
                  }}
                  title={template.title}
                  description={template.description}
                  price={getFormattedPrice(
                    template.proposedCalculation?.priceNetTotal || 0
                  )}
                  imageUrl={attachments.find(isImage)?.url}
                  images={attachments.filter(isImageOr3dModel)}
                  ActionButton={
                    <ButtonCircleWrapper>
                      <IconButton
                        onClick={event => {
                          event.stopPropagation();
                          setInspectedTemplate(template);
                        }}
                      >
                        <InfoOutlinedIcon color="secondary" />
                      </IconButton>
                    </ButtonCircleWrapper>
                  }
                  Info={Info}
                  Tags={
                    template.customTags.length > 0 ? (
                      <TagChips tags={template.customTags} wrap={true} />
                    ) : null
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <TablePagination
        component="div"
        count={totalCount}
        page={paginationModel.page}
        onPageChange={(event, newPage) => {
          setPaginationModel({
            pageSize: paginationModel.pageSize,
            page: newPage,
          });
        }}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={event => {
          setPaginationModel({
            pageSize: parseInt(event.target.value, 10),
            page: 0,
          });
        }}
        labelRowsPerPage={t("Items per page:", {
          ns: "Global",
        })}
      />
    </Stack>
  );
};

const DEFAULT_SORTING: TemplateSearchSortingInput[] = [
  {
    fieldSorting: {
      field: "createdAt",
      direction: "desc",
    },
  },
];
const DEFAULT_FILTERS: TemplateSearchFiltersInput = {};

function useTemplateSearch({
  initialSearchValue = "",
  initialFilters = DEFAULT_FILTERS,
  initialSorting = DEFAULT_SORTING,
  fixedFilters = DEFAULT_FILTERS,
}: {
  initialSearchValue?: string;
  initialFilters?: TemplateSearchFiltersInput;
  initialSorting?: TemplateSearchSortingInput[];
  fixedFilters?: TemplateSearchFiltersInput;
}) {
  const [searchTerm, setSearchTerm] =
    React.useState<string>(initialSearchValue);

  const [internalFilters, setInternalFilters] =
    React.useState<TemplateSearchFiltersInput>(initialFilters);

  const [urlParamFilters, setUrlParamFilters] =
    useStateWithUrlParams<TemplateFiltersFormValues>(
      "templateFilters",
      templateFiltersDefaultValue,
      []
    );

  const [templateFilters, setTemplateFilters] =
    React.useState<TemplateFiltersFormValues>({
      templateTypeIds:
        fixedFilters?.templateTypeIds ??
        initialFilters?.templateTypeIds ??
        templateFiltersDefaultValue.templateTypeIds ??
        urlParamFilters.templateTypeIds,
      properties:
        fixedFilters?.properties ??
        initialFilters?.properties ??
        templateFiltersDefaultValue.properties ??
        urlParamFilters.properties,
    });

  const resetFilters = React.useCallback(
    () => setInternalFilters(initialFilters),
    [initialFilters]
  );
  const clearFilters = React.useCallback(() => setInternalFilters({}), []);

  const [sorting, setSorting] =
    React.useState<TemplateSearchSortingInput[]>(initialSorting);

  const [paginationModel, setPaginationModel] = React.useState<PaginationModel>(
    { page: 0, pageSize: 25 }
  );

  const filters = React.useMemo(
    (): TemplateSearchFiltersInput => ({
      sources: ["available"],
      ...urlParamFilters,
      ...internalFilters,
      ...templateFilters,
      ...fixedFilters,
    }),
    [fixedFilters, internalFilters, templateFilters, urlParamFilters]
  );

  const templateSearchVariables = React.useMemo(
    () => ({
      searchTerm: searchTerm,
      filters: filters,
    }),
    [filters, searchTerm]
  );

  const templateFilterFormikProps = useFormik<TemplateFiltersFormValues>({
    initialValues: templateFilters,
    validationSchema: templateFiltersValidationSchema,
    validateOnMount: true,
    onSubmit: async values => {
      setTemplateFilters(values);
      setUrlParamFilters(values);
    },
  });

  const resetTemplateFilters = React.useCallback(async () => {
    await templateFilterFormikProps.setValues({
      templateTypeIds:
        fixedFilters?.templateTypeIds ??
        initialFilters?.templateTypeIds ??
        templateFiltersDefaultValue.templateTypeIds,
      properties:
        fixedFilters?.properties ??
        initialFilters?.properties ??
        templateFiltersDefaultValue.properties,
    });
    await templateFilterFormikProps.submitForm();
  }, [
    fixedFilters?.properties,
    fixedFilters?.templateTypeIds,
    initialFilters?.properties,
    initialFilters?.templateTypeIds,
    templateFilterFormikProps,
  ]);

  useAutoSave({
    enableReinitialize: true,
    initialValues: templateFilters,
    formikProps: templateFilterFormikProps,
  });

  const client = useApolloClient();
  const query = useTemplatesDisplay_SearchTemplatesQuery({
    client,
    variables: {
      filters: {
        ...filters,
        createdAfter: filters?.createdAfter?.format("YYYY-MM-DD"),
        createdBefore: filters?.createdBefore?.format("YYYY-MM-DD"),
      },
      searchTerm,
      sorting,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
    },
  });

  const result = getDataOrNull(query.data?.searchTemplates);
  const templates = result?.edges.map(edge => edge.node) ?? [];
  const totalCount = result?.totalCount ?? 0;

  return {
    templateSearchVariables,
    templateFilterFormikProps,
    resetTemplateFilters,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters: setInternalFilters, // can only set internal filters, fixed will always be set on top
    templateFilters,
    setTemplateFilters,
    resetFilters,
    clearFilters,
    searchTerm,
    setSearchTerm,
    sorting,
    setSorting,
    isLoading: query.loading,
    templates,
    totalCount,
  };
}
