import { isEqual, omitBy } from "lodash-es";

export function getActiveQuickFilterValue<
  QueryVariables extends { [key: string]: any },
  Q extends { [key: string]: { values: Partial<QueryVariables> } },
>(
  quickFilterSettings: Q,
  ignoreUnlessExistsOnQuickFilterSetting: string[],
  queryVariables: QueryVariables | undefined
): keyof Q | undefined {
  if (!queryVariables) return;

  return (Object.keys(quickFilterSettings) as Array<keyof Q>).find(key => {
    return isEqual(
      omitBy(
        queryVariables,
        (value, path) =>
          value === undefined ||
          (ignoreUnlessExistsOnQuickFilterSetting.includes(path) &&
            quickFilterSettings[key].values[path] === undefined)
      ),
      quickFilterSettings[key].values
    );
  });
}
