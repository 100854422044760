/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateQuoteTemplateMutationVariables = Types.Exact<{
  input: Types.CreateQuoteTemplateInput;
}>;


export type CreateQuoteTemplateMutation = { createQuoteTemplate: { __typename: 'CreateQuoteTemplateResult', quoteTemplate: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null, type: Types.ItemType } } } };

export type CreateQuoteTemplateButton_SetItemProductMutationVariables = Types.Exact<{
  input: Types.SetItemProductInput;
}>;


export type CreateQuoteTemplateButton_SetItemProductMutation = { setItemProduct: { __typename: 'SetItemProductResult', item: { __typename: 'Item', id: string } } };


export const CreateQuoteTemplateDocument = gql`
    mutation CreateQuoteTemplate($input: CreateQuoteTemplateInput!) {
  createQuoteTemplate(input: $input) {
    quoteTemplate {
      id
      resolvedAsReadModelVersionNumber
      rootItem {
        id
        originVersionNumber
        type
      }
    }
  }
}
    `;
export type CreateQuoteTemplateMutationFn = Apollo.MutationFunction<CreateQuoteTemplateMutation, CreateQuoteTemplateMutationVariables>;

/**
 * __useCreateQuoteTemplateMutation__
 *
 * To run a mutation, you first call `useCreateQuoteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteTemplateMutation, { data, loading, error }] = useCreateQuoteTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteTemplateMutation, CreateQuoteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteTemplateMutation, CreateQuoteTemplateMutationVariables>(CreateQuoteTemplateDocument, options);
      }
export type CreateQuoteTemplateMutationHookResult = ReturnType<typeof useCreateQuoteTemplateMutation>;
export type CreateQuoteTemplateMutationResult = Apollo.MutationResult<CreateQuoteTemplateMutation>;
export type CreateQuoteTemplateMutationOptions = Apollo.BaseMutationOptions<CreateQuoteTemplateMutation, CreateQuoteTemplateMutationVariables>;
export const CreateQuoteTemplateButton_SetItemProductDocument = gql`
    mutation CreateQuoteTemplateButton_SetItemProduct($input: SetItemProductInput!) {
  setItemProduct(input: $input) {
    item {
      id
    }
  }
}
    `;
export type CreateQuoteTemplateButton_SetItemProductMutationFn = Apollo.MutationFunction<CreateQuoteTemplateButton_SetItemProductMutation, CreateQuoteTemplateButton_SetItemProductMutationVariables>;

/**
 * __useCreateQuoteTemplateButton_SetItemProductMutation__
 *
 * To run a mutation, you first call `useCreateQuoteTemplateButton_SetItemProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteTemplateButton_SetItemProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteTemplateButtonSetItemProductMutation, { data, loading, error }] = useCreateQuoteTemplateButton_SetItemProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteTemplateButton_SetItemProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteTemplateButton_SetItemProductMutation, CreateQuoteTemplateButton_SetItemProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteTemplateButton_SetItemProductMutation, CreateQuoteTemplateButton_SetItemProductMutationVariables>(CreateQuoteTemplateButton_SetItemProductDocument, options);
      }
export type CreateQuoteTemplateButton_SetItemProductMutationHookResult = ReturnType<typeof useCreateQuoteTemplateButton_SetItemProductMutation>;
export type CreateQuoteTemplateButton_SetItemProductMutationResult = Apollo.MutationResult<CreateQuoteTemplateButton_SetItemProductMutation>;
export type CreateQuoteTemplateButton_SetItemProductMutationOptions = Apollo.BaseMutationOptions<CreateQuoteTemplateButton_SetItemProductMutation, CreateQuoteTemplateButton_SetItemProductMutationVariables>;