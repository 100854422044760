import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LabeledValue, Modal, ModalOpenButton } from "@msys/ui";
import {
  Button,
  ButtonProps,
  DialogContentText,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import partition from "lodash.partition";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CompileDocIsolatedExpressionResultDiagnosticPropertyMapping,
  SetItemProps2Mapping,
  namedOperations,
} from "../../../../../clients/graphqlTypes.js";
import { ConfirmModal } from "../../../../commons/modals/ConfirmModal.js";
import { buildDocPath } from "../../../../utils.js";
import { ExpressionModal } from "../../../doc-items/modals/ExpressionModal.js";
import {
  TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnosticFragment,
  useQuoteTemplateUpdateToLatestAvailablePublishedVersionMutation,
  useTemplateLinkUpdateModalQuery,
} from "./QuoteTemplateUpdateToLatestPublishedVersionButton.generated.js";

interface Props extends ButtonProps {
  docId: string;
  linkingItemId: string;
  latestAvailableVersionNumber: number;
  pathToDoc: string;
}

export const QuoteTemplateUpdateToLatestPublishedVersionButton = ({
  docId,
  linkingItemId,
  latestAvailableVersionNumber,
  pathToDoc,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Global", "Templates", "TemplatesSharing"]);

  const client = useApolloClient();
  const [updateToLatestAvailablePublishVersion] =
    useQuoteTemplateUpdateToLatestAvailablePublishedVersionMutation({
      client,
      refetchQueries: [
        namedOperations.Query.TemplateQuoteRoutes,
        namedOperations.Query.TemplateQuoteEditItem,
        namedOperations.Query.TemplateQuoteEditPreview,
      ],
      awaitRefetchQueries: true,
    });

  async function handleSubmit(
    newPropertyMappings?: SetItemProps2Mapping[],
    handleClose?: () => void
  ) {
    const result = await updateToLatestAvailablePublishVersion({
      variables: {
        input: {
          docId,
          linkingItemId,
          newPropertyMappings,
        },
      },
    });

    if (!result.data) {
      throw Error("Failed to update template link");
    }

    if (
      result.data.updateLinkedItemToLatestAvailablePublishedVersion
        .__typename ===
      "UpdateLinkedItemToLatestAvailablePublishedVersionResultValidationError"
    ) {
      enqueueSnackbar(t("Template link update failed", { ns: "Templates" }), {
        variant: "error",
      });
      throw new UpdateLinkedItemToLatestAvailablePublishedVersionResultValidationError(
        "Template link update failed",
        result.data.updateLinkedItemToLatestAvailablePublishedVersion.results
      );
    } else {
      enqueueSnackbar(t("Template link updated", { ns: "Templates" }), {
        variant: "success",
      });
    }
  }

  return (
    <ModalOpenButton
      Modal={TemplateLinkUpdateModal}
      modalProps={{
        docId,
        pathToDoc,
        linkingItemId,
        latestAvailableVersionNumber,
        handleSubmit,
      }}
    >
      <Button size="small" color="inherit">
        {t("Update", { ns: "Global" })}
      </Button>
    </ModalOpenButton>
  );
};

function TemplateLinkUpdateModal({
  docId,
  linkingItemId,
  latestAvailableVersionNumber,
  handleClose,
  handleSubmit,
  pathToDoc,
}: {
  docId: string;
  linkingItemId: string;
  latestAvailableVersionNumber: number;
  handleClose: () => void;
  handleSubmit: (
    mappings?: SetItemProps2Mapping[],
    handleClose?: () => void
  ) => Promise<void>;
  pathToDoc: string;
}) {
  const navigate = useNavigate();
  const { t } = useTranslate(["Global", "Templates", "TemplatesSharing"]);

  const [newMappings, setNewMappings] = React.useState<SetItemProps2Mapping[]>(
    []
  );

  const client = useApolloClient();
  const query = useTemplateLinkUpdateModalQuery({
    client,
    variables: {
      docId,
      itemId: linkingItemId,
      overrides: [
        {
          itemId: linkingItemId,
          versionNumber: { versionNumber: latestAvailableVersionNumber },
        },
      ],
    },
  });

  const errors = query.data?.compileDocIsolatedExpression.results ?? [];
  const [mappingErrors, nonMappingErrors] = partition(
    errors,
    (
      error
    ): error is CompileDocIsolatedExpressionResultDiagnosticPropertyMapping =>
      error.__typename ===
      "CompileDocIsolatedExpressionResultDiagnosticPropertyMapping"
  );
  const hasNonMappingErrors = nonMappingErrors.length > 0;
  const mappings = getDataOrNull(query.data?.item)?.propertyMappings;

  React.useEffect(() => {
    if (mappings) {
      setNewMappings(
        mappings.map(mapping => ({
          from: { expr: mapping.from.expr },
          to: { key: mapping.to.key },
        }))
      );
    }
  }, [mappings]);

  if (query.loading) {
    return <Modal handleClose={() => {}} isLoading={true} />;
  }

  if (hasNonMappingErrors) {
    return (
      <Modal
        title={t("Update not possible due to errors in this template", {
          ns: "Templates",
        })}
        handleClose={handleClose}
        actionButtons={[
          {
            label: t("Cancel", { ns: "Global" }),
            handleClick: handleClose,
            buttonProps: { variant: "text" },
          },
          {
            label: t("Go to Troubleshooting page", {
              ns: "TemplatesSharing",
            }),
            handleClick: async () => {
              navigate(`${buildDocPath(pathToDoc, null)}/troubleshoot`);
            },
          },
        ]}
      ></Modal>
    );
  }

  if (mappingErrors.length > 0) {
    return (
      <Modal
        title={t("Updating failed due to errors in some formulas", {
          ns: "Templates",
        })}
        handleClose={handleClose}
        actionButtons={[
          { label: t("Cancel", { ns: "Global" }), handleClick: handleClose },
          {
            label: t("Save and update", { ns: "Global" }),
            handleClick: async () => {
              await handleSubmit(newMappings);
              handleClose();
            },
            buttonProps: {
              disabled: mappingErrors.some(mappingError =>
                newMappings.some(
                  newMapping =>
                    newMapping.to.key === mappingError.toKey &&
                    newMapping.from.expr === mappingError.fromExpr
                )
              ),
            },
          },
        ]}
        maxWidth="lg"
      >
        <DialogContentText>
          {t(
            "Changes in the new version will cause some of your existing formulas to fail. Please delete or adjust the formulas below to update.",
            { ns: "Templates" }
          )}
        </DialogContentText>
        <Grid container columns={5} spacing={1} padding={1}>
          {mappingErrors && mappingErrors.length > 0 ? (
            mappingErrors.map(mappingError => {
              const newMapping = newMappings?.find(
                mapping => mapping.to.key === mappingError.toKey
              );
              if (!newMapping) return null;
              return (
                <>
                  <Grid item xs={1}>
                    <LabeledValue label={t("Property", { ns: "Templates" })}>
                      {mappingError.toKey}
                    </LabeledValue>
                  </Grid>
                  <Grid item xs={2}>
                    <LabeledValue label={t("Expression", { ns: "Templates" })}>
                      <ModalOpenButton
                        Modal={ExpressionModal}
                        modalProps={{
                          projectId: null,
                          isFullScreenLocalStorageKey:
                            "newPropertyMappingExpressions",
                          docId,
                          itemId: linkingItemId,
                          expression: newMapping.from.expr,
                          expressionError:
                            newMapping.from.expr === mappingError.fromExpr
                              ? new Error(mappingError.messageText)
                              : undefined,
                          handleDelete: handleClose => {
                            setNewMappings(state => {
                              return state.filter(m => m !== newMapping);
                            });
                            handleClose();
                          },
                          handleSave: (expression, handleClose) => {
                            setNewMappings(state =>
                              state?.map(m =>
                                m === newMapping
                                  ? { ...m, from: { expr: expression } }
                                  : m
                              )
                            );
                            handleClose();
                          },
                        }}
                      >
                        <Link>{newMapping.from.expr}</Link>
                      </ModalOpenButton>
                    </LabeledValue>
                  </Grid>
                  <Grid item xs={2}>
                    <LabeledValue
                      label={t("Error message", { ns: "Templates" })}
                      color={
                        newMapping.from.expr === mappingError?.fromExpr
                          ? "error"
                          : "warning"
                      }
                    >
                      {newMapping.from.expr === mappingError?.fromExpr
                        ? mappingError?.messageText
                        : "???"}
                    </LabeledValue>
                  </Grid>
                </>
              );
            })
          ) : (
            <Typography variant={"caption"}>
              {t("All property mappings removed", { ns: "Templates" })}
            </Typography>
          )}
        </Grid>
      </Modal>
    );
  }

  return (
    <ConfirmModal
      handleClose={handleClose}
      handleConfirm={async () => {
        await handleSubmit();
        handleClose();
      }}
    />
  );
}

class UpdateLinkedItemToLatestAvailablePublishedVersionResultValidationError extends Error {
  errors: TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnosticFragment[];

  constructor(
    message: string,
    errors: TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnosticFragment[]
  ) {
    super(message);

    this.errors = errors;
  }
}
