import { notNull } from "@msys/common";
import { CollapseSection, LoadingSpinner, Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { FormikProps, useFormik } from "formik";
import {
  TemplateFiltersFormValues,
  useTemplateFilterFields,
  templateFiltersDefaultValue,
  templateFiltersValidationSchema,
} from "./TemplateFilterFields.js";
import { useTranslate } from "@tolgee/react";

interface Props
  extends Pick<
    Parameters<typeof useTemplateFilterFields>[0],
    "templateSearchVariables" | "fixedFilters"
  > {
  title: string;
  handleClose: () => void;
  formikProps: FormikProps<TemplateFiltersFormValues>;
  onReset?(): void;
  onCancel?(): void;
  submitButtonTitle?: string;
}

export const TemplateTypeSearchFilterModal = ({
  title,
  handleClose,
  formikProps: outsideFormikProps,
  onCancel,
  onReset,
  submitButtonTitle,
  ...props
}: Props) => {
  const { t } = useTranslate(["Global", "TemplatesSearch"]);

  // we need an inner formik state to not immediately apply filters on the list
  const formikProps = useFormik<TemplateFiltersFormValues>({
    // @ts-ignore
    initialValues: {
      ...templateFiltersDefaultValue,
      ...props.templateSearchVariables.filters,
      ...outsideFormikProps.values,
    },
    enableReinitialize: false,
    validationSchema: templateFiltersValidationSchema,
    validateOnMount: true,
    onSubmit: async (newValues, formikHelpers) => {
      outsideFormikProps.setValues(newValues);
      outsideFormikProps.submitForm();
    },
  });

  const { nonPropertyFields, propertyFields, isLoading } =
    useTemplateFilterFields({
      formikProps,
      ...props,
    });

  const { isValid, dirty } = formikProps;

  return (
    <Modal
      dialogProps={{ maxWidth: "xs" }}
      title={title}
      handleClose={handleClose}
      headerButtons={
        isLoading ? (
          <div>
            <LoadingSpinner size="s" padding={0.5} />
          </div>
        ) : undefined
      }
      // @ts-ignore
      actionButtons={[
        onCancel
          ? {
              label: t("Cancel", { ns: "Global" }),
              handleClick: () => {
                onCancel?.();
                handleClose();
              },
              buttonProps: {
                variant: "text",
              },
            }
          : null,
        onReset
          ? {
              label: t("Reset", { ns: "Global" }),
              handleClick: () => {
                onReset?.();
                handleClose();
              },
              buttonProps: {
                variant: "text",
              },
            }
          : null,
        {
          label: submitButtonTitle ?? t("Apply", { ns: "Global" }),
          handleClick: async () => {
            await formikProps.submitForm();
            handleClose();
          },
          buttonProps: {
            disabled: !dirty || !isValid,
          },
        },
      ].filter(notNull)}
      alwaysVisible
    >
      <Stack direction="column" spacing={1}>
        {nonPropertyFields.length > 0 && (
          <Stack direction="column" spacing={1}>
            {nonPropertyFields}
          </Stack>
        )}

        {propertyFields.length > 0 && (
          <CollapseSection
            title={t("Properties", { ns: "TemplatesSearch" })}
            isInitiallyExpanded={true}
            itemCount={propertyFields.length}
          >
            <Stack direction="column" spacing={1}>
              {propertyFields}
            </Stack>
          </CollapseSection>
        )}
      </Stack>
    </Modal>
  );
};
