import { gql, useApolloClient } from "@apollo/client";
import { Select } from "@msys/ui";
import { Grid, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { ItemBinding, ItemType } from "../../../../clients/graphqlTypes.js";
import { useItemStateMenuItem__SetItemBindingMutation } from "./ItemStateMenuItem.generated.js";
import { useTranslate } from "@tolgee/react";

export const ItemStateMenuItem = ({
  projectId,
  docId,
  itemId,
  itemType,
  itemState,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  itemType: ItemType;
  itemState: ItemBinding;
}) => {
  const { t } = useTranslate("QuoteItem");

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [setItemBinding, { loading: setItemBindingLoading }] =
    useItemStateMenuItem__SetItemBindingMutation({
      client,
    });

  const modifyItemState = React.useCallback(
    async (values: { binding: ItemBinding }) => {
      if (itemType !== "paid") return;

      try {
        await setItemBinding({
          variables: {
            input: {
              projectId,
              docId,
              itemId,
              binding: values.binding,
            },
          },
        });
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [projectId, docId, itemId, itemType, setItemBinding, enqueueSnackbar]
  );

  return (
    <MenuItem
      disableRipple
      onClick={(event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
      }}
    >
      <Grid container alignItems="center" minWidth={"310px"}>
        <Grid item xs={5}>
          {t("Item state")}
        </Grid>
        <Grid item xs={7} minWidth={"180px"}>
          <Select
            autoWidth
            fullWidth
            disabled={setItemBindingLoading}
            value={itemState}
            label={t("Item state")}
            size={"extra-small"}
            onChange={async (value: ItemBinding) => {
              await modifyItemState({ binding: value });
            }}
            options={[
              { value: "binding", label: t("Binding") } as const,
              { value: "non_binding", label: t("Non-binding") } as const,
              { value: "draft", label: t("Draft") } as const,
            ]}
          />
        </Grid>
      </Grid>
    </MenuItem>
  );
};
