/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubscribeToQuoteTemplateMutationVariables = Types.Exact<{
  input: Types.SubscribeToQuoteTemplateInput;
}>;


export type SubscribeToQuoteTemplateMutation = { subscribeToQuoteTemplate: { __typename: 'SubscribeToQuoteTemplateResult', quoteTemplate: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null } } };

export type QuoteTemplateSubscribeButton_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null };

export const QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc = gql`
    fragment QuoteTemplateSubscribeButton_QuoteTemplate on QuoteTemplate {
  id
  subscribedToTemplate {
    subscribedVersionNumber
    updateAvailable
  }
}
    `;
export const SubscribeToQuoteTemplateDocument = gql`
    mutation SubscribeToQuoteTemplate($input: SubscribeToQuoteTemplateInput!) {
  subscribeToQuoteTemplate(input: $input) {
    quoteTemplate {
      id
      resolvedAsReadModelVersionNumber
      ...QuoteTemplateSubscribeButton_QuoteTemplate
    }
  }
}
    ${QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc}`;
export type SubscribeToQuoteTemplateMutationFn = Apollo.MutationFunction<SubscribeToQuoteTemplateMutation, SubscribeToQuoteTemplateMutationVariables>;

/**
 * __useSubscribeToQuoteTemplateMutation__
 *
 * To run a mutation, you first call `useSubscribeToQuoteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToQuoteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToQuoteTemplateMutation, { data, loading, error }] = useSubscribeToQuoteTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeToQuoteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToQuoteTemplateMutation, SubscribeToQuoteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeToQuoteTemplateMutation, SubscribeToQuoteTemplateMutationVariables>(SubscribeToQuoteTemplateDocument, options);
      }
export type SubscribeToQuoteTemplateMutationHookResult = ReturnType<typeof useSubscribeToQuoteTemplateMutation>;
export type SubscribeToQuoteTemplateMutationResult = Apollo.MutationResult<SubscribeToQuoteTemplateMutation>;
export type SubscribeToQuoteTemplateMutationOptions = Apollo.BaseMutationOptions<SubscribeToQuoteTemplateMutation, SubscribeToQuoteTemplateMutationVariables>;