/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type BuildingSubHeader_BuildingFragment = { __typename: 'Building', id: string, title: string };

export const BuildingSubHeader_BuildingFragmentDoc = gql`
    fragment BuildingSubHeader_Building on Building {
  id
  title
}
    `;