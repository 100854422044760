/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendProjectStateEventMutationVariables = Types.Exact<{
  input: Types.SendProjectStateEventInput;
}>;


export type SendProjectStateEventMutation = { sendProjectStateEvent: { __typename: 'Project', id: string, state: Types.ProjectStateMachineStatus, phase: { __typename: 'OrganisationProjectPhase', id: string, name: string } } };

export type UseProjectChangeStateQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type UseProjectChangeStateQuery = { projectOutgoingQuotes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'ProjectOutgoingQuoteConnection', totalCount: number } };


export const SendProjectStateEventDocument = gql`
    mutation SendProjectStateEvent($input: SendProjectStateEventInput!) {
  sendProjectStateEvent(input: $input) {
    id
    state
    phase {
      id
      name
    }
  }
}
    `;
export type SendProjectStateEventMutationFn = Apollo.MutationFunction<SendProjectStateEventMutation, SendProjectStateEventMutationVariables>;

/**
 * __useSendProjectStateEventMutation__
 *
 * To run a mutation, you first call `useSendProjectStateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendProjectStateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendProjectStateEventMutation, { data, loading, error }] = useSendProjectStateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendProjectStateEventMutation(baseOptions?: Apollo.MutationHookOptions<SendProjectStateEventMutation, SendProjectStateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendProjectStateEventMutation, SendProjectStateEventMutationVariables>(SendProjectStateEventDocument, options);
      }
export type SendProjectStateEventMutationHookResult = ReturnType<typeof useSendProjectStateEventMutation>;
export type SendProjectStateEventMutationResult = Apollo.MutationResult<SendProjectStateEventMutation>;
export type SendProjectStateEventMutationOptions = Apollo.BaseMutationOptions<SendProjectStateEventMutation, SendProjectStateEventMutationVariables>;
export const UseProjectChangeStateDocument = gql`
    query UseProjectChangeState($projectId: ID!) {
  projectOutgoingQuotes(
    projectId: $projectId
    limit: 0
    filters: {isPublished: true, agreement: NONE}
  ) {
    ... on ProjectOutgoingQuoteConnection {
      totalCount
    }
  }
}
    `;

/**
 * __useUseProjectChangeStateQuery__
 *
 * To run a query within a React component, call `useUseProjectChangeStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseProjectChangeStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseProjectChangeStateQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUseProjectChangeStateQuery(baseOptions: Apollo.QueryHookOptions<UseProjectChangeStateQuery, UseProjectChangeStateQueryVariables> & ({ variables: UseProjectChangeStateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseProjectChangeStateQuery, UseProjectChangeStateQueryVariables>(UseProjectChangeStateDocument, options);
      }
export function useUseProjectChangeStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseProjectChangeStateQuery, UseProjectChangeStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseProjectChangeStateQuery, UseProjectChangeStateQueryVariables>(UseProjectChangeStateDocument, options);
        }
export function useUseProjectChangeStateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UseProjectChangeStateQuery, UseProjectChangeStateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseProjectChangeStateQuery, UseProjectChangeStateQueryVariables>(UseProjectChangeStateDocument, options);
        }
export type UseProjectChangeStateQueryHookResult = ReturnType<typeof useUseProjectChangeStateQuery>;
export type UseProjectChangeStateLazyQueryHookResult = ReturnType<typeof useUseProjectChangeStateLazyQuery>;
export type UseProjectChangeStateSuspenseQueryHookResult = ReturnType<typeof useUseProjectChangeStateSuspenseQuery>;
export type UseProjectChangeStateQueryResult = Apollo.QueryResult<UseProjectChangeStateQuery, UseProjectChangeStateQueryVariables>;