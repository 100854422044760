import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { CrmCompanySelect } from "../crm-companies/CrmCompanySelect.js";
import { ProjectSelect } from "../projects/ProjectSelect.js";

interface Props {
  handleClose: () => void;
  handleComplete: (
    crmCompanyId: string | null,
    projectId: string | null
  ) => void;
  disableBlockedBusinessPartners?: boolean;
}

export const CompanyAndProjectSelectModal = ({
  handleClose,
  handleComplete,
  disableBlockedBusinessPartners,
}: Props) => {
  const { t } = useTranslate(["Global", "Task"]);

  const [selectedCompanyId, setSelectedCompanyId] = React.useState<
    string | null
  >(null);
  const [selectedProjectId, setSelectedProjectId] = React.useState<
    string | null
  >(null);

  return (
    <Modal
      title={t("Link to", {
        ns: "Task",
      })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "outlined" },
        },
        {
          label: t("Confirm", {
            ns: "Global",
          }),
          handleClick: () =>
            handleComplete(selectedCompanyId, selectedProjectId),
          buttonProps: {
            variant: "contained",
          },
        },
      ]}
    >
      <Stack spacing={1}>
        <CrmCompanySelect
          crmCompanyId={selectedCompanyId ?? undefined}
          filterProjectId={selectedProjectId ?? undefined}
          onChange={crmCompany => setSelectedCompanyId(crmCompany?.id ?? null)}
          inputLabel={t("CRM contact", { ns: "Task" })}
          required={false}
          canCreateNew
          createNewLabel={t("Add new contact", { ns: "Task" })}
          disableBlockedBusinessPartners={disableBlockedBusinessPartners}
        />
        <ProjectSelect
          crmCompanyId={selectedCompanyId ?? undefined}
          projectId={selectedProjectId}
          onChange={setSelectedProjectId}
          inputLabel={t("Project", { ns: "Task" })}
          pageName="Todos"
          canCreateNew={true}
          onCreateAutoNavigate={false}
        />
      </Stack>
    </Modal>
  );
};
