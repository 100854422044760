/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type RequirementHeader__RequirementFragment = { __typename: 'Requirement', id: string, number: string, publishedAt?: any | null, createdAt: any, originalProjectNumber: string, contractee: { __typename: 'DocActor', id: string, companyOrIndividual: Types.DocActorCompanyOrIndividual, companyName: string, firstname: string, familyname: string, phone: string, address?: { __typename: 'AddressSnapshot', city: string, countryCode: string, postalCode: string, streetLines1: string } | null, contact?: { __typename: 'DocActorContact', fullname: string } | null } };

export const RequirementHeader__RequirementFragmentDoc = gql`
    fragment RequirementHeader__Requirement on Requirement {
  id
  number
  publishedAt
  createdAt
  originalProjectNumber
  contractee {
    id
    companyOrIndividual
    companyName
    firstname
    familyname
    phone
    address {
      city
      countryCode
      postalCode
      streetLines1
    }
    contact {
      fullname
    }
  }
}
    `;