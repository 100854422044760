import { PimDiscountGroupDiscountType } from "../../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

export const useDiscountGroupTypes = () => {
  const { t } = useTranslate(["Product"]);

  const discountGroupTypeLabels: Record<PimDiscountGroupDiscountType, string> =
    {
      DISCOUNT: t("Discount", {
        ns: "Product",
      }),
      FACTOR: t("Factor", {
        ns: "Product",
      }),
      SURCHARGE: t("Surcharge", {
        ns: "Product",
      }),
    };

  return { discountGroupTypeLabels };
};
