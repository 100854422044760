import React from "react";
import { ALL_CONTRACT_TYPES } from "../../constants.js";
import { ContractType } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";
import { SelectField, SelectFieldProps, SelectOption } from "./SelectField.js";

export const useContractTypeNameLabels = (): Record<ContractType, string> => {
  const { t } = useTranslate("ContractTypeField");

  return React.useMemo(
    (): Record<ContractType, string> => ({
      fup: t("Fixed unit price"),
      fmsp_fr: t(
        "Time and material (fixed material unit price + variable time, fixed rate)"
      ),
      fmbp_fr: t(
        "Time and material (material price at cost + variable time, fixed rate)"
      ),
      lumpsum: t("Fixed price (lump sum)"),
    }),
    [t]
  );
};

export const useContractTypeDescriptionLabels = () => {
  const { t } = useTranslate("ContractTypeField");

  return React.useMemo(
    (): Record<ContractType, string> => ({
      fup: t(
        "The price per unit of performed work (incl. material and labor) is fixed as agreed with the client. Quantities are first estimated in quote, but actual quantities are invoiced."
      ),
      fmsp_fr: t(
        "Price per unit of material and rate per unit of labor are agreed with the client. Quantities of material and labor are estimated in quote, but actual quantities are invoiced."
      ),
      fmbp_fr: t(
        "Only rate per unit of labor is agreed with the client. Price of material is variable and depends on selected product and consumption. Prices are estimated in quote, but actual quantity of labor and selected and consumed material are invoiced."
      ),
      lumpsum: t(
        "The price per quote item is fixed as agreed with the client. Items are invoiced as agreed in the quote, and changes in quantity (increase or decrease) are not accounted for."
      ),
    }),
    [t]
  );
};

export const ContractTypeField = (props: SelectFieldProps<ContractType>) => {
  const nameLabels = useContractTypeNameLabels();

  const options: SelectOption<ContractType>[] = React.useMemo(
    () =>
      ALL_CONTRACT_TYPES.map(contractType => ({
        label: nameLabels[contractType],
        value: contractType,
      })),
    [nameLabels]
  );

  return <SelectField {...props} options={options} />;
};
