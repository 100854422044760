/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PendingDocSharesBox_PendingDocSharesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PendingDocSharesBox_PendingDocSharesQuery = { pendingDocShares: Array<{ __typename: 'PendingDocShare', id: string, docTitle: string, sharingSystemOrganisationId: string, sharingSystemOrganisationTitle: string, recipientSystemOrganisationId: string, recipientSystemOrganisationTitle: string, recipientPermissions: Array<Types.PermissionName>, docOwningSystemOrganisationId: string, docOwningSystemOrganisationTitle: string }> };

export type PendingDocSharesBox_AcceptDocShareMutationVariables = Types.Exact<{
  input: Types.AcceptDocShareInput;
}>;


export type PendingDocSharesBox_AcceptDocShareMutation = { acceptDocShare: { __typename: 'AcceptDocShareResult', docId: string, projectId: string } };


export const PendingDocSharesBox_PendingDocSharesDocument = gql`
    query PendingDocSharesBox_PendingDocShares {
  pendingDocShares {
    id
    docTitle
    sharingSystemOrganisationId
    sharingSystemOrganisationTitle
    recipientSystemOrganisationId
    recipientSystemOrganisationTitle
    recipientPermissions
    docOwningSystemOrganisationId
    docOwningSystemOrganisationTitle
  }
}
    `;

/**
 * __usePendingDocSharesBox_PendingDocSharesQuery__
 *
 * To run a query within a React component, call `usePendingDocSharesBox_PendingDocSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingDocSharesBox_PendingDocSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingDocSharesBox_PendingDocSharesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingDocSharesBox_PendingDocSharesQuery(baseOptions?: Apollo.QueryHookOptions<PendingDocSharesBox_PendingDocSharesQuery, PendingDocSharesBox_PendingDocSharesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingDocSharesBox_PendingDocSharesQuery, PendingDocSharesBox_PendingDocSharesQueryVariables>(PendingDocSharesBox_PendingDocSharesDocument, options);
      }
export function usePendingDocSharesBox_PendingDocSharesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingDocSharesBox_PendingDocSharesQuery, PendingDocSharesBox_PendingDocSharesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingDocSharesBox_PendingDocSharesQuery, PendingDocSharesBox_PendingDocSharesQueryVariables>(PendingDocSharesBox_PendingDocSharesDocument, options);
        }
export function usePendingDocSharesBox_PendingDocSharesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PendingDocSharesBox_PendingDocSharesQuery, PendingDocSharesBox_PendingDocSharesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PendingDocSharesBox_PendingDocSharesQuery, PendingDocSharesBox_PendingDocSharesQueryVariables>(PendingDocSharesBox_PendingDocSharesDocument, options);
        }
export type PendingDocSharesBox_PendingDocSharesQueryHookResult = ReturnType<typeof usePendingDocSharesBox_PendingDocSharesQuery>;
export type PendingDocSharesBox_PendingDocSharesLazyQueryHookResult = ReturnType<typeof usePendingDocSharesBox_PendingDocSharesLazyQuery>;
export type PendingDocSharesBox_PendingDocSharesSuspenseQueryHookResult = ReturnType<typeof usePendingDocSharesBox_PendingDocSharesSuspenseQuery>;
export type PendingDocSharesBox_PendingDocSharesQueryResult = Apollo.QueryResult<PendingDocSharesBox_PendingDocSharesQuery, PendingDocSharesBox_PendingDocSharesQueryVariables>;
export const PendingDocSharesBox_AcceptDocShareDocument = gql`
    mutation PendingDocSharesBox_AcceptDocShare($input: AcceptDocShareInput!) {
  acceptDocShare(input: $input) {
    docId
    projectId
  }
}
    `;
export type PendingDocSharesBox_AcceptDocShareMutationFn = Apollo.MutationFunction<PendingDocSharesBox_AcceptDocShareMutation, PendingDocSharesBox_AcceptDocShareMutationVariables>;

/**
 * __usePendingDocSharesBox_AcceptDocShareMutation__
 *
 * To run a mutation, you first call `usePendingDocSharesBox_AcceptDocShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePendingDocSharesBox_AcceptDocShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pendingDocSharesBoxAcceptDocShareMutation, { data, loading, error }] = usePendingDocSharesBox_AcceptDocShareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePendingDocSharesBox_AcceptDocShareMutation(baseOptions?: Apollo.MutationHookOptions<PendingDocSharesBox_AcceptDocShareMutation, PendingDocSharesBox_AcceptDocShareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PendingDocSharesBox_AcceptDocShareMutation, PendingDocSharesBox_AcceptDocShareMutationVariables>(PendingDocSharesBox_AcceptDocShareDocument, options);
      }
export type PendingDocSharesBox_AcceptDocShareMutationHookResult = ReturnType<typeof usePendingDocSharesBox_AcceptDocShareMutation>;
export type PendingDocSharesBox_AcceptDocShareMutationResult = Apollo.MutationResult<PendingDocSharesBox_AcceptDocShareMutation>;
export type PendingDocSharesBox_AcceptDocShareMutationOptions = Apollo.BaseMutationOptions<PendingDocSharesBox_AcceptDocShareMutation, PendingDocSharesBox_AcceptDocShareMutationVariables>;