import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  Autocomplete,
  Modal,
  ModalOpenButton,
  RadioGroup,
  getFilterOptions,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { DocType, namedOperations } from "../../../../clients/graphqlTypes.js";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { CrmCompanySelect } from "../../crm-companies/CrmCompanySelect.js";
import { CrmContactType } from "../../crm/useCrmContactTypes.js";
import { ProjectSelect } from "../../projects/ProjectSelect.js";
import { QuoteCreateModal } from "../../quotes/modals/QuoteCreateModal.js";
import { RequirementCreateModal } from "../../requirements/CreateRequirementModal.js";
import { ShopCartFragment } from "../Shop.generated.js";
import {
  ShopProjectFragment,
  ShopProjectsByCrmCompanyIdDocument,
  ShopProjectsByCrmCompanyIdQuery,
  ShopProjectsByCrmCompanyIdQueryVariables,
  ShopProjectsByProjectIdDocument,
  ShopProjectsByProjectIdQuery,
  ShopProjectsByProjectIdQueryVariables,
  useSelectShopQuotesQuery,
  useSelectShopRequirementsQuery,
  useShopProjectsByProjectIdQuery,
} from "./ShopListPickerModal.generated.js";

const ALLOWED_CONTACT_TYPES: CrmContactType[] = ["INDIVIDUAL", "COMPANY"];

const filterOptionsByTitle = getFilterOptions(["title"]);

interface Props {
  title?: string;
  handleClose: () => void;
  handleComplete: (cartId: string) => void | Promise<void>;
  selectedCart: ShopCartFragment | null | undefined;
}

export const ShopListPickerModal = ({
  title,
  handleComplete,
  handleClose,
  selectedCart,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const [docType, setDocType] = React.useState<DocType>(
    selectedCart && selectedCart.__typename === "Quote"
      ? "QUOTE"
      : "REQUIREMENT"
  );
  const [cartId, setCartId] = React.useState<string | null>(
    selectedCart?.id ?? null
  );
  const [projectId, setProjectId] = React.useState<string | null>(
    selectedCart?.projectId ?? null
  );
  const [organisationId, setOrganisationId] = React.useState<string | null>(
    null
  );

  const { fetchProjectById } = useFetchShopData();

  // setting initial organisation id
  const checkedOrganisationId = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (checkedOrganisationId.current) return;
    checkedOrganisationId.current = true;
    if (!projectId || !viewer.organisation.capabilities.includes("QUOTING"))
      return;

    (async () => {
      const selectedProject = await fetchProjectById(projectId);
      if (selectedProject) {
        if (viewer.organisation.capabilities.includes("QUOTING")) {
          setOrganisationId(selectedProject.crmOrganisation?.id ?? null);
        }
      }
    })();
  }, [fetchProjectById, projectId, viewer.organisation.capabilities]);

  const { t } = useTranslate(["Shop", "Global"]);

  const client = useApolloClient();

  // needed for permissions check
  const projectsQuery = useShopProjectsByProjectIdQuery({
    client,
    variables: { projectId: projectId! },
    skip: !projectId,
  });
  const projects = React.useMemo(
    () =>
      getDataOrNull(projectsQuery?.data?.projects)?.edges.map(e => e.node) ??
      [],
    [projectsQuery?.data?.projects]
  );
  const selectedProject = React.useMemo(
    () => projects.find(p => p.id === projectId) ?? null,
    [projects, projectId]
  );

  const handleCartChange = (
    value: { id: string; projectId: string } | null
  ) => {
    setCartId(value?.id ?? null);

    // setting project id as well
    if (value?.projectId) {
      setProjectId(value.projectId);
    }
    // setting organisation as well
    if (
      value?.projectId &&
      !organisationId &&
      viewer.organisation.capabilities.includes("QUOTING")
    ) {
      (async () => {
        const selectedProject = await fetchProjectById(value.projectId!);
        if (selectedProject) {
          if (viewer.organisation.capabilities.includes("QUOTING")) {
            setOrganisationId(selectedProject.crmOrganisation?.id ?? null);
          }
        }
      })();
    }
  };
  const handleProjectChange = (newProjectId: string | null) => {
    setProjectId(newProjectId);
    setCartId(null);

    // setting organisation as well
    if (
      newProjectId &&
      !organisationId &&
      viewer.organisation.capabilities.includes("QUOTING")
    ) {
      (async () => {
        const selectedProject = await fetchProjectById(newProjectId);
        if (selectedProject) {
          if (viewer.organisation.capabilities.includes("QUOTING")) {
            setOrganisationId(selectedProject.crmOrganisation?.id ?? null);
          }
        }
      })();
    }
  };
  const handleCrmOrganisationChange = (value: { id: string } | null) => {
    setOrganisationId(value?.id || null);
    setProjectId(null);
    setCartId(null);
  };

  return (
    <Modal
      title={
        title ??
        t("Select a list", {
          ns: "Shop",
        })
      }
      dialogProps={{ maxWidth: "sm" }}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          handleClick: async () => {
            if (!cartId) return;
            setSubmitting(true);
            try {
              await handleComplete(cartId);
            } catch (e) {
              throw e;
            } finally {
              setSubmitting(false);
            }
            handleClose();
          },
          buttonProps: {
            disabled: !cartId || isSubmitting,
          },
        },
      ]}
      handleClose={handleClose}
    >
      <Stack direction="column" spacing={1}>
        <RestrictedByCapabilityWithDebug capability="QUOTING">
          <CrmCompanySelect
            crmCompanyId={organisationId}
            onChange={handleCrmOrganisationChange}
            inputLabel={t("Select client", { ns: "Shop" })}
            canCreateNew={true}
            createModalTitle={t("Create client", { ns: "Shop" })}
            allowedContactTypesForCreate={ALLOWED_CONTACT_TYPES}
          />
        </RestrictedByCapabilityWithDebug>
        <ProjectSelect
          crmCompanyId={organisationId ?? undefined}
          projectId={projectId}
          onChange={handleProjectChange}
          inputLabel={t("Select project", { ns: "Shop" })}
          pageName="Shop"
          canCreateNew={true}
          onCreateAutoNavigate={false}
          desiredProjectInitialStatus="opportunity"
        />
        <RestrictedByCapabilityWithDebug capability="QUOTING">
          <RadioGroup
            inline
            options={[
              {
                value: "QUOTE",
                label: t("Quote", { ns: "Shop" }),
              },
              {
                value: "REQUIREMENT",
                label: t("Requirement", { ns: "Shop" }),
              },
            ]}
            value={docType}
            onChange={value => {
              setDocType(value);
              setCartId(null);
            }}
          />
        </RestrictedByCapabilityWithDebug>
        {docType === "QUOTE" && (
          <SelectShopQuote
            cartId={cartId}
            onChange={handleCartChange}
            projectId={projectId}
            crmOrganisationId={organisationId}
            selectedProject={selectedProject}
          />
        )}
        {docType === "REQUIREMENT" && (
          <SelectShopRequirement
            cartId={cartId}
            onChange={handleCartChange}
            projectId={projectId}
            crmOrganisationId={
              viewer.organisation.capabilities.includes("QUOTING")
                ? organisationId
                : null
            }
            selectedProject={selectedProject}
          />
        )}
      </Stack>
    </Modal>
  );
};

function useFetchShopData() {
  const client = useApolloClient();

  const fetchProjectById = React.useCallback(
    async (projectId: string) => {
      const result = await client.query<
        ShopProjectsByProjectIdQuery,
        ShopProjectsByProjectIdQueryVariables
      >({
        query: ShopProjectsByProjectIdDocument,
        variables: {
          projectId,
        },
      });
      return (
        getDataOrNull(result?.data?.projects)?.edges.map(e => e.node)[0] ?? null
      );
    },
    [client]
  );

  const fetchProjectsCrmCompanyId = React.useCallback(
    async (crmCompanyId: string) => {
      const result = await client.query<
        ShopProjectsByCrmCompanyIdQuery,
        ShopProjectsByCrmCompanyIdQueryVariables
      >({
        query: ShopProjectsByCrmCompanyIdDocument,
        variables: {
          crmCompanyId,
        },
      });
      return getDataOrNull(result?.data?.projects)?.edges.map(e => e.node);
    },
    [client]
  );

  return { fetchProjectById, fetchProjectsCrmCompanyId };
}

function SelectShopQuote({
  cartId,
  onChange,
  projectId,
  crmOrganisationId,
  selectedProject,
}: {
  cartId: string | null;
  onChange: (cart: { id: string; projectId: string } | null) => void;
  crmOrganisationId: string | null;
  projectId: string | null;
  selectedProject: ShopProjectFragment | null;
}) {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Shop", "Global"]);
  const client = useApolloClient();

  const query = useSelectShopQuotesQuery({
    client,
    variables: {
      projectId,
      filterDocActors: crmOrganisationId
        ? [
            { type: "CLIENT", crmOrganisationId },
            { type: "CONTRACTEE", crmOrganisationId },
            { type: "CONTRACTOR", crmOrganisationId },
          ]
        : undefined, // FIXME: which type(s) should we use?
    },
  });
  const quotes = React.useMemo(
    () =>
      getDataOrNull(query?.data?.outgoingQuotes)?.edges.map(e => e.node) ?? [],
    [query?.data?.outgoingQuotes]
  );

  return (
    <Stack direction="row" spacing={1}>
      <Autocomplete
        inputLabel={t("Select list", { ns: "Shop" })}
        options={quotes}
        getOptionLabel={option => option.title}
        filterOptions={filterOptionsByTitle}
        required
        value={quotes.find(q => q.id === cartId) ?? null}
        onChange={value => {
          onChange(value);
        }}
      />
      {selectedProject && (
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_QUOTES"
          project={selectedProject}
        >
          {(!(selectedProject.state === "opportunity") ||
            selectedProject.incomingQuoteRequests.length === 0 ||
            selectedProject.incomingQuoteRequests.some(
              request =>
                request.status === "PUBLISHED" ||
                request.wonBySystemOrganisationId === viewer.organisation.id
            )) && (
            <ModalOpenButton
              Modal={QuoteCreateModal}
              modalProps={{
                projectId: selectedProject.id,
                handleComplete: async (quoteId, handleClose) => {
                  onChange(
                    quoteId
                      ? { id: quoteId, projectId: selectedProject.id }
                      : null
                  );
                  handleClose();
                },
                refetchQueries: [namedOperations.Query.SelectShopQuotes],
              }}
            >
              <IconButton color="primary" size="large">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          )}
        </RestrictedByProjectPermissionWithDebug>
      )}
    </Stack>
  );
}

function SelectShopRequirement({
  cartId,
  onChange,
  projectId,
  crmOrganisationId,
  selectedProject,
}: {
  cartId: string | null;
  onChange: (cart: { id: string; projectId: string } | null) => void;
  crmOrganisationId: string | null;
  projectId: string | null;
  selectedProject: ShopProjectFragment | null;
}) {
  const { t } = useTranslate(["Shop", "Global"]);
  const client = useApolloClient();

  const query = useSelectShopRequirementsQuery({
    client,
    variables: { projectId, crmCompanyId: crmOrganisationId },
  });
  const requirements = React.useMemo(
    () =>
      getDataOrNull(query?.data?.requirements)?.edges.map(e => e.node) ?? [],
    [getDataOrNull(query?.data?.requirements)?.edges]
  );

  return (
    <Stack direction="row" spacing={1}>
      <Autocomplete
        inputLabel={t("Select list", { ns: "Shop" })}
        options={requirements}
        getOptionLabel={option => option.title}
        filterOptions={filterOptionsByTitle}
        required
        value={requirements.find(r => r.id === cartId) ?? null}
        onChange={value => {
          onChange(value);
        }}
      />
      {selectedProject && (
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_REQUIREMENTS"
          project={selectedProject}
        >
          <ModalOpenButton
            Modal={RequirementCreateModal}
            modalProps={{
              projectId: selectedProject.id,
              handleComplete: async requirementId => {
                onChange(
                  requirementId
                    ? { id: requirementId, projectId: selectedProject.id }
                    : null
                );
              },
              refetchQueries: [namedOperations.Query.SelectShopRequirements],
            }}
          >
            <IconButton color="primary" size="large">
              <AddIcon />
            </IconButton>
          </ModalOpenButton>
        </RestrictedByProjectPermissionWithDebug>
      )}
    </Stack>
  );
}
