import { gql, useApolloClient } from "@apollo/client";
import { MenuButton, TitleWithPathForPdf, useScreenWidth } from "@msys/ui";
import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { PageFullScreenModal } from "../../commons/layout/PageFullScreenModal.js";
import { PageMiddleColumn } from "../../commons/layout/PageMiddleColumn.js";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess.js";
import { BuildingItem } from "../../features/buildings/components/BuildingItem.js";
import { EmptyStateItem } from "../../features/doc-items/items/EmptyStateItem.js";
import { DocItemContextMenuItems } from "../../features/doc-items/menus/DocItemContextMenuItems.js";
import { ItemType } from "../../../clients/graphqlTypes.js";
import { useBuildingEditItemQuery } from "./BuildingEditItem.generated.js";

export function BuildingEditItem({ pathToDocPage }: { pathToDocPage: string }) {
  const { buildingId, itemId } = useParams();
  if (!buildingId) throw new Error("Building id is missing");
  if (!itemId) throw new Error("Building item id is missing");
  const navigate = useNavigate();

  const { isMinTablet } = useScreenWidth();

  const { expandedItemIds, pasteItem, isAllowedToPasteItem } =
    useOutletContext<{
      expandedItemIds: string[] | undefined;
      pasteItem:
        | ((docId: string, parentItemId: string) => Promise<void>)
        | null;
      isAllowedToPasteItem: (parentItem: { type: ItemType }) => boolean;
    }>();

  const client = useApolloClient();
  const query = useBuildingEditItemQuery({
    client,
    variables: { buildingId, itemId },
  });
  const building = query.data?.building;
  const item = building?.item[0];

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  if (!item)
    return (
      <EmptyStateItem
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      />
    );

  const buildingItem = (
    <>
      <BuildingItem
        key={itemId} // force reset forms
        building={building}
        item={item}
        pathToDocPage={pathToDocPage}
        startConfirmProcess={startConfirmProcess}
        expandedItemIds={expandedItemIds}
      />
      <ConfirmProcess ref={confirmProcessRef} />
    </>
  );

  const title = (
    <TitleWithPathForPdf title={item.title} pathForPdf={item.pathForPdf} />
  );

  const headerActions = (
    <MenuButton>
      <DocItemContextMenuItems
        pathToDocPage={pathToDocPage}
        docId={buildingId}
        docType="BUILDING"
        projectId={null}
        item={item}
        itemParentType={null}
        startConfirmProcess={startConfirmProcess}
        expandedItemIds={expandedItemIds}
        pasteItem={isAllowedToPasteItem(item) ? pasteItem : null}
        onAdditionalItemInputChange={null}
      />
    </MenuButton>
  );

  if (isMinTablet) {
    return (
      <PageMiddleColumn
        key={itemId}
        hasRightBorder
        hasBackground
        isSelected
        title={title}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
        headerActions={headerActions}
      >
        {buildingItem}
      </PageMiddleColumn>
    );
  }

  return (
    <PageFullScreenModal
      key={itemId}
      title={title}
      onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      headerActions={headerActions}
    >
      {buildingItem}
    </PageFullScreenModal>
  );
}
