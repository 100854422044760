import { gql, useApolloClient } from "@apollo/client";
import React from "react";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import {
  useLikeShopTemplateMutation,
  useUnlikeShopTemplateMutation,
} from "./useShopTemplatesBookmark.generated.js";

export const useShopTemplatesBookmark = () => {
  const client = useApolloClient();

  const [like, { loading: likeLoading }] = useLikeShopTemplateMutation({
    client,
  });
  const [unlike, { loading: unlikeLoading }] = useUnlikeShopTemplateMutation({
    client,
  });

  const handleBookmarkClick = React.useCallback(
    async (docId: string, isBookmarked: boolean) => {
      if (isBookmarked) {
        await unlike({
          variables: { input: { docId } },
          refetchQueries: [namedOperations.Query.ShopTopbarItems],
          awaitRefetchQueries: true,
        });
      } else {
        await like({
          variables: { input: { docId } },
          refetchQueries: [namedOperations.Query.ShopTopbarItems],
          awaitRefetchQueries: true,
        });
      }
    },
    [unlike, like]
  );

  return {
    handleBookmarkClick,
    loadingBookmark: likeLoading || unlikeLoading,
  };
};
