import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  FormattedPrice,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  ModalOpenButton,
  Tabs,
  useFormatting,
} from "@msys/ui";
import { BugReport as BugReportIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Done as DoneIcon } from "@mui/icons-material";
import {
  Chip,
  Divider,
  IconButton,
  Link as MuiLink,
  Stack,
  Typography,
} from "@mui/material";
import type { TFunction } from "@msys/tolgee";
import { useTranslate } from "@tolgee/react";
import { isMatch, pick } from "lodash-es";
import moment from "moment";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { useRestrictionFilter } from "../../auth/useRestrictionFilter.js";
import { useUserData } from "../../auth/useUserData.js";
import { EntityNumber } from "../../commons/EntityNumber.js";
import { TabsWithRestriction } from "../../commons/TabsWithRestriction.js";
import { ButtonCreate } from "../../commons/button/Button.js";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView.js";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageHeader } from "../../commons/layout/PageHeader.js";
import { QuoteListItem } from "../../features/quotes/QuoteListItem.js";
import {
  IncomingQuoteStatusBadge,
  QuoteStatusBadge,
} from "../../features/quotes/QuoteStatusBadge.js";
import { CreateQuoteButton } from "../../features/quotes/buttons/CreateQuoteButton.js";
import { RequestStatusBadge } from "../../features/requests/RequestStatusBadge.js";
import { RequestOverviewProjectIncomingQuoteModal } from "../../features/requests/modals/RequestOverviewModal.js";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore.js";
import {
  ProjectQuote_IncomingQuoteFragment,
  ProjectQuote_OutgoingQuoteFragment,
  useProjectQuotesLazyQuery,
  useProjectQuotesQuery,
} from "./ProjectQuotes.generated.js";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

type Tab = "INCOMING" | "OUTGOING";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export function usePreloadProjectQuotes(projectId: string | undefined) {
  const client = useApolloClient();
  const viewer = useUserData().currentUser!;
  const [preloadProjectQuotes] = useProjectQuotesLazyQuery({ client });

  useEffect(() => {
    if (projectId) {
      if (sessionStorage.getItem("x-msys-preload") === "true") {
        preloadProjectQuotes({
          variables: {
            projectId,
          },
        });
      }
    }
  }, [projectId, preloadProjectQuotes, viewer.organisation.capabilities]);
}

export function ProjectQuotes({
  projectId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) {
  const pathToProject = `/projects/${projectId}`;
  const pathToDocList = `${pathToProject}/quotes`;

  const { t } = useTranslate([
    "Quotes",
    "Global",
    "QuoteStateBadge",
    "QuoteCreate",
  ]);
  const viewer = useUserData().currentUser!;

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "project-quotes",
      "table",
      "list"
    );

  const initialOptions = [
    {
      value: "OUTGOING" as Tab,
      label: t("Outgoing", { ns: "Quotes" }),
      capability: "QUOTING" as const,
    },
    {
      value: "INCOMING" as Tab,
      label: t("Incoming", { ns: "Quotes" }),
    },
  ];
  const restrictionFilter = useRestrictionFilter();
  const options = initialOptions.filter(restrictionFilter);

  const [tab, setTab] = useStateWithUrlParam<Tab>(
    "tab",
    options[0].value,
    true
  );

  const client = useApolloClient();
  const quotesQuery = useProjectQuotesQuery({
    client,
    variables: {
      projectId,
    },
  });
  const project = getDataOrNull(quotesQuery.data?.project)?.project;
  const outgoingQuotes: ProjectQuote_OutgoingQuoteFragment[] =
    getDataOrNull(quotesQuery.data?.projectOutgoingQuotes)?.edges?.map(
      e => e.node
    ) ??
    [] ??
    [];
  const incomingQuotes: ProjectQuote_IncomingQuoteFragment[] =
    getDataOrNull(quotesQuery.data?.projectIncomingQuotes)?.edges?.map(
      e => e.node
    ) ??
    [] ??
    [];

  const breadcrumbs = React.useMemo(() => {
    const items = [
      ...prefixBreadcrumbs,
      {
        title: t("Quotes", {
          ns: "Quotes",
        }),
        to: `/projects/${projectId}/quotes`,
      },
    ];

    if (options.length > 1) {
      items.push({
        title:
          tab === "OUTGOING" && options.some(o => o.value === "OUTGOING")
            ? t("Outgoing", {
                ns: "Quotes",
              })
            : t("Incoming", {
                ns: "Quotes",
              }),
        to: `/projects/${projectId}/quotes?tab=${tab}`,
      });
    }

    return items;
  }, [prefixBreadcrumbs, t, projectId, options, tab]);

  const quickFilterSettings = {
    DRAFT: {
      label: t("Draft", {
        ns: "QuoteStateBadge",
      }),
      isHidden: tab !== "OUTGOING",
      values: {
        isPublished: false,
      },
    },
    BINDING_QUOTE: {
      label: t("Binding Quote", {
        ns: "QuoteStateBadge",
      }),
      isHidden: tab !== "OUTGOING",
      values: {
        isPublished: true,
        isBinding: true,
        agreement: "NONE",
      },
    },
    RECEIVED: {
      label: t("Received", {
        ns: "QuoteStateBadge",
      }),
      isHidden: tab !== "INCOMING",
      values: {
        agreement: "NONE",
      },
    },
    ACCEPTED: {
      label: t("Accepted", {
        ns: "QuoteStateBadge",
      }),
      isHidden: false,
      values: {
        agreement: "YES",
      },
    },
    DECLINED: {
      label: t("Declined", {
        ns: "QuoteStateBadge",
      }),
      isHidden: false,
      values: {
        agreement: "NO",
      },
    },
  };

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<
    keyof typeof quickFilterSettings | undefined
  >("quickfilter", undefined);

  const { search, setSearch, isSearchMatch } = useSearch();

  function isQuoteVisible(
    quote:
      | ProjectQuote_OutgoingQuoteFragment
      | ProjectQuote_IncomingQuoteFragment
  ) {
    const quickFilterValue =
      quickFilter && quickFilterSettings[quickFilter]?.values;
    const matchesFilter = quickFilterValue
      ? isMatch(quote, quickFilterValue)
      : true;
    const matchesSearch = isSearchMatch(quote, search);

    return matchesFilter && matchesSearch;
  }

  return (
    <Page
      subtitle={project?.title}
      title={t("Quotes", {
        ns: "Quotes",
      })}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
    >
      <PageContainer>
        <ListHeader
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          CreateButton={
            project && tab === "OUTGOING" ? (
              <RestrictedByCapabilityWithDebug capability="QUOTING">
                <RestrictedByProjectPermissionWithDebug
                  permission="MANAGE_QUOTES"
                  project={project}
                >
                  {(!(project.state === "opportunity") ||
                    project.incomingQuoteRequests.length === 0 ||
                    project.incomingQuoteRequests.some(
                      request =>
                        request.status === "PUBLISHED" ||
                        request.wonBySystemOrganisationId ===
                          viewer.organisation.id
                    )) && (
                    <CreateQuoteButton
                      projectId={projectId}
                      pathToProject={pathToProject}
                      refetchQueries={[namedOperations.Query.ProjectQuotes]}
                      Button={
                        <ButtonCreate
                          title={t("New client quote", { ns: "QuoteCreate" })}
                        />
                      }
                    />
                  )}
                </RestrictedByProjectPermissionWithDebug>
              </RestrictedByCapabilityWithDebug>
            ) : undefined
          }
          QuickFilter={
            <Stack direction="row" spacing={1}>
              {initialOptions.length > 1 && (
                <>
                  <TabsWithRestriction
                    condensed
                    useSelectOnMobile
                    options={initialOptions}
                    value={tab}
                    onChange={(newTab: Tab) => {
                      setTab(newTab);
                    }}
                  />
                  <Divider orientation="vertical" flexItem />
                </>
              )}
              <Tabs
                condensed
                useSelectOnMobile
                options={Object.entries(quickFilterSettings)
                  .filter(([, value]) => !value.isHidden)
                  .map(([key, value]) => ({
                    value: key as keyof typeof quickFilterSettings,
                    label: value.label,
                  }))}
                value={quickFilter}
                onChange={(
                  newQuickFilter: keyof typeof quickFilterSettings
                ) => {
                  setQuickFilter(newQuickFilter);
                }}
                onDeselect={() => {
                  setQuickFilter(undefined);
                }}
                notSelectedTitle={t("Not selected", { ns: "Global" })}
              />
            </Stack>
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={search}
              onChangeSearchTerm={newValue => {
                setSearch(newValue);
              }}
            />
          }
          FilterButton={undefined}
          FilterChips={undefined}
          marginBottom={2}
        />

        {tab === "OUTGOING" && (
          <OutgoingQuotesTable
            projectId={projectId}
            quotes={outgoingQuotes.filter(isQuoteVisible)}
            pathToDocList={pathToDocList}
            getToPath={quote => `${pathToDocList}/${quote.id}`}
            loading={quotesQuery.loading}
            activeView={activeView}
          />
        )}

        {tab === "INCOMING" && (
          <IncomingQuotesTable
            projectId={projectId}
            quotes={incomingQuotes.filter(isQuoteVisible)}
            pathToDocList={pathToDocList}
            getToPath={quote => `${pathToDocList}/${quote.id}`}
            loading={quotesQuery.loading}
            activeView={activeView}
          />
        )}
      </PageContainer>
    </Page>
  );
}

function OutgoingQuotesTable({
  loading,
  projectId,
  quotes,
  pathToDocList,
  getToPath,
  activeView,
}: {
  loading: boolean;
  projectId: string;
  quotes: ProjectQuote_OutgoingQuoteFragment[];
  pathToDocList: string;
  getToPath: (row: ProjectQuote_OutgoingQuoteFragment) => string;
  activeView: CollectionView;
}) {
  const { t } = useTranslate(["Quotes", "Global"]);
  const navigate = useNavigate();
  const { getFormattedDate, getFormattedDateTime } = useFormatting();

  const stateStore = useDataGridStateStore("ProjectQuotes");

  switch (activeView) {
    case "table": {
      const [one, two, three, four, five, six, ...rest] =
        getCommonQuotesDataGridColumns<ProjectQuote_OutgoingQuoteFragment>(
          t,
          getFormattedDate,
          pathToDocList
        );
      const columns: GridColDef<ProjectQuote_OutgoingQuoteFragment>[] = [
        one,
        {
          field: "organisation",
          headerName: t("Created by", {
            ns: "Quotes",
          }),
          editable: false,
          filterable: false,
          flex: 2,
          minWidth: 100,
          valueGetter: ({ row: quote }) => quote.createdBy,
        },
        two,
        {
          field: "request",
          headerName: t("Related request", {
            ns: "Quotes",
          }),
          editable: false,
          filterable: false,
          flex: 3,
          minWidth: 120,
          valueGetter: ({ row: quote }) => quote?.request?.title ?? "",
          renderCell: ({ row: quote }) =>
            quote.request ? (
              <Stack direction="row" alignItems="center" spacing={1}>
                <ModalOpenButton
                  Modal={RequestOverviewProjectIncomingQuoteModal}
                  modalProps={{ requestId: quote.request.id, projectId }}
                >
                  <MuiLink
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    {quote.request.title}
                  </MuiLink>
                </ModalOpenButton>
                <RequestStatusBadge request={quote.request} small />
              </Stack>
            ) : (
              "-"
            ),
        },
        three,
        four,
        five,
        {
          field: "status",
          headerName: t("Status", {
            ns: "Quotes",
          }),
          editable: false,
          filterable: false,
          flex: 1,
          minWidth: 80,
          valueGetter: ({ row: quote }) =>
            `${quote.isPublished}-${quote.isBinding}-${quote.needsAgreement}-${quote.agreement}`,
          renderCell: ({ row: quote }) => (
            <QuoteStatusBadge small quote={quote} />
          ),
        },
        {
          field: "createdAt",
          headerName: t("Created", {
            ns: "Quotes",
          }),
          editable: false,
          filterable: false,
          type: "date",
          flex: 1,
          minWidth: 80,
          valueGetter: ({ row: quote }) =>
            quote.createdAt ? new Date(quote.createdAt) : null,
          renderCell: ({ row: quote }) =>
            quote.createdAt ? getFormattedDate(quote.createdAt) : "-",
        },
        {
          field: "publishedAt",
          headerName: t("Sent", {
            ns: "Quotes",
          }),
          editable: false,
          filterable: false,
          type: "date",
          flex: 1,
          minWidth: 80,
          valueGetter: ({ row: quote }) =>
            quote.publishedAt ? new Date(quote.publishedAt) : null,
          renderCell: ({ row: quote }) =>
            quote.publishedAt ? getFormattedDate(quote.publishedAt) : "-",
        },
        six,
        {
          field: "xState",
          headerName: t("External status", { ns: "Quotes" }),
          editable: false,
          filterable: false,
          flex: 1,
          minWidth: 80,
          valueGetter: ({ row: quote }) => quote.xStatus,
          renderCell: ({ row: quote }) =>
            quote.xStatus ? (
              <Chip label={quote.xStatus} size="small" variant="filled" />
            ) : (
              "-"
            ),
        },
        ...rest,
      ];

      return (
        <DataGrid
          stateStore={stateStore}
          loading={loading}
          autoPageSize={true}
          columns={columns}
          rows={quotes}
          density="compact"
          disableColumnFilter={false}
          onRowClick={({ row }) => {
            navigate(getToPath(row));
          }}
          pagination={false}
          hideFooter={quotes.length === 0}
          sortingMode={"client"}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
        />
      );
    }
    case "list":
      if (loading) return <LoadingSpinner />;
      if (quotes.length === 0)
        return (
          <ErrorMessage
            message={t("There are no items to display", {
              ns: "Global",
            })}
          />
        );
      return (
        <Stack width="100%" direction="column" spacing={1}>
          {quotes.map(quote => (
            <CardItem
              key={quote.id}
              //@ts-ignore
              component={Link}
              to={getToPath(quote)}
            >
              <QuoteListItem quote={quote} />
            </CardItem>
          ))}
        </Stack>
      );
  }
  return null;
}

function IncomingQuotesTable({
  loading,
  projectId,
  quotes,
  pathToDocList,
  getToPath,
  activeView,
}: {
  loading: boolean;
  projectId: string;
  quotes: ProjectQuote_IncomingQuoteFragment[];
  pathToDocList: string;
  getToPath: (row: ProjectQuote_IncomingQuoteFragment) => string;
  activeView: CollectionView;
}) {
  const { t } = useTranslate(["Quotes", "Global"]);
  const navigate = useNavigate();
  const { getFormattedDate } = useFormatting();

  const stateStore = useDataGridStateStore("ProjectQuotes");

  switch (activeView) {
    case "table": {
      const [one, two, three, four, five, six, ...rest] =
        getCommonQuotesDataGridColumns<ProjectQuote_IncomingQuoteFragment>(
          t,
          getFormattedDate,
          pathToDocList
        );
      const columns: GridColDef<ProjectQuote_IncomingQuoteFragment>[] = [
        one,
        {
          field: "organisation",
          headerName: t("Created by", {
            ns: "Quotes",
          }),
          editable: false,
          filterable: false,
          flex: 2,
          minWidth: 100,
          valueGetter: ({ row: quote }) => quote.docActorContractorName,
        },
        two,
        {
          field: "request",
          headerName: t("Related request", {
            ns: "Quotes",
          }),
          editable: false,
          filterable: false,
          flex: 3,
          minWidth: 120,
          valueGetter: ({ row: quote }) => quote?.request?.title ?? "",
          renderCell: ({ row: quote }) =>
            quote.request ? (
              <Stack direction="row" alignItems="center" spacing={1}>
                <MuiLink
                  component={Link}
                  to={`/projects/${projectId}/requests/${quote.request.id}`}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {quote.request.title}
                </MuiLink>
                <RequestStatusBadge request={quote.request} small />
              </Stack>
            ) : (
              "-"
            ),
        },
        three,
        four,
        five,
        {
          field: "status",
          headerName: t("Status", {
            ns: "Quotes",
          }),
          editable: false,
          filterable: false,
          flex: 1,
          minWidth: 80,
          valueGetter: ({ row: quote }) =>
            `${quote.isPublished}-${quote.isBinding}-${quote.needsAgreement}-${quote.agreement}`,
          renderCell: ({ row: quote }) => (
            <IncomingQuoteStatusBadge small quote={quote} />
          ),
        },
        {
          field: "publishedAt",
          headerName: t("Received", {
            ns: "Quotes",
          }),
          editable: false,
          filterable: false,
          type: "date",
          flex: 1,
          minWidth: 80,
          valueGetter: ({ row: quote }) =>
            quote.publishedAt ? new Date(quote.publishedAt) : null,
          renderCell: ({ row: quote }) =>
            quote.publishedAt ? getFormattedDate(quote.publishedAt) : "-",
        },
        six,
        ...rest,
      ];

      return (
        <DataGrid
          stateStore={stateStore}
          loading={loading}
          autoPageSize={true}
          columns={columns}
          rows={quotes}
          density="compact"
          disableColumnFilter={false}
          onRowClick={({ row }) => {
            navigate(getToPath(row));
          }}
          pagination={false}
          hideFooter={quotes.length === 0}
          sortingMode={"client"}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
        />
      );
    }
    case "list":
      if (loading) return <LoadingSpinner />;
      if (quotes.length === 0)
        return (
          <ErrorMessage
            message={t("There are no items to display", {
              ns: "Global",
            })}
          />
        );
      return (
        <Stack width="100%" direction="column" spacing={1}>
          {quotes.map(quote => (
            <CardItem
              key={quote.id}
              //@ts-ignore
              component={Link}
              to={getToPath(quote)}
            >
              <QuoteListItem quote={quote} />
            </CardItem>
          ))}
        </Stack>
      );
  }
  return null;
}

function getCommonQuotesDataGridColumns<
  T extends
    | ProjectQuote_OutgoingQuoteFragment
    | ProjectQuote_IncomingQuoteFragment,
>(
  t: TFunction<"Quotes" | "Global">,
  getFormattedDate: (date: string) => string,
  pathToDocList: string
) {
  const columns: GridColDef<T>[] = [
    {
      field: "number",
      headerName: t("No.", {
        ns: "Global",
      }),
      editable: false,
      filterable: false,
      flex: 1,
      minWidth: 80,
      renderCell: ({ row: quote }) => (
        <EntityNumber noLabel number={quote.number} />
      ),
    },
    {
      field: "title",
      headerName: t("Title", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      flex: 3,
      minWidth: 120,
      renderCell: ({ row: quote }) => quote.title,
    },
    {
      field: "isBinding",
      headerName: t("Binding", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: true,
      type: "boolean",
      flex: 1,
      renderCell: ({ row: quote }) =>
        quote.isBinding ? (
          <DoneIcon fontSize="small" />
        ) : (
          <CloseIcon fontSize="small" color="secondary" />
        ),
    },
    {
      field: "proposedTotalPrice",
      headerName: t("Proposed Price", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: true,
      sortable: true,
      type: "number",
      flex: 1,
      align: "right",
      headerAlign: "right",
      minWidth: 80,
      valueGetter: ({ row: quote }) => quote.proposedTotalPrice || 0,
      renderCell: ({ row: quote }) => {
        return (
          <Typography
            component="span"
            align="right"
            variant="body2"
            style={{ fontWeight: 500 }}
          >
            <FormattedPrice value={quote.proposedTotalPrice || 0} />
          </Typography>
        );
      },
    },
    {
      field: "agreedTotalPrice",
      headerName: t("Agreed Price", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: true,
      sortable: false,
      type: "number",
      flex: 1,
      align: "right",
      headerAlign: "right",
      minWidth: 80,
      valueGetter: ({ row: quote }) => quote.agreedTotalPrice || 0,
      renderCell: ({ row: quote }) => {
        return quote.agreedTotalPrice === null ? (
          "-"
        ) : (
          <Typography
            component="span"
            align="right"
            variant="body2"
            style={{ fontWeight: 500 }}
          >
            <FormattedPrice value={quote.agreedTotalPrice || 0} />
          </Typography>
        );
      },
    },
    {
      field: "acceptedAt",
      headerName: t("Accepted", {
        ns: "Quotes",
      }),
      editable: false,
      filterable: false,
      type: "date",
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: quote }) =>
        quote.acceptedAt ? new Date(quote.acceptedAt) : null,
      renderCell: ({ row: quote }) =>
        quote.acceptedAt ? getFormattedDate(quote.acceptedAt) : "-",
    },
  ];

  columns.push({
    field: "actions",
    headerName: "",
    filterable: false,
    maxWidth: 40,
    minWidth: 40,
    renderCell: ({ row: quote }) => (
      <IconButton
        component={Link}
        to={`${pathToDocList}/${quote.id}/troubleshoot`}
        onClick={event => {
          event.stopPropagation();
        }}
        color={"primary"}
        sx={{ marginLeft: "-10px" }}
      >
        <BugReportIcon />
      </IconButton>
    ),
  });

  return columns;
}

const SEARCHABLE_FIELD_NAMES = [
  "number",
  "title",
  "createdBy",
  "clientName",
  "request.title",
  "proposedTotalPrice",
  "agreedTotalPrice",
  "createdAt",
];

function useSearch() {
  const { getFormattedDate } = useFormatting();
  const [search, setSearch] = useStateWithUrlParam<string>("search", "");

  const isSearchMatch = React.useCallback(
    (
      quote:
        | ProjectQuote_OutgoingQuoteFragment
        | ProjectQuote_IncomingQuoteFragment,
      search: string
    ) => {
      const searchableFields = pick(quote, SEARCHABLE_FIELD_NAMES);

      return Object.entries(searchableFields).some(([key, value]) => {
        if (isDate(key, value)) {
          return (
            value && moment(value) && getFormattedDate(value).includes(search)
          );
        }

        return (
          value &&
          JSON.stringify(value)
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        );
      });
    },
    [getFormattedDate]
  );

  return { search, setSearch, isSearchMatch };
}

function isDate(key: string, value: unknown): value is string {
  return key === "createdAt";
}
