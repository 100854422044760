import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import { AutocompleteMultiple } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { debounce, uniqBy, without } from "lodash-es";
import React from "react";
import { FilterChip } from "../../commons/filters/FilterChip.js";
import { highlightWithBold } from "../../utils.js";
import { CrmPersonListItem } from "./CrmCompanyOrPersonSelect.js";
import {
  useCrmCompanyOrPersonSelectLazyQuery,
  useCrmCompanyOrPersonSelectQuery,
} from "./CrmCompanyOrPersonSelect.generated.js";
import { CrmCompanyListItem } from "./CrmCompanySelect.js";

interface Props {
  crmCompaniesOrPersonsIds: string[];
  onChange: (crmCompaniesOrPersonsIds: string[]) => void;
  inputLabel?: string;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const CrmCompaniesOrPersonsSelect = ({
  crmCompaniesOrPersonsIds,
  onChange,
  inputLabel,
  required = false,
  error,
  disabled,
  placeholder,
}: Props) => {
  const { t } = useTranslate(["Global", "CrmUsers"]);

  const [inputValue, setInputValue] = React.useState("");

  const { highlightText, contacts, debouncedRefetch, loading, totalCount } =
    useCrmCompaniesOrPersons(
      crmCompaniesOrPersonsIds,
      inputValue,
      setInputValue
    );

  return (
    <AutocompleteMultiple
      placeholder={placeholder ?? t("Type to search", { ns: "Global" })}
      options={[
        ...contacts,
        ...(totalCount > contacts.length
          ? [
              {
                title: t("Type to see more...", { ns: "Global" }),
                id: "__type_to_see_more__" as const,
              },
            ]
          : []),
      ]}
      getOptionDisabled={option => option.id === "__type_to_see_more__"}
      getOptionLabel={option =>
        "__typename" in option
          ? option.__typename === "CrmCompaniesRecord"
            ? option.title
            : option.fullname
          : option.title
      }
      renderOption={(props, option) =>
        "__typename" in option ? (
          option.__typename === "CrmCompaniesRecord" ? (
            <CrmCompanyListItem
              {...props}
              key={option.id}
              option={option}
              highlightText={highlightText}
              showAvatar
            />
          ) : (
            <CrmPersonListItem
              {...props}
              key={option.id}
              option={option}
              highlightText={highlightText}
              showAvatar
            />
          )
        ) : (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        )
      }
      inputLabel={inputLabel ?? t("Select contacts", { ns: "CrmUsers" })}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
          debouncedRefetch(value);
        }
      }}
      required={required}
      loading={loading}
      error={error}
      disabled={disabled}
      value={contacts.filter(c => crmCompaniesOrPersonsIds.includes(c.id))}
      onChange={value => {
        onChange(
          value.filter(v => v.id !== "__type_to_see_more__").map(v => v.id)
        );
      }}
      onBlur={() => {
        setInputValue("");
      }}
    />
  );
};

const LIMIT = 10;

function useCrmCompaniesOrPersons(
  crmCompaniesOrPersonsIds: string[],
  inputValue: string,
  setInputValue: (value: string) => void
) {
  const client = useApolloClient();

  const [fetchQuery, query] = useCrmCompanyOrPersonSelectLazyQuery({
    client,
    variables: { limit: LIMIT },
  });
  const [fetchSelectedQuery, selectedQuery] =
    useCrmCompanyOrPersonSelectLazyQuery({
      client,
      variables: { limit: LIMIT },
    });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          searchTerm: value,
          filterIds: null,
        },
      });
    },
    [fetchQuery]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (crmCompaniesOrPersonsIds && crmCompaniesOrPersonsIds.length > 0) {
        await fetchSelectedQuery({
          variables: {
            limit: crmCompaniesOrPersonsIds.length,
            filterIds: crmCompaniesOrPersonsIds,
          },
        });
      }
      await fetchQuery({
        variables: {
          limit: LIMIT,
          searchTerm: inputValueRef.current,
          filterIds: null,
          filterExcludeIds:
            crmCompaniesOrPersonsIds && crmCompaniesOrPersonsIds.length > 0
              ? crmCompaniesOrPersonsIds
              : null,
        },
      });
    })();
  }, [crmCompaniesOrPersonsIds, fetchQuery, fetchSelectedQuery]);

  const crmCompanies =
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)?.edges.map(
      edge => edge.node
    ) ?? [];
  const crmSelectedCompanies =
    crmCompaniesOrPersonsIds && crmCompaniesOrPersonsIds.length > 0
      ? (getDataOrNull(
          (selectedQuery.data ?? selectedQuery.previousData)?.crmCompanies
        )?.edges.map(edge => edge.node) ?? [])
      : [];
  const crmPersons =
    getDataOrNull((query.data ?? query.previousData)?.crmPersons)?.edges.map(
      edge => edge.node
    ) ?? [];
  const crmSelectedPersons =
    crmCompaniesOrPersonsIds && crmCompaniesOrPersonsIds.length > 0
      ? (getDataOrNull(
          (selectedQuery.data ?? selectedQuery.previousData)?.crmPersons
        )?.edges.map(edge => edge.node) ?? [])
      : [];

  const totalCount =
    (getDataOrNull((query.data ?? query.previousData)?.crmCompanies)
      ?.totalCount ?? 0) +
    (getDataOrNull((query.data ?? query.previousData)?.crmPersons)
      ?.totalCount ?? 0);

  const highlightText = React.useCallback(
    (text: string | null) =>
      text && query?.variables?.searchTerm
        ? highlightWithBold(text, query.variables.searchTerm)
        : text,
    [query?.variables?.searchTerm]
  );

  const selectedContacts = (crmCompaniesOrPersonsIds ?? [])
    .map(
      id =>
        crmSelectedCompanies.find(c => c.id === id) ??
        crmSelectedPersons.find(p => p.id === id) ??
        null
    )
    .filter(notNull);

  const contacts = [...crmCompanies, ...crmPersons].sort((a, b) => {
    const aName = a.__typename === "CrmCompaniesRecord" ? a.title : a.firstname;
    const bName = b.__typename === "CrmCompaniesRecord" ? b.title : b.firstname;
    return aName.localeCompare(bName);
  });

  return {
    highlightText,
    contacts: uniqBy([...selectedContacts, ...contacts], ({ id }) => id),
    refetch,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.searchTerm &&
          query?.variables?.searchTerm !== inputValue
      ),
    totalCount,
  };
}

export function FilterCrmCompaniesOrPersonsChips({
  label,
  value,
  setValue,
}: {
  label?: string;
  value: string[] | null | undefined;
  setValue: (newValue: string[] | null | undefined) => void;
}) {
  const { t } = useTranslate(["Projects"]);

  const client = useApolloClient();
  const query = useCrmCompanyOrPersonSelectQuery({
    client,
    variables: { limit: value?.length ?? 0, filterIds: value },
    skip: !value || !value.length,
  });

  const crmCompanies = (
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)?.edges.map(
      edge => edge.node
    ) ?? []
  ).filter(c => value?.includes(c.id) ?? false);
  const crmPersons = (
    getDataOrNull((query.data ?? query.previousData)?.crmPersons)?.edges.map(
      edge => edge.node
    ) ?? []
  ).filter(p => value?.includes(p.id) ?? false);

  if (!crmCompanies.length && !crmPersons.length) return null;

  return (
    <>
      {(value ?? []).map(id => {
        const crmCompany = crmCompanies.find(c => c.id === id);
        const crmPerson = crmPersons.find(p => p.id === id);
        if (!crmCompany && !crmPerson) return null;
        return (
          <FilterChip
            key={id}
            label={label ?? t("Contact", { ns: "Projects" })}
            resetValue={null}
            setValue={() => {
              const newValue = without(value, id);
              setValue(newValue.length > 0 ? newValue : undefined);
            }}
            value={crmCompany?.title ?? crmPerson?.fullname ?? ""}
          />
        );
      })}
    </>
  );
}
