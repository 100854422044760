import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { ErrorMessage, LoadingSpinner as LoadingContainer } from "@msys/ui";
import { Close as CloseIcon } from "@mui/icons-material";
import { Divider, IconButton, Typography } from "@mui/material";
import { noop } from "lodash-es";
import React from "react";
import { useParams } from "react-router-dom";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { RightHandSidebar } from "../../commons/layout/RightHandSidebar.js";
import { Stack } from "../../commons/layout/Stack.js";
import { BillOfMaterialsItemAlert } from "../bill-of-materials/BillOfMaterialsItemAlert.js";
import { ItemManualProductBox } from "../bill-of-materials/boxes/ItemManualProductBox.js";
import { ProductBox } from "../bill-of-materials/boxes/ProductBox.js";
import { RelatedQuoteItemsBox } from "../bill-of-materials/boxes/RelatedQuoteItemsBox.js";
import { HeaderBox } from "../doc-items/boxes/HeaderBox.js";
import { usePurchaseOrderItemDetailsQuery } from "./PurchaseOrderItemDetails.generated.js";
import { RelatedOrderItemsBox } from "./boxes/RelatedOrderItemsBox.js";

export function PurchaseOrderItemDetails({
  projectId,
  handleClose,
  OrderDetailBox,
}: {
  projectId: string;
  handleClose: () => void;
  OrderDetailBox: React.ReactElement;
}) {
  const { itemId } = useParams();
  if (!itemId) throw new Error("Item id is missing");

  const relatedQuoteItemsRef = React.useRef<HTMLDivElement | null>(null);

  const client = useApolloClient();
  const query = usePurchaseOrderItemDetailsQuery({
    client,
    variables: {
      projectId,
      itemId,
    },
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const item = project?.billOfMaterialsItems[0];

  return (
    <RightHandSidebar>
      {query.error ? (
        <ErrorMessage message={query.error.message} />
      ) : query.loading ? (
        <LoadingContainer />
      ) : !item ? (
        <PageNotFound />
      ) : (
        <>
          <Stack padding={1} alignItems="center" justifyContent="space-between">
            <Typography variant="h3">{item.title}</Typography>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Stack flexDirection={"column"} padding={1}>
            <BillOfMaterialsItemAlert
              item={item}
              handleActionClick={() => {
                relatedQuoteItemsRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            />
            {!item.product && (
              <HeaderBox
                projectId={projectId}
                docId={item.relatedQuoteItems[0].quote.id}
                docType="QUOTE"
                item={item.relatedQuoteItems[0].quoteItem}
                viewMode={null}
                setViewMode={noop}
              />
            )}
            {item.product ? (
              <ProductBox
                productArticleNumber={item.product.articleNumber}
                productSupplierId={item.product.supplierId}
              />
            ) : (
              <ItemManualProductBox
                projectId={projectId}
                docId={item.relatedQuoteItems[0].quote.id}
                itemId={item.relatedQuoteItems[0].quoteItem.id}
                overriddenNetPrice={
                  item.netPrice ?? item.listPrice ?? undefined
                }
              />
            )}
            {OrderDetailBox}
            <RelatedOrderItemsBox
              orderItems={item.orderItems}
              project={project}
              document={{ viewerPermissions: project.viewerPermissions }}
            />
            <RelatedQuoteItemsBox
              ref={relatedQuoteItemsRef}
              relatedQuoteItems={item.relatedQuoteItems}
              getItemPath={(docId, itemId) =>
                `/projects/${projectId}/quotes/${docId}/edit/items/${itemId}`
              }
            />
          </Stack>
        </>
      )}
    </RightHandSidebar>
  );
}
