/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopProjectFragment = { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus, owningSystemOrganisationId: string, crmOrganisation?: { __typename: 'CrmCompany', id: string } | null, contractors: Array<{ __typename: 'CrmCompany', id: string }>, incomingQuoteRequests: Array<{ __typename: 'Request', id: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null }> };

export type ShopProjectsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ShopProjectsByProjectIdQuery = { projects: { __typename: 'ProjectConnection', edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus, owningSystemOrganisationId: string, crmOrganisation?: { __typename: 'CrmCompany', id: string } | null, contractors: Array<{ __typename: 'CrmCompany', id: string }>, incomingQuoteRequests: Array<{ __typename: 'Request', id: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null }> } }> } };

export type ShopProjectsByCrmCompanyIdQueryVariables = Types.Exact<{
  crmCompanyId: Types.Scalars['ID']['input'];
}>;


export type ShopProjectsByCrmCompanyIdQuery = { projects: { __typename: 'ProjectConnection', edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus, owningSystemOrganisationId: string, crmOrganisation?: { __typename: 'CrmCompany', id: string } | null, contractors: Array<{ __typename: 'CrmCompany', id: string }>, incomingQuoteRequests: Array<{ __typename: 'Request', id: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null }> } }> } };

export type SelectShopQuotesQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterDocActors?: Types.InputMaybe<Array<Types.DocActorFilterInput>>;
}>;


export type SelectShopQuotesQuery = { outgoingQuotes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'OutgoingQuoteConnection', totalCount: number, edges: Array<{ __typename: 'OutgoingQuoteEdge', node: { __typename: 'OutgoingQuote', id: string, title: string, projectId: string } }> } };

export type SelectShopRequirementsQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  crmCompanyId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type SelectShopRequirementsQuery = { requirements: { __typename: 'MissingPermissions' } | { __typename: 'RequirementsConnection', totalCount: number, edges: Array<{ __typename: 'RequirementEdge', node: { __typename: 'RequirementNode', id: string, title: string, projectId: string } }> } };

export const ShopProjectFragmentDoc = gql`
    fragment ShopProject on Project {
  id
  title
  viewerPermissions
  state
  owningSystemOrganisationId
  crmOrganisation {
    id
  }
  contractors {
    id
  }
  incomingQuoteRequests {
    id
    status
    wonBySystemOrganisationId
  }
}
    `;
export const ShopProjectsByProjectIdDocument = gql`
    query ShopProjectsByProjectId($projectId: ID!) {
  projects(
    limit: 1
    filters: {ids: [$projectId], includeState: [opportunity, contracted]}
  ) {
    ... on ProjectConnection {
      edges {
        node {
          id
          ...ShopProject
        }
      }
    }
  }
}
    ${ShopProjectFragmentDoc}`;

/**
 * __useShopProjectsByProjectIdQuery__
 *
 * To run a query within a React component, call `useShopProjectsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopProjectsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopProjectsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useShopProjectsByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<ShopProjectsByProjectIdQuery, ShopProjectsByProjectIdQueryVariables> & ({ variables: ShopProjectsByProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopProjectsByProjectIdQuery, ShopProjectsByProjectIdQueryVariables>(ShopProjectsByProjectIdDocument, options);
      }
export function useShopProjectsByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopProjectsByProjectIdQuery, ShopProjectsByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopProjectsByProjectIdQuery, ShopProjectsByProjectIdQueryVariables>(ShopProjectsByProjectIdDocument, options);
        }
export function useShopProjectsByProjectIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ShopProjectsByProjectIdQuery, ShopProjectsByProjectIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopProjectsByProjectIdQuery, ShopProjectsByProjectIdQueryVariables>(ShopProjectsByProjectIdDocument, options);
        }
export type ShopProjectsByProjectIdQueryHookResult = ReturnType<typeof useShopProjectsByProjectIdQuery>;
export type ShopProjectsByProjectIdLazyQueryHookResult = ReturnType<typeof useShopProjectsByProjectIdLazyQuery>;
export type ShopProjectsByProjectIdSuspenseQueryHookResult = ReturnType<typeof useShopProjectsByProjectIdSuspenseQuery>;
export type ShopProjectsByProjectIdQueryResult = Apollo.QueryResult<ShopProjectsByProjectIdQuery, ShopProjectsByProjectIdQueryVariables>;
export const ShopProjectsByCrmCompanyIdDocument = gql`
    query ShopProjectsByCrmCompanyId($crmCompanyId: ID!) {
  projects(
    limit: 0
    filters: {crmOrganisationId: $crmCompanyId, includeState: [opportunity, contracted]}
  ) {
    ... on ProjectConnection {
      edges {
        node {
          id
          ...ShopProject
        }
      }
    }
  }
}
    ${ShopProjectFragmentDoc}`;

/**
 * __useShopProjectsByCrmCompanyIdQuery__
 *
 * To run a query within a React component, call `useShopProjectsByCrmCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopProjectsByCrmCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopProjectsByCrmCompanyIdQuery({
 *   variables: {
 *      crmCompanyId: // value for 'crmCompanyId'
 *   },
 * });
 */
export function useShopProjectsByCrmCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<ShopProjectsByCrmCompanyIdQuery, ShopProjectsByCrmCompanyIdQueryVariables> & ({ variables: ShopProjectsByCrmCompanyIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopProjectsByCrmCompanyIdQuery, ShopProjectsByCrmCompanyIdQueryVariables>(ShopProjectsByCrmCompanyIdDocument, options);
      }
export function useShopProjectsByCrmCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopProjectsByCrmCompanyIdQuery, ShopProjectsByCrmCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopProjectsByCrmCompanyIdQuery, ShopProjectsByCrmCompanyIdQueryVariables>(ShopProjectsByCrmCompanyIdDocument, options);
        }
export function useShopProjectsByCrmCompanyIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ShopProjectsByCrmCompanyIdQuery, ShopProjectsByCrmCompanyIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopProjectsByCrmCompanyIdQuery, ShopProjectsByCrmCompanyIdQueryVariables>(ShopProjectsByCrmCompanyIdDocument, options);
        }
export type ShopProjectsByCrmCompanyIdQueryHookResult = ReturnType<typeof useShopProjectsByCrmCompanyIdQuery>;
export type ShopProjectsByCrmCompanyIdLazyQueryHookResult = ReturnType<typeof useShopProjectsByCrmCompanyIdLazyQuery>;
export type ShopProjectsByCrmCompanyIdSuspenseQueryHookResult = ReturnType<typeof useShopProjectsByCrmCompanyIdSuspenseQuery>;
export type ShopProjectsByCrmCompanyIdQueryResult = Apollo.QueryResult<ShopProjectsByCrmCompanyIdQuery, ShopProjectsByCrmCompanyIdQueryVariables>;
export const SelectShopQuotesDocument = gql`
    query SelectShopQuotes($projectId: ID, $filterDocActors: [DocActorFilterInput!]) {
  outgoingQuotes(
    projectId: $projectId
    limit: 0
    filters: {docActors: $filterDocActors, projectIncludeState: [opportunity, contracted]}
  ) {
    ... on OutgoingQuoteConnection {
      edges {
        node {
          id
          title
          projectId
        }
      }
      totalCount
    }
  }
}
    `;

/**
 * __useSelectShopQuotesQuery__
 *
 * To run a query within a React component, call `useSelectShopQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectShopQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectShopQuotesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      filterDocActors: // value for 'filterDocActors'
 *   },
 * });
 */
export function useSelectShopQuotesQuery(baseOptions?: Apollo.QueryHookOptions<SelectShopQuotesQuery, SelectShopQuotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectShopQuotesQuery, SelectShopQuotesQueryVariables>(SelectShopQuotesDocument, options);
      }
export function useSelectShopQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectShopQuotesQuery, SelectShopQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectShopQuotesQuery, SelectShopQuotesQueryVariables>(SelectShopQuotesDocument, options);
        }
export function useSelectShopQuotesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SelectShopQuotesQuery, SelectShopQuotesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectShopQuotesQuery, SelectShopQuotesQueryVariables>(SelectShopQuotesDocument, options);
        }
export type SelectShopQuotesQueryHookResult = ReturnType<typeof useSelectShopQuotesQuery>;
export type SelectShopQuotesLazyQueryHookResult = ReturnType<typeof useSelectShopQuotesLazyQuery>;
export type SelectShopQuotesSuspenseQueryHookResult = ReturnType<typeof useSelectShopQuotesSuspenseQuery>;
export type SelectShopQuotesQueryResult = Apollo.QueryResult<SelectShopQuotesQuery, SelectShopQuotesQueryVariables>;
export const SelectShopRequirementsDocument = gql`
    query SelectShopRequirements($projectId: ID, $crmCompanyId: ID) {
  requirements(
    limit: 0
    filters: {origin: OWN, projectIncludeState: [opportunity, contracted]}
    projectId: $projectId
  ) {
    ... on RequirementsConnection {
      edges {
        node {
          id
          title
          projectId
        }
      }
      totalCount
    }
  }
}
    `;

/**
 * __useSelectShopRequirementsQuery__
 *
 * To run a query within a React component, call `useSelectShopRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectShopRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectShopRequirementsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      crmCompanyId: // value for 'crmCompanyId'
 *   },
 * });
 */
export function useSelectShopRequirementsQuery(baseOptions?: Apollo.QueryHookOptions<SelectShopRequirementsQuery, SelectShopRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectShopRequirementsQuery, SelectShopRequirementsQueryVariables>(SelectShopRequirementsDocument, options);
      }
export function useSelectShopRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectShopRequirementsQuery, SelectShopRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectShopRequirementsQuery, SelectShopRequirementsQueryVariables>(SelectShopRequirementsDocument, options);
        }
export function useSelectShopRequirementsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SelectShopRequirementsQuery, SelectShopRequirementsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectShopRequirementsQuery, SelectShopRequirementsQueryVariables>(SelectShopRequirementsDocument, options);
        }
export type SelectShopRequirementsQueryHookResult = ReturnType<typeof useSelectShopRequirementsQuery>;
export type SelectShopRequirementsLazyQueryHookResult = ReturnType<typeof useSelectShopRequirementsLazyQuery>;
export type SelectShopRequirementsSuspenseQueryHookResult = ReturnType<typeof useSelectShopRequirementsSuspenseQuery>;
export type SelectShopRequirementsQueryResult = Apollo.QueryResult<SelectShopRequirementsQuery, SelectShopRequirementsQueryVariables>;