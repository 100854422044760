import { CardContainer, LabeledRichTextValue, useScreenWidth } from "@msys/ui";
import { Flag as FlagIcon } from "@mui/icons-material";
import { People as PeopleIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { ModifyOrganisationProfileAboutButton } from "../buttons/ModifyOrganisationProfileAboutButton.js";
import { OrganisationPublicAboutBox_OrganisationProfileFragment } from "./OrganisationPublicAboutBox.generated.js";

interface Props {
  organisationProfile: OrganisationPublicAboutBox_OrganisationProfileFragment;
  canEdit: boolean;
}

export const OrganisationPublicAboutBox = ({
  organisationProfile,
  canEdit,
}: Props) => {
  const { t } = useTranslate(["OrganisationPublicProfile", "Global"]);
  const viewer = useUserData().currentUser!;
  const { isMaxPhone } = useScreenWidth();

  return (
    <CardContainer
      title={t("About", {
        ns: "OrganisationPublicProfile",
      })}
      ActionButton={
        canEdit && (
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
            {viewer.organisation.id === organisationProfile.organisation.id && (
              <ModifyOrganisationProfileAboutButton
                slug={organisationProfile.slug}
                organisationProfile={organisationProfile}
              />
            )}
          </RestrictedByOrganisationPermissionWithDebug>
        )
      }
    >
      <Stack
        flexDirection="column"
        width="100%"
        p={isMaxPhone ? 1 : 2}
        spacing={isMaxPhone ? 1 / 2 : 3 / 2}
      >
        {organisationProfile.yearFounded ||
        organisationProfile.employeesNumber ? (
          <Stack spacing={isMaxPhone ? 2 : 4} alignItems="center">
            {organisationProfile.yearFounded && (
              <Stack spacing={1 / 2} alignItems="center">
                <FlagIcon />
                <Typography>
                  {t("Since {year}", {
                    ns: "OrganisationPublicProfile",
                    year: organisationProfile.yearFounded.toString(),
                  })}
                </Typography>
              </Stack>
            )}
            {organisationProfile.employeesNumber && (
              <Stack spacing={1 / 2} alignItems="center">
                <PeopleIcon />
                <Typography>
                  {t("{number} employees", {
                    ns: "OrganisationPublicProfile",
                    number: organisationProfile.employeesNumber.toString(),
                  })}
                </Typography>
              </Stack>
            )}
          </Stack>
        ) : null}
        <LabeledRichTextValue
          placeholder={t("Not set", {
            ns: "Global",
          })}
          content={organisationProfile.about}
        />
      </Stack>
    </CardContainer>
  );
};
