import { gql, useApolloClient } from "@apollo/client";
import { MenuItemWithIcon } from "@msys/ui";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { RestoreFromTrash as RestoreFromTrashIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import { ProductOverview__ProductFragment } from "../Product.generated.js";
import {
  useDeleteCustomSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButtonMutation,
  useDeleteSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButtonMutation,
  useReenableCustomSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButtonMutation,
  useReenableSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButtonMutation,
} from "./OrganisationSupplierProductDeleteMenuButton.generated.js";

export const OrganisationSupplierProductDeleteOrReenableMenuButton = ({
  product,
  refetchQueries,
}: {
  product: ProductOverview__ProductFragment;
  refetchQueries?: string[];
}) => {
  const { t } = useTranslate(["Product", "Global"]);

  const client = useApolloClient();

  const [deleteCustomSupplierProduct] =
    useDeleteCustomSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButtonMutation(
      { client, refetchQueries }
    );

  const [reenableCustomSupplierProduct] =
    useReenableCustomSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButtonMutation(
      { client, refetchQueries }
    );

  const [deleteSupplierProduct] =
    useDeleteSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButtonMutation(
      { client, refetchQueries }
    );

  const [reenableSupplierProduct] =
    useReenableSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButtonMutation(
      { client, refetchQueries }
    );

  if (!product.organisationCanEdit) {
    return null;
  }

  return (
    <MenuItemWithIcon
      icon={!product.deletedAt ? <DeleteIcon /> : <RestoreFromTrashIcon />}
      onClick={async () => {
        if (product.deletedAt) {
          if (product.__typename === "PimCustomSupplierProduct") {
            await reenableCustomSupplierProduct({
              variables: {
                input: {
                  articleNumber: product.articleNumber,
                  supplierId: product.supplierId,
                },
              },
            });
            return;
          }
          if (product.__typename === "PimSupplierProduct") {
            await reenableSupplierProduct({
              variables: {
                input: {
                  articleNumber: product.articleNumber,
                  supplierId: product.supplierId,
                },
              },
            });
            return;
          }
        } else {
          if (product.__typename === "PimCustomSupplierProduct") {
            await deleteCustomSupplierProduct({
              variables: {
                input: {
                  articleNumber: product.articleNumber,
                  supplierId: product.supplierId,
                },
              },
            });
            return;
          }
          if (product.__typename === "PimSupplierProduct") {
            await deleteSupplierProduct({
              variables: {
                input: {
                  articleNumber: product.articleNumber,
                  supplierId: product.supplierId,
                },
              },
            });
            return;
          }
        }
      }}
    >
      {!product.deletedAt
        ? t("Delete", {
            ns: "Product",
          })
        : t("Restore", {
            ns: "Product",
          })}
    </MenuItemWithIcon>
  );
};
