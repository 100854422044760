import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonCreate } from "../../../commons/button/Button.js";
import { useTranslate } from "@tolgee/react";
import { AddTimeReportModal } from "../AddTimeReportModal.js";

type Type = "menu-item" | "button";

interface Props {
  projectId: string;
  type: Type;
}

export const AddTimeReportButton = React.forwardRef<
  HTMLLIElement | HTMLButtonElement,
  Props
>(function ({ projectId, type }, ref) {
  const navigate = useNavigate();
  const { t } = useTranslate(["TimeReports", "ActivitiesRouter"]);

  return (
    <ModalOpenButton
      Modal={AddTimeReportModal}
      modalProps={{
        projectId,
        handleComplete: (reportId?: string) => {
          if (reportId)
            navigate(`/projects/${projectId}/tasks/reports/${reportId}`);
        },
      }}
    >
      {type === "menu-item" ? (
        <MenuItemWithIcon
          ref={ref as React.Ref<HTMLLIElement>}
          icon={<AddIcon />}
        >
          {t("Create new time report", {
            ns: "ActivitiesRouter",
          })}
        </MenuItemWithIcon>
      ) : (
        <ButtonCreate
          ref={ref as React.Ref<HTMLButtonElement>}
          title={t("New time report", {
            ns: "TimeReports",
          })}
        />
      )}
    </ModalOpenButton>
  );
});
