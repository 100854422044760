import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import * as Yup from "yup";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField.js";
import { SwitchField } from "../../../commons/form-fields/SwitchField.js";

interface Props {
  name: string;
  disabled?: boolean;
}

export type QuoteProductsVisibility = {
  showTitle: boolean;
  showDescription: boolean;
  showImages: "no" | "one" | "all";
};

export const defaultQuoteProductsVisibility: QuoteProductsVisibility = {
  showTitle: true,
  showDescription: true,
  showImages: "all",
};

export const QuoteProductsVisibilityField = ({ name, disabled }: Props) => {
  const { t } = useTranslate(["QuoteEditFooter", "Global"]);

  return (
    <Box>
      <Typography>
        {t("Products visibility", { ns: "QuoteEditFooter" })}
      </Typography>
      <Stack direction="column" spacing={0}>
        <SwitchField
          label={t("Show title", { ns: "QuoteEditFooter" })}
          name={`${name}.showTitle`}
          disabled={disabled}
        />
        <SwitchField
          label={t("Show description", { ns: "QuoteEditFooter" })}
          name={`${name}.showDescription`}
          disabled={disabled}
        />
        <Typography variant="body2">
          {t("Show images", { ns: "QuoteEditFooter" })}
        </Typography>
        <RadioGroupField
          name={`${name}.showImages`}
          inline
          options={[
            {
              value: "all",
              label: t("All images", { ns: "QuoteEditFooter" }),
            },
            {
              value: "one",
              label: t("First image only", { ns: "QuoteEditFooter" }),
            },
            {
              value: "no",
              label: t("No images", { ns: "QuoteEditFooter" }),
            },
          ]}
          disabled={disabled}
        />
      </Stack>
    </Box>
  );
};

export const useQuoteProductsVisibilityValidationSchema = () => {
  const { t } = useTranslate(["QuoteEditFooter", "Global"]);

  const validationSchema = Yup.object().shape({
    showTitle: Yup.boolean()
      .label(t("Show title", { ns: "QuoteEditFooter" }))
      .required(),
    showDescription: Yup.boolean()
      .label(t("Show description", { ns: "QuoteEditFooter" }))
      .required(),
    showImages: Yup.string()
      .oneOf(["no", "one", "all"])
      .label(t("Show images", { ns: "QuoteEditFooter" }))
      .required(),
  });

  return validationSchema;
};
