import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  useParams,
  Routes,
  Route,
  Navigate,
  generatePath,
} from "react-router-dom";
import { RestrictedByDocumentPermission } from "../../auth/RestrictedByDocumentPermission.js";
import { usePageWidth } from "../../commons/hooks/usePageWidth.js";
import { BreadcrumbItem } from "../../commons/layout/PageBreadcrumbs.js";
import { PageTopbarItem } from "../../commons/layout/PageSubmenu.js";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { SelectInfoMessageItem } from "../../features/doc-items/items/SelectInfoMessageItem.js";
import { ProjectRequirementEdit } from "./ProjectRequirementEdit.js";
import { ProjectRequirementEditItem } from "./ProjectRequirementEditItem.js";
import { ProjectRequirementPreview } from "./ProjectRequirementPreview.js";
import { ProjectRequirementThreeD } from "./ProjectRequirementThreeD.js";
import { useProjectRequirementRoutesQuery } from "./ProjectRoutes.generated.js";

export const ProjectRequirementRoutes = ({
  projectId,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const { requirementId } = useParams();
  if (!requirementId) throw new Error("Requirement id is missing");

  const { t } = useTranslate(["ProjectPageTopbar", "Quote"]);
  const { isMinTablet } = useScreenWidth();
  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();

  const client = useApolloClient();
  const query = useProjectRequirementRoutesQuery({
    client,
    variables: {
      projectId,
      requirementId,
    },
  });

  const requirement = getDataOrNull(query.data?.requirement)?.requirement;

  const canViewEdit = requirement?.viewerPermissions.includes("MANAGE_QUOTES");
  const redirectPath = canViewEdit
    ? `/projects/:projectId/requirements/:requirementId/edit`
    : `/projects/:projectId/requirements/:requirementId/preview`;

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Requirements", {
          ns: "ProjectPageTopbar",
        }),
        to: `/projects/${projectId}/requirements`,
      },
      {
        title: requirement?.title ?? "",
        to: `/projects/${projectId}/requirements/${requirementId}`,
      },
    ],
    [prefixBreadcrumbs, t, projectId, requirement?.title, requirementId]
  );

  if (!requirement) return null;

  return (
    <Routes>
      <Route
        index
        element={
          <Navigate
            to={generatePath(redirectPath, {
              projectId,
              requirementId,
            })}
            replace
          />
        }
      />
      <Route
        path={`preview`}
        element={
          <RestrictedByDocumentPermission
            permission="READ_REQUIREMENTS"
            document={requirement}
          >
            <ProjectRequirementPreview
              projectId={projectId}
              requirementId={requirementId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      />
      <Route
        path={`edit`}
        element={
          <RestrictedByDocumentPermission
            permission="MANAGE_REQUIREMENTS"
            document={requirement}
          >
            <ProjectRequirementEdit
              projectId={projectId}
              requirementId={requirementId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      >
        {isMinTablet &&
          (isMinTwoColumnsWithPreview || !isMinOneColumnWithPreview) && (
            <Route index element={<SelectInfoMessageItem />} />
          )}
        <Route
          path={"items/:itemId"}
          element={
            <ProjectRequirementEditItem
              projectId={projectId}
              requirementId={requirementId}
              pathToDocPage={`/projects/${projectId}/requirements/${requirementId}/edit`}
            />
          }
        />
      </Route>
      <Route
        path={`3d`}
        element={
          <RestrictedByDocumentPermission
            permission="READ_REQUIREMENTS"
            document={requirement}
          >
            <ProjectRequirementThreeD
              projectId={projectId}
              requirementId={requirementId}
              submenuItems={submenuItems}
              prefixBreadcrumbs={breadcrumbs}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      >
        <Route
          path={"items/:itemId"}
          element={
            <ProjectRequirementEditItem
              projectId={projectId}
              requirementId={requirementId}
              pathToDocPage={`/projects/${projectId}/requirements/${requirementId}/3d`}
              isAbsolute
            />
          }
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
