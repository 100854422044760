import { gql, useApolloClient } from "@apollo/client";
import {
  CardContainer,
  getFormattedDuration,
  getFormattedPrice,
  LabeledValue,
} from "@msys/ui";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTolgee } from "@tolgee/react";
import { BigNumber } from "bignumber.js";
import { Form, Formik } from "formik";
import { AutoSave } from "../../commons/form-fields/AutoSave.js";
import {
  useContractTypeDescriptionLabels,
  useContractTypeNameLabels,
} from "../../commons/form-fields/ContractTypeField.js";
import { DurationField } from "../../commons/form-fields/DurationField.js";
import { FormattedFloatField } from "../../commons/form-fields/FormattedFloatField.js";
import { FormattedPriceField } from "../../commons/form-fields/FormattedPriceField.js";
import { Stack } from "../../commons/layout/Stack.js";
import { TIME_AND_MATERIAL_CONTRACT_TYPES } from "../../constants.js";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";
import { ItemCalculationFragment } from "../doc-items/Fragments.generated.js";
import { useQuantityUnits } from "../doc-items/useQuantityUnits.js";
import { InvoiceSelectAgreedOrProposedList } from "./buttons/InvoiceSelectButton.js";
import {
  InvoiceItemCalculationBox_ItemFragment,
  InvoiceItemCalculationBox_QuoteFragment,
} from "./InvoiceItemCalculationBox.generated.js";
import { useInvoiceQuoteModifyItemMutation } from "./Invoices.generated.js";

interface Props {
  projectId: string;
  invoiceId: string;
  doc: InvoiceItemCalculationBox_QuoteFragment;
  item: InvoiceItemCalculationBox_ItemFragment;
  itemCalculation: ItemCalculationFragment | null;
  canEdit: boolean;
}

interface FormValues {
  invoiceOverridenActualTime: number;
  invoiceOverridenActualQuantity: number;
  invoiceOverridenActualMaterialBuyingPrice: number;
}

export const InvoiceItemCalculationBox = ({
  item,
  itemCalculation,
  doc,
  projectId,
  invoiceId,
  canEdit,
}: Props) => {
  const { t } = useTranslate("InvoiceDetailsForm");
  const language = useTolgee(["language"]).getLanguage()!;
  const { quantityUnitLabels } = useQuantityUnits();
  const client = useApolloClient();
  const [modifyItem] = useInvoiceQuoteModifyItemMutation({
    client,
    refetchQueries: [
      namedOperations.Query.ProjectOutgoingInvoice,
      namedOperations.Query.ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?,
    ],
  });

  const nameLabels = useContractTypeNameLabels();
  const descriptionLabels = useContractTypeDescriptionLabels();

  if (item.type === "section") {
    return null;
  }

  if (!itemCalculation) {
    return null;
  }

  const initialTime = itemCalculation.timeTotal;

  const onSubmit = async (values: FormValues) => {
    if (!canEdit) return;
    let invoiceOverriddenActualTime = null;
    if (
      !!item.invoiceOverriddenActualTime ||
      values.invoiceOverridenActualTime !== itemCalculation.timeTotal
    ) {
      invoiceOverriddenActualTime =
        values.invoiceOverridenActualQuantity !== 0
          ? new BigNumber(values.invoiceOverridenActualTime)
              .dividedBy(new BigNumber(values.invoiceOverridenActualQuantity))
              .toNumber()
          : null;
    }

    const invoiceOverriddenActualQuantity =
      values.invoiceOverridenActualQuantity !== 0 &&
      (!!item.invoiceOverriddenActualQuantity ||
        itemCalculation.quantity !== values.invoiceOverridenActualQuantity)
        ? values.invoiceOverridenActualQuantity
        : null;

    const invoiceOverriddenActualMaterialBuyingPrice =
      !!item.invoiceOverriddenActualMaterialBuyingPrice ||
      itemCalculation.materialBuyingPricePerUnit !==
        values.invoiceOverridenActualMaterialBuyingPrice
        ? values.invoiceOverridenActualMaterialBuyingPrice
        : null;

    await modifyItem({
      variables: {
        input: {
          projectId,
          docId: doc.id,
          itemId: item.id,
          invoiceId,
          values: {
            invoiceOverriddenActualTime,
            invoiceOverriddenActualQuantity,
            invoiceOverriddenActualMaterialBuyingPrice,
          },
        },
      },
    });
  };

  if (item.type !== "paid") {
    throw Error("Wrong calculation item");
  }

  const initialValues: FormValues = {
    invoiceOverridenActualTime: initialTime,
    invoiceOverridenActualQuantity: itemCalculation.quantity,
    invoiceOverridenActualMaterialBuyingPrice:
      itemCalculation.materialBuyingPricePerUnit,
  };

  return (
    <CardContainer
      isExpandable
      title={
        doc.contractType === "lumpsum"
          ? t("Calculation")
          : t("Invoice Calculation")
      }
    >
      <Formik<FormValues> onSubmit={onSubmit} initialValues={initialValues}>
        {({ values }) => (
          <Form>
            <Stack flexDirection="column" p={1}>
              <Typography variant="h3">
                {nameLabels[doc.contractType]}
              </Typography>
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {descriptionLabels[doc.contractType]}
                </Typography>
              </Box>

              <Divider />

              {item.type === "paid" && item.needsAgreement && (
                <>
                  <Typography variant="h3">{t("Calculation type")}</Typography>
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                      {t(
                        "Please select which calculation type should be applied for this invoice item:"
                      )}
                    </Typography>
                  </Box>

                  <InvoiceSelectAgreedOrProposedList
                    docId={doc.id}
                    projectId={projectId}
                    invoiceId={invoiceId}
                    itemId={item.id}
                    item={item}
                    refetchQueries={[
                      namedOperations.Query.ProjectOutgoingInvoice,
                      namedOperations.Query
                        .ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?
                    ]}
                    listProps={{ dense: true }}
                    selectedAgreedOrProposed={item.invoiceAgreedOrProposed}
                  />

                  <Divider />
                </>
              )}

              {/* Fixed unit price & Lump sum */}
              {!TIME_AND_MATERIAL_CONTRACT_TYPES.includes(doc.contractType) && (
                <>
                  {/*<Typography variant="h3">{t("Material")}</Typography>*/}

                  <Grid container spacing={1} alignItems="center">
                    <Grid xs={3} item></Grid>
                    <Grid xs={9} item>
                      <LabeledValue
                        label={t("Material Price / Unit")}
                        labelProps={{ style: { textAlign: "right" } }}
                        style={{ textAlign: "right" }}
                      >
                        {getFormattedPrice(
                          itemCalculation.materialPricePerUnit,
                          language
                        )}
                      </LabeledValue>
                    </Grid>
                  </Grid>

                  {/*<Typography variant="h3">{t("Work")}</Typography>*/}

                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={3}>
                      <LabeledValue label={t("Time / Unit")}>
                        {getFormattedDuration(itemCalculation.timePerUnit)}
                      </LabeledValue>
                    </Grid>
                    <Grid
                      justifyContent="center"
                      alignItems="center"
                      item
                      xs={1}
                    >
                      x
                    </Grid>
                    <Grid item xs={3}>
                      <LabeledValue label={t("Work rate")}>
                        {getFormattedPrice(
                          itemCalculation.workSellingRate,
                          language
                        )}
                      </LabeledValue>
                    </Grid>
                    <Grid
                      justifyContent="center"
                      alignItems="center"
                      item
                      xs={1}
                    >
                      =
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      direction="column"
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      <LabeledValue
                        label={t("Work Price / Unit")}
                        labelProps={{ style: { textAlign: "right" } }}
                        style={{ textAlign: "right" }}
                      >
                        {getFormattedPrice(
                          itemCalculation.workPricePerUnit,
                          language
                        )}
                      </LabeledValue>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} alignItems="center">
                    <Grid xs={8} item></Grid>
                    <Grid xs={4} item>
                      <Divider />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} alignItems="center">
                    <Grid xs={7} item>
                      <FormattedFloatField
                        name="invoiceOverridenActualQuantity"
                        label={t("Invoiced Quantity")}
                        type="float"
                        min={0}
                        // unit={"\u00A0" + quantityUnitLabels[item.quantityUnit]}
                        disabled={!canEdit}
                      />
                    </Grid>

                    <Grid
                      justifyContent="center"
                      alignItems="center"
                      item
                      xs={1}
                    >
                      x
                    </Grid>

                    <Grid
                      item
                      direction="column"
                      alignItems="flex-end"
                      justifyContent="center"
                      xs={4}
                    >
                      <LabeledValue
                        label={t("Price / Unit")}
                        labelProps={{ style: { textAlign: "right" } }}
                        style={{ textAlign: "right" }}
                      >
                        {getFormattedPrice(
                          itemCalculation.pricePerUnit,
                          language
                        )}
                      </LabeledValue>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* Time & material */}
              {TIME_AND_MATERIAL_CONTRACT_TYPES.includes(doc.contractType) && (
                <>
                  <Typography variant="h3">{t("Material")}</Typography>

                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4}>
                      <FormattedFloatField
                        type="float"
                        name="invoiceOverridenActualQuantity"
                        label={t("Invoiced Quantity")}
                        min={0}
                        unit={"\u00A0" + quantityUnitLabels[item.quantityUnit]}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid
                      justifyContent="center"
                      alignItems="center"
                      item
                      xs={1}
                    >
                      x
                    </Grid>
                    <Grid item xs={3}>
                      {doc.contractType === "fmsp_fr" ? (
                        <LabeledValue label={t("Material Price / Unit")}>
                          {getFormattedPrice(
                            itemCalculation.materialPricePerUnit,
                            language
                          )}
                        </LabeledValue>
                      ) : (
                        <FormattedPriceField
                          name="invoiceOverridenActualMaterialBuyingPrice"
                          label={t("Material Price / Unit")}
                          type="price"
                          disabled={!canEdit}
                        />
                      )}
                    </Grid>
                    <Grid
                      justifyContent="center"
                      alignItems="center"
                      item
                      xs={1}
                    >
                      =
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      direction="column"
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      <LabeledValue
                        label={t("Material Total")}
                        labelProps={{ style: { textAlign: "right" } }}
                        style={{ textAlign: "right" }}
                      >
                        {getFormattedPrice(
                          itemCalculation.materialPriceSubTotal,
                          language
                        )}
                      </LabeledValue>
                    </Grid>
                  </Grid>

                  <Typography variant="h3">{t("Work")}</Typography>

                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4}>
                      <DurationField
                        label={t("Invoiced time")}
                        name="invoiceOverridenActualTime"
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid
                      justifyContent="center"
                      alignItems="center"
                      item
                      xs={1}
                    >
                      x
                    </Grid>
                    <Grid item xs={3}>
                      <LabeledValue label={t("Work rate")}>
                        {getFormattedPrice(
                          itemCalculation.workSellingRate,
                          language
                        )}
                      </LabeledValue>
                    </Grid>
                    <Grid
                      justifyContent="center"
                      alignItems="center"
                      item
                      xs={1}
                    >
                      =
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      direction="column"
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      <LabeledValue
                        label={t("Work total")}
                        labelProps={{ style: { textAlign: "right" } }}
                        style={{ textAlign: "right" }}
                      >
                        {getFormattedPrice(
                          itemCalculation.workPriceSubTotal,
                          language
                        )}
                      </LabeledValue>
                    </Grid>
                  </Grid>
                </>
              )}

              <Divider />

              <Grid container spacing={1} alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="h3" component="span">
                    {t("Due amount")}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography variant="h3" component="span" align="right">
                      {getFormattedPrice(
                        itemCalculation.priceSubTotal,
                        language
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <AutoSave enableReinitialize initialValues={initialValues} />
            </Stack>
          </Form>
        )}
      </Formik>
    </CardContainer>
  );
};
