/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type RelatedQuoteItemsFragment = { __typename: 'BillOfMaterialsItem', relatedQuoteItems: Array<{ __typename: 'RelatedQuoteItem', quoteItem: { __typename: 'Item', id: string, title: string, pathForPdf: string, needsAgreement: boolean, binding: Types.ItemBinding, isOptional: boolean }, quote: { __typename: 'Quote', id: string, isPublished: boolean, agreement: Types.Agreement } }> };

export const RelatedQuoteItemsFragmentDoc = gql`
    fragment RelatedQuoteItems on BillOfMaterialsItem {
  relatedQuoteItems {
    quoteItem {
      id
      title
      pathForPdf
      needsAgreement
      binding
      isOptional
    }
    quote {
      id
      isPublished
      agreement
    }
  }
}
    `;