import { getFormattedNumber } from "@msys/formatting";
import { useTolgee, useTranslate } from "@tolgee/react";
import { isArray, isBoolean, isNil, isNumber, isString } from "lodash-es";
import {
  EntitySearchBoolFilterInput,
  EntitySearchBoolInFilterInput,
  EntitySearchNumberArrayOfFilterInput,
  EntitySearchNumberBetweenFilterInput,
  EntitySearchNumberFilterInput,
  EntitySearchNumberInFilterInput,
  EntitySearchTextArrayOfFilterInput,
  EntitySearchTextFilterInput,
  EntitySearchTextInFilterInput,
} from "../../../clients/graphqlTypes.js";
import {
  FilterChipGroup,
  FilterChipGroupProps,
} from "../../commons/filters/FilterChip.js";

type PropertyFilterValue =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | { min: number; max: number };

type PropertyFilterOperator =
  | "eq"
  | "lt"
  | "gt"
  | "gte"
  | "lte"
  | "in"
  | "between"
  | "anyOf"
  | "allOf";

type PropertyFilter = {
  key: string;
  boolFilter?: EntitySearchBoolFilterInput | null | undefined;
  boolInFilter?: EntitySearchBoolInFilterInput | null | undefined;
  textFilter?: EntitySearchTextFilterInput | null | undefined;
  textInFilter?: EntitySearchTextInFilterInput | null | undefined;
  numberFilter?: EntitySearchNumberFilterInput | null | undefined;
  numberInFilter?: EntitySearchNumberInFilterInput | null | undefined;
  numberBetweenFilter?: EntitySearchNumberBetweenFilterInput | null | undefined;
  textArrayOfFilter?: EntitySearchTextArrayOfFilterInput | null | undefined;
  numberArrayOfFilter?: EntitySearchNumberArrayOfFilterInput | null | undefined;
};

export function PropertiesFilterChips({
  getKeyLabel,
  ...props
}: Omit<FilterChipGroupProps<PropertyFilter>, "getValueLabel"> & {
  getKeyLabel?(key: string): string | undefined;
}) {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["Global"]);

  const showPropertyValue = (value: PropertyFilterValue): string => {
    if (isArray(value)) return value.map(v => showPropertyValue(v)).join(", ");
    if (isNumber(value)) return getFormattedNumber(value, language);
    if (isBoolean(value))
      return value ? t("Yes", { ns: "Global" }) : t("No", { ns: "Global" });
    if (isString(value)) return value;
    if (value && ("min" in value || "max" in value))
      return [
        !isNil(value.min)
          ? t("from {date}", {
              ns: "Global",
              date: getFormattedNumber(value.min, language),
            })
          : null,
        !isNil(value.max)
          ? t("to {date}", {
              ns: "Global",
              date: getFormattedNumber(value.max, language),
            })
          : null,
      ].join(" ");
    return value;
  };
  return (
    <FilterChipGroup
      {...props}
      getValueLabel={value =>
        `${getKeyLabel?.(value.key) || value.key}${mapOperator(getPropertyFilterOperator(value))}${showPropertyValue(
          getPropertyFilterValue(value)
        )}`
      }
    />
  );
}

function mapOperator(operator: PropertyFilterOperator) {
  switch (operator) {
    case "eq":
      return ": ";
    case "lt":
      return " < ";
    case "lte":
      return " ≤ ";
    case "gt":
      return " > ";
    case "gte":
      return " ≥ ";
    case "in":
      return " in ";
    case "between":
      return " between ";
    case "allOf":
      return " all of ";
    case "anyOf":
      return " any of ";
    default:
      return operator;
  }
}

function getPropertyFilterValue(filter: PropertyFilter): PropertyFilterValue {
  if (filter.boolFilter) {
    return filter.boolFilter.valueBool;
  }
  if (filter.boolInFilter) {
    return filter.boolInFilter.valueBoolIn;
  }
  if (filter.textFilter) {
    return filter.textFilter.valueText;
  }
  if (filter.textInFilter) {
    return filter.textInFilter.valueTextIn;
  }
  if (filter.numberFilter) {
    return filter.numberFilter.valueNumber;
  }
  if (filter.numberInFilter) {
    return filter.numberInFilter.valueNumberIn;
  }
  if (filter.numberBetweenFilter) {
    return {
      min: filter.numberBetweenFilter.valueNumberBetween.min,
      max: filter.numberBetweenFilter.valueNumberBetween.max,
    };
  }
  if (filter.textArrayOfFilter) {
    return filter.textArrayOfFilter.valueTextArrayOf;
  }
  if (filter.numberArrayOfFilter) {
    return filter.numberArrayOfFilter.valueNumberArrayOf;
  }

  throw new Error("No filter specified");
}

function getPropertyFilterOperator(
  filter: PropertyFilter
): PropertyFilterOperator {
  if (filter.boolFilter) {
    return filter.boolFilter.operatorBool;
  }
  if (filter.boolInFilter) {
    return filter.boolInFilter.operatorBoolIn;
  }
  if (filter.textFilter) {
    return filter.textFilter.operatorText;
  }
  if (filter.textInFilter) {
    return filter.textInFilter.operatorTextIn;
  }
  if (filter.numberFilter) {
    return filter.numberFilter.operatorNumber;
  }
  if (filter.numberInFilter) {
    return filter.numberInFilter.operatorNumberIn;
  }
  if (filter.numberBetweenFilter) {
    return filter.numberBetweenFilter.operatorNumberBetween;
  }
  if (filter.textArrayOfFilter) {
    return filter.textArrayOfFilter.operatorTextArrayOf;
  }
  if (filter.numberArrayOfFilter) {
    return filter.numberArrayOfFilter.operatorNumberArrayOf;
  }

  throw new Error("No filter specified");
}
