import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { ErrorMessage, Modal, ModalActionButtonProps } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { PageNotFound } from "../../../commons/PageNotFound.js";
import { PageGrid } from "../../../commons/layout/PageGrid.js";
import {
  useProductOverview__ProductMetaInfoQuery,
  useProductOverview__SupplierProductSetInfoQuery,
} from "../../../main-routes/products/ProductOverview.generated.js";
import { ProductOverview__ProductFragment } from "../Product.generated.js";
import { usePimGetProductByArticleNumberIdentifierQuery } from "../Products.generated.js";
import { ProductOverviewFilesBox } from "../boxes/ProductOverviewFilesBox.js";
import { ProductOverviewHeaderBox } from "../boxes/ProductOverviewHeaderBox.js";
import { ProductOverviewInstallationBox } from "../boxes/ProductOverviewInstallationBox.js";
import { ProductOverviewMergedPropertiesBox } from "../boxes/ProductOverviewMergedPropertiesBox.js";
import { ProductOverviewSupplierBox } from "../boxes/ProductOverviewSupplierBox.js";
import { ProductOverviewThreeDBox } from "../boxes/ProductOverviewThreeDBox.js";
import { useProductViewedEffect } from "../helper.js";
import { ProductOverviewSupplierProductSetInfoBox } from "../boxes/ProductOverviewSupplierProductSetInfoBox.js";

interface Props {
  fullHeight?: boolean;
  actionButtonProps?: ModalActionButtonProps;
  productSupplierId: string;
  productArticleNumber: string;
  handleSubmit?: (product: ProductOverview__ProductFragment) => Promise<void>;
  handleClose: () => void;
}

export const ProductOverviewModal = ({
  fullHeight = false,
  actionButtonProps,
  productSupplierId,
  productArticleNumber,
  handleSubmit,
  handleClose,
}: Props) => {
  const { t } = useTranslate(["Global", "Product"]);
  const navigate = useNavigate();

  const client = useApolloClient();
  const query = usePimGetProductByArticleNumberIdentifierQuery({
    client,
    variables: {
      articleNumber: productArticleNumber,
      supplierId: productSupplierId,
    },
  });

  const product = getDataOrNull(query.data?.pimGetProduct)?.product;

  const productMetaInfoQuery = useProductOverview__ProductMetaInfoQuery({
    client,
    variables: {
      articleNumber: productArticleNumber,
      supplierId: productSupplierId,
    },
  });

  const productMetaInfo = getDataOrNull(
    productMetaInfoQuery.data?.pimGetProductMetaInfo
  );

  const supplierProductSetInfoQuery =
    useProductOverview__SupplierProductSetInfoQuery({
      client,
      variables: {
        articleNumber: productArticleNumber,
        supplierId: productSupplierId,
      },
      fetchPolicy: "cache-and-network",
      skip: !product || product.__typename !== "PimSupplierProduct",
    });

  const supplierProductSetInfo = getDataOrNull(
    supplierProductSetInfoQuery.data?.pimGetSupplierProductSetInfo
  );

  useProductViewedEffect({
    client,
    product: {
      supplierId: productSupplierId,
      articleNumber: productArticleNumber,
    },
  });

  return (
    <Modal
      title={product?.texts?.title ?? product?.articleNumber}
      handleClose={handleClose}
      dialogProps={{
        maxWidth: "md",
        PaperProps: fullHeight ? { style: { height: "100%" } } : undefined,
      }}
      actionButtons={[
        {
          label: t("Back", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        ...(actionButtonProps
          ? [actionButtonProps]
          : product && handleSubmit
            ? [
                {
                  label: t("Select", {
                    ns: "Global",
                  }),
                  handleClick: async () => {
                    await handleSubmit(product);
                    handleClose();
                  },
                  buttonProps: {
                    color: "primary" as const,
                    variant: "contained" as const,
                  },
                },
              ]
            : [
                {
                  label: t("Catalogue", {
                    ns: "Product",
                  }),
                  handleClick: async () => {
                    navigate(
                      `/products/catalogues/supplier/${productSupplierId}/${encodeURIComponent(
                        productArticleNumber
                      )}/overview`
                    );
                    handleClose();
                  },
                  buttonProps: {
                    color: "primary" as const,
                    variant: "text" as const,
                  },
                },
              ]),
      ]}
      isLoading={query.loading}
    >
      {query.error ? (
        <ErrorMessage message={query.error.message} />
      ) : !product ? (
        <PageNotFound />
      ) : (
        <PageGrid columns={{ xs: 1, md: 2, lg: 2, xl: 2 }}>
          <>
            <ProductOverviewHeaderBox
              product={product}
              productMetaInfo={productMetaInfo}
              isEditable={false}
              refetchQueries={[
                namedOperations.Query.PimGetProductByArticleNumberIdentifier,
              ]}
            />
            <ProductOverviewMergedPropertiesBox
              product={product}
              viewerIsContractor={true}
            />
          </>
          <>
            <ProductOverviewInstallationBox
              product={product}
              isEditable={false}
            />
            <ProductOverviewSupplierBox product={product} isEditable={false} />
          </>
          <>
            <ProductOverviewFilesBox
              product={product}
              isEditable={false}
              refetchQueriesOnAction={[
                namedOperations.Query.PimGetProductByArticleNumberIdentifier,
              ]}
            />
            <ProductOverviewThreeDBox
              product={product}
              isEditable={false}
              refetchQueriesOnAction={[
                namedOperations.Query.PimGetProductByArticleNumberIdentifier,
              ]}
            />
            {supplierProductSetInfo &&
              supplierProductSetInfo.supplierProductSetInfo &&
              supplierProductSetInfo.supplierProductSetInfo.setProducts.length >
                0 && (
                <ProductOverviewSupplierProductSetInfoBox
                  setInfo={supplierProductSetInfo.supplierProductSetInfo}
                />
              )}
          </>
        </PageGrid>
      )}
    </Modal>
  );
};
