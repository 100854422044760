/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfirmDeactivationModalQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
  now?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type ConfirmDeactivationModalQuery = { projects: { __typename: 'ProjectConnection', edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, title: string } }> }, tickets: { __typename: 'ProjectConnection', edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, title: string } }> }, planSessions: { __typename: 'MissingCapabilities' } | { __typename: 'PlanSessionConnection', edges: Array<{ __typename: 'PlanSessionEdge', node: { __typename: 'PlanSession', id: string, from: any, till: any, isTentative: boolean, project: { __typename: 'Project', id: string, title: string } } }> } };


export const ConfirmDeactivationModalDocument = gql`
    query ConfirmDeactivationModal($userId: ID!, $now: DateTime) {
  projects(
    limit: 999999
    filters: {kind: DEFAULT, includeState: [contracted], includeStakeholderIds: [$userId]}
  ) {
    ... on ProjectConnection {
      edges {
        node {
          id
          title
        }
      }
    }
  }
  tickets: projects(
    limit: 999999
    filters: {kind: TICKET, includeState: [contracted], includeStakeholderIds: [$userId]}
  ) {
    ... on ProjectConnection {
      edges {
        node {
          id
          title
        }
      }
    }
  }
  planSessions(limit: 999999, filters: {whoIds: [$userId], rangeStart: $now}) {
    ... on PlanSessionConnection {
      edges {
        node {
          id
          from
          till
          isTentative
          project {
            id
            title
          }
        }
      }
    }
  }
}
    `;

/**
 * __useConfirmDeactivationModalQuery__
 *
 * To run a query within a React component, call `useConfirmDeactivationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmDeactivationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmDeactivationModalQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useConfirmDeactivationModalQuery(baseOptions: Apollo.QueryHookOptions<ConfirmDeactivationModalQuery, ConfirmDeactivationModalQueryVariables> & ({ variables: ConfirmDeactivationModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmDeactivationModalQuery, ConfirmDeactivationModalQueryVariables>(ConfirmDeactivationModalDocument, options);
      }
export function useConfirmDeactivationModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmDeactivationModalQuery, ConfirmDeactivationModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmDeactivationModalQuery, ConfirmDeactivationModalQueryVariables>(ConfirmDeactivationModalDocument, options);
        }
export function useConfirmDeactivationModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ConfirmDeactivationModalQuery, ConfirmDeactivationModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConfirmDeactivationModalQuery, ConfirmDeactivationModalQueryVariables>(ConfirmDeactivationModalDocument, options);
        }
export type ConfirmDeactivationModalQueryHookResult = ReturnType<typeof useConfirmDeactivationModalQuery>;
export type ConfirmDeactivationModalLazyQueryHookResult = ReturnType<typeof useConfirmDeactivationModalLazyQuery>;
export type ConfirmDeactivationModalSuspenseQueryHookResult = ReturnType<typeof useConfirmDeactivationModalSuspenseQuery>;
export type ConfirmDeactivationModalQueryResult = Apollo.QueryResult<ConfirmDeactivationModalQuery, ConfirmDeactivationModalQueryVariables>;