/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPlacementInProjectBuildingModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
}>;


export type AddPlacementInProjectBuildingModalQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, building?: { __typename: 'Building', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, parentId?: string | null, title: string, pathForPdf: string }> } | null, buildingItem?: { __typename: 'Item', id: string, originVersionNumber?: number | null, parentId?: string | null, title: string, pathForPdf: string } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string } | null } | null }, item: { __typename: 'Item', id: string, originVersionNumber?: number | null, placement?: { __typename: 'ItemRef', id: string, title: string, pathForPdf: string } | null } | { __typename: 'MissingPermissions' } };


export const AddPlacementInProjectBuildingModalDocument = gql`
    query AddPlacementInProjectBuildingModal($projectId: ID!, $docId: ID!, $itemId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        building {
          id
          items {
            id
            originVersionNumber
            parentId
            title
            pathForPdf
          }
        }
        buildingItem {
          id
          originVersionNumber
          parentId
          title
          pathForPdf
        }
        crmOrganisation {
          id
        }
      }
    }
  }
  item(projectId: $projectId, docId: $docId, itemId: $itemId) {
    ... on Item {
      id
      originVersionNumber
      placement {
        id
        title
        pathForPdf
      }
    }
  }
}
    `;

/**
 * __useAddPlacementInProjectBuildingModalQuery__
 *
 * To run a query within a React component, call `useAddPlacementInProjectBuildingModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlacementInProjectBuildingModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlacementInProjectBuildingModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useAddPlacementInProjectBuildingModalQuery(baseOptions: Apollo.QueryHookOptions<AddPlacementInProjectBuildingModalQuery, AddPlacementInProjectBuildingModalQueryVariables> & ({ variables: AddPlacementInProjectBuildingModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlacementInProjectBuildingModalQuery, AddPlacementInProjectBuildingModalQueryVariables>(AddPlacementInProjectBuildingModalDocument, options);
      }
export function useAddPlacementInProjectBuildingModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlacementInProjectBuildingModalQuery, AddPlacementInProjectBuildingModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlacementInProjectBuildingModalQuery, AddPlacementInProjectBuildingModalQueryVariables>(AddPlacementInProjectBuildingModalDocument, options);
        }
export function useAddPlacementInProjectBuildingModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AddPlacementInProjectBuildingModalQuery, AddPlacementInProjectBuildingModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddPlacementInProjectBuildingModalQuery, AddPlacementInProjectBuildingModalQueryVariables>(AddPlacementInProjectBuildingModalDocument, options);
        }
export type AddPlacementInProjectBuildingModalQueryHookResult = ReturnType<typeof useAddPlacementInProjectBuildingModalQuery>;
export type AddPlacementInProjectBuildingModalLazyQueryHookResult = ReturnType<typeof useAddPlacementInProjectBuildingModalLazyQuery>;
export type AddPlacementInProjectBuildingModalSuspenseQueryHookResult = ReturnType<typeof useAddPlacementInProjectBuildingModalSuspenseQuery>;
export type AddPlacementInProjectBuildingModalQueryResult = Apollo.QueryResult<AddPlacementInProjectBuildingModalQuery, AddPlacementInProjectBuildingModalQueryVariables>;