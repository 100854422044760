import { useApolloClient } from "@apollo/client";
import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { IntegrationInstructions as IntegrationInstructionsIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { useFeature } from "../../../../common/FeatureFlags.js";
import { QuoteSetExternalIdentifierModal } from "../QuoteSetExternalIdentifierModal.js";

interface Props {
  projectId: string;
  quoteId: string;
  type: "menu-item" | "button";
}

export const QuoteExternalIdentifierButton = ({
  projectId,
  quoteId,
  type = "menu-item",
}: Props) => {
  const { t } = useTranslate("QuoteEdit");

  const isQuoteExternalIdentifierEnabled = useFeature(
    "QuoteExternalIdentifier"
  );

  if (!isQuoteExternalIdentifierEnabled) {
    return null;
  }

  const button =
    type === "menu-item" ? (
      <MenuItemWithIcon icon={<IntegrationInstructionsIcon />}>
        {t("Set external identifier")}
      </MenuItemWithIcon>
    ) : type === "button" ? (
      <Button
        fullWidth
        size="small"
        variant="outlined"
        color="primary"
        startIcon={<IntegrationInstructionsIcon />}
      >
        {t("Set external identifier")}
      </Button>
    ) : null;

  if (button === null) return null;

  return (
    <ModalOpenButton
      Modal={QuoteSetExternalIdentifierModal}
      modalProps={{
        projectId,
        quoteId,
        handleComplete: async () => {},
      }}
    >
      {button}
    </ModalOpenButton>
  );
};
