import { StatusChip, StatusLabel } from "@msys/ui";
import React from "react";
import {
  projectEventChipColors,
  projectEventTextColors,
  ProjectStateMachineEvent,
  useProjectStates,
} from "../useProjectStates.js";

interface Props {
  projectEvent: ProjectStateMachineEvent;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: boolean;
  style?: React.CSSProperties;
}

export const ProjectEventNextStatusBadge = ({
  projectEvent,
  align,
  small,
  style,
}: Props) => {
  const { projectEventNextStateLabels } = useProjectStates();
  return (
    <StatusLabel
      color={projectEventTextColors[projectEvent]}
      align={align}
      small={small}
      style={style}
    >
      {projectEventNextStateLabels[projectEvent]}
    </StatusLabel>
  );
};

export const ProjectEventNextStatusChip = ({
  projectEvent,
  size,
  style,
}: {
  projectEvent: ProjectStateMachineEvent;
  size?: React.ComponentProps<typeof StatusChip>["size"];
  style?: React.CSSProperties;
}) => {
  const { projectEventNextStateLabels } = useProjectStates();
  return (
    <StatusChip
      label={projectEventNextStateLabels[projectEvent]}
      color={projectEventChipColors[projectEvent]}
      size={size}
      style={style}
    />
  );
};
