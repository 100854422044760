// import "./opentelemetry/local-trace";
import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { ModalStackProvider } from "@msys/ui";
import { Stack } from "@mui/material";
import Keycloak from "keycloak-js";
import React from "react";
import { Inspector, InspectParams } from "react-dev-inspector";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { ApolloClientProvider } from "../clients/ApolloClientProvider.js";
import { FeatureProvider } from "../common/FeatureFlags.js";
import { GlobalLoadingBar } from "../common/global-loading-state/GlobalLoadingBar.js";
import { GlobalLoadingStateProvider } from "../common/global-loading-state/GlobalLoadingStateProvider.js";
import { GoogleMapsProvider } from "../common/google-maps/GoogleMapsProvider.js";
import "../common/googleTagManager.js";
import "../common/mui.js";
import { suppressConsoleWarnings } from "../common/console.js";
import { MuiThemeProvider } from "../common/MuiThemeProvider.js";
import { initSentry } from "../common/sentry.js";
import { SnackbarProvider } from "../common/SnackbarProvider.js";
import { SplashScreen } from "../common/SplashScreen.js";
import "../common/syncfusion.js";
import { TranslationsProvider } from "../common/translations/TranslationsProvider.js";
import {
  DEPLOY_ENV,
  KEYCLOAK_AUTH_SERVER,
  KEYCLOAK_CLIENT,
  KEYCLOAK_REALM,
} from "../environment.js";
import { AuthProvider } from "./auth/AuthProvider.js";
import { AvailableUsersProvider } from "./auth/AvailableUsersProvider.js";
import { SelectedUserProvider } from "./auth/SelectedUserProvider.js";
import { UserDataProvider } from "./auth/UserDataProvider.js";
import { ClipboardProvider } from "./commons/hooks/useClipboard.js";
import { BrandingProvider } from "./features/branding/context.js";
import { NativeFeatureProvider } from "./global/NativeFeatureProvider.js";
import { OutdatedAppVersionProvider } from "./global/OutdatedAppVersionProvider.js";
import { OutdatedVersionDialog } from "./global/OutdatedVersionDialog.js";
import { PushNotificationsProvider } from "./global/PushNotificationsProvider.js";
import { Router } from "./global/Router.js";
import { ScreenFeatureProvider } from "./global/ScreenFeatureProvider.js";
import { SidebarProvider } from "./global/SidebarProvider.js";
import "./index.css.js";
import { InvitationTokenProvider } from "./invitation/InvitationTokenProvider.js";
import { AppRoutes } from "./Routes.js";
import { WizardApiClientProvider } from "../clients/openapi/client.js";

suppressConsoleWarnings();

const keycloak = new Keycloak({
  url: KEYCLOAK_AUTH_SERVER,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT,
});

initSentry(
  Capacitor.isNativePlatform() ? { tags: { capacitor: true } } : undefined
);

if (Capacitor.isNativePlatform()) {
  CapacitorUpdater.notifyAppReady();
}

(window as any).global = window;

const App = () => {
  // Remove initial loader
  React.useLayoutEffect(() => {
    document.querySelector("#app-loader")?.remove();
  }, []);

  return (
    <React.Suspense fallback={<SplashScreen />}>
      <WizardApiClientProvider>
        <HelmetProvider>
          <GoogleMapsProvider>
            <TranslationsProvider>
              <MuiThemeProvider>
                <FeatureProvider>
                  <ScreenFeatureProvider>
                    <NativeFeatureProvider>
                      <AuthProvider keycloak={keycloak}>
                        <SelectedUserProvider>
                          <Router>
                            <SnackbarProvider>
                              <OutdatedAppVersionProvider>
                                <ApolloClientProvider>
                                  <AvailableUsersProvider>
                                    <UserDataProvider>
                                      <ClipboardProvider>
                                        <ModalStackProvider>
                                          <BrandingProvider>
                                            <PushNotificationsProvider>
                                              <InvitationTokenProvider>
                                                <GlobalLoadingStateProvider>
                                                  <GlobalLoadingBar />
                                                  <SidebarProvider>
                                                    <Stack
                                                      direction={"row"}
                                                      sx={{
                                                        // Preventing top bar from being hidden on mobile
                                                        // when scrolling down more than one fold of the screen
                                                        height: {
                                                          xs: "unset",
                                                          md: "100%",
                                                        },
                                                        minHeight: {
                                                          xs: "100%",
                                                          md: "unset",
                                                        },
                                                      }}
                                                    >
                                                      <AppRoutes />
                                                    </Stack>
                                                  </SidebarProvider>
                                                </GlobalLoadingStateProvider>
                                              </InvitationTokenProvider>
                                              <OutdatedVersionDialog />
                                            </PushNotificationsProvider>
                                          </BrandingProvider>
                                        </ModalStackProvider>
                                      </ClipboardProvider>
                                    </UserDataProvider>
                                  </AvailableUsersProvider>
                                </ApolloClientProvider>
                              </OutdatedAppVersionProvider>
                            </SnackbarProvider>
                          </Router>
                        </SelectedUserProvider>
                      </AuthProvider>
                    </NativeFeatureProvider>
                  </ScreenFeatureProvider>
                </FeatureProvider>
              </MuiThemeProvider>
            </TranslationsProvider>
          </GoogleMapsProvider>
        </HelmetProvider>
      </WizardApiClientProvider>
    </React.Suspense>
  );
};

const InspectorWrapper =
  !Capacitor.isNativePlatform() && DEPLOY_ENV === "local"
    ? Inspector
    : React.Fragment;

const root = createRoot(document.getElementById("app-root")!);
root.render(
  <React.StrictMode>
    <InspectorWrapper
      // props docs see:
      // https://github.com/zthxxx/react-dev-inspector#inspector-component-props
      keys={["control", "shift", "command", "c"]}
      disableLaunchEditor={false}
      onHoverElement={(params: InspectParams) => {}}
      onClickElement={(params: InspectParams) => {}}
    >
      <App />
    </InspectorWrapper>
  </React.StrictMode>
);
