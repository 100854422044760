import { assertNever } from "@msys/common";
import { keyBy } from "lodash-es";
import { ItemTemplateSearchFilterPropertyFilterComputedFragment } from "../templates/templateSearchFilters.generated.js";
import { Props2NonComputedAllFragment } from "./properties.generated.js";

export function adjustTemplateSearchFilterPropertyFilterExpressionsToTemplateTypeProps2({
  filters,
  templateTypeProps,
}: {
  filters: ItemTemplateSearchFilterPropertyFilterComputedFragment[];
  templateTypeProps: Props2NonComputedAllFragment[];
}): ItemTemplateSearchFilterPropertyFilterComputedFragment[] {
  const templateTypePropsByKey = keyBy(templateTypeProps, p => p.key);

  return filters.flatMap(filter => {
    const templateTypeProp: Props2NonComputedAllFragment | null =
      templateTypePropsByKey[filter.key];

    if (!templateTypeProp) {
      return [];
    }

    const adjustedFilter =
      adjustTemplateSearchFilterPropertyFilterExpressionToTemplateTypeProperty({
        filter,
        templateTypeProp,
      });

    return adjustedFilter ? [adjustedFilter] : [];
  });
}

export function adjustTemplateSearchFilterPropertyFilterExpressionToTemplateTypeProperty({
  filter,
  templateTypeProp,
}: {
  filter: ItemTemplateSearchFilterPropertyFilterComputedFragment;
  templateTypeProp: Props2NonComputedAllFragment;
}): ItemTemplateSearchFilterPropertyFilterComputedFragment | null {
  switch (filter.__typename) {
    case "EntitySearchPropertyFilterBoolInFilterComputed": {
      if (templateTypeProp.__typename === "Props2Bool") {
        return filter;
      } else {
        return {
          ...filter,
          expr: commentedExpression(filter.expr),
        };
      }
    }
    case "EntitySearchPropertyFilterBoolFilterComputed": {
      if (templateTypeProp.__typename === "Props2Bool") {
        return filter;
      } else {
        return {
          ...filter,
          expr: commentedExpression(filter.expr),
        };
      }
    }
    case "EntitySearchPropertyFilterNumberInFilterComputed": {
      if (templateTypeProp.__typename === "Props2Number") {
        return filter;
      } else {
        return {
          ...filter,
          expr: commentedExpression(filter.expr),
        };
      }
    }
    case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
      if (templateTypeProp.__typename === "Props2Number") {
        return filter;
      } else {
        return {
          ...filter,
          expr: commentedExpression(filter.expr),
        };
      }
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
      if (templateTypeProp.__typename === "Props2NumberArray") {
        return filter;
      } else {
        return {
          ...filter,
          expr: commentedExpression(filter.expr),
        };
      }
    }
    case "EntitySearchPropertyFilterNumberFilterComputed": {
      if (templateTypeProp.__typename === "Props2Number") {
        return filter;
      } else {
        return {
          ...filter,
          expr: commentedExpression(filter.expr),
        };
      }
    }
    case "EntitySearchPropertyFilterTextInFilterComputed": {
      if (templateTypeProp.__typename === "Props2Text") {
        return filter;
      } else {
        return {
          ...filter,
          expr: commentedExpression(filter.expr),
        };
      }
    }
    case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
      if (templateTypeProp.__typename === "Props2TextArray") {
        return filter;
      } else {
        return {
          ...filter,
          expr: commentedExpression(filter.expr),
        };
      }
    }
    case "EntitySearchPropertyFilterTextFilterComputed": {
      if (templateTypeProp.__typename === "Props2Text") {
        return filter;
      } else {
        return {
          ...filter,
          expr: commentedExpression(filter.expr),
        };
      }
    }
    default:
      assertNever(filter);
  }
}

function commentedExpression(expr: string) {
  return `__NULL__ /** ${expr.replace(/\*\//g, "")} */`;
}
