/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type RequestBiddingDetailsBoxFragment = { __typename: 'Request', id: string, status: Types.RequestStatus, instructions: string };

export const RequestBiddingDetailsBoxFragmentDoc = gql`
    fragment RequestBiddingDetailsBox on Request {
  id
  status
  instructions
}
    `;