import {
  CardContainer,
  isImageOr3dModel,
  isPdfOrFile,
  MenuButton,
  processAttachment,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  List,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React, { useMemo, useRef, useState } from "react";
import { ItemAttachment } from "../../../clients/graphqlTypes.js";
import { GalleryGrid } from "../../commons/images/GalleryGrid.js";
import { ViewMode, ViewModeMenuItem } from "../../commons/ViewModeMenuItem.js";
import { AttachmentUploader } from "./AttachmentUploader.js";
import { FileBoxRow } from "./FileBoxRow.js";
import { Attachment, getRotatedUrl } from "./helpers.js";
import {
  AddAttachmentsFn,
  ModifyAttachmentFn,
  RemoveAttachmentFn,
} from "./useAttachments.js";

interface Props {
  title?: string;
  attachments: Array<Attachment | ItemAttachment> | undefined;
  gridColumns?: number;
  canSign?: boolean;
  canEdit?: boolean;
  defaultPlace?: string;
  defaultDate?: any;
  isInitiallyClosed?: boolean;
  noItemsText?: string;
  setClientVisibility?: (attachmentId: string, isVisible: boolean) => void;
  renderImages?: boolean;
  addAttachments?: AddAttachmentsFn;
  modifyAttachment?: ModifyAttachmentFn;
  removeAttachment?: RemoveAttachmentFn;
  loading?: boolean;
  renderAddIconButton?: (button: React.ReactNode) => React.ReactNode;
  renderEditIconButton?: (button: React.ReactNode) => React.ReactNode;
}

const defaultRenderButton = (button: React.ReactNode) => button;

export function FilesBoxTable({
  title,
  gridColumns = 3,
  canSign = false,
  canEdit = true,
  defaultPlace,
  defaultDate,
  isInitiallyClosed,
  noItemsText,
  attachments = [],
  setClientVisibility,
  renderImages = true,
  addAttachments,
  modifyAttachment,
  removeAttachment,
  loading,
  renderAddIconButton = defaultRenderButton,
  renderEditIconButton = defaultRenderButton,
}: Props) {
  const { t } = useTranslate("FilesBoxTable");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const pictureInputRef = useRef<HTMLInputElement>(null);

  const [isDeletable, setIsDeletable] = useState(false);

  const typedAttachments = attachments.map(processAttachment);
  const images = renderImages ? typedAttachments.filter(isImageOr3dModel) : [];
  const files = renderImages
    ? typedAttachments.filter(isPdfOrFile)
    : typedAttachments;

  const handleAdd =
    canEdit && addAttachments
      ? async () => {
          if (pictureInputRef.current) {
            pictureInputRef.current.click();
          }
        }
      : undefined;

  const handleRotate =
    canEdit && modifyAttachment
      ? async (attachment: Attachment, direction: "right" | "left") => {
          await modifyAttachment(
            attachment.id,
            getRotatedUrl(attachment.url, direction)
          );
        }
      : undefined;

  const handleDelete =
    canEdit && removeAttachment
      ? async (attachment: Attachment) => {
          await removeAttachment(attachment.id);
        }
      : undefined;

  const canChangeVisibility = Boolean(setClientVisibility);
  const viewModes: ViewMode[] = [
    null,
    ...(canChangeVisibility ? ["visibility" as const] : []),
    ...(canEdit && attachments.length > 0 ? ["delete" as const] : []),
  ];
  const [viewMode, setViewMode] = React.useState<ViewMode>(
    viewModes[0] ?? null
  );

  return (
    <CardContainer
      Icon={<AttachFileIcon />}
      title={title ?? t("Files")}
      itemCount={attachments.length}
      isInitiallyClosed={isInitiallyClosed}
      ActionButton={
        <Stack direction={"row"} spacing={1 / 2} alignItems="center">
          {canEdit &&
            renderAddIconButton(
              <Tooltip title={t("Upload file")}>
                <IconButton
                  aria-label={t("Upload file")}
                  color="primary"
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                  size="small"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          {viewModes.length > 1 &&
            renderEditIconButton(
              <MenuButton>
                <ViewModeMenuItem
                  allowedModes={viewModes}
                  viewMode={viewMode}
                  onViewModeChange={setViewMode}
                />
              </MenuButton>
            )}
        </Stack>
      }
    >
      {loading && <LinearProgress />}
      <Stack direction="column" spacing={0}>
        {images.length > 0 && (
          <Box padding={1}>
            <GalleryGrid
              images={images}
              columns={gridColumns}
              handleAdd={handleAdd}
              handleRotate={handleRotate}
              handleDelete={handleDelete}
              showDeleteButton={canEdit && isDeletable}
            />
          </Box>
        )}
        {files.length > 0 && (
          <List disablePadding>
            {files.map((file, index, array) => (
              <FileBoxRow
                key={file.id}
                file={file}
                onDeleteFile={
                  canEdit && removeAttachment
                    ? async attachmentId => {
                        await removeAttachment(attachmentId);
                      }
                    : undefined
                }
                // divider={index < array.length - 1}
                defaultPlace={defaultPlace}
                defaultDate={defaultDate}
                canSign={canSign}
                onSignedPdfAttachment={
                  canSign && addAttachments
                    ? async attachment => {
                        await addAttachments([attachment]);
                      }
                    : undefined
                }
                viewMode={viewMode}
                setClientVisibility={canEdit ? setClientVisibility : undefined}
                renderSignIconButton={renderAddIconButton}
                renderDeleteIconButton={renderEditIconButton}
                renderChangeVisibilityIconButton={renderEditIconButton}
              />
            ))}
          </List>
        )}
      </Stack>
      <AttachmentUploader
        innerRef={fileInputRef}
        accept="*"
        multiple={true}
        onComplete={async attachments => {
          await addAttachments?.(attachments);
        }}
      />
      <AttachmentUploader
        innerRef={pictureInputRef}
        accept="image/*,.heic,.heif"
        multiple={true}
        onComplete={async attachments => {
          await addAttachments?.(attachments);
        }}
      />
      {attachments.length === 0 && (
        <Stack direction={"column"} alignItems="center" padding={1} spacing={1}>
          {noItemsText && (
            <Typography variant="caption" color="gray" align="center">
              {noItemsText}
            </Typography>
          )}
          {canEdit &&
            renderAddIconButton(
              <Button
                startIcon={<AddIcon />}
                color="secondary"
                variant="text"
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              >
                {t("Upload file")}
              </Button>
            )}
        </Stack>
      )}
    </CardContainer>
  );
}

export function FilesBoxTableForm({
  title,
  gridColumns = 3,
  canSign = false,
  defaultPlace,
  defaultDate,
  isInitiallyClosed,
  noItemsText,
  setClientVisibility,
  renderImages = true,
  handleAdd,
  handleRotate,
  handleDelete,
  attachments,
  loading,
  ...props
}: Omit<Props, "canEdit" | "attachments" | "ownerId"> & {
  handleAdd: (attachment: Omit<Attachment, "id">) => void | Promise<void>;
  handleRotate: (
    attachmentId: string,
    direction: "right" | "left"
  ) => void | Promise<void>;
  handleDelete: (attachmentId: string) => void | Promise<void>;
  attachments: Array<Attachment | ItemAttachment>;
  loading?: boolean;
}) {
  const { t } = useTranslate("FilesBoxTable");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const pictureInputRef = useRef<HTMLInputElement>(null);

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isDeletable, setIsDeletable] = useState(false);

  const typedAttachments = attachments.map(processAttachment);
  const images = renderImages ? typedAttachments.filter(isImageOr3dModel) : [];
  const files = renderImages
    ? typedAttachments.filter(isPdfOrFile)
    : typedAttachments;

  const canChangeVisibility = !!setClientVisibility;

  const viewModes = useMemo(() => {
    const viewModes: ViewMode[] = [];

    viewModes.push(null);
    if (canChangeVisibility) viewModes.push("visibility");
    if (attachments.length > 0) viewModes.push("delete");

    return viewModes;
  }, [attachments, canChangeVisibility]);

  const [viewMode, setViewMode] = React.useState<ViewMode>(
    viewModes[0] ?? null
  );

  return (
    <CardContainer
      Icon={<AttachFileIcon />}
      title={title ?? t("Files")}
      itemCount={attachments.length}
      isInitiallyClosed={isInitiallyClosed}
      ActionButton={
        <Stack direction={"row"} spacing={1 / 2} alignItems="center">
          <Tooltip title={t("Upload file")}>
            <IconButton
              aria-label={t("Upload file")}
              color="primary"
              onClick={() => {
                fileInputRef.current?.click();
              }}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>

          {viewModes.length > 1 && (
            <MenuButton>
              <ViewModeMenuItem
                allowedModes={viewModes}
                viewMode={viewMode}
                onViewModeChange={setViewMode}
              />
            </MenuButton>
          )}
        </Stack>
      }
    >
      {(loading || isUploadingFile) && <LinearProgress />}
      <Stack direction="column" spacing={0}>
        {images.length > 0 && (
          <Box padding={1}>
            <GalleryGrid
              images={images}
              columns={gridColumns}
              handleAdd={() => {
                if (pictureInputRef.current) {
                  pictureInputRef.current.click();
                }
              }}
              handleRotate={(attachment, direction) => {
                handleRotate(attachment.id, direction);
              }}
              handleDelete={attachment => {
                handleDelete(attachment.id);
              }}
              showDeleteButton={isDeletable}
            />
          </Box>
        )}
        {files.length > 0 && (
          <List disablePadding>
            {files.map((file, index, array) => (
              <FileBoxRow
                key={file.id}
                file={file}
                onDeleteFile={handleDelete}
                // divider={index < array.length - 1}
                defaultPlace={defaultPlace}
                defaultDate={defaultDate}
                canSign={canSign}
                onStart={() => setIsUploadingFile(true)}
                onFinish={() => setIsUploadingFile(false)}
                onSignedPdfAttachment={attachment => {
                  handleAdd(attachment);
                }}
                viewMode={viewMode}
                setClientVisibility={setClientVisibility}
              />
            ))}
          </List>
        )}
      </Stack>
      <AttachmentUploader
        innerRef={fileInputRef}
        accept="*"
        multiple={true}
        onStart={() => setIsUploadingFile(true)}
        onComplete={attachments => {
          attachments.forEach(attachment => handleAdd(attachment));
          setIsUploadingFile(false);
        }}
      />
      <AttachmentUploader
        innerRef={pictureInputRef}
        accept="image/*,.heic,.heif"
        multiple={true}
        onStart={() => setIsUploadingFile(true)}
        onComplete={attachments => {
          attachments.forEach(attachment => handleAdd(attachment));
          setIsUploadingFile(false);
        }}
      />
      {attachments.length === 0 && (
        <Stack direction={"column"} alignItems="center" padding={1} spacing={1}>
          {noItemsText && (
            <Typography variant="caption" color="gray" align="center">
              {noItemsText}
            </Typography>
          )}
          <Button
            startIcon={<AddIcon />}
            color="secondary"
            variant="text"
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            {t("Upload file")}
          </Button>
        </Stack>
      )}
    </CardContainer>
  );
}
