import { Email as EmailIcon } from "@mui/icons-material";
import { AppBar, Toolbar } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { PermissionName } from "../../../clients/graphqlTypes.js";
import { BreadcrumbItem } from "../../commons/layout/PageBreadcrumbs.js";
import { PageTopbarItem } from "../../commons/layout/PageSubmenu.js";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { TabsWithRestriction } from "../../commons/TabsWithRestriction.js";
import { ChatIcon } from "../../features/channels/ChatIcon.js";
import { ProjectChatChannel, ProjectChats } from "./ProjectChats.js";
import { ProjectEmailPreview } from "./ProjectEmailPreview.js";
import { ProjectEmails } from "./ProjectEmails.js";

export const ProjectCommunicationRoutes = ({
  project,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: {
  project: { id: string; viewerPermissions: PermissionName[] };
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslate(["ProjectPageTopbar"]);

  const projectId = project.id;

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Materials", { ns: "ProjectPageTopbar" }),
        to: `/projects/${projectId}/materials/bill-of-materials`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  const tabItems = [
    {
      value: "project-chats",
      label: t("Chats", { ns: "ProjectPageTopbar" }),
      icon: <ChatIcon />,
      path: `/projects/${projectId}/communication/chats`,
      projectPermission: { name: "READ_PROJECT" as const, project },
    },
    {
      value: "project-emails",
      label: t("Emails", { ns: "ProjectPageTopbar" }),
      icon: <EmailIcon />,
      path: `/projects/${projectId}/communication/emails`,
      projectPermission: { name: "READ_PROJECT" as const, project },
    },
  ];

  const activeTab = tabItems.find(item =>
    matchPath(item.path, location.pathname)
  );

  const tabs = (
    <AppBar position="sticky" color="inherit">
      <Toolbar variant="dense" sx={{ minHeight: "auto" }}>
        <TabsWithRestriction
          useSelectOnMobile
          options={tabItems}
          value={activeTab?.value}
          onChange={value => {
            const tab = tabItems.find(t => t.value === value);
            if (tab) navigate(tab.path);
          }}
        />
      </Toolbar>
    </AppBar>
  );

  return (
    <Routes>
      <Route index element={<Navigate to={"chats"} replace />} />
      <Route
        path="chats"
        element={
          <ProjectChats
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
            tabs={tabs}
          />
        }
      >
        <Route
          path={":channelId"}
          element={<ProjectChatChannel projectId={projectId} />}
        />
      </Route>
      <Route
        path="emails"
        element={
          <ProjectEmails
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
            tabs={tabs}
          />
        }
      >
        <Route
          path={":emailId"}
          element={<ProjectEmailPreview projectId={projectId} />}
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
