/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationPaymentInformationBox_OrganisationFragment = { __typename: 'Organisation', id: string, bankName: string, bankAccount: string, bankCode: string };

export type OrganisationPaymentInformationBox_ViewerOrganisationFragment = { __typename: 'ViewerOrganisation', id: string, bankName: string, bankAccount: string, bankCode: string };

export type ModifyOrganisationPaymentInformationMutationVariables = Types.Exact<{
  input: Types.OrganisationUpdateInput;
}>;


export type ModifyOrganisationPaymentInformationMutation = { updateOrganisation: { __typename: 'OrganisationUpdateResult', organisation: { __typename: 'ViewerOrganisation', id: string, bankName: string, bankAccount: string, bankCode: string } } };

export const OrganisationPaymentInformationBox_OrganisationFragmentDoc = gql`
    fragment OrganisationPaymentInformationBox_Organisation on Organisation {
  id
  bankName
  bankAccount
  bankCode
}
    `;
export const OrganisationPaymentInformationBox_ViewerOrganisationFragmentDoc = gql`
    fragment OrganisationPaymentInformationBox_ViewerOrganisation on ViewerOrganisation {
  id
  bankName
  bankAccount
  bankCode
}
    `;
export const ModifyOrganisationPaymentInformationDocument = gql`
    mutation ModifyOrganisationPaymentInformation($input: OrganisationUpdateInput!) {
  updateOrganisation(input: $input) {
    organisation {
      id
      bankName
      bankAccount
      bankCode
    }
  }
}
    `;
export type ModifyOrganisationPaymentInformationMutationFn = Apollo.MutationFunction<ModifyOrganisationPaymentInformationMutation, ModifyOrganisationPaymentInformationMutationVariables>;

/**
 * __useModifyOrganisationPaymentInformationMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationPaymentInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationPaymentInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationPaymentInformationMutation, { data, loading, error }] = useModifyOrganisationPaymentInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationPaymentInformationMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationPaymentInformationMutation, ModifyOrganisationPaymentInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationPaymentInformationMutation, ModifyOrganisationPaymentInformationMutationVariables>(ModifyOrganisationPaymentInformationDocument, options);
      }
export type ModifyOrganisationPaymentInformationMutationHookResult = ReturnType<typeof useModifyOrganisationPaymentInformationMutation>;
export type ModifyOrganisationPaymentInformationMutationResult = Apollo.MutationResult<ModifyOrganisationPaymentInformationMutation>;
export type ModifyOrganisationPaymentInformationMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationPaymentInformationMutation, ModifyOrganisationPaymentInformationMutationVariables>;