/* eslint-disable react-hooks/rules-of-hooks */
import { Check as CheckIcon } from "@mui/icons-material";
import { Box, BoxProps, CircularProgress, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormikContext } from "formik";
import React from "react";
import { useLatest, useUpdateEffect } from "react-use";
import { assertNever } from "../../utils.js";
import { defaultIsEqualForm } from "../hooks/useAutoSave.js";

interface Props<V extends Record<string, any>> {
  /**
   * Function to filter current formik values before comparing with initial ones
   */
  filterValues?: (values: V) => V;
  /**
   * Initial values for enabling reinitialization
   */
  initialValues?: V;
  /**
   * Whether reinitialization is enabled
   */
  enableReinitialize?: boolean;
  /**
   * Whether auto-save feature is disabled
   */
  isAutoSaveDisabled?: boolean;
  /**
   * Debounce milliseconds after value change before actual save
   */
  debounceMs?: number;
  /**
   * Reset timeout in milliseconds before hiding "Changes saved" hint
   */
  resetMs?: number;
  /**
   * Whether to show text indicator, or icon indicator, or nothing
   */
  type?: "text" | "icon" | null;
  /**
   * Typography variant for indicator text
   */
  variant?: React.ComponentProps<typeof Typography>["variant"];
  /**
   * Justify style for indicator text
   */
  justifyContent?: React.CSSProperties["justifyContent"];
  /**
   * Custom equality check function
   */
  isEqualForm?: (v1: V | undefined, v2: V | undefined) => boolean;
  boxProps?: BoxProps;
}

export interface AutoSaveRef {
  /**
   * Force trigger saving process from outside
   */
  save: () => Promise<void>;
}

/**
 * Include this in a formik form to enable automatic submit on values change
 */
const _AutoSave = <V extends Record<string, any>>(
  {
    enableReinitialize = false,
    isAutoSaveDisabled = false,
    debounceMs = 650,
    resetMs = 3000,
    initialValues,
    filterValues,
    type = "text",
    variant = "body2",
    justifyContent = "flex-end",
    isEqualForm = defaultIsEqualForm,
    boxProps,
  }: Props<V>,
  forwardedRef: React.Ref<AutoSaveRef>
) => {
  const formik = useFormikContext<V>();
  const { t } = useTranslate("Global");

  // stores values that form has sent to the server
  const latestSubmittedValues = React.useRef<V | null>(null);
  // save values to detect actual change in initial values
  const latestInitialValues = React.useRef<V | undefined>(initialValues);
  // saved values to check actual values change
  const latestSavedValues = React.useRef<V>(formik.values);

  const options = React.useRef({
    resetMs,
    debounceMs,
    isAutoSaveDisabled,
    filterValues,
    isEqualForm,
  });
  options.current = {
    resetMs,
    debounceMs,
    isAutoSaveDisabled,
    filterValues,
    isEqualForm,
  };

  const formikRef = React.useRef(formik);
  formikRef.current = formik;

  const timeoutDebounceId = React.useRef<ReturnType<typeof setTimeout>>();
  const hasChanged = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (enableReinitialize && !initialValues)
      console.error(
        "<AutoSave>",
        "When `enableReinitialize` set to true, `initialValues` should be also provided"
      );
  }, [enableReinitialize, initialValues]);

  React.useEffect(() => {
    if (formik.enableReinitialize && !isAutoSaveDisabled)
      console.error(
        "<AutoSave>",
        "`enableReinitialize` in Formik shouldn't be set to true when using AutoSave"
      );
  }, [formik.enableReinitialize, isAutoSaveDisabled]);

  // indicator that data was saved
  const [isSaved, setIsSaved] = React.useState(false);
  useUpdateEffect(() => {
    if (options.current.isAutoSaveDisabled) return;
    if (!formik.isSubmitting) {
      setIsSaved(true);

      const timeoutResetId = setTimeout(() => {
        setIsSaved(false);
      }, options.current.resetMs);

      return () => {
        setIsSaved(false);
        clearTimeout(timeoutResetId);
      };
    }
  }, [formik.isSubmitting]);

  // enable reinitialize feature
  useUpdateEffect(() => {
    if (
      !enableReinitialize ||
      options.current.isAutoSaveDisabled ||
      !initialValues
    )
      return;

    if (
      !options.current.isEqualForm(latestInitialValues.current, initialValues)
    ) {
      // initial values were actually changed!

      // 1. just save them in latest reference
      latestInitialValues.current = initialValues;

      // 2. trying to define whether we had pre-saved values and whether they are different or not
      if (
        !latestSubmittedValues.current ||
        !options.current.isEqualForm(
          latestSubmittedValues.current,
          initialValues
        )
      ) {
        // values have changed! need to do full reset here
        formikRef.current.setValues(initialValues, false);
      }

      // 3. reset saved values to null, we don't need it anymore
      latestSubmittedValues.current = null;
    }
  }, [initialValues, enableReinitialize]);

  const autoSubmit = useLatest(async () => {
    if (formikRef.current.isSubmitting || !formikRef.current.isValid) return;

    // 1. we need to define current values using `filterValues` property
    const currentValues =
      options.current.filterValues?.(formikRef.current.values) ??
      formikRef.current.values;

    // 2. store last sent value in saved
    latestSubmittedValues.current = currentValues;
    // 3. actually submitting of the form
    await formikRef.current.submitForm();
  });

  // reacting on values change
  React.useEffect(() => {
    if (options.current.isAutoSaveDisabled) return;

    // need to wait till form is getting validated
    setTimeout(() => {
      if (formikRef.current.isValid) {
        // 1. we need to define current values using `filterValues` property
        const currentValues =
          options.current.filterValues?.(formikRef.current.values) ??
          formikRef.current.values;
        // 2. defining last saved values
        const savedValues =
          options.current.filterValues?.(latestSavedValues.current) ??
          latestSavedValues.current;

        // 3. if they are the save - just return
        if (options.current.isEqualForm(savedValues, currentValues)) {
          return;
        }
        // 4. save new last values
        latestSavedValues.current = formikRef.current.values;

        // 5. if form is still submitting - save in a flag
        if (formikRef.current.isSubmitting) {
          // just save a flag if the form is still submitting
          hasChanged.current = true;
          return;
        }

        // 5. trigger debounced update
        if (timeoutDebounceId.current) clearTimeout(timeoutDebounceId.current);
        timeoutDebounceId.current = setTimeout(() => {
          autoSubmit.current();
        }, options.current.debounceMs);
      }
    });
  }, [formik.values]); // eslint-disable-line react-hooks/exhaustive-deps

  // clean save timeout on unmount
  React.useEffect(() => {
    return () => {
      if (timeoutDebounceId.current) clearTimeout(timeoutDebounceId.current);
    };
  }, []);

  // submitting form again if values were change during submitting
  useUpdateEffect(() => {
    if (options.current.isAutoSaveDisabled) return;
    if (!formik.isSubmitting) {
      if (hasChanged.current) {
        hasChanged.current = false;

        // trigger debounced update
        if (timeoutDebounceId.current) clearTimeout(timeoutDebounceId.current);
        timeoutDebounceId.current = setTimeout(() => {
          autoSubmit.current();
        }, options.current.debounceMs);
      }
    }
  }, [formik.isSubmitting]);

  React.useImperativeHandle(forwardedRef, () => ({
    save: async () => {
      if (options.current.isAutoSaveDisabled) return;
      if (formikRef.current.isSubmitting) {
        if (formikRef.current.isValid) {
          hasChanged.current = true;
        }
        return;
      }

      // trigger form submit if needed
      if (timeoutDebounceId.current) clearTimeout(timeoutDebounceId.current);
      await autoSubmit.current();
    },
  }));

  return type === "text" ? (
    formik.isSubmitting || isSaved ? (
      <Box display="flex" justifyContent={justifyContent} {...boxProps}>
        <Typography variant={variant}>
          {formik.isSubmitting
            ? t("Saving...")
            : isSaved
              ? t("Saved changes")
              : null}
        </Typography>
      </Box>
    ) : null
  ) : type === "icon" ? (
    <Box
      width="24px"
      height="24px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...boxProps}
    >
      {formik.isSubmitting ? (
        <CircularProgress size={20} />
      ) : isSaved ? (
        <CheckIcon fontSize="small" />
      ) : null}
    </Box>
  ) : type === null ? null : (
    assertNever(type)
  );
};

export const AutoSave = React.forwardRef(_AutoSave);
