import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import { useParams, useSearchParams } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageGrid } from "../../commons/layout/PageGrid.js";
import { ProductOverviewAlternativePickerBox } from "../../features/products/boxes/ProductOverviewAlternativePickerBox.js";
import { ProductOverviewFilesBox } from "../../features/products/boxes/ProductOverviewFilesBox.js";
import { ProductOverviewHeaderBox } from "../../features/products/boxes/ProductOverviewHeaderBox.js";
import { ProductOverviewInstallationBox } from "../../features/products/boxes/ProductOverviewInstallationBox.js";
import { ProductOverviewPropertyBox } from "../../features/products/boxes/ProductOverviewPropertyBox.js";
import { ProductOverviewSupplierBox } from "../../features/products/boxes/ProductOverviewSupplierBox.js";
import { ProductOverviewSupplierProductPropertyBox } from "../../features/products/boxes/ProductOverviewSupplierProductPropertyBox.js";
import { ProductOverviewThreeDBox } from "../../features/products/boxes/ProductOverviewThreeDBox.js";
import {
  useProductBreadcrumbs,
  useProductViewedEffect,
} from "../../features/products/helper.js";
import { usePimGetProductByArticleNumberIdentifierQuery } from "../../features/products/Products.generated.js";
import {
  useProductOverview__ProductMetaInfoQuery,
  useProductOverview__SupplierProductAlternativesQuery,
  useProductOverview__SupplierProductSetInfoQuery,
} from "./ProductOverview.generated.js";
import { ProductOverviewSubHeader } from "./ProductOverviewSubHeader.js";
import { ProductOverviewSupplierProductSetInfoBox } from "../../features/products/boxes/ProductOverviewSupplierProductSetInfoBox.js";

interface Props {
  submenuItems: PageTopbarItem[];
}

export const ProductOverview = ({ submenuItems }: Props) => {
  const { supplierId, articleNumber } = useParams<{
    supplierId: string;
    articleNumber: string;
  }>();
  if (!(supplierId && articleNumber))
    throw new Error("Product identifier is missing");

  const { t } = useTranslate(["OrganisationPageTopbar", "Product", "Global"]);

  const client = useApolloClient();
  const query = usePimGetProductByArticleNumberIdentifierQuery({
    client,
    variables: { supplierId, articleNumber },
  });
  const product = getDataOrNull(query.data?.pimGetProduct)?.product;

  const supplierProductAlternativesQuery =
    useProductOverview__SupplierProductAlternativesQuery({
      client,
      variables: {
        supplierId,
        articleNumber,
      },
      fetchPolicy: "cache-and-network",
    });

  const productAlternatives = getDataOrNull(
    supplierProductAlternativesQuery.data?.pimSupplierProductAlternatives
  )?.result;

  const productMetaInfoQuery = useProductOverview__ProductMetaInfoQuery({
    client,
    variables: {
      supplierId,
      articleNumber,
    },
    fetchPolicy: "cache-and-network",
  });

  const productMetaInfo = getDataOrNull(
    productMetaInfoQuery.data?.pimGetProductMetaInfo
  );

  const supplierProductSetInfoQuery =
    useProductOverview__SupplierProductSetInfoQuery({
      client,
      variables: {
        supplierId,
        articleNumber,
      },
      fetchPolicy: "cache-and-network",
      skip: !product || product.__typename !== "PimSupplierProduct",
    });

  const supplierProductSetInfo = getDataOrNull(
    supplierProductSetInfoQuery.data?.pimGetSupplierProductSetInfo
  );

  const isEditable = Boolean(
    product &&
      product.organisationCanEdit &&
      product.__typename === "PimCustomSupplierProduct" &&
      !product.deletedAt
  );

  const isCustomProduct =
    product &&
    product.__typename === "PimSupplierProduct" &&
    product.organisationCanEdit;

  const [searchParams] = useSearchParams();
  const returnPath = searchParams.get("r");

  useProductViewedEffect({ client, product: { supplierId, articleNumber } });

  const categorySupplierId =
    product && product.__typename === "PimSupplierProduct"
      ? (product.supplierProductCategory?.supplierId ?? null)
      : null;
  const categoryKey =
    product && product.__typename === "PimSupplierProduct"
      ? (product.supplierProductCategory?.key ?? null)
      : null;

  const breadcrumbs = useProductBreadcrumbs(
    categorySupplierId,
    categoryKey,
    product?.texts?.title ?? product?.articleNumber ?? ""
  );

  const backLink: BreadcrumbItem | undefined = returnPath
    ? {
        title: `❮ ${t("Back to results", { ns: "Global" })}`,
        to: returnPath,
        // icon: <ArrowBackIosNewIcon fontSize="inherit" />,
      }
    : undefined;

  return (
    <Page
      subtitle={t("Products", { ns: "OrganisationPageTopbar" })}
      title={product?.texts?.title ?? product?.articleNumber}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      backLink={backLink}
      subHeader={
        product ? (
          <ProductOverviewSubHeader
            product={product}
            canEdit={true}
            refetchQueries={[
              namedOperations.Query.ProductOverview__ProductMetaInfo,
            ]}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
          />
        ) : undefined
      }
    >
      {product && (
        <PageContainer>
          <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
            <>
              <ProductOverviewHeaderBox
                product={product}
                productMetaInfo={productMetaInfo}
                isEditable={isEditable}
                refetchQueries={[
                  namedOperations.Query.PimGetProductByArticleNumberIdentifier,
                  namedOperations.Query
                    .ProductOverview__SupplierProductAlternatives,
                ]}
              />

              {!isCustomProduct && (
                <ProductOverviewPropertyBox
                  docType={null}
                  product={product}
                  isEditable={isEditable}
                  refetchQueries={[
                    namedOperations.Query
                      .PimGetProductByArticleNumberIdentifier,
                    namedOperations.Query
                      .ProductOverview__SupplierProductAlternatives,
                  ]}
                />
              )}

              <ProductOverviewSupplierProductPropertyBox
                product={product}
                isEditable={isEditable}
                refetchQueries={[
                  namedOperations.Query.PimGetProductByArticleNumberIdentifier,
                  namedOperations.Query
                    .ProductOverview__SupplierProductAlternatives,
                ]}
              />
            </>
            <>
              <ProductOverviewInstallationBox
                product={product}
                isEditable={isEditable}
              />
              <ProductOverviewSupplierBox
                product={product}
                isEditable={isEditable}
              />
            </>
            <>
              <ProductOverviewFilesBox
                product={product}
                isEditable={isEditable}
                refetchQueriesOnAction={[
                  namedOperations.Query.PimGetProductByArticleNumberIdentifier,
                  namedOperations.Query
                    .ProductOverview__SupplierProductAlternatives,
                ]}
              />
              <ProductOverviewThreeDBox
                product={product}
                isEditable={isEditable}
                refetchQueriesOnAction={[
                  namedOperations.Query.PimGetProductByArticleNumberIdentifier,
                  namedOperations.Query
                    .ProductOverview__SupplierProductAlternatives,
                ]}
              />
              {/* {hasProductTypeEnabled && (
                  <ProductOverviewManufacturerProductBox
                    product={product}
                    isEditable={isEditable}
                    refetchQueries={[
                      namedOperations.Query
                        .PimGetProductByArticleNumberIdentifier,
                    ]}
                  />
                )} */}
              {supplierProductSetInfo &&
                supplierProductSetInfo.supplierProductSetInfo &&
                supplierProductSetInfo.supplierProductSetInfo.setProducts
                  .length > 0 && (
                  <ProductOverviewSupplierProductSetInfoBox
                    setInfo={supplierProductSetInfo.supplierProductSetInfo}
                  />
                )}
              {productAlternatives &&
                (productAlternatives.propertyAlternatives.length > 0 ||
                  productAlternatives.propertyMatchingProductAlternatives
                    .length > 0) && (
                  <ProductOverviewAlternativePickerBox
                    product={product}
                    productAlternatives={productAlternatives}
                  />
                )}
            </>
          </PageGrid>
        </PageContainer>
      )}
    </Page>
  );
};
