/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type ToggleApprovalStatusButton__TaskDocumentItemFragment = { __typename: 'Item', id: string, approvalRequired: boolean, isApproved?: boolean | null };

export const ToggleApprovalStatusButton__TaskDocumentItemFragmentDoc = gql`
    fragment ToggleApprovalStatusButton__TaskDocumentItem on Item {
  id
  approvalRequired
  isApproved
}
    `;