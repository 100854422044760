import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EditProjectPlanSessionsModal } from "../../features/planning/EditProjectPlanSessionsModal.js";

interface Props {
  path: "projects" | "tickets";
}

export const PlanningPlanSessions = ({ path }: Props) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error("Project id is missing");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnPath = searchParams.get("r");

  const handleClose = React.useCallback(() => {
    navigate(returnPath ?? `/planning/${path}`);
  }, [navigate, returnPath, path]);

  return (
    <EditProjectPlanSessionsModal
      projectId={projectId}
      handleClose={handleClose}
    />
  );
};
