import { gql, useApolloClient } from "@apollo/client";
import { DEFAULT_END_WORK_DAY, DEFAULT_START_WORK_DAY } from "@msys/common";
import {
  CardContainer,
  DurationType,
  getDurationInSeconds,
  getNormalizedDuration,
} from "@msys/ui";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField.js";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { TimePickerField } from "../../../commons/form-fields/TimePickerField.js";
import { TimeZoneField } from "../../../commons/form-fields/TimeZoneField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import {
  DEFAULT_PROJECT_DURATION,
  DEFAULT_TICKET_DURATION,
  DURATION_MAX_VALUES,
} from "../../../utils.js";
import {
  OrganisationScheduleSettingsBox_DefaultsFragment,
  OrganisationScheduleSettingsBox_ViewerOrganisationFragment,
  useOrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation,
  useOrganisationScheduleSettingsBox_ModifyOrganisationMutation,
} from "./OrganisationScheduleSettingsBox.generated.js";

interface FormValues {
  timezone: string;
  defaultStartWorkDay: string;
  defaultEndWorkDay: string;
  defaultProjectDurationType: DurationType;
  defaultProjectDurationAmount: number;
  defaultTicketDurationType: DurationType;
  defaultTicketDurationAmount: number;
}

interface Props {
  organisationId: string;
  organisation: OrganisationScheduleSettingsBox_ViewerOrganisationFragment;
  organisationDefaults: OrganisationScheduleSettingsBox_DefaultsFragment;
}

export const OrganisationScheduleSettingsBox = ({
  organisationId,
  organisation,
  organisationDefaults,
}: Props) => {
  const { t } = useTranslate(["OrganisationSettings", "QuoteEditFooter"]);
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const viewer = useUserData().currentUser!;
  const [modifyOrganisation] =
    useOrganisationScheduleSettingsBox_ModifyOrganisationMutation({
      client,
    });
  const [modifyOrganisationDefaults] =
    useOrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation({
      client,
    });

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        timezone: Yup.string().nullable().required(),
        defaultStartWorkDay: Yup.string()
          .label(
            t("Default start of work day", {
              ns: "OrganisationSettings",
            })
          )
          .required(),
        defaultEndWorkDay: Yup.string()
          .label(
            t("Default end of work day", {
              ns: "OrganisationSettings",
            })
          )
          .required()
          .when(
            "defaultStartWorkDay",
            (defaultStartWorkDay: string, schema: any) => {
              return schema.test({
                test: (defaultEndWorkDay: string) =>
                  moment(defaultStartWorkDay, "HH:mm").valueOf() <
                  moment(defaultEndWorkDay, "HH:mm").valueOf(),
                message: t("End time should be after a start time", {
                  ns: "OrganisationSettings",
                }),
              });
            }
          ),
        defaultProjectDurationAmount: Yup.number()
          .label(
            t("Default project duration", {
              ns: "OrganisationSettings",
            })
          )
          .min(1)
          .required(),
        defaultProjectDurationType: Yup.string()
          .label(
            t("Units", {
              ns: "QuoteEditFooter",
            })
          )
          .oneOf(["hours", "days", "weeks", "months"]),
        defaultTicketDurationAmount: Yup.number()
          .label(
            t("Default ticket duration", {
              ns: "OrganisationSettings",
            })
          )
          .min(1)
          .required(),
        defaultTicketDurationType: Yup.string()
          .label(
            t("Units", {
              ns: "QuoteEditFooter",
            })
          )
          .oneOf(["hours", "days", "weeks", "months"]),
      }),
    [t]
  );

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const [projectDurationAmount, projectDurationType] = getNormalizedDuration(
    organisationDefaults.defaultProjectDuration || DEFAULT_PROJECT_DURATION
  );

  const [ticketDurationAmount, ticketDurationType] = getNormalizedDuration(
    organisationDefaults.defaultTicketDuration || DEFAULT_TICKET_DURATION
  );

  const initialValues: FormValues = {
    timezone: organisation.timezone,
    defaultStartWorkDay: organisationDefaults?.defaultStartWorkDay
      ? moment(organisationDefaults?.defaultStartWorkDay, "HH:mm:ssZ").format(
          "HH:mm"
        )
      : DEFAULT_START_WORK_DAY,
    defaultEndWorkDay: organisationDefaults?.defaultEndWorkDay
      ? moment(organisationDefaults?.defaultEndWorkDay, "HH:mm:ssZ").format(
          "HH:mm"
        )
      : DEFAULT_END_WORK_DAY,
    defaultProjectDurationType: projectDurationType,
    defaultProjectDurationAmount: projectDurationAmount,
    defaultTicketDurationType: ticketDurationType,
    defaultTicketDurationAmount: ticketDurationAmount,
  };

  const handleSubmit = async (values: FormValues) => {
    const suffix = ":00" + moment().format("Z");
    try {
      await modifyOrganisation({
        variables: {
          input: {
            timezone: values.timezone,
          },
        },
      });

      await modifyOrganisationDefaults({
        variables: {
          input: {
            defaultStartWorkDay: values.defaultStartWorkDay + suffix,
            defaultEndWorkDay: values.defaultEndWorkDay + suffix,
            defaultProjectDuration: getDurationInSeconds({
              durationAmount: values.defaultProjectDurationAmount,
              durationType: values.defaultProjectDurationType,
            }),
            defaultTicketDuration: getDurationInSeconds({
              durationAmount: values.defaultTicketDurationAmount,
              durationType: values.defaultTicketDurationType,
            }),
          },
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <CardContainer
      isExpandable
      title={t("Schedule settings", { ns: "OrganisationSettings" })}
    >
      <Box p={1}>
        <Formik<FormValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form>
              <Stack flexDirection="column">
                <TimeZoneField
                  name="timezone"
                  label={t("Timezone", {
                    ns: "OrganisationSettings",
                  })}
                  required
                />

                <Stack>
                  <TimePickerField
                    name="defaultStartWorkDay"
                    label={t("Default start of work day", {
                      ns: "OrganisationSettings",
                    })}
                    disabled={false}
                  />
                  <TimePickerField
                    name="defaultEndWorkDay"
                    label={t("Default end of work day", {
                      ns: "OrganisationSettings",
                    })}
                    disabled={false}
                  />
                </Stack>

                <Divider />

                <Typography>
                  {t("Default project and ticket duration", {
                    ns: "OrganisationSettings",
                  })}
                </Typography>

                <Stack>
                  <FormattedFloatField
                    name="defaultProjectDurationAmount"
                    label={t("Default project duration", {
                      ns: "OrganisationSettings",
                    })}
                    type="float"
                    min={0}
                    max={
                      DURATION_MAX_VALUES[
                        formikProps.values.defaultProjectDurationType
                      ]
                    }
                    disabled={false}
                  />
                  <SelectField
                    name="defaultProjectDurationType"
                    label={t("Units", {
                      ns: "QuoteEditFooter",
                    })}
                    disabled={false}
                    options={[
                      {
                        label: t("Hours", {
                          ns: "QuoteEditFooter",
                        }),
                        value: "hours",
                      },
                      {
                        label: t("Days", {
                          ns: "QuoteEditFooter",
                        }),
                        value: "days",
                      },
                      {
                        label: t("Weeks", {
                          ns: "QuoteEditFooter",
                        }),
                        value: "weeks",
                      },
                      {
                        label: t("Months", {
                          ns: "QuoteEditFooter",
                        }),
                        value: "months",
                      },
                    ]}
                  />
                </Stack>

                <Stack>
                  <FormattedFloatField
                    name="defaultTicketDurationAmount"
                    label={t("Default ticket duration", {
                      ns: "OrganisationSettings",
                    })}
                    type="float"
                    min={0}
                    max={
                      DURATION_MAX_VALUES[
                        formikProps.values.defaultTicketDurationType
                      ]
                    }
                    disabled={false}
                  />
                  <SelectField
                    name="defaultTicketDurationType"
                    label={t("Units", {
                      ns: "QuoteEditFooter",
                    })}
                    disabled={false}
                    options={[
                      {
                        label: t("Hours", {
                          ns: "QuoteEditFooter",
                        }),
                        value: "hours",
                      },
                      {
                        label: t("Days", {
                          ns: "QuoteEditFooter",
                        }),
                        value: "days",
                      },
                      {
                        label: t("Weeks", {
                          ns: "QuoteEditFooter",
                        }),
                        value: "weeks",
                      },
                      {
                        label: t("Months", {
                          ns: "QuoteEditFooter",
                        }),
                        value: "months",
                      },
                    ]}
                  />
                </Stack>

                <AutoSave />
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </CardContainer>
  );
};
