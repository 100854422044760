import { FormikProps } from "formik";
import { isEqualWith } from "lodash-es";
import moment from "moment";
import React from "react";
import { useLatest, useUpdateEffect } from "react-use";

export const customizerWithDates = (v1: any, v2: any) => {
  if (v1 instanceof moment && v2 instanceof moment) {
    return v1.valueOf() === v2.valueOf();
  }
  if (v1 instanceof Date && v2 instanceof Date) {
    return v1.valueOf() === v2.valueOf();
  }
};

export const defaultIsEqualForm = <V extends Record<string, any>>(
  v1: V | undefined,
  v2: V | undefined
) => {
  return isEqualWith(v1, v2, customizerWithDates);
};

interface Props<V extends Record<string, any>> {
  /**
   * Function to filter current formik values before comparing with initial ones
   */
  filterValues?: (values: V) => V;
  /**
   * Initial values for enabling reinitialization
   */
  initialValues?: V;
  /**
   * Whether reinitialization is enabled
   */
  enableReinitialize?: boolean;
  /**
   * Debounce milliseconds after value change before actual save
   */
  debounceMs?: number;
  /**
   * Custom equality check function
   */
  isEqualForm?: (v1: V | undefined, v2: V | undefined) => boolean;
  formikProps: FormikProps<V>;
}

export const useAutoSave = <V extends Record<string, any>>({
  enableReinitialize = false,
  debounceMs = 650,
  initialValues,
  filterValues,
  isEqualForm = defaultIsEqualForm,
  formikProps,
}: Props<V>) => {
  // stores values that form has sent to the server
  const latestSubmittedValues = React.useRef<V | null>(null);
  // save values to detect actual change in initial values
  const latestInitialValues = React.useRef<V | undefined>(initialValues);
  // saved values to check actual values change
  const latestSavedValues = React.useRef<V>(formikProps.values);

  const options = React.useRef({
    debounceMs,
    filterValues,
    isEqualForm,
  });
  options.current = {
    debounceMs,
    filterValues,
    isEqualForm,
  };

  const formikPropsRef = React.useRef(formikProps);
  formikPropsRef.current = formikProps;

  const timeoutDebounceId = React.useRef<ReturnType<typeof setTimeout>>();
  const hasChanged = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (enableReinitialize && !initialValues)
      console.error(
        "<AutoSave>",
        "When `enableReinitialize` set to true, `initialValues` should be also provided"
      );
  }, [enableReinitialize, initialValues]);

  React.useEffect(() => {
    if (formikProps.enableReinitialize)
      console.error(
        "<AutoSave>",
        "`enableReinitialize` in Formik shouldn't be set to true when using AutoSave"
      );
  }, [formikProps.enableReinitialize]);

  // enable reinitialize feature
  useUpdateEffect(() => {
    if (!enableReinitialize || !initialValues) return;

    if (
      !options.current.isEqualForm(latestInitialValues.current, initialValues)
    ) {
      // initial values were actually changed!

      // 1. just save them in latest reference
      latestInitialValues.current = initialValues;

      // 2. trying to define whether we had pre-saved values and whether they are different or not
      if (
        !latestSubmittedValues.current ||
        !options.current.isEqualForm(
          latestSubmittedValues.current,
          initialValues
        )
      ) {
        // values have changed! need to do full reset here
        formikPropsRef.current.setValues(initialValues, false);
      }

      // 3. reset saved values to null, we don't need it anymore
      latestSubmittedValues.current = null;
    }
  }, [initialValues, enableReinitialize]);

  const autoSubmit = useLatest(async () => {
    if (formikPropsRef.current.isSubmitting || !formikPropsRef.current.isValid)
      return;

    // 1. we need to define current values using `filterValues` property
    const currentValues =
      options.current.filterValues?.(formikPropsRef.current.values) ??
      formikPropsRef.current.values;

    // 2. store last sent value in saved
    latestSubmittedValues.current = currentValues;
    // 3. actually submitting of the form
    await formikPropsRef.current.submitForm();
  });

  // reacting on values change
  React.useEffect(() => {
    // need to wait till form is getting validated
    setTimeout(() => {
      if (formikPropsRef.current.isValid) {
        // 1. we need to define current values using `filterValues` property
        const currentValues =
          options.current.filterValues?.(formikPropsRef.current.values) ??
          formikPropsRef.current.values;
        // 2. defining last saved values
        const savedValues =
          options.current.filterValues?.(latestSavedValues.current) ??
          latestSavedValues.current;

        // 3. if they are the save - just return
        if (options.current.isEqualForm(savedValues, currentValues)) {
          return;
        }
        // 4. save new last values
        latestSavedValues.current = formikPropsRef.current.values;

        // 5. if form is still submitting - save in a flag
        if (formikPropsRef.current.isSubmitting) {
          // just save a flag if the form is still submitting
          hasChanged.current = true;
          return;
        }

        // 5. trigger debounced update
        if (timeoutDebounceId.current) clearTimeout(timeoutDebounceId.current);
        timeoutDebounceId.current = setTimeout(() => {
          autoSubmit.current();
        }, options.current.debounceMs);
      }
    });
  }, [formikProps.values]);

  // clean save timeout on unmount
  React.useEffect(() => {
    return () => {
      if (timeoutDebounceId.current) clearTimeout(timeoutDebounceId.current);
    };
  }, []);

  // submitting form again if values were change during submitting
  useUpdateEffect(() => {
    if (!formikProps.isSubmitting) {
      if (hasChanged.current) {
        hasChanged.current = false;

        // trigger debounced update
        if (timeoutDebounceId.current) clearTimeout(timeoutDebounceId.current);
        timeoutDebounceId.current = setTimeout(() => {
          autoSubmit.current();
        }, options.current.debounceMs);
      }
    }
  }, [formikProps.isSubmitting]);
};
