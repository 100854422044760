import { gql } from "@apollo/client";
import { useUseDistanceToProjectLazyQuery } from "./useDistanceToProject.generated.js";
import { useEffect, useMemo } from "react";

export const useDistanceToProject = (
  projectId: string | undefined
): Record<string, number | null> | null => {
  const [getData, query] = useUseDistanceToProjectLazyQuery({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (projectId) getData({ variables: { projectId } });
  }, [getData, projectId]);

  const distance = useMemo(
    () =>
      query.data?.organisationMemberships?.reduce(
        (acc, m) => {
          acc[m.id] = m.distanceToProject ?? null;
          return acc;
        },
        {} as Record<string, number | null>
      ) ?? {},
    [query.data?.organisationMemberships]
  );

  return !projectId ? null : distance;
};
