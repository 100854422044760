import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  BoxProps,
  Button,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useTranslate } from "@tolgee/react";
import { useFormik } from "formik";
import { isEqual } from "lodash-es";
import React from "react";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton.js";
import { FilterButton } from "../../commons/filters/FilterButton.js";
import { useAutoSave } from "../../commons/hooks/useAutoSave.js";
import { CollectionView } from "../../commons/hooks/useCollectionView.js";
import { FavouritesIconButton } from "./filters/FavouritesIconButton.js";
import {
  canShowChips,
  ProductFilterChips,
} from "./filters/ProductFilterChips.js";
import {
  getProductFormValues,
  productFiltersDefaultValue,
  ProductFiltersFormValues,
  productFiltersValidationSchema,
} from "./filters/ProductFilterFields.js";
import { ProductFilters } from "./filters/ProductFilters.js";
import {
  ProductSearchType,
  ProductSearchTypeOption,
} from "./filters/useProductSearchTypes.js";
import {
  ProductSearchInputWithManualForm,
  SearchInputFormValues,
} from "./inputs/ProductSearchInput.js";
import { ProductFilterModal } from "./modals/ProductFilterModal.js";

export type ChildrenFn = (props: {
  SearchLine: React.ReactNode;
  FiltersLine: React.ReactNode;
  ChipsLine: React.ReactNode;
  SearchBackdrop: React.ReactNode;
}) => JSX.Element;

export function ProductSearchListHeader({
  initialFilterState,
  filters,
  setFilters,
  resetFilters,
  productSearchTypeOptions,
  productSearchType,
  setProductSearchType,
  searchTerm,
  setSearchTerm,
  activeView,
  setActiveView,
  productSearchVariables,
  canChangeProductType,
  hideFilters,
  searchEnabled,
  setSearchEnabled,
  allowedViews,
  ActionButtons,
  AdditionalChips,
  handleBackClick,
  handleSearchSubmit,
  // spacing props, to support both in page and in modal
  px = 2,
  mx = -2,
  pt = 2,
  mt = -2,
  pb = 1,
  mb = -1,
  // rendering lines
  children,
}: {
  initialFilterState?: ProductFiltersFormValues;
  filters: ProductFiltersFormValues;
  setFilters: React.Dispatch<React.SetStateAction<ProductFiltersFormValues>>;
  resetFilters: () => void;
  productSearchTypeOptions: ProductSearchTypeOption[];
  productSearchType: ProductSearchType;
  setProductSearchType: (value: ProductSearchType) => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  activeView: CollectionView;
  setActiveView: (newValue: CollectionView) => void;
  productSearchVariables: { filters: ProductFiltersFormValues };
  canChangeProductType?: boolean;
  hideFilters?: boolean;
  searchEnabled: boolean;
  setSearchEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  allowedViews: CollectionView[];
  ActionButtons?: React.ReactNode;
  AdditionalChips?: React.ReactNode;
  handleBackClick?(): void;
  handleSearchSubmit?(values: SearchInputFormValues): Promise<void> | void;
  // spacing props, to support both in page and in modal
  px?: BoxProps["px"];
  mx?: BoxProps["mx"];
  pt?: BoxProps["pt"];
  mt?: BoxProps["mt"];
  pb?: BoxProps["pb"];
  mb?: BoxProps["mb"];
  // rendering lines
  children: ChildrenFn;
}) {
  const { t } = useTranslate(["Global"]);
  const { isMinTablet } = useScreenWidth();

  const initialValues = React.useMemo((): ProductFiltersFormValues => {
    return {
      ...productFiltersDefaultValue,
      ...initialFilterState,
      ...filters,
    };
  }, [filters, initialFilterState]);

  const formikProps = useFormik<ProductFiltersFormValues>({
    initialValues,
    validationSchema: productFiltersValidationSchema,
    validateOnMount: true,
    onSubmit: async (newValues, formikHelpers) => {
      setFilters(value => ({
        ...value,
        ...newValues,
      }));
    },
  });

  useAutoSave({
    enableReinitialize: true,
    initialValues,
    formikProps,
  });

  const onReset =
    canShowChips(filters) &&
    (!initialFilterState ||
      !isEqual(
        getProductFormValues(formikProps.values),
        getProductFormValues(initialFilterState)
      ))
      ? () => {
          resetFilters();
          formikProps.resetForm({
            values: { ...productFiltersDefaultValue, ...initialFilterState },
          });
        }
      : undefined;

  const theme = useTheme();

  const SearchField = (
    <ProductSearchInputWithManualForm
      productSearchTypeOptions={productSearchTypeOptions}
      productSearchType={productSearchType}
      setProductSearchType={setProductSearchType}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchEnabled={searchEnabled}
      setSearchEnabled={setSearchEnabled}
      handleSubmit={handleSearchSubmit}
    />
  );
  const SwitchViewButton = !hideFilters ? (
    <SwitchCollectionViewButton
      allowedViews={allowedViews}
      activeView={activeView}
      setActiveView={setActiveView}
    />
  ) : undefined;
  const FilterButtonComponent = !hideFilters ? (
    <Stack direction="row" spacing={0.5} height="32px" alignItems="center">
      <ModalOpenButton
        Modal={ProductFilterModal}
        modalProps={{
          formikProps,
          productSearchVariables,
          canChangeProductType,
          onReset,
        }}
      >
        <FilterButton />
      </ModalOpenButton>
      <FavouritesIconButton
        isActive={filters.organisationFavourite === true}
        toggleActive={active => {
          formikProps.setFieldValue(
            "organisationFavourite",
            active ? true : null
          );
          formikProps.submitForm();
        }}
      />
    </Stack>
  ) : undefined;
  const InlineFilters =
    isMinTablet && !hideFilters ? (
      <ProductFilters
        formikProps={formikProps}
        productSearchVariables={productSearchVariables}
        canChangeProductType={canChangeProductType}
        viewType="buttons"
      />
    ) : undefined;
  const FilterChips =
    canShowChips(filters) && !hideFilters ? (
      <ProductFilterChips
        filters={filters}
        setFilters={setFilters}
        onReset={onReset}
        canChangeProductType={canChangeProductType}
        AdditionalChips={AdditionalChips}
      />
    ) : undefined;
  const BackButton =
    handleBackClick && !hideFilters ? (
      !isMinTablet ? (
        <IconButton size="small" color="primary" onClick={handleBackClick}>
          <NavigateBeforeIcon />
        </IconButton>
      ) : (
        <Button
          size="extra-small"
          variant="text"
          color="primary"
          onClick={handleBackClick}
          startIcon={<NavigateBeforeIcon />}
        >
          {t("Back", { ns: "Global" })}
        </Button>
      )
    ) : undefined;

  // to support backdrop when search input is focused
  // @ts-ignore
  const style: SxProps = {
    position: searchEnabled ? "absolute" : "relative",
    zIndex: 2,
    px: px,
    mx: mx,
    pb: pb,
    mb: mb,
    pt: pt,
    mt: mt,
    width: "100%",
    boxSizing: searchEnabled ? "border-box" : "content-box",
    backgroundColor: theme.palette.common.white,
  };

  const SearchLine = !isMinTablet ? (
    // mobile
    <>
      <Box sx={style} p={1}>
        <Stack
          direction="row"
          width="100%"
          spacing={1}
          alignItems="center"
          position="relative"
          height="30px"
        >
          {!searchEnabled && BackButton && (
            <Box height="30px" display="flex">
              {BackButton}
            </Box>
          )}
          {SearchField}
          {!searchEnabled && FilterButtonComponent}
          {!searchEnabled && SwitchViewButton}
          {!searchEnabled && ActionButtons && (
            <Box height="30px" display="flex" alignItems="center">
              {ActionButtons}
            </Box>
          )}
        </Stack>
      </Box>
      {searchEnabled && (
        /*placeholder*/ <Box height="30px" sx={{ margin: "0 !important" }} />
      )}
    </>
  ) : (
    // desktop
    <>
      <Box sx={style}>
        <Stack
          direction="row"
          width="100%"
          spacing={1}
          alignItems="center"
          position="relative"
          height="30px"
        >
          {!searchEnabled && BackButton && (
            <Box height="30px" display="flex">
              {BackButton}
            </Box>
          )}
          {SearchField}
          {!searchEnabled && ActionButtons && (
            <Box height="30px" display="flex" alignItems="center">
              {ActionButtons}
            </Box>
          )}
        </Stack>
      </Box>
      {searchEnabled && (
        /*placeholder*/ <Box height="30px" sx={{ margin: "0 !important" }} />
      )}
    </>
  );

  const FiltersLine = !isMinTablet ? null : InlineFilters ||
    FilterButtonComponent ||
    SwitchViewButton ? (
    <Stack direction="row" width="100%" spacing={1}>
      <Stack direction="row" flex={1} alignItems="flex-start" spacing={1}>
        {InlineFilters}
        {FilterButtonComponent}
      </Stack>
      {SwitchViewButton}
    </Stack>
  ) : null;

  const ChipsLine = FilterChips;

  const SearchBackdrop = <Backdrop open={searchEnabled} sx={{ zIndex: 1 }} />;

  return children({ SearchLine, FiltersLine, ChipsLine, SearchBackdrop });

  // default
  /*
  return (
    <Stack direction="column" width="100%" spacing={1}>
      {SearchLine}
      {FiltersLine}
      {ChipsLine}
    </Stack>
  );
  */
}
