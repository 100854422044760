import { AutocompleteFreeSolo } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import {
  RelationshipRole,
  useRelationshipRoles,
} from "./useRelationshipRoles.js";

export function RelationshipRoleSelect({
  value,
  onChange,
  disabled,
  error,
}: {
  value: string | null;
  onChange: (role: string | null) => void;
  disabled?: boolean;
  error?: string;
}) {
  const { t } = useTranslate(["CrmUsers", "Global"]);
  const { roleOptions } = useRelationshipRoles();

  return (
    <AutocompleteFreeSolo<{ value: string; label: string; inputValue?: string }>
      inputLabel={t("Role", { ns: "CrmUsers" })}
      options={roleOptions}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      getNewValueOption={inputValue => ({
        inputValue,
        label: t("Add {role} role", {
          ns: "CrmUsers",
          role: inputValue,
        }),
        value: inputValue as RelationshipRole,
      })}
      value={
        roleOptions.find(r => r.value === value) ??
        (value !== null
          ? {
              value,
              label: value,
              inputValue: value,
            }
          : null)
      }
      onChange={newValue => {
        if (typeof newValue === "string") {
          onChange(newValue || null);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onChange(newValue.inputValue);
        } else if (newValue && newValue.value) {
          onChange(newValue.value);
        } else {
          onChange(null);
        }
      }}
      error={error}
      disabled={disabled}
    />
  );
}
