import { InvoiceTextReplacementName } from "@msys/textutils";
import { TableCell, TableRow } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { AvailableReplacementsModal } from "../documents/AvailableReplacementsModal.js";

interface Props {
  handleClose(): void;
}

export function InvoiceTextsAvailableReplacementsModal({ handleClose }: Props) {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  const replacements: Record<InvoiceTextReplacementName, string> = {
    "<invoice title>": t("The title of the invoice", {
      ns: "OrganisationSettings",
    }),
    "<client name>": t(
      'The first and last name of the client, e.g. "John Smith"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "<client firstname>": t('The first name of the client, e.g. "John"', {
      ns: "OrganisationSettings",
    }),
    "<client lastname>": t('The last name of the client, e.g. "Smith"', {
      ns: "OrganisationSettings",
    }),
    "<client salutation>": t('Salutation, e.g. "Mr"', {
      ns: "OrganisationSettings",
    }),
    "<organisation name>": t("The name of your organisation", {
      ns: "OrganisationSettings",
    }),
    "<your name>": t('Your full name, e.g. "John Doe"', {
      ns: "OrganisationSettings",
    }),
    "<client title>": t('Salutation, e.g. "Mr"', {
      ns: "OrganisationSettings",
    }),
  };

  return (
    <AvailableReplacementsModal handleClose={handleClose}>
      {Object.entries(replacements)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([name, description], index) => (
          <TableRow key={index}>
            <TableCell>{name}</TableCell>
            <TableCell>{description}</TableCell>
          </TableRow>
        ))}
    </AvailableReplacementsModal>
  );
}
