import { notNull } from "@msys/common";
import { CollapseSection, Modal } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";
import { CheckboxGroupField } from "../../../commons/form-fields/CheckboxField.js";
import { SwitchField } from "../../../commons/form-fields/SwitchField.js";
import { useRelationshipRoles } from "../../contact-links/useRelationshipRoles.js";
import { OpportunityListItemDisplayConfig } from "../../opportunities/components/OpportunityListItem.js";
import { PreferenceStore } from "../../users/useUserPreference.js";
import { ProjectListItemDisplayConfig } from "./ProjectListItem.js";

interface Props {
  listItemDisplayConfigStore: PreferenceStore<ProjectListItemDisplayConfig>;
  title?: string;
  handleClose(): void;
}

interface FormValues {
  listItemDisplayConfig: ProjectListItemDisplayConfig;
}

export const defaultListItemDisplayConfig: Required<ProjectListItemDisplayConfig> =
  {
    clientOrganisation: true,
    clientMainContact: true,
    clientEmail: false,
    clientPhone: false,
    projectNumber: true,
    projectLeadId: false,
    projectAddress: true,
    projectBudget: false,
    projectCreatedDate: false,
    projectEarliestStart: false,
    projectDueDate: true,
    projectCategories: false,
    projectType: false,
    projectEarliestPlanSessionDate: false,
    projectPlannedUsers: false,
    crmRoles: [],
  };

export const ProjectsListItemVisibilitySettingsModal = ({
  title,
  listItemDisplayConfigStore,
  handleClose,
}: Props) => {
  const { t } = useTranslate([
    "Opportunities",
    "Projects",
    "Global",
    "DataItem",
  ]);
  const viewer = useUserData().currentUser!;

  const initialValues: FormValues = React.useMemo(
    () => ({
      listItemDisplayConfig: {
        ...defaultListItemDisplayConfig,
        ...listItemDisplayConfigStore.value,
      },
    }),
    [listItemDisplayConfigStore.value]
  );
  const handleSubmit = (values: FormValues) => {
    listItemDisplayConfigStore.saveValue(values.listItemDisplayConfig);
    handleClose();
  };
  const formId = React.useMemo(() => uniqueId(), []);

  const clientDisplayOptions: {
    value: keyof OpportunityListItemDisplayConfig;
    label: string;
  }[] = [
    {
      value: "clientOrganisation",
      label: t("Company", { ns: "DataItem" }),
    },
    {
      value: "clientMainContact",
      label: t("Main contact", { ns: "DataItem" }),
    },
    { value: "clientEmail", label: t("Email", { ns: "DataItem" }) },
    { value: "clientPhone", label: t("Phone", { ns: "DataItem" }) },
  ];

  const projectDisplayOptions = [
    { value: "projectNumber", label: t("Number", { ns: "DataItem" }) },
    viewer.organisation.capabilities.includes("QUOTING")
      ? { value: "projectLeadId", label: t("Lead ID", { ns: "Projects" }) }
      : null,
    {
      value: "projectAddress",
      label: t("Address", { ns: "DataItem" }),
    },
    { value: "projectBudget", label: t("Budget", { ns: "DataItem" }) },
    {
      value: "projectCreatedDate",
      label: t("Created date", { ns: "DataItem" }),
    },
    {
      value: "projectEarliestStart",
      label: t("Earliest start", { ns: "DataItem" }),
    },
    {
      value: "projectDueDate",
      label: t("Deadline", { ns: "DataItem" }),
    },
    {
      value: "projectCategories",
      label: t("Categories", { ns: "DataItem" }),
    },
    { value: "projectType", label: t("Type", { ns: "DataItem" }) },
    viewer.organisation.capabilities.includes("PLANNING")
      ? {
          value: "projectPlannedUsers",
          label: t("Planned workers", { ns: "DataItem" }),
        }
      : null,
    viewer.organisation.capabilities.includes("PLANNING")
      ? {
          value: "projectEarliestPlanSessionDate",
          label: t("Earliest planned work session", { ns: "DataItem" }),
        }
      : null,
  ].filter(notNull) as {
    value: keyof OpportunityListItemDisplayConfig;
    label: string;
  }[];

  const { roleOptions } = useRelationshipRoles();

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={false}
    >
      {formikProps => (
        <Modal
          title={title ?? t("Projects view settings", { ns: "Projects" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", { ns: "Global" }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: formikProps.isSubmitting,
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
          maxWidth="xs"
        >
          <Form id={formId}>
            <Stack direction="column" spacing={2}>
              <CollapseSection
                title={t("Data on cards", { ns: "Opportunities" })}
              >
                <Stack direction="column" spacing={1}>
                  <Stack direction="column">
                    <Typography>
                      {viewer.organisation.isClientOrganisation
                        ? t("Organisation info", { ns: "Opportunities" })
                        : t("Client info", { ns: "Opportunities" })}
                    </Typography>
                    {clientDisplayOptions.map(({ value, label }) => (
                      <SwitchField
                        key={value}
                        label={label}
                        name={`listItemDisplayConfig.${value}`}
                        disabled={formikProps.isSubmitting}
                      />
                    ))}
                  </Stack>
                  <Stack direction="column">
                    <Typography>
                      {t("Project info", { ns: "Opportunities" })}
                    </Typography>
                    {projectDisplayOptions.map(({ value, label }) => (
                      <SwitchField
                        key={value}
                        label={label}
                        name={`listItemDisplayConfig.${value}`}
                        disabled={formikProps.isSubmitting}
                      />
                    ))}
                  </Stack>
                  <Stack direction="column">
                    <Typography>
                      {t("Other info", { ns: "Opportunities" })}
                    </Typography>
                    <CheckboxGroupField
                      inputType="switch"
                      name="listItemDisplayConfig.crmRoles"
                      options={roleOptions.filter(o =>
                        ["PARTNER"].includes(o.value)
                      )}
                      disabled={formikProps.isSubmitting}
                    />
                  </Stack>
                </Stack>
              </CollapseSection>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
