import { useApolloClient } from "@apollo/client";
import React from "react";
import { Meister1Flow } from "../../../clients/graphqlTypes.js";
import {
  useMeister1FlowsLazyQuery,
  useMeister1IntegrationQuery,
} from "./useM1Integration.generated.js";

export function useM1Integration(forOrganisationId: string) {
  const client = useApolloClient();
  const integrationQuery = useMeister1IntegrationQuery({
    client,
    variables: { forOrganisationId },
  });
  const m1PlusConfig = integrationQuery.data?.organisationM1PlusConfig;
  const hasMeister1Integration = !!m1PlusConfig;

  const [fetchFlows, flowsQuery] = useMeister1FlowsLazyQuery({
    client,
  });

  return {
    hasMeister1Integration,
    m1PlusConfig,
    getMeister1Flows: fetchFlows,
    isLoading: integrationQuery.loading || flowsQuery.loading,
  };
}

const EMPTY_M1_FLOWS_ARRAY: Array<Meister1Flow> = [];

export function useM1Flows(forOrganisationId: string) {
  const [meister1Flows, setMeiste1Flows] = React.useState<Array<Meister1Flow>>(
    []
  );

  const { hasMeister1Integration, getMeister1Flows, isLoading } =
    useM1Integration(forOrganisationId);

  React.useEffect(() => {
    if (hasMeister1Integration) {
      getMeister1Flows().then(result => {
        setMeiste1Flows(result.data?.meister1Flows ?? EMPTY_M1_FLOWS_ARRAY);
      });
    } else {
      setMeiste1Flows(EMPTY_M1_FLOWS_ARRAY);
    }
  }, [getMeister1Flows, hasMeister1Integration]);

  return { hasMeister1Integration, meister1Flows, isLoading };
}
