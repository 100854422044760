import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Business as BusinessIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { Alert, Button, IconButton, List, Paper, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  Address,
  CreateProjectForQuoteProductsDistributionInput,
} from "../../../clients/graphqlTypes.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { Avatar } from "../../commons/Avatar.js";
import { ProfileListItem } from "../../commons/ProfileListItem.js";
import { CheckboxField } from "../../commons/form-fields/CheckboxField.js";
import { getAddressLabel } from "../../features/addresses/helpers.js";
import { BuildingSelectModal } from "../../features/buildings/modals/BuildingSelectModal.js";
import { CrmCompanySelect } from "../../features/crm-companies/CrmCompanySelect.js";
import { CrmContactType } from "../../features/crm/useCrmContactTypes.js";
import { OrganisationAvatar } from "../../features/organisations/OrganisationAvatar.js";
import { OrganisationAvatarFragment } from "../../features/organisations/OrganisationAvatar.generated.js";
import { TemplateQuoteSelectField } from "../../features/templates/quote/TemplateQuoteSelectField.js";
import {
  useApplyArticleListMutation,
  useArticleListCrmCompanyQuery,
  useArticleListQuery,
  useArticleList_ModifyCrmCompanyEmailMutation,
} from "./OpportunitiesArticleList.generated.js";

export interface FormFields {
  crmCompany:
    | ({
        id: string;
        title: string;
        email: string | null | undefined;
      } & OrganisationAvatarFragment)
    | null
    | undefined;
  building:
    | {
        id: string;
        title: string;
        buildingAddress: Omit<Address, "__typename">;
      }
    | null
    | undefined;
  template: {
    id: string;
    title: string;
    owningSystemOrganisationId: string;
  } | null;
  quoteProductsDistributionOpts:
    | {
        deselectSiblingDecisionSelections: boolean | null | undefined;
        setExpensiveProductFirst: boolean | null | undefined;
        matchByImplicitTags: boolean | null | undefined;
      }
    | null
    | undefined;
}

const ALLOWED_CONTACT_TYPES: CrmContactType[] = ["INDIVIDUAL", "COMPANY"];

export function OpportunitiesArticleList({
  pathToPage,
  handleComplete,
}: {
  pathToPage: string;
  handleComplete: (projectId: string, quoteId: string) => void;
}) {
  const { t } = useTranslate([
    "Global",
    "ProjectCreate",
    "BuildingRow",
    "Templates",
    "Opportunities",
    "Categories",
    "QuoteCreate",
  ]);

  const debug = useFeature("DebugOutput");

  const { opportunityId } = useParams();
  if (!opportunityId) throw new Error("Request id is missing");
  const navigate = useNavigate();

  const client = useApolloClient();
  const articleListQuery = useArticleListQuery({
    client,
    variables: { id: opportunityId },
  });
  const articleList =
    articleListQuery.data?.quoteProductsDistribution.quoteProductsDistribution;
  const email = articleList?.clientEmail;

  const crmCompaniesQuery = useArticleListCrmCompanyQuery({
    client,
    variables: { email: email! },
    skip: !email,
  });
  const preselectedCrmCompany = getDataOrNull(
    crmCompaniesQuery.data?.crmCompanies
  )?.edges[0]?.node;

  const [isSelectingCrm, setIsSelectingCrm] = React.useState(false);

  const [createProject] = useApplyArticleListMutation({ client });
  const [modifyCrmEmail] = useArticleList_ModifyCrmCompanyEmailMutation({
    client,
  });

  const handleClose = React.useCallback(
    () => navigate(pathToPage),
    [navigate, pathToPage]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormFields>
      enableReinitialize
      initialValues={{
        crmCompany: preselectedCrmCompany,
        building: null,
        template: null,
        quoteProductsDistributionOpts: {
          setExpensiveProductFirst: true,
          deselectSiblingDecisionSelections: true,
          matchByImplicitTags: true,
        },
      }}
      validationSchema={Yup.object().shape({
        crmCompany: Yup.object().shape({
          id: Yup.string().required(),
        }),
        building: Yup.object()
          .shape({
            id: Yup.string().required(),
          })
          .nullable(),
        template: Yup.object().shape({ id: Yup.string().required() }),
        quoteProductsDistributionOpts: Yup.object().shape({
          setExpensiveProductFirst: Yup.boolean(),
          deselectSiblingDecisionSelections: Yup.boolean(),
          matchByImplicitTags: Yup.boolean(),
        }),
      })}
      validateOnMount
      onSubmit={async values => {
        if (!articleList) throw new Error("Article list missing");
        if (!values.crmCompany) throw new Error("Crm company missing");

        const input: CreateProjectForQuoteProductsDistributionInput = {
          project: {
            title: `${t("Bathroom", {
              ns: "Categories",
            })} ${values.crmCompany.title}`,
            description: "",
            type: "NEW" as const,
            crmOrganisationId: values.crmCompany.id,
          },
          quote: {
            title: `${t("Bathroom", {
              ns: "Categories",
            })} ${values.crmCompany.title}`,
            discountPercentage: 0,
            templateQuoteId: values.template?.id,
          },
          quoteProductsDistribution: {
            id: articleList.id,
            opts: values.quoteProductsDistributionOpts,
          },
        };

        const { data } = await createProject({ variables: { input } });
        if (!data) throw new Error("Failed to apply article list");

        if (!values.crmCompany.email && email) {
          await modifyCrmEmail({
            variables: { crmCompanyId: values.crmCompany.id, email },
          });
        }

        handleComplete(
          data.createProjectForQuoteProductsDistribution.project.id,
          data.createProjectForQuoteProductsDistribution.quote.id
        );
      }}
    >
      {formikProps => (
        <Modal
          title={t("Create Quote", {
            ns: "QuoteCreate",
          })}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Confirm", {
                ns: "Global",
              }),
              buttonProps: {
                disabled: !formikProps.isValid,
                type: "submit",
                form: formId,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          handleClose={handleClose}
          isLoading={articleListQuery.loading || crmCompaniesQuery.loading}
        >
          <Form id={formId}>
            <Stack spacing={1}>
              {!preselectedCrmCompany && email && (
                <Alert severity={"info"}>
                  {t(
                    "We could not find existing contact for {email}. Please select from existing contacts or create new contact.",
                    { ns: "Opportunities", email }
                  )}
                </Alert>
              )}
              {!formikProps.values.crmCompany || isSelectingCrm ? (
                <CrmCompanySelect
                  crmCompanyId={formikProps.values.crmCompany?.id}
                  onChange={value => {
                    formikProps.setFieldValue("crmCompany", value);
                    setIsSelectingCrm(false);
                  }}
                  inputLabel={t("Select from CRM contacts", {
                    ns: "ProjectCreate",
                  })}
                  required
                  canCreateNew
                  createModalTitle={t("Create client for project", {
                    ns: "ProjectCreate",
                  })}
                  createNewLabel={t("Add new contact", {
                    ns: "ProjectCreate",
                  })}
                  allowedContactTypesForCreate={ALLOWED_CONTACT_TYPES}
                  createModalPrefill={{ email }}
                />
              ) : (
                <Paper>
                  <List disablePadding>
                    <ProfileListItem
                      dense
                      avatar={
                        <OrganisationAvatar
                          size="m"
                          organisationAvatar={formikProps.values.crmCompany}
                        />
                      }
                      primary={formikProps.values.crmCompany.title}
                      secondary={formikProps.values.crmCompany.email}
                      button={false}
                      secondaryAction={
                        <IconButton
                          color="secondary"
                          onClick={() => setIsSelectingCrm(true)}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    />
                  </List>
                </Paper>
              )}
              {formikProps.values.crmCompany &&
                (formikProps.values.building ? (
                  <Paper>
                    <List disablePadding>
                      <ProfileListItem
                        dense
                        avatar={
                          <Avatar
                            size="m"
                            icon={<BusinessIcon />}
                            type="square"
                          />
                        }
                        primary={formikProps.values.building.title}
                        secondary={
                          formikProps.values.building.buildingAddress
                            ? getAddressLabel(
                                formikProps.values.building.buildingAddress
                              )
                            : t("Address not set", {
                                ns: "BuildingRow",
                              })
                        }
                        button={false}
                        secondaryAction={
                          <ModalOpenButton
                            Modal={BuildingSelectModal}
                            modalProps={{
                              buildingOwningOrganisationId:
                                formikProps.values.crmCompany.id,
                              hidePlacementSelect: true,
                              canCreateBuilding: true,
                              handleComplete: (
                                building,
                                buildingItem,
                                handleClose
                              ) => {
                                formikProps.setFieldValue("building", building);
                                handleClose();
                              },
                            }}
                          >
                            <IconButton color="secondary">
                              <EditIcon />
                            </IconButton>
                          </ModalOpenButton>
                        }
                      />
                    </List>
                  </Paper>
                ) : (
                  <ModalOpenButton
                    Modal={BuildingSelectModal}
                    modalProps={{
                      buildingOwningOrganisationId:
                        formikProps.values.crmCompany.id,
                      hidePlacementSelect: true,
                      canCreateBuilding: true,
                      handleComplete: (building, buildingItem, handleClose) => {
                        formikProps.setFieldValue("building", building);
                        handleClose();
                      },
                    }}
                  >
                    <Button
                      startIcon={<AddIcon />}
                      variant={"text"}
                      color="secondary"
                      sx={{ alignSelf: "flex-start" }}
                    >
                      {t("Add building", {
                        ns: "Opportunities",
                      })}
                    </Button>
                  </ModalOpenButton>
                ))}
              <TemplateQuoteSelectField
                inputLabel={t("Template", {
                  ns: "Templates",
                })}
                filterDocumentType="QUOTE"
                value={formikProps.values.template}
                onChange={value => {
                  formikProps.setFieldValue("template", value);
                }}
              />
              {debug && (
                <Stack>
                  <CheckboxField
                    name={`quoteProductsDistributionOpts.setExpensiveProductFirst`}
                    label={"setExpensiveProductFirst"}
                  />
                  <CheckboxField
                    name={`quoteProductsDistributionOpts.deselectSiblingDecisionSelections`}
                    label={"deselectSiblingDecisionSelections"}
                  />
                  <CheckboxField
                    name={`quoteProductsDistributionOpts.matchByImplicitTags`}
                    label={"matchByImplicitTags"}
                  />
                </Stack>
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
