import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { ModalOpenButton } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateQuoteRequestMutation } from "../Requests.generated.js";
import { RequestGetRequirementModal } from "../modals/RequestGetRequirementModal.js";
import {
  RequestQuoteForRequirementDocument,
  RequestQuoteForRequirementQuery,
} from "./RequestQuoteForRequirementButton.generated.js";

type Props = {
  projectId: string;
  requirementId?: string;
  refetchQueries?: string[];
  Button: React.ReactElement;
};

export function RequestQuoteForRequirementButton({
  projectId,
  requirementId,
  refetchQueries,
  Button,
}: Props) {
  const navigate = useNavigate();

  const [handleQuoteForRequirementRequest, loading] =
    useRequestQuoteForRequirement(projectId, refetchQueries);

  async function handleCreateQuoteRequest(requirementId: string) {
    const requestId = await handleQuoteForRequirementRequest(requirementId);
    navigate(`/projects/${projectId}/requests/${requestId}`);
  }

  if (!requirementId) {
    return (
      <WithRequirementInputModal
        projectId={projectId}
        handleCreateQuoteRequest={handleCreateQuoteRequest}
        Button={Button}
      />
    );
  }

  return React.cloneElement(Button, {
    onClick: () => handleCreateQuoteRequest(requirementId),
  });
}

function WithRequirementInputModal({
  projectId,
  handleCreateQuoteRequest,
  Button,
}: {
  projectId: string;
  handleCreateQuoteRequest: (requirementId: string) => Promise<void>;
  Button: React.ReactElement;
}) {
  return (
    <ModalOpenButton
      Modal={RequestGetRequirementModal}
      modalProps={{
        projectId,
        handleComplete: (requirementId, handleClose) => {
          handleClose();
          handleCreateQuoteRequest(requirementId);
        },
      }}
    >
      {Button}
    </ModalOpenButton>
  );
}

export function useRequestQuoteForRequirement(
  projectId: string,
  refetchQueries?: string[]
) {
  const { t } = useTranslate("Requests");

  const client = useApolloClient();
  const [createQuoteRequest, { loading }] = useCreateQuoteRequestMutation({
    client,
    refetchQueries,
  });

  async function handleCreateQuoteRequest(requirementId: string) {
    const requirementDocQuery =
      await client.query<RequestQuoteForRequirementQuery>({
        query: RequestQuoteForRequirementDocument,
        variables: { projectId, requirementId },
      });
    const requirement = getDataOrNull(
      requirementDocQuery.data?.requirement
    )?.requirement;

    const requestTitle = `${t("Request for Quote")} - ${
      requirement?.title ?? ""
    }`;

    const res = await createQuoteRequest({
      variables: {
        input: {
          projectId,
          categories: [],
          title: requestTitle,
          description: "",
          recipientSystemOrganisationIds: [],
          requestorDocId: requirementId,
        },
      },
    });
    const requestId = res.data?.createQuoteRequest.request.id;

    return requestId;
  }

  return [handleCreateQuoteRequest, loading] as const;
}
