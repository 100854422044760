import { useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { noop, uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Props2NonComputed } from "../../../../clients/graphqlTypes.js";
import { usePimUpdateProductMutation } from "../Product.generated.js";
import { PropertyField } from "../../doc-items/PropertyField.js";

interface Props {
  title?: string;
  isOpen?: boolean;
  productArticleNumber: string;
  productSupplierId: string;
  newProperties: Props2NonComputed[];
  currentProperties: Props2NonComputed[];
  refetchQueries?: string[];
  handleClose: () => void;
  handleComplete: () => void | Promise<void>;
}

interface FormValues {
  updateProperties: Props2NonComputed[];
}

export const ProductConfirmPropertiesModal = ({
  title,
  isOpen,
  productArticleNumber,
  productSupplierId,
  newProperties,
  currentProperties,
  refetchQueries,
  handleClose,
  handleComplete,
}: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { t } = useTranslate(["Global", "ItemPropertyField"]); // TODO: edit namespace

  const client = useApolloClient();

  const [modifyProduct] = usePimUpdateProductMutation({ client });
  const formId = useMemo(() => uniqueId(), []);
  const { enqueueSnackbar } = useSnackbar();

  const updateProperties = Object.values({
    ...Object.fromEntries(currentProperties.map(p => [p.key, p])),
    ...Object.fromEntries(newProperties.map(p => [p.key, p])),
  });

  const handleSubmit = async (properties: Props2NonComputed[]) => {
    if (isSubmitting) return;
    try {
      await modifyProduct({
        variables: {
          input: {
            productArticleNumber,
            productSupplierId,
            values: {
              properties: properties.map(property => {
                switch (property.__typename) {
                  case "Props2Bool": {
                    return {
                      bool: {
                        key: property.key,
                        label: property.label,
                        clientVisibility: property.clientVisibility,
                        valueBool: property.valueBool,
                      },
                    };
                  }
                  case "Props2Number": {
                    return {
                      number: {
                        key: property.key,
                        label: property.label,
                        clientVisibility: property.clientVisibility,
                        valueNumber: property.valueNumber,
                        unit: property.unit,
                      },
                    };
                  }
                  case "Props2Text": {
                    return {
                      text: {
                        key: property.key,
                        label: property.label,
                        allowedValuesText: property.allowedValuesText.map(
                          t => t.allowedText
                        ),
                        clientVisibility: property.clientVisibility,
                        valueText: property.valueText,
                      },
                    };
                  }
                  // TODO: pim properties
                  default:
                    throw new Error("Not supported");
                }
              }),
            },
          },
        },
        refetchQueries,
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setSubmitting(false);
      handleComplete();
    }
  };

  const initialValues: FormValues = { updateProperties };

  if (!isOpen) return null;

  return (
    <Modal
      title={
        title ??
        t("Edit services", {
          ns: "Global",
        })
      }
      dialogProps={{ maxWidth: "sm" }}
      actionButtons={[
        {
          label: t("Back", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
            disabled: isSubmitting,
          },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          buttonProps: {
            type: "submit",
            loading: isSubmitting,
            disabled: isSubmitting,
          },
          handleClick: () => handleSubmit(updateProperties),
        },
      ]}
      handleClose={handleClose}
    >
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={() => {}}
        enableReinitialize
      >
        {({ values }) => (
          <Form id={formId}>
            <Grid container spacing={1} columns={2}>
              {values.updateProperties.map((property, i) => (
                <Grid key={property.key} item xs={1}>
                  <PropertyField
                    disableExpressions
                    docId=""
                    itemId=""
                    projectId=""
                    property={property}
                    setPropertyValue={noop}
                    setVisibility={async () => {}}
                    readOnly={true}
                  />
                </Grid>
              ))}
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
