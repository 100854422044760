/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationContractingPdfFragment = { __typename: 'OrganisationContractingPdf', id: string, title: string, isEnabled: boolean, needInsertPriceTable: boolean, startPage: number, replacePages: number, replacements: Array<{ __typename: 'ContractingPdfReplacement', formFieldName: string, replacementValue: string }>, attachment?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null };

export type AddOrganisationContractingPdfsMutationVariables = Types.Exact<{
  input: Types.AddOrganisationContractingPdfInput;
}>;


export type AddOrganisationContractingPdfsMutation = { addOrganisationContractingPdf: { __typename: 'AddOrganisationContractingPdfResult', contractingPdf: { __typename: 'OrganisationContractingPdf', id: string, title: string, isEnabled: boolean, needInsertPriceTable: boolean, startPage: number, replacePages: number, replacements: Array<{ __typename: 'ContractingPdfReplacement', formFieldName: string, replacementValue: string }>, attachment?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null } } };

export type ModifyOrganisationContractingPdfsMutationVariables = Types.Exact<{
  input: Types.ModifyOrganisationContractingPdfInput;
}>;


export type ModifyOrganisationContractingPdfsMutation = { modifyOrganisationContractingPdf: { __typename: 'ModifyOrganisationContractingPdfResult', contractingPdf: { __typename: 'OrganisationContractingPdf', id: string, title: string, isEnabled: boolean, needInsertPriceTable: boolean, startPage: number, replacePages: number, replacements: Array<{ __typename: 'ContractingPdfReplacement', formFieldName: string, replacementValue: string }>, attachment?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null } } };

export type RemoveOrganisationContractingPdfsMutationVariables = Types.Exact<{
  input: Types.RemoveOrganisationContractingPdfInput;
}>;


export type RemoveOrganisationContractingPdfsMutation = { removeOrganisationContractingPdf: { __typename: 'RemoveOrganisationContractingPdfResult', result: boolean } };

export type ExtractOrganisationContractingPdfFormFieldsMutationVariables = Types.Exact<{
  input: Types.ExtractOrganisationContractingPdfFormFieldsInput;
}>;


export type ExtractOrganisationContractingPdfFormFieldsMutation = { extractOrganisationContractingPdfFormFields: { __typename: 'ExtractOrganisationContractingPdfFormFieldsResult', formFieldKeys: Array<string> } };

export const OrganisationContractingPdfFragmentDoc = gql`
    fragment OrganisationContractingPdf on OrganisationContractingPdf {
  id
  title
  isEnabled
  needInsertPriceTable
  startPage
  replacePages
  replacements {
    formFieldName
    replacementValue
  }
  attachment {
    ...AttachmentSnapshot
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const AddOrganisationContractingPdfsDocument = gql`
    mutation AddOrganisationContractingPdfs($input: AddOrganisationContractingPdfInput!) {
  addOrganisationContractingPdf(input: $input) {
    contractingPdf {
      id
      ...OrganisationContractingPdf
    }
  }
}
    ${OrganisationContractingPdfFragmentDoc}`;
export type AddOrganisationContractingPdfsMutationFn = Apollo.MutationFunction<AddOrganisationContractingPdfsMutation, AddOrganisationContractingPdfsMutationVariables>;

/**
 * __useAddOrganisationContractingPdfsMutation__
 *
 * To run a mutation, you first call `useAddOrganisationContractingPdfsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganisationContractingPdfsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganisationContractingPdfsMutation, { data, loading, error }] = useAddOrganisationContractingPdfsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganisationContractingPdfsMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganisationContractingPdfsMutation, AddOrganisationContractingPdfsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganisationContractingPdfsMutation, AddOrganisationContractingPdfsMutationVariables>(AddOrganisationContractingPdfsDocument, options);
      }
export type AddOrganisationContractingPdfsMutationHookResult = ReturnType<typeof useAddOrganisationContractingPdfsMutation>;
export type AddOrganisationContractingPdfsMutationResult = Apollo.MutationResult<AddOrganisationContractingPdfsMutation>;
export type AddOrganisationContractingPdfsMutationOptions = Apollo.BaseMutationOptions<AddOrganisationContractingPdfsMutation, AddOrganisationContractingPdfsMutationVariables>;
export const ModifyOrganisationContractingPdfsDocument = gql`
    mutation ModifyOrganisationContractingPdfs($input: ModifyOrganisationContractingPdfInput!) {
  modifyOrganisationContractingPdf(input: $input) {
    contractingPdf {
      id
      ...OrganisationContractingPdf
    }
  }
}
    ${OrganisationContractingPdfFragmentDoc}`;
export type ModifyOrganisationContractingPdfsMutationFn = Apollo.MutationFunction<ModifyOrganisationContractingPdfsMutation, ModifyOrganisationContractingPdfsMutationVariables>;

/**
 * __useModifyOrganisationContractingPdfsMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationContractingPdfsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationContractingPdfsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationContractingPdfsMutation, { data, loading, error }] = useModifyOrganisationContractingPdfsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationContractingPdfsMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationContractingPdfsMutation, ModifyOrganisationContractingPdfsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationContractingPdfsMutation, ModifyOrganisationContractingPdfsMutationVariables>(ModifyOrganisationContractingPdfsDocument, options);
      }
export type ModifyOrganisationContractingPdfsMutationHookResult = ReturnType<typeof useModifyOrganisationContractingPdfsMutation>;
export type ModifyOrganisationContractingPdfsMutationResult = Apollo.MutationResult<ModifyOrganisationContractingPdfsMutation>;
export type ModifyOrganisationContractingPdfsMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationContractingPdfsMutation, ModifyOrganisationContractingPdfsMutationVariables>;
export const RemoveOrganisationContractingPdfsDocument = gql`
    mutation RemoveOrganisationContractingPdfs($input: RemoveOrganisationContractingPdfInput!) {
  removeOrganisationContractingPdf(input: $input) {
    result
  }
}
    `;
export type RemoveOrganisationContractingPdfsMutationFn = Apollo.MutationFunction<RemoveOrganisationContractingPdfsMutation, RemoveOrganisationContractingPdfsMutationVariables>;

/**
 * __useRemoveOrganisationContractingPdfsMutation__
 *
 * To run a mutation, you first call `useRemoveOrganisationContractingPdfsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganisationContractingPdfsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganisationContractingPdfsMutation, { data, loading, error }] = useRemoveOrganisationContractingPdfsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOrganisationContractingPdfsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganisationContractingPdfsMutation, RemoveOrganisationContractingPdfsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrganisationContractingPdfsMutation, RemoveOrganisationContractingPdfsMutationVariables>(RemoveOrganisationContractingPdfsDocument, options);
      }
export type RemoveOrganisationContractingPdfsMutationHookResult = ReturnType<typeof useRemoveOrganisationContractingPdfsMutation>;
export type RemoveOrganisationContractingPdfsMutationResult = Apollo.MutationResult<RemoveOrganisationContractingPdfsMutation>;
export type RemoveOrganisationContractingPdfsMutationOptions = Apollo.BaseMutationOptions<RemoveOrganisationContractingPdfsMutation, RemoveOrganisationContractingPdfsMutationVariables>;
export const ExtractOrganisationContractingPdfFormFieldsDocument = gql`
    mutation ExtractOrganisationContractingPdfFormFields($input: ExtractOrganisationContractingPdfFormFieldsInput!) {
  extractOrganisationContractingPdfFormFields(input: $input) {
    formFieldKeys
  }
}
    `;
export type ExtractOrganisationContractingPdfFormFieldsMutationFn = Apollo.MutationFunction<ExtractOrganisationContractingPdfFormFieldsMutation, ExtractOrganisationContractingPdfFormFieldsMutationVariables>;

/**
 * __useExtractOrganisationContractingPdfFormFieldsMutation__
 *
 * To run a mutation, you first call `useExtractOrganisationContractingPdfFormFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractOrganisationContractingPdfFormFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractOrganisationContractingPdfFormFieldsMutation, { data, loading, error }] = useExtractOrganisationContractingPdfFormFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtractOrganisationContractingPdfFormFieldsMutation(baseOptions?: Apollo.MutationHookOptions<ExtractOrganisationContractingPdfFormFieldsMutation, ExtractOrganisationContractingPdfFormFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtractOrganisationContractingPdfFormFieldsMutation, ExtractOrganisationContractingPdfFormFieldsMutationVariables>(ExtractOrganisationContractingPdfFormFieldsDocument, options);
      }
export type ExtractOrganisationContractingPdfFormFieldsMutationHookResult = ReturnType<typeof useExtractOrganisationContractingPdfFormFieldsMutation>;
export type ExtractOrganisationContractingPdfFormFieldsMutationResult = Apollo.MutationResult<ExtractOrganisationContractingPdfFormFieldsMutation>;
export type ExtractOrganisationContractingPdfFormFieldsMutationOptions = Apollo.BaseMutationOptions<ExtractOrganisationContractingPdfFormFieldsMutation, ExtractOrganisationContractingPdfFormFieldsMutationVariables>;