import { CardContainer, normalizeTime } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { groupBy, mapValues, sortBy, values } from "lodash-es";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { color } from "../../../../common/MuiThemeProvider.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { durationInMinutesToTime } from "../../../utils.js";
import { PlanningIcon } from "../../planning/PlanningIcon.js";
import {
  formatDateRange,
  getTotalDurationInMinutes,
} from "../../planning/helpers.js";
import {
  ProjectOverviewPlanningBox_OrganisationDefaultsFragment,
  ProjectOverviewPlanningBox_ProjectFragment,
} from "./ProjectOverviewPlanningBox.generated.js";

interface Props {
  project: ProjectOverviewPlanningBox_ProjectFragment;
  organisationDefaults: ProjectOverviewPlanningBox_OrganisationDefaultsFragment;
  pathToPage: string;
}

export const ProjectOverviewPlanningBox = ({
  project,
  organisationDefaults,
  pathToPage,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("ProjectOverview");
  const location = useLocation();

  const planSessions = project.planSessions;

  const sessionsByAssignee = sortBy(
    values(
      mapValues(
        groupBy(planSessions, s => s.who.id),
        sessions => sortBy(sessions, [s => moment(s.from).valueOf()])
      )
    ),
    [sessionsGroup => sessionsGroup[0].who?.familyname]
  );

  return (
    <CardContainer
      Icon={<PlanningIcon />}
      title={t("Planning")}
      itemCount={planSessions.length}
      isInitiallyClosed={false}
      ActionButton={
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_PROJECT"
          project={project}
        >
          {viewer.organisation.id === project.owningSystemOrganisationId && (
            <IconButton
              color="primary"
              size="small"
              component={Link}
              to={{
                pathname: `/planning/projects/${project.id}`,
                search: new URLSearchParams({
                  r: location.pathname + location.search,
                }).toString(),
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </RestrictedByProjectPermissionWithDebug>
      }
    >
      {planSessions.length > 0 ? (
        <Box p={1}>
          <Grid container>
            <Grid item xs={4}>
              <Typography color="textSecondary" variant="caption">
                {t("Team member")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textSecondary" variant="caption">
                {t("Date and time")}
              </Typography>
            </Grid>
            <Grid item xs={2} alignItems="flex-end">
              <Typography
                color="textSecondary"
                variant="caption"
                align="right"
                component="div"
              >
                {t("Time")}
              </Typography>
            </Grid>
          </Grid>

          {sessionsByAssignee.map(sessionsGroup => {
            const assignee = sessionsGroup[0].who;
            return (
              <Grid key={assignee.id} container>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    color={
                      sessionsGroup.every(s => s.isTentative)
                        ? color.grey
                        : undefined
                    }
                  >
                    {assignee.firstname[0]}. {assignee.familyname}
                  </Typography>
                </Grid>
                <Grid xs={6} container item direction="column">
                  {sessionsGroup.map(s => (
                    <Typography
                      key={s.id}
                      component="span"
                      variant="body2"
                      color={s.isTentative ? color.grey : undefined}
                    >
                      {formatDateRange(s.from, s.till)}
                    </Typography>
                  ))}
                </Grid>
                <Grid
                  xs={2}
                  container
                  item
                  direction="column"
                  alignItems="flex-end"
                >
                  {sessionsGroup.map(s => {
                    const duration = getTotalDurationInMinutes(
                      moment(s.from),
                      moment(s.till),
                      normalizeTime(organisationDefaults?.defaultStartWorkDay),
                      normalizeTime(organisationDefaults?.defaultEndWorkDay)
                    );
                    return (
                      <Typography
                        key={s.id}
                        component="span"
                        variant="body2"
                        align="right"
                        color={s.isTentative ? color.grey : undefined}
                      >
                        {durationInMinutesToTime(duration)} h
                      </Typography>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      ) : (
        <Stack p={1} flexDirection="column">
          <Typography variant="caption" align="center" color="gray">
            {t("Schedule the work for your team.")}
          </Typography>
          <RestrictedByProjectPermissionWithDebug
            permission="MANAGE_PROJECT"
            project={project}
          >
            {viewer.organisation.id === project.owningSystemOrganisationId && (
              <Stack justifyContent="center">
                <Button
                  color="secondary"
                  startIcon={<AddIcon />}
                  component={Link}
                  to={{
                    pathname: `/planning/projects/${project.id}`,
                    search: new URLSearchParams({
                      r: location.pathname + location.search,
                    }).toString(),
                  }}
                >
                  {project.ticket
                    ? t("Schedule ticket work")
                    : t("Schedule project work")}
                </Button>
              </Stack>
            )}
          </RestrictedByProjectPermissionWithDebug>
        </Stack>
      )}
    </CardContainer>
  );
};
