import React from "react";
import { ExpressionModal, PropertyOption } from "./ExpressionModal.js";
import { useExpressionValidationError } from "./useExpressionValidation.js";
import { useSnackbar } from "notistack";

interface Props
  extends Omit<
    React.ComponentProps<typeof ExpressionModal>,
    "handleSave" | "handleDelete" | "expressionError" | "filterOptions"
  > {
  currentItemId: string;
  currentPropertyKey: string;
  handleComplete?: (
    expression: string | undefined,
    handleClose: () => void
  ) => Promise<void> | void;
}

export function AskIfConditionExpressionModal({
  currentItemId,
  currentPropertyKey,
  handleClose,
  handleComplete,
  ...props
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const filterOptions = React.useCallback(
    (option: PropertyOption) =>
      !(option.itemId === currentItemId && option.key === currentPropertyKey),
    [currentItemId, currentPropertyKey]
  );

  const relevantErrorPredicate = React.useCallback(
    (
      result: Parameters<Parameters<typeof useExpressionValidationError>[2]>[0]
    ) =>
      result.__typename === "CompileDocIsolatedExpressionResultDiagnosticAskIf",
    []
  );

  const { expressionError, validateExpressions } = useExpressionValidationError(
    props.docId,
    props.itemId,
    relevantErrorPredicate
  );

  const handleSave = async (expression: string) => {
    const result = await validateExpressions({
      docId: props.docId,
      itemId: props.itemId,
      overrides: [
        {
          itemId: props.itemId,
          askIf: { askIfExpr: expression, key: currentPropertyKey },
        },
      ],
    });

    const expressionCheckResult =
      result.data?.compileDocIsolatedExpression.results.find(
        relevantErrorPredicate
      );
    if (expressionCheckResult) {
      console.error("compile document error", expressionCheckResult);
      enqueueSnackbar(
        `Expression is invalid: ${expressionCheckResult.messageText}`,
        { variant: "error" }
      );
      return;
    }

    if (handleComplete) {
      await handleComplete(expression, handleClose);
    } else {
      handleClose();
    }
  };

  const handleDelete = async () => {
    if (handleComplete) {
      await handleComplete(undefined, handleClose);
    } else {
      handleClose();
    }
  };

  return (
    <ExpressionModal
      {...props}
      expressionError={expressionError}
      handleClose={handleClose}
      handleSave={handleSave}
      handleDelete={handleDelete}
      filterOptions={filterOptions}
    />
  );
}
