/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type BillOfMaterialsItemAlertFragment = { __typename: 'BillOfMaterialsItem', quantityRequiredTotal: number, quantityRequired: number, quantityRequiredDraft: number, quantityRequiredPending: number, quantityRequiredChange: number };

export const BillOfMaterialsItemAlertFragmentDoc = gql`
    fragment BillOfMaterialsItemAlert on BillOfMaterialsItem {
  quantityRequiredTotal
  quantityRequired
  quantityRequiredDraft
  quantityRequiredPending
  quantityRequiredChange
}
    `;