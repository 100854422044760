import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LoadingSpinner as LoadingContainer, Modal, Select } from "@msys/ui";
import { noop } from "lodash-es";
import React from "react";
import { useRequestGetRequirementModalQuery } from "./RequestGetRequirementModal.generated.js";
import { useTranslate } from "@tolgee/react";

interface Props {
  projectId: string;
  handleClose: () => void;
  handleComplete: (
    requirementId: string,
    handleClose: () => void
  ) => Promise<void> | void;
}

export function RequestGetRequirementModal({
  projectId,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["Requests", "Global"]);

  const client = useApolloClient();
  const query = useRequestGetRequirementModalQuery({
    client,
    variables: { projectId },
    fetchPolicy: "no-cache",
  });

  const ownRequirements =
    getDataOrNull(query.data?.projectRequirements)?.edges?.map(e => e.node) ??
    [];

  const [selectedRequirement, selectRequirement] = React.useState<
    string | null
  >(null);
  React.useEffect(() => {
    if (ownRequirements.length === 1) {
      selectRequirement(ownRequirements[0].id);
    }
  }, [ownRequirements]);

  if (query.loading)
    return (
      <Modal handleClose={noop}>
        <LoadingContainer />
      </Modal>
    );

  return (
    <Modal
      title={t("Select requirement", {
        ns: "Requests",
      })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text", disabled: query.loading },
        },
        {
          label: t("Select", {
            ns: "Global",
          }),
          handleClick: async () => {
            if (selectedRequirement) {
              handleComplete(selectedRequirement, handleClose);
            }
          },
          buttonProps: { loading: query.loading },
        },
      ]}
      alwaysVisible
    >
      <Select
        label={t("Requirement document", {
          ns: "Requests",
        })}
        options={ownRequirements.map(requirement => ({
          label: requirement.title,
          value: requirement.id,
        }))}
        value={selectedRequirement}
        onChange={value => selectRequirement(value)}
      />
    </Modal>
  );
}
