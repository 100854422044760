/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LikeShopTemplateMutationVariables = Types.Exact<{
  input: Types.LikeShopTemplateInput;
}>;


export type LikeShopTemplateMutation = { likeShopTemplate: { __typename: 'LikeShopTemplateResult', doc: { __typename: 'ShoppableTemplate', id: string, isLikedByMe: boolean } } };

export type UnlikeShopTemplateMutationVariables = Types.Exact<{
  input: Types.UnlikeShopTemplateInput;
}>;


export type UnlikeShopTemplateMutation = { unlikeShopTemplate: { __typename: 'UnlikeShopTemplateResult', doc: { __typename: 'ShoppableTemplate', id: string, isLikedByMe: boolean } } };


export const LikeShopTemplateDocument = gql`
    mutation LikeShopTemplate($input: LikeShopTemplateInput!) {
  likeShopTemplate(input: $input) {
    doc {
      id
      isLikedByMe
    }
  }
}
    `;
export type LikeShopTemplateMutationFn = Apollo.MutationFunction<LikeShopTemplateMutation, LikeShopTemplateMutationVariables>;

/**
 * __useLikeShopTemplateMutation__
 *
 * To run a mutation, you first call `useLikeShopTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeShopTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeShopTemplateMutation, { data, loading, error }] = useLikeShopTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikeShopTemplateMutation(baseOptions?: Apollo.MutationHookOptions<LikeShopTemplateMutation, LikeShopTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeShopTemplateMutation, LikeShopTemplateMutationVariables>(LikeShopTemplateDocument, options);
      }
export type LikeShopTemplateMutationHookResult = ReturnType<typeof useLikeShopTemplateMutation>;
export type LikeShopTemplateMutationResult = Apollo.MutationResult<LikeShopTemplateMutation>;
export type LikeShopTemplateMutationOptions = Apollo.BaseMutationOptions<LikeShopTemplateMutation, LikeShopTemplateMutationVariables>;
export const UnlikeShopTemplateDocument = gql`
    mutation UnlikeShopTemplate($input: UnlikeShopTemplateInput!) {
  unlikeShopTemplate(input: $input) {
    doc {
      id
      isLikedByMe
    }
  }
}
    `;
export type UnlikeShopTemplateMutationFn = Apollo.MutationFunction<UnlikeShopTemplateMutation, UnlikeShopTemplateMutationVariables>;

/**
 * __useUnlikeShopTemplateMutation__
 *
 * To run a mutation, you first call `useUnlikeShopTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikeShopTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikeShopTemplateMutation, { data, loading, error }] = useUnlikeShopTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnlikeShopTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UnlikeShopTemplateMutation, UnlikeShopTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlikeShopTemplateMutation, UnlikeShopTemplateMutationVariables>(UnlikeShopTemplateDocument, options);
      }
export type UnlikeShopTemplateMutationHookResult = ReturnType<typeof useUnlikeShopTemplateMutation>;
export type UnlikeShopTemplateMutationResult = Apollo.MutationResult<UnlikeShopTemplateMutation>;
export type UnlikeShopTemplateMutationOptions = Apollo.BaseMutationOptions<UnlikeShopTemplateMutation, UnlikeShopTemplateMutationVariables>;