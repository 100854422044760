import { useApolloClient } from "@apollo/client";
import {
  CardContainer,
  isImageOr3dModel,
  LabeledValue,
  processAttachment,
  RichTextValue,
} from "@msys/ui";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { isUndefined } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { ManualSave } from "../../../commons/form-fields/ManualSave.js";
import { RichTextField } from "../../../commons/form-fields/RichTextField.js";
import { PictureGallery } from "../../../commons/images/PictureGallery.js";
import { ViewMode } from "../../../commons/ViewModeMenuItem.js";
import { DocType } from "../../../../clients/graphqlTypes.js";
import { ButtonPhotoUpload } from "../../attachments/ButtonPhotoUpload.js";
import { useItemAttachments } from "../../attachments/useAttachments.js";
import {
  HeaderBox_ItemFragment,
  useHeaderBox_ModifyItemInfoMutation,
} from "./HeaderBox.generated.js";
import { PropertiesSection } from "./PropertiesSection.js";

interface FormValues {
  title: string;
  description: string;
}

interface Props {
  projectId: string | null;
  docId: string;
  docType: DocType;
  item: HeaderBox_ItemFragment;
  viewMode: ViewMode;
  setViewMode: (mode: ViewMode) => void;
  isManualSave?: boolean;
  onUpdateDataRefetchQueries?: string[];
}

export const HeaderBox = ({
  projectId,
  docId,
  docType,
  item,
  viewMode,
  setViewMode,
  isManualSave = false,
  onUpdateDataRefetchQueries,
}: Props) => {
  const { t } = useTranslate(["QuoteItem", "ItemPropertyField", "Global"]);

  const client = useApolloClient();
  const [modifyItemInfo] = useHeaderBox_ModifyItemInfoMutation({
    client,
    refetchQueries: onUpdateDataRefetchQueries,
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (values: FormValues) => {
    await modifyItemInfo({
      variables: {
        input: {
          itemId: item.id,
          docId: docId,
          projectId,
          values: {
            title: values.title,
            description: values.description,
          },
        },
      },
    });
  };

  const initialValues = React.useMemo(
    (): FormValues =>
      !item
        ? { title: "", description: "" }
        : {
            title: !isUndefined(item.pendingChangeAttributes["title"])
              ? item.pendingChangeAttributes["title"]
              : item.title,
            description: !isUndefined(
              item.pendingChangeAttributes["description"]
            )
              ? item.pendingChangeAttributes["description"]
              : item.description,
          },
    [item]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .label(t("Title", { ns: "QuoteItem" }))
          .required(),
        properties: Yup.mixed(),
      }),
    [t]
  );

  const pictures = item.attachments
    .map(processAttachment)
    .filter(isImageOr3dModel);

  const { addAttachments, modifyAttachment, removeAttachment, loading } =
    useItemAttachments(item.id, onUpdateDataRefetchQueries);

  if (viewMode === null) {
    // flag showing whether item has at least any info set in it
    const hasInfo =
      pictures.length > 0 ||
      initialValues.description ||
      item.props2?.length > 0;
    if (!hasInfo) {
      return null;
    }

    return (
      <CardContainer>
        <Stack direction="column" m={1} spacing={1}>
          <PictureGallery
            pictures={pictures}
            showDelete={false}
            showAdd={false}
            showUpload={false}
            showRotate={false}
          />
          {initialValues.description ? (
            <LabeledValue label={t("Description", { ns: "QuoteItem" })}>
              <RichTextValue
                key={initialValues.description}
                htmlContent={initialValues.description}
              />
            </LabeledValue>
          ) : null}
          {item.props2?.length > 0 && (
            <PropertiesSection
              projectId={projectId}
              docId={docId}
              docType={docType}
              itemId={item.id}
              item={item}
              readOnly={true}
            />
          )}
        </Stack>
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <Formik<FormValues>
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, ...formikProps }) => (
          <Form>
            <Stack direction="column" m={1} spacing={1}>
              {/* {projectId ? (
                <button
                  type="button"
                  onClick={async () => {
                    await continueLazyResolve({
                      variables: {
                        input: {
                          docId: item.docId,
                          itemId: item.id,
                          projectId,
                        },
                      },
                    });

                    await client.reFetchObservableQueries();
                  }}
                >
                  continue lazy resolve
                </button>
              ) : null} */}
              {pictures.length > 0 ? (
                <PictureGallery
                  pictures={pictures}
                  loading={loading}
                  addAttachments={addAttachments}
                  modifyAttachment={modifyAttachment}
                  removeAttachment={removeAttachment}
                  showDelete={true}
                  showAdd={true}
                  showRotate={true}
                  showUpload={false}
                />
              ) : (
                <ButtonPhotoUpload addAttachments={addAttachments}>
                  <Button startIcon={<AddAPhotoIcon />} variant="outlined">
                    {t("Add photo", { ns: "QuoteItem" })}
                  </Button>
                </ButtonPhotoUpload>
              )}
              <Field
                component={TextField}
                name="title"
                label={t("Title", { ns: "QuoteItem" })}
                disabled={false}
              />
              <RichTextField
                label={t("Description", { ns: "QuoteItem" })}
                name="description"
              />
              <PropertiesSection
                projectId={projectId}
                docId={docId}
                docType={docType}
                itemId={item.id}
                item={item}
                readOnly={false}
                refetchQueries={onUpdateDataRefetchQueries}
              />
              {isManualSave ? (
                <>
                  <ManualSave
                    disabled={formikProps.isSubmitting}
                    onCancel={() => {
                      setViewMode(null);
                    }}
                    onSubmitted={() => {
                      setViewMode(null);
                    }}
                  />
                  {/* <NavigationPrompt when={formikProps.dirty} /> */}
                </>
              ) : (
                <AutoSave enableReinitialize initialValues={initialValues} />
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </CardContainer>
  );
};
