import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RequestOverviewOpportunityQuoteModal } from "../../features/requests/modals/RequestOverviewModal.js";

export function RequestOverview({ pathToPage }: { pathToPage: string }) {
  const { opportunityId } = useParams();
  if (!opportunityId) throw new Error("Request id is missing");

  const navigate = useNavigate();

  const handleClose = React.useCallback(() => {
    navigate(pathToPage);
  }, [navigate, pathToPage]);

  return (
    <RequestOverviewOpportunityQuoteModal
      requestId={opportunityId}
      handleClose={handleClose}
    />
  );
}
