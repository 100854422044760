import { useScreenWidth } from "@msys/ui";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { CancelInvoiceButton } from "./buttons/CancelInvoiceButton.js";
import { OpenInvoiceButton } from "./buttons/OpenInvoiceButton.js";
import { PayInvoiceButton } from "./buttons/PayInvoiceButton.js";
import {
  InvoiceAction_InvoiceFragment,
  InvoiceAction_ProjectFragment,
} from "./InvoiceAction.generated.js";

interface Props {
  invoice: InvoiceAction_InvoiceFragment;
  project: InvoiceAction_ProjectFragment;
  onOpenPreview?: () => void;
}

export const InvoiceAction = ({ invoice, project, onOpenPreview }: Props) => {
  const { isMinDesktop } = useScreenWidth();
  const { t } = useTranslate(["InvoiceDetailsForm"]);

  const actionButtons: React.ReactNode[] = [
    invoice.status === "draft" && !isMinDesktop && onOpenPreview && (
      <Button
        color="primary"
        variant="outlined"
        onClick={onOpenPreview}
        key={"show preview"}
      >
        {t("Preview", {
          ns: "InvoiceDetailsForm",
        })}
      </Button>
    ),
    invoice.status === "draft" && (
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_INVOICES"
        project={project}
        key={"send invoice to client"}
      >
        {invoice.status === "draft" && (
          <OpenInvoiceButton
            invoiceId={invoice.id}
            projectId={project.id}
            docId={invoice.quote.id}
            disabled={
              !invoice.invoiceTexts?.opening || !invoice.invoiceTexts?.closing
            }
            paymentTermDuration={invoice.paymentTermDuration}
            deliveryOfServiceStart={invoice.deliveryOfServiceStart}
            deliveryOfServiceEnd={invoice.deliveryOfServiceEnd}
          />
        )}
      </RestrictedByProjectPermissionWithDebug>
    ),
    invoice.status !== "draft" && (
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_INVOICES"
        project={project}
        key={"cancel invoice"}
      >
        {invoice.status === "open" && (
          <CancelInvoiceButton
            invoiceId={invoice.id}
            projectId={project.id}
            docId={invoice.quote.id}
          />
        )}
      </RestrictedByProjectPermissionWithDebug>
    ),
    invoice.status !== "draft" && (
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_INVOICES"
        project={project}
        key={"mark invoice as paid"}
      >
        {invoice.status === "open" && (
          <PayInvoiceButton
            invoiceId={invoice.id}
            projectId={project.id}
            docId={invoice.quote.id}
          />
        )}
      </RestrictedByProjectPermissionWithDebug>
    ),
  ].filter(Boolean);

  return actionButtons;
};
