import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  ModalOpenButton,
  Select,
} from "@msys/ui";
import { Stack, TablePagination, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isEqual } from "lodash-es";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ButtonCreate } from "../../commons/button/Button.js";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton.js";
import { FilterButton } from "../../commons/filters/FilterButton.js";
import { FilterModal } from "../../commons/filters/FilterModal.js";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView.js";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants.js";
import { CrmPersonListItem } from "../../features/crm-persons/components/CrmPersonListItem.js";
import { CrmCreateModal } from "../../features/crm/CrmCreateModal.js";
import { CustomFieldConfigFragment } from "../../features/custom-fields/customFieldConfigs.generated.js";
import {
  FilterCustomFields,
  FilterCustomFieldsChips,
} from "../../features/custom-fields/filters/index.js";
import { useCustomFieldConfig } from "../../features/custom-fields/useCustomFieldConfig.js";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore.js";
import { UserAvatar } from "../../features/users/UserAvatar.js";
import {
  OrganisationCrmUsersSortBy,
  OrganisationCrmUsersSorting,
  SortDirection,
} from "../../../clients/graphqlTypes.js";
import {
  CrmUserRowFragment,
  CrmUsersTableQueryVariables,
  useCrmUsersTableQuery,
} from "./CrmPersons.generated.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission.js";

type Filters = Omit<CrmUsersTableQueryVariables, "limit">;

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

const DEFAULT_SORTING: OrganisationCrmUsersSorting[] = [
  {
    column: "familyname",
    direction: "asc",
  },
];

interface Props {
  submenuItems: PageTopbarItem[];
}

export function CrmPersons({ submenuItems }: Props) {
  const { t } = useTranslate([
    "CrmUsers",
    "CrmOrganisations",
    "Global",
    "CrmPageTopbar",
  ]);
  const navigate = useNavigate();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>("crm-persons", "table", "list");

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
  } = useFiltersAndPagination<OrganisationCrmUsersSorting, Filters>(
    {},
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const client = useApolloClient();
  const query = useCrmUsersTableQuery({
    client,
    fetchPolicy: "cache-and-network",
    variables: { ...QUERY_BASE_VARIABLES, ...filters, sorting },
  });
  const { customFieldConfig: customFieldConfigs } =
    useCustomFieldConfig("User");
  const users =
    getDataOrNull((query.data ?? query.previousData)?.crmPersons)?.edges?.map(
      e => e.node
    ) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.crmPersons)?.totalCount ??
    0;

  const columns = React.useMemo(
    (): GridColDef<CrmUserRowFragment>[] => [
      {
        field: "familyname",
        headerName: t("Name", {
          ns: "CrmUsers",
        }),
        flex: 2,
        minWidth: 100,
        renderCell: ({ row: user }) => (
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <UserAvatar userAvatar={user} size="s" />
            <div>{`${user.firstname} ${user.familyname}`}</div>
          </Stack>
        ),
      },
      {
        field: "email",
        headerName: t("Email", {
          ns: "CrmUsers",
        }),
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: user }) => user.email || "–",
      },
      {
        field: "phone",
        headerName: t("Phone", {
          ns: "CrmUsers",
        }),
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: user }) =>
          user.phones?.length > 0 ? user.phones?.[0]?.number : "–",
      },
    ],
    [t]
  );

  const stateStore = useDataGridStateStore("CrmPersons");

  return (
    <Page
      // title={t("CrmUsers::Contacts")}
      title={t("People", { ns: "CrmPageTopbar" })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <ListHeader
          // hideTitleOnMobile
          // title={t("People", {
          //   ns: "CrmPageTopbar",
          // })}
          // title={t("CrmUsers::Contacts")}
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          FilterButton={
            <ModalOpenButton
              Modal={CrmPersonsFilterModal}
              modalProps={{
                sorting,
                filters,
                setFilters,
                resetFilters,
                setSorting,
                customFieldConfigs,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.searchTerm}
              onChangeSearchTerm={(newValue: string) => {
                setFilters(filters => ({
                  ...filters,
                  searchTerm: newValue,
                }));
              }}
            />
          }
          FilterChips={
            query.variables?.filterByCustomFields ? (
              <Stack direction={"row"} flexWrap="wrap" spacing={1}>
                <FilterCustomFieldsChips
                  values={query.variables?.filterByCustomFields ?? []}
                  setValues={newValue =>
                    setFilters(filterVariables => ({
                      ...filterVariables,
                      filterByCustomFields: newValue,
                    }))
                  }
                />
              </Stack>
            ) : undefined
          }
          CreateButton={
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_CRM">
              <ModalOpenButton
                Modal={CrmCreateModal}
                modalProps={{
                  title: t("New contact", {
                    ns: "CrmOrganisations",
                  }),
                  handleComplete: async (handleClose, organisation) => {
                    handleClose();
                    navigate(`/crm/organisations/${organisation.id}`);
                  },
                }}
              >
                <ButtonCreate
                  variant="contained"
                  color="primary"
                  title={t("New contact", {
                    ns: "CrmOrganisations",
                  })}
                />
              </ModalOpenButton>
            </RestrictedByOrganisationPermissionWithDebug>
          }
          mb={2}
        />

        {(() => {
          switch (activeView) {
            case "table":
              return (
                <DataGrid
                  stateStore={stateStore}
                  density="standard"
                  loading={query.loading}
                  hideFooter={totalCount === 0}
                  columns={columns}
                  rows={users}
                  onRowClick={user => navigate(`/crm/users/${user.id}`)}
                  sortModel={sorting.map(s => ({
                    field: s.column,
                    sort: s.direction,
                  }))}
                  onSortModelChange={newModel => {
                    setSorting(
                      newModel.map(({ field, sort }) => ({
                        column: field as OrganisationCrmUsersSortBy,
                        direction: sort ?? "asc",
                      }))
                    );
                  }}
                  paginationModel={paginationModel}
                  onPaginationModelChange={newPaginationModel => {
                    setPaginationModel(newPaginationModel);
                  }}
                  disableColumnFilter
                  pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                  rowCount={totalCount}
                />
              );
            case "list":
              if (query.loading) return <LoadingSpinner />;
              if (totalCount === 0)
                return (
                  <ErrorMessage
                    message={t("There are no items to display", {
                      ns: "Global",
                    })}
                  />
                );
              return (
                <Stack direction="column" spacing={1}>
                  {users.map(crmPerson => (
                    <CardItem
                      key={crmPerson.id}
                      //@ts-ignore
                      component={Link}
                      to={`/crm/users/${crmPerson.id}`}
                    >
                      <CrmPersonListItem crmPerson={crmPerson} />
                    </CardItem>
                  ))}
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={paginationModel.page}
                    onPageChange={(event, newPage) => {
                      setPaginationModel({
                        pageSize: paginationModel.pageSize,
                        page: newPage,
                      });
                    }}
                    rowsPerPage={paginationModel.pageSize}
                    onRowsPerPageChange={event => {
                      setPaginationModel({
                        pageSize: parseInt(event.target.value, 10),
                        page: 0,
                      });
                    }}
                    labelRowsPerPage={t("Per page:", { ns: "Global" })}
                  />
                </Stack>
              );
          }
        })()}
      </PageContainer>
    </Page>
  );
}

function CrmPersonsFilterModal({
  handleClose,
  sorting,
  setSorting,
  filters,
  setFilters,
  resetFilters,
  customFieldConfigs,
}: {
  handleClose: () => void;
  sorting: OrganisationCrmUsersSorting[];
  setSorting: (sorting: OrganisationCrmUsersSorting[]) => void;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  resetFilters: () => void;
  customFieldConfigs: CustomFieldConfigFragment[];
}) {
  const { t } = useTranslate(["CrmUsers", "CrmUserSorting", "Global"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  const sortByOptions = React.useMemo(() => {
    const sortingLabels: Record<
      OrganisationCrmUsersSortBy,
      Record<SortDirection, string>
    > = {
      firstname: {
        asc: t("USER_FIRSTYNAME_ASC", {
          ns: "CrmUserSorting",
        }),
        desc: t("USER_FIRSTYNAME_DESC", {
          ns: "CrmUserSorting",
        }),
      },
      familyname: {
        asc: t("USER_FAMILYNAME_ASC", {
          ns: "CrmUserSorting",
        }),
        desc: t("USER_FAMILYNAME_DESC", {
          ns: "CrmUserSorting",
        }),
      },
    };

    const ALL_SORTING_FIELDS: OrganisationCrmUsersSortBy[] = [
      "firstname",
      "familyname",
    ];

    return ALL_SORTING_FIELDS.flatMap(value => [
      {
        label: sortingLabels[value]["asc"],
        value: { column: value, direction: "asc" as SortDirection },
      },
      {
        label: sortingLabels[value]["desc"],
        value: { column: value, direction: "desc" as SortDirection },
      },
    ]);
  }, [t]);

  return (
    <FilterModal
      title={t("Contacts Filters", {
        ns: "CrmUsers",
      })}
      handleApply={() => {
        setFilters(filtersState);
        setSorting(sortingState);
      }}
      handleClose={handleClose}
      handleReset={resetFilters}
    >
      <Typography variant="h3">
        {t("Sort by", {
          ns: "Global",
        })}
      </Typography>
      <Stack direction="column" spacing={1}>
        <Select
          label={t("Sort by", {
            ns: "Global",
          })}
          options={sortByOptions}
          value={
            sortByOptions.find(option => isEqual(option.value, sortingState[0]))
              ?.value
          }
          onChange={value => {
            setSortingState(value ? [value] : DEFAULT_SORTING);
          }}
        />
        {customFieldConfigs.length > 0 && (
          <Typography variant="h3">
            {t("Filter by", {
              ns: "Global",
            })}
          </Typography>
        )}
        {customFieldConfigs.length > 0 && (
          <FilterCustomFields
            fieldConfigs={customFieldConfigs}
            values={filtersState.filterByCustomFields ?? []}
            setValues={newValues =>
              setFiltersState(state => ({
                ...state,
                filterByCustomFields: newValues,
              }))
            }
          />
        )}
      </Stack>
    </FilterModal>
  );
}

// function renderMobileRow(user: CrmUserRowFragment) {
//   return (
//     <Paper>
//       <ProfileListItem
//         avatar={<UserAvatar userAvatar={user} size="m" />}
//         primary={`${user.firstname} ${user.familyname}`}
//         secondary={user.email}
//         // number={user.number}
//       />
//     </Paper>
//   );
// }
