/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Meister1IntegrationQueryVariables = Types.Exact<{
  forOrganisationId: Types.Scalars['ID']['input'];
}>;


export type Meister1IntegrationQuery = { organisationM1PlusConfig?: { __typename: 'M1PlusConfig', id: string, flowUrl: string } | null };

export type Meister1FlowsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Meister1FlowsQuery = { meister1Flows: Array<{ __typename: 'Meister1Flow', id: string, label: string, identName: string, calculatorCategoryCraftId?: string | null, calculatorId: number }> };


export const Meister1IntegrationDocument = gql`
    query Meister1Integration($forOrganisationId: ID!) {
  organisationM1PlusConfig(organisationId: $forOrganisationId) {
    id
    flowUrl
  }
}
    `;

/**
 * __useMeister1IntegrationQuery__
 *
 * To run a query within a React component, call `useMeister1IntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeister1IntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeister1IntegrationQuery({
 *   variables: {
 *      forOrganisationId: // value for 'forOrganisationId'
 *   },
 * });
 */
export function useMeister1IntegrationQuery(baseOptions: Apollo.QueryHookOptions<Meister1IntegrationQuery, Meister1IntegrationQueryVariables> & ({ variables: Meister1IntegrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Meister1IntegrationQuery, Meister1IntegrationQueryVariables>(Meister1IntegrationDocument, options);
      }
export function useMeister1IntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Meister1IntegrationQuery, Meister1IntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Meister1IntegrationQuery, Meister1IntegrationQueryVariables>(Meister1IntegrationDocument, options);
        }
export function useMeister1IntegrationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Meister1IntegrationQuery, Meister1IntegrationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Meister1IntegrationQuery, Meister1IntegrationQueryVariables>(Meister1IntegrationDocument, options);
        }
export type Meister1IntegrationQueryHookResult = ReturnType<typeof useMeister1IntegrationQuery>;
export type Meister1IntegrationLazyQueryHookResult = ReturnType<typeof useMeister1IntegrationLazyQuery>;
export type Meister1IntegrationSuspenseQueryHookResult = ReturnType<typeof useMeister1IntegrationSuspenseQuery>;
export type Meister1IntegrationQueryResult = Apollo.QueryResult<Meister1IntegrationQuery, Meister1IntegrationQueryVariables>;
export const Meister1FlowsDocument = gql`
    query Meister1Flows {
  meister1Flows {
    id
    label
    identName
    calculatorCategoryCraftId
    calculatorId
  }
}
    `;

/**
 * __useMeister1FlowsQuery__
 *
 * To run a query within a React component, call `useMeister1FlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeister1FlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeister1FlowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeister1FlowsQuery(baseOptions?: Apollo.QueryHookOptions<Meister1FlowsQuery, Meister1FlowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Meister1FlowsQuery, Meister1FlowsQueryVariables>(Meister1FlowsDocument, options);
      }
export function useMeister1FlowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Meister1FlowsQuery, Meister1FlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Meister1FlowsQuery, Meister1FlowsQueryVariables>(Meister1FlowsDocument, options);
        }
export function useMeister1FlowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Meister1FlowsQuery, Meister1FlowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Meister1FlowsQuery, Meister1FlowsQueryVariables>(Meister1FlowsDocument, options);
        }
export type Meister1FlowsQueryHookResult = ReturnType<typeof useMeister1FlowsQuery>;
export type Meister1FlowsLazyQueryHookResult = ReturnType<typeof useMeister1FlowsLazyQuery>;
export type Meister1FlowsSuspenseQueryHookResult = ReturnType<typeof useMeister1FlowsSuspenseQuery>;
export type Meister1FlowsQueryResult = Apollo.QueryResult<Meister1FlowsQuery, Meister1FlowsQueryVariables>;