import React from "react";
import { To } from "react-router-dom";
import { TREE_SCAFFOLD_PX_WIDTH } from "../../constants.js";
import { isItemGreyedOut } from "../../features/doc-items/helpers.js";
import { getSubcontractPath } from "../../features/quotes/helpers.js";
import { getParentItem } from "../../trees/helpers.js";
import { ItemComponent } from "../../trees/types.js";
import { entriesAmount } from "../../utils.js";
import { VirtualItem } from "../types.js";
import { VirtualBareTreeItem } from "./VirtualBareTreeItem.js";

interface Props<T extends VirtualItem> {
  item: T;
  items: T[];
  allItems?: T[];

  depth: number;
  scaffoldBlockPxWidth?: number;

  selected?: boolean;
  clickable?: boolean;
  sticky?: boolean;
  top?: number;
  bottom?: number;

  itemComponent: ItemComponent<T>;
  onClick?: () => void;
  to?: To | null;

  disabled?: boolean;
}

export const VirtualBareTreeStandaloneItem = <T extends VirtualItem>({
  item,
  items,
  allItems,
  depth,
  selected = false,
  clickable = false,
  disabled = false,
  sticky = false,
  top,
  bottom,
  to,
  scaffoldBlockPxWidth = TREE_SCAFFOLD_PX_WIDTH,
  itemComponent: ItemComponent,
}: Props<T>) => {
  const parentItem = getParentItem(item, items);
  return (
    <VirtualBareTreeItem
      depth={depth}
      selected={selected}
      clickable={!!clickable}
      scaffoldBlockPxWidth={scaffoldBlockPxWidth}
      isGreyedOut={isItemGreyedOut(item)}
      isInput={false}
      sticky={sticky}
      top={top}
      bottom={bottom}
    >
      <ItemComponent
        item={item}
        allItems={allItems ?? items}
        isRootItem={item.isRootItem}
        depth={entriesAmount(item.pathForPdf, ".")}
        parentItem={parentItem ?? null}
        isGreyedOut={false} // already used it
        isHidden={item.isHidden}
        isPriceHidden={item.isPriceHidden}
        subcontractPath={getSubcontractPath(item)}
        disabled={disabled}
      />
    </VirtualBareTreeItem>
  );
};
