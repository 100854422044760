import React from "react";
import {
  Add as AddIcon,
  Functions as FunctionsIcon,
} from "@mui/icons-material";
import {
  LabeledValue,
  LabeledValueWithButton,
  ModalOpenButton,
} from "@msys/ui";
import { useField } from "formik";
import { AskIfConditionExpressionModal } from "../modals/AskIfConditionExpressionModal.js";
import { ExpressionModal } from "../modals/ExpressionModal.js";
import { Button, FormControl, FormHelperText } from "@mui/material";

interface Props
  extends Omit<
    React.ComponentProps<typeof ExpressionModal>,
    | "handleSave"
    | "handleDelete"
    | "handleClose"
    | "expression"
    | "expressionError"
    | "filterOptions"
  > {
  name: string;
  label: string;
  addButtonLabel: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  currentItemId: string;
  currentPropertyKey: string;
}

export const AskIfConditionExpressionField = ({
  name,
  label,
  addButtonLabel,
  required,
  readOnly,
  disabled,
  currentItemId,
  currentPropertyKey,
  ...props
}: Props) => {
  const [{ value }, { touched, error }, { setValue }] = useField<
    string | undefined
  >(name);
  if (readOnly) {
    return (
      <LabeledValue
        label={`${label}${label && required ? " *" : ""}`}
        labelIcon={FunctionsIcon}
      >
        {value || "–"}
      </LabeledValue>
    );
  }

  return (
    <ModalOpenButton
      Modal={AskIfConditionExpressionModal}
      modalProps={{
        ...props,
        currentItemId: currentItemId,
        currentPropertyKey: currentPropertyKey,
        expression: value,
        async handleComplete(expression, handleClose) {
          await setValue(expression ?? "");
          handleClose();
        },
      }}
    >
      {!value ? (
        <FormControl disabled={disabled} error={Boolean(error)}>
          <LabeledValue label={`${label}${label && required ? " *" : ""}`}>
            <Button
              size="small"
              color="secondary"
              variant="text"
              startIcon={<AddIcon />}
              disabled={disabled}
              sx={{ textWrap: "nowrap" }}
            >
              {addButtonLabel}
            </Button>
          </LabeledValue>
          {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      ) : (
        <LabeledValueWithButton
          label={`${label}${label && required ? " *" : ""}`}
          labelIcon={FunctionsIcon}
          error={error}
          disabled={disabled}
        >
          {value}
        </LabeledValueWithButton>
      )}
    </ModalOpenButton>
  );
};
