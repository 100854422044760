/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationPdfSettingsBox_PdfLetterheadFragment = { __typename: 'PdfLetterhead', attachment: { __typename: 'PdfLetterheadAttachment', url: string }, settings: { __typename: 'PdfLetterheadSettings', marginTopFirstPage: number, marginTopSubsequentPage: number, marginBottom: number, textPrimaryColor: string, textSecondaryColor: string } };

export type GenerateOrganisationPdfLetterheadModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateOrganisationPdfLetterheadModalQuery = { organisationPdfSettings: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'PdfSettings', id: string, pdfFooterColumn1: string, pdfFooterColumn2: string, pdfFooterColumn3: string, pdfFooterVisibility: Types.PdfVisibility, pdfTextPrimaryColor: string, pdfTextSecondaryColor: string, pdfLogo?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null } };

export type RequestOrganisationPdfLetterheadUploadUrlMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RequestOrganisationPdfLetterheadUploadUrlMutation = { requestOrganisationPdfLetterheadUploadUrl: { __typename: 'RequestOrganisationLetterheadUploadUrlResult', uploadUrl: string, accessUrl: string } };

export type GenerateOrganisationPdfLetterheadMutationVariables = Types.Exact<{
  input: Types.GenerateOrganisationPdfLetterheadInput;
}>;


export type GenerateOrganisationPdfLetterheadMutation = { generateOrganisationPdfLetterhead: { __typename: 'GenerateOrganisationPdfLetterheadResult', url: string } };

export type GenerateExamplePdfDocumentMutationVariables = Types.Exact<{
  input: Types.GenerateExamplePdfDocumentInput;
}>;


export type GenerateExamplePdfDocumentMutation = { generateExamplePdfDocument: { __typename: 'GenerateExamplePdfDocumentResult', url: string } };

export type SetOrganisationPdfLetterheadMutationVariables = Types.Exact<{
  input: Types.SetOrganisationPdfLetterheadInput;
}>;


export type SetOrganisationPdfLetterheadMutation = { setOrganisationPdfLetterhead?: { __typename: 'SetOrganisationPdfLetterheadPayload', pdfSettings: { __typename: 'PdfSettings', id: string, pdfLetterhead?: { __typename: 'PdfLetterhead', attachment: { __typename: 'PdfLetterheadAttachment', url: string }, settings: { __typename: 'PdfLetterheadSettings', marginTopFirstPage: number, marginTopSubsequentPage: number, marginBottom: number, textPrimaryColor: string, textSecondaryColor: string } } | null } } | null };

export const OrganisationPdfSettingsBox_PdfLetterheadFragmentDoc = gql`
    fragment OrganisationPdfSettingsBox_PdfLetterhead on PdfLetterhead {
  attachment {
    url
  }
  settings {
    marginTopFirstPage
    marginTopSubsequentPage
    marginBottom
    textPrimaryColor
    textSecondaryColor
  }
}
    `;
export const GenerateOrganisationPdfLetterheadModalDocument = gql`
    query GenerateOrganisationPdfLetterheadModal {
  organisationPdfSettings {
    ... on PdfSettings {
      id
      pdfFooterColumn1
      pdfFooterColumn2
      pdfFooterColumn3
      pdfFooterVisibility
      pdfTextPrimaryColor
      pdfTextSecondaryColor
      pdfLogo {
        ...AttachmentSnapshot
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;

/**
 * __useGenerateOrganisationPdfLetterheadModalQuery__
 *
 * To run a query within a React component, call `useGenerateOrganisationPdfLetterheadModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateOrganisationPdfLetterheadModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateOrganisationPdfLetterheadModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateOrganisationPdfLetterheadModalQuery(baseOptions?: Apollo.QueryHookOptions<GenerateOrganisationPdfLetterheadModalQuery, GenerateOrganisationPdfLetterheadModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateOrganisationPdfLetterheadModalQuery, GenerateOrganisationPdfLetterheadModalQueryVariables>(GenerateOrganisationPdfLetterheadModalDocument, options);
      }
export function useGenerateOrganisationPdfLetterheadModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateOrganisationPdfLetterheadModalQuery, GenerateOrganisationPdfLetterheadModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateOrganisationPdfLetterheadModalQuery, GenerateOrganisationPdfLetterheadModalQueryVariables>(GenerateOrganisationPdfLetterheadModalDocument, options);
        }
export function useGenerateOrganisationPdfLetterheadModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenerateOrganisationPdfLetterheadModalQuery, GenerateOrganisationPdfLetterheadModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateOrganisationPdfLetterheadModalQuery, GenerateOrganisationPdfLetterheadModalQueryVariables>(GenerateOrganisationPdfLetterheadModalDocument, options);
        }
export type GenerateOrganisationPdfLetterheadModalQueryHookResult = ReturnType<typeof useGenerateOrganisationPdfLetterheadModalQuery>;
export type GenerateOrganisationPdfLetterheadModalLazyQueryHookResult = ReturnType<typeof useGenerateOrganisationPdfLetterheadModalLazyQuery>;
export type GenerateOrganisationPdfLetterheadModalSuspenseQueryHookResult = ReturnType<typeof useGenerateOrganisationPdfLetterheadModalSuspenseQuery>;
export type GenerateOrganisationPdfLetterheadModalQueryResult = Apollo.QueryResult<GenerateOrganisationPdfLetterheadModalQuery, GenerateOrganisationPdfLetterheadModalQueryVariables>;
export const RequestOrganisationPdfLetterheadUploadUrlDocument = gql`
    mutation RequestOrganisationPdfLetterheadUploadUrl {
  requestOrganisationPdfLetterheadUploadUrl {
    uploadUrl
    accessUrl
  }
}
    `;
export type RequestOrganisationPdfLetterheadUploadUrlMutationFn = Apollo.MutationFunction<RequestOrganisationPdfLetterheadUploadUrlMutation, RequestOrganisationPdfLetterheadUploadUrlMutationVariables>;

/**
 * __useRequestOrganisationPdfLetterheadUploadUrlMutation__
 *
 * To run a mutation, you first call `useRequestOrganisationPdfLetterheadUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOrganisationPdfLetterheadUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOrganisationPdfLetterheadUploadUrlMutation, { data, loading, error }] = useRequestOrganisationPdfLetterheadUploadUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestOrganisationPdfLetterheadUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<RequestOrganisationPdfLetterheadUploadUrlMutation, RequestOrganisationPdfLetterheadUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestOrganisationPdfLetterheadUploadUrlMutation, RequestOrganisationPdfLetterheadUploadUrlMutationVariables>(RequestOrganisationPdfLetterheadUploadUrlDocument, options);
      }
export type RequestOrganisationPdfLetterheadUploadUrlMutationHookResult = ReturnType<typeof useRequestOrganisationPdfLetterheadUploadUrlMutation>;
export type RequestOrganisationPdfLetterheadUploadUrlMutationResult = Apollo.MutationResult<RequestOrganisationPdfLetterheadUploadUrlMutation>;
export type RequestOrganisationPdfLetterheadUploadUrlMutationOptions = Apollo.BaseMutationOptions<RequestOrganisationPdfLetterheadUploadUrlMutation, RequestOrganisationPdfLetterheadUploadUrlMutationVariables>;
export const GenerateOrganisationPdfLetterheadDocument = gql`
    mutation GenerateOrganisationPdfLetterhead($input: GenerateOrganisationPdfLetterheadInput!) {
  generateOrganisationPdfLetterhead(input: $input) {
    url
  }
}
    `;
export type GenerateOrganisationPdfLetterheadMutationFn = Apollo.MutationFunction<GenerateOrganisationPdfLetterheadMutation, GenerateOrganisationPdfLetterheadMutationVariables>;

/**
 * __useGenerateOrganisationPdfLetterheadMutation__
 *
 * To run a mutation, you first call `useGenerateOrganisationPdfLetterheadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOrganisationPdfLetterheadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOrganisationPdfLetterheadMutation, { data, loading, error }] = useGenerateOrganisationPdfLetterheadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateOrganisationPdfLetterheadMutation(baseOptions?: Apollo.MutationHookOptions<GenerateOrganisationPdfLetterheadMutation, GenerateOrganisationPdfLetterheadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateOrganisationPdfLetterheadMutation, GenerateOrganisationPdfLetterheadMutationVariables>(GenerateOrganisationPdfLetterheadDocument, options);
      }
export type GenerateOrganisationPdfLetterheadMutationHookResult = ReturnType<typeof useGenerateOrganisationPdfLetterheadMutation>;
export type GenerateOrganisationPdfLetterheadMutationResult = Apollo.MutationResult<GenerateOrganisationPdfLetterheadMutation>;
export type GenerateOrganisationPdfLetterheadMutationOptions = Apollo.BaseMutationOptions<GenerateOrganisationPdfLetterheadMutation, GenerateOrganisationPdfLetterheadMutationVariables>;
export const GenerateExamplePdfDocumentDocument = gql`
    mutation GenerateExamplePdfDocument($input: GenerateExamplePdfDocumentInput!) {
  generateExamplePdfDocument(input: $input) {
    url
  }
}
    `;
export type GenerateExamplePdfDocumentMutationFn = Apollo.MutationFunction<GenerateExamplePdfDocumentMutation, GenerateExamplePdfDocumentMutationVariables>;

/**
 * __useGenerateExamplePdfDocumentMutation__
 *
 * To run a mutation, you first call `useGenerateExamplePdfDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateExamplePdfDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateExamplePdfDocumentMutation, { data, loading, error }] = useGenerateExamplePdfDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateExamplePdfDocumentMutation(baseOptions?: Apollo.MutationHookOptions<GenerateExamplePdfDocumentMutation, GenerateExamplePdfDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateExamplePdfDocumentMutation, GenerateExamplePdfDocumentMutationVariables>(GenerateExamplePdfDocumentDocument, options);
      }
export type GenerateExamplePdfDocumentMutationHookResult = ReturnType<typeof useGenerateExamplePdfDocumentMutation>;
export type GenerateExamplePdfDocumentMutationResult = Apollo.MutationResult<GenerateExamplePdfDocumentMutation>;
export type GenerateExamplePdfDocumentMutationOptions = Apollo.BaseMutationOptions<GenerateExamplePdfDocumentMutation, GenerateExamplePdfDocumentMutationVariables>;
export const SetOrganisationPdfLetterheadDocument = gql`
    mutation SetOrganisationPdfLetterhead($input: SetOrganisationPdfLetterheadInput!) {
  setOrganisationPdfLetterhead(input: $input) {
    pdfSettings {
      id
      pdfLetterhead {
        ...OrganisationPdfSettingsBox_PdfLetterhead
      }
    }
  }
}
    ${OrganisationPdfSettingsBox_PdfLetterheadFragmentDoc}`;
export type SetOrganisationPdfLetterheadMutationFn = Apollo.MutationFunction<SetOrganisationPdfLetterheadMutation, SetOrganisationPdfLetterheadMutationVariables>;

/**
 * __useSetOrganisationPdfLetterheadMutation__
 *
 * To run a mutation, you first call `useSetOrganisationPdfLetterheadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganisationPdfLetterheadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganisationPdfLetterheadMutation, { data, loading, error }] = useSetOrganisationPdfLetterheadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrganisationPdfLetterheadMutation(baseOptions?: Apollo.MutationHookOptions<SetOrganisationPdfLetterheadMutation, SetOrganisationPdfLetterheadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrganisationPdfLetterheadMutation, SetOrganisationPdfLetterheadMutationVariables>(SetOrganisationPdfLetterheadDocument, options);
      }
export type SetOrganisationPdfLetterheadMutationHookResult = ReturnType<typeof useSetOrganisationPdfLetterheadMutation>;
export type SetOrganisationPdfLetterheadMutationResult = Apollo.MutationResult<SetOrganisationPdfLetterheadMutation>;
export type SetOrganisationPdfLetterheadMutationOptions = Apollo.BaseMutationOptions<SetOrganisationPdfLetterheadMutation, SetOrganisationPdfLetterheadMutationVariables>;