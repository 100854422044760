import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Button, IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import React from "react";

interface Props<T> {
  name: string;
  value: T[];
  addButtonLabel: string;
  addButtonRef?: React.RefObject<HTMLButtonElement>;
  createNewItem: () => T;
  renderItem: React.ComponentType<{
    name: string;
    index: number;
    disabled: boolean | undefined;
  }>;
  disabled?: boolean;
  hideCreateButton?: boolean;
  hideDeleteButton?: boolean;
  autoSubmit?: boolean;
}

export function ArrayField<T>({
  name,
  value,
  addButtonLabel,
  addButtonRef,
  createNewItem,
  renderItem: Item,
  disabled = false,
  hideCreateButton,
  hideDeleteButton,
  autoSubmit = true,
}: Props<T>) {
  const { t } = useTranslate(["Global"]);
  const { submitForm } = useFormikContext();

  function renderItems(arrayHelpers: FieldArrayRenderProps) {
    if (value.length) {
      return value.map((item: T, index: number) => {
        return (
          <Stack key={index} direction="row" alignItems="center" spacing={1}>
            <Item name={name} index={index} disabled={disabled} />
            {!disabled && !hideDeleteButton && (
              <IconButton
                color="primary"
                onClick={async () => {
                  arrayHelpers.remove(index);
                  if (autoSubmit) await submitForm();
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        );
      });
    } else if (disabled) {
      return (
        <>
          {t("Not set", {
            ns: "Global",
          })}
        </>
      );
    }
  }

  function renderContainer(arrayHelpers: FieldArrayRenderProps) {
    return (
      <Stack spacing={1}>
        {renderItems(arrayHelpers)}
        {!disabled && !hideCreateButton && (
          <Button
            ref={addButtonRef}
            type="button"
            color="secondary"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => arrayHelpers.push(createNewItem())}
            sx={{ alignSelf: "flex-start" }}
          >
            {addButtonLabel}
          </Button>
        )}
      </Stack>
    );
  }

  return <FieldArray name={name} render={renderContainer} />;
}
