import { gql } from "@apollo/client";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import { ThumbUpOutlined as ThumbsUpOutlinedIcon } from "@mui/icons-material";
import { Timer as TimerIcon } from "@mui/icons-material";
import { ReactComponent as QAIcon } from "../../assets/icons/icon-qa.svg";
import { PermissionName } from "../../../clients/graphqlTypes.js";
import { TreeItemRightIconsFragment } from "./TreeRightIcons.generated.js";
import { RIGHT_ICON_STYLE } from "../BareTreeItem.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";

interface Props {
  item: TreeItemRightIconsFragment;
  isVisibleToOtherSide: boolean;
  document: {
    viewerPermissions: PermissionName[];
  };
}

export const TreeRightIcons = ({
  item,
  isVisibleToOtherSide,
  document,
}: Props) => (
  <>
    <RestrictedByDocumentPermissionWithDebug
      permission="EXECUTE_TASK"
      document={document}
    >
      {item.timeTrackingRequired && <TimerIcon style={RIGHT_ICON_STYLE} />}
    </RestrictedByDocumentPermissionWithDebug>
    {item.photoApprovalRequired && <AddAPhotoIcon style={RIGHT_ICON_STYLE} />}
    <RestrictedByDocumentPermissionWithDebug
      permission="EXECUTE_TASK"
      document={document}
    >
      {item.qaApprovalRequired && <QAIcon style={RIGHT_ICON_STYLE} />}
    </RestrictedByDocumentPermissionWithDebug>
    {isVisibleToOtherSide && item.approvalRequired && (
      <ThumbsUpOutlinedIcon style={RIGHT_ICON_STYLE} />
    )}
  </>
);
