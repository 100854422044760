import { StatusChip } from "@msys/ui";
import { Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import {
  PageSubHeader,
  PageSubHeaderInjectedProps,
} from "../../../commons/layout/PageSubHeader.js";
import { TemplateTypeOverviewSubHeader__TemplateTypeFragment } from "./TemplateTypeOverViewSubHeader.generated.js";

interface Props {
  templateType: TemplateTypeOverviewSubHeader__TemplateTypeFragment;
  pathToDocList: string;
}

export const TemplateTypeOverviewSubHeader = ({
  templateType,
  isHeaderVisible,
  setHeaderVisible,
  pathToDocList,
}: Props & PageSubHeaderInjectedProps) => {
  const { t } = useTranslate(["TemplateType"]);

  return (
    <PageSubHeader
      title={templateType.title}
      hideTitleOnMobile={false}
      isHeaderVisible={isHeaderVisible}
      setHeaderVisible={setHeaderVisible}
      status={
        templateType.deletedAt ? (
          <StatusChip
            data-test-id="templateType-status-chip"
            label={t("Deleted", { ns: "TemplateType" })}
            color={"warning"}
          />
        ) : null
      }
      entityNumber={
        <Typography variant="body2">
          {t("Key", { ns: "TemplateType" })}: {templateType.key},{" "}
          {t("Revision", { ns: "TemplateType" })}: {templateType.revision}
        </Typography>
      }
    />
  );
};
