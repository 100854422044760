import { useTranslate } from "@tolgee/react";
import { isNumber } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import { QuoteMaterialMarginRangeInput } from "../../../clients/graphqlTypes.js";

export const useMaterialMarginRangesFieldValidationSchema = (name: string) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  return React.useMemo(
    () =>
      Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            priceFrom: Yup.number()
              .min(0)
              .required()
              .test(
                "priceFromOverlapsWithPrevious",
                t("Should not overlap", {
                  ns: "OrganisationSettings",
                }),
                function (priceFrom) {
                  // @ts-ignore
                  const ranges = (this.from as any)[1].value[
                    name
                  ] as QuoteMaterialMarginRangeInput[];
                  const index = (this.options as any).index as number;

                  for (let i = 0; i < index; i++) {
                    const range = ranges[i];
                    if (
                      isNumber(priceFrom) &&
                      isNumber(range.priceFrom) &&
                      isNumber(range.priceTo) &&
                      (priceFrom as number) >= range.priceFrom &&
                      (priceFrom as number) < range.priceTo
                    ) {
                      return false;
                    }
                  }
                  return true;
                }
              )
              .label(
                t("Price from", {
                  ns: "OrganisationSettings",
                })
              ),
            priceTo: Yup.number()
              .min(0)
              .required()
              .test(
                "priceToOverlapsWithPrevious",
                t("Should not overlap", {
                  ns: "OrganisationSettings",
                }),
                function (priceTo) {
                  // @ts-ignore
                  const ranges = (this.from as any)[1].value[
                    name
                  ] as QuoteMaterialMarginRangeInput[];
                  const index = (this.options as any).index as number;
                  for (let i = 0; i < index; i++) {
                    const range = ranges[i];
                    if (
                      isNumber(priceTo) &&
                      isNumber(range.priceFrom) &&
                      isNumber(range.priceTo) &&
                      (priceTo as number) > range.priceFrom &&
                      (priceTo as number) <= range.priceTo
                    ) {
                      return false;
                    }
                  }
                  return true;
                }
              )
              .test(
                "smallerThanPriceFrom",
                t("Should be bigger than price from", {
                  ns: "OrganisationSettings",
                }),
                function (priceTo) {
                  // @ts-ignore
                  const ranges = (this.from as any)[1].value[
                    name
                  ] as QuoteMaterialMarginRangeInput[];
                  const index = (this.options as any).index as number;
                  const priceFrom = ranges[index].priceFrom;
                  if (
                    isNumber(priceTo) &&
                    isNumber(priceFrom) &&
                    (priceTo as number) <= priceFrom
                  ) {
                    return false;
                  }
                  return true;
                }
              )
              .label(
                t("Price up to", {
                  ns: "OrganisationSettings",
                })
              ),
            materialMargin: Yup.number()
              .min(0)
              .required()
              .label(
                t("Material margin", {
                  ns: "OrganisationSettings",
                })
              ),
          })
        )
        .required(),
    [t, name]
  );
};
