import moment from "moment";
import { gql } from "@apollo/client";
import { useUseAvailabilityFilter_MembersQuery } from "./useAvailabilityFilter.generated.js";
import { useMemo } from "react";

export const useAvailabilityFilter = (
  projectId: string | undefined,
  resourceAvailability:
    | [moment.Moment | null, moment.Moment | null]
    | null
    | undefined,
  resourceMaxDistance: number | null | undefined
): string[] | null => {
  const isAvailabilityDefined = Boolean(
    resourceAvailability && (resourceAvailability[0] || resourceAvailability[1])
  );
  const isMaxDistanceDefined =
    (resourceMaxDistance ?? 0) > 0 && Boolean(projectId);

  const query = useUseAvailabilityFilter_MembersQuery({
    variables: {
      rangeStart: resourceAvailability?.[0] ?? undefined,
      rangeEnd: resourceAvailability?.[1] ?? undefined,
      excludeProjectIds: projectId ? [projectId] : undefined,
      filterFromProjectId: projectId,
      filterWithinRadiusKm: resourceMaxDistance ?? 0,
    },
    fetchPolicy: "no-cache",
    skip: !isAvailabilityDefined && !isMaxDistanceDefined,
  });

  const userIds = useMemo(
    () => query.data?.availableMembers.map(m => m.id) ?? [],
    [query.data?.availableMembers]
  );

  return !isAvailabilityDefined && !isMaxDistanceDefined ? null : userIds;
};
