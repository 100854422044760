/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpenMasterdataAuthenticateMutationVariables = Types.Exact<{
  input: Types.OpenMasterdataAuthenticateInput;
}>;


export type OpenMasterdataAuthenticateMutation = { openMasterdataAuthenticate: { __typename: 'OpenMasterdataAuthenticateResult', ok: boolean } };


export const OpenMasterdataAuthenticateDocument = gql`
    mutation OpenMasterdataAuthenticate($input: OpenMasterdataAuthenticateInput!) {
  openMasterdataAuthenticate(input: $input) {
    ok
  }
}
    `;
export type OpenMasterdataAuthenticateMutationFn = Apollo.MutationFunction<OpenMasterdataAuthenticateMutation, OpenMasterdataAuthenticateMutationVariables>;

/**
 * __useOpenMasterdataAuthenticateMutation__
 *
 * To run a mutation, you first call `useOpenMasterdataAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenMasterdataAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openMasterdataAuthenticateMutation, { data, loading, error }] = useOpenMasterdataAuthenticateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenMasterdataAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<OpenMasterdataAuthenticateMutation, OpenMasterdataAuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpenMasterdataAuthenticateMutation, OpenMasterdataAuthenticateMutationVariables>(OpenMasterdataAuthenticateDocument, options);
      }
export type OpenMasterdataAuthenticateMutationHookResult = ReturnType<typeof useOpenMasterdataAuthenticateMutation>;
export type OpenMasterdataAuthenticateMutationResult = Apollo.MutationResult<OpenMasterdataAuthenticateMutation>;
export type OpenMasterdataAuthenticateMutationOptions = Apollo.BaseMutationOptions<OpenMasterdataAuthenticateMutation, OpenMasterdataAuthenticateMutationVariables>;