/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { VirtualItem__DocumentItemFragmentDoc } from '../../trees-virtual/types.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddTimeReportModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type AddTimeReportModalQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus, crmOrganisation?: { __typename: 'CrmCompany', id: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string } | null } | null, tasks: Array<{ __typename: 'TaskDocument', id: string, title: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, isDone: boolean, doneDate?: any | null, rank: number, level: number, parentId?: string | null, isRootItem: boolean, pathForPdf: string, pathSortable: string, isHidden: boolean, isPriceHidden: boolean, isParentDecisionPreselected: boolean, isParentDecisionNotPreselected: boolean, isParentContingencyNotPreselected: boolean, deletedAt?: any | null, type: Types.ItemType, binding: Types.ItemBinding, decisionIsContingentItem: boolean, decisionSubitemsPreselection: Array<string>, decisionBehaviorOfSubitems: Types.DecisionBehaviorOfSubitems, decisionContingentItemPreselection?: boolean | null, isExplicitHidden: boolean, childrenVisibility: Types.ItemChildrenVisibility, isItemEliminated: boolean, hasChildren: boolean, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null }> }> } | null }, viewer?: { __typename: 'Viewer', id: string, organisation: { __typename: 'ViewerOrganisation', id: string, title: string } } | null, organisationDocumentSettings: { __typename: 'DocumentSettings', id: string, timeReportTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null } } | { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } };

export type CreateTimeReportMutationVariables = Types.Exact<{
  input: Types.CreateTimeReportInput;
}>;


export type CreateTimeReportMutation = { createTimeReport: { __typename: 'CreateTimeReportResult', timeReport: { __typename: 'TimeReport', id: string } } };


export const AddTimeReportModalDocument = gql`
    query AddTimeReportModal($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        viewerPermissions
        state
        crmOrganisation {
          id
          contactPerson {
            id
            title
            firstname
            familyname
          }
        }
        tasks {
          id
          title
          items {
            id
            originVersionNumber
            title
            isDone
            doneDate
            ...VirtualItem__DocumentItem
          }
        }
      }
    }
  }
  viewer {
    id
    organisation {
      id
      title
    }
  }
  organisationDocumentSettings {
    ... on DocumentSettings {
      id
      timeReportTexts {
        id
        opening
        closing
      }
    }
  }
}
    ${VirtualItem__DocumentItemFragmentDoc}`;

/**
 * __useAddTimeReportModalQuery__
 *
 * To run a query within a React component, call `useAddTimeReportModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddTimeReportModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddTimeReportModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAddTimeReportModalQuery(baseOptions: Apollo.QueryHookOptions<AddTimeReportModalQuery, AddTimeReportModalQueryVariables> & ({ variables: AddTimeReportModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddTimeReportModalQuery, AddTimeReportModalQueryVariables>(AddTimeReportModalDocument, options);
      }
export function useAddTimeReportModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddTimeReportModalQuery, AddTimeReportModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddTimeReportModalQuery, AddTimeReportModalQueryVariables>(AddTimeReportModalDocument, options);
        }
export function useAddTimeReportModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AddTimeReportModalQuery, AddTimeReportModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddTimeReportModalQuery, AddTimeReportModalQueryVariables>(AddTimeReportModalDocument, options);
        }
export type AddTimeReportModalQueryHookResult = ReturnType<typeof useAddTimeReportModalQuery>;
export type AddTimeReportModalLazyQueryHookResult = ReturnType<typeof useAddTimeReportModalLazyQuery>;
export type AddTimeReportModalSuspenseQueryHookResult = ReturnType<typeof useAddTimeReportModalSuspenseQuery>;
export type AddTimeReportModalQueryResult = Apollo.QueryResult<AddTimeReportModalQuery, AddTimeReportModalQueryVariables>;
export const CreateTimeReportDocument = gql`
    mutation CreateTimeReport($input: CreateTimeReportInput!) {
  createTimeReport(input: $input) {
    timeReport {
      id
    }
  }
}
    `;
export type CreateTimeReportMutationFn = Apollo.MutationFunction<CreateTimeReportMutation, CreateTimeReportMutationVariables>;

/**
 * __useCreateTimeReportMutation__
 *
 * To run a mutation, you first call `useCreateTimeReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeReportMutation, { data, loading, error }] = useCreateTimeReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeReportMutation, CreateTimeReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeReportMutation, CreateTimeReportMutationVariables>(CreateTimeReportDocument, options);
      }
export type CreateTimeReportMutationHookResult = ReturnType<typeof useCreateTimeReportMutation>;
export type CreateTimeReportMutationResult = Apollo.MutationResult<CreateTimeReportMutation>;
export type CreateTimeReportMutationOptions = Apollo.BaseMutationOptions<CreateTimeReportMutation, CreateTimeReportMutationVariables>;