import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CollapseButton,
  CollapseSection,
  ModalOpenButton,
  TextWithOverflow,
  isImageOr3dModel,
  isPdfOrFile,
  processAttachment,
  useScreenWidth,
} from "@msys/ui";
import { Check as CheckIcon, Edit as EditIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  List,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import { noop, partition } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import { useFeature } from "../../../common/FeatureFlags.js";
import { color } from "../../../common/MuiThemeProvider.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { useUserData } from "../../auth/useUserData.js";
import { GalleryRow } from "../../commons/images/GalleryRow.js";
import { LabeledSignatureValue } from "../../commons/labeled-values/LabeledSignatureValue.js";
import { TIME_AND_MATERIAL_CONTRACT_TYPES } from "../../constants.js";
import {
  ALL_KEY,
  ExpandedStore,
} from "../../trees-virtual/hooks/useExpandedStore.js";
import { FileRow } from "../attachments/FileRow.js";
import { FilesBoxTable } from "../attachments/FilesBoxTable.js";
import { DecisionModalButton } from "../doc-items/buttons/DecisionModalButton.js";
import { ContingencyCheckbox } from "../doc-items/fields/ContingencyCheckbox.js";
import { DecisionOptionRadioOrCheckbox } from "../doc-items/fields/DecisionOptionRadioOrCheckbox.js";
import { SubitemsOptionsNoneSection } from "../doc-items/fields/SubitemsOptionsNoneSection.js";
import {
  getViewerDecisionRole,
  hasAnyQuestions,
} from "../doc-items/helpers.js";
import { useDecisionItemMutations } from "../doc-items/hooks/useDecisionItemMutations.js";
import { DocumentActorData } from "../documents/DocumentActorData.js";
import { DocumentPreviewItems } from "../documents/DocumentPreviewItems.js";
import {
  AdditionalColumn,
  Fields,
} from "../documents/DocumentPreviewItemsTypes.js";
import { RefinementWizardButton } from "../projects/quote-trees.js";
import { S4HanaQuoteDataBox } from "../sap-s4-hana/S4HanaQuoteDataBox.js";
import { ClosingTextInput } from "./QuoteClosingText.js";
import { OpeningTextInput } from "./QuoteOpeningText.js";
import {
  QuotePreview_ItemFragment,
  QuotePreview_QuoteFragment,
  useModifyQuoteTexts_QuotePreviewMutation,
  useQuoteItemSapS4HanaData_QuotePreviewQuery,
} from "./QuotePreview.generated.js";
import { filterItemProductImages } from "./helpers.js";
import { QuoteDocumentActorsModal } from "./modals/QuoteDocumentActorsModal.js";
import { QuotePreviewDates } from "./preview/QuotePreviewDates.js";
import { QuotePreviewHeader } from "./preview/QuotePreviewHeader.js";
import {
  QuotePreviewItemDescription,
  quoteItemHasDescription,
} from "./preview/QuotePreviewItemDescription.js";
import { QuotePreviewSummary } from "./preview/QuotePreviewSummary.js";
import { PreviewValue } from "./preview/QuotePreviewValues.js";
import { useProjectQuoteContext } from "../../main-routes/projects/ProjectQuoteContext.js";

interface Props<CanFinalize extends boolean> {
  navigateToItem?: (item: QuotePreview_ItemFragment) => void;
  selectedItemId?: string | null;
  projectId: string;
  quote: QuotePreview_QuoteFragment;
  container?: HTMLElement | null;
  expandedStore?: ExpandedStore;
  expandedItemIds: string[] | undefined;
  hasSapS4HanaIntegration: boolean;
  refetch?: () => Promise<unknown>;
}

export const QuotePreview = <CanFinalize extends boolean>({
  navigateToItem,
  selectedItemId,
  projectId,
  quote,
  container,
  expandedStore,
  expandedItemIds,
  hasSapS4HanaIntegration,
  refetch,
}: Props<CanFinalize>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMinTablet, isMinDesktop } = useScreenWidth();
  const viewer = useUserData().currentUser!;
  const isNewRefinementWizardEnabled =
    viewer.organisation.capabilities.includes("NEW_REFINEMENT_WIZARD");

  const { t } = useTranslate([
    "Quote",
    "QuoteStateBadge",
    "Global",
    "Decisions",
  ]);

  const client = useApolloClient();
  const [modifyQuoteTexts] = useModifyQuoteTexts_QuotePreviewMutation({
    client,
  });
  const querySapS4HanaData = useQuoteItemSapS4HanaData_QuotePreviewQuery({
    variables: { projectId: quote.projectId, docId: quote.id },
    skip: !hasSapS4HanaIntegration,
  });
  const sapS4HanaData = React.useMemo(
    () =>
      getDataOrNull(querySapS4HanaData.data?.quoteItemSapS4HanaData)?.items ??
      [],
    [querySapS4HanaData.data?.quoteItemSapS4HanaData]
  );
  const hasS4HanaData = hasSapS4HanaIntegration && sapS4HanaData.length > 0;

  const doc = quote;

  const isViewersDocument =
    doc.owningSystemOrganisationId === viewer.organisation.id;

  const isAgreed =
    (quote?.isPublished && quote?.isBinding && quote?.agreement === "YES") ??
    false;

  const viewerDecisionRole = getViewerDecisionRole(doc.actors);

  const allDocItems = doc.items;

  const rootItem = allDocItems.find(item => item.isRootItem);

  let [images, files] = partition(
    (rootItem?.attachments ?? [])
      .filter(attachment => attachment.clientVisibility)
      .map(processAttachment),
    isImageOr3dModel
  );
  images = filterItemProductImages(images, quote.productsVisibility);

  const isTimeAndMaterialContract = TIME_AND_MATERIAL_CONTRACT_TYPES.includes(
    quote.contractType
  );

  const saveQuoteText = async (content: any) => {
    try {
      await modifyQuoteTexts({
        variables: {
          input: {
            projectId: quote.projectId,
            docId: quote.id,
            values: {
              quoteTexts: content,
            },
          },
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const {
    handleDecisionPreselectionChange,
    handleContingencyPreselectionChange,
    handleFinalizeContingencyDecision,
    handleFinalizeSubitemDecision,
    canForceFinalizeSubitemDecision,
    canFinalizeContingencyDecision,
    loading,
  } = useDecisionItemMutations<QuotePreview_ItemFragment>(
    quote.projectId,
    quote.id,
    true,
    expandedItemIds
  );

  const collapseItemAndChildrenRecursively = React.useCallback(
    (item: QuotePreview_ItemFragment) => {
      if (!expandedStore) return;
      const toCollapseIds = allDocItems
        .filter(i => i.pathForPdf.startsWith(item.pathForPdf))
        .map(i => i.id);
      const toExpandItemIds = expandedStore.areAllItemsExpanded
        ? allDocItems
            .filter(i => i.hasChildren && !toCollapseIds.includes(i.id))
            .map(i => i.id)
        : [];
      expandedStore.setExpandedState(
        Object.fromEntries([
          [ALL_KEY, false],
          ...toExpandItemIds.map(id => [id, true]),
          ...toCollapseIds.map(id => [id, false]),
        ])
      );
    },
    [expandedStore, allDocItems]
  );

  const additionalColumns = React.useMemo(
    (): AdditionalColumn<QuotePreview_ItemFragment>[] | undefined =>
      hasS4HanaData
        ? [
            {
              columns: 2,
              label: t("Ref no.", { ns: "Quote" }),
              render: item => {
                const data = sapS4HanaData.find(data => data.id === item.id);
                return <>{data?.xId ?? null}</>;
              },
            },
          ]
        : undefined,
    [hasS4HanaData, sapS4HanaData, t]
  );

  const { hasItemAnyRefinementQuestions, refetchWizardQuestions } =
    useProjectQuoteContext();

  const fields = React.useMemo(
    (): Fields<QuotePreview_ItemFragment> => ({
      CollapseButton: {
        render: item => {
          if (!expandedStore) return null;
          const expanded = expandedStore.isItemExpanded(item.id);
          return item.hasChildren ? (
            <CollapseButton
              aria-label={expanded ? "Collapse" : "Expand"}
              isExpanded={expanded}
              setIsExpanded={newExpanded => {
                if (newExpanded) {
                  expandedStore.setItemExpanded(item.id, newExpanded);
                } else {
                  collapseItemAndChildrenRecursively(item);
                }
              }}
            />
          ) : null;
        },
      },
      NonBindingCaption: {
        render: item => {
          if (item.type === "paid" && item.binding === "non_binding") {
            return t("Non-Binding item", {
              ns: "Quote",
            });
          }

          return null;
        },
      },
      ContingencyOptionalCaption: {
        render: item => {
          if (item.decisionIsContingentItem) {
            const captions: string[] = [];
            captions.push(
              t("Optional item", {
                ns: "Quote",
              })
            );

            if (item.decisionContingentItemPreselection) {
              captions.push(
                t("Recommended", {
                  ns: "Quote",
                })
              );
            }

            return captions.join(" • ");
          }

          return null;
        },
      },
      ContingencyOptionsButtons: {
        render: item => {
          return item.decisionIsContingentItem ? (
            <RestrictedByDocumentPermissionWithDebug
              permission="TAKE_QUOTES_DECISION"
              document={quote}
              otherwise={
                <ContingencyCheckbox
                  item={item}
                  canEdit={false}
                  disabled={true}
                  handleContingencyPreselectionChange={noop}
                />
              }
            >
              <ContingencyCheckbox
                item={item}
                loading={loading}
                canEdit={true}
                handleContingencyPreselectionChange={async (...args) => {
                  await handleContingencyPreselectionChange(...args);
                  await refetch?.();
                }}
              />
            </RestrictedByDocumentPermissionWithDebug>
          ) : null;
        },
      },
      ContingencyFinalizeButton: {
        render: item => {
          return item.decisionIsContingentItem ? (
            <RestrictedByDocumentPermissionWithDebug
              permission="TAKE_QUOTES_DECISION"
              document={quote}
            >
              <Button
                disabled={loading || !canFinalizeContingencyDecision(item)}
                size="extra-small"
                variant="contained"
                color="primary"
                onClick={async e => {
                  e.preventDefault();
                  e.stopPropagation();
                  await handleFinalizeContingencyDecision(item.id);
                  await refetch?.();
                }}
              >
                {item.decisionContingentItemPreselection === true
                  ? t("Keep item", {
                      ns: "Quote",
                    })
                  : t("Remove item", { ns: "Quote" })}
              </Button>
            </RestrictedByDocumentPermissionWithDebug>
          ) : null;
        },
      },
      DecisionOptionButton: {
        render: (item, parent) => {
          return parent?.type === "decision" &&
            !item.decisionOptionElimination ? (
            <RestrictedByDocumentPermissionWithDebug
              permission="TAKE_QUOTES_DECISION"
              document={quote}
              otherwise={
                <DecisionOptionRadioOrCheckbox
                  docId={quote.id}
                  projectId={quote.projectId}
                  item={item}
                  disabled={true}
                  expandedItemIds={expandedItemIds}
                />
              }
            >
              <DecisionOptionRadioOrCheckbox
                docId={quote.id}
                projectId={quote.projectId}
                item={item}
                disabled={loading}
                expandedItemIds={expandedItemIds}
                refetch={refetch}
              />
            </RestrictedByDocumentPermissionWithDebug>
          ) : null;
        },
      },
      DecisionNoneOptionButton: {
        render: item => {
          return item.decisionBehaviorOfSubitems === "SELECT_ONE" ? (
            <RestrictedByDocumentPermissionWithDebug
              permission="TAKE_QUOTES_DECISION"
              document={quote}
              otherwise={
                <SubitemsOptionsNoneSection
                  item={item}
                  handleDecisionPreselectionChange={noop}
                  canEdit={false}
                  disabled={true}
                />
              }
            >
              <SubitemsOptionsNoneSection
                item={item}
                loading={loading}
                handleDecisionPreselectionChange={async (...args) => {
                  await handleDecisionPreselectionChange(...args);
                  await refetch?.();
                }}
                canEdit={true}
              />
            </RestrictedByDocumentPermissionWithDebug>
          ) : null;
        },
      },
      DecisionButton: {
        render: item => {
          if (isNewRefinementWizardEnabled) {
            const showRefinementWizardButton = hasItemAnyRefinementQuestions(
              item.id
            );

            return showRefinementWizardButton ? (
              <RefinementWizardButton
                projectId={projectId}
                quote={quote}
                item={item}
                refetchAfterApplyItemAction={refetchWizardQuestions}
              />
            ) : null;
          } else {
            return hasAnyQuestions(item, "QUOTE", viewerDecisionRole) ? (
              <RestrictedByDocumentPermissionWithDebug
                permission="TAKE_QUOTES_DECISION"
                document={quote}
                otherwise={
                  <DecisionModalButton
                    projectId={quote.projectId}
                    docType="QUOTE"
                    docId={quote.id}
                    itemId={item.id}
                    canFinalize={false}
                    disabled={true}
                    viewerDecisionRole={viewerDecisionRole}
                    expandedItemIds={expandedItemIds}
                    type="icon"
                    isOptionHidden={item => !isViewersDocument && item.isHidden}
                  />
                }
              >
                <DecisionModalButton
                  projectId={quote.projectId}
                  docType="QUOTE"
                  docId={quote.id}
                  itemId={item.id}
                  canFinalize={true}
                  viewerDecisionRole={viewerDecisionRole}
                  expandedItemIds={expandedItemIds}
                  type="icon"
                  refetch={refetch}
                  isOptionHidden={item => !isViewersDocument && item.isHidden}
                />
              </RestrictedByDocumentPermissionWithDebug>
            ) : null;
          }
        },
      },
      DecisionFinalizeButton: {
        render: item => {
          return item.type === "decision" ? (
            <RestrictedByDocumentPermissionWithDebug
              permission="TAKE_QUOTES_DECISION"
              document={quote}
            >
              <Stack direction="row" spacing={0.5} flexWrap="wrap">
                <Button
                  disabled={loading || !canForceFinalizeSubitemDecision(item)}
                  size="extra-small"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (item.decisionIsContingentItem) {
                      if (item.decisionContingentItemPreselection !== true) {
                        await handleContingencyPreselectionChange(
                          item.id,
                          true
                        );
                      }
                      await handleFinalizeContingencyDecision(item.id);
                    }

                    await handleFinalizeSubitemDecision(item.id);
                    await refetch?.();
                  }}
                >
                  {t("Finalize decision", {
                    ns: "Quote",
                  })}
                </Button>
              </Stack>
            </RestrictedByDocumentPermissionWithDebug>
          ) : null;
        },
      },
      Pos: {
        render: item => <TextWithOverflow>{item.pathForPdf}</TextWithOverflow>,
      },
      Title: {
        render: item => {
          return (
            <PreviewValue
              sx={{ wordBreak: "break-word" }}
              value={item.title}
              changedValue={item.pendingChangeAttributes?.title}
            />
          );
        },
      },
      Description: {
        render: item => {
          if (!quoteItemHasDescription(item, quote.productsVisibility))
            return null;
          return (
            <QuotePreviewItemDescription
              item={item}
              productsVisibility={quote.productsVisibility}
              columns={3}
            />
          );
        },
      },
      Images: {
        render: item => {
          const images = filterItemProductImages(
            item.attachments
              .filter(attachment => attachment.clientVisibility)
              .map(processAttachment)
              .filter(isImageOr3dModel),
            quote.productsVisibility
          );
          return images.length > 0 ? (
            <GalleryRow
              images={images}
              maxItems={4}
              style={
                isMinTablet && item.level > 2
                  ? { maxWidth: "300px" }
                  : undefined
              }
            />
          ) : null;
        },
      },
      Attachments: {
        render: item => {
          const files = item.attachments
            .filter(attachment => attachment.clientVisibility)
            .map(processAttachment)
            .filter(isPdfOrFile);
          return files.length > 0 ? (
            <CollapseSection
              title={t("Attachments", { ns: "Quote" })}
              titleVariant="body2"
              titleColor="secondary"
              iconButtonColor="secondary"
              minHeight="auto"
              isInitiallyExpanded={false}
              collapseTimeout={0}
            >
              <List
                disablePadding
                dense
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {files.map(attachment => (
                  <FileRow
                    key={attachment.id}
                    file={attachment}
                    button={false}
                    divider={false}
                    sx={{ minWidth: "200px", maxWidth: "320px", width: "auto" }}
                  />
                ))}
              </List>
            </CollapseSection>
          ) : null;
        },
      },
      Quantity: {
        render: (item, _, priceData) => {
          return priceData.rows &&
            priceData.rows.length > 0 &&
            priceData.rows.some(
              row =>
                (row.quantity?.agreed ?? row.quantity?.proposed) !== undefined
            ) ? (
            <Stack direction={"column"}>
              {priceData.rows.map((row, index) => (
                <PreviewValue
                  key={index}
                  value={row.quantity?.agreed ?? row.quantity?.proposed}
                  changedValue={row.quantity?.proposed}
                />
              ))}
            </Stack>
          ) : null;
        },
      },
      UnitPrice: {
        render: (item, _, priceData) => {
          return priceData.rows &&
            priceData.rows.length > 0 &&
            priceData.rows.some(
              row =>
                (row.unitPrice?.agreed ?? row.unitPrice?.proposed) !== undefined
            ) ? (
            <RestrictedByDocumentPermissionWithDebug
              permission="READ_QUOTES"
              document={quote}
            >
              <Stack direction={"column"}>
                {priceData.rows.map((row, index) => (
                  <PreviewValue
                    key={index}
                    value={row.unitPrice?.agreed ?? row.unitPrice?.proposed}
                    changedValue={row.unitPrice?.proposed}
                  />
                ))}
              </Stack>
            </RestrictedByDocumentPermissionWithDebug>
          ) : null;
        },
      },
      Subtotal: {
        render: (item, _, priceData) => {
          return priceData.rows &&
            priceData.rows.length > 0 &&
            priceData.rows.some(
              row =>
                (row.subtotal?.agreed ?? row.subtotal?.proposed) !== undefined
            ) ? (
            <RestrictedByDocumentPermissionWithDebug
              permission="READ_QUOTES"
              document={quote}
            >
              <Stack direction={"column"}>
                {priceData.rows.map((row, index) => (
                  <PreviewValue
                    key={index}
                    value={row.subtotal?.agreed ?? row.subtotal?.proposed}
                    changedValue={row.subtotal?.proposed}
                  />
                ))}
              </Stack>
            </RestrictedByDocumentPermissionWithDebug>
          ) : null;
        },
      },
      TotalPrice: {
        render: (item, _, priceData) => {
          return (
            <RestrictedByDocumentPermissionWithDebug
              permission="READ_QUOTES"
              document={quote}
            >
              <PreviewValue
                value={priceData.total?.agreed ?? priceData.total?.proposed}
                changedValue={priceData.total?.proposed}
              />
            </RestrictedByDocumentPermissionWithDebug>
          );
        },
      },
    }),
    [
      expandedStore,
      collapseItemAndChildrenRecursively,
      t,
      quote,
      loading,
      handleContingencyPreselectionChange,
      refetch,
      canFinalizeContingencyDecision,
      handleFinalizeContingencyDecision,
      expandedItemIds,
      handleDecisionPreselectionChange,
      isNewRefinementWizardEnabled,
      hasItemAnyRefinementQuestions,
      projectId,
      refetchWizardQuestions,
      viewerDecisionRole,
      isViewersDocument,
      canForceFinalizeSubitemDecision,
      handleFinalizeSubitemDecision,
      isMinTablet,
    ]
  );

  const extraActors = React.useMemo(
    () =>
      quote.actors.filter(
        actor =>
          !["CONTRACTOR", "CONTRACTEE"].includes(actor.type) &&
          (quote.headerVisibility.isEndClientVisible || actor.type !== "CLIENT")
      ),
    [quote.actors, quote.headerVisibility.isEndClientVisible]
  );

  return (
    <Stack width="100%" direction={"column"} spacing={1}>
      <QuotePreviewHeader
        quote={doc}
        canEdit={isViewersDocument && !isAgreed}
      />

      {extraActors.length > 0 && (
        <Paper sx={{ position: "relative", padding: 2, paddingRight: 2 }}>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyItems="space-between"
            spacing={4}
          >
            <Stack direction="row" spacing={4} flex={1}>
              {extraActors.map(actor => (
                <DocumentActorData actorType={actor.type} actor={actor} />
              ))}
            </Stack>
            <RestrictedByDocumentPermissionWithDebug
              permission="MANAGE_QUOTES_ACTORS"
              document={quote}
            >
              <ModalOpenButton
                Modal={QuoteDocumentActorsModal}
                modalProps={{ projectId: quote.projectId, quoteId: quote.id }}
              >
                <IconButton color="secondary" size="small">
                  <EditIcon />
                </IconButton>
              </ModalOpenButton>
            </RestrictedByDocumentPermissionWithDebug>
          </Stack>
        </Paper>
      )}

      {hasSapS4HanaIntegration && (
        <S4HanaQuoteDataBox
          quote={quote}
          projectId={quote.projectId}
          quoteId={quote.id}
          canEdit={isViewersDocument && !isAgreed}
        />
      )}

      <Paper>
        <Stack
          direction={"row"}
          padding={2}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Stack direction={"column"} spacing={1}>
            <Typography variant="h2">{doc.title}</Typography>
            {doc.isBinding === false && (
              <Typography
                fontWeight="bold"
                color="textSecondary"
                component="div"
              >
                {t("Non-Binding Quote", {
                  ns: "QuoteStateBadge",
                })}
              </Typography>
            )}
          </Stack>
          {rootItem &&
            (isNewRefinementWizardEnabled ? (
              hasItemAnyRefinementQuestions(rootItem.id) ? (
                <RefinementWizardButton
                  projectId={projectId}
                  quote={quote}
                  item={rootItem}
                  refetchAfterApplyItemAction={refetchWizardQuestions}
                />
              ) : null
            ) : (
              hasAnyQuestions(rootItem, "QUOTE", viewerDecisionRole) && (
                <RestrictedByDocumentPermissionWithDebug
                  permission="TAKE_QUOTES_DECISION"
                  document={quote}
                >
                  <DecisionModalButton
                    projectId={quote.projectId}
                    docType="QUOTE"
                    docId={quote.id}
                    itemId={rootItem.id}
                    canFinalize={true}
                    viewerDecisionRole={viewerDecisionRole}
                    expandedItemIds={expandedItemIds}
                    type="icon"
                    refetch={refetch}
                    isOptionHidden={item => !isViewersDocument && item.isHidden}
                  />
                </RestrictedByDocumentPermissionWithDebug>
              )
            ))}
        </Stack>
      </Paper>

      <OpeningTextInput
        canEdit={!doc.isPublished}
        content={doc.quoteTexts?.opening}
        readViewContent={doc.interpolatedQuoteTexts?.opening}
        onSave={saveQuoteText}
        showInfo={true}
        renderEditIconButton={button => (
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES_SETTINGS"
            document={quote}
          >
            {button}
          </RestrictedByDocumentPermissionWithDebug>
        )}
      />

      {rootItem &&
        (images.length > 0 ||
          quoteItemHasDescription(rootItem, quote.productsVisibility)) && (
          <Paper>
            <Stack padding={2} direction={"column"} spacing={2}>
              <GalleryRow
                images={images}
                maxItems={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
              />

              <QuotePreviewItemDescription
                item={rootItem}
                productsVisibility={quote.productsVisibility}
              />
            </Stack>
          </Paper>
        )}

      <DocumentPreviewItems<QuotePreview_ItemFragment>
        doc={doc}
        fields={fields}
        additionalColumns={additionalColumns}
        allDocItems={allDocItems}
        navigateToItem={navigateToItem}
        selectedItemId={selectedItemId}
        isTimeAndMaterialContract={isTimeAndMaterialContract}
        getItemStyle={getQuoteItemStyle}
        shouldRenderItem={shouldRenderQuoteItem}
        showCollapseButton={Boolean(expandedStore)}
        container={container}
        expandedStore={expandedStore}
      />

      {doc.proposedCalculation && (
        <QuotePreviewSummary
          vatVisibility={doc.vatVisibility}
          discountPercentage={doc.discountPercentage}
          discountReason={doc.discountReason}
          isDiscountPercentageVisible={doc.isDiscountPercentageVisible}
          calculation={
            doc.agreement === "YES"
              ? doc.agreedCalculation
              : doc.proposedCalculation
          }
          changedCalculation={doc.proposedCalculation}
        />
      )}

      {quote.showOfferConditions && (
        <QuotePreviewDates
          quote={quote}
          projectId={quote.projectId}
          quoteId={quote.id}
          earliestDate={doc.earliestDate}
          duration={doc.duration}
          expirationDate={doc.expirationDate}
          acceptedAt={doc.acceptedAt}
          isPublished={doc.isPublished}
          canEdit={isViewersDocument && !isAgreed}
        />
      )}

      <ClosingTextInput
        canEdit={isViewersDocument && !isAgreed}
        content={doc.quoteTexts?.closing}
        readViewContent={doc.interpolatedQuoteTexts?.closing}
        onSave={saveQuoteText}
        showInfo={true}
        renderEditIconButton={button => (
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES_SETTINGS"
            document={quote}
          >
            {button}
          </RestrictedByDocumentPermissionWithDebug>
        )}
      />

      {doc.isPublished && (
        <Paper>
          <Box p={2}>
            <LabeledSignatureValue
              canEdit={false}
              label={t("Signature", { ns: "Quote" })}
              value={
                doc.agreement === "YES" && !doc.needsAgreement
                  ? (doc.agreementSignature ?? null)
                  : null
              }
            />
          </Box>
        </Paper>
      )}

      {files.length > 0 && (
        <FilesBoxTable
          title={t("Attachments", { ns: "Quote" })}
          attachments={files}
          canEdit={false}
          gridColumns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
        />
      )}
    </Stack>
  );
};

const getQuoteItemStyle = (
  theme: Theme,
  item: QuotePreview_ItemFragment
): React.CSSProperties | undefined => ({
  color:
    item.decisionOptionElimination ||
    item.isParentDecisionNotPreselected ||
    item.isParentContingencyNotPreselected ||
    (item.decisionIsContingentItem &&
      item.decisionContingentItemPreselection !== true)
      ? theme.palette.grey[600]
      : item.type === "paid" && item.binding === "non_binding"
        ? theme.palette.secondary.main
        : undefined,
  fontStyle:
    item.type === "paid" && item.binding === "non_binding"
      ? "italic"
      : undefined,
  textDecoration:
    item.deletedAt || item.pendingChangeAttributes.deletedAt
      ? "line-through"
      : undefined,
  backgroundColor: item.pendingChangeAttributes.deletedAt
    ? color.yellowLight
    : undefined,
});

function shouldRenderQuoteItem(item: QuotePreview_ItemFragment) {
  return !item.isItemEliminated && !item.isAnyParentItemEliminated;
}
