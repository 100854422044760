import { useTranslate } from "@tolgee/react";
import { WarningsIconButton } from "../../commons/button/WarningsIconButton.js";
import { Button, Typography } from "@mui/material";
import { Stack } from "../../commons/layout/Stack.js";
import React from "react";
import { LoadingButton } from "@mui/lab";

interface Props {
  templateTypeRevision: number;
  atRevision: number;
  onResolve?: () => Promise<void>;
}

export const TemplateTypeAtVersionWarningButton = ({
  atRevision,
  templateTypeRevision,
  onResolve,
}: Props) => {
  const { t } = useTranslate("TemplateType");

  const [isLoading, setIsLoading] = React.useState(false);

  const onResolveClick = React.useMemo(
    () =>
      onResolve
        ? async () => {
            setIsLoading(true);
            try {
              await onResolve();
            } finally {
              setIsLoading(false);
            }
          }
        : undefined,
    [onResolve]
  );

  return (
    <WarningsIconButton
      warnings={[
        <Stack flexDirection={"column"}>
          <Typography variant="body2">
            {t(
              "Possibly outdated template type definition. Current revision is {templateTypeRevision}, used revision is {atRevision}.",
              { ns: "TemplateType", atRevision, templateTypeRevision }
            )}
          </Typography>
          {onResolveClick && (
            <LoadingButton onClick={onResolveClick} loading={isLoading}>
              {t("Resolve", { ns: "TemplateType" })}
            </LoadingButton>
          )}
        </Stack>,
      ]}
    />
  );
};
