/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PickBrandLineModalSearchBrandLines_BrandLineFragment = { __typename: 'PimBrandLineRef', id: string, title: string, brandId: string };

export type PickBrandLineModalSearchBrandLinesQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String']['input'];
  filter?: Types.InputMaybe<Types.PimSearchBrandLinesFilter>;
}>;


export type PickBrandLineModalSearchBrandLinesQuery = { pimSearchBrandLines: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchBrandLinesResult', totalCount: number, brandLines: Array<{ __typename: 'PimBrandLineRef', id: string, title: string, brandId: string }> } };

export const PickBrandLineModalSearchBrandLines_BrandLineFragmentDoc = gql`
    fragment PickBrandLineModalSearchBrandLines_BrandLine on PimBrandLineRef {
  id
  title
  brandId
}
    `;
export const PickBrandLineModalSearchBrandLinesDocument = gql`
    query PickBrandLineModalSearchBrandLines($searchTerm: String!, $filter: PimSearchBrandLinesFilter) {
  pimSearchBrandLines(limit: 10, searchTerm: $searchTerm, filter: $filter) {
    ... on PimSearchBrandLinesResult {
      brandLines {
        id
        ...PickBrandLineModalSearchBrandLines_BrandLine
      }
      totalCount
    }
  }
}
    ${PickBrandLineModalSearchBrandLines_BrandLineFragmentDoc}`;

/**
 * __usePickBrandLineModalSearchBrandLinesQuery__
 *
 * To run a query within a React component, call `usePickBrandLineModalSearchBrandLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickBrandLineModalSearchBrandLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickBrandLineModalSearchBrandLinesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePickBrandLineModalSearchBrandLinesQuery(baseOptions: Apollo.QueryHookOptions<PickBrandLineModalSearchBrandLinesQuery, PickBrandLineModalSearchBrandLinesQueryVariables> & ({ variables: PickBrandLineModalSearchBrandLinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickBrandLineModalSearchBrandLinesQuery, PickBrandLineModalSearchBrandLinesQueryVariables>(PickBrandLineModalSearchBrandLinesDocument, options);
      }
export function usePickBrandLineModalSearchBrandLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickBrandLineModalSearchBrandLinesQuery, PickBrandLineModalSearchBrandLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickBrandLineModalSearchBrandLinesQuery, PickBrandLineModalSearchBrandLinesQueryVariables>(PickBrandLineModalSearchBrandLinesDocument, options);
        }
export function usePickBrandLineModalSearchBrandLinesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PickBrandLineModalSearchBrandLinesQuery, PickBrandLineModalSearchBrandLinesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PickBrandLineModalSearchBrandLinesQuery, PickBrandLineModalSearchBrandLinesQueryVariables>(PickBrandLineModalSearchBrandLinesDocument, options);
        }
export type PickBrandLineModalSearchBrandLinesQueryHookResult = ReturnType<typeof usePickBrandLineModalSearchBrandLinesQuery>;
export type PickBrandLineModalSearchBrandLinesLazyQueryHookResult = ReturnType<typeof usePickBrandLineModalSearchBrandLinesLazyQuery>;
export type PickBrandLineModalSearchBrandLinesSuspenseQueryHookResult = ReturnType<typeof usePickBrandLineModalSearchBrandLinesSuspenseQuery>;
export type PickBrandLineModalSearchBrandLinesQueryResult = Apollo.QueryResult<PickBrandLineModalSearchBrandLinesQuery, PickBrandLineModalSearchBrandLinesQueryVariables>;