import { CardContainer, MenuItemWithIcon, useScreenWidth } from "@msys/ui";
import { ArrowForward as ArrowForward } from "@mui/icons-material";
import {
  Grid,
  Paper,
  Select as MuiSelect,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Stack } from "../../commons/layout/Stack.js";
import { color } from "../../../common/MuiThemeProvider.js";
import { withStyles } from "../../styles.js";
import { M1ClientCalculator, useM1Calculators } from "./useM1Calculators.js";

const StyledMuiSelect = withStyles(MuiSelect, {
  select: {
    paddingTop: 8,
    paddingBottom: 8,
  },
});

interface Props {
  flows: { label: string; identName: string }[];
  handleStartProcess: (identName: string) => void;
  linksDisabled: boolean;
}

export const M1CalculatorsBox = ({
  flows,
  linksDisabled,
  handleStartProcess,
}: Props) => {
  const { t } = useTranslate("M1Calculators");
  const { clientCalculators, isClientCalculator } = useM1Calculators();
  const { isMaxPhone } = useScreenWidth();

  const clientFlows = flows.filter(flow => isClientCalculator(flow.label));
  const EXPOSED_CALCULATOR_COUNT = isMaxPhone ? 4 : 3;
  const exposedFlows = clientFlows.slice(0, EXPOSED_CALCULATOR_COUNT);
  const otherFlows = clientFlows.slice(EXPOSED_CALCULATOR_COUNT);

  return (
    <CardContainer title={t("Get your instant quote")}>
      <Stack
        flexDirection="column"
        padding={isMaxPhone ? 1 : 2}
        spacing={isMaxPhone ? 1 : 2}
      >
        <Grid container spacing={isMaxPhone ? 1 : 2}>
          {exposedFlows.map(flow => (
            <Grid item xs={6} md={4} key={flow.identName}>
              <FlowTile
                disabled={linksDisabled}
                label={
                  clientCalculators[flow.label as M1ClientCalculator]
                    .displayName
                }
                image={
                  clientCalculators[flow.label as M1ClientCalculator].image
                }
                handleClick={() => {
                  handleStartProcess(flow.identName);
                }}
              />
            </Grid>
          ))}
        </Grid>

        {otherFlows.length > 0 && (
          <Grid container spacing={isMaxPhone ? 1 : 2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography>
                {t("Or choose a different cost calculator:")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledMuiSelect
                variant="outlined"
                fullWidth
                displayEmpty
                value={""}
                disabled={linksDisabled}
                renderValue={value => {
                  return t("Choose a calculator");
                }}
                onChange={event => {
                  if (event.target.value) {
                    handleStartProcess(event.target.value as string);
                  }
                }}
              >
                {otherFlows.map(option => (
                  <MenuItemWithIcon
                    key={option.label}
                    value={option.identName}
                    icon={
                      clientCalculators[option.label as M1ClientCalculator].icon
                    }
                  >
                    {
                      clientCalculators[option.label as M1ClientCalculator]
                        .displayName
                    }
                  </MenuItemWithIcon>
                ))}
              </StyledMuiSelect>
            </Grid>
          </Grid>
        )}
      </Stack>
    </CardContainer>
  );
};

const StyledPaper = withStyles(
  ({
    $disabled,
    ...props
  }: React.ComponentProps<typeof Paper> & { $disabled: boolean }) => (
    <Paper {...props} />
  ),
  (theme, props) => ({
    root: {
      cursor: !props.$disabled ? "pointer" : "default",
      padding: 0,
      display: "block",
      width: "100%",
      ...(!props.$disabled
        ? {
            transition: "background-color 0.2s ease-out",
            "&:hover": {
              // TODO: get color from a theme
              backgroundColor: color.blue9,
            },
          }
        : undefined),
    },
  })
);

const FlowTile = ({
  label,
  image,
  handleClick,
  disabled,
}: {
  label: string;
  image: React.ReactElement;
  handleClick: () => void;
  disabled: boolean;
}) => {
  const theme = useTheme();
  return (
    <StyledPaper
      $disabled={disabled}
      // @ts-ignore
      component={!disabled ? "button" : "div"}
      onClick={!disabled ? handleClick : undefined}
    >
      <Stack flexDirection="column" padding={1}>
        {image}
        <Stack alignItems="center" justifyContent="space-between">
          <Typography>{label}</Typography>
          <ArrowForward
            style={{
              color: !disabled
                ? theme.palette.text.primary
                : theme.palette.text.disabled,
            }}
          />
        </Stack>
      </Stack>
    </StyledPaper>
  );
};
