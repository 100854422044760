import { FormControl, FormHelperText, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { get, isString } from "lodash-es";
import React, { FC } from "react";
import { Attachment } from "../../features/attachments/helpers.js";
import { AttachmentImageDragAndDropInput } from "../inputs/AttachmentImageDragAndDropInput.js";

interface Props {
  skipEdit?: boolean;
  loading?: boolean;
  disabled?: boolean;
  label?: string;
  name: string;
  value?: Attachment | null;
  onChange?: (newValue: Attachment | null) => void;
  onAttachment?: (attachment: Attachment) => void | Promise<void>;
  minHeight?: number;
}

export const AttachmentImageDragAndDropField: FC<Props> = ({
  label,
  name,
  value,
  onChange,
  disabled,
  ...props
}) => {
  const { errors, values, setFieldValue } =
    useFormikContext<Record<string, any>>();

  const error = get(errors, name);

  return (
    <FormControl fullWidth={true} error={Boolean(error)} disabled={disabled}>
      {label ? (
        <Typography variant="body1" sx={{ mb: 1 }}>
          {label}
        </Typography>
      ) : null}
      <AttachmentImageDragAndDropInput
        value={value !== undefined ? value : (get(values, name) ?? null)}
        onChange={
          onChange ??
          (attachment => {
            setFieldValue(name, attachment);
          })
        }
        disabled={disabled}
        {...props}
      />
      {Boolean(error) && isString(error) && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
};
