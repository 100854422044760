import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  isImageOr3dModel,
  Modal,
  ModalOpenButton,
  processAttachment,
  useScreenWidth,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Gavel as GavelIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  DialogActions,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { partition } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { GalleryGrid } from "../../../commons/images/GalleryGrid.js";
import { PageSectionHeader } from "../../../commons/layout/PageSectionHeader.js";
import { PageNotFound } from "../../../commons/PageNotFound.js";
import { ALLOW_REQUIREMENT_IN_REQUEST } from "../../../constants.js";
import { FilesBoxTable } from "../../attachments/FilesBoxTable.js";
import { CreateQuoteButton } from "../../quotes/buttons/CreateQuoteButton.js";
import { QuoteCreateModal } from "../../quotes/modals/QuoteCreateModal.js";
import { M1Inputs } from "../../requirements/M1Inputs.js";
import {
  requirementItemHasDescription,
  RequirementPreviewItemDescription,
} from "../../requirements/preview/RequirementPreviewItemDescription.js";
import { RequirementPreviewItems } from "../../requirements/RequirementPreview.js";
import { RequestOverviewHeaderBox } from "../boxes/RequestOverviewHeaderBox.js";
import {
  useCreateProjectForRequestMutation,
  useProvideRequestFeedbackMutation,
} from "../Requests.generated.js";
import { RequestStatusBadge } from "../RequestStatusBadge.js";
import {
  useRequestOverviewOpportunityQuoteRequestQuery,
  useRequestOverviewProjectIncomingQuoteRequestQuery,
  useRequestOverviewProjectOutgoingQuoteRequestQuery,
} from "./RequestOverviewModal.generated.js";

const EMPTY_ARRAY: any[] = [];

export const RequestOverviewOpportunityQuoteModal = ({
  requestId,
  handleClose,
}: {
  requestId: string;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["RequestOverview", "Quote"]);
  const { isMaxPhone, isMinTablet, isMinDesktop } = useScreenWidth();

  const [contentContainer, setContentContainer] =
    React.useState<HTMLDivElement | null>(null);

  const client = useApolloClient();
  const query = useRequestOverviewOpportunityQuoteRequestQuery({
    client,
    variables: {
      requestId,
    },
  });

  const request = getDataOrNull(query.data?.opportunityQuoteRequest)?.request;

  const isLoading = !query.data && query.loading;
  const requirement = request?.requestorDoc;

  const rootItem = requirement?.items?.find(item => item.isRootItem);

  const [images, files] = partition(
    [
      ...(request?.attachments ?? []),
      ...(ALLOW_REQUIREMENT_IN_REQUEST
        ? (rootItem?.attachments ?? []).filter(
            attachment => attachment.clientVisibility
          )
        : []),
    ].map(processAttachment),
    isImageOr3dModel
  );

  const dialogActions = request ? (
    viewer.organisation.id ===
    request.owningSystemOrganisationId ? null : request.status !==
      "PUBLISHED" ? (
      <DialogActions sx={{ justifyContent: "stretch" }}>
        <Alert variant="outlined" severity="warning" sx={{ flex: 1 }}>
          {t("This opportunity is no longer accepting new quotes", {
            ns: "RequestOverview",
          })}
        </Alert>
      </DialogActions>
    ) : request.viewerRecipientStatus === "INITIAL" ? (
      <DialogActions sx={{ justifyContent: "stretch" }}>
        <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
          <RequestOverviewActionsBox opportunityId={requestId} />
        </RestrictedByOrganisationPermissionWithDebug>
      </DialogActions>
    ) : (request.viewerRecipientStatus === "YES" ||
        request.viewerRecipientStatus === "MAYBE") &&
      request.viewerRecipientProject ? (
      <DialogActions sx={{ justifyContent: "center" }}>
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_QUOTES"
          project={request.viewerRecipientProject}
        >
          <ModalOpenButton
            Modal={QuoteCreateModal}
            modalProps={{
              requestId,
              projectId: request.viewerRecipientProject.id,
              handleComplete: async quoteId => {
                if (quoteId)
                  navigate({
                    pathname: `/projects/${request.viewerRecipientProject?.id}/quotes/${quoteId}/edit`,
                  });
                else
                  navigate(
                    `/projects/${request.viewerRecipientProject?.id}/quotes`
                  );
              },
            }}
          >
            <Button variant="contained" color="primary" startIcon={<AddIcon />}>
              {t("New quote", {
                ns: "RequestOverview",
              })}
            </Button>
          </ModalOpenButton>
        </RestrictedByProjectPermissionWithDebug>
      </DialogActions>
    ) : request.viewerRecipientStatus === "NO" ? (
      <DialogActions sx={{ justifyContent: "stretch" }}>
        <Alert variant="outlined" severity="error" sx={{ flex: 1 }}>
          {t("You are not interested in this opportunity", {
            ns: "RequestOverview",
          })}
        </Alert>
      </DialogActions>
    ) : null
  ) : !isLoading ? (
    <DialogActions sx={{ justifyContent: "stretch" }}>
      <Alert variant="outlined" severity="error" sx={{ flex: 1 }}>
        {t("You don't have permission to view this document", {
          ns: "RequestOverview",
        })}
      </Alert>
    </DialogActions>
  ) : null;

  return (
    <Modal
      id="request-overview-modal"
      dialogProps={{ fullScreen: isMaxPhone, fullWidth: true }}
      maxWidth="lg"
      title={
        request ? (
          <PageSectionHeader
            status={<RequestStatusBadge request={request} />}
            title={request.title}
          />
        ) : undefined
      }
      handleClose={handleClose}
      isLoading={isLoading}
      contentContainerRef={setContentContainer}
      dialogActions={dialogActions}
    >
      {request ? (
        <Stack direction="column" spacing={1}>
          <RequestOverviewHeaderBox request={request} />
          {/*Don't show it in opportunity overview*/}
          {/*{requirement.m1 ? <M1Inputs m1={requirement.m1} /> : null}*/}
          {ALLOW_REQUIREMENT_IN_REQUEST &&
            requirement &&
            rootItem &&
            (images.length > 0 || requirementItemHasDescription(rootItem)) && (
              <Paper>
                <Stack padding={2} direction={"column"} spacing={2}>
                  <GalleryGrid
                    images={images}
                    columns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
                  />
                  <RequirementPreviewItemDescription
                    item={rootItem}
                    columns={isMinDesktop ? 4 : isMinTablet ? 2 : 1}
                  />
                </Stack>
              </Paper>
            )}
          {ALLOW_REQUIREMENT_IN_REQUEST && requirement && (
            <RequirementPreviewItems
              requirement={requirement}
              viewerDecisionRole={undefined}
              requirementId={requirement.id}
              canFinalize={false}
              projectId={null}
              allDocItems={requirement.items}
              expandedItemIds={EMPTY_ARRAY}
              container={contentContainer}
              viewerIsOwner={false}
            />
          )}
          {files.length > 0 && (
            <FilesBoxTable
              title={t("Attachments", { ns: "Quote" })}
              attachments={files}
              canEdit={false}
              gridColumns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
            />
          )}
        </Stack>
      ) : (
        <PageNotFound />
      )}
    </Modal>
  );
};

export const RequestOverviewProjectIncomingQuoteModal = ({
  projectId,
  requestId,
  handleClose,
}: {
  projectId: string;
  requestId: string;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["RequestOverview", "Quote"]);
  const { isMaxPhone, isMinTablet, isMinDesktop } = useScreenWidth();

  const [contentContainer, setContentContainer] =
    React.useState<HTMLDivElement | null>(null);

  const client = useApolloClient();
  const query = useRequestOverviewProjectIncomingQuoteRequestQuery({
    client,
    variables: {
      projectId,
      requestId,
    },
  });

  const request = getDataOrNull(
    query.data?.projectIncomingQuoteRequest
  )?.request;

  const isLoading = !query.data && query.loading;

  const requirement = request?.requestorDoc;

  const rootItem = requirement?.items?.find(item => item.isRootItem);

  const [images, files] = partition(
    [
      ...(request?.attachments ?? []),
      ...(rootItem?.attachments ?? []).filter(
        attachment => attachment.clientVisibility
      ),
    ].map(processAttachment),
    isImageOr3dModel
  );

  const dialogActions = request ? (
    viewer.organisation.id ===
    request.owningSystemOrganisationId ? null : request.status !==
      "PUBLISHED" ? (
      <DialogActions sx={{ justifyContent: "stretch" }}>
        <Alert variant="outlined" severity="warning" sx={{ flex: 1 }}>
          {t("This opportunity is no longer accepting new quotes", {
            ns: "RequestOverview",
          })}
        </Alert>
      </DialogActions>
    ) : request.viewerRecipientStatus === "INITIAL" ? (
      <DialogActions sx={{ justifyContent: "stretch" }}>
        <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
          <RequestOverviewActionsBox opportunityId={requestId} />
        </RestrictedByOrganisationPermissionWithDebug>
      </DialogActions>
    ) : (request.viewerRecipientStatus === "YES" ||
        request.viewerRecipientStatus === "MAYBE") &&
      request.viewerRecipientProject ? (
      <DialogActions sx={{ justifyContent: "center" }}>
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_QUOTES"
          project={request.viewerRecipientProject}
        >
          <ModalOpenButton
            Modal={QuoteCreateModal}
            modalProps={{
              requestId,
              projectId: request.viewerRecipientProject.id,
              handleComplete: async quoteId => {
                if (quoteId)
                  navigate(
                    `/projects/${request.viewerRecipientProject?.id}/quotes/${quoteId}/edit`
                  );
                else
                  navigate(
                    `/projects/${request.viewerRecipientProject?.id}/quotes`
                  );
              },
            }}
          >
            <Button variant="contained" color="primary" startIcon={<AddIcon />}>
              {t("New quote", {
                ns: "RequestOverview",
              })}
            </Button>
          </ModalOpenButton>
        </RestrictedByProjectPermissionWithDebug>
      </DialogActions>
    ) : request.viewerRecipientStatus === "NO" ? (
      <DialogActions sx={{ justifyContent: "stretch" }}>
        <Alert variant="outlined" severity="error" sx={{ flex: 1 }}>
          {t("You are not interested in this opportunity", {
            ns: "RequestOverview",
          })}
        </Alert>
      </DialogActions>
    ) : null
  ) : !isLoading ? (
    <DialogActions sx={{ justifyContent: "stretch" }}>
      <Alert variant="outlined" severity="error" sx={{ flex: 1 }}>
        {t("You don't have permission to view this document", {
          ns: "RequestOverview",
        })}
      </Alert>
    </DialogActions>
  ) : null;

  return (
    <Modal
      id="request-overview-modal"
      dialogProps={{ fullScreen: isMaxPhone, fullWidth: true }}
      maxWidth="lg"
      title={
        request ? (
          <PageSectionHeader
            status={<RequestStatusBadge request={request} />}
            title={request.title}
          />
        ) : undefined
      }
      handleClose={handleClose}
      isLoading={isLoading}
      contentContainerRef={setContentContainer}
      dialogActions={dialogActions}
    >
      {request && requirement && rootItem ? (
        <Stack direction="column" spacing={1}>
          <RequestOverviewHeaderBox request={request} />
          {/*Don't show it in incoming*/}
          {/*{requirement.m1 ? <M1Inputs m1={requirement.m1} /> : null}*/}
          {(images.length > 0 || requirementItemHasDescription(rootItem)) && (
            <Paper>
              <Stack padding={2} direction={"column"} spacing={2}>
                <GalleryGrid
                  images={images}
                  columns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
                />
                <RequirementPreviewItemDescription
                  item={rootItem}
                  columns={isMinDesktop ? 4 : isMinTablet ? 2 : 1}
                />
              </Stack>
            </Paper>
          )}
          <RequirementPreviewItems
            requirement={requirement}
            viewerDecisionRole={undefined}
            requirementId={requirement.id}
            canFinalize={false}
            projectId={null}
            allDocItems={requirement.items}
            expandedItemIds={EMPTY_ARRAY}
            container={contentContainer}
            viewerIsOwner={false}
          />
          {files.length > 0 && (
            <FilesBoxTable
              title={t("Attachments", { ns: "Quote" })}
              attachments={files}
              canEdit={false}
              gridColumns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
            />
          )}
        </Stack>
      ) : (
        <PageNotFound />
      )}
    </Modal>
  );
};

export const RequestOverviewProjectOutgoingQuoteModal = ({
  projectId,
  requestId,
  handleClose,
}: {
  projectId: string;
  requestId: string;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["RequestOverview", "Quote"]);
  const { isMaxPhone, isMinTablet, isMinDesktop } = useScreenWidth();

  const [contentContainer, setContentContainer] =
    React.useState<HTMLDivElement | null>(null);

  const client = useApolloClient();
  const query = useRequestOverviewProjectOutgoingQuoteRequestQuery({
    client,
    variables: {
      projectId,
      requestId,
    },
  });

  const request = getDataOrNull(
    query.data?.projectOutgoingQuoteRequest
  )?.request;

  const isLoading = !query.data && query.loading;

  const requirement = request?.requestorDoc;

  const rootItem = requirement?.items?.find(item => item.isRootItem);

  const [images, files] = partition(
    [
      ...(request?.attachments ?? []),
      ...(rootItem?.attachments ?? []).filter(
        attachment => attachment.clientVisibility
      ),
    ].map(processAttachment),
    isImageOr3dModel
  );

  const dialogActions = request ? (
    viewer.organisation.id ===
    request.owningSystemOrganisationId ? null : request.status !==
      "PUBLISHED" ? (
      <DialogActions sx={{ justifyContent: "stretch" }}>
        <Alert variant="outlined" severity="warning" sx={{ flex: 1 }}>
          {t("This opportunity is no longer accepting new quotes", {
            ns: "RequestOverview",
          })}
        </Alert>
      </DialogActions>
    ) : request.viewerRecipientStatus === "INITIAL" ? (
      <DialogActions sx={{ justifyContent: "stretch" }}>
        <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
          <RequestOverviewActionsBox opportunityId={requestId} />
        </RestrictedByOrganisationPermissionWithDebug>
      </DialogActions>
    ) : (request.viewerRecipientStatus === "YES" ||
        request.viewerRecipientStatus === "MAYBE") &&
      request.viewerRecipientProject ? (
      <DialogActions sx={{ justifyContent: "center" }}>
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_QUOTES"
          project={request.viewerRecipientProject}
        >
          <CreateQuoteButton
            projectId={request.viewerRecipientProject.id}
            pathToProject={`/projects/${request.viewerRecipientProject?.id}`}
            Button={
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                {t("New quote", {
                  ns: "RequestOverview",
                })}
              </Button>
            }
          />
        </RestrictedByProjectPermissionWithDebug>
      </DialogActions>
    ) : request.viewerRecipientStatus === "NO" ? (
      <DialogActions sx={{ justifyContent: "stretch" }}>
        <Alert variant="outlined" severity="error" sx={{ flex: 1 }}>
          {t("You are not interested in this opportunity", {
            ns: "RequestOverview",
          })}
        </Alert>
      </DialogActions>
    ) : null
  ) : !isLoading ? (
    <DialogActions sx={{ justifyContent: "stretch" }}>
      <Alert variant="outlined" severity="error" sx={{ flex: 1 }}>
        {t("You don't have permission to view this document", {
          ns: "RequestOverview",
        })}
      </Alert>
    </DialogActions>
  ) : null;

  return (
    <Modal
      id="request-overview-modal"
      dialogProps={{ fullScreen: isMaxPhone, fullWidth: true }}
      maxWidth="lg"
      title={
        request ? (
          <PageSectionHeader
            status={<RequestStatusBadge request={request} />}
            title={request.title}
          />
        ) : undefined
      }
      handleClose={handleClose}
      isLoading={isLoading}
      contentContainerRef={setContentContainer}
      dialogActions={dialogActions}
    >
      {request && requirement && rootItem ? (
        <Stack direction="column" spacing={1}>
          <RequestOverviewHeaderBox request={request} />
          {requirement.m1 ? <M1Inputs m1={requirement.m1} /> : null}
          {(images.length > 0 || requirementItemHasDescription(rootItem)) && (
            <Paper>
              <Stack padding={2} direction={"column"} spacing={2}>
                <GalleryGrid
                  images={images}
                  columns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
                />
                <RequirementPreviewItemDescription
                  item={rootItem}
                  columns={isMinDesktop ? 4 : isMinTablet ? 2 : 1}
                />
              </Stack>
            </Paper>
          )}
          <RequirementPreviewItems
            requirement={requirement}
            viewerDecisionRole={undefined}
            requirementId={requirement.id}
            canFinalize={false}
            projectId={null}
            allDocItems={requirement.items}
            expandedItemIds={EMPTY_ARRAY}
            container={contentContainer}
            viewerIsOwner={false}
          />
          {files.length > 0 && (
            <FilesBoxTable
              title={t("Attachments", { ns: "Quote" })}
              attachments={files}
              canEdit={false}
              gridColumns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
            />
          )}
        </Stack>
      ) : (
        <PageNotFound />
      )}
    </Modal>
  );
};

const RequestOverviewActionsBox = ({
  opportunityId,
}: {
  opportunityId: string;
}) => {
  const { t } = useTranslate("RequestOverview");
  const { isMinTablet } = useScreenWidth();
  const client = useApolloClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [createProjectForRequest, { loading: createLoading }] =
    useCreateProjectForRequestMutation({
      client,
    });
  const [provideRequestFeedback, { loading: feedbackLoading }] =
    useProvideRequestFeedbackMutation({
      client,
    });

  const handleInterested = async (maybeInterested = false) => {
    try {
      const res = await createProjectForRequest({
        variables: {
          input: { requestId: opportunityId },
        },
      });
      await provideRequestFeedback({
        variables: {
          input: {
            requestId: opportunityId,
            feedback: maybeInterested ? "MAYBE" : "YES",
          },
        },
        refetchQueries: [namedOperations.Query.MarketplaceOpportunities],
        awaitRefetchQueries: true,
      });

      const projectId = res.data?.createProjectForRequest.project.id;

      if (!projectId) throw new Error(t("Creating project failed"));

      const pathToProject = `/projects/${projectId}`;
      enqueueSnackbar(
        <Stack spacing={1}>
          <Typography>
            {maybeInterested
              ? t("Opportunity moved to inbox")
              : t("Opportunity moved to shortlisted")}
          </Typography>
          <Button
            size="small"
            color="inherit"
            variant="outlined"
            onClick={() => navigate(pathToProject)}
            sx={{ alignSelf: "flex-start" }}
          >
            {t("Open")}
          </Button>
        </Stack>
      );
      navigate(`/opportunities/current`);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const handleNotInterested = async () => {
    try {
      await provideRequestFeedback({
        variables: {
          input: {
            requestId: opportunityId,
            feedback: "NO",
          },
        },
        refetchQueries: [namedOperations.Query.MarketplaceOpportunities],
        awaitRefetchQueries: true,
      });
      enqueueSnackbar(t("Opportunity marked as not interesting"));
      navigate(`/opportunities/current`);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Stack
      direction={isMinTablet ? "row" : "column"}
      alignItems={"center"}
      justifyContent={isMinTablet ? "space-between" : "flex-start"}
      width="100%"
      spacing={1}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <GavelIcon />
        <Typography variant="h3">
          {t("Are you interested in bidding on this opportunity?")}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        width="auto"
        alignItems="center"
        alignContent="center"
        spacing={1}
      >
        <LoadingButton
          loadingPosition="center"
          onClick={() => handleInterested(false)}
          size={isMinTablet ? "medium" : "small"}
          color="success"
          variant="contained"
          loading={createLoading || feedbackLoading}
        >
          {t("I’m interested")}
        </LoadingButton>
        <LoadingButton
          loadingPosition="center"
          onClick={handleNotInterested}
          size={isMinTablet ? "medium" : "small"}
          color="error"
          variant="outlined"
          loading={createLoading || feedbackLoading}
        >
          {t("Not interested")}
        </LoadingButton>
        <LoadingButton
          loadingPosition="center"
          onClick={() => handleInterested(true)}
          size={isMinTablet ? "medium" : "small"}
          color="primary"
          variant="outlined"
          loading={createLoading || feedbackLoading}
        >
          {t("Maybe")}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
