import { MenuButton, MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { AddressInput } from "../../../clients/graphqlTypes.js";
import { AddressDetails__AddressFragment } from "./Addresses.generated.js";
import { useTranslate } from "@tolgee/react";
import { Address } from "./Address.js";
import { AddressSearch } from "./AddressSearch.js";
import { GetAddressModal } from "./GetAddressModal.js";

export const AddressComboField = ({
  label,
  address,
  handleChange,
  allowCustom = false,
}: {
  label: string;
  address: AddressDetails__AddressFragment | null;
  handleChange: (address: AddressInput | null) => void;
  allowCustom?: boolean;
}) => {
  const { t } = useTranslate("Address");

  if (!address) {
    return <AddressSearch label={label} onChange={handleChange} />;
  }

  return (
    <Address
      label={label}
      address={address}
      actionButtons={
        <MenuButton>
          <ModalOpenButton
            Modal={GetAddressModal}
            modalProps={{
              inputLabel: label,
              value: address,
              handleComplete: async (address, handleClose) => {
                handleChange(address);
                handleClose();
              },
              allowCustom,
            }}
          >
            <MenuItemWithIcon icon={<EditIcon />}>
              {t("Edit address")}
            </MenuItemWithIcon>
          </ModalOpenButton>
          <MenuItemWithIcon
            icon={<DeleteIcon />}
            onClick={() => {
              handleChange(null);
            }}
          >
            {t("Delete address")}
          </MenuItemWithIcon>
        </MenuButton>
      }
    />
  );
};
