import { Restore as RestoreIcon } from "@mui/icons-material";
import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { RestoreTemplateVersionModal } from "../RestoreTemplateVersionModal.js";

interface Props {
  docId: string;
  handleComplete: () => Promise<void> | void;
  title?: string;
}

export const QuoteTemplateRestoreTemplateVersionMenuItem = React.forwardRef<
  HTMLLIElement,
  Props
>(({ title, docId, handleComplete }, ref) => {
  const { t } = useTranslate("Templates");

  return (
    <ModalOpenButton
      Modal={RestoreTemplateVersionModal}
      modalProps={{
        docId,
        handleComplete: async handleClose => {
          await handleComplete();
          handleClose();
        },
      }}
    >
      <MenuItemWithIcon ref={ref} icon={<RestoreIcon />}>
        {title ?? t("Restore a specific version")}
      </MenuItemWithIcon>
    </ModalOpenButton>
  );
});
