import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal, Select } from "@msys/ui";
import { Box, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { noop, uniqueId } from "lodash-es";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../../auth/RestrictedByDocumentPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField.js";
import { DurationDailyField } from "../../../commons/form-fields/DurationDailyField.js";
import { TimePickerField } from "../../../commons/form-fields/TimePickerField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { VirtualBareTreeStandaloneItem } from "../../../trees-virtual/components/VirtualBareTreeStandaloneItem.js";
import { getAssigneeOptions } from "../../tasks/helpers.js";
import { createTreeSimpleItem } from "../../tasks/trees.js";
import { useAddTaskWorkSessionModalQuery } from "./AddTaskWorkSessionModal.generated.js";
import { useCreateWorkSessionsMutation } from "./AddTaskWorkSessionsModal.generated.js";

export interface FormValues {
  id: string;
  timeStart: string;
  timeEnd: string;
  date: Moment;
  duration: number;
}

interface Props {
  projectId: string;
  docId: string;
  itemId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: () => void;
}

export const AddTaskWorkSessionModal = ({
  projectId,
  docId,
  itemId,
  title,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["Task", "Global"]);
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();

  const { enqueueSnackbar } = useSnackbar();
  const formId = useMemo(() => uniqueId(), []);
  const now = useMemo(() => moment(), []);

  const [assigneeId, setAssigneeId] = useState<string>(viewer.id);

  const query = useAddTaskWorkSessionModalQuery({
    client,
    variables: { projectId, quoteId: docId, itemId },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const quote = project?.tasks[0];
  const item = quote?.item[0];
  const allDocItems = quote?.allDocItems;

  const [addWorkSessions] = useCreateWorkSessionsMutation({
    client,
    refetchQueries: [
      namedOperations.Query.TasksTimesheetTasksForm,
      namedOperations.Query.ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?,
    ],
    awaitRefetchQueries: false,
  });

  const TreeItem = useMemo(
    () =>
      quote?.agreement
        ? createTreeSimpleItem({
            pathToDocPage: null,
            docAgreement: quote.agreement,
            navigateToItem: noop,
            setItemExpanded: noop,
          })
        : undefined,
    [quote?.agreement]
  );

  const initialValues: FormValues = getEmptyWorkSession(now);

  const validationSchema = Yup.object().shape({
    date: Yup.object()
      .label(
        t("Date", {
          ns: "Task",
        })
      )
      .required(),
    duration: Yup.number()
      .label(
        t("Time spent", {
          ns: "Task",
        })
      )
      .required(),
    timeStart: Yup.string().label(
      t("Start time", {
        ns: "Task",
      })
    ),
    timeEnd: Yup.string()
      .label(
        t("Finish time", {
          ns: "Task",
        })
      )
      .when("timeStart", (timeStart: string, schema: any) => {
        return schema.test({
          test: (timeEnd: string) => {
            return (
              !timeStart ||
              !timeEnd ||
              moment(timeStart, "LT").valueOf() <
                moment(timeEnd, "LT").valueOf()
            );
          },
          message: t("Finish time should be after a start time", {
            ns: "Task",
          }),
        });
      }),
  });

  const handleSubmit = async (values: FormValues) => {
    if (!isWorkSessionValid(values)) return;

    const input = {
      itemId,
      inputs: [
        {
          assigneeId,
          ...(values.timeStart && values.timeEnd
            ? {
                from: moment(values.date)
                  .set({
                    hours: +values.timeStart.split(":")[0],
                    minutes: +values.timeStart.split(":")[1],
                  })
                  .toISOString(true),
                till: moment(values.date)
                  .set({
                    hours: +values.timeEnd.split(":")[0],
                    minutes: +values.timeEnd.split(":")[1],
                  })
                  .toISOString(true),
              }
            : {
                date: values.date.format("YYYY-MM-DD"),
                duration: values.duration,
              }),
        },
      ],
    };

    try {
      await addWorkSessions({
        variables: {
          input,
        },
      });
      enqueueSnackbar(
        t("Task work session added", {
          ns: "Task",
        })
      );
      handleComplete?.();
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        isSubmitting,
        isValid,
      }: FormikProps<FormValues>) => (
        <Modal
          title={
            title ??
            t("Add task work session", {
              ns: "Task",
            })
          }
          dialogProps={{ maxWidth: "xs" }}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Add", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled: !isValid || !isWorkSessionValid(values),
                loading: isSubmitting,
              },
            },
          ]}
          handleClose={handleClose}
          isLoading={query.loading}
        >
          {item && allDocItems && TreeItem && (
            <Box mb={3 / 2}>
              <VirtualBareTreeStandaloneItem
                item={{
                  ...item,
                  docViewerPermissions: quote.viewerPermissions,
                  docAgreement: quote.agreement,
                }}
                items={allDocItems.map(item => ({
                  ...item,
                  docViewerPermissions: quote.viewerPermissions,
                  docAgreement: quote.agreement,
                }))}
                depth={0}
                itemComponent={TreeItem}
              />
            </Box>
          )}

          {project && quote && (
            <RestrictedByDocumentPermissionWithDebug
              permission="MANAGE_TASK"
              document={quote}
            >
              {!!viewer.roles.find(r => r.internalName === "ORG_ADMIN") && (
                <Box mb={3 / 2}>
                  <Select
                    label={t("Team member", {
                      ns: "Task",
                    })}
                    value={assigneeId}
                    options={getAssigneeOptions(project)}
                    onChange={value => {
                      setAssigneeId(value);
                    }}
                  />
                </Box>
              )}
            </RestrictedByDocumentPermissionWithDebug>
          )}

          <Form id={formId}>
            <Stack width="100%" flexDirection="column">
              <Stack>
                <DatePickerField
                  required
                  disableFuture
                  name="date"
                  label={t("Date", {
                    ns: "Task",
                  })}
                />
                <DurationDailyField
                  required
                  name="duration"
                  label={t("Time spent", {
                    ns: "Task",
                  })}
                  onChange={duration => {
                    setFieldValue("duration", duration);
                    if (duration && values.timeStart) {
                      const [timeStart, timeEnd] = getTimesFromDuration(
                        values.timeStart,
                        duration
                      );
                      setFieldValue("timeStart", timeStart);
                      setFieldValue("timeEnd", timeEnd);
                    }
                  }}
                />
              </Stack>
              <Typography variant="caption" color="textSecondary">
                {t("Optionally", {
                  ns: "Task",
                })}
                :
              </Typography>
              <Stack>
                <TimePickerField
                  name="timeStart"
                  label={t("Start time", {
                    ns: "Task",
                  })}
                  onChange={(e, value) => {
                    setFieldValue("timeStart", value);
                    if (value && values.timeEnd) {
                      setFieldValue(
                        "duration",
                        getDurationFromTimes(value, values.timeEnd)
                      );
                    }
                  }}
                />
                <TimePickerField
                  name="timeEnd"
                  label={t("Finish time", {
                    ns: "Task",
                  })}
                  onChange={(e, value) => {
                    setFieldValue("timeEnd", value);
                    if (value && values.timeStart) {
                      setFieldValue(
                        "duration",
                        getDurationFromTimes(values.timeStart, value)
                      );
                    }
                  }}
                />
              </Stack>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export const isWorkSessionValid = (values: FormValues): boolean => {
  return Boolean(
    values.date &&
      (values.duration > 0 ||
        (values.timeStart &&
          values.timeEnd &&
          moment(values.timeStart, "LT").valueOf() <
            moment(values.timeEnd, "LT").valueOf()))
  );
};

export const getEmptyWorkSession = (now: Moment): FormValues => {
  return {
    id: v4(),
    date: moment(now).startOf("day"),
    duration: 0,
    timeStart: "",
    timeEnd: "",
  };
};

export const getDurationFromTimes = (timeStart: string, timeEnd: string) => {
  const minutes = moment(timeEnd, "HH:mm").diff(
    moment(timeStart, "HH:mm"),
    "minutes"
  );
  return Math.max(minutes, 0);
};

export const getTimesFromDuration = (timeStart: string, duration: number) => {
  const timeMax = moment(timeStart, "HH:mm").endOf("day");
  const timeMin = moment(timeStart, "HH:mm").startOf("day");
  const timeEnd = moment(timeStart, "HH:mm").add(duration, "minutes");

  if (timeEnd.valueOf() <= timeMax.valueOf())
    return [timeStart, timeEnd.format("HH:mm")];
  else {
    const newTimeStart = moment.max(
      timeMin,
      moment(timeMax).add(-duration, "minutes")
    );
    return [newTimeStart.format("HH:mm"), timeMax.format("HH:mm")];
  }
};
