import { CardContainer, ModalOpenButton } from "@msys/ui";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Stack } from "../../../commons/layout/Stack.js";
import { ModifyOrganisationPurchaseOrdersTextsModal } from "../modals/ModifyOrganisationPurchaseOrdersTextsModal.js";

interface Props {
  organisationId: string;
}

export const OrganisationPurchaseOrderSettingsBox = ({
  organisationId,
}: Props) => {
  const { t } = useTranslate("OrganisationSettings");

  return (
    <CardContainer isExpandable title={t("Purchase order PDF settings")}>
      <Stack flexDirection="column" p={1} width="100%">
        <div>
          <ModalOpenButton
            Modal={ModifyOrganisationPurchaseOrdersTextsModal}
            modalProps={{
              organisationId,
              title: t("Customize purchase order text"),
            }}
          >
            <Button
              type="button"
              color="secondary"
              variant="text"
              size="small"
              endIcon={<ChevronRightIcon />}
            >
              {t("Customize purchase order text")}
            </Button>
          </ModalOpenButton>
        </div>
      </Stack>
    </CardContainer>
  );
};
