import { useApolloClient } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { SwitchField } from "../../../commons/form-fields/SwitchField.js";
import { TemplateQuoteSelectField } from "../../templates/quote/TemplateQuoteSelectField.js";
import {
  OrganisationEmbeddableWizardSnippetBox_OrganisationFragment,
  OrganisationEmbeddableWizardSnippetBox_SettingsFragment,
  useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation,
} from "./OrganisationEmbeddableWizardSnippetBox.generated.js";

interface Props {
  organisationSettings: OrganisationEmbeddableWizardSnippetBox_SettingsFragment;
  organisation: OrganisationEmbeddableWizardSnippetBox_OrganisationFragment;
}

export const OrganisationEmbeddableWizardSnippetBox = ({
  organisationSettings,
  organisation,
}: Props) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  const [selectedTemplate, setSelectedTemplate] =
    React.useState<
      React.ComponentProps<typeof TemplateQuoteSelectField>["value"]
    >(null);

  return (
    <CardContainer
      title={t("Embeddable wizard", {
        ns: "OrganisationSettings",
      })}
      isExpandable
    >
      <Stack direction={"column"} spacing={1} padding={1}>
        <OrganisationEmbeddableWizardSettingsForm
          organisationId={organisation.id}
          organisationSettings={organisationSettings}
        />
      </Stack>
    </CardContainer>
  );
};

interface FormValues {
  embeddableWizardIsLeadAddressRequired: boolean;
}

export function OrganisationEmbeddableWizardSettingsForm({
  organisationId,
  organisationSettings,
}: {
  organisationSettings: OrganisationEmbeddableWizardSnippetBox_SettingsFragment;
  organisationId: string;
}) {
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const [modifyOrganisationSettings] =
    useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation(
      {
        client,
      }
    );

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const initialValues = {
    embeddableWizardIsLeadAddressRequired:
      organisationSettings.embeddableWizardIsLeadAddressRequired,
  };

  const validationSchema = Yup.object().shape({
    embeddableWizardIsLeadAddressRequired: Yup.bool().label(
      t("Lead address is required", { ns: "OrganisationSettings" })
    ),
  });

  const handleSubmit = async (values: FormValues) => {
    await modifyOrganisationSettings({
      variables: {
        input: {
          embeddableWizardIsLeadAddressRequired:
            values.embeddableWizardIsLeadAddressRequired,
        },
      },
    });
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack direction="column" spacing={1}>
            <div>
              <SwitchField
                name="embeddableWizardIsLeadAddressRequired"
                label={t("Lead address is required", {
                  ns: "OrganisationSettings",
                })}
                disabled={false}
              />
            </div>
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
