import {
  ClipboardRecordType,
  useClipboard,
} from "../../../commons/hooks/useClipboard.js";
import { ItemClipboard } from "../helpers.js";
import React from "react";

const KEY = "doc-items";

export const useDocItemsClipboard = (): [
  content: ItemClipboard | null,
  setContent: (newContent: ItemClipboard | null) => void,
] => {
  const [clipboard, setClipboard] = useClipboard();

  const content = React.useMemo(
    () => clipboard[KEY] as ItemClipboard | null,
    [clipboard]
  );
  const setContent = React.useCallback(
    (newContent: ItemClipboard | null) => {
      setClipboard((v: ClipboardRecordType | undefined) => ({
        ...(v ?? {}),
        [KEY]: newContent,
      }));
    },
    [setClipboard]
  );

  return [content, setContent];
};
