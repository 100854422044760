import { useScreenWidth } from "@msys/ui";
import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { SnackbarProvider as Provider } from "notistack";
import React from "react";
import { isInTestMode } from "../app/utils.js";

export const SnackbarProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { isMinTablet } = useScreenWidth();
  const notistackRef = React.useRef<any>();

  return (
    <Provider
      ref={notistackRef}
      preventDuplicate={true}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: isMinTablet ? "right" : "center",
      }}
      action={key => (
        <IconButton
          size="small"
          onClick={() => {
            notistackRef.current.closeSnackbar(key);
          }}
        >
          <CloseIcon style={{ color: "#FFFFFF" }} />
        </IconButton>
      )}
      style={isInTestMode() ? { display: "none" } : undefined}
      variant="success"
    >
      {children}
    </Provider>
  );
};
