import React from "react";
import { Avatar, AvatarSize } from "../../commons/Avatar.js";
import { Attachment } from "../attachments/helpers.js";
import { OrganisationAvatarFragment } from "./OrganisationAvatar.generated.js";

interface Props {
  organisationAvatar: OrganisationAvatarFragment;
  size?: AvatarSize;
  circle?: boolean;
  style?: React.CSSProperties;
  onAttachment?: (attachment: Attachment | null) => Promise<void>;
  disabled?: boolean;
}

export const OrganisationAvatar = ({
  organisationAvatar,
  size = "m",
  circle = false,
  style,
  onAttachment,
  disabled,
}: Props) => {
  return (
    <Avatar
      url={organisationAvatar.logo.url}
      initials={organisationAvatar.title.substring(0, 2)}
      onAttachment={onAttachment}
      disabled={disabled}
      size={size}
      type={circle ? "circle" : "square"}
      style={style}
    />
  );
};
