import { notNull } from "@msys/common";
import { ModalOpenButton, useFormatting } from "@msys/ui";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { EntityNumber } from "../../../commons/EntityNumber.js";
import {
  DocumentDataItem,
  DocumentPreviewHeader,
} from "../../documents/DocumentPreviewHeader.js";
import {
  QuotePreviewHeader__ProjectFragment,
  QuotePreviewHeader__QuoteFragment,
} from "./QuotePreviewHeader.generated.js";
import { QuoteDocumentActorsModal } from "../modals/QuoteDocumentActorsModal.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../../auth/RestrictedByDocumentPermission.js";

interface QuotePreviewHeaderProps {
  // project: QuotePreviewHeader__ProjectFragment;
  quote: QuotePreviewHeader__QuoteFragment;
  canEdit: boolean;
}

export const QuotePreviewHeader = ({
  quote,
  // project,
  canEdit,
}: QuotePreviewHeaderProps) => {
  const { t } = useTranslate("Quote");
  const { getFormattedDate } = useFormatting();

  const contractee = React.useMemo(() => {
    return {
      organisationName:
        quote.contractee.companyOrIndividual === "COMPANY"
          ? quote.contractee.companyName
          : `${quote.contractee.firstname} ${quote.contractee.familyname}`,
      contactName: quote.contractee.contact?.fullname ?? null,
      address: quote.contractee.address ?? null,
    };
  }, [quote.contractee]);

  const contractor = React.useMemo(
    () => ({
      organisationName: quote.contractor.companyName,
      contactName: quote.contractor.contact?.fullname ?? null,
      contactPhones: quote.contractor.phone
        ? [{ number: quote.contractor.phone }]
        : null,
      contactEmail: quote.contractor.email ?? null,
      address: quote.contractor.address ?? null,
    }),
    [
      quote.contractor.companyName,
      quote.contractor.contact?.fullname,
      quote.contractor.phone,
      quote.contractor.email,
      quote.contractor.address,
    ]
  );

  const externalIdentifier = quote.externalIdentifier ?? quote.xId;

  const documentData = React.useMemo(
    (): DocumentDataItem[] =>
      [
        quote.headerVisibility.isProjectNumberVisible
          ? {
              label: t("Project No"),
              value: (
                <EntityNumber noLabel number={quote.originalProjectNumber} />
              ),
            }
          : null,
        quote.headerVisibility.isClientNumberVisible
          ? {
              label: t("Client No"),
              value: quote.contractee.identifier ? (
                <EntityNumber noLabel number={quote.contractee.identifier} />
              ) : (
                ""
              ),
            }
          : null,
        quote.headerVisibility.isDocumentNumberVisible
          ? {
              label: t("Quote No"),
              value: <EntityNumber noLabel number={quote.number} />,
            }
          : null,
        quote.headerVisibility.isExternalIdentifierVisible && externalIdentifier
          ? {
              label: t("External Identifier"),
              value: <EntityNumber noLabel number={externalIdentifier} />,
            }
          : null,
        quote.headerVisibility.isPublishDateVisible
          ? {
              label: t("Issued on"),
              value: quote.publishedAt
                ? getFormattedDate(quote.publishedAt)
                : "-",
            }
          : null,
      ].filter(notNull),
    [
      quote.headerVisibility.isProjectNumberVisible,
      quote.headerVisibility.isClientNumberVisible,
      quote.headerVisibility.isDocumentNumberVisible,
      quote.headerVisibility.isExternalIdentifierVisible,
      quote.headerVisibility.isPublishDateVisible,
      quote.originalProjectNumber,
      quote.contractee.identifier,
      quote.number,
      quote.publishedAt,
      t,
      externalIdentifier,
      getFormattedDate,
    ]
  );

  return (
    <DocumentPreviewHeader
      recipient={contractee}
      sender={contractor}
      visibility={quote.headerVisibility}
      documentData={documentData}
      recipientActionButtons={
        canEdit &&
        quote.contractee && (
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES_ACTORS"
            document={quote}
          >
            <ModalOpenButton
              Modal={QuoteDocumentActorsModal}
              modalProps={{ projectId: quote.projectId, quoteId: quote.id }}
            >
              <IconButton color="secondary" size="small">
                <EditIcon fontSize="small" />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>
        )
      }
    />
  );
};
