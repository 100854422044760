import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Box } from "@mui/material";
import React from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Agreement,
  PermissionName,
  namedOperations,
} from "../../../clients/graphqlTypes.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { useUserData } from "../../auth/useUserData.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageColumn } from "../../commons/layout/PageColumn.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageHeader } from "../../commons/layout/PageHeader.js";
import { Stack } from "../../commons/layout/Stack.js";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess.js";
import { QUOTE_ITEM_TYPES } from "../../constants.js";
import {
  getViewerDecisionRole,
  isTreePreviewItemVisible,
} from "../../features/doc-items/helpers.js";
import {
  createTreeClientItem,
  createTreeItem,
  createTreeItemInput,
  useWizardButtonVisibility,
} from "../../features/projects/quote-trees.js";
import { QuoteAction } from "../../features/quotes/QuoteAction.js";
import { useQuoteQuickFilters } from "../../features/quotes/QuoteQuickFilters.js";
import { QuoteSubHeader } from "../../features/quotes/QuoteSubHeader.js";
import {
  allowHaveChildren,
  shouldRenderCreateInput,
} from "../../features/quotes/helpers.js";
import { ThreeD } from "../../features/threeD/ThreeD.js";
import { ThreeDEditorRef } from "../../features/threeD/ThreeDEditor.js";
import { VirtualItemTree } from "../../trees-virtual/VirtualItemTree.js";
import { VirtualBareTreeStandaloneItem } from "../../trees-virtual/components/VirtualBareTreeStandaloneItem.js";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../trees-virtual/hooks/useExpandedStore.js";
import { TreeViewMode } from "../../trees-virtual/types.js";
import { TreeToggleAllExpandedButton } from "../../trees/components/TreeToggleButton.js";
import { falseFn, nullFn } from "../../utils.js";
import {
  ProjectQuoteThreeD_ItemFragment,
  useProjectQuoteThreeDQuery,
} from "./ProjectQuoteThreeD.generated.js";
import { useHasSapS4HanaIntegration } from "../../features/sap-s4-hana/hooks.js";
import { useCreateQuoteItems } from "./ProjectQuoteEdit.js";

const REFETCH_QUERIES = [namedOperations.Query.ProjectQuoteEdit];

interface Props {
  projectId: string;
  quoteId: string;
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export function ProjectQuoteThreeD({
  projectId,
  quoteId,
  submenuItems,
  prefixBreadcrumbs,
  activeSubmenuItem,
}: Props) {
  const [searchParams] = useSearchParams();
  const returnPath = searchParams.get("r");
  const { itemId: selectedItemId = null } = useParams<{
    projectId: string;
    quoteId: string;
    itemId?: string;
  }>();
  const navigate = useNavigate();
  const viewer = useUserData().currentUser!;

  const threeDEditorRef = React.useRef<ThreeDEditorRef>(null);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const isClientVisibilityEnabled = useFeature("ClientVisibility");

  const { isMinDesktop, isMinTablet } = useScreenWidth();

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote",
    quoteId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useProjectQuoteThreeDQuery({
    client,
    variables: {
      projectId,
      quoteId,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const doc = getDataOrNull(query.data?.quote)?.quote;
  const docTitle = doc?.title ?? "";

  const pathToDocList = `/projects/${projectId}/quotes`;
  const pathToDoc = `/projects/${projectId}/quotes/${quoteId}`;
  const pathToDocPage = `${pathToDoc}/3d`;

  const isAgreed =
    (doc?.isPublished && doc?.isBinding && doc?.agreement === "YES") ?? false;
  const isViewersQuote =
    doc?.owningSystemOrganisationId === viewer.organisation.id;

  const enableCreating = isViewersQuote ? true : false;
  const enableDragging = isViewersQuote ? !isAgreed : false;

  const [QuickFiltersTabs, quickFilter] =
    useQuoteQuickFilters<ProjectQuoteThreeD_ItemFragment>(
      doc?.agreement === "YES"
    );

  const [filteredTreeItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!doc) return [[], undefined, []];
    const allDocItems = doc.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems?.filter(
      item => !item.isAnyParentItemEliminated
    );
    const filteredItems = quickFilter(treeItems);
    return [filteredItems, rootItem, allDocItems];
  }, [doc, quickFilter]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  const isRootSelected = selectedItemId === rootItem?.id;

  const navigateToItem = React.useCallback(
    (id: string) => navigate(`${pathToDocPage}/items/${id}`, { replace: true }),
    [pathToDocPage, navigate]
  );

  const quote = getDataOrNull(query.data?.quote)?.quote;

  const viewerPermissions: PermissionName[] = React.useMemo(
    () => quote?.viewerPermissions ?? [],
    [quote?.viewerPermissions]
  );
  const hasSapS4HanaIntegration = useHasSapS4HanaIntegration();

  const agreement: Agreement | undefined = doc?.agreement;

  const viewerDecisionRole = getViewerDecisionRole(doc?.actors ?? []);

  const {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  } = useCreateQuoteItems({
    projectId,
    quoteId,
    expandedItemIds: expandedStore.expandedItemIds,
  });

  const { filterButton, visibleButtons: visibleWizardButtons } =
    useWizardButtonVisibility();

  const TreeItem = React.useMemo(() => {
    if (!quote?.id || agreement === undefined) return nullFn;

    return isViewersQuote
      ? createTreeItem({
          pathToDocPage,
          projectId,
          doc: {
            id: quote.id,
            rootItemId: quote?.rootItem.id,
            viewerPermissions,
          },
          docAgreement: agreement,
          startConfirmProcess,
          expandedItemIds: expandedStore.expandedItemIds,
          setItemExpanded: expandedStore.setItemExpanded,
          pasteItem,
          isAllowedToPasteItem,
          viewerDecisionRole,
          onAdditionalItemInputChange: null,
          visibleWizardButtons,
          isNewRefinementWizardEnabled:
            viewer.organisation.capabilities.includes("NEW_REFINEMENT_WIZARD"),
        })
      : createTreeClientItem({
          projectId,
          doc: {
            id: quote.id,
            rootItemId: quote?.rootItem.id,
            viewerPermissions,
          },
          docAgreement: agreement,
          expandedItemIds: expandedStore.expandedItemIds,
        });
  }, [
    quote?.id,
    quote?.rootItem.id,
    agreement,
    isViewersQuote,
    pathToDocPage,
    projectId,
    viewerPermissions,
    startConfirmProcess,
    expandedStore.expandedItemIds,
    expandedStore.setItemExpanded,
    pasteItem,
    isAllowedToPasteItem,
    viewerDecisionRole,
    visibleWizardButtons,
    viewer.organisation.capabilities,
  ]);

  const TreeItemInput = React.useMemo(
    () =>
      createTreeItemInput({
        viewMode: TreeViewMode.Item,
        quoteId,
        createItem,
        createItemByType,
        createItemFromTemplate,
        createItemsFromTemplates,
        createItemsWithProducts,
        pasteItem,
        isAllowedToPasteItem,
      }),
    [
      createItem,
      createItemByType,
      createItemFromTemplate,
      createItemsFromTemplates,
      createItemsWithProducts,
      isAllowedToPasteItem,
      pasteItem,
      quoteId,
    ]
  );

  // redirect to edit route when selected item is filtered out
  React.useEffect(() => {
    if (
      !query.loading &&
      selectedItemId &&
      !filteredTreeItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredTreeItems,
    pathToDocPage,
    rootItem,
    navigate,
    query.loading,
  ]);

  const filterItem = React.useCallback(
    (i: ProjectQuoteThreeD_ItemFragment) =>
      isTreePreviewItemVisible(true, i, allDocItems, doc?.isBinding ?? true),
    [allDocItems, doc?.isBinding]
  );

  return (
    <Page
      subtitle={project?.title}
      title={docTitle}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      header={
        <PageHeader
          breadcrumbs={prefixBreadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && doc ? (
          <QuoteSubHeader
            project={project}
            quote={doc}
            activeView="3d"
            canEdit={isViewersQuote}
            isReadOnly={isAgreed}
            pathToDocList={pathToDocList}
            pathToDoc={pathToDoc}
            hasSapS4HanaIntegration={hasSapS4HanaIntegration}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
            expandedItemIds={expandedStore.expandedItemIds}
          />
        ) : undefined
      }
      action={
        project &&
        doc && (
          <QuoteAction
            doc={doc}
            project={project}
            onDiscardPendingChangesRefetchQueries={REFETCH_QUERIES}
            onDeclineChangesRefetchQueries={REFETCH_QUERIES}
            viewerDecisionRole={viewerDecisionRole}
            expandedItemIds={expandedStore.expandedItemIds}
          />
        )
      }
    >
      {isMinDesktop && (
        <PageColumn
          $hasRightBorder
          $hasBackground
          $display="block"
          ref={isMinTablet ? setTreeContainer : undefined}
        >
          {doc && project && rootItem && (
            <Stack flexDirection={"column"} spacing={2}>
              <Stack width="100%" alignItems="center" spacing={1}>
                <TreeToggleAllExpandedButton
                  areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                  setAllItemsExpanded={expandedStore.setAllItemsExpanded}
                />
                <QuickFiltersTabs />
                {isViewersQuote ? filterButton : null}
              </Stack>

              <Box>
                <VirtualBareTreeStandaloneItem
                  item={rootItem}
                  items={filteredTreeItems}
                  allItems={allDocItems}
                  depth={0}
                  itemComponent={TreeItem}
                  selected={isRootSelected}
                  sticky={isRootSelected}
                  top={0}
                  clickable
                  onClick={() => navigateToItem(rootItem.id)}
                  to={`${pathToDocPage}/items/${rootItem.id}`}
                />

                <VirtualItemTree<
                  ProjectQuoteThreeD_ItemFragment,
                  typeof enableDragging
                >
                  docId={enableDragging ? doc.id : null}
                  projectId={enableDragging ? projectId : null}
                  items={filteredTreeItems}
                  allItems={allDocItems}
                  filterItem={
                    isClientVisibilityEnabled || !isViewersQuote
                      ? filterItem
                      : undefined
                  }
                  selectedItemId={selectedItemId}
                  enableCreating={enableCreating}
                  enableDragging={enableDragging}
                  documentItemTypes={QUOTE_ITEM_TYPES}
                  container={treeContainer}
                  itemComponent={TreeItem}
                  inputComponent={isViewersQuote ? TreeItemInput : nullFn}
                  allowHaveChildren={
                    isViewersQuote ? allowHaveChildren : falseFn
                  }
                  shouldRenderCreateInput={
                    isViewersQuote ? shouldRenderCreateInput : falseFn
                  }
                  expandedStore={expandedStore}
                />
              </Box>
            </Stack>
          )}
        </PageColumn>
      )}
      <ConfirmProcess ref={confirmProcessRef} />

      <Outlet
        context={{
          expandedItemIds: expandedStore.expandedItemIds,
          CreateChildComponent: TreeItemInput,
          pasteItem,
          isAllowedToPasteItem,
          viewerDecisionRole,
        }}
      />

      <PageContainer $fullHeight $noPadding>
        <ThreeD
          threeDEditorRef={threeDEditorRef}
          projectId={projectId}
          docId={quoteId}
          returnPath={returnPath ?? pathToDoc}
          allDocItems={allDocItems}
          isEditable={isMinDesktop}
        />
      </PageContainer>
    </Page>
  );
}
