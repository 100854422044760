import { MenuItemWithIcon } from "@msys/ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FileMoveIcon } from "../../../assets/icons/icon-file-move.svg";
import { useTranslate } from "@tolgee/react";
import { useRequestQuoteForOutsourcing } from "../../requests/buttons/RequestQuoteForOutsourcingButton.js";

export const OutsourceMenuItem = ({
  docId,
  projectId,
  itemId,
  itemTitle,
  disabled,
}: {
  docId: string;
  projectId: string;
  itemId: string;
  itemTitle?: string;
  itemDescription?: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslate("QuoteItem");
  const navigate = useNavigate();

  const [fn, loading] = useRequestQuoteForOutsourcing({
    projectId,
  });

  return (
    <MenuItemWithIcon
      icon={
        <FileMoveIcon style={{ width: "22px", flexShrink: 0, flexGrow: 0 }} />
      }
      disabled={disabled || loading}
      onClick={async () => {
        const { requestId } = await fn({
          quoteId: docId,
          itemId,
          itemTitle: itemTitle ?? "",
        });
        navigate(`/projects/${projectId}/requests/${requestId}`);
      }}
    >
      {t("Do subcontract")}
    </MenuItemWithIcon>
  );

  // return (
  //   <RequestQuoteForQutsourcingButton
  //     projectId={projectId}
  //     originQuote={{
  //       quoteId: docId,
  //       itemId,
  //       itemTitle: itemTitle ?? "",
  //       quoteTitle: "",
  //     }}
  //   />
  // );

  // return (
  //   <ModalOpenButton
  //     Modal={RequestCreateFlowModal}
  //     modalProps={{
  //       projectId,
  //       originQuoteId: docId,
  //       originQuoteItem: { id: itemId, title: itemTitle ?? "" },
  //       handleComplete: requestId => {
  //         navigate(`/projects/${projectId}/requests/${requestId}`);
  //       },
  //     }}
  //   >
  //     <MenuItemWithIcon
  //       icon={<FileMoveIcon style={{ width: "22px", flexShrink: 0 }} />}
  //       disabled={disabled}
  //     >
  //       {t("Do subcontract")}
  //     </MenuItemWithIcon>
  //   </ModalOpenButton>
  // );
};
