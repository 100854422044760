/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanyEditBranchAddressModalQueryVariables = Types.Exact<{
  organisationId: Types.Scalars['ID']['input'];
}>;


export type CrmCompanyEditBranchAddressModalQuery = { crmCompany: { __typename: 'CrmCompanyResult', crmCompany?: { __typename: 'CrmCompany', id: string, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } | null } | { __typename: 'MissingPermissions' } };

export type CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutationVariables = Types.Exact<{
  crmCompanies: Array<Types.CrmOrganisationModifyInput>;
}>;


export type CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null }> } };


export const CrmCompanyEditBranchAddressModalDocument = gql`
    query CrmCompanyEditBranchAddressModal($organisationId: ID!) {
  crmCompany(id: $organisationId) {
    ... on CrmCompanyResult {
      crmCompany {
        id
        branchAddress {
          ...AddressDetails__Address
        }
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;

/**
 * __useCrmCompanyEditBranchAddressModalQuery__
 *
 * To run a query within a React component, call `useCrmCompanyEditBranchAddressModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanyEditBranchAddressModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCompanyEditBranchAddressModalQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useCrmCompanyEditBranchAddressModalQuery(baseOptions: Apollo.QueryHookOptions<CrmCompanyEditBranchAddressModalQuery, CrmCompanyEditBranchAddressModalQueryVariables> & ({ variables: CrmCompanyEditBranchAddressModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCompanyEditBranchAddressModalQuery, CrmCompanyEditBranchAddressModalQueryVariables>(CrmCompanyEditBranchAddressModalDocument, options);
      }
export function useCrmCompanyEditBranchAddressModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCompanyEditBranchAddressModalQuery, CrmCompanyEditBranchAddressModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCompanyEditBranchAddressModalQuery, CrmCompanyEditBranchAddressModalQueryVariables>(CrmCompanyEditBranchAddressModalDocument, options);
        }
export function useCrmCompanyEditBranchAddressModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CrmCompanyEditBranchAddressModalQuery, CrmCompanyEditBranchAddressModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCompanyEditBranchAddressModalQuery, CrmCompanyEditBranchAddressModalQueryVariables>(CrmCompanyEditBranchAddressModalDocument, options);
        }
export type CrmCompanyEditBranchAddressModalQueryHookResult = ReturnType<typeof useCrmCompanyEditBranchAddressModalQuery>;
export type CrmCompanyEditBranchAddressModalLazyQueryHookResult = ReturnType<typeof useCrmCompanyEditBranchAddressModalLazyQuery>;
export type CrmCompanyEditBranchAddressModalSuspenseQueryHookResult = ReturnType<typeof useCrmCompanyEditBranchAddressModalSuspenseQuery>;
export type CrmCompanyEditBranchAddressModalQueryResult = Apollo.QueryResult<CrmCompanyEditBranchAddressModalQuery, CrmCompanyEditBranchAddressModalQueryVariables>;
export const CrmCompanyEditBranchAddressModal_ModifyCrmCompanyDocument = gql`
    mutation CrmCompanyEditBranchAddressModal_ModifyCrmCompany($crmCompanies: [CrmOrganisationModifyInput!]!) {
  modifyCrmOrganisation(input: $crmCompanies) {
    crmOrganisations {
      id
      branchAddress {
        ...AddressDetails__Address
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export type CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutationFn = Apollo.MutationFunction<CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation, CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutationVariables>;

/**
 * __useCrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation__
 *
 * To run a mutation, you first call `useCrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmCompanyEditBranchAddressModalModifyCrmCompanyMutation, { data, loading, error }] = useCrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation({
 *   variables: {
 *      crmCompanies: // value for 'crmCompanies'
 *   },
 * });
 */
export function useCrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation, CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation, CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutationVariables>(CrmCompanyEditBranchAddressModal_ModifyCrmCompanyDocument, options);
      }
export type CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutationHookResult = ReturnType<typeof useCrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation>;
export type CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutationResult = Apollo.MutationResult<CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation>;
export type CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutationOptions = Apollo.BaseMutationOptions<CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation, CrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutationVariables>;