import React from "react";
import { ConfirmModalProps } from "../../commons/modals/ConfirmProcess.js";
import { BUILDING_ITEM_TYPES } from "../../constants.js";
import { AdditionalItemInput, VirtualItem } from "../../trees-virtual/types.js";
import { BareTreeItem } from "../../trees/BareTreeItem.js";
import { InputComponentProps, ItemComponentProps } from "../../trees/types.js";
import { CreateItemInput } from "../doc-items/CreateItemInput.js";
import { DocItemContextMenuItems } from "../doc-items/menus/DocItemContextMenuItems.js";
import { BuildingTreeItemFragment } from "./trees.generated.js";

export function createTreeItemInput<
  Item extends VirtualItem,
  ChildItem extends VirtualItem,
>({
  docId,
  createItem,
  createItemByType,
  createItemFromTemplate,
  createItemsFromTemplates,
  createItemsWithProducts,
  pasteItem,
  isAllowedToPasteItem,
}: { docId: string } & Pick<
  React.ComponentProps<typeof CreateItemInput>,
  | "createItem"
  | "createItemByType"
  | "createItemFromTemplate"
  | "createItemsFromTemplates"
  | "createItemsWithProducts"
  | "pasteItem"
  | "isAllowedToPasteItem"
>) {
  return (itemProps: InputComponentProps<Item, ChildItem>) => (
    <CreateItemInput
      doc={{ id: docId, docType: "BUILDING", templateIsDraft: false }}
      documentItemTypes={BUILDING_ITEM_TYPES}
      createItem={createItem}
      createItemByType={createItemByType}
      createItemFromTemplate={createItemFromTemplate}
      createItemsFromTemplates={createItemsFromTemplates}
      createItemsWithProducts={createItemsWithProducts}
      pasteItem={pasteItem}
      isAllowedToPasteItem={isAllowedToPasteItem}
      {...itemProps}
    />
  );
}

export const createTreeItem =
  ({
    pathToDocPage,
    docId,
    startConfirmProcess,
    navigateToItem,
    setItemExpanded,
    expandedItemIds,
    pasteItem,
    isAllowedToPasteItem,
    onAdditionalItemInputChange,
  }: {
    pathToDocPage: string;
    docId: string;
    startConfirmProcess: (
      props: ConfirmModalProps
    ) => Promise<boolean | undefined>;
    navigateToItem: (id: string) => void;
    setItemExpanded: (id: string, expanded: boolean) => void;
    expandedItemIds: string[] | undefined;
    pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
    isAllowedToPasteItem: (parentItem: BuildingTreeItemFragment) => boolean;
    onAdditionalItemInputChange: ((input: AdditionalItemInput) => void) | null;
  }) =>
  (itemProps: ItemComponentProps<BuildingTreeItemFragment>) => {
    const ContextMenu = React.useMemo(
      () => (
        <DocItemContextMenuItems
          pathToDocPage={pathToDocPage}
          docId={docId}
          docType="BUILDING"
          projectId={null}
          item={itemProps.item}
          itemParentType={itemProps.parentItem?.type ?? null}
          startConfirmProcess={startConfirmProcess}
          expandedItemIds={expandedItemIds}
          pasteItem={isAllowedToPasteItem(itemProps.item) ? pasteItem : null}
          onAdditionalItemInputChange={onAdditionalItemInputChange}
        />
      ),
      [itemProps.item, itemProps.parentItem?.type]
    );

    return (
      <BareTreeItem
        docAgreement={"NONE"}
        ContextMenu={ContextMenu}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        collapseButton={itemProps.collapseButton}
        subcontractPath={null}
        onClick={() => {
          setItemExpanded(itemProps.item.id, true);
        }}
        to={`${pathToDocPage}/items/${itemProps.item.id}`}
      />
    );
  };
