/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateRecomputeExpressionDependencyGraphMutationVariables = Types.Exact<{
  input: Types.DocumentRecomputePropertiesAndExpressionsInput;
}>;


export type TemplateRecomputeExpressionDependencyGraphMutation = { documentRecomputePropertiesAndExpressions: { __typename: 'DocumentRecomputePropertiesAndExpressionsResult' } };


export const TemplateRecomputeExpressionDependencyGraphDocument = gql`
    mutation TemplateRecomputeExpressionDependencyGraph($input: DocumentRecomputePropertiesAndExpressionsInput!) {
  documentRecomputePropertiesAndExpressions(input: $input) {
    __typename
  }
}
    `;
export type TemplateRecomputeExpressionDependencyGraphMutationFn = Apollo.MutationFunction<TemplateRecomputeExpressionDependencyGraphMutation, TemplateRecomputeExpressionDependencyGraphMutationVariables>;

/**
 * __useTemplateRecomputeExpressionDependencyGraphMutation__
 *
 * To run a mutation, you first call `useTemplateRecomputeExpressionDependencyGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateRecomputeExpressionDependencyGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateRecomputeExpressionDependencyGraphMutation, { data, loading, error }] = useTemplateRecomputeExpressionDependencyGraphMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTemplateRecomputeExpressionDependencyGraphMutation(baseOptions?: Apollo.MutationHookOptions<TemplateRecomputeExpressionDependencyGraphMutation, TemplateRecomputeExpressionDependencyGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TemplateRecomputeExpressionDependencyGraphMutation, TemplateRecomputeExpressionDependencyGraphMutationVariables>(TemplateRecomputeExpressionDependencyGraphDocument, options);
      }
export type TemplateRecomputeExpressionDependencyGraphMutationHookResult = ReturnType<typeof useTemplateRecomputeExpressionDependencyGraphMutation>;
export type TemplateRecomputeExpressionDependencyGraphMutationResult = Apollo.MutationResult<TemplateRecomputeExpressionDependencyGraphMutation>;
export type TemplateRecomputeExpressionDependencyGraphMutationOptions = Apollo.BaseMutationOptions<TemplateRecomputeExpressionDependencyGraphMutation, TemplateRecomputeExpressionDependencyGraphMutationVariables>;