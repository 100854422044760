import { ModalOpenButton } from "@msys/ui";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { merge } from "lodash-es";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Agreement, PermissionName } from "../../../clients/graphqlTypes.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission.js";
import { useUserData } from "../../auth/useUserData.js";
import { ViewMode } from "../../commons/ViewModeMenuItem.js";
import { ManualSave } from "../../commons/form-fields/ManualSave.js";
import { QUOTE_ITEM_TYPES } from "../../constants.js";
import { BareTreeItem } from "../../trees/BareTreeItem.js";
import { TreeRightIcons } from "../../trees/components/TreeRightIcons.js";
import { ContentBox } from "../contents/ContentBox.js";
import { CustomFieldsBox } from "../custom-fields/CustomFieldsBox.js";
import { EditCustomFieldModal } from "../custom-fields/EditCustomFieldModal.js";
import { itemHasEstimatedCalculationBox } from "../doc-items/app-conditions/boxes.js";
import { BuildingPlacementBox } from "../doc-items/boxes/BuildingPlacementBox.js";
import { DecisionManagementBox } from "../doc-items/boxes/DecisionManagementBox.js";
import {
  EstimatedCalculationBox,
  FormValues,
} from "../doc-items/boxes/EstimatedCalculationBox.js";
import { HeaderBox } from "../doc-items/boxes/HeaderBox.js";
import { ItemFilesBox } from "../doc-items/boxes/ItemFilesBox.js";
import { ProductBox } from "../doc-items/boxes/ProductBox.js";
import { RecommendationsBox } from "../doc-items/boxes/RecommendationsBox.js";
import { RequiredOnCompletionBox } from "../doc-items/boxes/RequiredOnCompletionBox.js";
import {
  SubelementsBox,
  SubitemComponentProps,
} from "../doc-items/boxes/SubelementsBox.js";
import { TaskManagementBox } from "../doc-items/boxes/TaskManagementBox.js";
import { TemplateTypePlaceholderBox } from "../doc-items/boxes/TemplateTypePlaceholderBox.js";
import { PendingChangesBox } from "../doc-items/boxes/debug/PendingChangesBox.js";
import { useProposedCalculationsWithOverride } from "../doc-items/useProposedCalculationsWithOverride.js";
import { getData123 } from "../projects/quote-trees.js";
import { RelatedOrderItemsBox } from "../purchase-orders/boxes/RelatedOrderItemsBox.js";
import { S4HanaQuoteItemDataBox } from "../sap-s4-hana/S4HanaQuoteItemDataBox.js";
import {
  QuoteItem_ChildItemComponentFragment,
  QuoteItem_ChildItemFragment,
  QuoteItem_ItemFragment,
  QuoteItem_ProjectFragment,
  QuoteItem_QuoteFragment,
} from "./QuoteItem.generated.js";
import { RulesBox } from "../doc-items/boxes/RulesBox.js";

interface Props {
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  isManualSave: boolean;
  project: QuoteItem_ProjectFragment;
  quote: QuoteItem_QuoteFragment;
  item: QuoteItem_ItemFragment;
  pathToDocList: string;
  pathToDocPage: string;
  onChangeCustomFieldsRefetchQueries: string[];
  onSetContentRefetchQueries: string[];
  onUpdateDataRefetchQueries?: string[];
  canEdit: boolean;
  expandedItemIds: string[] | undefined;
  hasSapS4HanaIntegration: boolean;
}

export const QuoteItem = ({
  viewMode,
  setViewMode,
  isManualSave,
  project,
  quote,
  item,
  onChangeCustomFieldsRefetchQueries,
  onSetContentRefetchQueries,
  onUpdateDataRefetchQueries,
  pathToDocPage,
  canEdit,
  expandedItemIds,
  hasSapS4HanaIntegration,
}: Props) => {
  const navigate = useNavigate();
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("QuoteItem");
  const showDebugOutput = useFeature("DebugOutput");

  const canManualSave =
    item.type === "paid" || item.type === "section" || item.type === "decision";

  const hasEstimatedCalculationBox = itemHasEstimatedCalculationBox(item);
  const isEliminated =
    Boolean(item.decisionOptionElimination) || item.isItemEliminated;
  const agreement: Agreement | undefined = quote?.agreement;

  const ChildItemComponent = React.useMemo(
    () =>
      agreement !== undefined
        ? createChildItemComponent(
            pathToDocPage,
            {
              viewerPermissions: quote?.viewerPermissions ?? [],
            },
            agreement
          )
        : () => null,
    [agreement, pathToDocPage, quote?.viewerPermissions]
  );

  return (
    <Stack direction="column" spacing={1}>
      {showDebugOutput && (
        <>
          <PendingChangesBox
            itemId={item.id}
            docId={quote.id}
            projectId={project.id}
          />
        </>
      )}

      <HeaderBox
        projectId={project.id}
        docId={quote.id}
        docType="QUOTE"
        item={item}
        viewMode={canEdit ? viewMode : null}
        setViewMode={setViewMode}
        isManualSave={canManualSave && isManualSave}
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />

      {!item.isRootItem && item.type !== "unpaid" && (
        <RulesBox
          projectId={project.id}
          docId={quote.id}
          item={item}
          canEdit={canEdit}
        />
      )}

      {item.type === "paid" && !isEliminated && (
        <ProductBox
          project={project}
          docId={quote.id}
          docType="QUOTE"
          item={item}
          isEditable={canEdit}
          viewerIsContractor={quote.contractor.isMyOrganisation}
          onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
        />
      )}

      {!isEliminated && (
        <CustomFieldsBox
          source={item}
          objectType={"QuoteItem"}
          actionButton={
            canEdit ? (
              <ModalOpenButton
                Modal={EditCustomFieldModal}
                modalProps={{
                  title: t("Edit quote item custom fields"),
                  objectId: item.id,
                  objectType: "QuoteItem",
                  customFields: item.customFields,
                  refetchQueries: onChangeCustomFieldsRefetchQueries,
                }}
              >
                <IconButton color="secondary" size="small">
                  <EditIcon />
                </IconButton>
              </ModalOpenButton>
            ) : undefined
          }
        />
      )}

      {hasEstimatedCalculationBox && !isEliminated && (
        <QuoteItemEstimatedCalculationBox
          key={`${item.product?.articleNumber}-${item.product?.supplierId}-${item.product?.title}`}
          projectId={project.id}
          docId={quote.id}
          item={item}
          isReadOnly={!canEdit}
          isManualSave={canManualSave && isManualSave}
          contractType={quote.contractType}
        />
      )}

      {hasSapS4HanaIntegration && (
        <S4HanaQuoteItemDataBox
          projectId={project.id}
          quoteId={quote.id}
          itemId={item.id}
          docViewerPermissions={quote.viewerPermissions}
          expandedItemIds={expandedItemIds}
          canEdit={canEdit}
        />
      )}

      {item.type === "decision" && !isEliminated && (
        <DecisionManagementBox
          item={item}
          doc={{
            id: quote.id,
            docType: "QUOTE",
            templateIsDraft: false,
            organisationId: quote.owningSystemOrganisationId,
          }}
          projectId={project.id}
          isEditable={canEdit}
          onItemDeleted={() => {
            navigate(pathToDocPage, { replace: true });
          }}
        />
      )}

      {!isEliminated && (
        <RestrictedByCapabilityWithDebug capability="EXECUTING">
          <RestrictedByDocumentPermissionWithDebug
            permission="EXECUTE_TASK"
            document={quote}
          >
            <TaskManagementBox
              project={project}
              docId={quote.id}
              document={quote}
              item={item}
              canEdit={true}
            />
          </RestrictedByDocumentPermissionWithDebug>
        </RestrictedByCapabilityWithDebug>
      )}

      {!isEliminated && (
        <RestrictedByCapabilityWithDebug capability="EXECUTING">
          <RequiredOnCompletionBox
            project={project}
            docId={quote.id}
            document={quote}
            item={item}
            canEdit={true}
          />
        </RestrictedByCapabilityWithDebug>
      )}

      {!isEliminated && viewer.organisation.isCraftsmanOrganisation && (
        <RestrictedByCapabilityWithDebug capability="ORDERING">
          <RelatedOrderItemsBox
            orderItems={item.orderItems}
            project={project}
            document={quote}
          />
        </RestrictedByCapabilityWithDebug>
      )}

      {!isEliminated && (
        <ItemFilesBox
          projectId={project.id}
          docId={quote.id}
          docType="QUOTE"
          viewerPermissions={quote.viewerPermissions}
          item={item}
          onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
        />
      )}

      {!isEliminated && (
        <SubelementsBox<QuoteItem_ChildItemFragment>
          projectId={project.id}
          doc={{ id: quote.id, docType: "QUOTE", templateIsDraft: false }}
          item={item}
          subitemComponent={ChildItemComponent}
          pathToDocPage={pathToDocPage}
          documentItemTypes={QUOTE_ITEM_TYPES}
          canChangeVisibility={true}
        />
      )}

      {!isEliminated && item.recommendations.length > 0 && (
        <RestrictedByCapabilityWithDebug capability="TEMPLATING">
          <RestrictedByOrganisationPermissionWithDebug permission="APPLY_TEMPLATE">
            <RecommendationsBox
              projectId={project.id}
              item={item}
              documentItemTypes={QUOTE_ITEM_TYPES}
              expandedItemIds={expandedItemIds}
              onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        </RestrictedByCapabilityWithDebug>
      )}

      {!isEliminated &&
        !(project.state === "opportunity") &&
        item.placement && (
          <BuildingPlacementBox
            project={project}
            docId={quote.id}
            item={item}
          />
        )}

      {!isEliminated && item.contents.length > 0 && (
        <ContentBox
          itemId={item.id}
          content={item.contents}
          refetchQueries={onSetContentRefetchQueries}
          isEditable
        />
      )}

      {!isEliminated && (
        <RestrictedByCapabilityWithDebug capability="TEMPLATING">
          <RestrictedByOrganisationPermissionWithDebug permission="APPLY_TEMPLATE">
            <TemplateTypePlaceholderBox
              projectId={project.id}
              doc={quote}
              item={item}
              isEditable={canEdit}
              onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        </RestrictedByCapabilityWithDebug>
      )}
    </Stack>
  );
};

const createChildItemComponent =
  <ChildItem extends QuoteItem_ChildItemComponentFragment>(
    pathToDocPage: string,
    doc: {
      viewerPermissions: PermissionName[];
    },
    docAgreement: Agreement
  ) =>
  (itemProps: SubitemComponentProps<ChildItem>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item, doc),
      [itemProps.item]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={doc}
          item={itemProps.item}
          isVisibleToOtherSide={itemProps.item.isVisibleToOtherSide}
        />
      ),
      [itemProps.item]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        RightIcons={RightIcons}
        showPath={!itemProps.item.isDecisionOption}
        item={itemProps.item}
        title={
          itemProps.item.pendingChangeAttributes?.title ?? itemProps.item.title
        }
        pathForPdf={
          itemProps.item.pendingChangeAttributes?.pathForPdf ??
          itemProps.item.pathForPdf
        }
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        subcontractPath={itemProps.subcontractPath}
        onClick={itemProps.onClick}
        to={
          itemProps.to !== undefined
            ? itemProps.to
            : `${pathToDocPage}/items/${itemProps.item.id}`
        }
      />
    );
  };

const QuoteItemEstimatedCalculationBox = ({
  projectId,
  docId,
  item,
  isReadOnly,
  isManualSave,
  ...props
}: Omit<
  React.ComponentProps<typeof EstimatedCalculationBox>,
  "projectId" | "manualSave" | "setIsEditing"
> & {
  projectId: string;
  isManualSave: boolean;
}) => {
  const { values, onValuesChanged, reset } =
    useProposedCalculationsWithOverride({
      projectId,
      docId,
      itemId: item.id,
    });

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const manualSaveProps = React.useMemo(
    () =>
      isManualSave
        ? {
            manualSave: (
              <ManualSave
                onCancel={() => {
                  setIsEditing(false);
                  reset();
                }}
                onSubmitted={() => {
                  setIsEditing(false);
                  reset();
                }}
                onChange={(values: FormValues) => {
                  const override =
                    "materialBuyingPrice" in values
                      ? {
                          quantity: values.estimatedQuantity,
                          materialBuyingPrice: values.materialBuyingPrice,
                          materialMargin: values.materialMargin / 100,
                          time: values.estimatedTime,
                          workSellingRate: values.workSellingRate,
                          workBuyingRate: values.workBuyingRate,
                        }
                      : { quantity: values.estimatedQuantity };
                  onValuesChanged({ itemId: item.id, ...override });
                }}
              />
            ),
            setIsEditing,
          }
        : {},
    [isManualSave, item.id, onValuesChanged, reset]
  );

  return (
    <EstimatedCalculationBox
      projectId={projectId}
      docId={docId}
      item={
        isEditing
          ? merge({}, item, {
              proposedCalculation: values,
            })
          : item
      }
      isReadOnly={isReadOnly || (isManualSave && !isEditing)}
      {...manualSaveProps}
      {...props}
    />
  );
};
