import { useApolloClient } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useDeclineChangesMutation } from "../Quotes.generated.js";
import { useTranslate } from "@tolgee/react";
import { Modal } from "@msys/ui";

interface FormValues {
  reason: string;
}

type Props = {
  projectId: string;
  quoteId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: () => Promise<unknown> | unknown;
  refetchQueries?: string[];
};

export const DeclineChangesModal: React.FC<Props> = ({
  projectId,
  quoteId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}) => {
  const { t } = useTranslate(["QuoteDeclineChanges", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [declineChanges] = useDeclineChangesMutation({ client });

  const onSubmit = async (values: FormValues) => {
    await declineChanges({
      variables: {
        input: {
          projectId,
          docId: quoteId,
          reason: values.reason,
        },
      },
      refetchQueries,
    });

    enqueueSnackbar(
      t("Changes declined.", {
        ns: "QuoteDeclineChanges",
      })
    );
    await handleComplete?.();
    handleClose();
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    reason: Yup.string().label(
      t("Reason", {
        ns: "QuoteDeclineChanges",
      })
    ),
  });

  return (
    <Modal
      title={
        title ??
        t("Decline Changes", {
          ns: "QuoteDeclineChanges",
        })
      }
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Decline Changes", {
            ns: "QuoteDeclineChanges",
          }),
          buttonProps: {
            type: "submit",
            form: formId,
          },
        },
      ]}
    >
      <Formik<FormValues>
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={{
          reason: "",
        }}
      >
        <Form id={formId}>
          <Field
            autoFocus
            label={t("Reason", {
              ns: "QuoteDeclineChanges",
            })}
            name="reason"
            multiline
            component={TextField}
            rows={3}
          />
        </Form>
      </Formik>
    </Modal>
  );
};
