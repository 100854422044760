/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutationVariables = Types.Exact<{
  input: Types.GetCsvHeaderInput;
}>;


export type PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation = { getCsvHeader: { __typename: 'GetCsvHeaderResult', headerAndValues: Array<{ __typename: 'HeaderAndValues', header: string, values: string }>, systemLabels: Array<{ __typename: 'SystemLabels', label: string, column: string, disabled: boolean }> } };


export const PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderDocument = gql`
    mutation PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeader($input: GetCsvHeaderInput!) {
  getCsvHeader(input: $input) {
    headerAndValues {
      header
      values
    }
    systemLabels {
      label
      column
      disabled
    }
  }
}
    `;
export type PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutationFn = Apollo.MutationFunction<PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation, PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutationVariables>;

/**
 * __usePimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation__
 *
 * To run a mutation, you first call `usePimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pimSupplierDiscountGroupsImportationColumnMappingModalGetCsvHeaderMutation, { data, loading, error }] = usePimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation(baseOptions?: Apollo.MutationHookOptions<PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation, PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation, PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutationVariables>(PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderDocument, options);
      }
export type PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutationHookResult = ReturnType<typeof usePimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation>;
export type PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutationResult = Apollo.MutationResult<PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation>;
export type PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutationOptions = Apollo.BaseMutationOptions<PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation, PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutationVariables>;