/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../features/addresses/Addresses.generated.js';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../features/attachments/Attachments.generated.js';
import { ProjectOverviewWorkSessionsBox_ProjectFragmentDoc } from '../features/projects/boxes/ProjectOverviewWorkSessionsBox.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstallerProjectOverviewQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type InstallerProjectOverviewQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, ticket: boolean, planned: boolean, overdue: boolean, urgent: boolean, state: Types.ProjectStateMachineStatus, description: string, viewerPermissions: Array<Types.PermissionName>, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string, crmCompany: { __typename: 'CrmCompany', id: string }, phones: Array<{ __typename: 'Phone', id: string, number: string }> } | null } | null, planSessions: Array<{ __typename: 'PlanSession', id: string, from: any, till: any, isTentative: boolean, who: { __typename: 'User', id: string } }>, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> } | null } };


export const InstallerProjectOverviewDocument = gql`
    query InstallerProjectOverview($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        ticket
        planned
        overdue
        urgent
        state
        description
        building {
          id
          buildingAddress {
            ...AddressDetails__Address
          }
        }
        crmOrganisation {
          id
          contactPerson {
            id
            title
            firstname
            familyname
            crmCompany {
              id
            }
            phones {
              id
              number
            }
          }
        }
        planSessions {
          id
          from
          till
          isTentative
          who {
            id
          }
        }
        attachments {
          ...Attachment
        }
        customFields {
          key
          value
          dataType
          group
        }
        ...ProjectOverviewWorkSessionsBox_Project
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentFragmentDoc}
${ProjectOverviewWorkSessionsBox_ProjectFragmentDoc}`;

/**
 * __useInstallerProjectOverviewQuery__
 *
 * To run a query within a React component, call `useInstallerProjectOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallerProjectOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallerProjectOverviewQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useInstallerProjectOverviewQuery(baseOptions: Apollo.QueryHookOptions<InstallerProjectOverviewQuery, InstallerProjectOverviewQueryVariables> & ({ variables: InstallerProjectOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstallerProjectOverviewQuery, InstallerProjectOverviewQueryVariables>(InstallerProjectOverviewDocument, options);
      }
export function useInstallerProjectOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstallerProjectOverviewQuery, InstallerProjectOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstallerProjectOverviewQuery, InstallerProjectOverviewQueryVariables>(InstallerProjectOverviewDocument, options);
        }
export function useInstallerProjectOverviewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InstallerProjectOverviewQuery, InstallerProjectOverviewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InstallerProjectOverviewQuery, InstallerProjectOverviewQueryVariables>(InstallerProjectOverviewDocument, options);
        }
export type InstallerProjectOverviewQueryHookResult = ReturnType<typeof useInstallerProjectOverviewQuery>;
export type InstallerProjectOverviewLazyQueryHookResult = ReturnType<typeof useInstallerProjectOverviewLazyQuery>;
export type InstallerProjectOverviewSuspenseQueryHookResult = ReturnType<typeof useInstallerProjectOverviewSuspenseQuery>;
export type InstallerProjectOverviewQueryResult = Apollo.QueryResult<InstallerProjectOverviewQuery, InstallerProjectOverviewQueryVariables>;