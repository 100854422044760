import React from "react";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../constants.js";
import { useUrlSearchParams } from "../hooks/useUrlSearchParams.js";

interface Args {
  pageUrlParam?: string;
  perPageUrlParam?: string;
  perPageDefaultAmount?: number;
  base?: 0 | 1;
}

export type PaginationModel = { page: number; pageSize: number };

export const usePaginationParams = ({
  pageUrlParam = "page",
  perPageUrlParam = "pageSize",
  perPageDefaultAmount = PAGE_LIST_RESULTS_PER_PAGE,
  base = 0,
}: Args = {}): {
  limit: number;
  offset: number;
  page: number;
  perPage: number;
  paginationModel: PaginationModel;
  getPageSearchParams: (args: {
    page: number;
    perPage?: number;
  }) => URLSearchParams;
  setPage: (newPage: number) => void;
  setPerPage: (newPerPage: number) => void;
  setPaginationModel: (newPaginationModel: PaginationModel) => void;
  pageUrlParam: string;
  perPageUrlParam: string;
  perPageDefaultAmount: number;
  base: 0 | 1;
} => {
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();

  const currentPage = parseInt(urlSearchParams.get(pageUrlParam) ?? "1");
  const currentPerPage =
    parseInt(urlSearchParams.get(perPageUrlParam) ?? "0") ||
    perPageDefaultAmount;

  const getPageSearchParams = React.useCallback(
    ({
      page,
      perPage = currentPerPage,
    }: {
      page: number;
      perPage?: number;
    }) => {
      const searchParams = new URLSearchParams();
      const oneBasedPageNumber = page + (1 - base);
      if (oneBasedPageNumber > 1)
        searchParams.set(pageUrlParam, oneBasedPageNumber.toString());
      if (perPage !== perPageDefaultAmount) {
        searchParams.set(perPageUrlParam, perPage.toString());
      }
      return searchParams;
    },
    [currentPerPage, base, pageUrlParam, perPageDefaultAmount, perPageUrlParam]
  );

  const setPage = React.useCallback(
    (page: number) => {
      const searchParams = getPageSearchParams({ page });
      setUrlSearchParams(Object.fromEntries(searchParams), [pageUrlParam]);
    },
    [getPageSearchParams, pageUrlParam, setUrlSearchParams]
  );

  const setPerPage = React.useCallback(
    (perPage: number) => {
      const searchParams = getPageSearchParams({ page: base, perPage });
      setUrlSearchParams(Object.fromEntries(searchParams), [
        pageUrlParam,
        perPageUrlParam,
      ]);
    },
    [
      base,
      getPageSearchParams,
      pageUrlParam,
      perPageUrlParam,
      setUrlSearchParams,
    ]
  );

  const offset = (currentPage - 1) * currentPerPage,
    limit = currentPerPage,
    page = currentPage - (1 - base),
    perPage = currentPerPage;

  const paginationModel = React.useMemo(
    (): { page: number; pageSize: number } => ({ page, pageSize: perPage }),
    [page, perPage]
  );

  const setPaginationModel = React.useCallback(
    (paginationModal: { page: number; pageSize: number }) => {
      const searchParams = getPageSearchParams({
        page:
          paginationModal.pageSize !== perPage ? base : paginationModal.page,
        perPage: paginationModal.pageSize,
      });
      setUrlSearchParams(Object.fromEntries(searchParams), [
        pageUrlParam,
        perPageUrlParam,
      ]);
    },
    [
      base,
      perPage,
      getPageSearchParams,
      pageUrlParam,
      perPageUrlParam,
      setUrlSearchParams,
    ]
  );

  return {
    offset,
    limit,
    page,
    perPage,
    getPageSearchParams,
    setPage,
    setPerPage,
    pageUrlParam,
    perPageUrlParam,
    perPageDefaultAmount,
    base,
    paginationModel,
    setPaginationModel,
  };
};
