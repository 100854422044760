import { gql } from "@apollo/client";
import { Table } from "@msys/ui";
import { Divider, Typography } from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { Stack } from "../../../commons/layout/Stack.js";
import { TIME_AND_MATERIAL_CONTRACT_TYPES } from "../../../constants.js";
import { useQuantityUnits } from "../../doc-items/useQuantityUnits.js";
import { getItemPriceData, PriceData } from "../helpers.js";
import { InvoiceItemDescriptionColumn } from "./InvoiceItemDescriptionColumn.js";
import { InvoiceItemMobileRow } from "./InvoiceItemMobileRow.js";
import {
  InvoicePriceTableItem__InvoiceExtraItemFragment,
  InvoicePriceTableItem__InvoiceItemFragment,
  InvoicePriceTableItem__InvoicePaymentItemFragment,
  InvoicePriceTable_InvoiceFragment,
} from "./InvoicePriceTable.generated.js";

type InvoiceItem =
  | InvoicePriceTableItem__InvoiceItemFragment
  | InvoicePriceTableItem__InvoiceExtraItemFragment
  | InvoicePriceTableItem__InvoicePaymentItemFragment;

const cellStyle = { verticalAlign: "top" };

interface Props<
  QuotItem extends
    | InvoicePriceTableItem__InvoiceItemFragment["item"]
    | InvoicePriceTableItem__InvoiceItemFragment["itemSnapshot"],
> {
  invoice: InvoicePriceTable_InvoiceFragment;
  quoteItemMapper: (item: any) => QuotItem; // FIXME
  onRowClick?: (item: InvoiceItem) => void;
}

export const InvoicePriceTable = <
  QuoteItem extends
    | InvoicePriceTableItem__InvoiceItemFragment["item"]
    | InvoicePriceTableItem__InvoiceItemFragment["itemSnapshot"],
>({
  invoice,
  quoteItemMapper,
  onRowClick,
}: Props<QuoteItem>) => {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate("Invoices");

  const { quantityUnitLabels } = useQuantityUnits();

  const quote = invoice.quote;
  const { invoiceItems, invoiceExtraItems, invoicePaymentItems } = invoice;

  const quoteItems = invoiceItems.map(item => item.item);

  const isTimeAndMaterialContract = TIME_AND_MATERIAL_CONTRACT_TYPES.includes(
    quote.contractType
  );

  const tableItems: (InvoiceItem & { priceData: PriceData })[] = [
    ...invoiceItems,
    ...invoiceExtraItems,
    ...invoicePaymentItems,
  ].map(item => ({
    ...item,
    priceData: getItemPriceData(
      quantityUnitLabels,
      item,
      quoteItems,
      isTimeAndMaterialContract,
      language
    ),
  }));

  const columnConfig = [
    {
      cellStyle,
      id: "pos",
      title: t("Pos. "),
      render: (item: InvoiceItem) => {
        if (item.__typename === "InvoiceItem") {
          const quoteItem = quoteItemMapper(item);
          return (
            <Typography variant="body2">{quoteItem.pathForPdf}</Typography>
          );
        }
        return null;
      },
    },
    {
      cellStyle,
      id: "description",
      isFullWidth: true,
      title: t("Description"),
      render: (item: InvoiceItem) => (
        <InvoiceItemDescriptionColumn<QuoteItem>
          item={item}
          quoteItemMapper={quoteItemMapper}
          productsVisibility={quote.productsVisibility}
        />
      ),
    },
    {
      cellStyle,
      id: "quantity",
      title: t("Quantity"),
      render: ({ priceData }: { priceData: PriceData }) => {
        return (
          <>
            <Stack flexDirection="column">
              {priceData.rows?.map((row, index) => (
                <span key={index}>{row.quantity}</span>
              ))}
            </Stack>
          </>
        );
      },
    },
    {
      cellStyle,
      id: "unitPrice",
      title: t("Unit Price"),
      render: ({ priceData }: { priceData: PriceData }) => {
        return (
          <>
            <Stack flexDirection="column">
              {priceData.rows?.map((row, index) => (
                <span key={index}>{row.unitPrice}</span>
              ))}
            </Stack>
          </>
        );
      },
    },
    {
      cellStyle,
      id: "subtotal",
      title: t("Subtotal"),
      render: ({ priceData }: { priceData: PriceData }) => {
        return (
          <>
            <Stack flexDirection="column">
              {priceData.rows?.map((row, index) => (
                <span key={index}>{row.subtotal}</span>
              ))}
            </Stack>
          </>
        );
      },
    },
    {
      cellStyle,
      id: "Total",
      title: t("Total Price"),
      render: ({ priceData }: { priceData: PriceData }) => {
        return (
          <Typography variant={"h4"} component={"span"}>
            {priceData.total}
          </Typography>
        );
      },
    },
  ];

  return (
    <Table
      columns={columnConfig}
      items={tableItems}
      onRowClick={onRowClick}
      mobileStackSpacing={0}
      renderPhoneRow={(item, index) => (
        <>
          {index > 0 ? <Divider /> : null}
          <InvoiceItemMobileRow<QuoteItem>
            item={item}
            quoteItemMapper={quoteItemMapper}
            productsVisibility={quote.productsVisibility}
          />
        </>
      )}
      renderEmptyScreen={() => null}
    />
  );
};
