import { gql, useApolloClient } from "@apollo/client";
import React from "react";
import {
  DecisionMutations_ItemFragment,
  useChangeContingencyMutation,
  useChangeDecisionMutation,
  useEliminateDecisionOptionMutation,
  useEliminateItemMutation,
  useFinalizeContingencyDecisionMutation,
  useFinalizeSubitemDecisionMutation,
  useResetEliminateDecisionOptionMutation,
  useResetEliminateItemMutation,
} from "./useDecisionItemMutations.generated.js";

export const useDecisionItemMutations = <
  T extends DecisionMutations_ItemFragment,
>(
  projectId: string | null,
  docId: string,
  canFinalize: boolean,
  expandedItemIds: string[] | undefined,
  onDecisionPreselectionChangeRefetchQueries?: string[],
  onContingencyPreselectionChangeRefetchQueries?: string[]
) => {
  const client = useApolloClient();

  const [modifyItemContingency, { loading: modifyItemContingencyLoading }] =
    useChangeContingencyMutation({ client });
  const handleContingencyPreselectionChange = React.useCallback(
    async (itemId: string, preselection: boolean) => {
      await modifyItemContingency({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            values: {
              decisionContingentItemPreselection: preselection,
            },
            expandedItemIds,
          },
        },
        refetchQueries: onContingencyPreselectionChangeRefetchQueries,
        awaitRefetchQueries: true,
      });
    },
    [
      docId,
      expandedItemIds,
      modifyItemContingency,
      projectId,
      onContingencyPreselectionChangeRefetchQueries,
    ]
  );

  const [
    finalizeContingencyDecision,
    { loading: finalizeContingencyDecisionLoading },
  ] = useFinalizeContingencyDecisionMutation({ client });
  const handleFinalizeContingencyDecision = React.useCallback(
    async (itemId: string) => {
      await finalizeContingencyDecision({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            expandedItemIds,
          },
        },
      });
    },
    [docId, expandedItemIds, finalizeContingencyDecision, projectId]
  );

  const [eliminateItem, { loading: eliminateItemLoading }] =
    useEliminateItemMutation({ client });
  const handleEliminateItem = React.useCallback(
    async (itemId: string) => {
      await eliminateItem({
        variables: { input: { projectId, docId, itemId, expandedItemIds } },
      });
    },
    [docId, eliminateItem, expandedItemIds, projectId]
  );
  const [resetEliminateItem, { loading: resetEliminateItemLoading }] =
    useResetEliminateItemMutation({ client });
  const handleResetEliminateItem = React.useCallback(
    async (itemId: string) => {
      await resetEliminateItem({
        variables: { input: { projectId, docId, itemId, expandedItemIds } },
      });
    },
    [docId, expandedItemIds, projectId, resetEliminateItem]
  );

  const [modifyItemDecision, { loading: modifyItemDecisionLoading }] =
    useChangeDecisionMutation({ client });
  const handleDecisionPreselectionChange = React.useCallback(
    async (itemId: string, subItemIds: string[]) => {
      await modifyItemDecision({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            values: { decisionSubitemsPreselection: subItemIds },
            expandedItemIds,
          },
        },
        refetchQueries: onDecisionPreselectionChangeRefetchQueries,
        awaitRefetchQueries: true,
      });
    },
    [
      docId,
      expandedItemIds,
      modifyItemDecision,
      projectId,
      onDecisionPreselectionChangeRefetchQueries,
    ]
  );

  const [finalizeSubitemDecision, { loading: finalizeSubitemDecisionLoading }] =
    useFinalizeSubitemDecisionMutation({ client });
  const handleFinalizeSubitemDecision = React.useCallback(
    async (itemId: string) => {
      await finalizeSubitemDecision({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            expandedItemIds,
          },
        },
      });
    },
    [docId, expandedItemIds, finalizeSubitemDecision, projectId]
  );

  const [eliminateDecisionOption, { loading: eliminateLoading }] =
    useEliminateDecisionOptionMutation({ client });
  const handleEliminateOption = React.useCallback(
    async (itemId: string) => {
      await eliminateDecisionOption({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            reason: "",
            expandedItemIds,
          },
        },
      });
    },
    [docId, eliminateDecisionOption, expandedItemIds, projectId]
  );

  const [resetEliminateDecisionOption, { loading: resetEliminateLoading }] =
    useResetEliminateDecisionOptionMutation({ client });
  const handleResetEliminateOption = React.useCallback(
    async (itemId: string) => {
      await resetEliminateDecisionOption({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            expandedItemIds,
          },
        },
      });
    },
    [docId, expandedItemIds, projectId, resetEliminateDecisionOption]
  );

  const loading =
    modifyItemDecisionLoading ||
    modifyItemContingencyLoading ||
    finalizeContingencyDecisionLoading ||
    finalizeSubitemDecisionLoading ||
    eliminateLoading ||
    resetEliminateLoading ||
    eliminateItemLoading ||
    resetEliminateItemLoading;

  const canFinalizeContingencyDecision = React.useCallback(
    (item: T) =>
      canFinalize &&
      item.decisionIsContingentItem &&
      (item.decisionContingentItemPreselection === true ||
        (item.decisionContingentItemPreselection === false &&
          item.parentId !== null)),
    [canFinalize]
  );

  const canFinalizeSubitemDecision = React.useCallback(
    (item: T) =>
      canFinalize &&
      item.decisionIsContingentItem === false &&
      ((item.decisionBehaviorOfSubitems === "SELECT_ONE" &&
        item.decisionSubitemsPreselection.length > 0) ||
        item.decisionBehaviorOfSubitems === "SELECT_MANY"),
    [canFinalize]
  );

  // Force finalize - means that contingency decision (if needed!) will be at first forcefully resolved,
  // and then resolving subitem decision will take place – it's needed in document previews
  // to resolve decision without bothering about contingency
  const canForceFinalizeSubitemDecision = React.useCallback(
    (item: T) =>
      canFinalize &&
      ((item.decisionBehaviorOfSubitems === "SELECT_ONE" &&
        item.decisionSubitemsPreselection.length > 0) ||
        item.decisionBehaviorOfSubitems === "SELECT_MANY"),
    [canFinalize]
  );

  const isSubitemFinalizeDisabled = React.useCallback(
    defaultIsSubitemFinalizeDisabled,
    []
  );

  const isItemGoingToBeRemoved = React.useCallback(
    defaultIsItemGoingToBeRemoved,
    []
  );

  const isItemGoingToBeReplaced = React.useCallback(
    defaultIsItemGoingToBeReplaced,
    []
  );

  const isItemNeedDecision = React.useCallback(defaultIsItemNeedDecision, []);

  return {
    // CONTINGENCY
    modifyItemContingency,
    handleContingencyPreselectionChange,
    finalizeContingencyDecision,
    handleFinalizeContingencyDecision,
    // DECISION
    modifyItemDecision,
    handleDecisionPreselectionChange,
    finalizeSubitemDecision,
    handleFinalizeSubitemDecision,
    // ITEM ELIMINATION
    eliminateItem: handleEliminateItem,
    resetEliminateItem: handleResetEliminateItem,
    // DECISION OPTION ELIMINATION
    eliminateDecisionOption: handleEliminateOption,
    handleEliminateOption,
    resetEliminateDecisionOption: handleResetEliminateOption,
    handleResetEliminateOption,
    // LOADING STATE
    modifyItemDecisionLoading,
    modifyItemContingencyLoading,
    finalizeContingencyDecisionLoading,
    finalizeSubitemDecisionLoading,
    eliminateLoading,
    resetEliminateLoading,
    loading,
    // HELPERS
    canFinalizeContingencyDecision,
    canFinalizeSubitemDecision,
    canForceFinalizeSubitemDecision,
    isSubitemFinalizeDisabled,
    isItemGoingToBeRemoved,
    isItemGoingToBeReplaced,
    isItemNeedDecision,
  };
};

export const defaultIsItemGoingToBeRemoved = <
  T extends DecisionMutations_ItemFragment,
>(
  item: T
) =>
  item.decisionIsContingentItem &&
  item.decisionContingentItemPreselection === false;

export const defaultIsItemGoingToBeReplaced = <
  T extends DecisionMutations_ItemFragment,
>(
  item: T
) => item.decisionBehaviorOfSubitems === "SELECT_ONE";

export const defaultIsItemNeedDecision = <
  T extends DecisionMutations_ItemFragment,
>(
  item: T
) => item.decisionIsContingentItem || item.type === "decision";

export const defaultIsSubitemFinalizeDisabled = <
  T extends DecisionMutations_ItemFragment,
>(
  item: T
) =>
  item.decisionIsContingentItem &&
  item.decisionContingentItemPreselection !== true;

// ----
