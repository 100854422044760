import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  Modal,
  ModalActions,
  ModalContent,
  ModalDialog,
  ModalOpenButton,
  ModalTitle,
  RadioGroupOption,
  useScreenWidth,
} from "@msys/ui";
import { Replay as ReplayIcon } from "@mui/icons-material";
import {
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { omit, uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import {
  PdfLetterheadSettings,
  PdfVisibility,
} from "../../../../clients/graphqlTypes.js";
import { AttachmentImageDragAndDropField } from "../../../commons/form-fields/AttachmentImageDragAndDropField.js";
import { ColorPickerField } from "../../../commons/form-fields/ColorPickerField.js";
import { FormattedIntegerField } from "../../../commons/form-fields/FormattedIntegerField.js";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField.js";
import { FileInput } from "../../../commons/inputs/FileInput.js";
import { Attachment } from "../../attachments/helpers.js";
import {
  OrganisationPdfSettingsBox_PdfLetterheadFragment,
  useGenerateExamplePdfDocumentMutation,
  useGenerateOrganisationPdfLetterheadModalQuery,
  useGenerateOrganisationPdfLetterheadMutation,
  useRequestOrganisationPdfLetterheadUploadUrlMutation,
  useSetOrganisationPdfLetterheadMutation,
} from "./OrganisationPdfSettingsBox.generated.js";

interface Props {
  organisationPdfLetterhead: OrganisationPdfSettingsBox_PdfLetterheadFragment | null;
}

export function OrganisationPdfSettingsBox({
  organisationPdfLetterhead,
}: Props) {
  const { t } = useTranslate("OrganisationSettings");
  return (
    <CardContainer isExpandable title={t("General PDF settings")}>
      <Stack direction="column" padding={1} spacing={1}>
        {organisationPdfLetterhead?.attachment.url && (
          <>
            <Link
              component={"a"}
              href={organisationPdfLetterhead.attachment.url}
              target="_blank"
            >
              {t("Letterhead", { ns: "OrganisationSettings" })}
            </Link>
            <ModalOpenButton
              Modal={EditLetterheadModal}
              modalProps={{
                url: organisationPdfLetterhead?.attachment.url,
                pdfLetterheadSettings: organisationPdfLetterhead.settings,
              }}
            >
              <Button variant="text" color="secondary">
                {t("Edit letterhead", { ns: "OrganisationSettings" })}
              </Button>
            </ModalOpenButton>
          </>
        )}
        <ModalOpenButton Modal={CreateNewPdfLetterheadModal} modalProps={{}}>
          <Button variant="text" color="secondary">
            {t("Create new letterhead", { ns: "OrganisationSettings" })}
          </Button>
        </ModalOpenButton>
      </Stack>
    </CardContainer>
  );
}

const EditLetterheadModal = ({
  handleClose,
  url,
  pdfLetterheadSettings,
}: {
  handleClose: () => void;
  url: string;
  pdfLetterheadSettings: PdfLetterheadSettings;
}) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  return (
    <ModalDialog handleClose={handleClose} maxWidth={url ? "md" : "sm"}>
      <ModalTitle
        title={t("Edit letterhead", { ns: "OrganisationSettings" })}
        handleClose={handleClose}
      />
      <LetterheadSettingsForm
        url={url}
        marginTopFirstPage={pdfLetterheadSettings.marginTopFirstPage}
        marginTopSubsequentPage={pdfLetterheadSettings.marginTopSubsequentPage}
        marginBottom={pdfLetterheadSettings.marginBottom}
        textPrimaryColor={pdfLetterheadSettings.textPrimaryColor}
        textSecondaryColor={pdfLetterheadSettings.textSecondaryColor}
        handleComplete={handleClose}
      />
    </ModalDialog>
  );
};

const CreateNewPdfLetterheadModal = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const [url, setUrl] = React.useState<string | null>(null);

  const client = useApolloClient();
  const [requestUploadUrl] =
    useRequestOrganisationPdfLetterheadUploadUrlMutation({
      client,
    });

  return (
    <ModalDialog handleClose={handleClose} maxWidth={url ? "md" : "sm"}>
      <ModalTitle
        title={t("Create new letterhead", { ns: "OrganisationSettings" })}
        handleClose={handleClose}
      />
      {url ? (
        <LetterheadSettingsForm url={url} handleComplete={handleClose} />
      ) : (
        <ModalContent>
          <Stack direction={"column"} spacing={1}>
            <Button
              color="secondary"
              onClick={() => fileUploadRef.current?.click()}
            >
              {t("Upload", { ns: "Global" })}
            </Button>
            <FileInput
              innerRef={fileUploadRef}
              multiple={false}
              accept="application/pdf"
              onComplete={async file => {
                try {
                  const response = await requestUploadUrl();
                  const uploadUrl =
                    response.data?.requestOrganisationPdfLetterheadUploadUrl
                      .uploadUrl;
                  const accessUrl =
                    response.data?.requestOrganisationPdfLetterheadUploadUrl
                      .accessUrl;
                  if (!uploadUrl) throw new Error("Upload URL is missing");
                  if (!accessUrl) throw new Error("Access URL is missing");

                  await fetch(uploadUrl, {
                    method: "PUT",
                    body: file,
                  });
                  setUrl(accessUrl);
                } catch (error) {
                  console.error(error);
                }
              }}
            />

            <Divider>
              <Typography color={theme => theme.palette.grey[400]}>
                {t("or", { ns: "Global" })}
              </Typography>
            </Divider>

            <ModalOpenButton
              Modal={GenerateOrganisationPdfLetterheadModal}
              modalProps={{
                handleComplete: async url => {
                  setUrl(url);
                },
              }}
            >
              <Button variant="text" color="secondary">
                {t("Generate", { ns: "Global" })}
              </Button>
            </ModalOpenButton>
          </Stack>
        </ModalContent>
      )}
    </ModalDialog>
  );
};

interface LetterheadSettingsFormValues {
  marginTopFirstPage: number;
  marginTopSubsequentPage: number;
  marginBottom: number;
  textPrimaryColor: string;
  textSecondaryColor: string;
}

const LetterheadSettingsForm = ({
  url,
  marginTopFirstPage,
  marginTopSubsequentPage,
  marginBottom,
  textPrimaryColor,
  textSecondaryColor,
  handleComplete,
}: {
  url: string;
  marginTopFirstPage?: number | undefined;
  marginTopSubsequentPage?: number | undefined;
  marginBottom?: number | undefined;
  textPrimaryColor?: string | undefined;
  textSecondaryColor?: string | undefined;
  handleComplete: () => void;
}) => {
  const { isMaxPhone } = useScreenWidth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate([
    "OrganisationSettings",
    "OrganisationBranding",
    "Global",
  ]);

  const [exampleDocumentUrl, setExampleDocumentUrl] = React.useState<
    string | null
  >(null);

  const client = useApolloClient();
  const [generateExampleDocument] = useGenerateExamplePdfDocumentMutation({
    client,
  });
  const [setLetterhead] = useSetOrganisationPdfLetterheadMutation({
    client,
  });

  const initialValues = React.useMemo((): LetterheadSettingsFormValues => {
    return {
      marginTopFirstPage: marginTopFirstPage ?? 140,
      marginTopSubsequentPage: marginTopSubsequentPage ?? 60,
      marginBottom: marginBottom ?? 74,
      textPrimaryColor: textPrimaryColor ?? "#000000", // theme.palette.text.primary,
      textSecondaryColor: textSecondaryColor ?? "#000000", // theme.palette.text.secondary,
    };
  }, [
    marginBottom,
    marginTopFirstPage,
    marginTopSubsequentPage,
    textPrimaryColor,
    textSecondaryColor,
  ]);

  const validationSchema = Yup.object().shape({
    marginTopFirstPage: Yup.number()
      .required()
      .min(0)
      .max(500)
      .label(t("Top margin on first page", { ns: "OrganisationSettings" })),
    marginTopSubsequentPage: Yup.number()
      .required()
      .min(0)
      .max(350)
      .label(
        t("Top margin on subsequent pages", { ns: "OrganisationSettings" })
      ),
    marginBottom: Yup.number()
      .required()
      .min(0)
      .max(200)
      .label(t("Bottom margin", { ns: "OrganisationSettings" })),
    textPrimaryColor: Yup.string()
      .required()
      .matches(
        /^#[a-f\d]{6}$/i,
        t("Wrong color format, only HEX is allowed", {
          ns: "OrganisationBranding",
        })
      )
      .label(t("Text primary color", { ns: "OrganisationSettings" })),
    textSecondaryColor: Yup.string()
      .required()
      .matches(
        /^#[a-f\d]{6}$/i,
        t("Wrong color format, only HEX is allowed", {
          ns: "OrganisationBranding",
        })
      )
      .label(t("Text secondary color", { ns: "OrganisationSettings" })),
  });

  React.useEffect(() => {
    generateExampleDocument({
      variables: { input: { pdfLetterheadUrl: url, ...initialValues } },
    }).then(({ data }) => {
      setExampleDocumentUrl(data?.generateExamplePdfDocument.url ?? null);
    });
  }, [generateExampleDocument, initialValues, url]);

  const handleSubmit = async (
    values: LetterheadSettingsFormValues,
    formikHelpers: FormikHelpers<LetterheadSettingsFormValues>
  ) => {
    generateExampleDocument({
      variables: {
        input: {
          pdfLetterheadUrl: url,
          ...values,
        },
      },
    }).then(({ data }) => {
      setExampleDocumentUrl(data?.generateExamplePdfDocument.url ?? null);
      formikHelpers.resetForm({ values });
    });
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<LetterheadSettingsFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <>
          <ModalContent>
            <Form id={formId}>
              <Stack
                direction={isMaxPhone ? "column-reverse" : "row"}
                spacing={1}
              >
                {exampleDocumentUrl && (
                  <iframe
                    style={{ border: "none" }}
                    src={exampleDocumentUrl}
                    width="100%"
                    height="1100px"
                  />
                )}
                <Divider
                  orientation={isMaxPhone ? "horizontal" : "vertical"}
                  flexItem
                />
                <Stack direction="column" spacing={1} minWidth={300}>
                  <Typography variant="h4">
                    {t("Content settings", { ns: "OrganisationSettings" })}
                  </Typography>
                  <FormattedIntegerField
                    name="marginTopFirstPage"
                    label={t("Top margin on first page", {
                      ns: "OrganisationSettings",
                    })}
                  />
                  <FormattedIntegerField
                    name="marginTopSubsequentPage"
                    label={t("Top margin on subsequent pages", {
                      ns: "OrganisationSettings",
                    })}
                  />
                  <FormattedIntegerField
                    name="marginBottom"
                    label={t("Bottom margin", { ns: "OrganisationSettings" })}
                  />
                  <Divider />
                  <ColorPickerField
                    label={t("Text primary color", {
                      ns: "OrganisationSettings",
                    })}
                    name="textPrimaryColor"
                    required
                  />
                  <ColorPickerField
                    label={t("Text secondary color", {
                      ns: "OrganisationSettings",
                    })}
                    name="textSecondaryColor"
                    required
                  />
                  <Button
                    variant="outlined"
                    startIcon={<ReplayIcon />}
                    type="submit"
                    disabled={!formikProps.dirty || !formikProps.isValid}
                  >
                    {t("Update preview", { ns: "OrganisationSettings" })}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          </ModalContent>
          <ModalActions
            actionButtons={[
              {
                label: t("Confirm", { ns: "Global" }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  disabled: formikProps.dirty,
                },
                handleClick: async () => {
                  try {
                    await setLetterhead({
                      variables: {
                        input: { pdfLetterheadUrl: url, ...formikProps.values },
                      },
                    });
                    handleComplete();
                  } catch (error) {
                    if (error instanceof Error)
                      enqueueSnackbar(error.message, { variant: "error" });
                  }
                },
              },
            ]}
          />
        </>
      )}
    </Formik>
  );
};

interface FormValues {
  pdfFooterColumn1: string;
  pdfFooterColumn2: string;
  pdfFooterColumn3: string;
  pdfFooterVisibility: PdfVisibility;
  pdfLogo: Attachment | null;
  pdfTextPrimaryColor: string;
  pdfTextSecondaryColor: string;
}

const GenerateOrganisationPdfLetterheadModal = ({
  handleClose,
  handleComplete,
}: {
  handleClose: () => void;
  handleComplete: (url: string) => Promise<void>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate([
    "OrganisationSettings",
    "OrganisationBranding",
    "Global",
  ]);

  const client = useApolloClient();
  const query = useGenerateOrganisationPdfLetterheadModalQuery({ client });
  const organisationPdfSettings = getDataOrNull(
    query.data?.organisationPdfSettings
  );
  const [generateLetterhead] = useGenerateOrganisationPdfLetterheadMutation({
    client,
  });

  const initialValues = React.useMemo((): FormValues => {
    if (organisationPdfSettings) {
      return {
        pdfFooterColumn1: organisationPdfSettings.pdfFooterColumn1,
        pdfFooterColumn2: organisationPdfSettings.pdfFooterColumn2,
        pdfFooterColumn3: organisationPdfSettings.pdfFooterColumn3,
        pdfFooterVisibility: organisationPdfSettings.pdfFooterVisibility,
        pdfTextPrimaryColor: organisationPdfSettings.pdfTextPrimaryColor,
        pdfTextSecondaryColor: organisationPdfSettings.pdfTextSecondaryColor,
        pdfLogo: organisationPdfSettings.pdfLogo ?? null,
      };
    }

    return {
      pdfFooterColumn1: "",
      pdfFooterColumn2: "",
      pdfFooterColumn3: "",
      pdfFooterVisibility: "no",
      pdfLogo: null,
      pdfTextPrimaryColor: "",
      pdfTextSecondaryColor: "",
    };
  }, [organisationPdfSettings]);

  const validationSchema = Yup.object().shape({
    pdfFooterColumn1: Yup.string().label(
      t("Column A", { ns: "OrganisationSettings" })
    ),
    pdfFooterColumn2: Yup.string().label(
      t("Column B", { ns: "OrganisationSettings" })
    ),
    pdfFooterColumn3: Yup.string().label(
      t("Column C", { ns: "OrganisationSettings" })
    ),
    pdfTextPrimaryColor: Yup.string()
      .required()
      .matches(
        /^#[a-f\d]{6}$/i,
        t("Wrong color format, only HEX is allowed", {
          ns: "OrganisationBranding",
        })
      )
      .label(t("Text primary color", { ns: "OrganisationSettings" })),
    pdfTextSecondaryColor: Yup.string()
      .required()
      .matches(
        /^#[a-f\d]{6}$/i,
        t("Wrong color format, only HEX is allowed", {
          ns: "OrganisationBranding",
        })
      )
      .label(t("Text secondary color", { ns: "OrganisationSettings" })),
    pdfFooterVisibility: Yup.string().required(),
    pdfLogo: Yup.object().nullable(),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      const input = {
        pdfFooterColumn1: values.pdfFooterColumn1,
        pdfFooterColumn2: values.pdfFooterColumn2,
        pdfFooterColumn3: values.pdfFooterColumn3,
        pdfFooterVisibility: values.pdfFooterVisibility,
        pdfTextPrimaryColor: values.pdfTextPrimaryColor,
        pdfTextSecondaryColor: values.pdfTextSecondaryColor,
        pdfLogo: values.pdfLogo
          ? omit(values.pdfLogo, "id", "__typename")
          : null,
      };
      const { data } = await generateLetterhead({
        variables: {
          input,
        },
      });
      const url = data?.generateOrganisationPdfLetterhead.url;
      if (!url) throw new Error("URL is missing");
      handleComplete(url);
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  if (query.loading) return null;

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <Form id={formId}>
        <Modal
          title={t("Generate PDF letterhead", { ns: "OrganisationSettings" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Generate", { ns: "Global" }),
              buttonProps: { type: "submit", form: formId },
            },
          ]}
          isLoading={query.loading}
        >
          <Stack direction="column" spacing={1}>
            <AttachmentImageDragAndDropField
              label={t("Logo in header", { ns: "OrganisationSettings" })}
              name="pdfLogo"
            />

            <Divider />
            <Typography>
              {t("Page colors", { ns: "OrganisationSettings" })}
            </Typography>

            <ColorPickerField
              label={t("Text primary color", { ns: "OrganisationSettings" })}
              name="pdfTextPrimaryColor"
              required
            />
            <ColorPickerField
              label={t("Text secondary color", { ns: "OrganisationSettings" })}
              name="pdfTextSecondaryColor"
              required
            />

            <Divider />

            <Typography>
              {t("Page footer", { ns: "OrganisationSettings" })}
            </Typography>
            <PdfVisibilityField name="pdfFooterVisibility" />

            <Field
              component={TextField}
              name="pdfFooterColumn1"
              label={t("Column A", { ns: "OrganisationSettings" })}
              multiline
              disabled={false}
              rows={3}
              helperText={t(
                "Eg. address, contact phone, contact email address etc.",
                { ns: "OrganisationSettings" }
              )}
            />

            <Field
              component={TextField}
              name="pdfFooterColumn2"
              label={t("Column B", { ns: "OrganisationSettings" })}
              multiline
              disabled={false}
              rows={3}
              helperText={t(
                "Eg. tax ID, registration office, managing director etc.",
                { ns: "OrganisationSettings" }
              )}
            />

            <Field
              component={TextField}
              name="pdfFooterColumn3"
              label={t("Column C", { ns: "OrganisationSettings" })}
              multiline
              disabled={false}
              rows={3}
              helperText={t("Eg. bank account, bank name, BIC code etc.", {
                ns: "OrganisationSettings",
              })}
            />
          </Stack>
        </Modal>
      </Form>
    </Formik>
  );
};

const usePdfVisibilityTranslation = () => {
  const { t } = useTranslate("PdfVisibilityField");

  return (pdfVisibility: PdfVisibility) => {
    switch (pdfVisibility) {
      case "all_pages":
        return t("On all pages");
      case "first_page":
        return t("Only on the first page");
      case "no":
        return t("None");
    }
  };
};

const ALL_PDF_VISIBILITY_TYPES: PdfVisibility[] = [
  "all_pages",
  "first_page",
  "no",
];

const PdfVisibilityField = (props: { name: string }) => {
  const t = usePdfVisibilityTranslation();
  const options: RadioGroupOption[] = ALL_PDF_VISIBILITY_TYPES.map(
    pdfVisibility => ({
      label: t(pdfVisibility), // FIXME: don't call t with variable, scanner will not detect these values
      value: pdfVisibility,
    })
  );
  return <RadioGroupField {...props} options={options} condensed />;
};
