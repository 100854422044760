import { ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { AddProjectMemberModal } from "../modals/AddProjectMemberModal.js";

export const AddProjectMemberButton = ({
  projectId,
  refetchQueries,
  Button,
}: {
  projectId: string;
  refetchQueries?: string[];
  Button?: React.ReactElement;
}) => {
  const { t } = useTranslate("ProjectMembers");
  const title = t("Add project member");

  const button = Button ?? (
    <Tooltip title={title}>
      <IconButton color="primary" aria-label={title} size="small">
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <ModalOpenButton
      Modal={AddProjectMemberModal}
      modalProps={{
        projectId,
        refetchQueries,
      }}
    >
      {button}
    </ModalOpenButton>
  );
};
