import { CardContainer, Switch } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { gql, useApolloClient } from "@apollo/client";
import {
  UserNotificationSettingsBox_UserFragment,
  useUpdateUserNotificationSettingsMutation,
} from "./UserNotificationSettingsBox.generated.js";
import { EditNotifications as EditNotificationsIcon } from "@mui/icons-material";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability.js";

interface Props {
  user: UserNotificationSettingsBox_UserFragment;
}

export function UserNotificationSettingsBox({ user }: Props) {
  const { t } = useTranslate(["UserProfile"]);

  const client = useApolloClient();
  const [updateNotificationSettings] =
    useUpdateUserNotificationSettingsMutation({ client });

  async function toggleNotification(type: string) {
    const newSettings = user.notifyEventsByEmail.includes(type)
      ? user.notifyEventsByEmail.filter(t => t !== type)
      : [...user.notifyEventsByEmail, type];

    await updateNotificationSettings({
      variables: { userId: user.id, notifyEventsByEmail: newSettings },
    });
  }

  return (
    <CardContainer
      Icon={<EditNotificationsIcon />}
      isExpandable
      title={t("Email notifications", {
        ns: "UserProfile",
      })}
    >
      <Stack padding={1} alignItems="flex-start">
        <Typography>
          {t("Receive an email when", { ns: "UserProfile" })}
        </Typography>
        <Switch
          id="project-assigned"
          label={t("A project is assigned to me", { ns: "UserProfile" })}
          checked={user.notifyEventsByEmail.includes("PROJECT_ASSIGNED")}
          onChange={() => {
            toggleNotification("PROJECT_ASSIGNED");
          }}
        />
        <Switch
          id="project-created"
          label={t("A new project is added", { ns: "UserProfile" })}
          checked={user.notifyEventsByEmail.includes("PROJECT_CREATED")}
          onChange={() => {
            toggleNotification("PROJECT_CREATED");
          }}
        />
        <RestrictedByCapabilityWithDebug capability="QUOTING">
          <Switch
            id="project-contractee-input-added"
            label={t("Project contractee input added", { ns: "UserProfile" })}
            checked={user.notifyEventsByEmail.includes(
              "PROJECT_CONTRACTEE_INPUT_ADDED"
            )}
            onChange={() => {
              toggleNotification("PROJECT_CONTRACTEE_INPUT_ADDED");
            }}
          />
        </RestrictedByCapabilityWithDebug>
      </Stack>
    </CardContainer>
  );
}
