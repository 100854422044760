import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { FolderOpen as FolderOpenIcon } from "@mui/icons-material";
import { IconButton, List } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "../../../commons/Avatar.js";
import { CardContainer } from "@msys/ui";
import { ProfileListItem } from "../../../commons/ProfileListItem.js";
import {
  useCancelProjectContracteeInvitationMutation,
  usePendingInvitationsContracteeQuery,
} from "./CrmPersonPendingContracteeInvitationsBox.generated.js";
import { useTranslate } from "@tolgee/react";

export function CrmPersonPendingContracteeInvitationsBox({
  userId,
}: {
  userId: string;
}) {
  const client = useApolloClient();
  const { t } = useTranslate("UserProfile");
  const query = usePendingInvitationsContracteeQuery({
    client,
    variables: { userId },
    fetchPolicy: "cache-and-network",
  });

  const [
    cancelProjectContracteeInvitation,
    { loading: cancelProjectContracteeInvitationLoading },
  ] = useCancelProjectContracteeInvitationMutation({ client });

  const crmPerson = getDataOrNull(query.data?.crmPerson)?.crmPerson;

  if (!crmPerson || crmPerson.projectContracteeInvitations.length === 0) {
    return null;
  }

  return (
    <CardContainer
      title={t("Contractee Invitations")}
      itemCount={crmPerson.projectContracteeInvitations.length}
    >
      <List disablePadding>
        {crmPerson.projectContracteeInvitations.map(contracteeInvitation => (
          <ProfileListItem
            button
            key={contracteeInvitation.id}
            component={Link}
            to={`/projects/${contracteeInvitation.project.id}`}
            avatar={<Avatar type="square" size="m" icon={<FolderOpenIcon />} />}
            primary={contracteeInvitation.project.title}
            ActionButton={
              <IconButton
                disabled={cancelProjectContracteeInvitationLoading}
                size="small"
                onClick={() => {
                  cancelProjectContracteeInvitation({
                    variables: {
                      projectContracteeInvitationId: contracteeInvitation.id,
                      projectId: contracteeInvitation.project.id,
                    },
                  });
                }}
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            }
          />
        ))}
      </List>
    </CardContainer>
  );
}
