import { isPicture } from "@msys/common";
import { orientation as exifrOrientation } from "exifr";
import uploadcare from "uploadcare-widget";
import { IMAGE_MAX_WIDTH, IMAGE_QUALITY } from "../../constants.js";
import { Attachment } from "@msys/ui";
export type { Attachment };

export const uploadFileToUploadCare = async (
  file: File,
  needCompress?: boolean
) => {
  let fileObject =
    needCompress === false || !canBeCompressed(file)
      ? file
      : await compress(file, IMAGE_QUALITY, IMAGE_MAX_WIDTH);

  let upload = uploadcare.fileFrom("object", fileObject);

  const result: Attachment = await new Promise((resolve, reject) => {
    upload.done((result: any) => {
      resolve({
        id: "volatile::" + result.uuid,
        url: result.cdnUrl,
        title: result.name,
        mimeType: result.mimeType,
      });
    });
  });

  return result;
};

export async function compressIfCan(file: File) {
  if (canBeCompressed(file)) {
    return await compress(file, IMAGE_QUALITY, IMAGE_MAX_WIDTH);
  }

  return file;
}

const NON_COMPRESSABLE_TYPES = [
  "image/png",
  "image/svg",
  "image/svg+xml",
  "image/webp",
];
export function canBeCompressed(file: File) {
  return !NON_COMPRESSABLE_TYPES.includes(file.type);
}

export function dataURLtoFile(dataurl: string, filename: string) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function getRotatedUrl(url: string, direction: "left" | "right") {
  if (url.includes("autorotate")) {
    return url.replace(
      "/autorotate/yes/",
      `/rotate/${direction !== "left" ? "270" : "90"}/`
    );
  }

  if (url.includes("/rotate/90/")) {
    return url.replace(
      "/rotate/90/",
      `/rotate/${direction !== "left" ? "0" : "180"}/`
    );
  }

  if (url.includes("/rotate/180/")) {
    return url.replace(
      "/rotate/180/",
      `/rotate/${direction !== "left" ? "90" : "270"}/`
    );
  }

  if (url.includes("/rotate/270/")) {
    return url.replace(
      "/rotate/270/",
      `/rotate/${direction !== "left" ? "180" : "0"}/`
    );
  }

  if (url.includes("/rotate/0/")) {
    return url.replace(
      "/rotate/0/",
      `/rotate/${direction !== "left" ? "270" : "90"}/`
    );
  }

  if (!url.includes("/rotate/") && !url.includes("/autorotate/")) {
    return `${url}-/rotate/${direction !== "left" ? "270" : "90"}/`;
  }

  return url;
}

async function compress(
  source: File,
  quality: number,
  maxWidth: number
): Promise<File> {
  if (!isPicture(source.type)) return source;

  let file = source;
  if (file.type === "image/heic" || file.type === "image/heif") {
    const heic2any = await import("heic2any");
    // @ts-expect-error FIXME
    const newBlob = (await heic2any({
      blob: file,
    })) as Blob;
    file = new File([newBlob], file.name, {
      lastModified: file.lastModified,
    });
  }
  const { origWidth, orientation } = await new Promise<{
    origWidth: number;
    origHeight: number;
    orientation: number | undefined;
  }>((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = function () {
      // file is loaded
      const img = new Image();

      img.onload = function () {
        exifrOrientation(img).then(orientation => {
          resolve({
            origWidth: img.width,
            origHeight: img.height,
            orientation: orientation,
          });
        });
      };

      (img as any).src = fr.result; // is the data URL because called with readAsDataURL
    };
  });

  return new Promise((resolve, reject) => {
    const fileName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      const img = new Image();
      (img as any).src = (event.target! as any).result;
      img.onload = () => {
        const width = origWidth > maxWidth ? maxWidth : origWidth;
        const scaleFactor = width / img.width;
        const height = img.height * scaleFactor;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;

        canvas.width = width;
        canvas.height = height;

        ctx.save();
        // https://gist.github.com/SagiMedina/f00a57de4e211456225d3114fd10b0d0
        // switch (orientation) {
        //   // explained here: https://i.stack.imgur.com/6cJTP.gif
        //   case 1:
        //     break;
        //
        //   case 2:
        //     ctx.translate(width, 0);
        //     ctx.scale(-1, 1);
        //     break;
        //
        //   case 3:
        //     ctx.translate(width, height);
        //     ctx.rotate((180 / 180) * Math.PI); // 180/180 is 1? No shit, but how else will you know its need 180 rotation?
        //     break;
        //
        //   case 4:
        //     ctx.translate(0, height);
        //     ctx.scale(1, -1);
        //     break;
        //
        //   case 5:
        //     canvas.width = height;
        //     canvas.height = width;
        //     ctx.rotate((90 / 180) * Math.PI);
        //     ctx.scale(1, -1);
        //     break;
        //
        //   case 6:
        //     canvas.width = height;
        //     canvas.height = width;
        //     ctx.rotate((90 / 180) * Math.PI);
        //     ctx.translate(0, -height);
        //     break;
        //
        //   case 7:
        //     canvas.width = height;
        //     canvas.height = width;
        //     ctx.rotate((270 / 180) * Math.PI);
        //     ctx.translate(-width, height);
        //     ctx.scale(1, -1);
        //     break;
        //
        //   case 8:
        //     canvas.width = height;
        //     canvas.height = width;
        //     ctx.translate(0, width);
        //     ctx.rotate((270 / 180) * Math.PI);
        //     break;
        //
        //   default:
        //     break;
        // }

        ctx.drawImage(img, 0, 0, width, height);
        ctx.restore();

        ctx.canvas.toBlob(
          blob => {
            resolve(
              new File([blob!], fileName, {
                type: "image/jpeg",
                lastModified: Date.now(),
              })
            );
          },
          "image/jpeg",
          quality
        );
      };
    };
    reader.onerror = error => {
      console.error(error);
      reject(error);
    };
  });
}
