import { MenuItemWithIcon } from "@msys/ui";
import { Check as CheckIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";

export type ViewMode = null | "visibility" | "delete" | "edit";
export type ViewEditMode = null | "edit";

interface Props {
  viewMode: ViewMode;
  onViewModeChange: (viewMode: ViewMode) => void;
  allowedModes: ViewMode[];
}

export const ViewModeMenuItem = ({
  viewMode,
  onViewModeChange,
  allowedModes,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const canUnset = allowedModes.includes(null);
  const modes: { label: string; value: ViewMode }[] = [
    {
      label: t("Visibility mode", { ns: "Global" }),
      value: "visibility",
    },
    {
      label: t("Edit mode", { ns: "Global" }),
      value: "edit",
    },
    {
      label: t("Delete mode", { ns: "Global" }),
      value: "delete",
    },
  ];

  return (
    <>
      {modes
        .filter(m => allowedModes.includes(m.value))
        .map(mode => (
          <MenuItemWithIcon
            key={`${mode.value}-mode-item`}
            icon={viewMode === mode.value ? <CheckIcon /> : undefined}
            inset={viewMode !== mode.value}
            onClick={() => {
              if (canUnset && viewMode === mode.value) {
                onViewModeChange(null);
              } else {
                onViewModeChange(mode.value);
              }
            }}
            selected={mode.value === viewMode}
          >
            {mode.label}
          </MenuItemWithIcon>
        ))}
    </>
  );
};
