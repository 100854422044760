import { Capacitor } from "@capacitor/core";
import { ImageWithPreview, useScreenWidth } from "@msys/ui";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import { Box, Fab } from "@mui/material";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon.js";
import React, { FC, useEffect, useState } from "react";
import { useLatest } from "react-use";
import { cssValue } from "../../../common/MuiThemeProvider.js";
import { useTranslate } from "@tolgee/react";
import { DragAndDropContainer } from "./DragAndDropContainer.js";
import { ButtonPhotoInput } from "./ButtonPhotoInput.js";

interface Props {
  noPreview?: boolean;
  uploading?: boolean;
  title?: string;
  skipEdit?: boolean;
  disabled?: boolean;
  onFile: (file: File | null) => void | Promise<void>;
  iconComponent?: React.ComponentType<SvgIconTypeMap["props"]>;
}

/**
 * Provides basic functionality to upload a single photo/image with drag & drop with image preview
 */
export const AttachmentPhotoDragAndDropField: FC<Props> = ({
  noPreview = false,
  uploading,
  title,
  skipEdit = false,
  disabled = false,
  onFile,
  iconComponent: Icon = AddAPhotoIcon,
}) => {
  const { t } = useTranslate("AttachmentField");
  const { isMinTablet } = useScreenWidth();

  const onFileLatest = useLatest(onFile);

  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    onFileLatest.current?.(file);
    if (file) {
      const preview = URL.createObjectURL(file);
      setPreview(preview);
      return () => {
        URL.revokeObjectURL(preview);
      };
    } else {
      setPreview(null);
    }
  }, [file, onFileLatest]);

  return preview && !noPreview ? (
    <ImageWithPreview
      src={preview}
      title={t("Preview")}
      borderRadius={cssValue.boxBorderRadius}
      handleDelete={() => setFile(null)}
    />
  ) : (
    <Box
      onClick={e => {
        e.stopPropagation();
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      {isMinTablet && !Capacitor.isNativePlatform() ? (
        <DragAndDropContainer
          onFiles={files => setFile(files[0])}
          title={title ?? t("Add photo")}
          disabled={disabled}
          accept="image/*,.heic,.heif"
          iconComponent={Icon}
        />
      ) : (
        <ButtonPhotoInput skipEdit={skipEdit} onComplete={setFile}>
          <Fab
            color="secondary"
            variant="extended"
            size="large"
            disabled={uploading || disabled}
          >
            <Icon style={{ marginRight: "10px" }} />
            {title ?? t("Add photo")}
          </Fab>
        </ButtonPhotoInput>
      )}
    </Box>
  );
};
