/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { CustomFieldsFragmentDoc } from '../../custom-fields/CustomFieldsBox.generated.js';
export type CrmPersonCustomFieldsBox_CrmPersonFragment = { __typename: 'CrmPerson', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export const CrmPersonCustomFieldsBox_CrmPersonFragmentDoc = gql`
    fragment CrmPersonCustomFieldsBox_CrmPerson on CrmPerson {
  id
  ...CustomFields
}
    ${CustomFieldsFragmentDoc}`;