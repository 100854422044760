import React, { FC } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { px } from "../../../../common/MuiThemeProvider.js";
import { Alert, AlertTitle } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ProjectStateMachineStatus } from "../../../../clients/graphqlTypes.js";

interface Props {
  subOriginProject: {
    state: ProjectStateMachineStatus;
    id: string;
  };
}

export const ProjectSubOriginAlert = ({ subOriginProject }: Props) => {
  const { t } = useTranslate("ProjectOverview");
  return (
    <Alert
      variant="outlined"
      severity="info"
      action={
        <Button
          component={Link}
          to={
            (subOriginProject.state === "opportunity"
              ? `/opportunities`
              : `/projects`) + `/${subOriginProject.id}/overview`
          }
          color="primary"
          size="small"
          variant="text"
          style={{ textAlign: "center" }}
        >
          {t("Go to main project")}
        </Button>
      }
    >
      <AlertTitle>{t("Subcontracting project")}</AlertTitle>
      {t("This project is linked to a main project.")}
    </Alert>
  );
};
