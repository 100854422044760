import { gql, useApolloClient } from "@apollo/client";
import { FileInput } from "../../../commons/inputs/FileInput.js";
import { useRequestImportXiopdUploadUrlMutation } from "./XiopdFileUploader.generated.js";

export const XiopdFileUploader = (props: {
  innerRef: any;
  onUploadFile: (file: File) => Promise<void>;
  onCancel?: () => void;
  onStart?: () => void;
  onError?: (e: any) => void;
}) => {
  const client = useApolloClient();

  const [requestImportUploadUrlMutation] =
    useRequestImportXiopdUploadUrlMutation({
      client,
    });

  let acceptType = ".xml,.XML";

  return (
    <FileInput
      innerRef={props.innerRef}
      accept={acceptType}
      multiple={false}
      onStart={props.onStart}
      onCancel={props.onCancel}
      onComplete={async file => {
        const docImportUrlResult = await requestImportUploadUrlMutation({
          variables: {
            input: {
              filename: file.name,
            },
          },
        });

        await fetch(
          docImportUrlResult.data!.requestXiopdImportUploadUrl.uploadUrl,
          {
            method: "PUT",
            body: file,
          }
        ).catch(err => {
          console.info(err);
        });

        if (props.innerRef.current) {
          props.innerRef.current.value = "";
        }

        await props.onUploadFile(file);
      }}
    />
  );
};
