import { CardContainer, FormattedPercentage, FormattedPrice } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { BigNumber } from "bignumber.js";
import { useTranslate } from "@tolgee/react";
import { ItemCalculationFragment } from "../Fragments.generated.js";

interface Props {
  calculated: ItemCalculationFragment;
}

export const ProfitabilityBox = ({ calculated }: Props) => {
  const { t } = useTranslate(["QuotePriceSummary", "Global"]);
  const netPrice = new BigNumber(calculated.priceNetTotal);
  const materialCost = new BigNumber(
    new BigNumber(calculated.materialBuyingPricePerUnit)
      .multipliedBy(calculated.quantity)
      .toFixed(2)
  );
  const workCost = new BigNumber(
    new BigNumber(calculated.workBuyingPricePerUnit)
      .multipliedBy(calculated.quantity)
      .toFixed(2)
  );
  const totalCost = new BigNumber(materialCost.plus(workCost).toFixed(2));
  const totalProfit = new BigNumber(netPrice.minus(totalCost).toFixed(2));
  const totalMargin =
    totalCost.toNumber() !== 0
      ? new BigNumber(netPrice.dividedBy(totalCost).minus(1).toFixed(2))
      : totalProfit.toNumber() === 0
        ? new BigNumber(0)
        : totalProfit.toNumber() > 0
          ? new BigNumber(1)
          : new BigNumber(-1);

  return (
    <CardContainer
      title={t("Profitability", {
        ns: "QuotePriceSummary",
      })}
      isExpandable
      CollapsedHint={
        <Typography variant="h3" component="div">
          <FormattedPercentage value={totalMargin.toNumber()} />
        </Typography>
      }
    >
      <Stack p={1} direction="column" spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h4" component="div">
            {t("Net price", {
              ns: "QuotePriceSummary",
            })}
          </Typography>
          <Typography variant="h4" component="div">
            <FormattedPrice value={netPrice.toNumber()} />
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography component="div">
            {t("Total material cost", {
              ns: "QuotePriceSummary",
            })}
          </Typography>
          <Typography component="div">
            <FormattedPrice value={materialCost.toNumber()} />
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography component="div">
            {t("Total work cost", {
              ns: "QuotePriceSummary",
            })}
          </Typography>
          <Typography component="div">
            <FormattedPrice value={workCost.toNumber()} />
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="flex-start"
          pt={1}
        >
          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography variant="h3" component="div">
              {t("Profit", {
                ns: "QuotePriceSummary",
              })}
            </Typography>
            <Typography variant="h3" component="div">
              <FormattedPercentage value={totalMargin.toNumber()} />
            </Typography>
          </Stack>
          <Typography
            variant="h3"
            component="div"
            style={{ textDecoration: "underline" }}
          >
            <FormattedPrice value={totalProfit.toNumber()} />
          </Typography>
        </Stack>
      </Stack>
    </CardContainer>
  );
};
