import { useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateQuoteRequestMutation } from "../Requests.generated.js";
import { RequestGetQuoteModal } from "../modals/RequestGetQuoteModal.js";
import { useOutsourceItemMutation } from "./RequestQuoteForOutsourcingButton.generated.js";

interface OriginQuote {
  quoteId: string;
  itemId: string;
  itemTitle: string;
}

type Props = {
  projectId: string;
  originQuote?: OriginQuote;
  refetchQueries?: string[];
  Button: React.ReactElement;
};

export function RequestQuoteForOutsourcingButton({
  projectId,
  originQuote,
  refetchQueries,
  Button,
}: Props) {
  const navigate = useNavigate();

  const [handleOutsourceQuoteRequest, loading] = useRequestQuoteForOutsourcing({
    projectId,
    refetchQueries,
  });

  async function handleCreateQuoteRequest(originQuote: OriginQuote) {
    const { requestId } = await handleOutsourceQuoteRequest(originQuote);
    navigate(`/projects/${projectId}/requests/${requestId}`);
  }

  if (!originQuote) {
    return (
      <WithQuoteInputModal
        projectId={projectId}
        handleCreateQuoteRequest={handleCreateQuoteRequest}
        Button={Button}
      />
    );
  }

  return React.cloneElement(Button, {
    onClick: () => handleCreateQuoteRequest(originQuote),
  });
}

function WithQuoteInputModal({
  projectId,
  handleCreateQuoteRequest,
  Button,
}: {
  projectId: string;
  handleCreateQuoteRequest: (quote: OriginQuote) => Promise<void>;
  Button: React.ReactElement;
}) {
  return (
    <ModalOpenButton
      Modal={RequestGetQuoteModal}
      modalProps={{
        projectId,
        handleComplete: (quote, handleClose) => {
          handleClose();
          handleCreateQuoteRequest({
            quoteId: quote.id,
            itemTitle: quote.title,
            itemId: quote.rootItemId,
          });
        },
      }}
    >
      {Button}
    </ModalOpenButton>
  );
}

export function useRequestQuoteForOutsourcing(
  args: Pick<Props, "projectId" | "refetchQueries">
) {
  const { t } = useTranslate(["Requests", "QuoteItem"]);

  const client = useApolloClient();
  const [outsourceItem] = useOutsourceItemMutation({ client });
  const [createQuoteRequest, { loading }] = useCreateQuoteRequestMutation({
    client,
    refetchQueries: args.refetchQueries,
  });

  const handleOutsourceQuoteRequest = async (originQuote: OriginQuote) => {
    const result = await outsourceItem({
      variables: {
        input: {
          itemId: originQuote.itemId,
          docId: originQuote.quoteId,
          projectId: args.projectId,
          rootItemDescription: t(
            "Subcontract requirements copied from “{title}”",
            {
              ns: "QuoteItem",
              title: originQuote.itemTitle,
            }
          ),
        },
      },
    });

    const { subcontract } = result.data!.subcontract;

    const requestorDocId = subcontract.subRequirement.id;
    const requestTitle = `${t("Request for Quote", {
      ns: "Requests",
    })} - ${subcontract.subRequirement.title}`;

    const res = await createQuoteRequest({
      variables: {
        input: {
          projectId: args.projectId,
          categories: [],
          title: requestTitle,
          description: "",
          recipientSystemOrganisationIds: [],
          requestorDocId,
        },
      },
    });
    const requestId = res.data?.createQuoteRequest.request.id;

    if (!requestId) throw new Error(`could not create request`);

    return { requestId };
  };

  return [handleOutsourceQuoteRequest, loading] as const;
}
