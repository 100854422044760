import { Locale } from "@msys/tolgee";
import { Currency } from "@msys/ui";
import { Ajax, L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";
import { setCurrencyCode } from "@syncfusion/ej2-base/src/internationalization.js";

export async function loadCalendarTranslation(locale: Locale) {
  const [translation, n1, n2, n3, n4, s, s2] = await Promise.all([
    ajaxGetPromise(
      `${import.meta.env.VITE_ROUTER_BASENAME}/ej2-locale/${locale}.json`
    ),
    ajaxGetPromise(
      `${
        import.meta.env.VITE_ROUTER_BASENAME
      }/cldr-data/main/${locale}/currencies.json`
    ),
    ajaxGetPromise(
      `${
        import.meta.env.VITE_ROUTER_BASENAME
      }/cldr-data/main/${locale}/timeZoneNames.json`
    ),
    ajaxGetPromise(
      `${
        import.meta.env.VITE_ROUTER_BASENAME
      }/cldr-data/main/${locale}/numbers.json`
    ),
    ajaxGetPromise(
      `${
        import.meta.env.VITE_ROUTER_BASENAME
      }/cldr-data/main/${locale}/ca-gregorian.json`
    ),
    ajaxGetPromise(
      `${
        import.meta.env.VITE_ROUTER_BASENAME
      }/cldr-data/supplemental/currencyData.json`
    ),
    ajaxGetPromise(
      `${
        import.meta.env.VITE_ROUTER_BASENAME
      }/cldr-data/supplemental/numberingSystems.json`
    ),
  ]);

  L10n.load(translation);
  loadCldr(n1, n2, n3, n4, s, s2);

  setCulture(locale);
  setCurrencyCode(Currency.Eur);
}

const ajaxGetPromise = (file: string): Promise<Object> =>
  new Promise<Object>((resolve, reject) => {
    const ajax = new Ajax(file, "GET", true);
    ajax.send().then(
      (value: unknown) => {
        resolve(JSON.parse(value as string));
      },
      (reason: string) => {
        reject(reason);
      }
    );
  });
