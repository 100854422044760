/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type PurchaseOrderTitle_OrderFragment = { __typename: 'Order', id: string, number: string };

export type PurchaseOrderTitle_OrderNodeFragment = { __typename: 'OrderNode', id: string, number: string };

export const PurchaseOrderTitle_OrderFragmentDoc = gql`
    fragment PurchaseOrderTitle_Order on Order {
  id
  number
}
    `;
export const PurchaseOrderTitle_OrderNodeFragmentDoc = gql`
    fragment PurchaseOrderTitle_OrderNode on OrderNode {
  id
  number
}
    `;