import React from "react";
import { BrowserRouter } from "react-router-dom";
import { URLSearchParamsProvider } from "../commons/hooks/useUrlSearchParams.js";

export function Router({ children }: React.PropsWithChildren<{}>) {
  return (
    <BrowserRouter basename={import.meta.env.VITE_ROUTER_BASENAME ?? undefined}>
      <URLSearchParamsProvider>{children}</URLSearchParamsProvider>
    </BrowserRouter>
  );
}
