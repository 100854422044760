/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuildingSelectModalQueryVariables = Types.Exact<{
  owningOrganisationId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type BuildingSelectModalQuery = { buildings: { __typename: 'BuildingConnection', edges: Array<{ __typename: 'BuildingEdge', node: { __typename: 'Building', id: string, title: string, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null }, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, pathForPdf: string }> } }> } };

export type BuildingSelectModal_BuildingFragment = { __typename: 'Building', id: string, title: string, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null }, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, pathForPdf: string }> };

export type BuildingSelectModal_ItemFragment = { __typename: 'Item', id: string, title: string, pathForPdf: string };

export const BuildingSelectModal_ItemFragmentDoc = gql`
    fragment BuildingSelectModal_Item on Item {
  id
  title
  pathForPdf
}
    `;
export const BuildingSelectModal_BuildingFragmentDoc = gql`
    fragment BuildingSelectModal_Building on Building {
  id
  title
  rootItem {
    id
    originVersionNumber
  }
  buildingAddress {
    ...AddressDetails__Address
  }
  items {
    id
    originVersionNumber
    ...BuildingSelectModal_Item
  }
}
    ${AddressDetails__AddressFragmentDoc}
${BuildingSelectModal_ItemFragmentDoc}`;
export const BuildingSelectModalDocument = gql`
    query BuildingSelectModal($owningOrganisationId: ID) {
  buildings(limit: 0, filters: {owningOrganisationId: $owningOrganisationId}) {
    edges {
      node {
        id
        ...BuildingSelectModal_Building
      }
    }
  }
}
    ${BuildingSelectModal_BuildingFragmentDoc}`;

/**
 * __useBuildingSelectModalQuery__
 *
 * To run a query within a React component, call `useBuildingSelectModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingSelectModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingSelectModalQuery({
 *   variables: {
 *      owningOrganisationId: // value for 'owningOrganisationId'
 *   },
 * });
 */
export function useBuildingSelectModalQuery(baseOptions?: Apollo.QueryHookOptions<BuildingSelectModalQuery, BuildingSelectModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingSelectModalQuery, BuildingSelectModalQueryVariables>(BuildingSelectModalDocument, options);
      }
export function useBuildingSelectModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingSelectModalQuery, BuildingSelectModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingSelectModalQuery, BuildingSelectModalQueryVariables>(BuildingSelectModalDocument, options);
        }
export function useBuildingSelectModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BuildingSelectModalQuery, BuildingSelectModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BuildingSelectModalQuery, BuildingSelectModalQueryVariables>(BuildingSelectModalDocument, options);
        }
export type BuildingSelectModalQueryHookResult = ReturnType<typeof useBuildingSelectModalQuery>;
export type BuildingSelectModalLazyQueryHookResult = ReturnType<typeof useBuildingSelectModalLazyQuery>;
export type BuildingSelectModalSuspenseQueryHookResult = ReturnType<typeof useBuildingSelectModalSuspenseQuery>;
export type BuildingSelectModalQueryResult = Apollo.QueryResult<BuildingSelectModalQuery, BuildingSelectModalQueryVariables>;