import { Capacitor } from "@capacitor/core";
import { NotificationsActive as NotificationsActiveIcon } from "@mui/icons-material";
import { Alert, Box, Button } from "@mui/material";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";
import { usePushNotifications } from "../../../global/PushNotificationsProvider.js";
import { useTranslate } from "@tolgee/react";

export const DashboardAppNotificationsBox = () => {
  const { t } = useTranslate("Dashboard");
  const { currentUser: viewer } = useUserData();
  const { requestPushPermission } = usePushNotifications();

  return Capacitor.isNativePlatform() ? (
    !viewer!.viewerHasPushNotificationToken ? (
      <>
        <Box>
          <Alert variant="outlined" severity="warning">
            {t("Notifications not enabled")}
          </Alert>
        </Box>
        <Box>
          <Button
            onClick={requestPushPermission}
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<NotificationsActiveIcon />}
          >
            {t("Enable notifications")}
          </Button>
        </Box>
      </>
    ) : (
      <Box>
        <Alert variant="outlined" severity="success">
          {t("Notifications enabled")}
        </Alert>
      </Box>
    )
  ) : null;
};
