import { ConnectDragPreview, ConnectDragSource } from "react-dnd";
import { NodeData, TreeItem } from "react-sortable-tree";
import { ItemType } from "../../clients/graphqlTypes.js";
import { VirtualItem__DocumentItemFragment } from "./types.generated.js";
import { InputComponent, ItemComponent } from "../trees/types.js";

export type VirtualItem = VirtualItem__DocumentItemFragment;

export interface VirtualTreeItem<T extends VirtualItem> extends TreeItem {
  id: string;
  parentId: string | null;
  item: T;
  type: ItemType | null;
  expanded: boolean;
  selected: boolean;
  isInput: boolean;
  rank: number;
}

export interface VirtualTreeItemContext<T extends VirtualItem>
  extends TreeItem {
  items: T[];
  allItems?: T[];
  rootItem?: T;
  rootItemComponent?: ItemComponent<T>;
  itemComponent?: ItemComponent<T>;
  inputComponent?: InputComponent<T, T>;
  selectedItemId: string | null;
  lastSelectedItemId: string | null;
}

export interface VirtualTreeTableContext<T extends VirtualItem>
  extends VirtualTreeItemContext<T> {
  EmptyRow?: JSX.Element | null;
  HeaderRow?: JSX.Element | null;
  headerTitle?: string | null;
}

export interface VirtualItemTreeRef {
  updateTree: () => TreeItem[];
  updatePosition: () => void;
  treeItemsCount: number;
}

export interface NodeRendererProps<T extends VirtualItem> {
  node: VirtualTreeItem<T>;
  path: (string | number)[];
  treeIndex: number;
  canDrag: boolean;
  scaffoldBlockPxWidth: number;

  toggleChildrenVisibility?(data: NodeData): void;

  lowerSiblingCounts: number[];
  swapDepth?: number;
  swapFrom?: number;
  swapLength?: number;
  listIndex: number;
  treeId: string;

  connectDragPreview: ConnectDragPreview;
  connectDragSource: ConnectDragSource;
  parentNode?: VirtualTreeItem<T>;
  isDragging: boolean;
  didDrop: boolean;
  draggedNode?: VirtualTreeItem<T>;
  isOver: boolean;
  canDrop?: boolean;
}

export enum TreeViewMode {
  Item = "Item",
  Table = "Table",
}

export type AdditionalItemInput = {
  itemId: string;
  itemParentId: string;
  itemParentType: ItemType;
  itemRank: number;
  position: "above" | "below";
};
