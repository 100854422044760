import { useApolloClient } from "@apollo/client";
import { Add as AddIcon } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { FileInput } from "../../../commons/inputs/FileInput.js";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import {
  PimCsvColumnMappingProcess,
  PimCsvColumnMappingProcessRef,
} from "../PimCsvColumnMappingProcess.js";
import {
  useAddPimImportMutation,
  usePimRequestImportationUploadUrlMutation,
} from "../PimImportations.generated.js";

export const AddPimImportationButton = ({
  supplierId,
}: {
  supplierId: string;
}) => {
  const client = useApolloClient();
  const [status, setStatus] = React.useState<"idle" | "loading" | "error">(
    "idle"
  );
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const pimMappingProcessRef = React.useRef<PimCsvColumnMappingProcessRef>();

  const [pimRequestImportationUploadUrlMutation] =
    usePimRequestImportationUploadUrlMutation({
      client,
    });

  const [addPimImport] = useAddPimImportMutation({
    client,
    refetchQueries: [namedOperations.Query.PimImportations],
  });

  return (
    <>
      <IconButton
        color="primary"
        onClick={() => {
          fileInputRef.current!.click();
        }}
        size="small"
        disabled={status === "loading"}
      >
        {status === "loading" ? (
          <CircularProgress size={"1.25rem"} />
        ) : (
          <AddIcon />
        )}
      </IconButton>
      <FileInput
        innerRef={fileInputRef}
        accept={"*"}
        multiple={true}
        onStart={() => {
          setStatus("loading");
        }}
        onCancel={() => {
          setStatus("idle");
        }}
        onComplete={async (filelist: File[]) => {
          const uploadFile = async (file: File) => {
            const result = await pimRequestImportationUploadUrlMutation({
              variables: {
                input: {
                  supplierId,
                  filename: file.name,
                },
              },
            });

            await fetch(result.data!.pimRequestImportationUploadUrl.uploadUrl, {
              method: "PUT",
              body: file,
            }).catch(err => {
              setStatus("error");
              console.info(err);
            });

            return { filename: file.name };
          };

          try {
            const files = Array.from(filelist);
            const zipFiles = files.filter(
              f =>
                f.type === "application/zip" ||
                f.name.toLocaleLowerCase().endsWith(".zip")
            );
            const csvFiles = files.filter(
              f =>
                f.type === "text/csv" ||
                f.name.toLocaleLowerCase().endsWith(".csv")
            );
            const restFiles = files.filter(
              f =>
                !(
                  f.type === "application/zip" ||
                  f.name.toLocaleLowerCase().endsWith(".zip") ||
                  f.type === "text/csv" ||
                  f.name.toLocaleLowerCase().endsWith(".csv")
                )
            );

            for (const csvFile of csvFiles) {
              const { filename } = await uploadFile(csvFile);
              const jsonMapping = await pimMappingProcessRef.current?.start({
                filename,
              });
              if (jsonMapping) {
                await addPimImport({
                  variables: {
                    input: {
                      filename: filename,
                      supplierId,
                      configuration: JSON.stringify({
                        separator: ";",
                        decimal_symbol: ",",
                        columns_mapping: jsonMapping,
                      }),
                    },
                  },
                });
              }
            }

            for (const zipFile of zipFiles) {
              const { filename } = await uploadFile(zipFile);
              await addPimImport({
                variables: {
                  input: {
                    filename: filename,
                    supplierId,
                    configuration: null,
                  },
                },
              });
            }

            if (restFiles.length > 0) {
              const zip = await import("@zip.js/zip.js");
              const zipWriter = new zip.ZipWriter(
                new zip.BlobWriter("application/zip"),
                { useWebWorkers: true, bufferedWrite: true }
              );
              for (const file of restFiles) {
                await zipWriter.add(file.name, new zip.BlobReader(file));
              }

              const zipBlob = await zipWriter.close();

              // const blobURL = URL.createObjectURL(zipBlob);
              // const tab = window.open(blobURL, "_blank");
              // if (tab) tab.focus();
              // return;

              const zipFile = new File(
                [zipBlob],
                `catalog_${moment().format("YYYY-MM-DD_hh-mm-ss")}.zip`,
                { type: "application/zip" }
              );

              const { filename } = await uploadFile(zipFile);

              await addPimImport({
                variables: {
                  input: {
                    filename: filename,
                    supplierId,
                    configuration: null,
                  },
                },
              });
            }

            // TODO: fix
            await client.reFetchObservableQueries();

            setStatus("idle");
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
            setStatus("error");
            console.error(e);
            Sentry.captureException(e);
          }
        }}
      />
      <PimCsvColumnMappingProcess
        ref={pimMappingProcessRef}
        supplierId={supplierId}
      />
    </>
  );
};
