import { gql } from "@apollo/client";
import { CardContainer, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Box, IconButton, List, Pagination } from "@mui/material";
import React from "react";
import { SHORT_LIST_RESULTS_PER_PAGE } from "../../../constants.js";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { CrmCompanyBuildingsBox__BuildingFragment } from "./CrmCompanyBuildingsBox.generated.js";
import { useTranslate } from "@tolgee/react";
import { BuildingIcon } from "../../buildings/BuildingIcon.js";
import BuildingRow from "../../buildings/components/BuildingRow.js";
import { BuildingCreateModal } from "../../buildings/modals/BuildingCreateModal.js";

interface Props {
  crmCompanyId: string;
  buildings: CrmCompanyBuildingsBox__BuildingFragment[];
  isLoading: boolean;
}

export const CrmCompanyBuildingsBox = ({
  crmCompanyId,
  buildings,
  isLoading,
}: Props) => {
  const { t } = useTranslate("Projects");

  const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const totalCount = buildings.length;

  const offset = (page - 1) * SHORT_LIST_RESULTS_PER_PAGE;
  const limit = SHORT_LIST_RESULTS_PER_PAGE;
  const pageCount = Math.ceil(totalCount / SHORT_LIST_RESULTS_PER_PAGE);

  return (
    <CardContainer
      Icon={<BuildingIcon />}
      title={t("Buildings")}
      itemCount={totalCount}
      ActionButton={
        <ModalOpenButton
          Modal={BuildingCreateModal}
          modalProps={{
            buildingOwningOrganisationId: crmCompanyId,
            refetchQueries: [namedOperations.Query.CrmCompanyProfile],
          }}
        >
          <IconButton size="small" color="primary">
            <AddIcon />
          </IconButton>
        </ModalOpenButton>
      }
      isLoading={isLoading}
    >
      <List disablePadding>
        {buildings
          .slice(offset, offset + limit)
          .map((building, index, array) => (
            <BuildingRow
              key={building.id}
              building={building}
              divider={index < array.length - 1}
            />
          ))}
      </List>
      {pageCount > 1 && (
        <Box padding={1}>
          <Pagination
            size="small"
            count={pageCount}
            page={page}
            onChange={handleChange}
            sx={{ ".MuiPagination-ul": { justifyContent: "center" } }}
          />
        </Box>
      )}
    </CardContainer>
  );
};
