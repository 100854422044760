import { gql, useApolloClient } from "@apollo/client";
import { Autocomplete } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { debounce } from "lodash-es";
import React from "react";
import {
  QuerySearchSuppliersSortBy,
  SortDirection,
} from "../../../clients/graphqlTypes.js";
import {
  SearchSuppliers__SupplierFragment,
  useSupplierSelect__SearchSuppliersLazyQuery,
} from "./SupplierSelect.generated.js";

interface Props {
  label?: string;
  excludeIfAlreadyCrm: boolean;
  required?: boolean;
  disabled?: boolean;
  productSupplierId: string | null;
  onChange: (productSupplier: SearchSuppliers__SupplierFragment | null) => void;
}

export const SupplierSelect = ({
  label,
  excludeIfAlreadyCrm,
  required = false,
  disabled = false,
  productSupplierId,
  onChange,
}: Props) => {
  const { t } = useTranslate(["Product", "Global"]);
  const [inputValue, setInputValue] = React.useState("");
  const { supplierOrganisations, totalCount, debouncedRefetch, loading } =
    useSupplierOrganisations(
      excludeIfAlreadyCrm,
      productSupplierId,
      inputValue,
      setInputValue
    );

  return (
    <Autocomplete
      placeholder={t("Type to search", { ns: "Global" })}
      inputLabel={label ?? t("Supplier", { ns: "Product" })}
      inputValue={inputValue}
      options={[
        ...supplierOrganisations,
        ...(totalCount > supplierOrganisations.length
          ? [
              {
                title: t("Type to see more...", { ns: "Global" }),
                id: "__type_to_see_more__" as const,
              },
            ]
          : []),
      ]}
      getOptionLabel={option => option.title}
      getOptionDisabled={option => option.id === "__type_to_see_more__"}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
          debouncedRefetch(value);
          if (productSupplierId) onChange(null);
        }
      }}
      value={
        supplierOrganisations.find(
          supplier => supplier.id === productSupplierId
        ) ?? null
      }
      onChange={value => {
        if (value && "__typename" in value) {
          setInputValue(value.title);
          onChange(value);
        } else if (!value) {
          setInputValue("");
          onChange(null);
        }
      }}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      loading={loading}
      required={required}
      disabled={disabled}
    />
  );
};

const LIMIT = 10;

const SUPPLIER_SORTING: {
  column: QuerySearchSuppliersSortBy;
  direction: SortDirection;
}[] = [
  {
    column: "name",
    direction: "asc",
  },
];

function useSupplierOrganisations(
  excludeIfAlreadyCrm: boolean,
  productSupplierId: string | null,
  inputValue: string,
  setInputValue: (value: string) => void
) {
  const client = useApolloClient();
  const [fetchQuery, query] = useSupplierSelect__SearchSuppliersLazyQuery({
    client,
    variables: {
      excludeIfAlreadyCrm,
      limit: LIMIT,
      sorting: SUPPLIER_SORTING,
    },
    fetchPolicy: "network-only",
  });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          sorting: SUPPLIER_SORTING,
          excludeIfAlreadyCrm,
          search: value,
        },
      });
    },
    [fetchQuery, excludeIfAlreadyCrm]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (productSupplierId) {
        const result = await fetchQuery({
          variables: {
            limit: 1,
            sorting: SUPPLIER_SORTING,
            organisationIds: [productSupplierId],
            excludeIfAlreadyCrm,
          },
        });
        const option = result.data?.searchSuppliers.edges[0].node.organisation;
        if (option) setInputValue(option.title);
      } else {
        await fetchQuery({
          variables: {
            limit: LIMIT,
            sorting: SUPPLIER_SORTING,
            search: inputValueRef.current,
            excludeIfAlreadyCrm,
          },
        });
      }
    })();
  }, [productSupplierId, excludeIfAlreadyCrm, fetchQuery, setInputValue]);

  const supplierOrganisations =
    (query.data ?? query.previousData)?.searchSuppliers.edges.map(
      edge => edge.node.organisation
    ) ?? [];
  const totalCount =
    (query.data ?? query.previousData)?.searchSuppliers.totalCount ?? 0;

  return {
    supplierOrganisations,
    refetch,
    totalCount,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.search &&
          query?.variables?.search !== inputValue
      ),
  };
}
