import { useApolloClient } from "@apollo/client";
import { MenuButton, MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { BugReport as BugReportIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { RecentActors as RecentActorsIcon } from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { useFeature } from "../../../common/FeatureFlags.js";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission.js";
import { DocExportAsExcelMenuItem } from "../importExport/docExcelExport/DocExportAsExcelMenuItem.js";
import { DocExportAsM1ImpexJsonMenuItem } from "../importExport/docM1ImpexJsonExport/DocExportAsM1ImpexJsonMenuItem.js";
import { ExportAsGaebMenuItem } from "../importExport/gaebExport/ExportAsGaebMenuItem.js";
import { ExportAsXiopdMenuItem } from "../importExport/xiopdExport/ExportAsXiopdMenuItem.js";
import { QuoteTemplateCreateFromItemButton } from "../templates/quote/buttons/QuoteTemplateCreateFromItemMenuItem.js";
import {
  QuoteMenu_ProjectFragment,
  QuoteMenu_QuoteFragment,
} from "./QuoteMenu.generated.js";
import { useDeleteQuoteMutation } from "./Quotes.generated.js";
import { DuplicateQuoteButton } from "./buttons/DuplicateQuoteButton.js";
import { ImportRequirementButton } from "./buttons/ImportRequirementButton.js";
import { QuoteBulkUpdateProductPricingsButton } from "./buttons/QuoteBulkUpdateProductPricingsButton.js";
import { QuoteBulkUpdateProductsButton } from "./buttons/QuoteBulkUpdateProductsButton.js";
import { QuoteExternalIdentifierButton } from "./buttons/QuoteExternalIdentifierButton.js";
import { ToggleQuoteBindingMenuItem } from "./buttons/ToggleQuoteBindingMenuItem.js";
import { QuoteDocumentActorsModal } from "./modals/QuoteDocumentActorsModal.js";
import { QuoteRecomputeExpressionDependencyGraphButton } from "./buttons/QuoteRecomputeExpressionDependencyGraphButton.jsx";

interface Props {
  project: QuoteMenu_ProjectFragment;
  quote: QuoteMenu_QuoteFragment;
  pathToDoc: string;
  pathToDocList: string;
  onUpdateDataRefetchQueries?: string[];
}

export const QuoteMenu = ({
  quote,
  project,
  pathToDoc,
  pathToDocList,
  onUpdateDataRefetchQueries,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslate(["Quote", "QuoteItem", "Global"]);
  const isDebugOutputEnabled = useFeature("DebugOutput");

  return (
    <MenuButton>
      <RestrictedByCapabilityWithDebug capability="QUOTING">
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES_SETTINGS"
          document={quote}
        >
          {quote.agreement !== "YES" && (
            <>
              <ToggleQuoteBindingMenuItem
                doc={quote}
                docId={quote.id}
                projectId={project.id}
              />
              <Divider />
            </>
          )}
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
      <RestrictedByDocumentPermissionWithDebug
        permission="MANAGE_QUOTES_ACTORS"
        document={quote}
      >
        <ModalOpenButton
          Modal={QuoteDocumentActorsModal}
          modalProps={{ projectId: project.id, quoteId: quote.id }}
        >
          <MenuItemWithIcon icon={<RecentActorsIcon />}>
            {t("Manage relevant parties", {
              ns: "Global",
            })}
          </MenuItemWithIcon>
        </ModalOpenButton>
      </RestrictedByDocumentPermissionWithDebug>
      <RestrictedByCapabilityWithDebug capability="QUOTING">
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES_SETTINGS"
          document={quote}
        >
          <QuoteExternalIdentifierButton
            projectId={project.id}
            quoteId={quote.id}
            type="menu-item"
          />
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
      <RestrictedByCapabilityWithDebug capability="QUOTING">
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES"
          document={quote}
        >
          {quote.agreement !== "YES" && (
            <ImportRequirementButton
              projectId={project.id}
              quoteId={quote.id}
              pathToDoc={pathToDoc}
              type="menu-item"
            />
          )}
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
      <RestrictedByCapabilityWithDebug capability="PIM">
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES"
          document={quote}
        >
          <QuoteBulkUpdateProductsButton
            docId={quote.id}
            projectId={project.id}
          />
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
      <RestrictedByCapabilityWithDebug capability="PIM">
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES"
          document={quote}
        >
          <QuoteBulkUpdateProductPricingsButton
            docId={quote.id}
            projectId={project.id}
          />
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
      <RestrictedByDocumentPermissionWithDebug
        permission="MANAGE_QUOTES"
        document={quote}
      >
        <DuplicateQuoteButton
          docId={quote.id}
          projectId={project.id}
          pathToDocList={pathToDocList}
        />
      </RestrictedByDocumentPermissionWithDebug>
      <RestrictedByCapabilityWithDebug capability="TEMPLATING">
        <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
          <QuoteTemplateCreateFromItemButton
            projectId={project.id}
            docId={quote.id}
            itemId={null}
            docHasAnyPublishedVersion={false}
          />
        </RestrictedByOrganisationPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
      <ExportAsXiopdMenuItem
        projectId={project.id}
        docId={quote.id}
        exportDestination={"UNKNOWN"}
      />
      <ExportAsXiopdMenuItem
        projectId={project.id}
        docId={quote.id}
        exportDestination={"MSYS"}
      />
      <ExportAsGaebMenuItem
        projectId={project.id}
        docId={quote.id}
        type={"p84"}
      />
      <ExportAsGaebMenuItem
        projectId={project.id}
        docId={quote.id}
        type={"p94"}
      />
      <DocExportAsExcelMenuItem projectId={project.id} docId={quote.id} />
      {isDebugOutputEnabled && (
        <DocExportAsM1ImpexJsonMenuItem
          projectId={project.id}
          docId={quote.id}
        />
      )}
      <RestrictedByCapabilityWithDebug capability="QUOTING">
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES"
          document={quote}
        >
          <Divider />
          <QuoteRecomputeExpressionDependencyGraphButton
            docId={quote.id}
            projectId={project.id}
            refetchQueries={onUpdateDataRefetchQueries}
          />
          <MenuItemWithIcon
            icon={<BugReportIcon />}
            onClick={() => navigate(`${pathToDoc}/troubleshoot`)}
          >
            {t("Troubleshoot", {
              ns: "Global",
            })}
          </MenuItemWithIcon>
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
      <RestrictedByCapabilityWithDebug capability="QUOTING">
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES_SETTINGS"
          document={quote}
        >
          {quote.agreement !== "YES" && (
            <>
              <Divider />
              <DeleteQuoteButton
                projectId={project.id}
                quote={quote}
                pathToDocList={pathToDocList}
              />
            </>
          )}
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    </MenuButton>
  );
};

const DeleteQuoteButton = ({
  projectId,
  quote,
  pathToDocList,
}: {
  projectId: string;
  quote: { id: string; isPublished: boolean; canBeDeleted: boolean };
  pathToDocList: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslate(["Quote", "QuoteItem", "Global"]);

  const client = useApolloClient();
  const [deleteQuoteMutation, { loading: deleteQuoteLoading }] =
    useDeleteQuoteMutation({
      client,
    });

  const deleteQuote = async () => {
    await deleteQuoteMutation({
      variables: {
        input: {
          quoteId: quote.id,
          projectId,
        },
      },
    });

    navigate(pathToDocList, { replace: true });
  };

  const deleteButton = (
    <MenuItemWithIcon
      icon={<DeleteIcon />}
      onClick={deleteQuote}
      disabled={deleteQuoteLoading || quote.isPublished || !quote.canBeDeleted}
    >
      {t("Delete Quote", {
        ns: "Quote",
      })}
    </MenuItemWithIcon>
  );

  if (quote.isPublished) {
    return (
      <Tooltip
        title={t("Quote is published and can not be deleted", {
          ns: "Quote",
        })}
      >
        <div>{deleteButton}</div>
      </Tooltip>
    );
  }

  return deleteButton;
};
