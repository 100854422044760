import { gql } from "@apollo/client";
import { Switch } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { DecisionContingencySwitch_ItemFragment } from "./DecisionContingencySwitch.generated.js";

interface Props<T extends DecisionContingencySwitch_ItemFragment> {
  item: T;
  disabled?: boolean;
  loading?: boolean;
  expandedItemIds: string[] | undefined;
  handleContingencyPreselectionChange: (
    itemId: string,
    preselection: boolean,
    expandedItemIds?: string[]
  ) => void | Promise<void>;
}

export const DecisionContingencySwitch = <
  T extends DecisionContingencySwitch_ItemFragment,
>({
  item,
  disabled,
  loading,
  handleContingencyPreselectionChange,
  expandedItemIds,
}: Props<T>) => {
  const { t } = useTranslate(["Decisions", "Global"]);
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Switch
        type="checkbox"
        label={t("Include this item", {
          ns: "Decisions",
        })}
        // labelPlacement="start"
        labelVariant="h3"
        disabled={loading || disabled}
        checked={item.decisionContingentItemPreselection ?? undefined}
        onChange={(_, value) =>
          handleContingencyPreselectionChange(item.id, value, expandedItemIds)
        }
      />
    </Stack>
  );
};
