import { useApolloClient } from "@apollo/client";
import { assertNever, Capabilities, getDataOrNull } from "@msys/common";
import {
  CardContainer,
  DataGrid,
  FormattedPrice,
  ListItem,
  Tabs,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Done as DoneIcon } from "@mui/icons-material";
import { ShoppingCartOutlined as ShoppingCartOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  PermissionName,
  ProjectDocumentOrigin,
} from "../../../../clients/graphqlTypes.js";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability.js";
import {
  Project,
  RestrictedByProjectPermissionWithDebug,
} from "../../../auth/RestrictedByProjectPermission.js";
import { useRestrictionFilter } from "../../../auth/useRestrictionFilter.js";
import { useUserData } from "../../../auth/useUserData.js";
import { CollectionView } from "../../../commons/hooks/useCollectionView.js";
import { useStateWithUrlParam } from "../../../commons/hooks/useStateWithUrlParam.js";
import {
  TabOptionWithRestriction,
  TabsWithRestriction,
} from "../../../commons/TabsWithRestriction.js";
import { SHORT_LIST_RESULTS_PER_PAGE } from "../../../constants.js";
import { RequirementRowFragment } from "../../../main-routes/projects/ProjectRequirements.generated.js";
import { CreateInvoiceButton } from "../../invoices/buttons/CreateInvoiceButton.js";
import { useInvoiceTypes } from "../../invoices/hooks/useInvoiceTypes.js";
import { InvoiceIcon } from "../../invoices/InvoiceIcon.js";
import { InvoiceListItem } from "../../invoices/InvoiceListItem.js";
import { InvoiceStatusBadge } from "../../invoices/InvoiceStatusBadge.js";
import { PurchaseOrderCreateButton } from "../../purchase-orders/buttons/PurchaseOrderCreateButton.js";
import { NodePurchaseOrderListItem } from "../../purchase-orders/PurchaseOrderListItem.js";
import { PurchaseOrderStatusBadge } from "../../purchase-orders/PurchaseOrderStatusBadge.js";
import { PurchaseOrderTitle } from "../../purchase-orders/PurchaseOrderTitle.js";
import { CreateQuoteButton } from "../../quotes/buttons/CreateQuoteButton.js";
import { QuoteIcon } from "../../quotes/QuoteIcon.js";
import { QuoteListItem } from "../../quotes/QuoteListItem.js";
import {
  IncomingQuoteStatusBadge,
  QuoteStatusBadge,
} from "../../quotes/QuoteStatusBadge.js";
import { CreateRequirementButton } from "../../requirements/buttons/CreateRequirementButton.js";
import { RequirementIcon } from "../../requirements/RequirementIcon.js";
import { RequirementListItem } from "../../requirements/RequirementListItem.js";
import {
  RequirementDraftBadge,
  RequirementPublishedBadge,
} from "../../requirements/RequirementStatusBadge.js";
import {
  ProjectOverview_IncomingInvoiceFragment,
  ProjectOverview_IncomingQuoteFragment,
  ProjectOverview_OrderNodeFragment,
  ProjectOverview_OutgoingInvoiceFragment,
  ProjectOverview_OutgoingQuoteFragment,
  ProjectOverviewDocumentsBox_ProjectFragment,
  ProjectOverviewIncomingInvoicesQueryVariables,
  ProjectOverviewIncomingQuotesQueryVariables,
  ProjectOverviewOutgoingInvoicesQueryVariables,
  ProjectOverviewOutgoingQuotesQueryVariables,
  ProjectOverviewPurchaseOrdersQueryVariables,
  useProjectOverviewIncomingInvoicesQuery,
  useProjectOverviewIncomingQuotesQuery,
  useProjectOverviewOutgoingInvoicesQuery,
  useProjectOverviewOutgoingQuotesQuery,
  useProjectOverviewPurchaseOrdersQuery,
  useProjectOverviewRequirementsQuery,
} from "./ProjectOverviewDocumentsBox.generated.js";

type Section = "requirements" | "quotes" | "orders" | "invoices";

interface Props {
  project: ProjectOverviewDocumentsBox_ProjectFragment;
  ordersCount: number;
  incomingRequirementsCount: number;
  outgoingRequirementsCount: number;
  incomingInvoicesCount: number;
  outgoingInvoicesCount: number;
  incomingQuotesCount: number;
  outgoingQuotesCount: number;
}

export const ProjectOverviewDocumentsBox = ({
  project,
  incomingRequirementsCount,
  outgoingRequirementsCount,
  ordersCount,
  incomingInvoicesCount,
  outgoingInvoicesCount,
  incomingQuotesCount,
  outgoingQuotesCount,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslate(["ProjectOverview", "Global"]);
  const viewer = useUserData().currentUser!;
  const { isMinTablet } = useScreenWidth();

  const [section, setSection] = useStateWithUrlParam<Section>(
    "docs-section",
    "quotes"
  );

  const collectionView: CollectionView = isMinTablet ? "table" : "list";

  const initialSections: {
    value: Section;
    label: string;
    icon: React.ReactElement;
    count?: number;
    capability?: Capabilities;
    projectPermission?: { name: PermissionName; project: Project };
  }[] = [
    {
      value: "requirements" as Section,
      label: t("Requirements", { ns: "ProjectOverview" }),
      icon: <RequirementIcon />,
      count: incomingRequirementsCount + outgoingRequirementsCount || undefined,
      projectPermission: { name: "READ_REQUIREMENTS", project },
    },
    {
      value: "quotes" as Section,
      label: t("Quotes", { ns: "ProjectOverview" }),
      icon: <QuoteIcon />,
      count: outgoingQuotesCount + incomingQuotesCount || undefined,
      projectPermission: { name: "READ_QUOTES", project },
    },
    {
      value: "orders" as Section,
      label: t("Materials", { ns: "ProjectOverview" }),
      icon: <ShoppingCartOutlined />,
      count: ordersCount || undefined,
      capability: "ORDERING" as const,
      projectPermission: { name: "MANAGE_QUOTES", project },
    },
    {
      value: "invoices" as Section,
      label: t("Invoices", { ns: "ProjectOverview" }),
      icon: <InvoiceIcon />,
      count: outgoingInvoicesCount + incomingInvoicesCount || undefined,
      projectPermission: { name: "READ_INVOICES", project },
    },
  ];

  const restrictionFilter = useRestrictionFilter();
  const sections = initialSections.filter(restrictionFilter);

  const ActionButtons: Record<Section, React.ReactElement | undefined> = {
    requirements: (
      <RestrictedByProjectPermissionWithDebug
        permission="READ_REQUIREMENTS"
        project={project}
      >
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_REQUIREMENTS"
          project={project}
        >
          <CreateRequirementButton
            projectId={project.id}
            Button={
              <IconButton size="small" color="primary">
                <AddIcon />
              </IconButton>
            }
          />
        </RestrictedByProjectPermissionWithDebug>
      </RestrictedByProjectPermissionWithDebug>
    ),
    quotes: (
      <RestrictedByCapabilityWithDebug capability="QUOTING">
        <RestrictedByProjectPermissionWithDebug
          permission="READ_QUOTES"
          project={project}
        >
          <RestrictedByProjectPermissionWithDebug
            permission="MANAGE_QUOTES"
            project={project}
          >
            {(!(project.state === "opportunity") ||
              project.incomingQuoteRequests.length === 0 ||
              project.incomingQuoteRequests.some(
                request =>
                  request.status === "PUBLISHED" ||
                  request.wonBySystemOrganisationId === viewer.organisation.id
              )) && (
              <CreateQuoteButton
                projectId={project.id}
                pathToProject={`/projects/${project.id}`}
                Button={
                  <IconButton size="small" color="primary">
                    <AddIcon />
                  </IconButton>
                }
              />
            )}
          </RestrictedByProjectPermissionWithDebug>
        </RestrictedByProjectPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    ),
    orders: (
      <RestrictedByCapabilityWithDebug capability="ORDERING">
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_QUOTES"
          project={project}
        >
          <PurchaseOrderCreateButton
            projectId={project.id}
            Button={
              <IconButton size="small" color="primary">
                <AddIcon />
              </IconButton>
            }
            handleOrderCreate={orderId => {
              navigate(
                `/projects/${project.id}/materials/purchase-orders/${orderId}/edit`
              );
            }}
          />
        </RestrictedByProjectPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    ),
    invoices: (
      <RestrictedByCapabilityWithDebug capability="INVOICING">
        <RestrictedByProjectPermissionWithDebug
          permission="READ_INVOICES"
          project={project}
        >
          <RestrictedByProjectPermissionWithDebug
            permission="MANAGE_INVOICES"
            project={project}
          >
            <CreateInvoiceButton
              projectId={project.id}
              Button={
                <IconButton size="small" color="primary">
                  <AddIcon />
                </IconButton>
              }
            />
          </RestrictedByProjectPermissionWithDebug>
        </RestrictedByProjectPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    ),
  };

  if (
    incomingRequirementsCount +
      outgoingRequirementsCount +
      ordersCount +
      incomingInvoicesCount +
      outgoingInvoicesCount +
      incomingQuotesCount +
      outgoingQuotesCount ===
    0
  )
    return null;

  return (
    <CardContainer
      isExpandable
      title={
        !isMinTablet
          ? t("Documents", {
              ns: "ProjectOverview",
            })
          : undefined
      }
      tabs={
        <TabsWithRestriction
          value={section}
          onChange={setSection}
          options={initialSections}
          condensed
          useSelectOnMobile
        />
      }
      ActionButton={ActionButtons[section]}
    >
      {(() => {
        switch (section) {
          case "orders":
            if (!sections.some(s => s.value === "orders")) return null;
            return (
              <OrdersList
                project={project}
                collectionView={collectionView}
                count={ordersCount}
              />
            );
          case "requirements":
            if (!sections.some(s => s.value === "requirements")) return null;
            return (
              <RequirementsList
                project={project}
                collectionView={collectionView}
                incomingRequirementsCount={incomingRequirementsCount}
                outgoingRequirementsCount={outgoingRequirementsCount}
              />
            );
          case "invoices":
            if (!sections.some(s => s.value === "invoices")) return null;
            return (
              <InvoicesList
                project={project}
                collectionView={collectionView}
                outgoingInvoicesCount={outgoingInvoicesCount}
                incomingInvoicesCount={incomingInvoicesCount}
              />
            );
          case "quotes":
            if (!sections.some(s => s.value === "quotes")) return null;
            return (
              <QuotesList
                project={project}
                collectionView={collectionView}
                outgoingQuotesCount={outgoingQuotesCount}
                incomingQuotesCount={incomingQuotesCount}
              />
            );
        }
      })()}
    </CardContainer>
  );
};

type PurchaseOrdersQuickFilterValue = "ALL" | "DRAFT" | "ORDERED";
type PurchaseOrdersFilters = Omit<
  ProjectOverviewPurchaseOrdersQueryVariables,
  "limit" | "offset" | "projectId" | "orderingCapability"
>;

function OrdersList({
  project,
  collectionView,
  count,
}: {
  project: ProjectOverviewDocumentsBox_ProjectFragment;
  collectionView: CollectionView;
  count: number;
}) {
  const { t } = useTranslate(["ProjectOverview", "PurchaseOrders", "Global"]);

  const navigate = useNavigate();

  const quickFilterSettings: Record<
    PurchaseOrdersQuickFilterValue,
    {
      label: string;
      values: PurchaseOrdersFilters;
    }
  > = React.useMemo(
    () => ({
      ALL: {
        label: t("All", {
          ns: "Global",
        }),
        values: {},
      },
      DRAFT: {
        label: t("Draft", {
          ns: "ProjectOverview",
        }),
        values: {
          filterStatus: "draft",
        },
      },
      ORDERED: {
        label: t("Ordered", {
          ns: "PurchaseOrders",
        }),
        values: {
          filterStatus: "ordered",
        },
      },
    }),
    [t]
  );

  const [quickFilter, setQuickFilter] =
    useStateWithUrlParam<PurchaseOrdersQuickFilterValue>(
      "orders-quickfilter",
      "ALL"
    );

  const client = useApolloClient();
  const query = useProjectOverviewPurchaseOrdersQuery({
    client,
    variables: {
      projectId: project.id,
      limit: SHORT_LIST_RESULTS_PER_PAGE,
      ...quickFilterSettings[quickFilter].values,
    },
  });
  const orders =
    getDataOrNull((query.data ?? query.previousData)?.orders)?.edges.map(
      e => e.node
    ) ?? [];

  return (
    <>
      <Box p={1}>
        <Tabs
          condensed
          useSelect
          options={Object.entries(quickFilterSettings).map(([key, value]) => ({
            value: key as PurchaseOrdersQuickFilterValue,
            label: value.label,
          }))}
          value={quickFilter}
          onChange={(newQuickFilter: PurchaseOrdersQuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
        />
      </Box>
      {(() => {
        switch (collectionView) {
          case "table":
            return (
              <DataGrid
                autoHeight={true}
                borderless
                disableColumnFilter
                disableVirtualization
                hideFooter
                loading={query.loading}
                columns={
                  [
                    {
                      field: "title",
                      headerName: t("Title", {
                        ns: "Global",
                      }),
                      flex: 3,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: order }) => (
                        <PurchaseOrderTitle order={order} />
                      ),
                    },
                    {
                      field: "status",
                      headerName: t("Status", {
                        ns: "PurchaseOrders",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: order }) => (
                        <PurchaseOrderStatusBadge order={order} />
                      ),
                    },
                    {
                      field: "supplier",
                      headerName: t("Supplier", {
                        ns: "PurchaseOrders",
                      }),
                      flex: 2,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: order }) => order.supplierName ?? "–",
                    },
                    {
                      field: "createdAt",
                      headerName: t("Order date", {
                        ns: "PurchaseOrders",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: order }) =>
                        moment(order.createdAt).format("L"),
                    },
                  ] as GridColDef<ProjectOverview_OrderNodeFragment>[]
                }
                rows={orders}
                onRowClick={({ row: order }) =>
                  navigate(
                    order.supplierName
                      ? `/projects/${project.id}/materials/purchase-orders/${order.id}`
                      : `/projects/${project.id}/materials/purchase-orders/${order.id}/edit`
                  )
                }
                rowCount={orders.length}
              />
            );
          case "list":
            if (orders.length === 0)
              return (
                <Box p={1} py={3}>
                  <Typography align="center">
                    {t("There are no items to display", {
                      ns: "Global",
                    })}
                  </Typography>
                </Box>
              );
            return (
              <Stack width="100%" direction="column">
                {orders.map(order => (
                  <ListItem
                    key={order.id}
                    component={Link}
                    //@ts-ignore
                    to={
                      order.supplierName
                        ? `/projects/${project.id}/materials/purchase-orders/${order.id}`
                        : `/projects/${project.id}/materials/purchase-orders/${order.id}/edit`
                    }
                  >
                    <NodePurchaseOrderListItem order={order} />
                  </ListItem>
                ))}
              </Stack>
            );
        }
      })()}
      {count > 0 && (
        <Box>
          <Button
            component={Link}
            to={`/projects/${project.id}/materials/purchase-orders`}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See more", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </>
  );
}

type RequirementsSection = "INCOMING" | "OUTGOING";

function RequirementsList({
  project,
  collectionView,
  incomingRequirementsCount,
  outgoingRequirementsCount,
}: {
  project: { id: string };
  collectionView: CollectionView;
  incomingRequirementsCount: number;
  outgoingRequirementsCount: number;
}) {
  const { t } = useTranslate(["Requirements", "ProjectOverview", "Global"]);
  const restrictionFilter = useRestrictionFilter();

  const initialSectionOptions: TabOptionWithRestriction<RequirementsSection>[] =
    [
      {
        value: "INCOMING" as RequirementsSection,
        label: t("Incoming", { ns: "Requirements" }),
        count: incomingRequirementsCount || undefined,
        capability: "QUOTING" as const,
      },
      {
        value: "OUTGOING" as RequirementsSection,
        label: t("Outgoing", { ns: "Requirements" }),
        count: outgoingRequirementsCount || undefined,
      },
    ];

  const sectionOptions = initialSectionOptions.filter(restrictionFilter);

  const [section, setSection] = useStateWithUrlParam<RequirementsSection>(
    "requirements-section",
    sectionOptions[0].value
  );

  switch (section) {
    case "OUTGOING":
      if (!sectionOptions.some(o => o.value === "OUTGOING")) return null;
      return (
        <RequirementsDocumentsList
          project={project}
          collectionView={collectionView}
          section={section}
          setSection={setSection}
          sectionOptions={initialSectionOptions}
          count={outgoingRequirementsCount}
          origin="OWN"
        />
      );
    case "INCOMING":
      if (!sectionOptions.some(o => o.value === "INCOMING")) return null;
      return (
        <RequirementsDocumentsList
          project={project}
          collectionView={collectionView}
          section={section}
          setSection={setSection}
          sectionOptions={initialSectionOptions}
          count={incomingRequirementsCount}
          origin="FOREIGN"
        />
      );
    default:
      assertNever(section);
  }
}

function RequirementsDocumentsList({
  project,
  collectionView,
  count,
  origin,
  section,
  setSection,
  sectionOptions,
}: {
  project: { id: string };
  collectionView: CollectionView;
  count: number;
  origin: ProjectDocumentOrigin;
  section: InvoicesSection;
  setSection: React.Dispatch<React.SetStateAction<InvoicesSection>>;
  sectionOptions: TabOptionWithRestriction<InvoicesSection>[];
}) {
  const { t } = useTranslate(["ProjectOverview", "Global", "Requirements"]);
  const { getFormattedDateTime } = useFormatting();

  const client = useApolloClient();
  const query = useProjectOverviewRequirementsQuery({
    client,
    variables: {
      origin,
      projectId: project.id,
      limit: SHORT_LIST_RESULTS_PER_PAGE,
    },
  });
  const requirements =
    getDataOrNull(
      query.data?.projectRequirements ?? query.previousData?.projectRequirements
    )?.edges?.map(e => e.node) ?? [];

  const navigate = useNavigate();

  return (
    <>
      {sectionOptions.length > 1 && (
        <Box p={1}>
          <TabsWithRestriction
            condensed
            useSelect
            options={sectionOptions}
            value={section}
            onChange={setSection}
          />
        </Box>
      )}
      {(() => {
        switch (collectionView) {
          case "table":
            return (
              <DataGrid
                autoHeight={true}
                borderless
                disableColumnFilter
                disableVirtualization
                hideFooter
                loading={query.loading}
                columns={
                  [
                    {
                      field: "title",
                      headerName: t("Title", {
                        ns: "Requirements",
                      }),
                      flex: 3,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: requirement }) => requirement.title,
                    },
                    {
                      field: "status",
                      headerName: t("Status", {
                        ns: "Requirements",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: requirement }) => {
                        return requirement.isPublished ? (
                          <RequirementPublishedBadge />
                        ) : (
                          <RequirementDraftBadge />
                        );
                      },
                    },
                    {
                      field: "createdAt",
                      headerName: t("Created at", {
                        ns: "Requirements",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      valueGetter: ({ row: requirement }) =>
                        requirement.createdAt
                          ? new Date(requirement.createdAt)
                          : null,
                      renderCell: ({ row: requirement }) =>
                        requirement.createdAt
                          ? getFormattedDateTime(requirement.createdAt)
                          : "-",
                    },
                  ] as GridColDef<RequirementRowFragment>[]
                }
                rows={requirements}
                onRowClick={requirement =>
                  navigate(
                    `/projects/${project.id}/requirements/${requirement.id}`
                  )
                }
                rowCount={requirements.length}
              />
            );
          case "list":
            if (requirements.length === 0)
              return (
                <Box p={1} py={3}>
                  <Typography align="center">
                    {t("There are no items to display", {
                      ns: "Global",
                    })}
                  </Typography>
                </Box>
              );
            return (
              <Stack width="100%" direction="column">
                {requirements.map(requirement => (
                  <ListItem
                    key={requirement.id}
                    component={Link}
                    //@ts-ignore
                    to={`/projects/${project.id}/requirements/${requirement.id}`}
                  >
                    <RequirementListItem requirement={requirement} />
                  </ListItem>
                ))}
              </Stack>
            );
        }
      })()}
      {origin === "OWN" && count > 0 && (
        <Box>
          <Button
            component={Link}
            to={`/projects/${project.id}/requirements`}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See more", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </>
  );
}

type InvoicesSection = "INCOMING" | "OUTGOING";

function InvoicesList({
  project,
  collectionView,
  incomingInvoicesCount,
  outgoingInvoicesCount,
}: {
  project: { id: string };
  collectionView: CollectionView;
  incomingInvoicesCount: number;
  outgoingInvoicesCount: number;
}) {
  const { t } = useTranslate(["Invoices", "ProjectOverview", "Global"]);
  const restrictionFilter = useRestrictionFilter();

  const initialSectionOptions: TabOptionWithRestriction<InvoicesSection>[] = [
    {
      value: "OUTGOING" as InvoicesSection,
      label: t("Outgoing", { ns: "Invoices" }),
      count: outgoingInvoicesCount || undefined,
      capability: "INVOICING" as const,
    },

    {
      value: "INCOMING" as InvoicesSection,
      label: t("Incoming", { ns: "Invoices" }),
      count: incomingInvoicesCount || undefined,
    },
  ];

  const sectionOptions = initialSectionOptions.filter(restrictionFilter);

  const [section, setSection] = useStateWithUrlParam<InvoicesSection>(
    "invoices-section",
    sectionOptions[0].value
  );

  switch (section) {
    case "OUTGOING":
      if (!sectionOptions.some(o => o.value === "OUTGOING")) return null;
      return (
        <InvoicesOutgoingList
          project={project}
          collectionView={collectionView}
          section={section}
          setSection={setSection}
          sectionOptions={initialSectionOptions}
          count={outgoingInvoicesCount}
        />
      );
    case "INCOMING":
      if (!sectionOptions.some(o => o.value === "INCOMING")) return null;
      return (
        <InvoicesIncomingList
          project={project}
          collectionView={collectionView}
          section={section}
          setSection={setSection}
          sectionOptions={initialSectionOptions}
          count={incomingInvoicesCount}
        />
      );
    default:
      assertNever(section);
  }
}

type OutgoingInvoicesQuickFilterValue =
  | "ALL"
  | "DRAFT"
  | "OPEN"
  | "OVERDUE"
  | "CANCELLED"
  | "PAID";
type OutgoingInvoicesFilters = Omit<
  ProjectOverviewOutgoingInvoicesQueryVariables,
  "limit" | "projectId" | "invoicingCapability"
>;

function InvoicesOutgoingList({
  project,
  collectionView,
  section,
  setSection,
  sectionOptions,
  count,
}: {
  project: { id: string };
  collectionView: CollectionView;
  section: InvoicesSection;
  setSection: React.Dispatch<React.SetStateAction<InvoicesSection>>;
  sectionOptions: TabOptionWithRestriction<InvoicesSection>[];
  count: number;
}) {
  const { t } = useTranslate(["Invoices", "ProjectOverview", "Global"]);
  const { getFormattedPrice, getFormattedDate, getFormattedDateTime } =
    useFormatting();

  const { invoicePaymentLabels } = useInvoiceTypes();

  const navigate = useNavigate();

  const quickFilterSettings = React.useMemo(
    (): Record<
      OutgoingInvoicesQuickFilterValue,
      {
        label: string;
        values: OutgoingInvoicesFilters;
      }
    > => ({
      ALL: {
        label: t("All", {
          ns: "Global",
        }),
        values: {},
      },
      DRAFT: {
        label: t("Draft", {
          ns: "ProjectOverview",
        }),
        values: {
          filterStatus: ["draft"],
        },
      },
      OPEN: {
        label: t("Open", {
          ns: "ProjectOverview",
        }),
        values: {
          filterStatus: ["open"],
        },
      },
      OVERDUE: {
        label: t("Overdue", {
          ns: "ProjectOverview",
        }),
        values: {
          filterOverdue: true,
          filterStatus: ["open"],
        },
      },
      CANCELLED: {
        label: t("Cancelled", {
          ns: "ProjectOverview",
        }),
        values: {
          filterStatus: ["cancelled"],
        },
      },
      PAID: {
        label: t("Paid", {
          ns: "ProjectOverview",
        }),
        values: {
          filterStatus: ["paid"],
        },
      },
    }),
    [t]
  );

  const [quickFilter, setQuickFilter] =
    useStateWithUrlParam<OutgoingInvoicesQuickFilterValue>(
      "outgoingInvoices-quickfilter",
      "ALL"
    );

  const client = useApolloClient();
  const query = useProjectOverviewOutgoingInvoicesQuery({
    client,
    variables: {
      projectId: project.id,
      limit: SHORT_LIST_RESULTS_PER_PAGE,
      ...quickFilterSettings[quickFilter].values,
    },
  });
  const invoices =
    getDataOrNull(
      (query.data ?? query.previousData)?.projectOutgoingInvoices
    )?.edges.map(e => e.node) ?? [];

  return (
    <>
      <Stack direction="row" spacing={1} p={1}>
        {sectionOptions.length > 1 && (
          <TabsWithRestriction
            condensed
            useSelect
            options={sectionOptions}
            value={section}
            onChange={setSection}
          />
        )}
        <Tabs
          condensed
          useSelect
          options={Object.entries(quickFilterSettings).map(([key, value]) => ({
            value: key as OutgoingInvoicesQuickFilterValue,
            label: value.label,
          }))}
          value={quickFilter}
          onChange={(newQuickFilter: OutgoingInvoicesQuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
        />
      </Stack>
      {(() => {
        switch (collectionView) {
          case "table":
            return (
              <DataGrid
                autoHeight={true}
                borderless
                disableColumnFilter
                disableVirtualization
                hideFooter
                loading={query.loading}
                columns={
                  [
                    {
                      field: "title",
                      headerName: t("Title", {
                        ns: "Global",
                      }),
                      flex: 3,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) => invoice.title,
                    },
                    {
                      field: "type",
                      headerName: t("Type", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        invoicePaymentLabels[invoice.type],
                    },
                    {
                      field: "status",
                      headerName: t("Status", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) => (
                        <InvoiceStatusBadge status={invoice.status} />
                      ),
                    },
                    {
                      field: "createdAt",
                      headerName: t("Created", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      valueGetter: ({ row: invoice }) =>
                        invoice.createdAt ? new Date(invoice.createdAt) : null,
                      renderCell: ({ row: invoice }) =>
                        invoice.createdAt
                          ? getFormattedDateTime(invoice.createdAt)
                          : "-",
                    },
                    {
                      field: "dueDate",
                      headerName: t("Due date", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        invoice.dueDate
                          ? getFormattedDate(invoice.dueDate)
                          : t("Not set", {
                              ns: "Global",
                            }),
                    },
                    {
                      field: "totalGrossPrice",
                      headerName: t("Total gross", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        getFormattedPrice(invoice.totalGrossPrice),
                    },
                  ] as GridColDef<ProjectOverview_OutgoingInvoiceFragment>[]
                }
                rows={invoices}
                onRowClick={invoice =>
                  navigate(
                    `/projects/${project.id}/invoices/outgoing/${invoice.id}`
                  )
                }
                rowCount={invoices.length}
              />
            );
          case "list":
            if (invoices.length === 0)
              return (
                <Box p={1} py={3}>
                  <Typography align="center">
                    {t("There are no items to display", {
                      ns: "Global",
                    })}
                  </Typography>
                </Box>
              );
            return (
              <Stack width="100%" direction="column">
                {invoices.map(invoice => (
                  <ListItem
                    key={invoice.id}
                    component={Link}
                    //@ts-ignore
                    to={`/projects/${project.id}/invoices/outgoing/${invoice.id}`}
                  >
                    <InvoiceListItem invoice={invoice} />
                  </ListItem>
                ))}
              </Stack>
            );
        }
      })()}
      {count > 0 && (
        <Box>
          <Button
            component={Link}
            to={`/projects/${project.id}/invoices/outgoing`}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See more", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </>
  );
}

type IncomingInvoicesQuickFilterValue = "ALL" | "OPEN" | "OVERDUE" | "PAID";
type IncomingInvoicesFilters = Omit<
  ProjectOverviewIncomingInvoicesQueryVariables,
  "limit" | "projectId"
>;

function InvoicesIncomingList({
  project,
  collectionView,
  section,
  setSection,
  sectionOptions,
  count,
}: {
  project: { id: string };
  collectionView: CollectionView;
  section: InvoicesSection;
  setSection: React.Dispatch<React.SetStateAction<InvoicesSection>>;
  sectionOptions: TabOptionWithRestriction<InvoicesSection>[];
  count: number;
}) {
  const { t } = useTranslate(["Invoices", "ProjectOverview", "Global"]);
  const { getFormattedPrice, getFormattedDate } = useFormatting();

  const { invoicePaymentLabels } = useInvoiceTypes();

  const navigate = useNavigate();

  const quickFilterSettings = React.useMemo(
    (): Record<
      IncomingInvoicesQuickFilterValue,
      {
        label: string;
        values: IncomingInvoicesFilters;
      }
    > => ({
      ALL: {
        label: t("All", {
          ns: "Global",
        }),
        values: {},
      },
      OPEN: {
        label: t("Open", {
          ns: "ProjectOverview",
        }),
        values: {
          filterStatus: ["open"],
        },
      },
      OVERDUE: {
        label: t("Overdue", {
          ns: "ProjectOverview",
        }),
        values: {
          filterOverdue: true,
          filterStatus: ["open"],
        },
      },
      PAID: {
        label: t("Paid", {
          ns: "ProjectOverview",
        }),
        values: {
          filterStatus: ["paid"],
        },
      },
    }),
    [t]
  );

  const [quickFilter, setQuickFilter] =
    useStateWithUrlParam<IncomingInvoicesQuickFilterValue>(
      "incomingInvoices-quickfilter",
      "ALL"
    );

  const client = useApolloClient();
  const query = useProjectOverviewIncomingInvoicesQuery({
    client,
    variables: {
      projectId: project.id,
      limit: SHORT_LIST_RESULTS_PER_PAGE,
      ...quickFilterSettings[quickFilter].values,
    },
  });

  const invoices =
    getDataOrNull(
      (query.data ?? query.previousData)?.projectIncomingInvoices
    )?.edges.map(edge => edge.node) ?? [];

  return (
    <>
      <Stack direction="row" spacing={1} p={1}>
        {sectionOptions.length > 1 && (
          <TabsWithRestriction
            condensed
            useSelect
            options={sectionOptions}
            value={section}
            onChange={setSection}
          />
        )}
        <Tabs
          condensed
          useSelect
          options={Object.entries(quickFilterSettings).map(([key, value]) => ({
            value: key as IncomingInvoicesQuickFilterValue,
            label: value.label,
          }))}
          value={quickFilter}
          onChange={(newQuickFilter: IncomingInvoicesQuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
        />
      </Stack>
      {(() => {
        switch (collectionView) {
          case "table":
            return (
              <DataGrid
                autoHeight={true}
                borderless
                disableColumnFilter
                disableVirtualization
                hideFooter
                loading={query.loading}
                columns={
                  [
                    {
                      field: "title",
                      headerName: t("Title", {
                        ns: "Global",
                      }),
                      flex: 3,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) => invoice.title,
                    },
                    {
                      field: "type",
                      headerName: t("Type", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        invoicePaymentLabels[invoice.type],
                    },
                    {
                      field: "status",
                      headerName: t("Status", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) => (
                        <InvoiceStatusBadge status={invoice.status} />
                      ),
                    },
                    {
                      field: "issueDate",
                      headerName: t("Issue Date", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        invoice.issueDate
                          ? getFormattedDate(invoice.issueDate)
                          : t("Not issued", {
                              ns: "Invoices",
                            }),
                    },
                    {
                      field: "dueDate",
                      headerName: t("Due date", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        invoice.dueDate
                          ? getFormattedDate(invoice.dueDate)
                          : t("Not set", {
                              ns: "Global",
                            }),
                    },
                    {
                      field: "totalGrossPrice",
                      headerName: t("Total gross", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        getFormattedPrice(invoice.totalGrossPrice),
                    },
                  ] as GridColDef<ProjectOverview_IncomingInvoiceFragment>[]
                }
                rows={invoices}
                onRowClick={invoice =>
                  navigate(
                    `/projects/${project.id}/invoices/incoming/${invoice.id}`
                  )
                }
                rowCount={invoices.length}
              />
            );
          case "list":
            if (invoices.length === 0)
              return (
                <Box p={1} py={3}>
                  <Typography align="center">
                    {t("There are no items to display", {
                      ns: "Global",
                    })}
                  </Typography>
                </Box>
              );
            return (
              <Stack width="100%" direction="column">
                {invoices.map(invoice => (
                  <ListItem
                    key={invoice.id}
                    component={Link}
                    //@ts-ignore
                    to={`/projects/${project.id}/invoices/incoming/${invoice.id}`}
                  >
                    <InvoiceListItem invoice={invoice} />
                  </ListItem>
                ))}
              </Stack>
            );
        }
      })()}
      {count > 0 && (
        <Box>
          <Button
            component={Link}
            to={`/projects/${project.id}/invoices/incoming`}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See more", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </>
  );
}

type QuotesSection = "INCOMING" | "OUTGOING";

function QuotesList({
  project,
  collectionView,
  outgoingQuotesCount,
  incomingQuotesCount,
}: {
  project: { id: string };
  collectionView: CollectionView;
  outgoingQuotesCount: number;
  incomingQuotesCount: number;
}) {
  const { t } = useTranslate(["Quotes", "ProjectOverview", "Global"]);
  const restrictionFilter = useRestrictionFilter();

  const initialSectionOptions: TabOptionWithRestriction<QuotesSection>[] = [
    {
      value: "OUTGOING" as QuotesSection,
      label: t("Outgoing", {
        ns: "Quotes",
      }),
      count: outgoingQuotesCount || undefined,
      capability: "QUOTING" as const,
    },
    {
      value: "INCOMING" as QuotesSection,
      label: t("Incoming", {
        ns: "Quotes",
      }),
      count: incomingQuotesCount || undefined,
    },
  ];

  const sectionOptions = initialSectionOptions.filter(restrictionFilter);

  const [section, setSection] = useStateWithUrlParam<QuotesSection>(
    "quotes-section",
    sectionOptions[0].value
  );

  switch (section) {
    case "OUTGOING":
      if (!sectionOptions.some(o => o.value === "OUTGOING")) return null;
      return (
        <QuotesOutgoingList
          project={project}
          collectionView={collectionView}
          section={section}
          setSection={setSection}
          sectionOptions={initialSectionOptions}
          count={outgoingQuotesCount}
        />
      );
    case "INCOMING":
      if (!sectionOptions.some(o => o.value === "INCOMING")) return null;
      return (
        <QuotesIncomingList
          project={project}
          collectionView={collectionView}
          section={section}
          setSection={setSection}
          sectionOptions={initialSectionOptions}
          count={incomingQuotesCount}
        />
      );
    default:
      assertNever(section);
  }
}

type OutgoingQuotesQuickFilterValue =
  | "ALL"
  | "DRAFT"
  | "BINDING_QUOTE"
  | "ACCEPTED"
  | "DECLINED";
type OutgoingQuotesFilters = Omit<
  ProjectOverviewOutgoingQuotesQueryVariables,
  "limit" | "projectId" | "quotingCapability"
>;

function QuotesOutgoingList({
  project,
  collectionView,
  section,
  setSection,
  sectionOptions,
  count,
}: {
  project: { id: string };
  collectionView: CollectionView;
  section: QuotesSection;
  setSection: React.Dispatch<React.SetStateAction<QuotesSection>>;
  sectionOptions: TabOptionWithRestriction<QuotesSection>[];
  count: number;
}) {
  const { t } = useTranslate([
    "Quotes",
    "QuoteStateBadge",
    "ProjectOverview",
    "Global",
  ]);
  const { getFormattedDateTime } = useFormatting();

  const navigate = useNavigate();

  const quickFilterSettings = React.useMemo(
    (): Record<
      OutgoingQuotesQuickFilterValue,
      {
        label: string;
        values: OutgoingQuotesFilters;
      }
    > => ({
      ALL: {
        label: t("All", {
          ns: "Global",
        }),
        values: {},
      },
      DRAFT: {
        label: t("Draft", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterIsPublished: false,
        },
      },
      BINDING_QUOTE: {
        label: t("Binding Quote", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterIsPublished: true,
          filterIsBinding: true,
          filterAgreement: "NONE",
        },
      },
      ACCEPTED: {
        label: t("Accepted", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "YES",
        },
      },
      DECLINED: {
        label: t("Declined", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "NO",
        },
      },
    }),
    [t]
  );

  const [quickFilter, setQuickFilter] =
    useStateWithUrlParam<OutgoingQuotesQuickFilterValue>(
      "outgoingQuotes-quickfilter",
      "ALL"
    );

  const client = useApolloClient();
  const query = useProjectOverviewOutgoingQuotesQuery({
    client,
    variables: {
      projectId: project.id,
      limit: SHORT_LIST_RESULTS_PER_PAGE,
      ...quickFilterSettings[quickFilter].values,
    },
  });
  const quotes =
    getDataOrNull(
      (query.data ?? query.previousData)?.projectOutgoingQuotes
    )?.edges.map(e => e.node) ?? [];

  return (
    <>
      <Stack direction="row" spacing={1} p={1}>
        {sectionOptions.length > 1 && (
          <TabsWithRestriction
            condensed
            useSelect
            options={sectionOptions}
            value={section}
            onChange={setSection}
          />
        )}
        <Tabs
          condensed
          useSelect
          options={Object.entries(quickFilterSettings).map(([key, value]) => ({
            value: key as OutgoingQuotesQuickFilterValue,
            label: value.label,
          }))}
          value={quickFilter}
          onChange={(newQuickFilter: OutgoingQuotesQuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
        />
      </Stack>
      {(() => {
        switch (collectionView) {
          case "table":
            return (
              <DataGrid
                autoHeight={true}
                borderless
                disableColumnFilter
                hideFooter
                loading={query.loading}
                columns={
                  [
                    {
                      field: "title",
                      headerName: t("Title", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      flex: 3,
                      minWidth: 120,
                      renderCell: ({ row: quote }) => quote.title,
                    },
                    {
                      field: "status",
                      headerName: t("Status", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      flex: 1,
                      minWidth: 80,
                      valueGetter: ({ row: quote }) =>
                        `${quote.isPublished}-${quote.isBinding}-${quote.needsAgreement}-${quote.agreement}`,
                      renderCell: ({ row: quote }) => (
                        <QuoteStatusBadge small quote={quote} />
                      ),
                    },
                    {
                      field: "organisation",
                      headerName: t("Created by", { ns: "Quotes" }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      flex: 1.5,
                      minWidth: 100,
                      valueGetter: ({ row: quote }) => quote.createdBy ?? "-",
                    },
                    {
                      field: "createdAt",
                      headerName: t("Created", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      type: "date",
                      flex: 1,
                      minWidth: 80,
                      valueGetter: ({ row: quote }) =>
                        quote.createdAt ? new Date(quote.createdAt) : null,
                      renderCell: ({ row: quote }) =>
                        quote.createdAt
                          ? getFormattedDateTime(quote.createdAt)
                          : "-",
                    },
                    {
                      field: "isBinding",
                      headerName: t("Binding", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      type: "boolean",
                      flex: 1,
                      renderCell: ({ row: quote }) =>
                        quote.isBinding ? (
                          <DoneIcon fontSize="small" />
                        ) : (
                          <CloseIcon fontSize="small" color="secondary" />
                        ),
                    },
                    {
                      field: "proposedTotalPrice",
                      headerName: t("Proposed Price", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      type: "number",
                      flex: 1,
                      align: "right",
                      headerAlign: "right",
                      minWidth: 80,
                      valueGetter: ({ row: quote }) =>
                        quote.proposedTotalPrice || 0,
                      renderCell: ({ row: quote }) => {
                        return (
                          <Typography
                            component="span"
                            align="right"
                            variant="body2"
                            style={{ fontWeight: 500 }}
                          >
                            <FormattedPrice
                              value={quote.proposedTotalPrice || 0}
                            />
                          </Typography>
                        );
                      },
                    },
                  ] as GridColDef<ProjectOverview_OutgoingQuoteFragment>[]
                }
                rows={quotes}
                onRowClick={quote =>
                  navigate(`/projects/${project.id}/quotes/${quote.id}`)
                }
                rowCount={quotes.length}
              />
            );
          case "list":
            if (quotes.length === 0)
              return (
                <Box p={1} py={3}>
                  <Typography align="center">
                    {t("There are no items to display", {
                      ns: "Global",
                    })}
                  </Typography>
                </Box>
              );
            return (
              <Stack width="100%" direction="column">
                {quotes.map(quote => (
                  <ListItem
                    key={quote.id}
                    component={Link}
                    // @ts-ignore
                    to={`/projects/${project.id}/quotes/${quote.id}`}
                  >
                    <QuoteListItem quote={quote} />
                  </ListItem>
                ))}
              </Stack>
            );
        }
      })()}
      {count > 0 && (
        <Box>
          <Button
            component={Link}
            to={`/projects/${project.id}/quotes?tab=OUTGOING`}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See more", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </>
  );
}

type IncomingQuotesQuickFilterValue =
  | "ALL"
  | "RECEIVED"
  | "ACCEPTED"
  | "DECLINED";
type IncomingQuotesFilters = Omit<
  ProjectOverviewIncomingQuotesQueryVariables,
  "limit" | "projectId"
>;

function QuotesIncomingList({
  project,
  collectionView,
  section,
  setSection,
  sectionOptions,
  count,
}: {
  project: { id: string };
  collectionView: CollectionView;
  section: QuotesSection;
  setSection: React.Dispatch<React.SetStateAction<QuotesSection>>;
  sectionOptions: TabOptionWithRestriction<QuotesSection>[];
  count: number;
}) {
  const { t } = useTranslate([
    "Quotes",
    "QuoteStateBadge",
    "ProjectOverview",
    "Global",
  ]);
  const { getFormattedDate } = useFormatting();

  const navigate = useNavigate();

  const quickFilterSettings = React.useMemo(
    (): Record<
      IncomingQuotesQuickFilterValue,
      {
        label: string;
        values: IncomingQuotesFilters;
      }
    > => ({
      ALL: {
        label: t("All", {
          ns: "Global",
        }),
        values: {},
      },
      RECEIVED: {
        label: t("Received", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "NONE",
        },
      },
      ACCEPTED: {
        label: t("Accepted", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "YES",
        },
      },
      DECLINED: {
        label: t("Declined", {
          ns: "QuoteStateBadge",
        }),
        values: {
          filterAgreement: "NO",
        },
      },
    }),
    [t]
  );

  const [quickFilter, setQuickFilter] =
    useStateWithUrlParam<IncomingQuotesQuickFilterValue>(
      "incomingQuotes-quickfilter",
      "ALL"
    );

  const client = useApolloClient();
  const query = useProjectOverviewIncomingQuotesQuery({
    client,
    variables: {
      projectId: project.id,
      limit: SHORT_LIST_RESULTS_PER_PAGE,
      ...quickFilterSettings[quickFilter].values,
    },
  });
  const quotes =
    getDataOrNull(
      (query.data ?? query.previousData)?.projectIncomingQuotes
    )?.edges.map(e => e.node) ?? [];

  return (
    <>
      <Stack direction="row" spacing={1} p={1}>
        {sectionOptions.length > 1 && (
          <TabsWithRestriction
            condensed
            useSelect
            options={sectionOptions}
            value={section}
            onChange={setSection}
          />
        )}
        <Tabs
          condensed
          useSelect
          options={Object.entries(quickFilterSettings).map(([key, value]) => ({
            value: key as IncomingQuotesQuickFilterValue,
            label: value.label,
          }))}
          value={quickFilter}
          onChange={(newQuickFilter: IncomingQuotesQuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
        />
      </Stack>
      {(() => {
        switch (collectionView) {
          case "table":
            return (
              <DataGrid
                autoHeight={true}
                borderless
                disableColumnFilter
                hideFooter
                loading={query.loading}
                columns={
                  [
                    {
                      field: "title",
                      headerName: t("Title", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      flex: 3,
                      minWidth: 120,
                      renderCell: ({ row: quote }) => quote.title,
                    },
                    {
                      field: "status",
                      headerName: t("Status", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      flex: 1,
                      minWidth: 80,
                      valueGetter: ({ row: quote }) =>
                        `${quote.isBinding}-${quote.needsAgreement}-${quote.agreement}`,
                      renderCell: ({ row: quote }) => (
                        <IncomingQuoteStatusBadge small quote={quote} />
                      ),
                    },
                    {
                      field: "organisation",
                      headerName: t("Created by", { ns: "Quotes" }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      flex: 2,
                      minWidth: 100,
                      valueGetter: ({ row: quote }) =>
                        quote.docActorContractorName ?? "-",
                    },
                    {
                      field: "publishedAt",
                      headerName: t("Received", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      type: "date",
                      flex: 1,
                      minWidth: 80,
                      valueGetter: ({ row: quote }) =>
                        quote.publishedAt ? new Date(quote.publishedAt) : null,
                      renderCell: ({ row: quote }) =>
                        quote.publishedAt
                          ? getFormattedDate(quote.publishedAt)
                          : "-",
                    },
                    {
                      field: "isBinding",
                      headerName: t("Binding", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      type: "boolean",
                      flex: 1,
                      renderCell: ({ row: quote }) =>
                        quote.isBinding ? (
                          <DoneIcon fontSize="small" />
                        ) : (
                          <CloseIcon fontSize="small" color="secondary" />
                        ),
                    },
                    {
                      field: "proposedTotalPrice",
                      headerName: t("Proposed Price", {
                        ns: "Quotes",
                      }),
                      editable: false,
                      filterable: false,
                      sortable: false,
                      type: "number",
                      flex: 1,
                      align: "right",
                      headerAlign: "right",
                      minWidth: 80,
                      valueGetter: ({ row: quote }) =>
                        quote.proposedTotalPrice || 0,
                      renderCell: ({ row: quote }) => {
                        return (
                          <Typography
                            component="span"
                            align="right"
                            variant="body2"
                            style={{ fontWeight: 500 }}
                          >
                            <FormattedPrice
                              value={quote.proposedTotalPrice || 0}
                            />
                          </Typography>
                        );
                      },
                    },
                  ] as GridColDef<ProjectOverview_IncomingQuoteFragment>[]
                }
                rows={quotes}
                onRowClick={quote =>
                  navigate(`/projects/${project.id}/quotes/${quote.id}`)
                }
                rowCount={quotes.length}
              />
            );
          case "list":
            if (quotes.length === 0)
              return (
                <Box p={1} py={3}>
                  <Typography align="center">
                    {t("There are no items to display", {
                      ns: "Global",
                    })}
                  </Typography>
                </Box>
              );
            return (
              <Stack width="100%" direction="column">
                {quotes.map(quote => (
                  <ListItem
                    key={quote.id}
                    component={Link}
                    //@ts-ignore
                    to={`/projects/${project.id}/quotes/${quote.id}`}
                  >
                    <QuoteListItem quote={quote} />
                  </ListItem>
                ))}
              </Stack>
            );
        }
      })()}
      {count > 0 && (
        <Box>
          <Button
            component={Link}
            to={`/projects/${project.id}/quotes?tab=INCOMING`}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See more", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </>
  );
}
