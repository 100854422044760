import { TimePickerMui, TimePickerNative } from "@msys/ui";
import { FormControl, TextFieldProps } from "@mui/material";
import { useFormikContext } from "formik";
import { get, isString } from "lodash-es";
import { ChangeEvent } from "react";
import { browserHasInputTime } from "../../featureDetection.js";

export interface Props extends Omit<TextFieldProps, "value" | "onChange"> {
  id?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  name: string;
  label: string;
  value?: string;
  required?: boolean;
  onChange?: (
    e: ChangeEvent<HTMLInputElement> | null,
    newValue: string | null
  ) => void;
}

export const TimePickerField = ({
  fullWidth = true,
  disabled,
  label,
  name,
  value,
  onChange,
  ...otherProps
}: Props) => {
  const { setFieldValue, setFieldTouched, values, errors } =
    useFormikContext<Record<string, any>>();

  const Component = browserHasInputTime ? TimePickerNative : TimePickerMui;

  const error = get(errors, name);

  return (
    <FormControl
      fullWidth={fullWidth}
      required={otherProps.required}
      error={Boolean(error)}
      disabled={disabled}
    >
      <Component
        error={Boolean(error)}
        label={label}
        disabled={disabled}
        value={value ?? get(values, name)}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          step: 60, // 1 min
        }}
        onChange={
          onChange ??
          ((e, value) => {
            setFieldValue(name, value);
          })
        }
        helperText={Boolean(error) && isString(error) ? error : undefined}
        {...otherProps}
      />
    </FormControl>
  );
};
