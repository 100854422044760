import { gql } from "@apollo/client";
import {
  CardContainer,
  CollapseSection,
  ListItem,
  ModalOpenButton,
} from "@msys/ui";
import { Box, Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { OpportunityIcon } from "../../opportunities/OpportunityIcon.js";
import { RequestOverviewProjectIncomingQuoteModal } from "../../requests/modals/RequestOverviewModal.js";
import { IncomingRequestListItem } from "../../requests/RequestListItem.js";
import { ProjectOverviewBiddingInformationBox_ProjectFragment } from "./ProjectOverviewBiddingInstructionsBox.generated.js";

interface Props {
  project: ProjectOverviewBiddingInformationBox_ProjectFragment;
}

export function ProjectOverviewBiddingInformationBox({ project }: Props) {
  const { t } = useTranslate(["ProjectOverview", "Global", "RequestOverview"]);

  const requests = project.incomingQuoteRequests ?? [];

  if (requests.length === 0) return null;

  return (
    <CardContainer
      id="project-overview-bidding-information-box"
      title={t("Bidding information", {
        ns: "ProjectOverview",
      })}
      Icon={<OpportunityIcon />}
      isExpandable={false}
      isInitiallyClosed={false}
    >
      <Box px={1}>
        <CollapseSection
          title={t("Quote requests", {
            ns: "RequestOverview",
          })}
          titleVariant="h4"
          itemCount={requests.length}
        >
          <Box mx={-1}>
            {requests.map(request => (
              <ModalOpenButton
                key={request.id}
                Modal={RequestOverviewProjectIncomingQuoteModal}
                modalProps={{
                  requestId: request.id,
                  projectId: project.id,
                }}
              >
                <ListItem>
                  <IncomingRequestListItem
                    request={request}
                    Action={
                      <ModalOpenButton
                        Modal={RequestOverviewProjectIncomingQuoteModal}
                        modalProps={{
                          requestId: request.id,
                          projectId: project.id,
                        }}
                      >
                        <Button
                          color="secondary"
                          variant="outlined"
                          size="extra-small"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          {t("View", { ns: "Global" })}
                        </Button>
                      </ModalOpenButton>
                    }
                  />
                </ListItem>
              </ModalOpenButton>
            ))}
          </Box>
        </CollapseSection>
      </Box>
    </CardContainer>
  );
}
