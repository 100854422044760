/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { WithDocumentItemTaskInfoFragmentDoc } from '../Fragments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TaskManagementBox_ItemFragment = { __typename: 'Item', id: string, priority: number, dueDate?: any | null, assignee?: { __typename: 'User', id: string } | null };

export type TaskManagementBox_ProjectFragment = { __typename: 'Project', id: string, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string }, roles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> }> };

export type QuoteModifyItemTaskInfo_TaskManagementBoxMutationVariables = Types.Exact<{
  input: Types.ModifyItemTaskInfoInput;
}>;


export type QuoteModifyItemTaskInfo_TaskManagementBoxMutation = { modifyItemTaskInfo: { __typename: 'ModifyItemTaskInfoResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null } } };

export const TaskManagementBox_ItemFragmentDoc = gql`
    fragment TaskManagementBox_Item on Item {
  id
  priority
  dueDate
  assignee {
    id
  }
}
    `;
export const TaskManagementBox_ProjectFragmentDoc = gql`
    fragment TaskManagementBox_Project on Project {
  id
  internalStakeholders {
    id
    user {
      id
      firstname
      familyname
    }
    roles {
      id
      internalName
    }
  }
}
    `;
export const QuoteModifyItemTaskInfo_TaskManagementBoxDocument = gql`
    mutation QuoteModifyItemTaskInfo_TaskManagementBox($input: ModifyItemTaskInfoInput!) {
  modifyItemTaskInfo(input: $input) {
    item {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
  }
}
    ${WithDocumentItemTaskInfoFragmentDoc}`;
export type QuoteModifyItemTaskInfo_TaskManagementBoxMutationFn = Apollo.MutationFunction<QuoteModifyItemTaskInfo_TaskManagementBoxMutation, QuoteModifyItemTaskInfo_TaskManagementBoxMutationVariables>;

/**
 * __useQuoteModifyItemTaskInfo_TaskManagementBoxMutation__
 *
 * To run a mutation, you first call `useQuoteModifyItemTaskInfo_TaskManagementBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteModifyItemTaskInfo_TaskManagementBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteModifyItemTaskInfoTaskManagementBoxMutation, { data, loading, error }] = useQuoteModifyItemTaskInfo_TaskManagementBoxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteModifyItemTaskInfo_TaskManagementBoxMutation(baseOptions?: Apollo.MutationHookOptions<QuoteModifyItemTaskInfo_TaskManagementBoxMutation, QuoteModifyItemTaskInfo_TaskManagementBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteModifyItemTaskInfo_TaskManagementBoxMutation, QuoteModifyItemTaskInfo_TaskManagementBoxMutationVariables>(QuoteModifyItemTaskInfo_TaskManagementBoxDocument, options);
      }
export type QuoteModifyItemTaskInfo_TaskManagementBoxMutationHookResult = ReturnType<typeof useQuoteModifyItemTaskInfo_TaskManagementBoxMutation>;
export type QuoteModifyItemTaskInfo_TaskManagementBoxMutationResult = Apollo.MutationResult<QuoteModifyItemTaskInfo_TaskManagementBoxMutation>;
export type QuoteModifyItemTaskInfo_TaskManagementBoxMutationOptions = Apollo.BaseMutationOptions<QuoteModifyItemTaskInfo_TaskManagementBoxMutation, QuoteModifyItemTaskInfo_TaskManagementBoxMutationVariables>;