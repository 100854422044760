/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type OrderDetailsFragment = { __typename: 'BillOfMaterialsItem', id: string, title: string, quantityRequiredTotal: number, quantityRequiredAccepted: number, quantityRequiredDraft: number, quantityRequiredPending: number, quantityRequiredChange: number, quantityOrdered: number, quantityUnit: Types.QuantityUnit, netPrice?: number | null, listPrice?: number | null };

export const OrderDetailsFragmentDoc = gql`
    fragment OrderDetails on BillOfMaterialsItem {
  id
  title
  quantityRequiredTotal
  quantityRequiredAccepted
  quantityRequiredDraft
  quantityRequiredPending
  quantityRequiredChange
  quantityOrdered
  quantityUnit
  netPrice
  listPrice
}
    `;