import { Alert, AlertTitle, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { SelectUserButton } from "./SelectUserButton.js";

export const CurrentUserError = () => {
  const { t } = useTranslate(["Global"]);

  return (
    <Stack justifyContent={"center"} alignItems={"center"} flex={1}>
      <Alert action={<SelectUserButton />}>
        <AlertTitle>{t("Error", { ns: "Global" })}</AlertTitle>
        {"Failed to get user data"}
      </Alert>
    </Stack>
  );
};
