/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteTemplateUpdateToLatestAvailablePublishedVersionMutationVariables = Types.Exact<{
  input: Types.UpdateLinkedItemToLatestAvailablePublishedVersionInput;
}>;


export type QuoteTemplateUpdateToLatestAvailablePublishedVersionMutation = { updateLinkedItemToLatestAvailablePublishedVersion: { __typename: 'UpdateLinkedItemToLatestAvailablePublishedVersionResultSuccess', ok?: boolean | null } | { __typename: 'UpdateLinkedItemToLatestAvailablePublishedVersionResultValidationError', results: Array<{ __typename: 'CompileDocIsolatedExpressionResultDiagnosticAskIf' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping', toKey: string, fromExpr: string, messageText: string } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf' }> } };

export type TemplateLinkUpdateModalQueryVariables = Types.Exact<{
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
  overrides?: Types.InputMaybe<Array<Types.CompileDocOverrideInput>>;
}>;


export type TemplateLinkUpdateModalQuery = { item: { __typename: 'Item', id: string, originVersionNumber?: number | null, propertyMappings: Array<{ __typename: 'PropertyMapping', from: { __typename: 'PropertyMappingFrom', expr: string }, to: { __typename: 'PropertyMappingTo', key: string } }> } | { __typename: 'MissingPermissions' }, compileDocIsolatedExpression: { __typename: 'CompileDocIsolatedExpressionResult', results: Array<{ __typename: 'CompileDocIsolatedExpressionResultDiagnosticAskIf' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping', toKey: string, fromExpr: string, messageText: string } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf' }> } };

export type TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticAskIf_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticAskIf' };

export type TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticAttribute_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute' };

export type TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticComputedProp_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp' };

export type TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression' };

export type TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticPropertyMapping_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping', toKey: string, fromExpr: string, messageText: string };

export type TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf' };

export type TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf' };

export type TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnosticFragment = TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticAskIf_Fragment | TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticAttribute_Fragment | TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticComputedProp_Fragment | TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression_Fragment | TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticPropertyMapping_Fragment | TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf_Fragment | TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf_Fragment;

export const TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnosticFragmentDoc = gql`
    fragment TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic on CompileDocIsolatedExpressionResultDiagnostic {
  __typename
  ... on CompileDocIsolatedExpressionResultDiagnosticPropertyMapping {
    toKey
    fromExpr
    messageText
  }
}
    `;
export const QuoteTemplateUpdateToLatestAvailablePublishedVersionDocument = gql`
    mutation QuoteTemplateUpdateToLatestAvailablePublishedVersion($input: UpdateLinkedItemToLatestAvailablePublishedVersionInput!) {
  updateLinkedItemToLatestAvailablePublishedVersion(input: $input) {
    ... on UpdateLinkedItemToLatestAvailablePublishedVersionResultSuccess {
      ok
    }
    ... on UpdateLinkedItemToLatestAvailablePublishedVersionResultValidationError {
      results {
        ...TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic
      }
    }
  }
}
    ${TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnosticFragmentDoc}`;
export type QuoteTemplateUpdateToLatestAvailablePublishedVersionMutationFn = Apollo.MutationFunction<QuoteTemplateUpdateToLatestAvailablePublishedVersionMutation, QuoteTemplateUpdateToLatestAvailablePublishedVersionMutationVariables>;

/**
 * __useQuoteTemplateUpdateToLatestAvailablePublishedVersionMutation__
 *
 * To run a mutation, you first call `useQuoteTemplateUpdateToLatestAvailablePublishedVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteTemplateUpdateToLatestAvailablePublishedVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteTemplateUpdateToLatestAvailablePublishedVersionMutation, { data, loading, error }] = useQuoteTemplateUpdateToLatestAvailablePublishedVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteTemplateUpdateToLatestAvailablePublishedVersionMutation(baseOptions?: Apollo.MutationHookOptions<QuoteTemplateUpdateToLatestAvailablePublishedVersionMutation, QuoteTemplateUpdateToLatestAvailablePublishedVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteTemplateUpdateToLatestAvailablePublishedVersionMutation, QuoteTemplateUpdateToLatestAvailablePublishedVersionMutationVariables>(QuoteTemplateUpdateToLatestAvailablePublishedVersionDocument, options);
      }
export type QuoteTemplateUpdateToLatestAvailablePublishedVersionMutationHookResult = ReturnType<typeof useQuoteTemplateUpdateToLatestAvailablePublishedVersionMutation>;
export type QuoteTemplateUpdateToLatestAvailablePublishedVersionMutationResult = Apollo.MutationResult<QuoteTemplateUpdateToLatestAvailablePublishedVersionMutation>;
export type QuoteTemplateUpdateToLatestAvailablePublishedVersionMutationOptions = Apollo.BaseMutationOptions<QuoteTemplateUpdateToLatestAvailablePublishedVersionMutation, QuoteTemplateUpdateToLatestAvailablePublishedVersionMutationVariables>;
export const TemplateLinkUpdateModalDocument = gql`
    query TemplateLinkUpdateModal($docId: ID!, $itemId: ID!, $overrides: [CompileDocOverrideInput!]) {
  item(docId: $docId, itemId: $itemId) {
    ... on Item {
      id
      originVersionNumber
      propertyMappings {
        from {
          expr
        }
        to {
          key
        }
      }
    }
  }
  compileDocIsolatedExpression(
    input: {docId: $docId, deep: true, overrides: $overrides}
  ) {
    results {
      ...TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic
    }
  }
}
    ${TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnosticFragmentDoc}`;

/**
 * __useTemplateLinkUpdateModalQuery__
 *
 * To run a query within a React component, call `useTemplateLinkUpdateModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateLinkUpdateModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateLinkUpdateModalQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *      overrides: // value for 'overrides'
 *   },
 * });
 */
export function useTemplateLinkUpdateModalQuery(baseOptions: Apollo.QueryHookOptions<TemplateLinkUpdateModalQuery, TemplateLinkUpdateModalQueryVariables> & ({ variables: TemplateLinkUpdateModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateLinkUpdateModalQuery, TemplateLinkUpdateModalQueryVariables>(TemplateLinkUpdateModalDocument, options);
      }
export function useTemplateLinkUpdateModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateLinkUpdateModalQuery, TemplateLinkUpdateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateLinkUpdateModalQuery, TemplateLinkUpdateModalQueryVariables>(TemplateLinkUpdateModalDocument, options);
        }
export function useTemplateLinkUpdateModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TemplateLinkUpdateModalQuery, TemplateLinkUpdateModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateLinkUpdateModalQuery, TemplateLinkUpdateModalQueryVariables>(TemplateLinkUpdateModalDocument, options);
        }
export type TemplateLinkUpdateModalQueryHookResult = ReturnType<typeof useTemplateLinkUpdateModalQuery>;
export type TemplateLinkUpdateModalLazyQueryHookResult = ReturnType<typeof useTemplateLinkUpdateModalLazyQuery>;
export type TemplateLinkUpdateModalSuspenseQueryHookResult = ReturnType<typeof useTemplateLinkUpdateModalSuspenseQuery>;
export type TemplateLinkUpdateModalQueryResult = Apollo.QueryResult<TemplateLinkUpdateModalQuery, TemplateLinkUpdateModalQueryVariables>;