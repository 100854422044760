/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductFilterFields_AvailableFiltersQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filters?: Types.InputMaybe<Types.PimSearchProductsFilters>;
  specificFieldQueryFields?: Types.InputMaybe<Array<Types.PimSpecificFieldQueryField>>;
}>;


export type ProductFilterFields_AvailableFiltersQuery = { pimSearchProductsAvailableFilters: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchProductsAvailableFiltersResult', productTypeIds: Array<{ __typename: 'PimAvailableFilterProductType', id: string, count: number, productType: { __typename: 'PimProductTypeRef', id: string, key: string, label: string, typeSystem: Types.PimProductTypeSystemSystemType } }>, supplierIds: Array<{ __typename: 'PimAvailableFilterSupplier', id: string, count: number, organisation: { __typename: 'Company', id: string, title: string } }>, brands: Array<{ __typename: 'PimAvailableFilterBrand', key: string, label: string, count: number }>, brandLines: Array<{ __typename: 'PimAvailableFilterBrandLine', key: string, label: string, count: number }>, listPrice?: { __typename: 'PimAvailableFilterRangeNumber', min: number, max: number } | null, netPrice?: { __typename: 'PimAvailableFilterRangeNumber', min: number, max: number } | null, propertiesText: Array<{ __typename: 'PimAvailableFilterPropertyText', key: string, isOrganisationFavourite: boolean, values: Array<{ __typename: 'PimAvailableFilterPropertyValueText', count: number, value: string }> }>, propertiesBool: Array<{ __typename: 'PimAvailableFilterPropertyBool', key: string, isOrganisationFavourite: boolean, values: Array<{ __typename: 'PimAvailableFilterPropertyValueBool', count: number, value: boolean }> }>, propertiesNumber: Array<{ __typename: 'PimAvailableFilterPropertyNumber', key: string, isOrganisationFavourite: boolean, values: Array<{ __typename: 'PimAvailableFilterPropertyValueNumber', count: number, value: number }> }> } };


export const ProductFilterFields_AvailableFiltersDocument = gql`
    query ProductFilterFields_AvailableFilters($searchTerm: String, $filters: PimSearchProductsFilters, $specificFieldQueryFields: [PimSpecificFieldQueryField!]) {
  pimSearchProductsAvailableFilters(
    filters: $filters
    specificFieldQueryFields: $specificFieldQueryFields
    search: $searchTerm
  ) {
    ... on PimSearchProductsAvailableFiltersResult {
      productTypeIds {
        id
        count
        productType {
          id
          key
          label
          typeSystem
        }
      }
      supplierIds {
        id
        count
        organisation {
          id
          title
        }
      }
      brands {
        key
        label
        count
      }
      brandLines {
        key
        label
        count
      }
      listPrice {
        min
        max
      }
      netPrice {
        min
        max
      }
      propertiesText {
        key
        values {
          count
          value
        }
        isOrganisationFavourite
      }
      propertiesBool {
        key
        values {
          count
          value
        }
        isOrganisationFavourite
      }
      propertiesNumber {
        key
        values {
          count
          value
        }
        isOrganisationFavourite
      }
    }
  }
}
    `;

/**
 * __useProductFilterFields_AvailableFiltersQuery__
 *
 * To run a query within a React component, call `useProductFilterFields_AvailableFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFilterFields_AvailableFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFilterFields_AvailableFiltersQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      filters: // value for 'filters'
 *      specificFieldQueryFields: // value for 'specificFieldQueryFields'
 *   },
 * });
 */
export function useProductFilterFields_AvailableFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ProductFilterFields_AvailableFiltersQuery, ProductFilterFields_AvailableFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductFilterFields_AvailableFiltersQuery, ProductFilterFields_AvailableFiltersQueryVariables>(ProductFilterFields_AvailableFiltersDocument, options);
      }
export function useProductFilterFields_AvailableFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductFilterFields_AvailableFiltersQuery, ProductFilterFields_AvailableFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductFilterFields_AvailableFiltersQuery, ProductFilterFields_AvailableFiltersQueryVariables>(ProductFilterFields_AvailableFiltersDocument, options);
        }
export function useProductFilterFields_AvailableFiltersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductFilterFields_AvailableFiltersQuery, ProductFilterFields_AvailableFiltersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductFilterFields_AvailableFiltersQuery, ProductFilterFields_AvailableFiltersQueryVariables>(ProductFilterFields_AvailableFiltersDocument, options);
        }
export type ProductFilterFields_AvailableFiltersQueryHookResult = ReturnType<typeof useProductFilterFields_AvailableFiltersQuery>;
export type ProductFilterFields_AvailableFiltersLazyQueryHookResult = ReturnType<typeof useProductFilterFields_AvailableFiltersLazyQuery>;
export type ProductFilterFields_AvailableFiltersSuspenseQueryHookResult = ReturnType<typeof useProductFilterFields_AvailableFiltersSuspenseQuery>;
export type ProductFilterFields_AvailableFiltersQueryResult = Apollo.QueryResult<ProductFilterFields_AvailableFiltersQuery, ProductFilterFields_AvailableFiltersQueryVariables>;