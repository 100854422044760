import { Edit as EditIcon } from "@mui/icons-material";
import { EditOff as EditOffIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ViewEditMode, ViewMode } from "./ViewModeMenuItem.js";

interface Props {
  viewMode: ViewMode;
  onViewModeChange: (viewMode: ViewMode) => void;
}

export const ViewModeEditIconButton = ({
  viewMode,
  onViewModeChange,
}: Props) => {
  const { t } = useTranslate("Global");
  const viewEditMode: ViewEditMode = viewMode === "edit" ? "edit" : null;

  return (
    <IconButton
      size="small"
      color="primary"
      onClick={() => {
        if (viewEditMode === "edit") {
          onViewModeChange(null);
        } else {
          onViewModeChange("edit");
        }
      }}
    >
      {viewEditMode === "edit" ? (
        <Tooltip title={t("Exit edit mode")}>
          <EditOffIcon fontSize="small" />
        </Tooltip>
      ) : (
        <Tooltip title={t("Enter edit mode")}>
          <EditIcon fontSize="small" />
        </Tooltip>
      )}
    </IconButton>
  );
};
