import { CardContainer, LabeledTextValue } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { TemplateTypeOverviewHeaderBox_TemplateTypeFragment } from "./TemplateTypeOverviewHeaderBox.generated.js";

interface Props {
  templateType: TemplateTypeOverviewHeaderBox_TemplateTypeFragment;
}

export const TemplateTypeOverviewHeaderBox = ({ templateType }: Props) => {
  const { t } = useTranslate(["Global", "TemplateTypeOverview"]);

  return (
    <CardContainer>
      <Stack direction="column" p={1}>
        <LabeledTextValue
          label={t("Title", { ns: "TemplateTypeOverview" })}
          text={templateType.title}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
        {templateType.description && (
          <LabeledTextValue
            label={t("Description", { ns: "TemplateTypeOverview" })}
            text={templateType.description}
            notSetLabel={t("Not set", { ns: "Global" })}
            showMoreLabel={t("Show more", { ns: "Global" })}
            showLessLabel={t("Show less", { ns: "Global" })}
          />
        )}
      </Stack>
    </CardContainer>
  );
};
