/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type OrganisationPublicServicesBox_OrganisationProfileFragment = { __typename: 'OrganisationProfile', id: string, slug: string, skillset: Array<Types.Skill>, organisation: { __typename: 'Organisation', id: string } };

export const OrganisationPublicServicesBox_OrganisationProfileFragmentDoc = gql`
    fragment OrganisationPublicServicesBox_OrganisationProfile on OrganisationProfile {
  id
  slug
  skillset
  organisation {
    id
  }
}
    `;