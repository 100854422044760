import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  CircularProgress,
  TextField,
} from "@mui/material";
import { AutocompleteFreeSoloValueMapping } from "@mui/material/useAutocomplete";
import { Field, useField } from "formik";
import { Autocomplete } from "formik-mui";
import React from "react";

export interface AutocompleteFieldProps<
  Option,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean = false,
> extends Omit<
    AutocompleteProps<Option, false, DisableClearable, FreeSolo>,
    "value" | "onChange" | "renderInput"
  > {
  onChange?: (
    value: Option | AutocompleteFreeSoloValueMapping<FreeSolo> | null,
    event: React.ChangeEvent<{}>
  ) => void;
  name: string;
  inputLabel: string;
  inputPlaceholder?: string;
  isRequired?: boolean;
  autoFocus?: boolean;
  error?: string;
  loading?: boolean;
  startAdornment?: React.ReactNode;
}

export const AutocompleteField = <
  Option,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean = false,
>({
  name,
  autoFocus,
  disabled,
  isRequired,
  inputLabel,
  inputPlaceholder,
  onChange,
  loading,
  startAdornment,
  ...props
}: AutocompleteFieldProps<Option, DisableClearable, FreeSolo>) => {
  const [, { touched, error }, { setValue }] = useField<any>(name);

  return (
    <Field
      {...props}
      disabled={disabled}
      component={Autocomplete}
      name={name}
      required={isRequired}
      error={touched && !!error}
      loading={loading}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            name={name}
            label={inputLabel}
            placeholder={inputPlaceholder}
            autoFocus={autoFocus}
            required={isRequired}
            error={touched && !!error}
            helperText={
              touched
                ? typeof error === "string"
                  ? error
                  : undefined
                : undefined
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps?.endAdornment}
                </>
              ),
              startAdornment:
                startAdornment || params.InputProps?.startAdornment ? (
                  <>
                    {startAdornment}
                    {params.InputProps?.startAdornment}
                  </>
                ) : undefined,
            }}
          />
        );
      }}
      onChange={
        onChange
          ? (
              event: React.ChangeEvent,
              newValue:
                | Option
                | AutocompleteFreeSoloValueMapping<FreeSolo>
                | null
            ) => onChange(newValue, event)
          : undefined
      }
    />
  );
};
