import { LabeledSelectMultipleValue, LabeledValue } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { AskWhen, AskWhom } from "../../../clients/graphqlTypes.js";
import { useQuestionContol } from "./useQuestionContol.js";

interface Props {
  allowedAskWhen?: AskWhen[];
  askWhen: AskWhen[];
  askWhom: AskWhom[];
  prompt: string;
}

export const LabeledQuestionControlSection = ({
  askWhen,
  askWhom,
  prompt,
  allowedAskWhen,
}: Props) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);
  const { askWhomOptions, askWhenOptions } = useQuestionContol(allowedAskWhen);
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction={"row"} spacing={1}>
        <LabeledSelectMultipleValue
          label={t("Ask when", { ns: "QuoteItem" })}
          value={askWhen}
          options={askWhenOptions}
          notSetText={t("Not set", { ns: "Global" })}
        />
        <LabeledSelectMultipleValue
          label={t("Ask whom", { ns: "QuoteItem" })}
          value={askWhom}
          options={askWhomOptions}
          notSetText={t("Not set", { ns: "Global" })}
        />
      </Stack>
      <LabeledValue label={t("Prompt", { ns: "QuoteItem" })} notSet={!prompt}>
        {prompt || t("Not set", { ns: "Global" })}
      </LabeledValue>
    </Stack>
  );
};
