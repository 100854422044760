/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AvailableUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AvailableUsersQuery = { availableUsers: Array<{ __typename: 'AvailableUser', id: string, active: boolean, email: any, firstname: string, familyname: string, fullname: string, organisationTitle: string, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string } }> };


export const AvailableUsersDocument = gql`
    query AvailableUsers {
  availableUsers {
    id
    active
    email
    firstname
    familyname
    fullname
    avatar {
      id
      url
    }
    organisationTitle
  }
}
    `;

/**
 * __useAvailableUsersQuery__
 *
 * To run a query within a React component, call `useAvailableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableUsersQuery(baseOptions?: Apollo.QueryHookOptions<AvailableUsersQuery, AvailableUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableUsersQuery, AvailableUsersQueryVariables>(AvailableUsersDocument, options);
      }
export function useAvailableUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableUsersQuery, AvailableUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableUsersQuery, AvailableUsersQueryVariables>(AvailableUsersDocument, options);
        }
export function useAvailableUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AvailableUsersQuery, AvailableUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableUsersQuery, AvailableUsersQueryVariables>(AvailableUsersDocument, options);
        }
export type AvailableUsersQueryHookResult = ReturnType<typeof useAvailableUsersQuery>;
export type AvailableUsersLazyQueryHookResult = ReturnType<typeof useAvailableUsersLazyQuery>;
export type AvailableUsersSuspenseQueryHookResult = ReturnType<typeof useAvailableUsersSuspenseQuery>;
export type AvailableUsersQueryResult = Apollo.QueryResult<AvailableUsersQuery, AvailableUsersQueryVariables>;