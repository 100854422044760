import {
  DEFAULT_END_WORK_DAY,
  DEFAULT_START_WORK_DAY,
  encodeGlobalId,
} from "@msys/common";
import { ellipsisStyle, ModalOpenButton, normalizeTime } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useUnmount } from "react-use";
import { v4 } from "uuid";
import { AutocompleteField } from "../../commons/form-fields/AutocompleteField.js";
import { AutoSave, AutoSaveRef } from "../../commons/form-fields/AutoSave.js";
import { CheckboxField } from "../../commons/form-fields/CheckboxField.js";
import { DateTimePickerField } from "../../commons/form-fields/DateTimePickerField.js";
import { useScreenWidth } from "@msys/ui";
import { Stack } from "../../commons/layout/Stack.js";
import { ConfirmModal } from "../../commons/modals/ConfirmModal.js";
import { color } from "../../../common/MuiThemeProvider.js";
import { PlanningResourcesFragment } from "./PlanningSchedule.generated.js";
import { useTranslate } from "@tolgee/react";
import { durationInMinutesToTime } from "../../utils.js";
import {
  getNextWorkingSessionDate,
  getTotalDurationInMinutes,
} from "./helpers.js";
import { ScheduleWhoFragment } from "../schedule/Fragments.generated.js";

export interface PlanningSessionsFormRef {
  /**
   * Force trigger saving process from outside
   */
  save: () => void;
}

export const createNewSession = (
  now: moment.Moment,
  projectIsTicket: boolean,
  defaultProjectDuration: number,
  defaultTicketDuration: number,
  lastPlanSession: PlanSessionForm | null,
  startWorkDay: string = DEFAULT_START_WORK_DAY, // should be in HH:mm format
  endWorkDay: string = DEFAULT_END_WORK_DAY // should be in HH:mm format
): PlanSessionForm => {
  const lastFrom = lastPlanSession?.from ?? null;
  const lastTill = lastPlanSession?.till ?? null;
  const [from, till] = getNextWorkingSessionDate(
    now,
    projectIsTicket,
    defaultProjectDuration,
    defaultTicketDuration,
    lastFrom,
    lastTill,
    startWorkDay,
    endWorkDay
  );
  return {
    id: encodeGlobalId("PlanSession", v4()),
    user: null,
    who: null,
    from,
    till,
    isTentative: false,
  };
};

export interface PlanSessionForm {
  id: string;
  from: moment.Moment;
  till: moment.Moment;
  user: {
    value: string;
    label: string;
  } | null;
  who: ScheduleWhoFragment | null;
  isTentative: boolean;
}

interface FormValues {
  planSessions: PlanSessionForm[];
}

interface Props {
  craftsmen: PlanningResourcesFragment[];
  projectIsTicket: boolean;
  projectDuration?: number;
  ticketDuration?: number;
  startWorkDay?: string;
  endWorkDay?: string;
  initialValues: FormValues;
  filterValues: (values: FormValues) => FormValues;
  isEqualForm: (
    v1: FormValues | undefined,
    v2: FormValues | undefined
  ) => boolean;
  onTotalDurationChange?(duration: number): void;
  showTotalDuration?: boolean;
}

export const PlanningSessionsForm = React.forwardRef<
  PlanningSessionsFormRef,
  Props
>(
  (
    {
      craftsmen,
      projectIsTicket,
      startWorkDay = DEFAULT_START_WORK_DAY,
      endWorkDay = DEFAULT_END_WORK_DAY,
      projectDuration = 0,
      ticketDuration = 0,
      initialValues,
      filterValues,
      isEqualForm,
      onTotalDurationChange,
      showTotalDuration = true,
    },
    forwardedRef
  ) => {
    const {
      values: { planSessions },
    } = useFormikContext<FormValues>();
    const { isMinTablet, isMinDesktop } = useScreenWidth();
    const { t } = useTranslate(["PlanningModal", "Global"]);
    const craftsmenOptions = craftsmen.map(u => ({
      value: u.id,
      label: u.fullname,
    }));

    const { setFieldValue } = useFormikContext();

    const autoSaveTimeoutId = useRef<ReturnType<typeof setTimeout>>();
    const autoSaveRef = useRef<AutoSaveRef>(null);

    const forceSave = useCallback(() => {
      if (autoSaveTimeoutId.current) clearTimeout(autoSaveTimeoutId.current);
      autoSaveTimeoutId.current = setTimeout(() => {
        autoSaveRef.current?.save();
      }, 250);
    }, []);

    useUnmount(() => {
      if (autoSaveTimeoutId.current) clearTimeout(autoSaveTimeoutId.current);
    });

    useImperativeHandle(forwardedRef, () => ({
      save: forceSave,
    }));

    const containerRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = useCallback(() => {
      if (containerRef.current)
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, []);

    const now = useMemo(() => moment().add(1, "hours").startOf("hour"), []);

    const durationsCache = useRef(new Map());

    const durations: { [id: string]: { isActive: boolean; minutes: number } } =
      useMemo(
        () =>
          planSessions.reduce((acc, session) => {
            const key =
              session.from && session.till
                ? `${session.from.format()}-${session.till.format()}`
                : null;

            let duration = key
              ? (durationsCache.current.get(key) ?? null)
              : null;
            const isActive = !!session.user?.value;

            if (duration === null && key !== null) {
              // expensive operation
              duration = getTotalDurationInMinutes(
                session.from,
                session.till,
                normalizeTime(startWorkDay),
                normalizeTime(endWorkDay)
              );
              durationsCache.current.set(key, duration);
            }

            return { ...acc, [session.id]: { isActive, minutes: duration } };
          }, {}),
        [planSessions, startWorkDay, endWorkDay]
      );

    const totalDuration = useMemo(() => {
      return Object.keys(durations).reduce(
        (acc, id) => acc + (durations[id].isActive ? durations[id].minutes : 0),
        0
      );
    }, [durations]);

    React.useEffect(() => {
      onTotalDurationChange?.(totalDuration);
    }, [totalDuration, onTotalDurationChange]);

    return (
      <FieldArray
        name="planSessions"
        render={({ remove, push }) => (
          <Box
            height="100%"
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            {isMinTablet && (
              <Stack
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                flexGrow={0}
                flexShrink={0}
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption" color="textSecondary">
                      {t("Team member", {
                        ns: "PlanningModal",
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant="caption" color="textSecondary">
                      {t("From", {
                        ns: "PlanningModal",
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant="caption" color="textSecondary">
                      {t("Until", {
                        ns: "PlanningModal",
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Stack alignItems="center" justifyContent="space-between">
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        align="left"
                        component="div"
                      >
                        {t("Tentative", {
                          ns: "PlanningModal",
                        })}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        align="right"
                        component="div"
                      >
                        {t("Duration", {
                          ns: "PlanningModal",
                        })}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => {
                    push(
                      createNewSession(
                        now,
                        projectIsTicket,
                        projectDuration,
                        ticketDuration,
                        planSessions?.[planSessions.length - 1] ?? null,
                        startWorkDay,
                        endWorkDay
                      )
                    );
                    setTimeout(scrollToBottom);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Stack>
            )}
            <Stack
              flexDirection="column"
              spacing={0.5}
              alignItems="stretch"
              overflow="auto"
              flexGrow={1}
              flexShrink={1}
              ref={containerRef}
              paddingBottom={!isMinDesktop ? "38px" : undefined}
            >
              {planSessions.length === 0 ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => {
                      push(
                        createNewSession(
                          now,
                          projectIsTicket,
                          projectDuration,
                          ticketDuration,
                          planSessions?.[planSessions.length - 1] ?? null,
                          startWorkDay,
                          endWorkDay
                        )
                      );
                      setTimeout(scrollToBottom);
                    }}
                    startIcon={<AddIcon />}
                  >
                    {t("New session", {
                      ns: "PlanningModal",
                    })}
                  </Button>
                </Box>
              ) : (
                planSessions.map((session, index) => {
                  const duration = durationInMinutesToTime(
                    durations[session.id].minutes
                  );
                  const isActive = !!session.user?.value;
                  return (
                    <React.Fragment key={session.id}>
                      {!isMinTablet && index > 0 && <Divider />}
                      <Stack
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid container spacing={1} alignItems="flex-start">
                          <Grid item xs={12} sm={4} md={4}>
                            <AutocompleteField
                              name={`planSessions[${index}].user`}
                              disableClearable
                              inputLabel=""
                              inputPlaceholder={t("Team member", {
                                ns: "PlanningModal",
                              })}
                              options={craftsmenOptions}
                              noOptionsText={t("No existing entry", {
                                ns: "Global",
                              })}
                              getOptionLabel={option => option.label}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              disabled={false}
                              onChange={newValue => {
                                const who = newValue?.value
                                  ? craftsmen.find(c => c.id === newValue.value)
                                  : null;
                                setFieldValue(
                                  `planSessions[${index}].who`,
                                  who ?? null
                                );
                                setFieldValue(
                                  `planSessions[${index}].user`,
                                  newValue
                                );
                                forceSave();
                              }}
                              size="extra-small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={3}>
                            <DateTimePickerField
                              label=""
                              placeholder={t("From", {
                                ns: "PlanningModal",
                              })}
                              name={`planSessions[${index}].from`}
                              disabled={false}
                              size="extra-small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={3}>
                            <DateTimePickerField
                              label=""
                              placeholder={t("Until", {
                                ns: "PlanningModal",
                              })}
                              name={`planSessions[${index}].till`}
                              disabled={false}
                              size="extra-small"
                              // minValue={moment(session.from).add(1, "minute")}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            {!isMinTablet ? (
                              <Stack
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <CheckboxField
                                  name={`planSessions[${index}].isTentative`}
                                  label={t("Tentative", {
                                    ns: "PlanningModal",
                                  })}
                                  disabled={false}
                                  size="small"
                                  sx={{ padding: "5px", ml: "4px" }}
                                />
                                <Typography
                                  align="right"
                                  style={{
                                    color: !isActive ? color.grey : undefined,
                                    ...ellipsisStyle,
                                  }}
                                >
                                  {t("Duration", {
                                    ns: "PlanningModal",
                                  })}
                                  : {duration} h
                                </Typography>
                              </Stack>
                            ) : (
                              <Stack
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box
                                  height="30px"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  <CheckboxField
                                    name={`planSessions[${index}].isTentative`}
                                    label={""}
                                    disabled={false}
                                    size="small"
                                    sx={{ padding: "5px", ml: "4px" }}
                                  />
                                </Box>
                                <Box
                                  height="30px"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  <Typography
                                    align="right"
                                    style={{
                                      color: !isActive ? color.grey : undefined,
                                      ...ellipsisStyle,
                                    }}
                                  >
                                    {duration} h
                                  </Typography>
                                </Box>
                              </Stack>
                            )}
                          </Grid>
                        </Grid>
                        <Box
                          height="30px"
                          display="flex"
                          alignItems="center"
                          alignSelf={!isMinTablet ? "center" : undefined}
                        >
                          {!isActive ? (
                            <IconButton
                              color="secondary"
                              size="small"
                              onClick={() => remove(index)}
                              sx={{ width: "30px", height: "30px" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <ModalOpenButton
                              Modal={ConfirmModal}
                              modalProps={{
                                text: t("Session will be permanently removed", {
                                  ns: "PlanningModal",
                                }),
                                handleConfirm: async () => {
                                  await remove(index);
                                  await forceSave();
                                },
                              }}
                            >
                              <IconButton
                                color="secondary"
                                size="small"
                                sx={{ width: "30px", height: "30px" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ModalOpenButton>
                          )}
                        </Box>
                      </Stack>
                    </React.Fragment>
                  );
                })
              )}
            </Stack>
            {showTotalDuration ? (
              <Stack
                borderTop={`1px solid ${color.primary}`}
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                flexGrow={0}
                flexShrink={0}
              >
                <Grid container spacing={1}>
                  <Grid item xs={4} md={4}>
                    <Box pt={0.5} style={ellipsisStyle}>
                      <AutoSave
                        ref={autoSaveRef}
                        isEqualForm={isEqualForm}
                        enableReinitialize
                        initialValues={initialValues}
                        filterValues={filterValues}
                        variant="body1"
                        justifyContent="flex-start"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Box pt={0.5}>
                      <Typography align="right">
                        {t("Total duration", { ns: "PlanningModal" })}:{" "}
                        {durationInMinutesToTime(totalDuration)} h
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <div style={{ width: 30 }} />
              </Stack>
            ) : (
              <AutoSave
                ref={autoSaveRef}
                isEqualForm={isEqualForm}
                enableReinitialize
                initialValues={initialValues}
                filterValues={filterValues}
                variant="body1"
                justifyContent="flex-end"
              />
            )}

            {!isMinDesktop && (
              <Box
                position="sticky"
                bottom={0}
                flexShrink={0}
                flexGrow={0}
                height={0}
              >
                <Fab
                  variant="extended"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    push(
                      createNewSession(
                        now,
                        projectIsTicket,
                        projectDuration,
                        ticketDuration,
                        planSessions?.[planSessions.length - 1] ?? null,
                        startWorkDay,
                        endWorkDay
                      )
                    );
                    setTimeout(scrollToBottom);
                  }}
                  sx={{ position: "absolute", bottom: 0 }}
                >
                  <AddIcon sx={{ mr: 1 }} />
                  {t("New session", { ns: "PlanningModal" })}
                </Fab>
              </Box>
            )}
          </Box>
        )}
      />
    );
  }
);
