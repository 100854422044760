import { Capacitor } from "@capacitor/core";
import { useLocalStorageAsState } from "@msys/ui";
import { noop } from "lodash-es";
import React from "react";

type Feature =
  | "Animations"
  | "ClientVisibility"
  | "Shop"
  | "ChangeItemType"
  | "DebugOutput"
  | "ThreeDDevelop"
  | "CrmEmailContentDebug"
  | "ArticleLists"
  | "TemplateDebug"
  | "QuoteExternalIdentifier"
  | "Marketplace"
  | "SupplierDiscountGroupVariants"
  | "DocSharing"
  | "ViewerPermissionDetails";

type Features = Record<Feature, boolean>;

interface FeatureFlagContext {
  features: Features;
  toggleFeature: (name: Feature) => void;
}

const initialFeatureFlags: Features = {
  Animations: Capacitor.isNativePlatform(),
  ClientVisibility: Capacitor.isNativePlatform(),
  Shop: false,
  ChangeItemType: false,
  DebugOutput: false,
  ThreeDDevelop: false,
  CrmEmailContentDebug: false,
  ArticleLists: false,
  TemplateDebug: false,
  QuoteExternalIdentifier: false,
  Marketplace: false,
  SupplierDiscountGroupVariants: false,
  DocSharing: false,
  ViewerPermissionDetails: false,
};

const FeatureFlags = React.createContext<FeatureFlagContext>({
  features: initialFeatureFlags,
  toggleFeature: noop,
});

export const FeatureProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [features, setFeatures] = useLocalStorageAsState<Features>(
    `msys-features`,
    initialFeatureFlags
  );

  const toggleFeature = React.useCallback(
    (name: Feature) => {
      setFeatures({ ...features, [name]: !features[name] });
    },
    [features, setFeatures]
  );

  return (
    <FeatureFlags.Provider value={{ features, toggleFeature }}>
      {children}
    </FeatureFlags.Provider>
  );
};

export function useFeatures() {
  const { features } = React.useContext(FeatureFlags);
  return features;
}

export function useFeature(name: Feature) {
  const { features } = React.useContext(FeatureFlags);
  if (features === null) {
    throw new Error("You must wrap your components in a FeatureProvider.");
  }

  return features[name] ?? initialFeatureFlags[name];
}

export function useFeatureWithToggle(name: Feature) {
  const { toggleFeature } = React.useContext(FeatureFlags);

  const feature = useFeature(name);

  const toggle = React.useCallback(() => {
    toggleFeature(name);
  }, [name, toggleFeature]);

  return { enabled: feature, toggle };
}
