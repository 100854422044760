/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectMemberCrmModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectMemberCrmModalQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, crmOrganisation?: { __typename: 'CrmCompany', id: string, members: Array<{ __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }> }> } | null } | null } };

export type AddProjectContracteeInvitationMutationVariables = Types.Exact<{
  input: Types.AddProjectContracteeInvitationInput;
}>;


export type AddProjectContracteeInvitationMutation = { addProjectContracteeInvitation: { __typename: 'AddProjectContracteeInvitationResult', invitation: { __typename: 'ProjectContracteeInvitation', id: string, invitationUrl: string } } };


export const ProjectMemberCrmModalDocument = gql`
    query ProjectMemberCrmModal($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        crmOrganisation {
          id
          members {
            id
            title
            description
            website
            isMeister
            familyname
            firstname
            fullname
            locale
            skillset
            id
            email
            phones {
              id
              main
              type
              number
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProjectMemberCrmModalQuery__
 *
 * To run a query within a React component, call `useProjectMemberCrmModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMemberCrmModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMemberCrmModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectMemberCrmModalQuery(baseOptions: Apollo.QueryHookOptions<ProjectMemberCrmModalQuery, ProjectMemberCrmModalQueryVariables> & ({ variables: ProjectMemberCrmModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectMemberCrmModalQuery, ProjectMemberCrmModalQueryVariables>(ProjectMemberCrmModalDocument, options);
      }
export function useProjectMemberCrmModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectMemberCrmModalQuery, ProjectMemberCrmModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectMemberCrmModalQuery, ProjectMemberCrmModalQueryVariables>(ProjectMemberCrmModalDocument, options);
        }
export function useProjectMemberCrmModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectMemberCrmModalQuery, ProjectMemberCrmModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectMemberCrmModalQuery, ProjectMemberCrmModalQueryVariables>(ProjectMemberCrmModalDocument, options);
        }
export type ProjectMemberCrmModalQueryHookResult = ReturnType<typeof useProjectMemberCrmModalQuery>;
export type ProjectMemberCrmModalLazyQueryHookResult = ReturnType<typeof useProjectMemberCrmModalLazyQuery>;
export type ProjectMemberCrmModalSuspenseQueryHookResult = ReturnType<typeof useProjectMemberCrmModalSuspenseQuery>;
export type ProjectMemberCrmModalQueryResult = Apollo.QueryResult<ProjectMemberCrmModalQuery, ProjectMemberCrmModalQueryVariables>;
export const AddProjectContracteeInvitationDocument = gql`
    mutation AddProjectContracteeInvitation($input: AddProjectContracteeInvitationInput!) {
  addProjectContracteeInvitation(input: $input) {
    invitation {
      id
      invitationUrl
    }
  }
}
    `;
export type AddProjectContracteeInvitationMutationFn = Apollo.MutationFunction<AddProjectContracteeInvitationMutation, AddProjectContracteeInvitationMutationVariables>;

/**
 * __useAddProjectContracteeInvitationMutation__
 *
 * To run a mutation, you first call `useAddProjectContracteeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectContracteeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectContracteeInvitationMutation, { data, loading, error }] = useAddProjectContracteeInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProjectContracteeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectContracteeInvitationMutation, AddProjectContracteeInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectContracteeInvitationMutation, AddProjectContracteeInvitationMutationVariables>(AddProjectContracteeInvitationDocument, options);
      }
export type AddProjectContracteeInvitationMutationHookResult = ReturnType<typeof useAddProjectContracteeInvitationMutation>;
export type AddProjectContracteeInvitationMutationResult = Apollo.MutationResult<AddProjectContracteeInvitationMutation>;
export type AddProjectContracteeInvitationMutationOptions = Apollo.BaseMutationOptions<AddProjectContracteeInvitationMutation, AddProjectContracteeInvitationMutationVariables>;