import { Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  TemplateBase,
  TemplatesDisplay,
  TemplatesDisplayProps,
} from "./TemplatesDisplay.js";

export const TemplateSelectModal = ({
  handleClose,
  handleComplete,
  title,
  ...props
}: TemplatesDisplayProps & {
  handleClose: () => void;
  handleComplete: (template: TemplateBase, handleClose: () => void) => void;
  title: string;
}) => {
  const { t } = useTranslate(["Templates", "Global"]);

  const [selectedTemplate, setSelectedTemplate] =
    React.useState<TemplateBase | null>(null);

  return (
    <Modal
      title={title}
      maxWidth="xl"
      fixedHeight
      actionButtons={[
        {
          label: t("Cancel", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Select", { ns: "Global" }),
          handleClick: () => {
            if (!selectedTemplate) {
              throw new Error("Template missing");
            }
            handleComplete(selectedTemplate, handleClose);
          },
          buttonProps: {
            disabled: !selectedTemplate,
          },
        },
      ]}
      handleClose={handleClose}
    >
      <TemplatesDisplay
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        onInspectedTemplateClick={template => {
          handleComplete(template, handleClose);
        }}
        {...props}
      />
    </Modal>
  );
};
