import { notNull } from "@msys/common";
import {
  DebouncedSearchInput,
  ListHeader,
  MenuButton,
  MenuItemWithIcon,
  ModalOpenButton,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import { Checklist as ChecklistIcon } from "@mui/icons-material";
import { Settings as SettingsIcon } from "@mui/icons-material";
import { ViewKanban as ViewKanbanIcon } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  namedOperations,
  OrganisationProjectsSorting,
  ProjectFilterKind,
  ProjectStateMachineStatus,
} from "../../../clients/graphqlTypes.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission.js";
import { useUserData } from "../../auth/useUserData.js";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton.js";
import { FilterButton } from "../../commons/filters/FilterButton.js";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView.js";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../constants.js";
import { useCustomFieldConfig } from "../../features/custom-fields/useCustomFieldConfig.js";
import { ImportCustomGaebP84Button } from "../../features/importExport/gaebImport/ImportCustomGaebP84Button.js";
import { ImportXiopdButton } from "../../features/importExport/xiopdImport/ImportXiopdButton.js";
import { OpportunityListItemDisplayConfig } from "../../features/opportunities/components/OpportunityListItem.js";
import { CreateOpportunityButton } from "../../features/opportunities/CreateOpportunityButton.js";
import { OpportunitiesKanban } from "../../features/opportunities/OpportunitiesKanban.js";
import {
  DEFAULT_SORTING,
  Filters,
  OpportunitiesList,
} from "../../features/opportunities/OpportunitiesList.js";
import {
  defaultListItemDisplayConfig,
  OpportunitiesListItemVisibilitySettingsModal,
} from "../../features/opportunities/OpportunitiesListItemVisibilitySettingsModal.js";
import { ImportVisibilityKeys } from "../../features/organisations/boxes/OrganisationProjectImportSettingsBox.js";
import { FilterRelevantToMe } from "../../features/projects/filters/index.js";
import { FilterProjectAssigneesButton } from "../../features/projects/filters/ProjectAssignees.js";
import { FilterProjectBudgetButton } from "../../features/projects/filters/ProjectBudget.js";
import { FilterProjectKindButton } from "../../features/projects/filters/ProjectKind.js";
import { ProjectsFilterModal } from "../../features/projects/ProjectsFilterModal.js";
import { renderProjectsFilterChips } from "../../features/projects/renderProjectsFilterChips.js";
import { PROJECT_FILTER_MAP } from "../../features/projects/useProjectSources.js";
import { ImportS4HanaSalesQuotationButton } from "../../features/sap-s4-hana/ImportS4HanaSalesQuotationButton.js";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore.js";
import { useUserPreference } from "../../features/users/useUserPreference.js";
import { useVisibilityStore } from "../../features/users/useVisibilityStore.js";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list", "kanban"];

interface Props {
  submenuItems: PageTopbarItem[];
}

export function OpportunitiesCurrent({ submenuItems }: Props) {
  const { t } = useTranslate([
    "Projects",
    "Tickets",
    "Opportunities",
    "Categories",
    "Global",
  ]);
  const { isMinTablet } = useScreenWidth();

  const { getFormattedPrice, getFormattedFloat } = useFormatting();

  const viewer = useUserData().currentUser!;

  const [isConfiguring, setIsConfiguring] = React.useState<boolean>(false);

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "opportunities-current",
      "kanban",
      "list"
    );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OrganisationProjectsSorting, Filters>(
    { kind: ["DEFAULT", "TICKET"] },
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const baseVariables = React.useMemo(
    () => ({
      offset,
      limit,
      sorting: DEFAULT_SORTING,
    }),
    [offset, limit]
  );

  const allowedStates: ProjectStateMachineStatus[] = React.useMemo(
    () => ["opportunity"],
    []
  );

  const variables = React.useMemo(
    () => ({
      ...baseVariables,
      ...filters,
      filterBySources: filters.sources
        ? filters.sources.map(s => PROJECT_FILTER_MAP[s]).flat(1)
        : undefined,
      filterIncludeState: filters.filterIncludeState?.length
        ? filters.filterIncludeState
        : allowedStates,
      sorting,
    }),
    [baseVariables, filters, sorting, allowedStates]
  );

  const stateStore = useDataGridStateStore("OpportunitiesCurrent");

  const importVisibilityStore = useVisibilityStore<ImportVisibilityKeys>(
    "OpportunitiesCurrent-Import"
  );
  const listItemDisplayConfigStore =
    useUserPreference<OpportunityListItemDisplayConfig>(
      `DisplayConfig-OpportunitiesCurrent`,
      defaultListItemDisplayConfig
    );

  const menuItems = !importVisibilityStore.loading
    ? [
        <RestrictedByOrganisationPermissionWithDebug
          permission="CREATE_PROJECT"
          key="import-gaeb"
        >
          {importVisibilityStore.value.gaeb !== false ? (
            <ImportCustomGaebP84Button
              projectParams={{ isTentative: true }}
              refetchQueries={[
                namedOperations.Query.OpportunitiesList,
                namedOperations.Query.OpportunitiesKanbanList,
              ]}
            />
          ) : null}
        </RestrictedByOrganisationPermissionWithDebug>,
        <RestrictedByOrganisationPermissionWithDebug
          permission="CREATE_PROJECT"
          key="import-xiopd"
        >
          {importVisibilityStore.value.xiopd !== false ? (
            <ImportXiopdButton
              projectParams={{ isTentative: true }}
              refetchQueries={[
                namedOperations.Query.OpportunitiesList,
                namedOperations.Query.OpportunitiesKanbanList,
              ]}
            />
          ) : null}
        </RestrictedByOrganisationPermissionWithDebug>,
        viewer.organisation.enabledIntegrationIds.includes("sap_s4hana") ? (
          <RestrictedByOrganisationPermissionWithDebug
            permission="CREATE_PROJECT"
            key="import-s4-hana-salesquoation"
          >
            <ImportS4HanaSalesQuotationButton
              projectId={null}
              refetchQueries={[
                namedOperations.Query.OpportunitiesList,
                namedOperations.Query.OpportunitiesKanbanList,
              ]}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        ) : null,
      ].filter(notNull)
    : [];

  const settingsItems = [
    activeView !== "table" ? (
      <ModalOpenButton
        key="opportunities-list-item-visibility-settings"
        Modal={OpportunitiesListItemVisibilitySettingsModal}
        modalProps={{
          listItemDisplayConfigStore,
        }}
      >
        <MenuItemWithIcon icon={<ChecklistIcon />}>
          {t("Opportunities view settings", { ns: "Opportunities" })}
        </MenuItemWithIcon>
      </ModalOpenButton>
    ) : null,
    activeView === "kanban" ? (
      <RestrictedByOrganisationPermissionWithDebug
        key="opportunities-configure-pipeline"
        permission="MANAGE_ORG"
      >
        <MenuItemWithIcon
          disabled={isConfiguring}
          icon={<ViewKanbanIcon />}
          onClick={() => {
            setIsConfiguring(true);
          }}
        >
          {t("Configure pipeline", { ns: "Opportunities" })}
        </MenuItemWithIcon>
      </RestrictedByOrganisationPermissionWithDebug>
    ) : null,
  ].filter(notNull);

  const onKindChange = (
    value: ProjectFilterKind | null | undefined
  ): Partial<Filters> => ({
    kind: !value ? ["DEFAULT", "TICKET"] : [value],
    filterKind: value,
  });

  const { customFieldConfig: customFieldConfigs } =
    useCustomFieldConfig("Project");

  return (
    <Page
      subtitle={t("Opportunities", { ns: "Opportunities" })}
      title={t("Current", { ns: "Opportunities" })}
      submenuItems={submenuItems}
    >
      <PageContainer $fullHeight={activeView === "kanban"}>
        <ListHeader
          // hideTitleOnMobile
          // title={title}
          QuickFilter={
            isMinTablet ? (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                flexShrink={1}
                flexGrow={0}
              >
                {viewer.organisation.isCraftsmanOrganisation ? (
                  <FilterProjectKindButton
                    value={filters.kind ?? []}
                    setValue={newValue =>
                      setFilters(state => ({
                        ...state,
                        kind: newValue,
                        filterKind:
                          newValue.length === 0 || newValue.length === 2
                            ? null
                            : newValue[0],
                      }))
                    }
                  />
                ) : undefined}
                <FilterProjectBudgetButton
                  filters={filters}
                  setFilters={setFilters}
                  variables={variables}
                />
                <FilterProjectAssigneesButton
                  unassigned={filters.filterUnassigned ?? false}
                  setUnassigned={unassigned =>
                    setFilters(filters => ({
                      ...filters,
                      filterUnassigned: unassigned || undefined,
                    }))
                  }
                  value={filters.filterAssigneeIds ?? []}
                  setValue={(newValue: string[]) =>
                    setFilters(filters => ({
                      ...filters,
                      filterAssigneeIds: newValue,
                    }))
                  }
                />
              </Stack>
            ) : undefined
          }
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS.filter(view => {
                if (
                  viewer.organisation.isClientOrganisation &&
                  view === "kanban"
                )
                  return false;
                return true;
              })}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          FilterButton={
            <ModalOpenButton
              Modal={ProjectsFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
                allowedStates,
                customFieldConfigs,
                onKindChange,
                showPhasesFilter: activeView !== "kanban",
                showSorting: activeView !== "kanban",
                showRelevantToMe: !isMinTablet,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          FilterFields={
            isMinTablet ? (
              <Box display="flex" alignItems="center" height={0} pl={1.5}>
                <FilterRelevantToMe filters={filters} setFilters={setFilters} />
              </Box>
            ) : undefined
          }
          ActionButtons={
            settingsItems.length > 0 ? (
              <MenuButton Icon={<SettingsIcon />}>{settingsItems}</MenuButton>
            ) : undefined
          }
          MenuButton={
            menuItems.length > 0 ? (
              <MenuButton>{menuItems}</MenuButton>
            ) : undefined
          }
          CreateButton={
            <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
              <CreateOpportunityButton
                pageName="Opportunities"
                type="button"
                initialCreateType={
                  filters.kind &&
                  filters.kind.length === 1 &&
                  filters.kind.at(0)! === "TICKET"
                    ? "ticket"
                    : "project"
                }
              />
            </RestrictedByOrganisationPermissionWithDebug>
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.filterSearchTerm}
              onChangeSearchTerm={newValue =>
                setFilters(filters => ({
                  ...filters,
                  filterSearchTerm: newValue,
                }))
              }
            />
          }
          FilterChips={renderProjectsFilterChips({
            viewer,
            t,
            filters,
            setFilters,
            allowedStates,
            getFormattedPrice,
            getFormattedFloat,
            onKindChange,
            showRelevantToMe: !isMinTablet,
            showPhasesChip: activeView !== "kanban",
          })}
          mb={2}
        />
        {!listItemDisplayConfigStore.loading &&
          (activeView === "kanban" ? (
            <OpportunitiesKanban
              variables={variables}
              displayConfig={listItemDisplayConfigStore.value}
              isConfiguring={isConfiguring}
              setIsConfiguring={setIsConfiguring}
            />
          ) : (
            <OpportunitiesList
              sorting={sorting}
              setSorting={setSorting}
              activeView={activeView}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              showStatus={allowedStates.length > 1}
              showPhase={true}
              stateStore={stateStore}
              displayConfig={listItemDisplayConfigStore.value}
              variables={variables}
            />
          ))}
      </PageContainer>
    </Page>
  );
}
