/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanySupplierCataloguesBox__SupplierCatalogueFragment = { __typename: 'PimSupplierCatalogue', id: string, isDefault: boolean, supplierId: string, title: string, supplierCatalogueSubscription?: { __typename: 'PimSupplierCatalogueSubscription', id: string, createdAt: any } | null };

export type CrmCompanySupplierCataloguesBoxQueryVariables = Types.Exact<{
  supplierId: Types.Scalars['ID']['input'];
}>;


export type CrmCompanySupplierCataloguesBoxQuery = { pimGetSupplierCatalogues: { __typename: 'MissingCapabilities' } | { __typename: 'PimGetSupplierCataloguesResult', supplierCatalogues: Array<{ __typename: 'PimSupplierCatalogue', id: string, isDefault: boolean, supplierId: string, title: string, supplierCatalogueSubscription?: { __typename: 'PimSupplierCatalogueSubscription', id: string, createdAt: any } | null }> } };

export type CrmCompanySupplierCataloguesBox__SubscribeToMutationVariables = Types.Exact<{
  input: Types.PimSubscribeToSupplierCatalogueInput;
}>;


export type CrmCompanySupplierCataloguesBox__SubscribeToMutation = { pimSubscribeToSupplierCatalogue: { __typename: 'PimSubscribeToSupplierCatalogueResult', supplierCatalogue: { __typename: 'PimSupplierCatalogue', id: string, isDefault: boolean, supplierId: string, title: string, supplierCatalogueSubscription?: { __typename: 'PimSupplierCatalogueSubscription', id: string, createdAt: any } | null } } };

export type CrmCompanySupplierCataloguesBox__UnsubscribeFromMutationVariables = Types.Exact<{
  input: Types.PimUnsubscribeFromSupplierCatalogueInput;
}>;


export type CrmCompanySupplierCataloguesBox__UnsubscribeFromMutation = { pimUnsubscribeFromSupplierCatalogue: { __typename: 'PimUnsubscribeFromSupplierCatalogueResult', supplierCatalogue: { __typename: 'PimSupplierCatalogue', id: string, isDefault: boolean, supplierId: string, title: string, supplierCatalogueSubscription?: { __typename: 'PimSupplierCatalogueSubscription', id: string, createdAt: any } | null } } };

export const CrmCompanySupplierCataloguesBox__SupplierCatalogueFragmentDoc = gql`
    fragment CrmCompanySupplierCataloguesBox__SupplierCatalogue on PimSupplierCatalogue {
  id
  isDefault
  supplierId
  title
  supplierCatalogueSubscription {
    id
    createdAt
  }
}
    `;
export const CrmCompanySupplierCataloguesBoxDocument = gql`
    query CrmCompanySupplierCataloguesBox($supplierId: ID!) {
  pimGetSupplierCatalogues(supplierId: $supplierId) {
    ... on PimGetSupplierCataloguesResult {
      supplierCatalogues {
        id
        ...CrmCompanySupplierCataloguesBox__SupplierCatalogue
      }
    }
  }
}
    ${CrmCompanySupplierCataloguesBox__SupplierCatalogueFragmentDoc}`;

/**
 * __useCrmCompanySupplierCataloguesBoxQuery__
 *
 * To run a query within a React component, call `useCrmCompanySupplierCataloguesBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanySupplierCataloguesBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCompanySupplierCataloguesBoxQuery({
 *   variables: {
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useCrmCompanySupplierCataloguesBoxQuery(baseOptions: Apollo.QueryHookOptions<CrmCompanySupplierCataloguesBoxQuery, CrmCompanySupplierCataloguesBoxQueryVariables> & ({ variables: CrmCompanySupplierCataloguesBoxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCompanySupplierCataloguesBoxQuery, CrmCompanySupplierCataloguesBoxQueryVariables>(CrmCompanySupplierCataloguesBoxDocument, options);
      }
export function useCrmCompanySupplierCataloguesBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCompanySupplierCataloguesBoxQuery, CrmCompanySupplierCataloguesBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCompanySupplierCataloguesBoxQuery, CrmCompanySupplierCataloguesBoxQueryVariables>(CrmCompanySupplierCataloguesBoxDocument, options);
        }
export function useCrmCompanySupplierCataloguesBoxSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CrmCompanySupplierCataloguesBoxQuery, CrmCompanySupplierCataloguesBoxQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCompanySupplierCataloguesBoxQuery, CrmCompanySupplierCataloguesBoxQueryVariables>(CrmCompanySupplierCataloguesBoxDocument, options);
        }
export type CrmCompanySupplierCataloguesBoxQueryHookResult = ReturnType<typeof useCrmCompanySupplierCataloguesBoxQuery>;
export type CrmCompanySupplierCataloguesBoxLazyQueryHookResult = ReturnType<typeof useCrmCompanySupplierCataloguesBoxLazyQuery>;
export type CrmCompanySupplierCataloguesBoxSuspenseQueryHookResult = ReturnType<typeof useCrmCompanySupplierCataloguesBoxSuspenseQuery>;
export type CrmCompanySupplierCataloguesBoxQueryResult = Apollo.QueryResult<CrmCompanySupplierCataloguesBoxQuery, CrmCompanySupplierCataloguesBoxQueryVariables>;
export const CrmCompanySupplierCataloguesBox__SubscribeToDocument = gql`
    mutation CrmCompanySupplierCataloguesBox__SubscribeTo($input: PimSubscribeToSupplierCatalogueInput!) {
  pimSubscribeToSupplierCatalogue(input: $input) {
    supplierCatalogue {
      id
      ...CrmCompanySupplierCataloguesBox__SupplierCatalogue
    }
  }
}
    ${CrmCompanySupplierCataloguesBox__SupplierCatalogueFragmentDoc}`;
export type CrmCompanySupplierCataloguesBox__SubscribeToMutationFn = Apollo.MutationFunction<CrmCompanySupplierCataloguesBox__SubscribeToMutation, CrmCompanySupplierCataloguesBox__SubscribeToMutationVariables>;

/**
 * __useCrmCompanySupplierCataloguesBox__SubscribeToMutation__
 *
 * To run a mutation, you first call `useCrmCompanySupplierCataloguesBox__SubscribeToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanySupplierCataloguesBox__SubscribeToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmCompanySupplierCataloguesBoxSubscribeToMutation, { data, loading, error }] = useCrmCompanySupplierCataloguesBox__SubscribeToMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCrmCompanySupplierCataloguesBox__SubscribeToMutation(baseOptions?: Apollo.MutationHookOptions<CrmCompanySupplierCataloguesBox__SubscribeToMutation, CrmCompanySupplierCataloguesBox__SubscribeToMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrmCompanySupplierCataloguesBox__SubscribeToMutation, CrmCompanySupplierCataloguesBox__SubscribeToMutationVariables>(CrmCompanySupplierCataloguesBox__SubscribeToDocument, options);
      }
export type CrmCompanySupplierCataloguesBox__SubscribeToMutationHookResult = ReturnType<typeof useCrmCompanySupplierCataloguesBox__SubscribeToMutation>;
export type CrmCompanySupplierCataloguesBox__SubscribeToMutationResult = Apollo.MutationResult<CrmCompanySupplierCataloguesBox__SubscribeToMutation>;
export type CrmCompanySupplierCataloguesBox__SubscribeToMutationOptions = Apollo.BaseMutationOptions<CrmCompanySupplierCataloguesBox__SubscribeToMutation, CrmCompanySupplierCataloguesBox__SubscribeToMutationVariables>;
export const CrmCompanySupplierCataloguesBox__UnsubscribeFromDocument = gql`
    mutation CrmCompanySupplierCataloguesBox__UnsubscribeFrom($input: PimUnsubscribeFromSupplierCatalogueInput!) {
  pimUnsubscribeFromSupplierCatalogue(input: $input) {
    supplierCatalogue {
      id
      ...CrmCompanySupplierCataloguesBox__SupplierCatalogue
    }
  }
}
    ${CrmCompanySupplierCataloguesBox__SupplierCatalogueFragmentDoc}`;
export type CrmCompanySupplierCataloguesBox__UnsubscribeFromMutationFn = Apollo.MutationFunction<CrmCompanySupplierCataloguesBox__UnsubscribeFromMutation, CrmCompanySupplierCataloguesBox__UnsubscribeFromMutationVariables>;

/**
 * __useCrmCompanySupplierCataloguesBox__UnsubscribeFromMutation__
 *
 * To run a mutation, you first call `useCrmCompanySupplierCataloguesBox__UnsubscribeFromMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanySupplierCataloguesBox__UnsubscribeFromMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmCompanySupplierCataloguesBoxUnsubscribeFromMutation, { data, loading, error }] = useCrmCompanySupplierCataloguesBox__UnsubscribeFromMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCrmCompanySupplierCataloguesBox__UnsubscribeFromMutation(baseOptions?: Apollo.MutationHookOptions<CrmCompanySupplierCataloguesBox__UnsubscribeFromMutation, CrmCompanySupplierCataloguesBox__UnsubscribeFromMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrmCompanySupplierCataloguesBox__UnsubscribeFromMutation, CrmCompanySupplierCataloguesBox__UnsubscribeFromMutationVariables>(CrmCompanySupplierCataloguesBox__UnsubscribeFromDocument, options);
      }
export type CrmCompanySupplierCataloguesBox__UnsubscribeFromMutationHookResult = ReturnType<typeof useCrmCompanySupplierCataloguesBox__UnsubscribeFromMutation>;
export type CrmCompanySupplierCataloguesBox__UnsubscribeFromMutationResult = Apollo.MutationResult<CrmCompanySupplierCataloguesBox__UnsubscribeFromMutation>;
export type CrmCompanySupplierCataloguesBox__UnsubscribeFromMutationOptions = Apollo.BaseMutationOptions<CrmCompanySupplierCataloguesBox__UnsubscribeFromMutation, CrmCompanySupplierCataloguesBox__UnsubscribeFromMutationVariables>;