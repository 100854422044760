import { gql } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import * as Yup from "yup";
import { ContactsIcon } from "../../crm-persons/ContactsIcon.js";
import {
  SupplierCatalogueSelect,
  SupplierCatalogueValue,
} from "../../suppliers/SupplierCatalogueSelect.js";
import {
  FormValues as ProductPricingEditFormValues,
  ProductPricingEdit,
} from "../ProductPricingEdit.js";

export interface FormValues {
  supplierCatalogue: SupplierCatalogueValue | null;
  articleNumber: string;
  pricing?: ProductPricingEditFormValues | null;
}

export function useValidationSchema() {
  const { t } = useTranslate(["Product", "Global"]);
  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      articleNumber: Yup.string()
        .trim()
        .required()
        .label(t("Article number", { ns: "Product" })),
      supplierCatalogue: Yup.object()
        .shape({
          id: Yup.string(),
          supplier: Yup.object().shape({
            id: Yup.string(),
          }),
        })
        .required()
        .label(t("Supplier catalogue", { ns: "Product" })),
      pricing: Yup.object().shape({
        listPrice: Yup.number().moreThan(0).nullable().required(),
        netPrice: Yup.number().nullable(),
      }),
    });
  }, [t]);

  return validationSchema;
}

type Props = {
  articleNumberDisabled?: boolean;
  supplierDisabled?: boolean;
};

export function ProductEditSupplierBox({
  articleNumberDisabled,
  supplierDisabled,
}: Props) {
  const { t } = useTranslate(["ProductOverview", "Product", "Global"]);

  const formikProps = useFormikContext<FormValues>();

  return (
    <CardContainer
      Icon={<ContactsIcon />}
      title={t("Supplier", {
        ns: "ProductOverview",
      })}
      isExpandable
      isInitiallyClosed={false}
    >
      <Stack p={1} direction="column" spacing={1}>
        <SupplierCatalogueSelect
          required
          disabled={Boolean(supplierDisabled)}
          supplierCatalogueId={formikProps.values.supplierCatalogue?.id ?? null}
          onChange={value => {
            formikProps.setFieldValue("supplierCatalogue", value);
          }}
          preselectSingle
        />
        <Field
          component={TextField}
          label={t("Article number", {
            ns: "Product",
          })}
          name="articleNumber"
          required
          disabled={Boolean(articleNumberDisabled)}
        />
        <ProductPricingEdit fieldName={"pricing"} />
      </Stack>
    </CardContainer>
  );
}
