/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSelectEntryFragment = { __typename: 'SapS4HanaSalesQuotationsEdge', salesQuotationId: string, createdByUser?: string | null };

export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.SapS4HanaSalesQuotationsFilterInput>;
}>;


export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery = { sapS4HanaSalesQuotations: { __typename: 'MissingPermissions' } | { __typename: 'SapS4HanaSalesQuotationsResult', totalCount: number, edges: Array<{ __typename: 'SapS4HanaSalesQuotationsEdge', salesQuotationId: string, createdByUser?: string | null }> } };

export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryVariables = Types.Exact<{
  salesQuotationId: Types.Scalars['ID']['input'];
}>;


export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery = { sapS4HanaSalesQuotation: { __typename: 'SapS4HanaSalesQuotationResult', salesQuotation: { __typename: 'SapS4HanaSalesQuotation', id: string, salesQuotationId: string, items: Array<{ __typename: 'SapS4HanaSalesQuotationItem', id: string, position: string, title: string, salesQuotationItemId: string }> } } };

export type S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutationVariables = Types.Exact<{
  input: Types.CreateQuoteFromSapS4HanaSalesQuoteInput;
}>;


export type S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation = { createQuoteFromSapS4HanaSalesQuote: { __typename: 'CreateQuoteFromSapS4HanaSalesQuoteResult', project: { __typename: 'Project', id: string }, quote: { __typename: 'Quote', id: string } } };

export const S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSelectEntryFragmentDoc = gql`
    fragment S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSelectEntry on SapS4HanaSalesQuotationsEdge {
  salesQuotationId
  createdByUser
}
    `;
export const S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsDocument = gql`
    query S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotations($limit: Int!, $filter: SapS4HanaSalesQuotationsFilterInput) {
  sapS4HanaSalesQuotations(limit: $limit, filter: $filter) {
    ... on SapS4HanaSalesQuotationsResult {
      edges {
        ...S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSelectEntry
      }
      totalCount
    }
  }
}
    ${S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSelectEntryFragmentDoc}`;

/**
 * __useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery__
 *
 * To run a query within a React component, call `useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery(baseOptions: Apollo.QueryHookOptions<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryVariables> & ({ variables: S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryVariables>(S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsDocument, options);
      }
export function useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryVariables>(S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsDocument, options);
        }
export function useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryVariables>(S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsDocument, options);
        }
export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryHookResult = ReturnType<typeof useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery>;
export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsLazyQueryHookResult = ReturnType<typeof useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsLazyQuery>;
export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsSuspenseQueryHookResult = ReturnType<typeof useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsSuspenseQuery>;
export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryResult = Apollo.QueryResult<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsQueryVariables>;
export const S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationDocument = gql`
    query S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotation($salesQuotationId: ID!) {
  sapS4HanaSalesQuotation(salesQuotationId: $salesQuotationId) {
    ... on SapS4HanaSalesQuotationResult {
      salesQuotation {
        id
        salesQuotationId
        items {
          id
          position
          title
          salesQuotationItemId
        }
      }
    }
  }
}
    `;

/**
 * __useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery__
 *
 * To run a query within a React component, call `useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery({
 *   variables: {
 *      salesQuotationId: // value for 'salesQuotationId'
 *   },
 * });
 */
export function useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery(baseOptions: Apollo.QueryHookOptions<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryVariables> & ({ variables: S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryVariables>(S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationDocument, options);
      }
export function useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryVariables>(S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationDocument, options);
        }
export function useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryVariables>(S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationDocument, options);
        }
export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryHookResult = ReturnType<typeof useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery>;
export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationLazyQueryHookResult = ReturnType<typeof useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationLazyQuery>;
export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSuspenseQueryHookResult = ReturnType<typeof useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSuspenseQuery>;
export type S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryResult = Apollo.QueryResult<S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery, S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQueryVariables>;
export const S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteDocument = gql`
    mutation S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuote($input: CreateQuoteFromSapS4HanaSalesQuoteInput!) {
  createQuoteFromSapS4HanaSalesQuote(input: $input) {
    project {
      id
    }
    quote {
      id
    }
  }
}
    `;
export type S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutationFn = Apollo.MutationFunction<S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation, S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutationVariables>;

/**
 * __useS4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation__
 *
 * To run a mutation, you first call `useS4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useS4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [s4HanaSalesQuotationImportModalCreateQuoteFromSapS4HanaSalesQuoteMutation, { data, loading, error }] = useS4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useS4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation(baseOptions?: Apollo.MutationHookOptions<S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation, S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation, S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutationVariables>(S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteDocument, options);
      }
export type S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutationHookResult = ReturnType<typeof useS4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation>;
export type S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutationResult = Apollo.MutationResult<S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation>;
export type S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutationOptions = Apollo.BaseMutationOptions<S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation, S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutationVariables>;