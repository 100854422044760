import { gql } from "@apollo/client";
import { ellipsisStyle } from "@msys/ui";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { uniq } from "lodash-es";
import React from "react";
import { ActionLine, ContactLine } from "../../../commons/DataItem.js";
import { CrmTagChips } from "../../crm/CrmTagChips.js";
import { OrganisationCategoryAvatar } from "../../organisations/OrganisationCategoryAvatar.js";
import { CrmCompanyListItemFragment } from "./CrmCompanyListItem.generated.js";

interface Props {
  crmCompany: CrmCompanyListItemFragment;
  Action?: React.ReactElement;
}

export const CrmCompanyListItem = ({ crmCompany, Action }: Props) => {
  const { t } = useTranslate("DataItem");
  const contact = crmCompany.contactPerson?.fullname;
  const tags = uniq([...crmCompany.systemTags, ...crmCompany.customTags]).sort(
    (t1, t2) => t1.localeCompare(t2)
  );
  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>{crmCompany.title}</span>
          {crmCompany.abcCategory && crmCompany.abcCategory !== "none" && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
                height: "20px",
                marginTop: "-10px",
              }}
            >
              <OrganisationCategoryAvatar
                abcCategory={crmCompany.abcCategory}
              />
            </span>
          )}
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>
      {contact || tags.length > 0 ? (
        <Box
          gap={0.5}
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          {contact && (
            <Tooltip title={t("Contact")} arrow placement="bottom">
              <div style={{ ...ellipsisStyle, alignSelf: "flex-start" }}>
                <ContactLine>{contact}</ContactLine>
              </div>
            </Tooltip>
          )}
          {tags.length > 0 && <CrmTagChips tags={tags} wrap={true} />}
        </Box>
      ) : null}
    </Stack>
  );
};
