import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
// import { ProductOverviewAlternativeSupplierBox } from "./boxes/ProductOverviewAlternativeSupplierBox.js";
import { Modal } from "@msys/ui";
import { Grid, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { SupplierCatalogueValue } from "../../suppliers/SupplierCatalogueSelect.js";
import {
  FormValues as ProductEditHeaderBoxFormValues,
  ProductEditHeaderBox,
  useValidationSchema as useProductEditHeaderBoxFormValuesValidationSchema,
} from "../boxes/ProductEditHeaderBox.js";
import {
  FormValues as ProductEditInstallationBoxFormValues,
  ProductEditInstallationBox,
  useValidationSchema as useProductEditInstallationBoxValidationSchema,
} from "../boxes/ProductEditInstallationBox.js";
import {
  FormValues as ProductEditSupplierBoxFormValues,
  ProductEditSupplierBox,
  useValidationSchema as useProductEditSupplierBoxValidationSchema,
} from "../boxes/ProductEditSupplierBox.js";
import { ProductOverview__PimSupplierProductFragment } from "../Product.generated.js";
import {
  useSupplierCatalogueProductCreateModal__GetSupplierProductQuery,
  useSupplierCatalogueProductCreateModal__PimCreateSupplierProductMutation,
} from "./SupplierCatalogueProductCreateModal.generated.js";
import {
  ProductOverviewSupplierProductPropertyFormBox,
  FormValues as SupplierProductPropertyBoxFormValues,
  useValidationSchema as useSupplierProductPropertyBoxValidationSchema,
} from "../boxes/ProductOverviewSupplierProductPropertyBox.js";
import { pimProductPropertyToInput } from "../helper.js";
import {
  ProductOverviewFilesBoxForm,
  FormValues as FilesBoxFormValues,
  useValidationSchema as useFilesBoxValidationSchema,
} from "../boxes/ProductOverviewFilesBox.js";
import {
  ProductOverviewThreeDBoxForm,
  FormValues as ThreeDBoxFormValues,
  useValidationSchema as useThreeDBoxValidationSchema,
} from "../boxes/ProductOverviewThreeDBox.js";

type FormValues = ProductEditHeaderBoxFormValues &
  ProductEditInstallationBoxFormValues &
  ProductEditSupplierBoxFormValues &
  SupplierProductPropertyBoxFormValues &
  FilesBoxFormValues &
  ThreeDBoxFormValues;

export interface SupplierCatalogueProductCreateModalProps {
  fullHeight?: boolean;
  handleComplete: (
    product: ProductOverview__PimSupplierProductFragment,
    handleClose: () => void
  ) => Promise<void> | void;
  handleClose: () => void;
  initialSupplierCatalogue?: SupplierCatalogueValue | null;
}

export function SupplierCatalogueProductCreateModal({
  fullHeight,
  handleComplete,
  handleClose,
  initialSupplierCatalogue,
}: SupplierCatalogueProductCreateModalProps) {
  const { t } = useTranslate([
    "Global",
    "ItemPropertyField",
    "QuoteItem",
    "Product",
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const getProductQuery =
    useSupplierCatalogueProductCreateModal__GetSupplierProductQuery({
      client,
      variables: { articleNumber: "", supplierId: "" },
      fetchPolicy: "cache-and-network",
      skip: true,
    });

  const [createSupplierCatalogueProductMutation] =
    useSupplierCatalogueProductCreateModal__PimCreateSupplierProductMutation({
      client,
    });

  const formId = React.useMemo(() => uniqueId(), []);

  const productEditHeaderBoxFormValuesValidationSchema =
    useProductEditHeaderBoxFormValuesValidationSchema();
  const productEditInstallationBoxValidationSchema =
    useProductEditInstallationBoxValidationSchema();
  const productEditSupplierBoxValidationSchema =
    useProductEditSupplierBoxValidationSchema();
  const supplierProductPropertyBoxValidationSchema =
    useSupplierProductPropertyBoxValidationSchema();
  const filesBoxValidationSchema = useFilesBoxValidationSchema();
  const threeDBoxValidationSchema = useThreeDBoxValidationSchema();

  const validationSchema = React.useMemo(
    () =>
      Yup.object()
        .shape({})
        .concat(productEditHeaderBoxFormValuesValidationSchema)
        .concat(productEditInstallationBoxValidationSchema)
        .concat(productEditSupplierBoxValidationSchema)
        .concat(supplierProductPropertyBoxValidationSchema)
        .concat(filesBoxValidationSchema)
        .concat(threeDBoxValidationSchema),
    [
      productEditHeaderBoxFormValuesValidationSchema,
      productEditInstallationBoxValidationSchema,
      productEditSupplierBoxValidationSchema,
      supplierProductPropertyBoxValidationSchema,
      filesBoxValidationSchema,
      threeDBoxValidationSchema,
    ]
  );

  const initialValues = React.useMemo(
    (): FormValues => ({
      articleNumber: "",
      supplierCatalogue: initialSupplierCatalogue ?? null,
      texts: {
        titleText: "",
        descriptionText: "",
        dimensionText: "",
        extendedDescriptionText: "",
        marketingText: "",
      },
      productType: null,
      branding: null,
      gtin: null,
      manufacturerArticleNumber: null,
      priceUnit: "",
      priceUnitAmount: 1,
      pricing: null,
      properties: [],
      attachments: [],
      threedModel: null,
    }),
    [initialSupplierCatalogue]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        if (!(values.articleNumber && values.supplierCatalogue)) {
          throw new Error("Article number and supplier catalogue not provided");
        }

        const getProductResult = await getProductQuery.refetch({
          articleNumber: values.articleNumber,
          supplierId: values.supplierCatalogue.supplier.id,
        });

        const maybeExistingProduct = getDataOrNull(
          getProductResult.data?.pimGetSupplierProduct
        )?.supplierProduct;

        if (maybeExistingProduct && !maybeExistingProduct.deletedAt) {
          enqueueSnackbar(
            t("This product does already exist", { ns: "Product" }),
            { variant: "warning" }
          );
          return;
        }

        const createSupplierProductResult =
          await createSupplierCatalogueProductMutation({
            variables: {
              input: {
                articleNumber: values.articleNumber,
                supplierId: values.supplierCatalogue!.supplier.id,
                supplierCatalogueId: values.supplierCatalogue!.id,
                texts: {
                  ...values.texts,
                  titleText: values.texts?.titleText ?? "",
                },
                pricing: {
                  listPrice: values.pricing?.listPrice ?? 0,
                  netPrice: values.pricing?.netPrice ?? null,
                  currency: "EUR",
                  priceUnit: values.priceUnit ?? "",
                  priceUnitAmount: values.priceUnitAmount,
                },
                branding: values.branding
                  ? {
                      brandId: values.branding.brand.id,
                      brandLineId: values.branding?.brandLine?.id,
                    }
                  : null,
                gtin: values.gtin ? values.gtin : null,
                productTypeId: values.productType?.id ?? null,
                manufacturerArticleNumber:
                  values.manufacturerArticleNumber || null,

                attachments: values.attachments.map(attachment => ({
                  url: attachment.url,
                  mimeType: attachment.mimeType,
                  title: attachment.title,
                })),
                properties: values.properties.map(pimProductPropertyToInput),
                threedModelId: values.threedModel
                  ? values.threedModel.id
                  : null,
              },
            },
          });

        await handleComplete(
          createSupplierProductResult.data!.pimCreateSupplierProduct
            .supplierProduct,
          handleClose
        );
      }}
    >
      {formikProps => {
        return (
          <Modal
            title={t("Create product for supplier catalogue", {
              ns: "Product",
            })}
            handleClose={handleClose}
            dialogProps={{
              maxWidth: "md",
              PaperProps: fullHeight
                ? { style: { height: "100%" } }
                : undefined,
            }}
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Create", {
                  ns: "Global",
                }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  disabled: !formikProps.isValid || !formikProps.dirty,
                  loading: formikProps.isSubmitting,
                },
              },
            ]}
            alwaysVisible
          >
            <Form id={formId}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={1}>
                    <ProductEditHeaderBox />
                    <ProductOverviewSupplierProductPropertyFormBox
                      itemCount={0}
                      productTypeId={formikProps.values.productType?.id ?? null}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={1}>
                    <ProductEditInstallationBox />
                    <ProductEditSupplierBox />
                    <ProductOverviewFilesBoxForm />
                    <ProductOverviewThreeDBoxForm />
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
