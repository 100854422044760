/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type M1InputsFragment = { __typename: 'Requirement', m1?: { __typename: 'M1', m1Input?: Array<{ __typename: 'M1InputGroup', label?: string | null, fields?: Array<{ __typename: 'M1InputGroupField', label?: string | null, values?: Array<{ __typename: 'M1InputGroupFieldValue', label?: string | null } | null> | null } | null> | null } | null> | null } | null };

export const M1InputsFragmentDoc = gql`
    fragment M1Inputs on Requirement {
  m1 {
    m1Input {
      label
      fields {
        label
        values {
          label
        }
      }
    }
  }
}
    `;