import { RefObject } from "react";
import * as Yup from "yup";
import { AttachmentUploader } from "./AttachmentUploader.js";
import { AddAttachmentsFn } from "./useAttachments.js";

interface Props {
  accept?: string;
  multiple: boolean;
  innerRef: RefObject<HTMLInputElement>;
  onIsUploadingChanged?: (isLoading: boolean) => void;
  addAttachments: AddAttachmentsFn;
}

export const AttachmentField = ({
  accept = "*",
  multiple = false,
  innerRef,
  onIsUploadingChanged,
  addAttachments,
}: Props) => {
  if (multiple) {
    return (
      <AttachmentUploader
        innerRef={innerRef}
        accept={accept}
        multiple={true}
        onStart={() => onIsUploadingChanged?.(true)}
        onError={() => onIsUploadingChanged?.(false)}
        onCancel={() => onIsUploadingChanged?.(false)}
        onComplete={async attachments => {
          try {
            await addAttachments(attachments);
          } catch (error) {
            throw error;
          } finally {
            onIsUploadingChanged?.(false);
          }
        }}
      />
    );
  }

  return (
    <AttachmentUploader
      innerRef={innerRef}
      accept={accept}
      multiple={false}
      onStart={() => onIsUploadingChanged?.(true)}
      onError={() => onIsUploadingChanged?.(false)}
      onCancel={() => onIsUploadingChanged?.(false)}
      onComplete={async attachment => {
        try {
          await addAttachments([attachment]);
        } catch (error) {
          throw error;
        } finally {
          onIsUploadingChanged?.(false);
        }
      }}
    />
  );
};

export const getAttachmentValidationSchema = () =>
  Yup.object().shape({
    url: Yup.string().required(),
    title: Yup.string(),
    mimeType: Yup.string(),
  });
