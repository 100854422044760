/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { OrganisationPublicAboutBox_OrganisationProfileFragmentDoc } from '../features/organisations/boxes/OrganisationPublicAboutBox.generated.js';
import { OrganisationPublicContactBox_OrganisationProfileFragmentDoc } from '../features/organisations/boxes/OrganisationPublicContactBox.generated.js';
import { OrganisationPublicServicesBox_OrganisationProfileFragmentDoc } from '../features/organisations/boxes/OrganisationPublicServicesBox.generated.js';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../features/attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationPublicProfileQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;


export type OrganisationPublicProfileQuery = { organisationProfile: { __typename: 'OrganisationProfileResult', profile?: { __typename: 'OrganisationProfile', id: string, isMeister: boolean, hasShop: boolean, has3DShop: boolean, about?: any | null, yearFounded?: number | null, employeesNumber?: number | null, slug: string, contactEmail?: any | null, contactWebsite?: string | null, skillset: Array<Types.Skill>, organisation: { __typename: 'Organisation', id: string, title: string }, meister1Flows: Array<{ __typename: 'Meister1Flow', id: string, identName: string, label: string }>, background?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null, logo?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null, contactAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, contactPhones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }>, openingHours?: Array<{ __typename: 'OpeningHour', from?: string | null, to?: string | null, isOpen: boolean }> | null } | null } };

export type Meister1PollForWebhookProjectContracteeInvitationQueryVariables = Types.Exact<{
  desiredInvitationId: Types.Scalars['String']['input'];
}>;


export type Meister1PollForWebhookProjectContracteeInvitationQuery = { meister1PollForWebhookProjectContracteeInvitation?: { __typename: 'WebhookProjectContracteeInvitation', contracteeInvitationUrl: string } | null };

export type LogoImage_OrganisationProfileFragment = { __typename: 'OrganisationProfile', id: string, logo?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null, organisation: { __typename: 'Organisation', id: string } };

export type BackgroundImage_OrganisationProfileFragment = { __typename: 'OrganisationProfile', id: string, background?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null, organisation: { __typename: 'Organisation', id: string } };

export const LogoImage_OrganisationProfileFragmentDoc = gql`
    fragment LogoImage_OrganisationProfile on OrganisationProfile {
  id
  logo {
    ...AttachmentSnapshot
  }
  organisation {
    id
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const BackgroundImage_OrganisationProfileFragmentDoc = gql`
    fragment BackgroundImage_OrganisationProfile on OrganisationProfile {
  id
  background {
    ...AttachmentSnapshot
  }
  organisation {
    id
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const OrganisationPublicProfileDocument = gql`
    query OrganisationPublicProfile($slug: String!) {
  organisationProfile(slug: $slug) {
    profile {
      id
      isMeister
      hasShop
      has3DShop
      organisation {
        id
        title
      }
      ...BackgroundImage_OrganisationProfile
      ...LogoImage_OrganisationProfile
      meister1Flows {
        id
        identName
        label
      }
      ...OrganisationPublicAboutBox_OrganisationProfile
      ...OrganisationPublicContactBox_OrganisationProfile
      ...OrganisationPublicServicesBox_OrganisationProfile
    }
  }
}
    ${BackgroundImage_OrganisationProfileFragmentDoc}
${LogoImage_OrganisationProfileFragmentDoc}
${OrganisationPublicAboutBox_OrganisationProfileFragmentDoc}
${OrganisationPublicContactBox_OrganisationProfileFragmentDoc}
${OrganisationPublicServicesBox_OrganisationProfileFragmentDoc}`;

/**
 * __useOrganisationPublicProfileQuery__
 *
 * To run a query within a React component, call `useOrganisationPublicProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationPublicProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationPublicProfileQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOrganisationPublicProfileQuery(baseOptions: Apollo.QueryHookOptions<OrganisationPublicProfileQuery, OrganisationPublicProfileQueryVariables> & ({ variables: OrganisationPublicProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationPublicProfileQuery, OrganisationPublicProfileQueryVariables>(OrganisationPublicProfileDocument, options);
      }
export function useOrganisationPublicProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationPublicProfileQuery, OrganisationPublicProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationPublicProfileQuery, OrganisationPublicProfileQueryVariables>(OrganisationPublicProfileDocument, options);
        }
export function useOrganisationPublicProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrganisationPublicProfileQuery, OrganisationPublicProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationPublicProfileQuery, OrganisationPublicProfileQueryVariables>(OrganisationPublicProfileDocument, options);
        }
export type OrganisationPublicProfileQueryHookResult = ReturnType<typeof useOrganisationPublicProfileQuery>;
export type OrganisationPublicProfileLazyQueryHookResult = ReturnType<typeof useOrganisationPublicProfileLazyQuery>;
export type OrganisationPublicProfileSuspenseQueryHookResult = ReturnType<typeof useOrganisationPublicProfileSuspenseQuery>;
export type OrganisationPublicProfileQueryResult = Apollo.QueryResult<OrganisationPublicProfileQuery, OrganisationPublicProfileQueryVariables>;
export const Meister1PollForWebhookProjectContracteeInvitationDocument = gql`
    query Meister1PollForWebhookProjectContracteeInvitation($desiredInvitationId: String!) {
  meister1PollForWebhookProjectContracteeInvitation(
    desiredInvitationId: $desiredInvitationId
  ) {
    contracteeInvitationUrl
  }
}
    `;

/**
 * __useMeister1PollForWebhookProjectContracteeInvitationQuery__
 *
 * To run a query within a React component, call `useMeister1PollForWebhookProjectContracteeInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeister1PollForWebhookProjectContracteeInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeister1PollForWebhookProjectContracteeInvitationQuery({
 *   variables: {
 *      desiredInvitationId: // value for 'desiredInvitationId'
 *   },
 * });
 */
export function useMeister1PollForWebhookProjectContracteeInvitationQuery(baseOptions: Apollo.QueryHookOptions<Meister1PollForWebhookProjectContracteeInvitationQuery, Meister1PollForWebhookProjectContracteeInvitationQueryVariables> & ({ variables: Meister1PollForWebhookProjectContracteeInvitationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Meister1PollForWebhookProjectContracteeInvitationQuery, Meister1PollForWebhookProjectContracteeInvitationQueryVariables>(Meister1PollForWebhookProjectContracteeInvitationDocument, options);
      }
export function useMeister1PollForWebhookProjectContracteeInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Meister1PollForWebhookProjectContracteeInvitationQuery, Meister1PollForWebhookProjectContracteeInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Meister1PollForWebhookProjectContracteeInvitationQuery, Meister1PollForWebhookProjectContracteeInvitationQueryVariables>(Meister1PollForWebhookProjectContracteeInvitationDocument, options);
        }
export function useMeister1PollForWebhookProjectContracteeInvitationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Meister1PollForWebhookProjectContracteeInvitationQuery, Meister1PollForWebhookProjectContracteeInvitationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Meister1PollForWebhookProjectContracteeInvitationQuery, Meister1PollForWebhookProjectContracteeInvitationQueryVariables>(Meister1PollForWebhookProjectContracteeInvitationDocument, options);
        }
export type Meister1PollForWebhookProjectContracteeInvitationQueryHookResult = ReturnType<typeof useMeister1PollForWebhookProjectContracteeInvitationQuery>;
export type Meister1PollForWebhookProjectContracteeInvitationLazyQueryHookResult = ReturnType<typeof useMeister1PollForWebhookProjectContracteeInvitationLazyQuery>;
export type Meister1PollForWebhookProjectContracteeInvitationSuspenseQueryHookResult = ReturnType<typeof useMeister1PollForWebhookProjectContracteeInvitationSuspenseQuery>;
export type Meister1PollForWebhookProjectContracteeInvitationQueryResult = Apollo.QueryResult<Meister1PollForWebhookProjectContracteeInvitationQuery, Meister1PollForWebhookProjectContracteeInvitationQueryVariables>;