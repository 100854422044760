/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type UserAvatar_AvailableUser_Fragment = { __typename: 'AvailableUser', id: string, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string } };

export type UserAvatar_CrmPerson_Fragment = { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string } };

export type UserAvatar_Person_Fragment = { __typename: 'Person', id: string, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string } };

export type UserAvatar_User_Fragment = { __typename: 'User', id: string, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string } };

export type UserAvatar_Viewer_Fragment = { __typename: 'Viewer', id: string, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string } };

export type UserAvatarFragment = UserAvatar_AvailableUser_Fragment | UserAvatar_CrmPerson_Fragment | UserAvatar_Person_Fragment | UserAvatar_User_Fragment | UserAvatar_Viewer_Fragment;

export const UserAvatarFragmentDoc = gql`
    fragment UserAvatar on WithAvatar {
  id
  firstname
  familyname
  avatar {
    id
    url
  }
}
    `;