import { defaults, pick } from "lodash-es";
import React from "react";
import {
  CreateItemsValues,
  DecisionBehaviorOfSubitems,
  ItemProductInput,
  ItemType,
  ModifyItemProductValuesInput,
} from "../../../../clients/graphqlTypes.js";
import { assertNever } from "../../../utils.js";
import { ProductSearchItem__ProductSearchResultFragment } from "../../products/Product.generated.js";
import { TemplatesQuoteSelectMultipleComponent_TemplateFragment } from "../../templates/quote/TemplatesQuoteSelectMultipleModal.generated.js";

export type CreateItemArgs =
  | {
      type: "paid";
      title: string;
      docId: string;
      parentItemId: string;
      newItemRank: number | null;
      product?: ItemProductInput;
    }
  | {
      type: "section";
      title: string;
      docId: string;
      parentItemId: string;
      newItemRank: number | null;
      templateQuoteId?: string;
    }
  | {
      type: "decision";
      title: string;
      docId: string;
      parentItemId: string;
      newItemRank: number | null;
      decisionBehaviorOfSubitems?: DecisionBehaviorOfSubitems;
    }
  | {
      type: Exclude<ItemType, "paid" | "section" | "decision">;
      title: string;
      docId: string;
      parentItemId: string;
      newItemRank: number | null;
    };

export type CreateItemByTypeArgs = {
  itemType: ItemType;
  docId: string;
  parentItemId: string;
  newItemRank: number | null;
  title?: string;
};

export type CreateItemWithLinkedTemplateArgs = {
  title: string;
  parentItemId: string;
  newItemRank: number | null;
};

export type CreateItemFromTemplateArgs = {
  title: string;
  parentItemId: string;
  templateQuoteId: string;
  newItemRank: number | null;
};

export type CreateItemsFromTemplatesArgs = {
  parentItemId: string;
  templates: {
    quantity: number;
    template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
  }[];
  newItemRank: number | null;
};

export type CreateItemsWithProductsArgs = {
  parentItemId: string;
  products: {
    quantity: number;
    product:
      | ProductSearchItem__ProductSearchResultFragment
      | ModifyItemProductValuesInput;
  }[];
  newItemRank: number | null;
};

export type CreateItemsVariables = {
  input: {
    projectId: string | null;
    docId: string;
    parentItemId: string;
    items: CreateItemsValues[];
    expandedItemIds?: string[];
    rank: number | null;
  };
};

export type CreateItemsReturn = {
  data?: { createItems: { items: { id: string }[] } } | null | undefined;
};

interface Props {
  projectId: string | null;
  createDocumentItems: (args: {
    variables: CreateItemsVariables;
  }) => Promise<CreateItemsReturn>;
  expandedItemIds?: string[];
}

export function useCreateItem({
  projectId,
  createDocumentItems,
  expandedItemIds,
}: Props) {
  const createItem = React.useCallback(
    async ({
      type,
      docId,
      parentItemId,
      newItemRank,
      ...opts
    }: CreateItemArgs) => {
      const item: CreateItemsValues = defaults(
        {
          type,
          ...opts,
          ...("product" in opts
            ? { product: pick(opts.product, "supplierId", "articleNumber") }
            : {}),
        },
        {
          type,
          title: "",
          templateQuoteId: undefined,
          decisionBehaviorOfSubitems:
            type === "decision" ? "SELECT_ONE" : "NONE",
          product: undefined,
        } as const
      );

      const result = await createDocumentItems({
        variables: {
          input: {
            projectId: projectId,
            docId: docId,
            parentItemId,
            items: [item],
            expandedItemIds,
            rank: newItemRank,
          },
        },
      });
      const newItem = result.data!.createItems.items[0];
      return newItem;
    },
    [createDocumentItems, expandedItemIds, projectId]
  );

  const createItemSection = React.useCallback(
    async (
      title: string,
      docId: string,
      parentItemId: string,
      newItemRank: number | null,
      templateQuoteId?: string
    ) => {
      return await createItem({
        type: "section",
        title,
        docId,
        parentItemId,
        newItemRank,
        templateQuoteId,
      });
    },
    [createItem]
  );
  const createItemDecision = React.useCallback(
    async (
      title: string,
      docId: string,
      parentItemId: string,
      newItemRank: number | null
    ) => {
      return await createItem({
        type: "decision",
        title,
        docId,
        parentItemId,
        newItemRank,
        decisionBehaviorOfSubitems: "SELECT_ONE",
      });
    },
    [createItem]
  );
  const createItemPaid = React.useCallback(
    async (
      title: string,
      docId: string,
      parentItemId: string,
      newItemRank: number | null
    ) => {
      return await createItem({
        type: "paid",
        docId,
        parentItemId,
        newItemRank,
        title,
      });
    },
    [createItem]
  );
  const createItemUnpaid = React.useCallback(
    async (
      title: string,
      docId: string,
      parentItemId: string,
      newItemRank: number | null
    ) => {
      return await createItem({
        type: "unpaid",
        docId,
        parentItemId,
        newItemRank,
        title,
      });
    },
    [createItem]
  );
  const createItemDefect = React.useCallback(
    async (
      title: string,
      docId: string,
      parentItemId: string,
      newItemRank: number | null
    ) => {
      return await createItem({
        type: "defect",
        docId,
        parentItemId,
        newItemRank,
        title,
      });
    },
    [createItem]
  );

  const createItemByType = React.useCallback(
    async ({
      itemType,
      docId,
      parentItemId,
      title,
      newItemRank,
    }: CreateItemByTypeArgs) => {
      switch (itemType) {
        case "section":
          return await createItemSection(
            title ?? "New section",
            docId,
            parentItemId,
            newItemRank
          );
        case "decision":
          return await createItemDecision(
            title ?? "New decision",
            docId,
            parentItemId,
            newItemRank
          );
        case "paid":
          return await createItemPaid(
            title ?? "New paid item",
            docId,
            parentItemId,
            newItemRank
          );
        case "unpaid":
          return await createItemUnpaid(
            title ?? "New unpaid item",
            docId,
            parentItemId,
            newItemRank
          );
        case "defect":
          return await createItemDefect(
            title ?? "New defect",
            docId,
            parentItemId,
            newItemRank
          );
        default:
          throw assertNever(itemType);
      }
    },
    [
      createItemDecision,
      createItemDefect,
      createItemPaid,
      createItemSection,
      createItemUnpaid,
    ]
  );

  return {
    createItem,
    createItemSection,
    createItemByType,
  };
}
