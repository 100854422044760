import { StatusLabel } from "@msys/ui";
import { useTheme } from "@mui/material";
import {
  BillOfMaterialsItemStatus,
  BillOfMaterialsItemType,
} from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";
import { assertNever } from "../../utils.js";

export function BillOfMaterialsItemStatusLabel({
  status,
  small,
}: {
  status: BillOfMaterialsItemStatus;
  small?: boolean;
}) {
  const { statusLabels } = useStatusLabels();
  const theme = useTheme();
  const color =
    status === "OPEN"
      ? theme.palette.grey[500]
      : status === "PARTIAL"
        ? theme.palette.secondary.main
        : status === "ORDERED"
          ? theme.palette.primary.main
          : assertNever(status);

  return (
    <StatusLabel color={color} small={small}>
      {statusLabels[status]}
    </StatusLabel>
  );
}

export function useStatusLabels() {
  const { t } = useTranslate("Materials");

  const statusLabels: Record<BillOfMaterialsItemStatus, string> = {
    OPEN: t("Open"),
    PARTIAL: t("Partial"),
    ORDERED: t("Ordered"),
  };

  return { statusLabels };
}

export function useTypeLabels() {
  const { t } = useTranslate("Materials");

  const typeLabels: Record<BillOfMaterialsItemType, string> = {
    custom_product: t("Custom product"),
    product: t("Product"),
    quote_item: t("Quote item"),
  };

  return { typeLabels };
}
