import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  ErrorMessage,
  ListHeader,
  LoadingSpinner,
} from "@msys/ui";
import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { EntityNumber } from "../../commons/EntityNumber.js";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PurchaseOrderListItem } from "../../features/purchase-orders/PurchaseOrderListItem.js";
import { PurchaseOrderStatusBadge } from "../../features/purchase-orders/PurchaseOrderStatusBadge.js";
import { PurchaseOrderTitle } from "../../features/purchase-orders/PurchaseOrderTitle.js";
import { PurchaseOrderCreateButton } from "../../features/purchase-orders/buttons/PurchaseOrderCreateButton.js";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore.js";
import {
  PurchaseOrders__OrderFragment,
  usePurchaseOrdersQuery,
} from "./ProjectPurchaseOrders.generated.js";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
  tabs: React.ReactElement | null;
}

export const ProjectPurchaseOrders = ({
  projectId,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
  tabs,
}: Props) => {
  const navigate = useNavigate();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "project-orders",
      "table",
      "list"
    );

  const { t } = useTranslate(["Materials", "PurchaseOrders", "Global"]);

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Purchase Orders", {
          ns: "PurchaseOrders",
        }),
        to: `/projects/${projectId}/materials/purchase-orders`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  const client = useApolloClient();
  const query = usePurchaseOrdersQuery({
    client,
    variables: {
      projectId,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const orders = project?.orders ?? [];

  const stateStore = useDataGridStateStore("ProjectPurchaseOrders");

  return (
    <Page
      subtitle={project?.title}
      title={t("Purchase Orders", {
        ns: "PurchaseOrders",
      })}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
    >
      {project && (
        <PageContainer>
          <ListHeader
            QuickFilter={tabs ?? undefined}
            SwitchViewButton={
              <SwitchCollectionViewButton
                allowedViews={ALLOWED_VIEWS}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            }
            CreateButton={
              <RestrictedByProjectPermissionWithDebug
                permission="MANAGE_QUOTES"
                project={project}
              >
                <PurchaseOrderCreateButton
                  projectId={projectId}
                  handleOrderCreate={orderId => {
                    navigate(
                      `/projects/${projectId}/materials/purchase-orders/${orderId}/edit`
                    );
                  }}
                />
              </RestrictedByProjectPermissionWithDebug>
            }
            mb={2}
          />
          {(() => {
            switch (activeView) {
              case "table":
                return (
                  <DataGrid
                    stateStore={stateStore}
                    loading={query.loading}
                    hideFooter={true}
                    columns={
                      [
                        {
                          field: "number",
                          headerName: t("No.", {
                            ns: "Global",
                          }),
                          flex: 1,
                          minWidth: 100,
                          sortable: false,
                          renderCell: ({ row: order }) => (
                            <EntityNumber noLabel number={order.number} />
                          ),
                        },
                        {
                          field: "status",
                          headerName: t("Status", {
                            ns: "PurchaseOrders",
                          }),
                          flex: 1,
                          minWidth: 100,
                          sortable: false,
                          renderCell: ({ row: order }) => (
                            <PurchaseOrderStatusBadge order={order} />
                          ),
                        },
                        {
                          field: "title",
                          headerName: t("Purchase order title", {
                            ns: "PurchaseOrders",
                          }),
                          flex: 3,
                          minWidth: 100,
                          sortable: false,
                          renderCell: ({ row: order }) => (
                            <PurchaseOrderTitle order={order} />
                          ),
                        },
                        {
                          field: "supplier",
                          headerName: t("Supplier", {
                            ns: "PurchaseOrders",
                          }),
                          flex: 2,
                          minWidth: 100,
                          sortable: false,
                          renderCell: ({ row: order }) =>
                            order.supplier?.title ?? "–",
                        },
                        {
                          field: "createdAt",
                          headerName: t("Order date", {
                            ns: "PurchaseOrders",
                          }),
                          flex: 1,
                          minWidth: 100,
                          sortable: false,
                          renderCell: ({ row: order }) =>
                            moment(order.createdAt).format("L"),
                        },
                      ] as GridColDef<PurchaseOrders__OrderFragment>[]
                    }
                    rows={orders}
                    onRowClick={({ row: order }) =>
                      navigate(
                        order.supplier
                          ? `/projects/${projectId}/materials/purchase-orders/${order.id}`
                          : `/projects/${projectId}/materials/purchase-orders/${order.id}/edit`
                      )
                    }
                    rowCount={orders.length}
                  />
                );
              case "list":
                if (query.loading) return <LoadingSpinner />;
                if (orders.length === 0)
                  return (
                    <ErrorMessage
                      message={t("There are no items to display", {
                        ns: "Global",
                      })}
                    />
                  );
                return (
                  <Stack width="100%" direction="column" spacing={1}>
                    {orders.map(order => (
                      <CardItem
                        key={order.id}
                        //@ts-ignore
                        component={Link}
                        to={
                          order.supplier
                            ? `/projects/${projectId}/materials/purchase-orders/${order.id}`
                            : `/projects/${projectId}/materials/purchase-orders/${order.id}/edit`
                        }
                      >
                        <PurchaseOrderListItem order={order} />
                      </CardItem>
                    ))}
                  </Stack>
                );
            }
          })()}
        </PageContainer>
      )}
    </Page>
  );
};
