import { gql, useApolloClient } from "@apollo/client";
import { Capacitor } from "@capacitor/core";
import { CardContainer, ErrorMessage, LoadingSpinner } from "@msys/ui";
import { CalendarMonth as CalendarMonthIcon } from "@mui/icons-material";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import {
  FilledInput,
  FormControlLabel,
  Icon,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { ReactComponent as CalendarCommonIcon } from "../../../assets/icons/calendar-common.svg";
import { ReactComponent as CalendarGoogleIcon } from "../../../assets/icons/calendar-google.svg";
import { ReactComponent as CalendarOffice365Icon } from "../../../assets/icons/calendar-office365.svg";
import { ReactComponent as CalendarOutlookIcon } from "../../../assets/icons/calendar-outlook.svg";
import { ICAL_GENERATOR_ENDPOINT } from "../../../../environment.js";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import {
  useDisableUserCalendarMutation,
  useEnableUserCalendarMutation,
  useUserCalendarTokenQuery,
} from "./UserCalendarBox.generated.js";
import { useTranslate } from "@tolgee/react";
import { copyTextToClipboard } from "../../addresses/helpers.js";
import { useAsync } from "react-use";

interface Props {
  userId: string;
}

export const UserCalendarBox = ({ userId }: Props) => {
  const { t } = useTranslate(["UserProfile", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [enableUserCalendar, { loading: enableLoading }] =
    useEnableUserCalendarMutation({
      client,
      refetchQueries: [namedOperations.Query.UserCalendarToken],
      awaitRefetchQueries: true,
    });

  const [disableUserCalendar, { loading: disabledLoading }] =
    useDisableUserCalendarMutation({
      client,
      refetchQueries: [namedOperations.Query.UserCalendarToken],
      awaitRefetchQueries: true,
    });

  const userCalendarTokenQuery = useUserCalendarTokenQuery({
    client,
    variables: { userId },
  });

  const userCalendarToken = userCalendarTokenQuery.data?.userCalendarToken;

  const calendarUrl = useAsync(async () => {
    if (!userCalendarToken) return null;
    return getUserCalendarUrl(ICAL_GENERATOR_ENDPOINT, userCalendarToken);
  }, [userCalendarToken]);

  if (userCalendarTokenQuery.loading || calendarUrl.loading)
    return <LoadingSpinner />;
  if (userCalendarTokenQuery.error || calendarUrl.error)
    return (
      <ErrorMessage
        message={
          userCalendarTokenQuery.error?.message ||
          calendarUrl.error?.message ||
          "Error occurred"
        }
      />
    );

  return (
    <CardContainer
      Icon={<CalendarMonthIcon />}
      isExpandable
      title={t("Export to calendar", {
        ns: "UserProfile",
      })}
    >
      <Stack direction="column" p={1} spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3">
            {t("Work sessions", {
              ns: "UserProfile",
            })}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(userCalendarToken)}
                disabled={enableLoading || disabledLoading}
                onChange={async e => {
                  if (e.currentTarget.checked) {
                    await enableUserCalendar({ variables: { userId } });
                  } else {
                    await disableUserCalendar({ variables: { userId } });
                  }
                }}
              />
            }
            label={
              userCalendarToken
                ? t("Enabled", {
                    ns: "Global",
                  })
                : t("Disabled", {
                    ns: "Global",
                  })
            }
            sx={
              !userCalendarToken
                ? {
                    "& .MuiFormControlLabel-label": {
                      color: "grey.500",
                    },
                  }
                : undefined
            }
          />
        </Stack>
        {userCalendarToken && calendarUrl.value ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              size="small"
              component="a"
              rel="noopener noreferrer"
              target="_blank"
              href={getGoogleCalendarUrl(calendarUrl.value)}
            >
              <Tooltip
                title={t("Export to Google Calendar", {
                  ns: "UserProfile",
                })}
              >
                <Icon>
                  <CalendarGoogleIcon />
                </Icon>
              </Tooltip>
            </IconButton>
            <IconButton
              size="small"
              component="a"
              rel="noopener noreferrer"
              target="_blank"
              href={getOutlookCalendarUrl(calendarUrl.value)}
            >
              <Tooltip
                title={t("Export to Outlook", {
                  ns: "UserProfile",
                })}
              >
                <Icon>
                  <CalendarOutlookIcon />
                </Icon>
              </Tooltip>
            </IconButton>
            <IconButton
              size="small"
              component="a"
              rel="noopener noreferrer"
              target="_blank"
              href={getOffice365CalendarUrl(calendarUrl.value)}
            >
              <Tooltip
                title={t("Export to Office 365", {
                  ns: "UserProfile",
                })}
              >
                <Icon>
                  <CalendarOffice365Icon />
                </Icon>
              </Tooltip>
            </IconButton>
            <IconButton
              size="small"
              component="a"
              rel="noopener noreferrer"
              target="_blank"
              href={getAppleCalendarUrl(calendarUrl.value)}
            >
              <Tooltip
                title={t("Export to Apple Calendar", {
                  ns: "UserProfile",
                })}
              >
                <Icon>
                  <CalendarCommonIcon />
                </Icon>
              </Tooltip>
            </IconButton>
            <Stack direction="row" spacing={1} alignItems="center" flex={1}>
              <FilledInput
                value={calendarUrl.value}
                readOnly
                fullWidth
                size="extra-small"
              />
              <IconButton
                size="small"
                color="secondary"
                onClick={async () => {
                  if (calendarUrl.value)
                    await copyTextToClipboard(calendarUrl.value);
                  enqueueSnackbar(
                    t("Copied to clipboard", {
                      ns: "Global",
                    })
                  );
                }}
              >
                <Tooltip
                  title={t("Copy subscribe URL", {
                    ns: "UserProfile",
                  })}
                >
                  <ContentCopyIcon />
                </Tooltip>
              </IconButton>
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </CardContainer>
  );
};

function getUserCalendarUrl(endpoint: string, token: string) {
  return `${endpoint}/v1/getUserPlanSessions?token=${encodeURIComponent(
    token
  )}`;
}

function getGoogleCalendarUrl(url: string) {
  return `https://www.google.com/calendar/render?cid=${encodeURIComponent(
    url.replace(/^https?:/, "webcal:")
  )}`;
}
function getOutlookCalendarUrl(url: string) {
  return `https://outlook.live.com/owa/?rru=addsubscription&name=MeisterSystems&url=${encodeURIComponent(
    url
  )}`;
}
function getOffice365CalendarUrl(url: string) {
  return `https://outlook.office.com/owa/?rru=addsubscription&name=MeisterSystems&url=${encodeURIComponent(
    url
  )}`;
}
function getAppleCalendarUrl(url: string) {
  return url.replace(/^https?:/, "webcal:");
}
