import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { PostAddOutlined as PostAddOutlinedIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Content } from "../../../../clients/graphqlTypes.js";
import { ContentEditModal } from "../../contents/ContentEditModal.js";

export const AddContentMenuItem = ({
  addArticle,
}: {
  addArticle: (newArticle: Content) => Promise<void>;
}) => {
  const { t } = useTranslate("QuoteItem");

  return (
    <ModalOpenButton
      Modal={ContentEditModal}
      modalProps={{
        handleComplete: async newArticle => {
          await addArticle(newArticle);
        },
      }}
    >
      <MenuItemWithIcon icon={<PostAddOutlinedIcon />}>
        {t("Add content")}
      </MenuItemWithIcon>
    </ModalOpenButton>
  );
};
