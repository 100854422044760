import { useApolloClient } from "@apollo/client";
import {
  CardContainer,
  LabeledDurationValue,
  getFormattedDuration,
} from "@msys/ui";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { groupBy, mapValues, sortBy, values } from "lodash-es";
import moment from "moment";
import { useCallback, useRef } from "react";
import { PermissionName } from "../../../../clients/graphqlTypes.js";
import { px } from "../../../../common/MuiThemeProvider.js";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../../auth/RestrictedByDocumentPermission.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import {
  AddTaskWorkSessionProcess,
  AddTaskWorkSessionProcessRef,
} from "../../work-sessions/AddTaskWorkSessionProcess.js";
import { AddWorkSessionButton } from "../../work-sessions/buttons/AddWorkSessionButton.js";
import { CorrectWorkSessionButton } from "../../work-sessions/buttons/CorrectWorkSessionButton.js";
import { LabeledQuantityValue } from "../LabeledQuantityValue.js";
import { useQuantityUnits } from "../useQuantityUnits.js";
import {
  TimeCalculationActualRow_ItemFragment,
  TimeCalculationBox_ItemFragment,
  TimeCalculationBox_ProjectFragment,
  TimeCalculationEstimatedRow_ItemFragment,
  TimeCalculationWorkSessions_ItemFragment,
  useQuoteModifyItem_TimeCalculationBoxMutation,
} from "./TimeCalculationBox.generated.js";

interface FormValues {
  actualQuantity: number;
}

interface Props {
  project: TimeCalculationBox_ProjectFragment;
  docId: string;
  document: { viewerPermissions: PermissionName[] };
  item: TimeCalculationBox_ItemFragment;
  canAddWorkSession?: boolean;
  refetchQueriesOnAction?: string[];
  isInitiallyClosed?: boolean;
}

export const TimeCalculationBox = ({
  project,
  docId,
  document,
  item,
  canAddWorkSession = false,
  refetchQueriesOnAction,
  isInitiallyClosed,
}: Props) => {
  const { t } = useTranslate("Task");

  return (
    <CardContainer
      isExpandable={true}
      title={t("Time Calculation")}
      isInitiallyClosed={isInitiallyClosed}
    >
      <Stack p={1} flexDirection="column">
        {item.type === "paid" && (
          <>
            <TimeCalculationEstimatedRow item={item} />

            <Divider />

            <TimeCalculationActualRow
              project={project}
              docId={docId}
              document={document}
              item={item}
              refetchQueriesOnAction={refetchQueriesOnAction}
              canEditWorkSessions={item.canBeWorkedOn}
            />
          </>
        )}

        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={document}
        >
          <TimeCalculationWorkSessions
            project={project}
            docId={docId}
            document={document}
            item={item}
            canAddWorkSession={canAddWorkSession && item.canBeWorkedOn}
            canEditWorkSessions={item.canBeWorkedOn}
          />
        </RestrictedByDocumentPermissionWithDebug>
      </Stack>
    </CardContainer>
  );
};

const TimeCalculationEstimatedRow = ({
  item,
}: {
  item: TimeCalculationEstimatedRow_ItemFragment;
}) => {
  const { t } = useTranslate("Task");

  return (
    <Stack flexDirection="column" spacing={1 / 2}>
      <Typography color="primary" variant="h3">
        {t("Estimated")}
      </Typography>
      <Grid container direction="row">
        <Grid
          item
          xs={4}
          style={{
            flexBasis: `calc(33.333333% - ${px.l})`,
            maxWidth: `calc(33.333333% - ${px.l})`,
          }}
        >
          <LabeledQuantityValue
            value={item.estimatedQuantity}
            unit={item.quantityUnit}
            label={t("Quantity")}
            color="primary"
          />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          style={{ width: px.l }}
        >
          x
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={4}
          style={{
            flexBasis: `calc(33.333333% - ${px.l})`,
            maxWidth: `calc(33.333333% - ${px.l})`,
          }}
        >
          <LabeledDurationValue
            label={t("Time / Unit")}
            value={item.estimatedTime}
          />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          style={{ width: px.l }}
        >
          =
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          xs={4}
        >
          <LabeledDurationValue
            label={t("Total time")}
            value={item.estimatedQuantity * item.estimatedTime}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

const TimeCalculationActualRow = ({
  project,
  docId,
  document,
  item,
  refetchQueriesOnAction,
  canEditWorkSessions,
}: {
  project: TimeCalculationBox_ProjectFragment;
  docId: string;
  document: { viewerPermissions: PermissionName[] };
  item: TimeCalculationActualRow_ItemFragment;
  refetchQueriesOnAction?: string[];
  canEditWorkSessions: boolean;
}) => {
  const { t } = useTranslate("Task");

  const client = useApolloClient();
  const [modifyItemActualCalculation] =
    useQuoteModifyItem_TimeCalculationBoxMutation({
      client,
    });

  const { quantityUnitLabels } = useQuantityUnits();

  const workSessions = item.workSessions;
  const correctionDate = workSessions.find(s => !s.assignee)?.date;
  const initialValues: FormValues = {
    actualQuantity: item.actualQuantity || item.estimatedQuantity,
  };

  const onSubmit = async (values: FormValues) => {
    await modifyItemActualCalculation({
      variables: {
        input: {
          itemId: item.id,
          docId,
          projectId: project.id,
          values: {
            actualQuantity: values.actualQuantity,
          },
        },
      },
      refetchQueries: refetchQueriesOnAction,
    });
  };

  return (
    <Stack flexDirection="column" spacing={1 / 2}>
      <Typography color="primary" variant="h3">
        {t("Actual")}
      </Typography>
      <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }: FormikProps<FormValues>) => (
          <Form>
            <Grid container direction="row">
              <Grid
                item
                xs={4}
                style={{
                  flexBasis: `calc(33.333333% - ${px.l})`,
                  maxWidth: `calc(33.333333% - ${px.l})`,
                }}
              >
                {canEditWorkSessions ? (
                  <FormattedFloatField
                    disabled={false}
                    label={t("Quantity")}
                    name="actualQuantity"
                    type="float"
                    min={0}
                    unit={"\u00A0" + quantityUnitLabels[item.quantityUnit]}
                  />
                ) : (
                  <LabeledQuantityValue
                    value={item.actualQuantity || item.estimatedQuantity}
                    unit={item.quantityUnit}
                    label={t("Quantity")}
                    color="primary"
                  />
                )}
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                style={{
                  width: px.l,
                  paddingBottom: px.xs,
                }}
              >
                x
              </Grid>
              <Grid
                item
                container
                xs={4}
                style={{
                  flexBasis: `calc(33.333333% - ${px.l})`,
                  maxWidth: `calc(33.333333% - ${px.l})`,
                }}
                alignItems="center"
              >
                <LabeledDurationValue
                  label={t("Time / Unit")}
                  value={
                    initialValues.actualQuantity > 0 &&
                    item.workSessionsDuration > 0
                      ? Math.floor(
                          item.workSessionsDuration /
                            initialValues.actualQuantity
                        )
                      : 0
                  }
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                style={{
                  width: px.l,
                  paddingBottom: px.xs,
                }}
              >
                =
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                xs={4}
              >
                <Stack spacing={1 / 2} alignItems="center">
                  <LabeledDurationValue
                    label={t("Total time")}
                    value={item.workSessionsDuration}
                  />
                  {canEditWorkSessions && (
                    <RestrictedByDocumentPermissionWithDebug
                      permission="MANAGE_TASK"
                      document={document}
                    >
                      <CorrectWorkSessionButton
                        projectId={project.id}
                        itemId={item.id}
                        docId={docId}
                        correctionDate={correctionDate}
                        timeTrackingRequired={item.timeTrackingRequired}
                      />
                    </RestrictedByDocumentPermissionWithDebug>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <AutoSave enableReinitialize initialValues={initialValues} />
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

const TimeCalculationWorkSessions = ({
  project,
  docId,
  document,
  item,
  canAddWorkSession,
  canEditWorkSessions,
}: {
  project: TimeCalculationBox_ProjectFragment;
  docId: string;
  document: { viewerPermissions: PermissionName[] };
  item: TimeCalculationWorkSessions_ItemFragment;
  canAddWorkSession: boolean;
  canEditWorkSessions: boolean;
}) => {
  const { t } = useTranslate("Task");

  const addTaskWorkSessionRef = useRef<AddTaskWorkSessionProcessRef>(null);

  const addWorkSession = useCallback(
    (itemId: string, projectId: string, docId: string) => {
      addTaskWorkSessionRef.current?.addWorkSession(itemId, projectId, docId);
    },
    []
  );

  const workSessions = item.workSessions;
  const correctionDate = workSessions.find(s => !s.assignee)?.date;

  const sessionsByAssignee = sortBy(
    values(
      mapValues(
        groupBy(workSessions, s => s.assignee?.id ?? s.author.id),
        sessions =>
          sortBy(sessions, [
            s =>
              s.assignee
                ? moment(s.date || s.from)
                    .startOf("day")
                    .valueOf()
                : Infinity,
            s =>
              s.date
                ? moment(s.date).endOf("day").valueOf() +
                  (s.isCorrection ? 1 : 0)
                : moment(s.from).valueOf(),
          ])
      )
    ),
    [
      sessionsGroup =>
        sessionsGroup[0].assignee?.familyname ??
        sessionsGroup[0].author.familyname,
    ]
  );

  return (
    <>
      {sessionsByAssignee.length > 0 && (
        <Stack flexDirection="column" spacing={1 / 2}>
          {item.type === "paid" && <Divider />}
          <Stack
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            spacing={1 / 2}
          >
            <Typography variant="h3">{t("Work Sessions")}</Typography>
            {canAddWorkSession && (
              <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
                <RestrictedByDocumentPermissionWithDebug
                  permission="EXECUTE_TASK"
                  document={document}
                >
                  <AddWorkSessionButton
                    style={{ marginTop: 0, marginBottom: -4 }}
                    itemId={item.id}
                    docId={docId}
                    projectId={project.id}
                    type="icon"
                    addWorkSession={addWorkSession}
                  />
                </RestrictedByDocumentPermissionWithDebug>
              </RestrictedByCapabilityWithDebug>
            )}
          </Stack>
          <Box>
            <Grid container>
              <Grid container item xs={3}>
                <Typography color="textSecondary" variant="caption">
                  {t("Assignee")}
                </Typography>
              </Grid>
              <Grid container item xs={3}>
                <Typography color="textSecondary" variant="caption">
                  {t("Date")}
                </Typography>
              </Grid>
              <Grid container item xs={4}>
                <Typography color="textSecondary" variant="caption">
                  {t("Time Frame")}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={2}
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  component="span"
                  color="textSecondary"
                  variant="caption"
                  align="right"
                >
                  {t("Time")}
                </Typography>
              </Grid>
            </Grid>

            {sessionsByAssignee.map(sessionsGroup => {
              const assignee =
                sessionsGroup[0].assignee || sessionsGroup[0].author;
              return (
                <Grid key={assignee.id} container>
                  <Grid container item xs={3}>
                    <Typography variant="body2">
                      {assignee.firstname[0]}. {assignee.familyname}
                    </Typography>
                  </Grid>
                  <Grid xs={3} container item direction="column">
                    {sessionsGroup.map(s => (
                      <Typography
                        key={s.id}
                        component="span"
                        variant="body2"
                        color={!s.assignee ? "secondary" : "primary"}
                      >
                        {s.date || s.from
                          ? moment(s.date || s.from).format("DD.MM")
                          : "–"}
                      </Typography>
                    ))}
                  </Grid>
                  <Grid xs={4} container item direction="column">
                    {sessionsGroup.map(s => (
                      <Typography
                        key={s.id}
                        component="span"
                        variant="body2"
                        color={!s.assignee ? "secondary" : "primary"}
                      >
                        {s.from && s.till
                          ? /* prettier-ignore */
                            `${moment(s.from).format("HH:mm")} – ${moment(s.till).format("HH:mm")}`
                          : s.isCorrection
                            ? t("correction")
                            : "–"}
                      </Typography>
                    ))}
                  </Grid>
                  <Grid
                    xs={2}
                    container
                    item
                    direction="column"
                    alignItems="flex-end"
                  >
                    {sessionsGroup.map(s => (
                      <Typography
                        key={s.id}
                        component="span"
                        variant="body2"
                        align="right"
                        color={!s.assignee ? "secondary" : "primary"}
                      >
                        {s.duration
                          ? getFormattedDuration(s.duration)
                          : getFormattedDuration(
                              moment(s.till).diff(s.from, "minutes")
                            )}{" "}
                        h
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
          <Divider style={{ marginTop: 2, marginBottom: 4 }} />
          <Grid container>
            <Grid item xs={7}>
              <Typography color="primary" variant="h3" component="span">
                {t("Total time")}
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-end"
              xs={5}
            >
              <Stack spacing={1} alignItems="center">
                <Typography
                  color="primary"
                  variant="h3"
                  component="span"
                  align="right"
                >
                  {getFormattedDuration(item.workSessionsDuration)} h
                </Typography>
                {canEditWorkSessions && (
                  <RestrictedByDocumentPermissionWithDebug
                    permission="MANAGE_TASK"
                    document={document}
                  >
                    <CorrectWorkSessionButton
                      projectId={project.id}
                      itemId={item.id}
                      docId={docId}
                      correctionDate={correctionDate}
                      timeTrackingRequired={item.timeTrackingRequired}
                      style={{ marginTop: -6 }}
                    />
                  </RestrictedByDocumentPermissionWithDebug>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}

      {canAddWorkSession && !sessionsByAssignee.length && (
        <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
          <RestrictedByDocumentPermissionWithDebug
            permission="EXECUTE_TASK"
            document={document}
          >
            {item.type === "paid" ? <Divider /> : undefined}
            <Box display="flex" justifyContent="center">
              <AddWorkSessionButton
                projectId={project.id}
                docId={docId}
                itemId={item.id}
                type="button"
                addWorkSession={addWorkSession}
              />
            </Box>
          </RestrictedByDocumentPermissionWithDebug>
        </RestrictedByCapabilityWithDebug>
      )}

      <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={document}
        >
          <AddTaskWorkSessionProcess ref={addTaskWorkSessionRef} />
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    </>
  );
};
