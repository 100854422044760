import { useApolloClient } from "@apollo/client";
import { CardContainer, CollapseSection, ModalOpenButton } from "@msys/ui";
import { AssignmentInd as ContactsIcon } from "@mui/icons-material";
import { Email as EmailIcon } from "@mui/icons-material";
import { Phone as PhoneIcon } from "@mui/icons-material";
import { Undo as UndoIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { Link } from "react-router-dom";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { ProfileListItem } from "../../../commons/ProfileListItem.js";
import {
  ALLOW_INVITE_EXISTING_CONTRACTEE,
  ALLOW_INVITE_NEW_CONTRACTEE,
} from "../../../constants.js";
import { useCancelProjectContracteeInvitationMutation } from "../../crm-persons/boxes/CrmPersonPendingContracteeInvitationsBox.generated.js";
import { CrmSendEmailModal } from "../../crm/CrmSendEmailModal.js";
import { InvitationStatusBadge } from "../../invitations/InvitationStatusBadge.js";
import { OrganisationAvatar } from "../../organisations/OrganisationAvatar.js";
import { UserAvatar } from "../../users/UserAvatar.js";
import { useProjectRoles } from "../../users/useRoles.js";
import { AddProjectMemberButton } from "../buttons/AddProjectMemberButton.js";
import { AddProjectMemberCRMButton } from "../buttons/AddProjectMemberCRMButton.js";
import { ModifyProjectMembersButton } from "../buttons/ModifyProjectMembersButton.js";
import { ProjectAssigneeSelectFormWithAutoSave } from "../ProjectAssigneeSelect.js";
import {
  ProjectOverviewParticipantsBox_ProjectFragment,
  RowContractee_CrmPersonFragment,
  RowContractee_ProjectContracteeInvitationFragment,
  RowContractee_ProjectFragment,
  RowMember_ProjectMembershipFragment,
} from "./ProjectOverviewParticipantsBox.generated.js";

interface Props {
  project: ProjectOverviewParticipantsBox_ProjectFragment;
}

export const ProjectOverviewParticipantsBox = ({ project }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate("ProjectOverview");
  const viewer = useUserData().currentUser!;

  const client = useApolloClient();
  const [cancelProjectContracteeInvitation] =
    useCancelProjectContracteeInvitationMutation({ client });

  const viewerOrganisation = viewer.organisation;
  const crmOrganisation = project.crmOrganisation;
  const showContractee =
    viewerOrganisation.capabilities.includes("QUOTING") && crmOrganisation;
  const canInviteContractee = project.canInviteContractee;

  const context = React.useMemo(
    () => ({ type: "PROJECT", id: project.id }) as const,
    [project.id]
  );

  return (
    <CardContainer
      id="project-overview-participants-box"
      Icon={<ContactsIcon />}
      title={t("Project participants")}
      isExpandable
    >
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_PROJECT"
        project={project}
        otherwise={
          <ProjectAssigneeSelectFormWithAutoSave
            projectId={project.id}
            readOnly={true}
          />
        }
      >
        <ProjectAssigneeSelectFormWithAutoSave projectId={project.id} />
      </RestrictedByProjectPermissionWithDebug>
      <Box paddingX={1}>
        <CollapseSection
          title={t("My team")}
          itemCount={project.internalStakeholders.length}
          ActionButtons={[
            <RestrictedByProjectPermissionWithDebug
              key="manage-participants"
              permission="MANAGE_PROJECT"
              project={project}
            >
              <ModifyProjectMembersButton
                projectId={project.id}
                type="icon"
                refetchQueries={[namedOperations.Query.ProjectOverview]}
              />
            </RestrictedByProjectPermissionWithDebug>,
            <RestrictedByProjectPermissionWithDebug
              key="add-member"
              permission="ADD_PROJECT_MEMBER"
              project={project}
            >
              <AddProjectMemberButton
                projectId={project.id}
                refetchQueries={[namedOperations.Query.ProjectOverview]}
              />
            </RestrictedByProjectPermissionWithDebug>,
          ]}
        >
          <List disablePadding sx={{ marginX: -1 }}>
            {project.internalStakeholders.map(m => (
              <RowMember key={m.id} membership={m} />
            ))}
          </List>
        </CollapseSection>
        {showContractee && (
          <>
            <Divider />
            <CollapseSection title={t("Contractee")}>
              <List disablePadding sx={{ marginX: -1 }}>
                <ProfileListItem
                  size="s"
                  button
                  component={Link}
                  to={`/crm/organisations/${crmOrganisation.id}`}
                  avatar={
                    <OrganisationAvatar
                      size="s"
                      organisationAvatar={crmOrganisation}
                    />
                  }
                  primary={crmOrganisation.title}
                  ActionButton={
                    <Stack direction="row" spacing={1}>
                      {crmOrganisation.email && (
                        <ModalOpenButton
                          Modal={CrmSendEmailModal}
                          modalProps={{
                            emailTemplateContext: "PROJECT",
                            context,
                            recipientOptions: [
                              {
                                key: crmOrganisation.id,
                                crmCompanyId: crmOrganisation.id,
                                crmPersonId: null,
                                label: `${crmOrganisation.title} <${crmOrganisation.email}>`,
                                value: crmOrganisation.id,
                              },
                            ],
                            recipient: {
                              key: crmOrganisation.id,
                              crmCompanyId: crmOrganisation.id,
                              crmPersonId: null,
                              label: `${crmOrganisation.title} <${crmOrganisation.email}>`,
                              value: crmOrganisation.id,
                            },
                            projectId: project.id,
                          }}
                        >
                          <IconButton color="primary">
                            <EmailIcon />
                          </IconButton>
                        </ModalOpenButton>
                      )}
                      {ALLOW_INVITE_NEW_CONTRACTEE && canInviteContractee ? (
                        <RestrictedByProjectPermissionWithDebug
                          permission="MANAGE_PROJECT"
                          project={project}
                        >
                          <AddProjectMemberCRMButton
                            projectId={project.id}
                            refetchQueries={[
                              namedOperations.Query.ProjectOverview,
                            ]}
                          />
                        </RestrictedByProjectPermissionWithDebug>
                      ) : undefined}
                    </Stack>
                  }
                />
                {crmOrganisation.members.map(member => {
                  const invitation = project.contracteeInvitations.find(
                    invitation => invitation.contact.id === member.id
                  );
                  return (
                    <RowContractee
                      key={member.id}
                      person={member}
                      invitation={invitation}
                      project={project}
                      organisationId={crmOrganisation.id}
                      onCancel={
                        invitation
                          ? async () => {
                              await cancelProjectContracteeInvitation({
                                variables: {
                                  projectId: project.id,
                                  projectContracteeInvitationId: invitation.id,
                                },
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                  namedOperations.Query.ProjectOverview,
                                ],
                              });
                              enqueueSnackbar(t("Invitation cancelled"), {
                                variant: "info",
                              });
                            }
                          : undefined
                      }
                      canInvite={
                        ALLOW_INVITE_EXISTING_CONTRACTEE && canInviteContractee
                      }
                    />
                  );
                })}
              </List>
            </CollapseSection>
          </>
        )}
        {project.invitingContractor &&
          !project.contractors.some(
            c => c.id === project.invitingContractor?.id
          ) && (
            <>
              <Divider />
              <CollapseSection title={t("Inviting contractor")}>
                <List disablePadding sx={{ marginX: -1 }}>
                  <ProfileListItem
                    size="s"
                    button
                    component={Link}
                    to={`/crm/organisations/${project.invitingContractor.id}`}
                    avatar={
                      <OrganisationAvatar
                        size="s"
                        organisationAvatar={project.invitingContractor}
                      />
                    }
                    primary={project.invitingContractor.title}
                  />
                </List>
              </CollapseSection>
            </>
          )}
        <Divider />
        <CollapseSection
          title={
            viewerOrganisation.isCraftsmanOrganisation
              ? t("Subcontractors")
              : t("Contractors")
          }
          itemCount={project.contractors.length}
        >
          <List disablePadding sx={{ marginX: -1 }}>
            {project.contractors.map(contractor => (
              <ProfileListItem
                key={contractor.id}
                size="s"
                button
                component={Link}
                to={`/crm/organisations/${contractor.id}`}
                avatar={
                  <OrganisationAvatar
                    size="s"
                    organisationAvatar={contractor}
                  />
                }
                primary={contractor.title}
              />
            ))}
          </List>
        </CollapseSection>
      </Box>
    </CardContainer>
  );
};

const RowMember = ({
  membership,
}: {
  membership: RowMember_ProjectMembershipFragment;
}) => {
  const viewer = useUserData().currentUser!;
  const { getProjectRoleTitle } = useProjectRoles();

  let primaryLabel =
    membership.user.firstname + " " + membership.user.familyname;
  if (primaryLabel.trim().length === 0) primaryLabel = membership.user.email;

  if (membership.user.id === viewer.id) primaryLabel += " (me)";

  const roleLabel = getProjectRoleTitle(membership.roles);

  return (
    <ProfileListItem
      key={membership.id}
      button
      component={Link}
      to={`/organisation/users/${membership.user.id}`}
      avatar={<UserAvatar userAvatar={membership.user} />}
      primary={primaryLabel}
      secondary={roleLabel}
      ActionButton={
        membership.user.phones.length ? (
          <IconButton
            color="primary"
            size="small"
            component="a"
            href={`tel:${membership.user.phones[0].number}`}
          >
            <PhoneIcon />
          </IconButton>
        ) : undefined
      }
    />
  );
};

const RowContractee = ({
  organisationId,
  project,
  person,
  invitation,
  onCancel,
  canInvite,
}: {
  organisationId: string;
  project: RowContractee_ProjectFragment;
  person: RowContractee_CrmPersonFragment;
  invitation?: RowContractee_ProjectContracteeInvitationFragment;
  onCancel?: () => Promise<void>;
  canInvite: boolean;
}) => {
  const { t } = useTranslate("ProjectOverview");
  const name = `${person.firstname} ${person.familyname}`.trim();

  const context = React.useMemo(
    () => ({ type: "PROJECT", id: project.id }) as const,
    [project.id]
  );

  return (
    <ProfileListItem
      button
      component={Link}
      key={person.id}
      to={`/crm/organisations/${organisationId}/users/${person.id}`}
      avatar={<UserAvatar userAvatar={person} />}
      primary={name}
      badge={
        invitation ? (
          <InvitationStatusBadge
            style={{ opacity: 0.6 }}
            status={invitation.status}
          />
        ) : undefined
      }
      ActionButtons={[
        canInvite && invitation ? (
          <RestrictedByProjectPermissionWithDebug
            key={"cancel-invitation"}
            permission="MANAGE_PROJECT"
            project={project}
          >
            <Tooltip
              style={{ pointerEvents: "all" }}
              title={t("Cancel invitation")}
            >
              <IconButton color="primary" onClick={onCancel}>
                <UndoIcon />
              </IconButton>
            </Tooltip>
          </RestrictedByProjectPermissionWithDebug>
        ) : undefined,
        person.email && (
          <ModalOpenButton
            key={"send-email"}
            Modal={CrmSendEmailModal}
            modalProps={{
              emailTemplateContext: "PROJECT",
              context,
              recipientOptions: [
                {
                  key: person.id,
                  crmCompanyId: organisationId,
                  crmPersonId: person.id,
                  label: `${person.fullname} <${person.email}>`,
                  value: person.id,
                },
              ],
              recipient: {
                key: person.id,
                crmCompanyId: organisationId,
                crmPersonId: person.id,
                label: `${person.fullname} <${person.email}>`,
                value: person.id,
              },
              projectId: project.id,
            }}
          >
            <IconButton color="primary">
              <EmailIcon />
            </IconButton>
          </ModalOpenButton>
        ),
      ].filter(Boolean)}
      RightButton={
        canInvite && !invitation ? (
          <RestrictedByProjectPermissionWithDebug
            permission="MANAGE_PROJECT"
            project={project}
          >
            <AddProjectMemberCRMButton
              title={t("Invite")}
              projectId={project.id}
              userId={person.id}
              style={{ pointerEvents: "all" }}
              refetchQueries={[namedOperations.Query.ProjectOverview]}
              Button={
                <Button
                  color="primary"
                  variant="outlined"
                  size="extra-small"
                  style={{ pointerEvents: "all" }}
                >
                  {t("Invite")}
                </Button>
              }
            />
          </RestrictedByProjectPermissionWithDebug>
        ) : undefined
      }
    />
  );
};
