import { ModalOpenButton, RichTextEditor } from "@msys/ui";
import { Help as HelpIcon } from "@mui/icons-material";
import { Box, IconButton, Paper, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ContentState, convertToRaw } from "draft-js";
import { BillOfMaterialsTextsAvailableReplacementsModal } from "./BillOfMaterialsTextsAvailableReplacementsModal.js";

export const OpeningTextInput = ({
  content,
  onSave = async () => {},
  canEdit = true,
  showInfo,
}: {
  content?: string | undefined | null;
  onSave?: (variables: any) => Promise<void>;
  canEdit?: boolean;
  showInfo: boolean;
}) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  return (
    <Paper>
      <Box padding={2}>
        <RichTextEditor
          label={t("Opening text", {
            ns: "OrganisationSettings",
          })}
          content={
            content
              ? JSON.parse(content)
              : convertToRaw(ContentState.createFromText(""))
          }
          onSave={content => onSave({ opening: JSON.stringify(content) })}
          canEdit={canEdit}
          saveButtonLabel={t("Save", {
            ns: "Global",
          })}
          cancelButtonLabel={t("Cancel", {
            ns: "Global",
          })}
          InfoButton={
            showInfo ? (
              <ModalOpenButton
                Modal={BillOfMaterialsTextsAvailableReplacementsModal}
                modalProps={{}}
              >
                <IconButton size="small" color="secondary">
                  <Tooltip
                    title={t("Available replacements", {
                      ns: "OrganisationSettings",
                    })}
                  >
                    <HelpIcon />
                  </Tooltip>
                </IconButton>
              </ModalOpenButton>
            ) : undefined
          }
        />
      </Box>
    </Paper>
  );
};
