import { gql, useApolloClient } from "@apollo/client";
import {
  CardContainer,
  CollapseSection,
  ellipsisStyle,
  LabeledValue,
  useFormatting,
} from "@msys/ui";
import { Box, Grid, Link, Stack, StackProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { groupBy } from "lodash-es";
import React from "react";
import { prepareUrl } from "../../commons/form-fields/helpers.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { color } from "../../../common/MuiThemeProvider.js";
import {
  CustomFieldDataType,
  CustomFieldObjectType,
} from "../../../clients/graphqlTypes.js";
import { assertNever } from "../../utils.js";
import { getYoutubeVideoId } from "../contents/ArticleYoutubeVideoPreview.js";
import {
  CustomFieldConfigFragment,
  useCustomFieldConfigQuery,
} from "./customFieldConfigs.generated.js";
import { CustomFieldsFragment } from "./CustomFieldsBox.generated.js";

export const CustomFieldsBox = ({
  source,
  objectType,
  title,
  actionButton,
}: {
  source: CustomFieldsFragment;
  objectType: CustomFieldObjectType;
  title?: string;
  actionButton?: React.ReactElement;
}) => {
  const { t } = useTranslate("CustomFields");
  const debug = useFeature("DebugOutput");

  const client = useApolloClient();
  const query = useCustomFieldConfigQuery({
    client,
    variables: { filterObjectType: objectType },
  });
  const customFieldConfigs = query.data?.customFieldConfig ?? [];
  if (customFieldConfigs.length === 0) return null;

  return (
    <CardContainer
      isExpandable={customFieldConfigs.length > 0}
      title={title ?? t("Custom Fields")}
      ActionButton={actionButton}
    >
      <CustomFieldsSection
        source={source}
        customFieldConfigs={customFieldConfigs}
        debug={debug}
        p={1}
      />
    </CardContainer>
  );
};

export function useGetCustomFieldValueAsDisplayContent() {
  const { t } = useTranslate(["Global"]);
  const { getFormattedDate } = useFormatting();

  const getFieldDisplayValue = React.useCallback(
    (
      field:
        | { dataType: CustomFieldDataType; key: string; value: any }
        | undefined
    ) => {
      if (field === undefined || field.value === null)
        return t("Not set", { ns: "Global" });
      switch (field.dataType) {
        case "t_text": {
          return field.value;
        }
        case "t_number": {
          return field.value;
        }
        case "t_date": {
          return getFormattedDate(field.value);
        }
        case "t_boolean": {
          return String(field.value);
        }
        case "t_mm": {
          return `${field.value} mm`;
        }
        case "t_cm": {
          return `${field.value} cm`;
        }
        case "t_m": {
          return `${field.value} m`;
        }
        case "t_external_url": {
          return (
            <Link
              href={prepareUrl(field.value)}
              target="_blank"
              rel="noopener noreferrer"
              style={ellipsisStyle}
            >
              {field.value}
            </Link>
          );
        }
        case "t_youtube_video_id": {
          let videoId: string = getYoutubeVideoId(field.value) ?? field.value;
          return (
            <iframe
              width="100%"
              style={{ aspectRatio: "16 / 9" }}
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        }
        default:
          return assertNever(field.dataType);
      }
    },
    [getFormattedDate, t]
  );

  return getFieldDisplayValue;
}

function getColumnsForCustomField(dataType: CustomFieldDataType) {
  switch (dataType) {
    case "t_external_url":
    case "t_youtube_video_id":
      return 2;
    case "t_text":
    case "t_number":
    case "t_date":
    case "t_boolean":
    case "t_mm":
    case "t_cm":
    case "t_m":
      return 1;
    default:
      return assertNever(dataType);
  }
}

export const CustomFieldsSection = ({
  source,
  customFieldConfigs,
  debug,
  ...props
}: {
  source: CustomFieldsFragment;
  customFieldConfigs: CustomFieldConfigFragment[];
  debug?: boolean;
} & StackProps) => {
  const { t } = useTranslate(["CustomFields", "Global"]);
  const groupedCustomFields = groupBy(
    [...customFieldConfigs]
      .sort((a, b) => a.key.localeCompare(b.key))
      .map(fieldConfig => {
        const customField = source.customFields.find(
          field => field.key === fieldConfig.key
        );

        if (customField) {
          return {
            key: fieldConfig.key,
            dataType: fieldConfig.dataType,
            group: fieldConfig.group,
            value: JSON.parse(customField.value),
          };
        }

        return {
          key: fieldConfig.key,
          dataType: fieldConfig.dataType,
          group: fieldConfig.group,
          value: null,
        };
      }),
    "group"
  );
  const hasGroups = customFieldConfigs.some(fc => Boolean(fc.group));

  return (
    <Stack direction="column" spacing={1} {...props}>
      {Object.entries(groupedCustomFields).map(([group, fields], index) =>
        group ? (
          <CollapseSection title={group} minHeight="auto" key={group}>
            <CustomFieldsLabeledValues fields={fields} debug={debug} />
          </CollapseSection>
        ) : hasGroups ? (
          <CollapseSection
            title={t("Other", { ns: "Global" })}
            minHeight="auto"
            key="other"
          >
            <CustomFieldsLabeledValues fields={fields} debug={debug} />
          </CollapseSection>
        ) : (
          <Box key="all">
            <CustomFieldsLabeledValues fields={fields} debug={debug} />
          </Box>
        )
      )}
    </Stack>
  );
};

export const CustomFieldsLabeledValues = ({
  fields,
  debug,
}: {
  fields: {
    key: string;
    group: string;
    dataType: CustomFieldDataType;
    value: any;
  }[];
  debug?: boolean;
}) => {
  const { t } = useTranslate("CustomFields");
  const getCustomFieldValueAsDisplayContent =
    useGetCustomFieldValueAsDisplayContent();
  return (
    <Grid container columns={2} spacing={1}>
      {fields.map((field, index, array) => (
        <Grid
          key={field.key}
          item
          xs={debug ? 2 : getColumnsForCustomField(field.dataType)}
        >
          <LabeledValue
            label={field.key}
            style={{
              color: field.value === null ? color.grey : undefined,
            }}
          >
            {getCustomFieldValueAsDisplayContent(field)}
          </LabeledValue>
          {debug && (
            <Stack direction="column" paddingTop={1} spacing={1}>
              <Stack direction="row" spacing={1}>
                <Box flex="1">
                  <LabeledValue label={t("Field type")}>
                    {field.dataType}
                  </LabeledValue>
                </Box>
                <Box flex="1">
                  <LabeledValue label={t("Category")}>
                    {field.group}
                  </LabeledValue>
                </Box>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Box flex="1">
                  <LabeledValue label={t("Field label")}>
                    {field.key}
                  </LabeledValue>
                </Box>
                <Box flex="1">
                  <LabeledValue label={t("value")}>{field.value}</LabeledValue>
                </Box>
              </Stack>
            </Stack>
          )}
        </Grid>
      ))}
    </Grid>
  );
};
