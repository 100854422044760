import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LoadingSpinner, useScreenWidth } from "@msys/ui";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  ButtonBaseProps,
  Grid,
  Icon,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, To } from "react-router-dom";
import { ButtonCircleWrapper } from "../../commons/button/ButtonCircleWrapper.js";
import { useCardsNumber } from "../../commons/hooks/useCardsNumber.js";
import { ProfileListItem } from "../../commons/ProfileListItem.js";
import { color } from "../../../common/MuiThemeProvider.js";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import { OrganisationAvatar } from "../organisations/OrganisationAvatar.js";
import { OrganisationSupplierProductFavouriteButton } from "./buttons/OrganisationSupplierProductFavouriteButton.js";
import { ProductCardItem } from "./components/ProductItem.js";
import { ProductFiltersFormValues } from "./filters/ProductFilterFields.js";
import { ProductSearchItem__ProductSearchResultFragment } from "./Product.generated.js";
import {
  productCategoryDefaultIcon,
  productCategoryIcons,
} from "./ProductCategoryIcons.js";
import {
  useProductSearchLandingPage_CurrentSuppliersQuery,
  useProductSearchLandingPage_FavouriteProductsQuery,
  useProductSearchLandingPage_ProductCategoryRootsQuery,
  useProductSearchLandingPage_RecentlyViewedProductsQuery,
} from "./ProductSearchLandingPage.generated.js";

export function ProductSearchLandingPage({
  setProductSearchFilters,
  getProductSearchFiltersTo,
  onProductClick,
  getProductTo,
  renderProductSelectButton,
  ...stackProps
}: StackProps & {
  setProductSearchFilters?: React.Dispatch<
    React.SetStateAction<ProductFiltersFormValues>
  >;
  getProductSearchFiltersTo?: (
    filters: Partial<ProductFiltersFormValues>
  ) => To;
  onProductClick?: (
    product: ProductSearchItem__ProductSearchResultFragment
  ) => void | Promise<void>;
  getProductTo?: (
    product: ProductSearchItem__ProductSearchResultFragment
  ) => To;
  renderProductSelectButton?: (
    product: ProductSearchItem__ProductSearchResultFragment
  ) => React.ReactNode;
}) {
  return (
    <Stack spacing={4} pt={2} flex={1} {...stackProps}>
      <ProductCategories
        setProductSearchFilters={setProductSearchFilters}
        getProductSearchFiltersTo={getProductSearchFiltersTo}
      />
      <CurrentSuppliers
        setProductSearchFilters={setProductSearchFilters}
        getProductSearchFiltersTo={getProductSearchFiltersTo}
      />
      <RecentlyViewedProducts
        onProductClick={onProductClick}
        getProductTo={getProductTo}
        renderProductSelectButton={renderProductSelectButton}
      />
      <FavouriteProducts
        setProductSearchFilters={setProductSearchFilters}
        getProductSearchFiltersTo={getProductSearchFiltersTo}
        onProductClick={onProductClick}
        getProductTo={getProductTo}
        renderProductSelectButton={renderProductSelectButton}
      />
    </Stack>
  );
}

function RecentlyViewedProducts({
  onProductClick,
  getProductTo,
  renderProductSelectButton,
}: {
  onProductClick?: (
    product: ProductSearchItem__ProductSearchResultFragment
  ) => void | Promise<void>;
  getProductTo?: (
    product: ProductSearchItem__ProductSearchResultFragment
  ) => To;
  renderProductSelectButton?: (
    product: ProductSearchItem__ProductSearchResultFragment
  ) => React.ReactNode;
}) {
  const { t } = useTranslate(["ProductSearch", "Product"]);
  const client = useApolloClient();

  const { spacing, sizeMeasureRef, columns } = useCardsNumber();

  const query = useProductSearchLandingPage_RecentlyViewedProductsQuery({
    client,
    variables: { limit: 10 },
    fetchPolicy: "cache-and-network",
  });

  const products =
    getDataOrNull(query.data?.pimSupplierProductRecentViews)?.products ?? [];

  const ActionButton = React.useCallback(
    ({
      product,
    }: {
      product: ProductSearchItem__ProductSearchResultFragment;
    }) => (
      <>
        <ButtonCircleWrapper>
          <OrganisationSupplierProductFavouriteButton
            product={product}
            canEdit={true}
            refetchQueries={[
              namedOperations.Query.ProductSearchLandingPage_FavouriteProducts,
            ]}
          />
        </ButtonCircleWrapper>
        {renderProductSelectButton?.(product)}
      </>
    ),
    [renderProductSelectButton]
  );

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant={"h2"}>
        {t("Recently viewed products", { ns: "ProductSearch" })}
      </Typography>
      <Box ref={sizeMeasureRef}>
        {query.loading ? (
          <LoadingSpinner />
        ) : products.length > 0 ? (
          <Grid container spacing={spacing}>
            {products.map((product, index) => (
              <Grid key={product.id} item {...columns}>
                <ProductCardItem
                  key={product.id}
                  product={product}
                  onClick={onProductClick}
                  getProductTo={getProductTo}
                  ActionButton={ActionButton}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography
            // align="center"
            paddingY={1}
            sx={{ color: "grey.500" }}
            variant="body2"
          >
            {t("No products viewed yet", { ns: "ProductSearch" })}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

function FavouriteProducts({
  setProductSearchFilters,
  getProductSearchFiltersTo,
  onProductClick,
  getProductTo,
  renderProductSelectButton,
}: {
  setProductSearchFilters?: React.Dispatch<
    React.SetStateAction<ProductFiltersFormValues>
  >;
  getProductSearchFiltersTo?: (
    filters: Partial<ProductFiltersFormValues>
  ) => To;
  onProductClick?: (
    product: ProductSearchItem__ProductSearchResultFragment
  ) => void | Promise<void>;
  getProductTo?: (
    product: ProductSearchItem__ProductSearchResultFragment
  ) => To;
  renderProductSelectButton?: (
    product: ProductSearchItem__ProductSearchResultFragment
  ) => React.ReactNode;
}) {
  const { t } = useTranslate(["ProductSearch", "Product"]);
  const { spacing, sizeMeasureRef, columns } = useCardsNumber();

  const client = useApolloClient();
  const query = useProductSearchLandingPage_FavouriteProductsQuery({
    client,
    variables: { limit: 10 },
    fetchPolicy: "cache-and-network",
  });

  const products =
    getDataOrNull(query.data?.pimSupplierProductOrganisationFavourites)
      ?.products ?? [];

  const ActionButton = React.useCallback(
    ({
      product,
    }: {
      product: ProductSearchItem__ProductSearchResultFragment;
    }) => (
      <>
        <ButtonCircleWrapper>
          <OrganisationSupplierProductFavouriteButton
            product={product}
            canEdit={true}
            refetchQueries={[
              namedOperations.Query.ProductSearchLandingPage_FavouriteProducts,
            ]}
          />
        </ButtonCircleWrapper>
        {renderProductSelectButton?.(product)}
      </>
    ),
    [renderProductSelectButton]
  );

  return (
    <Stack direction="column" spacing={2}>
      <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
        <Typography variant={"h2"}>
          {t("Favourites", { ns: "ProductSearch" })}
        </Typography>
        {/* @ts-ignore */}
        <Button
          type="button"
          color="secondary"
          variant="text"
          size="small"
          onClick={() => {
            setProductSearchFilters?.(filters => ({
              ...filters,
              organisationFavourite: true,
            }));
          }}
          {...(getProductSearchFiltersTo
            ? {
                component: Link,
                to: getProductSearchFiltersTo({
                  organisationFavourite: true,
                }),
              }
            : undefined)}
          endIcon={<ChevronRightIcon />}
        >
          {t("View all favourites", { ns: "ProductSearch" })}
        </Button>
      </Stack>
      <Box ref={sizeMeasureRef}>
        {query.loading ? (
          <LoadingSpinner />
        ) : products.length > 0 ? (
          <Grid container spacing={spacing}>
            {products.map((product, index) => (
              <Grid key={product.id} item {...columns}>
                <ProductCardItem
                  key={product.id}
                  product={product}
                  onClick={onProductClick}
                  getProductTo={getProductTo}
                  ActionButton={ActionButton}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography
            // align="center"
            paddingY={1}
            sx={{ color: "grey.500" }}
            variant="body2"
          >
            {t("No favourites saved yet", { ns: "ProductSearch" })}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

function CurrentSuppliers({
  setProductSearchFilters,
  getProductSearchFiltersTo,
}: {
  setProductSearchFilters?: React.Dispatch<
    React.SetStateAction<ProductFiltersFormValues>
  >;
  getProductSearchFiltersTo?: (
    filters: Partial<ProductFiltersFormValues>
  ) => To;
}) {
  const { t } = useTranslate(["ProductSearch"]);

  const client = useApolloClient();

  const { isMinTablet } = useScreenWidth();

  const query = useProductSearchLandingPage_CurrentSuppliersQuery({
    client,
    variables: {
      limit: 100, // TODO: add proper pagination and limit
    },
    fetchPolicy: "cache-and-network",
  });

  const suppliers =
    getDataOrNull(query.data?.pimCurrentSuppliers)?.suppliers ?? [];

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant={"h2"}>
        {t("Suppliers", { ns: "ProductSearch" })}
      </Typography>
      <Box>
        {query.loading ? (
          <LoadingSpinner />
        ) : suppliers.length > 0 ? (
          <Grid container spacing={isMinTablet ? 2 : 1}>
            {suppliers.map((supplier, index) => {
              return (
                <Grid key={supplier.id} item xs={12} sm={4} lg={3} xl={2}>
                  {/* @ts-ignore */}
                  <ProfileListItem
                    size="s"
                    button
                    onClick={() => {
                      setProductSearchFilters?.(filters => ({
                        ...filters,
                        supplierIds: [supplier.id],
                      }));
                    }}
                    {...(getProductSearchFiltersTo
                      ? {
                          component: Link,
                          to: getProductSearchFiltersTo({
                            supplierIds: [supplier.id],
                          }),
                        }
                      : undefined)}
                    avatar={
                      <OrganisationAvatar
                        size="s"
                        organisationAvatar={supplier}
                      />
                    }
                    primary={supplier.title}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography
            // align="center"
            paddingY={1}
            sx={{ color: "grey.500" }}
            variant="body2"
          >
            {t("No suppliers added yet.", { ns: "ProductSearch" })}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

function ProductCategories({
  setProductSearchFilters,
  getProductSearchFiltersTo,
}: {
  setProductSearchFilters?: React.Dispatch<
    React.SetStateAction<ProductFiltersFormValues>
  >;
  getProductSearchFiltersTo?: (
    filters: Partial<ProductFiltersFormValues>
  ) => To;
}) {
  const { t } = useTranslate(["ProductSearch"]);

  const client = useApolloClient();

  const { isMinTablet } = useScreenWidth();

  const query = useProductSearchLandingPage_ProductCategoryRootsQuery({
    client,
    variables: {
      limit: 100, // TODO: add proper pagination and limit
    },
    fetchPolicy: "cache-and-network",
  });

  const roots =
    getDataOrNull(query.data?.pimGetAvailableSupplierProductCategoryRoots)
      ?.result ?? [];

  if (query.loading) {
    return <LoadingSpinner />;
  }

  if (!roots.length) return null;

  return (
    <>
      {roots.map(root => (
        <Stack direction="column" spacing={2} key={root.supplier.id}>
          <Typography variant={"h2"}>
            {t("{supplierName} products by category", {
              ns: "ProductSearch",
              supplierName: root.supplier.title,
            })}
          </Typography>
          <Box>
            {root.supplierProductCategoryRoots.length > 0 ? (
              <Grid container spacing={isMinTablet ? 2 : 1}>
                {root.supplierProductCategoryRoots.map((category, index) => {
                  return (
                    <Grid key={category.id} item xs={6} sm={4} lg={3} xl={2}>
                      {/* @ts-ignore */}
                      <ButtonWithIcon
                        onClick={() => {
                          setProductSearchFilters?.(filters => ({
                            ...filters,
                            supplierProductCategoryKey: category.key,
                            supplierIds: [root.supplier.id],
                          }));
                        }}
                        title={category.title}
                        icon={
                          <Icon
                            sx={{
                              width: "2rem",
                              height: "2rem",
                            }}
                          >
                            {productCategoryIcons[category.key]?.filled ??
                              productCategoryDefaultIcon}
                          </Icon>
                        }
                        {...(getProductSearchFiltersTo
                          ? {
                              component: Link,
                              to: getProductSearchFiltersTo({
                                supplierProductCategoryKey: category.key,
                                supplierIds: [root.supplier.id],
                              }),
                            }
                          : undefined)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography
                // align="center"
                paddingY={1}
                sx={{ color: "grey.500" }}
                variant="body2"
              >
                {t("No categories for supplier", { ns: "ProductSearch" })}
              </Typography>
            )}
          </Box>
        </Stack>
      ))}
    </>
  );
}

function ButtonWithIcon({
  title,
  icon,
  ...props
}: {
  title: string;
  icon: React.ReactElement;
} & Omit<ButtonBaseProps, "title">) {
  return (
    <ButtonBase
      sx={theme => ({
        transition: "background-color 0.2s ease-out",
        color: theme.palette.primary.main,
        backgroundColor: color.blue9,
        ["&:hover:not(:has(button:hover)):not(:has(a:hover))"]: {
          backgroundColor: color.blue8,
        },
        ["&:active:not(:has(button:active)):not(:has(a:active))"]: {
          backgroundColor: color.blue7,
        },
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "88px",
        gap: 0.5,
      })}
      focusRipple
      {...props}
    >
      <div style={{ display: "flex" }}>{icon}</div>
      <Typography variant="body2" align="center">
        {title}
      </Typography>
    </ButtonBase>
  );
}
