import { MenuItemWithIcon } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { AdditionalItemInput } from "../../../trees-virtual/types.js";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { ItemType } from "../../../../clients/graphqlTypes.js";

interface Props {
  itemId: string;
  itemParentId: string;
  itemParentType: ItemType;
  itemRank: number;
  disabled?: boolean;
  onAdditionalItemInputChange(input: AdditionalItemInput): void;
}

export const AddAdditionalItemAboveMenuItem = ({
  itemId,
  itemParentId,
  itemParentType,
  itemRank,
  disabled,
  onAdditionalItemInputChange,
}: Props) => {
  const { t } = useTranslate("QuoteItem");
  return (
    <MenuItemWithIcon
      icon={<ArrowCircleUpIcon />}
      disabled={disabled}
      onClick={() =>
        onAdditionalItemInputChange({
          itemId,
          itemParentId,
          itemParentType,
          itemRank,
          position: "above",
        })
      }
    >
      {t("Add item above")}
    </MenuItemWithIcon>
  );
};

export const AddAdditionalItemBelowMenuItem = ({
  itemId,
  itemParentId,
  itemParentType,
  itemRank,
  disabled,
  onAdditionalItemInputChange,
}: Props) => {
  const { t } = useTranslate("QuoteItem");
  return (
    <MenuItemWithIcon
      icon={<ArrowCircleDownIcon />}
      disabled={disabled}
      onClick={() =>
        onAdditionalItemInputChange({
          itemId,
          itemParentId,
          itemParentType,
          itemRank,
          position: "below",
        })
      }
    >
      {t("Add item below")}
    </MenuItemWithIcon>
  );
};
