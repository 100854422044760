import { StyledComponent } from "@emotion/styled";
import React from "react";
import { ViewerBrandingSlot } from "../../../clients/graphqlTypes.js";
import { styled } from "@mui/material/styles";
import { Theme, Typography } from "@mui/material";
import { MUIStyledCommonProps } from "@mui/system";

/*
import { Theme } from "@mui/material";
import { StyledComponent } from "@emotion/styled";
import { MUIStyledCommonProps } from "@mui/system";

: StyledComponent<
  MUIStyledCommonProps<Theme>,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
>
*/

interface Props {
  height: number;
  width: number;
  logo: ViewerBrandingSlot;
  style?: React.CSSProperties;
  noLink?: boolean;
  objectPosition?: React.CSSProperties["objectPosition"];
  objectFit?: React.CSSProperties["objectFit"];
}

export const PageLogo = ({
  height,
  width,
  logo,
  style,
  objectPosition = "left center",
  objectFit = "contain",
  noLink,
}: Props) => {
  return (
    <PageLogoContainer $width={width} $height={height} style={style}>
      <div>
        {logo.title ? (
          <Typography variant="caption">{logo.title}</Typography>
        ) : null}
        {logo.attachment ? (
          <div
            style={{
              display: "flex",
              maxHeight: height - (logo.title ? 14 : 0),
              maxWidth: width,
              minWidth: width,
              position: "relative",
            }}
          >
            <img
              style={{
                objectFit,
                objectPosition,
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              src={logo.attachment.url}
              alt="Logo"
            />
          </div>
        ) : null}
      </div>
    </PageLogoContainer>
  );
};

export const PageLogoContainer: StyledComponent<
  MUIStyledCommonProps<Theme> & {
    $width: number;
    $height: number;
  },
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled("div")<{
  $width: number;
  $height: number;
}>`
  flex: 0 0 auto;
  min-width: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: ${({ $height }) => $height}px;
  max-width: ${({ $width }) => $width}px;
`;
