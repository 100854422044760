import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue } from "@msys/ui";
import { Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikHelpers } from "formik";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import {
  ModifyItemTaskInfoValuesInput,
  PermissionName,
} from "../../../../clients/graphqlTypes.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../../auth/RestrictedByDocumentPermission.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField.js";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { getAssigneeOptions } from "../../tasks/helpers.js";
import {
  TaskManagementBox_ItemFragment,
  TaskManagementBox_ProjectFragment,
  useQuoteModifyItemTaskInfo_TaskManagementBoxMutation,
} from "./TaskManagementBox.generated.js";

const PRIORITY_OPTIONS = [
  { label: "-", value: "0" },
  { label: "!", value: "1" },
  { label: "!!", value: "2" },
  { label: "!!!", value: "3" },
];

interface FormValues {
  assigneeId: string | null;
  dueDate: Moment | null;
  priority: number;
}

interface Props {
  project: TaskManagementBox_ProjectFragment;
  docId: string;
  document: { viewerPermissions: PermissionName[] };
  item: TaskManagementBox_ItemFragment;
  canEdit?: boolean;
  isInitiallyClosed?: boolean;
}

export const TaskManagementBox = ({
  item,
  docId,
  document,
  project,
  canEdit = false,
  isInitiallyClosed,
}: Props) => {
  const { t } = useTranslate(["QuoteItemTaskManagementBox", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyItemTaskInfo] =
    useQuoteModifyItemTaskInfo_TaskManagementBoxMutation({
      client,
    });

  const modifyTaskItem = async (values: ModifyItemTaskInfoValuesInput) => {
    await modifyItemTaskInfo({
      variables: {
        input: {
          projectId: project.id,
          docId,
          itemId: item.id,
          values,
        },
      },
    });
  };

  const initialValues: FormValues = {
    assigneeId: item.assignee?.id ?? "",
    dueDate: item.dueDate ? moment(item.dueDate) : null,
    priority: item.priority,
  };

  const onSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    try {
      await modifyTaskItem({
        assigneeId: values.assigneeId || null,
        dueDate: values.dueDate?.format("YYYY-MM-DD") ?? null,
        priority: Math.max(Math.min(values.priority, 3), 0),
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
      resetForm({ values: initialValues });
    }
  };

  const assignee = project.internalStakeholders.find(
    s => s.user.id === item.assignee?.id
  );

  return (
    <CardContainer
      isExpandable
      title={t("Task Management", {
        ns: "QuoteItemTaskManagementBox",
      })}
      isInitiallyClosed={isInitiallyClosed}
    >
      <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <Stack flexDirection="column" m={1}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                {canEdit ? (
                  <RestrictedByDocumentPermissionWithDebug
                    permission="MANAGE_QUOTES"
                    document={document}
                    otherwise={
                      <LabeledValue
                        label={t("Deadline", {
                          ns: "QuoteItemTaskManagementBox",
                        })}
                      >
                        {item.dueDate
                          ? moment(item.dueDate).format("L")
                          : t("Not set", {
                              ns: "Global",
                            })}
                      </LabeledValue>
                    }
                  >
                    <DatePickerField
                      name="dueDate"
                      label={t("Deadline", {
                        ns: "QuoteItemTaskManagementBox",
                      })}
                      disabled={false}
                    />
                  </RestrictedByDocumentPermissionWithDebug>
                ) : (
                  <LabeledValue
                    label={t("Deadline", {
                      ns: "QuoteItemTaskManagementBox",
                    })}
                  >
                    {item.dueDate
                      ? moment(item.dueDate).format("L")
                      : t("Not set", {
                          ns: "Global",
                        })}
                  </LabeledValue>
                )}
              </Grid>
              <Grid item xs={2}>
                {canEdit ? (
                  <RestrictedByDocumentPermissionWithDebug
                    permission="MANAGE_QUOTES"
                    document={document}
                    otherwise={
                      <LabeledValue
                        label={t("Priority", {
                          ns: "QuoteItemTaskManagementBox",
                        })}
                      >
                        {item.priority > 0
                          ? Array(item.priority + 1).join("!")
                          : "–"}
                      </LabeledValue>
                    }
                  >
                    <SelectField
                      disabled={false}
                      name="priority"
                      label={t("Priority", {
                        ns: "QuoteItemTaskManagementBox",
                      })}
                      options={PRIORITY_OPTIONS}
                    />
                  </RestrictedByDocumentPermissionWithDebug>
                ) : (
                  <LabeledValue
                    label={t("Priority", {
                      ns: "QuoteItemTaskManagementBox",
                    })}
                  >
                    {item.priority > 0
                      ? Array(item.priority + 1).join("!")
                      : "–"}
                  </LabeledValue>
                )}
              </Grid>
              <Grid item xs={5}>
                {canEdit ? (
                  <RestrictedByDocumentPermissionWithDebug
                    permission="MANAGE_QUOTES"
                    document={document}
                    otherwise={
                      <LabeledValue
                        label={t("Assignee", {
                          ns: "QuoteItemTaskManagementBox",
                        })}
                      >
                        {assignee
                          ? `${assignee.user.firstname[0]}. ${assignee.user.familyname}`
                          : t("Not set", {
                              ns: "Global",
                            })}
                      </LabeledValue>
                    }
                  >
                    <SelectField
                      name="assigneeId"
                      disabled={false}
                      options={getAssigneeOptions(project, true)}
                      label={t("Assignee", {
                        ns: "QuoteItemTaskManagementBox",
                      })}
                      clearable
                    />
                  </RestrictedByDocumentPermissionWithDebug>
                ) : (
                  <LabeledValue
                    label={t("Assignee", {
                      ns: "QuoteItemTaskManagementBox",
                    })}
                  >
                    {assignee
                      ? `${assignee.user.firstname[0]}. ${assignee.user.familyname}`
                      : t("Not set", {
                          ns: "Global",
                        })}
                  </LabeledValue>
                )}
              </Grid>
            </Grid>

            <AutoSave />
          </Stack>
        </Form>
      </Formik>
    </CardContainer>
  );
};
