import {
  CollapseSection,
  isImageOr3dModel,
  processAttachment,
  RichTextValue,
} from "@msys/ui";
import { Box, Grid, List, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { partition } from "lodash-es";
import React from "react";
import { GalleryGrid } from "../../commons/images/GalleryGrid.js";
import { FileRow } from "../attachments/FileRow.js";
import {
  ArticleYoutubeVideoPreview,
  getYoutubeVideoId,
} from "./ArticleYoutubeVideoPreview.js";
import { LinkRow } from "./LinkRow.js";

export const Article = ({
  id,
  title,
  previewImageUrl,
  text,
  links = [],
  attachments = [],
  MenuButton,
  handleClose,
}: {
  id: string;
  title: string;
  previewImageUrl: string;
  text: string;
  links: { url: string }[];
  attachments?: {
    id: string;
    title: string;
    mimeType: string;
    previewImageUrl: string;
    url: string;
  }[];
  MenuButton?: React.ReactNode;
  handleClose: () => void;
}) => {
  const { t } = useTranslate(["Global", "Contents"]);

  const [images, files] = partition(
    attachments.map(processAttachment),
    isImageOr3dModel
  );

  const hasAttachments = attachments.length > 0;
  const hasLinks = links.length > 0;

  const youtubePreviewLink = links.find(link =>
    Boolean(getYoutubeVideoId(link.url))
  );

  return (
    <Stack direction={"column"} spacing={2} id={id}>
      <Stack direction={"row"} spacing={2}>
        {/*<IconButton size="small" onClick={handleClose} color="primary">*/}
        {/*  <ArrowBackIcon />*/}
        {/*</IconButton>*/}
        <Typography variant="h2" sx={{ flex: 1, minWidth: 0, pt: 0.5 }}>
          {title}
        </Typography>
        {MenuButton}
      </Stack>
      {(previewImageUrl || youtubePreviewLink) && (
        <Box>
          <Grid spacing={2} container>
            {previewImageUrl && (
              <Grid
                item
                xs={12}
                md={previewImageUrl && youtubePreviewLink ? 6 : 12}
              >
                <Box
                  width="100%"
                  overflow="hidden"
                  display="flex"
                  sx={theme => ({
                    aspectRatio: "16 / 9",
                    maxHeight: theme.layout.galleryHeight.md,
                  })}
                >
                  <img
                    draggable={false}
                    src={previewImageUrl}
                    alt={t("Content cover photo", {
                      ns: "Contents",
                    })}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
            )}
            {youtubePreviewLink && (
              <Grid
                item
                xs={12}
                md={previewImageUrl && youtubePreviewLink ? 6 : 12}
              >
                <Box
                  width="100%"
                  overflow="hidden"
                  display="flex"
                  sx={theme => ({
                    aspectRatio: "16 / 9",
                    maxHeight: theme.layout.galleryHeight.md,
                  })}
                >
                  <ArticleYoutubeVideoPreview
                    url={youtubePreviewLink.url}
                    videoId={getYoutubeVideoId(youtubePreviewLink.url)}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={hasAttachments || hasLinks ? 8 : 12}>
            <RichTextValue htmlContent={text} />
          </Grid>
          {(hasAttachments || hasLinks) && (
            <Grid item xs={12} md={4}>
              <Stack direction={"column"} spacing={1}>
                {hasLinks && (
                  <CollapseSection
                    title={t("External resources", {
                      ns: "Contents",
                    })}
                    isInitiallyExpanded
                    itemCount={links.length}
                  >
                    <List disablePadding>
                      {links.map((link, index) => (
                        <LinkRow
                          key={index}
                          button={false}
                          link={link}
                          divider={false}
                        />
                      ))}
                    </List>
                  </CollapseSection>
                )}
                {hasAttachments && (
                  <CollapseSection
                    title={t("Attachments", {
                      ns: "Contents",
                    })}
                    isInitiallyExpanded
                    itemCount={links.length}
                  >
                    {images.length > 0 && (
                      <Box>
                        <GalleryGrid images={images} columns={4} />
                      </Box>
                    )}
                    {files.length > 0 && (
                      <Box>
                        <List disablePadding>
                          {files.map(attachment => (
                            <FileRow
                              key={attachment.id}
                              button={false}
                              file={attachment}
                              divider={false}
                            />
                          ))}
                        </List>
                      </Box>
                    )}
                  </CollapseSection>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
    </Stack>
  );
};
