import { useApolloClient } from "@apollo/client";
import { uniq } from "lodash-es";
import React from "react";
import { TagGroup } from "../../../clients/graphqlTypes.js";
import { useOrganisationTagsQuery } from "./useOrganisationTags.generated.js";

export const useOrganisationTags = (group: TagGroup) => {
  const client = useApolloClient();
  const tagsQuery = useOrganisationTagsQuery({
    client,
    variables: { group, includeSubscribedTemplateTags: group === "TEMPLATE" },
  });
  const { tags, customTags, systemTags } = React.useMemo((): {
    tags: string[];
    customTags: string[];
    systemTags: string[];
  } => {
    const customTags = tagsQuery.data?.viewer?.organisation.customTags ?? [];
    const systemTags = tagsQuery.data?.viewer?.organisation.systemTags ?? [];
    const subscribedTemplateTags =
      tagsQuery.data?.viewer?.organisation.subscribedTemplateTags ?? [];
    const tags = uniq([
      ...systemTags,
      ...customTags,
      ...subscribedTemplateTags,
    ]).sort((t1, t2) => t1.localeCompare(t2));
    const customAndSubscribedTags = uniq([
      ...customTags,
      ...subscribedTemplateTags,
    ]).sort((t1, t2) => t1.localeCompare(t2));
    return { tags, systemTags, customTags: customAndSubscribedTags };
  }, [
    tagsQuery.data?.viewer?.organisation.customTags,
    tagsQuery.data?.viewer?.organisation.subscribedTemplateTags,
    tagsQuery.data?.viewer?.organisation.systemTags,
  ]);
  return { tagsQuery, tags, customTags, systemTags };
};
