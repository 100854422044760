/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type CrmCompanyListItemFragment = { __typename: 'CrmCompaniesRecord', id: string, number: string, title: string, abcCategory: Types.CrmOrganisationAbcCategory, systemTags: Array<string>, customTags: Array<string>, email: any, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null };

export const CrmCompanyListItemFragmentDoc = gql`
    fragment CrmCompanyListItem on CrmCompaniesRecord {
  id
  number
  title
  contactPerson {
    id
    fullname
  }
  abcCategory
  systemTags
  customTags
  email
}
    `;