/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { CustomFieldConfigFragmentDoc } from './customFieldConfigs.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetCustomFieldConfigMutationVariables = Types.Exact<{
  input: Array<Types.SetCustomFieldConfigInput>;
}>;


export type SetCustomFieldConfigMutation = { setCustomFieldConfig: Array<{ __typename: 'CustomFieldConfig', id: string, key: string, objectType: Types.CustomFieldObjectType, dataType: Types.CustomFieldDataType, group: string, allowedValues: string, showAsColumnInList: boolean, showInCreateForm: boolean, mandatory: boolean, isUnique: boolean }> };


export const SetCustomFieldConfigDocument = gql`
    mutation SetCustomFieldConfig($input: [SetCustomFieldConfigInput!]!) {
  setCustomFieldConfig(input: $input) {
    ...CustomFieldConfig
  }
}
    ${CustomFieldConfigFragmentDoc}`;
export type SetCustomFieldConfigMutationFn = Apollo.MutationFunction<SetCustomFieldConfigMutation, SetCustomFieldConfigMutationVariables>;

/**
 * __useSetCustomFieldConfigMutation__
 *
 * To run a mutation, you first call `useSetCustomFieldConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomFieldConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomFieldConfigMutation, { data, loading, error }] = useSetCustomFieldConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCustomFieldConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomFieldConfigMutation, SetCustomFieldConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomFieldConfigMutation, SetCustomFieldConfigMutationVariables>(SetCustomFieldConfigDocument, options);
      }
export type SetCustomFieldConfigMutationHookResult = ReturnType<typeof useSetCustomFieldConfigMutation>;
export type SetCustomFieldConfigMutationResult = Apollo.MutationResult<SetCustomFieldConfigMutation>;
export type SetCustomFieldConfigMutationOptions = Apollo.BaseMutationOptions<SetCustomFieldConfigMutation, SetCustomFieldConfigMutationVariables>;