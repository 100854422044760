/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type PurchaseOrderListItem_OrderNodeFragment = { __typename: 'OrderNode', id: string, number: string, createdAt: any, orderedDate?: any | null, clientName: string, supplierName?: string | null };

export type PurchaseOrderListItemFragment = { __typename: 'Order', id: string, number: string, createdAt: any, orderedDate?: any | null, calculationPrepared: { __typename: 'OrderCalculationPrepared', id?: string | null, priceTotalOrdered: number }, supplier?: { __typename: 'Company', id: string, title: string } | null };

export const PurchaseOrderListItem_OrderNodeFragmentDoc = gql`
    fragment PurchaseOrderListItem_OrderNode on OrderNode {
  id
  number
  createdAt
  orderedDate
  clientName
  supplierName
}
    `;
export const PurchaseOrderListItemFragmentDoc = gql`
    fragment PurchaseOrderListItem on Order {
  id
  number
  createdAt
  orderedDate
  calculationPrepared {
    id
    priceTotalOrdered
  }
  supplier {
    id
    title
  }
}
    `;