import { useTranslate } from "@tolgee/react";
import { EmailTemplateContext } from "../../../../clients/graphqlTypes.js";

export function useEmailTemplateContexts() {
  const { t } = useTranslate(["OrganisationSettings"]);

  const labels: Record<EmailTemplateContext, string> = {
    PROJECT: t("Project", { ns: "OrganisationSettings" }),
    QUOTE: t("Quote", { ns: "OrganisationSettings" }),
    REQUIREMENT: t("Requirement", { ns: "OrganisationSettings" }),
    CRM: t("CRM", { ns: "OrganisationSettings" }),
  };

  const options = Object.entries(labels).map(([value, label]) => ({
    value,
    label,
  }));

  return { labels, options };
}
