import { Add as AddIcon } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { flatten, noop, uniq } from "lodash-es";
import React from "react";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { Stack } from "../../commons/layout/Stack.js";
import { VirtualItemTree } from "../../trees-virtual/VirtualItemTree.js";
import { VirtualBareTreeItem } from "../../trees-virtual/components/VirtualBareTreeItem.js";
import { ExpandedStore } from "../../trees-virtual/hooks/useExpandedStore.js";
import { TreeConnector } from "../../trees/components/TreeConnector.js";
import { TreeToggleAllExpandedButton } from "../../trees/components/TreeToggleButton.js";
import { getAllParentItems } from "../../trees/helpers.js";
import { InvoiceExtraTreeItem } from "./InvoiceExtraTreeItem.js";
import { InvoicePaymentItem } from "./InvoicePaymentItem.js";
import { useInvoiceQuickFilters } from "./InvoiceQuickFilters.js";
import {
  InvoiceSelectTreeItemsForm_InvoiceExtraItemFragment,
  InvoiceSelectTreeItemsForm_InvoiceFragment,
  InvoiceSelectTreeItemsForm_ProjectFragment,
  InvoiceSelectTreeItemsForm_QuoteFragment,
} from "./InvoiceSelectTreeItemsForm.generated.js";
import { createTreeItem } from "./trees.js";
import { InvoiceFormItem, InvoiceFormValues } from "./types.js";

export const InvoiceSelectTreeItemsForm = ({
  docId,
  docProject,
  allDocItems,
  invoice,
  invoiceExtraItems,
  quote,
  selectedItemIds,
  selectedItemId,
  setSelectedItemId,
  selectedExtraItemId,
  setSelectedExtraItemId,
  onSubmit,
  container,
  invoiceableQuoteItemIds,
  invoiceableInInvoiceQuoteItemIds,
  expandedStore,
}: {
  docId: string;
  docProject: InvoiceSelectTreeItemsForm_ProjectFragment;
  invoice: InvoiceSelectTreeItemsForm_InvoiceFragment;
  invoiceExtraItems: InvoiceSelectTreeItemsForm_InvoiceExtraItemFragment[];
  quote: InvoiceSelectTreeItemsForm_QuoteFragment;
  allDocItems: InvoiceFormItem[];
  selectedItemIds: string[];
  selectedItemId: string | null;
  setSelectedItemId: (id: string | null) => void;
  selectedExtraItemId: string | null;
  setSelectedExtraItemId: (id: string | "NEW" | null) => void;
  onSubmit: (data: { items: InvoiceFormItem[] }) => Promise<void>;
  container?: HTMLDivElement | null;
  invoiceableQuoteItemIds: string[];
  invoiceableInInvoiceQuoteItemIds: string[];
  expandedStore: ExpandedStore;
}) => {
  const { t } = useTranslate("InvoiceDetailsForm");
  const [QuickFiltersTabs, quickFilter] =
    useInvoiceQuickFilters<InvoiceFormItem>(
      "INVOICEABLE",
      invoiceableQuoteItemIds,
      invoiceableInInvoiceQuoteItemIds
    );

  const visibleItems = React.useMemo(() => {
    return quickFilter(allDocItems, selectedItemIds);
  }, [allDocItems, selectedItemIds, quickFilter]);

  const rootItem = React.useMemo(() => {
    return allDocItems.find(i => i.isRootItem)!;
  }, [allDocItems]);

  const enableNavigating =
    invoice.type === "partial_invoice" || invoice.type === "final_invoice";
  const allowAdditionalItems =
    invoice.type === "partial_invoice" || invoice.type === "final_invoice";

  const TreeItem = React.useMemo(
    () =>
      createTreeItem(
        docProject.id,
        invoice.id,
        invoice.type,
        docId,
        quote.agreement,
        { viewerPermissions: docProject.viewerPermissions },
        quote,
        invoiceableQuoteItemIds,
        invoiceableInInvoiceQuoteItemIds,
        enableNavigating ? setSelectedItemId : noop
      ),
    [
      docProject.viewerPermissions,
      docProject.id,
      invoice.id,
      invoice.type,
      docId,
      quote,
      invoiceableQuoteItemIds,
      invoiceableInInvoiceQuoteItemIds,
      enableNavigating,
      setSelectedItemId,
    ]
  );

  return (
    <Formik<InvoiceFormValues>
      enableReinitialize
      initialValues={{ selectedItemIds }}
      onSubmit={async values => {
        const items = uniq([
          ...allDocItems.filter(item =>
            values.selectedItemIds.includes(item.id)
          ),
          ...flatten(
            allDocItems
              .filter(item => values.selectedItemIds.includes(item.id))
              .map(item => getAllParentItems(item, allDocItems))
          ),
        ]);
        await onSubmit({ items });
      }}
    >
      {formikProps => {
        return (
          <>
            <Stack flexDirection={"column"} spacing={2}>
              <Stack width="100%" alignItems="center" spacing={1}>
                <TreeToggleAllExpandedButton
                  areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                  setAllItemsExpanded={expandedStore.setAllItemsExpanded}
                />
                {QuickFiltersTabs}
              </Stack>

              <Form>
                <Stack flexDirection="column" spacing={2}>
                  {visibleItems.length > 0 && (
                    <Box>
                      <VirtualBareTreeItem
                        depth={0}
                        selected={false}
                        clickable={false}
                        sticky={false}
                      >
                        <TreeItem
                          item={rootItem}
                          allItems={allDocItems}
                          isRootItem={true}
                          depth={0}
                          parentItem={null}
                          isGreyedOut={false}
                          isHidden={false}
                          isPriceHidden={false}
                          subcontractPath={null}
                        />
                      </VirtualBareTreeItem>
                      <VirtualItemTree<InvoiceFormItem, false>
                        container={container}
                        docId={null}
                        projectId={null}
                        items={visibleItems}
                        selectedItemId={selectedItemId}
                        enableCreating={false}
                        enableDragging={false}
                        documentItemTypes={[]}
                        itemComponent={TreeItem}
                        expandedStore={expandedStore}
                      />
                    </Box>
                  )}

                  {allowAdditionalItems && (
                    <Stack flexDirection="column" spacing={0.5}>
                      <Stack justifyContent="space-between" alignItems="center">
                        <Typography variant="h3">
                          {t("Additional items to invoice")}
                        </Typography>
                        <RestrictedByProjectPermissionWithDebug
                          permission="MANAGE_INVOICES"
                          project={docProject}
                        >
                          {invoice.status === "draft" && (
                            <IconButton
                              onClick={() => setSelectedExtraItemId("NEW")}
                              color="primary"
                              size="medium"
                            >
                              <AddIcon />
                            </IconButton>
                          )}
                        </RestrictedByProjectPermissionWithDebug>
                      </Stack>

                      {invoiceExtraItems.length > 0 && (
                        <div>
                          {invoiceExtraItems.map(extraItem => (
                            <InvoiceExtraTreeItem
                              selectedExtraItemId={selectedExtraItemId}
                              extraItem={extraItem}
                              invoice={invoice}
                              key={extraItem.id}
                              onClick={() => {
                                setSelectedExtraItemId(extraItem.id);
                              }}
                              project={docProject}
                            />
                          ))}
                        </div>
                      )}
                    </Stack>
                  )}

                  {invoice.invoicePaymentItems.length > 0 && (
                    <InvoicePaymentItem
                      quoteId={quote.id}
                      projectId={docProject.id}
                      invoiceId={invoice.id}
                      invoiceType={invoice.type}
                      invoiceCalculation={invoice.calculation}
                      invoiceNonInvoicedQuoteAmount={
                        invoice.nonInvoicedQuoteAmount
                      }
                      paymentItem={invoice.invoicePaymentItems[0]!}
                      quoteCalculation={quote.agreedCalculation!}
                      quoteDiscountPercentage={quote.discountPercentage}
                      project={docProject}
                    />
                  )}
                </Stack>
              </Form>
            </Stack>
          </>
        );
      }}
    </Formik>
  );
};
