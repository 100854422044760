import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  Modal,
  ModalOpenButton,
  StateStore,
  useLocalStorageAsState,
  useScreenWidth,
  VisibilityIndicatorIcon,
} from "@msys/ui";
import {
  Add as AddIcon,
  ContentPaste as ContentPasteIcon,
  CopyAll as CopyAll,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
  Edit as EditIcon,
  Functions as FunctionsIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-premium";
import { useTranslate } from "@tolgee/react";
import { capitalize, differenceBy, findIndex } from "lodash-es";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { useLatest } from "react-use";
import {
  DefineItemProps2Entry,
  DocType,
  Props2,
  Props2AllowedValuesNumber,
  Props2AllowedValuesText,
  Props2AskWhen,
  Props2AskWhom,
} from "../../../../clients/graphqlTypes.js";
import { LocalDndProvider } from "../../../../common/dnd.js";
import { FullScreenToggleIconButton } from "../../../commons/button/FullScreenToggleIconButton.js";
import { isEqual } from "../../../commons/hooks/useStateWithUrlParams.js";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal.js";
import { useDataGridStateStore } from "../../users/useDataGridStateStore.js";
import {
  getPropertyType,
  getPropInputGroup,
  groupProperties,
  isArrayType,
  isNonComputedProp,
  isNumberType,
  isTextType,
  Props2Group,
  propToDefineInput,
  ungroupProperties,
} from "../properties.js";
import { Props2NonComputedAllFragment } from "../properties.generated.js";
import { usePropertiesClipboard } from "../usePropertiesClipboard.js";
import { usePropertyHelpers } from "../usePropertyHelpers.js";
import { useQuestionContol } from "../useQuestionContol.js";
import { usePropertiesManageModal_QuoteTemplateQuery } from "./PropertiesManageModal.generated.js";
import { PropertiesPasteModal } from "./PropertiesPasteModal.js";
import { ItemPropertyAddModal } from "./PropertyAddModal.js";
import { PropertyEditModal } from "./PropertyEditModal.js";
import { PropertyEditWithTemplateTypeModal } from "./PropertyEditWithTemplateTypeModal.js";

interface Props {
  projectId: string | null;
  docId: string;
  docType: DocType;
  itemId: string;
  itemTitle: string;
  itemIsRootItem: boolean;
  properties: Props2[];
  handleClose: () => void;
  defineProperties: (properties: DefineItemProps2Entry[]) => Promise<void>;
}

export function PropertiesManageModal(props: Props) {
  return (
    <LocalDndProvider>
      <PropertiesManageModalComponent {...props} />
    </LocalDndProvider>
  );
}

function PropertiesManageModalComponent({
  projectId,
  docId,
  docType,
  itemId,
  itemTitle,
  itemIsRootItem,
  properties,
  handleClose,
  defineProperties,
}: Props) {
  const { t } = useTranslate(["Global", "QuoteItem"]);

  const { isMaxPhone } = useScreenWidth();

  const client = useApolloClient();
  const quoteTemplateQuery = usePropertiesManageModal_QuoteTemplateQuery({
    client,
    variables: { quoteTemplateId: docId },
    skip: !(docType === "TEMPLATE" && itemIsRootItem),
  });
  const templateTypeProps =
    getDataOrNull(quoteTemplateQuery.data?.quoteTemplateLatest)?.quoteTemplate
      ?.implementsTemplateType?.templateType?.props2 ?? [];

  const {
    itemTitle: clipboardItemTitle,
    properties: propertiesFromClipboard,
    group: propertiesGroup,
    setContent: setPropertiesClipboard,
  } = usePropertiesClipboard();

  const [selectedProperties, setSelectedProperties] = React.useState<
    GridRowId[]
  >([]);

  const stateStore = useDataGridStateStore("PropertiesManageModal");

  const groups = React.useMemo(() => groupProperties(properties), [properties]);

  const [groupKeysSorted, setGroupKeysSorted] = React.useState<string[]>(
    groups.map(g => g.key)
  );
  const groupKeysSortedRef = React.useRef(groupKeysSorted);
  groupKeysSortedRef.current = groupKeysSorted;

  React.useEffect(() => {
    const newKeys = groups.map(g => g.key);
    if (!isEqual(newKeys, groupKeysSortedRef.current)) {
      setGroupKeysSorted(newKeys);
    }
  }, [groups]);

  const onGroupMove = (dragKey: string, hoverKey: string) => {
    const dragIndex = findIndex(groupKeysSorted, k => k === dragKey);
    const hoverIndex = findIndex(groupKeysSorted, k => k === hoverKey);

    const newKeys = [...groupKeysSorted];
    const draggedKey = newKeys.splice(dragIndex, 1)[0];
    newKeys.splice(hoverIndex, 0, draggedKey);

    setGroupKeysSorted(newKeys);
  };

  const onGroupDropped = async (didDrop: boolean) => {
    await defineProperties(
      groupKeysSortedRef.current
        .map(key => groups.find(g => g.key === key)?.properties ?? [])
        .flat(1)
        .map(propToDefineInput)
    );
  };

  const [, drop] = useDrop({ accept: "card" });

  const [isFullScreen, setIsFullScreen] = useLocalStorageAsState<boolean>(
    "msys-properties-modal-fullscreen",
    false,
    true
  );

  return (
    <Modal
      title={t("Manage properties", { ns: "QuoteItem" })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Close", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
          },
        },
      ]}
      maxWidth="xl"
      dialogProps={
        !isMaxPhone && isFullScreen ? { fullScreen: true } : undefined
      }
      headerActions={
        !isMaxPhone ? (
          <FullScreenToggleIconButton
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
          />
        ) : undefined
      }
      alwaysVisible
      isLoading={quoteTemplateQuery.loading}
    >
      <Stack direction={"column"} spacing={2}>
        <Stack direction={"row"} justifyContent="flex-end" spacing={1}>
          <ModalOpenButton
            Modal={PropertiesPasteModal}
            modalProps={{
              group: propertiesGroup || clipboardItemTitle,
              async handleComplete(newGroup, handleClose) {
                const newGroups = [...groups];
                let newProperties = differenceBy(
                  (newGroup
                    ? propertiesFromClipboard?.map(prop => ({
                        ...prop,
                        group: newGroup,
                      }))
                    : propertiesFromClipboard) ?? [],
                  properties,
                  p => p.key
                );

                if (newProperties.length) {
                  newProperties.forEach(newProperty => {
                    const existingGroupIndex = findIndex(
                      groups,
                      g => g.key === (newProperty.group || "")
                    );
                    if (existingGroupIndex >= 0) {
                      newGroups[existingGroupIndex].properties = [
                        ...groups[existingGroupIndex].properties,
                        newProperty,
                      ];
                    } else {
                      newGroups.push({
                        key: newProperty.group || "",
                        properties: [newProperty],
                      });
                    }
                  });
                  await defineProperties(
                    ungroupProperties(newGroups).map(propToDefineInput)
                  );
                }

                handleClose();
              },
            }}
            disabled={!propertiesFromClipboard}
          >
            <Button startIcon={<ContentPasteIcon />} size="small">
              {t("Paste copied properties", {
                ns: "QuoteItem",
              })}
            </Button>
          </ModalOpenButton>
          <Button
            size="small"
            startIcon={<CopyAll />}
            onClick={() => {
              setPropertiesClipboard(
                itemTitle,
                properties.filter(property =>
                  selectedProperties.includes(property.key)
                )
              );
              setSelectedProperties([]);
            }}
            disabled={selectedProperties.length < 1}
          >
            {t("Copy properties", {
              ns: "QuoteItem",
            })}
          </Button>
          <ModalOpenButton
            Modal={ItemPropertyAddModal}
            modalProps={{
              docType,
              projectId,
              docId,
              itemId,
              async handleComplete(newProperty) {
                await defineProperties([
                  ...properties.map(propToDefineInput),
                  newProperty,
                ]);
              },
            }}
          >
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              size="small"
              disableElevation
            >
              {t("New property", { ns: "QuoteItem" })}
            </Button>
          </ModalOpenButton>
        </Stack>

        <Stack direction={"column"} spacing={2} ref={drop}>
          {groupKeysSorted.map(groupKey => {
            const groupIndex = findIndex(groups, g => g.key === groupKey);
            return (
              <DraggableGroupCard
                key={`group-${groupKey || ""}`}
                groupKey={groupKey}
                onCardMove={onGroupMove}
                onCardDropped={onGroupDropped}
              >
                <PropertiesTable
                  key={`group-${groupKey || ""}`}
                  defineProperties={defineProperties}
                  projectId={projectId}
                  docId={docId}
                  itemId={itemId}
                  groups={groups}
                  groupIndex={groupIndex}
                  stateStore={stateStore}
                  selectedProperties={selectedProperties}
                  setSelectedProperties={setSelectedProperties}
                  templateTypeProps={templateTypeProps}
                />
              </DraggableGroupCard>
            );
          })}
        </Stack>
      </Stack>
    </Modal>
  );
}

const PropertiesTable = React.memo(
  ({
    defineProperties,
    projectId,
    docId,
    itemId,
    groups,
    groupIndex,
    stateStore,
    selectedProperties,
    setSelectedProperties,
    templateTypeProps,
  }: {
    defineProperties: (properties: DefineItemProps2Entry[]) => Promise<void>;
    projectId: string | null;
    docId: string;
    itemId: string;
    groups: Props2Group[];
    groupIndex: number;
    stateStore: StateStore;
    selectedProperties: GridRowId[];
    setSelectedProperties: React.Dispatch<React.SetStateAction<GridRowId[]>>;
    templateTypeProps: Props2NonComputedAllFragment[];
  }) => {
    const { t } = useTranslate(["Global", "QuoteItem"]);
    const { getBoolLabel, getUnitLabel } = usePropertyHelpers();
    const { askWhomLabels, askWhenLabels } = useQuestionContol();

    const columns = React.useMemo(
      (): GridColDef<Props2>[] => [
        {
          field: "key",
          headerName: t("Key", { ns: "Global" }),
          sortable: false,
          groupable: false,
          flex: 1,
        },
        {
          field: "label",
          headerName: t("Label", {
            ns: "Global",
          }),
          sortable: false,
          groupable: false,
          flex: 1,
        },
        {
          field: "type",
          headerName: t("Type", {
            ns: "QuoteItem",
          }),
          sortable: false,
          groupable: false,
          flex: 1,
          valueGetter({ row: property, rowNode }) {
            return getPropertyType(property);
          },
          valueFormatter({ value }) {
            return capitalize(value);
          },
        },

        {
          field: "unit",
          headerName: t("Unit", {
            ns: "QuoteItem",
          }),
          sortable: false,
          groupable: false,
          flex: 1,
          valueGetter({ row: property, rowNode }) {
            return isNumberType(property) && property.unit
              ? property.unit
              : null;
          },
          valueFormatter({ value }) {
            return value ? getUnitLabel(value) : null;
          },
        },
        {
          field: "min",
          headerName: t("Min", {
            ns: "QuoteItem",
          }),
          sortable: false,
          groupable: false,
          flex: 1,
          valueGetter({ row: property, rowNode }) {
            return isNumberType(property) && isNonComputedProp(property)
              ? property.range?.min
              : null;
          },
        },
        {
          field: "max",
          headerName: t("Max", {
            ns: "QuoteItem",
          }),
          sortable: false,
          groupable: false,
          flex: 1,
          valueGetter({ row: property, rowNode }) {
            return isNumberType(property) && isNonComputedProp(property)
              ? property.range?.max
              : null;
          },
        },
        {
          field: "allowMultiple",
          headerName: t("Allow multiple", {
            ns: "QuoteItem",
          }),
          sortable: false,
          groupable: false,
          flex: 2,
          valueGetter({ row: property, rowNode }) {
            return isArrayType(property);
          },
          valueFormatter({ value }) {
            return value === true || value === false
              ? getBoolLabel(value)
              : null;
          },
        },
        {
          field: "allowedValues",
          headerName: t("Allowed values", {
            ns: "QuoteItem",
          }),
          sortable: false,
          groupable: false,
          flex: 2,
          valueGetter({ row: property, rowNode }) {
            return isNonComputedProp(property)
              ? isTextType(property)
                ? property.allowedValuesText
                : isNumberType(property)
                  ? property.allowedValuesNumber
                  : null
              : null;
          },
          valueFormatter({
            value,
          }: {
            value: Props2AllowedValuesText[] | Props2AllowedValuesNumber[];
          }) {
            return value && value.length > 0
              ? value
                  .map(v =>
                    "allowedText" in v
                      ? v.allowedText
                      : "allowedNumber" in v
                        ? v.allowedNumber
                        : ""
                  )
                  .join(", ")
              : null;
          },
        },
        {
          field: "prompt",
          headerName: t("Prompt", {
            ns: "QuoteItem",
          }),
          sortable: false,
          groupable: false,
          flex: 2,
          valueGetter({ row: property, rowNode }) {
            return isNonComputedProp(property) ? property.prompt : null;
          },
        },
        {
          field: "askWhom",
          headerName: t("Ask whom", {
            ns: "QuoteItem",
          }),
          sortable: false,
          groupable: false,
          flex: 2,
          valueGetter({ row: property, rowNode }) {
            return isNonComputedProp(property) ? property.askWhom : null;
          },
          valueFormatter({ value }) {
            return value
              ? value.map((i: Props2AskWhom) => askWhomLabels[i]).join(", ")
              : null;
          },
        },
        {
          field: "askWhen",
          headerName: t("Ask when", {
            ns: "QuoteItem",
          }),
          sortable: false,
          groupable: false,
          flex: 2,
          valueGetter({ row: property, rowNode }) {
            return isNonComputedProp(property) ? property.askWhen : null;
          },
          valueFormatter({ value }) {
            return value
              ? value.map((i: Props2AskWhen) => askWhenLabels[i]).join(", ")
              : "";
          },
        },
        {
          field: "group",
          headerName: t("Group", { ns: "QuoteItem" }),
          sortable: false,
          groupable: false,
          // hideable: true,
          flex: 1,
          valueGetter({ row: property, rowNode }) {
            return property.group;
          },
        },
        {
          field: "askIfExpr",
          headerName: t("Ask if", { ns: "QuoteItem" }),
          sortable: false,
          groupable: false,
          flex: 0,
          // minWidth: 34,
          valueGetter({ row: property, rowNode }) {
            return isNonComputedProp(property) && Boolean(property.askIfExpr);
          },
          renderCell({ row: property, rowNode }) {
            return isNonComputedProp(property) && property.askIfExpr ? (
              <FunctionsIcon color="secondary" />
            ) : null;
          },
        },
        {
          field: "visibility",
          headerName: t("Visibility", { ns: "Global" }),
          sortable: false,
          groupable: false,
          flex: 0,
          // minWidth: 34,
          valueGetter({ row: property, rowNode }) {
            return property.clientVisibility;
          },
          renderCell({ row: property, rowNode }) {
            return (
              <VisibilityIndicatorIcon isVisible={property.clientVisibility} />
            );
          },
        },
        {
          field: "edit",
          headerName: "",
          sortable: false,
          groupable: false,
          flex: 0,
          minWidth: 34,
          maxWidth: 34,
          renderCell({ row: property, rowNode }) {
            const templateTypeProp = templateTypeProps.find(
              ttp => ttp.key === property.key
            );
            const handlePropChange = async (
              changedProperty: DefineItemProps2Entry
            ) => {
              const oldGroup = property.group;
              const newGroup = getPropInputGroup(changedProperty);

              if (oldGroup === newGroup) {
                await defineProperties(
                  ungroupProperties(groups).map(p =>
                    p.key === property.key
                      ? changedProperty
                      : propToDefineInput(p)
                  )
                );
              } else if (oldGroup !== newGroup) {
                const newGroupIndex = findIndex(
                  groups,
                  g => g.key === newGroup
                );
                const sortedPropertiesInput = groups
                  .map(group => {
                    if (group.key === oldGroup) {
                      // remove from old group
                      return group.properties
                        .map(p =>
                          p.key === property.key ? null : propToDefineInput(p)
                        )
                        .filter(notNull);
                    }
                    if (group.key === newGroup) {
                      // add to new group
                      return [
                        ...group.properties.map(propToDefineInput),
                        changedProperty,
                      ];
                    }
                    return group.properties.map(propToDefineInput);
                  })
                  .flat(1);
                if (newGroupIndex < 0) {
                  sortedPropertiesInput.push(changedProperty);
                }
                await defineProperties(sortedPropertiesInput);
              }
            };
            return templateTypeProp ? (
              <ModalOpenButton
                Modal={PropertyEditWithTemplateTypeModal}
                modalProps={{
                  projectId,
                  docId,
                  itemId,
                  property,
                  templateTypeProperty: templateTypeProp,
                  handleComplete: handlePropChange,
                }}
              >
                <IconButton
                  color="primary"
                  size={"small"}
                  sx={{ marginLeft: "-10px" }}
                >
                  <EditIcon />
                </IconButton>
              </ModalOpenButton>
            ) : (
              <ModalOpenButton
                Modal={PropertyEditModal}
                modalProps={{
                  projectId,
                  docId,
                  itemId,
                  property,
                  handleComplete: handlePropChange,
                }}
              >
                <IconButton
                  color="primary"
                  size={"small"}
                  sx={{ marginLeft: "-10px" }}
                >
                  <EditIcon />
                </IconButton>
              </ModalOpenButton>
            );
          },
        },
        {
          field: "delete",
          headerName: "",
          sortable: false,
          groupable: false,
          flex: 0,
          minWidth: 34,
          maxWidth: 34,
          renderCell({ row: property, rowNode }) {
            const templateTypeProp = templateTypeProps.find(
              ttp => ttp.key === property.key
            );
            if (templateTypeProp) return null;
            return (
              <ModalOpenButton
                Modal={ConfirmModal}
                modalProps={{
                  async handleConfirm() {
                    await defineProperties(
                      ungroupProperties(groups)
                        .filter(prop => prop !== property)
                        .map(propToDefineInput)
                    );
                  },
                }}
              >
                <IconButton
                  color="primary"
                  size={"small"}
                  sx={{ marginLeft: "-10px" }}
                >
                  <DeleteIcon />
                </IconButton>
              </ModalOpenButton>
            );
          },
        },
      ],
      [
        t,
        getUnitLabel,
        getBoolLabel,
        askWhomLabels,
        askWhenLabels,
        templateTypeProps,
        projectId,
        docId,
        itemId,
        defineProperties,
        groups,
      ]
    );

    const [loading, setLoading] = React.useState<boolean>(false);
    const handleRowOrderChange = React.useCallback(
      async (params: GridRowOrderChangeParams) => {
        setLoading(true);
        try {
          const propertiesCloned = [...(groups[groupIndex]?.properties ?? [])];
          const prop = propertiesCloned.splice(params.oldIndex, 1)[0];
          propertiesCloned.splice(params.targetIndex, 0, prop);
          await defineProperties(
            groups
              .map((g, index) =>
                groupIndex === index ? propertiesCloned : g.properties
              )
              .flat(1)
              .map(propToDefineInput)
          );
        } finally {
          setLoading(false);
        }
      },
      [defineProperties, groupIndex, groups]
    );

    return (
      <DataGrid<Props2>
        loading={loading}
        stateStore={stateStore}
        columns={columns}
        // disableColumnMenu
        disableColumnFilter
        disableVirtualization
        disableRowGrouping={true}
        rows={groups[groupIndex]?.properties ?? []}
        getRowId={row => row.key}
        paginationMode="client"
        sortingMode="client"
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={selectedProperties}
        onRowSelectionModelChange={setSelectedProperties}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
      />
    );
  }
);

function DraggableGroupCard({
  groupKey,
  onCardMove,
  onCardDropped,
  children,
}: React.PropsWithChildren<{
  groupKey: string;
  onCardMove(dragKey: string, hoverKey: string): void;
  onCardDropped(didDrop: boolean): void | Promise<void>;
}>) {
  const { t } = useTranslate(["Global", "QuoteItem"]);

  const stateRef = useLatest({ onCardMove, onCardDropped });

  const [, drop] = useDrop<
    { groupKey: string; type: string },
    void,
    { isDragging: boolean }
  >({
    accept: "card",
    canDrop: () => false,
    hover({ groupKey: draggedGroupKey }, monitor) {
      if (draggedGroupKey !== groupKey) {
        stateRef.current.onCardMove(draggedGroupKey, groupKey);
      }
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag<
    { groupKey: string; type: string },
    void,
    { isDragging: boolean }
  >({
    item: { type: "card", groupKey },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const didDrop = monitor.didDrop();
      stateRef.current.onCardDropped(didDrop);
    },
  });

  return (
    <Stack
      direction="column"
      spacing={1}
      key={`group-${groupKey}`}
      sx={{ opacity: isDragging ? 0.25 : 1, userSelect: "none" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        ref={node => dragPreview(drop(node as HTMLDivElement | null))}
      >
        <Box
          display="flex"
          ref={node => drag(node as HTMLDivElement | null)}
          sx={{ cursor: "move" }}
        >
          <DragIndicatorIcon color="action" />
        </Box>
        <Typography variant="h3">
          {groupKey || t("Non-grouped", { ns: "QuoteItem" })}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
}
