/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { CrmPersonListItemFragmentDoc } from '../../features/crm-persons/components/CrmPersonListItem.generated.js';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../features/attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmUsersTableQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sorting?: Types.InputMaybe<Array<Types.OrganisationCrmUsersSorting>>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterByTagsAll?: Types.InputMaybe<Array<Types.Scalars['String']['input']>>;
  filterByTagsAny?: Types.InputMaybe<Array<Types.Scalars['String']['input']>>;
  filterByCustomFields?: Types.InputMaybe<Array<Types.CustomFieldFilter>>;
}>;


export type CrmUsersTableQuery = { crmPersons: { __typename: 'CrmPersonConnection', totalCount: number, edges: Array<{ __typename: 'CrmPersonEdge', node: { __typename: 'CrmPerson', id: string, email: any, fullname: string, firstname: string, familyname: string, website?: string | null, phones: Array<{ __typename: 'Phone', id: string, number: string, main: boolean, type: Types.PhoneType }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> } | { __typename: 'MissingPermissions' } };

export type CrmUserRowFragment = { __typename: 'CrmPerson', id: string, email: any, fullname: string, firstname: string, familyname: string, website?: string | null, phones: Array<{ __typename: 'Phone', id: string, number: string, main: boolean, type: Types.PhoneType }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export const CrmUserRowFragmentDoc = gql`
    fragment CrmUserRow on CrmPerson {
  id
  email
  phones {
    id
    number
  }
  avatar {
    ...AttachmentSnapshot
  }
  fullname
  firstname
  familyname
  email
  phones {
    id
    main
    type
    number
  }
  website
}
    ${AttachmentSnapshotFragmentDoc}`;
export const CrmUsersTableDocument = gql`
    query CrmUsersTable($limit: Int!, $offset: Int, $sorting: [OrganisationCrmUsersSorting!], $searchTerm: String, $filterByTagsAll: [String!], $filterByTagsAny: [String!], $filterByCustomFields: [CustomFieldFilter!]) {
  crmPersons(
    offset: $offset
    limit: $limit
    sorting: $sorting
    filters: {tagsAll: $filterByTagsAll, tagsAny: $filterByTagsAny, customFields: $filterByCustomFields}
    search: $searchTerm
  ) {
    ... on CrmPersonConnection {
      totalCount
      edges {
        node {
          id
          ...CrmUserRow
          ...CrmPersonListItem
        }
      }
    }
  }
}
    ${CrmUserRowFragmentDoc}
${CrmPersonListItemFragmentDoc}`;

/**
 * __useCrmUsersTableQuery__
 *
 * To run a query within a React component, call `useCrmUsersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmUsersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmUsersTableQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sorting: // value for 'sorting'
 *      searchTerm: // value for 'searchTerm'
 *      filterByTagsAll: // value for 'filterByTagsAll'
 *      filterByTagsAny: // value for 'filterByTagsAny'
 *      filterByCustomFields: // value for 'filterByCustomFields'
 *   },
 * });
 */
export function useCrmUsersTableQuery(baseOptions: Apollo.QueryHookOptions<CrmUsersTableQuery, CrmUsersTableQueryVariables> & ({ variables: CrmUsersTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmUsersTableQuery, CrmUsersTableQueryVariables>(CrmUsersTableDocument, options);
      }
export function useCrmUsersTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmUsersTableQuery, CrmUsersTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmUsersTableQuery, CrmUsersTableQueryVariables>(CrmUsersTableDocument, options);
        }
export function useCrmUsersTableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CrmUsersTableQuery, CrmUsersTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmUsersTableQuery, CrmUsersTableQueryVariables>(CrmUsersTableDocument, options);
        }
export type CrmUsersTableQueryHookResult = ReturnType<typeof useCrmUsersTableQuery>;
export type CrmUsersTableLazyQueryHookResult = ReturnType<typeof useCrmUsersTableLazyQuery>;
export type CrmUsersTableSuspenseQueryHookResult = ReturnType<typeof useCrmUsersTableSuspenseQuery>;
export type CrmUsersTableQueryResult = Apollo.QueryResult<CrmUsersTableQuery, CrmUsersTableQueryVariables>;