/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { Props2AllFragmentDoc, Prop2RelatedItemFieldsFragmentDoc, ItemAttributeExpressionFieldsFragmentDoc, Props2MappingAllFragmentDoc, Props2NonComputedAllFragmentDoc, ItemProps2FragmentDoc, ItemAttributeExpressionsFragmentDoc } from '../../doc-items/properties.generated.js';
export type PropertyMappingSection_Properties_Props2Bool_Fragment = { __typename: 'Props2Bool', key: string, label: string, valueBool?: boolean | null, essential: boolean, group: string, clientVisibility: boolean, prompt: string, askWhen: Array<Types.Props2AskWhen>, askWhom: Array<Types.Props2AskWhom>, askIfExpr: string };

export type PropertyMappingSection_Properties_Props2BoolComputed_Fragment = { __typename: 'Props2BoolComputed', key: string, label: string, expr: string, valueBoolComputed?: boolean | null, missingValue: boolean, group: string, clientVisibility: boolean };

export type PropertyMappingSection_Properties_Props2Number_Fragment = { __typename: 'Props2Number', key: string, label: string, valueNumber?: number | null, unit?: string | null, essential: boolean, group: string, clientVisibility: boolean, prompt: string, askWhen: Array<Types.Props2AskWhen>, askWhom: Array<Types.Props2AskWhom>, askIfExpr: string, range?: { __typename: 'Props2NumberRange', min?: number | null, max?: number | null } | null, allowedValuesNumber: Array<{ __typename: 'Props2AllowedValuesNumber', allowedNumber: number, media?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null }> };

export type PropertyMappingSection_Properties_Props2NumberArray_Fragment = { __typename: 'Props2NumberArray', key: string, label: string, valueNumberArray?: Array<number> | null, unit?: string | null, essential: boolean, group: string, clientVisibility: boolean, prompt: string, askWhen: Array<Types.Props2AskWhen>, askWhom: Array<Types.Props2AskWhom>, askIfExpr: string, range?: { __typename: 'Props2NumberRange', min?: number | null, max?: number | null } | null, allowedValuesNumber: Array<{ __typename: 'Props2AllowedValuesNumber', allowedNumber: number, media?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null }> };

export type PropertyMappingSection_Properties_Props2NumberArrayComputed_Fragment = { __typename: 'Props2NumberArrayComputed', key: string, label: string, expr: string, unit?: string | null, valueNumberArrayComputed?: Array<number> | null, missingValue: boolean, group: string, clientVisibility: boolean };

export type PropertyMappingSection_Properties_Props2NumberComputed_Fragment = { __typename: 'Props2NumberComputed', key: string, label: string, expr: string, unit?: string | null, valueNumberComputed?: number | null, missingValue: boolean, group: string, clientVisibility: boolean };

export type PropertyMappingSection_Properties_Props2Text_Fragment = { __typename: 'Props2Text', key: string, label: string, valueText?: string | null, essential: boolean, group: string, clientVisibility: boolean, prompt: string, askWhen: Array<Types.Props2AskWhen>, askWhom: Array<Types.Props2AskWhom>, askIfExpr: string, allowedValuesText: Array<{ __typename: 'Props2AllowedValuesText', allowedText: string, media?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null }> };

export type PropertyMappingSection_Properties_Props2TextArray_Fragment = { __typename: 'Props2TextArray', key: string, label: string, valueTextArray?: Array<string> | null, essential: boolean, group: string, clientVisibility: boolean, prompt: string, askWhen: Array<Types.Props2AskWhen>, askWhom: Array<Types.Props2AskWhom>, askIfExpr: string, allowedValuesText: Array<{ __typename: 'Props2AllowedValuesText', allowedText: string, media?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null }> };

export type PropertyMappingSection_Properties_Props2TextArrayComputed_Fragment = { __typename: 'Props2TextArrayComputed', key: string, label: string, expr: string, valueTextArrayComputed?: Array<string> | null, missingValue: boolean, group: string, clientVisibility: boolean };

export type PropertyMappingSection_Properties_Props2TextComputed_Fragment = { __typename: 'Props2TextComputed', key: string, label: string, expr: string, valueTextComputed?: string | null, missingValue: boolean, group: string, clientVisibility: boolean };

export type PropertyMappingSection_PropertiesFragment = PropertyMappingSection_Properties_Props2Bool_Fragment | PropertyMappingSection_Properties_Props2BoolComputed_Fragment | PropertyMappingSection_Properties_Props2Number_Fragment | PropertyMappingSection_Properties_Props2NumberArray_Fragment | PropertyMappingSection_Properties_Props2NumberArrayComputed_Fragment | PropertyMappingSection_Properties_Props2NumberComputed_Fragment | PropertyMappingSection_Properties_Props2Text_Fragment | PropertyMappingSection_Properties_Props2TextArray_Fragment | PropertyMappingSection_Properties_Props2TextArrayComputed_Fragment | PropertyMappingSection_Properties_Props2TextComputed_Fragment;

export type PropertyMappingSection_MappingsFragment = { __typename: 'PropertyMapping', from: { __typename: 'PropertyMappingFrom', expr: string }, to: { __typename: 'PropertyMappingTo', key: string } };

export const PropertyMappingSection_PropertiesFragmentDoc = gql`
    fragment PropertyMappingSection_Properties on Props2 {
  ...Props2All
}
    ${Props2AllFragmentDoc}`;
export const PropertyMappingSection_MappingsFragmentDoc = gql`
    fragment PropertyMappingSection_Mappings on PropertyMapping {
  ...Props2MappingAll
}
    ${Props2MappingAllFragmentDoc}`;