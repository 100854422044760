/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModifyProjectMemberModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ModifyProjectMemberModalQuery = { viewer?: { __typename: 'Viewer', id: string, organisation: { __typename: 'ViewerOrganisation', id: string } } | null, project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, fullname: string, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, roles: Array<{ __typename: 'ProjectRole', id: string, label: string, internalName: string }> }>, roles: Array<{ __typename: 'ProjectRole', id: string, label: string, internalName: string }> } | null } };

export type ModifyProjectMembershipMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  membershipId: Types.Scalars['ID']['input'];
  roleIds: Array<Types.Scalars['ID']['input']>;
}>;


export type ModifyProjectMembershipMutation = { modifyProjectMembership: { __typename: 'ModifyProjectMembershipResult', project: { __typename: 'Project', id: string } } };

export type RemoveProjectMembershipMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  membershipId: Types.Scalars['ID']['input'];
}>;


export type RemoveProjectMembershipMutation = { removeProjectMembership: { __typename: 'RemoveProjectMembershipResult', project: { __typename: 'Project', id: string } } };


export const ModifyProjectMemberModalDocument = gql`
    query ModifyProjectMemberModal($projectId: ID!) {
  viewer {
    id
    organisation {
      id
    }
  }
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        internalStakeholders {
          id
          user {
            id
            fullname
            firstname
            familyname
            avatar {
              ...AttachmentSnapshot
            }
          }
          roles {
            id
            label
            internalName
          }
        }
        roles {
          id
          label
          internalName
        }
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;

/**
 * __useModifyProjectMemberModalQuery__
 *
 * To run a query within a React component, call `useModifyProjectMemberModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useModifyProjectMemberModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModifyProjectMemberModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useModifyProjectMemberModalQuery(baseOptions: Apollo.QueryHookOptions<ModifyProjectMemberModalQuery, ModifyProjectMemberModalQueryVariables> & ({ variables: ModifyProjectMemberModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModifyProjectMemberModalQuery, ModifyProjectMemberModalQueryVariables>(ModifyProjectMemberModalDocument, options);
      }
export function useModifyProjectMemberModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModifyProjectMemberModalQuery, ModifyProjectMemberModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModifyProjectMemberModalQuery, ModifyProjectMemberModalQueryVariables>(ModifyProjectMemberModalDocument, options);
        }
export function useModifyProjectMemberModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ModifyProjectMemberModalQuery, ModifyProjectMemberModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ModifyProjectMemberModalQuery, ModifyProjectMemberModalQueryVariables>(ModifyProjectMemberModalDocument, options);
        }
export type ModifyProjectMemberModalQueryHookResult = ReturnType<typeof useModifyProjectMemberModalQuery>;
export type ModifyProjectMemberModalLazyQueryHookResult = ReturnType<typeof useModifyProjectMemberModalLazyQuery>;
export type ModifyProjectMemberModalSuspenseQueryHookResult = ReturnType<typeof useModifyProjectMemberModalSuspenseQuery>;
export type ModifyProjectMemberModalQueryResult = Apollo.QueryResult<ModifyProjectMemberModalQuery, ModifyProjectMemberModalQueryVariables>;
export const ModifyProjectMembershipDocument = gql`
    mutation modifyProjectMembership($projectId: ID!, $membershipId: ID!, $roleIds: [ID!]!) {
  modifyProjectMembership(
    input: {membershipId: $membershipId, projectId: $projectId, roleIds: $roleIds}
  ) {
    project {
      id
    }
  }
}
    `;
export type ModifyProjectMembershipMutationFn = Apollo.MutationFunction<ModifyProjectMembershipMutation, ModifyProjectMembershipMutationVariables>;

/**
 * __useModifyProjectMembershipMutation__
 *
 * To run a mutation, you first call `useModifyProjectMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyProjectMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyProjectMembershipMutation, { data, loading, error }] = useModifyProjectMembershipMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      membershipId: // value for 'membershipId'
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useModifyProjectMembershipMutation(baseOptions?: Apollo.MutationHookOptions<ModifyProjectMembershipMutation, ModifyProjectMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyProjectMembershipMutation, ModifyProjectMembershipMutationVariables>(ModifyProjectMembershipDocument, options);
      }
export type ModifyProjectMembershipMutationHookResult = ReturnType<typeof useModifyProjectMembershipMutation>;
export type ModifyProjectMembershipMutationResult = Apollo.MutationResult<ModifyProjectMembershipMutation>;
export type ModifyProjectMembershipMutationOptions = Apollo.BaseMutationOptions<ModifyProjectMembershipMutation, ModifyProjectMembershipMutationVariables>;
export const RemoveProjectMembershipDocument = gql`
    mutation RemoveProjectMembership($projectId: ID!, $membershipId: ID!) {
  removeProjectMembership(
    input: {membershipId: $membershipId, projectId: $projectId}
  ) {
    project {
      id
    }
  }
}
    `;
export type RemoveProjectMembershipMutationFn = Apollo.MutationFunction<RemoveProjectMembershipMutation, RemoveProjectMembershipMutationVariables>;

/**
 * __useRemoveProjectMembershipMutation__
 *
 * To run a mutation, you first call `useRemoveProjectMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectMembershipMutation, { data, loading, error }] = useRemoveProjectMembershipMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useRemoveProjectMembershipMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProjectMembershipMutation, RemoveProjectMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProjectMembershipMutation, RemoveProjectMembershipMutationVariables>(RemoveProjectMembershipDocument, options);
      }
export type RemoveProjectMembershipMutationHookResult = ReturnType<typeof useRemoveProjectMembershipMutation>;
export type RemoveProjectMembershipMutationResult = Apollo.MutationResult<RemoveProjectMembershipMutation>;
export type RemoveProjectMembershipMutationOptions = Apollo.BaseMutationOptions<RemoveProjectMembershipMutation, RemoveProjectMembershipMutationVariables>;