/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated.js';
export type RequestBiddersBoxFragment = { __typename: 'Request', id: string, status: Types.RequestStatus, owningSystemOrganisationId: string, marketplace: boolean, recipients: Array<{ __typename: 'RequestRecipient', id: string, source: Types.RequestRecipientSource, recipientStatus: Types.RequestRecipientStatus, recipientSystemOrganisationId: string, recipientProfileSlug?: string | null, recipientContact: { __typename: 'Organisation', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, recipientQuotes: Array<{ __typename: 'Quote', id: string, title: string, agreement: Types.Agreement }> }> };

export type RequestRecipientFragment = { __typename: 'RequestRecipient', id: string, source: Types.RequestRecipientSource, recipientStatus: Types.RequestRecipientStatus, recipientSystemOrganisationId: string, recipientProfileSlug?: string | null, recipientContact: { __typename: 'Organisation', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, recipientQuotes: Array<{ __typename: 'Quote', id: string, title: string, agreement: Types.Agreement }> };

export type RequestBidderActionMenuFragment = { __typename: 'RequestRecipient', recipientSystemOrganisationId: string, recipientStatus: Types.RequestRecipientStatus, recipientProfileSlug?: string | null };

export const RequestBidderActionMenuFragmentDoc = gql`
    fragment RequestBidderActionMenu on RequestRecipient {
  recipientSystemOrganisationId
  recipientStatus
  recipientProfileSlug
}
    `;
export const RequestRecipientFragmentDoc = gql`
    fragment RequestRecipient on RequestRecipient {
  id
  source
  recipientStatus
  recipientSystemOrganisationId
  ...RequestBidderActionMenu
  recipientContact {
    id
    title
    logo {
      ...AttachmentSnapshot
    }
  }
  recipientQuotes {
    id
    title
    agreement
  }
}
    ${RequestBidderActionMenuFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;
export const RequestBiddersBoxFragmentDoc = gql`
    fragment RequestBiddersBox on Request {
  id
  status
  owningSystemOrganisationId
  marketplace
  recipients {
    id
    ...RequestRecipient
  }
}
    ${RequestRecipientFragmentDoc}`;