import { FirebaseRemoteConfig } from "@capacitor-firebase/remote-config";
import { Capacitor } from "@capacitor/core";
import { DEPLOY_ENV } from "../environment.js";

export const isCapacitorEnabled =
  DEPLOY_ENV !== "local" && Capacitor.isNativePlatform();

const firebaseActivatePromise: Promise<void> | null = isCapacitorEnabled
  ? FirebaseRemoteConfig.fetchAndActivate()
  : null;

const activateFirebase = async () => {
  if (firebaseActivatePromise) {
    await firebaseActivatePromise;
  }
};

export async function getCapacitorAppUrl() {
  if (isCapacitorEnabled) {
    await activateFirebase();
    const { value } = await FirebaseRemoteConfig.getString({
      key: "capacitorAppUrl",
    });
    console.log(`[Remote Config / App URL]: ${value}`);
    if (value) return value;
  }
}

export async function getIOSAppBuildNumber() {
  if (isCapacitorEnabled) {
    await activateFirebase();
    const { value } = await FirebaseRemoteConfig.getNumber({
      key: "iosAppBuildNumber",
    });
    console.log(`[Remote Config / iOS Build number]: ${value}`);
    if (value !== null && value !== undefined) return value;
  }
}

export async function getAndroidAppBuildNumber() {
  if (isCapacitorEnabled) {
    await activateFirebase();
    const { value } = await FirebaseRemoteConfig.getNumber({
      key: "androidAppBuildNumber",
    });
    console.log(`[Remote Config / Android Build number]: ${value}`);
    if (value !== null && value !== undefined) return value;
  }
}
