import { useApolloClient } from "@apollo/client";
import {
  CardContainer,
  DataGrid,
  MenuButton,
  MenuItemWithIcon,
  ModalOpenButton,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { DeleteOutlined as DeleteOutlinedIcon } from "@mui/icons-material";
import { Group as GroupIcon } from "@mui/icons-material";
import { WebOutlined as WebOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { Project, namedOperations } from "../../../../clients/graphqlTypes.js";
import { useFeature } from "../../../../common/FeatureFlags.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal.js";
import { usePaginationParams } from "../../../commons/pagination/usePaginationParams.js";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../constants.js";
import { CraftsmenSelectModal } from "../../craftsman/CraftsmenSelectModal.js";
import { CraftsmenSearchOrganisationFragment } from "../../craftsman/CraftsmenSelectModal.generated.js";
import { OrganisationAvatar } from "../../organisations/OrganisationAvatar.js";
import { QuoteItemRow } from "../../quotes/QuoteItemRow.js";
import { useDataGridStateStore } from "../../users/useDataGridStateStore.js";
import { RequestPublishToMarketplaceSwitch } from "../RequestPublishToMarketplaceSwitch.js";
import {
  RequestRecipientQuoteRejectedBadge,
  RequestRecipientQuoteSubmittedBadge,
  RequestRecipientQuoteWonBadge,
  RequestRecipientStatusBadge,
} from "../RequestRecipientStatusBadge.js";
import {
  useAddRequestBiddersMutation,
  useRemoveRequestRecipientMutation,
} from "../Requests.generated.js";
import {
  RequestBidderActionMenuFragment,
  RequestBiddersBoxFragment,
} from "./RequestBiddersBox.generated.js";

export const RequestBiddersBox = ({
  project,
  request,
}: {
  project: Pick<Project, "id" | "viewerPermissions">;
  request: RequestBiddersBoxFragment;
}) => {
  const { t } = useTranslate(["Global", "Requests", "QuoteStatus"]);
  const { enqueueSnackbar } = useSnackbar();
  const isMarketplaceEnabled = useFeature("Marketplace");

  const { paginationModel, setPaginationModel } = usePaginationParams({
    pageUrlParam: "pageBidders",
    perPageUrlParam: "pageSizeBidders",
  });

  const recipients = request.recipients ?? [];
  const recipientOrganisationIds = recipients.map(
    recipient => recipient.recipientSystemOrganisationId
  );
  const totalCount = request.recipients?.length ?? 0;

  const client = useApolloClient();
  const [addRequestBidders, { loading: addLoading }] =
    useAddRequestBiddersMutation({
      client,
      refetchQueries: [namedOperations.Query.ProjectRequest],
    });

  const handleAddBidders = useCallback(
    async (organisations: CraftsmenSearchOrganisationFragment[]) => {
      try {
        await addRequestBidders({
          variables: {
            input: {
              projectId: project.id,
              requestId: request.id,
              recipients: organisations.map(o => ({
                systemOrganisationId: o.id,
              })),
            },
          },
          refetchQueries: [namedOperations.Query.ProjectRequest],
          awaitRefetchQueries: true,
        });
        enqueueSnackbar(
          t("Bidders added", {
            ns: "Requests",
          })
        );
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [addRequestBidders, request.id, t, enqueueSnackbar, project.id]
  );

  const canEdit = request.status === "OPEN" || request.status === "PUBLISHED";

  const stateStore = useDataGridStateStore("RequestBiddersBox");

  return (
    <CardContainer
      id="request-bidders-box"
      title={t("Bidders", {
        ns: "Requests",
      })}
      Icon={<GroupIcon />}
      itemCount={totalCount}
      isInitiallyClosed={false}
      disableAutoExpand={true}
      ActionButton={
        canEdit && project ? (
          <RestrictedByProjectPermissionWithDebug
            permission="SHARE_REQUIREMENTS"
            project={project}
          >
            <ModalOpenButton
              Modal={CraftsmenSelectModal}
              modalProps={{
                id: "request-bidders-modal",
                loading: addLoading,
                projectId: project.id,
                handleComplete: handleAddBidders,
                title: t("Add bidders", {
                  ns: "Requests",
                }),
                omitOrganisationIds: recipientOrganisationIds,
              }}
            >
              <IconButton size="small" color="primary">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByProjectPermissionWithDebug>
        ) : undefined
      }
    >
      {recipients.length > 0 ? (
        <>
          <DataGrid
            stateStore={stateStore}
            sx={{ border: "none" }}
            density="standard"
            hideFooter={totalCount === 0}
            disableColumnFilter
            columns={[
              {
                field: "companyName",
                headerName: t("Craftsman company", {
                  ns: "Requests",
                }),
                flex: 1,
                minWidth: 100,
                sortable: false,
                renderCell: ({
                  row: item,
                }: {
                  row: RequestBiddersBoxFragment["recipients"][0];
                }) => (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <OrganisationAvatar
                      size="s"
                      organisationAvatar={item.recipientContact}
                    />
                    <Typography>{item.recipientContact.title}</Typography>
                  </Stack>
                ),
              },
              {
                field: "status",
                headerName: t("Status", {
                  ns: "Requests",
                }),
                flex: 1,
                minWidth: 100,
                sortable: false,
                renderCell: ({
                  row: item,
                }: {
                  row: RequestBiddersBoxFragment["recipients"][0];
                }) => {
                  const hasQuotes = item.recipientQuotes?.length > 0;
                  const isAccepted = item.recipientQuotes.some(
                    quote => quote.agreement === "YES"
                  );
                  const isRejected = item.recipientQuotes.every(
                    quote => quote.agreement === "NO"
                  );
                  return hasQuotes && isAccepted ? (
                    <RequestRecipientQuoteWonBadge />
                  ) : hasQuotes && isRejected ? (
                    <RequestRecipientQuoteRejectedBadge />
                  ) : hasQuotes ? (
                    <RequestRecipientQuoteSubmittedBadge />
                  ) : (
                    <RequestRecipientStatusBadge
                      status={item.recipientStatus}
                      source={item.source}
                    />
                  );
                },
              },
              {
                field: "recipientQuotes",
                headerName: t("Quotes", {
                  ns: "Requests",
                }),
                flex: 1,
                minWidth: 100,
                sortable: false,
                renderCell: ({
                  row: item,
                }: {
                  row: RequestBiddersBoxFragment["recipients"][0];
                }) =>
                  item.recipientQuotes.length > 0 ? (
                    <Stack direction="row" spacing={2}>
                      {item.recipientQuotes.map(quote => (
                        <QuoteItemRow
                          key={quote.id}
                          to={`/projects/${project.id}/quotes/${quote.id}`}
                          title={`${quote.title}${
                            quote.agreement === "YES"
                              ? ` (${t("Accepted", {
                                  ns: "QuoteStatus",
                                }).toLocaleUpperCase()})`
                              : quote.agreement === "NO"
                                ? ` (${t("Declined", {
                                    ns: "QuoteStatus",
                                  }).toLocaleUpperCase()})`
                                : ""
                          }`}
                        />
                      ))}
                    </Stack>
                  ) : (
                    <Typography
                      sx={{ color: theme => theme.palette.grey[400] }}
                    >
                      {t("No submission", {
                        ns: "Requests",
                      })}
                    </Typography>
                  ),
              },
              {
                field: "actions",
                headerName: "",
                flex: 0.1,
                minWidth: 50,
                sortable: false,
                renderCell: ({
                  row: item,
                }: {
                  row: RequestBiddersBoxFragment["recipients"][0];
                }) => {
                  return (
                    <RecipientActionsMenu
                      requestId={request.id}
                      recipient={item}
                      project={project}
                    />
                  );
                },
              },
            ]}
            rows={recipients}
            paginationMode="client"
            paginationModel={paginationModel}
            onPaginationModelChange={newPaginationModel => {
              setPaginationModel(newPaginationModel);
            }}
            pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
            autoHeight={true}
          />
        </>
      ) : (
        <Stack p={1} direction="column" spacing={1}>
          <Stack direction="row" spacing={1} justifyContent="center" my={1}>
            <Typography
              variant="caption"
              align="center"
              sx={{ color: theme => theme.palette.grey[400] }}
            >
              {t("You have not added any bidders yet.", {
                ns: "Requests",
              })}
            </Typography>
          </Stack>
          <RestrictedByProjectPermissionWithDebug
            permission="SHARE_REQUIREMENTS"
            project={project}
          >
            <Stack direction="column" spacing={1} justifyContent="center">
              <ModalOpenButton
                Modal={CraftsmenSelectModal}
                modalProps={{
                  loading: addLoading,
                  projectId: project.id,
                  handleComplete: handleAddBidders,
                  title: t("Add bidders", {
                    ns: "Requests",
                  }),
                  omitOrganisationIds: recipientOrganisationIds,
                }}
              >
                <Button
                  color="secondary"
                  variant="text"
                  startIcon={<AddIcon />}
                  disabled={!canEdit}
                >
                  {t("Add bidders", {
                    ns: "Requests",
                  })}
                </Button>
              </ModalOpenButton>
            </Stack>
          </RestrictedByProjectPermissionWithDebug>
        </Stack>
      )}
      {project && isMarketplaceEnabled && (
        <RestrictedByProjectPermissionWithDebug
          permission="SHARE_REQUIREMENTS"
          project={project}
        >
          <>
            <Divider />
            <Box p={1} px={2}>
              <RequestPublishToMarketplaceSwitch
                projectId={project.id}
                requestId={request.id}
                marketplace={request.marketplace}
                canEdit={canEdit}
              />
            </Box>
          </>
        </RestrictedByProjectPermissionWithDebug>
      )}
    </CardContainer>
  );
};

function RecipientActionsMenu({
  requestId,
  recipient,
  project,
}: {
  requestId: string;
  recipient: RequestBidderActionMenuFragment;
  project: Pick<Project, "id" | "viewerPermissions">;
}) {
  const { t } = useTranslate("Requests");

  const client = useApolloClient();
  const [removeRequestRecipient] = useRemoveRequestRecipientMutation({
    client,
    refetchQueries: [namedOperations.Query.ProjectRequest],
  });

  return (
    <MenuButton>
      <RestrictedByProjectPermissionWithDebug
        permission="SHARE_REQUIREMENTS"
        project={project}
      >
        {recipient.recipientStatus === "INITIAL" && (
          <ModalOpenButton
            Modal={ConfirmModal}
            modalProps={{
              title: t("Are you sure you want to remove this bidder?"),
              handleConfirm: () => {
                removeRequestRecipient({
                  variables: {
                    input: {
                      projectId: project.id,
                      requestId,
                      recipientSystemOrganisationId:
                        recipient.recipientSystemOrganisationId,
                    },
                  },
                });
              },
            }}
          >
            <MenuItemWithIcon icon={<DeleteOutlinedIcon />}>
              {t("Remove bidder")}
            </MenuItemWithIcon>
          </ModalOpenButton>
        )}
      </RestrictedByProjectPermissionWithDebug>
      {recipient.recipientProfileSlug && (
        <MenuItemWithIcon
          icon={<WebOutlinedIcon />}
          component={Link}
          to={`/org/${recipient.recipientProfileSlug}`}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          {t("View profile")}
        </MenuItemWithIcon>
      )}
    </MenuButton>
  );
}
