/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestPimCreateThreedModelUploadUrlMutationVariables = Types.Exact<{
  input: Types.RequestPimCreateThreedModelUploadUrlInput;
}>;


export type RequestPimCreateThreedModelUploadUrlMutation = { requestPimCreateThreedModelUploadUrl: { __typename: 'RequestUploadUrlResult', uploadUrl: string } };


export const RequestPimCreateThreedModelUploadUrlDocument = gql`
    mutation RequestPimCreateThreedModelUploadUrl($input: RequestPimCreateThreedModelUploadUrlInput!) {
  requestPimCreateThreedModelUploadUrl(input: $input) {
    uploadUrl
  }
}
    `;
export type RequestPimCreateThreedModelUploadUrlMutationFn = Apollo.MutationFunction<RequestPimCreateThreedModelUploadUrlMutation, RequestPimCreateThreedModelUploadUrlMutationVariables>;

/**
 * __useRequestPimCreateThreedModelUploadUrlMutation__
 *
 * To run a mutation, you first call `useRequestPimCreateThreedModelUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPimCreateThreedModelUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPimCreateThreedModelUploadUrlMutation, { data, loading, error }] = useRequestPimCreateThreedModelUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPimCreateThreedModelUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<RequestPimCreateThreedModelUploadUrlMutation, RequestPimCreateThreedModelUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPimCreateThreedModelUploadUrlMutation, RequestPimCreateThreedModelUploadUrlMutationVariables>(RequestPimCreateThreedModelUploadUrlDocument, options);
      }
export type RequestPimCreateThreedModelUploadUrlMutationHookResult = ReturnType<typeof useRequestPimCreateThreedModelUploadUrlMutation>;
export type RequestPimCreateThreedModelUploadUrlMutationResult = Apollo.MutationResult<RequestPimCreateThreedModelUploadUrlMutation>;
export type RequestPimCreateThreedModelUploadUrlMutationOptions = Apollo.BaseMutationOptions<RequestPimCreateThreedModelUploadUrlMutation, RequestPimCreateThreedModelUploadUrlMutationVariables>;