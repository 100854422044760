/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { RequestStatusBadgeFragmentDoc } from '../RequestStatusBadge.generated.js';
export type RequestViewButtonFragment = { __typename: 'Requirement', id: string, requirementRequestWonId?: string | null, outgoingRequests: Array<{ __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string }> };

export const RequestViewButtonFragmentDoc = gql`
    fragment RequestViewButton on Requirement {
  id
  requirementRequestWonId
  outgoingRequests {
    id
    title
    ...RequestStatusBadge
  }
}
    ${RequestStatusBadgeFragmentDoc}`;