import { CardContainer } from "@msys/ui";
import { RateReview as RateReviewIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Channel } from "../../channels/Channel.js";
import { ProjectCommentsBox_ProjectFragment } from "./ProjectOverviewCommentsBox.generated.js";

interface Props {
  project: ProjectCommentsBox_ProjectFragment;
}

export const ProjectCommentsBox = ({ project }: Props) => {
  const { t } = useTranslate("Channel");

  const channelId = project.channels[0]?.id;

  return (
    <CardContainer
      Icon={<RateReviewIcon />}
      title={t("Internal comments")}
      isExpandable
      isInitiallyClosed={false}
    >
      {channelId && (
        <Box
          sx={{
            height: {
              xs: "360px",
              md: "400px",
            },
          }}
          display="flex"
          flexDirection="column"
        >
          <Channel
            channelId={channelId}
            inputPlaceholder={t("Add comment...")}
            loadPreviousMessagesLabel={t("Load previous comments")}
            noMessagesTitle={t(
              "Welcome to the project, you can write your team internal comments here"
            )}
            layout="COMMENTS"
          />
        </Box>
      )}
    </CardContainer>
  );
};
