import { useApolloClient } from "@apollo/client";
import { assertNever, getDataOrNull } from "@msys/common";
import { MenuButton, MenuItemWithIcon } from "@msys/ui";
import { GetApp as DownloadIcon } from "@mui/icons-material";
import { CircularProgress, Divider } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useOpenFile } from "../../../commons/hooks/useOpenFile.js";
import {
  DownloadQuoteAsPdfButton_QuoteFragment,
  GenerateQuoteContractingPdfDocument,
  GenerateQuoteContractingPdfQuery,
  GenerateQuoteContractingPdfQueryVariables,
  GenerateQuotePdfDocument,
  GenerateQuotePdfQuery,
  GenerateQuotePdfQueryVariables,
  GenerateQuoteSexyPdfDocument,
  GenerateQuoteSexyPdfQuery,
  GenerateQuoteSexyPdfQueryVariables,
  useDownloadQuoteAsPdfButtonQuery,
} from "./DownloadQuoteAsPdfIconButton.generated.js";

export const DownloadQuoteAsPdfIconButton = ({
  docId,
  projectId,
  doc,
}: {
  docId: string;
  projectId: string;
  doc: DownloadQuoteAsPdfButton_QuoteFragment;
}) => {
  const { t } = useTranslate(["Quote"]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingPdf, setLoadingPdf] = React.useState<null | string>(null);
  const { openPdf } = useOpenFile();

  const client = useApolloClient();
  const query = useDownloadQuoteAsPdfButtonQuery({
    client,
  });

  const handleClick =
    (
      args:
        | { templateType: "none"; calculationType: "agreed" | "proposed" }
        | {
            templateType: "contracting";
            calculationType: "agreed" | "proposed";
            contractingPdfId: string;
          }
        | {
            templateType: "sexy";
            calculationType: "proposed";
            pdfTemplateUrl: string;
          }
    ) =>
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (loadingPdf) return;

      try {
        switch (args.templateType) {
          case "none": {
            const { templateType, calculationType } = args;

            const key = `${templateType}-${calculationType}`;

            setLoadingPdf(key);

            const { data } = await client.query<
              GenerateQuotePdfQuery,
              GenerateQuotePdfQueryVariables
            >({
              query: GenerateQuotePdfDocument,
              variables: {
                projectId,
                quoteId: docId,
                calculationType,
              },
            });
            const url = getDataOrNull(data?.quoteGeneratePdf)?.url;
            if (!url) throw new Error("Failed to generate PDF");
            openPdf(url, `Quote_${docId}`);

            break;
          }
          case "contracting": {
            const { templateType, calculationType, contractingPdfId } = args;

            const key = `${templateType}-${calculationType}-${contractingPdfId}`;

            setLoadingPdf(key);

            const { data } = await client.query<
              GenerateQuoteContractingPdfQuery,
              GenerateQuoteContractingPdfQueryVariables
            >({
              query: GenerateQuoteContractingPdfDocument,
              variables: {
                projectId,
                quoteId: docId,
                contractingPdfId,
                calculationType,
              },
            });
            const url = getDataOrNull(data?.quoteGenerateContractingPdf)?.url;
            if (!url) throw new Error("Failed to generate PDF");
            openPdf(url, `Quote_${docId}`);

            break;
          }
          case "sexy": {
            const { templateType, calculationType, pdfTemplateUrl } = args;

            const key = `${templateType}-${calculationType}-${pdfTemplateUrl}`;

            setLoadingPdf(key);

            const { data } = await client.query<
              GenerateQuoteSexyPdfQuery,
              GenerateQuoteSexyPdfQueryVariables
            >({
              query: GenerateQuoteSexyPdfDocument,
              variables: {
                projectId,
                quoteId: docId,
                pdfTemplateUrl,
              },
            });
            const url = getDataOrNull(data?.quoteGenerateSexyPdf)?.url;
            if (!url) throw new Error("Failed to generate PDF");
            openPdf(url, `Quote_${docId}`);

            break;
          }
          default:
            assertNever(args);
        }
      } catch (error) {
        if (error instanceof Error)
          enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingPdf(null);
      }
    };

  const contractingPdfs =
    getDataOrNull(query.data?.organisationContractingPdfs)?.contractingPdfs ??
    [];
  const sexyQuotePdfTemplates = query.data?.organisationSexyQuotesConfig
    ? (getDataOrNull(query.data?.organisationSexyQuotesConfig)?.pdfTemplates ??
      [])
    : [];
  const hasSelectedCalculations = doc.agreedCalculation;
  const hasContractingTemplates = contractingPdfs.length > 0;
  const templateOriginQuoteId = doc.templateOriginQuoteId;
  const hasSexyQuotePdfTemplates =
    templateOriginQuoteId &&
    sexyQuotePdfTemplates.some(e =>
      e.templateIds.includes(templateOriginQuoteId)
    );

  return (
    <MenuButton
      Icon={
        loadingPdf ? (
          <CircularProgress size="1.5rem" color={"primary"} />
        ) : (
          <DownloadIcon />
        )
      }
      tooltip={t("Download PDF")}
    >
      {hasSelectedCalculations && (
        <MenuItemWithIcon
          icon={
            loadingPdf === "none-agreed" ? (
              <CircularProgress size="1.5rem" color={"primary"} />
            ) : (
              <DownloadIcon />
            )
          }
          onClick={handleClick({
            templateType: "none",
            calculationType: "agreed",
          })}
          disabled={loadingPdf === "none-agreed"}
        >
          {t("Download PDF (agreed)")}
        </MenuItemWithIcon>
      )}
      <MenuItemWithIcon
        icon={
          loadingPdf === "none-proposed" ? (
            <CircularProgress size={24} color={"primary"} />
          ) : (
            <DownloadIcon />
          )
        }
        onClick={handleClick({
          templateType: "none",
          calculationType: "proposed",
        })}
        disabled={loadingPdf === "none-proposed"}
      >
        {hasSelectedCalculations
          ? t("Download PDF (proposed)")
          : t("Download PDF")}
      </MenuItemWithIcon>
      {hasContractingTemplates && [
        <Divider key="divider" />,
        ...contractingPdfs.flatMap(contractingPdf =>
          [
            hasSelectedCalculations && (
              <MenuItemWithIcon
                key={`${contractingPdf.id}-agreed`}
                icon={
                  loadingPdf === `contracting-agreed-${contractingPdf.id}` ? (
                    <CircularProgress size="1.5rem" color={"primary"} />
                  ) : (
                    <DownloadIcon />
                  )
                }
                onClick={handleClick({
                  templateType: "contracting",
                  calculationType: "agreed",
                  contractingPdfId: contractingPdf.id,
                })}
                disabled={
                  loadingPdf === `contracting-agreed-${contractingPdf.id}`
                }
              >
                {t("Download PDF: {title} (agreed)", {
                  ns: "Quote",
                  title: contractingPdf.title,
                })}
              </MenuItemWithIcon>
            ),
            <MenuItemWithIcon
              key={`${contractingPdf.id}-propsed`}
              icon={
                loadingPdf === `contracting-proposed-${contractingPdf.id}` ? (
                  <CircularProgress size={24} color={"primary"} />
                ) : (
                  <DownloadIcon />
                )
              }
              onClick={handleClick({
                templateType: "contracting",
                calculationType: "proposed",
                contractingPdfId: contractingPdf.id,
              })}
              disabled={
                loadingPdf === `contracting-proposed-${contractingPdf.id}`
              }
            >
              {hasSelectedCalculations
                ? t("Download PDF: {title} (proposed)", {
                    ns: "Quote",
                    title: contractingPdf.title,
                  })
                : t("Download PDF: {title}", {
                    ns: "Quote",
                    title: contractingPdf.title,
                  })}
            </MenuItemWithIcon>,
          ].filter(value => value)
        ),
      ]}
      {hasSexyQuotePdfTemplates &&
        templateOriginQuoteId && [
          <Divider key="divider" />,
          ...sexyQuotePdfTemplates.flatMap(pdfTemplate =>
            pdfTemplate.templateIds.includes(templateOriginQuoteId)
              ? [
                  <MenuItemWithIcon
                    icon={
                      loadingPdf ===
                      `sexy-proposed-${pdfTemplate.pdfTemplateUrl}` ? (
                        <CircularProgress size={24} color={"primary"} />
                      ) : (
                        <DownloadIcon />
                      )
                    }
                    onClick={handleClick({
                      templateType: "sexy",
                      calculationType: "proposed",
                      pdfTemplateUrl: pdfTemplate.pdfTemplateUrl,
                    })}
                    disabled={
                      loadingPdf ===
                      `sexy-proposed-${pdfTemplate.pdfTemplateUrl}`
                    }
                  >
                    {t(`Download PDF template: {title}`, {
                      ns: "Quote",
                      title: pdfTemplate.label,
                    })}
                  </MenuItemWithIcon>,
                ]
              : []
          ),
        ]}
    </MenuButton>
  );
};
