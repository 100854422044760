import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { transientOptions } from "../../styles.js";
import { AddressDetails__AddressFragment } from "./Addresses.generated.js";
import { AddressWithBreaks } from "./AddressWithBreaks.js";
import {
  getAddressDirectionUrl,
  getAddressImageSrc,
  getAddressSearchUrl,
} from "./helpers.js";

const SIZE = 60;

const DEFAULT_POINT = { lat: 50.701606, lng: 7.37573 };
const DEFAULT_ZOOM = 4;

const ADDRESS_ZOOM = 13;

/* prettier-ignore */
const DEFAULT_ADDRESS_URL = getAddressImageSrc({location: {__typename: "Location", ...DEFAULT_POINT}}, SIZE * 2, SIZE * 2, DEFAULT_ZOOM, true);

const StyledMap = styled(
  Box,
  transientOptions
)<{ $url: string }>(({ $url }) => ({
  width: `${SIZE}px`,
  height: `${SIZE}px`,
  flexShrink: 0,
  flexGrow: 0,
  background: `url(${$url}) no-repeat center`,
  backgroundSize: "cover",
  textTransform: "none",
}));

interface Props {
  buildingTitle?: string;
  title?: string;
  address: AddressDetails__AddressFragment | null;
  AddButton?: React.ReactElement;
  EditButton?: React.ReactElement;
  BuildingButton?: React.ReactElement;
}

export const AddressBox = ({
  buildingTitle,
  title,
  address,
  AddButton,
  EditButton,
  BuildingButton,
}: Props) => {
  const { t } = useTranslate("AddressBox");
  const addressMapUrl =
    address && address.location
      ? getAddressImageSrc(
          { location: address.location },
          SIZE * 2,
          SIZE * 2,
          ADDRESS_ZOOM,
          false,
          true
        )
      : DEFAULT_ADDRESS_URL;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="stretch"
      minHeight={`${SIZE}px`}
    >
      {address ? (
        <StyledMap
          component="a"
          // @ts-ignore // FIXME
          target="_blank"
          rel="noreferrer nofollow"
          href={getAddressSearchUrl(address)}
          $url={addressMapUrl}
        />
      ) : (
        <StyledMap $url={addressMapUrl} />
      )}

      <Stack
        direction="row"
        spacing={0.5}
        pl={1}
        flexGrow={1}
        flexShrink={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="column" spacing={0} minHeight={`${SIZE}px`}>
          {title ? (
            <Typography variant="caption" component="div" color="textPrimary">
              {title}
            </Typography>
          ) : null}
          {address ? (
            <MuiLink
              target="_blank"
              rel="noreferrer nofollow"
              href={getAddressDirectionUrl(address)}
              variant="body2"
            >
              <AddressWithBreaks
                title={buildingTitle}
                address={address}
                useCountry={false}
              />
            </MuiLink>
          ) : (
            <Typography sx={{ color: "grey.500" }} variant="body2">
              {t("No address")}
            </Typography>
          )}
        </Stack>
        {AddButton || EditButton || BuildingButton ? (
          <Stack direction="row" spacing={0.5}>
            {AddButton}
            {EditButton}
            {BuildingButton}
            {/*{address && (*/}
            {/*  <IconButton*/}
            {/*    component="a"*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer nofollow"*/}
            {/*    href={getAddressDirectionUrl(address)}*/}
            {/*    size="small"*/}
            {/*    color="secondary"*/}
            {/*    onClick={copyToClipboard}*/}
            {/*  >*/}
            {/*    <Tooltip title={t("Directions")}>*/}
            {/*      <DirectionsIcon fontSize="small" />*/}
            {/*    </Tooltip>*/}
            {/*  </IconButton>*/}
            {/*)}*/}
            {/*{address ? (*/}
            {/*  <IconButton*/}
            {/*    size="small"*/}
            {/*    color="secondary"*/}
            {/*    onClick={copyToClipboard}*/}
            {/*  >*/}
            {/*    <Tooltip title={t("Copy address")}>*/}
            {/*      <Icon fontSize="small">*/}
            {/*        <FontAwesomeIcon icon={faClone} />*/}
            {/*      </Icon>*/}
            {/*    </Tooltip>*/}
            {/*  </IconButton>*/}
            {/*) : null}*/}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};
