/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated.js';
export type PurchaseOrderHeader__ProjectFragment = { __typename: 'Project', id: string, number: string, crmOrganisation?: { __typename: 'CrmCompany', id: string, number: string } | null };

export type PurchaseOrderHeader__SupplierFragment = { __typename: 'CrmCompany', id: string, title: string, contactType: Types.CrmOrganisationContactType, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null };

export type PurchaseOrderHeader__OrderFragment = { __typename: 'Order', author: { __typename: 'User', id: string, fullname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }> } };

export const PurchaseOrderHeader__ProjectFragmentDoc = gql`
    fragment PurchaseOrderHeader__Project on Project {
  id
  number
  crmOrganisation {
    id
    number
  }
}
    `;
export const PurchaseOrderHeader__SupplierFragmentDoc = gql`
    fragment PurchaseOrderHeader__Supplier on CrmCompany {
  id
  title
  branchAddress {
    ...AddressDetails__Address
  }
  contactType
  contactPerson {
    id
    fullname
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export const PurchaseOrderHeader__OrderFragmentDoc = gql`
    fragment PurchaseOrderHeader__Order on Order {
  author {
    id
    fullname
    phones {
      id
      number
    }
    email
  }
}
    `;