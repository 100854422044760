import { useApolloClient } from "@apollo/client";
import {
  CardContainer,
  FormattedPercentage,
  FormattedPrice,
  underlineDoubleStyle,
} from "@msys/ui";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import {
  IconButton,
  Tooltip as MuiTooltip,
  Stack,
  Typography,
} from "@mui/material";
// import { NavigationPrompt } from "../../global/Router.js";
import { useTranslate } from "@tolgee/react";
import { BigNumber } from "bignumber.js";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { PermissionName } from "../../../clients/graphqlTypes.js";
import { px } from "../../../common/MuiThemeProvider.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { useUserData } from "../../auth/useUserData.js";
import { ViewMode } from "../../commons/ViewModeMenuItem.js";
import { AutoSave } from "../../commons/form-fields/AutoSave.js";
import { FormattedPercentageField } from "../../commons/form-fields/FormattedPercentageField.js";
import { FormattedPriceField } from "../../commons/form-fields/FormattedPriceField.js";
import { ManualSave } from "../../commons/form-fields/ManualSave.js";
import { withStyles } from "../../styles.js";
import { ItemCalculationFragment } from "../doc-items/Fragments.generated.js";
import { QuotePriceBudgetSummary } from "./QuotePriceBudgetSummary.js";
import {
  QuotePriceSummaryBox_ProjectFragment,
  useModifyQuoteDiscountMutation,
} from "./QuotePriceSummaryBox.generated.js";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";

type FormValues = {
  discountPercentage: number;
  discountValue: number;
};

interface Props {
  quoteId: string;
  quote: {
    id: string;
    systemContractorId?: string | null | undefined;
    viewerPermissions: PermissionName[];
  };
  project: QuotePriceSummaryBox_ProjectFragment;
  discountPercentage: number;
  calculated: ItemCalculationFragment;
  isManualSave?: boolean;
}

export function QuotePriceSummaryBox({
  quoteId,
  quote,
  project,
  calculated,
  discountPercentage,
  isManualSave = false,
}: Props) {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("QuotePriceSummary");
  const budgetTotal = project?.budget?.amount || 0;
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyQuoteDiscount] = useModifyQuoteDiscountMutation({ client });

  const initialValues = React.useMemo(
    (): FormValues => ({
      discountPercentage,
      discountValue: parseFloat(
        new BigNumber(calculated.priceSubTotal)
          .minus(calculated.priceNetTotal)
          .toFixed(2)
      ),
    }),
    [discountPercentage, calculated.priceSubTotal, calculated.priceNetTotal]
  );

  const validationSchema = Yup.object().shape({
    discountPercentage: Yup.number().label(t("Discount")).min(0).max(1),
    discountValue: Yup.number()
      .label(t("Discount"))
      .min(0)
      .max(calculated.priceSubTotal),
  });

  const [viewMode, setViewMode] = React.useState<ViewMode>(
    isManualSave ? null : "edit"
  );

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyQuoteDiscount({
        variables: {
          input: {
            docId: quoteId,
            projectId: project.id,
            values: {
              discountPercentage: values.discountPercentage,
            },
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const showDiscount = discountPercentage !== 0 || viewMode === "edit";

  return (
    <CardContainer
      title={t("Totals")}
      isExpandable
      // TODO: add support of editing discount when quote is accepted
      // ActionButton={
      //   isManualSave && viewMode === null ? (
      //     <IconButton
      //       onClick={() => setViewMode("edit)}
      //       color={"primary"}
      //       size={"small"}
      //     >
      //       <EditIcon />
      //     </IconButton>
      //   ) : undefined
      // }
      CollapsedHint={
        <Typography variant="h3" component="div">
          <FormattedPrice value={calculated?.priceTotal ?? 0} />
        </Typography>
      }
    >
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={isManualSave}
      >
        {formikProps => (
          <Form>
            <Stack p={1} spacing={2} direction="column" width="100%">
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography variant="h4" component="div">
                    {t("Subtotal")}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    style={{ textDecoration: "underline" }}
                  >
                    <FormattedPrice value={calculated.priceSubTotal} />
                  </Typography>
                </Stack>
                {showDiscount && (
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <span>{t("Discount")}</span>
                      {/* TODO: add support of editing discount when quote is accepted */}
                      {viewMode === "edit" ? (
                        <FormattedPercentageField
                          label=""
                          name="discountPercentage"
                          min={0}
                          max={100}
                          InputProps={{ style: { width: "8ch" } }}
                          disabled={false}
                          size="extra-small"
                          // disabled={viewMode === null}
                          setFieldValue={(name, value) => {
                            const discountMultiplier = new BigNumber(1).minus(
                              value
                            );
                            const priceDiscountedSubTotal = new BigNumber(
                              calculated.priceSubTotal
                            )
                              .multipliedBy(discountMultiplier)
                              .toFixed(2);

                            const discountValue = new BigNumber(
                              calculated.priceSubTotal
                            )
                              .minus(priceDiscountedSubTotal)
                              .toFixed(2);

                            formikProps.setValues({
                              discountValue: parseFloat(discountValue),
                              discountPercentage: value,
                            });
                          }}
                        />
                      ) : (
                        <>
                          {" "}
                          (
                          <FormattedPercentage
                            value={formikProps.values.discountPercentage}
                          />
                          )
                        </>
                      )}
                    </Stack>
                    {viewMode === "edit" ? (
                      <FormattedPriceField
                        disabled={false}
                        name="discountValue"
                        label=""
                        type="price"
                        min={0}
                        InputProps={{
                          style: { width: "13ch" },
                          startAdornment: "−",
                        }}
                        FormHelperTextProps={{
                          style: { textAlign: "right" },
                        }}
                        style={{ alignItems: "flex-end" }}
                        size="extra-small"
                        setFieldValue={(name, value) => {
                          if (calculated.priceSubTotal === 0) {
                            formikProps.setFieldValue(name, value);
                            return;
                          }
                          const discountRate = new BigNumber(value)
                            .dividedBy(calculated.priceSubTotal)
                            .toFixed(6);
                          const discountMultiplier = new BigNumber(1).minus(
                            discountRate
                          );
                          const priceDiscountedSubTotal = new BigNumber(
                            calculated.priceSubTotal
                          )
                            .multipliedBy(discountMultiplier)
                            .toFixed(2);

                          const discountValue = new BigNumber(
                            calculated.priceSubTotal
                          )
                            .minus(priceDiscountedSubTotal)
                            .toFixed(2);

                          formikProps.setValues({
                            discountValue: parseFloat(discountValue),
                            discountPercentage: parseFloat(discountRate),
                          });
                        }}
                      />
                    ) : (
                      <span>
                        −{" "}
                        <FormattedPrice
                          value={
                            calculated.priceSubTotal - calculated.priceNetTotal
                          }
                        />
                      </span>
                    )}
                  </Stack>
                )}
              </Stack>
              <Stack direction="column" spacing={1}>
                {showDiscount && (
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Typography variant="h4" component="div">
                      {t("Net price")}
                    </Typography>
                    <Typography variant="h4" component="div">
                      <FormattedPrice value={calculated.priceNetTotal} />
                    </Typography>
                  </Stack>
                )}
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <span>
                    {t("VAT")} (
                    <FormattedPercentage value={calculated.vatRate} />)
                  </span>
                  <span>
                    + <FormattedPrice value={calculated.priceVatTotal} />
                  </span>
                </Stack>
              </Stack>
              <Stack spacing={1} direction="column">
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography variant="h3" component="div">
                    {t("Total")}
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    {budgetTotal > 0 && (
                      <RestrictedByCapabilityWithDebug capability="QUOTING">
                        <RestrictedByDocumentPermissionWithDebug
                          permission="MANAGE_QUOTES"
                          document={quote}
                        >
                          {viewer.organisation.id ===
                            quote.systemContractorId && ( // FIXME: this will not work with sharing
                            <Tooltip
                              title={
                                <QuotePriceBudgetSummary
                                  quoteId={quoteId}
                                  projectId={project.id}
                                />
                              }
                            >
                              <IconButton
                                aria-label="Additional info"
                                size="small"
                              >
                                <HelpOutlineIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </RestrictedByDocumentPermissionWithDebug>
                      </RestrictedByCapabilityWithDebug>
                    )}
                    <Typography
                      variant="h3"
                      component="div"
                      style={underlineDoubleStyle}
                    >
                      <FormattedPrice value={calculated.priceTotal} />
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              {viewMode === "edit" &&
                (isManualSave ? (
                  <>
                    <ManualSave
                      disabled={formikProps.isSubmitting}
                      onCancel={() => {
                        setViewMode(null);
                      }}
                      onSubmitted={() => {
                        setViewMode(null);
                      }}
                    />
                    {/* <NavigationPrompt when={formikProps.dirty} /> */}
                  </>
                ) : (
                  <AutoSave enableReinitialize initialValues={initialValues} />
                ))}
            </Stack>
          </Form>
        )}
      </Formik>
    </CardContainer>
  );
}

const Tooltip = withStyles(MuiTooltip, theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    color: theme.palette.primary.main,
    padding: `${px.m} ${px.l}`,
  },
  arrow: {
    color: "white",
  },
}));
