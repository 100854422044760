import { useApolloClient } from "@apollo/client";
import { ProjectState } from "@msys/statemachines";
import { ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { SwapHoriz as SwapHorizIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { EXPANDED_COLUMN_MIN_WIDTH } from "../../commons/kanban/KanbanColumn.js";
import { CreateNewPhaseModal } from "../opportunities/modals/CreateNewPhaseModal.js";
import { PhaseConfigCard } from "./PhaseConfigCard.js";
import {
  ProjectPhaseFragment,
  useAddOrganisationProjectPhaseMutation,
  useModifyOrganisationProjectPhaseMutation,
  useRearrangeOrganisationProjectPhaseMutation,
  useRemoveOrganisationProjectPhaseMutation,
} from "./ProjectPhasesConfigurator.generated.js";

interface Props {
  state: ProjectState;
  opportunitiesCount: Record<string, number>;
  phases: ProjectPhaseFragment[];
  isConfiguring: boolean;
  setIsConfiguring: React.Dispatch<React.SetStateAction<boolean>>;
  extraItems?: React.ReactNode[];
}

export const ProjectPhasesConfigurator = ({
  state,
  phases,
  opportunitiesCount,
  isConfiguring,
  setIsConfiguring,
  extraItems,
}: Props) => {
  const { t } = useTranslate(["Projects", "Opportunities"]);

  const client = useApolloClient();

  const [modifyOrganisationProjectPhase] =
    useModifyOrganisationProjectPhaseMutation({ client });
  const [rearrangeOrganisationProjectPhase] =
    useRearrangeOrganisationProjectPhaseMutation({ client });
  const [removeOrganisationProjectPhase] =
    useRemoveOrganisationProjectPhaseMutation({ client });
  const [addOrganisationProjectPhase] = useAddOrganisationProjectPhaseMutation({
    client,
  });

  if (!isConfiguring) return null;

  return (
    <Stack alignItems={"flex-end"}>
      <Stack
        spacing={1}
        padding={1}
        sx={theme => ({
          backgroundColor: theme.palette.blue.paper,
          borderRadius: `${theme.shape.borderRadius}px`,
        })}
        alignSelf={"stretch"}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h3">
            {t("Pipeline configuration (rename, reorder, add/delete phases)", {
              ns: "Projects",
            })}
          </Typography>
          <IconButton
            onClick={() => {
              setIsConfiguring(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack
          direction="row"
          alignItems={"center"}
          spacing={2}
          sx={{ overflowX: "auto" }}
        >
          {phases.map((phase, index) => (
            <React.Fragment key={phase.id}>
              <Stack
                direction="row"
                alignItems={"center"}
                spacing={1}
                flexBasis={EXPANDED_COLUMN_MIN_WIDTH}
                flexShrink={0}
                flexGrow={1}
                width={0}
              >
                <PhaseConfigCard
                  phase={phase}
                  handleChange={async (phaseId, { phaseName, phaseColor }) => {
                    await modifyOrganisationProjectPhase({
                      variables: {
                        input: {
                          phaseId,
                          name: phaseName,
                          color: phaseColor,
                        },
                      },
                    });
                  }}
                  isUsed={Boolean(
                    opportunitiesCount[phase.id] &&
                      opportunitiesCount[phase.id] > 0
                  )}
                  isOnlyPhase={phases?.length === 1}
                  isNameAvailable={phaseName =>
                    phases.every(p => p.id === phase.id || p.name !== phaseName)
                  }
                  handleDelete={async () => {
                    await removeOrganisationProjectPhase({
                      variables: {
                        input: { phaseId: phase.id },
                        filterIncludeStates: [state],
                      },
                    });
                  }}
                />
                {index < phases?.length - 1 ? (
                  <Box flexShrink={0}>
                    <IconButton
                      color={"primary"}
                      onClick={async () => {
                        await rearrangeOrganisationProjectPhase({
                          variables: {
                            input: {
                              phaseId: phase.id,
                              change: "SWAP_WITH_NEXT",
                            },
                            filterIncludeStates: [state],
                          },
                        });
                      }}
                    >
                      <SwapHorizIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Box flexShrink={0}>
                    <ModalOpenButton
                      Modal={CreateNewPhaseModal}
                      modalProps={{
                        handleComplete: async (
                          { phaseName, phaseColor },
                          handleClose
                        ) => {
                          await addOrganisationProjectPhase({
                            variables: {
                              state,
                              phaseName,
                              phaseColor,
                            },
                          });
                          handleClose();
                        },
                        isNameAvailable: phaseName =>
                          phases.every(phase => phase.name !== phaseName),
                      }}
                    >
                      <IconButton color={"primary"}>
                        <AddIcon />
                      </IconButton>
                    </ModalOpenButton>
                  </Box>
                )}
              </Stack>
            </React.Fragment>
          ))}
          {extraItems?.map((item, index) => (
            <Box
              key={`extra-item-${index}`}
              flexBasis={EXPANDED_COLUMN_MIN_WIDTH}
              flexShrink={0}
              flexGrow={1}
              display="flex"
              alignItems="center"
            >
              {item}
            </Box>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
