import { gql, useApolloClient } from "@apollo/client";
import { debounce } from "lodash-es";
import React from "react";
import { useUpdateEffect } from "react-use";
import {
  useSetUserPreferenceMutation,
  useViewerPreferenceQuery,
} from "./useUserPreference.generated.js";

export type PreferenceStore<T> = {
  value: T;
  saveValue: (newValue: T) => void;
  loading: boolean;
};

export const useUserPreference = <T>(
  key: string,
  defaultValue: T
): PreferenceStore<T> => {
  const [value, setValue] = React.useState<T>(defaultValue);

  const stateRef = React.useRef({ defaultValue, value });
  stateRef.current = { defaultValue, value };

  const client = useApolloClient();
  const query = useViewerPreferenceQuery({
    client,
    variables: { key },
    fetchPolicy: "no-cache",
  });

  // set back to default value when key changes
  useUpdateEffect(() => {
    setValue(stateRef.current.defaultValue);
  }, [key]);

  // set actual value when loading done
  React.useEffect(() => {
    if (!query.loading && !query.error && query.data) {
      const value = query.data.viewerPreference?.value;
      if (value !== undefined) {
        setValue(value);
      }
    }
  }, [query.loading, query.error, query.data]);

  const [setPreference] = useSetUserPreferenceMutation({ client });

  const saveValueDebounced = React.useCallback(
    debounce(async () => {
      await setPreference({
        variables: {
          input: { key, value: stateRef.current.value },
        },
      });
    }, 1000),
    [key, setPreference]
  );

  const saveValue = React.useCallback(
    (newValue: T) => {
      setValue(newValue);
      // trigger debounced save to persisted storage
      saveValueDebounced();
    },
    [saveValueDebounced]
  );

  const loading = Boolean(query.loading || query.error || !query.data);

  return React.useMemo(
    () => ({ value, saveValue, loading }),
    [value, saveValue, loading]
  );
};
