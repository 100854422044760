/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { ChannelListItemFragmentDoc, ChannelParticipantFragmentDoc } from '../../features/channels/Channels.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectChatSidebarQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectChatSidebarQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, channels: Array<{ __typename: 'Channel', id: string, type: string, label: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, messages: { __typename: 'MessageConnection', edges: Array<{ __typename: 'MessageEdge', node: { __typename: 'Message', text: string, createdAt: any } }> } }>, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, user2UserChannelId?: string | null, title: Types.Salutation, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> } | null } };

export type ProjectChatProjectFragment = { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, channels: Array<{ __typename: 'Channel', id: string, type: string, label: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, messages: { __typename: 'MessageConnection', edges: Array<{ __typename: 'MessageEdge', node: { __typename: 'Message', text: string, createdAt: any } }> } }>, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, user2UserChannelId?: string | null, title: Types.Salutation, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> };

export const ProjectChatProjectFragmentDoc = gql`
    fragment ProjectChatProject on Project {
  id
  title
  viewerPermissions
  channels {
    id
    ...ChannelListItem
  }
  internalStakeholders {
    id
    user {
      id
      user2UserChannelId
      ...ChannelParticipant
    }
  }
}
    ${ChannelListItemFragmentDoc}
${ChannelParticipantFragmentDoc}`;
export const ProjectChatSidebarDocument = gql`
    query ProjectChatSidebar($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        ...ProjectChatProject
      }
    }
  }
}
    ${ProjectChatProjectFragmentDoc}`;

/**
 * __useProjectChatSidebarQuery__
 *
 * To run a query within a React component, call `useProjectChatSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectChatSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectChatSidebarQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectChatSidebarQuery(baseOptions: Apollo.QueryHookOptions<ProjectChatSidebarQuery, ProjectChatSidebarQueryVariables> & ({ variables: ProjectChatSidebarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectChatSidebarQuery, ProjectChatSidebarQueryVariables>(ProjectChatSidebarDocument, options);
      }
export function useProjectChatSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectChatSidebarQuery, ProjectChatSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectChatSidebarQuery, ProjectChatSidebarQueryVariables>(ProjectChatSidebarDocument, options);
        }
export function useProjectChatSidebarSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectChatSidebarQuery, ProjectChatSidebarQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectChatSidebarQuery, ProjectChatSidebarQueryVariables>(ProjectChatSidebarDocument, options);
        }
export type ProjectChatSidebarQueryHookResult = ReturnType<typeof useProjectChatSidebarQuery>;
export type ProjectChatSidebarLazyQueryHookResult = ReturnType<typeof useProjectChatSidebarLazyQuery>;
export type ProjectChatSidebarSuspenseQueryHookResult = ReturnType<typeof useProjectChatSidebarSuspenseQuery>;
export type ProjectChatSidebarQueryResult = Apollo.QueryResult<ProjectChatSidebarQuery, ProjectChatSidebarQueryVariables>;