import { useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { TextField } from "../../commons/form-fields/TextField.js";
import { Stack } from "../../commons/layout/Stack.js";
import { useCreateTemplateListMutation } from "./CreateTemplateListModal.generated.js";

interface FormValues {
  title: string;
}

export const CreateTemplateListModal = ({
  refetchQueries,
  handleComplete,
  handleClose,
}: {
  refetchQueries?: string[];
  handleClose(): void;
  handleComplete?(templateListId: string): void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Global", "TemplateLists"]);

  const client = useApolloClient();
  const [create] = useCreateTemplateListMutation({ client });

  const handleSubmit = async (values: FormValues) => {
    try {
      const result = await create({
        variables: {
          input: {
            title: values.title,
          },
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
      if (result.data?.createTemplateList.templateList.id) {
        enqueueSnackbar(
          t("Template list added", {
            ns: "TemplateLists",
          })
        );
        handleComplete?.(result.data?.createTemplateList.templateList.id);
        handleClose();
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const initialValues: FormValues = {
    title: "",
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .label(t("Title", { ns: "TemplateLists" }))
          .required(),
      }),
    [t]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting, dirty, isValid }) => (
        <Modal
          title={t("Add new template list", {
            ns: "TemplateLists",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Create", { ns: "Global" }),
              buttonProps: {
                loading: isSubmitting,
                form: formId,
                type: "submit",
                disabled: !dirty || !isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack flexDirection="column">
              <TextField
                name="title"
                required
                label={t("Title", {
                  ns: "TemplateLists",
                })}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
