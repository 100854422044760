import { useApolloClient } from "@apollo/client";
import { useUserData } from "../../auth/useUserData.js";
import {
  useS4HanaQuoteDataHooksQuery,
  useS4HanaQuoteItemDataHooksQuery,
} from "./hooks.generated.js";
import {
  PimCustomSupplierProduct,
  PimSupplierProduct,
} from "../../../clients/graphqlTypes.js";
import { getDataOrNull } from "@msys/common";

export function useHasSapS4HanaIntegration() {
  const userData = useUserData();

  if (!userData.currentUser) {
    return false;
  }

  return userData.currentUser.organisation.enabledIntegrationIds.includes(
    "sap_s4hana"
  );
}

export function useProductIsConfigurable({
  product,
  projectId,
  quoteId,
}: {
  product:
    | Pick<PimCustomSupplierProduct, "__typename">
    | (Pick<PimSupplierProduct, "__typename"> & {
        supplierCatalogue: Pick<PimSupplierProduct["supplierCatalogue"], "id">;
      })
    | null
    | undefined;
  projectId: string | null | undefined;
  quoteId: string;
}) {
  const client = useApolloClient();

  const query = useS4HanaQuoteDataHooksQuery({
    client,
    variables: { projectId: projectId!, quoteId },
    skip: !projectId,
  });

  const quoteSapS4HanaData = query.data?.quoteSapS4HanaData;

  return !!(
    quoteSapS4HanaData &&
    product &&
    product.__typename === "PimSupplierProduct" &&
    quoteSapS4HanaData.configurableProductSupplierCatalogueIds.includes(
      product.supplierCatalogue.id
    )
  );
}

export function useQuoteItemIsSyncedWithSapS4Hana({
  projectId,
  quoteId,
  itemId,
}: {
  projectId: string;
  quoteId: string;
  itemId: string;
}) {
  const client = useApolloClient();

  const query = useS4HanaQuoteItemDataHooksQuery({
    client,
    variables: {
      projectId,
      quoteId,
      itemId,
    },
  });

  const sapItemData = getDataOrNull(
    query.data?.quoteItemSapS4HanaData
  )?.items?.at(0);

  return sapItemData ? !!sapItemData.xId : undefined;
}
