/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCsvHeaderMutationVariables = Types.Exact<{
  input: Types.GetCsvHeaderInput;
}>;


export type GetCsvHeaderMutation = { getCsvHeader: { __typename: 'GetCsvHeaderResult', headerAndValues: Array<{ __typename: 'HeaderAndValues', header: string, values: string }>, systemLabels: Array<{ __typename: 'SystemLabels', label: string, column: string, disabled: boolean }> } };


export const GetCsvHeaderDocument = gql`
    mutation GetCsvHeader($input: GetCsvHeaderInput!) {
  getCsvHeader(input: $input) {
    headerAndValues {
      header
      values
    }
    systemLabels {
      label
      column
      disabled
    }
  }
}
    `;
export type GetCsvHeaderMutationFn = Apollo.MutationFunction<GetCsvHeaderMutation, GetCsvHeaderMutationVariables>;

/**
 * __useGetCsvHeaderMutation__
 *
 * To run a mutation, you first call `useGetCsvHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCsvHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCsvHeaderMutation, { data, loading, error }] = useGetCsvHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCsvHeaderMutation(baseOptions?: Apollo.MutationHookOptions<GetCsvHeaderMutation, GetCsvHeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCsvHeaderMutation, GetCsvHeaderMutationVariables>(GetCsvHeaderDocument, options);
      }
export type GetCsvHeaderMutationHookResult = ReturnType<typeof useGetCsvHeaderMutation>;
export type GetCsvHeaderMutationResult = Apollo.MutationResult<GetCsvHeaderMutation>;
export type GetCsvHeaderMutationOptions = Apollo.BaseMutationOptions<GetCsvHeaderMutation, GetCsvHeaderMutationVariables>;