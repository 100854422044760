import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue, Modal, TextWithBreaks } from "@msys/ui";
import { Check as CheckIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { People as PeopleIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogContentText,
  Divider,
  IconButton,
  List,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import {
  Organisation,
  namedOperations,
} from "../../../../clients/graphqlTypes.js";
import { useUserData } from "../../../auth/useUserData.js";
import { Avatar } from "../../../commons/Avatar.js";
import { ProfileListItem } from "../../../commons/ProfileListItem.js";
import { Stack } from "../../../commons/layout/Stack.js";
import {
  InvitationRow__ProjectContracteeInvitationFragment,
  InvitationRow__ReceivedLinkingInvitationFragment,
  useInvitationListAcceptProjectContracteeInvitationMutation,
  useInvitationListDeclineProjectContracteeInvitationMutation,
} from "../../invitations/Invitations.generated.js";
import { OrganisationAvatar } from "../../organisations/OrganisationAvatar.js";
import { OrganisationAvatarFragment } from "../../organisations/OrganisationAvatar.generated.js";
import { UserAvatar } from "../UserAvatar.js";
import { UserAvatarFragment } from "../UserAvatar.generated.js";
import { useRedeemLinkingInvitationTokenMutation } from "./PendingInvitationsBox.generated.js";

export function PendingInvitationsBox({
  receivedLinkingInvitations,
  projectContracteeInvitations,
}: {
  receivedLinkingInvitations: InvitationRow__ReceivedLinkingInvitationFragment[];
  projectContracteeInvitations: InvitationRow__ProjectContracteeInvitationFragment[];
}) {
  const { t } = useTranslate(["UserProfile", "OrganisationRole", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const viewer = useUserData().currentUser!;

  const viewerOrganisation = viewer.organisation;

  const client = useApolloClient();

  const [
    redeemLinkingInvitationToken,
    { loading: redeemingLinkingInvitation },
  ] = useRedeemLinkingInvitationTokenMutation({
    client,
    refetchQueries: [
      namedOperations.Query.CrmAll,
      namedOperations.Query.InvitationList,
    ],
    awaitRefetchQueries: true,
  });

  const [
    acceptProjectContracteeInvitation,
    { loading: acceptProjectContracteeLoading },
  ] = useInvitationListAcceptProjectContracteeInvitationMutation({
    client,
  });
  const [
    declineProjectContracteeInvitation,
    { loading: declineProjectContracteeLoading },
  ] = useInvitationListDeclineProjectContracteeInvitationMutation({
    client,
  });

  const loading =
    acceptProjectContracteeLoading || declineProjectContracteeLoading;

  const handleAcceptProjectContracteeInvitation = async (
    invitationToken: string,
    acceptAsOrganisationId: string
  ) => {
    await acceptProjectContracteeInvitation({
      variables: {
        input: {
          invitationToken,
          acceptAsOrganisationId,
        },
      },
    });
    enqueueSnackbar(t("Invitation accepted", { ns: "UserProfile" }));
    await client.reFetchObservableQueries();
  };

  const handleDeclineProjectContracteeInvitation = async (
    invitationToken: string
  ) => {
    await declineProjectContracteeInvitation({
      variables: {
        invitationToken,
      },
    });

    enqueueSnackbar(t("Invitation declined", { ns: "UserProfile" }), {
      variant: "info",
    });

    await client.reFetchObservableQueries();
  };

  const handleRedeemLinkingInvitation = async (invitationToken: string) => {
    await redeemLinkingInvitationToken({
      variables: { token: invitationToken },
    });
    enqueueSnackbar(t("Invitation accepted", { ns: "UserProfile" }));
  };

  if (
    projectContracteeInvitations.length === 0 &&
    receivedLinkingInvitations.length === 0
  )
    return null;

  return (
    <>
      {/*
        ################################
        ######## LINKING
        ################################
      */}
      {receivedLinkingInvitations.length > 0 && (
        <CardContainer
          isInitiallyClosed={receivedLinkingInvitations.length === 0}
          itemCount={receivedLinkingInvitations.length}
          isExpandable
          title={t("Invitations to connect", {
            ns: "UserProfile",
          })}
        >
          <List disablePadding>
            {receivedLinkingInvitations.map((i, index, array) => {
              const avatar = (
                <OrganisationAvatar size="s" organisationAvatar={i} />
              );
              const button = (
                <Button
                  size="extra-small"
                  variant="outlined"
                  color="primary"
                  onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    await handleRedeemLinkingInvitation(i.token);
                  }}
                  disabled={redeemingLinkingInvitation}
                >
                  {t("Accept", {
                    ns: "Global",
                  })}
                </Button>
              );

              return !i.publicProfileSlug ? (
                <ProfileListItem
                  size="s"
                  divider={index < array.length - 1}
                  key={i.token}
                  avatar={avatar}
                  primary={i.title}
                  ActionButton={button}
                />
              ) : (
                <ProfileListItem
                  button
                  size="s"
                  component={Link}
                  to={`/org/${i.publicProfileSlug}`}
                  divider={index < array.length - 1}
                  key={i.token}
                  avatar={avatar}
                  primary={i.title}
                  ActionButton={button}
                />
              );
            })}
          </List>
        </CardContainer>
      )}
      {/*
        ################################
        ######## PROJECT CONTRACTEE
        ################################
      */}
      <Invitations<InvitationRow__ProjectContracteeInvitationFragment>
        loading={loading}
        invitations={projectContracteeInvitations}
        cardTitle={t("Project Invitations", {
          ns: "UserProfile",
        })}
        secondaryLabel={invitation =>
          t("Send by {authorName}", {
            ns: "UserProfile",
            authorName: invitation.author.fullname,
          })
        }
        dialogTitle={t("Join project", {
          ns: "UserProfile",
        })}
        invitingOrganisation={invitiation => invitiation.author.company}
        getDialogDescription={invitation =>
          t("{invitorName} wants to add you to their project {projectName}.", {
            ns: "UserProfile",
            invitorName: invitation.author.fullname,

            // TODO-MODEL2022 fix
            projectName: invitation.title,
          })
        }
        invitationDetails={invitation => (
          <Stack p={2} flexDirection="column">
            <Stack alignItems="center" mb={1}>
              <OrganisationAvatar
                organisationAvatar={invitation.author.company}
              />
              <Typography variant="h3">
                {
                  invitation.title // TODO-MODEL2022 fix
                }
              </Typography>
            </Stack>
            {/* // TODO-MODEL2022 fix */}
            {/* <LabeledValue label={t("UserProfile::Project address")}>
          {getAddressLabel(invitation.project.building?.buildingAddress) ||
            t("Global::Not set")}
        </LabeledValue> */}
            <LabeledValue
              label={t("Contractor", {
                ns: "UserProfile",
              })}
            >
              {invitation.author.company.title}
            </LabeledValue>
          </Stack>
        )}
        organisation={viewerOrganisation}
        openDialogOnAcceptClick
        onClickAccept={(invitation, organisation) => {
          handleAcceptProjectContracteeInvitation(
            invitation.invitationToken,
            organisation!
          );
        }}
        onClickDecline={invitation =>
          handleDeclineProjectContracteeInvitation(invitation.invitationToken)
        }
      />
    </>
  );
}

function Invitations<
  T extends {
    id: string;
    title: string;
    messageBody: string;
    author: UserAvatarFragment;
  },
>({
  loading,
  invitations,
  cardTitle,
  onClickAccept,
  onClickDecline,
  secondaryLabel,
  getDialogDescription,
  openDialogOnAcceptClick,
  dialogTitle,
  invitationDetails,
  invitingOrganisation,
  organisation,
}: {
  loading: boolean;
  invitations: Array<T>;
  invitingOrganisation?: (invitation: T) => OrganisationAvatarFragment;
  onClickAccept: (
    invitation: T,
    organisationId?: string | null
  ) => Promise<void> | void;
  onClickDecline: (invitation: T) => Promise<void> | void;
  openDialogOnAcceptClick?: boolean;
  getDialogDescription: (invitation: T) => React.ReactNode;
  cardTitle: string;
  dialogTitle?: string;
  secondaryLabel: (invitation: T) => string;
  invitationDetails?: (invitation: T) => React.ReactNode;
  organisation?: Pick<Organisation, "id" | "title">;
}): JSX.Element | null {
  const { t } = useTranslate(["UserProfile", "Global"]);

  const [selectedInvitation, setSelectedInvitation] = React.useState<T | null>(
    null
  );

  const handleClose = () => {
    setSelectedInvitation(null);
  };

  const validationSchema = organisation
    ? Yup.object().shape({
        organisationId: Yup.string()
          .label(
            t("Organisation", {
              ns: "UserProfile",
            })
          )
          .required(),
      })
    : undefined;

  if (invitations.length === 0) {
    return null;
  }

  return (
    <CardContainer
      isInitiallyClosed={invitations.length === 0}
      itemCount={invitations.length}
      isExpandable
      title={cardTitle}
    >
      <List disablePadding>
        {invitations.map((invitation, index, array) => {
          return (
            <ProfileListItem
              divider={index < array.length - 1}
              key={invitation.id}
              button
              onClick={() => setSelectedInvitation(invitation)}
              avatar={
                invitingOrganisation ? (
                  <OrganisationAvatar
                    size="m"
                    organisationAvatar={invitingOrganisation(invitation)}
                  />
                ) : (
                  <Avatar type="square" size="m">
                    <PeopleIcon />
                  </Avatar>
                )
              }
              primary={invitation.title}
              secondary={secondaryLabel(invitation)}
              ActionButtons={[
                <Tooltip
                  key="decline"
                  title={t("Decline Invitation", {
                    ns: "UserProfile",
                  })}
                >
                  <IconButton
                    size="small"
                    onClick={() => onClickDecline(invitation)}
                    color="primary"
                    disabled={loading}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>,
                ...(onClickAccept
                  ? [
                      <Tooltip
                        key="accept"
                        title={t("Accept Invitation", {
                          ns: "UserProfile",
                        })}
                      >
                        <IconButton
                          size="small"
                          onClick={() =>
                            openDialogOnAcceptClick
                              ? setSelectedInvitation(invitation)
                              : onClickAccept(invitation)
                          }
                          color="secondary"
                          disabled={loading}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>,
                    ]
                  : []),
              ].filter(Boolean)}
            />
          );
        })}
      </List>
      {selectedInvitation && (
        <Formik<{ organisationId: string }>
          initialValues={{
            organisationId: organisation ? organisation.id : "",
          }}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={async values => {
            await onClickAccept(selectedInvitation, values.organisationId);
            handleClose();
          }}
        >
          {({ isSubmitting, isValid }) => {
            const formId = `form-invitation-${selectedInvitation.id}`;
            return (
              <Modal
                title={
                  dialogTitle ||
                  t("Invitation details", {
                    ns: "UserProfile",
                  })
                }
                dialogProps={{ maxWidth: "md" }}
                handleClose={handleClose}
                actionButtons={[
                  {
                    label: t("Decline", {
                      ns: "Global",
                    }),
                    handleClick: async () => {
                      await onClickDecline(selectedInvitation);
                      handleClose();
                    },
                    buttonProps: {
                      loading: isSubmitting,
                      variant: "text",
                      disabled: loading,
                    },
                  },
                  {
                    label: t("Accept", {
                      ns: "Global",
                    }),
                    buttonProps: {
                      form: formId,
                      type: "submit",
                      loading: isSubmitting,
                      disabled: !isValid || loading,
                    },
                  },
                ]}
              >
                <Form id={formId}>
                  <DialogContentText>
                    {getDialogDescription(selectedInvitation)}
                  </DialogContentText>

                  <Paper>
                    <Box>
                      <Stack p={2}>
                        <UserAvatar userAvatar={selectedInvitation.author} />
                        <div>
                          <TextWithBreaks
                            text={selectedInvitation?.messageBody ?? ""}
                          />
                        </div>
                      </Stack>
                      {invitationDetails && (
                        <>
                          <Divider />
                          {invitationDetails(selectedInvitation)}
                        </>
                      )}
                    </Box>
                  </Paper>
                </Form>
              </Modal>
            );
          }}
        </Formik>
      )}
    </CardContainer>
  );
}
