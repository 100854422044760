import { assertNever } from "@msys/common";
import { ModalOpenButton } from "@msys/ui";
import { HelpOutline as DecisionIcon } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { AskWhom, DocType } from "../../../../clients/graphqlTypes.js";
import { useDefaultDecisionActions } from "../hooks/useDefaultDecisionActions.js";
import { DecisionModal } from "../modals/DecisionModal.js";

interface Props {
  projectId: string | null;
  docType: DocType;
  docId: string;
  itemId: string;
  canFinalize: boolean;
  viewerDecisionRole: AskWhom | undefined;
  expandedItemIds: string[] | undefined;
  refetch?: () => Promise<unknown>;
  isOptionHidden?: React.ComponentProps<typeof DecisionModal>["isOptionHidden"];

  type?: "button" | "icon";
  color?: ButtonProps["color"];
  label?: string;
  disabled?: boolean;
}

export const DecisionModalButton = ({
  projectId,
  docType,
  docId,
  itemId,
  canFinalize,
  viewerDecisionRole,
  expandedItemIds,
  refetch,
  isOptionHidden,

  type = "button",
  color = "secondary",
  label,
  disabled,
}: Props) => {
  const { t } = useTranslate(["Decisions"]);

  const decisionActions = useDefaultDecisionActions({
    projectId,
    docId,
    canFinalize,
    expandedItemIds,
    refetch,
  });

  const button =
    type === "button" ? (
      <Button
        disabled={disabled}
        color={color}
        variant="contained"
        size="small"
        startIcon={<DecisionIcon />}
        title={label ?? t("Question", { ns: "Decisions" })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {label ?? t("Question", { ns: "Decisions" })}
      </Button>
    ) : type === "icon" ? (
      <Button
        disabled={disabled}
        color={color}
        variant="contained"
        size="small"
        title={t("Question", { ns: "Decisions" })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ minWidth: 32, width: 32 }}
      >
        <DecisionIcon fontSize="small" style={{ fontSize: "1.15rem" }} />
      </Button>
    ) : (
      assertNever(type)
    );

  return (
    <ModalOpenButton
      Modal={DecisionModal}
      modalProps={{
        projectId,
        docType,
        docId,
        itemId,
        canFinalize,
        viewerDecisionRole,
        expandedItemIds,
        decisionActions,
        refetch: decisionActions.refetch,
        isOptionHidden,
      }}
    >
      {button}
    </ModalOpenButton>
  );
};
