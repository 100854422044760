import {
  ListAlt as BillOfMaterialsIcon,
  DescriptionOutlined as PurchaseOrderIcon,
} from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  matchPath,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { PermissionName } from "../../../clients/graphqlTypes.js";
import { BreadcrumbItem } from "../../commons/layout/PageBreadcrumbs.js";
import { PageTopbarItem } from "../../commons/layout/PageSubmenu.js";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { TabsWithRestriction } from "../../commons/TabsWithRestriction.js";
import { BillOfMaterialsItemDetails } from "../../features/bill-of-materials/BillOfMaterialsItemDetails.js";
import { ProjectBillOfMaterials } from "./ProjectBillOfMaterials.js";
import {
  ProjectPurchaseOrderEdit,
  ProjectPurchaseOrderEditItem,
} from "./ProjectPurchaseOrderEdit.js";
import { ProjectPurchaseOrderPreview } from "./ProjectPurchaseOrderPreview.js";
import { ProjectPurchaseOrders } from "./ProjectPurchaseOrders.js";

export const ProjectMaterialsRoutes = ({
  project,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: {
  project: { id: string; viewerPermissions: PermissionName[] };
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useTranslate("Materials");

  const projectId = project.id;

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Materials"),
        to: `/projects/${projectId}/materials/bill-of-materials`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  const tabItems = [
    {
      value: "project-bill-of-materials",
      label: t("Bill of materials"),
      icon: <BillOfMaterialsIcon />,
      path: `/projects/${projectId}/materials/bill-of-materials`,
      projectPermission: { name: "MANAGE_QUOTES" as const, project },
    },
    {
      value: "project-purchase-orders",
      label: t("Purchase orders"),
      icon: <PurchaseOrderIcon />,
      path: `/projects/${projectId}/materials/purchase-orders`,
      projectPermission: { name: "MANAGE_QUOTES" as const, project },
    },
  ];

  const activeTab = tabItems.find(item =>
    matchPath(item.path, location.pathname)
  );
  const tabs = (
    <TabsWithRestriction
      condensed
      useSelectOnMobile
      options={tabItems}
      value={activeTab?.value}
      onChange={value => {
        const tab = tabItems.find(t => t.value === value);
        if (tab) navigate(tab.path);
      }}
    />
  );

  return (
    <Routes>
      <Route index element={<Navigate to={"bill-of-materials"} replace />} />
      <Route
        path={`bill-of-materials`}
        element={
          <ProjectBillOfMaterials
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
            tabs={tabs}
          />
        }
      >
        <Route
          path={":itemId"}
          element={
            <BillOfMaterialsItemDetails
              projectId={projectId}
              handleClose={() => {
                navigate({
                  pathname: `/projects/${projectId}/materials/bill-of-materials`,
                  search: searchParams.toString(),
                });
              }}
            />
          }
        />
      </Route>
      <Route
        path="purchase-orders"
        element={
          <ProjectPurchaseOrders
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
            tabs={tabs}
          />
        }
      />
      <Route
        path={"purchase-orders/:orderId/edit"}
        element={
          <ProjectPurchaseOrderEdit
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
          />
        }
      >
        <Route
          path={"items/:itemId"}
          element={<ProjectPurchaseOrderEditItem projectId={projectId} />}
        />
      </Route>
      <Route
        path="purchase-orders/:orderId"
        element={
          <ProjectPurchaseOrderPreview
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
          />
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
