import React from "react";
import { useTranslate } from "@tolgee/react";
import { Button } from "@mui/material";
import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import { DeclineChangesModal } from "../modals/DeclineChangesModal.js";

export const DeclineChangesButton: React.FC<{
  // doc: Doc;
  // docProject: DocProject2;
  // allDocItems: Array<Item>;
  docId: string;
  projectId: string;
  refetchQueries?: string[];
  handleComplete?: () => Promise<unknown> | unknown;
}> = ({ docId, projectId, refetchQueries, handleComplete }) => {
  const { t } = useTranslate(["QuoteEdit", "Global"]);
  const { isMaxPhone } = useScreenWidth();
  return (
    // <ShowAction
    //   action={{
    //     name: "decline changes",
    //     doc: doc,
    //     project: docProject,
    //     allDocItems,
    //   }}
    // >
    // </ShowAction>
    <ModalOpenButton
      Modal={DeclineChangesModal}
      modalProps={{ projectId, quoteId: docId, refetchQueries, handleComplete }}
    >
      <Button color="warning" variant="contained">
        {isMaxPhone
          ? t("Decline", { ns: "Global" })
          : t("Decline changes", { ns: "QuoteEdit" })}
      </Button>
    </ModalOpenButton>
  );
};
