import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LabeledValue, Modal, useFormatting } from "@msys/ui";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "../../commons/layout/Stack.js";
import { useConfirmDeactivationModalQuery } from "./ConfirmDeactivationModal.generated.js";
import { useTranslate } from "@tolgee/react";

export const ConfirmDeactivationModal = ({
  title,
  handleClose,
  handleComplete,
  organisationId,
  userId,
}: {
  title?: string;
  handleClose: () => void;
  handleComplete?: (args?: any) => void;
  userId: string;
  organisationId: string;
}) => {
  const { t } = useTranslate("UserProfile");
  const { getFormattedDateTime } = useFormatting();

  const now = React.useMemo(() => new Date(), []);
  const client = useApolloClient();
  const query = useConfirmDeactivationModalQuery({
    client,
    variables: {
      userId,
      now,
    },
  });

  const projects =
    getDataOrNull(query.data?.projects)?.edges.map(e => e.node) ?? [];
  const tickets =
    getDataOrNull(query.data?.tickets)?.edges.map(e => e.node) ?? [];
  const projectsAndTickets = [...projects, ...tickets];

  const upcomingPlannedSessions =
    getDataOrNull(query.data?.planSessions)?.edges.map(e => e.node) ?? [];

  return (
    <Modal
      title={title ?? t("Deactivate membership")}
      actionButtons={[
        {
          label: t("Cancel"),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Deactivate"),
          handleClick: handleComplete,
        },
      ]}
      handleClose={handleClose}
    >
      <Typography variant="h3">
        {t("Are you sure you want to deactivate this member?")}
      </Typography>
      {upcomingPlannedSessions.length > 0 && (
        <Stack paddingY={2} spacing={1} flexDirection="column">
          <Typography>
            {t(
              "This member is a participant in the projects and tickets below. Open them to remove the user from these projects and reschedule the planned sessions."
            )}
          </Typography>
          {projects.length > 0 && (
            <LabeledValue label={t("Projects")}>
              {projects.map(project => (
                <div key={project.id}>
                  <Link
                    to={`/projects/${project.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {project.title}
                  </Link>
                </div>
              ))}
            </LabeledValue>
          )}
          {tickets.length > 0 && (
            <LabeledValue label={t("Tickets")}>
              {tickets.map(ticket => (
                <div key={ticket.id}>
                  <Link
                    to={`/projects/${ticket.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ticket.title}
                  </Link>
                </div>
              ))}
            </LabeledValue>
          )}
          {upcomingPlannedSessions && upcomingPlannedSessions.length > 0 && (
            <LabeledValue label={t("Upcoming planned sessions")}>
              {upcomingPlannedSessions?.map(session => (
                <div key={session.id}>{`${getFormattedDateTime(
                  session.from
                )} - ${getFormattedDateTime(session.till)} (${
                  session.project.title
                })`}</div>
              ))}
            </LabeledValue>
          )}
          {projectsAndTickets.length <= 5 && (
            <Stack>
              <Button
                color="secondary"
                onClick={() => {
                  projectsAndTickets.forEach(project =>
                    window.open(`/projects/${project.id}`, "_blank")
                  );
                }}
              >
                {t("Open all")}
              </Button>
            </Stack>
          )}
        </Stack>
      )}
    </Modal>
  );
};
