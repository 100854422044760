import { useApolloClient } from "@apollo/client";
import { Checkbox } from "@mui/material";
import { ChangeEvent } from "react";
import { color } from "../../../../common/MuiThemeProvider.js";
import { useChangeContingencyMutation } from "../hooks/useDecisionItemMutations.generated.js";
import { DecisionContingencyCheckbox_ItemFragment } from "./DecisionContingencyCheckbox.generated.js";

interface Props {
  itemId: string;
  docId: string;
  projectId: string | null;
  item: DecisionContingencyCheckbox_ItemFragment;
  disabled?: boolean;
}

export const DecisionContingencyCheckbox = ({
  docId,
  projectId,
  itemId,
  item,
  disabled = false,
}: Props) => {
  const client = useApolloClient();

  const [
    modifyItemContingencyPreselection,
    { loading: modifyItemContingencyPreselectionLoading },
  ] = useChangeContingencyMutation({ client });

  const { decisionContingentItemPreselection } = item;

  const checkboxDisabled = disabled || modifyItemContingencyPreselectionLoading;

  return (
    <Checkbox
      size="small"
      color={checkboxDisabled ? undefined : "default"}
      style={checkboxDisabled ? undefined : { color: color.default }}
      disabled={checkboxDisabled}
      checked={Boolean(decisionContingentItemPreselection)}
      indeterminate={decisionContingentItemPreselection === null}
      onClick={async (event: ChangeEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();
        let newPreselection: boolean | null = false;
        if (decisionContingentItemPreselection === null) newPreselection = true;
        if (decisionContingentItemPreselection === false)
          newPreselection = true;
        await modifyItemContingencyPreselection({
          variables: {
            input: {
              projectId,
              docId,
              itemId,
              values: {
                decisionContingentItemPreselection: newPreselection,
              },
            },
          },
        });
      }}
    />
  );
};
