import { useApolloClient } from "@apollo/client";
import { LoadingSpinner } from "@msys/ui";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { THREED_PLANNER_URL } from "../../../environment.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import {
  AttachmentInput,
  _3d_ShapeInput,
} from "../../../clients/graphqlTypes.js";
import {
  useThreeDAddSnapshotsMutation,
  useThreeDModifyRoomShapeMutation,
  useThreeDRoomLazyQuery,
} from "./ThreeD.generated.js";
import { ThreeDEditor, ThreeDEditorRef } from "./ThreeDEditor.js";

interface FormValues {
  url: string;
}

type Props = {
  projectId: string | null;
  docId: string;

  returnPath: string;
  threeDEditorRef: React.Ref<ThreeDEditorRef> | undefined;
  allDocItems: { id: string }[];
  isEditable?: boolean;
};

export const ThreeD = ({
  projectId,
  docId,
  returnPath,
  threeDEditorRef,
  allDocItems,
  isEditable = true,
}: Props) => {
  const navigate = useNavigate();

  const isThreeDDevelopEnabled = useFeature("ThreeDDevelop");
  const [threeDUrl, setThreeDUrl] = useState<string>(THREED_PLANNER_URL);

  const client = useApolloClient();
  const [fetchThreeDRoomQuery, threeDRoomQuery] = useThreeDRoomLazyQuery({
    client,
    variables: { projectId, quoteId: docId },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (!threeDRoomQuery.called) {
      fetchThreeDRoomQuery();
    } else {
      threeDRoomQuery.refetch?.();
    }
  }, [allDocItems, fetchThreeDRoomQuery, threeDRoomQuery]);

  const [threeDModifyRoomShape] = useThreeDModifyRoomShapeMutation({
    client,
  });

  const [threeDAddSnapshots] = useThreeDAddSnapshotsMutation({
    client,
  });

  const onClose = useCallback(
    () => navigate(returnPath),
    [navigate, returnPath]
  );

  const onDataChange = useCallback(
    async (inputDataShape: _3d_ShapeInput) => {
      if (!isEditable) return;
      await threeDModifyRoomShape({
        variables: {
          input: {
            projectId,
            docId,
            shape: inputDataShape,
          },
        },
      });
    },
    [projectId, docId, threeDModifyRoomShape, isEditable]
  );

  const onSnapshots = useCallback(
    async (inputSnapshots: AttachmentInput[]) => {
      if (!isEditable) return;
      await threeDAddSnapshots({
        variables: {
          input: {
            projectId,
            docId,
            snapshots: inputSnapshots,
          },
        },
      });
    },
    [projectId, docId, threeDAddSnapshots, isEditable]
  );

  const _3d_room = threeDRoomQuery.data?._3d_room;

  if (!threeDUrl) return <div>No 3D planner specified</div>;

  if (!_3d_room && threeDRoomQuery.loading) return <LoadingSpinner />;
  if (!_3d_room) return <div>3D room not found</div>;

  return (
    <>
      {isThreeDDevelopEnabled && (
        <Formik<FormValues>
          initialValues={{ url: threeDUrl }}
          onSubmit={(values, formikProps) => {
            setThreeDUrl(values.url);
            formikProps.setSubmitting(false);
          }}
          enableReinitialize
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form>
              <Field component={TextField} name="url" label={"3D url"} />
            </Form>
          )}
        </Formik>
      )}
      <ThreeDEditor
        ref={threeDEditorRef}
        threeDUrl={threeDUrl}
        threeDRoomSnapshot={_3d_room._3d_roomSnapshot}
        threeDSelectedRoomItems={_3d_room._3d_selectedRoomItems}
        onClose={onClose}
        onDataChange={onDataChange}
        onSnapshots={onSnapshots}
        threeDGuiMode={isEditable ? "complete-ui" : "no-ui"}
      />
    </>
  );
};
