import { useField } from "formik";
import React from "react";
import { compressIfCan } from "./helpers.js";

interface Props {
  name: string;
  accept?: string;
  button: React.ReactElement;
}

export const FilesInputField = ({ name, accept = "*/*", button }: Props) => {
  const attachmentsRef = React.useRef<HTMLInputElement>(null);
  const [{ value }, { touched, error }, { setValue }] = useField<File[]>(name);

  const buttonWithClick = React.cloneElement(button, {
    onClick: () => attachmentsRef.current?.click(),
  });

  return (
    <>
      {buttonWithClick}
      <input
        style={{ display: "none" }}
        ref={attachmentsRef}
        type="file"
        accept={accept}
        name={name}
        multiple
        onChange={async event => {
          const files = event.target.files
            ? Array.from(event.target.files)
            : [];
          if (files.length > 0) {
            setValue([
              ...value,
              ...(await Promise.all(files.map(file => compressIfCan(file)))),
            ]);
          }
        }}
      />
    </>
  );
};
