/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseDistanceToProjectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type UseDistanceToProjectQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, distanceToProject?: number | null }> };


export const UseDistanceToProjectDocument = gql`
    query UseDistanceToProject($projectId: ID!) {
  organisationMemberships(filters: {active: true}) {
    id
    distanceToProject(projectId: $projectId)
  }
}
    `;

/**
 * __useUseDistanceToProjectQuery__
 *
 * To run a query within a React component, call `useUseDistanceToProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseDistanceToProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseDistanceToProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUseDistanceToProjectQuery(baseOptions: Apollo.QueryHookOptions<UseDistanceToProjectQuery, UseDistanceToProjectQueryVariables> & ({ variables: UseDistanceToProjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseDistanceToProjectQuery, UseDistanceToProjectQueryVariables>(UseDistanceToProjectDocument, options);
      }
export function useUseDistanceToProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseDistanceToProjectQuery, UseDistanceToProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseDistanceToProjectQuery, UseDistanceToProjectQueryVariables>(UseDistanceToProjectDocument, options);
        }
export function useUseDistanceToProjectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UseDistanceToProjectQuery, UseDistanceToProjectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseDistanceToProjectQuery, UseDistanceToProjectQueryVariables>(UseDistanceToProjectDocument, options);
        }
export type UseDistanceToProjectQueryHookResult = ReturnType<typeof useUseDistanceToProjectQuery>;
export type UseDistanceToProjectLazyQueryHookResult = ReturnType<typeof useUseDistanceToProjectLazyQuery>;
export type UseDistanceToProjectSuspenseQueryHookResult = ReturnType<typeof useUseDistanceToProjectSuspenseQuery>;
export type UseDistanceToProjectQueryResult = Apollo.QueryResult<UseDistanceToProjectQuery, UseDistanceToProjectQueryVariables>;