/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../features/attachments/Attachments.generated.js';
import { OrganisationDetailsBox_OrganisationFragmentDoc } from '../../features/organisations/boxes/OrganisationDetailsBox.generated.js';
import { OrganisationPaymentInformationBox_OrganisationFragmentDoc } from '../../features/organisations/boxes/OrganisationPaymentInformationBox.generated.js';
import { OrganisationRegistrationDetailsBox_OrganisationFragmentDoc } from '../../features/organisations/boxes/OrganisationRegistrationDetailsBox.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GlobalOrganisationProfileQueryVariables = Types.Exact<{
  organisationId: Types.Scalars['ID']['input'];
}>;


export type GlobalOrganisationProfileQuery = { globalOrganisation?: { __typename: 'Organisation', id: string, title: string, organisationType: Types.OrganisationType, description: string, isCraftsmanOrganisation: boolean, email: any, website: string, skillset: Array<Types.Skill>, bankName: string, bankAccount: string, bankCode: string, fullLegalName: string, taxNumber: string, vatNumber: string, registrationNumber: string, registrationOffice: string, managingDirector: string, representive?: { __typename: 'User', id: string } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, pickupAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, profile?: { __typename: 'OrganisationProfile', id: string, slug: string } | null } | null };


export const GlobalOrganisationProfileDocument = gql`
    query GlobalOrganisationProfile($organisationId: ID!) {
  globalOrganisation(id: $organisationId) {
    id
    title
    organisationType
    representive {
      id
    }
    logo {
      ...AttachmentSnapshot
    }
    ...OrganisationDetailsBox_Organisation
    ...OrganisationPaymentInformationBox_Organisation
    ...OrganisationRegistrationDetailsBox_Organisation
  }
}
    ${AttachmentSnapshotFragmentDoc}
${OrganisationDetailsBox_OrganisationFragmentDoc}
${OrganisationPaymentInformationBox_OrganisationFragmentDoc}
${OrganisationRegistrationDetailsBox_OrganisationFragmentDoc}`;

/**
 * __useGlobalOrganisationProfileQuery__
 *
 * To run a query within a React component, call `useGlobalOrganisationProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalOrganisationProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalOrganisationProfileQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGlobalOrganisationProfileQuery(baseOptions: Apollo.QueryHookOptions<GlobalOrganisationProfileQuery, GlobalOrganisationProfileQueryVariables> & ({ variables: GlobalOrganisationProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalOrganisationProfileQuery, GlobalOrganisationProfileQueryVariables>(GlobalOrganisationProfileDocument, options);
      }
export function useGlobalOrganisationProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalOrganisationProfileQuery, GlobalOrganisationProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalOrganisationProfileQuery, GlobalOrganisationProfileQueryVariables>(GlobalOrganisationProfileDocument, options);
        }
export function useGlobalOrganisationProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GlobalOrganisationProfileQuery, GlobalOrganisationProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GlobalOrganisationProfileQuery, GlobalOrganisationProfileQueryVariables>(GlobalOrganisationProfileDocument, options);
        }
export type GlobalOrganisationProfileQueryHookResult = ReturnType<typeof useGlobalOrganisationProfileQuery>;
export type GlobalOrganisationProfileLazyQueryHookResult = ReturnType<typeof useGlobalOrganisationProfileLazyQuery>;
export type GlobalOrganisationProfileSuspenseQueryHookResult = ReturnType<typeof useGlobalOrganisationProfileSuspenseQuery>;
export type GlobalOrganisationProfileQueryResult = Apollo.QueryResult<GlobalOrganisationProfileQuery, GlobalOrganisationProfileQueryVariables>;