import { CollapseButton } from "@msys/ui";
import { styled } from "@mui/material/styles";
import React from "react";
import { isDescendant } from "react-sortable-tree";
import { color, cssValue, size } from "../../../common/MuiThemeProvider.js";
import { isItemGreyedOut } from "../../features/doc-items/helpers.js";
import { getSubcontractPath } from "../../features/quotes/helpers.js";
import { transientOptions } from "../../styles.js";
import { getItemChildren } from "../../trees/helpers.js";
import {
  createRecursiveTryFocusElementFunction,
  isInTestMode,
} from "../../utils.js";
import {
  NodeRendererProps,
  VirtualItem,
  VirtualTreeTableContext,
} from "../types.js";

export const VirtualTableNodeRenderer = <T extends VirtualItem>({
  node,
  canDrag = false,
  toggleChildrenVisibility,
  parentNode,
  draggedNode,
  canDrop = false,
  connectDragSource,
  connectDragPreview,
  didDrop,
  isDragging,
  path,
  treeIndex,
  items,
  allItems,
  rootItem,
  inputComponent: InputComponent,
  rootItemComponent: RootItemComponent,
  itemComponent: ItemComponent,
  selectedItemId,
  lastSelectedItemId,
  EmptyRow,
}: NodeRendererProps<T> & VirtualTreeTableContext<T>) => {
  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  const resetFocus = React.useMemo(
    () =>
      createRecursiveTryFocusElementFunction(
        `input#msys-table-input-${node.item.type}-${node.item.id}:enabled`
      ),
    [node.item.id, node.item.type]
  );

  const input = node.isInput ? (
    InputComponent ? (
      <InputComponent
        id={`msys-table-input-${node.item.type}-${node.item.id}`}
        item={node.item}
        itemChildren={getItemChildren(node.item, allItems ?? items)}
        nodeRank={node.rank}
        isRootItem={node.item.isRootItem}
        autoFocus={false} // doesn't work well with virtual tree
        resetFocus={
          //  auto focus does not play nicely with e2e automated tests
          //  the e2e tests will set this sessionStorage as a temporary solution
          isInTestMode() ? undefined : resetFocus
        }
      />
    ) : null
  ) : null;

  const component = !node.isInput ? (
    <div
      className="rst__row"
      style={{
        opacity: isDraggedDescendant ? 0.5 : 1,
      }}
    >
      {node.item.isRootItem && RootItemComponent ? (
        <RootItemComponent
          item={node.item}
          allItems={allItems ?? items}
          isRootItem={node.item.isRootItem}
          depth={0}
          parentItem={(parentNode?.item ?? rootItem)!}
          isGreyedOut={false} // already used it
          isHidden={node.item.isHidden}
          isPriceHidden={node.item.isPriceHidden}
          subcontractPath={getSubcontractPath(node.item)}
        />
      ) : ItemComponent ? (
        <ItemComponent
          item={node.item}
          allItems={allItems ?? items}
          isRootItem={node.item.isRootItem}
          depth={RootItemComponent ? path.length - 1 : path.length}
          parentItem={(parentNode?.item ?? rootItem)!}
          isGreyedOut={false} // already used it
          isHidden={node.item.isHidden}
          isPriceHidden={node.item.isPriceHidden}
          subcontractPath={getSubcontractPath(node.item)}
        />
      ) : null}
    </div>
  ) : null;

  const toggleButton = !node.isInput ? (
    toggleChildrenVisibility && node.item.hasChildren ? (
      <StyledExpandWrapper className="rts__expandWrapper">
        <CollapseButton
          aria-label={node.expanded ? "Collapse" : "Expand"}
          isExpanded={node.expanded}
          setIsExpanded={newExpanded =>
            toggleChildrenVisibility({
              node,
              path,
              treeIndex,
            })
          }
        />
      </StyledExpandWrapper>
    ) : (
      <StyledExpandWrapper className="rts__expandWrapper" />
    )
  ) : null;

  return (
    <StyledContentWrapper
      $clickable={!node.isInput}
      $selected={node.selected}
      $selectedDescendant={
        !node.selected &&
        node.id !== selectedItemId &&
        node.id === lastSelectedItemId
      }
      $isInput={node.isInput}
      $isLandingPadActive={isLandingPadActive}
      $isLandingPadCancel={isLandingPadActive && !canDrop}
      $isGreyedOut={isItemGreyedOut(node.item)}
      $isPreselected={node.item.isParentDecisionPreselected}
    >
      {node.isInput ? (
        <StyledInputRowWrapper>
          {input}
          {EmptyRow}
        </StyledInputRowWrapper>
      ) : (
        <>
          {toggleButton}
          <StyledRowWrapper>
            {connectDragPreview(
              canDrag
                ? connectDragSource(component, { dropEffect: "copy" })
                : component
            )}
          </StyledRowWrapper>
        </>
      )}
    </StyledContentWrapper>
  );
};

const StyledContentWrapper = styled("div", transientOptions)<{
  $selected: boolean;
  $selectedDescendant: boolean;
  $clickable: boolean;
  $isLandingPadActive: boolean;
  $isLandingPadCancel: boolean;
  $isGreyedOut: boolean;
  $isPreselected: boolean;
  $isInput: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ $isGreyedOut, $isInput, theme }) =>
    $isGreyedOut && !$isInput ? theme.palette.grey[100] : "transparent"};

  ${({ $selectedDescendant }) =>
    $selectedDescendant
      ? `
        background-color: ${color.blue9};
      `
      : ""}
  ${({ $selected }) =>
    $selected
      ? `
        background-color: ${color.blue8};
        box-shadow: ${cssValue.selectedTableBorderShadow};
      `
      : ""}

  ${({ $clickable, $selected, $isGreyedOut, $isInput, theme }) =>
    $clickable && !$selected && !$isInput
      ? `
        &:hover {
          background-color: ${
            $isGreyedOut ? theme.palette.grey[200] : theme.palette.grey[50]
          };
        }
      `
      : ""}


  ${({ $isLandingPadActive }) =>
    $isLandingPadActive
      ? `
          & > * {
            pointer-events: none;
          }
          cursor: move;
          background-color: ${color.blue7} !important;
        `
      : ""}

  ${({ $isLandingPadCancel }) =>
    $isLandingPadCancel
      ? `
          & > * {
            pointer-events: none;
          }
          cursor: move;
          background-color: ${color.redLight} !important;
        `
      : ""}

  .rst__row {
    height: 100%;
    width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const StyledExpandWrapper = styled("div")(`
  display: inline-flex;
  height: 100%;
  width: 32px;
  justify-content: flex-end;
  align-items: center;
  z-index: 5;

  flex-shrink: 0;
  flex-grow: 0;
`);

const StyledRowWrapper = styled("div")(`
  height: ${size.tableItemFullHeight}px;
  box-sizing: border-box;
  flex-grow: 1;
  min-width: 0;
`);

const StyledInputRowWrapper = styled("div")(`
  height: ${size.tableItemFullHeight}px;
  box-sizing: border-box;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  padding-right: 6px;
`);
