import { StatusLabel } from "@msys/ui";
import { useTheme } from "@mui/material";
import React from "react";
import {
  PurchaseOrderStatusBadge_OrderFragment,
  PurchaseOrderStatusBadge_OrderNodeFragment,
} from "./PurchaseOrderStatusBadge.generated.js";
import { useTranslate } from "@tolgee/react";

interface Props {
  order:
    | PurchaseOrderStatusBadge_OrderFragment
    | PurchaseOrderStatusBadge_OrderNodeFragment;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: boolean;
}

export const PurchaseOrderStatusBadge = ({ order, align, small }: Props) => {
  const { t } = useTranslate("PurchaseOrders");
  const theme = useTheme();

  if (order.orderedDate) {
    return (
      <StatusLabel
        align={align}
        small={small}
        color={theme.palette.primary.main}
      >
        {t("Ordered")}
      </StatusLabel>
    );
  }

  return (
    <StatusLabel align={align} small={small} color={theme.palette.grey[500]}>
      {t("Draft")}
    </StatusLabel>
  );
};
