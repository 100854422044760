import { gql } from "@apollo/client";
import { partition } from "lodash-es";
import { VanMarckeProfitability_ItemFragment } from "./calculateProfitability.generated.js";
// import { ItemType } from "../../graphqlTypes.js";

export interface ProductData {
  an: string; // article number
  pp: number; // purchase price
}

export interface Profitability {
  revenue: number;
  costProducts: number;
  costInstaller: number;
  marginValue: number;
  marginPercentage: number;
  maxDiscountPossible: number;
}

const PROFIT_MARGIN_TARGET = 0.2;
const COMMISSION_PERCENTAGE = 0.2;

export async function calculateProfitability(
  items: VanMarckeProfitability_ItemFragment[],
  getItemData: (articleNumber: string) => Promise<ProductData>
): Promise<Profitability> {
  const rootItem = items.find(item => item.isRootItem);
  if (!rootItem) throw new Error("Root item missing");
  if (!rootItem.proposedCalculation)
    throw new Error("Root item price is missing");

  const paidItems = items.filter(item => item.type === "paid");
  const [catalogItems, otherItems] = partition(
    paidItems,
    item => item.product?.articleNumber
  );
  const enhanchedCatalogItems = await Promise.all(
    catalogItems.map(async item => {
      const data = await getItemData(item.product!.articleNumber!);

      if (!data) throw new Error("Product data missing!");

      return {
        ...item,
        purchasePrice: data.pp,
      };
    })
  );

  const revenue = rootItem.proposedCalculation.priceSubTotal;
  const costProducts = enhanchedCatalogItems.reduce(
    (acc, item) =>
      acc + item.proposedCalculation!.quantity * item.purchasePrice,
    0
  );
  const commission = enhanchedCatalogItems.reduce(
    (acc, item) =>
      acc + item.proposedCalculation!.priceSubTotal * COMMISSION_PERCENTAGE,
    0
  );
  const costInstaller =
    otherItems.reduce(
      (acc, item) => acc + item.proposedCalculation!.priceSubTotal,
      0
    ) + commission;
  const marginValue = revenue - costProducts - costInstaller;
  const marginPercentage = marginValue / revenue;
  const maxDiscountPossible =
    (revenue * (1 - PROFIT_MARGIN_TARGET) - costProducts - costInstaller) /
    (1 - PROFIT_MARGIN_TARGET);

  return {
    revenue,
    costProducts,
    costInstaller,
    marginValue,
    marginPercentage,
    maxDiscountPossible,
  };
}
