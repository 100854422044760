import { useTranslate } from "@tolgee/react";
import { useCallback, useMemo } from "react";
import { uniq } from "lodash-es";

export type ProjectRole =
  | "PROJECT_ADMIN"
  | "PROJECT_MANAGER"
  | "PROJECT_MEMBER";

export function useProjectRoles() {
  const { t } = useTranslate("ProjectRole");

  const projectRoleLabels: Record<ProjectRole, string> = useMemo(
    () => ({
      PROJECT_ADMIN: t("PROJECT_ADMIN"),
      PROJECT_MANAGER: t("PROJECT_MANAGER"),
      PROJECT_MEMBER: t("PROJECT_MEMBER"),
    }),
    [t]
  );

  const getProjectRoleOptions = useCallback(
    (
      roles?:
        | {
            __typename?: "ProjectRole";
            id: string;
            internalName: string;
            label: string;
          }[]
        | undefined
        | null
    ): { value: string; label: string; internalName: string }[] => {
      return (
        roles
          ?.filter(
            role =>
              role?.internalName &&
              projectRoleLabels[role.internalName as ProjectRole]
          )
          .map(role => ({
            value: role.id,
            label: projectRoleLabels[role.internalName as ProjectRole],
            internalName: role.internalName,
          })) ?? []
      );
    },
    [projectRoleLabels]
  );

  const getProjectRoleTitle = useCallback(
    (
      roles?:
        | {
            __typename?: "ProjectRole";
            internalName: string;
          }[]
        | undefined
        | null
    ): string => {
      return uniq(
        roles
          ?.filter(
            role =>
              role?.internalName &&
              projectRoleLabels[role.internalName as ProjectRole]
          )
          .map(role => projectRoleLabels[role.internalName as ProjectRole]) ??
          []
      ).join(", ");
    },
    [projectRoleLabels]
  );

  return { getProjectRoleTitle, getProjectRoleOptions };
}

export type OrganisationRole =
  | "ORG_ADMIN"
  | "ORG_COORDINATOR"
  | "ORG_FINANCE"
  | "ORG_MANAGER"
  | "ORG_MEMBER"
  | "ORG_SALES_MANAGER"
  | "ORG_CONSULTANT";

export function useOrganisationRoles() {
  const { t } = useTranslate("OrganisationRole");

  const organisationRoleLabels: Record<OrganisationRole, string> = useMemo(
    () => ({
      ORG_ADMIN: t("ORG_ADMIN"),
      ORG_COORDINATOR: t("ORG_COORDINATOR"),
      ORG_FINANCE: t("ORG_FINANCE"),
      ORG_MANAGER: t("ORG_MANAGER"),
      ORG_MEMBER: t("ORG_MEMBER"),
      ORG_SALES_MANAGER: t("ORG_SALES_MANAGER"),
      ORG_CONSULTANT: t("ORG_CONSULTANT"),
    }),
    [t]
  );

  const getOrganisationRoleOptions = useCallback(
    (
      roles?:
        | {
            __typename?: "OrganisationRole";
            id: string;
            internalName: string;
            label: string;
          }[]
        | null
        | undefined
    ): { value: string; label: string; internalName: string }[] => {
      return (
        roles
          ?.filter(
            role =>
              role?.internalName &&
              organisationRoleLabels[role.internalName as OrganisationRole]
          )
          .map(role => ({
            value: role.id,
            label:
              organisationRoleLabels[role.internalName as OrganisationRole],
            internalName: role.internalName,
          })) ?? []
      );
    },
    [organisationRoleLabels]
  );

  const getOrganisationRoleTitle = useCallback(
    (
      roles?:
        | {
            __typename?: "OrganisationRole";
            internalName: string;
          }[]
        | null
        | undefined
    ) => {
      return uniq(
        roles
          ?.filter(
            role =>
              role?.internalName &&
              organisationRoleLabels[role.internalName as OrganisationRole]
          )
          .map(
            role =>
              organisationRoleLabels[role.internalName as OrganisationRole]
          ) ?? []
      ).join(", ");
    },
    [organisationRoleLabels]
  );

  return {
    getOrganisationRoleTitle,
    getOrganisationRoleOptions,
  };
}
