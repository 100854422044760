/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptOrganisationMembershipInvitationMutationVariables = Types.Exact<{
  input: Types.AcceptOrganisationMembershipInvitationInput;
}>;


export type AcceptOrganisationMembershipInvitationMutation = { acceptOrganisationMembershipInvitation: { __typename: 'AcceptOrganisationMembershipInvitationResult', user: { __typename: 'Viewer', id: string } } };


export const AcceptOrganisationMembershipInvitationDocument = gql`
    mutation AcceptOrganisationMembershipInvitation($input: AcceptOrganisationMembershipInvitationInput!) {
  acceptOrganisationMembershipInvitation(input: $input) {
    user {
      id
    }
  }
}
    `;
export type AcceptOrganisationMembershipInvitationMutationFn = Apollo.MutationFunction<AcceptOrganisationMembershipInvitationMutation, AcceptOrganisationMembershipInvitationMutationVariables>;

/**
 * __useAcceptOrganisationMembershipInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptOrganisationMembershipInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptOrganisationMembershipInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptOrganisationMembershipInvitationMutation, { data, loading, error }] = useAcceptOrganisationMembershipInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptOrganisationMembershipInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptOrganisationMembershipInvitationMutation, AcceptOrganisationMembershipInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptOrganisationMembershipInvitationMutation, AcceptOrganisationMembershipInvitationMutationVariables>(AcceptOrganisationMembershipInvitationDocument, options);
      }
export type AcceptOrganisationMembershipInvitationMutationHookResult = ReturnType<typeof useAcceptOrganisationMembershipInvitationMutation>;
export type AcceptOrganisationMembershipInvitationMutationResult = Apollo.MutationResult<AcceptOrganisationMembershipInvitationMutation>;
export type AcceptOrganisationMembershipInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptOrganisationMembershipInvitationMutation, AcceptOrganisationMembershipInvitationMutationVariables>;