/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { PimProductCategoryFragmentDoc, ProductSearchItem__ProductSearchResultFragmentDoc } from './Product.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductCategoryTreeSelectQueryVariables = Types.Exact<{
  categoryKey?: Types.InputMaybe<Types.Scalars['String']['input']>;
  supplierId: Types.Scalars['ID']['input'];
}>;


export type ProductCategoryTreeSelectQuery = { pimGetSupplierProductCategoryTree: { __typename: 'MissingCapabilities' } | { __typename: 'PimProductCategoryTreeWithSupplier', tree: { __typename: 'PimProductCategoryTree', children: Array<{ __typename: 'PimProductCategory', id: string, parentId?: string | null, supplierId: string, key: string, title: string }>, parents: Array<{ __typename: 'PimProductCategory', id: string, parentId?: string | null, supplierId: string, key: string, title: string }>, productCategory?: { __typename: 'PimProductCategory', id: string, parentId?: string | null, supplierId: string, key: string, title: string } | null }, supplier?: { __typename: 'Company', id: string, title: string } | null } };


export const ProductCategoryTreeSelectDocument = gql`
    query ProductCategoryTreeSelect($categoryKey: String, $supplierId: ID!) {
  pimGetSupplierProductCategoryTree(
    supplierId: $supplierId
    supplierProductCategoryKey: $categoryKey
  ) {
    ... on PimProductCategoryTreeWithSupplier {
      tree {
        children {
          ...PimProductCategory
        }
        parents {
          ...PimProductCategory
        }
        productCategory {
          ...PimProductCategory
        }
      }
      supplier {
        id
        title
      }
    }
  }
}
    ${PimProductCategoryFragmentDoc}`;

/**
 * __useProductCategoryTreeSelectQuery__
 *
 * To run a query within a React component, call `useProductCategoryTreeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoryTreeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoryTreeSelectQuery({
 *   variables: {
 *      categoryKey: // value for 'categoryKey'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useProductCategoryTreeSelectQuery(baseOptions: Apollo.QueryHookOptions<ProductCategoryTreeSelectQuery, ProductCategoryTreeSelectQueryVariables> & ({ variables: ProductCategoryTreeSelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductCategoryTreeSelectQuery, ProductCategoryTreeSelectQueryVariables>(ProductCategoryTreeSelectDocument, options);
      }
export function useProductCategoryTreeSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoryTreeSelectQuery, ProductCategoryTreeSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductCategoryTreeSelectQuery, ProductCategoryTreeSelectQueryVariables>(ProductCategoryTreeSelectDocument, options);
        }
export function useProductCategoryTreeSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductCategoryTreeSelectQuery, ProductCategoryTreeSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductCategoryTreeSelectQuery, ProductCategoryTreeSelectQueryVariables>(ProductCategoryTreeSelectDocument, options);
        }
export type ProductCategoryTreeSelectQueryHookResult = ReturnType<typeof useProductCategoryTreeSelectQuery>;
export type ProductCategoryTreeSelectLazyQueryHookResult = ReturnType<typeof useProductCategoryTreeSelectLazyQuery>;
export type ProductCategoryTreeSelectSuspenseQueryHookResult = ReturnType<typeof useProductCategoryTreeSelectSuspenseQuery>;
export type ProductCategoryTreeSelectQueryResult = Apollo.QueryResult<ProductCategoryTreeSelectQuery, ProductCategoryTreeSelectQueryVariables>;