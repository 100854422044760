import { LabeledValue } from "@msys/ui";
import { Link } from "@mui/material";
import { FC } from "react";
import { Phone } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";
import { usePhoneTypes } from "./usePhoneTypes.js";

interface Props {
  label: string;
  phones?: Phone[] | null;
}

export const LabeledPhoneValue: FC<Props> = ({ label, phones }) => {
  const { t } = useTranslate(["Global"]);
  const { phoneTypeLabels } = usePhoneTypes();

  return (
    <LabeledValue label={label}>
      {!phones
        ? t("Not set", {
            ns: "Global",
          })
        : phones.length > 0
          ? phones.map((phone, idx) => {
              if (phone.type === "PRIVATE")
                throw new Error("Invalid phone type");
              return (
                <div key={idx}>
                  {phoneTypeLabels[phone.type]}{" "}
                  <Link href={`tel:${phone.number}`}>{phone.number}</Link>
                </div>
              );
            })
          : t("Not set", {
              ns: "Global",
            })}
    </LabeledValue>
  );
};
