import { LabeledValue, ModalOpenButton } from "@msys/ui";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Stack,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { PickBrandLineModal } from "../modals/PickBrandLineModal.js";
import { Delete as DeleteIcon } from "@mui/icons-material";

interface BrandLine {
  id: string;
  title: string;
  brandId: string;
}

interface Props {
  label?: string;
  valueLabel?: string;
  brandLine?: BrandLine | null;
  brandId?: string | null;
  handleChange: (brandLine: BrandLine | null) => void;
  canEdit?: boolean;
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
}
export function PickBrandLineButton({
  label,
  valueLabel,
  brandLine,
  brandId,
  handleChange,
  canEdit = true,
  buttonProps,
  iconButtonProps,
}: Props) {
  const { t } = useTranslate("ProductOverview");

  if (!canEdit && !brandLine) return null;

  if (brandLine) {
    return (
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <LabeledValue label={valueLabel ?? t("Product type")}>
          {brandLine.title}
        </LabeledValue>
        {canEdit && (
          <ModalOpenButton
            Modal={PickBrandLineModal}
            modalProps={{
              brandLine: brandLine,
              brandId: brandId,
              handleComplete: handleChange,
            }}
          >
            <IconButton size="small" color="secondary" {...iconButtonProps}>
              <EditIcon />
            </IconButton>
          </ModalOpenButton>
        )}
        {canEdit && (
          <IconButton
            size="small"
            color="secondary"
            {...iconButtonProps}
            onClick={() => handleChange(null)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="flex-start">
      <LabeledValue label={valueLabel ?? t("Product type")}>
        <ModalOpenButton
          Modal={PickBrandLineModal}
          modalProps={{
            brandLine: brandLine,
            brandId: brandId,
            handleComplete: handleChange,
          }}
        >
          <Button
            size="small"
            variant="text"
            color="secondary"
            {...buttonProps}
          >
            {label ?? t("Assign product type")}
          </Button>
        </ModalOpenButton>
      </LabeledValue>
    </Stack>
  );
}
