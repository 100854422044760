import React from "react";
import { SelectedUserContext } from "./SelectedUserContext.js";
import { useLocalStorage, useSessionStorage } from "react-use";
import { useAuth } from "./useAuth.js";

export const SelectedUserProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { auth } = useAuth();
  const [selectedUserId, setSelectedUserId] = useSelectedUserStore(
    auth.idTokenParsed?.sub ?? null
  );

  return (
    <SelectedUserContext.Provider value={{ selectedUserId, setSelectedUserId }}>
      {children}
    </SelectedUserContext.Provider>
  );
};

type Value = string | null;
const KEY = "msys-selected-user";

function useSelectedUserStore(keycloakSubjectId: string | null) {
  const key = `${KEY}-${keycloakSubjectId}`;

  const [selectedUserFromLocal, setSelectedUserForLocal] =
    useLocalStorage<Value>(key, null);

  const [selectedUserFromSession, setSelectedUserForSession] =
    useSessionStorage<Value>(key, selectedUserFromLocal);

  const setSelectedUser = React.useCallback(
    (value: Value) => {
      setSelectedUserForSession(value);
      setSelectedUserForLocal(value);
    },
    [setSelectedUserForLocal, setSelectedUserForSession]
  );

  return [selectedUserFromSession, setSelectedUser] as const;
}
