import { gql } from "@apollo/client";
import { assertNever } from "@msys/common";
import { Select } from "@msys/ui";
import { Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import { Stack } from "../../commons/layout/Stack.js";
import { usePropertyHelpers } from "../doc-items/usePropertyHelpers.js";
import { PimProductAlternativePropertyValueFragment } from "./Product.generated.js";
import {
  SupplierProductAlternativePicker__PimSupplierProductAlternativesAlternativeValuesProductFragment,
  SupplierProductAlternativePicker__PimSupplierProductAlternativesProductFragment,
  SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternativeFragment,
} from "./SupplierProductAlternativePicker.generated.js";

export type OnProductSelectFn = (
  product: SupplierProductAlternativePicker__PimSupplierProductAlternativesProductFragment
) => Promise<void> | void;

type Product = {
  id: string;
  articleNumber: string;
  supplierId: string;
  texts?: { title?: string | null | undefined } | null | undefined;
};

type Props = {
  product: Product;
  productAlternatives: {
    propertyAlternatives: SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternativeFragment[];
    propertyMatchingProductAlternatives: SupplierProductAlternativePicker__PimSupplierProductAlternativesProductFragment[];
  };
  onProductSelect: OnProductSelectFn;
};

export function SupplierProductAlternativePicker({
  product,
  productAlternatives,
  onProductSelect,
}: Props) {
  const { getBoolLabel, getNumberLabel, getUnitLabel } = usePropertyHelpers();

  const { sortedPropertyAlternatives } = useSortedPropertyAlternatives(
    productAlternatives?.propertyAlternatives
  );

  if (!productAlternatives) {
    return null;
  }
  if (
    sortedPropertyAlternatives.length === 0 &&
    productAlternatives.propertyMatchingProductAlternatives.length === 0
  ) {
    return null;
  }

  return (
    <Stack style={{ flexDirection: "column" }}>
      <Grid container spacing={1}>
        {sortedPropertyAlternatives.map(propertyAlternative => {
          return (
            <Grid
              item
              xs={6}
              key={propertyAlternative.referenceProperty.prop2.key}
            >
              <SupplierProductPropertyAlternativeSelect
                propertyAlternative={propertyAlternative}
                onProductSelect={onProductSelect}
                getLabel={{ getBoolLabel, getNumberLabel, getUnitLabel }}
              />
            </Grid>
          );
        })}
      </Grid>
      {productAlternatives.propertyMatchingProductAlternatives.length > 0 && (
        <SupplierProductPropertyMatchingProductAlternativesSelect
          product={product}
          propertyMatchingProductAlternatives={
            productAlternatives.propertyMatchingProductAlternatives
          }
          onProductSelect={onProductSelect}
        />
      )}
    </Stack>
  );
}

export function SupplierProductPropertyAlternativeSelect({
  propertyAlternative,
  onProductSelect,
  getLabel: { getBoolLabel, getNumberLabel, getUnitLabel },
  valueNotSetLabel = "-",
}: {
  propertyAlternative: SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternativeFragment;
  onProductSelect: OnProductSelectFn;
  getLabel: Pick<
    ReturnType<typeof usePropertyHelpers>,
    "getBoolLabel" | "getNumberLabel" | "getUnitLabel"
  >;
  valueNotSetLabel?: string;
}) {
  const options = [
    {
      label: getPropertyAlternativeValueLabel(
        propertyAlternative.value,
        {
          getBoolLabel,
          getNumberLabel,
          getUnitLabel,
        },
        valueNotSetLabel
      ),
      value: "current" as
        | "current"
        | SupplierProductAlternativePicker__PimSupplierProductAlternativesAlternativeValuesProductFragment[],
      greyedOut: false,
      originValue: propertyAlternative.value,
    },
  ]
    .concat(
      propertyAlternative.alternativeValues.map(alternativeValue => ({
        label: getPropertyAlternativeValueLabel(
          alternativeValue.value,
          {
            getBoolLabel,
            getNumberLabel,
            getUnitLabel,
          },
          valueNotSetLabel
        ),
        value: alternativeValue.products,
        originValue: alternativeValue.value,
        greyedOut:
          alternativeValue.products[0]?.mismatchPropertyKeys?.length > 0,
      }))
    )
    .sort((e1, e2) => {
      if (
        (e1.originValue === null || e1.originValue === undefined) &&
        (e2.originValue === null || e2.originValue === undefined)
      ) {
        return 0;
      }
      if (e1.originValue === null || e1.originValue === undefined) {
        return 1;
      }
      if (e2.originValue === null || e2.originValue === undefined) {
        return -1;
      }

      if (
        e1.originValue.__typename ===
          "PimProductAlternativePropertyValueNumber" &&
        e2.originValue.__typename === "PimProductAlternativePropertyValueNumber"
      ) {
        return e1.originValue.valueNumber - e2.originValue.valueNumber;
      }

      if (
        e1.originValue.__typename ===
          "PimProductAlternativePropertyValueText" &&
        e2.originValue.__typename === "PimProductAlternativePropertyValueText"
      ) {
        return e1.originValue.valueText.localeCompare(e1.originValue.valueText);
      }

      if (
        e1.originValue.__typename ===
          "PimProductAlternativePropertyValueBool" &&
        e2.originValue.__typename === "PimProductAlternativePropertyValueBool"
      ) {
        return (
          Number(e1.originValue.valueBool) - Number(e2.originValue.valueBool)
        );
      }

      if (
        e1.originValue.__typename ===
          "PimProductAlternativePropertyValueRange" &&
        e2.originValue.__typename === "PimProductAlternativePropertyValueRange"
      ) {
        return e1.originValue.valueRange.min != e2.originValue.valueRange.min
          ? e1.originValue.valueRange.min - e2.originValue.valueRange.min
          : e1.originValue.valueRange.max - e2.originValue.valueRange.max;
      }

      return 0;
    });

  return (
    <Select
      label={propertyAlternative.referenceProperty.prop2.label}
      options={options ?? []}
      value={"current"}
      onChange={newValue => {
        if (newValue !== "current" && newValue.length > 0) {
          onProductSelect(newValue[0].product);
        }
      }}
    />
  );
}

export function SupplierProductPropertyMatchingProductAlternativesSelect({
  product,
  propertyMatchingProductAlternatives,
  onProductSelect,
}: {
  product: Product;
  propertyMatchingProductAlternatives: SupplierProductAlternativePicker__PimSupplierProductAlternativesProductFragment[];
  onProductSelect: OnProductSelectFn;
}) {
  const { t } = useTranslate(["Product"]);
  return propertyMatchingProductAlternatives.length > 0 ? (
    <Select
      label={t("Article number", { ns: "Product" })}
      options={[
        {
          label: `${product.articleNumber} ${product.texts?.title ?? ""}`,
          value: "current" as
            | "current"
            | SupplierProductAlternativePicker__PimSupplierProductAlternativesProductFragment,
        },
      ].concat(
        propertyMatchingProductAlternatives.map(a => ({
          label: `${a.articleNumber} ${a.title}`,
          value: a,
        }))
      )}
      value={"current"}
      onChange={newValue => {
        if (newValue !== "current") {
          onProductSelect(newValue);
        }
      }}
    />
  ) : null;
}

export function useSortedPropertyAlternatives(
  propertyAlternatives:
    | SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternativeFragment[]
    | undefined
    | null
) {
  const sortedPropertyAlternatives = useMemo(() => {
    if (propertyAlternatives) {
      return Array.from(propertyAlternatives).sort((e1, e2) => {
        if (
          (e1.value === null || e1.value === undefined) &&
          e2.value !== null &&
          e2.value !== undefined
        ) {
          return 1;
        }

        if (
          (e2.value === null || e2.value === undefined) &&
          e1.value !== null &&
          e1.value !== undefined
        ) {
          return -1;
        }

        return e1.referenceProperty.prop2.label.localeCompare(
          e2.referenceProperty.prop2.label
        );
      });
    }

    return [];
  }, [propertyAlternatives]);

  return { sortedPropertyAlternatives };
}

function getPropertyAlternativeValueLabel(
  value: PimProductAlternativePropertyValueFragment | undefined | null,
  getLabel: Pick<
    ReturnType<typeof usePropertyHelpers>,
    "getBoolLabel" | "getNumberLabel" | "getUnitLabel"
  >,
  valueNotSetLabel: string
): string {
  if (!value) {
    return valueNotSetLabel;
  }

  const { getBoolLabel, getNumberLabel, getUnitLabel } = getLabel;

  switch (value.__typename) {
    case "PimProductAlternativePropertyValueBool": {
      return getBoolLabel(value.valueBool);
    }
    case "PimProductAlternativePropertyValueNumber": {
      return `${getNumberLabel(value.valueNumber)}${
        value.unit ? getUnitLabel(value.unit) : ""
      }`;
    }
    case "PimProductAlternativePropertyValueRange": {
      return `${getNumberLabel(value.valueRange.min)} - ${getNumberLabel(
        value.valueRange.max
      )}${value.unit ? getUnitLabel(value.unit) : ""}`;
    }
    case "PimProductAlternativePropertyValueText": {
      return value.valueText;
    }
    default:
      assertNever(value);
  }
}
