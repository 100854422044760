import { gql } from "@apollo/client";
import { getPictures, stripHtmlTags } from "@msys/common";
import { TypographyWithMaxNumberOfLines, useFormatting } from "@msys/ui";
import { Check as CheckIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isUndefined } from "lodash-es";
import defaultImageUrl from "../../assets/images/no-image.png";
import { PropertiesLabeledValue } from "../../commons/PropertiesLabeledValue.js";
import { cssValue } from "../../../common/MuiThemeProvider.js";
import { useQuoteItemProperties } from "../item-properties/itemProperties.js";
import {
  DecisionOptionListItem_ItemFragment,
  DecisionOptionListItem_ParentItemFragment,
} from "./DecisionOptionListItem.generated.js";
import { isItemGreyedOut } from "./helpers.js";

interface Props {
  item: DecisionOptionListItem_ItemFragment;
  parentItem: DecisionOptionListItem_ParentItemFragment;
  layout?: "vertical" | "horizontal";
  onClick?: () => void;
  onMoreClick?: () => void;
  selected?: boolean;
  visiblePropertiesCount?: number;
  disabled?: boolean;
  loading?: boolean;
  handleDecisionPreselectionChange: (
    itemId: string,
    subItemIds: string[]
  ) => void | Promise<void>;
  eliminateDecisionOption: (itemId: string) => Promise<void>;
  resetEliminateDecisionOption: (itemId: string) => Promise<void>;
  hideDiscard?: boolean;
}

export const DecisionOptionListItem = ({
  item,
  parentItem,
  layout = "horizontal",
  onClick,
  onMoreClick,
  selected: isSelected = false,
  visiblePropertiesCount,
  loading,
  disabled,
  handleDecisionPreselectionChange,
  eliminateDecisionOption,
  resetEliminateDecisionOption,
  hideDiscard,
}: Props) => {
  const { t } = useTranslate(["Global", "QuoteItem"]);
  const { getFormattedPrice } = useFormatting();

  const [selfProperties, productProperties] = useQuoteItemProperties(item);

  const properties = [...selfProperties, ...productProperties];

  const title = !isUndefined(item.pendingChangeAttributes["title"])
    ? item.pendingChangeAttributes["title"]
    : item.title;

  const pathForPdf = !isUndefined(item.pendingChangeAttributes["pathForPdf"])
    ? item.pendingChangeAttributes["pathForPdf"]
    : item.pathForPdf;

  const description = !isUndefined(item.pendingChangeAttributes["description"])
    ? item.pendingChangeAttributes["description"]
    : item.description;

  const productDescription =
    item.pendingChangeAttributes.productDescription &&
    item.pendingChangeAttributes.productDescriptionClientVisibility !== "hide"
      ? item.pendingChangeAttributes.productDescription
      : item.product &&
          item.product.description &&
          item.product.descriptionClientVisibility !== "hide"
        ? item.product.description
        : null;

  const pictures = getPictures(item.attachments);

  const propertiesColumnCount = layout === "horizontal" ? 2 : 2;
  const propertiesShowAmount = visiblePropertiesCount ?? propertiesColumnCount;
  const priceSubTotal = item.proposedCalculation?.priceSubTotal || 0;

  const isGreyedOut = isItemGreyedOut(item);
  const isEliminated = !!item.decisionOptionElimination;
  const isPreselected = item.decisionOptionIsPreselected;

  const handlePreselectionClick = async () => {
    if (parentItem.decisionBehaviorOfSubitems === "SELECT_ONE") {
      if (isPreselected) {
        await handleDecisionPreselectionChange(parentItem.id, []);
      } else {
        await handleDecisionPreselectionChange(parentItem.id, [item.id]);
      }
    } else if (parentItem.decisionBehaviorOfSubitems === "SELECT_MANY") {
      if (isPreselected) {
        await handleDecisionPreselectionChange(
          parentItem.id,
          parentItem.decisionSubitemsPreselection.filter(id => id !== item.id)
        );
      } else {
        await handleDecisionPreselectionChange(parentItem.id, [
          ...parentItem.decisionSubitemsPreselection,
          item.id,
        ]);
      }
    }
  };

  const buttons = isEliminated ? (
    <Button
      size="medium"
      color="secondary"
      variant={"outlined"}
      disabled={loading || disabled}
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();
        await resetEliminateDecisionOption(item.id);
      }}
    >
      {t("Restore", {
        ns: "QuoteItem",
      })}
    </Button>
  ) : (
    <Stack direction="row" alignItems="center" spacing={1}>
      {!hideDiscard && (
        <Button
          size="medium"
          color="error"
          variant={"outlined"}
          disabled={loading || disabled}
          startIcon={
            <Tooltip
              title={t("Discard", {
                ns: "QuoteItem",
              })}
            >
              <CloseIcon />
            </Tooltip>
          }
          sx={getButtonIconStyle}
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();

            await eliminateDecisionOption(item.id);
          }}
        />
      )}
      <Button
        size="medium"
        color="success"
        variant={isPreselected ? "contained" : "outlined"}
        disabled={loading || disabled || isEliminated}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handlePreselectionClick();
        }}
        startIcon={
          <Tooltip
            title={
              isPreselected
                ? t("Unselect", {
                    ns: "Global",
                  })
                : t("Select", {
                    ns: "Global",
                  })
            }
          >
            <CheckIcon />
          </Tooltip>
        }
        sx={getButtonIconStyle}
      />
    </Stack>
  );

  return (
    <Paper
      onClick={onClick}
      sx={theme => ({
        cursor: onClick ? "pointer" : "default",
        textAlign: "left",
        transition: "background-color 0.2s ease-out",
        padding: 1,
        borderRadius: "4px",
        width: "100%",
        display: "flex",
        justifyContent: "stretch",
        backgroundColor: isGreyedOut
          ? theme.palette.grey[100]
          : theme.palette.common.white,
        color: isEliminated ? theme.palette.grey[600] : "inherit",
        ...(!isPreselected
          ? {
              "&:hover:not(:has(button:hover)):not(:has(a:hover))": {
                backgroundColor: isGreyedOut
                  ? theme.palette.grey[200]
                  : theme.palette.grey[100],
              },
            }
          : undefined),
        ...(isPreselected
          ? {
              boxShadow: cssValue.preselectedBorderShadow,
              borderColor: "transparent",
            }
          : undefined),
        ...(isSelected
          ? {
              boxShadow: cssValue.selectedBorderShadow,
              borderColor: "transparent",
            }
          : undefined),
      })}
    >
      <Stack
        direction={layout === "horizontal" ? "row" : "column"}
        justifyContent="flex-start"
        spacing={2}
        width="100%"
        flex={1}
      >
        <Box
          flexShrink="0"
          flexGrow="0"
          width={layout === "horizontal" ? "calc(100% / 3)" : "100%"}
          overflow="hidden"
          display="flex"
          sx={{ aspectRatio: layout === "horizontal" ? "4 / 3" : "16 / 9" }}
        >
          <img
            draggable={false}
            src={pictures?.[0]?.url || defaultImageUrl}
            alt={title}
            style={{
              height: "100%",
              width: "100%",
              objectFit: layout === "horizontal" ? "cover" : "contain",
            }}
          />
        </Box>

        <Stack
          direction="column"
          minWidth={0}
          flex={1}
          spacing={1}
          justifyContent="space-between"
        >
          <Stack direction="column" minWidth={0} flex={1} spacing={1} pt={1}>
            <TypographyWithMaxNumberOfLines variant={"h3"} $maxLines={2}>
              {title}
            </TypographyWithMaxNumberOfLines>

            {description ? (
              <TypographyWithMaxNumberOfLines $maxLines={2} variant="body2">
                {stripHtmlTags(description, true)}
              </TypographyWithMaxNumberOfLines>
            ) : productDescription ? (
              <TypographyWithMaxNumberOfLines $maxLines={2} variant="body2">
                {stripHtmlTags(productDescription, true)}
              </TypographyWithMaxNumberOfLines>
            ) : null}

            {properties.length > 0 ? (
              <Box>
                <PropertiesLabeledValue
                  properties={properties.slice(0, propertiesShowAmount)}
                  columns={propertiesColumnCount}
                />
              </Box>
            ) : null}
            {properties.length > propertiesShowAmount && onMoreClick && (
              <Box>
                <Button
                  size="extra-small"
                  color="secondary"
                  sx={{ ml: -1 }}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    onMoreClick();
                  }}
                >
                  {t("Show more", {
                    ns: "Global",
                  })}
                </Button>
              </Box>
            )}
          </Stack>

          <Stack
            direction="row"
            minWidth={0}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {!item.isPriceHidden && priceSubTotal > 0 && (
                <Typography variant="h3" component="div" fontWeight="bold">
                  {getFormattedPrice(priceSubTotal)}
                </Typography>
              )}
            </Box>
            <Box>{buttons}</Box>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

const getButtonIconStyle = (theme: Theme) => ({
  minWidth: "auto",
  padding: "7px 7px",
  borderRadius: "50%",
  [".MuiButton-startIcon"]: {
    marginLeft: 0,
    marginRight: 0,
  },
  [".MuiSvgIcon-root"]: {
    fontSize: "1.5rem",
  },
  boxShadow: (theme.shadows as string[])[1],
});
