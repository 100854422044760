/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { DecisionOptionButton_DocumentItemFragmentDoc } from '../fields/DecisionOptionRadioOrCheckbox.generated.js';
import { ItemAttributeExpressionsFragmentDoc } from '../properties.generated.js';
import { DocumentStatus_DocumentFragmentDoc, DocumentInterpolatedTexts_DocumentFragmentDoc, ProposedCalculations_ItemFragmentDoc } from '../hooks/useDecisionItemMutations.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RulesBox_ItemFragment = { __typename: 'Item', id: string, parentId?: string | null, decisionIsContingentItem: boolean, decisionContingentItemPreselection?: boolean | null, isDecisionOption: boolean, isItemEliminated: boolean, decisionOptionBehaviour: Types.DecisionBehaviorOfSubitems, decisionOptionIsPreselected?: boolean | null, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null, attributeExpressions: Array<{ __typename: 'ItemAttributeExpression', attribute: string, expr: string, result: any, missingValue: boolean }>, wizardSettings: { __typename: 'ItemWizardSettings', askWhenContingent: Array<Types.AskWhen>, askWhomContingent: Array<Types.AskWhom>, promptContingent: string } };

export type RulesBox_QuestionControlForm_ItemFragment = { __typename: 'Item', id: string, wizardSettings: { __typename: 'ItemWizardSettings', askWhenContingent: Array<Types.AskWhen>, askWhomContingent: Array<Types.AskWhom>, promptContingent: string } };

export type RulesBox_QuestionControlForm_ModifyWizardSettingsMutationVariables = Types.Exact<{
  input: Types.ModifyItemWizardSettingsInput;
}>;


export type RulesBox_QuestionControlForm_ModifyWizardSettingsMutation = { modifyItemWizardSettings: { __typename: 'ModifyItemWizardSettingsResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, wizardSettings: { __typename: 'ItemWizardSettings', askWhenContingent: Array<Types.AskWhen>, askWhomContingent: Array<Types.AskWhom>, promptContingent: string } }, doc: { __typename: 'Building', id: string } | { __typename: 'Quote', id: string, isPublished: boolean, isBinding: boolean, needsAgreement: boolean, agreement: Types.Agreement } | { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, accessType: Types.TemplateAccessType, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, containsUnpublishedChanged: boolean, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null } | { __typename: 'Requirement', id: string, isPublished: boolean } } };

export const RulesBox_QuestionControlForm_ItemFragmentDoc = gql`
    fragment RulesBox_QuestionControlForm_Item on Item {
  id
  wizardSettings {
    askWhenContingent
    askWhomContingent
    promptContingent
  }
}
    `;
export const RulesBox_ItemFragmentDoc = gql`
    fragment RulesBox_Item on Item {
  id
  parentId
  decisionIsContingentItem
  decisionContingentItemPreselection
  isDecisionOption
  isItemEliminated
  ...DecisionOptionButton_DocumentItem
  ...ItemAttributeExpressions
  ...RulesBox_QuestionControlForm_Item
}
    ${DecisionOptionButton_DocumentItemFragmentDoc}
${ItemAttributeExpressionsFragmentDoc}
${RulesBox_QuestionControlForm_ItemFragmentDoc}`;
export const RulesBox_QuestionControlForm_ModifyWizardSettingsDocument = gql`
    mutation RulesBox_QuestionControlForm_ModifyWizardSettings($input: ModifyItemWizardSettingsInput!) {
  modifyItemWizardSettings(input: $input) {
    item {
      id
      originVersionNumber
      ...RulesBox_QuestionControlForm_Item
    }
    doc {
      ...DocumentStatus_Document
    }
  }
}
    ${RulesBox_QuestionControlForm_ItemFragmentDoc}
${DocumentStatus_DocumentFragmentDoc}`;
export type RulesBox_QuestionControlForm_ModifyWizardSettingsMutationFn = Apollo.MutationFunction<RulesBox_QuestionControlForm_ModifyWizardSettingsMutation, RulesBox_QuestionControlForm_ModifyWizardSettingsMutationVariables>;

/**
 * __useRulesBox_QuestionControlForm_ModifyWizardSettingsMutation__
 *
 * To run a mutation, you first call `useRulesBox_QuestionControlForm_ModifyWizardSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRulesBox_QuestionControlForm_ModifyWizardSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rulesBoxQuestionControlFormModifyWizardSettingsMutation, { data, loading, error }] = useRulesBox_QuestionControlForm_ModifyWizardSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRulesBox_QuestionControlForm_ModifyWizardSettingsMutation(baseOptions?: Apollo.MutationHookOptions<RulesBox_QuestionControlForm_ModifyWizardSettingsMutation, RulesBox_QuestionControlForm_ModifyWizardSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RulesBox_QuestionControlForm_ModifyWizardSettingsMutation, RulesBox_QuestionControlForm_ModifyWizardSettingsMutationVariables>(RulesBox_QuestionControlForm_ModifyWizardSettingsDocument, options);
      }
export type RulesBox_QuestionControlForm_ModifyWizardSettingsMutationHookResult = ReturnType<typeof useRulesBox_QuestionControlForm_ModifyWizardSettingsMutation>;
export type RulesBox_QuestionControlForm_ModifyWizardSettingsMutationResult = Apollo.MutationResult<RulesBox_QuestionControlForm_ModifyWizardSettingsMutation>;
export type RulesBox_QuestionControlForm_ModifyWizardSettingsMutationOptions = Apollo.BaseMutationOptions<RulesBox_QuestionControlForm_ModifyWizardSettingsMutation, RulesBox_QuestionControlForm_ModifyWizardSettingsMutationVariables>;