import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import { FilterChip } from "../../commons/filters/FilterChip.js";
import { useCrmCompanySelectQuery } from "./CrmCompanySelect.generated.js";

export function CrmCompanyFilterChip({
  label,
  value,
  setValue,
}: {
  label?: string;
  value: string | null | undefined;
  setValue: (newValue: string | null | undefined) => void;
}) {
  const { t } = useTranslate(["Projects"]);

  const client = useApolloClient();
  const query = useCrmCompanySelectQuery({
    client,
    variables: { limit: 1, filterIds: value ? [value] : [] },
    skip: !value,
  });

  const crmCompany = getDataOrNull(query.data?.crmCompanies)?.edges[0]?.node;
  if (!crmCompany) return null;

  return (
    <FilterChip
      label={label ?? t("CRM contact", { ns: "Projects" })}
      resetValue={null}
      setValue={setValue}
      value={crmCompany.title}
    />
  );
}
