import { Modal } from "@msys/ui";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { Stack } from "../layout/Stack.js";

interface Props {
  title?: string;
  spacing?: number;
  handleClose: () => void;
  handleApply: () => void | Promise<void>;
  handleReset: () => void | Promise<void>;
}

export function FilterModal({
  title,
  spacing = 1,
  handleClose,
  handleApply,
  handleReset,
  children,
}: React.PropsWithChildren<Props>) {
  const { t } = useTranslate("Global");

  return (
    <Modal
      handleClose={handleClose}
      dialogProps={{ maxWidth: "xs" }}
      title={title}
      actionButtons={[
        {
          label: t("Reset"),
          handleClick: async () => {
            await handleReset();
            handleClose();
          },
          buttonProps: { variant: "text" },
        },
        {
          label: t("Apply"),
          handleClick: async () => {
            await handleApply();
            handleClose();
          },
          buttonProps: {
            color: "primary",
            variant: "contained",
          },
        },
      ]}
      notInStack
    >
      <Stack flexDirection="column" spacing={spacing}>
        {children}
      </Stack>
    </Modal>
  );
}
