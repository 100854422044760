import { Checkbox } from "@mui/material";
import { VirtualItem } from "../../../trees-virtual/types.js";

export function ContingencyCheckbox<T extends VirtualItem>({
  disabled = false,
  loading = false,
  handleContingencyPreselectionChange,
  item,
  canEdit,
}: {
  disabled?: boolean;
  loading?: boolean;
  handleContingencyPreselectionChange: (
    itemId: string,
    preselection: boolean
  ) => void | Promise<void>;
  item: T;
  canEdit: boolean;
}) {
  const checkboxDisabled = disabled || loading || !canEdit;

  return item.decisionIsContingentItem ? (
    <Checkbox
      size="small"
      color={checkboxDisabled ? undefined : "default"}
      disabled={checkboxDisabled}
      checked={Boolean(item.decisionContingentItemPreselection)}
      indeterminate={item.decisionContingentItemPreselection === null}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        handleContingencyPreselectionChange(
          item.id,
          !item.decisionContingentItemPreselection
        );
      }}
    />
  ) : null;
}
