import React from "react";
import { MenuButton, MenuItemWithIcon, Tabs } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { Ballot as BallotIcon } from "@mui/icons-material";
import { Check as CheckIcon } from "@mui/icons-material";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";
import { FilterIcon } from "../../commons/filters/FilterButton.js";
import { Badge } from "@mui/material";

export type DecisionOptionsType = "all" | "selected" | "discarded";

interface Props {
  value: DecisionOptionsType;
  onChange(value: DecisionOptionsType): void;
}

export const DecisionOptionsTypeTabs = ({ value, onChange }: Props) => {
  const { t } = useTranslate(["Decisions", "Global"]);

  const options = React.useMemo(
    (): {
      value: DecisionOptionsType;
      label: string;
      icon: React.ReactElement;
    }[] => [
      {
        value: "all",
        label: t("All", {
          ns: "Global",
        }),
        icon: <BallotIcon />,
      },
      {
        value: "selected",
        label: t("Selected", {
          ns: "Decisions",
        }),
        icon: <CheckIcon />,
      },
      {
        value: "discarded",
        label: t("Discarded", {
          ns: "Decisions",
        }),
        icon: <DeleteOutlineIcon />,
      },
    ],
    [t]
  );

  return (
    <Tabs
      condensed={true}
      onChange={onChange}
      value={value}
      options={options}
    />
  );
};

export const DecisionOptionsFilterMenu = ({ value, onChange }: Props) => {
  const { t } = useTranslate(["Decisions", "Global"]);

  return (
    <MenuButton
      Icon={
        <Badge color="warning" variant="dot" invisible={value === "all"}>
          <FilterIcon />
        </Badge>
      }
    >
      <MenuItemWithIcon
        icon={<BallotIcon />}
        selected={value === "all"}
        onClick={() => onChange("all")}
      >
        {t("All", {
          ns: "Global",
        })}
      </MenuItemWithIcon>
      <MenuItemWithIcon
        icon={<CheckIcon />}
        selected={value === "selected"}
        onClick={() => onChange("selected")}
      >
        {t("Selected", {
          ns: "Decisions",
        })}
      </MenuItemWithIcon>
      <MenuItemWithIcon
        icon={<DeleteOutlineIcon />}
        selected={value === "discarded"}
        onClick={() => onChange("discarded")}
      >
        {t("Discarded", {
          ns: "Decisions",
        })}
      </MenuItemWithIcon>
    </MenuButton>
  );
};
