/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationScheduleSettingsBox_ViewerOrganisationFragment = { __typename: 'ViewerOrganisation', id: string, timezone: string };

export type OrganisationScheduleSettingsBox_DefaultsFragment = { __typename: 'OrganisationDefaults', id: string, defaultStartWorkDay?: string | null, defaultEndWorkDay?: string | null, defaultProjectDuration: number, defaultTicketDuration: number };

export type OrganisationScheduleSettingsBox_ModifyOrganisationMutationVariables = Types.Exact<{
  input: Types.OrganisationUpdateInput;
}>;


export type OrganisationScheduleSettingsBox_ModifyOrganisationMutation = { updateOrganisation: { __typename: 'OrganisationUpdateResult', organisation: { __typename: 'ViewerOrganisation', id: string, timezone: string } } };

export type OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutationVariables = Types.Exact<{
  input: Types.OrganisationDefaultsUpdateInput;
}>;


export type OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation = { updateOrganisationDefaults: { __typename: 'OrganisationDefaultsUpdateResult', organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultStartWorkDay?: string | null, defaultEndWorkDay?: string | null, defaultProjectDuration: number, defaultTicketDuration: number } } };

export const OrganisationScheduleSettingsBox_ViewerOrganisationFragmentDoc = gql`
    fragment OrganisationScheduleSettingsBox_ViewerOrganisation on ViewerOrganisation {
  id
  timezone
}
    `;
export const OrganisationScheduleSettingsBox_DefaultsFragmentDoc = gql`
    fragment OrganisationScheduleSettingsBox_Defaults on OrganisationDefaults {
  id
  defaultStartWorkDay
  defaultEndWorkDay
  defaultProjectDuration
  defaultTicketDuration
}
    `;
export const OrganisationScheduleSettingsBox_ModifyOrganisationDocument = gql`
    mutation OrganisationScheduleSettingsBox_ModifyOrganisation($input: OrganisationUpdateInput!) {
  updateOrganisation(input: $input) {
    organisation {
      id
      ...OrganisationScheduleSettingsBox_ViewerOrganisation
    }
  }
}
    ${OrganisationScheduleSettingsBox_ViewerOrganisationFragmentDoc}`;
export type OrganisationScheduleSettingsBox_ModifyOrganisationMutationFn = Apollo.MutationFunction<OrganisationScheduleSettingsBox_ModifyOrganisationMutation, OrganisationScheduleSettingsBox_ModifyOrganisationMutationVariables>;

/**
 * __useOrganisationScheduleSettingsBox_ModifyOrganisationMutation__
 *
 * To run a mutation, you first call `useOrganisationScheduleSettingsBox_ModifyOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationScheduleSettingsBox_ModifyOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationScheduleSettingsBoxModifyOrganisationMutation, { data, loading, error }] = useOrganisationScheduleSettingsBox_ModifyOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationScheduleSettingsBox_ModifyOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationScheduleSettingsBox_ModifyOrganisationMutation, OrganisationScheduleSettingsBox_ModifyOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationScheduleSettingsBox_ModifyOrganisationMutation, OrganisationScheduleSettingsBox_ModifyOrganisationMutationVariables>(OrganisationScheduleSettingsBox_ModifyOrganisationDocument, options);
      }
export type OrganisationScheduleSettingsBox_ModifyOrganisationMutationHookResult = ReturnType<typeof useOrganisationScheduleSettingsBox_ModifyOrganisationMutation>;
export type OrganisationScheduleSettingsBox_ModifyOrganisationMutationResult = Apollo.MutationResult<OrganisationScheduleSettingsBox_ModifyOrganisationMutation>;
export type OrganisationScheduleSettingsBox_ModifyOrganisationMutationOptions = Apollo.BaseMutationOptions<OrganisationScheduleSettingsBox_ModifyOrganisationMutation, OrganisationScheduleSettingsBox_ModifyOrganisationMutationVariables>;
export const OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsDocument = gql`
    mutation OrganisationScheduleSettingsBox_ModifyOrganisationDefaults($input: OrganisationDefaultsUpdateInput!) {
  updateOrganisationDefaults(input: $input) {
    organisationDefaults {
      id
      ...OrganisationScheduleSettingsBox_Defaults
    }
  }
}
    ${OrganisationScheduleSettingsBox_DefaultsFragmentDoc}`;
export type OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutationFn = Apollo.MutationFunction<OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation, OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutationVariables>;

/**
 * __useOrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation__
 *
 * To run a mutation, you first call `useOrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationScheduleSettingsBoxModifyOrganisationDefaultsMutation, { data, loading, error }] = useOrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation, OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation, OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutationVariables>(OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsDocument, options);
      }
export type OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutationHookResult = ReturnType<typeof useOrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation>;
export type OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutationResult = Apollo.MutationResult<OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation>;
export type OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutationOptions = Apollo.BaseMutationOptions<OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutation, OrganisationScheduleSettingsBox_ModifyOrganisationDefaultsMutationVariables>;