import { useApolloClient } from "@apollo/client";
import { getDataOrNull, getFiles } from "@msys/common";
import { MenuButton, useScreenWidth } from "@msys/ui";
import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { uniqBy } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { useUserData } from "../../auth/useUserData.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageHeader } from "../../commons/layout/PageHeader.js";
import { ContentBox } from "../../features/contents/ContentBox.js";
import { ImportGaebButton } from "../../features/importExport/gaebImport/ImportGaebButton.js";
import { ImportXiopdButton } from "../../features/importExport/xiopdImport/ImportXiopdButton.js";
import { ProjectPhaseChip } from "../../features/projects/ProjectPhaseChip.js";
import { useModifyProjectMutation } from "../../features/projects/Projects.generated.js";
import { TicketStatusChip } from "../../features/projects/TicketStatusBadge.js";
import { ProjectOpportunityAlert } from "../../features/projects/alerts/ProjectOpportunityAlert.js";
import { ProjectOverdueAlert } from "../../features/projects/alerts/ProjectOverdueAlert.js";
import { ProjectSubOriginAlert } from "../../features/projects/alerts/ProjectSubOriginAlert.js";
import { ProjectTicketUnplannedAlert } from "../../features/projects/alerts/ProjectTicketUnplannedAlert.js";
import { ProjectStatusChipWithEditing } from "../../features/projects/badges/ProjectStatusChipWithEditing.js";
import { ProjectOverviewActionListBox } from "../../features/projects/boxes/ProjectOverviewActionListBox.js";
import { ProjectOverviewBiddingInformationBox } from "../../features/projects/boxes/ProjectOverviewBiddingInstructionsBox.js";
import { ProjectCommentsBox } from "../../features/projects/boxes/ProjectOverviewCommentsBox.js";
import { ProjectOverviewContactsBox } from "../../features/projects/boxes/ProjectOverviewContactsBox.js";
import { ProjectOverviewDocumentsBox } from "../../features/projects/boxes/ProjectOverviewDocumentsBox.js";
import { ProjectOverviewFilesBox } from "../../features/projects/boxes/ProjectOverviewFilesBox.js";
import { ProjectOverviewHeaderBox } from "../../features/projects/boxes/ProjectOverviewHeaderBox.js";
import { ProjectOverviewNextTasksBox } from "../../features/projects/boxes/ProjectOverviewNextTasksBox.js";
import { ProjectOverviewOutgoingRequestsBox } from "../../features/projects/boxes/ProjectOverviewOutgoingRequestsBox.js";
import { ProjectOverviewParticipantsBox } from "../../features/projects/boxes/ProjectOverviewParticipantsBox.js";
import { ProjectOverviewPlanningBox } from "../../features/projects/boxes/ProjectOverviewPlanningBox.js";
import { ProjectOverviewWorkSessionsBox } from "../../features/projects/boxes/ProjectOverviewWorkSessionsBox.js";
import { ProjectAddEntityButton } from "../../features/projects/buttons/ProjectAddEntityButton.js";
import { TodosBox } from "../../features/todos/TodosBox.js";
import { getAllItemChildren, getAllParentItems } from "../../trees/helpers.js";
import { useProjectOverviewQuery } from "./ProjectOverview.generated.js";
import { usePreloadProjectQuotes } from "./ProjectQuotes.js";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectOverview = ({
  projectId,
  submenuItems,
  prefixBreadcrumbs,
  activeSubmenuItem,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["ProjectOverview", "Global"]);
  const viewer = useUserData().currentUser!;

  const theme = useTheme();
  const isMinSm = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm),
    { noSsr: true }
  );

  const { isMinTablet, isMinDesktop, isMinLargeDesktop } = useScreenWidth();

  const client = useApolloClient();
  const query = useProjectOverviewQuery({
    client,
    variables: {
      projectId,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const incomingRequirementsCount =
    getDataOrNull(query.data?.incomingRequirements)?.totalCount ?? 0;
  const outgoingRequirementsCount =
    getDataOrNull(query.data?.outgoingRequirements)?.totalCount ?? 0;
  const incomingInvoicesCount =
    getDataOrNull(query.data?.projectIncomingInvoices)?.totalCount ?? 0;
  const outgoingInvoicesCount =
    getDataOrNull(query.data?.projectOutgoingInvoices)?.totalCount ?? 0;
  const incomingQuotesCount =
    getDataOrNull(query.data?.projectIncomingQuotes)?.totalCount ?? 0;
  const outgoingQuotesCount =
    getDataOrNull(query.data?.projectOutgoingQuotes)?.totalCount ?? 0;
  const outgoingRequirementsWithoutWonRequestCount =
    getDataOrNull(query.data?.outgoingRequirementsWithoutWonRequest)
      ?.totalCount ?? 0;
  const ordersCount = getDataOrNull(query.data?.orders)?.totalCount ?? 0;
  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);
  const hasTodoItems = Boolean(query.data?.todoItems?.totalCount);
  const hasIncomingRequests = Boolean(project?.incomingQuoteRequests?.length);
  const hasOutgoingRequests = Boolean(project?.outgoingQuoteRequests?.length);
  const hasIncomingAgreedQuotes = Boolean(
    getDataOrNull(query.data?.projectIncomingAgreedQuotes)?.totalCount
  );
  const hasAttachments = Boolean(project?.attachments?.length);
  const hasContents = Boolean(project?.contents?.length);
  const hasAddress = Boolean(project?.building?.buildingAddress);
  const phases = query.data?.organisationSettings.projectPhases;

  const viewerPermissionDetailsEnabled = useFeature("ViewerPermissionDetails");

  const [changeProjectTitle] = useModifyProjectMutation({ client });

  const pathToPage = `/projects/${projectId}/overview`;

  usePreloadProjectQuotes(project?.id);

  const buildingItems = project?.building?.items ?? [];

  const allRelevantBuildingItems = project?.buildingItem
    ? getAllParentItems(project?.buildingItem, buildingItems)
        .concat([project?.buildingItem])
        .concat(getAllItemChildren(project?.buildingItem, buildingItems))
    : [];

  const buildingContacts = uniqBy(
    allRelevantBuildingItems.map(item => item.crmLinks).flat(2),
    a => {
      return a.__typename === "QuoteItemLinkCrmUser"
        ? `${a.__typename}:${a.crmUser.id}:${a.linkAs}`
        : a.__typename === "QuoteItemLinkCrmOrganisation"
          ? `${a.__typename}:${a.crmOrganisation.id}:${a.linkAs}`
          : "";
    }
  );

  const pageActionsMenu = project && (
    <MenuButton>
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_QUOTES"
        project={project}
      >
        {(!(project.state === "opportunity") ||
          project.incomingQuoteRequests.length === 0 ||
          project.incomingQuoteRequests.some(
            request =>
              request.status === "PUBLISHED" ||
              request.wonBySystemOrganisationId === viewer.organisation.id
          )) && (
          <ImportXiopdButton
            projectId={projectId}
            refetchQueries={[
              namedOperations.Query.ProjectOverviewOutgoingQuotes,
            ]}
          />
        )}
      </RestrictedByProjectPermissionWithDebug>
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_QUOTES"
        project={project}
      >
        {(!(project.state === "opportunity") ||
          project.incomingQuoteRequests.length === 0 ||
          project.incomingQuoteRequests.some(
            request =>
              request.status === "PUBLISHED" ||
              request.wonBySystemOrganisationId === viewer.organisation.id
          )) && (
          <ImportGaebButton
            projectId={projectId}
            refetchQueries={[
              namedOperations.Query.ProjectOverviewOutgoingQuotes,
            ]}
            type={"p94"}
          />
        )}
      </RestrictedByProjectPermissionWithDebug>
    </MenuButton>
  );

  const HeaderBox = project ? (
    <RestrictedByProjectPermissionWithDebug
      permission="READ_PROJECT"
      project={project}
    >
      <ProjectOverviewHeaderBox project={project} />
    </RestrictedByProjectPermissionWithDebug>
  ) : null;
  const ActionListBox = project ? (
    <RestrictedByCapabilityWithDebug capability="QUOTING">
      <ProjectOverviewActionListBox
        key={project.state} // we'd like to close/open lists based on current status
        project={project}
        hasAttachments={hasAttachments}
        hasContents={hasContents}
        hasAddress={hasAddress}
        hasOutgoingRequests={hasOutgoingRequests}
        hasIncomingAgreedQuotes={hasIncomingAgreedQuotes}
        ordersCount={ordersCount}
        incomingRequirementsCount={incomingRequirementsCount}
        outgoingRequirementsCount={outgoingRequirementsCount}
        outgoingInvoicesCount={outgoingInvoicesCount}
        incomingInvoicesCount={incomingInvoicesCount}
        outgoingQuotesCount={outgoingQuotesCount}
        incomingQuotesCount={incomingQuotesCount}
        refetchQueries={[namedOperations.Query.ProjectOverview]}
      />
    </RestrictedByCapabilityWithDebug>
  ) : null;
  const DocumentsBox = project ? (
    <ProjectOverviewDocumentsBox
      project={project}
      ordersCount={ordersCount}
      incomingRequirementsCount={incomingRequirementsCount}
      outgoingRequirementsCount={outgoingRequirementsCount}
      outgoingInvoicesCount={outgoingInvoicesCount}
      incomingInvoicesCount={incomingInvoicesCount}
      outgoingQuotesCount={outgoingQuotesCount}
      incomingQuotesCount={incomingQuotesCount}
    />
  ) : null;
  const FilesBox =
    project && getFiles(project.attachments).length > 0 ? (
      <ProjectOverviewFilesBox project={project} />
    ) : null;
  const WorkSessionsBox =
    project && project.state === "contracted" ? (
      <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
        <RestrictedByProjectPermissionWithDebug
          permission="EXECUTE_TASK"
          project={project}
        >
          <ProjectOverviewWorkSessionsBox project={project} />
        </RestrictedByProjectPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    ) : null;
  const NextTasksBox =
    project &&
    project.state === "contracted" &&
    viewer.organisation.isCraftsmanOrganisation ? (
      <RestrictedByCapabilityWithDebug capability="EXECUTING">
        <ProjectOverviewNextTasksBox project={project} />
      </RestrictedByCapabilityWithDebug>
    ) : null;
  const BiddingInformationBox = project ? (
    <ProjectOverviewBiddingInformationBox project={project} />
  ) : null;
  const OutgoingRequestsBox = project ? (
    <ProjectOverviewOutgoingRequestsBox project={project} />
  ) : null;
  const ParticipantsBox = project ? (
    <RestrictedByProjectPermissionWithDebug
      permission={"READ_PROJECT_MEMBERS"}
      project={project}
    >
      <ProjectOverviewParticipantsBox project={project} />
    </RestrictedByProjectPermissionWithDebug>
  ) : null;

  const ViewerPermissionsDetails =
    project && viewerPermissionDetailsEnabled ? (
      <div>
        <h2>viewer permission details</h2>
        <hr />
        <textarea
          style={{ width: "100%", height: 250 }}
          readOnly
          defaultValue={JSON.stringify(
            project.viewerPermissionsDetails,
            null,
            2
          )}
        ></textarea>
      </div>
    ) : null;

  const ContactsBox =
    project && (project.crmLinks.length > 0 || buildingContacts.length > 0) ? (
      <ProjectOverviewContactsBox
        projectId={project.id}
        contacts={project.crmLinks}
        buildingContacts={buildingContacts}
        canEdit={true} // FIXME
        refetchQueries={[namedOperations.Query.ProjectOverview]}
      />
    ) : null;
  const PlanningBox =
    project && organisationDefaults && project.planSessions.length > 0 ? (
      <RestrictedByCapabilityWithDebug capability="PLANNING">
        <ProjectOverviewPlanningBox
          project={project}
          pathToPage={pathToPage}
          organisationDefaults={organisationDefaults}
        />
      </RestrictedByCapabilityWithDebug>
    ) : null;
  const ProjectContentBox =
    project && project.contents.length > 0 ? (
      <ContentBox
        itemId={project.id}
        content={project.contents}
        refetchQueries={[namedOperations.Query.ProjectOverview]}
        isEditable
      />
    ) : null;
  const ProjectTodosBox =
    project && hasTodoItems ? <TodosBox linkedProject={project} /> : null;
  const CommentsBox = project ? <ProjectCommentsBox project={project} /> : null;

  const floatAction = React.useMemo(
    () =>
      project ? (
        <ProjectAddEntityButton
          project={project}
          refetchQueries={[namedOperations.Query.ProjectOverview]}
          outgoingQuotesCount={outgoingQuotesCount}
          outgoingRequirementsWithoutWonRequestCount={
            outgoingRequirementsWithoutWonRequestCount
          }
        />
      ) : undefined,
    [project, outgoingQuotesCount, outgoingRequirementsWithoutWonRequestCount]
  );

  const Status = project && (
    <Stack direction="row" spacing={1}>
      <TicketStatusChip ticket={project} />
      <ProjectStatusChipWithEditing
        canEdit={true}
        projectState={project.state}
        projectId={projectId}
      />
      <ProjectPhaseChip
        projectId={projectId}
        currentState={project.state}
        currentPhase={project.phase}
        availablePhases={phases}
      />
    </Stack>
  );

  return (
    <Page
      subtitle={project?.title}
      title={t("Overview", {
        ns: "ProjectOverview",
      })}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      header={
        <PageHeader
          title={project?.title}
          handleTitleChange={async newValue => {
            await changeProjectTitle({
              variables: { input: { projectId, title: newValue } },
            });
            enqueueSnackbar(
              t("Project title changed", {
                ns: "ProjectOverview",
              })
            );
          }}
          titleEditTooltip={t("Rename", {
            ns: "Global",
          })}
          status={Status}
          actionMenu={pageActionsMenu}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      floatAction={floatAction}
    >
      {project && organisationDefaults && (
        <PageContainer>
          <Stack
            direction="column"
            spacing={2}
            pb={isMinTablet ? "72px" : "48px"} // for float button
          >
            {!isMinSm && Status}
            <ProjectOverdueAlert project={project} />
            <ProjectTicketUnplannedAlert project={project} />
            {project.state === "opportunity" && (
              <ProjectOpportunityAlert project={project} />
            )}
            {project.subOriginProject && (
              // TODO-MODEL2022 correct?
              // viewer.organisation.id === project.contractee?.id && (
              <ProjectSubOriginAlert
                subOriginProject={project.subOriginProject}
              />
            )}
            <Box>
              {(() => {
                if (!isMinSm) {
                  // 1-column
                  return (
                    <Stack direction="column" spacing={2}>
                      {HeaderBox}
                      {ParticipantsBox}
                      {ViewerPermissionsDetails}
                      {ContactsBox}
                      {DocumentsBox}
                      {FilesBox}
                      {PlanningBox}
                      {WorkSessionsBox}
                      {NextTasksBox}
                      {BiddingInformationBox}
                      {OutgoingRequestsBox}
                      {ProjectContentBox}
                      {ProjectTodosBox}
                      {CommentsBox}
                      {ActionListBox}
                    </Stack>
                  );
                } else if (!isMinTablet) {
                  // 2-columns
                  return (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack direction="column" spacing={2}>
                          {HeaderBox}
                          {ParticipantsBox}
                          {ViewerPermissionsDetails}
                          {ContactsBox}
                          {PlanningBox}
                          {WorkSessionsBox}
                          {NextTasksBox}
                          {ProjectContentBox}
                          {CommentsBox}
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="column" spacing={2}>
                          {ActionListBox}
                          {DocumentsBox}
                          {FilesBox}
                          {BiddingInformationBox}
                          {OutgoingRequestsBox}
                          {ProjectTodosBox}
                        </Stack>
                      </Grid>
                    </Grid>
                  );
                } else if (!isMinLargeDesktop) {
                  // 3-columns
                  return (
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Stack direction="column" spacing={2}>
                          {HeaderBox}
                          {ParticipantsBox}
                          {ViewerPermissionsDetails}
                          {ContactsBox}
                          {PlanningBox}
                          {WorkSessionsBox}
                          {NextTasksBox}
                        </Stack>
                      </Grid>
                      <Grid item xs={8}>
                        <Stack direction="column" spacing={2}>
                          {DocumentsBox}
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                  {ActionListBox}
                                  {FilesBox}
                                </Stack>
                              </Grid>
                              <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                  {BiddingInformationBox}
                                  {OutgoingRequestsBox}
                                  {ProjectTodosBox}
                                  {ProjectContentBox}
                                  {CommentsBox}
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  );
                } else {
                  // 4-columns
                  return (
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Stack direction="column" spacing={2}>
                          {HeaderBox}
                          {ParticipantsBox}
                          {ViewerPermissionsDetails}
                          {ContactsBox}
                          {PlanningBox}
                          {WorkSessionsBox}
                          {NextTasksBox}
                        </Stack>
                      </Grid>
                      <Grid item xs={3}>
                        <Stack direction="column" spacing={2}>
                          {ActionListBox}
                          {CommentsBox}
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="column" spacing={2}>
                          {DocumentsBox}
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                  {BiddingInformationBox}
                                  {OutgoingRequestsBox}
                                  {FilesBox}
                                </Stack>
                              </Grid>
                              <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                  {ProjectTodosBox}
                                  {ProjectContentBox}
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  );
                }
              })()}
            </Box>
          </Stack>
        </PageContainer>
      )}
    </Page>
  );
};
