/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OutsourceItemMutationVariables = Types.Exact<{
  input: Types.SubcontractInput;
}>;


export type OutsourceItemMutation = { subcontract: { __typename: 'SubcontractResult', subcontract: { __typename: 'Subcontract', subProject: { __typename: 'ProjectInfo', id: string }, subRequirement: { __typename: 'Requirement', id: string, title: string } }, item: { __typename: 'Item', id: string, originVersionNumber?: number | null, selfOrClosestSubcontract?: { __typename: 'Subcontract', id: string, subProject: { __typename: 'ProjectInfo', id: string }, subRequirement: { __typename: 'Requirement', id: string } } | null } } };


export const OutsourceItemDocument = gql`
    mutation OutsourceItem($input: SubcontractInput!) {
  subcontract(input: $input) {
    subcontract {
      subProject {
        id
      }
      subRequirement {
        id
        title
      }
    }
    item {
      id
      originVersionNumber
      selfOrClosestSubcontract {
        id
        subProject {
          id
        }
        subRequirement {
          id
        }
      }
    }
  }
}
    `;
export type OutsourceItemMutationFn = Apollo.MutationFunction<OutsourceItemMutation, OutsourceItemMutationVariables>;

/**
 * __useOutsourceItemMutation__
 *
 * To run a mutation, you first call `useOutsourceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOutsourceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [outsourceItemMutation, { data, loading, error }] = useOutsourceItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOutsourceItemMutation(baseOptions?: Apollo.MutationHookOptions<OutsourceItemMutation, OutsourceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OutsourceItemMutation, OutsourceItemMutationVariables>(OutsourceItemDocument, options);
      }
export type OutsourceItemMutationHookResult = ReturnType<typeof useOutsourceItemMutation>;
export type OutsourceItemMutationResult = Apollo.MutationResult<OutsourceItemMutation>;
export type OutsourceItemMutationOptions = Apollo.BaseMutationOptions<OutsourceItemMutation, OutsourceItemMutationVariables>;