import { useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import omitDeep from "omit-deep-lodash";
import { ContentInput } from "../../../../clients/graphqlTypes.js";
import {
  ContentsContentFragment,
  useContentsSetMutation,
} from "../../contents/Content.generated.js";
import { ContentEditModal } from "../../contents/ContentEditModal.js";

interface Props {
  project: {
    id: string;
    contents: Array<ContentsContentFragment>;
  };
  title?: string;
  confirmButtonLabel?: string;
  Button?: React.ReactElement;
  handleComplete?: () => Promise<void>;
  refetchQueries?: string[];
}

export const AddProjectContentButton = ({
  project,
  title,
  confirmButtonLabel,
  Button,
  handleComplete,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["Contents", "Global"]);

  const client = useApolloClient();
  const [setContents] = useContentsSetMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const button = Button ?? (
    <IconButton color="primary" size="small">
      <AddIcon />
    </IconButton>
  );

  return (
    <ModalOpenButton
      Modal={ContentEditModal}
      modalProps={{
        title: title ?? t("Create new content", { ns: "Contents" }),
        confirmButtonLabel: confirmButtonLabel ?? t("Add", { ns: "Global" }),
        handleComplete: async article => {
          await setContents({
            variables: {
              input: {
                id: project.id,
                contents: [...project.contents, article].map(item =>
                  omitDeep(item, ["__typename"])
                ) as ContentInput[],
              },
            },
          });
          handleComplete?.();
        },
      }}
    >
      {button}
    </ModalOpenButton>
  );
};
