import { useApolloClient } from "@apollo/client";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { SplashScreen } from "../../common/SplashScreen.js";
import { useLanguages } from "../../common/translations/useLanguages.js";
import { useUrlSearchParams } from "../commons/hooks/useUrlSearchParams.js";
import {
  ORGANISATION_TYPE_URL_SEARCH_PARAM,
  REF_TOKEN_URL_SEARCH_PARAM,
  SELECTED_USER_HTTP_HEADER,
} from "../constants.js";
import {
  useImplicitFlow_AcceptProjectContracteeInvitationMutation,
  useImplicitFlow_CreateNewOrganisationAndUserMutation,
} from "./ImplicitFlow.generated.js";
import { useSelectedUser } from "./useSelectedUser.js";

interface Props {
  organisationName: string;
  invitationToken: string;
}

export const ImplicitFlow = ({ organisationName, invitationToken }: Props) => {
  const { t } = useTranslate(["Global"]);
  const { currentLanguage } = useLanguages();
  const { setUrlSearchParams } = useUrlSearchParams();
  const { setSelectedUserId } = useSelectedUser();

  const client = useApolloClient();
  const [createOrgAndUser] =
    useImplicitFlow_CreateNewOrganisationAndUserMutation({ client });
  const [acceptInvitation] =
    useImplicitFlow_AcceptProjectContracteeInvitationMutation({
      client,
    });

  const handleFlow = React.useCallback(async () => {
    const { data } = await createOrgAndUser({
      variables: {
        input: {
          organisation: {
            organisationType: "CLIENT",
            title: organisationName,
            skillset: [],
            phones: [],
          },
          user: { locale: currentLanguage.locale, phones: [] },
        },
      },
    });
    if (!data) throw new Error("User creation failed");

    await acceptInvitation({
      variables: { input: { invitationToken } },
      context: {
        headers: {
          [SELECTED_USER_HTTP_HEADER]: data.createOrganisationAndUser.user.id,
        },
      },
    });
    setUrlSearchParams({}, [
      ORGANISATION_TYPE_URL_SEARCH_PARAM,
      REF_TOKEN_URL_SEARCH_PARAM,
    ]);
    setSelectedUserId(data.createOrganisationAndUser.user.id);
  }, [
    acceptInvitation,
    createOrgAndUser,
    currentLanguage.locale,
    invitationToken,
    organisationName,
    setSelectedUserId,
    setUrlSearchParams,
  ]);

  useEffectOnce(handleFlow);

  return (
    <SplashScreen message={t("Preparing account ...", { ns: "Global" })} />
  );
};

function useEffectOnce(callback: Function) {
  const first = React.useRef(true);

  React.useEffect(() => {
    if (first.current === false) return;

    first.current = false;
    callback();
  }, [callback]);

  return null;
}
