import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import React from "react";
import {
  EntitySearchSortingDirection,
  EntitySearchSortingPropertyValueSorting,
  ItemTemplateSearchSortingDefinition,
  TemplateSearchPropertySortingKind,
  TemplateSearchSortingDefinitionInput,
} from "../../../clients/graphqlTypes.js";
import { useTemplateSeachSortingsSection_DefineTemplateSearchSortingDefintionsMutation } from "./useTemplateSearchSortingsMutations.generated.js";

export function useTemplateSearchSortingsMutations({
  projectId,
  docId,
  itemId,
  searchSortingDefinitions,
  refetchQueries,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  searchSortingDefinitions: ItemTemplateSearchSortingDefinition[];
  refetchQueries?: string[];
}) {
  const client = useApolloClient();

  /////////////////
  // DEFINITIONS //
  /////////////////

  const [defineItemTemplateSearchSortingDefinitions] =
    useTemplateSeachSortingsSection_DefineTemplateSearchSortingDefintionsMutation(
      { client, refetchQueries }
    );

  const handleSetTemplateSearchPropertySortingDefinition = React.useCallback(
    async (
      key: string,
      kind: TemplateSearchPropertySortingKind,
      direction: EntitySearchSortingDirection
    ) => {
      const templateSearchSortingDefinitions = searchSortingDefinitions.some(
        d =>
          d.__typename === "EntitySearchSortingPropertyValueSorting" &&
          d.key === key
      )
        ? searchSortingDefinitions.map(d =>
            d.__typename === "EntitySearchSortingPropertyValueSorting" &&
            d.key === key
              ? { ...d, key: key, kind: kind, direction: direction }
              : d
          )
        : searchSortingDefinitions.concat({
            __typename: "EntitySearchSortingPropertyValueSorting",
            key: key,
            kind: kind,
            direction: direction,
          });

      await defineItemTemplateSearchSortingDefinitions({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            templateSearchSortingDefinitions:
              templateSearchSortingDefinitions.map(
                templateSearchSortingPropertySortingDefinition2Input
              ),
          },
        },
        refetchQueries,
      });
    },
    [
      defineItemTemplateSearchSortingDefinitions,
      docId,
      itemId,
      projectId,
      searchSortingDefinitions,
      refetchQueries,
    ]
  );
  const handleDeleteTemplateSearchPropertySortingDefinition = React.useCallback(
    async (key: string) => {
      await defineItemTemplateSearchSortingDefinitions({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            templateSearchSortingDefinitions: searchSortingDefinitions
              .filter(
                d =>
                  !(
                    d.__typename ===
                      "EntitySearchSortingPropertyValueSorting" && d.key === key
                  )
              )
              .map(templateSearchSortingPropertySortingDefinition2Input),
          },
        },
        refetchQueries,
      });
    },
    [
      defineItemTemplateSearchSortingDefinitions,
      docId,
      itemId,
      projectId,
      searchSortingDefinitions,
      refetchQueries,
    ]
  );

  return {
    handleSetTemplateSearchPropertySortingDefinition,
    handleDeleteTemplateSearchPropertySortingDefinition,
  };
}

export function templateSearchSortingPropertySortingDefinition2Input(
  sorting: EntitySearchSortingPropertyValueSorting
): TemplateSearchSortingDefinitionInput {
  switch (sorting.__typename) {
    case "EntitySearchSortingPropertyValueSorting": {
      return {
        propertySorting: {
          key: sorting.key,
          direction: sorting.direction,
          kind: sorting.kind,
        },
      };
    }
    default: {
      assertNever(sorting.__typename);
    }
  }
}
