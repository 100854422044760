import { gql, useApolloClient } from "@apollo/client";
import {
  CardContainer,
  FormattedPercentage,
  FormattedPrice,
  underlineDoubleStyle,
} from "@msys/ui";
import { Typography } from "@mui/material";
import { BigNumber } from "bignumber.js";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { FormattedPercentageField } from "../../../commons/form-fields/FormattedPercentageField.js";
import { FormattedPriceField } from "../../../commons/form-fields/FormattedPriceField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { useModifyQuoteTemplateDiscountMutation } from "./TemplatesQuotePriceSummaryBox.generated.js";
import { useTranslate } from "@tolgee/react";
import { ItemCalculationFragment } from "../../doc-items/Fragments.generated.js";

type FormValues = {
  discountPercentage: number;
  discountValue: number;
};

interface Props {
  docId: string;
  discountPercentage: number;
  calculated: ItemCalculationFragment;
  isReadOnly?: boolean;
}

export const TemplatesQuotePriceSummaryBox = ({
  docId,
  calculated,
  discountPercentage,
  isReadOnly = false,
}: Props) => {
  const { t } = useTranslate("QuotePriceSummary");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyQuoteTemplateDiscount] = useModifyQuoteTemplateDiscountMutation({
    client,
  });

  const initialValues = React.useMemo(
    (): FormValues => ({
      discountPercentage,
      discountValue: parseFloat(
        new BigNumber(calculated.priceSubTotal)
          .minus(calculated.priceNetTotal)
          .toFixed(2)
      ),
    }),
    [discountPercentage, calculated.priceSubTotal, calculated.priceNetTotal]
  );

  const validationSchema = Yup.object().shape({
    discountPercentage: Yup.number().label(t("Discount")).min(0).max(1),
    discountValue: Yup.number()
      .label(t("Discount"))
      .min(0)
      .max(calculated.priceSubTotal),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyQuoteTemplateDiscount({
        variables: {
          input: {
            quoteTemplateId: docId,
            values: {
              discountPercentage: values.discountPercentage,
            },
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <CardContainer
      title={t("Totals")}
      isExpandable
      CollapsedHint={
        <Typography variant="h3" component="div">
          <FormattedPrice value={calculated?.priceTotal ?? 0} />
        </Typography>
      }
    >
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => (
          <Form>
            <Stack p={1} spacing={2} flexDirection="column" width="100%">
              <Stack flexDirection="column">
                <Stack justifyContent="space-between" alignItems="flex-start">
                  <Typography variant="h4" component="div">
                    {t("Subtotal")}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    style={{ textDecoration: "underline" }}
                  >
                    <FormattedPrice value={calculated.priceSubTotal} />
                  </Typography>
                </Stack>
                <Stack justifyContent="space-between" alignItems="flex-start">
                  <Stack alignItems="center">
                    <span>{t("Discount")}</span>
                    {!isReadOnly ? (
                      <FormattedPercentageField
                        label=""
                        name="discountPercentage"
                        min={0}
                        max={100}
                        InputProps={{ style: { width: "8ch" } }}
                        disabled={false}
                        size="extra-small"
                        setFieldValue={(name, value) => {
                          const discountMultiplier = new BigNumber(1).minus(
                            value
                          );
                          const priceDiscountedSubTotal = new BigNumber(
                            calculated.priceSubTotal
                          )
                            .multipliedBy(discountMultiplier)
                            .toFixed(2);

                          const discountValue = new BigNumber(
                            calculated.priceSubTotal
                          )
                            .minus(priceDiscountedSubTotal)
                            .toFixed(2);

                          formikProps.setValues({
                            discountValue: parseFloat(discountValue),
                            discountPercentage: value,
                          });
                        }}
                      />
                    ) : (
                      <>
                        {" "}
                        (
                        <FormattedPercentage
                          value={formikProps.values.discountPercentage}
                        />
                        )
                      </>
                    )}
                  </Stack>
                  {!isReadOnly ? (
                    <FormattedPriceField
                      disabled={false}
                      name="discountValue"
                      label=""
                      type="price"
                      min={0}
                      InputProps={{
                        style: { width: "13ch" },
                        startAdornment: "−",
                      }}
                      FormHelperTextProps={{
                        style: { textAlign: "right" },
                      }}
                      style={{ alignItems: "flex-end" }}
                      size="extra-small"
                      setFieldValue={(name, value) => {
                        if (calculated.priceSubTotal === 0) {
                          formikProps.setFieldValue(name, value);
                          return;
                        }
                        const discountRate = new BigNumber(value)
                          .dividedBy(calculated.priceSubTotal)
                          .toFixed(6);
                        const discountMultiplier = new BigNumber(1).minus(
                          discountRate
                        );
                        const priceDiscountedSubTotal = new BigNumber(
                          calculated.priceSubTotal
                        )
                          .multipliedBy(discountMultiplier)
                          .toFixed(2);

                        const discountValue = new BigNumber(
                          calculated.priceSubTotal
                        )
                          .minus(priceDiscountedSubTotal)
                          .toFixed(2);

                        formikProps.setValues({
                          discountValue: parseFloat(discountValue),
                          discountPercentage: parseFloat(discountRate),
                        });
                      }}
                    />
                  ) : (
                    <span>
                      −{" "}
                      <FormattedPrice
                        value={
                          calculated.priceSubTotal - calculated.priceNetTotal
                        }
                      />
                    </span>
                  )}
                </Stack>
              </Stack>
              <Stack flexDirection="column">
                <Stack justifyContent="space-between" alignItems="flex-start">
                  <Typography variant="h4" component="div">
                    {t("Net price")}
                  </Typography>
                  <Typography variant="h4" component="div">
                    <FormattedPrice value={calculated.priceNetTotal} />
                  </Typography>
                </Stack>
                <Stack flexDirection="column">
                  <Stack justifyContent="space-between" alignItems="flex-start">
                    <span>
                      {t("VAT")} (
                      <FormattedPercentage value={calculated.vatRate} />)
                    </span>
                    <span>
                      + <FormattedPrice value={calculated.priceVatTotal} />
                    </span>
                  </Stack>
                </Stack>
              </Stack>
              <Stack flexDirection="column">
                <Stack justifyContent="space-between" alignItems="flex-start">
                  <Typography variant="h3" component="div">
                    {t("Total")}
                  </Typography>
                  <Typography
                    variant="h3"
                    component="div"
                    style={underlineDoubleStyle}
                  >
                    <FormattedPrice value={calculated.priceTotal} />
                  </Typography>
                </Stack>
              </Stack>
              {!isReadOnly && (
                <AutoSave enableReinitialize initialValues={initialValues} />
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </CardContainer>
  );
};
