import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import { ColorPickerField } from "../../../commons/form-fields/ColorPickerField.js";
import { TextField } from "../../../commons/form-fields/TextField.js";

interface FormValues {
  phaseName: string;
  phaseColor: string;
}

export function CreateNewPhaseModal({
  handleClose,
  handleComplete,
  isNameAvailable,
}: {
  handleClose: () => void;
  handleComplete: (
    values: FormValues,
    handleClose: () => void
  ) => void | Promise<void>;
  isNameAvailable: (phaseName: string) => boolean;
}) {
  const { t } = useTranslate(["Projects", "Global", "OrganisationBranding"]);

  const initialValues: FormValues = {
    phaseName: "",
    phaseColor: "#FFFFFF",
  };
  const validationSchema = Yup.object().shape({
    phaseName: Yup.string()
      .label(t("Phase name", { ns: "Projects" }))
      .trim()
      .required(t("Phase name can't be empty", { ns: "Projects" }))
      .test(
        "alreadyUsed",
        t("This name is already used", { ns: "Projects" }),
        (name: string | undefined | null) => {
          return Boolean(name && isNameAvailable(name));
        }
      ),
    phaseColor: Yup.string()
      .required()
      .matches(
        /^#[a-f\d]{6}$/i,
        t("Wrong color format, only HEX is allowed", {
          ns: "OrganisationBranding",
        })
      )
      .label(t("Phase color", { ns: "Projects" })),
  });
  const handleSubmit = async (values: FormValues) => {
    await handleComplete(values, handleClose);
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Modal
          title={t("Create new phase", { ns: "Projects" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Confirm", { ns: "Global" }),
              buttonProps: {
                loading: formikProps.isSubmitting,
                form: formId,
                type: "submit",
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <TextField
                name={"phaseName"}
                label={t("Phase name", { ns: "Projects" })}
                required
              />
              <ColorPickerField
                label={t("Phase color", { ns: "Projects" })}
                name="phaseColor"
                required
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
