import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { debounce } from "lodash-es";
import React from "react";
import { useSupplierCatalogueSelect__PimSearchSupplierCataloguesLazyQuery } from "./SupplierCatalogueSelect.generated.js";

export type SupplierCatalogueValue = {
  id: string;
  title: string;
  supplier: { id: string; title: string };
};

export function SupplierCatalogueSelect({
  required = false,
  disabled = false,
  supplierCatalogueId,
  onChange,
  preselectSingle = false,
}: {
  required?: boolean;
  disabled?: boolean;
  supplierCatalogueId?: string | undefined | null;
  onChange: (supplierCatalogue: SupplierCatalogueValue | null) => void;
  preselectSingle?: boolean;
}) {
  const { t } = useTranslate(["ProductOverview", "Product", "Global"]);
  const [inputValue, setInputValue] = React.useState("");
  const { supplierCatalogues, debouncedRefetch, loading, totalCount } =
    useSupplierCatalogues(
      supplierCatalogueId,
      inputValue,
      setInputValue,
      preselectSingle,
      onChange
    );

  return (
    <Autocomplete
      placeholder={t("Type to search", {
        ns: "Global",
      })}
      inputLabel={t("Supplier catalogue", {
        ns: "Product",
      })}
      inputValue={inputValue}
      options={[
        ...supplierCatalogues,
        ...(totalCount > supplierCatalogues.length
          ? [
              {
                title: t("Type to see more...", { ns: "Global" }),
                id: "__type_to_see_more__" as const,
              },
            ]
          : []),
      ]}
      getOptionDisabled={option => option.id === "__type_to_see_more__"}
      getOptionLabel={option =>
        "__typename" in option
          ? `${option.supplier.title}: ${option.title ?? "–"}`
          : option.title
      }
      value={
        supplierCatalogues.find(
          catalogue => catalogue.id === supplierCatalogueId
        ) ?? null
      }
      onChange={value => {
        if (value && "__typename" in value) {
          setInputValue(`${value.supplier.title}: ${value.title ?? "–"}`);
          onChange(value);
        } else if (!value) {
          setInputValue("");
          onChange(null);
        }
      }}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
          debouncedRefetch(value);
          if (supplierCatalogueId) onChange(null);
        }
      }}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      loading={loading}
      disabled={disabled}
      required={required}
    />
  );
}

const LIMIT = 10;

function useSupplierCatalogues(
  supplierCatalogueId: string | undefined | null,
  inputValue: string,
  setInputValue: (value: string) => void,
  preselectSingle: boolean,
  onChange: (supplierCatalogue: SupplierCatalogueValue | null) => void
) {
  const client = useApolloClient();
  const [fetchQuery, query] =
    useSupplierCatalogueSelect__PimSearchSupplierCataloguesLazyQuery({
      client,
      variables: { limit: LIMIT },
      fetchPolicy: "network-only",
    });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          searchTerm: value,
        },
      });
    },
    [fetchQuery]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (supplierCatalogueId) {
        const result = await fetchQuery({
          variables: {
            limit: 1,
            supplierCatalogueId,
          },
        });
        const option = getDataOrNull(result.data?.pimSearchSupplierCatalogues)
          ?.supplierCatalogues[0];
        if (option)
          setInputValue(`${option.supplier.title}: ${option.title ?? "–"}`);
      } else {
        await fetchQuery({
          variables: {
            limit: LIMIT,
            searchTerm: inputValueRef.current,
          },
        });
      }
    })();
  }, [fetchQuery, supplierCatalogueId, setInputValue]);

  const supplierCatalogues =
    getDataOrNull(
      (query.data ?? query.previousData)?.pimSearchSupplierCatalogues
    )?.supplierCatalogues ?? [];
  const totalCount =
    getDataOrNull(
      (query.data ?? query.previousData)?.pimSearchSupplierCatalogues
    )?.totalCount ?? 0;

  // select single value after list loaded
  const checked = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (query.called && !query.loading && !query.error && !checked.current) {
      checked.current = true;
      if (
        preselectSingle &&
        !supplierCatalogueId &&
        supplierCatalogues.length === 1
      ) {
        onChange(supplierCatalogues[0]);
      }
    }
  }, [
    query,
    preselectSingle,
    supplierCatalogueId,
    supplierCatalogues,
    onChange,
  ]);

  return {
    supplierCatalogues,
    refetch,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.searchTerm &&
          query?.variables?.searchTerm !== inputValue
      ),
    totalCount,
  };
}
