import { SxProps } from "@mui/system";
import React, { CSSProperties } from "react";
import { Avatar, AvatarSize } from "../../commons/Avatar.js";
import { Attachment } from "../attachments/helpers.js";
import { UserAvatarFragment } from "./UserAvatar.generated.js";

interface Props {
  userAvatar: UserAvatarFragment;
  size?: AvatarSize;
  style?: CSSProperties;
  sx?: SxProps;
  onAttachment?: (attachment: Attachment | null) => Promise<void>;
  disabled?: boolean;
}

export const UserAvatar = ({
  userAvatar,
  size = "m",
  style,
  sx,
  onAttachment,
  disabled,
}: Props) => {
  const i1 = userAvatar.firstname?.substring(0, 1) ?? "";
  const i2 = userAvatar.familyname?.substring(0, 1) ?? "";

  return (
    <Avatar
      url={userAvatar.avatar.url}
      initials={`${i1}${i2}`}
      size={size}
      type="circle"
      style={style}
      sx={sx}
      onAttachment={onAttachment}
      disabled={disabled}
    />
  );
};
