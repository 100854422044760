import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { ButtonInputWithPopover, PriceSliderWithInput } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import type { Filters } from "../ProjectsList.js";
import { ProjectsListQueryVariables } from "../ProjectsList.generated.js";
import { useProjectBudgetQuery } from "./ProjectBudget.generated.js";

export function FilterProjectBudgetButton<F extends Filters>({
  filters,
  setFilters,
  variables,
}: {
  filters: F;
  setFilters: React.Dispatch<React.SetStateAction<F>>;
  variables: ProjectsListQueryVariables;
}) {
  const { t } = useTranslate(["Global", "Projects"]);

  const client = useApolloClient();
  const query = useProjectBudgetQuery({
    client,
    variables,
  });
  const projectHighest = (
    getDataOrNull(
      query.data?.projectsHighest ?? query.previousData?.projectsHighest
    )?.edges?.map(e => e.node) ?? []
  ).at(0);
  const projectLowest = (
    getDataOrNull(
      query.data?.projectsLowest ?? query.previousData?.projectsLowest
    )?.edges?.map(e => e.node) ?? []
  ).at(0);

  return (
    <ButtonInputWithPopover
      label={t("Budget", { ns: "Projects" })}
      counter={
        (filters.filterMinBudget ? 1 : 0) + (filters.filterMaxBudget ? 1 : 0)
      }
      popoverWidth="xxl"
      content={
        <Box p={1}>
          <PriceSliderWithInput
            label={t("Budget", { ns: "Projects" })}
            range={[
              projectLowest?.budget?.amount || 0,
              projectHighest?.budget?.amount || 0,
            ]}
            value={[
              filters.filterMinBudget ?? null,
              filters.filterMaxBudget ?? null,
            ]}
            setValue={newValue => {
              if (
                filters.filterMinBudget !== newValue[0] ||
                filters.filterMaxBudget !== newValue[1]
              ) {
                setFilters(filters => ({
                  ...filters,
                  filterMinBudget: newValue[0],
                  filterMaxBudget: newValue[1],
                }));
              }
            }}
            labelMin={t("Min", { ns: "Global" })}
            labelMax={t("Max", { ns: "Global" })}
          />
        </Box>
      }
      overflowVisible
    />
  );
}
