import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { SupplierDiscountGroupVariantCreateModal } from "../modals/SupplierDiscountGroupVariantCreateModal.js";
import { Add as AddIcon } from "@mui/icons-material";

export const SupplierDiscountGroupVariantCreateMenuButton = ({
  supplierId,
  refetchQueries,
}: {
  supplierId: string;
  refetchQueries?: string[];
}) => {
  const { t } = useTranslate(["SupplierDiscountGroups"]);

  return (
    <ModalOpenButton
      Modal={SupplierDiscountGroupVariantCreateModal}
      modalProps={{ supplierId, refetchQueries }}
    >
      <MenuItemWithIcon icon={<AddIcon />}>
        {t("Create variant", { ns: "SupplierDiscountGroups" })}
      </MenuItemWithIcon>
    </ModalOpenButton>
  );
};
