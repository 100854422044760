import { Button, ButtonProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useLanguages } from "../../common/translations/useLanguages.js";
import { useUrlSearchParams } from "../commons/hooks/useUrlSearchParams.js";
import { ORGANISATION_TYPE_URL_SEARCH_PARAM } from "../constants.js";
import type { CreateableOrganisationType } from "./isValidOrganisationType.js";
import { useAuth } from "./useAuth.js";

interface Props {
  prefill?:
    | {
        firstName: string;
        lastName: string;
        email: string;
        salutation: string;
      }
    | undefined;
  organisationType?: CreateableOrganisationType | undefined;
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
}

export const RegisterButton = ({
  prefill,
  organisationType,
  variant = "contained",
  color = "secondary",
}: Props) => {
  const { auth } = useAuth();
  const { t } = useTranslate(["Global"]);
  const { currentLanguage } = useLanguages();
  const { setUrlSearchParams } = useUrlSearchParams();

  const handleClick = React.useCallback(() => {
    if (organisationType) {
      setUrlSearchParams({
        [ORGANISATION_TYPE_URL_SEARCH_PARAM]: organisationType,
      });
    }

    setTimeout(() => {
      const url = new URL(
        auth.createRegisterUrl({ locale: currentLanguage.locale })
      );
      if (prefill) {
        url.searchParams.set("email", prefill.email);
        url.searchParams.set("firstName", prefill.firstName);
        url.searchParams.set("lastName", prefill.lastName);
        url.searchParams.set("user.attributes.salutation", prefill.salutation);
      }
      window.open(url.toString(), "_self");
    });
  }, [
    auth,
    currentLanguage.locale,
    organisationType,
    prefill,
    setUrlSearchParams,
  ]);

  return (
    <Button
      variant={variant}
      color={color}
      disableElevation
      fullWidth
      onClick={handleClick}
    >
      {t("Signup", { ns: "Global" })}
    </Button>
  );
};
