import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  generatePath,
  useParams,
} from "react-router-dom";
import { useWizardQuestion } from "../../../clients/openapi/client.js";
import { RestrictedByDocumentPermission } from "../../auth/RestrictedByDocumentPermission.js";
import { useAuth } from "../../auth/useAuth.js";
import { useUserData } from "../../auth/useUserData.js";
import { usePageWidth } from "../../commons/hooks/usePageWidth.js";
import { BreadcrumbItem } from "../../commons/layout/PageBreadcrumbs.js";
import { PageTopbarItem } from "../../commons/layout/PageSubmenu.js";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { SelectInfoMessageItem } from "../../features/doc-items/items/SelectInfoMessageItem.js";
import { ProjectQuoteCalculation } from "./ProjectQuoteCalculation.js";
import { ProjectQuoteContext } from "./ProjectQuoteContext.js";
import { ProjectQuoteEdit } from "./ProjectQuoteEdit.js";
import { ProjectQuoteEditItem } from "./ProjectQuoteEditItem.js";
import { ProjectQuotePreview } from "./ProjectQuotePreview.js";
import { ProjectQuoteThreeD } from "./ProjectQuoteThreeD.js";
import { ProjectQuoteTroubleshoot } from "./ProjectQuoteTroubleshoot.js";
import { useProjectQuoteRoutesQuery } from "./ProjectRoutes.generated.js";

export const ProjectQuoteRoutes = ({
  projectId,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const { quoteId } = useParams();
  if (!quoteId) throw new Error("Quote id is missing");

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["ProjectPageTopbar", "Quotes"]);
  const { isMinTablet } = useScreenWidth();
  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();

  const client = useApolloClient();
  const query = useProjectQuoteRoutesQuery({
    client,
    variables: {
      projectId,
      quoteId,
    },
  });

  const quote = getDataOrNull(query.data?.quote)?.quote;
  const canViewEdit = quote?.viewerPermissions.includes("MANAGE_QUOTES");
  const redirectPath = canViewEdit
    ? `/projects/:projectId/quotes/:quoteId/edit`
    : `/projects/:projectId/quotes/:quoteId/preview`;

  const [
    hasItemAnyQuestions,
    hasItemAnyRefinementQuestions,
    refetchWizardQuestions,
  ] = useTreeItemIdsWithWizardQuestions({
    quoteId,
    wizardTypes: canViewEdit ? ["item", "refinement"] : ["refinement"],
  });

  const breadcrumbs = React.useMemo(() => {
    const items = [
      ...prefixBreadcrumbs,
      {
        title: t("Quotes", {
          ns: "ProjectPageTopbar",
        }),
        to: `/projects/${projectId}/quotes`,
      },
    ];

    if (viewer.organisation.capabilities.includes("QUOTING")) {
      items.push({
        title: canViewEdit
          ? t("Outgoing", {
              ns: "Quotes",
            })
          : t("Incoming", {
              ns: "Quotes",
            }),
        to: `/projects/${projectId}/quotes?tab=${canViewEdit ? "OUTGOING" : "INCOMING"}`,
      });
    }

    items.push({
      title: quote?.title ?? "",
      to: `/projects/${projectId}/quotes/${quoteId}`,
    });

    return items;
  }, [
    prefixBreadcrumbs,
    t,
    projectId,
    viewer.organisation.capabilities,
    quote?.title,
    quoteId,
    canViewEdit,
  ]);

  if (!quote) return null;

  return (
    <ProjectQuoteContext.Provider
      value={{
        projectId,
        quoteId,
        hasItemAnyQuestions,
        hasItemAnyRefinementQuestions,
        refetchWizardQuestions,
      }}
    >
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={generatePath(redirectPath, {
                projectId,
                quoteId,
              })}
              replace
            />
          }
        />

        <Route
          path={`preview`}
          element={
            <RestrictedByDocumentPermission
              permission="READ_QUOTES"
              document={quote}
            >
              <ProjectQuotePreview
                projectId={projectId}
                quoteId={quoteId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeSubmenuItem}
              />
            </RestrictedByDocumentPermission>
          }
        />
        <Route
          path={`edit`}
          element={
            <RestrictedByDocumentPermission
              permission="MANAGE_QUOTES"
              document={quote}
            >
              <ProjectQuoteEdit
                projectId={projectId}
                quoteId={quoteId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeSubmenuItem}
              />
            </RestrictedByDocumentPermission>
          }
        >
          {isMinTablet &&
            (isMinTwoColumnsWithPreview || !isMinOneColumnWithPreview) && (
              <Route index element={<SelectInfoMessageItem />} />
            )}
          <Route
            path={"items/:itemId"}
            element={
              <ProjectQuoteEditItem
                projectId={projectId}
                quoteId={quoteId}
                pathToDocList={`/projects/${projectId}/quotes`}
                pathToDocPage={`/projects/${projectId}/quotes/${quoteId}/edit`}
              />
            }
          />
        </Route>
        <Route
          path={`calculation`}
          element={
            <RestrictedByDocumentPermission
              permission="MANAGE_QUOTES"
              document={quote}
            >
              <ProjectQuoteCalculation
                projectId={projectId}
                quoteId={quoteId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeSubmenuItem}
              />
            </RestrictedByDocumentPermission>
          }
        >
          <Route
            path={"items/:itemId"}
            element={
              <ProjectQuoteEditItem
                projectId={projectId}
                quoteId={quoteId}
                pathToDocList={`/projects/${projectId}/quotes`}
                pathToDocPage={`/projects/${projectId}/quotes/${quoteId}/calculation`}
                isAbsolute={false}
              />
            }
          />
        </Route>
        <Route
          path={`3d`}
          element={
            <RestrictedByDocumentPermission
              permission="READ_QUOTES"
              document={quote}
            >
              <ProjectQuoteThreeD
                projectId={projectId}
                quoteId={quoteId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeSubmenuItem}
              />
            </RestrictedByDocumentPermission>
          }
        >
          <Route
            path={"items/:itemId"}
            element={
              <ProjectQuoteEditItem
                projectId={projectId}
                quoteId={quoteId}
                pathToDocList={`/projects/${projectId}/quotes`}
                pathToDocPage={`/projects/${projectId}/quotes/${quoteId}/3d`}
                isAbsolute={true}
              />
            }
          />
        </Route>
        <Route
          path={`troubleshoot`}
          element={
            <RestrictedByDocumentPermission
              permission="MANAGE_QUOTES"
              document={quote}
            >
              <ProjectQuoteTroubleshoot
                projectId={projectId}
                quoteId={quoteId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeSubmenuItem}
                pathToDocList={`/projects/${projectId}/quotes`}
                pathToDoc={`/projects/${projectId}/quotes/${quoteId}`}
              />
            </RestrictedByDocumentPermission>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ProjectQuoteContext.Provider>
  );
};

function useTreeItemIdsWithWizardQuestions({
  quoteId,
  wizardTypes,
}: {
  quoteId: string;
  wizardTypes: Array<"refinement" | "item">;
}) {
  const hasItemWizard = wizardTypes.includes("item");
  const hasRefinementWizard = wizardTypes.includes("refinement");

  const { itemIdsWithQuestionByItemEach, refetch: refetchQuestionByItemEach } =
    useItemIdsWithQuestionByItemEach({
      quoteId,
      enabled: hasItemWizard,
    });
  const hasItemAnyQuestions = React.useCallback(
    (itemId: string) =>
      hasItemWizard ? itemIdsWithQuestionByItemEach.includes(itemId) : false,
    [hasItemWizard, itemIdsWithQuestionByItemEach]
  );
  const {
    itemIdsWithQuestionByOnQuoteRefinement,
    refetch: refetchQuestionByOnQuoteRefinement,
  } = useItemIdsWithQuestionByOnQuoteRefinement({
    quoteId,
    enabled: hasRefinementWizard,
  });
  const hasItemAnyRefinementQuestions = React.useCallback(
    (itemId: string) =>
      hasRefinementWizard
        ? itemIdsWithQuestionByOnQuoteRefinement.includes(itemId)
        : false,
    [hasRefinementWizard, itemIdsWithQuestionByOnQuoteRefinement]
  );

  const refetch = React.useCallback(async () => {
    if (hasItemWizard) {
      refetchQuestionByItemEach();
    }
    if (hasRefinementWizard) {
      refetchQuestionByOnQuoteRefinement();
    }
  }, [
    hasItemWizard,
    hasRefinementWizard,
    refetchQuestionByItemEach,
    refetchQuestionByOnQuoteRefinement,
  ]);

  return [hasItemAnyQuestions, hasItemAnyRefinementQuestions, refetch] as const;
}

function useItemIdsWithQuestionByItemEach({
  quoteId,
  enabled,
}: {
  quoteId: string;
  enabled: boolean;
}) {
  const { auth } = useAuth();
  const viewer = useUserData().currentUser!;

  const { query } = useWizardQuestion({
    token: auth.token,
    viewerId: viewer.id,
    body: {
      applyItemActions: [],
      questionStrategy: { type: "item-each" },
      sourceDoc: {
        type: "quote",
        quoteId: quoteId,
        contractorId: viewer.organisation.id,
      },
    },
    enabled,
  });

  const itemIdsWithQuestionByItemEach = React.useMemo(() => {
    return query.data?.questions.map(q => q.itemId) ?? [];
  }, [query.data?.questions]);

  return { itemIdsWithQuestionByItemEach, refetch: query.refetch };
}

function useItemIdsWithQuestionByOnQuoteRefinement({
  quoteId,
  enabled,
}: {
  quoteId: string;
  enabled: boolean;
}) {
  const { auth } = useAuth();
  const viewer = useUserData().currentUser!;

  const { query } = useWizardQuestion({
    token: auth.token,
    viewerId: viewer.id,
    body: {
      applyItemActions: [],
      questionStrategy: {
        type: "questionControl",
        askWhen: "onQuoteRefinement",
        askWhom: "contractor",
        startingNode: { type: "root" },
      },
      sourceDoc: {
        type: "quote",
        quoteId: quoteId,
        contractorId: viewer.organisation.id,
      },
    },
    enabled,
  });

  const itemIdsWithQuestionByOnQuoteRefinement = React.useMemo(() => {
    return query.data?.questions.map(q => q.itemId) ?? [];
  }, [query.data?.questions]);

  return { itemIdsWithQuestionByOnQuoteRefinement, refetch: query.refetch };
}
