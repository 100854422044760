import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal, Select } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Box,
  DialogContentText,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { noop, uniqueId } from "lodash-es";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../../auth/RestrictedByDocumentPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { Button } from "../../../commons/button/Button.js";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField.js";
import { DurationDailyField } from "../../../commons/form-fields/DurationDailyField.js";
import { TimePickerField } from "../../../commons/form-fields/TimePickerField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { VirtualBareTreeStandaloneItem } from "../../../trees-virtual/components/VirtualBareTreeStandaloneItem.js";
import { getAssigneeOptions } from "../../tasks/helpers.js";
import { createTreeSimpleItem } from "../../tasks/trees.js";
import {
  FormValues,
  getDurationFromTimes,
  getEmptyWorkSession,
  getTimesFromDuration,
  isWorkSessionValid,
} from "./AddTaskWorkSessionModal.js";
import { useAddTaskWorkSessionModalQuery } from "./AddTaskWorkSessionModal.generated.js";
import {
  AddTaskWorkSessionsModal_ItemFragment,
  useCreateWorkSessionsMutation,
} from "./AddTaskWorkSessionsModal.generated.js";

interface FormMultipleValues {
  sessions: FormValues[];
}

interface Props {
  projectId: string;
  itemId: string;
  title?: string;
  description?: string;
  handleClose: () => void;
  handleComplete?: () => void;
  allDocItems: AddTaskWorkSessionsModal_ItemFragment[];
}

export const AddTaskWorkSessionsModal = ({
  itemId,
  projectId,
  title,
  description,
  handleClose,
  handleComplete,
  allDocItems,
}: Props) => {
  const { t } = useTranslate(["Task", "Global"]);
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();

  const { enqueueSnackbar } = useSnackbar();
  const formId = useMemo(() => uniqueId(), []);
  const now = useMemo(() => moment(), []);

  const [assigneeId, setAssigneeId] = useState<string>(viewer.id);

  const item = useMemo(
    () => allDocItems?.find(i => i.id === itemId),
    [itemId, allDocItems]
  );

  const query = useAddTaskWorkSessionModalQuery({
    client,
    variables: { projectId, quoteId: item!.docId, itemId },
    skip: !item?.docId,
    fetchPolicy: "cache-and-network",
  });

  const [addWorkSessions] = useCreateWorkSessionsMutation({
    client,
    refetchQueries: [
      namedOperations.Query.TasksTimesheetTasksForm,
      namedOperations.Query.ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?,
    ],
    awaitRefetchQueries: false,
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const quote = project?.tasks[0];

  const TreeItem = useMemo(
    () =>
      quote?.agreement
        ? createTreeSimpleItem({
            pathToDocPage: null,
            docAgreement: quote?.agreement,
            navigateToItem: noop,
            setItemExpanded: noop,
          })
        : undefined,
    [quote?.agreement]
  );

  const initialValues: FormMultipleValues = {
    sessions: [getEmptyWorkSession(now)],
  };

  const validationSchema = Yup.object().shape({
    sessions: Yup.array().of(
      Yup.object().shape({
        date: Yup.object()
          .label(
            t("Date", {
              ns: "Task",
            })
          )
          .required(),
        duration: Yup.number()
          .label(
            t("Time spent", {
              ns: "Task",
            })
          )
          .required(),
        timeStart: Yup.string().label(
          t("Start time", {
            ns: "Task",
          })
        ),
        timeEnd: Yup.string()
          .label(
            t("Finish time", {
              ns: "Task",
            })
          )
          .when("timeStart", (timeStart: string, schema: any) => {
            return schema.test({
              test: (timeEnd: string) => {
                return (
                  !timeStart ||
                  !timeEnd ||
                  moment(timeStart, "LT").valueOf() <
                    moment(timeEnd, "LT").valueOf()
                );
              },
              message: t("Finish time should be after a start time", {
                ns: "Task",
              }),
            });
          }),
      })
    ),
  });

  const handleSubmit = async (values: FormMultipleValues) => {
    if (!values.sessions.some(s => isWorkSessionValid(s))) return;

    const input = {
      itemId,
      inputs: values.sessions.filter(isWorkSessionValid).map(s => ({
        assigneeId,
        ...(s.timeStart && s.timeEnd
          ? {
              from: moment(s.date)
                .set({
                  hours: +s.timeStart.split(":")[0],
                  minutes: +s.timeStart.split(":")[1],
                })
                .toISOString(true),
              till: moment(s.date)
                .set({
                  hours: +s.timeEnd.split(":")[0],
                  minutes: +s.timeEnd.split(":")[1],
                })
                .toISOString(true),
            }
          : {
              date: s.date.format("YYYY-MM-DD"),
              duration: s.duration,
            }),
      })),
    };

    try {
      await addWorkSessions({
        variables: {
          input,
        },
      });
      enqueueSnackbar(
        t("Task work sessions added", {
          ns: "Task",
        })
      );
      handleComplete?.();
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormMultipleValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({
        setFieldValue,
        values,
        isSubmitting,
        isValid,
      }: FormikProps<FormMultipleValues>) => (
        <Modal
          title={
            title ??
            t("Add task work sessions", {
              ns: "Task",
            })
          }
          dialogProps={{ maxWidth: "xs" }}
          actionButtons={[
            {
              label: t("Skip", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Add", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled:
                  !isValid || !values.sessions.some(s => isWorkSessionValid(s)),
                loading: isSubmitting,
              },
            },
          ]}
          handleClose={handleClose}
          isLoading={query.loading}
        >
          {description && <DialogContentText>{description}</DialogContentText>}

          {quote && item && TreeItem && (
            <Box mb={3 / 2}>
              <VirtualBareTreeStandaloneItem
                item={{
                  ...item,
                  docViewerPermissions: quote.viewerPermissions,
                  docAgreement: quote.agreement,
                }}
                items={allDocItems.map(item => ({
                  ...item,
                  docViewerPermissions: quote.viewerPermissions,
                  docAgreement: quote.agreement,
                }))}
                depth={0}
                itemComponent={TreeItem}
              />
            </Box>
          )}

          {project && quote && (
            <RestrictedByDocumentPermissionWithDebug
              permission="MANAGE_TASK"
              document={quote}
            >
              {!!viewer.roles.find(r => r.internalName === "ORG_ADMIN") && (
                <Box mb={3 / 2}>
                  <Select
                    label={t("Team member", {
                      ns: "Task",
                    })}
                    value={assigneeId}
                    options={getAssigneeOptions(project)}
                    onChange={value => {
                      setAssigneeId(value);
                    }}
                  />
                </Box>
              )}
            </RestrictedByDocumentPermissionWithDebug>
          )}

          <Form id={formId}>
            <FieldArray
              name="sessions"
              render={({ remove, push }) => (
                <Stack width="100%" flexDirection="column">
                  {values.sessions.map((session, index) => (
                    <React.Fragment key={session.id}>
                      {index > 0 && (
                        <Divider orientation="horizontal" flexItem />
                      )}
                      <Stack>
                        <Stack flexDirection="row" alignItems="center">
                          <Stack
                            flexDirection="column"
                            flexGrow={1}
                            flexShrink={1}
                          >
                            <Stack>
                              <DatePickerField
                                required
                                disableFuture
                                name={`sessions.${index}.date`}
                                label={t("Date", {
                                  ns: "Task",
                                })}
                              />
                              <DurationDailyField
                                required
                                name={`sessions.${index}.duration`}
                                label={t("Time spent", {
                                  ns: "Task",
                                })}
                                onChange={duration => {
                                  setFieldValue(
                                    `sessions.${index}.duration`,
                                    duration
                                  );
                                  if (duration && session.timeStart) {
                                    const [timeStart, timeEnd] =
                                      getTimesFromDuration(
                                        session.timeStart,
                                        duration
                                      );
                                    setFieldValue(
                                      `sessions.${index}.timeStart`,
                                      timeStart
                                    );
                                    setFieldValue(
                                      `sessions.${index}.timeEnd`,
                                      timeEnd
                                    );
                                  }
                                }}
                              />
                            </Stack>
                            <Typography variant="caption" color="textSecondary">
                              {t("Optionally", {
                                ns: "Task",
                              })}
                              :
                            </Typography>
                            <Stack>
                              <TimePickerField
                                name={`sessions.${index}.timeStart`}
                                label={t("Start time", {
                                  ns: "Task",
                                })}
                                onChange={(e, value) => {
                                  setFieldValue(
                                    `sessions.${index}.timeStart`,
                                    value
                                  );
                                  if (value && session.timeEnd) {
                                    setFieldValue(
                                      `sessions.${index}.duration`,
                                      getDurationFromTimes(
                                        value,
                                        session.timeEnd
                                      )
                                    );
                                  }
                                }}
                              />
                              <TimePickerField
                                name={`sessions.${index}.timeEnd`}
                                label={t("Finish time", {
                                  ns: "Task",
                                })}
                                onChange={(e, value) => {
                                  setFieldValue(
                                    `sessions.${index}.timeEnd`,
                                    value
                                  );
                                  if (value && session.timeStart) {
                                    setFieldValue(
                                      `sessions.${index}.duration`,
                                      getDurationFromTimes(
                                        session.timeStart,
                                        value
                                      )
                                    );
                                  }
                                }}
                              />
                            </Stack>
                          </Stack>
                          <Tooltip
                            title={t("Remove work session", {
                              ns: "Task",
                            })}
                          >
                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </Stack>
                    </React.Fragment>
                  ))}
                  <Divider />
                  <Box>
                    <Button
                      size="small"
                      variant="text"
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        push(getEmptyWorkSession(now));
                      }}
                    >
                      {t("New work session", {
                        ns: "Task",
                      })}
                    </Button>
                  </Box>
                </Stack>
              )}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
