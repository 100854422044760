/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { ProjectPhaseFragmentDoc } from './ProjectPhasesConfigurator.generated.js';
export type ProjectPhaseChip_ProjectFragment = { __typename: 'Project', id: string, phase: { __typename: 'OrganisationProjectPhase', id: string, name: string, applicableForState: Types.ProjectStateMachineStatus, color: string } };

export type ProjectPhaseChip_OrganisationSettingsFragment = { __typename: 'OrganisationSetting', id: string, projectPhases: Array<{ __typename: 'OrganisationProjectPhase', id: string, name: string, applicableForState: Types.ProjectStateMachineStatus, color: string }> };

export const ProjectPhaseChip_ProjectFragmentDoc = gql`
    fragment ProjectPhaseChip_Project on Project {
  id
  phase {
    id
    ...ProjectPhase
  }
}
    ${ProjectPhaseFragmentDoc}`;
export const ProjectPhaseChip_OrganisationSettingsFragmentDoc = gql`
    fragment ProjectPhaseChip_OrganisationSettings on OrganisationSetting {
  id
  projectPhases(filterIncludeState: [opportunity, contracted]) {
    id
    ...ProjectPhase
  }
}
    ${ProjectPhaseFragmentDoc}`;