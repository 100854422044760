import { ModalOpenButton } from "@msys/ui";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { OrderViaIdsConnectModal } from "../OrderViaIdsConnectModal.js";

interface Props
  extends Omit<
    React.ComponentProps<typeof OrderViaIdsConnectModal>,
    "handleClose" | "handleComplete"
  > {}

export const IdsConnectOrderButton = ({
  projectId,
  orderId,
  systemSupplierOrganisationId,
  clientId,
  idsConnectInfo,
}: Props) => {
  const { t } = useTranslate(["PurchaseOrders", "Global"]);

  return (
    <ModalOpenButton
      Modal={OrderViaIdsConnectModal}
      modalProps={{
        projectId,
        orderId,
        systemSupplierOrganisationId,
        clientId,
        idsConnectInfo,
        handleComplete: openWindowWithPost,
      }}
    >
      <Button size="small" variant="contained" color="secondary">
        {t("Checkout with IDS Connect", {
          ns: "PurchaseOrders",
        })}
      </Button>
    </ModalOpenButton>
  );
};

function openWindowWithPost(url: string, data: { [key: string]: string }) {
  const form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.enctype = "multipart/form-data";
  form.style.display = "none";

  for (const key in data) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
