import { gql } from "@apollo/client";
import { LabeledValue, Modal, ModalOpenButton } from "@msys/ui";
import { Help as HelpIcon } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import React from "react";
import * as uuid from "uuid";
import * as Yup from "yup";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { TextField } from "../../../commons/form-fields/TextField.js";
import { LexicalRichTextEditorWithHtml } from "../../../commons/rich-text-editor/LexicalRichTextEditorWIthHtml.js";
import { useFeature } from "../../../../common/FeatureFlags.js";
import { EmailTemplateContext } from "../../../../clients/graphqlTypes.js";
import { AttachmentFileRow } from "../../attachments/AttachmentFileRow.js";
import { AttachmentUploadRow } from "../../attachments/AttachmentUploadRow.js";
import { Attachment } from "../../attachments/helpers.js";
import { useEmailTemplateContexts } from "../boxes/useEmailTemplateContexts.js";
import { OrganisationEmailTemplateAvailableReplacementsModal } from "./OrganisationEmailTemplateAvailableReplacementsModal.js";

interface TemplateFormValues {
  context: EmailTemplateContext;
  description: string;
  subject: string;
  content: string;
  attachments: (Attachment & { group: string })[];
}

export const OrganisationEmailTemplateModal = ({
  template,
  title,
  handleClose,
  handleComplete,
}: {
  template?: TemplateFormValues;
  title: string;
  handleClose: () => void;
  handleComplete: (
    values: TemplateFormValues,
    handleClose: () => void
  ) => Promise<void>;
}) => {
  const crmEmailContentDebug = useFeature("CrmEmailContentDebug");
  const { t } = useTranslate(["OrganisationSettings", "Global", "Contents"]);
  const {
    labels: emailTemplateContextLabels,
    options: emailTemplateContextOptions,
  } = useEmailTemplateContexts();

  const handleSubmit = async (values: TemplateFormValues) => {
    await handleComplete(values, handleClose);
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<TemplateFormValues>
      initialValues={
        template
          ? {
              context: template.context,
              description: template.description,
              subject: template.subject,
              content: template.content,
              attachments: template.attachments,
            }
          : {
              context: "PROJECT",
              description: "",
              subject: "",
              content: "",
              attachments: [],
            }
      }
      validationSchema={Yup.object().shape({
        description: Yup.string()
          .label(t("Description", { ns: "OrganisationSettings" }))
          .trim()
          .required(),
        subject: Yup.string()
          .label(t("Subject", { ns: "OrganisationSettings" }))
          .trim()
          .required(),
        content: Yup.string()
          .label(t("Text", { ns: "OrganisationSettings" }))
          .trim()
          .required(),
      })}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Modal
          title={title}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", { ns: "Global" }),
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
              handleClick: handleClose,
            },
            {
              label: t("Confirm", { ns: "Global" }),
              buttonProps: {
                form: formId,
                type: "submit",
                loading: formikProps.isSubmitting,
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
          maxWidth="md"
        >
          <Form id={formId}>
            <Stack spacing={1}>
              {template ? (
                <LabeledValue
                  label={t("Context", { ns: "OrganisationSettings" })}
                >
                  {emailTemplateContextLabels[template.context]}
                </LabeledValue>
              ) : (
                <SelectField
                  name="context"
                  label={t("Context", { ns: "OrganisationSettings" })}
                  options={emailTemplateContextOptions}
                />
              )}
              <TextField
                required
                name={"description"}
                label={t("Description", { ns: "Global" })}
              />
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={1}
              >
                <TextField
                  required
                  name={"subject"}
                  label={t("Subject", { ns: "OrganisationSettings" })}
                />
                <ModalOpenButton
                  Modal={OrganisationEmailTemplateAvailableReplacementsModal}
                  modalProps={{ context: formikProps.values.context }}
                >
                  <IconButton size="small" color="secondary">
                    <Tooltip
                      title={t("Available replacements", {
                        ns: "OrganisationSettings",
                      })}
                    >
                      <HelpIcon />
                    </Tooltip>
                  </IconButton>
                </ModalOpenButton>
              </Stack>
              <LexicalRichTextEditorWithHtml
                placeholder={t("Text", { ns: "OrganisationSettings" })}
                initialHtmlValue={formikProps.values.content}
                handleChange={value => {
                  formikProps.setFieldValue("content", value);
                }}
                debug={crmEmailContentDebug}
              />

              <FieldArray
                name="attachments"
                render={arrayHelpers => (
                  <Stack>
                    <AttachmentUploadRow
                      title={t("Attachments", {
                        ns: "Global",
                      })}
                      subTitle={t(
                        "Upload documents (eg. terms and conditions) that will be added to the email created from this template.",
                        {
                          ns: "OrganisationSettings",
                        }
                      )}
                      accept="*"
                      multiple={true}
                      onComplete={attachments => {
                        attachments.forEach(attachment =>
                          arrayHelpers.push({
                            ...attachment,
                            group: "",
                            id: uuid.v4(),
                          })
                        );
                      }}
                    />
                    {formikProps.values.attachments.length > 0 &&
                      formikProps.values.attachments.map(
                        (attachment: Attachment, index: number) => (
                          <AttachmentFileRow
                            key={attachment.id}
                            attachment={attachment}
                            onRemove={async () => {
                              arrayHelpers.remove(index);
                            }}
                          />
                        )
                      )}
                  </Stack>
                )}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
