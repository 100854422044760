import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { uniq, without } from "lodash-es";
import React from "react";
import {
  getAllItemChildren,
  getAllParentItems,
  getItemChildren,
  getRootItem,
} from "../../trees/helpers.js";
import { ItemTreeItem } from "../../trees/types.js";

interface Item extends ItemTreeItem {
  title: string;
  pathForPdf: string;
}

interface Props {
  name: string;
  allDocItems: Item[];
  values: string[];
  setValues: (values: string[]) => void;
}

export const CheckboxTreeField = ({
  name,
  allDocItems,
  values,
  setValues,
}: Props) => {
  const { t } = useTranslate(["Global"]);

  function renderCheckboxFields(item: Item, depth: number) {
    const subItems = getItemChildren(item, allDocItems);
    return (
      <Box key={item.id} sx={{ ml: `${depth * 8}px` }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id={`${name}-${item.id}-checkbox`}
                checked={values.includes(item.id)}
                onChange={(e, checked) => {
                  if (!checked) {
                    const allChildrenId = getAllItemChildren(
                      item,
                      allDocItems
                    ).map(i => i.id);
                    setValues(without(values, item.id, ...allChildrenId));
                  } else {
                    const newValues = getAllParentItems(item, allDocItems)
                      .concat(item)
                      .map(i => i.id);
                    setValues(uniq([...newValues, ...values]));
                  }
                }}
              />
            }
            label={`${item.pathForPdf} ${item.title}`}
          />
        </FormGroup>
        {subItems.map(subItem => renderCheckboxFields(subItem, depth + 1))}
      </Box>
    );
  }

  const allChecked = values.length === allDocItems.length;

  return (
    <React.Fragment>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id={`${name}-checkbox-all`}
              checked={allChecked}
              onChange={() => {
                setValues(allChecked ? [] : allDocItems.map(item => item.id));
              }}
            />
          }
          label={t("All", { ns: "Global" })}
        />
      </FormGroup>
      <Divider sx={{ mb: 0.5 }} />
      {renderCheckboxFields(getRootItem(allDocItems), 0)}
    </React.Fragment>
  );
};

export default CheckboxTreeField;
