/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated.js';
import { EditOrganisationProfileContactModal_OrganisationProfileFragmentDoc } from '../modals/EditOrganisationProfileContactModal.generated.js';
export type OrganisationPublicContactBox_OrganisationProfileFragment = { __typename: 'OrganisationProfile', id: string, contactEmail?: any | null, contactWebsite?: string | null, slug: string, contactAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, contactPhones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }>, openingHours?: Array<{ __typename: 'OpeningHour', from?: string | null, to?: string | null, isOpen: boolean }> | null, organisation: { __typename: 'Organisation', id: string } };

export const OrganisationPublicContactBox_OrganisationProfileFragmentDoc = gql`
    fragment OrganisationPublicContactBox_OrganisationProfile on OrganisationProfile {
  id
  contactAddress {
    ...AddressDetails__Address
  }
  contactEmail
  contactPhones {
    id
    number
    type
    main
  }
  contactWebsite
  openingHours {
    from
    to
    isOpen
  }
  organisation {
    id
  }
  ...EditOrganisationProfileContactModal_OrganisationProfile
}
    ${AddressDetails__AddressFragmentDoc}
${EditOrganisationProfileContactModal_OrganisationProfileFragmentDoc}`;