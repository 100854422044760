import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  ImageWithPreview,
  ModalOpenButton,
  useIconButtonLightStyles,
  useScreenWidth,
} from "@msys/ui";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import { Business as BusinessIcon } from "@mui/icons-material";
import { StarBorder as StarBorderIcon } from "@mui/icons-material";
import { VerifiedUser as VerifiedUserIcon } from "@mui/icons-material";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { color, media } from "../../common/MuiThemeProvider.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../auth/RestrictedByOrganisationPermission.js";
import { useUserData } from "../auth/useUserData.js";
import { Page } from "../commons/layout/Page.js";
import { PageContainer } from "../commons/layout/PageContainer.js";
import { Stack } from "../commons/layout/Stack.js";
import { UploadPhotoModal } from "../commons/modals/UploadPhotoModal.js";
import { Attachment } from "../features/attachments/helpers.js";
import { M1CalculatorsBox } from "../features/m1-calculators/M1CalculatorsBox.js";
import {
  Meister1Process,
  Meister1ProcessRef,
  Meister1State,
  Meister1WidgetInputs,
} from "../features/m1-calculators/Meister1Process.js";
import { useModifyOrganisationProfile__AttachmentsMutation } from "../features/organisations/Organisations.generated.js";
import { Organisation3dTemplatesBox } from "../features/organisations/boxes/Organisation3dTemplatesBox.js";
import { OrganisationPublicAboutBox } from "../features/organisations/boxes/OrganisationPublicAboutBox.js";
import { OrganisationPublicContactBox } from "../features/organisations/boxes/OrganisationPublicContactBox.js";
import { OrganisationPublicServicesBox } from "../features/organisations/boxes/OrganisationPublicServicesBox.js";
import { OrganisationPublicShopBox } from "../features/organisations/boxes/OrganisationPublicShopBox.js";
import { transientOptions } from "../styles.js";
import {
  BackgroundImage_OrganisationProfileFragment,
  LogoImage_OrganisationProfileFragment,
  Meister1PollForWebhookProjectContracteeInvitationDocument,
  Meister1PollForWebhookProjectContracteeInvitationQuery,
  Meister1PollForWebhookProjectContracteeInvitationQueryVariables,
  OrganisationPublicProfileQuery,
  useOrganisationPublicProfileQuery,
} from "./OrganisationPublicProfile.generated.js";

export function OrganisationPublicProfile() {
  const { slug } = useParams();
  if (!slug) throw new Error("Slug is missing");

  const { currentUser: viewer } = useUserData();

  const client = useApolloClient();
  const query = useOrganisationPublicProfileQuery({
    client,
    variables: { slug },
  });

  const profile = getDataOrNull(query.data?.organisationProfile)?.profile;

  const meister1ProcessRef = React.useRef<Meister1ProcessRef>(null);

  const desiredInvitationId = useMemo(() => v4(), []);

  // const pollForProjectContracteeInvitation = useMeister1PollForProjectContracteeInvitationQuery({client})

  const canEdit = viewer?.organisation?.id === profile?.organisation.id;

  let m1LinksDisabled = false;

  if (viewer) {
    m1LinksDisabled =
      viewer.organisation.id === profile?.organisation.id ||
      viewer.organisation.organisationType !== "CLIENT";
  }

  const handleStartProcess = (identName: string) => {
    const inputs: Meister1WidgetInputs | undefined = (() => {
      if (!viewer) return undefined;
      const viewerOrganisationMembership = viewer;
      if (!viewerOrganisationMembership) return undefined;

      const email = viewerOrganisationMembership.email ?? "";
      const phone =
        (
          viewerOrganisationMembership.phones.find(e => e.main === true) ??
          viewerOrganisationMembership.phones[0]
        )?.number ?? "";

      return {
        ...(viewer.title === "mr"
          ? { salutation: "MR" as const }
          : viewer.title === "ms"
            ? { salutation: "MRS" as const }
            : {}),
        firstname: viewer.firstname ?? "",
        lastname: viewer.familyname ?? "",
        street: "",
        street_no: "",
        email,
        phone,
        // TODO
        // zip: "13407",
        // city: "Berlin",
      };
    })();

    const meister1State: Meister1State = {
      type: "webhook-with-invite",
      desiredInvitationId,
    };

    meister1ProcessRef.current?.startProcess({
      identName,
      completeBeforeConfirmPage: false,
      meister1State,
      inputs,
      onComplete: async (leadId, contactData) => {
        while (true) {
          await new Promise(resolve => setTimeout(() => resolve(null), 2000));

          const res = await client.query<
            Meister1PollForWebhookProjectContracteeInvitationQuery,
            Meister1PollForWebhookProjectContracteeInvitationQueryVariables
          >({
            query: Meister1PollForWebhookProjectContracteeInvitationDocument,
            variables: {
              desiredInvitationId,
            },
          });

          if (
            res.data &&
            res.data.meister1PollForWebhookProjectContracteeInvitation
          ) {
            if (window.top)
              window.top.location.href =
                res.data.meister1PollForWebhookProjectContracteeInvitation.contracteeInvitationUrl;
            break;
          }
        }
      },
    });
  };

  return (
    <Page isTopBarVisible title={profile?.organisation?.title}>
      <PageContainer>
        {profile && (
          <Container maxWidth="lg">
            <Stack flexDirection="column">
              <OrganisationPublicHeader
                slug={slug}
                profile={profile}
                canEdit={canEdit}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <OrganisationPublicServicesBox
                    organisationProfile={profile}
                    canEdit={canEdit}
                  />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Stack flexDirection="column" spacing={2}>
                    <OrganisationPublicAboutBox
                      organisationProfile={profile}
                      canEdit={canEdit}
                    />
                    <OrganisationPublicContactBox
                      organisationProfile={profile}
                      canEdit={canEdit}
                    />
                    {viewer && profile.hasShop && (
                      <OrganisationPublicShopBox slug={slug} />
                    )}

                    {profile.has3DShop && (
                      <Organisation3dTemplatesBox slug={slug} />
                    )}

                    {profile.meister1Flows.length > 0 && (
                      <M1CalculatorsBox
                        flows={profile.meister1Flows}
                        linksDisabled={m1LinksDisabled}
                        handleStartProcess={handleStartProcess}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Container>
        )}
      </PageContainer>

      {profile && (
        <Meister1Process
          ref={meister1ProcessRef}
          forOrganisationId={profile.organisation.id}
        />
      )}
    </Page>
  );
}

const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  margin-bottom: 4px;

  @media (${media.minTablet}) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 8px;
  }
`;

interface Props {
  slug: string;
  canEdit: boolean;
  profile: Exclude<
    Exclude<
      OrganisationPublicProfileQuery["organisationProfile"],
      { __typename: "MissingCapabilities" }
    >["profile"],
    null | undefined
  >;
}

export function OrganisationPublicHeader({ profile, canEdit, slug }: Props) {
  const { t } = useTranslate("OrganisationPublicProfile");
  const { isMaxPhone } = useScreenWidth();
  return (
    <>
      <OrganisationPublicBackgroundImage
        profile={profile}
        canEdit={canEdit}
        slug={slug}
      />
      <StyledContainer>
        <OrganisationPublicLogoImage
          profile={profile}
          canEdit={canEdit}
          slug={slug}
        />
        <Box flexGrow={1} alignSelf={isMaxPhone ? "stretch" : undefined}>
          <Stack justifyContent={isMaxPhone ? "center" : "space-between"}>
            <Typography
              variant="h1"
              align={isMaxPhone ? "center" : "left"}
              gutterBottom
            >
              {profile.organisation.title}
            </Typography>
            {!isMaxPhone && profile.isMeister && (
              <Stack spacing={1 / 2} style={{ marginTop: 2 }}>
                <VerifiedUserIcon />
                <Typography variant="body1">{t("Meister")}</Typography>
              </Stack>
            )}
          </Stack>
          <Stack
            justifyContent={isMaxPhone ? "center" : "flex-start"}
            alignItems="center"
            spacing={2}
          >
            <Typography variant="body2" component="div">
              <Stack
                spacing={1}
                alignItems="center"
                justifyContent={isMaxPhone ? "center" : "flex-start"}
              >
                <Stack spacing={0}>
                  {Array(5)
                    .fill(null)
                    .map((v, index) => (
                      <StarBorderIcon
                        key={index}
                        fontSize="small"
                        style={{ color: color.grey }}
                      />
                    ))}
                </Stack>
                <Typography variant="body2" style={{ color: color.grey }}>
                  0.0
                </Typography>
                <Typography variant="body2" style={{ color: color.grey }}>
                  {isMaxPhone
                    ? `(0)`
                    : `(${t("{number} reviews", { ns: "OrganisationPublicProfile", number: "0" })})`}
                </Typography>
              </Stack>
            </Typography>
            {isMaxPhone && profile.isMeister && (
              <Stack spacing={1 / 2}>
                <VerifiedUserIcon fontSize="small" />
                <Typography variant="body2">{t("Meister")}</Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      </StyledContainer>
    </>
  );
}

function OrganisationPublicLogoImage({
  profile,
  canEdit,
  slug,
}: {
  slug: string;
  canEdit: boolean;
  profile: LogoImage_OrganisationProfileFragment;
}) {
  const viewer = useUserData().currentUser!;
  const { classes: iconButtonClasses } = useIconButtonLightStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate("OrganisationPublicProfile");

  const client = useApolloClient();
  const [modifyProfile, { loading: modifyProfileLoading }] =
    useModifyOrganisationProfile__AttachmentsMutation({
      client,
    });

  const onAttachment = async (attachment: Attachment | null) => {
    try {
      await modifyProfile({
        variables: {
          input: {
            slug,
            logo: attachment
              ? {
                  url: attachment.url,
                  title: attachment.title,
                  mimeType: attachment.mimeType,
                }
              : null,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <StyledContainer2 $hasImage={!!profile.logo}>
      {profile.logo ? (
        <ImageWithPreview
          src={profile.logo.url}
          title={profile.logo.title}
          borderRadius="8px"
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <StyledBusinessIcon />
      )}

      {canEdit && (
        <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
          {viewer.organisation.id === profile.organisation.id && (
            <ButtonContainer>
              <ModalOpenButton
                Modal={UploadPhotoModal}
                modalProps={{
                  title: t("Edit logo"),
                  initialUrl: profile?.logo?.url,
                  initialTitle: profile?.logo?.title,
                  handleComplete: async (attachment: Attachment) => {
                    await onAttachment(attachment);
                  },
                  handleRemove: profile.logo
                    ? async () => {
                        await onAttachment(null);
                      }
                    : undefined,
                  loading: modifyProfileLoading,
                  skipEdit: true,
                }}
              >
                <IconButton
                  disabled={!canEdit || modifyProfileLoading}
                  color="primary"
                  classes={iconButtonClasses}
                  size="large"
                >
                  <AddAPhotoIcon />
                </IconButton>
              </ModalOpenButton>
            </ButtonContainer>
          )}
        </RestrictedByOrganisationPermissionWithDebug>
      )}
    </StyledContainer2>
  );
}

const StyledContainer2 = styled("div", transientOptions)<{
  $hasImage: boolean;
}>`
  border-radius: 8px;
  background-color: ${({ theme, $hasImage }) =>
    $hasImage ? theme.palette.common.white : theme.palette.grey[300]};

  position: relative;

  width: 96px;
  height: 96px;

  margin-top: -72px;
  margin-bottom: 12px;

  flex-shrink: 0;
  flex-grow: 0;

  border: 2px solid ${({ theme }) => theme.palette.common.white};

  ${({ $hasImage }) =>
    $hasImage
      ? `box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;`
      : ""}

  & > img {
    border-radius: inherit;
  }

  @media (${media.minTablet}) {
    width: 160px;
    height: 160px;

    margin-top: -96px;

    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled("div")`
  position: absolute;
  right: 4px;
  bottom: 4px;
  @media (${media.minTablet}) {
    right: 8px;
    bottom: 8px;
  }
`;

const StyledBusinessIcon = styled(BusinessIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
`;

function OrganisationPublicBackgroundImage({
  profile,
  canEdit,
  slug,
}: {
  slug: string;
  canEdit: boolean;
  profile: BackgroundImage_OrganisationProfileFragment;
}) {
  const viewer = useUserData().currentUser!;
  const { classes: iconButtonClasses } = useIconButtonLightStyles();

  const { t } = useTranslate("OrganisationPublicProfile");

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyProfile, { loading: modifyProfileLoading }] =
    useModifyOrganisationProfile__AttachmentsMutation({
      client,
    });

  const onAttachment = async (attachment: Attachment | null) => {
    try {
      await modifyProfile({
        variables: {
          input: {
            slug,
            background: attachment
              ? {
                  url: attachment.url,
                  title: attachment.title,
                  mimeType: attachment.mimeType,
                }
              : null,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <StyledContainer3>
      {profile.background ? (
        <ImageWithPreview
          src={profile.background.url}
          title={profile.background.title}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
        />
      ) : null}

      {canEdit && (
        <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
          {viewer.organisation.id === profile.organisation.id && (
            <ButtonContainer2>
              <ModalOpenButton
                Modal={UploadPhotoModal}
                modalProps={{
                  title: t("Edit background image"),
                  initialUrl: profile?.background?.url,
                  initialTitle: profile?.background?.title,
                  handleComplete: async (attachment: Attachment) => {
                    await onAttachment(attachment);
                  },
                  handleRemove: profile.background
                    ? async () => {
                        await onAttachment(null);
                      }
                    : undefined,
                  loading: modifyProfileLoading,
                  skipEdit: true,
                }}
              >
                <IconButton
                  disabled={!canEdit || modifyProfileLoading}
                  color="primary"
                  classes={iconButtonClasses}
                  size="large"
                >
                  <AddAPhotoIcon />
                </IconButton>
              </ModalOpenButton>
            </ButtonContainer2>
          )}
        </RestrictedByOrganisationPermissionWithDebug>
      )}
    </StyledContainer3>
  );
}

const StyledContainer3 = styled("div")`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${({ theme }) => theme.palette.grey[300]};

  position: relative;

  width: 100%;
  height: 100px;

  margin-top: 0;
  margin-bottom: 12px;

  & > * {
    border-radius: inherit;
  }

  @media (${media.minTablet}) {
    height: 200px;

    margin-bottom: 16px;
  }
  @media (${media.minDesktop}) {
    height: 220px;
  }
  @media (min-width: 1368px) {
    height: 240px;
  }
`;

const ButtonContainer2 = styled("div")`
  position: absolute;
  right: 4px;
  bottom: 4px;
  @media (${media.minTablet}) {
    right: 8px;
    bottom: 8px;
  }
`;
