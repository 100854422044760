import { gql } from "@apollo/client";
import { Typography } from "@mui/material";
import { CardContainer } from "@msys/ui";
import { M1InputsFragment } from "./M1Inputs.generated.js";

export function M1Inputs(props: { m1: M1InputsFragment["m1"] }) {
  return (props.m1?.m1Input ?? []).length > 0 ? (
    <CardContainer>
      <Typography variant="h1" padding={2}>
        Meister1 Inputs
      </Typography>
      <hr />
      <ul>
        {props.m1?.m1Input?.map(l1 => (
          <li key={l1?.label}>
            {l1?.label ?? "n/a"}
            <ul>
              {l1?.fields?.map(l2 => (
                <li key={l2?.label}>
                  {l2?.label}
                  <ul>
                    {l2?.values?.map(l3 => (
                      <li key={l3?.label}>{l3?.label}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </CardContainer>
  ) : null;
}
