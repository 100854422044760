import { useTheme } from "@emotion/react";
import { assertNever } from "@msys/common";
import { Switch } from "@msys/ui";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useFeature } from "../../common/FeatureFlags.js";
import { Forbidden } from "./Forbidden.js";

interface Props {
  isRestricted: boolean;
  otherwise?: React.ReactNode;
}

export const Restricted = ({
  isRestricted,
  otherwise = <Forbidden />,
  children,
}: React.PropsWithChildren<Props>) => {
  return isRestricted ? otherwise : children;
};

interface DebugProps {
  debugLabel: string;
  debugColor: string;
}

export const RestrictedWithDebug = ({
  isRestricted,
  otherwise = null,
  children,
  debugLabel: label,
  debugColor: color,
}: React.PropsWithChildren<Props & DebugProps>) => {
  const debug = useFeature("DebugOutput");

  if (debug) {
    return (
      <RestrictedDebug
        isRestricted={isRestricted}
        otherwise={otherwise}
        color={color}
        label={label}
      >
        {children}
      </RestrictedDebug>
    );
  }

  if (isRestricted) {
    return otherwise;
  }

  return (
    <Restricted isRestricted={isRestricted} otherwise={otherwise}>
      {children}
    </Restricted>
  );
};

function RestrictedDebug({
  isRestricted,
  otherwise,
  color,
  label,
  children,
}: React.PropsWithChildren<{
  isRestricted: boolean;
  otherwise?: React.ReactNode;
  color: string;
  label: string;
}>) {
  const theme = useTheme();
  const position = React.useContext(PositionContext);
  const positionAttributes = getPositionStyle(position);
  const [showRestrictedComponent, setShowRestrictedComponent] =
    React.useState(false);

  return (
    <Box
      component="span"
      sx={{
        display: "block",
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: color,
        position: "relative",
        margin: 0.25,
      }}
    >
      <PositionContext.Provider
        value={POSITIONS[POSITIONS.indexOf(position) + 1] ?? "top-left"}
      >
        {isRestricted && !showRestrictedComponent
          ? (otherwise ?? (
              <div style={{ visibility: "hidden" }}>{children}</div>
            ))
          : children}
      </PositionContext.Provider>
      <Tooltip
        title={
          <Stack
            direction="row"
            alignItems={"center"}
            spacing={0.5}
            whiteSpace={"nowrap"}
          >
            <Box
              borderRadius={"50%"}
              width={10}
              height={10}
              sx={{
                backgroundColor: isRestricted
                  ? theme.palette.error.light
                  : theme.palette.success.light,
              }}
            ></Box>
            <Typography variant="caption">{label}</Typography>
            {isRestricted && (
              <Switch
                label=""
                checked={showRestrictedComponent}
                onChange={() =>
                  setShowRestrictedComponent(!showRestrictedComponent)
                }
              />
            )}
          </Stack>
        }
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        }}
      >
        <Box
          component={"span"}
          sx={{
            zIndex: 1,
            display: "inline-block",
            width: "12px",
            height: "12px",
            backgroundColor: isRestricted
              ? theme.palette.error.light
              : theme.palette.success.light,
            position: "absolute",
            ...positionAttributes,
            ["&:hover > * > span.debug-label"]: {
              visibility: "visible !important",
            },
          }}
        ></Box>
      </Tooltip>
    </Box>
  );
}

const POSITIONS = [
  "top-left",
  "top-right",
  "bottom-right",
  "bottom-left",
] as const;
type Position = (typeof POSITIONS)[number];
const PositionContext = React.createContext<Position>(POSITIONS[0]);

function getPositionStyle(position: Position) {
  switch (position) {
    case "top-left":
      return {
        top: 0,
        left: 0,
      };
    case "top-right":
      return {
        top: 0,
        right: 0,
      };
    case "bottom-right":
      return {
        bottom: 0,
        right: 0,
      };
    case "bottom-left":
      return {
        bottom: 0,
        left: 0,
      };
    default:
      assertNever(position);
  }
}
