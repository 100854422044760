/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomFieldConfigFragment = { __typename: 'CustomFieldConfig', id: string, key: string, objectType: Types.CustomFieldObjectType, dataType: Types.CustomFieldDataType, group: string, allowedValues: string, showAsColumnInList: boolean, showInCreateForm: boolean, mandatory: boolean, isUnique: boolean };

export type CustomFieldConfigQueryVariables = Types.Exact<{
  filterObjectType?: Types.InputMaybe<Types.CustomFieldObjectType>;
}>;


export type CustomFieldConfigQuery = { customFieldConfig: Array<{ __typename: 'CustomFieldConfig', id: string, key: string, objectType: Types.CustomFieldObjectType, dataType: Types.CustomFieldDataType, group: string, allowedValues: string, showAsColumnInList: boolean, showInCreateForm: boolean, mandatory: boolean, isUnique: boolean }> };

export const CustomFieldConfigFragmentDoc = gql`
    fragment CustomFieldConfig on CustomFieldConfig {
  id
  key
  objectType
  dataType
  group
  allowedValues
  showAsColumnInList
  showInCreateForm
  mandatory
  isUnique
}
    `;
export const CustomFieldConfigDocument = gql`
    query CustomFieldConfig($filterObjectType: CustomFieldObjectType) {
  customFieldConfig(filterObjectType: $filterObjectType) {
    id
    ...CustomFieldConfig
  }
}
    ${CustomFieldConfigFragmentDoc}`;

/**
 * __useCustomFieldConfigQuery__
 *
 * To run a query within a React component, call `useCustomFieldConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFieldConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFieldConfigQuery({
 *   variables: {
 *      filterObjectType: // value for 'filterObjectType'
 *   },
 * });
 */
export function useCustomFieldConfigQuery(baseOptions?: Apollo.QueryHookOptions<CustomFieldConfigQuery, CustomFieldConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomFieldConfigQuery, CustomFieldConfigQueryVariables>(CustomFieldConfigDocument, options);
      }
export function useCustomFieldConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomFieldConfigQuery, CustomFieldConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomFieldConfigQuery, CustomFieldConfigQueryVariables>(CustomFieldConfigDocument, options);
        }
export function useCustomFieldConfigSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CustomFieldConfigQuery, CustomFieldConfigQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomFieldConfigQuery, CustomFieldConfigQueryVariables>(CustomFieldConfigDocument, options);
        }
export type CustomFieldConfigQueryHookResult = ReturnType<typeof useCustomFieldConfigQuery>;
export type CustomFieldConfigLazyQueryHookResult = ReturnType<typeof useCustomFieldConfigLazyQuery>;
export type CustomFieldConfigSuspenseQueryHookResult = ReturnType<typeof useCustomFieldConfigSuspenseQuery>;
export type CustomFieldConfigQueryResult = Apollo.QueryResult<CustomFieldConfigQuery, CustomFieldConfigQueryVariables>;