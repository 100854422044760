import { Stack, StackProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { color } from "../../../../common/MuiThemeProvider.js";
import { transientOptions } from "../../../styles.js";

interface Props {
  value: string | number | undefined;
  changedValue: string | number | undefined;
  prefix?: string;
  suffix?: string;
}

export function PreviewValue({
  value,
  changedValue,
  prefix = "",
  suffix = "",
  ...props
}: Props & StackProps) {
  const hasChanged = Boolean(changedValue) && changedValue !== value;
  return (
    <Stack {...props}>
      <ValueContainer $hasChanged={hasChanged}>
        {prefix}
        {value}
        {suffix}
      </ValueContainer>
      {hasChanged && (
        <ChangedValueContainer>
          {prefix}
          {changedValue}
          {suffix}
        </ChangedValueContainer>
      )}
    </Stack>
  );
}

const ValueContainer = styled(
  "span",
  transientOptions
)<{
  $hasChanged: boolean;
}>(({ $hasChanged }) => ({
  textDecoration: $hasChanged ? "line-through" : "none",
}));

const ChangedValueContainer = styled("span")`
  background-color: ${color.yellowLight};
  border-radius: 2px;
  padding: 0 4px;
  margin: 0 -4px;
`;
