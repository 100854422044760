import { flatten, includes, uniq } from "lodash-es";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { Tabs } from "@msys/ui";
import { getAllParentItems } from "../../trees/helpers.js";
import { TreeHelperItem } from "../../trees/types.js";
import { InvoiceType } from "../../../clients/graphqlTypes.js";

type QuickFilterValue = "ALL" | "INVOICEABLE" | "DONE";

interface FilterableItem extends TreeHelperItem {
  id: string;
  isDone: boolean;
}

export function useInvoiceQuickFilters<T extends FilterableItem>(
  initialValue: unknown,
  invoiceableQuoteItemIds: string[],
  invoiceableInInvoiceQuoteItemIds: string[]
): [React.ReactNode, (items: T[], selectedItemIds: string[]) => T[]] {
  const { t } = useTranslate(["InvoiceDetailsForm", "Global"]);

  const [quickFilter, setQuickFilter] = React.useState<QuickFilterValue>(
    isInvoiceQuickFilter(initialValue) ? initialValue : "ALL"
  );
  const FilterTabs = (
    <Tabs
      condensed
      onChange={setQuickFilter}
      value={quickFilter}
      options={[
        {
          value: "ALL",
          label: t("All", {
            ns: "Global",
          }),
        },
        {
          value: "INVOICEABLE",
          label: t("Invoiceable", {
            ns: "InvoiceDetailsForm",
          }),
        },
        {
          value: "DONE",
          label: t("Done", {
            ns: "InvoiceDetailsForm",
          }),
        },
      ]}
    />
  );

  const applyQuickFilter = React.useCallback(
    (allItems: T[], selectedItemIds: string[]) => {
      if (quickFilter !== "ALL") {
        const selectableItems = allItems.filter(e => {
          if (quickFilter === "INVOICEABLE") {
            return (
              includes(selectedItemIds, e.id) ||
              invoiceableQuoteItemIds.includes(e.id) ||
              invoiceableInInvoiceQuoteItemIds.includes(e.id)
            );
          }

          if (quickFilter === "DONE") {
            return e.isDone;
          }
          return true;
        });

        return uniq([
          ...selectableItems,
          ...flatten(
            selectableItems.map(item => getAllParentItems(item, allItems))
          ),
        ]);
      }

      return allItems;
    },
    [quickFilter, invoiceableQuoteItemIds, invoiceableInInvoiceQuoteItemIds]
  );

  return [FilterTabs, applyQuickFilter];
}

const isInvoiceQuickFilter = (value: unknown): value is QuickFilterValue => {
  return !!(
    value &&
    typeof value === "string" &&
    ["ALL", "INVOICEABLE", "DONE"].includes(value)
  );
};
