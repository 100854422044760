/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type CrmPersonInformationForm_CrmPersonFragment = { __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string, email: any, isEmailValid?: boolean | null, jobTitle: string, customTags: Array<string>, notices: string, phones: Array<{ __typename: 'Phone', id: string, type: Types.PhoneType, main: boolean, number: string }> };

export const CrmPersonInformationForm_CrmPersonFragmentDoc = gql`
    fragment CrmPersonInformationForm_CrmPerson on CrmPerson {
  id
  title
  firstname
  familyname
  email
  isEmailValid
  jobTitle
  phones {
    id
    type
    main
    number
  }
  customTags
  notices
}
    `;