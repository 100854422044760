import { MenuButton, StatusChip } from "@msys/ui";
import { KeyboardArrowDown as KeyboardArrowDown } from "@mui/icons-material";
import { Chip, MenuItem, useTheme } from "@mui/material";
import { colord } from "colord";
import React from "react";
import { useProjectChangePhase } from "./useProjectChangePhase.js";
import {
  ProjectPhaseChip_OrganisationSettingsFragment,
  ProjectPhaseChip_ProjectFragment,
} from "./ProjectPhaseChip.generated.js";
import { ProjectStateMachineStatus } from "../../../clients/graphqlTypes.js";

interface Props {
  projectId: string;
  currentState: ProjectStateMachineStatus;
  currentPhase: ProjectPhaseChip_ProjectFragment["phase"];
  availablePhases:
    | ProjectPhaseChip_OrganisationSettingsFragment["projectPhases"]
    | undefined;
}

const COLOR_WHITE = "#FFFFFF";

interface PhaseChipProps extends React.ComponentProps<typeof Chip> {
  phase: ProjectPhaseChip_ProjectFragment["phase"];
  endIcon?: React.ReactNode;
  disabled?: boolean;
}

export const PhaseChip = React.forwardRef<HTMLDivElement, PhaseChipProps>(
  ({ disabled, phase, endIcon, ...props }, forwardedRef) => {
    const theme = useTheme();
    const isWhite = colord(phase.color).isEqual(COLOR_WHITE);
    const isDark = colord(phase.color).isDark();
    return (
      <StatusChip
        ref={forwardedRef}
        disabled={disabled}
        label={phase.name}
        endIcon={endIcon}
        variant={isWhite ? "outlined" : "filled"}
        backgroundColor={phase.color}
        textColor={
          isDark ? theme.palette.common.white : theme.palette.primary.main
        }
        {...props}
      />
    );
  }
);

export const ProjectPhaseChip = ({
  projectId,
  currentState,
  currentPhase,
  availablePhases = [],
}: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { handlePhaseChange } = useProjectChangePhase({
    projectId,
    projectPhaseId: currentPhase.id,
  });

  const disabled = availablePhases.length < 1;

  return (
    <MenuButton
      Button={
        <PhaseChip
          disabled={disabled}
          phase={currentPhase}
          endIcon={
            !disabled ? (
              <KeyboardArrowDown
                fontSize="small"
                sx={{
                  transition: "transform 0.2s ease-out",
                  ...(open ? { transform: `rotate(-180deg)` } : undefined),
                }}
              />
            ) : undefined
          }
        />
      }
      onToggle={!disabled ? setOpen : undefined}
    >
      {availablePhases
        .filter(
          phase =>
            phase.applicableForState === currentState &&
            phase.id !== currentPhase.id
        )
        .map(phase => (
          <MenuItem
            key={phase.id}
            onClick={async event => {
              await handlePhaseChange(phase.id);
            }}
          >
            <PhaseChip phase={phase} />
          </MenuItem>
        ))}
    </MenuButton>
  );
};
