import { gql, useApolloClient } from "@apollo/client";
import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { NoteAdd as NoteAddIcon } from "@mui/icons-material";
import { Button, ButtonProps, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "../../../../commons/modals/ConfirmModal.js";
import { namedOperations } from "../../../../../clients/graphqlTypes.js";
import { buildDocPath } from "../../../../utils.js";
import { useQuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation } from "./QuoteTemplateCreateFromItemMenuItem.generated.js";

const pathToDocumentTemplates = "/templates/documents";

interface Props extends ButtonProps {
  projectId: string | null;
  docId: string;
  itemId: string | null;
  docHasAnyPublishedVersion: boolean;
  refetchQueries?: string[];
  onItemReplaced?: (newItemId: string) => void;
}

export const QuoteTemplateCreateFromItemButton = ({
  projectId,
  docId,
  itemId,
  onItemReplaced,
  docHasAnyPublishedVersion,
  refetchQueries,
}: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate(["QuoteItem", "Global"]);

  const client = useApolloClient();
  const [
    createTemplateFromItemMutation,
    { loading: createTemplateFromItemLoading },
  ] = useQuoteTemplateCreateFromItemButton_CreateTemplateFromItemMutation({
    client,
  });

  const createTemplateFromItem = async () => {
    try {
      const response = await createTemplateFromItemMutation({
        variables: {
          input: {
            originProjectId: projectId,
            originDocId: docId,
            originItemId: itemId,
          },
        },
        refetchQueries: [
          namedOperations.Query.TemplateQuotes,
          ...(refetchQueries ?? []),
        ],
        awaitRefetchQueries: false,
      });

      const template = response.data?.createTemplateFromItem?.templateDoc;
      const item = response.data?.createTemplateFromItem?.item;

      if (template) {
        const pathToDoc = buildDocPath(
          `${pathToDocumentTemplates}/${template.id}`,
          template.resolvedAsReadModelVersionNumber ?? null
        );
        enqueueSnackbar(
          <Stack spacing={1}>
            <Typography>
              {t("Template created", {
                ns: "QuoteItem",
              })}
            </Typography>
            <Button
              size="small"
              color="inherit"
              variant="outlined"
              onClick={() => navigate(pathToDoc)}
              sx={{ alignSelf: "flex-start" }}
            >
              {t("Open", {
                ns: "Global",
              })}
            </Button>
          </Stack>
        );
      }

      if (item && item.id !== itemId && onItemReplaced) {
        onItemReplaced(item.id);
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return docHasAnyPublishedVersion ? (
    <ModalOpenButton
      Modal={ConfirmModal}
      modalProps={{
        text: t(
          "Are you sure you want to convert this item into a template? The created template will be published immediately.",
          {
            ns: "QuoteItem",
          }
        ),
        handleConfirm: async () => {
          await createTemplateFromItem();
        },
      }}
    >
      <MenuItemWithIcon icon={<NoteAddIcon />}>
        {t("Save as quote template", { ns: "QuoteItem" })}
      </MenuItemWithIcon>
    </ModalOpenButton>
  ) : (
    <MenuItemWithIcon
      icon={<NoteAddIcon />}
      onClick={createTemplateFromItem}
      disabled={createTemplateFromItemLoading}
    >
      {t("Save as quote template", { ns: "QuoteItem" })}
    </MenuItemWithIcon>
  );
};
