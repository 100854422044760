import { gql, useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { LoadingButton } from "@mui/lab";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import { FormattedFloatField } from "../../commons/form-fields/FormattedFloatField.js";
import { SelectField } from "../../commons/form-fields/SelectField.js";
import { TextField } from "../../commons/form-fields/TextField.js";
import { Stack } from "../../commons/layout/Stack.js";
import { usePimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation } from "./PimSupplierDiscountGroupsImportationColumnMappingModal.generated.js";

type FormValues = {
  csvSettings: { delimiter: string; decimal: string };
  key: string;
  description: string | null;
  value: string;
  _multiplier: number;
};

interface Props {
  csvUrl?: string;
  csvFilename: string;
  title?: string;
  supplierId: string;
  handleClose: () => void;
  handleComplete: ({
    delimiter,
    decimal,
    fieldMapping,
  }: {
    delimiter: string;
    decimal: string;
    fieldMapping: {
      key: string;
      description: string | null;
      value: string;
      _multiplier: number;
    };
  }) => Promise<void> | void;
}

export const PimSupplierDiscountGroupsImportationColumnMappingModal = ({
  csvUrl,
  csvFilename,
  title,
  supplierId,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate([
    "Global",
    "SupplierDiscountGroups",
    "PimImportations",
  ]);

  const [csvHeaders, setCsvHeaders] = React.useState<
    | {
        header: string;
        values: string;
      }[]
    | null
  >(null);

  const client = useApolloClient();
  const [getCsvHeadersMutation, { loading: getCsvHeadersMutationIsLoading }] =
    usePimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeaderMutation(
      { client }
    );

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      validationSchema={Yup.object({
        key: Yup.string().required(),
        description: Yup.string().nullable(),
        value: Yup.string().required(),
        _multiplier: Yup.number().required(),
        csvSettings: Yup.object({
          delimiter: Yup.string().trim().required(),
          decimal: Yup.string().trim().required(),
        }),
      })}
      onSubmit={async values => {
        await handleComplete({
          decimal: values.csvSettings.decimal,
          delimiter: values.csvSettings.delimiter,
          fieldMapping: {
            _multiplier: values._multiplier,
            key: values.key,
            description: values.description,
            value: values.value,
          },
        });

        handleClose();
      }}
      initialValues={{
        key: "",
        description: null,
        value: "",
        _multiplier: 1,
        csvSettings: { delimiter: ";", decimal: "." },
      }}
    >
      {({ isSubmitting, dirty, isValid, errors, values }) => (
        <Modal
          title={title}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            ...(csvHeaders
              ? [
                  {
                    label: t("Create", {
                      ns: "Global",
                    }),
                    buttonProps: {
                      loading: isSubmitting,
                      form: formId,
                      type: "submit" as const,
                      disabled: !dirty || !isValid,
                    },
                  },
                ]
              : []),
          ]}
        >
          <Form id={formId}>
            {!csvHeaders ? (
              <Stack flexDirection="row">
                <TextField
                  required
                  label={t("Delimiter", {
                    ns: "PimImportations",
                  })}
                  name="csvSettings.delimiter"
                />
                <TextField
                  required
                  label={t("Decimal", {
                    ns: "PimImportations",
                  })}
                  name="csvSettings.decimal"
                />
                <LoadingButton
                  disabled={
                    !!(
                      errors.csvSettings?.decimal ||
                      errors.csvSettings?.delimiter
                    )
                  }
                  onClick={async () => {
                    const result = await getCsvHeadersMutation({
                      variables: {
                        input: {
                          url: csvUrl,
                          filename: csvFilename,
                          separator: values.csvSettings.delimiter,
                          supplierId,
                        },
                      },
                    });

                    setCsvHeaders(result.data!.getCsvHeader.headerAndValues);
                  }}
                  loading={getCsvHeadersMutationIsLoading}
                >
                  {t("Next", { ns: "Global" })}
                </LoadingButton>
              </Stack>
            ) : (
              <Stack flexDirection="column">
                <SelectField
                  name="key"
                  required
                  options={csvHeaders
                    .map(h => h.header)
                    .map(h => ({ label: h, value: h }))}
                  label={t("Key", {
                    ns: "SupplierDiscountGroups",
                  })}
                />
                <SelectField
                  name="description"
                  options={[{ value: null as string | null, label: "" }].concat(
                    csvHeaders
                      .map(h => h.header)
                      .map(h => ({ label: h, value: h }))
                  )}
                  label={t("Description", {
                    ns: "SupplierDiscountGroups",
                  })}
                />
                <SelectField
                  name="value"
                  required
                  options={csvHeaders
                    .map(h => h.header)
                    .map(h => ({ label: h, value: h }))}
                  label={t("Value", {
                    ns: "SupplierDiscountGroups",
                  })}
                />
                <FormattedFloatField
                  required
                  name="_multiplier"
                  label={t("Value multiplier", {
                    ns: "SupplierDiscountGroups",
                  })}
                  multiplier={1}
                />
              </Stack>
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
