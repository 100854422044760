/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutationVariables = Types.Exact<{
  input: Types.PimDeleteSupplierDiscountGroupVariantInput;
}>;


export type SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation = { pimDeleteSupplierDiscountGroupVariant: { __typename: 'PimDeleteSupplierDiscountGroupVariantResult', ok: boolean } };


export const SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantDocument = gql`
    mutation SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariant($input: PimDeleteSupplierDiscountGroupVariantInput!) {
  pimDeleteSupplierDiscountGroupVariant(input: $input) {
    ok
  }
}
    `;
export type SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutationFn = Apollo.MutationFunction<SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation, SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutationVariables>;

/**
 * __useSupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation__
 *
 * To run a mutation, you first call `useSupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierDiscountGroupVariantDeleteMenuButtonDeleteSupplierDiscountGroupVariantMutation, { data, loading, error }] = useSupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation(baseOptions?: Apollo.MutationHookOptions<SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation, SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation, SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutationVariables>(SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantDocument, options);
      }
export type SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutationHookResult = ReturnType<typeof useSupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation>;
export type SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutationResult = Apollo.MutationResult<SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation>;
export type SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutationOptions = Apollo.BaseMutationOptions<SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation, SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutationVariables>;