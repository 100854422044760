import { PreferenceStore, useUserPreference } from "./useUserPreference.js";

export type VisibilityStore<Keys extends string> = PreferenceStore<
  Partial<Record<Keys, boolean>>
>;

export const useVisibilityStore = <Keys extends string>(
  name: string
): VisibilityStore<Keys> => {
  return useUserPreference<Partial<Record<Keys, boolean>>>(
    `Visibility-${name}`,
    {}
  );
};
