import { gql } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { CrmPersonCustomFieldsBox_CrmPersonFragment } from "./CrmPersonCustomFieldsBox.generated.js";
import { useTranslate } from "@tolgee/react";
import { CustomFieldsBox } from "../../custom-fields/CustomFieldsBox.js";
import { EditCustomFieldModal } from "../../custom-fields/EditCustomFieldModal.js";

interface Props {
  crmPerson: CrmPersonCustomFieldsBox_CrmPersonFragment;
  onChangeCustomFieldsRefetchQueries: string[];
}

export function CrmPersonCustomFieldsBox({
  crmPerson,
  onChangeCustomFieldsRefetchQueries,
}: Props) {
  const { t } = useTranslate("UserProfile");

  return (
    <CustomFieldsBox
      source={crmPerson}
      objectType={"User"}
      actionButton={
        <ModalOpenButton
          Modal={EditCustomFieldModal}
          modalProps={{
            title: t("Edit user custom fields"),
            objectId: crmPerson.id,
            objectType: "User", // user.__typename
            customFields: crmPerson.customFields,
            refetchQueries: onChangeCustomFieldsRefetchQueries,
          }}
        >
          <IconButton color="secondary" size="small">
            <EditIcon />
          </IconButton>
        </ModalOpenButton>
      }
    />
  );
}
