/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type ProjectSourceEntity_ProjectSourceFragment = { __typename: 'ProjectSource', id: string, source: Types.ProjectSourceType, importFileName?: string | null, manualPageName?: Types.ProjectSourcePageName | null, quoteProductsDistributionId?: string | null, requestId?: string | null, requestTitle?: string | null, flowTemplateId?: string | null, flowTemplateTitle?: string | null, flowTemplateVersionCounter?: number | null };

export const ProjectSourceEntity_ProjectSourceFragmentDoc = gql`
    fragment ProjectSourceEntity_ProjectSource on ProjectSource {
  id
  source
  importFileName
  manualPageName
  quoteProductsDistributionId
  requestId
  requestTitle
  flowTemplateId
  flowTemplateTitle
  flowTemplateVersionCounter
}
    `;