import { Box, IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Compress as CompressIcon } from "@mui/icons-material";
import { Expand as ExpandIcon } from "@mui/icons-material";

interface Props {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

export const ExpandCollapseIconButton = ({
  isExpanded,
  setIsExpanded,
}: Props) => {
  const { t } = useTranslate("Global");
  return (
    <Tooltip title={isExpanded ? t("Collapse") : t("Expand")}>
      <Box display="flex" component="span">
        <IconButton
          color="primary"
          size="small"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? (
            <CompressIcon sx={{ transform: `rotate(90deg)` }} />
          ) : (
            <ExpandIcon sx={{ transform: `rotate(90deg)` }} />
          )}
        </IconButton>
      </Box>
    </Tooltip>
  );
};
