import React, { FC } from "react";
import { Stack } from "../../commons/layout/Stack.js";
import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import {
  CalendarViewType,
  useCalendarViewType,
} from "./useCalendarViewType.js";
import { VerticalAlignTop as VerticalAlignTopIcon } from "@mui/icons-material";
import { VerticalAlignBottom as VerticalAlignBottomIcon } from "@mui/icons-material";
import { VerticalAlignCenter as VerticalAlignCenterIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";

interface Props {
  name: string;
  Calendar: React.ReactElement;
  List: React.ReactElement;
}

export const CalendarViewLayout: FC<Props> = ({ name, List, Calendar }) => {
  const { t } = useTranslate("CalendarView");

  const { calendarView, setCalendarView } = useCalendarViewType(name);

  const FilterButtons = (
    <Box position="absolute" right="0" top="2px">
      <ToggleButtonGroup
        exclusive
        size="extra-small"
        value={calendarView}
        onChange={(e, value) => setCalendarView(value as CalendarViewType)}
      >
        <ToggleButton value={CalendarViewType.FullScreen}>
          <Tooltip title={t("Show calendar to full screen")}>
            <VerticalAlignTopIcon fontSize="small" />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={CalendarViewType.HalfScreen}>
          <Tooltip title={t("Show calendar to half screen")}>
            <VerticalAlignCenterIcon fontSize="small" />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={CalendarViewType.Hidden}>
          <Tooltip title={t("Hide calendar")}>
            <VerticalAlignBottomIcon fontSize="small" />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <Stack
      height="100%"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="stretch"
      flexGrow={1}
      flexShrink={1}
    >
      {calendarView === CalendarViewType.FullScreen ? (
        <>
          <Box height="100%" flexGrow={1} flexShrink={1} position="relative">
            {Calendar}
            {FilterButtons}
          </Box>
        </>
      ) : calendarView === CalendarViewType.HalfScreen ? (
        <>
          <Box height="calc(50% - 8px)" flexGrow={0} flexShrink={0}>
            {List}
          </Box>
          <Box height="50%" flexGrow={0} flexShrink={0} position="relative">
            {Calendar}
            {FilterButtons}
          </Box>
        </>
      ) : (
        <>
          <Box height="calc(100% - 38px)" flexGrow={1} flexShrink={1}>
            {List}
          </Box>
          <Box height="30px" flexGrow={0} flexShrink={0} position="relative">
            <Button
              size="small"
              variant="outlined"
              onClick={() => setCalendarView(CalendarViewType.HalfScreen)}
              endIcon={<VerticalAlignTopIcon />}
            >
              {t("Show calendar")}
            </Button>
            {FilterButtons}
          </Box>
        </>
      )}
    </Stack>
  );
};
