import { flatten, uniq } from "lodash-es";
import React from "react";
import { useUserData } from "../../auth/useUserData.js";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam.js";
import { Tabs } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { getAllParentItems } from "../../trees/helpers.js";
import { TreeHelperItem } from "../../trees/types.js";
import { assertNever } from "../../utils.js";

type QuickFilterValue = "ALL" | "MINE" | "OPEN" | "DONE" | "DECLINED";

interface FilterableItem extends TreeHelperItem {
  assignee?: { id: string } | null;
  isDone: boolean;
  isApproved?: boolean | null | undefined;
}

export function useTasksQuickFilters<T extends FilterableItem>(): [
  React.ComponentType,
  (items: T[]) => T[],
] {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("ActivitiesRouter");

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "quickfilter",
    "ALL"
  );

  const FilterTabs = React.useCallback(
    () => (
      <Tabs
        condensed
        onChange={setQuickFilter}
        value={quickFilter}
        options={[
          { value: "ALL", label: t("All") },
          { value: "MINE", label: t("Mine") },
          { value: "OPEN", label: t("Open") },
          { value: "DONE", label: t("Done") },
          { value: "DECLINED", label: t("Declined") },
        ]}
      />
    ),
    [quickFilter, setQuickFilter, t]
  );

  const applyQuickFilter = React.useCallback(
    (allItems: T[]) => {
      if (quickFilter !== "ALL") {
        const criteriaItems = allItems.filter(item => {
          switch (quickFilter) {
            case "MINE":
              return item.assignee?.id === viewer.id;
            case "OPEN":
              return item.isDone === false;
            case "DONE":
              return item.isDone === true;
            case "DECLINED":
              return item.isApproved === false;
            default:
              return assertNever(quickFilter);
          }
        });

        return uniq([
          ...criteriaItems,
          ...flatten(
            criteriaItems.map(item => getAllParentItems(item, allItems))
          ),
        ]);
      }

      return allItems;
    },
    [quickFilter, viewer.id]
  );

  return [FilterTabs, applyQuickFilter];
}
