import { useApolloClient } from "@apollo/client";
import { getDataOrNull, throwIfNil, throwIfNull } from "@msys/common";
import {
  MenuButton,
  MenuItemWithIcon,
  ModalOpenButton,
  Select,
  SubMenuItem,
} from "@msys/ui";
import { Grid, Icon, MenuItem, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import {
  namedOperations,
  PermissionName,
} from "../../../clients/graphqlTypes.js";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as SapLogo } from "../../assets/icons/sap-logo.svg";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { useOpenFile } from "../../commons/hooks/useOpenFile.js";
import {
  S4HanaCancelReasonSelectProcess,
  S4HanaCancelReasonSelectProcessRef,
} from "./S4HanaCancelReasonSelectProcess.js";
import { S4HanaDocumentFlowModal } from "./S4HanaDocumentFlowModal.js";
import { S4HanaQuoteCopyModal } from "./S4HanaQuoteCopyModal.js";
import {
  useS4HanaCancelQuoteMutation,
  useS4HanaCreateQuoteMutation,
  useS4HanaQuoteDataQuery,
  useS4HanaSimulateQuotationMutation,
  useS4HanaUpdateQuoteMutation,
  useSapS4HanaExportQuoteAsMutation,
  useSapS4HanaUpdateQuoteFromRemoteMutation,
} from "./S4HanaQuoteMenuButton.generated.js";

const REFETCH_QUERIES = [
  namedOperations.Query.S4HanaQuoteData,
  namedOperations.Query.S4HanaQuoteItemDataBox,
  namedOperations.Query.S4HanaQuoteDataBox,
  namedOperations.Query.QuoteItemSapS4HanaData_QuotePreview,
];

interface Props {
  projectId: string;
  quoteId: string;
  expandedItemIds: string[] | undefined;
  docViewerPermissions: PermissionName[];
}

export const S4HanaQuoteMenuButton = ({
  projectId,
  quoteId,
  expandedItemIds,
  docViewerPermissions,
}: Props) => {
  const { t } = useTranslate(["SapS4Hana", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const cancelReasonSelectProcessRef =
    React.useRef<S4HanaCancelReasonSelectProcessRef>(null);

  const client = useApolloClient();
  const query = useS4HanaQuoteDataQuery({
    client,
    variables: { projectId, quoteId },
  });
  const [simulateQuotation, { loading: simulateQuotationIsLoading }] =
    useS4HanaSimulateQuotationMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
      awaitRefetchQueries: true,
    });
  const [createQuote, { loading: createQuoteIsLoading }] =
    useS4HanaCreateQuoteMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
      awaitRefetchQueries: true,
    });
  const [updateQuote, { loading: updateQuoteIsLoading }] =
    useS4HanaUpdateQuoteMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
      awaitRefetchQueries: true,
    });
  const [cancelQuote, { loading: cancelQuoteIsLoading }] =
    useS4HanaCancelQuoteMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
      awaitRefetchQueries: true,
    });

  const [updateQuoteFromRemote, { loading: updateQuoteFromRemoteLoading }] =
    useSapS4HanaUpdateQuoteFromRemoteMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
    });

  const [sapS4HanaExportQuoteAs, { loading: sapS4HanaExportQuoteAsLoading }] =
    useSapS4HanaExportQuoteAsMutation({ client });

  const { openPdf } = useOpenFile();

  const availableCancelReasons =
    query.data?.quoteSapS4HanaData
      ?.xAvailableSalesQuotationItemSalesDocumentRejectionReasons;
  const defaultCancelReason =
    query.data?.quoteSapS4HanaData
      ?.xSalesQuotationItemSalesDocumentRejectionReasonDefault;
  const crmRedirectUrl =
    query.data?.quoteSapS4HanaData?.salesQuotationCrmRedirectUrl;
  const availableDocumentExportTypes =
    query.data?.quoteSapS4HanaData?.availableDocumentExportTypes ?? [];

  const menuItems = [
    <MenuItem
      key="quotation-simutation"
      onClick={async () => {
        await simulateQuotation();
      }}
      disabled={simulateQuotationIsLoading}
    >
      {t("SAP quotation simulation", { ns: "SapS4Hana" })}
    </MenuItem>,
    ...(!query.loading && query.data?.quoteSapS4HanaData?.xId
      ? [
          <ModalOpenButton
            Modal={S4HanaDocumentFlowModal}
            modalProps={{
              projectId,
              docId: quoteId,
              salesQuotationId: query.data.quoteSapS4HanaData.xId,
            }}
            key="document-flow-sap-quote"
          >
            <MenuItem>{t("Show document flow", { ns: "SapS4Hana" })}</MenuItem>
          </ModalOpenButton>,
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES"
            document={{ viewerPermissions: docViewerPermissions }}
            key="copy-sap-quote"
          >
            <ModalOpenButton
              Modal={S4HanaQuoteCopyModal}
              modalProps={{ projectId, docId: quoteId }}
            >
              <MenuItem>
                {t("Copy with reference", { ns: "SapS4Hana" })}
              </MenuItem>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>,
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES"
            document={{ viewerPermissions: docViewerPermissions }}
            key="update-sap-quote"
          >
            <MenuItem
              onClick={async () => {
                const result = await updateQuote();

                if (
                  result.data?.updateSapS4HanaSalesQuoteFromQuote?.errorMessage
                ) {
                  enqueueSnackbar(
                    result.data.updateSapS4HanaSalesQuoteFromQuote.errorMessage,
                    {
                      variant: "error",
                    }
                  );
                } else {
                  enqueueSnackbar(t("Quote updated", { ns: "SapS4Hana" }), {
                    variant: "success",
                  });
                }
              }}
              disabled={updateQuoteIsLoading}
            >
              {t("Update SAP quote", { ns: "SapS4Hana" })}
            </MenuItem>
          </RestrictedByDocumentPermissionWithDebug>,
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES"
            document={{ viewerPermissions: docViewerPermissions }}
            key="cancel-sap-quote"
          >
            <MenuItem
              onClick={async () => {
                const cancelReasonCode =
                  await cancelReasonSelectProcessRef.current?.startProcess({
                    availableReasons: availableCancelReasons ?? [],
                    defaultReason: defaultCancelReason || null,
                    isReasonRequired: true,
                  });
                if (!cancelReasonCode) return;
                await cancelQuote({
                  variables: {
                    input: {
                      projectId,
                      docId: quoteId,
                      reason: cancelReasonCode,
                      expandedItemIds,
                    },
                  },
                  refetchQueries: REFETCH_QUERIES,
                  awaitRefetchQueries: true,
                });
                enqueueSnackbar(t("Quote cancelled", { ns: "SapS4Hana" }));
              }}
              disabled={cancelQuoteIsLoading}
            >
              {t("Cancel SAP quote", { ns: "SapS4Hana" })}
              <S4HanaCancelReasonSelectProcess
                ref={cancelReasonSelectProcessRef}
              />
            </MenuItem>
          </RestrictedByDocumentPermissionWithDebug>,
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES"
            document={{ viewerPermissions: docViewerPermissions }}
            key="update-quote-from-remote"
          >
            <MenuItem
              onClick={async () => {
                await updateQuoteFromRemote();
                enqueueSnackbar(t("Quote updated", { ns: "SapS4Hana" }), {
                  variant: "success",
                });
              }}
              disabled={updateQuoteFromRemoteLoading}
            >
              {t("Update quote from remote", { ns: "SapS4Hana" })}
            </MenuItem>
          </RestrictedByDocumentPermissionWithDebug>,
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES"
            document={{ viewerPermissions: docViewerPermissions }}
            key="create-sap-pdf"
          >
            <SubMenuItem
              label={t("Export as", { ns: "SapS4Hana" })}
              disabled={availableDocumentExportTypes.length === 0}
            >
              {availableDocumentExportTypes.map(exportType => (
                <MenuItem
                  onClick={async () => {
                    try {
                      const result = await sapS4HanaExportQuoteAs({
                        variables: {
                          input: {
                            docId: quoteId,
                            projectId,
                            exportType: exportType.value,
                          },
                        },
                      });

                      const file = throwIfNil(
                        result.data?.sapS4HanaGenerateQuoteExport?.file,
                        "Failed to export document"
                      );
                      openPdf(file.url, `Quote_${quoteId}`);
                    } catch (error) {
                      if (error instanceof Error) {
                        enqueueSnackbar(error.message, { variant: "error" });
                      }
                    }
                  }}
                  disabled={updateQuoteFromRemoteLoading}
                >
                  {`${exportType.value} - ${exportType.label}`}
                </MenuItem>
              ))}
            </SubMenuItem>
          </RestrictedByDocumentPermissionWithDebug>,
          ...(crmRedirectUrl
            ? [
                <RestrictedByDocumentPermissionWithDebug
                  permission="MANAGE_QUOTES"
                  document={{ viewerPermissions: docViewerPermissions }}
                  key="open-in-crm-sap"
                >
                  <MenuItem href={crmRedirectUrl} target="_blank" component="a">
                    {t("Open in CRM", { ns: "SapS4Hana" })}
                  </MenuItem>
                </RestrictedByDocumentPermissionWithDebug>,
              ]
            : []),
        ]
      : []),
    ...(!query.loading && !query.data?.quoteSapS4HanaData?.xId
      ? [
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES"
            document={{ viewerPermissions: docViewerPermissions }}
            key="create-sap-quote"
          >
            <MenuItem
              onClick={async () => {
                const result = await createQuote();

                if (
                  result.data?.createSapS4HanaSalesQuoteFromQuote?.errorMessage
                ) {
                  enqueueSnackbar(
                    result.data.createSapS4HanaSalesQuoteFromQuote.errorMessage,
                    {
                      variant: "error",
                    }
                  );
                } else {
                  enqueueSnackbar(t("Quote created", { ns: "SapS4Hana" }), {
                    variant: "success",
                  });
                }
              }}
              disabled={createQuoteIsLoading}
            >
              {t("Create SAP quote", { ns: "SapS4Hana" })}
            </MenuItem>
          </RestrictedByDocumentPermissionWithDebug>,
        ]
      : []),
  ];

  return (
    <MenuButton
      Icon={
        <Tooltip title={t("SAP actions", { ns: "SapS4Hana" })}>
          <Icon>
            <SapLogo />
          </Icon>
        </Tooltip>
      }
    >
      {menuItems}
    </MenuButton>
  );
};
