import { useApolloClient } from "@apollo/client";
import { assertNever, notNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Home as HomeIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Icon, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { namedOperations } from "../../clients/graphqlTypes.js";
import { useLanguages } from "../../common/translations/useLanguages.js";
import { ReactComponent as CraftsmanIcon } from "../assets/icons/icon-craftsman.svg";
import { LanguageSelector } from "../commons/LanguageSelector.js";
import { useUrlSearchParams } from "../commons/hooks/useUrlSearchParams.js";
import { Page } from "../commons/layout/Page.js";
import {
  ORGANISATION_TYPE_URL_SEARCH_PARAM,
  REF_TOKEN_URL_SEARCH_PARAM,
} from "../constants.js";
import { OrganisationAvatar } from "../features/organisations/OrganisationAvatar.js";
import { RefTokenQuery } from "../invitation/InvitationTokenProvider.generated.js";
import { useCreateNewOrganisationAndUserMutation } from "./CreateNewOrganisationPage.generated.js";
import { LogoutButton } from "./LogoutButton.js";
import { useAuth } from "./useAuth.js";
import { isValidOrganisationType } from "./isValidOrganisationType.js";

interface Props {
  referral: {
    token: string;
    data: Exclude<RefTokenQuery["referralInfo"], undefined | null>;
  } | null;
  onComplete: (userId: string) => void;
}

export const CreateNewOrganisationPage = ({ referral, onComplete }: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["PageSignup"]);
  const { languages, selectLanguage, currentLanguage } = useLanguages();
  const { auth } = useAuth();
  const idToken = auth.idTokenParsed;
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();

  const organisationType =
    urlSearchParams.get(ORGANISATION_TYPE_URL_SEARCH_PARAM) ?? "CRAFTSMAN";
  if (!isValidOrganisationType(organisationType)) {
    throw new Error("Invalid organisation type");
  }

  const client = useApolloClient();
  const [createNewOrganisationAndUser, { loading }] =
    useCreateNewOrganisationAndUserMutation({
      client,
      awaitRefetchQueries: true,
      refetchQueries: [namedOperations.Query.AvailableUsers],
    });

  const handleSignupError = (e: unknown) => {
    if (e instanceof Error) {
      if (isAlreadyExistingEmailError(e.message)) {
        enqueueSnackbar(
          t("Email address is already registered", {
            ns: "PageSignup",
          }),
          {
            variant: "error",
          }
        );
        return;
      }

      enqueueSnackbar(e.message, { variant: "error" });
      return;
    }
    throw new Error(`expecting an Error instance`);
  };

  if (!idToken) {
    throw new Error("idToken missing");
  }

  return (
    <Page
      hideNavigation
      isTopBarVisible
      topbarItems={
        <>
          <LogoutButton color={"inherit"} />
          <LanguageSelector
            languages={languages}
            selectLanguage={selectLanguage}
            currentLanguage={currentLanguage}
          />
        </>
      }
    >
      {organisationType === "CLIENT" ? (
        <SignupLayout image={<img alt="Client" src="/auth/auth-client.svg" />}>
          {referral && <ReferralInfo referralInfo={referral.data} />}
          <ClientForm
            initialValues={{
              company: `${idToken.given_name} ${idToken.family_name}`,
            }}
            onSubmit={async values => {
              try {
                const result = await createNewOrganisationAndUser({
                  variables: {
                    input: {
                      refToken: referral?.token,
                      organisation: {
                        title:
                          values.company ||
                          `${idToken.given_name} ${idToken.family_name}`,
                        phones: [],
                        skillset: [],
                        organisationType,
                      },
                      user: {
                        phones: [],
                        locale: currentLanguage.locale,
                      },
                    },
                  },
                  context: {
                    noErrorNotification: isAlreadyExistingEmailError,
                  },
                });

                const user = result.data?.createOrganisationAndUser.user;

                if (!user) throw new Error(`user creation failed`);

                setUrlSearchParams(
                  {},
                  [
                    ORGANISATION_TYPE_URL_SEARCH_PARAM,
                    referral ? REF_TOKEN_URL_SEARCH_PARAM : null,
                  ].filter(notNull)
                );
                onComplete(user.id);
              } catch (e) {
                handleSignupError(e);
              }
            }}
            switchToCraftsman={() => {
              const searchParams = new URLSearchParams(location.search);
              searchParams.set(ORGANISATION_TYPE_URL_SEARCH_PARAM, "CRAFTSMAN");
              navigate({
                search: searchParams.toString(),
              });
            }}
          />
        </SignupLayout>
      ) : organisationType === "CRAFTSMAN" ? (
        <SignupLayout
          image={<img alt="Craftsman" src="/auth/auth-craftsman.svg" />}
        >
          {referral && <ReferralInfo referralInfo={referral.data} />}
          <CraftsmanForm
            initialValues={{
              company: "",
            }}
            onSubmit={async values => {
              try {
                const result = await createNewOrganisationAndUser({
                  variables: {
                    input: {
                      refToken: referral?.token,
                      organisation: {
                        title: values.company,
                        phones: [],
                        skillset: [],
                        organisationType,
                      },
                      user: {
                        phones: [],
                        locale: currentLanguage.locale,
                      },
                    },
                  },
                  context: {
                    noErrorNotification: isAlreadyExistingEmailError,
                  },
                });

                const user = result.data?.createOrganisationAndUser.user;

                if (!user) throw new Error(`user creation failed`);

                setUrlSearchParams({}, [
                  ORGANISATION_TYPE_URL_SEARCH_PARAM,
                  REF_TOKEN_URL_SEARCH_PARAM,
                ]);
                onComplete(user.id);
              } catch (e) {
                handleSignupError(e);
              }
            }}
            switchToClient={() => {
              const searchParams = new URLSearchParams(location.search);
              searchParams.set(ORGANISATION_TYPE_URL_SEARCH_PARAM, "CLIENT");
              navigate({ search: searchParams.toString() });
            }}
          />
        </SignupLayout>
      ) : (
        assertNever(organisationType)
      )}
    </Page>
  );
};

const isAlreadyExistingEmailError = (message: string) =>
  message === "email already exists" ||
  message.includes(
    'duplicate key value violates unique constraint "uniq_system_users_email"'
  );

const SignupLayout = ({
  children,
  image,
}: React.PropsWithChildren<{
  image: React.ReactElement;
}>) => {
  const { t } = useTranslate("PageSignup");

  const { isMinTablet, isMinDesktop, isMinLargeDesktop } = useScreenWidth();

  return (
    <Stack
      flexGrow={1}
      flexShrink={1}
      direction={isMinTablet ? "row" : "column"}
      alignItems="stretch"
      justifyContent="flex-start"
      spacing={1}
    >
      <Stack
        flexGrow={1}
        flexShrink={1}
        my={4}
        px={2}
        display="flex"
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Box width="100%" maxWidth={420}>
          {children}
        </Box>
      </Stack>
      <Stack
        spacing={1}
        direction="column"
        justifyContent="center"
        width={
          isMinLargeDesktop
            ? 680
            : isMinDesktop
              ? 540
              : isMinTablet
                ? 420
                : "100%"
        }
        flexGrow={0}
        flexShrink={0}
        sx={{ backgroundColor: "#033099" }}
        py={3}
      >
        <Box
          pb={3}
          px={2}
          maxWidth={isMinLargeDesktop ? 520 : 420}
          alignSelf="center"
        >
          <Typography
            sx={theme => ({
              fontSize: "1.375rem",
              lineHeight: 1.2,
              [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
                fontSize: "1.5rem",
                lineHeight: 1.2,
              },
              [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
                fontSize: "1.75rem",
                lineHeight: 1.2,
              },
              color: theme => theme.palette.common.white,
            })}
            variant="h2"
            align="center"
          >
            {t(
              "Strap on your tool belts, welcome to the future of construction"
            )}
          </Typography>
        </Box>
        {image}
      </Stack>
    </Stack>
  );
};

interface FormValues {
  company: string;
}
type OnSubmit = (
  values: FormValues,
  formikHelpers: FormikHelpers<FormValues>
) => Promise<void>;

const CraftsmanForm = ({
  onSubmit,
  initialValues,
  switchToClient,
}: {
  onSubmit: OnSubmit;
  initialValues: FormValues;
  switchToClient: () => void;
}) => {
  const { t } = useTranslate(["PageSignup", "Global"]);

  const userDetailsValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        company: Yup.string()
          .label(
            t("Company", {
              ns: "PageSignup",
            })
          )
          .required(),
      }),
    [t]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={userDetailsValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ isSubmitting }: FormikProps<FormValues>) => (
        <Form style={{ width: "100%" }}>
          <Stack direction="column" spacing={1} py={4}>
            <Box alignSelf="center" pb={3}>
              <Typography
                variant="h1"
                align="center"
                fontWeight={500}
                sx={{
                  "& > b, & > strong": {
                    color: theme => theme.palette.orange.main,
                  },
                }}
              >
                {
                  // @ts-ignore
                  t("Create a {organisationType} organisation", {
                    ns: "Global",
                    organisationType: (
                      <strong>{t("Craftsman", { ns: "Global" })}</strong>
                    ),
                  })
                }
              </Typography>
            </Box>
            <Field
              component={TextField}
              label={t("Company", {
                ns: "PageSignup",
              })}
              name="company"
              required
              autoFocus
            />
            <Box pt={3}>
              <LoadingButton
                fullWidth
                type="submit"
                color="orange"
                variant="contained"
                size="extra-large"
                loading={isSubmitting}
                disableElevation
              >
                {t("Confirm", {
                  ns: "Global",
                })}
              </LoadingButton>
            </Box>
            <Box position="relative" pt={3}>
              <Divider>
                <Typography
                  variant="body2"
                  align="center"
                  sx={t => ({
                    textTransform: "uppercase",
                    color: t.palette.text.disabled,
                  })}
                >
                  {t("Or", {
                    ns: "Global",
                  })}
                </Typography>
              </Divider>
            </Box>
            <Stack direction="column" spacing={1} pt={2} alignItems="center">
              <Button
                onClick={switchToClient}
                color="primary"
                variant="text"
                size="small"
                startIcon={<HomeIcon />}
              >
                {t("Create a homeowner organisation instead", {
                  ns: "Global",
                })}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const ClientForm = ({
  onSubmit,
  initialValues,
  switchToCraftsman,
}: {
  onSubmit: OnSubmit;
  initialValues: FormValues;
  switchToCraftsman: () => void;
}) => {
  const { t } = useTranslate(["PageSignup", "Global"]);

  const userDetailsValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        company: Yup.string()
          .label(
            t("Company", {
              ns: "PageSignup",
            })
          )
          .required(),
      }),
    [t]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={userDetailsValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ isSubmitting, isValid }: FormikProps<FormValues>) => (
        <Form style={{ width: "100%" }} autoComplete="off">
          <Stack direction="column" spacing={1} py={4}>
            <Box alignSelf="center" pb={3}>
              <Typography
                variant="h1"
                align="center"
                fontWeight={500}
                sx={{
                  "& > b, & > strong": {
                    color: theme => theme.palette.orange.main,
                  },
                }}
              >
                {
                  // @ts-ignore
                  t("Create a {organisationType} organisation", {
                    ns: "Global",
                    organisationType: (
                      <strong>{t("Homeowner", { ns: "Global" })}</strong>
                    ),
                  })
                }
              </Typography>
            </Box>
            <Field
              component={TextField}
              label={t("Account name", {
                ns: "PageSignup",
              })}
              name="company"
              required
              autoFocus
            />
            <Box pt={3}>
              <LoadingButton
                fullWidth
                type="submit"
                color="orange"
                variant="contained"
                size="extra-large"
                loading={isSubmitting}
                disableElevation
              >
                {t("Confirm", {
                  ns: "Global",
                })}
              </LoadingButton>
            </Box>

            <Box position="relative" pt={3}>
              <Divider>
                <Typography
                  variant="body2"
                  align="center"
                  sx={t => ({
                    textTransform: "uppercase",
                    color: t.palette.text.disabled,
                  })}
                >
                  {t("Or", {
                    ns: "Global",
                  })}
                </Typography>
              </Divider>
            </Box>
            <Stack direction="column" spacing={1} pt={2} alignItems="center">
              <Button
                onClick={switchToCraftsman}
                color="primary"
                variant="text"
                size="small"
                startIcon={
                  <Icon>
                    <CraftsmanIcon />
                  </Icon>
                }
              >
                {t("Create a craftsman organisation instead", {
                  ns: "Global",
                })}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const ReferralInfo = ({
  referralInfo,
}: {
  referralInfo: Exclude<RefTokenQuery["referralInfo"], null | undefined>;
}) => {
  return (
    <Stack alignItems="center" mb={6}>
      <OrganisationAvatar organisationAvatar={referralInfo} size="l" />
      <Typography variant="h1">{referralInfo.title}</Typography>
    </Stack>
  );
};
