import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import {
  EntitySearchBoolFilterOperator,
  EntitySearchBoolInFilterOperator,
  EntitySearchNumberBetweenFilterOperator,
  EntitySearchNumberFilterOperator,
  EntitySearchNumberInFilterOperator,
  EntitySearchTextFilterOperator,
  EntitySearchTextInFilterOperator,
  ProductSearchFilterExpressionFilterKey,
  ProductSearchFilterExpressionFilterKind,
} from "../../../../clients/graphqlTypes.js";
import {
  BoolFilterOperatorOptions,
  NumberFilterOperatorOptions,
  PriceFilterOperatorOptions,
  TextFilterOperatorOptions,
} from "../../products/ProductSearchFilterOperatorOptions.js";
import { useProductSearchFilterExpressionMutations } from "../useProductSearchFilterExpressionMutations.js";
import { useItemProductSearchFilterExpressionModalQuery } from "./ItemProductSearchFilterExpressionModal.generated.js";
import { ProductSearchFilterExpressionModal } from "./ProductSearchFilterExpressionModal.js";

interface Props
  extends Omit<
    React.ComponentProps<typeof ProductSearchFilterExpressionModal>,
    "handleComplete" | "allowedSearchFilterOperators"
  > {
  filterKind: ProductSearchFilterExpressionFilterKind;
  filterKey: ProductSearchFilterExpressionFilterKey;
  propertyKey?: string;
  refetchQueries?: string[];
}

export function ItemProductSearchFilterExpressionModal({
  projectId,
  docId,
  itemId,
  filterKind,
  filterKey,
  propertyKey,
  expression,
  operator,
  handleClose,
  refetchQueries,
}: Props) {
  const { t } = useTranslate(["Product"]);

  const client = useApolloClient();
  const query = useItemProductSearchFilterExpressionModalQuery({
    client,
    variables: { docId, itemId },
  });
  const productSearchFilterExpressions = getDataOrNull(
    query.data?.item
  )?.productSearchFilterExpressions;
  const listPriceSearchFilterExpression =
    productSearchFilterExpressions?.listPriceFilter;
  const brandSearchFilterExpression =
    productSearchFilterExpressions?.brandFilter;
  const brandLineSearchFilterExpression =
    productSearchFilterExpressions?.brandLineFilter;
  const propertySearchFiltersExpressions =
    productSearchFilterExpressions?.propertyFilters ?? [];

  const {
    handleSetProductSearchFilterExpression,
    handleSetProductSearchFilterPropertyExpression,
  } = useProductSearchFilterExpressionMutations({
    projectId,
    docId,
    itemId,
    listPriceSearchFilterExpression,
    brandSearchFilterExpression,
    brandLineSearchFilterExpression,
    propertySearchFiltersExpressions,
    refetchQueries,
  });

  if (filterKey === "listPrice") {
    const handleComplete = async (value: {
      expr: string;
      operator:
        | EntitySearchNumberFilterOperator
        | EntitySearchNumberBetweenFilterOperator;
    }) => {
      await handleSetProductSearchFilterExpression(
        "itemProductSearchFilterListPriceFilterExpression",
        {
          numberBetweenFilter:
            value.operator === "between"
              ? { expr: value.expr, operatorNumberBetween: value.operator }
              : undefined,
          numberFilter:
            value.operator !== "between"
              ? { expr: value.expr, operatorNumber: value.operator }
              : undefined,
        }
      );
    };

    return (
      <ProductSearchFilterExpressionModal
        projectId={projectId}
        docId={docId}
        itemId={itemId}
        filterKey={t("List price", {
          ns: "Product",
        })}
        expression={expression}
        operator={
          operator === "between" ||
          operator === "eq" ||
          operator === "gt" ||
          operator === "gte" ||
          operator === "lt" ||
          operator === "lte"
            ? operator
            : "eq"
        }
        allowedSearchFilterOperators={PriceFilterOperatorOptions}
        handleClose={handleClose}
        handleComplete={handleComplete}
      />
    );
  }

  if (filterKey === "brand") {
    const handleComplete = async (value: {
      expr: string;
      operator:
        | EntitySearchTextFilterOperator
        | EntitySearchTextInFilterOperator;
    }) => {
      await handleSetProductSearchFilterExpression(
        "itemProductSearchFilterBrandFilterExpression",
        {
          textFilter:
            value.operator === "eq"
              ? {
                  expr: value.expr,
                  operatorText: value.operator,
                }
              : undefined,
          textInFilter:
            value.operator === "in"
              ? {
                  expr: value.expr,
                  operatorTextIn: value.operator,
                }
              : undefined,
        }
      );
    };

    return (
      <ProductSearchFilterExpressionModal
        projectId={projectId}
        docId={docId}
        itemId={itemId}
        filterKey={t("Brand", {
          ns: "Product",
        })}
        operator={operator === "in" ? operator : "eq"}
        expression={expression}
        allowedSearchFilterOperators={TextFilterOperatorOptions}
        handleClose={handleClose}
        handleComplete={handleComplete}
      />
    );
  }

  if (filterKey === "brandLine") {
    const handleComplete = async (value: {
      expr: string;
      operator:
        | EntitySearchTextFilterOperator
        | EntitySearchTextInFilterOperator;
    }) => {
      await handleSetProductSearchFilterExpression(
        "itemProductSearchFilterBrandLineFilterExpression",
        {
          textFilter:
            value.operator === "eq"
              ? {
                  expr: value.expr,
                  operatorText: value.operator,
                }
              : undefined,
          textInFilter:
            value.operator === "in"
              ? {
                  expr: value.expr,
                  operatorTextIn: value.operator,
                }
              : undefined,
        }
      );
    };

    return (
      <ProductSearchFilterExpressionModal
        projectId={projectId}
        docId={docId}
        itemId={itemId}
        filterKey={t("Brand line", {
          ns: "Product",
        })}
        operator={operator === "in" ? operator : "eq"}
        expression={expression}
        allowedSearchFilterOperators={TextFilterOperatorOptions}
        handleClose={handleClose}
        handleComplete={handleComplete}
      />
    );
  }

  if (filterKey === "properties" && propertyKey) {
    if (filterKind === "number") {
      const handleComplete = async (value: {
        expr: string;
        operator:
          | EntitySearchNumberFilterOperator
          | EntitySearchNumberInFilterOperator
          | EntitySearchNumberBetweenFilterOperator;
      }) => {
        await handleSetProductSearchFilterPropertyExpression(propertyKey, {
          numberBetweenFilter:
            value.operator === "between"
              ? {
                  expr: value.expr,
                  operatorNumberBetween: value.operator,
                  key: propertyKey,
                }
              : undefined,
          numberInFilter:
            value.operator === "in"
              ? {
                  expr: value.expr,
                  operatorNumberIn: value.operator,
                  key: propertyKey,
                }
              : undefined,
          numberFilter:
            value.operator !== "between" && value.operator !== "in"
              ? {
                  expr: value.expr,
                  operatorNumber: value.operator,
                  key: propertyKey,
                }
              : undefined,
        });
      };

      return (
        <ProductSearchFilterExpressionModal
          projectId={projectId}
          docId={docId}
          itemId={itemId}
          filterKey={propertyKey}
          operator={
            operator === "between" ||
            operator === "eq" ||
            operator === "gt" ||
            operator === "gte" ||
            operator === "lt" ||
            operator === "lte"
              ? operator
              : "eq"
          }
          expression={expression}
          allowedSearchFilterOperators={NumberFilterOperatorOptions}
          handleClose={handleClose}
          handleComplete={handleComplete}
        />
      );
    }

    if (filterKind === "bool") {
      const handleComplete = async (value: {
        expr: string;
        operator:
          | EntitySearchBoolFilterOperator
          | EntitySearchBoolInFilterOperator;
      }) => {
        await handleSetProductSearchFilterPropertyExpression(propertyKey, {
          boolFilter:
            value.operator === "eq"
              ? {
                  expr: value.expr,
                  operatorBool: value.operator,
                  key: propertyKey,
                }
              : undefined,
          boolInFilter:
            value.operator === "in"
              ? {
                  expr: value.expr,
                  operatorBoolIn: value.operator,
                  key: propertyKey,
                }
              : undefined,
        });
      };

      return (
        <ProductSearchFilterExpressionModal
          projectId={projectId}
          docId={docId}
          itemId={itemId}
          filterKey={propertyKey}
          operator={"eq"}
          expression={expression}
          allowedSearchFilterOperators={BoolFilterOperatorOptions}
          handleClose={handleClose}
          handleComplete={handleComplete}
        />
      );
    }

    if (filterKind === "text") {
      const handleComplete = async (value: {
        expr: string;
        operator:
          | EntitySearchTextFilterOperator
          | EntitySearchTextInFilterOperator;
      }) => {
        await handleSetProductSearchFilterPropertyExpression(propertyKey, {
          textFilter:
            value.operator === "eq"
              ? {
                  expr: value.expr,
                  operatorText: value.operator,
                  key: propertyKey,
                }
              : undefined,
          textInFilter:
            value.operator === "in"
              ? {
                  expr: value.expr,
                  operatorTextIn: value.operator,
                  key: propertyKey,
                }
              : undefined,
        });
      };

      return (
        <ProductSearchFilterExpressionModal
          projectId={projectId}
          docId={docId}
          itemId={itemId}
          filterKey={propertyKey}
          operator={"eq"}
          expression={expression}
          allowedSearchFilterOperators={BoolFilterOperatorOptions}
          handleClose={handleClose}
          handleComplete={handleComplete}
        />
      );
    }
  }

  return null;
}
