import { gql, useApolloClient } from "@apollo/client";
import {
  CardContainer,
  DurationType,
  getDurationInSeconds,
  getNormalizedDuration,
} from "@msys/ui";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { ContractTypeRadioGroupField } from "../../../commons/form-fields/ContractTypeRadioGroupField.js";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField.js";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { SwitchField } from "../../../commons/form-fields/SwitchField.js";
import { ContractType } from "../../../../clients/graphqlTypes.js";
import {
  DEFAULT_QUOTE_VALIDITY_DURATION,
  DURATION_MAX_VALUES,
} from "../../../utils.js";
import { useQuoteMaterialPriceHandlingOptions } from "../useQuoteMaterialPriceHandlingOptions.js";
import {
  OrganisationQuoteSettingsForm_DefaultsFragment,
  useOrganisationQuoteSettingsForm_ModifyOrganisationDefaultsMutation,
} from "./OrganisationQuoteSettingsBox.generated.js";

interface Props {
  organisationId: string;
  organisationDefaults: OrganisationQuoteSettingsForm_DefaultsFragment;
}

export function OrganisationQuoteSettingsBox(props: Props) {
  const { t } = useTranslate("OrganisationSettings");
  return (
    <CardContainer
      isExpandable
      title={t("Quote settings")}
      ActionButton={
        <Tooltip
          title={t(
            "Defaults can be adjusted individually on each created document"
          )}
        >
          <IconButton color="secondary" aria-label="Show helper" size="small">
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <Box p={1}>
        <OrganisationQuoteSettingsForm {...props} />
      </Box>
    </CardContainer>
  );
}

interface FormValues {
  defaultContractType: ContractType;
  defaultQuoteIsBinding: boolean;
  defaultQuoteShowOfferConditions: boolean;
  defaultQuoteValidityDurationType: DurationType;
  defaultQuoteValidityDurationAmount: number;
}

function OrganisationQuoteSettingsForm({
  organisationId,
  organisationDefaults,
}: {
  organisationId: string;
  organisationDefaults: OrganisationQuoteSettingsForm_DefaultsFragment;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["OrganisationSettings", "QuoteEditFooter"]);

  const quoteMaterialPriceHandlingOptions =
    useQuoteMaterialPriceHandlingOptions();

  const client = useApolloClient();
  const [modifyOrganisationDefaults] =
    useOrganisationQuoteSettingsForm_ModifyOrganisationDefaultsMutation({
      client,
    });

  const initialValues = React.useMemo((): FormValues | null => {
    if (!organisationDefaults) return null;
    const [durationAmount, durationType] = getNormalizedDuration(
      organisationDefaults.defaultQuoteValidityDuration ||
        DEFAULT_QUOTE_VALIDITY_DURATION
    );
    return {
      defaultContractType: organisationDefaults.defaultContractType,
      defaultQuoteIsBinding: organisationDefaults.defaultQuoteIsBinding,
      defaultQuoteShowOfferConditions:
        organisationDefaults.defaultQuoteShowOfferConditions,
      defaultQuoteValidityDurationType: durationType,
      defaultQuoteValidityDurationAmount: durationAmount,
    };
  }, [organisationDefaults]);

  const validationSchema = Yup.object().shape({
    defaultContractType: Yup.string()
      .label(
        t("Default contract type for new quotes", {
          ns: "OrganisationSettings",
        })
      )
      .required(),
    defaultQuoteIsBinding: Yup.bool().label(
      t("Quote is binding by default", {
        ns: "OrganisationSettings",
      })
    ),
    defaultQuoteShowOfferConditions: Yup.bool().label(
      t("Quote offer conditions are visible by default", {
        ns: "OrganisationSettings",
      })
    ),
    defaultQuoteValidityDurationAmount: Yup.number()
      .label(
        t("Default validity duration", {
          ns: "OrganisationSettings",
        })
      )
      .min(1)
      .required(),
    defaultQuoteValidityDurationType: Yup.string()
      .label(
        t("Units", {
          ns: "QuoteEditFooter",
        })
      )
      .oneOf(["days", "weeks", "months"]),
  });

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const handleSubmit = async (values: FormValues) => {
    try {
      const input = {
        defaultContractType: values.defaultContractType,
        defaultQuoteIsBinding: values.defaultQuoteIsBinding,
        defaultQuoteShowOfferConditions: values.defaultQuoteShowOfferConditions,
        defaultQuoteValidityDuration: getDurationInSeconds({
          durationAmount: values.defaultQuoteValidityDurationAmount,
          durationType: values.defaultQuoteValidityDurationType,
        }),
      };
      await modifyOrganisationDefaults({ variables: { input } });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  return (
    <Formik<FormValues>
      initialValues={initialValues!}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack direction="column" spacing={1}>
            <Box>
              <Typography>
                {t("Validity for quotes", {
                  ns: "OrganisationSettings",
                })}
              </Typography>
            </Box>
            <SwitchField
              name="defaultQuoteIsBinding"
              label={t("Quote is binding by default", {
                ns: "OrganisationSettings",
              })}
              disabled={false}
            />
            <SwitchField
              name="defaultQuoteShowOfferConditions"
              label={t("Quote offer conditions are visible by default", {
                ns: "OrganisationSettings",
              })}
              disabled={false}
            />
            <Stack direction="row" spacing={1}>
              <FormattedFloatField
                name="defaultQuoteValidityDurationAmount"
                label={t("Default validity duration", {
                  ns: "OrganisationSettings",
                })}
                type="float"
                min={0}
                max={
                  DURATION_MAX_VALUES[
                    formikProps.values.defaultQuoteValidityDurationType
                  ]
                }
                disabled={false}
              />
              <SelectField
                name="defaultQuoteValidityDurationType"
                label={t("Units", {
                  ns: "QuoteEditFooter",
                })}
                disabled={false}
                options={[
                  {
                    label: t("Days", {
                      ns: "QuoteEditFooter",
                    }),
                    value: "days",
                  },
                  {
                    label: t("Weeks", {
                      ns: "QuoteEditFooter",
                    }),
                    value: "weeks",
                  },
                  {
                    label: t("Months", {
                      ns: "QuoteEditFooter",
                    }),
                    value: "months",
                  },
                ]}
              />
            </Stack>
            <Divider />
            <Typography>
              {t("Default contract type for new quotes", {
                ns: "OrganisationSettings",
              })}
            </Typography>
            <ContractTypeRadioGroupField
              name="defaultContractType"
              disabled={false}
            />
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
