import { gql, useApolloClient } from "@apollo/client";
import { MenuButton, MenuItemWithIcon } from "@msys/ui";
import { MoneyOff as MoneyOffIcon } from "@mui/icons-material";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  DocType,
  ItemChildrenVisibility,
} from "../../../../clients/graphqlTypes.js";
import {
  ChildVisibilityIconButton_ItemFragment,
  useSetItemChildrenVisibilityMutation,
} from "./ChildVisibilityIconButton.generated.js";

const visibilityIconMap: Record<ItemChildrenVisibility, React.ReactElement> = {
  SHOW_CHILDREN: <VisibilityIcon />,
  SHOW_CHILDREN_HIDE_PRICE: <MoneyOffIcon />,
  SHOW_ONLY_MAINPRODUCT_HIDE_PRICE: <MoneyOffIcon />,
  HIDE_CHILDREN: <VisibilityOffIcon />,
};

const ALL_CHILDREN_VISIBILITY_OPTIONS: ItemChildrenVisibility[] = [
  "SHOW_CHILDREN",
  "SHOW_CHILDREN_HIDE_PRICE",
  "SHOW_ONLY_MAINPRODUCT_HIDE_PRICE",
  "HIDE_CHILDREN",
];

interface Props {
  item: ChildVisibilityIconButton_ItemFragment;
  doc: { id: string; docType: DocType };
  projectId: string | null;
}

export const ChildVisibilityIconButton = ({ item, doc, projectId }: Props) => {
  const { t } = useTranslate("QuoteItem");

  const client = useApolloClient();
  const [setItemChildrenVisibility] = useSetItemChildrenVisibilityMutation({
    client,
  });

  const handleVisibilitySelect = async (
    childrenVisibility: ItemChildrenVisibility
  ) => {
    await setItemChildrenVisibility({
      variables: {
        input: {
          projectId,
          docId: doc.id,
          itemId: item.id,
          values: {
            childrenVisibility,
          },
        },
      },
    });
  };

  const itemChildVisibilityLabels: Record<ItemChildrenVisibility, string> = {
    SHOW_CHILDREN: t("SHOW_CHILDREN"),
    SHOW_CHILDREN_HIDE_PRICE: t("SHOW_CHILDREN_HIDE_PRICE"),
    SHOW_ONLY_MAINPRODUCT_HIDE_PRICE: t("SHOW_ONLY_MAINPRODUCT_HIDE_PRICE"),
    HIDE_CHILDREN: t("HIDE_CHILDREN"),
  };

  const options = ALL_CHILDREN_VISIBILITY_OPTIONS.filter(e => {
    if (doc.docType === "REQUIREMENT") {
      return ["SHOW_CHILDREN", "HIDE_CHILDREN"].includes(e);
    }

    // TODO needs not before launch, needs also further specs
    if (e === "SHOW_ONLY_MAINPRODUCT_HIDE_PRICE") return false;

    return true;
  });

  const ButtonIcon = visibilityIconMap[item.childrenVisibility];

  return (
    <MenuButton Icon={ButtonIcon}>
      {options.map(option => (
        <MenuItemWithIcon
          key={option}
          selected={item.childrenVisibility === option}
          onClick={() => handleVisibilitySelect(option)}
          icon={visibilityIconMap[option]}
        >
          {itemChildVisibilityLabels[option]}
        </MenuItemWithIcon>
      ))}
    </MenuButton>
  );
};
