/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteRefinementWizardModal_ApplyQuoteItemActionsMutationVariables = Types.Exact<{
  input: Types.ApplyQuoteItemActionsInput;
}>;


export type QuoteRefinementWizardModal_ApplyQuoteItemActionsMutation = { applyQuoteItemActions: { __typename: 'ApplyQuoteItemActionsResult', quote: { __typename: 'Quote', id: string } } };


export const QuoteRefinementWizardModal_ApplyQuoteItemActionsDocument = gql`
    mutation QuoteRefinementWizardModal_applyQuoteItemActions($input: ApplyQuoteItemActionsInput!) {
  applyQuoteItemActions(input: $input) {
    quote {
      id
    }
  }
}
    `;
export type QuoteRefinementWizardModal_ApplyQuoteItemActionsMutationFn = Apollo.MutationFunction<QuoteRefinementWizardModal_ApplyQuoteItemActionsMutation, QuoteRefinementWizardModal_ApplyQuoteItemActionsMutationVariables>;

/**
 * __useQuoteRefinementWizardModal_ApplyQuoteItemActionsMutation__
 *
 * To run a mutation, you first call `useQuoteRefinementWizardModal_ApplyQuoteItemActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteRefinementWizardModal_ApplyQuoteItemActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteRefinementWizardModalApplyQuoteItemActionsMutation, { data, loading, error }] = useQuoteRefinementWizardModal_ApplyQuoteItemActionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteRefinementWizardModal_ApplyQuoteItemActionsMutation(baseOptions?: Apollo.MutationHookOptions<QuoteRefinementWizardModal_ApplyQuoteItemActionsMutation, QuoteRefinementWizardModal_ApplyQuoteItemActionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteRefinementWizardModal_ApplyQuoteItemActionsMutation, QuoteRefinementWizardModal_ApplyQuoteItemActionsMutationVariables>(QuoteRefinementWizardModal_ApplyQuoteItemActionsDocument, options);
      }
export type QuoteRefinementWizardModal_ApplyQuoteItemActionsMutationHookResult = ReturnType<typeof useQuoteRefinementWizardModal_ApplyQuoteItemActionsMutation>;
export type QuoteRefinementWizardModal_ApplyQuoteItemActionsMutationResult = Apollo.MutationResult<QuoteRefinementWizardModal_ApplyQuoteItemActionsMutation>;
export type QuoteRefinementWizardModal_ApplyQuoteItemActionsMutationOptions = Apollo.BaseMutationOptions<QuoteRefinementWizardModal_ApplyQuoteItemActionsMutation, QuoteRefinementWizardModal_ApplyQuoteItemActionsMutationVariables>;