/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { InvoiceCalculationFragmentDoc } from './Fragments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModifyInvoicePaymentItemMutationVariables = Types.Exact<{
  input: Types.ModifyInvoicePaymentItemInput;
}>;


export type ModifyInvoicePaymentItemMutation = { modifyInvoicePaymentItem: { __typename: 'ModifyInvoicePaymentItemResult', invoice: { __typename: 'Invoice', id: string, calculation: { __typename: 'InvoiceCalculation', discountRate: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, vatRate: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workRate: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, extraItemsPriceSubTotal: number, extraItemsPriceNetTotal: number, extraItemsPriceVatTotal: number, extraItemsPriceTotal: number, paymentItemsPriceSubTotal: number, paymentItemsPriceNetTotal: number, paymentItemsPriceVatTotal: number, paymentItemsPriceTotal: number, openPriceNetTotal: number, openPriceVatTotal: number, openPriceTotal: number, finalPriceNetTotal: number, finalPriceVatTotal: number, finalPriceTotal: number } }, invoicePaymentItem: { __typename: 'InvoicePaymentItem', id: string, title: string, price: number, totalPricePercentage: number } } };


export const ModifyInvoicePaymentItemDocument = gql`
    mutation ModifyInvoicePaymentItem($input: ModifyInvoicePaymentItemInput!) {
  modifyInvoicePaymentItem(input: $input) {
    invoice {
      id
      calculation {
        ...InvoiceCalculation
      }
    }
    invoicePaymentItem {
      id
      title
      price
      totalPricePercentage
    }
  }
}
    ${InvoiceCalculationFragmentDoc}`;
export type ModifyInvoicePaymentItemMutationFn = Apollo.MutationFunction<ModifyInvoicePaymentItemMutation, ModifyInvoicePaymentItemMutationVariables>;

/**
 * __useModifyInvoicePaymentItemMutation__
 *
 * To run a mutation, you first call `useModifyInvoicePaymentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyInvoicePaymentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyInvoicePaymentItemMutation, { data, loading, error }] = useModifyInvoicePaymentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyInvoicePaymentItemMutation(baseOptions?: Apollo.MutationHookOptions<ModifyInvoicePaymentItemMutation, ModifyInvoicePaymentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyInvoicePaymentItemMutation, ModifyInvoicePaymentItemMutationVariables>(ModifyInvoicePaymentItemDocument, options);
      }
export type ModifyInvoicePaymentItemMutationHookResult = ReturnType<typeof useModifyInvoicePaymentItemMutation>;
export type ModifyInvoicePaymentItemMutationResult = Apollo.MutationResult<ModifyInvoicePaymentItemMutation>;
export type ModifyInvoicePaymentItemMutationOptions = Apollo.BaseMutationOptions<ModifyInvoicePaymentItemMutation, ModifyInvoicePaymentItemMutationVariables>;