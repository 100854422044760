import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Link, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React, { Dispatch, SetStateAction } from "react";
import { PimSearchProductsFilters } from "../../../../clients/graphqlTypes.js";
import {
  FilterChip,
  FilterChipGroup,
} from "../../../commons/filters/FilterChip.js";
import { FilterPriceRangeChip } from "../../../commons/filters/FilterPriceRangeChip.js";
import { PropertiesFilterChips } from "../../properties/PropertiesFilterChips.js";
import {
  useProductSearchModal_ManufacturersQuery,
  useProductSearchModal_ProductTypesQuery,
  useProductSearchModal_SuppliersQuery,
} from "./ProductFilterChips.generated.js";

interface Props<Filters extends PimSearchProductsFilters> {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
  onReset?(): void;
  canChangeProductType?: boolean;
  AdditionalChips?: React.ReactNode;
}

export function ProductFilterChips<Filters extends PimSearchProductsFilters>({
  filters,
  setFilters,
  onReset,
  canChangeProductType = true,
  AdditionalChips,
}: Props<Filters>) {
  const { t } = useTranslate(["Product", "ProductSearch", "Global"]);

  const client = useApolloClient();
  const productTypesQuery = useProductSearchModal_ProductTypesQuery({
    client,
    variables: {
      productTypeIds: filters.productTypeIds ?? [],
    },
    fetchPolicy: "cache-and-network",
    skip: !filters.productTypeIds || filters.productTypeIds.length === 0,
  });
  const supplierOrganisationsQuery = useProductSearchModal_SuppliersQuery({
    client,
    variables: {
      organisationIds: filters.supplierIds,
    },
    fetchPolicy: "cache-and-network",
    skip: !filters.supplierIds || filters.supplierIds.length === 0,
  });
  const manufacturerOrganisationsQuery =
    useProductSearchModal_ManufacturersQuery({
      client,
      variables: {
        organisationIds: filters.manufacturerIds,
      },
      fetchPolicy: "cache-and-network",
      skip: !filters.manufacturerIds || filters.manufacturerIds.length === 0,
    });

  const getProductTypeName = (id: string) =>
    getDataOrNull(
      (productTypesQuery.data ?? productTypesQuery.previousData)
        ?.pimProductTypes
    )?.productTypes.find(productType => productType.id === id)?.label;
  const getSupplierName = (id: string) => {
    const r = (
      supplierOrganisationsQuery.data ?? supplierOrganisationsQuery.previousData
    )?.searchSuppliers.edges.find(
      edge => edge.node.organisation.id === id
    )?.node;
    return r?.crmCompany ? r.crmCompany.title : r?.organisation.title;
  };
  const getManufacturerName = (id: string) =>
    (
      manufacturerOrganisationsQuery.data ??
      manufacturerOrganisationsQuery.previousData
    )?.searchManufacturers.edges.find(edge => edge.node.organisation.id === id)
      ?.node.organisation.title;

  return canShowChips(filters) ? (
    <Stack
      direction={"row"}
      spacing={1}
      flexWrap="wrap"
      alignItems="center"
      useFlexGap
    >
      {filters.productTypeIds && (
        <FilterChipGroup
          // label={t("Product type", { ns: "Product" })}
          values={filters.productTypeIds}
          setValues={newValues => {
            setFilters(filters => ({
              ...filters,
              productTypeIds:
                newValues && newValues.length > 0 ? newValues : null,
            }));
          }}
          getValueLabel={getProductTypeName}
          canEdit={canChangeProductType}
        />
      )}
      {filters.supplierIds && (
        <FilterChipGroup
          // label={t("Supplier", { ns: "Product" })}
          values={filters.supplierIds}
          setValues={newValues => {
            setFilters(filters => ({
              ...filters,
              supplierIds: newValues && newValues.length > 0 ? newValues : null,
              ...(!newValues || !newValues.length
                ? { supplierProductCategoryKey: null }
                : undefined),
            }));
          }}
          getValueLabel={getSupplierName}
        />
      )}
      {filters.manufacturerIds && (
        <FilterChipGroup
          label={t("Manufacturer", { ns: "Product" })}
          values={filters.manufacturerIds}
          setValues={newValues => {
            setFilters(filters => ({
              ...filters,
              manufacturerIds:
                newValues && newValues.length > 0 ? newValues : null,
            }));
          }}
          getValueLabel={getManufacturerName}
        />
      )}
      {filters.brands && filters.brands.length > 0 && (
        <FilterChipGroup
          values={filters.brands}
          setValues={newValues => {
            setFilters(filters => ({
              ...filters,
              brands: newValues && newValues.length > 0 ? newValues : null,
            }));
          }}
        />
      )}
      {filters.brandLines && filters.brandLines.length > 0 && (
        <FilterChipGroup
          values={filters.brandLines}
          setValues={newValues => {
            setFilters(filters => ({
              ...filters,
              brandLines: newValues && newValues.length > 0 ? newValues : null,
            }));
          }}
        />
      )}
      <FilterPriceRangeChip
        label={t("Net price", { ns: "Global" })}
        valueMin={filters.netPriceMin}
        valueMax={filters.netPriceMax}
        onReset={() => {
          setFilters(filters => ({
            ...filters,
            netPriceMin: null,
            netPriceMax: null,
          }));
        }}
      />
      <FilterPriceRangeChip
        label={t("List price", { ns: "Product" })}
        valueMin={filters.listPriceMin}
        valueMax={filters.listPriceMax}
        onReset={() => {
          setFilters(filters => ({
            ...filters,
            listPriceMin: null,
            listPriceMax: null,
          }));
        }}
      />
      {filters.organisationFavourite && (
        <FilterChip
          value={t("Favourites", { ns: "ProductSearch" })}
          onDelete={() =>
            setFilters(filters => ({ ...filters, organisationFavourite: null }))
          }
        />
      )}
      {filters.properties && (
        <PropertiesFilterChips
          values={filters.properties}
          setValues={newValue =>
            setFilters(filters => ({ ...filters, properties: newValue }))
          }
        />
      )}
      {AdditionalChips}
      {onReset && (
        <Typography variant="body2">
          <Link onClick={onReset} color="secondary" sx={{ cursor: "pointer" }}>
            {t("Clear all", { ns: "Global" })}
          </Link>
        </Typography>
      )}
    </Stack>
  ) : null;
}

export function canShowChips<Filters extends PimSearchProductsFilters>(
  filters: Filters
): boolean {
  return !!(
    filters.netPriceMin ||
    filters.netPriceMax ||
    filters.listPriceMin ||
    filters.listPriceMax ||
    filters.organisationFavourite ||
    (filters.supplierIds && filters.supplierIds.length > 0) ||
    (filters.manufacturerIds && filters.manufacturerIds.length > 0) ||
    (filters.productTypeIds && filters.productTypeIds.length > 0) ||
    (filters.brands && filters.brands.length > 0) ||
    (filters.brandLines && filters.brandLines.length > 0) ||
    (filters.properties && filters.properties.length > 0)
  );
}
