import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { MenuButton, TitleWithPathForPdf, useScreenWidth } from "@msys/ui";
import { Divider, Stack } from "@mui/material";
import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AskWhom, namedOperations } from "../../../../clients/graphqlTypes.js";
import { usePageWidth } from "../../../commons/hooks/usePageWidth.js";
import { PageFullScreenModal } from "../../../commons/layout/PageFullScreenModal.js";
import { PageMiddleColumn } from "../../../commons/layout/PageMiddleColumn.js";
import { QUOTE_TEMPLATE_ITEM_TYPES } from "../../../constants.js";
import { itemCanHaveVerticalSelection } from "../../../features/doc-items/app-conditions/actions.js";
import { rootItemsAllowedConstraints } from "../../../features/doc-items/constraints.js";
import { PropertiesDebugMenuItem } from "../../../features/doc-items/debug/PropertiesDebug.js";
import { RunAttributeExpressionsMenuItem } from "../../../features/doc-items/debug/RunAttributeExpressionsMenuItem.js";
import { EmptyStateItem } from "../../../features/doc-items/items/EmptyStateItem.js";
import { DocItemContextMenuItems } from "../../../features/doc-items/menus/DocItemContextMenuItems.js";
import { ItemVerticalMenuItem } from "../../../features/doc-items/menus/ItemVerticalMenuItem.js";
import { QuoteItemMenuItems } from "../../../features/quotes/QuoteItemMenuItems.js";
import { TemplateLinkIconButton } from "../../../features/templates/buttons/TemplateLinkIconButton.js";
import { TemplateQuoteItem } from "../../../features/templates/quote/TemplateQuoteItem.js";
import { QuoteTemplateCreateFromItemButton } from "../../../features/templates/quote/buttons/QuoteTemplateCreateFromItemMenuItem.js";
import { fromVersionNumberParamToVersionNumberInt } from "../../../utils.js";
import { useTemplateQuoteVersionItemQuery } from "./TemplateQuoteVersionItem.generated.js";

const AFTER_DELETE_REFETCH_QUERIES = [
  namedOperations.Query.TemplateQuoteVersion,
  namedOperations.Query.TemplateQuoteVersionPreview,
];
const REFETCH_QUERIES = [
  namedOperations.Query.TemplateQuoteVersion,
  namedOperations.Query.TemplateQuoteVersionPreview,
  namedOperations.Query.TemplateQuoteVersionItem,
];
const REFETCH_QUERIES_PREVIEW = [
  namedOperations.Query.TemplateQuoteVersionPreview,
  namedOperations.Query.TemplateQuoteVersionItem,
];

export function TemplateQuoteVersionItem({
  docId,
  pathToDocList,
  pathToDoc,
  pathToDocPage,
  isAbsolute,
}: {
  docId: string;
  pathToDocList: string;
  pathToDoc: string;
  pathToDocPage: string;
  isAbsolute?: boolean;
}) {
  const { itemId, versionNumber: versionNumberFromParams } = useParams();

  if (!itemId) throw new Error("Quote template item id is missing");

  const versionNumber = fromVersionNumberParamToVersionNumberInt(
    versionNumberFromParams
  );

  const navigate = useNavigate();
  const { isMinTablet } = useScreenWidth();
  const { isMinTwoColumnsWithPreview } = usePageWidth();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { expandedItemIds, viewerDecisionRole } = useOutletContext<{
    expandedItemIds: string[] | undefined;
    viewerDecisionRole: AskWhom | undefined;
  }>();

  const client = useApolloClient();

  const query = useTemplateQuoteVersionItemQuery({
    client,
    variables: { docId, itemId, versionNumber: versionNumber! },
  });

  const quoteTemplate = getDataOrNull(
    query.data?.quoteTemplateVersion
  )?.quoteTemplate;
  const item = quoteTemplate?.item?.[0];

  const canEdit = false;

  if (!item)
    return (
      <EmptyStateItem
        isAbsolute={isAbsolute ?? !isMinTwoColumnsWithPreview}
        loading={query.loading}
        error={query.error}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      />
    );

  const quoteTemplateItem = (
    <>
      <TemplateQuoteItem
        key={item.id} // force reset forms
        doc={quoteTemplate}
        item={item}
        pathToDocList={pathToDocList}
        pathToDoc={pathToDoc}
        pathToDocPage={pathToDocPage}
        onResolveTemplateRefetchQueries={REFETCH_QUERIES}
        onSetContentRefetchQueries={REFETCH_QUERIES}
        onUpdateDataRefetchQueries={REFETCH_QUERIES_PREVIEW}
        isEditable={canEdit}
      />
    </>
  );

  const title = (
    <TitleWithPathForPdf title={item.title} pathForPdf={item.pathForPdf} />
  );

  const canCreateTemplate =
    !item.deletedAt &&
    rootItemsAllowedConstraints["TEMPLATE"].includes(item.type) &&
    !item.isRootItem &&
    !item.linkedQuoteTemplate;

  const canHaveVerticalSelection = itemCanHaveVerticalSelection(item);

  const headerActions = canEdit ? (
    <Stack spacing={1}>
      <MenuButton onToggle={setIsOpen}>
        {canCreateTemplate && (
          <QuoteTemplateCreateFromItemButton
            projectId={null}
            docId={docId}
            itemId={itemId}
            onItemReplaced={newItemId =>
              navigate(`${pathToDoc}/latest/edit/items/${newItemId}`)
            }
            refetchQueries={AFTER_DELETE_REFETCH_QUERIES}
            docHasAnyPublishedVersion={quoteTemplate.hasAnyPublishedVersion}
          />
        )}
        {canCreateTemplate && <Divider />}
        <QuoteItemMenuItems
          projectId={null}
          docId={docId}
          item={item}
          onSetContentRefetchQueries={REFETCH_QUERIES}
          documentItemTypes={QUOTE_TEMPLATE_ITEM_TYPES}
          expandedItemIds={expandedItemIds}
          isMenuOpen={isOpen}
        />
        <Divider />

        {canHaveVerticalSelection && (
          <ItemVerticalMenuItem
            projectId={null}
            docId={docId}
            itemId={item.id}
            vertical={item.vertical ?? null}
          />
        )}
        {canHaveVerticalSelection && <Divider />}

        <DocItemContextMenuItems
          pathToDocPage={pathToDocPage}
          docId={docId}
          docType="TEMPLATE"
          projectId={null}
          item={item}
          itemParentType={null}
          disabled
          startConfirmProcess={noopStartConfirmProcess}
          expandedItemIds={expandedItemIds}
          pasteItem={null}
          onAdditionalItemInputChange={null}
        />

        <Divider />
        <RunAttributeExpressionsMenuItem projectId={null} docId={docId} />
        <PropertiesDebugMenuItem
          projectId={null}
          docId={docId}
          itemId={itemId}
        />
      </MenuButton>
    </Stack>
  ) : undefined;

  const headerButtons =
    item.linkedQuoteTemplate && item.linkedQuoteTemplateAtVersionNumber ? (
      <TemplateLinkIconButton
        pathToTemplateDoc={`${pathToDocList}/${item.linkedQuoteTemplate.id}/${item.linkedQuoteTemplateAtVersionNumber}`}
        iconButtonProps={{ size: "small" }}
      />
    ) : undefined;

  if (isMinTablet) {
    return (
      <PageMiddleColumn
        key={itemId}
        hasRightBorder
        hasBackground
        isSelected
        isAbsolute={isAbsolute ?? !isMinTwoColumnsWithPreview}
        title={title}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
        headerActions={headerActions}
        headerButtons={headerButtons}
      >
        {quoteTemplateItem}
      </PageMiddleColumn>
    );
  }

  return (
    <PageFullScreenModal
      key={itemId}
      title={title}
      onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      headerActions={headerActions}
      headerButtons={headerButtons}
    >
      {quoteTemplateItem}
    </PageFullScreenModal>
  );
}

async function noopStartConfirmProcess() {
  return false;
}
