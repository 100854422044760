import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { Stack } from "../../../commons/layout/Stack.js";
import {
  OrganisationRegistrationDetailsBox_OrganisationFragment,
  OrganisationRegistrationDetailsBox_ViewerOrganisationFragment,
  useModifyOrganisationRegistrationDetailsMutation,
} from "./OrganisationRegistrationDetailsBox.generated.js";

type Organisation =
  | OrganisationRegistrationDetailsBox_OrganisationFragment
  | OrganisationRegistrationDetailsBox_ViewerOrganisationFragment;

interface Props {
  organisation: Organisation;
}

export function OrganisationRegistrationDetailsBox({ organisation }: Props) {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("OrganisationProfile");

  return (
    <CardContainer isExpandable title={t("Company registration details")}>
      <Box p={1}>
        <RestrictedByOrganisationPermissionWithDebug
          permission="MANAGE_ORG"
          otherwise={
            <OrganisationRegistrationDetailsData organisation={organisation} />
          }
        >
          {viewer.organisation.id === organisation.id &&
          !!viewer.roles.find(
            r =>
              r.internalName === "ORG_ADMIN" || r.internalName === "ORG_ADMIN"
          ) ? (
            <OrganisationRegistrationDetailsForm organisation={organisation} />
          ) : (
            <OrganisationRegistrationDetailsData organisation={organisation} />
          )}
        </RestrictedByOrganisationPermissionWithDebug>
      </Box>
    </CardContainer>
  );
}

function OrganisationRegistrationDetailsData({
  organisation,
}: {
  organisation: Organisation;
}) {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);

  return (
    <Stack flexDirection="column">
      <LabeledValue
        label={t("Full legal name", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.fullLegalName ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Tax number", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.taxNumber ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("VAT number", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.vatNumber ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Registration number", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.registrationNumber ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Registration office", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.registrationOffice ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Managing director", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.managingDirector ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
    </Stack>
  );
}

interface FormValues {
  fullLegalName: string;
  taxNumber: string;
  vatNumber: string;
  registrationNumber: string;
  registrationOffice: string;
  managingDirector: string;
}

function OrganisationRegistrationDetailsForm({
  organisation,
}: {
  organisation: Organisation;
}) {
  const { t } = useTranslate("OrganisationProfile");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyOrganisation] = useModifyOrganisationRegistrationDetailsMutation(
    {
      client,
    }
  );

  const initialValues = {
    fullLegalName: organisation.fullLegalName,
    taxNumber: organisation.taxNumber,
    vatNumber: organisation.vatNumber,
    registrationNumber: organisation.registrationNumber,
    registrationOffice: organisation.registrationOffice,
    managingDirector: organisation.managingDirector,
  };

  const validationSchema = Yup.object().shape({
    fullLegalName: Yup.string().label(t("Full legal name")),
    taxNumber: Yup.string().label(t("Tax number")),
    vatNumber: Yup.string().label(t("VAT number")),
    registrationNumber: Yup.string().label(t("Registration number")),
    registrationOffice: Yup.string().label(t("Registration office")),
    managingDirector: Yup.string().label(t("Managing director")),
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={async (values, formikActions) => {
        try {
          await modifyOrganisation({
            variables: {
              input: {
                organisationId: organisation.id,
                fullLegalName: values.fullLegalName,
                taxNumber: values.taxNumber,
                vatNumber: values.vatNumber,
                registrationNumber: values.registrationNumber,
                registrationOffice: values.registrationOffice,
                managingDirector: values.managingDirector,
              },
            },
          });
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, { variant: "error" });
        }
      }}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack flexDirection="column">
            <Field
              component={TextField}
              disabled={false}
              name="fullLegalName"
              label={t("Full legal name")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="taxNumber"
              label={t("Tax number")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="vatNumber"
              label={t("VAT number")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="registrationNumber"
              label={t("Registration number")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="registrationOffice"
              label={t("Registration office")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="managingDirector"
              label={t("Managing director")}
            />
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
