/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SapSalesQuotationQuoteRedirectRouteQueryVariables = Types.Exact<{
  salesQuotationId: Types.Scalars['ID']['input'];
}>;


export type SapSalesQuotationQuoteRedirectRouteQuery = { quoteSapS4HanaDataBySalesQuotationId?: { __typename: 'QuoteSapS4HanaData', id: string, docId: string, projectId: string } | null };


export const SapSalesQuotationQuoteRedirectRouteDocument = gql`
    query SapSalesQuotationQuoteRedirectRoute($salesQuotationId: ID!) {
  quoteSapS4HanaDataBySalesQuotationId(salesQuotationId: $salesQuotationId) {
    id
    docId
    projectId
  }
}
    `;

/**
 * __useSapSalesQuotationQuoteRedirectRouteQuery__
 *
 * To run a query within a React component, call `useSapSalesQuotationQuoteRedirectRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSapSalesQuotationQuoteRedirectRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSapSalesQuotationQuoteRedirectRouteQuery({
 *   variables: {
 *      salesQuotationId: // value for 'salesQuotationId'
 *   },
 * });
 */
export function useSapSalesQuotationQuoteRedirectRouteQuery(baseOptions: Apollo.QueryHookOptions<SapSalesQuotationQuoteRedirectRouteQuery, SapSalesQuotationQuoteRedirectRouteQueryVariables> & ({ variables: SapSalesQuotationQuoteRedirectRouteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SapSalesQuotationQuoteRedirectRouteQuery, SapSalesQuotationQuoteRedirectRouteQueryVariables>(SapSalesQuotationQuoteRedirectRouteDocument, options);
      }
export function useSapSalesQuotationQuoteRedirectRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SapSalesQuotationQuoteRedirectRouteQuery, SapSalesQuotationQuoteRedirectRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SapSalesQuotationQuoteRedirectRouteQuery, SapSalesQuotationQuoteRedirectRouteQueryVariables>(SapSalesQuotationQuoteRedirectRouteDocument, options);
        }
export function useSapSalesQuotationQuoteRedirectRouteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SapSalesQuotationQuoteRedirectRouteQuery, SapSalesQuotationQuoteRedirectRouteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SapSalesQuotationQuoteRedirectRouteQuery, SapSalesQuotationQuoteRedirectRouteQueryVariables>(SapSalesQuotationQuoteRedirectRouteDocument, options);
        }
export type SapSalesQuotationQuoteRedirectRouteQueryHookResult = ReturnType<typeof useSapSalesQuotationQuoteRedirectRouteQuery>;
export type SapSalesQuotationQuoteRedirectRouteLazyQueryHookResult = ReturnType<typeof useSapSalesQuotationQuoteRedirectRouteLazyQuery>;
export type SapSalesQuotationQuoteRedirectRouteSuspenseQueryHookResult = ReturnType<typeof useSapSalesQuotationQuoteRedirectRouteSuspenseQuery>;
export type SapSalesQuotationQuoteRedirectRouteQueryResult = Apollo.QueryResult<SapSalesQuotationQuoteRedirectRouteQuery, SapSalesQuotationQuoteRedirectRouteQueryVariables>;