import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  Autocomplete,
  Modal,
  Select,
  useLocalStorageAsState,
  useScreenWidth,
} from "@msys/ui";
import { Input as InputIcon } from "@mui/icons-material";
import {
  darken,
  IconButton,
  lighten,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash-es";
import React from "react";
import {
  EntitySearchFilterOperator,
  Props2,
} from "../../../../clients/graphqlTypes.js";
import {
  ExpressionModal_DocumentItemFragment,
  useExpressionModalQuery,
} from "./ExpressionModal.generated.js";
import { useTranslate } from "@tolgee/react";
import { isComputedProp, isPropMissingValue } from "../properties.js";
import { FullScreenToggleIconButton } from "../../../commons/button/FullScreenToggleIconButton.js";

interface Option {
  value: string;
  label: string;
  itemId: string;
  isComputed: boolean;
  hasValue: boolean;
}

interface Props<Operator extends EntitySearchFilterOperator> {
  projectId: string | null;
  docId: string;
  itemId: string;
  filterKey: string;
  operator: Operator;
  allowedSearchFilterOperators: { label: string; value: Operator }[];
  expression: string | undefined;
  handleClose: () => void;
  handleComplete: (
    value: {
      expr: string;
      operator: Operator;
    },
    handleClose: () => void
  ) => Promise<void> | void;
}

export function ProductSearchFilterExpressionModal<
  Operator extends EntitySearchFilterOperator,
>({
  projectId,
  docId,
  itemId,
  filterKey,
  operator: passedOperator,
  allowedSearchFilterOperators,
  expression: passedExpression = "",
  handleClose,
  handleComplete,
}: Props<Operator>) {
  const { t } = useTranslate(["Global", "QuoteItem"]);

  const { isMaxPhone } = useScreenWidth();
  const [isFullScreen, setIsFullScreen] = useLocalStorageAsState<boolean>(
    `msys-product-search-expression-modal-fullscreen`,
    false,
    true
  );

  const expressionFieldRef = React.useRef<HTMLTextAreaElement>(null);
  const [inputState, setInputState] = React.useState<{
    expression: string;
    cursorIndex: number | null;
  }>({ expression: passedExpression, cursorIndex: null });

  const [selectedVariable, setSelectedVariable] = React.useState<Option | null>(
    null
  );

  const [selectedOperator, setSelectedOperator] =
    React.useState<Operator>(passedOperator);

  const client = useApolloClient();
  const query = useExpressionModalQuery({
    client,
    variables: {
      docId,
      projectId,
    },
  });
  const [items, self] = React.useMemo(() => {
    const items =
      getDataOrNull(query.data?.items)?.items?.filter(validItemTypePredicate) ??
      [];
    const self = items.find(i => i.id === itemId);

    return [items, self, []];
  }, [itemId, query.data?.items]);

  const options = React.useMemo(() => {
    function sortItems(
      item1: ExpressionModal_DocumentItemFragment,
      item2: ExpressionModal_DocumentItemFragment
    ) {
      if (item1.id === self?.id) return -1;
      if (item2.id === self?.id) return 1;
      if (item1.id === self?.parentId) return -1;
      if (item2.id === self?.parentId) return 1;
      if (item1.isRootItem) return -1;
      if (item2.isRootItem) return 1;
      return 0;
    }

    function sortProps(a: Props2, b: Props2) {
      return a.key.localeCompare(b.key);
    }

    return items.sort(sortItems).flatMap(item =>
      [...item.props2].sort(sortProps).map(prop => ({
        itemId: item.id,
        value: `prop($item_${item.id.replaceAll("-", "_")}, "${prop.key}")`,
        label: prop.key,
        isComputed: isComputedProp(prop),
        hasValue: !isPropMissingValue(prop),
      }))
    );
  }, [items, self?.id, self?.parentId]);

  const handleSave = async () => {
    handleComplete(
      { expr: inputState.expression, operator: selectedOperator },
      handleClose
    );
  };

  return (
    <Modal
      title={t("Build your formula", {
        ns: "QuoteItem",
      })}
      dialogProps={
        !isMaxPhone && isFullScreen ? { fullScreen: true } : undefined
      }
      headerActions={
        !isMaxPhone ? (
          <FullScreenToggleIconButton
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
          />
        ) : undefined
      }
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
          },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          handleClick: handleSave,
          buttonProps: {
            loading: query.loading,
            disabled: !inputState.expression.trim(),
          },
        },
      ]}
      handleClose={handleClose}
    >
      <Stack spacing={1}>
        <Typography color={"secondary"}>{filterKey}</Typography>
        <Select
          label={"Operator"}
          options={allowedSearchFilterOperators}
          value={selectedOperator}
          onChange={value => {
            setSelectedOperator(value as Operator);
          }}
          required
        />
        <ExpressionInput
          inputRef={expressionFieldRef}
          autoFocus
          label={t("Formula", {
            ns: "QuoteItem",
          })}
          value={inputState.expression}
          onChange={event =>
            setInputState(state => ({
              ...state,
              expression: event.target.value,
            }))
          }
          onBlur={event =>
            setInputState(state => ({
              ...state,
              cursorIndex: event.target.selectionStart,
            }))
          }
          onFocus={event => {
            event.target.setSelectionRange(
              inputState.cursorIndex,
              inputState.cursorIndex
            );
          }}
          required
        />
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Autocomplete
            inputLabel={t("Variable for formula", {
              ns: "QuoteItem",
            })}
            options={options}
            groupBy={option => option.itemId}
            renderGroup={params => {
              const item = items.find(item => item.id === params.group);
              const pseudoReferences = [];
              if (params.group === itemId) pseudoReferences.push("self");
              if (item?.id === self?.parentId) pseudoReferences.push("parent");
              if (item?.isRootItem) pseudoReferences.push("root");
              return (
                <li key={params.group}>
                  <GroupHeader direction="row" spacing={1}>
                    <Typography fontWeight={"bold"}>
                      {`${item?.pathForPdf} ${item?.title}`}
                    </Typography>
                    <Typography color={"gray"}>
                      {pseudoReferences.length > 0
                        ? `(${pseudoReferences.join(", ")})`
                        : null}
                    </Typography>
                  </GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              );
            }}
            renderOption={(props, option) => {
              return (
                <Stack
                  component={"li"}
                  direction={"row"}
                  spacing={1}
                  key={option.value}
                  {...props}
                >
                  <Typography>{option.label}</Typography>
                  {option.isComputed && (
                    <Typography color="secondary">{"(computed)"}</Typography>
                  )}
                  {!option.hasValue && (
                    <Typography color="error">{"(missing value)"}</Typography>
                  )}
                </Stack>
              );
            }}
            value={selectedVariable}
            isOptionEqualToValue={(option, value) => isEqual(option, value)}
            onChange={value => setSelectedVariable(value)}
          />
          <IconButton
            color={"secondary"}
            disabled={!selectedVariable}
            onClick={() => {
              if (!selectedVariable) return;

              setInputState(({ expression, cursorIndex }) => {
                const index = cursorIndex ?? expression.length;
                return {
                  expression: `${expression.substring(0, index)}${
                    selectedVariable.value
                  }${expression.substring(index)}`,
                  cursorIndex:
                    (cursorIndex ?? 0) + selectedVariable.value.length,
                };
              });

              setTimeout(() => expressionFieldRef.current?.focus());
            }}
          >
            <InputIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Modal>
  );
}

const VALID_DOCUMENT_ITEM_TYPES = ["Item" as const];
// const VALID_DOCUMENT_ITEM_TYPES: DocumentItem["__typename"][] = [
//   "RequirementItem",
//   "QuoteItem",
//   "QuoteTemplateItem",
// ];

function validItemTypePredicate(
  item: ExpressionModal_DocumentItemFragment
): item is Extract<ExpressionModal_DocumentItemFragment, { props2: Props2[] }> {
  return VALID_DOCUMENT_ITEM_TYPES.includes(item.__typename);
}

function ExpressionInput({ ...props }: React.ComponentProps<typeof TextField>) {
  return <TextField {...props} multiline />;
}

const GroupHeader = styled(Stack)(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
