import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, TextWithBreaks } from "@msys/ui";
import { FormatListBulleted as FormatListBulletedIcon } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { flatten, noop, sortBy, take } from "lodash-es";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useLatest } from "react-use";
import {
  AttachmentInput,
  ModifyItemTaskInfoValuesInput,
} from "../../../../clients/graphqlTypes.js";
import { useFeature } from "../../../../common/FeatureFlags.js";
import { color } from "../../../../common/MuiThemeProvider.js";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { ButtonLink } from "../../../commons/button/Button.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { SHORT_LIST_RESULTS_PER_PAGE } from "../../../constants.js";
import { TreeListItem } from "../../../trees/components/TreeListItem.js";
import { useQuoteModifyItemTaskInfo_TaskManagementBoxMutation } from "../../doc-items/boxes/TaskManagementBox.generated.js";
import { isTreePreviewItemVisible } from "../../doc-items/helpers.js";
import {
  PhotoApprovalProcess,
  PhotoApprovalProcessRef,
} from "../../tasks/PhotoApprovalProcess.js";
import { usePhotoApproveTaskItemMutation } from "../../tasks/Tasks.generated.js";
import { DoneModalRef } from "../../tasks/modals/DoneModal.js";
import { TaskListItem, createTreeItem } from "../../tasks/trees.js";
import {
  AddTaskWorkSessionProcess,
  AddTaskWorkSessionProcessRef,
} from "../../work-sessions/AddTaskWorkSessionProcess.js";
import {
  AddTaskWorkSessionsProcess,
  AddTaskWorkSessionsProcessRef,
} from "../../work-sessions/AddTaskWorkSessionsProcess.js";
import {
  ProjectOverviewNextTasksBox_ProjectFragment,
  useProjectOverviewNextTasksBoxQuery,
} from "./ProjectOverviewNextTasksBox.generated.js";

const DoneModal = React.lazy(() => import("../../tasks/modals/DoneModal.js"));

interface Props {
  project: ProjectOverviewNextTasksBox_ProjectFragment;
}

export const ProjectOverviewNextTasksBox = ({ project }: Props) => {
  const { t } = useTranslate([
    "ProjectOverview",
    "Global",
    "QuoteItemTaskManagementBox",
  ]);
  const viewer = useUserData().currentUser!;

  const pathToDocPage = `/projects/${project.id}/tasks/edit`;

  const { enqueueSnackbar } = useSnackbar();

  const photoApprovalRef = useRef<PhotoApprovalProcessRef>(null);
  const checkPhotoApprove = useCallback(
    (itemId: string, docId: string, checkChildren: boolean) => {
      photoApprovalRef.current?.checkPhotoApprove(itemId, docId, checkChildren);
    },
    []
  );

  const addTaskWorkSessionRef =
    React.useRef<AddTaskWorkSessionProcessRef>(null);
  const addTaskWorkSessionsRef =
    React.useRef<AddTaskWorkSessionsProcessRef>(null);
  const addWorkSession = React.useCallback(
    (itemId: string, projectId: string, docId: string) => {
      addTaskWorkSessionRef.current?.addWorkSession(itemId, projectId, docId);
    },
    []
  );

  const client = useApolloClient();
  const query = useProjectOverviewNextTasksBoxQuery({
    client,
  });

  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);

  const tasks = project.tasks;

  const [tasksIds, setTasksIds] = useState<Record<string, boolean> | null>(
    null
  );
  const tasksIdsLatest = useLatest(tasksIds);

  const [tasksItems, filteredTasksItems] = useMemo(() => {
    const items = tasks
      ? flatten(
          tasks.map(tasksDoc =>
            tasksDoc.items
              .map(item => ({
                ...item,
                docAgreement: tasksDoc.agreement,
                docViewerPermissions: tasksDoc.viewerPermissions,
              }))
              .filter((i, idx, arr) =>
                isTreePreviewItemVisible(true, i, arr, true, false)
              )
              .filter(i => i.canBeWorkedOn && !i.deletedAt)
          )
        )
      : [];

    const filteredItems = sortBy(
      items.filter(
        item =>
          item.assignee?.id === viewer.id &&
          (item.isDone === false || tasksIdsLatest.current?.[item.id]) &&
          !item.isRootItem
      ),
      item => (item.dueDate ? moment(item.dueDate).valueOf() : Infinity)
    );

    return [items, filteredItems];
  }, [viewer, tasks, tasksIdsLatest]);

  useEffect(() => {
    if (filteredTasksItems.length && !tasksIdsLatest.current) {
      setTasksIds(
        filteredTasksItems.reduce(
          (acc, item) => ({ ...acc, [item.id]: true }),
          {} as Record<string, boolean>
        )
      );
    }
  }, [filteredTasksItems, tasksIdsLatest]);

  const doneRef = useRef<DoneModalRef>(null);

  const onBeforeTaskDone = useCallback(
    async (
      itemId: string,
      docId: string,
      projectId: string,
      newIsDone: boolean
    ) => {
      if (newIsDone) {
        if (organisationDefaults?.defaultTaskPhotoIsRequired) {
          const areAllPhotosAdded: boolean =
            (await photoApprovalRef.current?.checkPhotoApprove(
              itemId,
              docId,
              true
            )) ?? true;
          if (!areAllPhotosAdded) {
            enqueueSnackbar(
              t("Please upload all photos to mark task as completed", {
                ns: "QuoteItemTaskManagementBox",
              }),
              { variant: "error" }
            );
            return false;
          }
        }
      }
      return true;
    },
    [organisationDefaults?.defaultTaskPhotoIsRequired, enqueueSnackbar, t]
  );

  const onAfterTaskDone = useCallback(
    async (
      itemId: string,
      docId: string,
      projectId: string,
      newIsDone: boolean
    ) => {
      if (newIsDone) {
        await doneRef.current?.open();
        if (!organisationDefaults?.defaultTaskPhotoIsRequired) {
          await photoApprovalRef.current?.checkPhotoApprove(
            itemId,
            docId,
            true
          );
        }
        await addTaskWorkSessionsRef.current?.addWorkSessions(
          itemId,
          docId,
          projectId
        );
      }
    },
    [organisationDefaults?.defaultTaskPhotoIsRequired]
  );

  const TreeItem = React.useMemo(
    () =>
      createTreeItem({
        pathToDocPage: null,
        projectId: project.id,
        onBeforeTaskDone,
        onAfterTaskDone,
        checkPhotoApprove,
        addWorkSession,
        navigateToItem: noop,
        setItemExpanded: noop,
      }),
    [
      project.id,
      onBeforeTaskDone,
      onAfterTaskDone,
      checkPhotoApprove,
      addWorkSession,
    ]
  );

  const areAnimationsEnabled = useFeature("Animations");

  const [modifyItemTaskInfo, { loading: modifyItemTaskInfoLoading }] =
    useQuoteModifyItemTaskInfo_TaskManagementBoxMutation({ client });

  const modifyTaskItem = async (
    itemId: string,
    docId: string,
    values: ModifyItemTaskInfoValuesInput
  ) => {
    try {
      await modifyItemTaskInfo({
        variables: { input: { docId, projectId: project.id, itemId, values } },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const [
    photoApproveTaskItemMutation,
    { loading: photoApproveTaskItemLoading },
  ] = usePhotoApproveTaskItemMutation({ client });

  const photoApproveTaskItem = async (
    itemId: string,
    docId: string,
    photo: AttachmentInput
  ) => {
    try {
      await photoApproveTaskItemMutation({
        variables: { input: { projectId: project.id, docId, itemId, photo } },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <>
      <RestrictedByProjectPermissionWithDebug
        permission={"EXECUTE_TASK"}
        project={project}
      >
        <CardContainer
          isExpandable
          isInitiallyClosed={false}
          itemCount={filteredTasksItems.length}
          Icon={<FormatListBulletedIcon />}
          title={t("My next tasks", {
            ns: "ProjectOverview",
          })}
        >
          {filteredTasksItems.length > 0 && (
            <Box>
              {take(filteredTasksItems, SHORT_LIST_RESULTS_PER_PAGE).map(
                item => (
                  <TreeListItem
                    p={0}
                    key={item.id}
                    component={Link}
                    // @ts-ignore compound component
                    to={`${pathToDocPage}/${item.docId}/items/${item.id}`}
                    $canBeFirst={false}
                  >
                    <TaskListItem
                      item={item}
                      allDocItems={tasksItems}
                      itemComponent={TreeItem}
                    />
                  </TreeListItem>
                )
              )}
            </Box>
          )}
          {filteredTasksItems.length > SHORT_LIST_RESULTS_PER_PAGE && (
            <>
              <Divider />
              <ButtonLink
                isLast
                fullWidth
                to={`${pathToDocPage}?quickfilter=MINE`}
                color="secondary"
                variant="text"
              >
                {t("See more", {
                  ns: "Global",
                })}
              </ButtonLink>
            </>
          )}
          {!filteredTasksItems.length && (
            <Stack p={1} flexDirection="column">
              <Stack justifyContent="center" my={1}>
                <Typography
                  variant="caption"
                  align="center"
                  style={{ color: color.grey }}
                >
                  <TextWithBreaks
                    text={t("There are no tasks assigned to you right now", {
                      ns: "ProjectOverview",
                    })}
                  />
                </Typography>
              </Stack>
              <Stack justifyContent="center">
                <Button
                  color="secondary"
                  component={Link}
                  to={`${pathToDocPage}?quickfilter=ALL`}
                >
                  {t("View all tasks", {
                    ns: "ProjectOverview",
                  })}
                </Button>
              </Stack>
            </Stack>
          )}
        </CardContainer>
      </RestrictedByProjectPermissionWithDebug>
      <Suspense fallback={null}>
        <DoneModal ref={doneRef} enabled={areAnimationsEnabled} />
      </Suspense>
      <RestrictedByProjectPermissionWithDebug
        permission="EXECUTE_TASK"
        project={project}
      >
        <PhotoApprovalProcess
          ref={photoApprovalRef}
          loading={photoApproveTaskItemLoading}
          photoApproveTaskItem={photoApproveTaskItem}
          projectId={project.id}
        />
      </RestrictedByProjectPermissionWithDebug>
      <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
        <RestrictedByProjectPermissionWithDebug
          permission="EXECUTE_TASK"
          project={project}
        >
          <AddTaskWorkSessionProcess ref={addTaskWorkSessionRef} />
        </RestrictedByProjectPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
      <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
        <RestrictedByProjectPermissionWithDebug
          permission="EXECUTE_TASK"
          project={project}
        >
          <AddTaskWorkSessionsProcess ref={addTaskWorkSessionsRef} />
        </RestrictedByProjectPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    </>
  );
};
