import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";
import { ClosingTextInput as PurchaseOrderClosingTextInput } from "../../purchase-orders/PurchaseOrderClosingText.js";
import { OpeningTextInput as PurchaseOrderOpeningTextInput } from "../../purchase-orders/PurchaseOrderOpeningText.js";
import { ModifyOrganisationTextsModal } from "./ModifyOrganisationTextsModal.js";
import {
  useModifyOrganisationTextsModalQuery,
  useOrganisationTextsModal_ModifyOrganisationMutation,
} from "./ModifyOrganisationTextsModal.generated.js";

interface FormValues {
  opening?: string;
  closing?: string;
}

interface Props {
  organisationId: string;
  title: string;
  handleClose: () => void;
  handleComplete?: (organisationId: string) => Promise<void>;
}

export const ModifyOrganisationPurchaseOrdersTextsModal = ({
  organisationId,
  title,
  handleClose,
  handleComplete,
}: React.PropsWithChildren<Props>) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const viewer = useUserData().currentUser!;

  const client = useApolloClient();
  const query = useModifyOrganisationTextsModalQuery({
    client,
  });
  const [modifyOrganisation] =
    useOrganisationTextsModal_ModifyOrganisationMutation({
      client,
    });

  const organisationDocumentSettings = getDataOrNull(
    query.data?.organisationDocumentSettings
  );

  const onSubmit = async (values: FormValues) => {
    try {
      await modifyOrganisation({
        variables: {
          input: {
            purchaseOrderTexts: {
              opening: values.opening,
              closing: values.closing,
            },
          },
        },
      });
      enqueueSnackbar(
        t("Changes saved", {
          ns: "Global",
        })
      );
      handleComplete?.(organisationId);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  if (viewer.organisation.id !== organisationId)
    return <div>Not own organisation!</div>;

  if (!organisationDocumentSettings) return null;

  return (
    <ModifyOrganisationTextsModal
      handleClose={handleClose}
      isLoading={query.loading}
      organisationId={organisationId}
      title={title}
    >
      <Stack spacing={3} direction="column">
        <Stack direction="column" spacing={1}>
          <Typography variant="h3">
            {t("Opening text", {
              ns: "OrganisationSettings",
            })}
          </Typography>
          <PurchaseOrderOpeningTextInput
            content={organisationDocumentSettings.purchaseOrderTexts.opening}
            onSave={content => onSubmit(content)}
            showInfo={true}
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="h3">
            {t("Closing text", {
              ns: "OrganisationSettings",
            })}
          </Typography>
          <PurchaseOrderClosingTextInput
            content={organisationDocumentSettings.purchaseOrderTexts.closing}
            onSave={content => onSubmit(content)}
            showInfo={true}
          />
        </Stack>
      </Stack>
    </ModifyOrganisationTextsModal>
  );
};
