import {
  CollapseHeader,
  Size,
  TypographyWithIcon,
  useElementObserveSize,
  useElementSize,
} from "@msys/ui";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import { partition } from "lodash-es";
import React from "react";
import { List, WindowScroller } from "react-virtualized";
import { ListRowRenderer } from "react-virtualized/dist/es/List.js";
import { useHeightsCache } from "../../commons/hooks/useHeightsCache.js";
import { ItemType } from "../../../clients/graphqlTypes.js";
import { PriceDataExtended } from "../doc-items/helpers.js";
import { PreviewItem__DocumentItemFragment } from "./DocumentPreviewItems.generated.js";
import { Fields, PreviewItem } from "./DocumentPreviewItemsTypes.js";

export const DocumentPreviewItemsWithVirtualizationMobile = React.memo(
  <T extends PreviewItem__DocumentItemFragment>({
    previewItems,
    container,
    fields,
    getItemStyle,
    defaultHeight,
    overscanRowCount,
    showCollapseButton,
  }: {
    previewItems: PreviewItem<T>[];
    container?: HTMLElement | null;
    fields: Fields<T>;
    getItemStyle?: (theme: Theme, item: T) => React.CSSProperties | undefined;
    defaultHeight: number;
    overscanRowCount: number;
    showCollapseButton: boolean;
  }) => {
    const sizeMeasureRef = React.useRef<HTMLDivElement | null>(null);
    const size = useElementSize(sizeMeasureRef);
    const { setHeightForId, totalHeight, getHeightForIndex, cache } =
      useHeightsCache(previewItems, defaultHeight, i => i.item.id);
    const rowRenderer = React.useMemo(
      () =>
        getRowRendererMobile({
          previewItems,
          fields,
          getItemStyle,
          setHeightForId,
          showCollapseButton,
        }),
      [previewItems, fields, getItemStyle, setHeightForId, showCollapseButton]
    );

    const listRef = React.useRef<List | null>(null);
    React.useEffect(() => {
      listRef.current?.recomputeRowHeights();
    }, [previewItems]);

    return (
      // @ts-ignore render return type is incompatible
      <WindowScroller scrollElement={container ?? window}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <div
            ref={ref => {
              sizeMeasureRef.current = ref;
              // @ts-ignore wrong types
              registerChild(ref);
            }}
            style={{ height: totalHeight }} // this is important to set, otherwise height will be shrinking when going up
          >
            {/*@ts-ignore render return type is incompatible*/}
            <List
              ref={listRef}
              autoHeight
              deferredMeasurementCache={cache}
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              overscanRowCount={overscanRowCount}
              rowCount={previewItems.length}
              rowHeight={getHeightForIndex}
              rowRenderer={rowRenderer}
              scrollTop={scrollTop}
              width={size.width}
            />
          </div>
        )}
      </WindowScroller>
    );
  }
);

const getRowRendererMobile =
  <T extends PreviewItem__DocumentItemFragment>({
    previewItems,
    fields,
    getItemStyle,
    setHeightForId,
    showCollapseButton,
  }: {
    previewItems: PreviewItem<T>[];
    fields: Fields<T>;
    getItemStyle?: (theme: Theme, item: T) => React.CSSProperties | undefined;
    setHeightForId: (id: string, height: number) => boolean;
    showCollapseButton: boolean;
  }): ListRowRenderer =>
  ({ index, isScrolling, key, style, parent }) => {
    const {
      item,
      parent: parentItem,
      childItems,
      priceData,
    } = previewItems[index];
    return (
      <div style={{ ...style, overflow: "hidden" }} key={key}>
        <DocumentPreviewItemRowMobile
          key={item.id}
          fields={fields}
          item={item}
          parent={parentItem}
          priceData={priceData}
          childItems={childItems}
          getItemStyle={getItemStyle}
          onSizeChange={size => {
            const changed = setHeightForId(item.id, Math.ceil(size.height));
            if (changed) {
              parent?.recomputeGridSize?.({
                columnIndex: 0,
                rowIndex: index,
              });
            }
          }}
          divider={index > 0}
          showCollapseButton={showCollapseButton}
        />
      </div>
    );
  };

const DocumentPreviewItemRowMobile = <
  T extends PreviewItem__DocumentItemFragment,
>({
  fields,
  item,
  parent,
  priceData,
  childItems,
  getItemStyle,
  onSizeChange,
  style,
  divider,
  showCollapseButton,
}: {
  fields: Fields<T>;
  item: T;
  parent: T | undefined;
  priceData: PriceDataExtended;
  childItems:
    | {
        item: T;
        parent: T | undefined;
        priceData: PriceDataExtended;
      }[]
    | null;
  getItemStyle?: (theme: Theme, item: T) => React.CSSProperties | undefined;
  onSizeChange?(size: Size): void;
  style?: React.CSSProperties;
  divider?: boolean;
  showCollapseButton: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslate(["Decisions", "Quote"]);

  const sizeMeasureRef = React.useRef<HTMLDivElement | null>(null);
  useElementObserveSize(sizeMeasureRef, onSizeChange);

  const [isEliminatedExpanded, setIsEliminatedExpanded] = React.useState(false);
  const [isAlternativeExpanded, setIsAlternativeExpanded] =
    React.useState(false);

  const DecisionFinalizeButton = fields.DecisionFinalizeButton.render(
    item,
    parent,
    priceData
  );
  const DecisionNoneOptionButton = fields.DecisionNoneOptionButton.render(
    item,
    parent,
    priceData
  );

  const [itemChildren, eliminatedItemChildren] = partition(
    childItems ?? [],
    ({ item }) => !item.decisionOptionElimination
  );

  const [mainSubItems, alternativeSubItems] =
    item.decisionSubitemsPreselection.length > 0
      ? partition(itemChildren, ({ item: i }) =>
          item.decisionSubitemsPreselection.includes(i.id)
        )
      : [itemChildren, []];

  const itemStyle = getItemStyle?.(theme, item);
  const titleLineStyle = getTitleLineStyle(item.level, item.type);

  return (
    <Typography
      component="div"
      variant="body2"
      ref={sizeMeasureRef}
      style={style}
    >
      {divider && <Divider />}
      <Stack p={1} direction="column" spacing={1}>
        {!item.isRootItem && (
          <ItemBlock
            item={item}
            itemStyle={itemStyle}
            titleLineStyle={titleLineStyle}
            parent={parent}
            priceData={priceData}
            Pos={fields.Pos.render(item, parent, priceData)}
            DecisionButton={fields.DecisionButton.render(
              item,
              parent,
              priceData
            )}
            DecisionOptionButton={null}
            ContingencyOptionsButtons={fields.ContingencyOptionsButtons.render(
              item,
              parent,
              priceData
            )}
            ContingencyFinalizeButton={fields.ContingencyFinalizeButton.render(
              item,
              parent,
              priceData
            )}
            CollapseButton={fields.CollapseButton.render(
              item,
              parent,
              priceData
            )}
            showCollapseButton={showCollapseButton}
            fields={fields}
          />
        )}
        {mainSubItems.map(({ item, parent, priceData }) => (
          <ItemBlock
            item={item}
            itemStyle={itemStyle}
            titleLineStyle={titleLineStyle}
            parent={parent}
            priceData={priceData}
            Pos={null}
            DecisionButton={null}
            DecisionOptionButton={fields.DecisionOptionButton.render(
              item,
              parent
            )}
            ContingencyOptionsButtons={null}
            ContingencyFinalizeButton={null}
            CollapseButton={null}
            showCollapseButton={false}
            fields={fields}
          />
        ))}

        {alternativeSubItems.length > 0 && (
          <Box display="flex" minWidth={0}>
            <Box width="60px"></Box>
            <Box flex={1}>
              <CollapseHeader
                isExpandable={true}
                isExpanded={isAlternativeExpanded}
                setIsExpanded={setIsAlternativeExpanded}
                title={
                  <TypographyWithIcon
                    count={alternativeSubItems.length}
                    variant="h4"
                  >
                    {item.decisionBehaviorOfSubitems === "SELECT_MANY"
                      ? t("Additional options", {
                          ns: "Quote",
                        })
                      : t("Alternative options", {
                          ns: "Quote",
                        })}
                  </TypographyWithIcon>
                }
              />
            </Box>
          </Box>
        )}

        {isAlternativeExpanded &&
          alternativeSubItems.map(({ item, parent, priceData }) => (
            <ItemBlock
              item={item}
              itemStyle={itemStyle}
              titleLineStyle={titleLineStyle}
              parent={parent}
              priceData={priceData}
              Pos={null}
              DecisionButton={null}
              DecisionOptionButton={fields.DecisionOptionButton.render(
                item,
                parent
              )}
              ContingencyOptionsButtons={null}
              ContingencyFinalizeButton={null}
              CollapseButton={null}
              showCollapseButton={false}
              fields={fields}
            />
          ))}

        {eliminatedItemChildren.length > 0 && (
          <Box display="flex" minWidth={0}>
            <Box width="60px"></Box>
            <Box flex={1}>
              <CollapseHeader
                isExpandable={true}
                isExpanded={isEliminatedExpanded}
                setIsExpanded={setIsEliminatedExpanded}
                title={
                  <TypographyWithIcon
                    count={eliminatedItemChildren.length}
                    variant="h4"
                  >
                    {t("Discarded options", {
                      ns: "Decisions",
                    })}
                  </TypographyWithIcon>
                }
              />
            </Box>
          </Box>
        )}

        {isEliminatedExpanded &&
          eliminatedItemChildren.map(({ item, parent, priceData }) => (
            <ItemBlock
              item={item}
              itemStyle={itemStyle}
              titleLineStyle={titleLineStyle}
              parent={parent}
              priceData={priceData}
              Pos={null}
              DecisionButton={null}
              DecisionOptionButton={null}
              ContingencyOptionsButtons={null}
              ContingencyFinalizeButton={null}
              CollapseButton={null}
              showCollapseButton={false}
              fields={fields}
            />
          ))}

        {(DecisionFinalizeButton || DecisionNoneOptionButton) && (
          <Box display="flex" minWidth={0}>
            <Box width="60px"></Box>
            <Stack flex={1} direction="column" minWidth={0} spacing={1}>
              {DecisionNoneOptionButton}
              {DecisionFinalizeButton}
            </Stack>
          </Box>
        )}
      </Stack>
    </Typography>
  );
};

function ItemBlock<T extends PreviewItem__DocumentItemFragment>({
  item,
  itemStyle,
  titleLineStyle,
  parent,
  priceData,
  Pos,
  DecisionButton,
  DecisionOptionButton,
  ContingencyOptionsButtons,
  ContingencyFinalizeButton,
  CollapseButton,
  fields,
  showCollapseButton,
}: {
  item: T;
  itemStyle: React.CSSProperties | undefined;
  titleLineStyle: React.CSSProperties | undefined;
  parent: T | undefined;
  priceData: PriceDataExtended;
  Pos: React.ReactNode | null;
  DecisionButton: React.ReactNode | null;
  DecisionOptionButton: React.ReactNode | null;
  ContingencyOptionsButtons: React.ReactNode | null;
  ContingencyFinalizeButton: React.ReactNode | null;
  CollapseButton: React.ReactNode | null;
  fields: Fields<T>;
  showCollapseButton: boolean;
}) {
  const NonBindingCaption = fields.NonBindingCaption.render(
    item,
    parent,
    priceData
  );
  const ContingencyOptionalCaption = fields.ContingencyOptionalCaption.render(
    item,
    parent,
    priceData
  );
  return (
    <>
      {(NonBindingCaption || ContingencyOptionalCaption) && (
        <Box display="flex" minWidth={0}>
          <Box width="60px"></Box>
          <Stack flex={1} direction="column" minWidth={0} spacing={1}>
            {NonBindingCaption}
            {ContingencyOptionalCaption}
          </Stack>
        </Box>
      )}

      <Box style={itemStyle} display="flex" minWidth={0}>
        <Box width="60px">
          {Pos}
          {showCollapseButton && CollapseButton}
          {DecisionOptionButton}
        </Box>
        <Stack flex={1} direction="column" minWidth={0} spacing={1}>
          <Stack direction="column" spacing={0.5} alignItems="flex-start">
            <div style={titleLineStyle}>
              {fields.Title.render(item, parent, priceData)}
            </div>
            {fields.Description.render(item, parent, priceData)}
            {fields.Images.render(item, parent, priceData)}
            {ContingencyOptionsButtons}
            {ContingencyFinalizeButton}
          </Stack>

          <Stack direction="row" spacing={2}>
            {fields.Quantity.render(item, parent, priceData)}
            {fields.UnitPrice.render(item, parent, priceData)}
            {fields.Subtotal.render(item, parent, priceData)}
          </Stack>

          <Box alignSelf="flex-end" textAlign="right">
            <div>{fields.TotalPrice.render(item, parent, priceData)}</div>
            {DecisionButton && <div>{DecisionButton}</div>}
          </Box>
        </Stack>
      </Box>
    </>
  );
}

function getTitleLineStyle(
  itemLevel: number,
  itemType: ItemType
): Pick<React.CSSProperties, "fontSize" | "fontWeight" | "lineHeight"> {
  switch (itemLevel) {
    case 1: {
      return {
        fontSize: "20px",
        fontWeight: 500, // itemType === "paid" ? "regular" : "medium",
        lineHeight: "24px",
      };
    }
    case 2: {
      return {
        fontSize: "18px",
        fontWeight: 500, // itemType === "paid" ? "regular" : "medium",
        lineHeight: "22px",
      };
    }
    case 3: {
      return {
        fontSize: "17px",
        fontWeight: 500, // itemType === "paid" ? "regular" : "medium",
        lineHeight: "22px",
      };
    }
    default: {
      return {
        fontSize: "16px",
        fontWeight: 500, // itemType === "paid" ? "regular" : "medium",
        lineHeight: "20px",
      };
    }
  }
}
