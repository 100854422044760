import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import React, { FC, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Modal } from "@msys/ui";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { Skill } from "../../../../clients/graphqlTypes.js";
import CategoriesField from "../../skill-categories/CategoriesField.js";

interface Props {
  title?: string;
  skillset: Skill[];
  handleClose: () => void;
  handleComplete: (skillset: Skill[]) => void | Promise<void>;
}

interface FormValues {
  skillset: Skill[];
}

export const PickSkillsetModal: FC<Props> = ({
  title,
  skillset,
  handleClose,
  handleComplete,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { t } = useTranslate(["Categories", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const formId = useMemo(() => uniqueId(), []);

  const initialValues: FormValues = {
    skillset,
  };

  const handleSubmit = async (values: FormValues) => {
    if (isSubmitting) return;
    setSubmitting(true);
    try {
      await handleComplete(values.skillset);
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        skillset: Yup.array()
          .label(t("Services", { ns: "Categories" }))
          .required(),
      }),
    [t]
  );

  return (
    <Modal
      title={title ?? t("Edit services", { ns: "Categories" })}
      dialogProps={{ maxWidth: "xs" }}
      actionButtons={[
        {
          label: t("Cancel", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: { variant: "text", disabled: isSubmitting },
        },
        {
          label: t("Save", { ns: "Global" }),
          buttonProps: {
            type: "submit",
            form: formId,
            loading: isSubmitting,
            disabled: isSubmitting,
          },
        },
      ]}
      handleClose={handleClose}
    >
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => (
          <Form id={formId}>
            <CategoriesField
              name="skillset"
              label={t("Services", { ns: "Categories" })}
              disabled={false}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
