/* eslint-disable react-hooks/rules-of-hooks */
import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import React from "react";
import { useExpandedStoreWithComponentState } from "../../trees-virtual/hooks/useExpandedStore.js";
import {
  DecisionsInBulkProcess,
  DecisionsInBulkProcessRef,
} from "../doc-items/modals/DecisionsInBulkProcess.js";
import { useQuoteDecisionsInBulkProcessLazyQuery } from "./QuoteDecisionsInBulkProcess.generated.js";

interface Props
  extends Omit<
    React.ComponentProps<typeof DecisionsInBulkProcess>,
    | "projectId"
    | "docType"
    | "docId"
    | "allDocItems"
    | "fetchItems"
    | "isLoading"
    | "expandedStore"
  > {
  projectId: string;
  quoteId: string;
}

export const QuoteDecisionsInBulkProcess = React.forwardRef(
  _QuoteDecisionsInBulkProcess
);

function _QuoteDecisionsInBulkProcess(
  { projectId, quoteId, ...rest }: Props,
  ref: React.ForwardedRef<DecisionsInBulkProcessRef>
) {
  const expandedStore = useExpandedStoreWithComponentState();

  const client = useApolloClient();
  const [fetchData, query] = useQuoteDecisionsInBulkProcessLazyQuery({
    client,
    variables: { projectId, quoteId },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const fetchItems = async () => {
    const result = await fetchData();
    return getDataOrNull(result.data?.quote)?.quote?.items ?? [];
  };

  return (
    <DecisionsInBulkProcess
      ref={ref}
      projectId={projectId}
      docType="QUOTE"
      docId={quoteId}
      fetchItems={fetchItems}
      isLoading={query.loading}
      expandedStore={expandedStore}
      {...rest}
    />
  );
}
