import { useApolloClient } from "@apollo/client";
import { Capacitor } from "@capacitor/core";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDataOrNull } from "@msys/common";
import {
  Attachment,
  MenuButton,
  MenuItemWithIcon,
  useDebouncedValue,
} from "@msys/ui";
import {
  Category as CategoryIcon,
  FindInPageOutlined as FindInPageOutlinedIcon,
  Link as LinkIcon,
  PhotoCamera as PhotoCameraIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Icon,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { useTranslate } from "@tolgee/react";
import { flatten, omit, partition, uniq } from "lodash-es";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useUpdateEffect } from "react-use";
import {
  DocType,
  ItemType,
  TplApplicableFor,
} from "../../../clients/graphqlTypes.js";
import { size } from "../../../common/MuiThemeProvider.js";
import { useUserData } from "../../auth/useUserData.js";
import { Stack } from "../../commons/layout/Stack.js";
import { transientOptions } from "../../styles.js";
import { TreeViewMode, VirtualItem } from "../../trees-virtual/types.js";
import {
  AttachmentPhotoUploaderWithEditor,
  AttachmentPhotoUploaderWithEditorRef,
} from "../attachments/AttachmentPhotoUploaderWithEditor.js";
import { useAddItemAttachmentsMutation } from "../attachments/Attachments.generated.js";
import { AttachmentUploader } from "../attachments/AttachmentUploader.js";
import { TemplateIcon } from "../templates/TemplateIcon.js";
import { getDefaultCreatableSubItemTypes } from "./constraints.js";
import {
  useCreateItemAutoCompleteProductsLazyQuery,
  useCreateItemAutoCompleteTemplatesLazyQuery,
} from "./CreateItemInput.generated.js";
import {
  CreateItemArgs,
  CreateItemByTypeArgs,
  CreateItemFromTemplateArgs,
  CreateItemsFromTemplatesArgs,
  CreateItemsWithProductsArgs,
  CreateItemWithLinkedTemplateArgs,
} from "./hooks/useCreateItem.js";
import {
  itemTypeIcons,
  useItemTypeCreateLabels,
} from "./hooks/useItemTypes.js";
import { SearchItemInputModal } from "./SearchItemInputModal.js";
import { SearchTab } from "./SearchProductsAndTemplates.js";

const SEARCH_PRODUCTS_OPTION_ID = "search_products";
const SEARCH_TEMPLATES_OPTION_ID = "search_templates";
const PASTE_OPTION_ID = "paste_from_clipboard";
const TEMPLATE_OPTION_ID = "insert_from_template";

type OptionType<
  IsHeader extends boolean = false,
  IsText extends boolean = false,
  IsTemplate extends boolean = false,
  IsProduct extends boolean = false,
> = {
  isHeader?: IsHeader;
  isText?: IsText;
  isTemplate?: IsTemplate;
  isProduct?: IsProduct;
  title: string;
  Title?: React.ReactNode;
  hint?: string;
  icon?: React.ReactNode;
  inputValue?: string;
};

type ItemTypeInputOption = OptionType & {
  id: ItemType;
};
type SearchProductsOption = OptionType & {
  id: typeof SEARCH_PRODUCTS_OPTION_ID;
};
type SearchTemplatesOption = OptionType & {
  id: typeof SEARCH_TEMPLATES_OPTION_ID;
};
type PasteOption = OptionType & {
  id: typeof PASTE_OPTION_ID;
};
type TemplateOption = OptionType & {
  id: typeof TEMPLATE_OPTION_ID;
};
type HeaderInputOption = OptionType<true> & {
  id: string;
  isLoading: boolean;
};
type TextInputOption = OptionType<false, true> & {
  id: string;
};
type TemplateInputOption = OptionType<false, false, true> & {
  id: string;
};
type ProductInputOption = OptionType<false, false, false, true> & {
  id: string;
};

type InputOption =
  | ItemTypeInputOption
  | SearchProductsOption
  | SearchTemplatesOption
  | PasteOption
  | TemplateOption
  | HeaderInputOption
  | TextInputOption
  | TemplateInputOption
  | ProductInputOption;

interface Props<TItem extends VirtualItem, TAllDocItem extends VirtualItem> {
  viewMode?: TreeViewMode;
  id?: string;
  doc: {
    id: string;
    docType: DocType;
    templateIsDraft: boolean;
  };
  item: TItem;
  isRootItem: boolean;
  itemChildren: Array<TAllDocItem>;
  nodeRank: number | null;
  autoFocus: boolean;
  documentItemTypes: ItemType[];
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
  resetFocus?: () => Promise<void> | void;
  label?: string;
  createItem: (args: CreateItemArgs) => Promise<{ id: string }>;
  createItemByType: (args: CreateItemByTypeArgs) => Promise<{ id: string }>;
  createItemFromTemplate:
    | ((
        args: CreateItemFromTemplateArgs
      ) => Promise<{ id: string } | null | undefined>)
    | null;
  createItemsFromTemplates:
    | ((
        args: CreateItemsFromTemplatesArgs
      ) => Promise<Array<{ id: string }> | null | undefined>)
    | null;
  createItemsWithProducts:
    | ((
        args: CreateItemsWithProductsArgs
      ) => Promise<Array<{ id: string }> | null | undefined>)
    | null;
  createItemWithLinkedTemplate?: (
    args: CreateItemWithLinkedTemplateArgs
  ) => Promise<void | null>;
  pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
  isAllowedToPasteItem: (parentItem: TItem) => boolean;
}

export const CreateItemInput = <
  TItem extends VirtualItem,
  TAllDocItem extends VirtualItem,
>({
  viewMode = TreeViewMode.Item,
  id,
  doc,
  item,
  isRootItem = false,
  autoFocus = true,
  nodeRank,
  onFocus,
  resetFocus,
  itemChildren,
  documentItemTypes,
  label: passedLabel,
  createItem,
  createItemByType,
  createItemFromTemplate,
  createItemWithLinkedTemplate,
  createItemsFromTemplates,
  createItemsWithProducts,
  pasteItem,
  isAllowedToPasteItem,
}: Props<TItem, TAllDocItem>) => {
  const { id: docId, docType } = doc;

  const viewer = useUserData().currentUser!;

  const { t } = useTranslate(["ItemTree", "QuoteCreate"]);

  const [inputValue, setInputActualValue] = useState<string>("");
  const inputValueDebounced = useDebouncedValue(inputValue, 500);

  const [creatingItem, setCreatingItem] = useState<boolean>(false);

  const setInputValue = (value: string) => {
    if (creatingItem) return;
    setInputActualValue(value);
  };

  const canPasteItem = isAllowedToPasteItem(item);

  const areAnyTemplatesAllowed =
    ["section", "decision"].includes(item.type) &&
    documentItemTypes.includes("section") &&
    viewer.organisation.capabilities.includes("TEMPLATING") &&
    viewer.organisationPermissions.includes("APPLY_TEMPLATE");

  const areAnyProductsAllowed =
    ["section", "decision"].includes(item.type) &&
    documentItemTypes.includes("paid") &&
    viewer.organisation.capabilities.includes("PIM");

  const allowTemplatesSearch =
    (docType === "REQUIREMENT" ||
      docType === "QUOTE" ||
      docType === "TEMPLATE") &&
    areAnyTemplatesAllowed;

  const allowTemplatesInDropdown =
    (docType === "REQUIREMENT" ||
      docType === "QUOTE" ||
      docType === "TEMPLATE" ||
      docType === "BUILDING") &&
    areAnyTemplatesAllowed;

  const allowProductsSearch =
    (docType === "QUOTE" || docType === "TEMPLATE") && areAnyProductsAllowed;

  const allowProductsInDropdown =
    (docType === "QUOTE" || docType === "TEMPLATE") && areAnyProductsAllowed;

  const applicableFor = docType !== "TEMPLATE" ? [docType] : undefined;

  const defaultLabel = isRootItem
    ? (
        {
          section: t("Add item", { ns: "ItemTree" }),
          decision: t("Add decision option", { ns: "ItemTree" }),
          unpaid: t("Add subtask", { ns: "ItemTree" }),
          paid: t("Add task", { ns: "ItemTree" }),
          defect: t("Add subtask", { ns: "ItemTree" }),
        } as Record<ItemType, string>
      )[item.type]
    : t(`Add to {itemNumber} {itemTitle}`, {
        ns: "ItemTree",
        itemNumber: item.pathForPdf,
        itemTitle: item.title,
      });

  const label = passedLabel ?? defaultLabel;

  const { defaultCreatableSubItemTypes, subItemTypesOptions } =
    useCreateItemSubItemTypeOptions({
      docType,
      documentItemTypes,
      isDraftTemplate: doc.templateIsDraft,
      item,
      isRootItem,
      itemChildren,
      areAnyTemplatesAllowed,
    });

  const {
    templateSearchResults,
    searchingTemplates,
    productSearchResults,
    searchingProducts,
  } = useCreateItemAutoCompleteSearch({
    inputValueDebounced,
    docId: doc.id,
    applicableFor,
    allowTemplates: allowTemplatesInDropdown,
    allowProducts: allowProductsInDropdown,
  });

  const autoCompleteOptions: Readonly<Array<InputOption>> = [
    ...templateSearchResults.map(e => ({
      id: e.id,
      title: e.title,
      icon: <LinkIcon style={{ flexGrow: 0, flexShrink: 0 }} />,
      inputValue: t("Adding template...", {
        ns: "ItemTree",
      }),
      isTemplate: true as const,
    })),
    ...productSearchResults.map(e => ({
      id: e.id,
      title: e.texts?.title ?? "",
      hint: e.articleNumber ?? "",
      icon: <CategoryIcon style={{ flexGrow: 0, flexShrink: 0 }} />,
      inputValue: t("Adding product...", {
        ns: "ItemTree",
      }),
      isProduct: true as const,
    })),
  ].sort((a, b) => -b.title.localeCompare(a.title));

  const pasteOptions = React.useMemo(() => {
    return [
      {
        id: PASTE_OPTION_ID,
        inputValue: t("Pasting subitems...", {
          ns: "ItemTree",
        }),
        title: t("Paste as subitem", {
          ns: "ItemTree",
        }),
        icon: (
          <Icon>
            <FontAwesomeIcon icon={faClipboard} />
          </Icon>
        ),
      },
    ];
  }, [t]);

  const [searchModalState, setSearchModalState] = React.useState<{
    searchValue: string;
    searchTab: SearchTab;
    resolve: (value?: any) => void;
  } | null>(null);

  const searchProductsOptions = React.useMemo(() => {
    return [
      {
        id: SEARCH_PRODUCTS_OPTION_ID,
        inputValue: t("Adding products...", {
          ns: "ItemTree",
        }),
        title: !inputValue
          ? t("Search products", {
              ns: "ItemTree",
            })
          : `${t("Search products", {
              ns: "ItemTree",
            })}: “${inputValue}”`,
        icon: <SearchIcon style={{ flexGrow: 0, flexShrink: 0 }} />,
      },
    ];
  }, [t, inputValue]);

  const searchTemplatesOptions = React.useMemo(() => {
    return [
      {
        id: SEARCH_TEMPLATES_OPTION_ID,
        inputValue: t("Adding templates...", {
          ns: "ItemTree",
        }),
        title: !inputValue
          ? t("Search templates", {
              ns: "ItemTree",
            })
          : `${t("Search templates", {
              ns: "ItemTree",
            })}: “${inputValue}”`,
        icon: <FindInPageOutlinedIcon style={{ flexGrow: 0, flexShrink: 0 }} />,
      },
    ];
  }, [t, inputValue]);

  const newItemRank = nodeRank ? Math.ceil(nodeRank) : null;

  return (
    <CreateItemContainer $viewMode={viewMode}>
      <Autocomplete
        id={id}
        style={{ flexGrow: 1 }}
        freeSolo
        openOnFocus
        selectOnFocus
        clearOnBlur={false}
        handleHomeEndKeys
        disableClearable
        autoHighlight
        blurOnSelect
        value={""} // it's important to keep it "" - it'll prevent from changing highlighted option when getting templates/products query finishes
        disabled={creatingItem}
        readOnly={creatingItem}
        loading={creatingItem}
        inputValue={inputValue}
        options={autoCompleteOptions}
        getOptionDisabled={option =>
          Boolean(option.isHeader) || Boolean(option.isText)
        }
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        filterOptions={(options, params) => {
          if (params.inputValue === "") {
            return [
              ...(allowProductsSearch ? searchProductsOptions : []),
              ...(allowTemplatesSearch ? searchTemplatesOptions : []),
              ...(canPasteItem ? pasteOptions : []),
            ];
          }

          const autoCompleteTemplateOptions = options.filter(o => o.isTemplate);
          const autoCompleteProductOptions = options.filter(o => o.isProduct);

          const areAnyTemplatesLoading =
            searchingTemplates || inputValueDebounced !== params.inputValue;

          const areProductsLoading =
            searchingProducts || inputValueDebounced !== params.inputValue;

          return [
            ...subItemTypesOptions,
            ...(allowProductsSearch ? searchProductsOptions : []),
            ...(allowTemplatesSearch ? searchTemplatesOptions : []),
            ...(allowTemplatesInDropdown &&
            params.inputValue !== "" &&
            !creatingItem
              ? [
                  {
                    id: "templates-header",
                    isHeader: true as const,
                    title: t("Templates", {
                      ns: "ItemTree",
                    }),
                    isLoading: areAnyTemplatesLoading,
                    hint:
                      !areAnyTemplatesLoading &&
                      autoCompleteTemplateOptions.length === 0
                        ? t("Nothing found", {
                            ns: "ItemTree",
                          })
                        : undefined,
                  },
                  ...(!areAnyTemplatesLoading &&
                  autoCompleteTemplateOptions.length > 0
                    ? autoCompleteTemplateOptions
                    : []),
                ]
              : []),
            ...(allowProductsInDropdown &&
            params.inputValue !== "" &&
            !creatingItem
              ? [
                  {
                    id: "products-header",
                    isHeader: true as const,
                    title: t("Products", {
                      ns: "ItemTree",
                    }),
                    isLoading: areProductsLoading,
                    hint:
                      !areProductsLoading &&
                      autoCompleteProductOptions.length === 0
                        ? t("Nothing found", {
                            ns: "ItemTree",
                          })
                        : undefined,
                  },
                  ...(!areProductsLoading &&
                  autoCompleteProductOptions.length > 0
                    ? autoCompleteProductOptions
                    : []),
                ]
              : []),
          ];
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.isHeader ? (
                <HeaderOption option={option} />
              ) : option.isText ? (
                <TextOption option={option} />
              ) : (
                <Option option={option} />
              )}
            </li>
          );
        }}
        onInputChange={(e, v) => {
          setInputValue(v);
        }}
        onChange={async (e, value) => {
          e.preventDefault();
          e.stopPropagation();

          if (creatingItem) return;
          setCreatingItem(true);

          try {
            if (typeof value === "string") {
              if (defaultCreatableSubItemTypes.length > 0) {
                await createItem({
                  type: defaultCreatableSubItemTypes[0],
                  title: inputValue,
                  docId: doc.id,
                  parentItemId: item.id,
                  newItemRank,
                });
              }
            } else if (value?.id === SEARCH_PRODUCTS_OPTION_ID) {
              await new Promise(resolve => {
                setSearchModalState({
                  searchValue: inputValue,
                  searchTab: "products",
                  resolve,
                });
              });
            } else if (value?.id === SEARCH_TEMPLATES_OPTION_ID) {
              await new Promise(resolve => {
                setSearchModalState({
                  searchValue: inputValue,
                  searchTab: "templates",
                  resolve,
                });
              });
            } else if (
              value?.id === TEMPLATE_OPTION_ID &&
              createItemWithLinkedTemplate
            ) {
              // creating a new template and immediately linking it
              await createItemWithLinkedTemplate({
                title: inputValue,
                parentItemId: item.id,
                newItemRank,
              });
            } else if (value?.id === PASTE_OPTION_ID && pasteItem) {
              await pasteItem(docId, item.id);
            } else if (value?.isTemplate && createItemFromTemplate) {
              await createItemFromTemplate({
                title: value.title,
                parentItemId: item.id,
                templateQuoteId: value.id,
                newItemRank,
              });
            } else if (value?.isProduct) {
              const product = productSearchResults.find(
                product => product.id === value.id
              );
              if (!product) return;
              await createItem({
                type: "paid",
                title: value.title,
                docId: doc.id,
                parentItemId: item.id,
                product,
                newItemRank,
              });
            } else if (isItemTypeOption(value)) {
              await createItemByType({
                itemType: value.id,
                docId: doc.id,
                parentItemId: item.id,
                title: inputValue,
                newItemRank,
              });
            } else {
              throw new Error("Invalid input option");
            }
            await resetFocus?.();
          } catch (e) {
            console.error(e);
            Sentry.captureException(e);
          } finally {
            setCreatingItem(false);
            setInputActualValue("");
          }
        }}
        renderInput={params => (
          <TextField
            onFocus={onFocus}
            autoFocus={autoFocus}
            {...params}
            // id={`${id}-input`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {creatingItem ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </InputAdornment>
              ),
            }}
            inputProps={{ ...params.inputProps, [`data-node-rank`]: nodeRank }}
            label={viewMode === TreeViewMode.Item ? label : undefined}
            placeholder={viewMode === TreeViewMode.Table ? label : undefined}
            variant={viewMode === TreeViewMode.Table ? "standard" : "filled"}
          />
        )}
      />
      {viewMode === TreeViewMode.Item && (
        <CreateItemByPhoto
          disabled={creatingItem}
          onIsUploadingChanged={(isUploading: boolean) => {
            setCreatingItem(isUploading);
          }}
          itemTypes={defaultCreatableSubItemTypes}
          createItem={async itemType => {
            try {
              setCreatingItem(true);
              return createItemByType({
                itemType,
                docId: doc.id,
                parentItemId: item.id,
                newItemRank,
              });
            } catch (e) {
              console.error(e);
            } finally {
              setCreatingItem(false);
            }
          }}
        />
      )}
      {searchModalState &&
        createItemsWithProducts &&
        createItemsFromTemplates && (
          <SearchItemInputModal
            initialSearchValue={searchModalState.searchValue}
            initialSearchTab={searchModalState.searchTab}
            handleClose={async () => {
              setSearchModalState(null);
              searchModalState.resolve();
            }}
            excludeTemplateIds={[docId]}
            handleSelect={async ({ products, templates }) => {
              try {
                if (products.length > 0)
                  await createItemsWithProducts({
                    parentItemId: item.id,
                    products,
                    newItemRank,
                  });
                if (templates.length > 0)
                  await createItemsFromTemplates({
                    parentItemId: item.id,
                    templates,
                    newItemRank,
                  });
              } catch (e) {
                console.error(e);
                Sentry.captureException(e);
              } finally {
                searchModalState.resolve();
              }
            }}
          />
        )}
    </CreateItemContainer>
  );
};

function useCreateItemSubItemTypeOptions<
  TItem extends VirtualItem,
  TAllDocItem extends VirtualItem,
>({
  docType,
  documentItemTypes,
  isDraftTemplate,
  item,
  isRootItem,
  itemChildren,
  areAnyTemplatesAllowed,
}: {
  docType: DocType;
  documentItemTypes: ItemType[];
  isDraftTemplate: boolean;
  item: TItem;
  isRootItem: boolean;
  itemChildren: TAllDocItem[];
  areAnyTemplatesAllowed: boolean;
}) {
  const { t } = useTranslate(["ItemTree"]);

  const itemOptionLabels = useItemTypeCreateLabels();
  const itemInputValues = React.useMemo(
    (): Record<ItemType, string> => ({
      section: t("Adding section...", {
        ns: "ItemTree",
      }),
      paid: t("Adding paid...", {
        ns: "ItemTree",
      }),
      unpaid: t("Adding unpaid...", {
        ns: "ItemTree",
      }),
      defect: t("Adding defect...", {
        ns: "ItemTree",
      }),
      decision: t("Adding decision...", {
        ns: "ItemTree",
      }),
    }),
    [t]
  );

  const defaultCreatableSubItemTypes: ItemType[] = React.useMemo(() => {
    const creatableSubItemTypes =
      "linkedQuoteTemplate" in item && Boolean(item.linkedQuoteTemplate)
        ? []
        : getDefaultCreatableSubItemTypes(item.type, documentItemTypes);
    const defaultItemType = getDefaultItemType(item, isRootItem, itemChildren);

    return flatten(
      partition(creatableSubItemTypes, type => type === defaultItemType)
    );
  }, [item, documentItemTypes, isRootItem, itemChildren]);

  const subItemTypesOptions = React.useMemo(() => {
    const subItemTypesOptions = [];

    // Suggest the creation of a new value
    const itemTypeOptions = defaultCreatableSubItemTypes.map(
      creatableSubItemType => {
        switch (creatableSubItemType) {
          case "section":
          case "unpaid":
          case "decision":
          case "defect":
          case "paid":
            return {
              id: creatableSubItemType,
              inputValue: itemInputValues[creatableSubItemType],
              title: itemOptionLabels[creatableSubItemType],
              icon: itemTypeIcons[creatableSubItemType],
            };
          default:
            throw new Error("Unknown item type: " + creatableSubItemType);
        }
      }
    );

    subItemTypesOptions.push(...itemTypeOptions);

    if (areAnyTemplatesAllowed && docType === "TEMPLATE" && isDraftTemplate)
      subItemTypesOptions.push({
        inputValue: t("Adding template...", {
          ns: "ItemTree",
        }),
        title: t("Add Template", {
          ns: "ItemTree",
        }),
        id: TEMPLATE_OPTION_ID,
        icon: (
          <TemplateIcon
            color="secondary"
            style={{ flexGrow: 0, flexShrink: 0 }}
          />
        ),
      });

    return subItemTypesOptions;
  }, [
    defaultCreatableSubItemTypes,
    areAnyTemplatesAllowed,
    docType,
    isDraftTemplate,
    t,
    itemInputValues,
    itemOptionLabels,
  ]);

  return { defaultCreatableSubItemTypes, subItemTypesOptions };
}

function useCreateItemAutoCompleteSearch({
  inputValueDebounced,
  docId,
  applicableFor,
  allowTemplates,
  allowProducts,
}: {
  inputValueDebounced: string;
  docId: string;
  applicableFor: TplApplicableFor[] | undefined;
  allowTemplates: boolean;
  allowProducts: boolean;
}) {
  const { t } = useTranslate(["ItemTree"]);

  const client = useApolloClient();
  const [fetchQuoteTemplates, templatesQuery] =
    useCreateItemAutoCompleteTemplatesLazyQuery({
      client,
      fetchPolicy: "no-cache",
    });
  const searchingTemplates = templatesQuery.loading;
  const templateSearchResults =
    getDataOrNull(templatesQuery.data?.quoteTemplates)?.edges.map(
      edge => edge.node
    ) ?? [];

  const [fetchProducts, productsQuery] =
    useCreateItemAutoCompleteProductsLazyQuery({
      client,
      fetchPolicy: "no-cache",
      context: { noTracking: true },
    });
  const searchingProducts = productsQuery.loading;
  const productSearchResults =
    getDataOrNull(productsQuery.data?.pimSearchProducts)?.edges.map(
      edge => edge.node
    ) ?? [];

  useUpdateEffect(() => {
    if (
      !inputValueDebounced ||
      (
        [
          t("Adding section...", {
            ns: "ItemTree",
          }),
          t("Adding paid...", {
            ns: "ItemTree",
          }),
          t("Adding unpaid...", {
            ns: "ItemTree",
          }),
          t("Adding defect...", {
            ns: "ItemTree",
          }),
          t("Adding decision...", {
            ns: "ItemTree",
          }),
          t("Adding checklist...", {
            ns: "ItemTree",
          }),
          t("Adding decision...", {
            ns: "ItemTree",
          }),
          t("Adding template...", {
            ns: "ItemTree",
          }),
          t("Adding templates...", {
            ns: "ItemTree",
          }),
          t("Adding building template...", {
            ns: "ItemTree",
          }),
          t("Adding base template...", {
            ns: "ItemTree",
          }),
          t("Adding product...", {
            ns: "ItemTree",
          }),
          t("Adding products...", {
            ns: "ItemTree",
          }),
        ] as string[]
      ).includes(inputValueDebounced)
    )
      return;

    if (allowTemplates)
      fetchQuoteTemplates({
        variables: {
          searchTerm: inputValueDebounced,
          applicableFor,
          excludeTemplateId: docId,
        },
      });
    if (allowProducts)
      fetchProducts({
        variables: {
          searchTerm: inputValueDebounced,
        },
      });
  }, [inputValueDebounced]);

  return {
    searchingTemplates,
    templateSearchResults,
    searchingProducts,
    productSearchResults,
  };
}

const ALL_ITEM_TYPES: ItemType[] = [
  "paid",
  "section",
  "decision",
  "unpaid",
  "defect",
];

function isItemTypeOption(option: {
  id: string | ItemType;
}): option is ItemTypeInputOption {
  return ALL_ITEM_TYPES.includes(option.id as ItemType);
}

function getDefaultItemType<
  TItem extends VirtualItem,
  TChildren extends VirtualItem,
>(item: TItem, isRootItem: boolean, children: TChildren[]): ItemType {
  // 1. The default item type on the top level is SECTION,
  if (isRootItem) {
    return "section";
  }

  // 2. The default item type on any level lower is PAID ITEM IFF no siblings have been created.
  if (children.length === 0) {
    return "paid";
  }

  // 3. When a sibling has been created on a level, then the default item type for new siblings is the SAME ITEM TYPE
  const childrenTypes = uniq(children.map(s => s.type));
  if (childrenTypes.length === 1) {
    return childrenTypes[0];
  }

  return "paid";
}

const CreateItemContainer = styled(
  "div",
  transientOptions
)<{ $viewMode: TreeViewMode }>(
  ({ $viewMode }) => `
  display: flex;
  align-items: center;

  width: 100%;
  height: ${
    $viewMode === TreeViewMode.Table
      ? size.tableItemFullHeight
      : size.treeItemHeight
  }px;

  position: relative;
  padding-left: ${$viewMode === TreeViewMode.Table ? 38 : 1}px;
  padding-right: 4px;

  ${
    $viewMode === TreeViewMode.Table
      ? `
        & .MuiAutocomplete-inputRoot {
          padding-top: 1px;
        }
      `
      : `
        top: -1px;
      `
  }

  .MuiAutocomplete-root > .MuiFormControl-root {
    overflow: hidden;
  }
  .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputLabel-root {
    white-space: nowrap;
  }
`
);

const CreateItemByPhoto = ({
  disabled,
  onIsUploadingChanged,
  createItem,
  itemTypes,
  onAddPhotoRefetchQueries,
}: {
  disabled?: boolean;
  onIsUploadingChanged: (isUploading: boolean) => void;
  itemTypes: ItemType[];
  createItem: (itemType: ItemType) => Promise<{ id: string } | undefined>;
  onAddPhotoRefetchQueries?: string[];
}) => {
  const { t } = useTranslate("FilesBoxTable");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [add, { loading: loadingAdd }] = useAddItemAttachmentsMutation({
    client,
  });

  const itemOptionLabels = useItemTypeCreateLabels();

  const attachmentFieldRef = useRef<HTMLInputElement>(null);
  const attachmentPhotoUploaderRef =
    React.useRef<AttachmentPhotoUploaderWithEditorRef>(null);

  const [itemType, setItemType] = useState<ItemType>("section");
  const handleItemTypeSelect = (newItemType: ItemType) => {
    if (disabled || loadingAdd) return;

    setItemType(newItemType);

    if (Capacitor.isNativePlatform())
      attachmentPhotoUploaderRef.current?.start();
    else attachmentFieldRef.current?.click();
  };

  const createItemAndAddAttachments = async (attachments: Attachment[]) => {
    try {
      const item = await createItem(itemType);
      if (!item) return;
      await add({
        variables: {
          attachments: attachments.map(a => omit(a, "id", "__type")),
          itemId: item.id,
        },
        ...(onAddPhotoRefetchQueries
          ? {
              refetchQueries: onAddPhotoRefetchQueries,
              awaitRefetchQueries: true,
            }
          : undefined),
      });
      if (!onAddPhotoRefetchQueries) await client.reFetchObservableQueries();
      enqueueSnackbar(
        attachments.length > 1 ? t("Files uploaded") : t("File uploaded")
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <>
      <MenuButton
        Icon={<PhotoCameraIcon style={{ width: 26, height: 26 }} />}
        menuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        buttonProps={{
          size: "small",
          color: "secondary",
          style: { marginLeft: 4, width: 40, height: 40 },
          disabled: disabled || loadingAdd,
        }}
      >
        {itemTypes.map((itemType, idx) => {
          return (
            <MenuItemWithIcon
              key={idx}
              onClick={() => handleItemTypeSelect(itemType)}
              icon={itemTypeIcons[itemType]}
            >
              {itemOptionLabels[itemType]}
            </MenuItemWithIcon>
          );
        })}
      </MenuButton>
      {Capacitor.isNativePlatform() ? (
        <AttachmentPhotoUploaderWithEditor
          ref={attachmentPhotoUploaderRef}
          onStart={() => onIsUploadingChanged(true)}
          onError={() => onIsUploadingChanged(false)}
          onAttachment={async attachment => {
            try {
              await createItemAndAddAttachments([attachment]);
            } catch (error) {
              throw error;
            } finally {
              onIsUploadingChanged?.(false);
            }
          }}
        />
      ) : (
        <AttachmentUploader
          innerRef={attachmentFieldRef}
          accept="*"
          multiple={true}
          onStart={() => onIsUploadingChanged?.(true)}
          onError={() => onIsUploadingChanged?.(false)}
          onCancel={() => onIsUploadingChanged?.(false)}
          onComplete={async attachments => {
            try {
              await createItemAndAddAttachments(attachments);
            } catch (error) {
              throw error;
            } finally {
              onIsUploadingChanged?.(false);
            }
          }}
        />
      )}
    </>
  );
};

function HeaderOption({ option }: { option: HeaderInputOption }) {
  const theme = useTheme();

  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      style={{ marginTop: 4 }}
    >
      <Typography variant="caption" style={{ color: theme.palette.grey[900] }}>
        {option.Title ?? option.title}
      </Typography>
      {option.isLoading ? (
        <Box
          width={14}
          height={14}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress
            size={14}
            style={{
              color: theme.palette.grey[900],
            }}
          />
        </Box>
      ) : option.hint ? (
        <Typography
          variant="caption"
          style={{ color: theme.palette.grey[900] }}
        >
          {option.hint}
        </Typography>
      ) : null}
    </Stack>
  );
}

function TextOption({ option }: { option: TextInputOption }) {
  const theme = useTheme();

  return (
    <Stack alignItems="center">
      {option.icon}
      <Typography variant="body1" style={{ color: theme.palette.grey[900] }}>
        {option.Title ?? option.title}
      </Typography>
    </Stack>
  );
}

function Option({ option }: { option: InputOption }) {
  const theme = useTheme();

  return (
    <Stack alignItems="center" width="100%">
      {option.icon}
      <Typography variant="body1" style={{ flex: 1 }}>
        {option.Title ?? option.title}
      </Typography>
      {option.hint ? (
        <Typography
          variant="caption"
          style={{ color: theme.palette.grey[900] }}
        >
          {option.hint}
        </Typography>
      ) : null}
    </Stack>
  );
}
