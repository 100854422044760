import { question } from "@msys/question-isomorphic";
import { ModalContent, ModalDialog, ModalTitle } from "@msys/ui";
import { useUserData } from "../../../auth/useUserData.js";
import { QuoteCreateWizardSection } from "../QuoteCreateWizardSection.js";

export function QuoteCreateWizardModal({
  organisationId,
  templateId,
  onClose,
  onComplete,
}: {
  organisationId: string;
  templateId: string;
  onClose: () => void;
  onComplete: (
    result: Array<question.AnsweredQuestion>
  ) => Promise<void> | void;
}) {
  const viewer = useUserData().currentUser!;

  return (
    <ModalDialog handleClose={onClose}>
      <ModalTitle />
      <ModalContent>
        <QuoteCreateWizardSection
          onComplete={onComplete}
          onClose={onClose}
          wizardConfiguration={{
            showCardImage: viewer.organisation.isCraftsmanOrganisation
              ? "false"
              : "true",
            navigationType: null,
            questionStrategy: {
              type: "questionControl",
              askWhen: "onQuoteCreate",
              askWhom: "contractor",
              startingNode: { type: "root" },
            },
            requestInitialAnswers: "false",
            sourceDoc: {
              type: "template",
              templateId,
              contractorId: organisationId,
            },
            showCloseButton: "true",
            showSkipWizardButton: "true",
          }}
          initialAnswers={[]}
        />
      </ModalContent>
    </ModalDialog>
  );
}
