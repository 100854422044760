/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type InviteContracteeField_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName> };

export const InviteContracteeField_ProjectFragmentDoc = gql`
    fragment InviteContracteeField_Project on Project {
  id
  viewerPermissions
}
    `;