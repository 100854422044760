import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LoadingSpinner, Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { BuildingSelectModal } from "../../buildings/modals/BuildingSelectModal.js";
import {
  BuildingSelectModal_BuildingFragment,
  BuildingSelectModal_ItemFragment,
} from "../../buildings/modals/BuildingSelectModal.generated.js";
import {
  useProjectAssignBuildingModalQuery,
  useProjectAssignBuildingMutation,
} from "./ProjectAssignBuildingModal.generated.js";

type BuildingSelectModalProps = React.ComponentProps<
  typeof BuildingSelectModal
>;
interface Props
  extends Omit<
    BuildingSelectModalProps,
    "title" | "primaryActionLabel" | "primaryActionDisabled" | "handleComplete"
  > {
  projectId: string;
  handleComplete?: (
    buildingId: string,
    buildingItemId: string
  ) => Promise<void> | void;
  refetchQueries?: string[];
}

export function ProjectAssignBuildingModal({
  projectId,
  buildingOwningOrganisationId,
  hidePlacementSelect,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["BuildingSelectBox", "Global"]);

  const client = useApolloClient();
  const query = useProjectAssignBuildingModalQuery({
    client,
    variables: { projectId },
  });

  const project = getDataOrNull(query.data?.project)?.project;

  const [assignBuilding, { loading: modifyProjectLoading }] =
    useProjectAssignBuildingMutation({ client });

  const onComplete = React.useCallback(
    async (
      building: Omit<BuildingSelectModal_BuildingFragment, "items">,
      buildingItem: BuildingSelectModal_ItemFragment
    ) => {
      try {
        await assignBuilding({
          variables: {
            input: {
              projectId,
              buildingItemId: buildingItem.id,
            },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        });
        await handleComplete?.(building.id, buildingItem.id);
        handleClose();
        enqueueSnackbar(t("Building assigned", { ns: "BuildingSelectBox" }));
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [
      assignBuilding,
      enqueueSnackbar,
      handleClose,
      handleComplete,
      projectId,
      refetchQueries,
      t,
    ]
  );

  if (query.loading && !project) {
    return (
      <Modal handleClose={() => {}}>
        <LoadingSpinner />
      </Modal>
    );
  }

  return (
    <BuildingSelectModal
      id="project-assign-building-modal"
      title={t("Assign building", {
        ns: "BuildingSelectBox",
      })}
      buildingOwningOrganisationId={buildingOwningOrganisationId}
      hidePlacementSelect={hidePlacementSelect}
      primaryActionLabel={t("Assign", {
        ns: "BuildingSelectBox",
      })}
      primaryActionDisabled={modifyProjectLoading}
      canCreateBuilding={true}
      preselectedBuildingId={project?.buildingItem?.docId}
      preselectedBuildingItemId={project?.buildingItem?.id}
      handleClose={handleClose}
      handleComplete={onComplete}
    />
  );
}
