import { useApolloClient } from "@apollo/client";
import { Check as CheckIcon } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { LoginButton } from "../auth/LoginButton.js";
import { RegisterButton } from "../auth/RegisterButton.js";
import { AvailableUser, SelectUserList } from "../auth/SelectUserList.js";
import { useSelectedUser } from "../auth/useSelectedUser.js";
import { SELECTED_USER_HTTP_HEADER } from "../constants.js";
import { OrganisationAvatar } from "../features/organisations/OrganisationAvatar.js";
import { ButtonDivider } from "./ButtonDivider.js";
import { type RefTokenQuery } from "./InvitationTokenProvider.generated.js";
import { useAcceptProjectContracteeInvitationMutation } from "./ProjectContracteeInvitation.generated.js";
import { useInvitationToken } from "./useInvitationToken.js";

type Props = {
  invitation: Exclude<
    RefTokenQuery["projectContracteeInvitation"],
    null | undefined
  >;
} & (
  | {
      isAuthenticated: false;
      availableUsers?: void;
      availableUsersIsLoading?: void;
    }
  | {
      isAuthenticated: true;
      availableUsers: AvailableUser[];
      availableUsersIsLoading: boolean;
    }
);

export const ProjectContracteeInvitation = ({
  invitation,
  isAuthenticated,
  availableUsers,
  availableUsersIsLoading,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const { setSelectedUserId } = useSelectedUser();
  const { remove: removeInvitationToken } = useInvitationToken();

  const [selectedUser, setSelectedUser] = React.useState<AvailableUser | null>(
    null
  );

  const client = useApolloClient();
  const [acceptProjectContracteeInvitation] =
    useAcceptProjectContracteeInvitationMutation({ client });

  if (!isAuthenticated) {
    return (
      <ProjectContracteeInvitationUnAuthenticated invitation={invitation} />
    );
  }

  return (
    <Stack direction="column" alignItems={"center"} spacing={6}>
      <OrganisationAvatar
        organisationAvatar={invitation.author.company}
        size="xl"
      />
      <Typography variant="h3">
        {t(
          "Please choose the organisation that will work with {inviterOrgName}",
          {
            ns: "Global",
            inviterOrgName: invitation.author.company.title,
          }
        )}
      </Typography>
      <SelectUserList
        isLoading={availableUsersIsLoading}
        availableUsers={availableUsers}
        selectedUser={selectedUser}
        handleSelect={setSelectedUser}
      />
      <Stack
        direction="column"
        spacing={2}
        justifyContent={"stretch"}
        minWidth={300}
      >
        <Button
          variant="contained"
          color="primary"
          disableElevation
          startIcon={<CheckIcon />}
          onClick={async () => {
            if (!selectedUser) throw new Error("Need to select user first!");
            await acceptProjectContracteeInvitation({
              variables: {
                input: { invitationToken: invitation.invitationToken },
              },
              context: {
                headers: {
                  [SELECTED_USER_HTTP_HEADER]: selectedUser.id,
                },
              },
            });
            removeInvitationToken();
            setSelectedUserId(selectedUser.id);
          }}
          disabled={!selectedUser}
        >
          {t("Select", { ns: "Global" })}
        </Button>
        <ButtonDivider label={t("Or", { ns: "Global" })} />
        <Button
          color="warning"
          onClick={() => {
            removeInvitationToken();
          }}
        >
          {t("Discard", { ns: "Global" })}
        </Button>
      </Stack>
    </Stack>
  );
};

const ProjectContracteeInvitationUnAuthenticated = ({
  invitation,
}: Pick<Props, "invitation">) => {
  const { t } = useTranslate(["Global"]);

  const prefill = React.useMemo(() => {
    const firstName = invitation.contact.firstname;
    const lastName = invitation.contact.familyname;
    const email = invitation.contact.email;
    const salutation = invitation.contact.title;

    return { firstName, lastName, email, salutation };
  }, [
    invitation.contact.email,
    invitation.contact.familyname,
    invitation.contact.firstname,
    invitation.contact.title,
  ]);

  return (
    <Stack direction="column" alignItems={"center"} spacing={6}>
      <OrganisationAvatar
        organisationAvatar={invitation.author.company}
        size="xl"
      />
      <Typography variant="h3">
        {t(
          "Please sign in to collaborate with {inviterOrgName} on MeisterSystems",
          {
            ns: "Global",
            inviterOrgName: invitation.author.company.title,
          }
        )}
      </Typography>
      <Stack direction={"column"} spacing={2} minWidth={300}>
        <RegisterButton
          prefill={prefill}
          organisationType="CLIENT"
          color="primary"
        />
        <ButtonDivider label={t("Or", { ns: "Global" })} />
        <LoginButton color="secondary" variant="text" prefill={prefill} />
      </Stack>
    </Stack>
  );
};
