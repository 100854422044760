import { useApolloClient } from "@apollo/client";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { isNull, omit } from "lodash-es";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo } from "react";
import * as Yup from "yup";
import { PhoneInput } from "../../../clients/graphqlTypes.js";
import { AutoSave } from "../../commons/form-fields/AutoSave.js";
import { PHONE_REG_EXP } from "../../commons/form-fields/helpers.js";
import { Stack } from "../../commons/layout/Stack.js";
import { AddressComboField } from "../addresses/AddressComboField.js";
import { AddressDetails__AddressFragment } from "../addresses/Addresses.generated.js";
import { PhonesField } from "../phones/PhonesField.js";
import { defaultPhoneType } from "../phones/usePhoneTypes.js";
import { useModifyUserOrganisationMembershipMutation } from "./Users.generated.js";

interface FormValues {
  jobTitle: string;
  email: string;
  phones: PhoneInput[];
  homeAddress: AddressDetails__AddressFragment | null;
}

interface Props {
  user: {
    id: string;
    jobTitle: string;
    email: string;
    phones: PhoneInput[];
    homeAddress?: AddressDetails__AddressFragment | null | undefined;
  };
}

export const UserInformationForm = ({ user }: Props) => {
  const { t } = useTranslate("UserProfile");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyMembership] = useModifyUserOrganisationMembershipMutation({
    client,
  });

  const initialValues: FormValues = useMemo(
    () => ({
      jobTitle: user.jobTitle,
      email: user.email,
      phones: user.phones?.length > 0 ? user.phones : [defaultPhoneType],
      homeAddress: user.homeAddress ?? null,
    }),
    [user]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        jobTitle: Yup.string().label(t("Job title")),
        email: Yup.string().label(t("Email")).email().trim(),
        phones: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            main: Yup.boolean(),
            type: Yup.string(),
            number: Yup.string().matches(
              PHONE_REG_EXP,
              t("Phone number is not valid")
            ),
            // .required(t("Phone number is required")),
          })
        ),
        homeAddress: Yup.object().label(t("Home address")).nullable(),
      }),
    [t]
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const body = {
          jobTitle: values.jobTitle,
          email: values.email,
          phones: values.phones
            .filter(p => p.number.trim())
            .map(p => omit(p, "__typename", "id")),
          homeAddress: isNull(values.homeAddress)
            ? null
            : values.homeAddress
              ? omit(values.homeAddress, "__typename", "id")
              : undefined,
        };

        await modifyMembership({
          variables: { input: { memberId: user.id, ...body } },
        });
      } catch (error) {
        if (error instanceof Error)
          enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [user, modifyMembership, enqueueSnackbar]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack flexDirection="column">
            <Field
              component={TextField}
              name="jobTitle"
              label={t("Job title")}
              disabled={false}
            />

            <Field
              component={TextField}
              name="email"
              label={t("Email")}
              type="email"
              disabled={true}
            />

            <AddressComboField
              label={t("Home address")}
              address={formikProps.values.homeAddress}
              handleChange={address =>
                formikProps.setFieldValue("homeAddress", address)
              }
            />

            <PhonesField
              name="phones"
              buttonLabel={t("Add phone number")}
              value={formikProps.values.phones}
              disabled={false}
            />

            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
