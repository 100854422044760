/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../doc-items/Fragments.generated.js';
import { DocumentInterpolatedTexts_DocumentFragmentDoc, ProposedCalculations_ItemFragmentDoc } from '../doc-items/hooks/useDecisionItemMutations.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuotePriceSummaryBox_ProjectFragment = { __typename: 'Project', id: string, budget?: { __typename: 'Money', amount: number, currency: string } | null };

export type ModifyQuoteDiscountMutationVariables = Types.Exact<{
  input: Types.QuoteModifyInput;
}>;


export type ModifyQuoteDiscountMutation = { modifyQuote: { __typename: 'ModifyQuoteResult', quote: { __typename: 'Quote', id: string, discountPercentage: number, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, interpolatedQuoteTexts?: { __typename: 'QuoteTexts', id: string, opening: string, closing: string } | null } } };

export const QuotePriceSummaryBox_ProjectFragmentDoc = gql`
    fragment QuotePriceSummaryBox_Project on Project {
  id
  budget {
    amount
    currency
  }
}
    `;
export const ModifyQuoteDiscountDocument = gql`
    mutation ModifyQuoteDiscount($input: QuoteModifyInput!) {
  modifyQuote(input: $input) {
    quote {
      id
      discountPercentage
      proposedCalculation {
        ...ItemCalculation
      }
      ...DocumentInterpolatedTexts_Document
    }
  }
}
    ${ItemCalculationFragmentDoc}
${DocumentInterpolatedTexts_DocumentFragmentDoc}`;
export type ModifyQuoteDiscountMutationFn = Apollo.MutationFunction<ModifyQuoteDiscountMutation, ModifyQuoteDiscountMutationVariables>;

/**
 * __useModifyQuoteDiscountMutation__
 *
 * To run a mutation, you first call `useModifyQuoteDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyQuoteDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyQuoteDiscountMutation, { data, loading, error }] = useModifyQuoteDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyQuoteDiscountMutation(baseOptions?: Apollo.MutationHookOptions<ModifyQuoteDiscountMutation, ModifyQuoteDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyQuoteDiscountMutation, ModifyQuoteDiscountMutationVariables>(ModifyQuoteDiscountDocument, options);
      }
export type ModifyQuoteDiscountMutationHookResult = ReturnType<typeof useModifyQuoteDiscountMutation>;
export type ModifyQuoteDiscountMutationResult = Apollo.MutationResult<ModifyQuoteDiscountMutation>;
export type ModifyQuoteDiscountMutationOptions = Apollo.BaseMutationOptions<ModifyQuoteDiscountMutation, ModifyQuoteDiscountMutationVariables>;