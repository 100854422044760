import React from "react";
import {
  AttachmentPhotoUploaderWithEditor,
  AttachmentPhotoUploaderWithEditorRef,
} from "./AttachmentPhotoUploaderWithEditor.js";
import { AddAttachmentsFn } from "./useAttachments.js";

interface Props {
  onIsUploadingChanged?: (isLoading: boolean) => void;
  onFile?: (file: File) => void | Promise<void>;
  skipUpload?: boolean;
  skipEdit?: boolean;
  addAttachments: AddAttachmentsFn;
}

export const AttachmentPhotoFieldWithEditor = React.forwardRef<
  AttachmentPhotoUploaderWithEditorRef,
  Props
>(
  (
    { onIsUploadingChanged, onFile, skipUpload, skipEdit, addAttachments },
    forwardedRef
  ) => {
    return (
      <AttachmentPhotoUploaderWithEditor
        ref={forwardedRef}
        skipUpload={skipUpload}
        skipEdit={skipEdit}
        onFile={onFile}
        onStart={() => onIsUploadingChanged?.(true)}
        onError={() => onIsUploadingChanged?.(false)}
        onAttachment={async attachment => {
          try {
            await addAttachments([attachment]);
          } catch (error) {
            throw error;
          } finally {
            onIsUploadingChanged?.(false);
          }
        }}
      />
    );
  }
);
