import { LabeledValue } from "@msys/ui";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  Chip,
  IconButton,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Stack,
} from "@mui/material";
import { Field, useField } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import * as Yup from "yup";

interface Props {
  id?: string;
  name: string;
  label: string;
  required?: boolean;
  fullWidth?: boolean | undefined;
  variant?: MuiTextFieldProps["variant"];
}

export const EmailField = ({
  id,
  name,
  label,
  required,
  fullWidth,
  variant,
}: Props) => {
  return (
    <Field
      id={id}
      component={TextField}
      name={name}
      type={"email"}
      label={label}
      required={required}
      InputProps={{}}
      fullWidth={fullWidth}
      variant={variant}
    />
  );
};

export const DetachedEmailField = ({
  id,
  name,
  label,
  required,
  validate,
  isValid,
  fullWidth,
  hideLabelInReadView,
  variant,
}: Props & {
  validate: (value: string) => Promise<boolean>;
  isValid: boolean | null | undefined;
  hideLabelInReadView?: boolean;
}) => {
  const [{ value: formValue = "" }, , fieldHelperProps] = useField({
    name,
  });

  const [isEditing, setIsEditing] = React.useState(!formValue);
  const [error, setError] = React.useState<Error | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  async function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const value = event.target.value;

    try {
      setError(null);
      await Yup.string()
        .label(label)
        .test("validateEmailAddress", async (value, context) => {
          if (!value) return !required;

          try {
            let schema = Yup.string().trim().email();
            if (required) {
              schema = schema.required();
            }

            await schema.validate(value);

            return validate(value);
          } catch (error) {
            return false;
          }
        })
        .validate(value);

      fieldHelperProps.setValue(value);
      fieldHelperProps.setTouched(true);
      setIsEditing(false);
    } catch (error) {
      console.error(error);
      setError(
        error instanceof Error ? error : new Error(JSON.stringify(error))
      );
    }
  }

  if (isEditing) {
    return (
      <MuiTextField
        inputRef={inputRef}
        id={id}
        name={name}
        type={"email"}
        label={label}
        defaultValue={formValue}
        required={required}
        InputProps={{}}
        onBlur={handleBlur}
        error={Boolean(error)}
        helperText={error?.message}
        fullWidth={fullWidth}
        variant={variant}
      />
    );
  }

  return (
    <Stack direction={"row"} alignItems="center" spacing={1}>
      <LabeledValue label={hideLabelInReadView ? "" : label}>
        {formValue}
      </LabeledValue>
      {isValid === false && (
        <Chip variant="outlined" color="error" size="small" label={"Invalid"} />
      )}
      <Stack direction={"row"} spacing={0.5}>
        <IconButton
          color="primary"
          onClick={() => {
            setIsEditing(true);
            setTimeout(() => {
              inputRef.current?.focus();
            });
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => {
            fieldHelperProps.setValue("");
            fieldHelperProps.setTouched(true);
            setIsEditing(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
