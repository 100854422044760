import { ContractType, ItemType } from "../clients/graphqlTypes.js";

export const MOBILE_APP_IOS_URL =
  "https://apps.apple.com/de/app/meistersystems/id1593867371";
export const MOBILE_APP_ANDROID_URL =
  "https://play.google.com/store/apps/details?id=de.meistersystems.app";

export const RESULTS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const CLIENT_PER_PAGE_OPTIONS = [10, 25, 50, 100, 250, 500];
export const PAGE_LIST_RESULTS_PER_PAGE = 25;
export const MEDIUM_LIST_RESULTS_PER_PAGE = 10;
export const SHORT_LIST_RESULTS_PER_PAGE = 5;

export const TREE_SCAFFOLD_PX_WIDTH = 20;

export const ALLOW_INVITE_NEW_CONTRACTEE = false;
export const ALLOW_INVITE_EXISTING_CONTRACTEE = true;
export const ALLOW_AI_FEATURE = false;
export const ALLOW_REQUIREMENT_IN_REQUEST = false;
export const VAT = 0.19;

export const IMAGE_QUALITY = 0.8;
export const IMAGE_MAX_WIDTH = 1400;

export const STORAGE_ACCESS_TOKEN = "STORAGE_ACCESS_TOKEN";

export const TIME_AND_MATERIAL_CONTRACT_TYPES: ContractType[] = [
  "fmbp_fr",
  "fmsp_fr",
];

export const ALL_CONTRACT_TYPES: ContractType[] = [
  "fup",
  ...TIME_AND_MATERIAL_CONTRACT_TYPES,
  "lumpsum",
];

export const BUILDING_ITEM_TYPES: ItemType[] = ["section"];
export const QUOTE_ITEM_TYPES: ItemType[] = [
  "section",
  "paid",
  "decision",
  "unpaid",
];
export const REQUIREMENT_ITEM_TYPES: ItemType[] = [
  "section",
  "paid",
  "decision",
  "unpaid",
];
export const QUOTE_TEMPLATE_ITEM_TYPES: ItemType[] = [
  "section",
  "paid",
  "decision",
  "unpaid",
];

export const SELECTED_USER_HTTP_HEADER = "x-selected-user-id";

export const ORGANISATION_TYPE_URL_SEARCH_PARAM = "ot";
export const REF_TOKEN_URL_SEARCH_PARAM = "refToken";
