/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentActorEditModal_CrmPersonQueryVariables = Types.Exact<{
  crmPersonId: Types.Scalars['ID']['input'];
}>;


export type DocumentActorEditModal_CrmPersonQuery = { crmPersons: { __typename: 'CrmPersonConnection', edges: Array<{ __typename: 'CrmPersonEdge', node: { __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }> } }> } | { __typename: 'MissingPermissions' } };

export type DocumentActorEditModal_OrganisationMembershipQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type DocumentActorEditModal_OrganisationMembershipQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, title: Types.Salutation, firstname: string, familyname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }> }> };


export const DocumentActorEditModal_CrmPersonDocument = gql`
    query DocumentActorEditModal_CrmPerson($crmPersonId: ID!) {
  crmPersons(filters: {ids: [$crmPersonId]}, limit: 1) {
    ... on CrmPersonConnection {
      edges {
        node {
          id
          title
          firstname
          familyname
          email
          phones {
            id
            number
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDocumentActorEditModal_CrmPersonQuery__
 *
 * To run a query within a React component, call `useDocumentActorEditModal_CrmPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentActorEditModal_CrmPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentActorEditModal_CrmPersonQuery({
 *   variables: {
 *      crmPersonId: // value for 'crmPersonId'
 *   },
 * });
 */
export function useDocumentActorEditModal_CrmPersonQuery(baseOptions: Apollo.QueryHookOptions<DocumentActorEditModal_CrmPersonQuery, DocumentActorEditModal_CrmPersonQueryVariables> & ({ variables: DocumentActorEditModal_CrmPersonQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentActorEditModal_CrmPersonQuery, DocumentActorEditModal_CrmPersonQueryVariables>(DocumentActorEditModal_CrmPersonDocument, options);
      }
export function useDocumentActorEditModal_CrmPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentActorEditModal_CrmPersonQuery, DocumentActorEditModal_CrmPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentActorEditModal_CrmPersonQuery, DocumentActorEditModal_CrmPersonQueryVariables>(DocumentActorEditModal_CrmPersonDocument, options);
        }
export function useDocumentActorEditModal_CrmPersonSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DocumentActorEditModal_CrmPersonQuery, DocumentActorEditModal_CrmPersonQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentActorEditModal_CrmPersonQuery, DocumentActorEditModal_CrmPersonQueryVariables>(DocumentActorEditModal_CrmPersonDocument, options);
        }
export type DocumentActorEditModal_CrmPersonQueryHookResult = ReturnType<typeof useDocumentActorEditModal_CrmPersonQuery>;
export type DocumentActorEditModal_CrmPersonLazyQueryHookResult = ReturnType<typeof useDocumentActorEditModal_CrmPersonLazyQuery>;
export type DocumentActorEditModal_CrmPersonSuspenseQueryHookResult = ReturnType<typeof useDocumentActorEditModal_CrmPersonSuspenseQuery>;
export type DocumentActorEditModal_CrmPersonQueryResult = Apollo.QueryResult<DocumentActorEditModal_CrmPersonQuery, DocumentActorEditModal_CrmPersonQueryVariables>;
export const DocumentActorEditModal_OrganisationMembershipDocument = gql`
    query DocumentActorEditModal_OrganisationMembership($userId: ID!) {
  organisationMemberships(filters: {active: true, ids: [$userId]}) {
    id
    title
    firstname
    familyname
    email
    phones {
      id
      number
    }
  }
}
    `;

/**
 * __useDocumentActorEditModal_OrganisationMembershipQuery__
 *
 * To run a query within a React component, call `useDocumentActorEditModal_OrganisationMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentActorEditModal_OrganisationMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentActorEditModal_OrganisationMembershipQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDocumentActorEditModal_OrganisationMembershipQuery(baseOptions: Apollo.QueryHookOptions<DocumentActorEditModal_OrganisationMembershipQuery, DocumentActorEditModal_OrganisationMembershipQueryVariables> & ({ variables: DocumentActorEditModal_OrganisationMembershipQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentActorEditModal_OrganisationMembershipQuery, DocumentActorEditModal_OrganisationMembershipQueryVariables>(DocumentActorEditModal_OrganisationMembershipDocument, options);
      }
export function useDocumentActorEditModal_OrganisationMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentActorEditModal_OrganisationMembershipQuery, DocumentActorEditModal_OrganisationMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentActorEditModal_OrganisationMembershipQuery, DocumentActorEditModal_OrganisationMembershipQueryVariables>(DocumentActorEditModal_OrganisationMembershipDocument, options);
        }
export function useDocumentActorEditModal_OrganisationMembershipSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DocumentActorEditModal_OrganisationMembershipQuery, DocumentActorEditModal_OrganisationMembershipQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentActorEditModal_OrganisationMembershipQuery, DocumentActorEditModal_OrganisationMembershipQueryVariables>(DocumentActorEditModal_OrganisationMembershipDocument, options);
        }
export type DocumentActorEditModal_OrganisationMembershipQueryHookResult = ReturnType<typeof useDocumentActorEditModal_OrganisationMembershipQuery>;
export type DocumentActorEditModal_OrganisationMembershipLazyQueryHookResult = ReturnType<typeof useDocumentActorEditModal_OrganisationMembershipLazyQuery>;
export type DocumentActorEditModal_OrganisationMembershipSuspenseQueryHookResult = ReturnType<typeof useDocumentActorEditModal_OrganisationMembershipSuspenseQuery>;
export type DocumentActorEditModal_OrganisationMembershipQueryResult = Apollo.QueryResult<DocumentActorEditModal_OrganisationMembershipQuery, DocumentActorEditModal_OrganisationMembershipQueryVariables>;