import { gql } from "@apollo/client";
import { StatusChip, StatusLabel } from "@msys/ui";
import { useTheme } from "@mui/material";
import React from "react";
import { useUserData } from "../../auth/useUserData.js";
import { RequestStatusBadgeFragment } from "./RequestStatusBadge.generated.js";
import { useTranslate } from "@tolgee/react";

interface Props {
  request: RequestStatusBadgeFragment;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: boolean;
}

export const RequestStatusBadge = ({ request, align, small }: Props) => {
  const { t } = useTranslate("Requests");
  const viewer = useUserData().currentUser!;
  const theme = useTheme();

  if (request.wonBySystemOrganisationId) {
    if (viewer.organisation.id === request.owningSystemOrganisationId) {
      return (
        <StatusLabel
          color={theme.palette.success.main}
          align={align}
          small={small}
        >
          {t("Completed")}
        </StatusLabel>
      );
    } else {
      if (viewer.organisation.id === request.wonBySystemOrganisationId) {
        return (
          <StatusLabel
            color={theme.palette.success.main}
            align={align}
            small={small}
          >
            {t("Won")}
          </StatusLabel>
        );
      } else {
        return (
          <StatusLabel
            color={theme.palette.error.main}
            align={align}
            small={small}
          >
            {t("Lost")}
          </StatusLabel>
        );
      }
    }
  }

  switch (request.status) {
    case "CLOSED_FOR_BIDDING": {
      return (
        <StatusLabel
          color={theme.palette.warning.main}
          align={align}
          small={small}
        >
          {t("Closed for bidding")}
        </StatusLabel>
      );
    }
    case "CLOSED": {
      return (
        <StatusLabel
          color={theme.palette.grey[600]}
          align={align}
          small={small}
        >
          {t("Closed")}
        </StatusLabel>
      );
    }
    case "OPEN": {
      return (
        <StatusLabel
          color={theme.palette.grey[600]}
          align={align}
          small={small}
        >
          {t("Draft")}
        </StatusLabel>
      );
    }
    case "PUBLISHED": {
      return (
        <StatusLabel
          color={theme.palette.success.main}
          align={align}
          small={small}
        >
          {t("Published")}
        </StatusLabel>
      );
    }
    case "WITHDRAWN": {
      return (
        <StatusLabel
          color={theme.palette.error.main}
          align={align}
          small={small}
        >
          {t("Cancelled")}
        </StatusLabel>
      );
    }
  }
};

export const RequestStatusChip = ({
  request,
  size,
}: {
  request: RequestStatusBadgeFragment;
  size?: React.ComponentProps<typeof StatusChip>["size"];
}) => {
  const { t } = useTranslate("Requests");
  const viewer = useUserData().currentUser!;

  if (request.wonBySystemOrganisationId) {
    if (viewer.organisation.id === request.owningSystemOrganisationId) {
      return (
        <StatusChip label={t("Completed")} color={"success"} size={size} />
      );
    } else {
      if (viewer.organisation.id === request.wonBySystemOrganisationId) {
        return <StatusChip label={t("Won")} color={"success"} size={size} />;
      } else {
        return <StatusChip label={t("Lost")} color={"error"} size={size} />;
      }
    }
  }

  switch (request.status) {
    case "CLOSED_FOR_BIDDING": {
      return (
        <StatusChip
          label={t("Closed for bidding")}
          color={"warning"}
          size={size}
        />
      );
    }
    case "CLOSED": {
      return <StatusChip label={t("Closed")} color={"default"} size={size} />;
    }
    case "OPEN": {
      return <StatusChip label={t("Draft")} color={"default"} size={size} />;
    }
    case "PUBLISHED": {
      return (
        <StatusChip label={t("Published")} color={"success"} size={size} />
      );
    }
    case "WITHDRAWN": {
      return <StatusChip label={t("Cancelled")} color={"error"} size={size} />;
    }
  }
};
