import { gql, useApolloClient } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { SwitchField } from "../../../commons/form-fields/SwitchField.js";
import {
  OrganisationTaskSettingsBox_DefaultsFragment,
  useOrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation,
} from "./OrganisationTaskSettingsBox.generated.js";

interface Props {
  organisationDefaults: OrganisationTaskSettingsBox_DefaultsFragment;
  organisationId: string;
}

export function OrganisationTaskSettingsBox(props: Props) {
  const { t } = useTranslate("OrganisationSettings");
  return (
    <CardContainer isExpandable title={t("Task settings")}>
      <OrganisationTaskSettingsForm {...props} />
    </CardContainer>
  );
}

interface FormValues {
  defaultTaskPhotoIsRequired: boolean;
}

function OrganisationTaskSettingsForm({
  organisationId,
  organisationDefaults,
}: {
  organisationDefaults: OrganisationTaskSettingsBox_DefaultsFragment;
  organisationId: string;
}) {
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();
  const { t } = useTranslate("OrganisationSettings");
  const { enqueueSnackbar } = useSnackbar();
  const [modifyOrganisationDefaults] =
    useOrganisationTaskSettingsForm_ModifyOrganisationDefaultsMutation({
      client,
    });

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const initialValues = {
    defaultTaskPhotoIsRequired: organisationDefaults.defaultTaskPhotoIsRequired,
  };

  const validationSchema = Yup.object().shape({
    defaultTaskPhotoIsRequired: Yup.bool().label(
      t("Uploading photo is required to mark task as done")
    ),
  });

  const handleSubmit = async (values: FormValues) => {
    await modifyOrganisationDefaults({
      variables: {
        input: {
          defaultTaskPhotoIsRequired: values.defaultTaskPhotoIsRequired,
        },
      },
    });
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack direction="column" spacing={1} p={1}>
            <div>
              <Typography>{t("Required on completion")}</Typography>
              <SwitchField
                name="defaultTaskPhotoIsRequired"
                label={t("Uploading photo is required to mark task as done")}
                disabled={false}
              />
            </div>
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
