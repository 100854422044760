/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { IncomingRequestListItemFragmentDoc } from '../../requests/RequestListItem.generated.js';
export type ProjectOverviewBiddingInformationBox_ProjectFragment = { __typename: 'Project', id: string, incomingQuoteRequests: Array<{ __typename: 'Request', id: string, overdue: boolean, earliestStart?: any | null, deadline?: any | null, urgent: boolean, title: string, viewerRecipientNumPublishedQuotes: number, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string, budget?: { __typename: 'Money', amount: number, currency: string } | null }> };

export const ProjectOverviewBiddingInformationBox_ProjectFragmentDoc = gql`
    fragment ProjectOverviewBiddingInformationBox_Project on Project {
  id
  incomingQuoteRequests {
    id
    ...IncomingRequestListItem
  }
}
    ${IncomingRequestListItemFragmentDoc}`;