import React from "react";
import { CustomFieldFilter } from "../../../../clients/graphqlTypes.js";
import { FilterChip } from "../../../commons/filters/FilterChip.js";
import { getExistingOperator, getHumanReadableOperator } from "../helpers.js";

export const FilterCustomFieldsChips: React.FC<{
  values: CustomFieldFilter[] | CustomFieldFilter | undefined | null;
  setValues: (value: CustomFieldFilter[]) => void;
}> = ({ values, setValues }) => {
  if (!values) return null;

  const chips = Array.isArray(values) ? values : [values];

  return (
    <>
      {chips.map((value, index) => {
        const operator = getExistingOperator(value);
        const humanReadableOperator = getHumanReadableOperator(operator);

        return (
          <FilterChip
            key={index}
            label={value.key}
            value={JSON.parse(value[operator])}
            setValue={() =>
              setValues([...chips.filter(v => v.key !== value.key)])
            }
            resetValue={undefined}
            divider={` ${humanReadableOperator} `}
          />
        );
      })}
    </>
  );
};
