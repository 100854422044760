import { useApolloClient } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Link as MuiLink,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { noop } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Agreement, PermissionName } from "../../../../clients/graphqlTypes.js";
import { useFeature } from "../../../../common/FeatureFlags.js";
import { useUserData } from "../../../auth/useUserData.js";
import { ButtonLink } from "../../../commons/button/Button.js";
import { QUOTE_TEMPLATE_ITEM_TYPES } from "../../../constants.js";
import { BareTreeItem } from "../../../trees/BareTreeItem.js";
import { TreeRightIcons } from "../../../trees/components/TreeRightIcons.js";
import { buildDocPath } from "../../../utils.js";
import { ContentBox } from "../../contents/ContentBox.js";
import { itemHasEstimatedCalculationBox } from "../../doc-items/app-conditions/boxes.js";
import { DecisionManagementBox } from "../../doc-items/boxes/DecisionManagementBox.js";
import { EstimatedCalculationBox } from "../../doc-items/boxes/EstimatedCalculationBox.js";
import { HeaderBox } from "../../doc-items/boxes/HeaderBox.js";
import { ItemFilesBox } from "../../doc-items/boxes/ItemFilesBox.js";
import { ProductBox } from "../../doc-items/boxes/ProductBox.js";
import { RecommendationsConfigBox } from "../../doc-items/boxes/RecommendationsConfigBox.js";
import { RequiredOnCompletionBox } from "../../doc-items/boxes/RequiredOnCompletionBox.js";
import {
  SubelementsBox,
  SubitemComponentProps,
} from "../../doc-items/boxes/SubelementsBox.js";
import { TemplateTypePlaceholderBox } from "../../doc-items/boxes/TemplateTypePlaceholderBox.js";
import { QuoteTemplatePriceAlert } from "./QuoteTemplatePriceAlert.js";
import {
  TemplateQuoteItem_ChildItemComponentFragment,
  TemplateQuoteItem_ChildItemFragment,
  TemplateQuoteItem_DocumentFragment,
  TemplateQuoteItem_ItemFragment,
  useResolveTemplateMutation,
} from "./TemplateQuoteItem.generated.js";
import { TemplateQuoteLinkedTemplateItemHeaderBox } from "./TemplateQuoteLinkedTemplateItemHeaderBox.js";
import { QuoteTemplateUpdateToLatestPublishedVersionButton } from "./buttons/QuoteTemplateUpdateToLatestPublishedVersionButton.js";
import { getData123 } from "./trees.js";
import { RulesBox } from "../../doc-items/boxes/RulesBox.js";

interface Props {
  doc: TemplateQuoteItem_DocumentFragment;
  item: TemplateQuoteItem_ItemFragment;
  pathToDocList: string;
  pathToDoc: string;
  pathToDocPage: string;
  onResolveTemplateRefetchQueries: string[];
  onSetContentRefetchQueries: string[];
  onUpdateDataRefetchQueries: string[];
  refetchQueries?: string[];
  isEditable?: boolean;
}

export const TemplateQuoteItem = ({
  doc,
  item,
  pathToDocList,
  pathToDoc,
  pathToDocPage,
  onResolveTemplateRefetchQueries,
  onSetContentRefetchQueries,
  onUpdateDataRefetchQueries,
  isEditable = true,
}: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["QuoteItem", "Templates", "Global"]);
  const isTemplateDebugEnabled = useFeature("TemplateDebug");

  const isOwnTemplate =
    doc.owningSystemOrganisationId === viewer.organisation.id;

  const client = useApolloClient();

  const [resolveTemplateMutation, { loading: resolveTemplateMutationLoading }] =
    useResolveTemplateMutation({ client });

  const resolveTemplate = async () => {
    try {
      await resolveTemplateMutation({
        variables: {
          input: {
            projectId: null,
            docId: doc.id,
            itemId: item.id,
            resolveDepth: 1,
          },
        },
        refetchQueries: onResolveTemplateRefetchQueries,
      });
      navigate(pathToDocPage, { replace: true });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const hasEstimatedCalculationBox = itemHasEstimatedCalculationBox(item);

  const ChildItemComponent = React.useMemo(
    () =>
      createChildItemComponent(
        pathToDocPage,
        { viewerPermissions: viewer.organisationPermissions },
        "NONE"
      ),
    [pathToDocPage, viewer.organisationPermissions]
  );

  if (item.linkedQuoteTemplate) {
    return (
      <Stack spacing={1} direction="column">
        {isOwnTemplate &&
          item.linkedQuoteTemplate.latestAvailableVersionNumber &&
          item.linkedQuoteTemplateAtVersionNumber !==
            item.linkedQuoteTemplate.latestAvailableVersionNumber && (
            <Alert
              variant="standard"
              severity="warning"
              action={
                isEditable ? (
                  <QuoteTemplateUpdateToLatestPublishedVersionButton
                    docId={doc.id}
                    linkingItemId={item.id}
                    latestAvailableVersionNumber={
                      item.linkedQuoteTemplate.latestAvailableVersionNumber
                    }
                    pathToDoc={pathToDoc}
                  />
                ) : (
                  <Button
                    component={Link}
                    to={`${buildDocPath(pathToDoc, null)}/edit/items/${
                      item.id
                    }`}
                    color="inherit"
                  >
                    {t("Edit", { ns: "Global" })}
                  </Button>
                )
              }
            >
              <AlertTitle>
                {t("The linked template is outdated!", { ns: "Templates" })}
              </AlertTitle>
              <Stack alignItems="stretch" spacing={1}>
                {t("There is a newer version of this template available", {
                  ns: "Templates",
                })}
                {isTemplateDebugEnabled && (
                  <div
                    style={{
                      fontStyle: "italic",
                      textAlign: "right",
                      alignSelf: "flex-end",
                    }}
                  >
                    <Typography>Debug:</Typography>
                    <div>
                      linked version:{item.linkedQuoteTemplateAtVersionNumber}
                    </div>
                    <div>
                      latest version:
                      {item.linkedQuoteTemplate.latestAvailableVersionNumber}
                    </div>
                  </div>
                )}
                <MuiLink
                  component={Link}
                  to={`${pathToDocList}/${item.linkedQuoteTemplate.id}/${item.linkedQuoteTemplate.latestAvailableVersionNumber}`}
                  target="_blank"
                  color="inherit"
                  variant="h4"
                >
                  {t("See the available version", { ns: "Templates" })}
                </MuiLink>
              </Stack>
            </Alert>
          )}

        <TemplateQuoteLinkedTemplateItemHeaderBox
          docId={doc.id}
          item={item}
          isEditable={isEditable}
        />

        {!item.isRootItem && item.type !== "unpaid" && (
          <RulesBox
            projectId={null}
            docId={doc.id}
            item={item}
            canEdit={isEditable}
          />
        )}

        {hasEstimatedCalculationBox && (
          <EstimatedCalculationBox
            projectId={null}
            docId={doc.id}
            item={item}
            contractType={doc.contractType}
            isReadOnly={!isEditable}
            viewerIsContractor={isEditable}
            showDetailedCalculation={false}
            onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
          />
        )}

        <Stack direction="column" spacing={1}>
          <ButtonLink
            color="primary"
            to={buildDocPath(
              `${pathToDocList}/${item.linkedQuoteTemplate.id}`,
              item.linkedQuoteTemplateAtVersionNumber ?? null
            )}
            variant="contained"
          >
            {t("View Template Details", { ns: "QuoteItem" })}
          </ButtonLink>
          {/* hide resolveTemplate button, creating items by template is out-of-scope for MEIS-6177 */}
          {item.linkedQuoteTemplateAtVersionNumber && (
            <LoadingButton
              onClick={resolveTemplate}
              loading={resolveTemplateMutationLoading}
              color="secondary"
              variant="outlined"
              disabled={!isEditable}
            >
              {t("Resolve template", { ns: "QuoteItem" })}
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={1}>
      {item.isRootItem && !isEditable && <QuoteTemplatePriceAlert />}

      <HeaderBox
        projectId={null}
        docId={doc.id}
        docType="TEMPLATE"
        item={item}
        viewMode={isEditable ? "edit" : null}
        setViewMode={noop}
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />

      {!item.isRootItem && item.type !== "unpaid" && (
        <RulesBox
          projectId={null}
          docId={doc.id}
          item={item}
          canEdit={isEditable}
        />
      )}

      {item.type === "paid" && (
        <ProductBox
          project={null}
          docId={doc.id}
          docType="TEMPLATE"
          item={item}
          isEditable={isEditable}
          viewerIsContractor={isEditable}
          onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
        />
      )}

      {hasEstimatedCalculationBox && (
        <EstimatedCalculationBox
          key={`${item.product?.articleNumber}-${item.product?.supplierId}-${item.product?.title}`}
          projectId={null}
          docId={doc.id}
          item={item}
          contractType={doc.contractType}
          isReadOnly={!isEditable}
          viewerIsContractor={isEditable}
          onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
        />
      )}

      {item.type === "decision" && isOwnTemplate && (
        <DecisionManagementBox
          item={item}
          doc={{
            id: doc.id,
            docType: "TEMPLATE",
            templateIsDraft: !doc.hasAnyPublishedVersion,
            organisationId: doc.owningSystemOrganisationId,
          }}
          projectId={null}
          onItemDeleted={() => {
            navigate(pathToDocPage, { replace: true });
          }}
          isEditable={isEditable}
        />
      )}
      <RequiredOnCompletionBox
        project={null}
        docId={doc.id}
        document={{ viewerPermissions: viewer.organisationPermissions }}
        item={item}
        canEdit={isEditable}
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />
      <ItemFilesBox
        projectId={null}
        docId={doc.id}
        docType="TEMPLATE"
        viewerPermissions={viewer.organisationPermissions}
        item={item}
        isEditable={isEditable}
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />
      <SubelementsBox<TemplateQuoteItem_ChildItemFragment>
        projectId={null}
        doc={{
          id: doc.id,
          docType: "TEMPLATE",
          templateIsDraft: !doc.hasAnyPublishedVersion,
        }}
        item={item}
        subitemComponent={ChildItemComponent}
        pathToDocPage={pathToDocPage}
        documentItemTypes={QUOTE_TEMPLATE_ITEM_TYPES}
        canChangeVisibility={true}
        isEditable={isEditable}
      />

      <RecommendationsConfigBox
        item={item}
        canEdit={isEditable}
        documentItemTypes={QUOTE_TEMPLATE_ITEM_TYPES}
      />

      {item.contents.length > 0 && (
        <ContentBox
          itemId={item.id}
          content={item.contents}
          refetchQueries={onSetContentRefetchQueries}
          isEditable={isEditable}
        />
      )}

      <TemplateTypePlaceholderBox
        projectId={null}
        doc={doc}
        item={item}
        isEditable={isEditable}
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />
    </Stack>
  );
};

const createChildItemComponent =
  <T extends TemplateQuoteItem_ChildItemComponentFragment>(
    pathToDocPage: string,
    document: {
      viewerPermissions: PermissionName[];
    },
    docAgreement: Agreement
  ) =>
  (itemProps: SubitemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item),
      [itemProps.item]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={document}
          item={itemProps.item}
          isVisibleToOtherSide={itemProps.item.isVisibleToOtherSide}
        />
      ),
      [itemProps.item]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        RightIcons={RightIcons}
        showPath={!itemProps.item.isDecisionOption}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        onClick={itemProps.onClick}
        subcontractPath={null}
        to={
          itemProps.to !== undefined
            ? itemProps.to
            : `${pathToDocPage}/items/${itemProps.item.id}`
        }
      />
    );
  };
