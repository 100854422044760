/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { PimProductCategoryFragmentDoc, ProductSearchItem__ProductSearchResultFragmentDoc } from './Product.generated.js';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductSearchLandingPage_ProductCategoryRootsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
}>;


export type ProductSearchLandingPage_ProductCategoryRootsQuery = { pimGetAvailableSupplierProductCategoryRoots: { __typename: 'MissingCapabilities' } | { __typename: 'PimGetAvailableSupplierProductCategoryRootsResult', result: Array<{ __typename: 'PimSupplierProductCategoryRoots', supplier: { __typename: 'Company', id: string, title: string }, supplierProductCategoryRoots: Array<{ __typename: 'PimProductCategory', id: string, parentId?: string | null, supplierId: string, key: string, title: string }> }> } };

export type ProductSearchLandingPage_RecentlyViewedProductsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
}>;


export type ProductSearchLandingPage_RecentlyViewedProductsQuery = { pimSupplierProductRecentViews: { __typename: 'MissingCapabilities' } | { __typename: 'PimSupplierProductRecentViewsResult', products: Array<{ __typename: 'PimCustomSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } | { __typename: 'PimSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, supplierFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> }> } };

export type ProductSearchLandingPage_FavouriteProductsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
}>;


export type ProductSearchLandingPage_FavouriteProductsQuery = { pimSupplierProductOrganisationFavourites: { __typename: 'MissingCapabilities' } | { __typename: 'PimSupplierProductOrganisationFavouritesResult', products: Array<{ __typename: 'PimCustomSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } | { __typename: 'PimSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, supplierFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> }> } };

export type ProductSearchLandingPage_CurrentSuppliersQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
}>;


export type ProductSearchLandingPage_CurrentSuppliersQuery = { pimCurrentSuppliers: { __typename: 'MissingCapabilities' } | { __typename: 'PimCurrentSuppliersResult', suppliers: Array<{ __typename: 'Company', id: string, title: string, description: string, website: string, isMeister: boolean, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> } };


export const ProductSearchLandingPage_ProductCategoryRootsDocument = gql`
    query ProductSearchLandingPage_ProductCategoryRoots($limit: Int!) {
  pimGetAvailableSupplierProductCategoryRoots(limit: $limit) {
    ... on PimGetAvailableSupplierProductCategoryRootsResult {
      result {
        supplier {
          id
          title
        }
        supplierProductCategoryRoots {
          ...PimProductCategory
        }
      }
    }
  }
}
    ${PimProductCategoryFragmentDoc}`;

/**
 * __useProductSearchLandingPage_ProductCategoryRootsQuery__
 *
 * To run a query within a React component, call `useProductSearchLandingPage_ProductCategoryRootsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSearchLandingPage_ProductCategoryRootsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSearchLandingPage_ProductCategoryRootsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductSearchLandingPage_ProductCategoryRootsQuery(baseOptions: Apollo.QueryHookOptions<ProductSearchLandingPage_ProductCategoryRootsQuery, ProductSearchLandingPage_ProductCategoryRootsQueryVariables> & ({ variables: ProductSearchLandingPage_ProductCategoryRootsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSearchLandingPage_ProductCategoryRootsQuery, ProductSearchLandingPage_ProductCategoryRootsQueryVariables>(ProductSearchLandingPage_ProductCategoryRootsDocument, options);
      }
export function useProductSearchLandingPage_ProductCategoryRootsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSearchLandingPage_ProductCategoryRootsQuery, ProductSearchLandingPage_ProductCategoryRootsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSearchLandingPage_ProductCategoryRootsQuery, ProductSearchLandingPage_ProductCategoryRootsQueryVariables>(ProductSearchLandingPage_ProductCategoryRootsDocument, options);
        }
export function useProductSearchLandingPage_ProductCategoryRootsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductSearchLandingPage_ProductCategoryRootsQuery, ProductSearchLandingPage_ProductCategoryRootsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSearchLandingPage_ProductCategoryRootsQuery, ProductSearchLandingPage_ProductCategoryRootsQueryVariables>(ProductSearchLandingPage_ProductCategoryRootsDocument, options);
        }
export type ProductSearchLandingPage_ProductCategoryRootsQueryHookResult = ReturnType<typeof useProductSearchLandingPage_ProductCategoryRootsQuery>;
export type ProductSearchLandingPage_ProductCategoryRootsLazyQueryHookResult = ReturnType<typeof useProductSearchLandingPage_ProductCategoryRootsLazyQuery>;
export type ProductSearchLandingPage_ProductCategoryRootsSuspenseQueryHookResult = ReturnType<typeof useProductSearchLandingPage_ProductCategoryRootsSuspenseQuery>;
export type ProductSearchLandingPage_ProductCategoryRootsQueryResult = Apollo.QueryResult<ProductSearchLandingPage_ProductCategoryRootsQuery, ProductSearchLandingPage_ProductCategoryRootsQueryVariables>;
export const ProductSearchLandingPage_RecentlyViewedProductsDocument = gql`
    query ProductSearchLandingPage_RecentlyViewedProducts($limit: Int!) {
  pimSupplierProductRecentViews(limit: $limit) {
    ... on PimSupplierProductRecentViewsResult {
      products {
        id
        ...ProductSearchItem__ProductSearchResult
      }
    }
  }
}
    ${ProductSearchItem__ProductSearchResultFragmentDoc}`;

/**
 * __useProductSearchLandingPage_RecentlyViewedProductsQuery__
 *
 * To run a query within a React component, call `useProductSearchLandingPage_RecentlyViewedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSearchLandingPage_RecentlyViewedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSearchLandingPage_RecentlyViewedProductsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductSearchLandingPage_RecentlyViewedProductsQuery(baseOptions: Apollo.QueryHookOptions<ProductSearchLandingPage_RecentlyViewedProductsQuery, ProductSearchLandingPage_RecentlyViewedProductsQueryVariables> & ({ variables: ProductSearchLandingPage_RecentlyViewedProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSearchLandingPage_RecentlyViewedProductsQuery, ProductSearchLandingPage_RecentlyViewedProductsQueryVariables>(ProductSearchLandingPage_RecentlyViewedProductsDocument, options);
      }
export function useProductSearchLandingPage_RecentlyViewedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSearchLandingPage_RecentlyViewedProductsQuery, ProductSearchLandingPage_RecentlyViewedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSearchLandingPage_RecentlyViewedProductsQuery, ProductSearchLandingPage_RecentlyViewedProductsQueryVariables>(ProductSearchLandingPage_RecentlyViewedProductsDocument, options);
        }
export function useProductSearchLandingPage_RecentlyViewedProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductSearchLandingPage_RecentlyViewedProductsQuery, ProductSearchLandingPage_RecentlyViewedProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSearchLandingPage_RecentlyViewedProductsQuery, ProductSearchLandingPage_RecentlyViewedProductsQueryVariables>(ProductSearchLandingPage_RecentlyViewedProductsDocument, options);
        }
export type ProductSearchLandingPage_RecentlyViewedProductsQueryHookResult = ReturnType<typeof useProductSearchLandingPage_RecentlyViewedProductsQuery>;
export type ProductSearchLandingPage_RecentlyViewedProductsLazyQueryHookResult = ReturnType<typeof useProductSearchLandingPage_RecentlyViewedProductsLazyQuery>;
export type ProductSearchLandingPage_RecentlyViewedProductsSuspenseQueryHookResult = ReturnType<typeof useProductSearchLandingPage_RecentlyViewedProductsSuspenseQuery>;
export type ProductSearchLandingPage_RecentlyViewedProductsQueryResult = Apollo.QueryResult<ProductSearchLandingPage_RecentlyViewedProductsQuery, ProductSearchLandingPage_RecentlyViewedProductsQueryVariables>;
export const ProductSearchLandingPage_FavouriteProductsDocument = gql`
    query ProductSearchLandingPage_FavouriteProducts($limit: Int!) {
  pimSupplierProductOrganisationFavourites(limit: $limit) {
    ... on PimSupplierProductOrganisationFavouritesResult {
      products {
        id
        ...ProductSearchItem__ProductSearchResult
      }
    }
  }
}
    ${ProductSearchItem__ProductSearchResultFragmentDoc}`;

/**
 * __useProductSearchLandingPage_FavouriteProductsQuery__
 *
 * To run a query within a React component, call `useProductSearchLandingPage_FavouriteProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSearchLandingPage_FavouriteProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSearchLandingPage_FavouriteProductsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductSearchLandingPage_FavouriteProductsQuery(baseOptions: Apollo.QueryHookOptions<ProductSearchLandingPage_FavouriteProductsQuery, ProductSearchLandingPage_FavouriteProductsQueryVariables> & ({ variables: ProductSearchLandingPage_FavouriteProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSearchLandingPage_FavouriteProductsQuery, ProductSearchLandingPage_FavouriteProductsQueryVariables>(ProductSearchLandingPage_FavouriteProductsDocument, options);
      }
export function useProductSearchLandingPage_FavouriteProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSearchLandingPage_FavouriteProductsQuery, ProductSearchLandingPage_FavouriteProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSearchLandingPage_FavouriteProductsQuery, ProductSearchLandingPage_FavouriteProductsQueryVariables>(ProductSearchLandingPage_FavouriteProductsDocument, options);
        }
export function useProductSearchLandingPage_FavouriteProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductSearchLandingPage_FavouriteProductsQuery, ProductSearchLandingPage_FavouriteProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSearchLandingPage_FavouriteProductsQuery, ProductSearchLandingPage_FavouriteProductsQueryVariables>(ProductSearchLandingPage_FavouriteProductsDocument, options);
        }
export type ProductSearchLandingPage_FavouriteProductsQueryHookResult = ReturnType<typeof useProductSearchLandingPage_FavouriteProductsQuery>;
export type ProductSearchLandingPage_FavouriteProductsLazyQueryHookResult = ReturnType<typeof useProductSearchLandingPage_FavouriteProductsLazyQuery>;
export type ProductSearchLandingPage_FavouriteProductsSuspenseQueryHookResult = ReturnType<typeof useProductSearchLandingPage_FavouriteProductsSuspenseQuery>;
export type ProductSearchLandingPage_FavouriteProductsQueryResult = Apollo.QueryResult<ProductSearchLandingPage_FavouriteProductsQuery, ProductSearchLandingPage_FavouriteProductsQueryVariables>;
export const ProductSearchLandingPage_CurrentSuppliersDocument = gql`
    query ProductSearchLandingPage_CurrentSuppliers($limit: Int!) {
  pimCurrentSuppliers(limit: $limit) {
    ... on PimCurrentSuppliersResult {
      suppliers {
        id
        title
        description
        website
        isMeister
        contactPerson {
          id
          title
          description
          website
          isMeister
          familyname
          firstname
          fullname
          locale
          skillset
        }
        logo {
          ...AttachmentSnapshot
        }
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;

/**
 * __useProductSearchLandingPage_CurrentSuppliersQuery__
 *
 * To run a query within a React component, call `useProductSearchLandingPage_CurrentSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSearchLandingPage_CurrentSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSearchLandingPage_CurrentSuppliersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductSearchLandingPage_CurrentSuppliersQuery(baseOptions: Apollo.QueryHookOptions<ProductSearchLandingPage_CurrentSuppliersQuery, ProductSearchLandingPage_CurrentSuppliersQueryVariables> & ({ variables: ProductSearchLandingPage_CurrentSuppliersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSearchLandingPage_CurrentSuppliersQuery, ProductSearchLandingPage_CurrentSuppliersQueryVariables>(ProductSearchLandingPage_CurrentSuppliersDocument, options);
      }
export function useProductSearchLandingPage_CurrentSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSearchLandingPage_CurrentSuppliersQuery, ProductSearchLandingPage_CurrentSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSearchLandingPage_CurrentSuppliersQuery, ProductSearchLandingPage_CurrentSuppliersQueryVariables>(ProductSearchLandingPage_CurrentSuppliersDocument, options);
        }
export function useProductSearchLandingPage_CurrentSuppliersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductSearchLandingPage_CurrentSuppliersQuery, ProductSearchLandingPage_CurrentSuppliersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSearchLandingPage_CurrentSuppliersQuery, ProductSearchLandingPage_CurrentSuppliersQueryVariables>(ProductSearchLandingPage_CurrentSuppliersDocument, options);
        }
export type ProductSearchLandingPage_CurrentSuppliersQueryHookResult = ReturnType<typeof useProductSearchLandingPage_CurrentSuppliersQuery>;
export type ProductSearchLandingPage_CurrentSuppliersLazyQueryHookResult = ReturnType<typeof useProductSearchLandingPage_CurrentSuppliersLazyQuery>;
export type ProductSearchLandingPage_CurrentSuppliersSuspenseQueryHookResult = ReturnType<typeof useProductSearchLandingPage_CurrentSuppliersSuspenseQuery>;
export type ProductSearchLandingPage_CurrentSuppliersQueryResult = Apollo.QueryResult<ProductSearchLandingPage_CurrentSuppliersQuery, ProductSearchLandingPage_CurrentSuppliersQueryVariables>;