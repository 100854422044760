import { TemplateListsSorting } from "../../../clients/graphqlTypes.js";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView.js";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../constants.js";

export const DEFAULT_SORTING: TemplateListsSorting[] = [
  { column: "createdAt", direction: "desc" },
];

interface BaseQueryVariables {
  searchTerm?: string | null;
  offset?: number | null;
  limit: number;
}

export const useTemplateListsList = <
  QueryVariables extends BaseQueryVariables,
>({
  activeViewStorageKey,
  activeViewInitialDesktopValue,
  activeViewInitialMobileValue,
  initialFilters,
  pageUrlParam,
  perPageUrlParam,
  filtersUrlParam,
  sortingsUrlParam,
}: {
  activeViewStorageKey: string;
  activeViewInitialDesktopValue: CollectionView;
  activeViewInitialMobileValue: CollectionView;
  initialFilters: Omit<QueryVariables, "offset" | "limit" | "sorting">;
  pageUrlParam?: string;
  perPageUrlParam?: string;
  filtersUrlParam?: string;
  sortingsUrlParam?: string;
}) => {
  const {
    limit,
    offset,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<
    TemplateListsSorting,
    Omit<QueryVariables, "offset" | "limit" | "sorting">
  >(
    initialFilters,
    [],
    PAGE_LIST_RESULTS_PER_PAGE,
    pageUrlParam,
    perPageUrlParam,
    filtersUrlParam,
    sortingsUrlParam
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    activeViewStorageKey,
    activeViewInitialDesktopValue,
    activeViewInitialMobileValue
  );

  return {
    limit,
    offset,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    activeView,
    setActiveView,
    queryBaseVariables: QUERY_BASE_VARIABLES,
    toRemoveParams,
  };
};
