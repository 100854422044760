import {
  OrganisationQuoteTemplatesFilters,
  OrganisationQuoteTemplatesSorting,
  TemplateSearchFiltersInput,
  TemplateSearchSortingInput,
} from "../../../../clients/graphqlTypes.js";
import { useFiltersAndPagination } from "../../../commons/filters/useFiltersAndPagination.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../../commons/hooks/useCollectionView.js";
import { useStateWithUrlParam } from "../../../commons/hooks/useStateWithUrlParam.js";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../../constants.js";
import { DEFAULT_SORTING } from "./TemplatesQuoteFilterModal.js";

export const useTemplatesQuoteList = ({
  activeViewStorageKey,
  activeViewInitialDesktopValue,
  activeViewInitialMobileValue,
  initialFilters,
  initialSearchTerm,
}: {
  activeViewStorageKey: string;
  activeViewInitialDesktopValue: CollectionView;
  activeViewInitialMobileValue: CollectionView;
  initialFilters: OrganisationQuoteTemplatesFilters;
  initialSearchTerm?: string;
}) => {
  const {
    limit,
    offset,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<
    OrganisationQuoteTemplatesSorting,
    OrganisationQuoteTemplatesFilters
  >(initialFilters, DEFAULT_SORTING, PAGE_LIST_RESULTS_PER_PAGE);

  const [searchTerm, setSearchTerm] = useStateWithUrlParam<string>(
    "search",
    initialSearchTerm ?? ""
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    activeViewStorageKey,
    activeViewInitialDesktopValue,
    activeViewInitialMobileValue
  );

  return {
    limit,
    offset,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    activeView,
    setActiveView,
    queryBaseVariables: QUERY_BASE_VARIABLES,
    toRemoveParams,
    searchTerm,
    setSearchTerm,
  };
};
