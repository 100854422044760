import { useTranslate } from "@tolgee/react";
import React from "react";
import { AskWhen, AskWhom } from "../../../clients/graphqlTypes.js";

const DEFAULT_ALLOWED_ASK_WHEN: AskWhen[] = [
  "onEmbeddableWizard",
  "onQuoteCreate",
  "onQuoteRefinement",
];

export function useQuestionContol(
  allowedAskWhen: AskWhen[] = DEFAULT_ALLOWED_ASK_WHEN
) {
  const { t } = useTranslate(["Global", "QuoteItem"]);

  const askWhomLabels = React.useMemo(
    (): Record<AskWhom, string> => ({
      contractee: t("Homeowner", {
        ns: "QuoteItem",
      }),
      contractor: t("Craftsmen", {
        ns: "QuoteItem",
      }),
    }),
    [t]
  );
  const askWhomOptions = React.useMemo(
    (): { value: AskWhom; label: string }[] => [
      { value: "contractor", label: askWhomLabels["contractor"] },
      { value: "contractee", label: askWhomLabels["contractee"] },
    ],
    [askWhomLabels]
  );

  const askWhenLabels = React.useMemo(
    (): Record<AskWhen, string> => ({
      onQuoteCreate: t("On quote creation", {
        ns: "QuoteItem",
      }),
      onQuoteRefinement: t("On quote refinement", { ns: "QuoteItem" }),
      onEmbeddableWizard: t("On embeddable wizard", { ns: "QuoteItem" }),
    }),
    [t]
  );

  const askWhenOptions = React.useMemo(
    (): { value: AskWhen; label: string }[] =>
      allowedAskWhen.map(value => ({
        value,
        label: askWhenLabels[value],
      })),
    [allowedAskWhen, askWhenLabels]
  );

  return {
    askWhomLabels,
    askWhomOptions,
    askWhenLabels,
    askWhenOptions,
  };
}
