import { gql } from "@apollo/client";
import { ellipsisStyle } from "@msys/ui";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ActionLine, EmailLine } from "../../../commons/DataItem.js";
import { CrmPersonListItemFragment } from "./CrmPersonListItem.generated.js";

interface Props {
  crmPerson: CrmPersonListItemFragment;
  Action?: React.ReactElement;
}

interface Props {}

export const CrmPersonListItem = ({ crmPerson, Action }: Props) => {
  const { t } = useTranslate("DataItem");
  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>{`${crmPerson.firstname} ${crmPerson.familyname}`}</span>
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>
      {crmPerson.email ? (
        <Tooltip title={t("Contact")} arrow placement="bottom">
          <div style={{ ...ellipsisStyle, alignSelf: "flex-start" }}>
            <EmailLine>{crmPerson.email}</EmailLine>
          </div>
        </Tooltip>
      ) : null}
    </Stack>
  );
};
