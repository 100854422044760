import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  CollapseSection,
  LabeledValue,
  TruncatedHtmlValue,
} from "@msys/ui";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { uniqBy } from "lodash-es";
import React from "react";
import { LabeledProductPropertiesValue } from "../../item-properties/LabeledProductPropertiesValue.js";
import { ProductCataloguePageButton } from "../../products/buttons/ProductCataloguePageButton.js";
import { ProductPricing } from "../../products/ProductPricing.js";
import { useBillOfMaterialsItemProductBoxQuery } from "./ProductBox.generated.js";

interface Props {
  productArticleNumber: string;
  productSupplierId: string;
}

export const ProductBox = ({
  productArticleNumber,
  productSupplierId,
}: Props) => {
  const { t } = useTranslate([
    "QuoteItem",
    "Global",
    "Product",
    "ItemPropertyField",
  ]);

  const client = useApolloClient();

  const query = useBillOfMaterialsItemProductBoxQuery({
    client,
    variables: {
      articleNumber: productArticleNumber,
      supplierId: productSupplierId,
    },
  });

  const product = getDataOrNull(query.data?.pimGetProduct)?.product;

  if (!product) return null;

  const properties = uniqBy(
    product.organisationProperties.concat(product.supplierProductProperties),
    p => p.prop2.key
  ).map(p => p.prop2);

  return (
    <CardContainer
      isExpandable
      title={t("Product", {
        ns: "QuoteItem",
      })}
    >
      <Stack direction="column" m={1} spacing={1}>
        <Typography variant={"h3"}>
          {product.texts?.title ?? product.articleNumber}
        </Typography>
        <Stack marginBottom={2} direction="column" spacing={1}>
          {product.texts?.description?.trim() && (
            <TruncatedHtmlValue
              textProps={{ variant: "body1" }}
              lines={5}
              html={product.texts?.description?.trim()}
              showMoreLabel={t("Show more", { ns: "Global" })}
              showLessLabel={t("Show less", { ns: "Global" })}
              notSetLabel={t("Not set", { ns: "Global" })}
            />
          )}
          {product.texts?.extendedDescription?.trim() && (
            <TruncatedHtmlValue
              textProps={{ variant: "body1" }}
              lines={5}
              html={product.texts?.extendedDescription?.trim()}
              showMoreLabel={t("Show more", { ns: "Global" })}
              showLessLabel={t("Show less", { ns: "Global" })}
              notSetLabel={t("Not set", { ns: "Global" })}
            />
          )}
        </Stack>
        <Box>
          <Grid container spacing={2} style={{ clear: "left" }}>
            {product.category && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("Category", {
                    ns: "Product",
                  })}
                >
                  {product.category}
                </LabeledValue>
              </Grid>
            )}
            <Grid item xs={6}>
              <LabeledValue
                label={t("Article Number", {
                  ns: "Product",
                })}
              >
                {product.articleNumber}
              </LabeledValue>
            </Grid>
            <Grid item xs={6}>
              <LabeledValue
                label={t("Supplier", {
                  ns: "Product",
                })}
              >
                {product.supplier.title}
              </LabeledValue>
            </Grid>
            {product.gtin && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("GTIN", {
                    ns: "Product",
                  })}
                >
                  {product.gtin}
                </LabeledValue>
              </Grid>
            )}
            {product.manufacturerArticleNumber && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("Manufacturer article number", {
                    ns: "Product",
                  })}
                >
                  {product.manufacturerArticleNumber}
                </LabeledValue>
              </Grid>
            )}
            {product.branding && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("Brand", {
                    ns: "Product",
                  })}
                >
                  {product.branding.brand.title}
                </LabeledValue>
              </Grid>
            )}
            {product.branding?.brandLine && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("Brandline", {
                    ns: "Product",
                  })}
                >
                  {product.branding.brandLine.title}
                </LabeledValue>
              </Grid>
            )}
          </Grid>
        </Box>
        <CollapseSection
          title={t("Product Properties", {
            ns: "QuoteItem",
          })}
          isInitiallyExpanded={properties.length > 0}
          titleVariant="h4"
          itemCount={properties.length}
          hideItemCountOnExpand={true}
        >
          <LabeledProductPropertiesValue
            properties={properties}
            viewerIsContractor={true}
            hideVisibility={true}
          />
        </CollapseSection>

        <ProductPricing product={product} hideVisibility={true} />

        <Stack direction={"column"} spacing={1}>
          <Divider />
          <Stack alignContent={"flex-start"}>
            <ProductCataloguePageButton
              productArticleNumber={product.articleNumber}
              productSupplierId={product.supplierId}
            />
          </Stack>
        </Stack>
      </Stack>
    </CardContainer>
  );
};
