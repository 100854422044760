import { gql, useApolloClient } from "@apollo/client";
import {
  DEFAULT_END_WORK_DAY,
  DEFAULT_START_WORK_DAY,
  getDataOrNull,
} from "@msys/common";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import React from "react";
import { useUserData } from "../auth/useUserData.js";
import { Page } from "../commons/layout/Page.js";
import { PageContainer } from "../commons/layout/PageContainer.js";
import { useInvitationListQuery } from "../features/invitations/Invitations.generated.js";
import { AgendaBox, MomentRange } from "../features/sessions/AgendaBox.js";
import { MyTodosBox } from "../features/todos/TodosBox.js";
import { PendingInvitationsBox } from "../features/users/boxes/PendingInvitationsBox.js";
import { UserCalendarBox } from "../features/users/boxes/UserCalendarBox.js";
import { usePushNotifications } from "../global/PushNotificationsProvider.js";
import { DashboardAppNotificationsBox } from "../main-routes/dashboard/boxes/DashboardAppNotificationsBox.js";
import { useInstallerAgendaQuery } from "./Agenda.generated.js";

export const Agenda = () => {
  const { t } = useTranslate("Global");

  const viewer = useUserData().currentUser!;
  const { requestPushPermission } = usePushNotifications();

  const locale = moment.locale();

  const [range, setRange] = React.useState<MomentRange>([
    moment().startOf("week"),
    moment().endOf("week"),
  ]);

  React.useEffect(() => {
    setRange(range => [range[0].locale(locale), range[1].locale(locale)]);
  }, [locale]);

  const client = useApolloClient();

  const invitationQuery = useInvitationListQuery({
    client,
  });
  const projectContracteeInvitations =
    invitationQuery?.data?.viewer?.projectContracteeInvitations;
  const receivedLinkingInvitations =
    invitationQuery?.data?.viewer?.organisation.receivedLinkingInvitations;

  const planSessionsQuery = useInstallerAgendaQuery({
    client,
    fetchPolicy: "network-only",
    skip: !range || !range[0] || !range[1],
    variables: {
      rangeStart: range[0]?.toISOString(true),
      rangeEnd: range[1]?.toISOString(true),
      userId: viewer.id,
    },
  });

  const organisationDefaults = getDataOrNull(
    planSessionsQuery.data?.organisationDefaults
  );
  const startOfWorkHour = organisationDefaults?.defaultStartWorkDay
    ? moment(organisationDefaults.defaultStartWorkDay, "HH:mm:ssZ").hours()
    : moment(DEFAULT_START_WORK_DAY, "HH:mm").hours();
  const endOfWorkHour = organisationDefaults?.defaultEndWorkDay
    ? moment(organisationDefaults.defaultEndWorkDay, "HH:mm:ssZ").hours()
    : moment(DEFAULT_END_WORK_DAY, "HH:mm").hours();

  const userSessions =
    getDataOrNull(planSessionsQuery.data?.planSessions)?.edges.map(
      edge => edge.node
    ) ?? [];

  return (
    <Page isTopBarVisible title={t("Agenda")}>
      <PageContainer>
        <Stack direction={"column"} spacing={2}>
          <DashboardAppNotificationsBox />
          {projectContracteeInvitations && receivedLinkingInvitations && (
            <PendingInvitationsBox
              projectContracteeInvitations={projectContracteeInvitations}
              receivedLinkingInvitations={receivedLinkingInvitations}
            />
          )}
          <AgendaBox
            userSessions={userSessions}
            startOfWorkHour={startOfWorkHour}
            endOfWorkHour={endOfWorkHour}
            dateRange={range}
            setDateRange={setRange}
          />
          <MyTodosBox limit={100} />
          <UserCalendarBox userId={viewer.id} />
        </Stack>
      </PageContainer>
    </Page>
  );
};
