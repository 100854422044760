import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Formik } from "formik";
import { uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import {
  AttachmentSnapshot,
  ItemAttachment,
} from "../../../clients/graphqlTypes.js";
import {
  CrmSendEmailForm,
  FormValues,
  UrlAttachment,
  useCrmSendEmail,
} from "../crm/CrmSendEmailForm.js";
import { crmContactToEmailRecipient } from "../crm/utils.js";
import { useAllProjectContacts } from "../projects/useAllProjectContacts.js";
import {
  RequirementSendViaEmailModal_ProjectFragment,
  RequirementSendViaEmailModal_RequirementFragment,
  useSendRequirementViaEmailQuery,
} from "./RequirementSendViaEmailModal.generated.js";
import { useGenerateRequirementPdfLazyQuery } from "./buttons/DownloadRequirementAsPdfIconButton.generated.js";

interface Props {
  project: RequirementSendViaEmailModal_ProjectFragment;
  requirement: RequirementSendViaEmailModal_RequirementFragment;
  handleClose: () => void;
  handleComplete?: (handleClose: () => void) => Promise<void>;
}

export const RequirementSendViaEmailModal = ({
  requirement,
  project,
  handleClose,
  handleComplete,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Requirements", "Global"]);

  const { recipientOptions, urlAttachments, isLoading } =
    useSendRequirementViaEmail({
      projectId: project.id,
      requirementId: requirement.id,
    });

  const context = React.useMemo(
    () => ({ type: "REQUIREMENT", id: requirement.id }) as const,
    [requirement]
  );

  const { initialValues, validationSchema, handleSendEmail, emailTemplates } =
    useCrmSendEmail({
      urlAttachments,
      emailTemplateContext: "REQUIREMENT",
      context,
    });

  const handleSubmit = async (
    values: FormValues & { inviteContractee: boolean }
  ) => {
    await handleSendEmail(values);
    enqueueSnackbar(
      t("Requirement was sent to client", {
        ns: "Requirements",
      })
    );

    if (handleComplete) {
      await handleComplete(handleClose);
    } else {
      handleClose();
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const initValues = React.useMemo(
    () => ({ ...initialValues, inviteContractee: false }),
    [initialValues]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formikProps => (
        <Modal
          title={t("Send requirement", {
            ns: "Requirements",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Send", { ns: "Global" }),
              buttonProps: {
                loading: formikProps.isSubmitting,
                type: "submit",
                form: formId,
              },
            },
          ]}
          isLoading={isLoading}
          maxWidth="md"
        >
          <Stack spacing={2}>
            <CrmSendEmailForm
              recipientOptions={recipientOptions}
              formId={formId}
              availableTemplates={emailTemplates}
              context={context}
            />
          </Stack>
        </Modal>
      )}
    </Formik>
  );
};

function useSendRequirementViaEmail({
  projectId,
  requirementId,
}: {
  projectId: string;
  requirementId: string;
}) {
  const { t } = useTranslate(["Requirements"]);

  const { contacts: projectContacts } = useAllProjectContacts(projectId);

  const client = useApolloClient();
  const query = useSendRequirementViaEmailQuery({
    client,
    variables: {
      projectId,
      requirementId,
    },
    fetchPolicy: "no-cache",
  });

  const requirement = getDataOrNull(
    (query.data ?? query.previousData)?.requirement
  )?.requirement;
  if (!query.loading && !requirement) throw new Error("Requirement is missing");

  const recipientOptions = projectContacts
    .filter(contact => contact.id && contact.email)
    .map(crmContactToEmailRecipient);

  const { pdfUrl: requirementPdfUrl, isLoading: requirementPdfLoading } =
    useRequirementPdf(projectId, requirementId);

  const requirementAttachments = React.useMemo(
    () =>
      requirement?.items
        .filter(item => item.isVisibleToOtherSide)
        .flatMap(
          item =>
            item.attachments.filter(
              attachment =>
                attachment.clientVisibility &&
                (item.isRootItem || attachment.mimeType === "application/pdf")
            ) ?? []
        ) ?? [],
    [requirement?.items]
  );

  const urlAttachments = React.useMemo(() => {
    const urlAttachments: UrlAttachment[] = [];

    if (requirementPdfUrl) {
      urlAttachments.push({
        attachment: {
          filename: "",
          url: requirementPdfUrl,
          mimeType: "application/pdf",
        },
        notRemovable: true,
      });
    }

    urlAttachments.push(
      ...requirementAttachments.map(attachment => ({
        attachment: convertAttachmentSnapshotToUrlAttachment(attachment),
        group: t("Files from requirement", {
          ns: "Requirements",
        }),
      }))
    );

    return urlAttachments;
  }, [requirementPdfUrl, requirementAttachments, t]);

  return {
    recipientOptions,
    urlAttachments,
    isLoading: query.loading || requirementPdfLoading,
  };
}

function useRequirementPdf(projectId: string, requirementId: string) {
  const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);

  const client = useApolloClient();
  const [generateRequirementPdf, query] = useGenerateRequirementPdfLazyQuery({
    client,
  });

  const getRequirementPdfUrl = React.useCallback(async (): Promise<string> => {
    const { data } = await generateRequirementPdf({
      variables: {
        projectId,
        requirementId,
      },
    });
    const url = getDataOrNull(data?.requirementGeneratePdf)?.url;
    if (!url) throw new Error("Failed to generate PDF");
    return url;
  }, [generateRequirementPdf, projectId, requirementId]);

  React.useEffect(() => {
    getRequirementPdfUrl().then(setPdfUrl);
  }, [getRequirementPdfUrl]);

  return {
    pdfUrl,
    isLoading: query.loading,
  };
}

function convertAttachmentSnapshotToUrlAttachment(
  attachment: AttachmentSnapshot | ItemAttachment
): UrlAttachment["attachment"] {
  return {
    filename: attachment.title,
    mimeType: attachment.mimeType,
    url: attachment.url,
  };
}
