/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type QuantityBox_ItemFragment = { __typename: 'Item', id: string, type: Types.ItemType, quantityUnit: Types.QuantityUnit, estimatedQuantity: number, pendingChangeAttributes: any };

export const QuantityBox_ItemFragmentDoc = gql`
    fragment QuantityBox_Item on Item {
  id
  type
  quantityUnit
  estimatedQuantity
  pendingChangeAttributes
}
    `;