import { Box } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { FC } from "react";
import * as Yup from "yup";
import { CardContainer } from "@msys/ui";
import { Stack } from "../../../commons/layout/Stack.js";
import { useTranslate } from "@tolgee/react";
import { BrandingSlotFragment } from "../../../main-routes/my-organisation/OrganisationBranding.generated.js";
import { SwitchField } from "../../../commons/form-fields/SwitchField.js";
import { ManualSave } from "../../../commons/form-fields/ManualSave.js";
import { Attachment } from "../../attachments/helpers.js";
import { TextField } from "formik-mui";
import { AttachmentImageDragAndDropField } from "../../../commons/form-fields/AttachmentImageDragAndDropField.js";

const getDefaultValues = (): SlotFormValues => {
  return {
    enabled: false,
    attachment: null,
    title: "",
    url: "",
  };
};

const getValues = (slot: BrandingSlotFragment): SlotFormValues => {
  return {
    enabled: slot.enabled,
    attachment: slot.attachment ?? null,
    title: slot.title,
    url: slot.url,
  };
};

export interface SlotFormValues {
  enabled: boolean;
  attachment: Attachment | null;
  title: string;
  url: string;
}

interface Props {
  title: string;
  slot: BrandingSlotFragment | null;
  onSubmit: (values: SlotFormValues) => void | Promise<void>;
  hideUrl?: boolean;
  hideTitle?: boolean;
}

export const OrganisationBrandingSlotBox: FC<Props> = ({
  title,
  slot,
  onSubmit,
  hideUrl = false,
  hideTitle = false,
}) => {
  const { t } = useTranslate("OrganisationBranding");

  const validationSchema = Yup.object().shape({
    enabled: Yup.boolean().required(),
    attachment: Yup.object().nullable(),
    title: Yup.string(),
    url: Yup.string(),
  });

  const initialValues: SlotFormValues = slot
    ? getValues(slot)
    : getDefaultValues();

  return (
    <CardContainer isExpandable title={title}>
      <Box p={1}>
        <Formik<SlotFormValues>
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ dirty, handleReset, values, isSubmitting }) => (
            <Form>
              <Stack flexDirection="column">
                <SwitchField label={t("Enabled")} name="enabled" />
                {values.enabled && (
                  <>
                    <AttachmentImageDragAndDropField name="attachment" />
                    {!hideTitle && (
                      <Field
                        label={`${t("Title")} (${t("optional")})`}
                        name="title"
                        component={TextField}
                      />
                    )}
                    {!hideUrl && (
                      <Field
                        label={`${t("Url")} (${t("optional")})`}
                        name="url"
                        component={TextField}
                      />
                    )}
                  </>
                )}
                <ManualSave
                  onCancel={handleReset}
                  disabled={isSubmitting}
                  disabledCancel={!dirty}
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </CardContainer>
  );
};
