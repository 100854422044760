import { gql } from "@apollo/client";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { AddPhotoApprovalIconButton__TaskDocumentItemFragment } from "./AddPhotoApprovalIconButton.generated.js";
import { useTranslate } from "@tolgee/react";

interface Props {
  itemId: string;
  item: AddPhotoApprovalIconButton__TaskDocumentItemFragment;
  docId: string;
  checkPhotoApprove: (
    itemId: string,
    docId: string,
    checkChildren: boolean
  ) => void;
}

export const AddPhotoApprovalIconButton = ({
  itemId,
  item,
  docId,
  checkPhotoApprove,
}: Props) => {
  const { t } = useTranslate("QuoteItemTaskManagementBox");

  if (!item || !item.isDone || Boolean(item.photoApproved)) {
    return null;
  }

  return (
    <Tooltip title={t("Add a completion photo")}>
      <IconButton
        style={{ width: 40, height: 40 }}
        size="small"
        color="secondary"
        disabled={!!item.deletedAt}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          checkPhotoApprove(itemId, docId, false);
        }}
      >
        <AddAPhotoIcon style={{ width: 26, height: 26 }} />
      </IconButton>
    </Tooltip>
  );
};
