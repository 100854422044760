import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull, parseNumber } from "@msys/common";
import {
  DataGrid,
  GridColDef,
  Modal,
  Select,
  getFromLocalStorage,
  setToLocalStorage,
} from "@msys/ui";
import { Replay as ReplayIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { chunk, findIndex, isEqual, omit, sortBy } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import { v4 } from "uuid";
import {
  ImportMappingsStage,
  MappingRow,
  MappingSet,
} from "../../commons/imports/ImportMappingsStage.js";
import { RESULTS_PER_PAGE_OPTIONS } from "../../constants.js";
import {
  ImportCrmEntryInput,
  ImportCrmOrganisationInput,
  ImportCrmUserInput,
  ImportResolveDuplicateStrategy,
} from "../../../clients/graphqlTypes.js";
import {
  MassImportCrm_CrmCompanyFragment,
  MassImportCrm_OrganisationSettingsFragment,
  useImportCrmOrganisationsMutation,
  useMassImportCrm_CrmCompaniesQuery,
  useMassImportCrm_OrganisationSettingsQuery,
} from "./MassImportCrmModal.generated.js";

const storageKey = "msys-crm-import-mapping-value";

const defaultResolveDuplicate = "DO_NOTHING" as ImportResolveDuplicateStrategy;

type AdditionalPersonNumber = 1 | 2 | 3 | 4;
const additionalPersonNumbers: AdditionalPersonNumber[] = [1, 2, 3, 4];

type EntryState = {
  isSkipped?: boolean;
  isImported?: boolean;
  isImporting?: boolean;
};

type MappingField =
  | "clientNumber"
  | "contactType"
  | "companyName"
  | "description"
  | "contactPersonTitle"
  | "contactPersonFirstName"
  | "contactPersonFamilyName"
  | "billingAddressStreetLine1"
  | "billingAddressPostalCode"
  | "billingAddressCity"
  | "billingAddressCountryCode"
  | "branchAddressStreetLine1"
  | "branchAddressPostalCode"
  | "branchAddressCity"
  | "branchAddressCountryCode"
  | "phoneNumber"
  | "mobileNumber"
  | "faxNumber"
  | "email"
  | "website"
  | "bankAccount"
  | "bankName"
  | "bankCode"
  | "notices"
  | `additionalContactPersonTitle${AdditionalPersonNumber}`
  | `additionalContactPersonFirstName${AdditionalPersonNumber}`
  | `additionalContactPersonFamilyName${AdditionalPersonNumber}`
  | `additionalContactPersonPhoneNumber${AdditionalPersonNumber}`
  | `additionalContactPersonMobileNumber${AdditionalPersonNumber}`
  | `additionalContactPersonFaxNumber${AdditionalPersonNumber}`
  | `additionalContactPersonEmail${AdditionalPersonNumber}`;

type EntryRow = ImportCrmEntryInput & { id: string };

interface Props {
  handleClose(): void;
}

export const MassImportCrmModal = ({ handleClose }: Props) => {
  const { t } = useTranslate(["Global", "CrmOrganisations"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const query = useMassImportCrm_OrganisationSettingsQuery({
    client,
  });
  const settings = query?.data?.organisationSettings;

  const [stage, setStage] = React.useState<"mappings" | "entries">("mappings");

  const [mappingsData, setMappingsData] = React.useState<MappingRow[] | null>(
    null
  );
  const [mappingsValue, setMappingsValue] = React.useState<
    MappingSet<MappingField>
  >(getFromLocalStorage<MappingSet<MappingField>>(storageKey, {}));

  const [entries, setEntries] = React.useState<EntryRow[]>([]);
  const [entriesState, setEntriesState] = React.useState<
    Record<string, EntryState>
  >({});

  React.useEffect(() => {
    setToLocalStorage<MappingSet<MappingField>>(storageKey, mappingsValue);
  }, [mappingsValue]);

  const entriesToImport = React.useMemo(
    (): EntryRow[] =>
      entries.filter(
        e => entriesState[e.organisation.number]?.isSkipped !== true
      ),
    [entries, entriesState]
  );

  const {
    state: { importedCount, totalCount, isImporting, isDone },
    handleImport,
  } = useCrmImport(entriesState, setEntriesState);

  const resolveDuplicateOptions = [
    {
      value: "DO_NOTHING" as ImportResolveDuplicateStrategy,
      label: t("Do nothing", {
        ns: "CrmOrganisations",
      }),
    },
    {
      value: "UPDATE_EXISTING" as ImportResolveDuplicateStrategy,
      label: t("Update existing", {
        ns: "CrmOrganisations",
      }),
    },
    {
      value: "ADD_NEW" as ImportResolveDuplicateStrategy,
      label: t("Add new", {
        ns: "CrmOrganisations",
      }),
    },
  ];

  const [resolveDuplicateAll, setResolveDuplicateAll] =
    React.useState<ImportResolveDuplicateStrategy>(defaultResolveDuplicate);

  const handleApplyResolveDuplicateAll = () => {
    setEntries(e =>
      e.map(e => ({ ...e, resolveDuplicate: resolveDuplicateAll }))
    );
  };

  return (
    <Modal
      title={t("Import crm data", {
        ns: "CrmOrganisations",
      })}
      handleClose={handleClose}
      dialogProps={{
        maxWidth: stage === "entries" ? "lg" : "md",
      }}
      actionButtons={
        stage === "mappings"
          ? [
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Next", {
                  ns: "Global",
                }),
                handleClick: () => {
                  try {
                    if (!mappingsData)
                      throw new Error(`Mappings data doesn not exist`);
                    const entries = processMappingsData(
                      mappingsData,
                      mappingsValue
                    );
                    if (!settings)
                      throw new Error(`No settings found for organisation`);
                    validateEntries(entries, settings);
                    setEntries(entries);
                    setStage("entries");
                  } catch (e) {
                    if (e instanceof Error)
                      enqueueSnackbar(e.message, { variant: "error" });
                  }
                },
                buttonProps: { disabled: !mappingsData },
              },
            ]
          : stage === "entries" && isDone
            ? [
                {
                  label: t("Close", {
                    ns: "Global",
                  }),
                  handleClick: handleClose,
                  buttonProps: { variant: "text" },
                },
              ]
            : []
      }
      dialogActions={
        stage === "entries" && !isDone ? (
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {!isImporting && entries.length > 0 && (
                <>
                  <Box>
                    <Select
                      label={t("On duplicate", {
                        ns: "CrmOrganisations",
                      })}
                      placeholder={t("On duplicate", {
                        ns: "CrmOrganisations",
                      })}
                      size="extra-small"
                      options={resolveDuplicateOptions}
                      value={resolveDuplicateAll}
                      onChange={value =>
                        setResolveDuplicateAll(
                          value as ImportResolveDuplicateStrategy
                        )
                      }
                      disabled={isImporting}
                    />
                  </Box>
                  <Button
                    onClick={handleApplyResolveDuplicateAll}
                    variant="text"
                    color="primary"
                    disabled={isImporting}
                  >
                    {t("Apply to all", {
                      ns: "CrmOrganisations",
                    })}
                  </Button>
                </>
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              flex={1}
              justifyContent="flex-end"
            >
              <Typography>
                {isImporting
                  ? t("{importedCount}/{totalCount} imported", {
                      ns: "CrmOrganisations",
                      importedCount,
                      totalCount,
                    })
                  : t(
                      "{count, plural, =0 {No entry} one {One entry} other {# entries}} to import",
                      {
                        ns: "CrmOrganisations",
                        count: entriesToImport.length,
                      }
                    )}
              </Typography>
              <Button
                disabled={isImporting}
                onClick={handleClose}
                variant="text"
                color="primary"
              >
                {t("Close", {
                  ns: "Global",
                })}
              </Button>
              <LoadingButton
                disabled={isImporting}
                loading={isImporting}
                onClick={async () => {
                  await handleImport(entriesToImport);
                  await client.reFetchObservableQueries();
                  enqueueSnackbar(
                    t("All entries have been successfully processed", {
                      ns: "CrmOrganisations",
                    })
                  );
                }}
                variant="contained"
                color="primary"
              >
                {t("Import", {
                  ns: "Global",
                })}
              </LoadingButton>
            </Stack>
          </DialogActions>
        ) : undefined
      }
    >
      <Stack direction="column" spacing={1}>
        {stage === "mappings" && (
          <MappingsStage
            mappingsData={mappingsData}
            setMappingsData={setMappingsData}
            mappingsValue={mappingsValue}
            setMappingsValue={setMappingsValue}
          />
        )}
        {stage === "entries" && (
          <EntriesStage
            entries={entries}
            setEntries={setEntries}
            entriesState={entriesState}
            setEntriesState={setEntriesState}
          />
        )}
      </Stack>
    </Modal>
  );
};

function MappingsStage({
  mappingsData,
  setMappingsData,
  mappingsValue,
  setMappingsValue,
}: {
  mappingsData: MappingRow[] | null;
  setMappingsData: React.Dispatch<React.SetStateAction<MappingRow[] | null>>;
  mappingsValue: MappingSet<MappingField>;
  setMappingsValue: React.Dispatch<
    React.SetStateAction<MappingSet<MappingField>>
  >;
}) {
  const { t } = useTranslate(["CrmImports"]);

  const mappingsList: MappingField[] = [
    "clientNumber",
    "contactType",
    "companyName",
    "description",
    "contactPersonTitle",
    "contactPersonFirstName",
    "contactPersonFamilyName",
    "billingAddressStreetLine1",
    "billingAddressPostalCode",
    "billingAddressCity",
    "billingAddressCountryCode",
    "branchAddressStreetLine1",
    "branchAddressPostalCode",
    "branchAddressCity",
    "branchAddressCountryCode",
    "phoneNumber",
    "mobileNumber",
    "faxNumber",
    "email",
    "website",
    "bankName",
    "bankAccount",
    "bankCode",
    "notices",
    ...additionalPersonNumbers
      .map(number => [
        `additionalContactPersonTitle${number}` as const,
        `additionalContactPersonFirstName${number}` as const,
        `additionalContactPersonFamilyName${number}` as const,
        `additionalContactPersonPhoneNumber${number}` as const,
        `additionalContactPersonMobileNumber${number}` as const,
        `additionalContactPersonFaxNumber${number}` as const,
        `additionalContactPersonEmail${number}` as const,
      ])
      .flat(1),
  ];

  const mappingsLabels: Record<MappingField, string> = Object.fromEntries([
    [
      "clientNumber",
      t("Client number, eg. 0054", {
        ns: "CrmImports",
      }),
    ],
    [
      "contactType",
      t("Contact type, eg. Company or Private", {
        ns: "CrmImports",
      }),
    ],
    [
      "companyName",
      t("Company name", {
        ns: "CrmImports",
      }),
    ],
    [
      "description",
      t("Description", {
        ns: "CrmImports",
      }),
    ],
    [
      "contactPersonTitle",
      t("Contact person title, eg. Mr. or Ms.", {
        ns: "CrmImports",
      }),
    ],
    [
      "contactPersonFirstName",
      t("Contact person first name", {
        ns: "CrmImports",
      }),
    ],
    [
      "contactPersonFamilyName",
      t("Contact person family name", {
        ns: "CrmImports",
      }),
    ],
    [
      "billingAddressStreetLine1",
      t("Billing address street and house number", {
        ns: "CrmImports",
      }),
    ],
    [
      "billingAddressPostalCode",
      t("Billing address postal code", {
        ns: "CrmImports",
      }),
    ],
    [
      "billingAddressCity",
      t("Billing address city", {
        ns: "CrmImports",
      }),
    ],
    [
      "billingAddressCountryCode",
      t("Billing address country or country code", {
        ns: "CrmImports",
      }),
    ],
    [
      "branchAddressStreetLine1",
      t("Branch address street and house number", {
        ns: "CrmImports",
      }),
    ],
    [
      "branchAddressPostalCode",
      t("Branch address postal code", {
        ns: "CrmImports",
      }),
    ],
    [
      "branchAddressCity",
      t("Branch address city", {
        ns: "CrmImports",
      }),
    ],
    [
      "branchAddressCountryCode",
      t("Branch address country or country code", {
        ns: "CrmImports",
      }),
    ],
    [
      "phoneNumber",
      t("Main phone number", {
        ns: "CrmImports",
      }),
    ],
    [
      "mobileNumber",
      t("Mobile phone number", {
        ns: "CrmImports",
      }),
    ],
    [
      "faxNumber",
      t("Fax number", {
        ns: "CrmImports",
      }),
    ],
    [
      "email",
      t("Organisation email", {
        ns: "CrmImports",
      }),
    ],
    [
      "website",
      t("Organisation website", {
        ns: "CrmImports",
      }),
    ],
    [
      "bankName",
      t("Organisation bank name", {
        ns: "CrmImports",
      }),
    ],
    [
      "bankAccount",
      t("Organisation IBAN", {
        ns: "CrmImports",
      }),
    ],
    [
      "bankCode",
      t("Organisation BIC", {
        ns: "CrmImports",
      }),
    ],
    [
      "notices",
      t("Notes", {
        ns: "CrmImports",
      }),
    ],
    ...additionalPersonNumbers
      .map(number => [
        [
          `additionalContactPersonTitle${number}` as const,
          t("Additional contact person {number} title, eg. Mr. or Ms.", {
            ns: "CrmImports",
            number,
          }),
        ],
        [
          `additionalContactPersonFirstName${number}` as const,
          t("Additional contact person {number} first name", {
            ns: "CrmImports",
            number,
          }),
        ],
        [
          `additionalContactPersonFamilyName${number}` as const,
          t("Additional contact person {number} family name", {
            ns: "CrmImports",
            number,
          }),
        ],
        [
          `additionalContactPersonPhoneNumber${number}` as const,
          t("Additional contact person {number} main phone number", {
            ns: "CrmImports",
            number,
          }),
        ],
        [
          `additionalContactPersonMobileNumber${number}` as const,
          t("Additional contact person {number} mobile number", {
            ns: "CrmImports",
            number,
          }),
        ],
        [
          `additionalContactPersonFaxNumber${number}` as const,
          t("Additional contact person {number} fax number", {
            ns: "CrmImports",
            number,
          }),
        ],
        [
          `additionalContactPersonEmail${number}` as const,
          t("Additional contact person {number} email", {
            ns: "CrmImports",
            number,
          }),
        ],
      ])
      .flat(1),
  ]);

  return (
    <ImportMappingsStage
      mappingsData={mappingsData}
      setMappingsData={setMappingsData}
      mappingsValue={mappingsValue}
      setMappingsValue={setMappingsValue}
      mappingsLabels={mappingsLabels}
      mappingsList={mappingsList}
    />
  );
}

function processMappingsData(
  mappingsData: MappingRow[],
  mappingsValue: MappingSet<MappingField>
) {
  if (mappingsData.length === 0) return [] as EntryRow[];

  const mappingsFields: Partial<Record<MappingField, number>> =
    Object.fromEntries(
      Object.values(mappingsValue)
        .filter(v => v.field)
        .map(v => [v.field, v.index])
    );

  if (mappingsFields["clientNumber"] === undefined)
    throw new Error(`No client number field defined`);

  const extractData = (i: number): Partial<Record<MappingField, string>> => {
    return Object.fromEntries(
      Object.entries(mappingsFields).map(([field, index]) => [
        field,
        mappingsData[index].values[i],
      ])
    );
  };

  const entriesCount = mappingsData[0].values.length;

  const entries: EntryRow[] = [];

  for (let i = 0; i < entriesCount; i++) {
    const data = extractData(i);
    const clientNumber = data["clientNumber"]?.trim();
    if (!clientNumber) continue;

    const contactType = data["contactType"]
      ? (parseContactType(data["contactType"].trim()) ?? "INDIVIDUAL")
      : "INDIVIDUAL";

    const entry = {
      organisation: {
        number: clientNumber,
        contactType,
        title:
          data["companyName"]?.trim() ||
          [
            data["contactPersonFirstName"]?.trim(),
            data["contactPersonFamilyName"]?.trim(),
          ]
            .filter(Boolean)
            .join(" ")
            .trim() ||
          "",
        description: data["description"]?.trim(),
        notices: data["notices"]?.trim(),
        email: data["email"]?.trim(),
        website: data["website"]?.trim(),
        billingAddress: [
          data["billingAddressStreetLine1"],
          data["billingAddressPostalCode"],
          data["billingAddressCity"],
          data["billingAddressCountryCode"],
        ]
          .filter(Boolean)
          .join(", "),
        branchAddress: [
          data["branchAddressStreetLine1"],
          data["branchAddressPostalCode"],
          data["branchAddressCity"],
          data["branchAddressCountryCode"],
        ]
          .filter(Boolean)
          .join(", "),
        phones: [
          {
            main: true,
            number: data["phoneNumber"]?.trim() || "",
            type: contactType === "COMPANY" ? "WORK" : "HOME",
          },
          {
            main: false,
            number: data["mobileNumber"]?.trim() || "",
            type: "MOBILE",
          },
          {
            main: false,
            number: data["faxNumber"]?.trim() || "",
            type: "PRIVATE",
          },
        ].filter(p => p.number),
        bankAccount: data["bankAccount"]?.trim(),
        bankName: data["bankName"]?.trim(),
        bankCode: data["bankCode"]?.trim(),
      } as ImportCrmOrganisationInput,
      users: [
        {
          title:
            parseUserTitle(data["contactPersonTitle"]?.trim() || "") ?? "mr",
          firstname: data["contactPersonFirstName"]?.trim(),
          familyname: data["contactPersonFamilyName"]?.trim(),
          email: data["email"]?.trim(),
          phones: [
            {
              main: true,
              number: data["phoneNumber"]?.trim() || "",
              type: contactType === "COMPANY" ? "WORK" : "HOME",
            },
            {
              main: false,
              number: data["mobileNumber"]?.trim() || "",
              type: "MOBILE",
            },
            {
              main: false,
              number: data["faxNumber"]?.trim() || "",
              type: "PRIVATE",
            },
          ].filter(p => p.number),
        },
        ...additionalPersonNumbers.map(number => ({
          title:
            parseUserTitle(
              data[`additionalContactPersonTitle${number}` as const]?.trim() ||
                ""
            ) ?? "mr",
          firstname:
            data[`additionalContactPersonFirstName${number}` as const]?.trim(),
          familyname:
            data[`additionalContactPersonFamilyName${number}` as const]?.trim(),
          email: data[`additionalContactPersonEmail${number}` as const]?.trim(),
          phones: [
            {
              main: true,
              number:
                data[
                  `additionalContactPersonPhoneNumber${number}` as const
                ]?.trim() || "",
              type: contactType === "COMPANY" ? "WORK" : "HOME",
            },
            {
              main: false,
              number:
                data[
                  `additionalContactPersonMobileNumber${number}` as const
                ]?.trim() || "",
              type: "MOBILE",
            },
            {
              main: false,
              number:
                data[
                  `additionalContactPersonFaxNumber${number}` as const
                ]?.trim() || "",
              type: "PRIVATE",
            },
          ].filter(p => p.number),
        })),
      ].filter(u => u.firstname || u.familyname) as ImportCrmUserInput[],
      resolveDuplicate: defaultResolveDuplicate,
      id: v4(),
    } as EntryRow;
    entries.push(entry);
  }

  return entries;
}

function parseUserTitle(title: string) {
  if (title.trim().match(/frau|ms|mrs/i)) return "ms" as const;
  if (title.trim().match(/herr|mr/i)) return "mr" as const;
  return null;
}

function parseContactType(type: string) {
  if (
    type.trim() === "0" ||
    type
      .trim()
      .match(
        /busines|bussines|compan|organis|organiz|unterneh|geschäft|geschaft|geschaeft|firma|gesell/i
      )
  )
    return "COMPANY" as const;

  if (
    type.trim() === "1" ||
    type.trim().match(/pers|famil|individ|mensch|herr|frau/i)
  )
    return "INDIVIDUAL" as const;

  return null;
}

function validateEntries(
  entries: EntryRow[],
  settings: MassImportCrm_OrganisationSettingsFragment
) {
  const duplicatesByNumber = entries.filter(
    (entry, index, array) =>
      findIndex(
        array,
        e => e.organisation.number === entry.organisation.number
      ) !== index
  );
  if (duplicatesByNumber.length > 0) {
    throw new Error(
      `Duplicates in client number: ${duplicatesByNumber
        .map(e => e.organisation.number)
        .join(", ")}`
    );
  }
  entries.forEach((entry, index) => {
    try {
      parseNumber(
        entry.organisation.number,
        settings.crmOrganisationNumberPrefix,
        settings.crmOrganisationNumberMinLength
      );
      if (!entry.organisation.title)
        throw new Error(`No company title provided`);
    } catch (e) {
      if (e instanceof Error)
        throw new Error(`Validation error in line ${index + 1}: ${e.message}`);
    }
  });
}

function EntriesStage({
  entries,
  setEntries,
  entriesState,
  setEntriesState,
}: {
  entries: EntryRow[];
  setEntries: React.Dispatch<React.SetStateAction<EntryRow[]>>;
  entriesState: Record<string, EntryState>;
  setEntriesState: React.Dispatch<
    React.SetStateAction<Record<string, EntryState>>
  >;
}) {
  const { t } = useTranslate(["Global", "CrmOrganisations"]);

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 25 });

  const [numbers, setNumbers] = React.useState<string[]>([]);
  const lastNumbers = React.useRef(numbers);
  lastNumbers.current = numbers;

  React.useEffect(() => {
    const newNumbers = sortBy(
      entries
        .slice(
          paginationModel.page * paginationModel.pageSize,
          (paginationModel.page + 1) * paginationModel.pageSize
        )
        .map(e => e.organisation.number)
    );
    if (!isEqual(lastNumbers.current, newNumbers)) setNumbers(newNumbers);
  }, [entries, paginationModel]);

  const client = useApolloClient();
  const query = useMassImportCrm_CrmCompaniesQuery({
    client,
    fetchPolicy: "no-cache",
    skip: numbers.length === 0,
    variables: {
      limit: numbers.length,
      numbers,
    },
  });

  const crmCompaniesByNumber = React.useMemo(
    (): Record<string, MassImportCrm_CrmCompanyFragment> =>
      Object.fromEntries(
        (
          getDataOrNull(query?.data?.crmCompanies)?.edges?.map(e => e.node) ??
          []
        ).map(company => [company.number, company])
      ),
    [getDataOrNull(query?.data?.crmCompanies)?.edges]
  );

  const resolveDuplicateOptions = [
    {
      value: "DO_NOTHING" as ImportResolveDuplicateStrategy,
      label: t("Do nothing", {
        ns: "CrmOrganisations",
      }),
    },
    {
      value: "UPDATE_EXISTING" as ImportResolveDuplicateStrategy,
      label: t("Update existing", {
        ns: "CrmOrganisations",
      }),
    },
    {
      value: "ADD_NEW" as ImportResolveDuplicateStrategy,
      label: t("Add new", {
        ns: "CrmOrganisations",
      }),
    },
  ];

  return (
    <DataGrid<EntryRow>
      density="compact"
      loading={false}
      hideFooter={entries.length === 0}
      columns={
        [
          {
            field: "number",
            headerName: t("Number", {
              ns: "Global",
            }),
            flex: 0.75,
            minWidth: 50,
            sortable: false,
            valueGetter: ({ row }) => row.organisation.number,
          },
          {
            field: "newEntry",
            headerName: t("New entry", {
              ns: "CrmOrganisations",
            }),
            flex: 2,
            minWidth: 50,
            sortable: false,
            valueGetter: ({ row }) => row.organisation.title,
          },
          {
            field: "existingEntry",
            headerName: t("Existing entry", {
              ns: "CrmOrganisations",
            }),
            flex: 2,
            minWidth: 50,
            sortable: false,
            renderCell: ({ row }) =>
              query?.loading
                ? t("Loading…", {
                    ns: "Global",
                  })
                : (crmCompaniesByNumber[row.organisation.number]?.title ?? "–"),
          },
          {
            field: "onDuplicate",
            headerName: t("On duplicate", {
              ns: "CrmOrganisations",
            }),
            flex: 1.5,
            minWidth: 50,
            sortable: false,
            renderCell: ({ row }) => (
              <Select
                label={t("On duplicate", {
                  ns: "CrmOrganisations",
                })}
                placeholder={t("On duplicate", {
                  ns: "CrmOrganisations",
                })}
                size="extra-small"
                options={resolveDuplicateOptions}
                value={row.resolveDuplicate}
                onChange={value =>
                  setEntries(entries =>
                    entries.map(entry =>
                      entry.id === row.id
                        ? {
                            ...entry,
                            resolveDuplicate:
                              value as ImportResolveDuplicateStrategy,
                          }
                        : entry
                    )
                  )
                }
              />
            ),
          },
          {
            field: "actions",
            headerName: t("Actions", {
              ns: "CrmOrganisations",
            }),
            flex: 1.5,
            minWidth: 50,
            sortable: false,
            renderCell: ({ row }) => {
              const isSkipped =
                entriesState[row.organisation.number]?.isSkipped ?? false;
              const isImporting =
                entriesState[row.organisation.number]?.isImporting ?? false;
              const isImported =
                entriesState[row.organisation.number]?.isImported ?? false;
              return (
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isImporting ? (
                    <Typography variant="body2">
                      {t("Importing…", {
                        ns: "CrmOrganisations",
                      })}
                    </Typography>
                  ) : isImported ? (
                    <Typography variant="body2">
                      {t("Imported", {
                        ns: "CrmOrganisations",
                      })}
                    </Typography>
                  ) : isSkipped ? (
                    <>
                      <Typography variant="body2">
                        {t("Skipped", {
                          ns: "CrmOrganisations",
                        })}
                      </Typography>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() =>
                          setEntriesState(s => ({
                            ...s,
                            [row.organisation.number]: {
                              ...s[row.organisation.number],
                              isSkipped: false,
                            },
                          }))
                        }
                      >
                        <Tooltip
                          title={t("Revert", {
                            ns: "CrmOrganisations",
                          })}
                        >
                          <ReplayIcon />
                        </Tooltip>
                      </IconButton>
                    </>
                  ) : (
                    <Button
                      size="extra-small"
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        setEntriesState(s => ({
                          ...s,
                          [row.organisation.number]: {
                            ...s[row.organisation.number],
                            isSkipped: true,
                          },
                        }));
                      }}
                    >
                      {t("Skip", {
                        ns: "Global",
                      })}
                    </Button>
                  )}
                </Stack>
              );
            },
          },
        ] as GridColDef<EntryRow>[]
      }
      rows={entries}
      paginationModel={paginationModel}
      onPaginationModelChange={newPaginationModel => {
        setPaginationModel(newPaginationModel);
      }}
      disableColumnFilter
      pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
      rowCount={entries.length}
      filterMode={"client"}
      paginationMode={"client"}
      sortingMode={"client"}
    />
  );
}

function useCrmImport(
  entriesState: Record<string, EntryState>,
  setEntriesState: React.Dispatch<
    React.SetStateAction<Record<string, EntryState>>
  >
) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = React.useState<{
    importedCount: number;
    totalCount: number;
    isImporting: boolean;
    isDone: boolean;
  }>({ importedCount: 0, totalCount: 0, isImporting: false, isDone: false });
  const client = useApolloClient();
  const [importCrmOrganisations] = useImportCrmOrganisationsMutation({
    client,
  });

  const handleImport = async (entries: EntryRow[]) => {
    if (state.isImporting) return;
    setState({
      importedCount: 0,
      totalCount: entries.length,
      isImporting: true,
      isDone: false,
    });
    try {
      const chunks = chunk(entries, 10);

      for (let chunk of chunks) {
        setEntriesState(state => {
          const newState = { ...state };
          chunk.forEach(e => {
            newState[e.organisation.number] = {
              ...newState[e.organisation.number],
              isImporting: true,
              isImported: false,
            };
          });
          return newState;
        });
        await importCrmOrganisations({
          variables: {
            input: chunk.map(e => omit(e, "id")),
          },
        });
        setState(s => ({
          ...s,
          importedCount: s.importedCount + chunk.length,
        }));
        setEntriesState(state => {
          const newState = { ...state };
          chunk.forEach(e => {
            newState[e.organisation.number] = {
              ...newState[e.organisation.number],
              isImporting: false,
              isImported: true,
            };
          });
          return newState;
        });
      }

      setState(s => ({ ...s, isDone: true }));
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setState(s => ({ ...s, isImporting: false }));
    }
  };

  return { state, handleImport };
}
