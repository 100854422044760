import { App } from "@capacitor/app";
import { Capacitor, CapacitorHttp } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Dialog } from "@capacitor/dialog";
import { SplashScreen } from "@capacitor/splash-screen";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import * as Sentry from "@sentry/react";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { useLatest } from "react-use";
import { MOBILE_APP_ANDROID_URL, MOBILE_APP_IOS_URL } from "../constants.js";
import {
  getAndroidAppBuildNumber,
  getCapacitorAppUrl,
  getIOSAppBuildNumber,
  isCapacitorEnabled,
} from "../firebase.js";
import { VERSION } from "../../environment.js";

// Over-the-air update (JS bundle only)
export const useOTAUpdate = () => {
  const { t } = useTranslate("OutdatedDialog");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const requestOTAUpdate = async (appUrl: string, latestVersion: string) => {
    let bundle;
    try {
      enqueueSnackbar(
        <div>
          {t("Downloading the update...")}
          <span id="msys-version-update-percent"></span>
        </div>,
        {
          variant: "default",
          key: "msys-version-update",
          persist: true,
        }
      );

      bundle = await CapacitorUpdater.download({
        url: `${appUrl}/${latestVersion}.zip`,
        version: latestVersion,
      });
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(`Cannot download new package: ${e.message}`, {
          variant: "error",
        });
        console.error(`Cannot download new package: ${e.message}`, e);
      }
      Sentry.captureException(e);
    } finally {
      closeSnackbar("msys-version-update");
    }

    if (bundle) {
      try {
        await SplashScreen.show(); // show the splashscreen to let the update happen
        await CapacitorUpdater.set(bundle);
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(`Cannot set new app version: ${e.message}`, {
            variant: "error",
          });
          console.error(`Cannot set new app version: ${e.message}`, e);
        }
        Sentry.captureException(e);
      } finally {
        await SplashScreen.hide(); // in case the set fail, otherwise the new app will have to hide it
      }
    }
  };

  const cleanOldOTABundles = async () => {
    // cleaning up the old versions more than 3 days ago
    const moment3DaysAgo = moment().startOf("day").subtract(3, "days");
    try {
      const { bundles } = await CapacitorUpdater.list();
      const { bundle: currentBundle } = await CapacitorUpdater.current();

      if (bundles.length > 0) {
        for (const bundle of bundles) {
          if (
            currentBundle.version !== bundle.version &&
            moment3DaysAgo.isAfter(bundle.downloaded)
          ) {
            console.log(
              "[Capacitor]",
              "Clean up old version:",
              bundle.version,
              "Current version:",
              currentBundle.version
            );
            await CapacitorUpdater.delete({ id: bundle.id });
          }
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return { requestOTAUpdate, cleanOldOTABundles };
};

// Update from App Store / Google Play Market
export const useStoreUpdate = () => {
  const { t } = useTranslate("OutdatedDialog");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const forceStoreUpdate = async () => {
    try {
      const { platform } = await Device.getInfo();
      if (platform === "ios") {
        window.location.href = MOBILE_APP_IOS_URL;
      }
      if (platform === "android") {
        window.location.href = MOBILE_APP_ANDROID_URL;
      }
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(`Cannot open app store: ${e.message}`, {
          variant: "error",
        });
        console.error(`Cannot open app store: ${e.message}`);
      }
      Sentry.captureException(e);
    }
  };

  return { forceStoreUpdate };
};

export const useCheckForUpdates = () => {
  const checkForUpdates = async () => {
    const deviceInfo = await Device.getInfo();
    const platform = deviceInfo.platform;
    const appInfo = await App.getInfo();

    const appUrl =
      (await getCapacitorAppUrl()) ?? "https://www.prod.meistersystems.de";

    const latestBuildNumber =
      platform === "android"
        ? await getAndroidAppBuildNumber()
        : await getIOSAppBuildNumber();

    const currentBuildNumber = parseInt(appInfo.build, 10);

    const latestVersion: string = await CapacitorHttp.get({
      url: `${appUrl}/VERSION.json`,
    }).then(response => response.data.version);
    const currentVersion: string = VERSION;

    let needStoreUpdate = false,
      needOTAUpdate = false,
      needOTAClean = false;

    if (latestBuildNumber) {
      // we received build number from firebase, checking if update from store is required
      if (currentBuildNumber < latestBuildNumber) {
        needStoreUpdate = true;
      } else if (currentBuildNumber >= latestBuildNumber) {
        if (latestVersion !== currentVersion) {
          needOTAUpdate = true;
        } else {
          needOTAClean = true;
        }
      }
    }

    return {
      needStoreUpdate,
      needOTAUpdate,
      needOTAClean,
      latestVersion,
      appUrl,
      platform,
    };
  };

  return { checkForUpdates };
};

export const useOutdatedAppCheck = () => {
  const { requestOTAUpdate, cleanOldOTABundles } = useOTAUpdate();
  const { forceStoreUpdate } = useStoreUpdate();
  const { checkForUpdates } = useCheckForUpdates();

  const { t } = useTranslate("OutdatedDialog");

  const [isChecked, setIsChecked] = React.useState<boolean>(
    isCapacitorEnabled ? false : true
  );
  const checkInitialized = React.useRef<boolean>(false);
  const [isChecking, setIsChecking] = React.useState<boolean>(true);

  // initially force run update
  const forceRunUpdate = useLatest(async () => {
    const {
      needStoreUpdate,
      needOTAUpdate,
      needOTAClean,
      latestVersion,
      appUrl,
      platform,
    } = await checkForUpdates();

    if (needStoreUpdate) {
      // just open dialog following to the store
      await Dialog.alert({
        title: t("New version released"),
        message:
          platform === "android"
            ? t(
                "Please proceed to Play Market to update the application to the latest version"
              )
            : t(
                "Please proceed to App Store to update the application to the latest version"
              ),
        buttonTitle:
          platform === "android"
            ? t("Go to Play Market")
            : t("Go to App Store"),
      });
      try {
        await forceStoreUpdate();
      } finally {
        setIsChecking(false);
      }
    } else if (needOTAUpdate) {
      // immediately do OTA update
      try {
        await requestOTAUpdate(appUrl, latestVersion);
      } finally {
        setIsChecking(false);
      }
    } else if (needOTAClean) {
      cleanOldOTABundles(); // no need to await here
      setIsChecked(true);
    } else {
      setIsChecked(true);
    }
  });

  // check when app is becoming active again
  const checkForUpdate = useLatest(async () => {
    try {
      const {
        needStoreUpdate,
        needOTAUpdate,
        needOTAClean,
        latestVersion,
        appUrl,
        platform,
      } = await checkForUpdates();

      if (needStoreUpdate) {
        // just open dialog following to the store
        await Dialog.alert({
          title: t("New version released"),
          message:
            platform === "android"
              ? t(
                  "Please proceed to Play Market to update the application to the latest version"
                )
              : t(
                  "Please proceed to App Store to update the application to the latest version"
                ),
          buttonTitle:
            platform === "android"
              ? t("Go to Play Market")
              : t("Go to App Store"),
        });
        await forceStoreUpdate();
      } else if (needOTAUpdate) {
        // open the dialog for OTA update
        await Dialog.alert({
          title: t("Update required"),
          message: t("Please update the application to the latest version"),
          buttonTitle: t("Update"),
        });
        await requestOTAUpdate(appUrl, latestVersion);
      } else if (needOTAClean) {
        await cleanOldOTABundles();
      }
    } catch (e) {
      if (e instanceof Error)
        console.error(`Error while checking for update: ${e.message}`);
      Sentry.captureException(e);
    }
  });

  // here we kick-off an initial check
  React.useEffect(() => {
    if (!isChecked && !checkInitialized.current) {
      checkInitialized.current = true;
      forceRunUpdate.current();
    }
  }, [isChecked, forceRunUpdate]);

  // repeat initial force update if failed/needed
  const repeatInitialUpdate = async () => {
    if (isChecked || isChecking) return;
    setIsChecking(true);
    setTimeout(() => {
      forceRunUpdate.current();
    });
  };

  // run repeated check when app is becoming active again
  React.useEffect(() => {
    if (!isCapacitorEnabled) return;

    // initial kick off of version checking
    // checkForUpdate.current();

    App.addListener("appStateChange", ({ isActive }) => {
      if (isActive) {
        // kick off of version checking every time app is becoming active
        checkForUpdate.current();
      }
    });

    CapacitorUpdater.addListener(
      "download",
      ({ percent }: { percent: number }) => {
        const el = document.querySelector("#msys-version-update-percent");
        if (el) el.innerHTML = ` ${percent}%`;
      }
    );

    return () => {
      App.removeAllListeners();
      CapacitorUpdater.removeAllListeners();
    };
  }, [checkForUpdate]);

  return { isChecked, isChecking, repeatInitialUpdate };
};
