import * as Sentry from "@sentry/react";
import { DEPLOY_ENV, VERSION } from "../environment.js";

export function initSentry(
  initialScope?: Parameters<typeof Sentry.init>[0]["initialScope"]
) {
  Sentry.init({
    enabled: DEPLOY_ENV !== "local",
    dsn: "https://04cdd66329cf4f138d7e6edbe37c5785@o1127144.ingest.sentry.io/6168786",
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],

    environment: DEPLOY_ENV,
    initialScope,
    release: VERSION,
    beforeSend: function (event, hint) {
      const exception = hint?.originalException;

      if (exception instanceof Error) {
        event.fingerprint = ["{{ default }}", exception.message];
      }

      return event;
    },
    replaysSessionSampleRate:
      DEPLOY_ENV === "dev" || DEPLOY_ENV === "stg" ? 1 : 0,
    replaysOnErrorSampleRate: 1.0,
  });
}
