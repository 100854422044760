/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequirementMenu_RequirementFragment = { __typename: 'Requirement', id: string, viewerPermissions: Array<Types.PermissionName>, isPublished: boolean, owningSystemOrganisationId: string, outgoingRequests: Array<{ __typename: 'Request', id: string }>, mainContract?: { __typename: 'Subcontract', id: string, originProject: { __typename: 'ProjectInfo', id: string, state: Types.ProjectStateMachineStatus }, originQuote: { __typename: 'Quote', id: string }, originItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } } | null };

export type RequirementMenu_ProjectFragment = { __typename: 'Project', id: string, state: Types.ProjectStateMachineStatus, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, fullname: string, email: any }> } | null };

export type DeleteRequirementMutationVariables = Types.Exact<{
  input: Types.DeleteRequirementInput;
}>;


export type DeleteRequirementMutation = { deleteRequirement: { __typename: 'DeleteRequirementResult', ok: boolean } };

export const RequirementMenu_RequirementFragmentDoc = gql`
    fragment RequirementMenu_Requirement on Requirement {
  id
  viewerPermissions
  isPublished
  owningSystemOrganisationId
  outgoingRequests {
    id
  }
  mainContract {
    id
    originProject {
      id
      state
    }
    originQuote {
      id
    }
    originItem {
      id
      originVersionNumber
    }
  }
}
    `;
export const RequirementMenu_ProjectFragmentDoc = gql`
    fragment RequirementMenu_Project on Project {
  id
  state
  crmOrganisation {
    id
    title
    email
    members {
      id
      fullname
      email
    }
  }
}
    `;
export const DeleteRequirementDocument = gql`
    mutation DeleteRequirement($input: DeleteRequirementInput!) {
  deleteRequirement(input: $input) {
    ok
  }
}
    `;
export type DeleteRequirementMutationFn = Apollo.MutationFunction<DeleteRequirementMutation, DeleteRequirementMutationVariables>;

/**
 * __useDeleteRequirementMutation__
 *
 * To run a mutation, you first call `useDeleteRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequirementMutation, { data, loading, error }] = useDeleteRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRequirementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRequirementMutation, DeleteRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRequirementMutation, DeleteRequirementMutationVariables>(DeleteRequirementDocument, options);
      }
export type DeleteRequirementMutationHookResult = ReturnType<typeof useDeleteRequirementMutation>;
export type DeleteRequirementMutationResult = Apollo.MutationResult<DeleteRequirementMutation>;
export type DeleteRequirementMutationOptions = Apollo.BaseMutationOptions<DeleteRequirementMutation, DeleteRequirementMutationVariables>;