import { Cancel as CancelIcon } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import { useField } from "formik";
import { isArray, isEqual, without } from "lodash-es";
import React from "react";
import { SelectField, SelectFieldProps } from "./SelectField.js";

export const SelectMultipleField = <Value,>(
  props: Omit<SelectFieldProps<Value>, "multiple" | "renderValue">
) => {
  const { options, name } = props;
  const ref = React.useRef<HTMLElement>(null);

  const [
    { value: currentValue },
    { touched, error },
    { setValue: setFormValue, setTouched },
  ] = useField<Value[] | null>(name);

  return (
    <SelectField<Value, true>
      {...props}
      inputRef={ref}
      multiple
      renderValue={selected => {
        if (!selected) return null;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="baseline"
            flexWrap="wrap"
            sx={{ ml: -0.5, mt: -0.5 }}
          >
            {selected.map((value, index) => (
              <Chip
                key={index}
                size="small"
                clickable={false}
                variant="outlined"
                label={
                  options?.find(option => isEqual(option.value, value))?.label
                }
                onDelete={
                  !props.disabled
                    ? () => {
                        setFormValue(without(currentValue || [], value));
                        requestAnimationFrame(() => {
                          setTouched(true);
                        });
                      }
                    : undefined
                }
                deleteIcon={
                  <CancelIcon
                    onMouseDown={e => {
                      // prevent Select intercepting 'mousedown' event
                      e.stopPropagation();
                      e.preventDefault();
                      ref.current?.focus();
                    }}
                  />
                }
                sx={{ ml: 0.5, mt: 0.25 }}
              />
            ))}
          </Box>
        );
      }}
    />
  );
};

export const SelectMultipleNumberField = (
  props: Omit<SelectFieldProps<number>, "multiple" | "renderValue">
) => {
  const [, , { setValue, setTouched }] = useField<number[] | null>(props.name);
  return (
    <SelectMultipleField<number>
      {...props}
      onChange={e =>
        setValue(
          e.target.value && isArray(e.target.value)
            ? e.target.value.map(parseFloat)
            : null
        )
      }
      onClear={() => setValue(null)}
    />
  );
};
