import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { Typography } from "@mui/material";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
  type EditorState,
  type LexicalEditor,
} from "lexical";
import React from "react";
import { BlockType, LexicalRichTextEditor } from "./LexicalRichTextEditor.js";

interface Props {
  placeholder?: string;
  initialHtmlValue: string;
  handleChange: (htmlValue: string) => void;
  debug?: boolean;
}

export const LexicalRichTextEditorWithHtml = ({
  placeholder,
  initialHtmlValue,
  handleChange,
  debug = false,
}: Props) => {
  const handleStateChange = React.useCallback(
    (editorState: EditorState, editor: LexicalEditor) => {
      // $generateHtmlFromNodes needs to be wrapped inside editor.update()
      // https://github.com/facebook/lexical/issues/2325#issuecomment-1145324881
      editor.update(() => {
        const htmlString = $generateHtmlFromNodes(editor, null);
        handleChange(htmlString);
      });
    },
    [handleChange]
  );

  return (
    <>
      <LexicalRichTextEditor
        placeholder={placeholder}
        initialEditorState={editor => {
          if (isValidRichTextEditorHtml(initialHtmlValue)) {
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialHtmlValue, "text/html");
            const nodes = $generateNodesFromDOM(editor, dom);
            $getRoot().select();
            $insertNodes(nodes);
          } else {
            console.warn("Initial value is not an valid rich text HTML string");
            // Legacy support
            // email template used to be just plain text
            const root = $getRoot();
            initialHtmlValue.split("\n").forEach(value => {
              const textNode = $createTextNode(value);
              const paragraphNode = $createParagraphNode();
              paragraphNode.append(textNode);
              root.append(paragraphNode);
            });
            setTimeout(() => {
              handleStateChange(editor.getEditorState(), editor);
            });
          }
        }}
        handleChange={handleStateChange}
      />
      {debug && <HtmlString value={initialHtmlValue} />}
    </>
  );
};

const BLOCK_TYPE_TAG_MAPPING: Record<BlockType, string> = {
  paragraph: "p",
  h1: "h1",
  h2: "h2",
  ul: "ul",
  ol: "ol",
};

function isValidRichTextEditorHtml(value: string) {
  return Object.values(BLOCK_TYPE_TAG_MAPPING).some(tag =>
    value.startsWith(`<${tag}`)
  );
}

const HtmlString = ({ value }: { value: string }) => {
  return (
    <Typography
      padding={2}
      fontFamily={"monospace"}
      fontSize={14}
      whiteSpace={"pre-wrap"}
      borderRadius={1}
      sx={{
        backgroundColor: "rgb(255, 244, 229)",
      }}
    >
      {value.replaceAll("<", "\n<")}
    </Typography>
  );
};
