import { useLocalStorageAsState } from "@msys/ui";
import moment from "moment";

// @ts-ignore
export const useResourceFilter = (
  organisationId: string,
  projectId: string | undefined,
  section: string = "schedule"
) => {
  const [resourceOnTop, setResourceOnTop] = useLocalStorageAsState<boolean>(
    `msys-planning-${section}-resourceOnTop-${organisationId}`,
    true
  );
  const [resourceList, setResourceList] = useLocalStorageAsState<
    string[] | null
  >(`msys-planning-${section}-resourceList-${organisationId}`, null);
  const [resourceOrganisationRoleIds, setResourceOrganisationRoleIds] =
    useLocalStorageAsState<string[] | null>(
      `msys-planning-${section}-resourceOrganisationRoleIds-${organisationId}`,
      null
    );

  const [resourceMaxDistance, setResourceMaxDistance] =
    useLocalStorageAsState<number>(
      `msys-planning-${section}-resourceMaxDistance-${organisationId}-${
        projectId ?? "all"
      }`,
      0
    );

  const [resourceAvailability, setResourceAvailability] =
    useLocalStorageAsState<[moment.Moment | null, moment.Moment | null] | null>(
      `msys-planning-${section}-resourceAvailability-${organisationId}-${
        projectId ?? "all"
      }`,
      null,
      false,
      {
        serializer: value =>
          JSON.stringify(value ? value.map(v => (v ? v.format() : v)) : value),
        deserializer: string => {
          if (!string) return null;
          const value = JSON.parse(string);
          if (!value) return null;
          return value.map((v: string | null) => (v ? moment(v) : v)) as [
            moment.Moment | null,
            moment.Moment | null,
          ];
        },
      }
    );

  return {
    resourceList,
    setResourceList,
    resourceOnTop,
    setResourceOnTop,
    resourceOrganisationRoleIds,
    setResourceOrganisationRoleIds,
    resourceAvailability,
    setResourceAvailability,
    resourceMaxDistance,
    setResourceMaxDistance,
  };
};
