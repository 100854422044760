import { Capacitor } from "@capacitor/core";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React, { PropsWithChildren } from "react";
import { FileInput } from "../../commons/inputs/FileInput.js";
import {
  AttachmentPhotoUploaderWithEditor,
  AttachmentPhotoUploaderWithEditorRef,
} from "./AttachmentPhotoUploaderWithEditor.js";

interface Props {
  onComplete: (file: File) => void | Promise<void>;
  skipEdit?: boolean;
  label?: string;
  children?: React.ReactElement;
}

export const ButtonPhotoInput = React.forwardRef(
  (
    { onComplete, skipEdit, label, children }: PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const { t } = useTranslate("AttachmentField");
    const attachmentFieldRef = React.useRef<HTMLInputElement>(null);
    const attachmentPhotoUploaderRef =
      React.useRef<AttachmentPhotoUploaderWithEditorRef>(null);

    const handleClick = () => {
      Capacitor.isNativePlatform()
        ? attachmentPhotoUploaderRef.current?.start()
        : attachmentFieldRef.current?.click();
    };

    const button = children ? (
      React.cloneElement(children, {
        onClick: handleClick,
        ref,
      })
    ) : (
      <IconButton color="primary" size="small" onClick={handleClick} ref={ref}>
        <AddAPhotoIcon />
      </IconButton>
    );

    return Capacitor.isNativePlatform() ? (
      <>
        {button}
        <AttachmentPhotoUploaderWithEditor
          ref={attachmentPhotoUploaderRef}
          onFile={onComplete}
          skipEdit={skipEdit}
        />
      </>
    ) : (
      <>
        <Tooltip title={label ?? t("Upload file")}>{button}</Tooltip>

        <FileInput
          innerRef={attachmentFieldRef}
          accept="image/*,.heic,.heif"
          multiple={false}
          onComplete={onComplete}
        />
      </>
    );
  }
);
