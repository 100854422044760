/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { PublishQuoteButton_QuoteFragmentDoc, PublishQuoteButton_ProjectFragmentDoc } from './buttons/PublishQuoteButton.generated.js';
import { SendQuoteViaEmailButton_QuoteFragmentDoc, SendQuoteViaEmailButton_ProjectFragmentDoc } from './modals/SendQuoteViaEmailButton.generated.js';
export type QuoteAction_QuoteFragment = { __typename: 'Quote', id: string, viewerPermissions: Array<Types.PermissionName>, owningSystemOrganisationId: string, agreement: Types.Agreement, needsAgreement: boolean, isPublished: boolean, isBinding: boolean, expirationDate?: any | null, incomingRequestRecipientStatus?: Types.RequestRecipientStatus | null, sentAt?: any | null, title: string, createdAt: any, incomingRequest?: { __typename: 'Request', id: string, owningSystemOrganisationId: string, status: Types.RequestStatus } | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null }, contractee: { __typename: 'DocActor', id: string, isMyOrganisation: boolean, companyName: string, address?: { __typename: 'AddressSnapshot', city: string } | null }, contractor: { __typename: 'DocActor', id: string, address?: { __typename: 'AddressSnapshot', countryCode: string, postalCode: string, city: string, streetLines1: string } | null }, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export type QuoteAction_ProjectFragment = { __typename: 'Project', id: string, title: string, canInviteContractee: boolean, viewerPermissions: Array<Types.PermissionName>, crmOrganisation?: { __typename: 'CrmCompany', id: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string, email: any }> } | null, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, city: string } | null } | null, contracteeInvitations: Array<{ __typename: 'ProjectContracteeInvitation', id: string, contact: { __typename: 'InvitationContact', id: string } }> };

export const QuoteAction_QuoteFragmentDoc = gql`
    fragment QuoteAction_Quote on Quote {
  id
  viewerPermissions
  owningSystemOrganisationId
  incomingRequest {
    id
    owningSystemOrganisationId
  }
  agreement
  needsAgreement
  isPublished
  isBinding
  expirationDate
  incomingRequestRecipientStatus
  incomingRequest {
    id
    status
  }
  rootItem {
    id
    originVersionNumber
  }
  contractee {
    id
    isMyOrganisation
    address {
      city
    }
  }
  ...PublishQuoteButton_Quote
  ...SendQuoteViaEmailButton_Quote
}
    ${PublishQuoteButton_QuoteFragmentDoc}
${SendQuoteViaEmailButton_QuoteFragmentDoc}`;
export const QuoteAction_ProjectFragmentDoc = gql`
    fragment QuoteAction_Project on Project {
  id
  crmOrganisation {
    id
    email
  }
  building {
    id
    buildingAddress {
      id
      city
    }
  }
  ...PublishQuoteButton_Project
  ...SendQuoteViaEmailButton_Project
}
    ${PublishQuoteButton_ProjectFragmentDoc}
${SendQuoteViaEmailButton_ProjectFragmentDoc}`;