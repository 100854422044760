/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { BuildingRow__BuildingFragmentDoc } from '../../buildings/Buildings.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProjectModal_OrganisationDefaultsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateProjectModal_OrganisationDefaultsQuery = { organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultQuoteIsBinding: boolean, defaultContractType: Types.ContractType, defaultVerticalSettings: Array<{ __typename: 'OrganisationVerticalSetting', id: string, vertical: Types.Vertical, averageWorkSellingPrice: number }> } };

export type CreateProjectModal_CrmCompanyQueryVariables = Types.Exact<{
  crmCompanyId: Types.Scalars['ID']['input'];
}>;


export type CreateProjectModal_CrmCompanyQuery = { crmCompany: { __typename: 'CrmCompanyResult', crmCompany?: { __typename: 'CrmCompany', id: string, title: string, buildings: Array<{ __typename: 'Building', id: string, title: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, pathForPdf: string }>, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } }> } | null } | { __typename: 'MissingPermissions' } };

export type CreateProjectModal_CreateQuoteMutationVariables = Types.Exact<{
  input: Types.CreateQuoteInput;
}>;


export type CreateProjectModal_CreateQuoteMutation = { createQuote: { __typename: 'CreateQuoteResult', quote: { __typename: 'Quote', id: string }, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } } };

export type CreateProjectModal_CreateDocumentItemMutationVariables = Types.Exact<{
  input: Types.CreateItemsInput;
}>;


export type CreateProjectModal_CreateDocumentItemMutation = { createItems: { __typename: 'CreateItemsResult', items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> } };


export const CreateProjectModal_OrganisationDefaultsDocument = gql`
    query CreateProjectModal_OrganisationDefaults {
  organisationDefaults {
    ... on OrganisationDefaults {
      id
      defaultQuoteIsBinding
      defaultContractType
      defaultVerticalSettings {
        id
        vertical
        averageWorkSellingPrice
      }
    }
  }
}
    `;

/**
 * __useCreateProjectModal_OrganisationDefaultsQuery__
 *
 * To run a query within a React component, call `useCreateProjectModal_OrganisationDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectModal_OrganisationDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProjectModal_OrganisationDefaultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateProjectModal_OrganisationDefaultsQuery(baseOptions?: Apollo.QueryHookOptions<CreateProjectModal_OrganisationDefaultsQuery, CreateProjectModal_OrganisationDefaultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateProjectModal_OrganisationDefaultsQuery, CreateProjectModal_OrganisationDefaultsQueryVariables>(CreateProjectModal_OrganisationDefaultsDocument, options);
      }
export function useCreateProjectModal_OrganisationDefaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateProjectModal_OrganisationDefaultsQuery, CreateProjectModal_OrganisationDefaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateProjectModal_OrganisationDefaultsQuery, CreateProjectModal_OrganisationDefaultsQueryVariables>(CreateProjectModal_OrganisationDefaultsDocument, options);
        }
export function useCreateProjectModal_OrganisationDefaultsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CreateProjectModal_OrganisationDefaultsQuery, CreateProjectModal_OrganisationDefaultsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateProjectModal_OrganisationDefaultsQuery, CreateProjectModal_OrganisationDefaultsQueryVariables>(CreateProjectModal_OrganisationDefaultsDocument, options);
        }
export type CreateProjectModal_OrganisationDefaultsQueryHookResult = ReturnType<typeof useCreateProjectModal_OrganisationDefaultsQuery>;
export type CreateProjectModal_OrganisationDefaultsLazyQueryHookResult = ReturnType<typeof useCreateProjectModal_OrganisationDefaultsLazyQuery>;
export type CreateProjectModal_OrganisationDefaultsSuspenseQueryHookResult = ReturnType<typeof useCreateProjectModal_OrganisationDefaultsSuspenseQuery>;
export type CreateProjectModal_OrganisationDefaultsQueryResult = Apollo.QueryResult<CreateProjectModal_OrganisationDefaultsQuery, CreateProjectModal_OrganisationDefaultsQueryVariables>;
export const CreateProjectModal_CrmCompanyDocument = gql`
    query CreateProjectModal_CrmCompany($crmCompanyId: ID!) {
  crmCompany(id: $crmCompanyId) {
    ... on CrmCompanyResult {
      crmCompany {
        id
        title
        buildings {
          id
          ...BuildingRow__Building
          items {
            id
            originVersionNumber
            title
            pathForPdf
          }
        }
      }
    }
  }
}
    ${BuildingRow__BuildingFragmentDoc}`;

/**
 * __useCreateProjectModal_CrmCompanyQuery__
 *
 * To run a query within a React component, call `useCreateProjectModal_CrmCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectModal_CrmCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateProjectModal_CrmCompanyQuery({
 *   variables: {
 *      crmCompanyId: // value for 'crmCompanyId'
 *   },
 * });
 */
export function useCreateProjectModal_CrmCompanyQuery(baseOptions: Apollo.QueryHookOptions<CreateProjectModal_CrmCompanyQuery, CreateProjectModal_CrmCompanyQueryVariables> & ({ variables: CreateProjectModal_CrmCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateProjectModal_CrmCompanyQuery, CreateProjectModal_CrmCompanyQueryVariables>(CreateProjectModal_CrmCompanyDocument, options);
      }
export function useCreateProjectModal_CrmCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateProjectModal_CrmCompanyQuery, CreateProjectModal_CrmCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateProjectModal_CrmCompanyQuery, CreateProjectModal_CrmCompanyQueryVariables>(CreateProjectModal_CrmCompanyDocument, options);
        }
export function useCreateProjectModal_CrmCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CreateProjectModal_CrmCompanyQuery, CreateProjectModal_CrmCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateProjectModal_CrmCompanyQuery, CreateProjectModal_CrmCompanyQueryVariables>(CreateProjectModal_CrmCompanyDocument, options);
        }
export type CreateProjectModal_CrmCompanyQueryHookResult = ReturnType<typeof useCreateProjectModal_CrmCompanyQuery>;
export type CreateProjectModal_CrmCompanyLazyQueryHookResult = ReturnType<typeof useCreateProjectModal_CrmCompanyLazyQuery>;
export type CreateProjectModal_CrmCompanySuspenseQueryHookResult = ReturnType<typeof useCreateProjectModal_CrmCompanySuspenseQuery>;
export type CreateProjectModal_CrmCompanyQueryResult = Apollo.QueryResult<CreateProjectModal_CrmCompanyQuery, CreateProjectModal_CrmCompanyQueryVariables>;
export const CreateProjectModal_CreateQuoteDocument = gql`
    mutation CreateProjectModal_CreateQuote($input: CreateQuoteInput!) {
  createQuote(input: $input) {
    quote {
      id
    }
    rootItem {
      id
      originVersionNumber
    }
  }
}
    `;
export type CreateProjectModal_CreateQuoteMutationFn = Apollo.MutationFunction<CreateProjectModal_CreateQuoteMutation, CreateProjectModal_CreateQuoteMutationVariables>;

/**
 * __useCreateProjectModal_CreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateProjectModal_CreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectModal_CreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectModalCreateQuoteMutation, { data, loading, error }] = useCreateProjectModal_CreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectModal_CreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectModal_CreateQuoteMutation, CreateProjectModal_CreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectModal_CreateQuoteMutation, CreateProjectModal_CreateQuoteMutationVariables>(CreateProjectModal_CreateQuoteDocument, options);
      }
export type CreateProjectModal_CreateQuoteMutationHookResult = ReturnType<typeof useCreateProjectModal_CreateQuoteMutation>;
export type CreateProjectModal_CreateQuoteMutationResult = Apollo.MutationResult<CreateProjectModal_CreateQuoteMutation>;
export type CreateProjectModal_CreateQuoteMutationOptions = Apollo.BaseMutationOptions<CreateProjectModal_CreateQuoteMutation, CreateProjectModal_CreateQuoteMutationVariables>;
export const CreateProjectModal_CreateDocumentItemDocument = gql`
    mutation CreateProjectModal_CreateDocumentItem($input: CreateItemsInput!) {
  createItems(input: $input) {
    items {
      id
      originVersionNumber
    }
  }
}
    `;
export type CreateProjectModal_CreateDocumentItemMutationFn = Apollo.MutationFunction<CreateProjectModal_CreateDocumentItemMutation, CreateProjectModal_CreateDocumentItemMutationVariables>;

/**
 * __useCreateProjectModal_CreateDocumentItemMutation__
 *
 * To run a mutation, you first call `useCreateProjectModal_CreateDocumentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectModal_CreateDocumentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectModalCreateDocumentItemMutation, { data, loading, error }] = useCreateProjectModal_CreateDocumentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectModal_CreateDocumentItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectModal_CreateDocumentItemMutation, CreateProjectModal_CreateDocumentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectModal_CreateDocumentItemMutation, CreateProjectModal_CreateDocumentItemMutationVariables>(CreateProjectModal_CreateDocumentItemDocument, options);
      }
export type CreateProjectModal_CreateDocumentItemMutationHookResult = ReturnType<typeof useCreateProjectModal_CreateDocumentItemMutation>;
export type CreateProjectModal_CreateDocumentItemMutationResult = Apollo.MutationResult<CreateProjectModal_CreateDocumentItemMutation>;
export type CreateProjectModal_CreateDocumentItemMutationOptions = Apollo.BaseMutationOptions<CreateProjectModal_CreateDocumentItemMutation, CreateProjectModal_CreateDocumentItemMutationVariables>;