import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { omit } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import { CompileDocIsolatedExpressionResultDiagnosticAttribute } from "../../../../clients/graphqlTypes.js";
import {
  useAttributeExpressionModalQuery,
  useAttributeExpressionModal_DefineItemAttributeExpressionMutation,
} from "./AttributeExpressionModal.generated.js";
import { ExpressionModal } from "./ExpressionModal.js";
import { useExpressionValidationError } from "./useExpressionValidation.js";

interface Props
  extends Omit<
    React.ComponentProps<typeof ExpressionModal>,
    "handleSave" | "handleDelete" | "expressionError"
  > {
  fieldName: string;
  handleComplete?: (
    expression: string | undefined,
    handleClose: () => void
  ) => Promise<void> | void;
}

export function AttributeExpressionModal({
  projectId,
  docId,
  itemId,
  fieldName,
  handleClose,
  handleComplete,
  ...props
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const query = useAttributeExpressionModalQuery({
    client,
    variables: {
      projectId,
      docId,
      itemId,
    },
  });
  const item = getDataOrNull(query.data?.item);
  if (item && !("attributeExpressions" in item))
    throw new Error("Invalid item type");
  const attributeExpressions = item?.attributeExpressions ?? [];

  const relevantErrorPredicate = React.useCallback(
    (
      result: Parameters<Parameters<typeof useExpressionValidationError>[2]>[0]
    ) => "attribute" in result && result.attribute === fieldName,
    [fieldName]
  );

  const { expressionError, validateExpressions } = useExpressionValidationError(
    docId,
    itemId,
    relevantErrorPredicate
  );

  const [defineItemAttributeExpression] =
    useAttributeExpressionModal_DefineItemAttributeExpressionMutation({
      client,
    });

  const handleSave = async (expression: string) => {
    const result = await validateExpressions({
      docId,
      itemId,
      overrides: [
        { itemId, attribute: { attribute: fieldName, expr: expression } },
      ],
    });

    const expressionCheckResult =
      result.data?.compileDocIsolatedExpression.results.find(
        relevantErrorPredicate
      );

    if (expressionCheckResult) {
      console.error("compile document error", expressionCheckResult);
      enqueueSnackbar(
        `Expression is invalid: ${expressionCheckResult.messageText}`,
        { variant: "error" }
      );
      return;
    }

    await defineItemAttributeExpression({
      variables: {
        input: {
          projectId,
          docId,
          itemId,
          attributeExpressions: [
            ...attributeExpressions
              .filter(expr => expr.attribute !== fieldName)
              .map(a => omit(a, ["__typename", "missingValue", "result"])),
            {
              attribute: fieldName,
              expr: expression,
            },
          ],
        },
      },
    });

    if (handleComplete) {
      handleComplete(expression, handleClose);
    } else {
      handleClose();
    }
  };

  const handleDelete = async () => {
    await defineItemAttributeExpression({
      variables: {
        input: {
          projectId,
          docId,
          itemId,
          attributeExpressions: attributeExpressions
            ?.filter(expr => expr.attribute !== fieldName)
            .map(a => omit(a, ["__typename", "missingValue", "result"])),
        },
      },
    });

    if (handleComplete) {
      handleComplete(undefined, handleClose);
    } else {
      handleClose();
    }
  };

  return (
    <ExpressionModal
      {...props}
      projectId={projectId}
      docId={docId}
      itemId={itemId}
      expressionError={expressionError}
      handleClose={handleClose}
      handleSave={handleSave}
      handleDelete={handleDelete}
      isFullScreenLocalStorageKey="attributeExpressions"
    />
  );
}
