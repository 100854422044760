import { StyledComponent } from "@emotion/styled";
import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import React from "react";
import { transientOptions } from "../../styles.js"; // FIXME

interface PageColumnProps {
  $noPadding?: boolean;
  $hasBackground?: boolean;
  $hasLeftBorder?: boolean;
  $hasRightBorder?: boolean;
  $hasSelectedBorder?: boolean;
  $isSelected?: boolean;
  $isAbsolute?: boolean;
  $size?: "sm" | "md" | "lg";
  $zIndex?: number;
  $display?: React.CSSProperties["display"];
}

export const PageColumn: StyledComponent<
  MUIStyledCommonProps<Theme> & PageColumnProps,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled(
  "div",
  transientOptions
)<PageColumnProps>(
  ({
    theme,
    $noPadding,
    $display = "flex",
    $zIndex,
    $size = "lg",
    $isAbsolute,
    $isSelected,
    $hasSelectedBorder,
    $hasRightBorder,
    $hasLeftBorder,
    $hasBackground,
  }) => ({
    boxSizing: "border-box",
    display: $display,
    flexDirection: "column",
    flexGrow: 0,
    flexShrink: 0,
    position: "relative",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      overflow: "auto",
      padding: theme.spacing(2),
      minWidth: `${theme.layout.columnWidth[$size]}px`,
      maxWidth: `${theme.layout.columnWidth[$size]}px`,
      width: `${theme.layout.columnWidth[$size]}px`,
      flexBasis: `${theme.layout.columnWidth[$size]}px`,
      boxShadow:
        [
          $hasRightBorder ? `inset -1px 0 0 ${theme.palette.grey[300]}` : null,
          $hasLeftBorder ? `inset 1px 0 0 ${theme.palette.grey[300]}` : null,
        ]
          .filter(Boolean)
          .join(", ") || "none",
      ...($noPadding ? { padding: 0 } : {}),
      ...($isAbsolute
        ? {
            position: "absolute",
            top: 0,
            bottom: 0,
            zIndex: 5,
            left: `${theme.layout.columnWidth.lg}px`, // FIXME should not be dependant on column size!!!
          }
        : {}),
      ...($zIndex ? { zIndex: $zIndex } : {}),
      ...($hasBackground
        ? { backgroundColor: theme.palette.background.default }
        : {}),
      ...($isSelected
        ? {
            marginLeft: "-1px" /* cover border at the left */,
            backgroundColor: theme.palette.background.default,
          }
        : {}),
      ...($isSelected && !$hasSelectedBorder ? { boxShadow: "none" } : {}),
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: theme.spacing(2),
      width: "100%",
      ...($noPadding ? { padding: 0 } : {}),
    },
  })
);

PageColumn.defaultProps = {
  className: "msys-container",
};
