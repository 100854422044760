import { StateStore } from "@msys/ui";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium.js";
import { useUserPreference } from "./useUserPreference.js";

// here we make `useUserPreference` compatible with StateStore
export const useDataGridStateStore = (key: string): StateStore => {
  return useUserPreference<GridInitialStatePremium | undefined>(
    `DataGrid-${key}`,
    undefined
  );
};
