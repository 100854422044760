import { useScreenWidth } from "@msys/ui";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { SelectInfoMessageItem } from "../../features/doc-items/items/SelectInfoMessageItem.js";
import { BuildingEdit } from "./BuildingEdit.js";
import { BuildingEditItem } from "./BuildingEditItem.js";
import { Buildings } from "./Buildings.js";
import { RestrictedByCapability } from "../../auth/RestrictedByCapability.js";

export const BuildingsRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <RestrictedByCapability capability="BUILDINGS">
            <Buildings />
          </RestrictedByCapability>
        }
      />
      <Route path={":buildingId/*"} element={<BuildingRoutes />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const BuildingRoutes = () => {
  const { buildingId } = useParams();
  if (!buildingId) throw new Error("Building id is missing");

  const { isMinTablet } = useScreenWidth();

  return (
    <Routes>
      <Route index element={<Navigate to="edit" replace />} />
      <Route path={"edit"} element={<BuildingEdit />}>
        {isMinTablet && <Route index element={<SelectInfoMessageItem />} />}
        <Route
          path={"items/:itemId"}
          element={
            <BuildingEditItem pathToDocPage={`/buildings/${buildingId}/edit`} />
          }
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
