import { useApolloClient } from "@apollo/client";
import { MenuItemWithIcon } from "@msys/ui";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useQuoteRecomputeExpressionDependencyGraphMutation } from "./QuoteRecomputeExpressionDependencyGraphButton.generated.js";

interface Props {
  projectId: string;
  docId: string;
  refetchQueries?: string[];
}

export function QuoteRecomputeExpressionDependencyGraphButton({
  projectId,
  docId,
  refetchQueries,
}: Props) {
  const { t } = useTranslate("Quote");

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [recomputeExpressionDependencyGraph, { loading }] =
    useQuoteRecomputeExpressionDependencyGraphMutation({
      client,
      refetchQueries,
    });

  return (
    <MenuItemWithIcon
      icon={<ScatterPlotIcon />}
      onClick={async () => {
        try {
          await recomputeExpressionDependencyGraph({
            variables: {
              input: {
                docId: docId,
                projectId: projectId,
              },
            },
          });
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, {
              variant: "error",
            });
        }
      }}
      disabled={loading}
    >
      {t("Recompute expressions", { ns: "Quote" })}
    </MenuItemWithIcon>
  );
}
