import { Capabilities } from "@msys/common";
import { noop } from "lodash-es";
import React from "react";
import { PermissionName } from "../../clients/graphqlTypes.js";

export interface SidebarGroup {
  name: string;
  items: SidebarItem[];
  capability?: Capabilities;
  permission?: PermissionName;
}

export interface SidebarItem {
  name: string;
  icon: React.ReactNode;
  label: string;
  path: string;
  activePaths?: string[];
  capability?: Capabilities;
  permission?: PermissionName;
}

interface SidebarContextType {
  isSidebarOpened: boolean;
  setIsSidebarOpened: (isOpened: boolean) => void;
  isSidebarPinned: boolean;
  toggleSidebarPinned: () => void;
  sidebarGroups: SidebarGroup[];
  setSidebarGroups: React.Dispatch<React.SetStateAction<SidebarGroup[]>>;
}

const SidebarContext = React.createContext<SidebarContextType>({
  isSidebarOpened: false,
  setIsSidebarOpened: noop,
  isSidebarPinned: false,
  toggleSidebarPinned: noop,
  sidebarGroups: [],
  setSidebarGroups: noop,
});

export const SidebarProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [sidebarGroups, setSidebarGroups] = React.useState<SidebarGroup[]>([]);
  const [isSidebarOpened, setIsSidebarOpened] = React.useState(false);
  const [isSidebarPinned, setSidebarPinned] = React.useState(false);
  const toggleSidebarPinned = React.useCallback(
    () => setSidebarPinned(isSidebarPinned => !isSidebarPinned),
    []
  );

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpened,
        setIsSidebarOpened,
        isSidebarPinned,
        toggleSidebarPinned,
        sidebarGroups,
        setSidebarGroups,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  return React.useContext(SidebarContext);
};

export const useSidebarGroups = (sidebarGroups: SidebarGroup[]) => {
  const { setSidebarGroups } = React.useContext(SidebarContext);

  return React.useEffect(() => {
    setSidebarGroups(sidebarGroups);
    return () => setSidebarGroups([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarGroups]);
};
