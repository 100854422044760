import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { AddAPhoto as AddAPhotoIcon } from "@mui/icons-material";
import { Check as CheckIcon } from "@mui/icons-material";
import { ThumbDown as ThumbDownIcon } from "@mui/icons-material";
import { ThumbUp as ThumbUpIcon } from "@mui/icons-material";
import { Fab, StyledEngineProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React, { Suspense, useRef } from "react";
import { AttachmentInput } from "../../../clients/graphqlTypes.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { ReactComponent as QAIcon } from "../../assets/icons/icon-qa.svg";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { useUserData } from "../../auth/useUserData.js";
import { Stack } from "../../commons/layout/Stack.js";
import {
  AddTaskWorkSessionsProcess,
  AddTaskWorkSessionsProcessRef,
} from "../work-sessions/AddTaskWorkSessionsProcess.js";
import {
  PhotoApprovalProcess,
  PhotoApprovalProcessRef,
} from "./PhotoApprovalProcess.js";
import { useTaskManagementButtonsQuery } from "./TaskManagementButtons.generated.js";
import {
  useApproveTaskItemMutation,
  useDisapproveTaskItemMutation,
  usePhotoApproveTaskItemMutation,
  useQaApproveTaskItemMutation,
  useQaDisapproveTaskItemMutation,
  useSetTaskItemDoneMutation,
} from "./Tasks.generated.js";
import { DoneModalRef } from "./modals/DoneModal.js";

const DoneModal = React.lazy(() => import("./modals/DoneModal.js"));

interface Props {
  itemId: string;
  docId: string;
  projectId: string;
}

export const TaskManagementButtons = ({ docId, itemId, projectId }: Props) => {
  const { t } = useTranslate("QuoteItemTaskManagementBox");
  const photoApprovalRef = useRef<PhotoApprovalProcessRef>(null);
  const addTaskWorkSessionsRef = useRef<AddTaskWorkSessionsProcessRef>(null);
  const { enqueueSnackbar } = useSnackbar();
  const doneRef = useRef<DoneModalRef>(null);

  const client = useApolloClient();
  const query = useTaskManagementButtonsQuery({
    client,
    variables: {
      projectId,
      docId,
      itemId,
    },
  });
  const [setTaskItemDoneMutation, { loading: setTaskItemDoneLoading }] =
    useSetTaskItemDoneMutation({ client });
  const [
    photoApproveTaskItemMutation,
    { loading: photoApproveTaskItemLoading },
  ] = usePhotoApproveTaskItemMutation({ client });
  const [approveTaskItemMutation, { loading: approveTaskItemLoading }] =
    useApproveTaskItemMutation({ client });
  const [disapproveTaskItemMutation, { loading: disapproveTaskItemLoading }] =
    useDisapproveTaskItemMutation({ client });
  const [qaApproveTaskItemMutation, { loading: qaApproveTaskItemLoading }] =
    useQaApproveTaskItemMutation({ client });
  const [
    qaDisapproveTaskItemMutation,
    { loading: qaDisapproveTaskItemLoading },
  ] = useQaDisapproveTaskItemMutation({ client });
  const viewer = useUserData().currentUser!;

  const photoApproveTaskItem = async (
    itemId: string,
    docId: string,
    photo: AttachmentInput
  ) => {
    try {
      await photoApproveTaskItemMutation({
        variables: { input: { projectId, docId, itemId, photo } },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const areAnimationsEnabled = useFeature("Animations");

  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);
  const project = getDataOrNull(query.data?.project)?.project;
  const task = project?.tasks?.[0];
  const item = task?.items?.[0];

  if (!project) return null;
  if (!item) return null;
  if (!organisationDefaults) return null;

  if (!item.canBeWorkedOn || item.deletedAt) return null;

  const renderDoneButton = !item.isDone;

  const renderApprovePhotoButton =
    item.isDone && item.photoApprovalRequired && !item.photoApproved;

  const renderApproveQAButton =
    item.isDone &&
    (!item.photoApprovalRequired ||
      (item.photoApprovalRequired && item.photoApproved)) &&
    item.qaApprovalRequired &&
    item.isQaApproved !== true;

  const renderApproveButton =
    item.isDone &&
    (!item.photoApprovalRequired ||
      (item.photoApprovalRequired && item.photoApproved)) &&
    (!item.qaApprovalRequired ||
      (item.qaApprovalRequired && item.isQaApproved === true)) &&
    item.approvalRequired &&
    item.isApproved !== true;
  // item.viewerCanDoClientInspection;

  const onBeforeTaskDone = async (itemId: string, projectId: string) => {
    if (organisationDefaults.defaultTaskPhotoIsRequired) {
      const areAllPhotosAdded: boolean =
        (await photoApprovalRef.current?.checkPhotoApprove(
          itemId,
          docId,
          true
        )) ?? true;
      if (!areAllPhotosAdded) {
        enqueueSnackbar(
          t("Please upload all photos to mark task as completed"),
          { variant: "error" }
        );
        return false;
      }
    }
    return true;
  };

  const onAfterTaskDone = async (itemId: string, projectId: string) => {
    await doneRef.current?.open();
    if (!organisationDefaults.defaultTaskPhotoIsRequired) {
      await photoApprovalRef.current?.checkPhotoApprove(itemId, docId, true);
    }
    await addTaskWorkSessionsRef.current?.addWorkSessions(
      itemId,
      docId,
      projectId
    );
  };

  return (
    <>
      {renderDoneButton ? (
        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={task}
        >
          <StickyButtonContainer>
            <Fab
              color="secondary"
              variant="extended"
              size="large"
              disabled={setTaskItemDoneLoading}
              onClick={async () => {
                const isAllowed = await onBeforeTaskDone(itemId, projectId);
                if (!isAllowed) return;
                try {
                  await setTaskItemDoneMutation({
                    variables: { input: { projectId, itemId, docId } },
                  });
                } catch (e) {
                  if (e instanceof Error)
                    enqueueSnackbar(e.message, { variant: "error" });
                }
                await onAfterTaskDone(itemId, projectId);
              }}
            >
              <CheckIcon style={{ marginRight: "10px" }} />
              {t("Mark as done")}
            </Fab>
          </StickyButtonContainer>
        </RestrictedByDocumentPermissionWithDebug>
      ) : renderApprovePhotoButton ? (
        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={task}
        >
          <StickyButtonContainer
            onClick={() => {
              photoApprovalRef.current?.checkPhotoApprove(itemId, docId, false);
            }}
          >
            <Fab
              color="secondary"
              variant="extended"
              size="large"
              disabled={photoApproveTaskItemLoading}
            >
              <AddAPhotoIcon style={{ marginRight: "10px" }} />
              {t("Add completion photo")}
            </Fab>
          </StickyButtonContainer>
        </RestrictedByDocumentPermissionWithDebug>
      ) : renderApproveQAButton ? (
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_TASK"
          document={task}
        >
          <StyledEngineProvider injectFirst>
            <StickyButtonContainer>
              <Fab
                variant="extended"
                size="large"
                onClick={async () => {
                  try {
                    await qaDisapproveTaskItemMutation({
                      variables: { input: { projectId, itemId, docId } },
                    });
                  } catch (e) {
                    if (e instanceof Error)
                      enqueueSnackbar(e.message, { variant: "error" });
                  }
                }}
                sx={{
                  backgroundColor: "error.main",
                  color: "common.white",
                  "&:hover:not(.Mui-disabled)": {
                    backgroundColor: "error.dark",
                  },
                }}
                disabled={
                  qaDisapproveTaskItemLoading || qaApproveTaskItemLoading
                }
              >
                <QAIcon style={{ marginRight: "10px" }} />
                {t("Failed")}
              </Fab>
              <Fab
                variant="extended"
                size="large"
                onClick={async () => {
                  try {
                    await qaApproveTaskItemMutation({
                      variables: { input: { projectId, itemId, docId } },
                    });
                  } catch (e) {
                    if (e instanceof Error)
                      enqueueSnackbar(e.message, { variant: "error" });
                  }
                }}
                sx={{
                  backgroundColor: "success.main",
                  color: "common.white",
                  "&:hover:not(.Mui-disabled)": {
                    backgroundColor: "success.dark",
                  },
                }}
              >
                <QAIcon style={{ marginRight: "10px" }} />
                {t("Passed")}
              </Fab>
            </StickyButtonContainer>
          </StyledEngineProvider>
        </RestrictedByDocumentPermissionWithDebug>
      ) : renderApproveButton ? (
        <RestrictedByDocumentPermissionWithDebug
          permission="DECIDE_QUOTES"
          document={task}
        >
          <StyledEngineProvider injectFirst>
            <StickyButtonContainer>
              <Fab
                variant="extended"
                size="large"
                onClick={async () => {
                  try {
                    await disapproveTaskItemMutation({
                      variables: { input: { projectId, itemId, docId } },
                    });
                  } catch (e) {
                    if (e instanceof Error)
                      enqueueSnackbar(e.message, { variant: "error" });
                  }
                }}
                sx={{
                  backgroundColor: "error.main",
                  color: "common.white",
                  "&:hover:not(.Mui-disabled)": {
                    backgroundColor: "error.dark",
                  },
                }}
                disabled={disapproveTaskItemLoading || approveTaskItemLoading}
              >
                <ThumbDownIcon style={{ marginRight: "10px" }} />
                {t("Failed")}
              </Fab>
              <Fab
                variant="extended"
                size="large"
                onClick={async () => {
                  try {
                    await approveTaskItemMutation({
                      variables: { input: { projectId, itemId, docId } },
                    });
                  } catch (e) {
                    if (e instanceof Error)
                      enqueueSnackbar(e.message, { variant: "error" });
                  }
                }}
                sx={{
                  backgroundColor: "success.main",
                  color: "common.white",
                  "&:hover:not(.Mui-disabled)": {
                    backgroundColor: "success.dark",
                  },
                }}
              >
                <ThumbUpIcon style={{ marginRight: "10px" }} />
                {t("Passed")}
              </Fab>
            </StickyButtonContainer>
          </StyledEngineProvider>
        </RestrictedByDocumentPermissionWithDebug>
      ) : null}

      <Suspense fallback={null}>
        <DoneModal ref={doneRef} enabled={areAnimationsEnabled} />
      </Suspense>

      <RestrictedByDocumentPermissionWithDebug
        permission="EXECUTE_TASK"
        document={task}
      >
        <PhotoApprovalProcess
          ref={photoApprovalRef}
          loading={photoApproveTaskItemLoading}
          photoApproveTaskItem={photoApproveTaskItem}
          projectId={project.id}
        />
      </RestrictedByDocumentPermissionWithDebug>

      <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={task}
        >
          <AddTaskWorkSessionsProcess ref={addTaskWorkSessionsRef} />
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    </>
  );
};

const StickyButtonContainer = styled(Stack)`
  bottom: 0;
  padding-bottom: 8px;
  position: sticky;
  justify-content: center;
  z-index: 3;
`;
