import { Modal, useScreenWidth } from "@msys/ui";
import { Box, DialogContentText, styled } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { noop, omit } from "lodash-es";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import {
  Agreement,
  AttachmentInput,
  PermissionName,
} from "../../../../clients/graphqlTypes.js";
import { useGlobalLoadingCounter } from "../../../../common/global-loading-state/useGlobalLoadingCounter.js";
import { VirtualBareTreeStandaloneItem } from "../../../trees-virtual/components/VirtualBareTreeStandaloneItem.js";
import { AttachmentPhotoDragAndDropField } from "../../attachments/AttachmentPhotoDragAndDropField.js";
import { uploadFileToUploadCare } from "../../attachments/helpers.js";
import { createTreeSimpleItem } from "../trees.js";
import { PhotoApprovalMultipleModalFragment } from "./PhotoApprovalMultipleModal.generated.js";

interface Props {
  items:
    | (PhotoApprovalMultipleModalFragment & {
        docAgreement: Agreement;
        docViewerPermissions: PermissionName[];
      })[]
    | null;
  allDocItems:
    | (PhotoApprovalMultipleModalFragment & {
        docAgreement: Agreement;
        docViewerPermissions: PermissionName[];
      })[]
    | null;
  photoApproveTaskItem: (
    itemId: string,
    docId: string,
    photo: AttachmentInput
  ) => void;
  loading?: boolean;
  handleClose: () => void;
  handleComplete: (areAllPhotosAdded: boolean) => void;
}

export const PhotoApprovalMultipleModal = ({
  items,
  allDocItems,
  loading = false,
  handleClose,
  handleComplete,
  photoApproveTaskItem,
}: Props) => {
  const [index, setIndex] = useState<number | null>(0);
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const { t } = useTranslate(["QuoteItemTaskManagementBox", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const { increment, decrement } = useGlobalLoadingCounter();
  const { isMaxPhone } = useScreenWidth();

  const addedPhotosCount = useRef<number>(0);

  const TreeItem = React.useMemo(
    () =>
      createTreeSimpleItem({
        pathToDocPage: null,
        docAgreement: "YES",
        navigateToItem: noop,
        setItemExpanded: noop,
      }),
    []
  );

  const onSave = async () => {
    if (!file || !items || index === null) return;
    const item = items[index];
    try {
      setUploading(true);
      increment();
      const attachment = await uploadFileToUploadCare(file);
      await photoApproveTaskItem(
        item.id,
        item.docId,
        omit(attachment, "id", "__type")
      );
      addedPhotosCount.current += 1;
      if (index === items.length - 1) {
        handleComplete(addedPhotosCount.current === items.length);
        handleClose();
      } else {
        setFile(null);
        setIndex(index + 1);
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setUploading(false);
      decrement();
    }
  };

  return items && allDocItems ? (
    <Modal
      key={`modal-approve-${index ? items[index].id : "start"}`}
      title={
        index === null
          ? t("Subtasks photo required", {
              ns: "QuoteItemTaskManagementBox",
            })
          : `${t("Add a completion photo", {
              ns: "QuoteItemTaskManagementBox",
            })} ${index + 1}/${items.length}`
      }
      dialogProps={{ maxWidth: "xs" }}
      handleClose={handleClose}
      actionButtons={
        index === null
          ? [
              {
                label: isMaxPhone
                  ? t("No", {
                      ns: "Global",
                    })
                  : t("Not now", {
                      ns: "QuoteItemTaskManagementBox",
                    }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Yes, let's add it", {
                  ns: "QuoteItemTaskManagementBox",
                }),
                handleClick: () => setIndex(0),
              },
            ]
          : [
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: {
                  variant: "text",
                  disabled: loading || uploading,
                },
              },
              {
                label:
                  index === items.length - 1
                    ? t("Done", {
                        ns: "Global",
                      })
                    : t("Save & next", {
                        ns: "Global",
                      }),
                handleClick: onSave,
                buttonProps: {
                  loading: loading || uploading,
                  disabled: !file || loading || uploading,
                },
              },
            ]
      }
    >
      <Container>
        {index === null ? (
          <>
            <DialogContentText>
              {t(
                "{number} subtasks of this item require a photo as a proof of completion.",
                {
                  ns: "QuoteItemTaskManagementBox",
                  number: `${items.length}`,
                }
              )}
            </DialogContentText>
            <DialogContentText>
              {t("Do you want to add them now?", {
                ns: "QuoteItemTaskManagementBox",
              })}
            </DialogContentText>
          </>
        ) : (
          <>
            <DialogContentText>
              {t(
                "{number} subtasks of this item require a photo as a proof of completion.",
                {
                  ns: "QuoteItemTaskManagementBox",
                  number: `${items.length}`,
                }
              )}
            </DialogContentText>
            <Box mb={1}>
              <VirtualBareTreeStandaloneItem
                key={items[index].id}
                item={items[index]}
                items={allDocItems}
                depth={0}
                itemComponent={TreeItem}
              />
            </Box>
            <AttachmentPhotoDragAndDropField onFile={setFile} />
          </>
        )}
      </Container>
    </Modal>
  ) : null;
};

const Container = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
});
