import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  LabeledValueHorizontal,
  LoadingSpinner,
  MenuButton,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import {
  Badge,
  Divider,
  Link,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageFullScreenModal } from "../../commons/layout/PageFullScreenModal.js";
import { useProjectEmailPreviewQuery } from "./ProjectEmailPreview.generated.js";

interface Props {
  projectId: string;
}

export const ProjectEmailPreview = ({ projectId }: Props) => {
  const { emailId } = useParams();
  if (!emailId) throw new Error("Email id is missing");

  const navigate = useNavigate();
  const { t } = useTranslate(["ProjectPageTopbar", "Email"]);
  const { isMinTablet } = useScreenWidth();
  const { getFormattedDateTime } = useFormatting();

  const client = useApolloClient();
  const query = useProjectEmailPreviewQuery({
    client,
    variables: { messageId: emailId },
  });
  const email = getDataOrNull(query.data?.crmEmailMessage);

  const preview = query.loading ? (
    <LoadingSpinner />
  ) : !email ? null : (
    <Paper>
      <Stack padding={2} spacing={2}>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Typography variant="h3">{email.subject}</Typography>
          <Typography variant="body2">
            {getFormattedDateTime(email.createdAt)}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent={"space-between"}>
          <Stack>
            <LabeledValueHorizontal
              label={t("From", {
                ns: "Email",
              })}
            >
              {email.from}
            </LabeledValueHorizontal>
            <LabeledValueHorizontal
              label={t("To", {
                ns: "Email",
              })}
            >
              {email.to}
            </LabeledValueHorizontal>
          </Stack>
          {email.attachments.length > 0 && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MenuButton
                Icon={
                  <Badge
                    badgeContent={email.attachments.length}
                    color="secondary"
                  >
                    <AttachFileIcon />
                  </Badge>
                }
              >
                {email.attachments.map(attachment => (
                  <MenuItem>
                    <Link
                      href={attachment.url}
                      target="_blank"
                      referrerPolicy="no-referrer"
                      download
                    >
                      {attachment.filename}
                    </Link>
                  </MenuItem>
                ))}
              </MenuButton>
            </Stack>
          )}
        </Stack>
        <Divider />
        <Stack>
          <Typography variant={"body2"} whiteSpace={"pre-wrap"}>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(email.body, {
                  allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                    "img",
                  ]),
                  allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat([
                    "data",
                  ]),
                }),
              }}
            />
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );

  if (isMinTablet) {
    return <PageContainer>{preview}</PageContainer>;
  }

  return (
    <PageFullScreenModal
      title={t("Email", { ns: "ProjectPageTopbar" })}
      onCloseButtonClick={
        () => navigate(`/projects/${projectId}/communication/emails`) // FIXME: don't hardcode path
      }
      noPadding
    >
      {preview}
    </PageFullScreenModal>
  );
};
