import { Modal } from "@msys/ui";
import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import {
  SupplierCatalogueSelect,
  SupplierCatalogueValue,
} from "../../suppliers/SupplierCatalogueSelect.js";

interface FormValues {
  productType: "one-time" | "catalogue";
  supplierCatalogue?: SupplierCatalogueValue | null;
}

export interface ProductCreateModalProps {
  handleComplete: (value: FormValues) => Promise<void> | void;
  handleClose: () => void;
}

export const ProductAskTypeModal = ({
  handleComplete,
  handleClose,
}: ProductCreateModalProps) => {
  const { t } = useTranslate(["Global", "Product"]);

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        productType: Yup.string().oneOf(["one-time", "catalogue"]).required(),
        supplierCatalogue: Yup.object()
          .shape({
            id: Yup.string(),
            supplier: Yup.object().shape({
              id: Yup.string(),
            }),
          })
          .when("productType", {
            is: (productType: string) => productType === "catalogue",
            then: schema => schema.required(),
          })
          .nullable()
          .label(t("Supplier catalogue", { ns: "Product" })),
      }),
    [t]
  );

  const initialValues: FormValues = {
    productType: "catalogue",
    supplierCatalogue: null,
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        await handleComplete(values);
        handleClose();
      }}
      validateOnMount
    >
      {formikProps => {
        return (
          <Modal
            title={t("Add product to supplier catalogue?", { ns: "Product" })}
            handleClose={handleClose}
            dialogProps={{
              maxWidth: "sm",
            }}
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Confirm", {
                  ns: "Global",
                }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  disabled: !formikProps.isValid,
                  loading: formikProps.isSubmitting,
                },
              },
            ]}
          >
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                <FormControlLabel
                  style={{ marginLeft: 0, marginRight: 0 }}
                  name="productType"
                  control={
                    <Radio
                      name="productType"
                      value="catalogue"
                      checked={formikProps.values.productType === "catalogue"}
                      onChange={(e, checked) => {
                        if (checked)
                          formikProps.setFieldValue("productType", "catalogue");
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {t("Yes", { ns: "Global" })}
                    </Typography>
                  }
                />
                {formikProps.values.productType === "catalogue" && (
                  <Box pl="42px">
                    <SupplierCatalogueSelect
                      required={true}
                      disabled={formikProps.isSubmitting}
                      supplierCatalogueId={
                        formikProps.values.supplierCatalogue?.id ?? null
                      }
                      onChange={value => {
                        formikProps.setFieldValue("supplierCatalogue", value);
                      }}
                      preselectSingle
                    />
                  </Box>
                )}
                <FormControlLabel
                  style={{ marginLeft: 0, marginRight: 0 }}
                  name="productType"
                  control={
                    <Radio
                      name="productType"
                      value="one-time"
                      checked={formikProps.values.productType === "one-time"}
                      onChange={(e, checked) => {
                        if (checked)
                          formikProps.setFieldValue("productType", "one-time");
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {t("No, create product for one-time use", {
                        ns: "Product",
                      })}
                    </Typography>
                  }
                />
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export interface ProductAskTypeProcessRef {
  open: () => Promise<FormValues | null>;
}

export const ProductAskTypeProcess = React.forwardRef(
  (_: {}, forwardedRef: React.Ref<ProductAskTypeProcessRef>) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const resolveRef = React.useRef<
      ((values: FormValues | null) => void) | null
    >(null);

    React.useImperativeHandle(forwardedRef, () => ({
      open: () =>
        new Promise<FormValues | null>(resolve => {
          resolveRef.current = resolve;
          setIsOpen(true);
        }),
    }));

    return isOpen ? (
      <ProductAskTypeModal
        handleComplete={(values: FormValues) => {
          if (resolveRef.current) {
            resolveRef.current(values);
            resolveRef.current = null;
          }
        }}
        handleClose={() => {
          setIsOpen(false);
          if (resolveRef.current) {
            resolveRef.current(null);
            resolveRef.current = null;
          }
        }}
      />
    ) : null;
  }
);
