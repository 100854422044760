import {
  Modal,
  ModalContent,
  ModalDialog,
  ModalOpenButton,
  ModalOpenProcess,
  ModalOpenProcessRef,
  Select,
  SelectOption,
  useLocalStorageAsState,
} from "@msys/ui";
import { Button, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";
import { AskWhen, AskWhom } from "../../../../clients/graphqlTypes.js";
import WizardIcon from "@mui/icons-material/AutoFixHigh";
import { QuoteCreateWizardSection } from "../../quotes/QuoteCreateWizardSection.js";
import { useSnackbar } from "notistack";

interface Props {
  templateId: string;
}

export function TemplateDecisionExternalWizardPreview({ templateId }: Props) {
  const { t } = useTranslate(["Quote"]);
  return (
    <ModalOpenButton
      Modal={ExternalWizardConfigurePreviewModal}
      modalProps={{ templateId }}
    >
      <Button
        startIcon={<WizardIcon />}
        variant={"contained"}
        color={"secondary"}
      >
        {t("Wizard preview", { ns: "Quote" })}
      </Button>
    </ModalOpenButton>
  );
}

function ExternalWizardConfigurePreviewModal({
  templateId,
  handleClose,
}: {
  templateId: string;
  handleClose: () => void;
}) {
  const { t } = useTranslate(["Decisions", "Global", "Templates", "Quote"]);

  const [{ askWhen, askWhom, sourceDocType }, setState] =
    useLocalStorageAsState<{
      askWhen: AskWhen;
      askWhom: AskWhom;
      sourceDocType: "template" | "template-unpublished";
    }>(
      `msys-external-wizard-preview-state`,
      {
        askWhen: "onQuoteCreate",
        askWhom: "contractor",
        sourceDocType: "template",
      },
      true
    );

  const askWhenOptions = [
    {
      value: "onQuoteCreate",
      label: t("Quote create wizard", { ns: "Decisions" }),
    },
    {
      value: "onQuoteRefinement",
      label: t("Quote refinement wizard", { ns: "Decisions" }),
    },
    {
      value: "onEmbeddableWizard",
      label: t("Embeddable wizard", { ns: "Decisions" }),
    },
  ] as SelectOption<AskWhen>[];

  const askWhomOptions = [
    { value: "contractor", label: t("Contractor", { ns: "Global" }) },
    { value: "contractee", label: t("Contractee", { ns: "Global" }) },
  ] as SelectOption<AskWhom>[];

  const sourceDocTypeOptions = [
    { value: "template", label: t("Published version", { ns: "Decisions" }) },
    {
      value: "template-unpublished",
      label: t("Unpublished version", { ns: "Decisions" }),
    },
  ] as SelectOption<"template" | "template-unpublished">[];

  const externalWizardPreviewRef = React.useRef<ModalOpenProcessRef>();

  return (
    <Modal
      handleClose={handleClose}
      maxWidth="xs"
      title={t("Configure wizard", { ns: "Decisions" })}
      actionButtons={[
        {
          label: t("Close", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Preview", { ns: "Global" }),
          handleClick: () => {
            externalWizardPreviewRef.current?.open();
          },
        },
      ]}
    >
      <Stack direction="column" spacing={1}>
        <Select
          fullWidth
          label={t("Ask whom", { ns: "Decisions" })}
          onChange={askWhom => setState(s => ({ ...s, askWhom }))}
          value={askWhom}
          options={askWhomOptions}
        />
        <Select
          fullWidth
          label={t("Ask when", { ns: "Decisions" })}
          onChange={askWhen => setState(s => ({ ...s, askWhen }))}
          value={askWhen}
          options={askWhenOptions}
        />
        <Select
          fullWidth
          label={t("Template publishing", { ns: "Decisions" })}
          onChange={sourceDocType => setState(s => ({ ...s, sourceDocType }))}
          value={sourceDocType}
          options={sourceDocTypeOptions}
        />
      </Stack>
      <ModalOpenProcess
        ref={externalWizardPreviewRef}
        Modal={ExternalWizardPreviewModal}
        modalProps={{
          askWhen,
          askWhom,
          sourceDocType,
          templateId,
        }}
      />
    </Modal>
  );
}

function ExternalWizardPreviewModal({
  askWhen,
  askWhom,
  sourceDocType,
  templateId,
  handleClose,
}: {
  askWhen: AskWhen;
  askWhom: AskWhom;
  sourceDocType: "template" | "template-unpublished";
  templateId: string;
  handleClose: () => void;
}) {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Decisions"]);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <ModalDialog maxWidth="sm">
      <ModalContent
        contentProps={{
          sx: {
            paddingTop: 1,
          },
        }}
      >
        <QuoteCreateWizardSection
          wizardConfiguration={{
            showCardImage: askWhom === "contractee" ? "true" : "false",
            navigationType: null,
            questionStrategy: {
              type: "questionControl",
              askWhen: askWhen,
              askWhom: askWhom,
              startingNode: { type: "root" },
            },
            requestInitialAnswers: "false",
            sourceDoc: {
              type: sourceDocType,
              templateId: templateId,
              contractorId: viewer.organisation.id,
            },
            showCloseButton: "true",
          }}
          initialAnswers={[]}
          onAnswer={async answers => void 0}
          onComplete={async result => {
            enqueueSnackbar(t("Wizard completed", { ns: "Decisions" }));
            handleClose();
          }}
          onClose={handleClose}
        />
      </ModalContent>
    </ModalDialog>
  );
}
