import { FormControlLabel, Radio } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { VirtualItem } from "../../../trees-virtual/types.js";

export const SubitemsOptionsNoneSection = <T extends VirtualItem>({
  disabled = false,
  loading = false,
  handleDecisionPreselectionChange,
  item,
  canEdit,
}: {
  disabled?: boolean;
  loading?: boolean;
  handleDecisionPreselectionChange: (
    itemId: string,
    subItemIds: string[]
  ) => void | Promise<void>;
  item: T;
  canEdit: boolean;
}) => {
  const { t } = useTranslate("QuoteItem");

  return item.decisionBehaviorOfSubitems === "SELECT_ONE" ? (
    <FormControlLabel
      onClick={e => {
        e.stopPropagation();
      }}
      control={
        <Radio
          size="small"
          disabled={disabled || loading || !canEdit}
          checked={item.decisionSubitemsPreselection.length === 0}
          onClick={() => handleDecisionPreselectionChange(item.id, [])}
          sx={{ my: "-7px", mr: "4px" }}
        />
      }
      label={t("None of the options")}
    />
  ) : null;
};
