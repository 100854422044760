import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSelectedUser } from "./useSelectedUser.js";

export const SelectUserButton = () => {
  const { t } = useTranslate(["Global"]);
  const { setSelectedUserId } = useSelectedUser();

  return (
    <Button
      variant="contained"
      color="secondary"
      disableElevation
      fullWidth
      onClick={() => {
        setSelectedUserId(null);
      }}
    >
      {t("Select organisation", { ns: "Global" })}
    </Button>
  );
};
