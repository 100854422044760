import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { ErrorMessage, LoadingSpinner, useScreenWidth } from "@msys/ui";
import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSessionStorage } from "react-use";
import { AttachmentInput, _3d_ShapeInput } from "../../clients/graphqlTypes.js";
import { THREED_PLANNER_URL } from "../../environment.js";
import { Page } from "../commons/layout/Page.js";
import { PageColumn } from "../commons/layout/PageColumn.js";
import { PageContainer } from "../commons/layout/PageContainer.js";
import { Stack } from "../commons/layout/Stack.js";
import { QUOTE_TEMPLATE_ITEM_TYPES } from "../constants.js";
import { isTreePreviewItemVisible } from "../features/doc-items/helpers.js";
import { createThreeDPublicFlowTreeItem } from "../features/templates/quote/trees.js";
import { ThreeDEditor } from "../features/threeD/ThreeDEditor.js";
import { VirtualItemTree } from "../trees-virtual/VirtualItemTree.js";
import { VirtualBareTreeStandaloneItem } from "../trees-virtual/components/VirtualBareTreeStandaloneItem.js";
import {
  ExpandedStore,
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../trees-virtual/hooks/useExpandedStore.js";
import { TreeToggleAllExpandedButton } from "../trees/components/TreeToggleButton.js";
import { ThreeDProcessData } from "./OrganisationThreeDFinish.js";
import {
  ThreeDProcess_ItemFragment,
  ThreeDProcess__ShoppableTemplateThreeDQuery,
  useThreeDProcess__OrganisationProfileQuery,
  useThreeDProcess__ShoppableTemplateThreeDQuery,
  useThreeDRoomShoppableTemplateQuery,
} from "./OrganisationThreeDProcess.generated.js";

export const OrganisationThreeDProcess = () => {
  const { slug, templateId } = useParams();
  if (!templateId) throw new Error("Template id is missing");
  if (!slug) throw new Error("Slug is missing");

  const navigate = useNavigate();
  const pathToDocPage = `/org/${slug}/3d/${templateId}`;
  const match = useMatch(`${pathToDocPage}/items/:itemId`);
  const selectedItemId = match?.params?.itemId ?? null;

  const { isMinDesktop, isMinTablet } = useScreenWidth();

  const [threeDProcessData, setThreeDProcessData] =
    useSessionStorage<ThreeDProcessData>(`3d-process-data-${templateId}`, {
      templateId,
      templateVariantConfiguration: [],
    });

  const [treeContainer, setTreeContainer] = useState<HTMLDivElement | null>(
    null
  );

  const client = useApolloClient();
  const organisationProfileQuery = useThreeDProcess__OrganisationProfileQuery({
    client,
    variables: {
      slug: slug,
    },
  });
  const organisationProfile = getDataOrNull(
    organisationProfileQuery.data?.organisationProfile
  )?.profile;
  const organisation = organisationProfile?.organisation;

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote-template",
    templateId,
    selectedItemId
  );

  const shoppableTemplateThreeDQuery =
    useThreeDProcess__ShoppableTemplateThreeDQuery({
      client,
      variables: {
        slug,
        docId: templateId,
        templateVariantConfiguration:
          threeDProcessData.templateVariantConfiguration,
        expandedItemIds: expandedStore.expandedItemIds,
      },
    });

  const shoppableTemplate = getDataOrNull(
    shoppableTemplateThreeDQuery.data?.organisationProfile
  )?.profile?.shoppableTemplate;

  const [filteredTreeItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!shoppableTemplate) return [[], undefined, []];
    const allDocItems = shoppableTemplate.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems;
    const filteredItems = treeItems;
    return [filteredItems, rootItem, allDocItems];
  }, [shoppableTemplate]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !shoppableTemplateThreeDQuery.loading && !shoppableTemplateThreeDQuery.error
  );

  useEffect(() => {
    if (
      !shoppableTemplateThreeDQuery.loading &&
      selectedItemId &&
      !filteredTreeItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredTreeItems,
    pathToDocPage,
    rootItem,
    navigate,
    shoppableTemplateThreeDQuery,
  ]);

  if (organisationProfileQuery.loading && !organisationProfile) {
    return <LoadingSpinner />;
  }
  if (organisationProfileQuery.error) {
    return <ErrorMessage message={organisationProfileQuery.error.message} />;
  }
  if (!organisationProfile) {
    return <div>organisationProfile not found</div>;
  }
  if (!organisation) {
    return <div>organisation not found</div>;
  }
  if (!organisationProfile.has3DShop) {
    return <Navigate to={`/org/${slug}`} replace />;
  }

  if (shoppableTemplateThreeDQuery.loading && !shoppableTemplate) {
    return <LoadingSpinner />;
  }
  if (!shoppableTemplate) {
    return <div>template not found</div>;
  }

  return (
    <Page
      isTopBarVisible
      subtitle={organisation?.title}
      title={shoppableTemplate?.title}
    >
      {shoppableTemplate && rootItem && (
        <>
          {isMinDesktop && (
            <PageColumn
              $hasRightBorder
              $hasBackground
              $display="block"
              ref={isMinTablet ? setTreeContainer : undefined}
            >
              <ThreeDProcessItemTree
                templateId={templateId}
                pathToDocPage={pathToDocPage}
                treeContainer={treeContainer}
                shoppableTemplate={shoppableTemplate}
                allDocItems={allDocItems}
                filteredTreeItems={filteredTreeItems}
                rootItem={rootItem}
                selectedItemId={selectedItemId}
                // @ts-ignore https://github.com/streamich/react-use/pull/1905
                setThreeDProcessData={setThreeDProcessData}
                expandedStore={expandedStore}
              />
            </PageColumn>
          )}

          <Outlet
            context={{ expandedItemIds: expandedStore.expandedItemIds }}
          />

          <PageContainer $fullHeight $noPadding>
            <ThreeDProcessThreeD
              slug={slug}
              templateId={templateId}
              msysOrganisationId={organisation.id}
              threeDProcessData={threeDProcessData}
              // @ts-ignore https://github.com/streamich/react-use/pull/1905
              setThreeDProcessData={setThreeDProcessData}
            />
          </PageContainer>
        </>
      )}
    </Page>
  );
};

const falseFn = () => false;
const nullFn = () => null;

type ShoppableTemplate = Exclude<
  Exclude<
    Exclude<
      ThreeDProcess__ShoppableTemplateThreeDQuery["organisationProfile"],
      { __typename: "MissingCapabilities" }
    >["profile"],
    undefined | null
  >["shoppableTemplate"],
  undefined | null
>;
type UnnestedItem = ShoppableTemplate["items"][0];

const ThreeDProcessItemTree = ({
  templateId,
  shoppableTemplate,
  allDocItems,
  filteredTreeItems,
  rootItem,
  selectedItemId,
  treeContainer,
  pathToDocPage,
  setThreeDProcessData,
  expandedStore,
}: {
  templateId: string;
  shoppableTemplate: ShoppableTemplate;
  allDocItems: UnnestedItem[];
  filteredTreeItems: UnnestedItem[];
  rootItem: UnnestedItem;
  selectedItemId: string | null;
  treeContainer: HTMLElement | null;
  pathToDocPage: string;
  setThreeDProcessData: React.Dispatch<React.SetStateAction<ThreeDProcessData>>;
  expandedStore: ExpandedStore;
}) => {
  const isRootSelected = selectedItemId === rootItem?.id;

  const filterItem = React.useCallback(
    (i: Parameters<typeof isTreePreviewItemVisible>[1]) =>
      isTreePreviewItemVisible(
        false,
        i,
        allDocItems,
        shoppableTemplate?.isBinding ?? true
      ),
    [allDocItems, shoppableTemplate?.isBinding]
  );

  const modifyItemContingencyPreselection = useCallback(
    (
      docId: string,
      itemId: string,
      decisionContingentItemPreselection: boolean | null
    ) => {
      setThreeDProcessData(state => ({
        ...state,
        templateVariantConfiguration: [
          ...state.templateVariantConfiguration.filter(
            config => config.itemId !== itemId
          ),
          {
            itemId,
            ...state.templateVariantConfiguration.find(
              config => config.itemId === itemId
            ),
            decisionContingentItemPreselection,
          },
        ],
      }));
    },
    [setThreeDProcessData]
  );

  const modifyItemDecisionPreselection = useCallback(
    (docId: string, itemId: string, decisionSubitemsPreselection: string[]) => {
      setThreeDProcessData(state => ({
        ...state,
        templateVariantConfiguration: [
          ...(state?.templateVariantConfiguration?.filter(
            config => config.itemId !== itemId
          ) ?? []),
          {
            itemId,
            ...state?.templateVariantConfiguration.find(
              config => config.itemId === itemId
            ),
            decisionSubitemsPreselection,
          },
        ],
      }));
    },
    [setThreeDProcessData]
  );

  const UnnestedTemplateTreeItem = React.useMemo(
    () =>
      createThreeDPublicFlowTreeItem(
        pathToDocPage,
        templateId,
        modifyItemContingencyPreselection,
        modifyItemDecisionPreselection
      ),
    [
      pathToDocPage,
      templateId,
      modifyItemContingencyPreselection,
      modifyItemDecisionPreselection,
    ]
  );

  return (
    <Stack flexDirection={"column"} spacing={2}>
      <Stack width="100%" alignItems="center" spacing={1}>
        <TreeToggleAllExpandedButton
          type="button"
          areAllItemsExpanded={expandedStore.areAllItemsExpanded}
          setAllItemsExpanded={expandedStore.setAllItemsExpanded}
        />
      </Stack>

      <Box>
        <VirtualBareTreeStandaloneItem
          item={rootItem}
          items={filteredTreeItems}
          allItems={allDocItems}
          depth={0}
          itemComponent={UnnestedTemplateTreeItem}
          top={0}
        />
        <VirtualItemTree<ThreeDProcess_ItemFragment, false>
          docId={null}
          projectId={null}
          items={filteredTreeItems}
          allItems={allDocItems}
          filterItem={filterItem}
          selectedItemId={selectedItemId}
          enableCreating={false}
          enableDragging={false}
          shouldRenderCreateInput={falseFn}
          allowHaveChildren={falseFn}
          documentItemTypes={QUOTE_TEMPLATE_ITEM_TYPES}
          container={treeContainer}
          itemComponent={UnnestedTemplateTreeItem}
          inputComponent={nullFn}
          expandedStore={expandedStore}
        />
      </Box>
    </Stack>
  );
};

const ThreeDProcessThreeD = ({
  slug,
  templateId,
  threeDProcessData,
  setThreeDProcessData,
}: {
  slug: string;
  templateId: string;
  threeDProcessData: ThreeDProcessData;
  setThreeDProcessData: React.Dispatch<React.SetStateAction<ThreeDProcessData>>;
}) => {
  const navigate = useNavigate();

  const client = useApolloClient();
  const threeDRoomQuery = useThreeDRoomShoppableTemplateQuery({
    client,
    variables: {
      slug,
      docId: templateId,
      templateVariantConfiguration:
        threeDProcessData?.templateVariantConfiguration,
    },
  });

  const _3d_roomShoppableTemplate = getDataOrNull(
    threeDRoomQuery.data?.organisationProfile
  )?.profile?._3d_roomShoppableTemplate;

  const onClose = React.useCallback(() => {
    if (
      threeDProcessData?.templateId === templateId &&
      threeDProcessData.dataShape
    ) {
      navigate(`/org/${slug}/3d/${templateId}/finish`, {
        state: threeDProcessData,
      });
    } else {
      navigate(`/org/${slug}/3d`);
    }
  }, [threeDProcessData, templateId, navigate, slug]);

  const onDataChange = React.useCallback(
    (inputDataShape: _3d_ShapeInput) => {
      setThreeDProcessData(state => ({ ...state, dataShape: inputDataShape }));
    },
    [setThreeDProcessData]
  );

  const onSnapshots = React.useCallback(
    (inputSnapshots: AttachmentInput[]) => {
      setThreeDProcessData(state => ({ ...state, snapshots: inputSnapshots }));
    },
    [setThreeDProcessData]
  );

  if (threeDRoomQuery.loading && !_3d_roomShoppableTemplate) {
    return <LoadingSpinner />;
  }

  return (
    <ThreeDEditor
      threeDUrl={THREED_PLANNER_URL}
      threeDRoomSnapshot={_3d_roomShoppableTemplate?._3d_roomSnapshot}
      threeDSelectedRoomItems={
        _3d_roomShoppableTemplate?._3d_selectedRoomItems ?? []
      }
      threeDInitialStepKey={"module-3d"}
      onClose={onClose}
      onDataChange={onDataChange}
      onSnapshots={onSnapshots}
      threeDGuiMode={"complete-ui"}
    />
  );
};
