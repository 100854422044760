import { gql } from "@apollo/client";
import { RecipientOption } from "../crm/CrmSendEmailForm.js";
import { CrmPersonToEmailRecipientOptionl_CrmPersonFragment } from "./utils.generated.js";

export function crmPersonToEmailRecipientOption(
  crmPerson: CrmPersonToEmailRecipientOptionl_CrmPersonFragment
): RecipientOption {
  return {
    key: crmPerson.id,
    label: `${crmPerson.fullname} <${crmPerson.email}>`,
    value: crmPerson.id,
    crmCompanyId: crmPerson.crmCompany.id,
    crmPersonId: crmPerson.id,
  };
}
