import { useApolloClient } from "@apollo/client";
import {
  CardContainer,
  isImageOr3dModel,
  LabeledDateValue,
  LabeledSelectValue,
  LabeledSpecializedValue,
  LabeledValue,
  processAttachment,
  TruncatedTextValue,
  useScreenWidth,
} from "@msys/ui";
import { BusinessCenter as BusinessCenterIcon } from "@mui/icons-material";
import { EventAvailable as EventAvailableIcon } from "@mui/icons-material";
import { Today as TodayIcon } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { isEqual } from "lodash-es";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffectOnce } from "react-use";
import * as Yup from "yup";
import {
  Money,
  namedOperations,
  ProjectType,
} from "../../../../clients/graphqlTypes.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField.js";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField.js";
import { FormattedMoneyField } from "../../../commons/form-fields/FormattedMoneyField.js";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { useUrlSearchParams } from "../../../commons/hooks/useUrlSearchParams.js";
import { PictureGallery } from "../../../commons/images/PictureGallery.js";
import { ViewModeEditIconButton } from "../../../commons/ViewModeEditIconButton.js";
import { ViewMode } from "../../../commons/ViewModeMenuItem.js";
import { useProjectAttachments } from "../../attachments/useAttachments.js";
import {
  CustomFieldConfigFragment,
  useCustomFieldConfigQuery,
} from "../../custom-fields/customFieldConfigs.generated.js";
import { CustomFieldsSection } from "../../custom-fields/CustomFieldsBox.js";
import {
  CustomFieldsArray,
  CustomFieldsFormValues,
  useCustomFieldsForm,
} from "../../custom-fields/EditCustomFieldModal.js";
import CategoriesField from "../../skill-categories/CategoriesField.js";
import { LabeledCategoriesValue } from "../../skill-categories/LabeledCategoriesValue.js";
import { ProjectSourceCreatedBy } from "../components/ProjectSourceCreatedBy.js";
import { ProjectSourceEntity } from "../components/ProjectSourceEntity.js";
import { useModifyProjectMutation } from "../Projects.generated.js";
import { useProjectTypes } from "../useProjectTypes.js";
import { ProjectOverviewBuildingBox } from "./ProjectOverviewBuildingBox.js";
import { ProjectOverviewBuildingBox_ProjectFragment } from "./ProjectOverviewBuildingBox.generated.js";
import {
  ProjectOverviewData_ProjectFragment,
  ProjectOverviewForm_ProjectFragment,
  ProjectOverviewHeaderBox_ProjectFragment,
  ProjectOverviewSource_ProjectSourceFragment,
} from "./ProjectOverviewHeaderBox.generated.js";

interface FormValues {
  title: string;
  description: string;
  categories: string[];
  type: ProjectType;
  earliestStart: Moment | null;
  deadline: Moment | null;
  budget: Money | null;
  urgent: boolean;
}

interface Props {
  project: ProjectOverviewHeaderBox_ProjectFragment;
}

export const ProjectOverviewHeaderBox = ({ project }: Props) => {
  const { t } = useTranslate(["ProjectOverview", "Global"]);

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();
  useEffectOnce(() => {
    if (urlSearchParams.has("create")) {
      setUrlSearchParams({}, ["create"], { replace: true });
      setViewMode("edit");
    }
  });

  const [showMore, setShowMore] = React.useState<boolean>(false);

  const attachments = project.attachments;
  const pictures = attachments.map(processAttachment).filter(isImageOr3dModel);

  const showUploadFile = project.viewerPermissions.includes("READ_PROJECT");
  const showEditFile = project.viewerPermissions.includes("MANAGE_PROJECT");

  const client = useApolloClient();
  const query = useCustomFieldConfigQuery({
    client,
    variables: { filterObjectType: "Project" },
  });
  const customFieldConfigs = query.data?.customFieldConfig ?? [];

  const { addAttachments, modifyAttachment, removeAttachment, loading } =
    useProjectAttachments(project.id, [namedOperations.Query.ProjectOverview]);

  return (
    <CardContainer
      title={t("Project", { ns: "ProjectOverview" })}
      Icon={<BusinessCenterIcon />}
      ActionButton={
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_PROJECT"
          project={project}
        >
          <ViewModeEditIconButton
            viewMode={viewMode}
            onViewModeChange={setViewMode}
          />
        </RestrictedByProjectPermissionWithDebug>
      }
      isExpandable
    >
      <PictureGallery
        layout="cols"
        pictures={pictures}
        loading={loading}
        showAdd={showUploadFile && viewMode === "edit"}
        showDelete={showEditFile && viewMode === "edit"}
        showUpload={showEditFile && viewMode === "edit"}
        showRotate={showEditFile && viewMode === "edit"}
        uploadBoxProps={{ px: 1 }}
        height={200}
        showImageName={false}
        addAttachments={addAttachments}
        modifyAttachment={modifyAttachment}
        removeAttachment={removeAttachment}
      />
      <Stack p={1} direction="column" spacing={1}>
        {viewMode === "edit" ? (
          <>
            <ProjectOverviewForm project={project} />
            {customFieldConfigs.length > 0 && (
              <>
                <Divider />
                <ProjectCustomFieldsForm
                  project={project}
                  customFieldConfigs={customFieldConfigs}
                />
              </>
            )}
          </>
        ) : (
          <>
            <ProjectOverviewData project={project} showMore={showMore} />
            {showMore && customFieldConfigs.length > 0 && (
              <>
                <Divider />
                <CustomFieldsSection
                  source={project}
                  customFieldConfigs={customFieldConfigs}
                />
              </>
            )}
            {showMore && project.source && (
              <>
                <Divider />
                <ProjectOverviewSource
                  projectId={project.id}
                  projectSource={project.source}
                />
              </>
            )}
            <Box
              sx={{
                mb: "-8px !important",
                ml: "-8px !important",
                mr: "-8px !important",
              }}
            >
              {!showMore ? (
                <Button
                  color="secondary"
                  variant="text"
                  fullWidth
                  onClick={() => setShowMore(true)}
                >
                  {t("Show more", { ns: "Global" })}
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="text"
                  fullWidth
                  onClick={() => setShowMore(false)}
                >
                  {t("Show less", { ns: "Global" })}
                </Button>
              )}
            </Box>
          </>
        )}
      </Stack>
    </CardContainer>
  );
};

const isEqualForm = (
  v1: FormValues | undefined,
  v2: FormValues | undefined
) => {
  return isEqual(
    {
      ...v1,
      earliestStart: v1?.earliestStart
        ? moment(v1.earliestStart).format("YYYY-MM-DD")
        : null,
      deadline: v1?.deadline ? moment(v1.deadline).format("YYYY-MM-DD") : null,
    },
    {
      ...v2,
      earliestStart: v2?.earliestStart
        ? moment(v2.earliestStart).format("YYYY-MM-DD")
        : null,
      deadline: v2?.deadline ? moment(v2.deadline).format("YYYY-MM-DD") : null,
    }
  );
};

const ProjectCustomFieldsForm = ({
  project,
  customFieldConfigs,
}: {
  project: ProjectOverviewHeaderBox_ProjectFragment;
  customFieldConfigs: CustomFieldConfigFragment[];
}) => {
  const { handleSubmit, validationSchema, initialValues } = useCustomFieldsForm(
    {
      objectId: project.id,
      objectType: "Project",
      customFields: project.customFields,
      customFieldConfigs,
      refetchQueries: [namedOperations.Query.ProjectOverview],
      useAutoSave: true,
    }
  );
  return (
    <Formik<CustomFieldsFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={false}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Stack direction="column" spacing={1}>
          <Form>
            <CustomFieldsArray
              values={values}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              customFieldConfigs={customFieldConfigs}
              objectType="Project"
              useAutoSave={true}
            />
          </Form>
          <AutoSave enableReinitialize initialValues={initialValues} />
        </Stack>
      )}
    </Formik>
  );
};

const ProjectOverviewForm = ({
  project,
}: {
  project: ProjectOverviewForm_ProjectFragment &
    ProjectOverviewBuildingBox_ProjectFragment;
}) => {
  const { t } = useTranslate(["ProjectOverview", "AddressBox", "Global"]);

  const { isMaxPhone } = useScreenWidth();

  const client = useApolloClient();

  const [modifyProject] = useModifyProjectMutation({ client });
  const { projectTypeOptions } = useProjectTypes();

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(
        t("Title", {
          ns: "ProjectOverview",
        })
      )
      .required(),
    description: Yup.string().label(
      t("Description", {
        ns: "ProjectOverview",
      })
    ),
    type: Yup.string()
      .label(
        t("Project Type", {
          ns: "ProjectOverview",
        })
      )
      .required(),
    earliestStart: Yup.date()
      .label(
        t("Earliest start", {
          ns: "ProjectOverview",
        })
      )
      .nullable(),
    deadline: Yup.date()
      .label(
        t("Deadline", {
          ns: "ProjectOverview",
        })
      )
      .nullable()
      .default(null)
      .when("earliestStart", (earliestStart: any, schema: any) => {
        return earliestStart
          ? schema.min(
              earliestStart,
              t("Deadline cannot be before earliest start.", {
                ns: "ProjectOverview",
              })
            )
          : schema;
      }),
    budget: Yup.object()
      .label(
        t("Budget", {
          ns: "ProjectOverview",
        })
      )
      .nullable(),
    urgent: Yup.boolean().label(
      t("Urgent", {
        ns: "ProjectOverview",
      })
    ),
  });

  const initialValues: FormValues = {
    title: project.title,
    description: project.description,
    categories: project.categories,
    type: project.type,
    earliestStart: project.earliestStart ? moment(project.earliestStart) : null,
    deadline: project.deadline ? moment(project.deadline) : null,
    budget: project.budget || null,
    urgent: project.urgent ?? false,
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        try {
          await modifyProject({
            variables: {
              input: {
                projectId: project.id,
                title: values.title,
                description: values.description,
                type: values.type,
                categories: values.categories,
                earliestStart:
                  values.earliestStart?.format("YYYY-MM-DD") ?? null,
                deadline: values.deadline?.format("YYYY-MM-DD") ?? null,
                budgetAmount:
                  values.budget && values.budget.amount > 0
                    ? values.budget.amount
                    : null,
                ...(project.ticket ? { urgent: values.urgent } : {}),
              },
            },
          });
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, { variant: "error" });
        }
      }}
    >
      {formikProps => (
        <Form>
          <Grid container spacing={1}>
            {isMaxPhone && (
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="title"
                  label={t("Title", {
                    ns: "ProjectOverview",
                  })}
                  disabled={false}
                  required
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Field
                component={TextField}
                disabled={false}
                multiline
                name="description"
                label={t("Description", {
                  ns: "ProjectOverview",
                })}
              />
            </Grid>
            <Grid item xs={8}>
              <CategoriesField
                name="categories"
                label={t("Categories", {
                  ns: "ProjectOverview",
                })}
                disabled={false}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField
                name="type"
                options={projectTypeOptions}
                label={t("Project Type", {
                  ns: "ProjectOverview",
                })}
                disabled={false}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" spacing={1}>
                <Divider />
                <ProjectOverviewBuildingBox project={project} canEdit={true} />
                <Divider />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <DatePickerField
                label={t("Earliest start", {
                  ns: "ProjectOverview",
                })}
                name="earliestStart"
                disabled={false}
                disablePast
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerField
                label={t("Deadline", {
                  ns: "ProjectOverview",
                })}
                name="deadline"
                disabled={false}
                disablePast
              />
            </Grid>
            {project.ticket && (
              <Grid item xs={12}>
                <CheckboxField
                  label={t("Urgent", {
                    ns: "ProjectOverview",
                  })}
                  name="urgent"
                  disabled={false}
                />
              </Grid>
            )}
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <LabeledValue
                label={t("Project number", {
                  ns: "ProjectOverview",
                })}
              >
                {project.number}
              </LabeledValue>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <LabeledDateValue
                label={t("Created at", {
                  ns: "ProjectOverview",
                })}
                value={project.createdAt}
                notSetText={t("Not set", {
                  ns: "Global",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <RestrictedByProjectPermissionWithDebug
                permission="READ_QUOTES"
                project={project}
              >
                <FormattedMoneyField
                  label={t("Budget", {
                    ns: "ProjectOverview",
                  })}
                  name="budget"
                  type="money"
                  disabled={false}
                />
              </RestrictedByProjectPermissionWithDebug>
            </Grid>
          </Grid>
          <AutoSave<FormValues>
            enableReinitialize
            initialValues={initialValues}
            isEqualForm={isEqualForm}
            boxProps={{ mt: 1 }}
          />
        </Form>
      )}
    </Formik>
  );
};

const ProjectOverviewData = ({
  project,
  showMore,
}: {
  project: ProjectOverviewData_ProjectFragment &
    ProjectOverviewBuildingBox_ProjectFragment;
  showMore: boolean;
}) => {
  const { t } = useTranslate(["ProjectOverview", "Global"]);
  const { projectTypeOptions } = useProjectTypes();
  return (
    <Box>
      <Grid container spacing={1}>
        {project.description && (
          <Grid item xs={12}>
            <TruncatedTextValue
              text={project.description}
              lines={2}
              showMoreLabel={t("Show more", { ns: "Global" })}
              showLessLabel={t("Show less", { ns: "Global" })}
              notSetLabel={t("Not set", { ns: "Global" })}
            />
          </Grid>
        )}
        <Grid item xs={8}>
          <LabeledCategoriesValue
            label={t("Categories", {
              ns: "ProjectOverview",
            })}
            chipSize="small"
            value={project.categories}
          />
        </Grid>
        <Grid item xs={4}>
          <LabeledSelectValue
            label={t("Project Type", {
              ns: "ProjectOverview",
            })}
            options={projectTypeOptions}
            value={project.type}
            notSetText={t("Not set", {
              ns: "Global",
            })}
          />
        </Grid>
        <RestrictedByProjectPermissionWithDebug
          permission="READ_PROJECT"
          project={project}
        >
          <Grid item xs={12}>
            <Stack direction="column" spacing={1}>
              <Divider />
              <ProjectOverviewBuildingBox project={project} canEdit={false} />
              <Divider />
            </Stack>
          </Grid>
        </RestrictedByProjectPermissionWithDebug>
        <Grid item xs={6}>
          <LabeledDateValue
            label={t("Earliest start", {
              ns: "ProjectOverview",
            })}
            value={project.earliestStart}
            notSetText={t("Not set", {
              ns: "Global",
            })}
            icon={TodayIcon}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledDateValue
            label={t("Deadline", {
              ns: "ProjectOverview",
            })}
            value={project.deadline}
            notSetText={t("Not set", {
              ns: "Global",
            })}
            icon={EventAvailableIcon}
          />
        </Grid>
        {showMore && (
          <>
            <Grid item xs={6}>
              <LabeledValue
                label={t("Project number", {
                  ns: "ProjectOverview",
                })}
              >
                {project.number}
              </LabeledValue>
            </Grid>
            <Grid item xs={6}>
              <LabeledDateValue
                label={t("Created at", {
                  ns: "ProjectOverview",
                })}
                value={project.createdAt}
                notSetText={t("Not set", {
                  ns: "Global",
                })}
              />
            </Grid>
            <RestrictedByProjectPermissionWithDebug
              permission="READ_QUOTES"
              project={project}
            >
              <Grid item xs={6}>
                <LabeledSpecializedValue
                  label={t("Budget", {
                    ns: "ProjectOverview",
                  })}
                  value={project.budget}
                  type="money"
                  notSetText={t("Not set", {
                    ns: "Global",
                  })}
                />
              </Grid>
            </RestrictedByProjectPermissionWithDebug>
          </>
        )}
      </Grid>
    </Box>
  );
};

const ProjectOverviewSource = ({
  projectId,
  projectSource,
}: {
  projectId: string;
  projectSource: ProjectOverviewSource_ProjectSourceFragment;
}) => {
  const { t } = useTranslate(["ProjectOverview", "Global"]);
  const { projectTypeOptions } = useProjectTypes();
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <LabeledValue label={t("Source", { ns: "ProjectOverview" })}>
            <ProjectSourceEntity
              projectSource={projectSource}
              projectId={projectId}
            />
          </LabeledValue>
        </Grid>
        <Grid item xs={6}>
          <LabeledValue label={t("Created by", { ns: "ProjectOverview" })}>
            <ProjectSourceCreatedBy projectSource={projectSource} />
          </LabeledValue>
        </Grid>
      </Grid>
    </Box>
  );
};
