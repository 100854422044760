import React from "react";
import { PermissionName } from "../../clients/graphqlTypes.js";
import { Restricted, RestrictedWithDebug } from "./Restricted.js";

export interface Document {
  viewerPermissions: PermissionName[];
}

interface Props
  extends Omit<React.ComponentProps<typeof Restricted>, "isRestricted"> {
  permission: PermissionName;
  document: Document;
}

export const RestrictedByDocumentPermission = ({
  permission,
  document,
  ...props
}: Props) => {
  const isPermitted = document.viewerPermissions.includes(permission);

  return <Restricted isRestricted={!isPermitted} {...props} />;
};

export const RestrictedByDocumentPermissionWithDebug = ({
  document,
  permission,
  children,
  ...props
}: Props) => {
  const isPermitted = document.viewerPermissions.includes(permission);

  return (
    <RestrictedWithDebug
      {...props}
      isRestricted={!isPermitted}
      debugLabel={`Document permission: ${permission}`}
      debugColor={"#DA70D6"}
    >
      {children}
    </RestrictedWithDebug>
  );
};
