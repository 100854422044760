import { decodeGlobalId } from "@msys/common";
import { useTranslate } from "@tolgee/react";

interface Props {
  id?: string;
  number?: string;
  noLabel?: boolean;
}

export const EntityNumber = ({
  number: numberProp,
  id,
  noLabel = false,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const number = numberProp ?? (id ? decodeGlobalId(id).id : t("Not set"));
  return (
    <span style={{ whiteSpace: "nowrap", hyphens: "none" }}>
      {noLabel ? number : t("No. {number}", { ns: "Global", number })}
    </span>
  );
};
