import { isArray, isPlainObject, mergeWith } from "lodash-es";

function customizer(existing: any, incoming: any) {
  if (isArray(existing)) {
    return incoming;
  }
}

export const mergeObjects = (existing: any, incoming: any) => {
  if (isArray(existing)) return incoming;
  if (isPlainObject(existing))
    return mergeWith({}, existing, incoming, customizer);
  return incoming;
};
