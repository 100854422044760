import { Modal } from "@msys/ui";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { LexicalEditor } from "lexical";
import React from "react";
import { FileInput } from "../inputs/FileInput.js";
import { INSERT_IMAGE_COMMAND, InsertImagePayload } from "./ImagesPlugin.js";

export function InsertImageModal({
  activeEditor,
  handleClose,
}: {
  activeEditor: LexicalEditor;
  handleClose: () => void;
}): JSX.Element {
  const { t } = useTranslate(["Global"]);

  const hasModifier = React.useRef(false);

  React.useEffect(() => {
    hasModifier.current = false;
    const handler = (e: KeyboardEvent) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [activeEditor]);

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [src, setSrc] = React.useState("");
  const [altText, setAltText] = React.useState("");
  const isDisabled = src === "";

  const loadImage = (file: File | null) => {
    const reader = new FileReader();
    reader.onload = function () {
      if (typeof reader.result === "string") {
        setSrc(reader.result);
      }
      return "";
    };
    if (file !== null) {
      reader.readAsDataURL(file);
    }
  };

  const handleConfirm = (payload: InsertImagePayload) => {
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    handleClose();
  };

  return (
    <Modal
      // @ts-ignore FIXME
      title={t("Insert image", { ns: "Global" })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Confirm", { ns: "Global" }),
          handleClick: () => handleConfirm({ altText, src }),
          buttonProps: {
            disabled: isDisabled,
          },
        },
      ]}
      notInStack
    >
      <Stack spacing={1}>
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              fileInputRef.current?.click();
            }}
            sx={{ alignSelf: "flex-start" }}
          >
            {/* @ts-ignore FIXME */}
            {t("Browse", { ns: "Global" })}
          </Button>
          <Typography>{fileInputRef.current?.files?.[0]?.name}</Typography>
        </Stack>
        <FileInput
          id="lexical-image-plugin-file-input"
          innerRef={fileInputRef}
          multiple={false}
          onComplete={file => loadImage(file)}
          accept="image/*"
          resetValue={false}
        />
        <TextField
          // @ts-ignore FIXME
          label={t("Alt Text", { ns: "Global" })}
          placeholder="Descriptive alternative text"
          onChange={event => {
            setAltText(event.target.value);
          }}
          value={altText}
          data-test-id="image-modal-alt-text-input"
        />
      </Stack>
    </Modal>
  );
}
