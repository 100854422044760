import { CardContainer, StatusLabel } from "@msys/ui";
import { Masonry } from "@mui/lab";
import { Box, Divider, Paper, Typography, useTheme } from "@mui/material";
import { Page } from "../../commons/layout/Page.js";
import { PageColumn } from "../../commons/layout/PageColumn.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageSectionHeader } from "../../commons/layout/PageSectionHeader.js";

export const ExampleLayoutFluid = () => {
  const theme = useTheme();
  return (
    <>
      <PageContainer>
        <PageSectionHeader
          status={
            <StatusLabel color={theme.palette.grey[600]}>Example</StatusLabel>
          }
          title="Page title"
        />
      </PageContainer>
      <Page>
        <PageContainer>
          <Masonry columns={{ xs: 1, md: 2, lg: 3, xl: 4 }} spacing={2}>
            <div>
              <CardContainer title="Header">
                <Box height={400} />
              </CardContainer>
              <CardContainer title="Address">
                <Box height={100} />
              </CardContainer>
              <CardContainer title="Next tasks">
                <Box height={200} />
              </CardContainer>
            </div>
            <div>
              <CardContainer title="Requirements">
                <Box height={300} />
              </CardContainer>
              <CardContainer title="Quotes">
                <Box height={500} />
              </CardContainer>
              <CardContainer title="Tasks">
                <Box height={150} />
              </CardContainer>
              <CardContainer title="Purchase Orders">
                <Box height={150} />
              </CardContainer>
              <CardContainer title="Invoices">
                <Box height={150} />
              </CardContainer>
              <CardContainer title="Files">
                <Box height={400} />
              </CardContainer>
            </div>
            <div>
              <CardContainer title="Project participants">
                <Box height={600} />
              </CardContainer>
            </div>
            <div>
              <CardContainer title="Planning">
                <Box height={1600} />
              </CardContainer>
            </div>
          </Masonry>
        </PageContainer>
      </Page>
    </>
  );
};

export const ExampleLayoutFluidChat = () => {
  return (
    <Page>
      <PageContainer $noPadding>
        <Box
          display="flex"
          flexGrow={1}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="stretch"
          overflow="auto"
        >
          <Box overflow="auto">
            {Array(60)
              .fill(null)
              .map((v, index) => (
                <Box m={1} key={index}>
                  <Paper>
                    <Box p={1}>
                      <Typography>Message</Typography>
                    </Box>
                  </Paper>
                </Box>
              ))}
          </Box>
          <div>
            <Divider />
            <Box p={1} height={64} display="flex" alignItems="center">
              <Typography>Input</Typography>
            </Box>
          </div>
        </Box>
      </PageContainer>
    </Page>
  );
};

export const ExampleLayoutFluidChatWithSidebar = () => {
  return (
    <Page>
      <PageColumn $hasBackground $hasRightBorder>
        <CardContainer title="Project">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Company">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Direct">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Client">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Meister">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Third-parties">
          <Box height={200} />
        </CardContainer>
      </PageColumn>
      <PageContainer $noPadding>
        <Box
          display="flex"
          flexGrow={1}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="stretch"
          overflow="auto"
        >
          <Box overflow="auto">
            {Array(60)
              .fill(null)
              .map((v, index) => (
                <Box m={1} key={index}>
                  <Paper>
                    <Box p={1}>
                      <Typography>Message</Typography>
                    </Box>
                  </Paper>
                </Box>
              ))}
          </Box>
          <div>
            <Divider />
            <Box p={1} height={64} display="flex" alignItems="center">
              <Typography>Input</Typography>
            </Box>
          </div>
        </Box>
      </PageContainer>
    </Page>
  );
};
