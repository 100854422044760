/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { OutgoingRequestListItemFragmentDoc } from '../../requests/RequestListItem.generated.js';
export type ProjectOverviewOutgoingRequestsBox_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, outgoingQuoteRequests: Array<{ __typename: 'Request', id: string, overdue: boolean, earliestStart?: any | null, deadline?: any | null, urgent: boolean, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string, budget?: { __typename: 'Money', amount: number, currency: string } | null, quotes: Array<{ __typename: 'Quote', id: string, agreement: Types.Agreement }> }> };

export const ProjectOverviewOutgoingRequestsBox_ProjectFragmentDoc = gql`
    fragment ProjectOverviewOutgoingRequestsBox_Project on Project {
  id
  viewerPermissions
  outgoingQuoteRequests {
    id
    ...OutgoingRequestListItem
  }
}
    ${OutgoingRequestListItemFragmentDoc}`;