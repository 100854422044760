import { gql } from "@apollo/client";
import {
  CardContainer,
  LabeledValueHorizontal,
  MenuButton,
  Modal,
  ModalOpenButton,
  useFormatting,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  Link,
  List,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { groupBy } from "lodash-es";
import React from "react";
import sanitizeHtml from "sanitize-html";
import { CrmEmailListItem } from "./CrmEmailListItem.js";
import {
  CrmEmailMessagesBox_CrmCompanyFragment,
  CrmEmailMessagesBox_CrmPersonFragment,
  EmailMessagePreviewModal_CrmMailMessageFragment,
} from "./CrmEmailMessagesBox.generated.js";
import { CrmSendEmailModal } from "./CrmSendEmailModal.js";

interface WithCrmCompanyProps {
  crmCompany: CrmEmailMessagesBox_CrmCompanyFragment;
  crmPerson?: undefined;
}
interface WithCrmPersonProps {
  crmCompany?: undefined;
  crmPerson: CrmEmailMessagesBox_CrmPersonFragment;
}

type Props = (WithCrmCompanyProps | WithCrmPersonProps) & {
  refetchQueries?: string[];
};

export function CrmEmailMessagesBox({
  crmCompany,
  crmPerson,
  refetchQueries,
}: Props) {
  const { t } = useTranslate(["Email", "Global"]);

  const { mailMessages } = crmCompany ? crmCompany : crmPerson;

  const threads = groupBy(mailMessages, email => email.threadId);

  const recipientOptions = React.useMemo(
    () =>
      (crmCompany
        ? [
            {
              id: crmCompany.id,
              label: `${crmCompany.title} <${crmCompany.email}>`,
              value: crmCompany.id,
              email: crmCompany.email,
              crmCompanyId: crmCompany.id,
              crmPersonId: null,
            },
            ...crmCompany.members.map(member => ({
              id: member.id,
              label: `${member.fullname} <${member.email}>`,
              value: member.id,
              email: member.email,
              crmCompanyId: crmCompany.id,
              crmPersonId: member.id,
            })),
          ]
        : [
            {
              id: crmPerson.id,
              label: `${crmPerson.fullname} <${crmPerson.email}>`,
              value: crmPerson.id,
              email: crmPerson.email,
              crmCompanyId: crmPerson.crmCompany.id,
              crmPersonId: crmPerson.id,
            },
          ]
      ).filter(option => option.email),
    [
      crmCompany,
      crmPerson?.crmCompany.id,
      crmPerson?.email,
      crmPerson?.fullname,
      crmPerson?.id,
    ]
  );

  const recipient = crmCompany
    ? recipientOptions.find(option => option.crmPersonId === null)
    : recipientOptions[0];

  return (
    <CardContainer
      title={t("Email messages", {
        ns: "Email",
      })}
      itemCount={mailMessages.length}
      ActionButton={
        <ModalOpenButton
          Modal={CrmSendEmailModal}
          modalProps={{
            recipient,
            recipientOptions,
            emailTemplateContext: "CRM",
            refetchQueries,
            context: null,
          }}
          disabled={recipientOptions.length < 1}
        >
          <IconButton color="primary" size="small">
            <AddIcon />
          </IconButton>
        </ModalOpenButton>
      }
    >
      <Stack padding={1} spacing={1}>
        {Object.entries(threads).map(([threadId, emails]) => (
          <Paper key={threadId}>
            <List disablePadding>
              {emails
                .sort(
                  (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                )
                .map((email, index, array) => (
                  <ModalOpenButton
                    key={email.id}
                    Modal={EmailMessagePreviewModal}
                    modalProps={{ message: email }}
                  >
                    <CrmEmailListItem
                      key={email.id}
                      email={email}
                      selected={false}
                      isThreadRoot={index === 0}
                      divider={index < array.length - 1}
                    />
                  </ModalOpenButton>
                ))}
            </List>
          </Paper>
        ))}
      </Stack>
    </CardContainer>
  );
}

function EmailMessagePreviewModal({
  handleClose,
  message,
}: {
  handleClose: () => void;
  message: EmailMessagePreviewModal_CrmMailMessageFragment;
}) {
  const { t } = useTranslate(["Email", "Global"]);
  const { getFormattedDateTime } = useFormatting();

  return (
    <Modal
      title={message.subject}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Close", {
            ns: "Global",
          }),
          handleClick: handleClose,
        },
      ]}
      maxWidth={"md"}
    >
      <Stack direction="row" spacing={2} justifyContent={"space-between"}>
        <Stack>
          <LabeledValueHorizontal
            label={t("Date", {
              ns: "Global",
            })}
          >
            {getFormattedDateTime(message.createdAt)}
          </LabeledValueHorizontal>
          <LabeledValueHorizontal
            label={t("From", {
              ns: "Email",
            })}
          >
            {message.from}
          </LabeledValueHorizontal>
          <LabeledValueHorizontal
            label={t("To", {
              ns: "Email",
            })}
          >
            {message.to}
          </LabeledValueHorizontal>
        </Stack>

        {message.attachments.length > 0 && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <MenuButton
              Icon={
                <Badge
                  badgeContent={message.attachments.length}
                  color="secondary"
                >
                  <AttachFileIcon />
                </Badge>
              }
            >
              {message.attachments.map(attachment => (
                <MenuItem>
                  <Link
                    href={attachment.url}
                    target="_blank"
                    referrerPolicy="no-referrer"
                    download
                  >
                    {attachment.filename}
                  </Link>
                </MenuItem>
              ))}
            </MenuButton>
          </Stack>
        )}
      </Stack>
      <Box paddingY={2}>
        <Divider />
      </Box>
      <Typography variant={"body2"} whiteSpace={"pre-wrap"}>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(message.body, {
              allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
              allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat([
                "data",
              ]),
            }),
          }}
        />
      </Typography>
    </Modal>
  );
}
