/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestGetRequirementModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type RequestGetRequirementModalQuery = { projectRequirements: { __typename: 'MissingPermissions' } | { __typename: 'ProjectRequirementsConnection', totalCount: number, edges: Array<{ __typename: 'RequirementEdge', node: { __typename: 'RequirementNode', id: string, title: string } }> } };


export const RequestGetRequirementModalDocument = gql`
    query RequestGetRequirementModal($projectId: ID!) {
  projectRequirements(projectId: $projectId, filters: {origin: OWN}, limit: 0) {
    ... on ProjectRequirementsConnection {
      edges {
        node {
          id
          title
        }
      }
      totalCount
    }
  }
}
    `;

/**
 * __useRequestGetRequirementModalQuery__
 *
 * To run a query within a React component, call `useRequestGetRequirementModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestGetRequirementModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestGetRequirementModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRequestGetRequirementModalQuery(baseOptions: Apollo.QueryHookOptions<RequestGetRequirementModalQuery, RequestGetRequirementModalQueryVariables> & ({ variables: RequestGetRequirementModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestGetRequirementModalQuery, RequestGetRequirementModalQueryVariables>(RequestGetRequirementModalDocument, options);
      }
export function useRequestGetRequirementModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestGetRequirementModalQuery, RequestGetRequirementModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestGetRequirementModalQuery, RequestGetRequirementModalQueryVariables>(RequestGetRequirementModalDocument, options);
        }
export function useRequestGetRequirementModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RequestGetRequirementModalQuery, RequestGetRequirementModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequestGetRequirementModalQuery, RequestGetRequirementModalQueryVariables>(RequestGetRequirementModalDocument, options);
        }
export type RequestGetRequirementModalQueryHookResult = ReturnType<typeof useRequestGetRequirementModalQuery>;
export type RequestGetRequirementModalLazyQueryHookResult = ReturnType<typeof useRequestGetRequirementModalLazyQuery>;
export type RequestGetRequirementModalSuspenseQueryHookResult = ReturnType<typeof useRequestGetRequirementModalSuspenseQuery>;
export type RequestGetRequirementModalQueryResult = Apollo.QueryResult<RequestGetRequirementModalQuery, RequestGetRequirementModalQueryVariables>;