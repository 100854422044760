import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { DataGrid, GridColDef, Modal, useLocalStorageAsState } from "@msys/ui";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { Check as CheckIcon } from "@mui/icons-material";
import { Replay as ReplayIcon } from "@mui/icons-material";
import { WarningAmber as WarningAmberIcon } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import React from "react";
import * as uuid from "uuid";
import * as Yup from "yup";
import { PasswordField } from "../../commons/form-fields/PasswordField.js";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import {
  DatanormOnlineEntriesDocument,
  DatanormOnlineEntriesQuery,
  DatanormOnlineEntriesQueryVariables,
  DatanormOnlineEntryFragment,
  useImportDatanormOnlineEntryMutation,
} from "./ImportViaDatanormOnlineModal.generated.js";

const getRowId = (row: DatanormOnlineEntryFragment) =>
  `${row.url}-${row.filename}-${row.date}-${row.dataDate}`;

interface FormValues {
  clientId: string;
  username: string;
  password: string;
}

interface Props {
  supplierId: string;
  datanormOnlineInfo: {
    clientIdRequired: boolean;
    usernameRequired: boolean;
    passwordRequired: boolean;
  };
  handleClose: () => void;
}

export function ImportViaDatanormOnlineModal({
  supplierId,
  datanormOnlineInfo,
  handleClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["PurchaseOrders", "PimImportations", "Global"]);

  const [entries, setEntries] = React.useState<
    DatanormOnlineEntryFragment[] | null
  >(null);

  const [statuses, setStatuses] = React.useState<
    Record<string, "loading" | "error" | "done">
  >({});

  const client = useApolloClient();
  const [addPimImport] = useImportDatanormOnlineEntryMutation({
    client,
    refetchQueries: [namedOperations.Query.PimImportations],
  });

  const [datanormOnlineLogin, setDatanormOnlineLogin] = useLocalStorageAsState<{
    clientId: string;
    username: string;
  }>(`msys-datanorm-online-login-${supplierId}`, {
    clientId: "",
    username: "",
  });

  const initialValues: FormValues = {
    clientId: datanormOnlineLogin?.clientId ?? "",
    username: datanormOnlineLogin?.username ?? "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    ...(datanormOnlineInfo.clientIdRequired
      ? {
          clientId: Yup.string()
            .label(
              t("Client id", {
                ns: "PurchaseOrders",
              })
            )
            .required(),
        }
      : undefined),
    ...(datanormOnlineInfo.usernameRequired
      ? {
          username: Yup.string()
            .label(
              t("Username", {
                ns: "PurchaseOrders",
              })
            )
            .required(),
        }
      : undefined),
    ...(datanormOnlineInfo.passwordRequired
      ? {
          password: Yup.string()
            .label(
              t("Password", {
                ns: "PurchaseOrders",
              })
            )
            .required(),
        }
      : undefined),
  });

  const formId = React.useMemo(() => uuid.v4(), []);

  const handleSubmit = async ({ username, clientId, password }: FormValues) => {
    try {
      const { data } = await client.query<
        DatanormOnlineEntriesQuery,
        DatanormOnlineEntriesQueryVariables
      >({
        query: DatanormOnlineEntriesDocument,
        variables: {
          authentication: {
            msOrganisationId: supplierId,
            username,
            clientId,
            password,
          },
        },
      });
      const entries = getDataOrNull(data?.datanormOnlineEntries)?.entries;
      if (!entries) throw new Error("No entries found");
      setEntries(entries);
      setDatanormOnlineLogin({ username, clientId });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const handleImport = async (
    { username, clientId, password }: FormValues,
    row: DatanormOnlineEntryFragment
  ) => {
    setStatuses(s => ({ ...s, [getRowId(row)]: "loading" }));
    try {
      if (!row.url) {
        throw new Error("Url not found");
      }
      await addPimImport({
        variables: {
          input: {
            authentication: {
              msOrganisationId: supplierId,
              username,
              clientId,
              password,
            },
            datanormOnlineEntry: {
              description: row.description,
              dataDate: row.dataDate,
              date: row.date,
              filename: row.filename,
              url: row.url,
            },
          },
        },
      });
      setStatuses(s => ({ ...s, [getRowId(row)]: "done" }));
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
      setStatuses(s => ({ ...s, [getRowId(row)]: "error" }));
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {formikProps => (
        <Modal
          dialogProps={{ maxWidth: entries === null ? "sm" : "md" }}
          title={t("Import via Datanorm Online", {
            ns: "PimImportations",
          })}
          handleClose={handleClose}
          actionButtons={
            entries === null
              ? [
                  {
                    label: t("Cancel", {
                      ns: "Global",
                    }),
                    handleClick: handleClose,
                    buttonProps: { variant: "text" },
                  },
                  {
                    label: t("Submit", {
                      ns: "Global",
                    }),
                    buttonProps: {
                      disabled: !formikProps.dirty || !formikProps.isValid,
                      loading: formikProps.isSubmitting,
                      endIcon: <ArrowForwardIcon />,
                      type: "submit",
                      form: formId,
                    },
                  },
                ]
              : [
                  {
                    label: t("Done", {
                      ns: "Global",
                    }),
                    handleClick: handleClose,
                  },
                ]
          }
        >
          {entries === null ? (
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                {datanormOnlineInfo.clientIdRequired && (
                  <Field
                    component={TextField}
                    label={t("Client id", {
                      ns: "PurchaseOrders",
                    })}
                    name="clientId"
                    required
                  />
                )}
                {datanormOnlineInfo.usernameRequired && (
                  <Field
                    component={TextField}
                    label={t("Username", {
                      ns: "PurchaseOrders",
                    })}
                    name="username"
                    required
                  />
                )}
                {datanormOnlineInfo.passwordRequired && (
                  <PasswordField
                    name="password"
                    label={t("Password", {
                      ns: "PurchaseOrders",
                    })}
                  />
                )}
              </Stack>
            </Form>
          ) : (
            <DataGrid
              density="compact"
              hideFooter={true}
              getRowId={getRowId}
              columns={
                [
                  {
                    field: "description",
                    headerName: t("Description", {
                      ns: "Global",
                    }),
                    flex: 3,
                    minWidth: 30,
                    sortable: false,
                    valueGetter: ({ row }) => row.description,
                  },
                  {
                    field: "filename",
                    headerName: t("Name", {
                      ns: "Global",
                    }),
                    flex: 1.5,
                    minWidth: 50,
                    sortable: false,
                    valueGetter: ({ row }) => row.filename,
                  },
                  {
                    field: "value",
                    headerName: t("Date", {
                      ns: "Global",
                    }),
                    flex: 1,
                    minWidth: 30,
                    sortable: false,
                    valueGetter: ({ row }) => row.date,
                  },
                  {
                    field: "discountType",
                    headerName: t("Date of data", {
                      ns: "PimImportations",
                    }),
                    flex: 1,
                    minWidth: 30,
                    sortable: false,
                    valueGetter: ({ row }) => row.dataDate,
                  },
                  {
                    field: "actions",
                    headerName: t("Actions", {
                      ns: "PimImportations",
                    }),
                    flex: 1,
                    minWidth: 30,
                    sortable: false,
                    renderCell: ({ row }) => {
                      const status = statuses[getRowId(row)];
                      return !status ? (
                        <Button
                          size="extra-small"
                          variant="contained"
                          color="primary"
                          onClick={() => handleImport(formikProps.values, row)}
                        >
                          {t("Import", {
                            ns: "PimImportations",
                          })}
                        </Button>
                      ) : status === "loading" ? (
                        <CircularProgress color="primary" size="1.25rem" />
                      ) : status === "error" ? (
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Tooltip
                            title={t(
                              "Error occurred during importation process, please try again later",
                              {
                                ns: "PimImportations",
                              }
                            )}
                          >
                            <WarningAmberIcon
                              color="warning"
                              fontSize="small"
                            />
                          </Tooltip>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() =>
                              handleImport(formikProps.values, row)
                            }
                          >
                            <Tooltip
                              title={t("Retry", {
                                ns: "Global",
                              })}
                            >
                              <ReplayIcon />
                            </Tooltip>
                          </IconButton>
                        </Stack>
                      ) : status === "done" ? (
                        <CheckIcon color="success" fontSize="small" />
                      ) : null;
                    },
                  },
                ] as GridColDef<DatanormOnlineEntryFragment>[]
              }
              rows={entries}
            />
          )}
        </Modal>
      )}
    </Formik>
  );
}
