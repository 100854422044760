import { gql, useApolloClient } from "@apollo/client";
import {
  CardContainer,
  DurationType,
  getDurationInSeconds,
  getNormalizedDuration,
} from "@msys/ui";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField.js";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { SwitchField } from "../../../commons/form-fields/SwitchField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import {
  DEFAULT_INVOICE_PAYMENT_TERM_DURATION,
  DURATION_MAX_VALUES,
} from "../../../utils.js";
import {
  OrganisationInvoiceSettingsForm_DefaultsFragment,
  useOrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation,
} from "./OrganisationInvoiceSettingsBox.generated.js";

interface Props {
  organisationId: string;
  organisationDefaults: OrganisationInvoiceSettingsForm_DefaultsFragment;
}

export function OrganisationInvoiceSettingsBox(props: Props) {
  const { t } = useTranslate("OrganisationSettings");
  return (
    <CardContainer isExpandable title={t("Invoice settings")}>
      <Box p={1}>
        <OrganisationInvoiceSettingsForm {...props} />
      </Box>
    </CardContainer>
  );
}

interface FormValues {
  defaultInvoiceShowLaborCostSeparately: boolean;
  defaultInvoicePaymentTermDurationType: DurationType;
  defaultInvoicePaymentTermDurationAmount: number;
}

function OrganisationInvoiceSettingsForm({
  organisationId,
  organisationDefaults,
}: {
  organisationId: string;
  organisationDefaults: OrganisationInvoiceSettingsForm_DefaultsFragment;
}) {
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();
  const { t } = useTranslate(["OrganisationSettings", "QuoteEditFooter"]);
  const { enqueueSnackbar } = useSnackbar();
  const [modifyOrganisationDefaults] =
    useOrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation({
      client,
    });

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const [durationAmount, durationType] = getNormalizedDuration(
    organisationDefaults.defaultInvoicePaymentTermDuration ||
      DEFAULT_INVOICE_PAYMENT_TERM_DURATION
  );

  const initialValues = {
    defaultInvoiceShowLaborCostSeparately:
      organisationDefaults.defaultInvoiceShowLaborCostSeparately,
    defaultInvoicePaymentTermDurationType: durationType,
    defaultInvoicePaymentTermDurationAmount: durationAmount,
  };

  const validationSchema = Yup.object().shape({
    defaultInvoiceShowLaborCostSeparately: Yup.boolean()
      .label(
        t("Show labor cost separately by default", {
          ns: "OrganisationSettings",
        })
      )
      .required(),
    defaultInvoicePaymentTermDurationAmount: Yup.number()
      .label(
        t("Default payment term", {
          ns: "OrganisationSettings",
        })
      )
      .min(1)
      .required(),
    defaultInvoicePaymentTermDurationType: Yup.string()
      .label(
        t("Units", {
          ns: "QuoteEditFooter",
        })
      )
      .oneOf(["days", "weeks", "months"]),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyOrganisationDefaults({
        variables: {
          input: {
            defaultInvoiceShowLaborCostSeparately:
              values.defaultInvoiceShowLaborCostSeparately,
            defaultInvoicePaymentTermDuration: getDurationInSeconds({
              durationAmount: values.defaultInvoicePaymentTermDurationAmount,
              durationType: values.defaultInvoicePaymentTermDurationType,
            }),
          },
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack flexDirection="column">
            <Stack>
              <FormattedFloatField
                name="defaultInvoicePaymentTermDurationAmount"
                label={t("Default payment term", {
                  ns: "OrganisationSettings",
                })}
                type="float"
                min={0}
                max={
                  DURATION_MAX_VALUES[
                    formikProps.values.defaultInvoicePaymentTermDurationType
                  ]
                }
                disabled={false}
              />
              <SelectField
                name="defaultInvoicePaymentTermDurationType"
                label={t("Units", {
                  ns: "QuoteEditFooter",
                })}
                disabled={false}
                options={[
                  {
                    label: t("Days", {
                      ns: "QuoteEditFooter",
                    }),
                    value: "days",
                  },
                  {
                    label: t("Weeks", {
                      ns: "QuoteEditFooter",
                    }),
                    value: "weeks",
                  },
                  {
                    label: t("Months", {
                      ns: "QuoteEditFooter",
                    }),
                    value: "months",
                  },
                ]}
              />
            </Stack>

            <Divider />

            <div>
              <Typography>
                {t("Display options", {
                  ns: "OrganisationSettings",
                })}
              </Typography>
              <SwitchField
                name="defaultInvoiceShowLaborCostSeparately"
                label={t("Show labor cost separately by default", {
                  ns: "OrganisationSettings",
                })}
                disabled={false}
              />
            </div>

            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
