import React from "react";
import { Popover } from "@mui/material";

export const useContextMenu = <T extends HTMLElement>(
  menu?: React.ReactNode
): [(e: React.MouseEvent<T>) => void, JSX.Element | null] => {
  const [element, setElement] = React.useState<Element | null>(null);

  const handleClick = React.useCallback(
    (e: React.MouseEvent<T>) => {
      if (!menu) return;
      e.preventDefault();
      e.stopPropagation();
      const element = e.currentTarget || e.target;
      if (element && element instanceof Element) {
        setElement(element);
      }
    },
    [menu]
  );

  const handleClose = React.useCallback(() => {
    setElement(null);
  }, []);

  const menuElement = React.useMemo(
    () =>
      element ? (
        <Popover
          disableAutoFocus={true}
          disableEnforceFocus={true}
          disableEscapeKeyDown={true}
          disableRestoreFocus={true}
          disableScrollLock={true}
          open={true}
          anchorEl={element}
          onClose={(e, reason) => {
            (e as any)?.preventDefault();
            (e as any)?.stopPropagation();
            handleClose();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              onMouseDown: e => {
                e.stopPropagation();
              },
              onTouchStart: e => {
                e.stopPropagation();
              },
              onClick: e => {
                e.preventDefault();
                e.stopPropagation();
                setElement(null);
              },
            },
          }}
        >
          {menu}
        </Popover>
      ) : null,
    [element, menu, handleClose]
  );

  return [handleClick, menuElement];
};
