import { gql } from "@apollo/client";
import { Bookmark as BookmarkIcon } from "@mui/icons-material";
import { BookmarkBorder as BookmarkBorderIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { TemplateQuoteSubscriptionStatus_QuoteTemplateFragment } from "./TemplateQuoteSubscriptionStatus.generated.js";

interface Props {
  doc: TemplateQuoteSubscriptionStatus_QuoteTemplateFragment;
}

export const TemplateQuoteSubscriptionStatusIcon = ({ doc }: Props) => {
  const { t } = useTranslate(["TemplatesPublishing"]);

  const label = doc.subscribedToTemplate
    ? t("Subscribed", {
        ns: "TemplatesPublishing",
      })
    : t("Not subscribed", {
        ns: "TemplatesPublishing",
      });

  return (
    <Tooltip title={label}>
      {doc.subscribedToTemplate ? (
        <BookmarkIcon color="warning" />
      ) : (
        <BookmarkBorderIcon color="disabled" />
      )}
    </Tooltip>
  );
};
