import { Box } from "@mui/material";
import React, { FC } from "react";
import { CardContainer } from "@msys/ui";
import { Page } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";

export const ExampleLayoutMd1Column: FC = () => {
  return (
    <Page>
      <PageContainer>
        <CardContainer title="Print preview">
          <Box height={1400} />
        </CardContainer>
      </PageContainer>
    </Page>
  );
};
