/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated.js';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated.js';
import { CreateCrmOrganisation_CrmCompanyFragmentDoc } from '../crm-companies/CrmCompany.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationVariables = Types.Exact<{
  input: Types.CopySupplierOrganisationAsCrmInput;
}>;


export type CopySupplierOrganisationAsCrm__NewSupplierMutation = { copySupplierOrganisationAsCrm: { __typename: 'CopySupplierOrganisationAsCrmResult', crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, number: string, email: any, abcCategory: Types.CrmOrganisationAbcCategory, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any }> } } };

export type SapDuplicateCrmCompanyFragment = { __typename: 'CrmCompany', id: string, number: string, title: string, email: any, abcCategory: Types.CrmOrganisationAbcCategory, phones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType }>, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, contactPerson?: { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, fullname: string } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any }> };

export type SapCreateBusinessPartnerEndClientMutationVariables = Types.Exact<{
  input: Types.SapCreateBusinessPartnerEndClientInput;
}>;


export type SapCreateBusinessPartnerEndClientMutation = { sapCreateBusinessPartnerEndClient: { __typename: 'SapCreateBusinessPartnerEndClientResult', fioriLaunchPadUrl: string } };

export type SapCreateBusinessPartnerCraftsmanMutationVariables = Types.Exact<{
  input: Types.SapCreateBusinessPartnerCustomerInput;
}>;


export type SapCreateBusinessPartnerCraftsmanMutation = { sapCreateBusinessPartnerCustomer: { __typename: 'SapCreateBusinessPartnerCustomerResult', ok: boolean } };

export type SapSearchBusinessPartnerEndClientDuplicatesQueryVariables = Types.Exact<{
  input: Types.SapSearchBusinessPartnerEndClientDuplicatesInput;
}>;


export type SapSearchBusinessPartnerEndClientDuplicatesQuery = { sapSearchBusinessPartnerEndClientDuplicates: { __typename: 'SapSearchBusinessPartnerEndClientDuplicatesResult', rule?: any | null, scoredCrmCompanies: Array<{ __typename: 'SapSearchBusinessPartnerEndClientDuplicatesResultScoredCrmCompany', score: number, crmCompany: { __typename: 'CrmCompany', id: string, number: string, title: string, email: any, abcCategory: Types.CrmOrganisationAbcCategory, phones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType }>, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, contactPerson?: { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, fullname: string } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any }> }, columnScores: Array<{ __typename: 'SapSearchBusinessPartnerEndClientDuplicatesResultScoredCrmCompanyColumnScore', columnName: string, score?: number | null }> }> } };

export type SapSearchBusinessPartnerCustomerDuplicatesQueryVariables = Types.Exact<{
  input: Types.SapSearchBusinessPartnerCustomerDuplicatesInput;
}>;


export type SapSearchBusinessPartnerCustomerDuplicatesQuery = { sapSearchBusinessPartnerCustomerDuplicates: { __typename: 'SapSearchBusinessPartnerCustomerDuplicatesResult', rule?: any | null, scoredCrmCompanies: Array<{ __typename: 'SapSearchBusinessPartnerCustomerDuplicatesResultScoredCrmCompany', score: number, crmCompany: { __typename: 'CrmCompany', id: string, number: string, title: string, email: any, abcCategory: Types.CrmOrganisationAbcCategory, phones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType }>, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, contactPerson?: { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, fullname: string } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any }> }, columnScores: Array<{ __typename: 'SapSearchBusinessPartnerCustomerDuplicatesResultScoredCrmCompanyColumnScore', columnName: string, score?: number | null }> }> } };

export type CrmCreateModal__SapDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CrmCreateModal__SapDataQuery = { sapValueHelp: { __typename: 'SapS4HanaValueHelp', businessPartnerSalesOffices: Array<{ __typename: 'SapS4HanaValueHelpAvailableValue', label: string, value: string }> }, sapConfig: { __typename: 'SapS4HanaConfig', crmUserIdCustomFieldKey: string } };

export const SapDuplicateCrmCompanyFragmentDoc = gql`
    fragment SapDuplicateCrmCompany on CrmCompany {
  id
  number
  title
  email
  phones {
    id
    number
    type
  }
  billingAddress {
    ...AddressDetails__Address
  }
  contactPerson {
    id
    firstname
    familyname
    fullname
  }
  ...CreateCrmOrganisation_CrmCompany
}
    ${AddressDetails__AddressFragmentDoc}
${CreateCrmOrganisation_CrmCompanyFragmentDoc}`;
export const CopySupplierOrganisationAsCrm__NewSupplierDocument = gql`
    mutation CopySupplierOrganisationAsCrm__NewSupplier($input: CopySupplierOrganisationAsCrmInput!) {
  copySupplierOrganisationAsCrm(input: $input) {
    crmOrganisation {
      id
      title
      number
      email
      abcCategory
      contactPerson {
        id
        fullname
      }
      billingAddress {
        ...AddressDetails__Address
      }
      logo {
        ...AttachmentSnapshot
      }
      customFields {
        key
        value
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationFn = Apollo.MutationFunction<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>;

/**
 * __useCopySupplierOrganisationAsCrm__NewSupplierMutation__
 *
 * To run a mutation, you first call `useCopySupplierOrganisationAsCrm__NewSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySupplierOrganisationAsCrm__NewSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySupplierOrganisationAsCrmNewSupplierMutation, { data, loading, error }] = useCopySupplierOrganisationAsCrm__NewSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopySupplierOrganisationAsCrm__NewSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>(CopySupplierOrganisationAsCrm__NewSupplierDocument, options);
      }
export type CopySupplierOrganisationAsCrm__NewSupplierMutationHookResult = ReturnType<typeof useCopySupplierOrganisationAsCrm__NewSupplierMutation>;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationResult = Apollo.MutationResult<CopySupplierOrganisationAsCrm__NewSupplierMutation>;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationOptions = Apollo.BaseMutationOptions<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>;
export const SapCreateBusinessPartnerEndClientDocument = gql`
    mutation SapCreateBusinessPartnerEndClient($input: SapCreateBusinessPartnerEndClientInput!) {
  sapCreateBusinessPartnerEndClient(input: $input) {
    fioriLaunchPadUrl
  }
}
    `;
export type SapCreateBusinessPartnerEndClientMutationFn = Apollo.MutationFunction<SapCreateBusinessPartnerEndClientMutation, SapCreateBusinessPartnerEndClientMutationVariables>;

/**
 * __useSapCreateBusinessPartnerEndClientMutation__
 *
 * To run a mutation, you first call `useSapCreateBusinessPartnerEndClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSapCreateBusinessPartnerEndClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sapCreateBusinessPartnerEndClientMutation, { data, loading, error }] = useSapCreateBusinessPartnerEndClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSapCreateBusinessPartnerEndClientMutation(baseOptions?: Apollo.MutationHookOptions<SapCreateBusinessPartnerEndClientMutation, SapCreateBusinessPartnerEndClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SapCreateBusinessPartnerEndClientMutation, SapCreateBusinessPartnerEndClientMutationVariables>(SapCreateBusinessPartnerEndClientDocument, options);
      }
export type SapCreateBusinessPartnerEndClientMutationHookResult = ReturnType<typeof useSapCreateBusinessPartnerEndClientMutation>;
export type SapCreateBusinessPartnerEndClientMutationResult = Apollo.MutationResult<SapCreateBusinessPartnerEndClientMutation>;
export type SapCreateBusinessPartnerEndClientMutationOptions = Apollo.BaseMutationOptions<SapCreateBusinessPartnerEndClientMutation, SapCreateBusinessPartnerEndClientMutationVariables>;
export const SapCreateBusinessPartnerCraftsmanDocument = gql`
    mutation SapCreateBusinessPartnerCraftsman($input: SapCreateBusinessPartnerCustomerInput!) {
  sapCreateBusinessPartnerCustomer(input: $input) {
    ok
  }
}
    `;
export type SapCreateBusinessPartnerCraftsmanMutationFn = Apollo.MutationFunction<SapCreateBusinessPartnerCraftsmanMutation, SapCreateBusinessPartnerCraftsmanMutationVariables>;

/**
 * __useSapCreateBusinessPartnerCraftsmanMutation__
 *
 * To run a mutation, you first call `useSapCreateBusinessPartnerCraftsmanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSapCreateBusinessPartnerCraftsmanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sapCreateBusinessPartnerCraftsmanMutation, { data, loading, error }] = useSapCreateBusinessPartnerCraftsmanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSapCreateBusinessPartnerCraftsmanMutation(baseOptions?: Apollo.MutationHookOptions<SapCreateBusinessPartnerCraftsmanMutation, SapCreateBusinessPartnerCraftsmanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SapCreateBusinessPartnerCraftsmanMutation, SapCreateBusinessPartnerCraftsmanMutationVariables>(SapCreateBusinessPartnerCraftsmanDocument, options);
      }
export type SapCreateBusinessPartnerCraftsmanMutationHookResult = ReturnType<typeof useSapCreateBusinessPartnerCraftsmanMutation>;
export type SapCreateBusinessPartnerCraftsmanMutationResult = Apollo.MutationResult<SapCreateBusinessPartnerCraftsmanMutation>;
export type SapCreateBusinessPartnerCraftsmanMutationOptions = Apollo.BaseMutationOptions<SapCreateBusinessPartnerCraftsmanMutation, SapCreateBusinessPartnerCraftsmanMutationVariables>;
export const SapSearchBusinessPartnerEndClientDuplicatesDocument = gql`
    query SapSearchBusinessPartnerEndClientDuplicates($input: SapSearchBusinessPartnerEndClientDuplicatesInput!) {
  sapSearchBusinessPartnerEndClientDuplicates(input: $input) {
    scoredCrmCompanies {
      crmCompany {
        ...SapDuplicateCrmCompany
      }
      score
      columnScores {
        columnName
        score
      }
    }
    rule
  }
}
    ${SapDuplicateCrmCompanyFragmentDoc}`;

/**
 * __useSapSearchBusinessPartnerEndClientDuplicatesQuery__
 *
 * To run a query within a React component, call `useSapSearchBusinessPartnerEndClientDuplicatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSapSearchBusinessPartnerEndClientDuplicatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSapSearchBusinessPartnerEndClientDuplicatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSapSearchBusinessPartnerEndClientDuplicatesQuery(baseOptions: Apollo.QueryHookOptions<SapSearchBusinessPartnerEndClientDuplicatesQuery, SapSearchBusinessPartnerEndClientDuplicatesQueryVariables> & ({ variables: SapSearchBusinessPartnerEndClientDuplicatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SapSearchBusinessPartnerEndClientDuplicatesQuery, SapSearchBusinessPartnerEndClientDuplicatesQueryVariables>(SapSearchBusinessPartnerEndClientDuplicatesDocument, options);
      }
export function useSapSearchBusinessPartnerEndClientDuplicatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SapSearchBusinessPartnerEndClientDuplicatesQuery, SapSearchBusinessPartnerEndClientDuplicatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SapSearchBusinessPartnerEndClientDuplicatesQuery, SapSearchBusinessPartnerEndClientDuplicatesQueryVariables>(SapSearchBusinessPartnerEndClientDuplicatesDocument, options);
        }
export function useSapSearchBusinessPartnerEndClientDuplicatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SapSearchBusinessPartnerEndClientDuplicatesQuery, SapSearchBusinessPartnerEndClientDuplicatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SapSearchBusinessPartnerEndClientDuplicatesQuery, SapSearchBusinessPartnerEndClientDuplicatesQueryVariables>(SapSearchBusinessPartnerEndClientDuplicatesDocument, options);
        }
export type SapSearchBusinessPartnerEndClientDuplicatesQueryHookResult = ReturnType<typeof useSapSearchBusinessPartnerEndClientDuplicatesQuery>;
export type SapSearchBusinessPartnerEndClientDuplicatesLazyQueryHookResult = ReturnType<typeof useSapSearchBusinessPartnerEndClientDuplicatesLazyQuery>;
export type SapSearchBusinessPartnerEndClientDuplicatesSuspenseQueryHookResult = ReturnType<typeof useSapSearchBusinessPartnerEndClientDuplicatesSuspenseQuery>;
export type SapSearchBusinessPartnerEndClientDuplicatesQueryResult = Apollo.QueryResult<SapSearchBusinessPartnerEndClientDuplicatesQuery, SapSearchBusinessPartnerEndClientDuplicatesQueryVariables>;
export const SapSearchBusinessPartnerCustomerDuplicatesDocument = gql`
    query SapSearchBusinessPartnerCustomerDuplicates($input: SapSearchBusinessPartnerCustomerDuplicatesInput!) {
  sapSearchBusinessPartnerCustomerDuplicates(input: $input) {
    scoredCrmCompanies {
      crmCompany {
        ...SapDuplicateCrmCompany
      }
      score
      columnScores {
        columnName
        score
      }
    }
    rule
  }
}
    ${SapDuplicateCrmCompanyFragmentDoc}`;

/**
 * __useSapSearchBusinessPartnerCustomerDuplicatesQuery__
 *
 * To run a query within a React component, call `useSapSearchBusinessPartnerCustomerDuplicatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSapSearchBusinessPartnerCustomerDuplicatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSapSearchBusinessPartnerCustomerDuplicatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSapSearchBusinessPartnerCustomerDuplicatesQuery(baseOptions: Apollo.QueryHookOptions<SapSearchBusinessPartnerCustomerDuplicatesQuery, SapSearchBusinessPartnerCustomerDuplicatesQueryVariables> & ({ variables: SapSearchBusinessPartnerCustomerDuplicatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SapSearchBusinessPartnerCustomerDuplicatesQuery, SapSearchBusinessPartnerCustomerDuplicatesQueryVariables>(SapSearchBusinessPartnerCustomerDuplicatesDocument, options);
      }
export function useSapSearchBusinessPartnerCustomerDuplicatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SapSearchBusinessPartnerCustomerDuplicatesQuery, SapSearchBusinessPartnerCustomerDuplicatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SapSearchBusinessPartnerCustomerDuplicatesQuery, SapSearchBusinessPartnerCustomerDuplicatesQueryVariables>(SapSearchBusinessPartnerCustomerDuplicatesDocument, options);
        }
export function useSapSearchBusinessPartnerCustomerDuplicatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SapSearchBusinessPartnerCustomerDuplicatesQuery, SapSearchBusinessPartnerCustomerDuplicatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SapSearchBusinessPartnerCustomerDuplicatesQuery, SapSearchBusinessPartnerCustomerDuplicatesQueryVariables>(SapSearchBusinessPartnerCustomerDuplicatesDocument, options);
        }
export type SapSearchBusinessPartnerCustomerDuplicatesQueryHookResult = ReturnType<typeof useSapSearchBusinessPartnerCustomerDuplicatesQuery>;
export type SapSearchBusinessPartnerCustomerDuplicatesLazyQueryHookResult = ReturnType<typeof useSapSearchBusinessPartnerCustomerDuplicatesLazyQuery>;
export type SapSearchBusinessPartnerCustomerDuplicatesSuspenseQueryHookResult = ReturnType<typeof useSapSearchBusinessPartnerCustomerDuplicatesSuspenseQuery>;
export type SapSearchBusinessPartnerCustomerDuplicatesQueryResult = Apollo.QueryResult<SapSearchBusinessPartnerCustomerDuplicatesQuery, SapSearchBusinessPartnerCustomerDuplicatesQueryVariables>;
export const CrmCreateModal__SapDataDocument = gql`
    query CrmCreateModal__SapData {
  sapValueHelp {
    businessPartnerSalesOffices {
      label
      value
    }
  }
  sapConfig {
    crmUserIdCustomFieldKey
  }
}
    `;

/**
 * __useCrmCreateModal__SapDataQuery__
 *
 * To run a query within a React component, call `useCrmCreateModal__SapDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCreateModal__SapDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCreateModal__SapDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCrmCreateModal__SapDataQuery(baseOptions?: Apollo.QueryHookOptions<CrmCreateModal__SapDataQuery, CrmCreateModal__SapDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCreateModal__SapDataQuery, CrmCreateModal__SapDataQueryVariables>(CrmCreateModal__SapDataDocument, options);
      }
export function useCrmCreateModal__SapDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCreateModal__SapDataQuery, CrmCreateModal__SapDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCreateModal__SapDataQuery, CrmCreateModal__SapDataQueryVariables>(CrmCreateModal__SapDataDocument, options);
        }
export function useCrmCreateModal__SapDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CrmCreateModal__SapDataQuery, CrmCreateModal__SapDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCreateModal__SapDataQuery, CrmCreateModal__SapDataQueryVariables>(CrmCreateModal__SapDataDocument, options);
        }
export type CrmCreateModal__SapDataQueryHookResult = ReturnType<typeof useCrmCreateModal__SapDataQuery>;
export type CrmCreateModal__SapDataLazyQueryHookResult = ReturnType<typeof useCrmCreateModal__SapDataLazyQuery>;
export type CrmCreateModal__SapDataSuspenseQueryHookResult = ReturnType<typeof useCrmCreateModal__SapDataSuspenseQuery>;
export type CrmCreateModal__SapDataQueryResult = Apollo.QueryResult<CrmCreateModal__SapDataQuery, CrmCreateModal__SapDataQueryVariables>;