import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { CrmOrganisationAbcCategory } from "../../../clients/graphqlTypes.js";

export interface OrganisationCategoryAvatarProps {
  /**
   * Organisation ABC category
   */
  abcCategory: CrmOrganisationAbcCategory;
}

const getColor = (
  theme: Theme,
  abcCategory: CrmOrganisationAbcCategory
): string => {
  switch (abcCategory) {
    case "a":
      return theme.palette.success.main;
    case "b":
      return theme.palette.secondary.main;
    case "c":
      return theme.palette.grey[600];
    default:
      return theme.palette.grey[400];
  }
};

const StyledAvatar = styled("div", {
  shouldForwardProp: prop => !prop.toString().startsWith("$"),
})<{ $abcCategory: CrmOrganisationAbcCategory }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 400;
  border: 2px solid
    ${({ theme, $abcCategory }) => getColor(theme, $abcCategory)};
  color: ${({ theme, $abcCategory }) => getColor(theme, $abcCategory)};
`;

export const OrganisationCategoryAvatar: FC<
  OrganisationCategoryAvatarProps
> = ({ abcCategory }) => (
  <StyledAvatar $abcCategory={abcCategory}>
    {abcCategory === "none" ? "–" : abcCategory.toUpperCase()}
  </StyledAvatar>
);
