/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { InvoiceCalculationFragmentDoc } from '../Fragments.generated.js';
export type InvoiceSum__InvoiceFragment = { __typename: 'Invoice', id: string, showLaborCostSeparately: boolean, calculation: { __typename: 'InvoiceCalculation', discountRate: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, vatRate: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workRate: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, extraItemsPriceSubTotal: number, extraItemsPriceNetTotal: number, extraItemsPriceVatTotal: number, extraItemsPriceTotal: number, paymentItemsPriceSubTotal: number, paymentItemsPriceNetTotal: number, paymentItemsPriceVatTotal: number, paymentItemsPriceTotal: number, openPriceNetTotal: number, openPriceVatTotal: number, openPriceTotal: number, finalPriceNetTotal: number, finalPriceVatTotal: number, finalPriceTotal: number }, quote: { __typename: 'Quote', id: string, discountPercentage: number, discountReason: string, isDiscountPercentageVisible: boolean } };

export const InvoiceSum__InvoiceFragmentDoc = gql`
    fragment InvoiceSum__Invoice on Invoice {
  id
  calculation {
    ...InvoiceCalculation
  }
  quote {
    id
    discountPercentage
    discountReason
    isDiscountPercentageVisible
  }
  showLaborCostSeparately
}
    ${InvoiceCalculationFragmentDoc}`;