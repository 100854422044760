import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import createFetchClient from "openapi-fetch";
import createClient from "openapi-react-query";
import React from "react";
import type { paths } from "./wizard-api.generated.d.ts";

function createOpenapiClient(backendUrl: string) {
  const fetchClient = createFetchClient<paths>({
    baseUrl: `${backendUrl}/embeddable-wizard`,
  });

  const reactQueryClient = createClient(fetchClient);

  return { fetchClient, reactQueryClient };
}

const { fetchClient, reactQueryClient } = createOpenapiClient("/api");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      experimental_prefetchInRender: true,
    },
  },
});

const WizardApiClientContext = React.createContext<
  ReturnType<typeof createOpenapiClient>
>({ fetchClient, reactQueryClient });

interface Props {}

export const WizardApiClientProvider = ({
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <WizardApiClientContext.Provider value={{ fetchClient, reactQueryClient }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WizardApiClientContext.Provider>
  );
};

export function useWizardApiClient() {
  const {
    fetchClient: fetchWizardApiClient,
    reactQueryClient: reactWizardApiQueryClient,
  } = React.useContext(WizardApiClientContext);

  return {
    fetchWizardApiClient,
    reactWizardApiQueryClient,
  };
}

export function useWizardQuestion({
  token,
  viewerId,
  body,
  enabled,
}: {
  token: string | undefined;
  viewerId: string;
  body: paths["/questions"]["post"]["requestBody"]["content"]["application/json"];
  enabled?: boolean;
}) {
  const { reactWizardApiQueryClient } = useWizardApiClient();

  const query = reactWizardApiQueryClient.useQuery(
    "post",
    "/questions",
    {
      body,
      headers: {
        "x-selected-user-id": viewerId,
        authorization: token ? `Bearer ${token}` : undefined,
      },
    },
    {
      gcTime: 0,
      placeholderData: previousData => previousData,
      networkMode: "always",
      enabled,
    }
  );

  return { query };
}
