import { gql } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { Stack } from "../../../commons/layout/Stack.js";
import { QuoteProductsVisibilityFragment } from "../../quotes/Quotes.generated.js";
import { PriceData } from "../helpers.js";
import {
  InvoiceItemDescriptionColumn,
  InvoiceItem as InvoiceDescriptionItem,
} from "./InvoiceItemDescriptionColumn.js";
import {
  InvoiceItemMobileRow_InvoiceExtraItemFragment,
  InvoiceItemMobileRow_InvoiceItemFragment,
  InvoiceItemMobileRow_InvoicePaymentItemFragment,
} from "./InvoiceItemMobileRow.generated.js";

type InvoiceItem =
  | InvoiceItemMobileRow_InvoiceItemFragment
  | InvoiceItemMobileRow_InvoiceExtraItemFragment
  | InvoiceItemMobileRow_InvoicePaymentItemFragment;

type InvoiceQuoteItemMobile =
  | InvoiceItemMobileRow_InvoiceItemFragment["item"]
  | InvoiceItemMobileRow_InvoiceItemFragment["itemSnapshot"];

export const InvoiceItemMobileRow = <QuoteItem extends InvoiceQuoteItemMobile>({
  item,
  quoteItemMapper,
  productsVisibility,
}: {
  item: InvoiceItem & { priceData: PriceData };
  quoteItemMapper: (item: InvoiceDescriptionItem) => QuoteItem;
  productsVisibility: QuoteProductsVisibilityFragment;
}) => {
  const pathForPdf =
    item.__typename === "InvoiceItem" ? quoteItemMapper(item).pathForPdf : null;
  const priceData = item.priceData;

  return (
    <Stack p={1}>
      <Typography style={{ minWidth: "60px" }} variant="body2">
        {pathForPdf}
      </Typography>
      <Stack flexDirection="column" minWidth={0} flex={1}>
        <InvoiceItemDescriptionColumn<QuoteItem>
          item={item}
          quoteItemMapper={quoteItemMapper}
          productsVisibility={productsVisibility}
        />
        {priceData.rows?.length ? (
          <Stack flexDirection="column">
            {priceData.rows.map((row, index) => (
              <Stack key={index} spacing={2}>
                <Typography variant="body2" component="div">
                  {row.quantity}
                </Typography>
                <Typography variant="body2" component="div">
                  {row.unitPrice}
                </Typography>
                <Typography variant="body2" component="div">
                  {row.subtotal}
                </Typography>
              </Stack>
            ))}
          </Stack>
        ) : null}
        {priceData.total && (
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h3" component="div">
              {priceData.total}
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
