import { CardContainer } from "@msys/ui";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useLocation } from "react-use";
import { useUserData } from "../../../auth/useUserData.js";
import { copyTextToClipboard } from "../../addresses/helpers.js";
import { REF_TOKEN_URL_SEARCH_PARAM } from "../../../constants.js";

export const OrganisationReferralBox = () => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const viewer = useUserData().currentUser!;
  const location = useLocation();

  const refUrlBase = new URL(`${location.protocol}//${location.host}`);
  const refTokenUrl = new URL(refUrlBase);
  refTokenUrl.searchParams.set(
    REF_TOKEN_URL_SEARCH_PARAM,
    viewer.viewerRefToken
  );
  const personalRefTokenUrl = new URL(refUrlBase);
  personalRefTokenUrl.searchParams.set(
    REF_TOKEN_URL_SEARCH_PARAM,
    viewer.viewerPersonalRefToken
  );

  return (
    <CardContainer
      title={t("Referral links", { ns: "OrganisationSettings" })}
      isExpandable
    >
      <Stack direction="column" spacing={1} p={1}>
        <FormControl variant="filled">
          <InputLabel>
            {t("Organisation link", { ns: "OrganisationSettings" })}
          </InputLabel>
          <FilledInput
            value={refTokenUrl}
            readOnly
            fullWidth
            endAdornment={
              <IconButton
                size="small"
                color="secondary"
                onClick={async () => {
                  if (refTokenUrl)
                    await copyTextToClipboard(refTokenUrl.toString());
                  enqueueSnackbar(
                    t("Copied to clipboard", {
                      ns: "Global",
                    })
                  );
                }}
              >
                <Tooltip title={t("Copy link", { ns: "Global" })}>
                  <ContentCopyIcon />
                </Tooltip>
              </IconButton>
            }
          />
        </FormControl>
        <FormControl variant="filled">
          <InputLabel>
            {t("Personal link", { ns: "OrganisationSettings" })}
          </InputLabel>
          <FilledInput
            value={personalRefTokenUrl}
            readOnly
            fullWidth
            endAdornment={
              <IconButton
                size="small"
                color="secondary"
                onClick={async () => {
                  if (personalRefTokenUrl)
                    await copyTextToClipboard(personalRefTokenUrl.toString());
                  enqueueSnackbar(
                    t("Copied to clipboard", {
                      ns: "Global",
                    })
                  );
                }}
              >
                <Tooltip title={t("Copy link", { ns: "Global" })}>
                  <ContentCopyIcon />
                </Tooltip>
              </IconButton>
            }
          />
        </FormControl>
      </Stack>
    </CardContainer>
  );
};
