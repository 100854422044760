import { useFormikContext } from "formik";
import { SelectField } from "../../commons/form-fields/SelectField.js";
import { QuantityUnit } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";
import { useQuantityUnits } from "./useQuantityUnits.js";

interface Props {
  name: string;
  size?: React.ComponentProps<typeof SelectField>["size"];
  hideLabel?: boolean;
  /**
   * Whether to use placeholder in the Unit field
   */
  usePlaceholder?: boolean;
  /**
   * Whether to disable editing of unit type
   */
  disabled?: boolean;
  /**
   * Whether to show select
   */
  showSelect?: boolean;
}

export const QuantityUnitField = ({
  name,
  hideLabel,
  usePlaceholder = false,
  showSelect = true,
  disabled,
  size,
}: Props) => {
  const { t } = useTranslate("QuantityUnitField");
  const { quantityUnitOptions, quantityUnitLabels } = useQuantityUnits();

  const { getFieldProps } = useFormikContext();
  const unitValue = getFieldProps<QuantityUnit>(name).value;

  return !showSelect ? (
    <div style={{ minWidth: "90px", flexBasis: "52%" }}>
      {quantityUnitLabels[unitValue]}
    </div>
  ) : (
    <SelectField
      name={name}
      placeholder={usePlaceholder ? t("Unit") : undefined}
      label={usePlaceholder || hideLabel ? "" : t("Unit")}
      options={quantityUnitOptions}
      disabled={disabled}
      size={size}
      displayEmpty
      style={{ minWidth: "90px", flexBasis: "52%" }}
    />
  );
};
