import { notNull } from "@msys/common";
import { useUserData } from "../../auth/useUserData.js";
import { CustomFieldObjectType } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

export function useCustomFieldObjectTypes() {
  const { t } = useTranslate("CustomFields");
  const viewer = useUserData().currentUser!;

  const customFieldObjectTypes = (
    [
      "Building",
      viewer.organisation.capabilities.includes("INVOICING") ? "Invoice" : null,
      viewer.organisation.capabilities.includes("INVOICING")
        ? "InvoiceItem"
        : null,
      viewer.organisation.capabilities.includes("ORDERING") ? "Order" : null,
      viewer.organisation.capabilities.includes("ORDERING")
        ? "OrderItem"
        : null,
      "Organisation",
      "Project",
      viewer.organisation.capabilities.includes("PLANNING")
        ? "PlanSession"
        : null,
      viewer.organisation.capabilities.includes("QUOTING") ? "Quote" : null,
      viewer.organisation.capabilities.includes("QUOTING") ? "QuoteItem" : null,
      "User",
      "UserProjectMembership",
    ] as (CustomFieldObjectType | null)[]
  ).filter(notNull);

  const customFieldObjectTypeLabels: Record<CustomFieldObjectType, string> = {
    Building: t("Building"),
    Order: t("Purchase order"),
    OrderItem: t("Purchase order item"),
    Organisation: t("Organisation"),
    Project: t("Project"),
    PlanSession: t("Planned session"),
    Quote: t("Quote"),
    QuoteItem: t("Quote item"),
    Invoice: t("Invoice"),
    InvoiceItem: t("Invoice item"),
    User: t("User"),
    UserProjectMembership: t("Project membership"),
  };

  return { customFieldObjectTypes, customFieldObjectTypeLabels };
}
