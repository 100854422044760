import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useParams } from "react-router-dom";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageHeader } from "../../commons/layout/PageHeader.js";
import { InvoiceAction } from "../../features/invoices/InvoiceAction.js";
import { InvoicePreview } from "../../features/invoices/InvoicePreview.js";
import { InvoicePreviewWithSelectTreeItemsForm } from "../../features/invoices/InvoicePreviewWithSelectTreeItemsForm.js";
import { InvoiceSubHeader } from "../../features/invoices/InvoiceSubHeader.js";
import {
  useProjectOutgoingInvoice_QuoteInvoiceCalculationLazyQuery,
  useProjectOutgoingInvoiceQuery,
} from "./ProjectOutgoingInvoice.generated.js";

export const ProjectOutgoingInvoice = ({
  projectId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const { invoiceId } = useParams();
  if (!invoiceId) throw new Error("Invoice id is missing");

  const { t } = useTranslate(["Invoices", "Global"]);

  const client = useApolloClient();
  const query = useProjectOutgoingInvoiceQuery({
    client,
    variables: { invoiceId, projectId },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const invoice = getDataOrNull(query.data?.outgoingInvoice);
  const quote = invoice?.quote;

  const [fetchQuoteInvoiceCalculations, quoteInvoiceCalculationQuery] =
    useProjectOutgoingInvoice_QuoteInvoiceCalculationLazyQuery({ client });
  const quoteInvoiceCalculation =
    quoteInvoiceCalculationQuery.data?.quoteInvoiceCalculation;

  React.useEffect(() => {
    if (projectId && quote?.id) {
      fetchQuoteInvoiceCalculations({
        variables: {
          projectId,
          docId: quote.id,
        },
      });
    }
  }, [fetchQuoteInvoiceCalculations, projectId, quote?.id]);

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Outgoing invoices", {
          ns: "Invoices",
        }),
        to: `/projects/${projectId}/invoices/outgoing`,
      },
      {
        title: invoice?.title ?? "",
        to: `/projects/${projectId}/invoices/outgoing/${invoiceId}`,
      },
    ],
    [prefixBreadcrumbs, t, projectId, invoice?.title, invoiceId]
  );

  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

  return (
    <Page
      subtitle={project?.title}
      title={invoice?.title}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && invoice ? (
          <InvoiceSubHeader
            invoice={invoice}
            project={project}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
          />
        ) : undefined
      }
      action={
        project && invoice ? (
          <InvoiceAction
            invoice={invoice}
            project={project}
            onOpenPreview={() => setIsPreviewOpen(true)}
          />
        ) : undefined
      }
    >
      {project &&
        invoice &&
        quote &&
        quoteInvoiceCalculation &&
        (invoice.status === "draft" ? (
          <InvoicePreviewWithSelectTreeItemsForm
            project={project}
            invoice={invoice}
            quote={quote}
            quoteInvoiceCalculation={quoteInvoiceCalculation}
            docId={invoice.quote.id}
            isPreviewOpen={isPreviewOpen}
            setIsPreviewOpen={setIsPreviewOpen}
          />
        ) : (
          <InvoicePreview
            invoice={invoice}
            payee={invoice.payee}
            payer={invoice.payer}
            project={project}
          />
        ))}
    </Page>
  );
};
