import { GetApp as GetAppIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useOpenFile } from "../../../commons/hooks/useOpenFile.js";
import { Attachment } from "../../../../clients/graphqlTypes.js";

interface Props {
  size?: "small" | "medium";
  projectId: string;
  reportId: string;
  reportFile?: Pick<Attachment, "id" | "url"> | null;
}

export const TimeReportDownloadButton = ({
  size = "small",
  projectId,
  reportId,
  reportFile,
}: Props) => {
  const { openPdf } = useOpenFile();

  const { t } = useTranslate("TimeReports");
  const handleClick = async () => {
    if (reportFile?.url) openPdf(reportFile.url, `TimeReport_${reportId}`);
  };

  return reportFile?.url ? (
    <Tooltip title={t("Download PDF")}>
      <IconButton size={size} color="primary" onClick={handleClick}>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  ) : null;
};
