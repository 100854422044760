import { useFormatting } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { usePropertyUnits } from "./usePropertyUnits.js";

export function usePropertyHelpers() {
  const { t } = useTranslate(["Global", "QuoteItem"]);
  const { getFormattedFloat } = useFormatting();
  const { getUnitLabel } = usePropertyUnits();
  const getBoolLabel = React.useCallback(
    (value: boolean) =>
      value
        ? t("Yes", {
            ns: "Global",
          })
        : t("No", {
            ns: "Global",
          }),
    [t]
  );

  return {
    getUnitLabel,
    getBoolLabel,
    getNumberLabel: getFormattedFloat,
  };
}
