import React from "react";
import { AttachmentUploader } from "../../attachments/AttachmentUploader.js";
import { useProjectAttachments } from "../../attachments/useAttachments.js";

interface Props {
  projectId: string;
  Button: React.ReactElement;
  refetchQueries?: string[];
  handleClick?: () => void;
}

export const AddProjectFilesButton = ({
  projectId,
  Button,
  refetchQueries,
  handleClick,
}: Props) => {
  const { addAttachments, loading } = useProjectAttachments(
    projectId,
    refetchQueries
  );

  const attachmentsInputRef = React.useRef<HTMLInputElement>(null);

  const button = React.cloneElement(Button, {
    onClick: () => {
      attachmentsInputRef.current?.click();
      handleClick?.();
    },
    disabled: loading,
  });

  return (
    <>
      {button}
      <AttachmentUploader
        innerRef={attachmentsInputRef}
        accept="*"
        multiple={true}
        onComplete={async attachments => {
          await addAttachments(attachments);
        }}
      />
    </>
  );
};
