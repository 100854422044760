import { gql, useApolloClient } from "@apollo/client";
import { CardContainer, isImageOr3dModel, TypedAttachment } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { PictureGallery } from "../../../commons/images/PictureGallery.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { useFeature } from "../../../../common/FeatureFlags.js";
import { FilesBoxTable } from "../../attachments/FilesBoxTable.js";
import {
  ProductOverview__ProductFragment,
  use_3d_AddProductDataMutation,
} from "../Product.generated.js";
import { ThreedModelDataUploader } from "../ThreedModelDataUploader.js";
import { ThreeDDataUploader } from "../_3d_DataUploader.js";
import {
  usePimCreateThreedModelMutation,
  use_3d_RemoveProductDataMutation,
} from "./ProductOverviewThreeDBox.generated.js";

interface Props {
  product: ProductOverview__ProductFragment;
  isEditable?: boolean;
  refetchQueriesOnAction?: string[];
}

type DefinitionFile = {
  id: string;
  url: string;
  filename: string;
  mimeType: string;
};

function definitionFileToAttachment(file: DefinitionFile): TypedAttachment {
  if ([".gltf", ".glb"].some(ext => file.filename.endsWith(ext))) {
    return {
      __type: "3d_model",
      url: file.url,
      title: file.filename,
      mimeType: file.mimeType,
      id: file.id,
    };
  }
  if (file.filename === "thumbnail.png" || file.filename === "thumbnail.jpg") {
    return {
      __type: "image",
      url: file.url,
      title: file.filename,
      mimeType: file.mimeType,
      id: file.id,
    };
  }
  return {
    __type: "file",
    url: file.url,
    title: file.filename,
    mimeType: file.mimeType,
    id: file.id,
  };
}

export type FormValues = {
  threedModel: {
    id: string;
    definitionFiles: DefinitionFile[];
  } | null;
};

export function useValidationSchema() {
  return Yup.object().shape({
    threedModel: Yup.object().shape({ id: Yup.string().required() }).nullable(),
  });
}

export function ProductOverviewThreeDBox({
  product,
  isEditable,
  refetchQueriesOnAction,
}: Props) {
  const client = useApolloClient();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const _3d_Pictures = (product.threedModel?.definitionFiles ?? [])
    .map(definitionFileToAttachment)
    .filter(isImageOr3dModel);

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [_3d_addProductDataMutation] = use_3d_AddProductDataMutation({
    client,
    refetchQueries: refetchQueriesOnAction,
  });

  const [_3d_removeProductDataMutation] = use_3d_RemoveProductDataMutation({
    client,
    refetchQueries: refetchQueriesOnAction,
  });

  const { t } = useTranslate("ProductOverview");

  const debug = useFeature("DebugOutput");

  return (
    <CardContainer
      title={t("3D Data")}
      ActionButton={
        isEditable ? (
          <Stack spacing={1 / 2}>
            {!product.threedModel && (
              <Tooltip title={t("Add 3d data")}>
                <IconButton
                  aria-label={"Add 3d data"} //{t("Upload file")}
                  color="primary"
                  onClick={async () => {
                    fileInputRef.current!.click();
                  }}
                  size="small"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}

            {product.threedModel && (
              <Tooltip title={t("Edit 3d data")}>
                <IconButton
                  aria-label={"Edit 3d data"} //{t("Upload file")}
                  color="secondary"
                  onClick={async () => {
                    fileInputRef.current!.click();
                  }}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {product.threedModel && (
              <Tooltip title={t("Delete 3d data")}>
                <IconButton
                  aria-label={"Delete 3d data"} //{t("Upload file")}
                  color="secondary"
                  onClick={async () => {
                    await _3d_removeProductDataMutation({
                      variables: { input: { productId: product.id } },
                    });
                  }}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        ) : undefined
      }
      isInitiallyClosed={!product.threedModel}
      isExpandable={true}
    >
      {isUploadingFile && <LinearProgress />}
      <ThreeDDataUploader
        innerRef={fileInputRef}
        productId={product.id}
        onUploadFile={async uploadFile => {
          setIsUploadingFile(true);
          await _3d_addProductDataMutation({
            variables: {
              input: {
                filename: uploadFile.filename,
                productId: product.id,
              },
            },
          });
          setIsUploadingFile(false);
        }}
      />
      <PictureGallery
        pictures={_3d_Pictures}
        showAdd={false}
        showDelete={false}
        showRotate={false}
        showUpload={false}
      />
      {debug && product.threedModel && (
        <FilesBoxTable
          attachments={product.threedModel.definitionFiles.map(
            definitionFileToAttachment
          )}
          canEdit={false}
        />
      )}
    </CardContainer>
  );
}

export function ProductOverviewThreeDBoxForm() {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const client = useApolloClient();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const _3d_Pictures = (values.threedModel?.definitionFiles ?? [])
    .map(definitionFileToAttachment)
    .filter(isImageOr3dModel);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const [pimCreateThreedModel] = usePimCreateThreedModelMutation({
    client,
  });

  const { t } = useTranslate("ProductOverview");

  const debug = useFeature("DebugOutput");

  return (
    <CardContainer
      title={t("3D Data")}
      ActionButton={
        <Stack spacing={1 / 2}>
          {!values.threedModel && (
            <Tooltip title={t("Add 3d data")}>
              <IconButton
                aria-label={"Add 3d data"} //{t("Upload file")}
                color="primary"
                onClick={async () => {
                  fileInputRef.current!.click();
                }}
                size="small"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}

          {values.threedModel && (
            <Tooltip title={t("Edit 3d data")}>
              <IconButton
                aria-label={"Edit 3d data"} //{t("Upload file")}
                color="secondary"
                onClick={async () => {
                  fileInputRef.current!.click();
                }}
                size="small"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {values.threedModel && (
            <Tooltip title={t("Delete 3d data")}>
              <IconButton
                aria-label={"Delete 3d data"} //{t("Upload file")}
                color="secondary"
                onClick={() => {
                  setFieldValue("threedModel", null);
                }}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      }
      isInitiallyClosed={false}
      isExpandable={true}
    >
      {isUploadingFile && <LinearProgress />}
      <ThreedModelDataUploader
        innerRef={fileInputRef}
        onStart={() => setIsUploadingFile(true)}
        onUploadFile={async uploadFile => {
          const createPimThreedModelResult = await pimCreateThreedModel({
            variables: { input: { filename: uploadFile.filename } },
          });

          if (createPimThreedModelResult.data) {
            setFieldValue(
              "threedModel",
              createPimThreedModelResult.data.pimCreateThreedModel.threedModel
            );
          }
          setIsUploadingFile(false);
        }}
        onCancel={() => setIsUploadingFile(false)}
        onError={() => setIsUploadingFile(false)}
      />
      {values.threedModel && (
        <PictureGallery
          pictures={_3d_Pictures}
          showAdd={false}
          showDelete={false}
          showRotate={false}
          showUpload={false}
        />
      )}
      {debug && values.threedModel && (
        <FilesBoxTable
          attachments={values.threedModel.definitionFiles.map(
            definitionFileToAttachment
          )}
          canEdit={false}
        />
      )}
    </CardContainer>
  );
}
