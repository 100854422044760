import { CircularProgress } from "@mui/material";
import * as React from "react";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useInterval, useUpdateEffect } from "react-use";
import { styled } from "@mui/material/styles";
import { color as colorTheme } from "../../../common/MuiThemeProvider.js";
import { transientOptions } from "../../styles.js";

const randomInt = (min: number, max: number): number =>
  Math.random() * (max + 1 - min) + min;

export interface LoadingBarRef {
  /**
   * Start loading progress
   */
  start: () => void;
  /**
   * Finish loading progress
   */
  complete: () => void;
}

export interface LoadingBarProps {
  /**
   * Loading bar color
   */
  color?: string;
  /**
   * Loading bar height
   */
  height?: number;
  /**
   * Whether to show spinner or not
   */
  spinner?: boolean;
}

const ANIMATION_DURATION = 250;
const RESET_TIME = 500;
const STARTING_TIME = 600;

const Container = styled("div")`
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`;

const Peg = styled("div", transientOptions)<{ $color: string }>`
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: ${({ $color }) => `0 0 10px ${$color}, 0 0 5px ${$color}`};
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
`;

const Bar = styled("div", transientOptions)<{
  $color: string;
  $height: number;
  $finished: boolean;
}>`
  opacity: ${({ $finished }) => ($finished ? 0 : 1)};
  background-color: ${({ $color }) => $color};
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ $height }) => $height}px;
  transition:
    transform ${ANIMATION_DURATION}ms ease,
    opacity ${ANIMATION_DURATION}ms linear
      ${({ $finished }) => ($finished ? "100ms" : "")};
`;

const SpinnerContainer = styled("div", transientOptions)<{
  $finished: boolean;
  $visible: boolean;
}>`
  opacity: ${({ $finished, $visible }) => ($finished || !$visible ? 0 : 1)};
  display: block;
  position: fixed;
  z-index: 9999999;
  top: 8px;
  right: 8px;
  transition: opacity ${ANIMATION_DURATION}ms linear
    ${({ $finished }) => ($finished ? "100ms" : "")};

  ${({ theme }) => theme.breakpoints.down(theme.breakpoints.values.tablet)} {
    display: none;
  }
`;

export const LoadingBar = forwardRef<LoadingBarRef, LoadingBarProps>(
  ({ height = 2, color = colorTheme.lightBlue, spinner = false }, ref) => {
    const [progress, setProgress] = useState<number>(0);
    const [active, setActive] = useState<boolean>(false);

    const started = useRef<boolean>(false);
    const finished = useRef<boolean>(false);

    const starting = useRef<boolean>(false);
    const finishing = useRef<boolean>(false);

    const start = () => {
      setTimeout(() => {
        if (finished.current) finished.current = false;
        if (finishing.current) {
          started.current = true;
          return;
        }
        if (started.current) started.current = false;
        if (active || starting.current) {
          return;
        }
        setActive(true);
        setProgress(randomInt(7, 15));
      });
    };

    const complete = () => {
      setTimeout(() => {
        if (started.current) started.current = false;
        if (starting.current) {
          finished.current = true;
          return;
        }
        if (finished.current) finished.current = false;
        if (!active || finishing.current) {
          return;
        }
        setActive(false);
        setProgress(100);
      });
    };

    useImperativeHandle(ref, () => ({
      start,
      complete,
    }));

    useUpdateEffect(() => {
      if (active) {
        starting.current = true;
        const timeoutStartingId = setTimeout(() => {
          starting.current = false;
          if (finished.current) {
            complete();
          }
        }, STARTING_TIME);
        return () => {
          clearTimeout(timeoutStartingId);
        };
      } else {
        finishing.current = true;
        const timeoutFinishingId = setTimeout(() => {
          setProgress(0);
        }, RESET_TIME);
        const timeoutResettingId = setTimeout(() => {
          finishing.current = false;
          if (started.current) {
            start();
          }
        }, RESET_TIME * 2);
        return () => {
          clearTimeout(timeoutFinishingId);
          clearTimeout(timeoutResettingId);
        };
      }
    }, [active]);

    useInterval(
      () => {
        const random =
          progress >= 90
            ? 1
            : progress >= 80
              ? randomInt(1, 3)
              : progress >= 60
                ? randomInt(2, 6)
                : progress >= 40
                  ? randomInt(4, 10)
                  : randomInt(5, 12);
        if (progress + random <= 99) {
          setProgress(progress + random);
        }
      },
      active ? 500 : null
    );

    return (
      <Container>
        <Bar
          $color={color}
          $height={height}
          $finished={!active}
          style={{
            transform: `translateX(${
              Math.max(Math.min(progress, 100), 0) - 100
            }%)`,
          }}
        >
          <Peg $color={color} />
        </Bar>
        <SpinnerContainer $visible={spinner} $finished={!active}>
          <CircularProgress size={18} style={{ color }} />
        </SpinnerContainer>
      </Container>
    );
  }
);
