import { MenuItemWithIcon } from "@msys/ui";
import { IconButton, Menu, SvgIcon, ThemeProvider } from "@mui/material";
import React from "react";
import { Language } from "../../common/translations/languages.js";
import { theme } from "../../common/MuiThemeProvider.js";

export const LanguageSelector = ({
  languages,
  currentLanguage,
  selectLanguage,
  style,
}: {
  languages: Language[];
  currentLanguage: Language;
  selectLanguage: (language: Language) => void;
  style?: React.CSSProperties;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen} style={style} size={"large"}>
        <SvgIcon
          component={currentLanguage.flag}
          viewBox="0 0 512 512"
          titleAccess={currentLanguage.locale}
        />
      </IconButton>
      <ThemeProvider theme={theme}>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          id="language-selector"
          onClose={handleClose}
        >
          {languages.map(language => (
            <MenuItemWithIcon
              selected={currentLanguage.locale === language.locale}
              icon={<SvgIcon component={language.flag} viewBox="0 0 512 512" />}
              key={language.locale}
              onClick={() => {
                selectLanguage(language);
                handleClose();
              }}
              style={{ height: 48 }}
            >
              {language.language_name}
            </MenuItemWithIcon>
          ))}
        </Menu>
      </ThemeProvider>
    </>
  );
};
