/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectRoutesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectRoutesQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, ticket: boolean, title: string } | null } };

export type ProjectQuoteRoutesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type ProjectQuoteRoutesQuery = { quote: { __typename: 'MissingPermissions' } | { __typename: 'QuoteResult', quote?: { __typename: 'Quote', id: string, owningSystemOrganisationId: string, title: string, viewerPermissions: Array<Types.PermissionName> } | null } };

export type ProjectRequirementRoutesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  requirementId: Types.Scalars['ID']['input'];
}>;


export type ProjectRequirementRoutesQuery = { requirement: { __typename: 'MissingPermissions' } | { __typename: 'RequirementResult', requirement?: { __typename: 'Requirement', id: string, owningSystemOrganisationId: string, title: string, viewerPermissions: Array<Types.PermissionName> } | null } };


export const ProjectRoutesDocument = gql`
    query ProjectRoutes($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        viewerPermissions
        ticket
        title
      }
    }
  }
}
    `;

/**
 * __useProjectRoutesQuery__
 *
 * To run a query within a React component, call `useProjectRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRoutesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectRoutesQuery(baseOptions: Apollo.QueryHookOptions<ProjectRoutesQuery, ProjectRoutesQueryVariables> & ({ variables: ProjectRoutesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectRoutesQuery, ProjectRoutesQueryVariables>(ProjectRoutesDocument, options);
      }
export function useProjectRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectRoutesQuery, ProjectRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectRoutesQuery, ProjectRoutesQueryVariables>(ProjectRoutesDocument, options);
        }
export function useProjectRoutesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectRoutesQuery, ProjectRoutesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectRoutesQuery, ProjectRoutesQueryVariables>(ProjectRoutesDocument, options);
        }
export type ProjectRoutesQueryHookResult = ReturnType<typeof useProjectRoutesQuery>;
export type ProjectRoutesLazyQueryHookResult = ReturnType<typeof useProjectRoutesLazyQuery>;
export type ProjectRoutesSuspenseQueryHookResult = ReturnType<typeof useProjectRoutesSuspenseQuery>;
export type ProjectRoutesQueryResult = Apollo.QueryResult<ProjectRoutesQuery, ProjectRoutesQueryVariables>;
export const ProjectQuoteRoutesDocument = gql`
    query ProjectQuoteRoutes($projectId: ID!, $quoteId: ID!) {
  quote(projectId: $projectId, docId: $quoteId) {
    ... on QuoteResult {
      quote {
        id
        owningSystemOrganisationId
        title
        viewerPermissions
      }
    }
  }
}
    `;

/**
 * __useProjectQuoteRoutesQuery__
 *
 * To run a query within a React component, call `useProjectQuoteRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuoteRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuoteRoutesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useProjectQuoteRoutesQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuoteRoutesQuery, ProjectQuoteRoutesQueryVariables> & ({ variables: ProjectQuoteRoutesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuoteRoutesQuery, ProjectQuoteRoutesQueryVariables>(ProjectQuoteRoutesDocument, options);
      }
export function useProjectQuoteRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuoteRoutesQuery, ProjectQuoteRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuoteRoutesQuery, ProjectQuoteRoutesQueryVariables>(ProjectQuoteRoutesDocument, options);
        }
export function useProjectQuoteRoutesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectQuoteRoutesQuery, ProjectQuoteRoutesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectQuoteRoutesQuery, ProjectQuoteRoutesQueryVariables>(ProjectQuoteRoutesDocument, options);
        }
export type ProjectQuoteRoutesQueryHookResult = ReturnType<typeof useProjectQuoteRoutesQuery>;
export type ProjectQuoteRoutesLazyQueryHookResult = ReturnType<typeof useProjectQuoteRoutesLazyQuery>;
export type ProjectQuoteRoutesSuspenseQueryHookResult = ReturnType<typeof useProjectQuoteRoutesSuspenseQuery>;
export type ProjectQuoteRoutesQueryResult = Apollo.QueryResult<ProjectQuoteRoutesQuery, ProjectQuoteRoutesQueryVariables>;
export const ProjectRequirementRoutesDocument = gql`
    query ProjectRequirementRoutes($projectId: ID!, $requirementId: ID!) {
  requirement(projectId: $projectId, docId: $requirementId) {
    ... on RequirementResult {
      requirement {
        id
        owningSystemOrganisationId
        title
        viewerPermissions
      }
    }
  }
}
    `;

/**
 * __useProjectRequirementRoutesQuery__
 *
 * To run a query within a React component, call `useProjectRequirementRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRequirementRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRequirementRoutesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      requirementId: // value for 'requirementId'
 *   },
 * });
 */
export function useProjectRequirementRoutesQuery(baseOptions: Apollo.QueryHookOptions<ProjectRequirementRoutesQuery, ProjectRequirementRoutesQueryVariables> & ({ variables: ProjectRequirementRoutesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectRequirementRoutesQuery, ProjectRequirementRoutesQueryVariables>(ProjectRequirementRoutesDocument, options);
      }
export function useProjectRequirementRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectRequirementRoutesQuery, ProjectRequirementRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectRequirementRoutesQuery, ProjectRequirementRoutesQueryVariables>(ProjectRequirementRoutesDocument, options);
        }
export function useProjectRequirementRoutesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectRequirementRoutesQuery, ProjectRequirementRoutesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectRequirementRoutesQuery, ProjectRequirementRoutesQueryVariables>(ProjectRequirementRoutesDocument, options);
        }
export type ProjectRequirementRoutesQueryHookResult = ReturnType<typeof useProjectRequirementRoutesQuery>;
export type ProjectRequirementRoutesLazyQueryHookResult = ReturnType<typeof useProjectRequirementRoutesLazyQuery>;
export type ProjectRequirementRoutesSuspenseQueryHookResult = ReturnType<typeof useProjectRequirementRoutesSuspenseQuery>;
export type ProjectRequirementRoutesQueryResult = Apollo.QueryResult<ProjectRequirementRoutesQuery, ProjectRequirementRoutesQueryVariables>;