import { useApolloClient } from "@apollo/client";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { Icon } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { S4HanaSalesQuotationImportModal } from "./S4HanaSalesQuotationImportModal.js";

type Props = {
  projectId: string | null;
  refetchQueries?: string[];
};

export const ImportS4HanaSalesQuotationButton = ({
  projectId,
  refetchQueries,
}: Props) => {
  const client = useApolloClient();
  const { t } = useTranslate("Projects");

  const navigate = useNavigate();

  return (
    <ModalOpenButton
      Modal={S4HanaSalesQuotationImportModal}
      modalProps={{
        projectId: projectId,
        refetchQueries,
        handleComplete: ({ docId, projectId }) => {
          navigate(`/projects/${projectId}/quotes/${docId}`);
        },
      }}
    >
      <MenuItemWithIcon
        icon={
          <Icon>
            <FontAwesomeIcon icon={faFileImport} />
          </Icon>
        }
      >{`${t("Import")} SAP`}</MenuItemWithIcon>
    </ModalOpenButton>
  );
};
