import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { replaceInText } from "@msys/textutils";
import { Modal } from "@msys/ui";
import { Send as SendIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData.js";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField.js";
import { EmailField } from "../../../commons/form-fields/EmailField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { Salutation } from "../../../../clients/graphqlTypes.js";
import {
  useCreateCrmUserMutation,
  useModifyCrmUserMutation,
} from "../../crm-persons/CrmPerson.generated.js";
import { useEmailAddressValidationSchema } from "../../email/validateEmailAddress.js";
import { UserTitleWithNameField } from "../../users/UserTitleWithNameField.js";
import { useSalutation } from "../../users/useSalutation.js";
import {
  useAddProjectContracteeInvitationMutation,
  useProjectMemberCrmModalQuery,
} from "./AddProjectMemberCRMModal.generated.js";

interface FormValues {
  email: string;
  firstName: string;
  familyName: string;
  title: Salutation;
  message: string;
  shareOwnership: boolean;
}

interface Props {
  projectId: string;
  userId?: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (userId: string) => Promise<void> | void;
  refetchQueries?: string[];
}

export const AddProjectMemberCRMModal = ({
  projectId,
  userId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["ProjectMembers", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const { getNameWithSalutation } = useSalutation();
  const { createEmailAddressValidationSchema } =
    useEmailAddressValidationSchema();

  const client = useApolloClient();
  const query = useProjectMemberCrmModalQuery({
    client,
    variables: { projectId },
    fetchPolicy: "network-only",
  });
  const { data, loading: isLoading } = query;
  const [createCrmUser] = useCreateCrmUserMutation({ client });
  const [modifyCrmUser] = useModifyCrmUserMutation({ client });
  const [addProjectContracteeInvitation] =
    useAddProjectContracteeInvitationMutation({ client });

  const project = getDataOrNull(data?.project)?.project;
  const member = project?.crmOrganisation?.members.find(m => m.id === userId);

  const onSubmit = async (values: FormValues) => {
    if (!project) return;

    let uId = userId;
    if (uId) {
      await modifyCrmUser({
        variables: {
          users: [
            {
              id: uId,
              title: values.title,
              firstname: values.firstName,
              familyname: values.familyName,
              email: values.email,
            },
          ],
        },
      });
    } else {
      const createdUser = await createCrmUser({
        variables: {
          input: [
            {
              firstname: values.firstName,
              title: values.title,
              familyname: values.familyName,
              email: values.email,
              organisationId: project.crmOrganisation!.id,
              notices: "",
              phones: [],
            },
          ],
        },
      });

      uId = createdUser.data?.createCrmUser.users[0].id;
    }

    await addProjectContracteeInvitation({
      variables: {
        input: {
          contactUserId: uId!,
          contactOrganisationId: project.crmOrganisation!.id,
          projectId: projectId,
          messageBody: replaceInText(values.message, {
            "[NAME]": getNameWithSalutation(
              values.title,
              values.firstName,
              values.familyName
            ),
          }),
          messageSubject: t(
            "You are invited to join {projectName} on MeisterSystems!",
            {
              ns: "ProjectMembers",
              projectName: project.title ?? "",
            }
          ),
          attachments: [],
        },
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });

    await handleComplete?.(uId!);
    handleClose();
    enqueueSnackbar(
      t("Invitation sent our", {
        ns: "ProjectMembers",
      })
    );
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: createEmailAddressValidationSchema(
          t("Email address", {
            ns: "ProjectMembers",
          }),
          true
        ),
        firstName: Yup.string().label(
          t("First name", {
            ns: "ProjectMembers",
          })
        ),
        familyName: Yup.string()
          .label(
            t("Last name", {
              ns: "ProjectMembers",
            })
          )
          .required(),
        title: Yup.string()
          .label(
            t("Salutation", {
              ns: "Global",
            })
          )
          .required(),
        message: Yup.string()
          .label(
            t("Personal message", {
              ns: "ProjectMembers",
            })
          )
          .required(),
      }),
    [createEmailAddressValidationSchema, t]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        email: member?.email ?? "",
        firstName: member?.firstname ?? "",
        familyName: member?.familyname ?? "",
        title: member?.title ?? "mr",
        message: t(
          "Dear [NAME], Join our project {projectName} on MeisterSystems. Kind Regards, {authorName}",
          {
            ns: "ProjectMembers",
            projectName: project?.title ?? "",
            authorName: viewer.firstname + " " + viewer.familyname,
          }
        ),
        shareOwnership: true,
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting, isValid }: FormikProps<FormValues>) => (
        <Modal
          id="add-project-member-crm-modal"
          title={
            title ??
            t("Invite contractee", {
              ns: "ProjectMembers",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Invite", {
                ns: "ProjectMembers",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                color: "primary",
                endIcon: isSubmitting ? undefined : <SendIcon />,
                loading: isSubmitting,
                disabled: isLoading,
              },
            },
          ]}
          isLoading={isLoading}
        >
          <Form id={formId}>
            <Stack flexDirection="column">
              <Typography variant="h4" color="textSecondary">
                {t("Add invitation details:", {
                  ns: "ProjectMembers",
                })}
              </Typography>

              <EmailField
                name="email"
                label={t("Email address", {
                  ns: "ProjectMembers",
                })}
                required
              />

              <UserTitleWithNameField />

              <Field
                name="message"
                label={t("Personal message", {
                  ns: "ProjectMembers",
                })}
                required
                component={TextField}
                multiline
                rows={7}
                helperText={t(
                  "[NAME] will to be replaced by the name of the contractee",
                  {
                    ns: "ProjectMembers",
                  }
                )}
              />

              <CheckboxField
                disabled={true}
                name="shareOwnership"
                label={
                  <Stack flexDirection="column" mb={1} spacing={0}>
                    <Typography>
                      {t("Share Ownership", {
                        ns: "ProjectMembers",
                      })}
                    </Typography>
                    <Typography variant="body2">
                      {t(
                        "NOTE: If you decide to share ownership with the contractee, they’ll be able to manage their side of the project, eg. accept changes to the quote. ",
                        {
                          ns: "ProjectMembers",
                        }
                      )}
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
