import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import { pick } from "lodash-es";
import React from "react";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import { ItemType, namedOperations } from "../../../clients/graphqlTypes.js";
import { useUserData } from "../../auth/useUserData.js";
import { RestrictedByDocumentPermissionByTypeWithDebug } from "../../auth/RestrictedByDocumentPermissionByType.js";
import { Page } from "../../commons/layout/Page.js";
import { PageColumn } from "../../commons/layout/PageColumn.js";
import { Stack } from "../../commons/layout/Stack.js";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess.js";
import { BUILDING_ITEM_TYPES } from "../../constants.js";
import { FilesBoxTable } from "../../features/attachments/FilesBoxTable.js";
import { useItemAttachments } from "../../features/attachments/useAttachments.js";
import { BuildingAddressBox } from "../../features/buildings/boxes/BuildingAddressBox.js";
import { BuildingSubHeader } from "../../features/buildings/BuildingSubHeader.js";
import {
  createTreeItem,
  createTreeItemInput,
} from "../../features/buildings/trees.js";
import { isAllowedToPaste } from "../../features/doc-items/constraints.js";
import {
  CreateItemFromTemplateArgs,
  CreateItemsFromTemplatesArgs,
  CreateItemsWithProductsArgs,
  useCreateItem,
} from "../../features/doc-items/hooks/useCreateItem.js";
import { useDocItemsClipboard } from "../../features/doc-items/hooks/useDocItemsClipboard.js";
import { VirtualBareTreeStandaloneItem } from "../../trees-virtual/components/VirtualBareTreeStandaloneItem.js";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../trees-virtual/hooks/useExpandedStore.js";
import { VirtualItemTree } from "../../trees-virtual/VirtualItemTree.js";
import { TreeToggleAllExpandedButton } from "../../trees/components/TreeToggleButton.js";
import {
  BuildingEdit_ItemFragment,
  useBuildingEdit_CreateItemsFromTemplatesMutation,
  useBuildingEdit_CreateItemsMutation,
  useBuildingEdit_PasteItemFromClipboardMutation,
  useBuildingEditQuery,
} from "./BuildingEdit.generated.js";
import { AdditionalItemInput } from "../../trees-virtual/types.js";
import { useUpdateEffect } from "react-use";
import {
  createRecursiveTryFocusElementFunction,
  isInTestMode,
} from "../../utils.js";

const REFETCH_QUERIES = [namedOperations.Query.BuildingEdit];

export const BuildingEdit = () => {
  const { buildingId: docId } = useParams();
  if (!docId) throw new Error("Building id is missing");
  const match = useMatch(`/buildings/:buildingId/edit/items/:itemId`);
  const selectedItemId = match?.params?.itemId ?? null;
  const navigate = useNavigate();

  const [additionalItemInput, setAdditionalItemInput] =
    React.useState<AdditionalItemInput | null>(null);

  const onAdditionalItemInputChange = React.useCallback(
    (input: AdditionalItemInput) => {
      setAdditionalItemInput(input);
      setTimeout(() => {
        const inputRank =
          input.itemRank + (input.position === "below" ? 0.5 : -0.5);
        if (!isInTestMode())
          createRecursiveTryFocusElementFunction(
            `input#msys-tree-input-${input.itemParentType}-${input.itemParentId}:enabled[data-node-rank="${inputRank}"]`
          )();
      });
    },
    []
  );

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);

  const pathToDocList = `/buildings`;
  const pathToDoc = `${pathToDocList}/${docId}`;
  const pathToDocPage = `${pathToDoc}/edit`;

  const expandedStore = useExpandedStoreWithLocalStorage(
    "building",
    docId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useBuildingEditQuery({
    client,
    variables: {
      docId,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });

  const viewer = useUserData().currentUser!;

  const doc = query.data?.building;

  const navigateToItem = React.useCallback(
    (id: string) => navigate(`${pathToDocPage}/items/${id}`, { replace: true }),
    [navigate, pathToDocPage]
  );

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  } = useCreateBuildingItems({
    buildingId: docId,
    expandedItemIds: expandedStore.expandedItemIds,
  });

  const TreeItem = React.useMemo(
    () =>
      createTreeItem({
        pathToDocPage,
        docId,
        startConfirmProcess,
        navigateToItem,
        setItemExpanded: expandedStore.setItemExpanded,
        expandedItemIds: expandedStore.expandedItemIds,
        pasteItem,
        isAllowedToPasteItem,
        onAdditionalItemInputChange,
      }),
    [
      pathToDocPage,
      docId,
      startConfirmProcess,
      navigateToItem,
      expandedStore.setItemExpanded,
      expandedStore.expandedItemIds,
      pasteItem,
      isAllowedToPasteItem,
      onAdditionalItemInputChange,
    ]
  );

  const TreeItemInput = React.useMemo(
    () =>
      createTreeItemInput({
        docId,
        createItem,
        createItemByType,
        createItemFromTemplate,
        createItemsFromTemplates,
        createItemsWithProducts,
        pasteItem,
        isAllowedToPasteItem,
      }),
    [
      docId,
      createItem,
      createItemByType,
      createItemFromTemplate,
      createItemsFromTemplates,
      createItemsWithProducts,
      pasteItem,
      isAllowedToPasteItem,
    ]
  );

  const [treeItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!doc) return [[], undefined, []];
    const allDocItems = doc.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems.filter(
      e => true
      // e => e.authorOrganisation.id === viewer.organisation.id
    );

    return [treeItems, rootItem, allDocItems];
  }, [doc]);

  useUpdateEffect(() => {
    setAdditionalItemInput(null);
  }, [selectedItemId, allDocItems]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  const isRootSelected = selectedItemId === rootItem?.id;

  // const projects = doc.projectsInBuilding.edges.map(e => e.node);

  const { addAttachments, modifyAttachment, removeAttachment, loading } =
    useItemAttachments(doc?.rootItem.id ?? "", REFETCH_QUERIES);

  return (
    <Page
      title={doc?.title}
      subHeader={
        doc && rootItem ? (
          <BuildingSubHeader
            docId={docId}
            rootItemId={rootItem.id}
            doc={doc}
            pathToDoc={pathToDoc}
            pathToDocList={pathToDocList}
            pathToDocPage={pathToDocPage}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
          />
        ) : undefined
      }
    >
      {doc && rootItem && (
        <>
          <PageColumn
            $hasRightBorder
            $hasBackground
            $display="block"
            ref={setTreeContainer}
          >
            <Stack flexDirection={"column"} spacing={2}>
              <Box>
                <BuildingAddressBox building={doc} />
              </Box>

              <Box>
                <TreeToggleAllExpandedButton
                  type="button"
                  areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                  setAllItemsExpanded={expandedStore.setAllItemsExpanded}
                />
              </Box>

              <Box>
                <VirtualBareTreeStandaloneItem<BuildingEdit_ItemFragment>
                  item={rootItem}
                  items={treeItems}
                  allItems={treeItems}
                  depth={0}
                  itemComponent={TreeItem}
                  selected={isRootSelected}
                  sticky={isRootSelected}
                  top={0}
                  clickable
                  onClick={() => navigateToItem(rootItem.id)}
                  to={`${pathToDocPage}/items/${rootItem.id}`}
                />

                <VirtualItemTree<BuildingEdit_ItemFragment, true>
                  docId={doc.id}
                  projectId={null}
                  items={treeItems}
                  selectedItemId={selectedItemId}
                  enableCreating={true}
                  enableDragging={true}
                  documentItemTypes={BUILDING_ITEM_TYPES}
                  container={treeContainer}
                  itemComponent={TreeItem}
                  inputComponent={TreeItemInput}
                  expandedStore={expandedStore}
                  additionalItemInput={additionalItemInput}
                />
              </Box>

              <FilesBoxTable
                attachments={doc.attachments}
                canEdit={true}
                addAttachments={addAttachments}
                modifyAttachment={modifyAttachment}
                removeAttachment={removeAttachment}
                loading={loading}
                renderEditIconButton={button => (
                  <RestrictedByDocumentPermissionByTypeWithDebug
                    permissionByType={{
                      QUOTE: "MANAGE_QUOTES",
                      REQUIREMENT: "MANAGE_REQUIREMENTS",
                      TEMPLATE: "MANAGE_TEMPLATE",
                      BUILDING: "MANAGE_ORG",
                    }}
                    document={{
                      docType: "BUILDING",
                      viewerPermissions: viewer.organisationPermissions,
                    }}
                  >
                    {button}
                  </RestrictedByDocumentPermissionByTypeWithDebug>
                )}
              />

              {/* <BuildingProjectsBox
                projects={projects}
                buildingItemId={doc.rootItem.id}
                organisationId={doc.organisation.id}
                canAdd={
                  viewer.organisation.id === doc.organisation.id ||
                  (viewer.organisation.isCraftsmanOrganisation &&
                    doc.organisation.isCrmOrganisation)
                }
              />

              <BuildingContactsBox
                contacts={doc.rootItem.crmLinks}
                buildingId={docId}
                rootItemId={doc.rootItem.id}
                canEdit={
                  viewer.organisation.id === doc.organisation.id ||
                  (viewer.organisation.isCraftsmanOrganisation &&
                    doc.organisation.isCrmOrganisation)
                }
                refetchQueries={["BuildingEdit"]}
              />*/}
            </Stack>
          </PageColumn>
        </>
      )}
      <ConfirmProcess ref={confirmProcessRef} />

      <Outlet
        context={{
          expandedItemIds: expandedStore.expandedItemIds,
          CreateChildComponent: TreeItemInput,
          pasteItem,
          isAllowedToPasteItem,
        }}
      />
    </Page>
  );
};

function useCreateBuildingItems({
  buildingId,
  expandedItemIds,
}: {
  buildingId: string;
  expandedItemIds: string[] | undefined;
}) {
  const client = useApolloClient();
  const [createItems] = useBuildingEdit_CreateItemsMutation({ client });

  const { createItem, createItemByType } = useCreateItem({
    projectId: null,
    createDocumentItems: createItems,
    expandedItemIds,
  });

  const [createItemsFromTemplatesMutation] =
    useBuildingEdit_CreateItemsFromTemplatesMutation({
      client,
    });
  const createItemFromTemplate = React.useCallback(
    async ({
      parentItemId,
      templateQuoteId,
      newItemRank,
    }: CreateItemFromTemplateArgs) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: null,
            parentDocId: buildingId,
            parentItemId,
            templates: [{ templateQuoteId }],
            expandedItemIds,
            rank: newItemRank,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items[0];
    },
    [buildingId, createItemsFromTemplatesMutation, expandedItemIds]
  );

  const createItemsFromTemplates = React.useCallback(
    async ({
      parentItemId,
      templates,
      newItemRank,
    }: CreateItemsFromTemplatesArgs) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: null,
            parentDocId: buildingId,
            parentItemId,
            templates: templates.map(opts => ({
              title: opts.template.title,
              type: "section",
              decisionBehaviorOfSubitems: "NONE",
              estimatedQuantity: opts.quantity,
              templateQuoteId: opts.template.id,
            })),
            expandedItemIds,
            rank: newItemRank,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items;
    },
    [buildingId, createItemsFromTemplatesMutation, expandedItemIds]
  );

  const createItemsWithProducts = React.useCallback(
    async ({
      parentItemId,
      products,
      newItemRank,
    }: CreateItemsWithProductsArgs) => {
      const result = await createItems({
        variables: {
          input: {
            projectId: null,
            docId: buildingId,
            parentItemId,
            items: products.map(opts =>
              "__typename" in opts.product
                ? {
                    title: opts.product.texts?.title ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    product: pick(opts.product, "supplierId", "articleNumber"),
                  }
                : {
                    title: opts.product.productTitle ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    productValues: opts.product,
                  }
            ),
            expandedItemIds,
            rank: newItemRank,
          },
        },
      });

      return result.data?.createItems.items;
    },
    [buildingId, createItems, expandedItemIds]
  );

  const [copiedItem] = useDocItemsClipboard();
  const [pasteItemFromClipboardMutation] =
    useBuildingEdit_PasteItemFromClipboardMutation({ client });
  const pasteItem = React.useCallback(
    async (docId: string, parentItemId: string) => {
      if (!copiedItem) throw new Error("No item copied");

      const result = await pasteItemFromClipboardMutation({
        variables: {
          input: {
            sourceProjectId: copiedItem.projectId,
            sourceDocId: copiedItem.docId,
            sourceItemId: copiedItem.itemId,
            destProjectId: null,
            destDocId: docId,
            destItemId: parentItemId,
            expandedItemIds,
          },
        },
      });
    },
    [copiedItem, pasteItemFromClipboardMutation, expandedItemIds]
  );
  const isAllowedToPasteItem = React.useCallback(
    (parentItem: { type: ItemType }) => {
      if (!copiedItem?.itemType || !parentItem.type) return false;

      return isAllowedToPaste({
        documentItemTypes: BUILDING_ITEM_TYPES,
        copiedItemType: copiedItem.itemType,
        parentItemType: parentItem.type,
      });
    },
    [copiedItem?.itemType]
  );

  return {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  };
}
