import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LoadingSpinner as LoadingContainer, useScreenWidth } from "@msys/ui";
import {
  Dashboard as DashboardIcon,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { ReactComponent as TimesheetIcon } from "../../assets/icons/icon-timesheet.svg";
import { RestrictedByCapability } from "../../auth/RestrictedByCapability.js";
import { useRestrictionFilter } from "../../auth/useRestrictionFilter.js";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { useBubbleContext } from "../../commons/hooks/useBubbleContext.js";
import { PageTopbarItem } from "../../commons/layout/Page.js";
import { ChatIcon } from "../../features/channels/ChatIcon.js";
import { SelectInfoMessageItem } from "../../features/doc-items/items/SelectInfoMessageItem.js";
import { InvoiceIcon } from "../../features/invoices/InvoiceIcon.js";
import { QuoteIcon } from "../../features/quotes/QuoteIcon.js";
import { RequirementIcon } from "../../features/requirements/RequirementIcon.js";
import { TaskIcon } from "../../features/tasks/TaskIcon.js";
import { matchPathPredicate } from "../../utils.js";
import { ProjectCommunicationRoutes } from "./ProjectCommunicationRoutes.js";
import { ProjectIncomingInvoice } from "./ProjectIncomingInvoice.js";
import {
  ProjectIncomingInvoices,
  ProjectOutgoingInvoices,
} from "./ProjectInvoices.js";
import { ProjectMaterialsRoutes } from "./ProjectMaterialsRoutes.js";
import { ProjectOutgoingInvoice } from "./ProjectOutgoingInvoice.js";
import { ProjectOverview } from "./ProjectOverview.js";
import { ProjectQuoteRoutes } from "./ProjectQuoteRoutes.js";
import { ProjectQuotes } from "./ProjectQuotes.js";
import { ProjectRequest } from "./ProjectRequest.js";
import { ProjectRequests } from "./ProjectRequests.js";
import { ProjectRequirementRoutes } from "./ProjectRequirementRoutes.js";
import { ProjectRequirements } from "./ProjectRequirements.js";
import { useProjectRoutesQuery } from "./ProjectRoutes.generated.js";
import { ProjectTasks } from "./ProjectTasks.js";
import { ProjectTasksItem } from "./ProjectTasksItem.js";
import { ProjectTimeReportPreview } from "./ProjectTimeReportPreview.js";
import { ProjectTimeReports } from "./ProjectTimeReports.js";

type PageSubmenuItem = PageTopbarItem;

export const ProjectRoutes = () => {
  const { projectId } = useParams();
  if (!projectId) throw new Error("Project id is missing");
  const location = useLocation();

  const { bubble } = useBubbleContext();
  const bubbleAmount = bubble.perProject[projectId] || 0;
  const { isMaxPhone, isMinTablet } = useScreenWidth();

  const { t } = useTranslate(["ProjectPageTopbar", "SidebarItems", "Invoices"]);

  const client = useApolloClient();
  const { data, loading } = useProjectRoutesQuery({
    client,
    variables: {
      projectId,
    },
  });

  const project = getDataOrNull(data?.project)?.project;
  const submenuItems: PageSubmenuItem[] = React.useMemo(() => {
    if (!project?.viewerPermissions) return [];

    return [
      {
        name: "project-overview",
        label: t("Overview", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/overview`,
        icon: <DashboardIcon />,
        projectPermission: { name: "READ_PROJECT", project },
      },
      {
        name: "project-communication",
        label: t("Communication", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/communication`,
        bubbleAmount,
        icon: <ChatIcon />,
        isInMoreMenu: true,
        projectPermission: { name: "READ_PROJECT", project },
      },
      {
        name: "project-requirements",
        label: t("Requirements", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/requirements`,
        icon: <RequirementIcon />,
        isInMoreMenu: true,
        projectPermission: { name: "READ_REQUIREMENTS", project },
      },
      {
        name: "project-quotes",
        label: t("Quotes", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/quotes`,
        icon: <QuoteIcon />,
        projectPermission: { name: "READ_QUOTES", project },
      },
      ...(isMaxPhone
        ? ([
            {
              name: "project-tasks",
              label: t("Tasks", {
                ns: "ProjectPageTopbar",
              }),
              path: `/projects/${projectId}/tasks/edit#list`,
              icon: <TaskIcon />,
              projectPermission: {
                name: "READ_PROJECT",
                project,
              },
              capability: "EXECUTING",
            },
            {
              name: "project-timesheet",
              label: t("Timesheet", {
                ns: "ProjectPageTopbar",
              }),
              path: `/projects/${projectId}/tasks/edit#timesheet`,
              icon: <TimesheetIcon />,
              projectPermission: {
                name: "READ_PROJECT",
                project,
              },
              capability: "EXECUTING",
            },
          ] as PageSubmenuItem[])
        : ([
            {
              name: "project-tasks",
              label: t("Tasks", {
                ns: "ProjectPageTopbar",
              }),
              path: `/projects/${projectId}/tasks`,
              icon: <TaskIcon />,
              projectPermission: {
                name: "READ_PROJECT",
                project,
              },
              capability: "EXECUTING",
            },
          ] as PageSubmenuItem[])),
      {
        name: "project-materials",
        label: t("Materials", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/materials`,
        icon: <ShoppingCartOutlined />,
        isInMoreMenu: true,
        capability: "ORDERING",
        projectPermission: { name: "MANAGE_QUOTES", project },
      },
      {
        name: "project-invoices",
        label: t("Invoices", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/invoices`,
        icon: <InvoiceIcon />,
        isInMoreMenu: true,
        projectPermission: { name: "READ_INVOICES", project },
      },
    ];
  }, [project, t, projectId, bubbleAmount, isMaxPhone]);

  const activeItem = submenuItems.find(item =>
    item.activePaths
      ? item.activePaths.some(path => matchPathPredicate(`${path}/*`, location))
      : matchPathPredicate(`${item.path}/*`, location)
  );

  const breadcrumbs = React.useMemo(
    () => [
      {
        title: project?.title ?? "",
        to: `/projects/${projectId}`,
      },
    ],
    [project?.title, projectId]
  );

  const initialInvoicesTabOptions = [
    {
      value: "outgoing" as const,
      label: t("Outgoing", { ns: "Invoices" }),
      capability: "INVOICING" as const,
    },
    {
      value: "incoming" as const,
      label: t("Incoming", { ns: "Invoices" }),
    },
  ];
  const restrictionFilter = useRestrictionFilter();
  const invoicesTabOptions =
    initialInvoicesTabOptions.filter(restrictionFilter);

  if (loading && !data) return <LoadingContainer />;
  if (!project) return <PageNotFound />;

  return (
    <Routes>
      <Route index element={<Navigate to={`overview`} replace />} />
      <Route
        path={`overview`}
        element={
          <ProjectOverview
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      <Route
        path={`communication/*`}
        element={
          <ProjectCommunicationRoutes
            project={project}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      {/* REQUIREMENTS */}
      <Route
        path={`requirements`}
        element={
          <ProjectRequirements
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      <Route
        path={`requirements/:requirementId/*`}
        element={
          <ProjectRequirementRoutes
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      {/* QUOTES */}
      <Route
        path={`quotes`}
        element={
          <ProjectQuotes
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      <Route
        path={`quotes/:quoteId/*`}
        element={
          <ProjectQuoteRoutes
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      {/* PURCHASE ORDER */}
      <Route
        path={`materials/*`}
        element={
          <RestrictedByCapability capability="ORDERING">
            <ProjectMaterialsRoutes
              project={project}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeItem}
            />
          </RestrictedByCapability>
        }
      />
      {/* TASKS */}
      <Route path={`tasks`}>
        <Route
          index
          element={
            <RestrictedByCapability capability="EXECUTING">
              <Navigate to={isMaxPhone ? `edit#list` : `edit`} replace />
            </RestrictedByCapability>
          }
        />

        <Route
          path={`edit`}
          element={
            <RestrictedByCapability capability="EXECUTING">
              <ProjectTasks
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            </RestrictedByCapability>
          }
        >
          {isMinTablet && <Route index element={<SelectInfoMessageItem />} />}
          <Route
            path={":docId/items/:itemId"}
            element={
              <ProjectTasksItem
                projectId={projectId}
                pathToDocPage={`/projects/${projectId}/tasks/edit`}
                isInitiallyClosed={false}
              />
            }
          />
        </Route>
        <Route
          path={`reports`}
          element={
            <RestrictedByCapability capability="TIME_TRACKING">
              <ProjectTimeReports
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            </RestrictedByCapability>
          }
        />
        <Route
          path={`reports/:reportId`}
          element={
            <RestrictedByCapability capability="TIME_TRACKING">
              <ProjectTimeReportPreview
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            </RestrictedByCapability>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* INVOICES */}

      <Route path={`invoices`}>
        <Route
          index
          element={<Navigate to={invoicesTabOptions[0].value} replace />}
        />
        <Route path={`outgoing`}>
          <Route
            index
            element={
              <RestrictedByCapability capability="INVOICING">
                <ProjectOutgoingInvoices
                  projectId={projectId}
                  projectTitle={project.title}
                  prefixBreadcrumbs={breadcrumbs}
                  submenuItems={submenuItems}
                  activeSubmenuItem={activeItem}
                  tabOptions={initialInvoicesTabOptions}
                />
              </RestrictedByCapability>
            }
          />
          <Route
            path={`:invoiceId`}
            element={
              <ProjectOutgoingInvoice
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            }
          />

          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path={`incoming`}>
          <Route
            index
            element={
              <ProjectIncomingInvoices
                projectId={projectId}
                projectTitle={project.title}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
                tabOptions={initialInvoicesTabOptions}
              />
            }
          />
          <Route
            path={`:invoiceId`}
            element={
              <ProjectIncomingInvoice
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            }
          />

          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* REQUESTS */}
      <Route
        path={`requests`}
        element={
          <ProjectRequests
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      <Route
        path={`requests/:requestId`}
        element={
          <ProjectRequest
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
