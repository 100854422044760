/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated.js';
export type ChannelMessages__ChannelFragment = { __typename: 'Channel', id: string, messagesSinceLastRead: number };

export type ChannelMessages__MessageFragment = { __typename: 'Message', id: string, createdAt: any, text: string, extraAttributes: any, author?: { __typename: 'User', id: string, title: Types.Salutation, firstname: string, familyname: string } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, quote?: { __typename: 'Quote', id: string, projectId: string, title: string } | null, project?: { __typename: 'Project', id: string, title: string } | null, invoice?: { __typename: 'Invoice', id: string, title: string, file?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null } | null };

export const ChannelMessages__ChannelFragmentDoc = gql`
    fragment ChannelMessages__Channel on Channel {
  id
  messagesSinceLastRead
}
    `;
export const ChannelMessages__MessageFragmentDoc = gql`
    fragment ChannelMessages__Message on Message {
  id
  author {
    id
    title
    firstname
    familyname
  }
  createdAt
  text
  extraAttributes
  attachments {
    ...Attachment
  }
  quote {
    id
    projectId
    title
  }
  project {
    id
    title
  }
  invoice {
    id
    title
    file {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;