import { ApolloCache, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  CollapseButtonBase,
  CollapseTitle,
  ellipsisStyle,
  LoadingSpinner,
  Switch,
  useScreenWidth,
} from "@msys/ui";
import { KeyboardArrowRight as KeyboardArrowRightIcon } from "@mui/icons-material";
import { MultipleStop as MultipleStopIcon } from "@mui/icons-material";
import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { noop, omit } from "lodash-es";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { Link } from "react-router-dom";
import { useLatest } from "react-use";
import {
  addEntryToEdges,
  removeEntryFromEdges,
} from "../../../clients/apollo-client-pagination.js";
import { ProjectStateMachineStatus } from "../../../clients/graphqlTypes.js";
import { FetchMoreListener } from "../../commons/FetchMoreListener.js";
import {
  EXPANDED_COLUMN_MIN_WIDTH,
  KanbanColumn,
  KanbanColumnOverlay,
} from "../../commons/kanban/KanbanColumn.js";
import {
  KanbanContainer,
  KanbanDragItem,
} from "../../commons/kanban/KanbanContainer.js";
import { useKanbanColumnCollapseStore } from "../../commons/kanban/useKanbanColumnCollapseStore.js";
import {
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess.js";
import { ProjectListItem } from "../projects/components/ProjectListItem.js";
import { ProjectListItemFragment } from "../projects/components/ProjectListItem.generated.js";
import { ProjectPhaseAndStateChangeMenuButton } from "../projects/ProjectPhaseAndStateChangeMenuButton.js";
import { ProjectPhasesConfigurator } from "../projects/ProjectPhasesConfigurator.js";
import { ProjectPhaseFragment } from "../projects/ProjectPhasesConfigurator.generated.js";
import { useChangeProjectPhaseMutation } from "../projects/useProjectChangePhase.generated.js";
import { useProjectChangeConfirm } from "../projects/useProjectChangeState.js";
import { useSendProjectStateEventMutation } from "../projects/useProjectChangeState.generated.js";
import { ProjectStateMachineEvent } from "../projects/useProjectStates.js";
import { useVisibilityStore } from "../users/useVisibilityStore.js";
import {
  OpportunityListItem,
  OpportunityListItemDisplayConfig,
} from "./components/OpportunityListItem.js";
import {
  OpportunitiesKanbanListDocument,
  OpportunitiesKanbanListQueryVariables,
  OpportunitiesKanbanWonListDocument,
  OpportunitiesKanbanWonListQueryVariables,
  OpportunityCardItemMemoized_ProjectFragment,
  useOpportunitiesKanbanListQuery,
  useOpportunitiesKanbanQuery,
  useOpportunitiesKanbanWonListQuery,
} from "./OpportunitiesKanban.generated.js";
import { OpportunitiesListQueryVariables } from "./OpportunitiesList.generated.js";

type ColumnsVisibilityKeys = "projectsWon";

type OpportunityEdge = {
  __typename: "ProjectEdge";
  cursor?: string | null | undefined;
  node: OpportunityCardItemMemoized_ProjectFragment & ProjectListItemFragment;
};

export function OpportunitiesKanban({
  displayConfig,
  variables,
  isConfiguring,
  setIsConfiguring,
}: {
  displayConfig: OpportunityListItemDisplayConfig;
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">;
  isConfiguring: boolean;
  setIsConfiguring: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslate(["Opportunities"]);

  const { isMinTablet } = useScreenWidth();

  const [opportunitiesCount, setOpportunitiesCount] = React.useState<
    Record<string, number>
  >({});

  const onOpportunitiesCountChange = (phaseId: string, count: number) => {
    setOpportunitiesCount(state => ({ ...state, [phaseId]: count }));
  };

  const columnsVisibilityStore = useVisibilityStore<ColumnsVisibilityKeys>(
    "OpportunitiesKanban-Columns"
  );

  const client = useApolloClient();
  const query = useOpportunitiesKanbanQuery({
    client,
  });
  const projectPhases = query.data?.organisationSettings.projectPhases ?? [];
  const opportunityPhases =
    query.data?.organisationSettings.opportunityPhases ?? [];

  const { collapsedState, setPhaseCollapsed, collapseStateLoading } =
    useKanbanColumnCollapseStore({
      key: "Opportunity-KanBan-Phases-Collapsed",
      columns: opportunityPhases,
    });

  if (query.loading || collapseStateLoading) return <LoadingSpinner />;

  const projectsWonPhase = projectPhases.length > 0 ? projectPhases[0] : null;
  if (!projectsWonPhase) throw new Error(`No projects won phase found`);

  return (
    <Stack spacing={2} flexBasis={0} flexGrow={1} justifyContent={"stretch"}>
      <ProjectPhasesConfigurator
        state={"opportunity"}
        phases={opportunityPhases}
        opportunitiesCount={opportunitiesCount}
        isConfiguring={isConfiguring}
        setIsConfiguring={setIsConfiguring}
        extraItems={[
          <Switch
            label={t("Show projects", { ns: "Opportunities" })}
            checked={columnsVisibilityStore.value.projectsWon}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              columnsVisibilityStore.saveValue({
                ...columnsVisibilityStore.value,
                projectsWon: checked,
              });
            }}
          />,
        ]}
      />
      <KanbanContainer>
        {opportunityPhases.map(phase => (
          <OpportunitiesPhaseColumn
            key={phase.id}
            phase={phase}
            projectsWonPhase={projectsWonPhase}
            isCollapsed={collapsedState?.[phase.id] ?? false}
            setCollapsed={collapsed => {
              setPhaseCollapsed(phase.id, collapsed);
            }}
            availablePhases={opportunityPhases}
            displayConfig={displayConfig}
            onCountChange={(count: number) =>
              onOpportunitiesCountChange(phase.id, count)
            }
            variables={variables}
          />
        ))}
        {isConfiguring || columnsVisibilityStore.value.projectsWon ? (
          !columnsVisibilityStore.value.projectsWon ? (
            <Box
              flexBasis={isMinTablet ? EXPANDED_COLUMN_MIN_WIDTH : "100%"}
              flexShrink={0}
              flexGrow={1}
              width={0}
            />
          ) : (
            <ProjectsWonColumn variables={variables} phase={projectsWonPhase} />
          )
        ) : null}
      </KanbanContainer>
    </Stack>
  );
}

const LIMIT = 20;

function getOpportunitiesKanbanListQueryVariables(
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">,
  phaseId: string
): OpportunitiesKanbanListQueryVariables {
  return {
    ...omit(variables, ["limit", "offset", "filterPhaseIds"]),
    filterByPhaseId: phaseId,
    sorting: [
      { column: "createdAt", direction: "desc" },
      { column: "id", direction: "desc" },
    ],
    after: null,
    first: LIMIT,
  };
}

function getOpportunitiesKanbanWonListQueryVariables(
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">,
  phaseId: string
): OpportunitiesKanbanWonListQueryVariables {
  return {
    ...omit(variables, ["limit", "offset", "filterPhaseIds"]),
    filterIncludeState: ["contracted"],
    filterByPhaseId: phaseId,
    sorting: [
      { column: "createdAt", direction: "desc" },
      { column: "id", direction: "desc" },
    ],
    after: null,
    first: LIMIT,
  };
}

function OpportunitiesPhaseColumn({
  phase,
  projectsWonPhase,
  isCollapsed,
  setCollapsed,
  availablePhases,
  displayConfig,
  onCountChange,
  variables,
}: {
  phase: ProjectPhaseFragment;
  projectsWonPhase: ProjectPhaseFragment;
  isCollapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  availablePhases: ProjectPhaseFragment[];
  displayConfig: OpportunityListItemDisplayConfig;
  onCountChange(count: number): void;
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">;
}) {
  const { t } = useTranslate(["Global"]);

  const client = useApolloClient();
  const queryVariables = React.useMemo(
    () => getOpportunitiesKanbanListQueryVariables(variables, phase.id),
    [variables, phase.id]
  );

  const query = useOpportunitiesKanbanListQuery({
    client,
    variables: queryVariables,
    fetchPolicy: "network-only",
  });

  const opportunitiesEdges = getDataOrNull(query.data?.projects)?.edges;
  const totalCount = getDataOrNull(query.data?.projects)?.totalCount;

  const onCountChangeLatest = useLatest(onCountChange);
  React.useEffect(() => {
    if (totalCount !== undefined) onCountChangeLatest.current?.(totalCount);
  }, [totalCount, onCountChangeLatest]);

  const canFetchMore = Boolean(
    opportunitiesEdges && totalCount && opportunitiesEdges.length < totalCount
  );

  const fetchMore = async () => {
    const after =
      getDataOrNull(query.data?.projects)?.pageInfo?.endCursor ?? null;
    if (!after) return;
    await query.fetchMore({
      variables: {
        after,
        first: LIMIT,
      },
    });
  };

  const [changePhase, { loading: changePhaseLoading }] =
    useChangeProjectPhaseMutation({
      client,
    });

  const [{ isOver, isDragging }, drop] = useDrop<
    KanbanDragItem<OpportunityEdge>,
    Promise<void>,
    { isOver: boolean; isDragging: boolean }
  >({
    accept: "kanban-card",
    drop: async (item, monitor) => {
      if (phase.id !== item.phaseId) {
        await changePhase({
          variables: {
            input: { projectId: item.projectId, newPhaseId: phase.id },
          },
          update: (cache, result) => {
            changeProjectPhaseCacheUpdate(
              item.phaseId,
              phase.id,
              cache,
              variables,
              item.edge
            );
          },
        });
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      isDragging: monitor.canDrop(),
    }),
  });

  return (
    <KanbanColumn
      ref={drop}
      color={phase.color}
      title={<CollapseTitle title={phase.name} count={totalCount} />}
      collapsedTitle={`${phase.name}${
        totalCount !== undefined ? ` (${totalCount})` : ""
      }`}
      isCollapsed={isCollapsed}
      setCollapsed={setCollapsed}
      overlay={
        (isDragging && isOver) || changePhaseLoading ? (
          <KanbanColumnOverlay
            loading={changePhaseLoading}
            selected={isDragging && isOver}
          />
        ) : undefined
      }
    >
      {query.loading && !opportunitiesEdges ? (
        <LoadingSpinner />
      ) : (
        <Stack spacing={1} padding={1}>
          {opportunitiesEdges?.map(opportunityEdge => (
            <OpportunityCardItemMemoized
              key={opportunityEdge.node.id}
              opportunityEdge={opportunityEdge}
              phaseId={phase.id}
              projectsWonPhaseId={projectsWonPhase.id}
              availablePhases={availablePhases}
              variables={variables}
              displayConfig={displayConfig}
            />
          ))}
          {!opportunitiesEdges?.length && (
            <Box
              minHeight={80}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography align="center" color="grey.500" variant="body2">
                {t("There are no items to display", { ns: "Global" })}
              </Typography>
            </Box>
          )}
          <FetchMoreListener enabled={canFetchMore} fetchMore={fetchMore} />
        </Stack>
      )}
    </KanbanColumn>
  );
}

const OpportunityCardItemMemoized = React.memo(function OpportunityCardItem({
  phaseId,
  projectsWonPhaseId,
  availablePhases,
  opportunityEdge,
  variables,
  displayConfig,
}: {
  phaseId: string;
  projectsWonPhaseId: string;
  availablePhases: ProjectPhaseFragment[];
  opportunityEdge: OpportunityEdge;
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">;
  displayConfig: OpportunityListItemDisplayConfig;
}) {
  const { t } = useTranslate(["Projects"]);

  const [, drag] = useDrag<
    KanbanDragItem<OpportunityEdge>,
    void,
    { isDragging: boolean }
  >({
    item: {
      type: "kanban-card",
      phaseId,
      projectId: opportunityEdge.node.id,
      state: opportunityEdge.node.state,
      edge: opportunityEdge,
    },
  });

  return (
    <CardItem
      ref={drag}
      key={opportunityEdge.node.id}
      // @ts-ignore
      component={Link}
      to={`/projects/${opportunityEdge.node.id}`}
      hoverable
    >
      <OpportunityListItem
        project={opportunityEdge.node}
        showStatus={false}
        showAssignee
        Action={
          <ProjectPhaseAndStateChangeMenuButton
            projectId={opportunityEdge.node.id}
            projectPhaseId={phaseId}
            projectState={opportunityEdge.node.state}
            availablePhases={availablePhases.filter(p => p.id !== phaseId)}
            Icon={<MultipleStopIcon fontSize="small" />}
            buttonProps={{
              size: "small",
              sx: { padding: "1px" },
            }}
            // onStateChangeRefetchQueries={[
            //   namedOperations.Query.OpportunitiesList,
            // ]}
            phaseGroupLabel={t("Move opportunity to another phase", {
              ns: "Projects",
            })}
            changeProjectPhaseUpdate={(oldPhaseId, newPhaseId, cache) => {
              changeProjectPhaseCacheUpdate(
                oldPhaseId,
                newPhaseId,
                cache,
                variables,
                opportunityEdge
              );
            }}
            stateGroupLabel={t("Mark opportunity as won or lost", {
              ns: "Projects",
            })}
            changeProjectStateUpdate={(oldState, newStateEvent, cache) => {
              changeProjectStateCacheUpdate(
                oldState,
                newStateEvent,
                phaseId,
                projectsWonPhaseId,
                cache,
                variables,
                opportunityEdge
              );
            }}
          />
        }
        displayConfig={displayConfig}
      />
    </CardItem>
  );
});

function ProjectsWonColumn({
  variables,
  phase,
}: {
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">;
  phase: ProjectPhaseFragment;
}) {
  const { t } = useTranslate(["Global"]);

  const queryVariables = React.useMemo(
    () => getOpportunitiesKanbanWonListQueryVariables(variables, phase.id),
    [phase.id, variables]
  );

  const client = useApolloClient();
  const query = useOpportunitiesKanbanWonListQuery({
    client,
    variables: queryVariables,
    fetchPolicy: "network-only",
  });

  const projects = getDataOrNull(query.data?.projects)?.edges.map(e => e.node);
  const totalCount = getDataOrNull(query.data?.projects)?.totalCount;

  const canFetchMore = Boolean(
    projects && totalCount && projects.length < totalCount
  );

  const fetchMore = async () => {
    const after =
      getDataOrNull(query.data?.projects)?.pageInfo?.endCursor ?? null;
    if (!after) return;
    await query.fetchMore({
      variables: {
        after,
        first: LIMIT,
      },
    });
  };

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const { handleProjectChangeStateConfirm } = useProjectChangeConfirm({
    confirmProcessRef,
  });

  const [sendProjectStateEvent, { loading: changeStateLoading }] =
    useSendProjectStateEventMutation({ client });

  const [{ isOver, isDragging }, drop] = useDrop<
    KanbanDragItem<OpportunityEdge>,
    Promise<void>,
    { isOver: boolean; isDragging: boolean }
  >({
    accept: "kanban-card",
    drop: async (item, monitor) => {
      if (item.state !== "contracted") {
        const stateEvent: ProjectStateMachineEvent = "WIN";
        const isConfirmed = await handleProjectChangeStateConfirm(
          item.projectId,
          stateEvent
        );
        if (!isConfirmed) return;

        await sendProjectStateEvent({
          variables: {
            input: { projectId: item.projectId, event: { [stateEvent]: {} } },
          },
          update: (cache, result) => {
            changeProjectStateCacheUpdate(
              item.state,
              stateEvent,
              item.phaseId,
              phase.id,
              cache,
              variables,
              item.edge
            );
          },
        });
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      isDragging: monitor.canDrop(),
    }),
  });

  return (
    <KanbanColumn
      ref={drop}
      title={
        <CollapseButtonBase
          title={<CollapseTitle title={phase.name} count={totalCount} />}
          endIcon={<KeyboardArrowRightIcon />}
          // @ts-ignore
          component={props => (
            <ButtonBase
              {...props}
              component={Link}
              to={`/projects/current`}
              style={ellipsisStyle}
            />
          )}
        />
      }
      color={phase.color}
      collapsedTitle={`${phase.name} ${
        totalCount !== undefined ? ` (${totalCount})` : ""
      }`}
      isCollapsed={false}
      setCollapsed={noop}
      showCollapsedButton={false}
      overlay={
        (isDragging && isOver) || changeStateLoading ? (
          <KanbanColumnOverlay
            loading={changeStateLoading}
            selected={isDragging && isOver}
          />
        ) : undefined
      }
    >
      {query.loading && !projects ? (
        <LoadingSpinner />
      ) : (
        <Stack spacing={1} padding={1}>
          {projects?.map(project => (
            <ProjectCardItemMemoized key={project.id} project={project} />
          ))}
          {!projects?.length && (
            <Box
              minHeight={80}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography align="center" color="grey.500" variant="body2">
                {t("There are no items to display", { ns: "Global" })}
              </Typography>
            </Box>
          )}
          <FetchMoreListener enabled={canFetchMore} fetchMore={fetchMore} />
        </Stack>
      )}
      <ConfirmProcess ref={confirmProcessRef} />
    </KanbanColumn>
  );
}

const ProjectCardItemMemoized = React.memo(
  ({ project }: { project: ProjectListItemFragment }) => (
    <CardItem
      key={project.id}
      // @ts-ignore
      component={Link}
      to={`/projects/${project.id}`}
    >
      <ProjectListItem project={project} showStatus={false} showAssignee />
    </CardItem>
  )
);

function changeProjectPhaseCacheUpdate(
  oldPhaseId: string,
  newPhaseId: string,
  cache: ApolloCache<any>,
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">,
  opportunityEdge: OpportunityEdge
) {
  const fromVariables = getOpportunitiesKanbanListQueryVariables(
    variables,
    oldPhaseId
  );
  const toVariables = getOpportunitiesKanbanListQueryVariables(
    variables,
    newPhaseId
  );
  cache.updateQuery(
    {
      query: OpportunitiesKanbanListDocument,
      variables: fromVariables,
    },
    data => {
      if (data && data.projects)
        return {
          ...data,
          projects: removeEntryFromEdges(data.projects, opportunityEdge),
        };
    }
  );
  cache.updateQuery(
    {
      query: OpportunitiesKanbanListDocument,
      variables: toVariables,
    },
    data => {
      if (data && data.projects)
        return {
          ...data,
          projects: addEntryToEdges(data.projects, opportunityEdge),
        };
    }
  );
}

function changeProjectStateCacheUpdate(
  oldState: ProjectStateMachineStatus,
  newStateEvent: ProjectStateMachineEvent,
  oldPhaseId: string,
  newPhaseId: string,
  cache: ApolloCache<any>,
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">,
  opportunityEdge: OpportunityEdge
) {
  if (oldState === "opportunity") {
    const fromVariables = getOpportunitiesKanbanListQueryVariables(
      variables,
      oldPhaseId
    );
    cache.updateQuery(
      {
        query: OpportunitiesKanbanListDocument,
        variables: fromVariables,
      },
      data => {
        if (data && data.projects)
          return {
            ...data,
            projects: removeEntryFromEdges(data.projects, opportunityEdge),
          };
      }
    );
  }
  if (newStateEvent === "WIN") {
    const toVariables = getOpportunitiesKanbanWonListQueryVariables(
      variables,
      newPhaseId
    );
    cache.updateQuery(
      {
        query: OpportunitiesKanbanWonListDocument,
        variables: toVariables,
      },
      data => {
        if (data && data.projects)
          return {
            ...data,
            projects: addEntryToEdges(data.projects, opportunityEdge),
          };
      }
    );
  }
}
