/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectWorkSessionsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  assigneeId: Types.Scalars['ID']['input'];
  date?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;


export type ProjectWorkSessionsQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, workSessions: Array<{ __typename: 'WorkSession', id: string, from?: any | null, till?: any | null, date?: any | null, duration?: number | null, isBreak: boolean, isCorrection: boolean, itemId?: string | null }> } | null } };

export type ProjectOverviewWorkSessionsBox_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName> };

export type WorkSession__EntityFragment = { __typename: 'WorkSession', id: string, from?: any | null, till?: any | null, date?: any | null, duration?: number | null, isBreak: boolean, isCorrection: boolean, itemId?: string | null };

export type StartProjectWorkSessionMutationVariables = Types.Exact<{
  input: Types.StartProjectWorkSessionInput;
  assigneeId: Types.Scalars['ID']['input'];
  date?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;


export type StartProjectWorkSessionMutation = { startProjectWorkSession: { __typename: 'StartProjectWorkSessionResult', project: { __typename: 'Project', id: string, workSessions: Array<{ __typename: 'WorkSession', id: string, from?: any | null, till?: any | null, date?: any | null, duration?: number | null, isBreak: boolean, isCorrection: boolean, itemId?: string | null }> } } };

export type FinishProjectWorkSessionMutationVariables = Types.Exact<{
  input: Types.FinishProjectWorkSessionInput;
  assigneeId: Types.Scalars['ID']['input'];
  date?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;


export type FinishProjectWorkSessionMutation = { finishProjectWorkSession: { __typename: 'FinishProjectWorkSessionResult', project: { __typename: 'Project', id: string, workSessions: Array<{ __typename: 'WorkSession', id: string, from?: any | null, till?: any | null, date?: any | null, duration?: number | null, isBreak: boolean, isCorrection: boolean, itemId?: string | null }> } } };

export const ProjectOverviewWorkSessionsBox_ProjectFragmentDoc = gql`
    fragment ProjectOverviewWorkSessionsBox_Project on Project {
  id
  viewerPermissions
}
    `;
export const WorkSession__EntityFragmentDoc = gql`
    fragment WorkSession__Entity on WorkSession {
  id
  from
  till
  date
  duration
  isBreak
  isCorrection
  itemId
}
    `;
export const ProjectWorkSessionsDocument = gql`
    query ProjectWorkSessions($projectId: ID!, $assigneeId: ID!, $date: Date) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        workSessions(assigneeId: $assigneeId, date: $date) {
          id
          ...WorkSession__Entity
        }
      }
    }
  }
}
    ${WorkSession__EntityFragmentDoc}`;

/**
 * __useProjectWorkSessionsQuery__
 *
 * To run a query within a React component, call `useProjectWorkSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWorkSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWorkSessionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      assigneeId: // value for 'assigneeId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useProjectWorkSessionsQuery(baseOptions: Apollo.QueryHookOptions<ProjectWorkSessionsQuery, ProjectWorkSessionsQueryVariables> & ({ variables: ProjectWorkSessionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectWorkSessionsQuery, ProjectWorkSessionsQueryVariables>(ProjectWorkSessionsDocument, options);
      }
export function useProjectWorkSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectWorkSessionsQuery, ProjectWorkSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectWorkSessionsQuery, ProjectWorkSessionsQueryVariables>(ProjectWorkSessionsDocument, options);
        }
export function useProjectWorkSessionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectWorkSessionsQuery, ProjectWorkSessionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectWorkSessionsQuery, ProjectWorkSessionsQueryVariables>(ProjectWorkSessionsDocument, options);
        }
export type ProjectWorkSessionsQueryHookResult = ReturnType<typeof useProjectWorkSessionsQuery>;
export type ProjectWorkSessionsLazyQueryHookResult = ReturnType<typeof useProjectWorkSessionsLazyQuery>;
export type ProjectWorkSessionsSuspenseQueryHookResult = ReturnType<typeof useProjectWorkSessionsSuspenseQuery>;
export type ProjectWorkSessionsQueryResult = Apollo.QueryResult<ProjectWorkSessionsQuery, ProjectWorkSessionsQueryVariables>;
export const StartProjectWorkSessionDocument = gql`
    mutation StartProjectWorkSession($input: StartProjectWorkSessionInput!, $assigneeId: ID!, $date: Date) {
  startProjectWorkSession(input: $input) {
    project {
      id
      workSessions(assigneeId: $assigneeId, date: $date) {
        id
        ...WorkSession__Entity
      }
    }
  }
}
    ${WorkSession__EntityFragmentDoc}`;
export type StartProjectWorkSessionMutationFn = Apollo.MutationFunction<StartProjectWorkSessionMutation, StartProjectWorkSessionMutationVariables>;

/**
 * __useStartProjectWorkSessionMutation__
 *
 * To run a mutation, you first call `useStartProjectWorkSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartProjectWorkSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startProjectWorkSessionMutation, { data, loading, error }] = useStartProjectWorkSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      assigneeId: // value for 'assigneeId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useStartProjectWorkSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartProjectWorkSessionMutation, StartProjectWorkSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartProjectWorkSessionMutation, StartProjectWorkSessionMutationVariables>(StartProjectWorkSessionDocument, options);
      }
export type StartProjectWorkSessionMutationHookResult = ReturnType<typeof useStartProjectWorkSessionMutation>;
export type StartProjectWorkSessionMutationResult = Apollo.MutationResult<StartProjectWorkSessionMutation>;
export type StartProjectWorkSessionMutationOptions = Apollo.BaseMutationOptions<StartProjectWorkSessionMutation, StartProjectWorkSessionMutationVariables>;
export const FinishProjectWorkSessionDocument = gql`
    mutation FinishProjectWorkSession($input: FinishProjectWorkSessionInput!, $assigneeId: ID!, $date: Date) {
  finishProjectWorkSession(input: $input) {
    project {
      id
      workSessions(assigneeId: $assigneeId, date: $date) {
        id
        ...WorkSession__Entity
      }
    }
  }
}
    ${WorkSession__EntityFragmentDoc}`;
export type FinishProjectWorkSessionMutationFn = Apollo.MutationFunction<FinishProjectWorkSessionMutation, FinishProjectWorkSessionMutationVariables>;

/**
 * __useFinishProjectWorkSessionMutation__
 *
 * To run a mutation, you first call `useFinishProjectWorkSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishProjectWorkSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishProjectWorkSessionMutation, { data, loading, error }] = useFinishProjectWorkSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      assigneeId: // value for 'assigneeId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useFinishProjectWorkSessionMutation(baseOptions?: Apollo.MutationHookOptions<FinishProjectWorkSessionMutation, FinishProjectWorkSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishProjectWorkSessionMutation, FinishProjectWorkSessionMutationVariables>(FinishProjectWorkSessionDocument, options);
      }
export type FinishProjectWorkSessionMutationHookResult = ReturnType<typeof useFinishProjectWorkSessionMutation>;
export type FinishProjectWorkSessionMutationResult = Apollo.MutationResult<FinishProjectWorkSessionMutation>;
export type FinishProjectWorkSessionMutationOptions = Apollo.BaseMutationOptions<FinishProjectWorkSessionMutation, FinishProjectWorkSessionMutationVariables>;