import { uniqueId } from "lodash-es";
import React from "react";
import { useUnmount } from "react-use";
import { GlobalLoadingStateContext } from "./GlobalLoadingStateContext.js";

export const useGlobalLoadingCounter = () => {
  const id = React.useMemo<string>(() => uniqueId(), []);
  const { setCounters } = React.useContext(GlobalLoadingStateContext);

  const increment = React.useCallback(() => {
    setCounters(state => ({ ...state, [id]: (state[id] ?? 0) + 1 }));
  }, [id, setCounters]);
  const decrement = React.useCallback(() => {
    setCounters(state => ({ ...state, [id]: (state[id] ?? 0) - 1 }));
  }, [id, setCounters]);

  useUnmount(() => {
    setCounters(state => {
      if (id in state) {
        const { [id]: amount, ...newState } = state;
        return newState;
      }
      return state;
    });
  });

  return { increment, decrement };
};
