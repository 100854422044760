import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  MenuButton,
  MenuItemWithIcon,
  ModalOpenButton,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, IconButton, Paper, Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ProfileListItem } from "../../commons/ProfileListItem.js";
import {
  useRelatedToBox_CrmCompanyLazyQuery,
  useRelatedToBox_CrmPersonLazyQuery,
  useRelatedToBox_ProjectLazyQuery,
} from "./RelatedToBox.generated.js";
import { useTranslate } from "@tolgee/react";
import { OrganisationAvatar } from "../organisations/OrganisationAvatar.js";
import { ProjectListItem } from "../projects/components/ProjectListItem.js";
import { UserAvatar } from "../users/UserAvatar.js";
import { CompanyAndProjectSelectModal } from "./CompanyAndProjectSelectModal.js";

interface Props {
  linkedProjectId: string | null;
  linkedCrmCompanyId: string | null;
  linkedCrmPersonId: string | null; // deprecated
  handleDelete: () => void;
  handleChange: (crmCompanyId: string | null, projectId: string | null) => void;
}

export const RelatedToBox = ({
  linkedProjectId,
  linkedCrmCompanyId,
  linkedCrmPersonId,
  handleDelete,
  handleChange,
}: Props) => {
  const { t } = useTranslate(["Tasks", "Global"]);

  const client = useApolloClient();
  const [fetchProject, projectQuery] = useRelatedToBox_ProjectLazyQuery({
    client,
  });
  React.useEffect(() => {
    if (linkedProjectId) {
      fetchProject({ variables: { projectId: linkedProjectId } });
    }
  }, [fetchProject, linkedProjectId]);
  const [fetchCrmCompany, crmCompanyQuery] =
    useRelatedToBox_CrmCompanyLazyQuery({
      client,
    });
  React.useEffect(() => {
    if (linkedCrmCompanyId) {
      fetchCrmCompany({ variables: { crmCompanyId: linkedCrmCompanyId } });
    }
  }, [fetchCrmCompany, linkedCrmCompanyId]);
  const [fetchCrmPerson, crmPersonQuery] = useRelatedToBox_CrmPersonLazyQuery({
    client,
  });
  React.useEffect(() => {
    if (linkedCrmPersonId) {
      fetchCrmPerson({ variables: { crmPersonId: linkedCrmPersonId } });
    }
  }, [fetchCrmPerson, linkedCrmPersonId]);

  const linkedProject = linkedProjectId
    ? (getDataOrNull(projectQuery?.data?.project)?.project ?? null)
    : null;
  const linkedCrmCompany = linkedCrmCompanyId
    ? (getDataOrNull(crmCompanyQuery.data?.crmCompany)?.crmCompany ?? null)
    : null;
  const linkedCrmPerson = linkedCrmPersonId
    ? (getDataOrNull(crmPersonQuery.data?.crmPerson)?.crmPerson ?? null)
    : null;

  const hasLinks = linkedProject || linkedCrmCompany || linkedCrmPerson;

  return (
    <CardContainer
      isExpandable
      title={t("Related to", {
        ns: "Tasks",
      })}
      ActionButton={
        linkedProject || linkedCrmCompany || linkedCrmPerson ? (
          <MenuButton>
            <MenuItemWithIcon icon={<DeleteIcon />} onClick={handleDelete}>
              {t("Delete", {
                ns: "Global",
              })}
            </MenuItemWithIcon>
          </MenuButton>
        ) : (
          <ModalOpenButton
            Modal={CompanyAndProjectSelectModal}
            modalProps={{
              handleComplete: handleChange,
              disableBlockedBusinessPartners: false,
            }}
          >
            <IconButton color={"primary"}>
              <AddIcon />
            </IconButton>
          </ModalOpenButton>
        )
      }
    >
      {hasLinks && (
        <Stack padding={1} spacing={1}>
          {linkedCrmPerson && (
            <Box
              component={Link}
              to={`/crm/users/${linkedCrmPerson.id}`}
              sx={{
                textDecoration: "inherit",
                color: "inherit",
                display: "block",
              }}
            >
              <ProfileListItem
                primary={`${linkedCrmPerson.firstname} ${linkedCrmPerson.familyname}`}
                avatar={<UserAvatar userAvatar={linkedCrmPerson} />}
                button={false}
              />
            </Box>
          )}
          {linkedCrmCompany && (
            <Box
              component={Link}
              to={`/crm/organisations/${linkedCrmCompany.id}`}
              sx={{
                textDecoration: "inherit",
                color: "inherit",
                display: "block",
              }}
            >
              <ProfileListItem
                primary={linkedCrmCompany.title}
                avatar={
                  <OrganisationAvatar
                    size="m"
                    organisationAvatar={linkedCrmCompany}
                  />
                }
                button={false}
              />
            </Box>
          )}
          {linkedProject && (
            <Box
              component={Link}
              to={`/projects/${linkedProject.id}`}
              sx={{
                textDecoration: "inherit",
                color: "inherit",
                display: "block",
              }}
            >
              <ProjectListItem project={linkedProject} />
            </Box>
          )}
        </Stack>
      )}
    </CardContainer>
  );
};
