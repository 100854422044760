import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CircularProgress, IconButton } from "@mui/material";
import { GetApp as DownloadIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useOpenFile } from "../../../commons/hooks/useOpenFile.js";
import React from "react";
import {
  GenerateRequirementPdfDocument,
  GenerateRequirementPdfQuery,
  GenerateRequirementPdfQueryVariables,
} from "./DownloadRequirementAsPdfIconButton.generated.js";

interface Props {
  projectId: string;
  requirementId: string;
}

export const DownloadRequirementAsPdfButton = ({
  projectId,
  requirementId,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const { openPdf } = useOpenFile();

  const client = useApolloClient();

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (loadingPdf) return;

    setLoadingPdf(true);

    try {
      const { data } = await client.query<
        GenerateRequirementPdfQuery,
        GenerateRequirementPdfQueryVariables
      >({
        query: GenerateRequirementPdfDocument,
        variables: {
          projectId,
          requirementId,
        },
      });
      const url = getDataOrNull(data?.requirementGeneratePdf)?.url;
      if (!url) throw new Error("Failed to generate PDF");
      openPdf(url, `Requirement_${requirementId}`);
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingPdf(false);
    }
  };

  return (
    <IconButton
      aria-haspopup="true"
      style={{ flexShrink: 0, flexGrow: 0 }}
      color="primary"
      size="small"
      onClick={handleClick}
    >
      {loadingPdf ? (
        <CircularProgress size="1.5rem" color={"primary"} />
      ) : (
        <DownloadIcon />
      )}
    </IconButton>
  );
};
