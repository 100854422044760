import { ModalOpenButton } from "@msys/ui";
import WizardIcon from "@mui/icons-material/AutoFixHigh";
import BallotIcon from "@mui/icons-material/Ballot";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import React from "react";
import { AskWhom } from "../../../clients/graphqlTypes.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { useDecisionProcess } from "../doc-items/hooks/useDecisionProcess.js";
import { DecisionWizardModalWithDefaultActions } from "../doc-items/modals/DecisionWizardModal.js";
import {
  QuoteAction_ProjectFragment,
  QuoteAction_QuoteFragment,
} from "./QuoteAction.generated";
import { AcceptChangesButton } from "./buttons/AcceptChangesButton.js";
import { AcceptQuoteButton } from "./buttons/AcceptQuoteButton.js";
import { DeclineChangesButton } from "./buttons/DeclineChangesButton.js";
import { DeclineQuoteButton } from "./buttons/DeclineQuoteButton.js";
import { DiscardPendingChangesButton } from "./buttons/DiscardPendingChangesButton.js";
import { FinalizeDecisionsInBulkButton } from "./buttons/FinalizeDecisionsInBulkButton.js";
import { MarkQuoteAsAcceptedButton } from "./buttons/MarkQuoteAsAcceptedButton.js";
import { PublishQuoteButton } from "./buttons/PublishQuoteButton.js";
import { ShopButton } from "./buttons/ShopButton.js";
import { UnpublishQuoteButton } from "./buttons/UnpublishQuoteButton.js";
import { QuoteRefinementWizardModal } from "./modals/QuoteRefinementWizardModal.js";
import { SendQuoteViaEmailButton } from "./modals/SendQuoteViaEmailButton.js";
import { useProjectQuoteContext } from "../../main-routes/projects/ProjectQuoteContext.js";
import { useUserData } from "../../auth/useUserData.js";

interface Props {
  doc: QuoteAction_QuoteFragment;
  project: QuoteAction_ProjectFragment;
  onDiscardPendingChangesRefetchQueries: string[];
  onDeclineChangesRefetchQueries: string[];
  startDecisionsInBulkProcess?: () => void;
  refetch?: () => Promise<unknown>;
  expandedItemIds: string[] | undefined;
  viewerDecisionRole: AskWhom | undefined;
}

export const QuoteAction = ({
  doc,
  project,
  onDiscardPendingChangesRefetchQueries,
  onDeclineChangesRefetchQueries,
  startDecisionsInBulkProcess,
  refetch,
  expandedItemIds,
  viewerDecisionRole,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const isShopEnabled = useFeature("Shop");
  const isNewRefinementWizardEnabled =
    viewer.organisation.capabilities.includes("NEW_REFINEMENT_WIZARD");
  const { t } = useTranslate(["Quote"]);

  const { refetchWizardQuestions } = useProjectQuoteContext();

  const hasPendingChanges = doc.agreement === "YES" && doc.needsAgreement; // Is this correct??? copied from "decline changes" permission app condition
  const isExpired =
    doc.expirationDate &&
    moment().startOf("day").isAfter(moment(doc.expirationDate));

  const actionButtons: React.ReactNode[] = [
    <RestrictedByCapabilityWithDebug
      capability="QUOTING"
      key={"send project quote to client"}
    >
      <RestrictedByDocumentPermissionWithDebug
        permission="SHARE_QUOTES"
        document={doc}
      >
        {doc.isPublished && (
          <SendQuoteViaEmailButton
            quoteId={doc.id}
            quote={doc}
            projectId={project.id}
            project={project}
            calculationType={"proposed"}
          />
        )}
      </RestrictedByDocumentPermissionWithDebug>
    </RestrictedByCapabilityWithDebug>,
    <RestrictedByCapabilityWithDebug
      capability="QUOTING"
      key={"mark quote as accepted"}
    >
      <RestrictedByDocumentPermissionWithDebug
        permission="DECIDE_QUOTES"
        document={doc}
      >
        {!doc.contractee.isMyOrganisation &&
          !doc.incomingRequest &&
          doc.agreement === "NONE" &&
          doc.isPublished &&
          doc.isBinding &&
          !isExpired && (
            <MarkQuoteAsAcceptedButton docId={doc.id} projectId={project.id} />
          )}
      </RestrictedByDocumentPermissionWithDebug>
    </RestrictedByCapabilityWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="DECIDE_QUOTES"
      document={doc}
      key={"accept quote"}
    >
      {doc.contractee.isMyOrganisation &&
        (!doc.incomingRequestRecipientStatus ||
          doc.incomingRequestRecipientStatus === "YES" ||
          doc.incomingRequestRecipientStatus === "MAYBE") &&
        doc.agreement === "NONE" &&
        doc.isPublished &&
        doc.isBinding &&
        !isExpired && (
          <AcceptQuoteButton
            docId={doc.id}
            projectId={project.id}
            handleComplete={refetch}
          />
        )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="DECIDE_QUOTES"
      document={doc}
      key={"decline quote"}
    >
      {(doc.contractee.isMyOrganisation || !doc.incomingRequest) &&
        doc.isPublished &&
        doc.agreement === "NONE" &&
        doc.isBinding &&
        (!doc.expirationDate ||
          moment().startOf("day").isBefore(moment(doc.expirationDate))) && (
          <DeclineQuoteButton
            docId={doc.id}
            projectId={project.id}
            handleComplete={refetch}
          />
        )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="DECIDE_QUOTES"
      document={doc}
      key={"accept changes"}
    >
      {(doc.contractee.isMyOrganisation || !doc.incomingRequest) &&
        doc.agreement === "YES" &&
        doc.needsAgreement && (
          <AcceptChangesButton
            docId={doc.id}
            projectId={project.id}
            defaultPlace={
              project.building?.buildingAddress?.city ??
              doc.contractee?.address?.city ??
              ""
            }
            handleComplete={refetch}
          />
        )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="DECIDE_QUOTES"
      document={doc}
      key={"decline changes"}
    >
      {(doc.contractee.isMyOrganisation || !doc.incomingRequest) &&
        doc.agreement === "YES" &&
        doc.needsAgreement && (
          <DeclineChangesButton
            docId={doc.id}
            projectId={project.id}
            refetchQueries={onDeclineChangesRefetchQueries}
            handleComplete={refetch}
          />
        )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="MANAGE_QUOTES"
      document={doc}
      key={"discard pending changes"}
    >
      {hasPendingChanges && (
        <DiscardPendingChangesButton
          projectId={project.id}
          quoteId={doc.id}
          itemId={doc.rootItem.id}
          refetchQueries={onDiscardPendingChangesRefetchQueries}
          handleComplete={refetch}
        />
      )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByCapabilityWithDebug capability="QUOTING" key={"publish quote"}>
      <RestrictedByDocumentPermissionWithDebug
        permission="MANAGE_QUOTES"
        document={doc}
      >
        {!doc.isPublished &&
          doc.agreement !== "YES" &&
          (!doc.incomingRequest ||
            doc.incomingRequest.status === "PUBLISHED") && (
            <PublishQuoteButton
              doc={doc}
              project={project}
              docId={doc.id}
              projectId={project.id}
              handleComplete={refetch}
              calculationType={"proposed"}
            />
          )}
      </RestrictedByDocumentPermissionWithDebug>
    </RestrictedByCapabilityWithDebug>,
    <RestrictedByCapabilityWithDebug
      capability="QUOTING"
      key={"unpublish quote"}
    >
      <RestrictedByDocumentPermissionWithDebug
        permission="MANAGE_QUOTES"
        document={doc}
      >
        {doc.isPublished &&
          doc.agreement !== "YES" &&
          (!doc.incomingRequest ||
            doc.incomingRequest.status === "PUBLISHED") && (
            <UnpublishQuoteButton
              docId={doc.id}
              projectId={project.id}
              handleComplete={refetch}
            />
          )}
      </RestrictedByDocumentPermissionWithDebug>
    </RestrictedByCapabilityWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="TAKE_QUOTES_DECISION"
      document={doc}
      key={"decision-wizard"}
    >
      {isNewRefinementWizardEnabled ? (
        <ModalOpenButton
          Modal={QuoteRefinementWizardModal}
          modalProps={{
            projectId: project.id,
            quoteId: doc.id,
            startingNode: { type: "root" },
            refetchAfterApplyItemAction: refetchWizardQuestions,
          }}
        >
          <Button
            startIcon={<WizardIcon />}
            variant={"contained"}
            color={"secondary"}
          >
            {t("Open wizard", { ns: "Quote" })}
          </Button>
        </ModalOpenButton>
      ) : (
        <DecisionWizardButton
          projectId={project.id}
          quoteId={doc.id}
          viewerDecisionRole={viewerDecisionRole}
          expandedItemIds={expandedItemIds}
        />
      )}
    </RestrictedByDocumentPermissionWithDebug>,
    startDecisionsInBulkProcess && (
      <RestrictedByDocumentPermissionWithDebug
        permission="TAKE_QUOTES_DECISION"
        document={doc}
        key={"finalize-decisions"}
      >
        <FinalizeDecisionsInBulkButton onClick={startDecisionsInBulkProcess} />
      </RestrictedByDocumentPermissionWithDebug>
    ),
    isShopEnabled && (
      <RestrictedByCapabilityWithDebug capability="SHOP" key={"shop"}>
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES"
          document={doc}
        >
          <ShopButton docId={doc.id} />
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    ),
  ].filter(Boolean);

  return actionButtons;
};

const DecisionWizardButton = ({
  projectId,
  quoteId,
  viewerDecisionRole,
  expandedItemIds,
}: {
  projectId: string;
  quoteId: string;
  viewerDecisionRole: AskWhom | undefined;
  expandedItemIds: string[] | undefined;
}) => {
  const { t } = useTranslate(["Quote"]);

  const decisionProcess = useDecisionProcess({
    projectId,
    docId: quoteId,
    embeddedMode: false,
    itemUuidSeed: "",
    viewerDecisionRole,
    decisionContext: "onQuoteRefinement",
  });

  return (
    <ModalOpenButton
      Modal={DecisionWizardModalWithDefaultActions}
      modalProps={{
        processState: decisionProcess.state,
        projectId: projectId,
        docType: "QUOTE",
        docId: quoteId,
        viewerDecisionRole: viewerDecisionRole,
        expandedItemIds: expandedItemIds,
        handleNextItem: decisionProcess.next,
        handlePreviousItem: decisionProcess.previous,
        decisionContext: decisionProcess.decisionContext,
      }}
      onCloseCallback={decisionProcess.close}
      onOpenCallback={decisionProcess.start}
    >
      <Button
        startIcon={<BallotIcon />}
        variant={"contained"}
        color={"secondary"}
      >
        {t("Open wizard", { ns: "Quote" })}
      </Button>
    </ModalOpenButton>
  );
};
