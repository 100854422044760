import { ModalOpenButton, RichTextEditor } from "@msys/ui";
import { Help as HelpIcon } from "@mui/icons-material";
import { Box, IconButton, Paper, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ContentState, convertToRaw } from "draft-js";
import { InvoiceTextsAvailableReplacementsModal } from "./InvoiceTextsAvailableReplacementsModal.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import {
  InvoiceDraftPreview_InvoiceFragment,
  InvoiceDraftPreview_ProjectFragment,
  InvoiceDraftPreview_QuoteFragment,
} from "./InvoiceDraftPreview.generated.js";
import { RestrictedWithDebug } from "../../auth/Restricted.js";
import React from "react";

interface Props {
  content?: string | undefined | null;
  onSave?: (variables: any) => Promise<void>;
  canEdit?: boolean;
  showInfo: boolean;
  renderEditIconButton?: (button: React.ReactNode) => React.ReactNode;
}

export const OpeningTextInput = ({
  content,
  onSave = async () => {},
  canEdit = true,
  showInfo,
  renderEditIconButton,
}: Props) => {
  const { t } = useTranslate([
    "InvoiceDetailsForm",
    "Global",
    "OrganisationSettings",
  ]);

  return (
    <Paper>
      <Box padding={2}>
        <RichTextEditor
          label={t("Opening text", {
            ns: "InvoiceDetailsForm",
          })}
          content={
            content
              ? JSON.parse(content)
              : convertToRaw(ContentState.createFromText(""))
          }
          onSave={content => onSave({ opening: JSON.stringify(content) })}
          canEdit={canEdit}
          saveButtonLabel={t("Save", {
            ns: "Global",
          })}
          cancelButtonLabel={t("Cancel", {
            ns: "Global",
          })}
          renderEditIconButton={renderEditIconButton}
          InfoButton={
            showInfo ? (
              <ModalOpenButton
                Modal={InvoiceTextsAvailableReplacementsModal}
                modalProps={{}}
              >
                <IconButton size="small" color="secondary">
                  <Tooltip
                    title={t("Available replacements", {
                      ns: "OrganisationSettings",
                    })}
                  >
                    <HelpIcon />
                  </Tooltip>
                </IconButton>
              </ModalOpenButton>
            ) : undefined
          }
        />
      </Box>
    </Paper>
  );
};
