/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type S4HanaQuoteDataHooksQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type S4HanaQuoteDataHooksQuery = { quoteSapS4HanaData?: { __typename: 'QuoteSapS4HanaData', id: string, configurableProductSupplierCatalogueIds: Array<string> } | null };

export type S4HanaQuoteItemDataHooksQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
}>;


export type S4HanaQuoteItemDataHooksQuery = { quoteItemSapS4HanaData: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'QuoteItemSapS4HanaDataResult', items: Array<{ __typename: 'ItemSapS4HanaData', id: string, xId?: string | null }> } };


export const S4HanaQuoteDataHooksDocument = gql`
    query S4HanaQuoteDataHooks($projectId: ID!, $quoteId: ID!) {
  quoteSapS4HanaData(projectId: $projectId, docId: $quoteId) {
    id
    configurableProductSupplierCatalogueIds
  }
}
    `;

/**
 * __useS4HanaQuoteDataHooksQuery__
 *
 * To run a query within a React component, call `useS4HanaQuoteDataHooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useS4HanaQuoteDataHooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS4HanaQuoteDataHooksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useS4HanaQuoteDataHooksQuery(baseOptions: Apollo.QueryHookOptions<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables> & ({ variables: S4HanaQuoteDataHooksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>(S4HanaQuoteDataHooksDocument, options);
      }
export function useS4HanaQuoteDataHooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>(S4HanaQuoteDataHooksDocument, options);
        }
export function useS4HanaQuoteDataHooksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>(S4HanaQuoteDataHooksDocument, options);
        }
export type S4HanaQuoteDataHooksQueryHookResult = ReturnType<typeof useS4HanaQuoteDataHooksQuery>;
export type S4HanaQuoteDataHooksLazyQueryHookResult = ReturnType<typeof useS4HanaQuoteDataHooksLazyQuery>;
export type S4HanaQuoteDataHooksSuspenseQueryHookResult = ReturnType<typeof useS4HanaQuoteDataHooksSuspenseQuery>;
export type S4HanaQuoteDataHooksQueryResult = Apollo.QueryResult<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>;
export const S4HanaQuoteItemDataHooksDocument = gql`
    query S4HanaQuoteItemDataHooks($projectId: ID!, $quoteId: ID!, $itemId: ID!) {
  quoteItemSapS4HanaData(
    projectId: $projectId
    docId: $quoteId
    itemIds: [$itemId]
  ) {
    ... on QuoteItemSapS4HanaDataResult {
      items {
        id
        xId
      }
    }
  }
}
    `;

/**
 * __useS4HanaQuoteItemDataHooksQuery__
 *
 * To run a query within a React component, call `useS4HanaQuoteItemDataHooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useS4HanaQuoteItemDataHooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS4HanaQuoteItemDataHooksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useS4HanaQuoteItemDataHooksQuery(baseOptions: Apollo.QueryHookOptions<S4HanaQuoteItemDataHooksQuery, S4HanaQuoteItemDataHooksQueryVariables> & ({ variables: S4HanaQuoteItemDataHooksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<S4HanaQuoteItemDataHooksQuery, S4HanaQuoteItemDataHooksQueryVariables>(S4HanaQuoteItemDataHooksDocument, options);
      }
export function useS4HanaQuoteItemDataHooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<S4HanaQuoteItemDataHooksQuery, S4HanaQuoteItemDataHooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<S4HanaQuoteItemDataHooksQuery, S4HanaQuoteItemDataHooksQueryVariables>(S4HanaQuoteItemDataHooksDocument, options);
        }
export function useS4HanaQuoteItemDataHooksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<S4HanaQuoteItemDataHooksQuery, S4HanaQuoteItemDataHooksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<S4HanaQuoteItemDataHooksQuery, S4HanaQuoteItemDataHooksQueryVariables>(S4HanaQuoteItemDataHooksDocument, options);
        }
export type S4HanaQuoteItemDataHooksQueryHookResult = ReturnType<typeof useS4HanaQuoteItemDataHooksQuery>;
export type S4HanaQuoteItemDataHooksLazyQueryHookResult = ReturnType<typeof useS4HanaQuoteItemDataHooksLazyQuery>;
export type S4HanaQuoteItemDataHooksSuspenseQueryHookResult = ReturnType<typeof useS4HanaQuoteItemDataHooksSuspenseQuery>;
export type S4HanaQuoteItemDataHooksQueryResult = Apollo.QueryResult<S4HanaQuoteItemDataHooksQuery, S4HanaQuoteItemDataHooksQueryVariables>;