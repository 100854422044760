import { gql, useApolloClient } from "@apollo/client";
import { Checkbox, Radio, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ChangeEvent } from "react";
import {
  DecisionOptionButton_DocumentItemFragment,
  useToggleDecisionOptionPreselectionMutation,
} from "./DecisionOptionRadioOrCheckbox.generated.js";

interface Props {
  docId: string;
  projectId: string | null;
  item: DecisionOptionButton_DocumentItemFragment;
  disabled?: boolean;
  expandedItemIds: string[] | undefined;
  refetch?: () => Promise<unknown>;
}

export const DecisionOptionRadioOrCheckbox = ({
  docId,
  projectId,
  item,
  disabled,
  expandedItemIds,
  refetch,
}: Props) => {
  const { t } = useTranslate("Decisions");

  const client = useApolloClient();
  const [
    toggleDecisionOptionPreselection,
    { loading: toggleDecisionOptionPreselectionLoading },
  ] = useToggleDecisionOptionPreselectionMutation({ client });

  const selected = item.decisionOptionIsPreselected ?? false;
  const isEliminated = !!item.decisionOptionElimination;

  const onChange = async (event: ChangeEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!item.parentId) throw new Error("item.parentId is missing");

    await toggleDecisionOptionPreselection({
      variables: {
        input: {
          projectId,
          docId: docId,
          itemId: item.parentId,
          decisionOptionId: item.id,
          expandedItemIds,
        },
      },
    });
    await refetch?.();
  };

  const Button =
    item.decisionOptionBehaviour === "SELECT_MANY" ? (
      <Checkbox
        checked={Boolean(selected)}
        onClick={onChange}
        disabled={
          disabled || isEliminated || toggleDecisionOptionPreselectionLoading
        }
        size="small"
        style={{ flexGrow: 0, flexShrink: 0 }}
      />
    ) : (
      <Radio
        checked={selected}
        onClick={onChange}
        disabled={
          disabled || isEliminated || toggleDecisionOptionPreselectionLoading
        }
        size="small"
        style={{ flexGrow: 0, flexShrink: 0 }}
      />
    );

  if (isEliminated) {
    return (
      <Tooltip title={t("Decision option is discarded")}>
        <div style={{ display: "flex" }}>{Button}</div>
      </Tooltip>
    );
  }

  return Button;
};
