import { useApolloClient } from "@apollo/client";
import { Cancel as Cancel } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import { useCancelPimImportMutation } from "../PimImportations.generated.js";

export const CancelPimImportationButton = ({
  pimImportation,
}: {
  pimImportation: {
    id: string;
  };
}) => {
  const client = useApolloClient();
  const [status, setStatus] = React.useState<"idle" | "loading" | "error">(
    "idle"
  );

  const [cancelPimImport] = useCancelPimImportMutation({ client });

  return (
    <IconButton
      disabled={status === "loading"}
      color="primary"
      onClick={async () => {
        setStatus("loading");
        await cancelPimImport({
          variables: {
            input: {
              importId: pimImportation.id,
            },
          },
        });
        setStatus("idle");
      }}
      size="small"
    >
      {status === "loading" ? (
        <CircularProgress size={"1.25rem"} />
      ) : (
        <Cancel />
      )}
    </IconButton>
  );
};
