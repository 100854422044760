import { Button, Stack, StackProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormikContext } from "formik";
import React from "react";
import { useUpdateEffect } from "react-use";

interface Props {
  disabled?: boolean;
  disabledCancel?: boolean;
  disabledSubmit?: boolean;
  onCancel?: () => void;
  onChange?: (values: any) => void;
  onSubmitted?: () => void;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  form?: string;
  stackProps?: StackProps;
  saveButtonTitle?: string;
  cancelButtonStyle?: React.CSSProperties;
  submitButtonStyle?: React.CSSProperties;
}

export const ManualSave = ({
  disabledCancel = false,
  disabledSubmit = false,
  disabled = false,
  onCancel,
  onChange,
  onSubmitted,
  onSubmit,
  form,
  stackProps,
  saveButtonTitle,
  cancelButtonStyle,
  submitButtonStyle,
}: Props) => {
  const { t } = useTranslate("Global");
  const { isValid, isSubmitting, dirty } = useFormikContext();
  const { values } = useFormikContext();

  useUpdateEffect(() => {
    onChange?.(values);
  }, [values]);

  useUpdateEffect(() => {
    if (!isSubmitting) onSubmitted?.();
  }, [isSubmitting]);

  return (
    <Stack
      width="100%"
      justifyContent="stretch"
      direction="row"
      spacing={1}
      {...stackProps}
    >
      <Button
        disabled={disabled || disabledCancel}
        fullWidth
        onClick={onCancel}
        size="small"
        variant="outlined"
        color="primary"
        style={cancelButtonStyle}
        sx={{ flex: 1 }}
      >
        {t("Cancel")}
      </Button>
      <Button
        fullWidth
        disabled={
          disabled || disabledSubmit || !dirty || !isValid || isSubmitting
        }
        type="submit"
        size="small"
        variant="contained"
        color="primary"
        form={form}
        onClick={onSubmit}
        style={submitButtonStyle}
        sx={{ flex: 1 }}
      >
        {saveButtonTitle ?? t("Save changes")}
      </Button>
    </Stack>
  );
};
