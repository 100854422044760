/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetOrganisationMemberOrganisationRolesMutationVariables = Types.Exact<{
  input: Types.SetOrganisationMemberOrganisationRolesInput;
}>;


export type SetOrganisationMemberOrganisationRolesMutation = { setOrganisationMemberOrganisationRoles: { __typename: 'SetOrganisationMemberOrganisationRolesResult', member: { __typename: 'User', id: string, roles: Array<{ __typename: 'OrganisationRole', id: string, label: string, internalName: string }> } } };

export type SetOrganisationMemberDefaultProjectRolesMutationVariables = Types.Exact<{
  input: Types.SetOrganisationMemberDefaultProjectRolesInput;
}>;


export type SetOrganisationMemberDefaultProjectRolesMutation = { setOrganisationMemberDefaultProjectRoles: { __typename: 'SetOrganisationMemberDefaultProjectRolesResult', member: { __typename: 'User', id: string, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, label: string, internalName: string }> } } };


export const SetOrganisationMemberOrganisationRolesDocument = gql`
    mutation SetOrganisationMemberOrganisationRoles($input: SetOrganisationMemberOrganisationRolesInput!) {
  setOrganisationMemberOrganisationRoles(input: $input) {
    member {
      id
      roles {
        id
        label
        internalName
      }
    }
  }
}
    `;
export type SetOrganisationMemberOrganisationRolesMutationFn = Apollo.MutationFunction<SetOrganisationMemberOrganisationRolesMutation, SetOrganisationMemberOrganisationRolesMutationVariables>;

/**
 * __useSetOrganisationMemberOrganisationRolesMutation__
 *
 * To run a mutation, you first call `useSetOrganisationMemberOrganisationRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganisationMemberOrganisationRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganisationMemberOrganisationRolesMutation, { data, loading, error }] = useSetOrganisationMemberOrganisationRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrganisationMemberOrganisationRolesMutation(baseOptions?: Apollo.MutationHookOptions<SetOrganisationMemberOrganisationRolesMutation, SetOrganisationMemberOrganisationRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrganisationMemberOrganisationRolesMutation, SetOrganisationMemberOrganisationRolesMutationVariables>(SetOrganisationMemberOrganisationRolesDocument, options);
      }
export type SetOrganisationMemberOrganisationRolesMutationHookResult = ReturnType<typeof useSetOrganisationMemberOrganisationRolesMutation>;
export type SetOrganisationMemberOrganisationRolesMutationResult = Apollo.MutationResult<SetOrganisationMemberOrganisationRolesMutation>;
export type SetOrganisationMemberOrganisationRolesMutationOptions = Apollo.BaseMutationOptions<SetOrganisationMemberOrganisationRolesMutation, SetOrganisationMemberOrganisationRolesMutationVariables>;
export const SetOrganisationMemberDefaultProjectRolesDocument = gql`
    mutation SetOrganisationMemberDefaultProjectRoles($input: SetOrganisationMemberDefaultProjectRolesInput!) {
  setOrganisationMemberDefaultProjectRoles(input: $input) {
    member {
      id
      defaultProjectRoles {
        id
        label
        internalName
      }
    }
  }
}
    `;
export type SetOrganisationMemberDefaultProjectRolesMutationFn = Apollo.MutationFunction<SetOrganisationMemberDefaultProjectRolesMutation, SetOrganisationMemberDefaultProjectRolesMutationVariables>;

/**
 * __useSetOrganisationMemberDefaultProjectRolesMutation__
 *
 * To run a mutation, you first call `useSetOrganisationMemberDefaultProjectRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganisationMemberDefaultProjectRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganisationMemberDefaultProjectRolesMutation, { data, loading, error }] = useSetOrganisationMemberDefaultProjectRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrganisationMemberDefaultProjectRolesMutation(baseOptions?: Apollo.MutationHookOptions<SetOrganisationMemberDefaultProjectRolesMutation, SetOrganisationMemberDefaultProjectRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrganisationMemberDefaultProjectRolesMutation, SetOrganisationMemberDefaultProjectRolesMutationVariables>(SetOrganisationMemberDefaultProjectRolesDocument, options);
      }
export type SetOrganisationMemberDefaultProjectRolesMutationHookResult = ReturnType<typeof useSetOrganisationMemberDefaultProjectRolesMutation>;
export type SetOrganisationMemberDefaultProjectRolesMutationResult = Apollo.MutationResult<SetOrganisationMemberDefaultProjectRolesMutation>;
export type SetOrganisationMemberDefaultProjectRolesMutationOptions = Apollo.BaseMutationOptions<SetOrganisationMemberDefaultProjectRolesMutation, SetOrganisationMemberDefaultProjectRolesMutationVariables>;