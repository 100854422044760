import { gql, useApolloClient } from "@apollo/client";
import { ErrorMessage, FormattedPrice, Table, TableColumn } from "@msys/ui";
import { Divider } from "@mui/material";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import { isEqual, pick } from "lodash-es";
import { useSnackbar } from "notistack";
import { FC, useMemo } from "react";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField.js";
import { FormattedPriceField } from "../../../commons/form-fields/FormattedPriceField.js";
import { QuantityUnit } from "../../../../clients/graphqlTypes.js";
import {
  PurchaseOrderQuery,
  PurchaseOrder__ItemFragment,
} from "../../../main-routes/projects/ProjectPurchaseOrderPreview.generated.js";
import { useChangeOrderItemQuantityMutation } from "./PurchaseOrderItemsTable.generated.js";
import { useTranslate } from "@tolgee/react";
import { QuantityUnitField } from "../../doc-items/QuantityUnitField.js";
import { useQuantityUnits } from "../../doc-items/useQuantityUnits.js";
import { PurchaseOrderItemDescriptionColumn } from "./PurchaseOrderItemDescriptionColumn.js";
import { PurchaseOrderItemMobileRow } from "./PurchaseOrderItemMobileRow.js";

const cellStyle = { verticalAlign: "top" };

interface FormValues {
  [key: string]: {
    quantityOrdered: number;
    quantityUnit: QuantityUnit;
    unitPrice: number;
    productArticleNumber?: string | null | undefined;
    productManufacturerArticleNumber?: string | null | undefined;
  };
}

type Order = Exclude<
  Exclude<
    Extract<
      PurchaseOrderQuery["project"],
      { __typename: "ProjectResult" }
    >["project"],
    null | undefined
  >["order"],
  null | undefined
>;

interface Props {
  order: Order;
}

export const PurchaseOrderItemsTable: FC<Props> = ({ order }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["PurchaseOrders", "Quote", "QuantityUnitField"]);
  const { quantityUnitLabels } = useQuantityUnits();

  const client = useApolloClient();
  const [changeOrderItem] = useChangeOrderItemQuantityMutation({
    client,
  });

  const items = order?.items;

  const columns: TableColumn<PurchaseOrder__ItemFragment>[] = [
    {
      cellStyle,
      id: "position",
      title: t("Pos. ", {
        ns: "Quote",
      }),
      render: (item, index) => `${index + 1}.`,
    },
    {
      cellStyle,
      id: "description",
      title: t("Description", {
        ns: "Quote",
      }),
      render: item => <PurchaseOrderItemDescriptionColumn item={item} />,
    },
    {
      cellStyle,
      id: "article",
      title: t("Article no.", {
        ns: "Quote",
      }),
      render: item =>
        order.orderedDate ? (
          item.productArticleNumber
        ) : (
          <Field
            component={TextField}
            placeholder={t("Article no.", {
              ns: "Quote",
            })}
            name={`${item.id}.productArticleNumber`}
            disabled={false}
            hiddenLabel
            size={"extra-small"}
          />
        ),
    },
    {
      cellStyle,
      id: "manufacturer-article-number",
      title: t("Manufacturer article no.", {
        ns: "Quote",
      }),
      render: item =>
        order.orderedDate ? (
          item.productManufacturerArticleNumber
        ) : (
          <Field
            component={TextField}
            placeholder={t("Manufacturer article no.", {
              ns: "Quote",
            })}
            name={`${item.id}.productManufacturerArticleNumber`}
            disabled={false}
            hiddenLabel
            size={"extra-small"}
          />
        ),
    },
    {
      cellStyle,
      id: "quantity",
      title: t("Quantity", {
        ns: "Quote",
      }),
      render: item => {
        return order.orderedDate ? (
          item.quantityOrdered +
            "\u00A0" +
            quantityUnitLabels[item.quantityUnit]
        ) : (
          <FormattedFloatField
            label=""
            disabled={false}
            placeholder={t("Quantity", {
              ns: "Quote",
            })}
            name={`${item.id}.quantityOrdered`}
            type="float"
            min={0}
            size={"extra-small"}
            hiddenLabel
          />
        );
      },
      width: 100,
    },
    ...(order.orderedDate
      ? []
      : [
          {
            cellStyle,
            id: "quantityUnit",
            title: t("Unit", {
              ns: "QuantityUnitField",
            }), // fixme
            render: (item: PurchaseOrder__ItemFragment) => (
              <QuantityUnitField
                name={`${item.id}.quantityUnit`}
                size={"extra-small"}
                disabled={false}
              />
            ),
            width: 60,
          },
        ]),
    {
      cellStyle,
      id: "price",
      title: t("Unit Price", {
        ns: "Quote",
      }),
      render: item =>
        order.orderedDate ? (
          <FormattedPrice value={item.unitPrice} />
        ) : (
          <FormattedPriceField
            label=""
            disabled={false}
            name={`${item.id}.unitPrice`}
            placeholder={t("Unit Price", {
              ns: "Quote",
            })}
            type="price"
            min={0}
            size={"extra-small"}
            hiddenLabel
          />
        ),
      width: 140,
      textAlign: "right",
    },
    {
      cellStyle,
      id: "subtotal",
      title: t("Subtotal", {
        ns: "Quote",
      }),
      render: item => (
        <FormattedPrice value={item.unitPrice * item.quantityOrdered} />
      ),
      width: 120,
      textAlign: "right",
    },
  ];

  const initialValues = useMemo(
    () =>
      items.reduce<FormValues>(
        (acc, item) => ({
          ...acc,
          [item.id]: pick(
            item,
            "quantityOrdered",
            "quantityUnit",
            "unitPrice",
            "productArticleNumber",
            "productManufacturerArticleNumber"
          ),
        }),
        {} as FormValues
      ),
    [items]
  );

  const onSubmit = async (formValues: FormValues) => {
    try {
      await Promise.all(
        Object.entries(formValues)
          .filter(
            ([itemId, rowValues]) => !isEqual(rowValues, initialValues[itemId])
          )
          .map(([itemId, rowValues]) => {
            return changeOrderItem({
              variables: {
                input: {
                  orderId: order.id,
                  itemId,
                  quantity: rowValues.quantityOrdered ?? 0,
                  quantityUnit: rowValues.quantityUnit ?? "piece",
                  unitPrice: rowValues.unitPrice ?? 0,
                  productArticleNumber: rowValues.productArticleNumber ?? null,
                  productManufacturerArticleNumber:
                    rowValues.productManufacturerArticleNumber ?? null,
                },
              },
            });
          })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = Yup.object().shape(
    items.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: Yup.object().shape({
          quantityOrdered: Yup.number(),
          quantityUnit: Yup.string(),
          unitPrice: Yup.number(),
          productArticleNumber: Yup.string().nullable(),
          productManufacturerArticleNumber: Yup.string().nullable(),
        }),
      }),
      {}
    )
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <>
        <Table
          columns={columns}
          items={items}
          mobileStackSpacing={0}
          renderPhoneRow={(item, index) => (
            <>
              {index > 0 ? <Divider /> : null}
              <PurchaseOrderItemMobileRow
                item={item}
                index={index}
                isEditable={!order.orderedDate}
              />
            </>
          )}
          renderEmptyScreen={() => (
            <ErrorMessage
              message={t("No purchase order items", {
                ns: "PurchaseOrders",
              })}
            />
          )}
        />
        <AutoSave />
      </>
    </Formik>
  );
};
