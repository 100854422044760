import { useGlobalLoadingCounter } from "../../../common/global-loading-state/useGlobalLoadingCounter.js";
import { FileInput } from "../../commons/inputs/FileInput.js";
import { Attachment, uploadFileToUploadCare } from "./helpers.js";

interface Props {
  innerRef: React.ComponentProps<typeof FileInput>["innerRef"];
  accept: string;
  onCancel?: () => void;
  onStart?: () => void;
  onError?: (e: any) => void;
}
interface Single {
  multiple: false;
  onComplete: (attachment: Attachment) => Promise<void> | void;
}
interface Multiple {
  multiple: true;
  onComplete: (attachments: Attachment[]) => Promise<void> | void;
}

export const AttachmentUploader = ({
  innerRef,
  accept,
  multiple,
  onStart,
  onCancel,
  onError,
  onComplete,
}: Props & (Single | Multiple)) => {
  const { increment, decrement } = useGlobalLoadingCounter();

  if (multiple) {
    return (
      <FileInput
        innerRef={innerRef}
        accept={accept}
        multiple={true}
        onStart={onStart}
        onCancel={onCancel}
        onComplete={async files => {
          try {
            increment();

            const attachments = await Promise.all(
              files.map((file: File) => uploadFileToUploadCare(file))
            );
            await onComplete?.(attachments);

            if (innerRef.current) innerRef.current.value = "";
          } catch (e) {
            onError?.(e);
            throw e;
          } finally {
            decrement();
          }
        }}
      />
    );
  }

  return (
    <FileInput
      innerRef={innerRef}
      multiple={false}
      accept={accept}
      onStart={onStart}
      onCancel={onCancel}
      onComplete={async file => {
        try {
          increment();

          const attachment = await uploadFileToUploadCare(file);
          await onComplete(attachment);

          if (innerRef.current) innerRef.current.value = "";
        } catch (e) {
          onError?.(e);
          throw e;
        } finally {
          decrement();
        }
      }}
    />
  );
};
