import { DatePicker, FormattedPriceInput, Select } from "@msys/ui";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import React from "react";
import { browserHasInputDate } from "../../featureDetection.js";
import { OrganisationUserSelect } from "../../features/organisations/OrganisationUserSelect.js";
import { OrganisationUsersSelect } from "../../features/organisations/OrganisationUsersSelect.js";

export interface FilterProps<T> {
  value: T;
  setValue: (newValue: T) => void;
}

type FilterByEnumProps<T> = {
  label: string;
  anyEnum: Record<string, T>;
  getOptionLabel?: (option: T) => string;
} & (
  | {
      type: "one";
      setValue: (newValue: T) => void;
      value: T;
    }
  | {
      type: "many";
      setValue: (newValue: T[]) => void;
      value: T[];
    }
);

export function FilterByEnum<T extends string>(props: FilterByEnumProps<T>) {
  const { t } = useTranslate(["Global"]);

  if (props.type !== "many") {
    return (
      <Select
        fullWidth
        label={props.label}
        onChange={value => {
          props.setValue(value as T);
        }}
        value={props.value}
        options={Object.entries(props.anyEnum).map(([key, value]) => ({
          value: value,
          label: String(value),
        }))}
      />
    );
  }

  return (
    <Autocomplete
      multiple
      openOnFocus
      disableCloseOnSelect
      noOptionsText={t("No existing entry", {
        ns: "Global",
      })}
      options={Object.entries(props.anyEnum).map(([key, value]) => value)}
      value={props.value as T[]}
      onChange={(event, newValues) => {
        // @ts-ignore // FIXME
        props.setValue(newValues);
      }}
      getOptionLabel={value =>
        props.getOptionLabel ? props.getOptionLabel(value) : String(value)
      }
      renderInput={params => <TextField {...params} label={props.label} />}
    />
  );
}

type FilterByComplexEnumProps<T> = {
  label: string;
  anyEnum: Record<string, T>;
  getOptionLabel: (option: T) => string;
} & (
  | {
      type: "one";
      setValue: (newValue: T) => void;
      value: T;
    }
  | {
      type: "many";
      setValue: (newValue: T[]) => void;
      value: T[];
    }
);

export function FilterByComplexEnum<T>(props: FilterByComplexEnumProps<T>) {
  const { t } = useTranslate(["Global"]);

  if (props.type !== "many") {
    return (
      <Select
        fullWidth
        label={props.label}
        onChange={value => {
          props.setValue(value as T);
        }}
        value={props.value}
        options={Object.entries(props.anyEnum).map(([key, value]) => ({
          value: value,
          label: props.getOptionLabel(value),
        }))}
      />
    );
  }

  return (
    <Autocomplete
      multiple
      openOnFocus
      disableCloseOnSelect
      noOptionsText={t("No existing entry", {
        ns: "Global",
      })}
      options={Object.entries(props.anyEnum).map(([key, value]) => value)}
      value={props.value as T[]}
      onChange={(event, newValues) => {
        // @ts-ignore // FIXME
        props.setValue(newValues);
      }}
      getOptionLabel={value =>
        props.getOptionLabel ? props.getOptionLabel(value) : String(value)
      }
      renderInput={params => <TextField {...params} label={props.label} />}
    />
  );
}

export function FilterByText(
  props: {
    label: string;
    placeholder: string;
    helperText?: string;
  } & FilterProps<string>
) {
  return (
    <TextField
      label={props.label}
      type="text"
      helperText={props.helperText}
      value={props.value}
      onChange={e => props.setValue(e.target.value)}
      placeholder={props.placeholder}
    />
  );
}

export function FilterByNumber(
  props: {
    label: string;
    placeholder: string;
    helperText?: string;
  } & FilterProps<number>
) {
  return (
    <FilterByText
      label={props.label}
      placeholder={props.placeholder}
      value={props.value.toString()}
      helperText={props.helperText}
      setValue={value =>
        props.setValue(
          !Number.isNaN(parseInt(value, 10)) ? parseInt(value, 10) : 0
        )
      }
    />
  );
}

export function FilterByPrice(
  props: {
    label: string;
    placeholder: string;
    helperText?: string;
  } & FilterProps<number | null>
) {
  return (
    <FormattedPriceInput
      label={props.label}
      value={props.value}
      placeholder={props.placeholder}
      helperText={props.helperText}
      onChange={value => props.setValue(value)}
    />
  );
}

export function FilterByDate(
  props: {
    label: string;
  } & FilterProps<moment.Moment | null>
) {
  const value = React.useMemo(
    () =>
      props.value
        ? moment.isMoment(props.value)
          ? props.value
          : moment(props.value)
        : null,
    [props.value]
  );
  return (
    <DatePicker
      browserHasInputDate={browserHasInputDate}
      label={props.label}
      value={value}
      onChange={(event, value) => props.setValue(value)}
    />
  );
}

export function FilterByOrganisationUser(
  props: {
    label: string;
    placeholder: string;
  } & FilterProps<string | null>
) {
  return (
    <OrganisationUserSelect
      inputLabel={props.label}
      placeholder={props.placeholder}
      userId={props.value}
      onChange={value => props.setValue(value)}
    />
  );
}

export function FilterByOrganisationUsers(
  props: {
    label: string;
    placeholder: string;
    autocompleteProps?: React.ComponentProps<
      typeof OrganisationUsersSelect
    >["autocompleteProps"];
  } & FilterProps<string[]>
) {
  return (
    <OrganisationUsersSelect
      inputLabel={props.label}
      placeholder={props.placeholder}
      userIds={props.value}
      onChange={value => props.setValue(value)}
      autocompleteProps={props.autocompleteProps}
    />
  );
}

export function FilterByBoolean(
  props: FilterProps<boolean | undefined> & {
    label: string;
    yesLabel?: string;
    noLabel?: string;
    allLabel?: string;
  }
) {
  const { t } = useTranslate("Global");
  const toValueLabel = (value: boolean | undefined): "yes" | "no" | "" =>
    value === true ? "yes" : value === false ? "no" : "";
  const fromValueLabel = (value: "yes" | "no" | ""): boolean | undefined =>
    value === "yes" ? true : value === "no" ? false : undefined;
  return (
    <Select
      fullWidth
      label={props.label}
      value={toValueLabel(props.value)}
      onChange={value => {
        props.setValue(fromValueLabel(value as "yes" | "no" | ""));
      }}
      clearable={true}
      onClear={() => props.setValue(undefined)}
      options={[
        { value: "yes" as const, label: props.yesLabel || t("Yes") },
        { value: "no" as const, label: props.noLabel || t("No") },
      ]}
    />
  );
}

export function FilterCheckbox(
  props: FilterProps<boolean | undefined> & {
    label: string;
  }
) {
  return (
    <FormControl variant="standard">
      <FormControlLabel
        control={
          <Checkbox
            checked={props.value}
            indeterminate={false}
            onChange={(event, checked) => {
              props.setValue(checked || undefined);
            }}
            size="small"
          />
        }
        label={props.label}
      />
    </FormControl>
  );
}
