/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated.js';
export type ProjectSourceCreatedBy_ProjectSourceFragment = { __typename: 'ProjectSource', id: string, createdByCrmOrganisationId?: string | null, crmOrganisationTitle?: string | null, createdByUserId?: string | null, userFamilyname?: string | null, userFirstname?: string | null, crmOrganisationLogo?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null, userAvatar?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null };

export const ProjectSourceCreatedBy_ProjectSourceFragmentDoc = gql`
    fragment ProjectSourceCreatedBy_ProjectSource on ProjectSource {
  id
  createdByCrmOrganisationId
  crmOrganisationTitle
  crmOrganisationLogo {
    ...AttachmentSnapshot
  }
  createdByUserId
  userAvatar {
    ...AttachmentSnapshot
  }
  userFamilyname
  userFirstname
}
    ${AttachmentSnapshotFragmentDoc}`;