/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { RequestStatusBadgeFragmentDoc } from '../../features/requests/RequestStatusBadge.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectRequestsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectRequestsQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, outgoingQuoteRequests: Array<{ __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string, requestorDoc: { __typename: 'Requirement', id: string, title: string }, quotes: Array<{ __typename: 'Quote', id: string }> }> } | null }, projectRequirements: { __typename: 'MissingPermissions' } | { __typename: 'ProjectRequirementsConnection', totalCount: number }, projectOutgoingQuotes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'ProjectOutgoingQuoteConnection', totalCount: number, edges: Array<{ __typename: 'OutgoingQuoteEdge', node: { __typename: 'OutgoingQuote', id: string } }> } };

export type ProjectRequest__RequestFragment = { __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string, requestorDoc: { __typename: 'Requirement', id: string, title: string }, quotes: Array<{ __typename: 'Quote', id: string }> };

export const ProjectRequest__RequestFragmentDoc = gql`
    fragment ProjectRequest__Request on Request {
  id
  title
  status
  requestorDoc {
    id
    title
  }
  quotes {
    id
  }
  ...RequestStatusBadge
}
    ${RequestStatusBadgeFragmentDoc}`;
export const ProjectRequestsDocument = gql`
    query ProjectRequests($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        viewerPermissions
        outgoingQuoteRequests {
          id
          ...ProjectRequest__Request
        }
      }
    }
  }
  projectRequirements(
    projectId: $projectId
    filters: {origin: OWN, withoutWonRequest: true}
    limit: 0
  ) {
    ... on ProjectRequirementsConnection {
      totalCount
    }
  }
  projectOutgoingQuotes(limit: 0, projectId: $projectId) {
    ... on ProjectOutgoingQuoteConnection {
      edges {
        node {
          id
        }
      }
      totalCount
    }
  }
}
    ${ProjectRequest__RequestFragmentDoc}`;

/**
 * __useProjectRequestsQuery__
 *
 * To run a query within a React component, call `useProjectRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRequestsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectRequestsQuery(baseOptions: Apollo.QueryHookOptions<ProjectRequestsQuery, ProjectRequestsQueryVariables> & ({ variables: ProjectRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectRequestsQuery, ProjectRequestsQueryVariables>(ProjectRequestsDocument, options);
      }
export function useProjectRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectRequestsQuery, ProjectRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectRequestsQuery, ProjectRequestsQueryVariables>(ProjectRequestsDocument, options);
        }
export function useProjectRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectRequestsQuery, ProjectRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectRequestsQuery, ProjectRequestsQueryVariables>(ProjectRequestsDocument, options);
        }
export type ProjectRequestsQueryHookResult = ReturnType<typeof useProjectRequestsQuery>;
export type ProjectRequestsLazyQueryHookResult = ReturnType<typeof useProjectRequestsLazyQuery>;
export type ProjectRequestsSuspenseQueryHookResult = ReturnType<typeof useProjectRequestsSuspenseQuery>;
export type ProjectRequestsQueryResult = Apollo.QueryResult<ProjectRequestsQuery, ProjectRequestsQueryVariables>;