import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  ProjectSourcePageName,
  ProjectSourceType,
} from "../../../clients/graphqlTypes.js";

export type ProjectSourceFilterType =
  | "Request"
  | "Manual"
  | "Import"
  | "Flow"
  | "QuoteProductsDistribution"
  | "ContracteeInvitation";

export const PROJECT_FILTER_MAP: Record<
  ProjectSourceFilterType,
  ProjectSourceType[]
> = {
  Request: ["RequestManual", "RequestAutomated"],
  Manual: ["Manual"],
  Import: ["Import"],
  Flow: ["FlowM1", "FlowWizard", "Flow3D"],
  QuoteProductsDistribution: ["QuoteProductsDistribution"],
  ContracteeInvitation: ["ContracteeInvitation"],
};

const PROJECT_SOURCES: ProjectSourceType[] = [
  "Manual",
  "Import",
  "FlowM1",
  "FlowWizard",
  "Flow3D",
  "RequestManual",
  "RequestAutomated",
  "QuoteProductsDistribution",
  "ContracteeInvitation",
  "Legacy",
];

export function useProjectSources() {
  const { t } = useTranslate("ProjectSources");

  const projectSourcePageNameLabels: Record<ProjectSourcePageName, string> =
    React.useMemo(
      () => ({
        Shop: t("Shop"),
        Building: t("Building"),
        CrmCompany: t("CRM"),
        Projects: t("Projects list"),
        Tickets: t("Tickets list"),
        Todos: t("Tasks list"),
        CreateProjectModal: t("Project create modal"),
        CreateTicketModal: t("Ticket create modal"),
        Opportunities: t("Opportunities list"),
        PlanningProjects: t("Planning for projects"),
        PlanningTickets: t("Planning for tickets"),
        Dashboard: t("Dashboard"),
      }),
      [t]
    );
  const projectSourceLabels: Record<ProjectSourceType, string> = React.useMemo(
    () => ({
      Manual: t("Manual"),
      Import: t("Import"),
      FlowM1: t("M1 flow"),
      FlowWizard: t("Embeddable wizard"),
      Flow3D: t("3D flow"),
      RequestManual: t("Request for quote (manual)"),
      RequestAutomated: t("Request for quote (automatic)"),
      QuoteProductsDistribution: t("Article list"),
      ContracteeInvitation: t("Project invitation"),
      Legacy: t("Legacy"),
    }),
    [t]
  );
  const projectSourceFilterLabels: Record<ProjectSourceFilterType, string> =
    React.useMemo(
      () => ({
        Request: t("Request for quote"),
        Manual: t("Inner page"),
        Import: t("File import"),
        Flow: t("Form submission"),
        QuoteProductsDistribution: t("Article list"),
        ContracteeInvitation: t("Contractor"),
      }),
      [t]
    );
  const projectSourceFilterOptions: {
    value: ProjectSourceFilterType;
    label: string;
  }[] = React.useMemo(
    () =>
      Object.entries(projectSourceFilterLabels).map(([value, label]) => ({
        value: value as ProjectSourceFilterType,
        label,
      })),
    [projectSourceFilterLabels]
  );

  const projectSourceOptions = React.useMemo(
    () =>
      PROJECT_SOURCES.map(projectSource => ({
        value: projectSource,
        label: projectSourceLabels[projectSource],
      })),
    [projectSourceLabels]
  );

  return {
    projectSourceLabels,
    projectSourceOptions,
    projectSourcePageNameLabels,
    projectSourceFilterLabels,
    projectSourceFilterOptions,
  };
}
