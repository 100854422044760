/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated.js';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationDetailsBox_OrganisationFragment = { __typename: 'Organisation', id: string, title: string, description: string, organisationType: Types.OrganisationType, isCraftsmanOrganisation: boolean, email: any, website: string, skillset: Array<Types.Skill>, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, representive?: { __typename: 'User', id: string } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, pickupAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, profile?: { __typename: 'OrganisationProfile', id: string, slug: string } | null };

export type OrganisationDetailsBox_ViewerOrganisationFragment = { __typename: 'ViewerOrganisation', id: string, title: string, description: string, organisationType: Types.OrganisationType, isCraftsmanOrganisation: boolean, email: any, website: string, skillset: Array<Types.Skill>, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, representive?: { __typename: 'User', id: string } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, pickupAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, profile?: { __typename: 'OrganisationProfile', id: string, slug: string } | null };

export type ModifyOrganisationAvatarMutationVariables = Types.Exact<{
  input: Types.OrganisationUpdateInput;
}>;


export type ModifyOrganisationAvatarMutation = { updateOrganisation: { __typename: 'OrganisationUpdateResult', organisation: { __typename: 'ViewerOrganisation', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } };

export type OrganisationDetailsForm_ModifyOrganisationMutationVariables = Types.Exact<{
  input: Types.OrganisationUpdateInput;
}>;


export type OrganisationDetailsForm_ModifyOrganisationMutation = { updateOrganisation: { __typename: 'OrganisationUpdateResult', organisation: { __typename: 'ViewerOrganisation', id: string, title: string, description: string, isCraftsmanOrganisation: boolean, email: any, website: string, skillset: Array<Types.Skill>, organisationType: Types.OrganisationType, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, pickupAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, phones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }> } } };

export const OrganisationDetailsBox_OrganisationFragmentDoc = gql`
    fragment OrganisationDetailsBox_Organisation on Organisation {
  id
  title
  description
  organisationType
  isCraftsmanOrganisation
  email
  phones {
    id
    main
    type
    number
  }
  representive {
    id
  }
  logo {
    ...AttachmentSnapshot
  }
  website
  billingAddress {
    ...AddressDetails__Address
  }
  branchAddress {
    ...AddressDetails__Address
  }
  pickupAddress {
    ...AddressDetails__Address
  }
  skillset
  profile {
    id
    slug
  }
}
    ${AttachmentSnapshotFragmentDoc}
${AddressDetails__AddressFragmentDoc}`;
export const OrganisationDetailsBox_ViewerOrganisationFragmentDoc = gql`
    fragment OrganisationDetailsBox_ViewerOrganisation on ViewerOrganisation {
  id
  title
  description
  organisationType
  isCraftsmanOrganisation
  email
  phones {
    id
    main
    type
    number
  }
  representive {
    id
  }
  logo {
    ...AttachmentSnapshot
  }
  website
  billingAddress {
    ...AddressDetails__Address
  }
  branchAddress {
    ...AddressDetails__Address
  }
  pickupAddress {
    ...AddressDetails__Address
  }
  skillset
  profile {
    id
    slug
  }
}
    ${AttachmentSnapshotFragmentDoc}
${AddressDetails__AddressFragmentDoc}`;
export const ModifyOrganisationAvatarDocument = gql`
    mutation ModifyOrganisationAvatar($input: OrganisationUpdateInput!) {
  updateOrganisation(input: $input) {
    organisation {
      id
      title
      logo {
        ...AttachmentSnapshot
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type ModifyOrganisationAvatarMutationFn = Apollo.MutationFunction<ModifyOrganisationAvatarMutation, ModifyOrganisationAvatarMutationVariables>;

/**
 * __useModifyOrganisationAvatarMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationAvatarMutation, { data, loading, error }] = useModifyOrganisationAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationAvatarMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationAvatarMutation, ModifyOrganisationAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationAvatarMutation, ModifyOrganisationAvatarMutationVariables>(ModifyOrganisationAvatarDocument, options);
      }
export type ModifyOrganisationAvatarMutationHookResult = ReturnType<typeof useModifyOrganisationAvatarMutation>;
export type ModifyOrganisationAvatarMutationResult = Apollo.MutationResult<ModifyOrganisationAvatarMutation>;
export type ModifyOrganisationAvatarMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationAvatarMutation, ModifyOrganisationAvatarMutationVariables>;
export const OrganisationDetailsForm_ModifyOrganisationDocument = gql`
    mutation OrganisationDetailsForm_ModifyOrganisation($input: OrganisationUpdateInput!) {
  updateOrganisation(input: $input) {
    organisation {
      id
      title
      description
      isCraftsmanOrganisation
      email
      website
      billingAddress {
        ...AddressDetails__Address
      }
      branchAddress {
        ...AddressDetails__Address
      }
      pickupAddress {
        ...AddressDetails__Address
      }
      phones {
        id
        number
        type
        main
      }
      skillset
      organisationType
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export type OrganisationDetailsForm_ModifyOrganisationMutationFn = Apollo.MutationFunction<OrganisationDetailsForm_ModifyOrganisationMutation, OrganisationDetailsForm_ModifyOrganisationMutationVariables>;

/**
 * __useOrganisationDetailsForm_ModifyOrganisationMutation__
 *
 * To run a mutation, you first call `useOrganisationDetailsForm_ModifyOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationDetailsForm_ModifyOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationDetailsFormModifyOrganisationMutation, { data, loading, error }] = useOrganisationDetailsForm_ModifyOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationDetailsForm_ModifyOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationDetailsForm_ModifyOrganisationMutation, OrganisationDetailsForm_ModifyOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationDetailsForm_ModifyOrganisationMutation, OrganisationDetailsForm_ModifyOrganisationMutationVariables>(OrganisationDetailsForm_ModifyOrganisationDocument, options);
      }
export type OrganisationDetailsForm_ModifyOrganisationMutationHookResult = ReturnType<typeof useOrganisationDetailsForm_ModifyOrganisationMutation>;
export type OrganisationDetailsForm_ModifyOrganisationMutationResult = Apollo.MutationResult<OrganisationDetailsForm_ModifyOrganisationMutation>;
export type OrganisationDetailsForm_ModifyOrganisationMutationOptions = Apollo.BaseMutationOptions<OrganisationDetailsForm_ModifyOrganisationMutation, OrganisationDetailsForm_ModifyOrganisationMutationVariables>;