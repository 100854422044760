/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type OrganisationVerticalSettingFragment = { __typename: 'OrganisationVerticalSetting', id: string, vertical: Types.Vertical, averageWorkBuyingPrice: number, averageWorkSellingPrice: number };

export type QuoteVerticalSettingFragment = { __typename: 'QuoteVerticalSetting', id: string, vertical: Types.Vertical, averageWorkBuyingPrice: number, averageWorkSellingPrice: number };

export const OrganisationVerticalSettingFragmentDoc = gql`
    fragment OrganisationVerticalSetting on OrganisationVerticalSetting {
  id
  vertical
  averageWorkBuyingPrice
  averageWorkSellingPrice
}
    `;
export const QuoteVerticalSettingFragmentDoc = gql`
    fragment QuoteVerticalSetting on QuoteVerticalSetting {
  id
  vertical
  averageWorkBuyingPrice
  averageWorkSellingPrice
}
    `;