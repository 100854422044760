/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated.js';
export type RequestOverviewHeaderBoxFragment = { __typename: 'Request', id: string, title: string, description: string, categories: Array<Types.Skill>, type: Types.ProjectType, earliestStart?: any | null, deadline?: any | null, instructions: string, marketplaceFullAddress: boolean, budget?: { __typename: 'Money', currency: string, amount: number } | null, address?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, requestorDoc: { __typename: 'Requirement', id: string, contractee: { __typename: 'DocActor', id: string, companyOrIndividual: Types.DocActorCompanyOrIndividual, companyName: string, salutation: Types.Salutation, firstname: string, familyname: string, contact?: { __typename: 'DocActorContact', salutation: Types.Salutation, firstname: string, familyname: string } | null } } };

export const RequestOverviewHeaderBoxFragmentDoc = gql`
    fragment RequestOverviewHeaderBox on Request {
  id
  title
  description
  categories
  type
  earliestStart
  deadline
  budget {
    currency
    amount
  }
  instructions
  marketplaceFullAddress
  address {
    ...AddressDetails__Address
  }
  requestorDoc {
    id
    contractee {
      id
      companyOrIndividual
      companyName
      salutation
      firstname
      familyname
      companyOrIndividual
      contact {
        salutation
        firstname
        familyname
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;