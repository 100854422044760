import { Tooltip, TooltipProps, useTheme } from "@mui/material";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { ReportProblemOutlined as ReportProblemOutlinedIcon } from "@mui/icons-material";
import React from "react";
import { BillOfMaterialsItemFragment } from "./BillOfMaterials.generated.js";
import { useTranslate } from "@tolgee/react";

export function BillOfMaterialsItemQuantity({
  item,
  showAlerts = false,
}: {
  item: BillOfMaterialsItemFragment;
  // FIXME: we're not using it anymore (always equals 'false'), since we removed ALL quick filter types
  showAlerts?: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslate(["Materials"]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: theme.spacing(1),
        }}
      >
        {showAlerts && item.quantityRequiredDraft === item.quantityRequired && (
          <ScalableTooltip title={t("Draft quantities")} arrow>
            <InfoOutlinedIcon color="secondary" />
          </ScalableTooltip>
        )}
        {showAlerts &&
          item.quantityRequiredAccepted < item.quantityRequired &&
          item.quantityRequiredDraft < item.quantityRequired && (
            <ScalableTooltip
              title={t(
                "Includes pending or draft quantities. Open item for details."
              )}
              arrow
            >
              <ReportProblemOutlinedIcon
                htmlColor={theme.palette.warning.main}
              />
            </ScalableTooltip>
          )}
        <span>{item.quantityRequired}</span>
      </div>
      {item.quantityRequired !== item.quantityRequiredTotal && (
        <div style={{ color: theme.palette.grey[500], whiteSpace: "nowrap" }}>
          {`(${t(
            "{count, plural, =0 {0 total} one {1 total} other {# total}}",
            {
              ns: "Materials",
              count: item.quantityRequiredTotal,
            }
          )})`}
        </div>
      )}
    </div>
  );
}

function ScalableTooltip({
  children,
  ...props
}: React.PropsWithChildren<TooltipProps>) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <div
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      style={{ display: "inherit" }}
    >
      {isOpen ? <Tooltip {...props}>{children}</Tooltip> : children}
    </div>
  );
}
