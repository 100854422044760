import { InfoLabelMessage } from "@msys/ui";
import { Stack } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useTranslate } from "@tolgee/react";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { get, isString } from "lodash-es";
import React from "react";
import { useGoogleMapsApi } from "../../../common/google-maps/useGoogleMapsApi.js";
import { AddressSearch } from "./AddressSearch.js";

const defaultZoom = 15;

// Hackescher Markt
const defaultCenter = {
  lat: 52.523502,
  lng: 13.401785,
};

interface Props
  extends Omit<
    React.ComponentProps<typeof AddressSearch>,
    "onChange" | "error"
  > {
  name: string;
  allowCustom?: boolean;
}

export const AddressField = ({
  name,
  allowCustom = false,
  ...props
}: Props) => {
  const { isLoaded } = useGoogleMapsApi();
  const { t } = useTranslate("Address");
  const { setFieldValue, values, errors, isSubmitting } = useFormikContext<{
    [name: string]: Exclude<
      React.ComponentProps<typeof AddressSearch>["value"],
      undefined
    >;
  }>();

  const value = get(values, name);
  const error = get(errors, name);

  const [center, setCenter] = React.useState<{ lat: number; lng: number }>(
    defaultCenter
  );
  const lastCenter = React.useRef<{ lat: number; lng: number }>(defaultCenter);

  React.useEffect(() => {
    if (value && value.location) {
      if (
        lastCenter.current.lat !== value.location.lat ||
        lastCenter.current.lng !== value.location.lng
      ) {
        const newValue = { lat: value.location.lat, lng: value.location.lng };
        lastCenter.current = newValue;
        setCenter(newValue);
      }
    }
  }, [value]);

  return (
    <Stack direction="column" spacing={1}>
      <AddressSearch
        {...props}
        value={value}
        onChange={value => setFieldValue(name, value)}
        error={error && isString(error) ? error : undefined}
        disabled={props.disabled ?? isSubmitting}
      />
      {value && (
        <Stack direction="column" spacing={1}>
          <Field
            component={TextField}
            name={`${name}.streetLines1`}
            label={t("Street and house number")}
            disabled={!allowCustom}
          />
          <Stack direction="row" spacing={1}>
            <Field
              component={TextField}
              name={`${name}.postalCode`}
              label={t("Post code")}
              disabled={!allowCustom}
            />
            <Field
              component={TextField}
              name={`${name}.city`}
              label={t("City/town")}
              disabled={!allowCustom}
            />
          </Stack>
          {/* <Field
            component={TextField}
            name={`note`}
            label={t("Note (eg. doorbell, additional directions)")}
          /> */}
          {isLoaded && allowCustom && (
            <>
              {/*@ts-ignore property 'children' does not exist*/}
              <GoogleMap
                options={{ mapTypeControl: false, streetViewControl: false }}
                mapTypeId={google.maps.MapTypeId.ROADMAP}
                mapContainerStyle={{ width: "100%", height: 200 }}
                zoom={defaultZoom}
                center={center}
              >
                <Marker
                  visible
                  draggable
                  position={
                    value && value.location
                      ? { lat: value.location.lat, lng: value.location.lng }
                      : defaultCenter
                  }
                  zIndex={1}
                  onDragEnd={e => {
                    const newValue = {
                      lat: e.latLng.lat(),
                      lng: e.latLng.lng(),
                    };
                    lastCenter.current = newValue;
                    setFieldValue(name, {
                      ...value,
                      ...newValue,
                    });
                  }}
                />
              </GoogleMap>
              <InfoLabelMessage
                message={t("Drag marker to change address position on a map")}
              />
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
};
