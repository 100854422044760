import { useMemo } from "react";
import { ProjectType } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

const ALL_PROJECT_TYPES: ProjectType[] = [
  "DIY",
  "NEW",
  "OTHER",
  "RENEWAL",
  "REPAIR",
];

export function useProjectTypes() {
  const { t } = useTranslate("ProjectTypes");

  const projectTypeLabels: Record<ProjectType, string> = useMemo(
    () => ({
      REPAIR: t("Repair / maintenance"),
      RENEWAL: t("Renewal"),
      DIY: t("Do it Yourself"),
      NEW: t("New"),
      OTHER: t("Other / Don't know"),
    }),
    [t]
  );

  const projectTypeOptions = useMemo(
    () =>
      ALL_PROJECT_TYPES.map(projectType => ({
        value: projectType,
        label: projectTypeLabels[projectType],
      })),
    [projectTypeLabels]
  );

  return { projectTypeLabels, projectTypeOptions };
}
