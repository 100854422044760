import { gql, useApolloClient } from "@apollo/client";
import { LabeledValue, Modal } from "@msys/ui";
import { Alert, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { Props2 } from "../../../../clients/graphqlTypes.js";
import { useRenameProps2KeyMutation } from "./PropertyKeyEditModal.generated.js";

interface FormValues {
  propertyKey: string;
  newPropertyKey: string;
}

interface Props {
  projectId: string | null;
  docId: string;
  itemId: string;
  property: Props2;
  handleClose: () => void;
  handleComplete?: (handleClose: () => void) => Promise<void>;
}

export function PropertyKeyEditModal({
  projectId,
  docId,
  itemId,
  property,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["Global", "QuoteItem"]);

  const [error, setError] = React.useState<Error | null>(null);

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues: FormValues = {
    propertyKey: property.key,
    newPropertyKey: "",
  };

  const validationSchema = Yup.object().shape({
    propertyKey: Yup.string().required(),
    newPropertyKey: Yup.string().required(),
  });

  const client = useApolloClient();
  const [renameProps2Key, { loading }] = useRenameProps2KeyMutation({ client });

  const handleSubmit = async (values: FormValues) => {
    try {
      await renameProps2Key({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            oldKey: values.propertyKey,
            newKey: values.newPropertyKey,
          },
        },
      });

      if (handleComplete) {
        await handleComplete(handleClose);
      } else {
        handleClose();
      }
    } catch (err) {
      const error = err instanceof Error ? err : new Error(JSON.stringify(err));
      setError(error);
      throw error;
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Modal
          title={t("Edit property key", {
            ns: "QuoteItem",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
              },
            },
            {
              label: t("Confirm", {
                ns: "Global",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled:
                  !formikProps.dirty ||
                  !formikProps.isValid ||
                  formikProps.isSubmitting ||
                  loading,
              },
            },
          ]}
          isLoading={formikProps.isSubmitting}
        >
          <Form id={formId}>
            <Stack spacing={1}>
              {error && (
                <Alert severity="error" sx={{ alignSelf: "stretch" }}>
                  {error.message}
                </Alert>
              )}
              <Stack direction={"row"} spacing={1}>
                <LabeledValue
                  label={t("Current key", {
                    ns: "QuoteItem",
                  })}
                >
                  {formikProps.values.propertyKey}
                </LabeledValue>
                <Field
                  component={TextField}
                  name={"newPropertyKey"}
                  label={t("New key", {
                    ns: "QuoteItem",
                  })}
                />
              </Stack>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
