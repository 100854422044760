/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type DecisionContingencySwitch_ItemFragment = { __typename: 'Item', id: string, decisionContingentItemPreselection?: boolean | null };

export const DecisionContingencySwitch_ItemFragmentDoc = gql`
    fragment DecisionContingencySwitch_Item on Item {
  id
  decisionContingentItemPreselection
}
    `;