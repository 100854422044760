import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  Autocomplete,
  ellipsisStyle,
  ModalOpenProcess,
  ModalOpenProcessRef,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { debounce } from "lodash-es";
import React from "react";
import { CompanyLine } from "../../commons/DataItem.js";
import { highlightWithBold } from "../../utils.js";
import { CrmCreateModal } from "../crm/CrmCreateModal.js";
import { CrmContactType } from "../crm/useCrmContactTypes.js";
import { UserAvatar } from "../users/UserAvatar.js";
import {
  CrmCompanyOrPersonSelect_CrmPersonFragment,
  useCrmCompanyOrPersonSelectLazyQuery,
} from "./CrmCompanyOrPersonSelect.generated.js";
import { CrmCompanyListItem } from "./CrmCompanySelect.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission.js";

const DEFAULT_ALLOWED_CONTACT_TYPES: CrmContactType[] = [
  "INDIVIDUAL",
  "COMPANY",
];

interface Props {
  crmCompanyOrPersonId: string | undefined | null;
  crmCompanyOrPersonExcludeIds: string[] | undefined | null;
  onChange: (crmCompanyOrPersonId: string | null) => void;
  inputLabel?: string;
  required?: boolean;
  canCreateNew?: boolean;
  createModalTitle?: string;
  createNewLabel?: string;
  allowedContactTypesForCreate?: CrmContactType[];
  createModalPrefill?: React.ComponentProps<typeof CrmCreateModal>["prefill"];
  error?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const CrmCompanyOrPersonSelect = ({
  crmCompanyOrPersonId,
  crmCompanyOrPersonExcludeIds,
  onChange,
  inputLabel,
  required = false,
  canCreateNew = false,
  createModalTitle,
  allowedContactTypesForCreate = DEFAULT_ALLOWED_CONTACT_TYPES,
  createNewLabel,
  createModalPrefill,
  error,
  disabled,
  placeholder,
}: Props) => {
  const { t } = useTranslate(["Global", "CrmUsers"]);

  const [inputValue, setInputValue] = React.useState("");

  const { highlightText, contacts, debouncedRefetch, loading, totalCount } =
    useCrmCompaniesOrPersons(
      crmCompanyOrPersonId,
      crmCompanyOrPersonExcludeIds,
      inputValue,
      setInputValue
    );

  // unselect if excluded contact is selected
  const excludeIdsAsString = crmCompanyOrPersonExcludeIds?.sort().join("-");
  const onChangeRef = React.useRef(onChange);
  onChangeRef.current = onChange;
  React.useEffect(() => {
    if (
      crmCompanyOrPersonId &&
      crmCompanyOrPersonExcludeIds?.includes(crmCompanyOrPersonId)
    ) {
      setInputValue("");
      onChangeRef.current?.(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeIdsAsString, crmCompanyOrPersonId, setInputValue]);

  const processRef = React.useRef<ModalOpenProcessRef>();

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Autocomplete
          placeholder={placeholder ?? t("Type to search", { ns: "Global" })}
          options={[
            ...contacts,
            ...(canCreateNew && createNewLabel && !crmCompanyOrPersonId
              ? [
                  {
                    title: createNewLabel,
                    id: "__create_new__" as const,
                  },
                ]
              : []),
            ...(totalCount > contacts.length
              ? [
                  {
                    title: t("Type to see more...", { ns: "Global" }),
                    id: "__type_to_see_more__" as const,
                  },
                ]
              : []),
          ]}
          getOptionDisabled={option => option.id === "__type_to_see_more__"}
          getOptionLabel={option =>
            "__typename" in option
              ? option.__typename === "CrmCompaniesRecord"
                ? option.title
                : option.fullname
              : option.title
          }
          renderOption={(props, option) =>
            "__typename" in option ? (
              option.__typename === "CrmCompaniesRecord" ? (
                <CrmCompanyListItem
                  {...props}
                  key={option.id}
                  option={option}
                  highlightText={highlightText}
                  showAvatar
                />
              ) : (
                <CrmPersonListItem
                  {...props}
                  key={option.id}
                  option={option}
                  highlightText={highlightText}
                  showAvatar
                />
              )
            ) : option.id === "__create_new__" ? (
              <li
                {...props}
                key={option.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AddIcon sx={{ mr: 0.5 }} color="secondary" />
                <Typography fontWeight="500" color="textSecondary">
                  {option.title}
                </Typography>
              </li>
            ) : (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            )
          }
          inputLabel={
            inputLabel ??
            t("Select existing contact", {
              ns: "CrmUsers",
            })
          }
          inputValue={inputValue}
          onInputChange={(_, value, reason) => {
            if (reason === "input") {
              setInputValue(value);
              debouncedRefetch(value);
              if (crmCompanyOrPersonId) onChange(null);
            }
          }}
          required={required}
          value={contacts.find(c => c.id === crmCompanyOrPersonId) ?? null}
          onChange={value => {
            if (value && "__typename" in value) {
              setInputValue(
                value?.__typename === "CrmCompaniesRecord"
                  ? value?.title
                  : value?.fullname
              );
              onChange(value.id);
            } else if (value && value.id === "__create_new__") {
              processRef.current?.open();
            } else if (!value) {
              setInputValue("");
              onChange(null);
            }
          }}
          loading={loading}
          error={error}
          disabled={disabled}
        />
        {canCreateNew && !disabled && (
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_CRM">
            <IconButton
              color="primary"
              size="large"
              onClick={() => processRef.current?.open()}
              sx={{ alignSelf: "center" }}
            >
              <AddIcon />
            </IconButton>
          </RestrictedByOrganisationPermissionWithDebug>
        )}
      </Stack>
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_CRM">
        <ModalOpenProcess
          ref={processRef}
          Modal={CrmCreateModal}
          modalProps={{
            id: "create-crm-modal",
            title: createModalTitle,
            allowedContactTypes: allowedContactTypesForCreate,
            prefill: createModalPrefill,
            handleComplete: async (handleClose, organisation) => {
              setInputValue(organisation.title);
              onChange(organisation.id);
              handleClose();
            },
          }}
        />
      </RestrictedByOrganisationPermissionWithDebug>
    </>
  );
};

export function CrmPersonListItem({
  option,
  highlightText,
  showAvatar = false,
  ...props
}: React.HTMLAttributes<HTMLLIElement> & {
  option: CrmCompanyOrPersonSelect_CrmPersonFragment;
  highlightText: (text: string | null) => React.ReactNode;
  showAvatar?: boolean;
}) {
  return (
    <li {...props} key={option.id}>
      {showAvatar && (
        <UserAvatar
          size="s"
          userAvatar={option}
          style={{ marginRight: "12px" }}
        />
      )}
      <Stack direction="column" spacing={0.5} minWidth={0} flex={1}>
        <div>{highlightText(option.fullname)}</div>
        <div style={{ ...ellipsisStyle, alignSelf: "flex-start" }}>
          <CompanyLine>{highlightText(option.crmCompany.title)}</CompanyLine>
        </div>
      </Stack>
    </li>
  );
}

const LIMIT = 10;

function useCrmCompaniesOrPersons(
  crmCompanyOrPersonId: string | undefined | null,
  crmCompanyOrPersonExcludeIds: string[] | undefined | null,
  inputValue: string,
  setInputValue: (value: string) => void
) {
  const client = useApolloClient();

  const [fetchQuery, query] = useCrmCompanyOrPersonSelectLazyQuery({
    client,
    variables: { limit: LIMIT },
  });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const excludeIdsAsString = crmCompanyOrPersonExcludeIds?.sort().join("-");

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          searchTerm: value,
          filterIds: null,
          filterExcludeIds: crmCompanyOrPersonExcludeIds,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [excludeIdsAsString, fetchQuery]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (
        crmCompanyOrPersonId &&
        !crmCompanyOrPersonExcludeIds?.includes(crmCompanyOrPersonId)
      ) {
        const result = await fetchQuery({
          variables: {
            limit: 1,
            filterIds: [crmCompanyOrPersonId],
          },
        });
        setInputValue(
          getDataOrNull(result.data?.crmCompanies)?.edges?.[0]?.node.title ??
            getDataOrNull(result.data?.crmPersons)?.edges?.[0]?.node.fullname ??
            ""
        );
      } else {
        await fetchQuery({
          variables: {
            limit: LIMIT,
            searchTerm: inputValueRef.current,
            filterIds: null,
            filterExcludeIds: crmCompanyOrPersonExcludeIds,
          },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmCompanyOrPersonId, excludeIdsAsString, fetchQuery, setInputValue]);

  const crmCompanies =
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)?.edges.map(
      edge => edge.node
    ) ?? [];
  const crmPersons =
    getDataOrNull((query.data ?? query.previousData)?.crmPersons)?.edges.map(
      edge => edge.node
    ) ?? [];
  const totalCount =
    (getDataOrNull((query.data ?? query.previousData)?.crmCompanies)
      ?.totalCount ?? 0) +
    (getDataOrNull((query.data ?? query.previousData)?.crmPersons)
      ?.totalCount ?? 0);

  const highlightText = React.useCallback(
    (text: string | null) =>
      text && query?.variables?.searchTerm
        ? highlightWithBold(text, query.variables.searchTerm)
        : text,
    [query?.variables?.searchTerm]
  );

  const contacts = [...crmCompanies, ...crmPersons].sort((a, b) => {
    const aName = a.__typename === "CrmCompaniesRecord" ? a.title : a.firstname;
    const bName = b.__typename === "CrmCompaniesRecord" ? b.title : b.firstname;
    return aName.localeCompare(bName);
  });

  return {
    highlightText,
    contacts,
    refetch,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.searchTerm &&
          query?.variables?.searchTerm !== inputValue
      ),
    totalCount,
  };
}
