/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { TemplateQuoteWarnings_QuoteTemplateFragmentDoc } from './TemplateQuoteWarnings.generated.js';
import { TemplateQuoteStatuses_QuoteTemplateFragmentDoc } from './TemplateQuoteStatuses.generated.js';
import { TemplatesQuoteStatus_TemplateFragmentDoc } from './TemplateQuoteStatus.generated.js';
import { TemplateQuoteTemplateMenu_QuoteTemplateFragmentDoc } from './TemplatesQuoteTemplateMenu.generated.js';
export type TemplatesQuoteTemplateSubHeader_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, title: string, has3dRoomSnapshot: boolean, resolvedAsReadModelVersionNumber?: number | null, latestPublishedVersionNumber?: number | null, containsUnpublishedChanged: boolean, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, accessType: Types.TemplateAccessType, sharedPublicly: boolean, usedInOtherTemplates: boolean, addedToShop: boolean, isBinding: boolean, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null, sharedWithOrganisations: Array<{ __typename: 'Company', id: string }> };

export type TemplatesQuoteTemplateSubHeader_QuoteTemplateItemFragment = { __typename: 'Item', id: string, type: Types.ItemType };

export const TemplatesQuoteTemplateSubHeader_QuoteTemplateFragmentDoc = gql`
    fragment TemplatesQuoteTemplateSubHeader_QuoteTemplate on QuoteTemplate {
  id
  title
  has3dRoomSnapshot
  ...TemplateQuoteWarnings_QuoteTemplate
  ...TemplateQuoteStatuses_QuoteTemplate
  ...TemplatesQuoteStatus_Template
  ...TemplateQuoteTemplateMenu_QuoteTemplate
}
    ${TemplateQuoteWarnings_QuoteTemplateFragmentDoc}
${TemplateQuoteStatuses_QuoteTemplateFragmentDoc}
${TemplatesQuoteStatus_TemplateFragmentDoc}
${TemplateQuoteTemplateMenu_QuoteTemplateFragmentDoc}`;
export const TemplatesQuoteTemplateSubHeader_QuoteTemplateItemFragmentDoc = gql`
    fragment TemplatesQuoteTemplateSubHeader_QuoteTemplateItem on Item {
  id
  type
}
    `;