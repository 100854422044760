import {
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TypographyProps,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { px } from "../../common/MuiThemeProvider.js";
import { Stack } from "./layout/Stack.js";

// MUI has split ListItem and ListItemButton due to difficulties of typying
// We need to also split it to get type safetyness.

export interface ProfileListItemProps
  extends Omit<React.ComponentProps<typeof ListItem>, "button" | "component"> {
  /**
   * List item size
   */
  size?: "m" | "s";
  /**
   * Avatar element
   */
  avatar?: JSX.Element;
  /**
   * Badge element
   */
  badge?: JSX.Element;
  /**
   * Category element
   */
  category?: JSX.Element;
  /**
   * Primary line
   */
  primary: React.ReactNode;
  /**
   * Secondary line
   */
  secondary?: React.ReactNode;
  /**
   * Whether item is not active
   */
  isNotActive?: boolean;
  /**
   * Right button element
   */
  RightButton?: JSX.Element;
  /**
   * Action button element
   */
  ActionButton?: JSX.Element;
  /**
   * Action button elements array
   */
  ActionButtons?: JSX.Element[];
  /**
   * Primary typography props
   */
  primaryTypographyProps?: TypographyProps;
  /**
   * Secondary typography props
   */
  secondaryTypographyProps?: TypographyProps;

  // FIXME
  button?: boolean;
  component?: typeof Link;
  to?: string;
}

export function ProfileListItem({
  size = "m",
  avatar,
  badge,
  category,
  isNotActive = false,
  primary,
  secondary,
  primaryTypographyProps,
  secondaryTypographyProps,
  RightButton,
  ActionButton,
  ActionButtons,
  className,
  button,
  component,
  children,
  ...props
}: ProfileListItemProps) {
  const hasInfo = !!(badge || category || RightButton);
  const hasAction = !!(ActionButton || ActionButtons?.length);
  const actionCount = hasAction ? (ActionButtons?.length ?? 1) : 0;
  const actionsPadding = 56 + (actionCount - 1) * 48;

  return (
    <ListItem
      classes={{ container: className }}
      // @ts-ignore FIXME
      button={button}
      // @ts-ignore FIXME
      component={component}
      sx={{
        "&.MuiListItem-secondaryAction": {
          paddingRight: `${actionsPadding}px`,
        },
      }}
      {...props}
    >
      {avatar ? (
        <ListItemAvatar
          sx={
            size === "m"
              ? theme => ({ minWidth: theme.layout.listItemMinWidth.lg })
              : size === "s"
                ? theme => ({ minWidth: theme.layout.listItemMinWidth.sm })
                : undefined
          }
        >
          {avatar}
        </ListItemAvatar>
      ) : null}
      <ListItemText
        style={isNotActive ? { opacity: 0.4 } : undefined}
        primary={primary}
        secondary={size === "m" ? secondary : undefined}
        // @ts-ignore ignore incompatibility
        primaryTypographyProps={{
          ...(primaryTypographyProps ?? {}),
          style: {
            display: "-webkit-box",
            overflow: "hidden",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            maxHeight: 48,
            ...(primaryTypographyProps?.style ?? {}),
          },
        }}
        // @ts-ignore ignore incompatibility
        secondaryTypographyProps={{
          ...(secondaryTypographyProps ?? {}),
          style: {
            display: "-webkit-box",
            overflow: "hidden",
            //WebkitLineClamp: size === "m" && number ? 3 : 2,
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            maxHeight: 40,
            ...(secondaryTypographyProps?.style ?? {}),
          },
        }}
      />
      {hasInfo && (
        <Stack
          spacing={1 / 2}
          alignItems="center"
          style={{ pointerEvents: "none", marginLeft: px.s }}
        >
          {badge ? (
            <span style={isNotActive ? { opacity: 0.6 } : undefined}>
              {badge}
            </span>
          ) : null}
          {category ? (
            <span style={isNotActive ? { opacity: 0.6 } : undefined}>
              {category}
            </span>
          ) : null}
          {RightButton ? (
            <span
              style={{ pointerEvents: "all" }}
              onMouseDown={e => {
                e.stopPropagation();
              }}
              onTouchStart={e => {
                e.stopPropagation();
              }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {RightButton}
            </span>
          ) : null}
        </Stack>
      )}
      {hasAction ? (
        <ListItemSecondaryAction sx={{ right: "8px" }}>
          {ActionButton}
          {ActionButtons?.length ? (
            <Stack spacing={1 / 2} alignItems="center">
              {ActionButtons}
            </Stack>
          ) : null}
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
}
