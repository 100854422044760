/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmPersonSelectQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  filterIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterCrmCompanyId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CrmPersonSelectQuery = { crmPersons: { __typename: 'CrmPersonConnection', totalCount: number, edges: Array<{ __typename: 'CrmPersonEdge', node: { __typename: 'CrmPerson', id: string, fullname: string } }> } | { __typename: 'MissingPermissions' } };

export type CrmPersonSelect_CrmPersonFragment = { __typename: 'CrmPerson', id: string, fullname: string };

export const CrmPersonSelect_CrmPersonFragmentDoc = gql`
    fragment CrmPersonSelect_CrmPerson on CrmPerson {
  id
  fullname
}
    `;
export const CrmPersonSelectDocument = gql`
    query CrmPersonSelect($limit: Int!, $filterIds: [ID!], $filterCrmCompanyId: ID, $searchTerm: String) {
  crmPersons(
    limit: $limit
    filters: {ids: $filterIds, crmCompanyId: $filterCrmCompanyId}
    search: $searchTerm
  ) {
    ... on CrmPersonConnection {
      edges {
        node {
          id
          ...CrmPersonSelect_CrmPerson
        }
      }
      totalCount
    }
  }
}
    ${CrmPersonSelect_CrmPersonFragmentDoc}`;

/**
 * __useCrmPersonSelectQuery__
 *
 * To run a query within a React component, call `useCrmPersonSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmPersonSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmPersonSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filterIds: // value for 'filterIds'
 *      filterCrmCompanyId: // value for 'filterCrmCompanyId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCrmPersonSelectQuery(baseOptions: Apollo.QueryHookOptions<CrmPersonSelectQuery, CrmPersonSelectQueryVariables> & ({ variables: CrmPersonSelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmPersonSelectQuery, CrmPersonSelectQueryVariables>(CrmPersonSelectDocument, options);
      }
export function useCrmPersonSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmPersonSelectQuery, CrmPersonSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmPersonSelectQuery, CrmPersonSelectQueryVariables>(CrmPersonSelectDocument, options);
        }
export function useCrmPersonSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CrmPersonSelectQuery, CrmPersonSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmPersonSelectQuery, CrmPersonSelectQueryVariables>(CrmPersonSelectDocument, options);
        }
export type CrmPersonSelectQueryHookResult = ReturnType<typeof useCrmPersonSelectQuery>;
export type CrmPersonSelectLazyQueryHookResult = ReturnType<typeof useCrmPersonSelectLazyQuery>;
export type CrmPersonSelectSuspenseQueryHookResult = ReturnType<typeof useCrmPersonSelectSuspenseQuery>;
export type CrmPersonSelectQueryResult = Apollo.QueryResult<CrmPersonSelectQuery, CrmPersonSelectQueryVariables>;