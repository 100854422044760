/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateListOverviewSubHeader__TemplateListFragment = { __typename: 'TemplateList', id: string, title: string, isDefault: boolean };

export type ModifyTemplateList_OverviewSubHeaderMutationVariables = Types.Exact<{
  input: Types.ModifyTemplateListInput;
}>;


export type ModifyTemplateList_OverviewSubHeaderMutation = { modifyTemplateList: { __typename: 'ModifyTemplateListResult', templateList: { __typename: 'TemplateList', id: string, title: string, isDefault: boolean } } };

export const TemplateListOverviewSubHeader__TemplateListFragmentDoc = gql`
    fragment TemplateListOverviewSubHeader__TemplateList on TemplateList {
  id
  title
  isDefault
}
    `;
export const ModifyTemplateList_OverviewSubHeaderDocument = gql`
    mutation ModifyTemplateList_OverviewSubHeader($input: ModifyTemplateListInput!) {
  modifyTemplateList(input: $input) {
    templateList {
      ...TemplateListOverviewSubHeader__TemplateList
    }
  }
}
    ${TemplateListOverviewSubHeader__TemplateListFragmentDoc}`;
export type ModifyTemplateList_OverviewSubHeaderMutationFn = Apollo.MutationFunction<ModifyTemplateList_OverviewSubHeaderMutation, ModifyTemplateList_OverviewSubHeaderMutationVariables>;

/**
 * __useModifyTemplateList_OverviewSubHeaderMutation__
 *
 * To run a mutation, you first call `useModifyTemplateList_OverviewSubHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyTemplateList_OverviewSubHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyTemplateListOverviewSubHeaderMutation, { data, loading, error }] = useModifyTemplateList_OverviewSubHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyTemplateList_OverviewSubHeaderMutation(baseOptions?: Apollo.MutationHookOptions<ModifyTemplateList_OverviewSubHeaderMutation, ModifyTemplateList_OverviewSubHeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyTemplateList_OverviewSubHeaderMutation, ModifyTemplateList_OverviewSubHeaderMutationVariables>(ModifyTemplateList_OverviewSubHeaderDocument, options);
      }
export type ModifyTemplateList_OverviewSubHeaderMutationHookResult = ReturnType<typeof useModifyTemplateList_OverviewSubHeaderMutation>;
export type ModifyTemplateList_OverviewSubHeaderMutationResult = Apollo.MutationResult<ModifyTemplateList_OverviewSubHeaderMutation>;
export type ModifyTemplateList_OverviewSubHeaderMutationOptions = Apollo.BaseMutationOptions<ModifyTemplateList_OverviewSubHeaderMutation, ModifyTemplateList_OverviewSubHeaderMutationVariables>;