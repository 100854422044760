/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { RequestViewButtonFragmentDoc } from '../requests/buttons/RequestViewButton.generated.js';
import { RequirementSendViaEmailModal_RequirementFragmentDoc, RequirementSendViaEmailModal_ProjectFragmentDoc } from './RequirementSendViaEmailModal.generated.js';
export type RequirementAction_RequirementFragment = { __typename: 'Requirement', id: string, viewerPermissions: Array<Types.PermissionName>, owningSystemOrganisationId: string, requirementRequestWonId?: string | null, outgoingRequests: Array<{ __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string }> };

export type RequirementAction_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName> };

export const RequirementAction_RequirementFragmentDoc = gql`
    fragment RequirementAction_Requirement on Requirement {
  id
  viewerPermissions
  owningSystemOrganisationId
  requirementRequestWonId
  outgoingRequests {
    id
  }
  ...RequestViewButton
  ...RequirementSendViaEmailModal_Requirement
}
    ${RequestViewButtonFragmentDoc}
${RequirementSendViaEmailModal_RequirementFragmentDoc}`;
export const RequirementAction_ProjectFragmentDoc = gql`
    fragment RequirementAction_Project on Project {
  id
  viewerPermissions
  ...RequirementSendViaEmailModal_Project
}
    ${RequirementSendViaEmailModal_ProjectFragmentDoc}`;