/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateIdsConnectOrderMutationVariables = Types.Exact<{
  input: Types.CreateIdsConnectOrderInput;
}>;


export type CreateIdsConnectOrderMutation = { createIdsConnectOrder: { __typename: 'CreateIdsConnectOrderResult', url: string, data: any } };


export const CreateIdsConnectOrderDocument = gql`
    mutation CreateIdsConnectOrder($input: CreateIdsConnectOrderInput!) {
  createIdsConnectOrder(input: $input) {
    url
    data
  }
}
    `;
export type CreateIdsConnectOrderMutationFn = Apollo.MutationFunction<CreateIdsConnectOrderMutation, CreateIdsConnectOrderMutationVariables>;

/**
 * __useCreateIdsConnectOrderMutation__
 *
 * To run a mutation, you first call `useCreateIdsConnectOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIdsConnectOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIdsConnectOrderMutation, { data, loading, error }] = useCreateIdsConnectOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIdsConnectOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateIdsConnectOrderMutation, CreateIdsConnectOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIdsConnectOrderMutation, CreateIdsConnectOrderMutationVariables>(CreateIdsConnectOrderDocument, options);
      }
export type CreateIdsConnectOrderMutationHookResult = ReturnType<typeof useCreateIdsConnectOrderMutation>;
export type CreateIdsConnectOrderMutationResult = Apollo.MutationResult<CreateIdsConnectOrderMutation>;
export type CreateIdsConnectOrderMutationOptions = Apollo.BaseMutationOptions<CreateIdsConnectOrderMutation, CreateIdsConnectOrderMutationVariables>;