import { gql, useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { Send as SendIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal.js";
import { usePublishRequestMutation } from "../Requests.generated.js";
import { RequestPublishButtonFragment } from "./RequestPublishButton.generated.js";
import { useTranslate } from "@tolgee/react";

interface Props {
  projectId: string;
  request: RequestPublishButtonFragment;
  refetchQueries?: string[];
}

export const RequestPublishButton = ({
  projectId,
  request,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate("Requests");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [publishRequest, { loading }] = usePublishRequestMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const handlePublishRequest = async () => {
    try {
      await publishRequest({
        variables: {
          input: { requestId: request.id, projectId },
        },
      });
      enqueueSnackbar(t("Request published"));
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const isDisabled =
    !request.marketplace && request.viewerNumActiveRecipients === 0;

  return (
    <Tooltip
      title={
        isDisabled
          ? t(
              "Add at least one bidder OR make the request visible on marketplace"
            )
          : ""
      }
    >
      {/* this span is needed for MUI Tooltip to receive pointer events. */}
      <span>
        <ModalOpenButton
          Modal={ConfirmModal}
          modalProps={{
            id: "request-publish-confirm-modal",
            title: t("Confirm publishing"),
            confirmButtonLabel: t("Publish request"),
            text: t(
              "Project information and bidding details will not be editable once the request is published. You will still be able to add and manage bidders."
            ),
            handleConfirm: handlePublishRequest,
          }}
        >
          <LoadingButton
            startIcon={<SendIcon />}
            variant="contained"
            color="success"
            loading={loading}
            disabled={isDisabled}
          >
            {t("Publish request")}
          </LoadingButton>
        </ModalOpenButton>
      </span>
    </Tooltip>
  );
};
