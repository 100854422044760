/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDraftInvoiceMutationVariables = Types.Exact<{
  input: Types.DeleteInvoiceInput;
}>;


export type DeleteDraftInvoiceMutation = { deleteInvoice: { __typename: 'DeleteInvoiceResult', invoiceId: string } };


export const DeleteDraftInvoiceDocument = gql`
    mutation DeleteDraftInvoice($input: DeleteInvoiceInput!) {
  deleteInvoice(input: $input) {
    invoiceId
  }
}
    `;
export type DeleteDraftInvoiceMutationFn = Apollo.MutationFunction<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;

/**
 * __useDeleteDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftInvoiceMutation, { data, loading, error }] = useDeleteDraftInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>(DeleteDraftInvoiceDocument, options);
      }
export type DeleteDraftInvoiceMutationHookResult = ReturnType<typeof useDeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationResult = Apollo.MutationResult<DeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;