import { Add as AddIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { CreateQuoteButton } from "./CreateQuoteButton.js";

export const CreateQuoteFromRequirementButton = ({
  requestId,
  docId,
  docProjectId,
  pathToProject,
}: {
  requestId?: string;
  docId: string;
  docProjectId: string;
  pathToProject: string;
}) => {
  const { t } = useTranslate("QuoteCreate");

  return (
    <CreateQuoteButton
      projectId={docProjectId}
      pathToProject={pathToProject}
      requirementId={docId}
      requestId={requestId}
      Button={
        <Button color="secondary" variant="contained" startIcon={<AddIcon />}>
          {t("New Quote")}
        </Button>
      }
    />
  );
};
