import React from "react";
import { NavigateOptions } from "react-router-dom";
import { useLatest } from "react-use";
import { useUrlSearchParams } from "./useUrlSearchParams.js";

// Could be used for String type only
export const useStateWithUrlParam = <T extends string | undefined | null>(
  paramName: string,
  initialState: T,
  toRemoveParams:
    | string[]
    | true
    | null
    | ((key: string, value: string) => boolean) = null,
  navigateOptions: NavigateOptions = { replace: true }
): readonly [state: T, setState: React.Dispatch<React.SetStateAction<T>>] => {
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();

  const latestToRemoveParams = useLatest(toRemoveParams);
  const latestInitialState = useLatest(initialState);
  const latestNavigateOptions = useLatest(navigateOptions);

  // set state directly to url search parameters
  const setStateToUrl = React.useCallback(
    (state: T) => {
      setUrlSearchParams(
        { [paramName]: state },
        latestToRemoveParams.current ?? undefined,
        latestNavigateOptions.current
      );
    },
    [paramName, setUrlSearchParams, latestToRemoveParams, latestNavigateOptions]
  );

  // get state directly from url search parameters
  const state = React.useMemo(
    () =>
      urlSearchParams.has(paramName)
        ? (urlSearchParams.get(paramName) as string as T)
        : latestInitialState.current,
    [urlSearchParams, paramName, latestInitialState]
  );

  const latestState = useLatest(state);

  const setState = React.useCallback(
    (stateOrFunction: T | ((state: T) => T)): void => {
      if (stateOrFunction instanceof Function) {
        setStateToUrl(stateOrFunction(latestState.current));
      } else {
        setStateToUrl(stateOrFunction);
      }
    },
    [setStateToUrl, latestState]
  );

  return [state, setState];
};
