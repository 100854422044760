import { LabeledTextValue, LabeledValue, TagChips } from "@msys/ui";
import { Divider, Link as MuiLink, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { LabeledPhoneValue } from "../phones/LabeledPhoneValue.js";
import { CrmPersonInformationForm_CrmPersonFragment } from "./CrmPersonInformationForm.generated.js";

interface Props {
  crmPerson: CrmPersonInformationForm_CrmPersonFragment;
}

export const CrmPersonInformationData = ({ crmPerson }: Props) => {
  const { t } = useTranslate(["UserProfile", "Global"]);
  return (
    <Stack direction="column" spacing={1}>
      <LabeledValue label={t("Email", { ns: "UserProfile" })}>
        {crmPerson.email ? (
          <MuiLink href={`mailto:${crmPerson.email}`}>
            {crmPerson.email}
          </MuiLink>
        ) : (
          t("Not set", { ns: "Global" })
        )}
      </LabeledValue>
      <LabeledValue
        label={t("Job title", {
          ns: "UserProfile",
        })}
      >
        {crmPerson.jobTitle || t("Not set", { ns: "Global" })}
      </LabeledValue>
      <LabeledPhoneValue
        label={t("Phone", { ns: "Global" })}
        phones={crmPerson.phones}
      />
      <Divider />
      <LabeledTextValue
        label={t("Notes", { ns: "UserProfile" })}
        text={crmPerson.notices}
        notSetLabel={t("Not set", { ns: "Global" })}
        showMoreLabel={t("Show more", { ns: "Global" })}
        showLessLabel={t("Show less", { ns: "Global" })}
      />
      <LabeledValue label={t("Tags", { ns: "UserProfile" })}>
        {crmPerson.customTags.length > 0 ? (
          <TagChips tags={crmPerson.customTags} />
        ) : (
          "–"
        )}
      </LabeledValue>
    </Stack>
  );
};
