import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer } from "@msys/ui";
import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink, Navigate, useParams } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission.js";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageGrid } from "../../commons/layout/PageGrid.js";
import { Attachment } from "../../features/attachments/helpers.js";
import { CrmPersonCustomFieldsBox } from "../../features/crm-persons/boxes/CrmPersonCustomFieldsBox.js";
import { CrmPersonPendingContracteeInvitationsBox } from "../../features/crm-persons/boxes/CrmPersonPendingContracteeInvitationsBox.js";
import { CrmPersonInformationData } from "../../features/crm-persons/CrmPersonInformationData.js";
import { CrmPersonInformationForm } from "../../features/crm-persons/CrmPersonInformationForm.js";
import { CrmEmailMessagesBox } from "../../features/crm/CrmEmailMessagesBox.js";
import { TodosBox } from "../../features/todos/TodosBox.js";
import { UserAvatar } from "../../features/users/UserAvatar.js";
import {
  useCrmPersonProfileQuery,
  useModifyCrmUserAvatarMutation,
} from "./CrmPersonProfile.generated.js";

interface Props {
  submenuItems: PageTopbarItem[];
}

export function CrmPersonProfile({ submenuItems }: Props) {
  const { userId, organisationId } = useParams();
  if (!userId) throw new Error("User id is missing");

  const client = useApolloClient();
  const query = useCrmPersonProfileQuery({
    client,
    variables: { userId },
  });

  const [modifyCrmUserAvatar, { loading: modifyCrmUserLoading }] =
    useModifyCrmUserAvatarMutation({
      client,
      refetchQueries: [namedOperations.Query.CrmPersonProfile],
    });

  async function onAttachment(attachment: Attachment | null) {
    await modifyCrmUserAvatar({
      variables: {
        users: [
          {
            id: userId!, // FIXME: why can't typescript infer that userId is not undefined here?
            avatar: attachment
              ? {
                  url: attachment.url,
                  title: attachment.title,
                  mimeType: attachment.mimeType,
                }
              : null,
          },
        ],
      },
    });
  }

  const crmPerson = getDataOrNull(query.data?.crmPerson)?.crmPerson;

  if (crmPerson && !organisationId) {
    // Redirecting to the crm user's profile specific to the crm organisation
    return (
      <Navigate
        to={`/crm/organisations/${crmPerson.crmCompany.id}/users/${crmPerson.id}`}
        replace
      />
    );
  }

  return (
    <Page
      title={crmPerson ? `${crmPerson.firstname} ${crmPerson.familyname}` : ""}
      submenuItems={submenuItems}
    >
      {crmPerson && (
        <PageContainer>
          <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
            <>
              <CardContainer>
                <Stack direction="column" spacing={1} p={1}>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    mb={1}
                  >
                    <RestrictedByOrganisationPermissionWithDebug
                      permission="MANAGE_CRM"
                      otherwise={<UserAvatar userAvatar={crmPerson} size="l" />}
                    >
                      <UserAvatar
                        userAvatar={crmPerson}
                        size="l"
                        onAttachment={onAttachment}
                        disabled={modifyCrmUserLoading}
                      />
                    </RestrictedByOrganisationPermissionWithDebug>
                  </Stack>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="h2" align="center">
                      {crmPerson.firstname} {crmPerson.familyname}
                    </Typography>
                    <Link
                      component={RouterLink}
                      to={`/crm/organisations/${crmPerson.crmCompany.id}`}
                      sx={{ alignSelf: "center" }}
                    >
                      {`${crmPerson.crmCompany.title}`}
                    </Link>
                    {/* <Typography
                      variant="body2"
                      align="center"
                      color="primary"
                      gutterBottom
                    >
                      <EntityNumber number={user.number} />
                    </Typography> */}
                  </Stack>
                  <RestrictedByOrganisationPermissionWithDebug
                    permission="MANAGE_CRM"
                    otherwise={
                      <CrmPersonInformationData crmPerson={crmPerson} />
                    }
                  >
                    <CrmPersonInformationForm crmPerson={crmPerson} />
                  </RestrictedByOrganisationPermissionWithDebug>
                </Stack>
              </CardContainer>

              <CrmPersonCustomFieldsBox
                crmPerson={crmPerson}
                onChangeCustomFieldsRefetchQueries={[
                  namedOperations.Query.CrmPersonProfile,
                ]}
              />
            </>
            <>
              <TodosBox linkedCrmUser={crmPerson} />
              <CrmEmailMessagesBox
                crmPerson={crmPerson}
                refetchQueries={[namedOperations.Query.CrmPersonProfile]}
              />
            </>
            <>
              <CrmPersonPendingContracteeInvitationsBox userId={crmPerson.id} />
            </>
          </PageGrid>
        </PageContainer>
      )}
    </Page>
  );
}
