import React from "react";
import { useApolloClient } from "@apollo/client";
import {
  useProjectAssigneeSelectLazyQuery,
  useSetProjectAssigneeMutation,
} from "../ProjectAssigneeSelect.generated.js";
import { useAddProjectMemberFromOrganisationMembersMutation } from "../modals/AddProjectMemberModal.generated.js";
import { useUserData } from "../../../auth/useUserData.js";
import { getDataOrNull } from "@msys/common";
import { Button, ButtonProps } from "@mui/material";

interface Props {
  projectId: string;
  refetchQueries?: string[];
  onAssigneeChanged?: (
    assigneeUserId: string | null,
    membershipId: string | null
  ) => void | Promise<void>;
  size?: ButtonProps["size"];
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
  disabled?: boolean;
  title: string;
}

export const ProjectAssignToMeButton = ({
  projectId,
  refetchQueries,
  onAssigneeChanged,
  size,
  color,
  variant,
  disabled,
  title,
}: Props) => {
  const { assignToMe, loading } = useAssignToMe({ projectId, refetchQueries });

  return (
    <Button
      size={size}
      color={color}
      variant={variant}
      onClick={async () => {
        const { assigneeUserId, membershipId } = await assignToMe();
        onAssigneeChanged?.(assigneeUserId, membershipId);
      }}
      disabled={disabled || loading}
    >
      {title}
    </Button>
  );
};

export function useAssignToMe({
  projectId: propProjectId,
  refetchQueries,
}: {
  projectId?: string;
  refetchQueries?: string[];
}) {
  const viewer = useUserData().currentUser!;

  const client = useApolloClient();

  const [fetchQuery, query] = useProjectAssigneeSelectLazyQuery({
    client,
    variables: { projectId: propProjectId! },
    fetchPolicy: "network-only",
  });

  const [setAssignee, { loading: setAssigneeLoading }] =
    useSetProjectAssigneeMutation({
      client,
    });
  const [addProjectMember, { loading: addProjectMemberLoading }] =
    useAddProjectMemberFromOrganisationMembersMutation({
      client,
    });

  const assignToMe = async (passedProjectId?: string) => {
    const projectId = passedProjectId || propProjectId;
    if (!projectId) throw new Error("No project id provided");

    const result = await fetchQuery({ variables: { projectId } });
    const projectMembers =
      getDataOrNull(result.data?.project)?.project?.internalStakeholders ?? [];
    const projectRoles =
      getDataOrNull(result.data?.project)?.project?.roles ?? [];
    const activeMembers = result.data?.organisationMemberships ?? [];

    let membershipId =
      projectMembers.find(m => m.user.id === viewer.id)?.id ?? null;

    if (!membershipId) {
      const user = activeMembers.find(u => u.id === viewer.id);
      if (!user) throw new Error("Cannot find user");
      let userRoleIds = user.defaultProjectRoles.map(r => r.id);
      if (!userRoleIds.length) {
        userRoleIds = projectRoles
          .filter(r => r.internalName === "PROJECT_MEMBER")
          .map(r => r.id);
      }
      const result = await addProjectMember({
        variables: {
          projectId: projectId,
          userId: user.id,
          roleIds: userRoleIds,
        },
      });
      membershipId =
        result.data?.addProjectMemberFromOrganisationMembers.project.internalStakeholders.find(
          m => m.user.id === user.id
        )?.id ?? null;
    }
    if (!membershipId) throw new Error("Cannot add member to project");

    await setAssignee({
      variables: {
        projectId,
        membershipId,
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });

    return { assigneeUserId: viewer.id, membershipId };
  };

  return {
    assignToMe,
    loading: setAssigneeLoading || addProjectMemberLoading || query.loading,
  };
}
