import { useApolloClient } from "@apollo/client";
import { CardContainer, ModalOpenButton } from "@msys/ui";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Button, Divider, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { omit, uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";
import {
  BillOfMaterialsExportForm,
  FormValues,
} from "../../bill-of-materials/BillOfMaterialsExportForm.js";
import {
  OrganisationBillOfMaterialsSettingsBox_SettingsFragment,
  useOrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation,
} from "./OrganisationBillOfMaterialsSettingsBox.generated.js";
import { ModifyOrganisationBillOfMaterialsTextsModal } from "../modals/ModifyOrganisationBillOfMaterialsTextsModal.js";

interface Props {
  organisationId: string;
  organisationSettings: OrganisationBillOfMaterialsSettingsBox_SettingsFragment;
}

export function OrganisationBillOfMaterialsSettingsBox({
  organisationSettings,
  organisationId,
}: Props) {
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();
  const { t } = useTranslate("OrganisationSettings");
  const { enqueueSnackbar } = useSnackbar();

  const [modifyOrganisationSettings] =
    useOrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation(
      {
        client,
      }
    );

  const formId = React.useMemo(() => uniqueId(), []);

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyOrganisationSettings({
        variables: {
          input: {
            billOfMaterialsExportSettings: values,
          },
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <CardContainer isExpandable title={t("Material list settings")}>
      <Stack direction="column" p={1} spacing={1}>
        <div>
          <ModalOpenButton
            Modal={ModifyOrganisationBillOfMaterialsTextsModal}
            modalProps={{
              organisationId,
              title: t("Customize material list text"),
            }}
          >
            <Button
              type="button"
              color="secondary"
              variant="text"
              size="small"
              endIcon={<ChevronRightIcon />}
            >
              {t("Customize material list text")}
            </Button>
          </ModalOpenButton>
        </div>
        <Divider />
        <BillOfMaterialsExportForm
          autoSave={true}
          formId={formId}
          initialValues={omit(
            organisationSettings.billOfMaterialsExportSettings,
            "__typename"
          )}
          onSubmit={handleSubmit}
        />
      </Stack>
    </CardContainer>
  );
}
