import { useApolloClient } from "@apollo/client";
import { MenuItemWithIcon } from "@msys/ui";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useDuplicateQuoteMutation } from "./DuplicateQuoteButton.generated.js";

interface Props {
  pathToDocList: string;
  docId: string;
  projectId: string;
}

export function DuplicateQuoteButton({
  pathToDocList,
  docId,
  projectId,
}: Props) {
  const { t } = useTranslate("Quote");

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [duplicateQuote, { loading: duplicateLoading }] =
    useDuplicateQuoteMutation({ client });

  return (
    <MenuItemWithIcon
      icon={<FileCopyIcon />}
      onClick={async () => {
        try {
          const copyQuoteIntoNewQuoteResult = await duplicateQuote({
            variables: {
              input: {
                quoteId: docId,
                projectId: projectId,
              },
            },
          });

          navigate(
            `${pathToDocList}/${copyQuoteIntoNewQuoteResult.data?.copyQuoteIntoNewQuote.quote.id}`
          );
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, {
              variant: "error",
            });
        }
      }}
      disabled={duplicateLoading}
    >
      {t("Duplicate quote")}
    </MenuItemWithIcon>
  );
}
