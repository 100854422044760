import { gql, useApolloClient } from "@apollo/client";
import { ErrorMessage, useScreenWidth } from "@msys/ui";
import { Container } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { keyBy } from "lodash-es";
import React from "react";
import { PageColumn } from "../../commons/layout/PageColumn.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageFullScreenModal } from "../../commons/layout/PageFullScreenModal.js";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../trees-virtual/hooks/useExpandedStore.js";
import { InvoiceDraftPreview } from "./InvoiceDraftPreview.js";
import { InvoiceExtraItemDetailsModal } from "./InvoiceExtraItemDetailsModal.js";
import { InvoiceItemDetailsModal } from "./InvoiceItemDetailsModal.js";
import {
  InvoicePreviewWithSelectTreeItemsForm_InvoiceFragment,
  InvoicePreviewWithSelectTreeItemsForm_ProjectFragment,
  InvoicePreviewWithSelectTreeItemsForm_QuoteFragment,
  InvoicePreviewWithSelectTreeItemsForm_QuoteInvoiceCalculationFragment,
} from "./InvoicePreviewWithSelectTreeItemsForm.generated.js";
import { useModifyInvoiceMutation } from "./Invoices.generated.js";
import { InvoiceSelectTreeItemsForm } from "./InvoiceSelectTreeItemsForm.js";
import { InvoiceFormItem } from "./types.js";

const REFETCH_QUERIES = [
  namedOperations.Query.ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?;
];

export const InvoicePreviewWithSelectTreeItemsForm = ({
  project,
  invoice,
  quote,
  quoteInvoiceCalculation,
  docId,
  isPreviewOpen,
  setIsPreviewOpen,
}: {
  project: InvoicePreviewWithSelectTreeItemsForm_ProjectFragment;
  invoice: InvoicePreviewWithSelectTreeItemsForm_InvoiceFragment;
  quote: InvoicePreviewWithSelectTreeItemsForm_QuoteFragment;
  quoteInvoiceCalculation: InvoicePreviewWithSelectTreeItemsForm_QuoteInvoiceCalculationFragment;
  docId: string;
  isPreviewOpen: boolean;
  setIsPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslate("Invoices");

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);
  const { isMinDesktop } = useScreenWidth();
  const [selectedItemId, setSelectedItemId] = React.useState<string | null>(
    null
  );
  const [selectedExtraItemId, setSelectedExtraItemId] = React.useState<
    string | "NEW" | null
  >(null);

  const invoiceExtraItems = invoice.invoiceExtraItems;

  const selectedItemIds = React.useMemo<string[]>(
    () =>
      invoice.invoiceItems
        .filter(
          item =>
            item.item?.type === "paid" ||
            (item.item?.type === "section" &&
              item.item?.childrenVisibility === "HIDE_CHILDREN")
        )
        .map(item => item.item?.id) ?? [],
    [invoice.invoiceItems]
  );

  const expandedStore = useExpandedStoreWithLocalStorage("invoice", docId);

  const client = useApolloClient();
  const [modifyInvoice] = useModifyInvoiceMutation({ client });

  const handleSubmit = async ({ items }: { items: InvoiceFormItem[] }) => {
    await modifyInvoice({
      variables: {
        input: {
          projectId: project.id,
          docId: invoice.quote.id,
          invoiceId: invoice.id,
          values: {
            itemIds: items.map(e => e.id),
          },
        },
      },
    });
  };

  const allDocItems = quote.items.filter(
    item => item.type !== "defect" && item.type !== "unpaid"
  );

  const allDocItemsInvoiceCalculations = React.useMemo(
    () => quoteInvoiceCalculation?.itemCalculations ?? [],
    [quoteInvoiceCalculation?.itemCalculations]
  );

  const quoteItemsMap = React.useMemo(
    () => keyBy(allDocItems, item => item.id),
    [allDocItems]
  );

  const selectedItem = React.useMemo(
    () => allDocItems.find(e => e.id === selectedItemId),
    [allDocItems, selectedItemId]
  );

  const selectedItemInvoiceCalculation = React.useMemo(
    () =>
      allDocItemsInvoiceCalculations && selectedItemId
        ? (allDocItemsInvoiceCalculations.find(
            calculation => calculation && calculation?.itemId === selectedItemId
          )?.itemCalculation ?? null)
        : null,
    [allDocItemsInvoiceCalculations, selectedItemId]
  );

  const selectedExtraItem = React.useMemo(
    () => invoiceExtraItems.find(item => item.id === selectedExtraItemId),
    [invoiceExtraItems, selectedExtraItemId]
  );

  if (invoice.status !== "draft")
    return <ErrorMessage message="Invoice is not a draft!" />;

  return (
    <>
      <PageColumn
        $hasBackground
        $hasRightBorder
        $display="block"
        ref={setTreeContainer}
      >
        <InvoiceSelectTreeItemsForm
          docId={docId}
          docProject={project}
          allDocItems={allDocItems.map(item => {
            const calculationInvoice =
              allDocItemsInvoiceCalculations.find(
                calculation => calculation && calculation.itemId === item.id
              )?.itemCalculation ?? null;
            return {
              ...item,
              calculationInvoice,
            };
          })}
          invoice={invoice}
          invoiceExtraItems={invoiceExtraItems}
          selectedExtraItemId={selectedExtraItemId}
          setSelectedExtraItemId={setSelectedExtraItemId}
          quote={quote}
          selectedItemIds={selectedItemIds}
          selectedItemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
          onSubmit={handleSubmit}
          container={treeContainer}
          invoiceableQuoteItemIds={invoice.invoiceableQuoteItemIds}
          invoiceableInInvoiceQuoteItemIds={
            invoice.invoiceableInInvoiceQuoteItemIds
          }
          expandedStore={expandedStore}
        />
      </PageColumn>
      {isMinDesktop ? (
        <PageContainer>
          <Container maxWidth="md">
            <InvoiceDraftPreview
              project={project}
              invoice={invoice}
              quote={quote}
              quoteItemsMap={quoteItemsMap}
              setSelectedItemId={setSelectedItemId}
              setSelectedExtraItemId={setSelectedExtraItemId}
            />
          </Container>
        </PageContainer>
      ) : (
        isPreviewOpen && (
          <PageFullScreenModal
            title={t("Preview")}
            onCloseButtonClick={() => setIsPreviewOpen(false)}
          >
            <InvoiceDraftPreview
              project={project}
              invoice={invoice}
              quote={quote}
              quoteItemsMap={quoteItemsMap}
              setSelectedItemId={setSelectedItemId}
              setSelectedExtraItemId={setSelectedExtraItemId}
            />
          </PageFullScreenModal>
        )
      )}
      {selectedItem && (
        <InvoiceItemDetailsModal
          item={selectedItem}
          selectedItemInvoiceCalculation={selectedItemInvoiceCalculation}
          doc={quote}
          project={project}
          invoiceId={invoice.id}
          handleClose={() => setSelectedItemId(null)}
          invoiceableQuoteItemIds={invoice.invoiceableQuoteItemIds}
          invoiceableInInvoiceQuoteItemIds={
            invoice.invoiceableInInvoiceQuoteItemIds
          }
        />
      )}
      {selectedExtraItemId && (
        <InvoiceExtraItemDetailsModal
          invoice={invoice}
          projectId={project.id}
          docId={docId}
          extraItem={
            selectedExtraItemId === "NEW" ? undefined : selectedExtraItem
          }
          handleClose={() => {
            setSelectedExtraItemId(null);
          }}
          refetchQueries={REFETCH_QUERIES}
        />
      )}
    </>
  );
};
