/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type UserInformationBox_UserFragment = { __typename: 'User', id: string, jobTitle: string, email: any, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, phones: Array<{ __typename: 'Phone', id: string, type: Types.PhoneType, main: boolean, number: string }> };

export type UserInformationBox_ViewerFragment = { __typename: 'Viewer', id: string, jobTitle: string, email: any, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, phones: Array<{ __typename: 'Phone', id: string, type: Types.PhoneType, main: boolean, number: string }> };

export const UserInformationBox_UserFragmentDoc = gql`
    fragment UserInformationBox_User on User {
  id
  jobTitle
  email
  homeAddress {
    id
    streetLines1
    city
    postalCode
    countryCode
    location {
      lat
      lng
    }
  }
  phones {
    id
    type
    main
    number
  }
}
    `;
export const UserInformationBox_ViewerFragmentDoc = gql`
    fragment UserInformationBox_Viewer on Viewer {
  id
  jobTitle
  email
  homeAddress {
    id
    streetLines1
    city
    postalCode
    countryCode
    location {
      lat
      lng
    }
  }
  phones {
    id
    type
    main
    number
  }
}
    `;