import { gql, useApolloClient } from "@apollo/client";
import {
  ModalOpenProcess,
  ModalOpenProcessRef,
  Autocomplete,
  Modal,
} from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { selectLabelForBuilding } from "../helper.js";
import { BuildingCreateModal } from "./BuildingCreateModal.js";
import {
  BuildingSelectModal_BuildingFragment,
  BuildingSelectModal_ItemFragment,
  useBuildingSelectModalQuery,
} from "./BuildingSelectModal.generated.js";

interface Props {
  id?: string;
  title?: string;
  buildingOwningOrganisationId: string;
  hidePlacementSelect?: true;
  primaryActionLabel?: string;
  primaryActionDisabled?: boolean;
  canCreateBuilding?: boolean;
  preselectedBuildingId?: string | null | undefined;
  preselectedBuildingItemId?: string | null | undefined;
  handleClose: () => void;
  handleComplete: (
    building: Omit<BuildingSelectModal_BuildingFragment, "items">,
    buildingItem: BuildingSelectModal_ItemFragment,
    handleClose: () => void
  ) => Promise<void> | void;
}

export function BuildingSelectModal({
  id,
  title,
  buildingOwningOrganisationId,
  primaryActionLabel,
  primaryActionDisabled,
  canCreateBuilding = true,
  hidePlacementSelect,
  preselectedBuildingId,
  preselectedBuildingItemId,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["Buildings", "BuildingSelectBox", "Global"]);

  const [buildingId, setBuildingId] = React.useState(
    preselectedBuildingId ?? ""
  );
  const [buildingItemId, setBuildingItemId] = React.useState(
    preselectedBuildingItemId ?? ""
  );

  const client = useApolloClient();
  const query = useBuildingSelectModalQuery({
    client,
    variables: { owningOrganisationId: buildingOwningOrganisationId },
  });

  const buildings = query.data?.buildings.edges.map(edge => edge.node);

  const placements = React.useMemo(
    () => buildings?.find(b => b.id === buildingId)?.items ?? [],
    [buildingId, buildings]
  );

  const options =
    buildings?.sort((a, b) =>
      a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase())
    ) ?? [];

  const processRef = React.useRef<ModalOpenProcessRef>();

  return (
    <Modal
      id={id}
      title={title ?? t("Select building", { ns: "Buildings" })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label:
            primaryActionLabel ??
            t("Select", {
              ns: "Global",
            }),
          buttonProps: {
            disabled: !buildingItemId || query.loading || primaryActionDisabled,
            onClick: async () => {
              const building = buildings?.find(
                building => building.id === buildingId
              );
              const buildingItem = building?.items.find(
                item => item.id === buildingItemId
              );

              if (!building || !buildingItem)
                throw new Error("Building or building item missing");

              await handleComplete(building, buildingItem, handleClose);
            },
          },
        },
      ]}
      isLoading={query.loading}
    >
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Autocomplete
            key={buildings?.map(b => b.id).join(",")}
            inputLabel={t("Select an existing building", {
              ns: "BuildingSelectBox",
            })}
            options={[
              ...options,
              ...(canCreateBuilding && !buildingId
                ? [
                    {
                      title: t("Add new building", { ns: "BuildingSelectBox" }),
                      id: "__create_new__" as const,
                    },
                  ]
                : []),
            ]}
            getOptionLabel={option => selectLabelForBuilding(option)}
            value={buildings?.find(b => b.id === buildingId)}
            onChange={value => {
              if (value && "__typename" in value) {
                setBuildingId(value?.id ?? "");
                setBuildingItemId(value?.rootItem?.id ?? "");
              } else if (value && value.id === "__create_new__") {
                processRef.current?.open();
              } else if (!value) {
                setBuildingId("");
                setBuildingItemId("");
              }
            }}
            renderOption={(props, option) =>
              "__typename" in option ? (
                <li {...props} key={option.id}>
                  {selectLabelForBuilding(option)}
                </li>
              ) : option.id === "__create_new__" ? (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddIcon sx={{ mr: 0.5 }} color="secondary" />
                  <Typography fontWeight="500" color="textSecondary">
                    {option.title}
                  </Typography>
                </li>
              ) : null
            }
          />
          {canCreateBuilding && (
            <IconButton
              color="primary"
              size="large"
              onClick={() => processRef.current?.open()}
            >
              <AddIcon />
            </IconButton>
          )}
        </Stack>
        {!hidePlacementSelect && buildingId ? (
          <Autocomplete
            inputLabel={t("Placement", { ns: "BuildingSelectBox" })}
            value={
              placements.find(placement => placement.id === buildingItemId) ??
              null
            }
            required
            disabled={!buildingId || placements.length === 0}
            getOptionLabel={option =>
              `${option.pathForPdf ? `${option.pathForPdf} ` : ""}${
                option.title
              }`
            }
            options={placements}
            onChange={value => {
              const newPlacement = value;
              setBuildingItemId(
                placements.find(placement => placement.id === newPlacement?.id)
                  ?.id ?? ""
              );
            }}
          />
        ) : null}
        <ModalOpenProcess
          ref={processRef}
          Modal={BuildingCreateModal}
          modalProps={{
            id: "building-create-modal",
            buildingOwningOrganisationId,
            handleComplete: async (buildingId, buildingRootItemId) => {
              setBuildingId(buildingId);
              setBuildingItemId(buildingRootItemId);
            },
            refetchQueries: [namedOperations.Query.BuildingSelectModal],
          }}
        />
      </Stack>
    </Modal>
  );
}
