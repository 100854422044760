import { useField } from "formik";
import { CategoriesInput } from "./CategoriesInput.js";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

const CategoriesField = ({ name, label, required, disabled }: Props) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name);

  return (
    <CategoriesInput
      label={label}
      value={value}
      onChange={value => {
        setValue(value);
      }}
      required={required}
      disabled={disabled}
      touched={touched}
      error={error}
    />
  );
};

export default CategoriesField;
