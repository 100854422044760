import { LabeledValue } from "@msys/ui";
import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { get } from "lodash-es";
import moment from "moment";
import { FC } from "react";
import { useTranslate } from "@tolgee/react";
import { Stack } from "../layout/Stack.js";
import { CheckboxField } from "./CheckboxField.js";
import { TimePickerField } from "./TimePickerField.js";

interface Props {
  label?: string;
  name: string;
  disabled?: boolean;
}

export const OpeningHoursField: FC<Props> = ({ disabled, label, name }) => {
  const { t } = useTranslate("OpeningHoursField");
  const { values } = useFormikContext<Record<string, any>>();
  return (
    <LabeledValue label={label ?? t("Opening hours")}>
      <Stack alignItems="stretch" flexDirection="column" spacing={1 / 2}>
        {Array(7)
          .fill(null)
          .map((v, index) => {
            const value = get(values, name);
            const isOpen = value?.[index]?.isOpen ?? false;
            return (
              <Stack
                justifyContent="space-between"
                alignItems="center"
                key={index}
                spacing={1 / 2}
              >
                <Typography>
                  {moment()
                    .isoWeekday(index + 1)
                    .format("dddd")}
                </Typography>
                <Stack
                  justifyContent="flex-end"
                  alignItems="center"
                  key={index}
                  spacing={1 / 2}
                >
                  <CheckboxField
                    name={`${name}[${index}].isOpen`}
                    label={"Open"}
                    disabled={disabled}
                    indeterminate={false}
                  />
                  <TimePickerField
                    name={`${name}[${index}].from`}
                    label={t("From")}
                    disabled={!isOpen || disabled}
                    required={isOpen}
                  />
                  <TimePickerField
                    name={`${name}[${index}].to`}
                    label={t("To")}
                    disabled={!isOpen || disabled}
                    required={isOpen}
                  />
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </LabeledValue>
  );
};
