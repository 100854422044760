import { Box, BoxProps, IconButton, Paper } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { ArrowRight as ArrowRightIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import { TREE_SCAFFOLD_PX_WIDTH } from "../../constants.js";
import {
  color,
  cssValue,
  media,
  size,
} from "../../../common/MuiThemeProvider.js";
import { TreeSelectedIndicator } from "../../trees/components/TreeSelectedIndicator.js";
import { transientOptions } from "../../styles.js";

const ItemWrapper = styled(
  Box,
  transientOptions
)<{
  $selected: boolean;
  $depth: number;
  $scaffoldBlockPxWidth: number;
  $sticky: boolean;
  $top?: number;
  $bottom?: number;
}>(
  ({
    theme,
    $selected,
    $depth,
    $scaffoldBlockPxWidth,
    $sticky,
    $top,
    $bottom,
  }) => ({
    position: $sticky ? "sticky" : "relative",
    boxSizing: "border-box",
    padding: `${size.treeSpacing / 2}px 0 ${size.treeSpacing / 2}px 0`,
    width: "100%",
    height: `${size.treeItemFullHeight}px`,
    textDecoration: "none",
    color: "inherit",
    display: "block",
    paddingLeft: `${$depth * $scaffoldBlockPxWidth}px`,
    ...($sticky && $top !== undefined
      ? { top: `${$top}px`, zIndex: 1000 }
      : undefined),
    ...($sticky && $bottom !== undefined
      ? { bottom: `${$bottom}px`, zIndex: 1000 }
      : undefined),
    ...($selected
      ? {
          [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
            width: `calc(100% - ${size.treeIndicatorWidth}px)`,
          },
        }
      : undefined),
  })
);

const ExpandWrapper = styled("div")(`
  display: inline-flex;
  height: 100%;
  width: 16px;
  z-index: 5;

  & > button {
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translate(-4px, -50%);
    background-color: #f5f5f5;
    &:hover {
      background-color: #e0e0e0;
    }
  }
`);

const RowWrapper = styled("div")(`
  height: ${size.treeItemHeight}px;
  box-sizing: border-box;
  min-width: 0;
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`);

const ItemContainer = styled(
  Paper,
  transientOptions
)<{
  $clickable: boolean;
  $selected: boolean;
  $isGreyedOut: boolean;
  $isInput: boolean;
}>(({ theme, $clickable, $selected, $isGreyedOut, $isInput }) => ({
  position: "relative",
  display: "flex",
  width: "100%",
  backgroundColor: $isGreyedOut
    ? theme.palette.grey[100]
    : theme.palette.common.white,
  height: `${size.treeItemHeight}px`,
  ...($isInput ? { minWidth: "150px" } : undefined),
  ...($selected ? { boxShadow: cssValue.selectedBorderShadow } : undefined),
  ...($isGreyedOut
    ? {
        // white border around greyed out expand button
        ".rts__expandWrapper > button": {
          boxShadow: `inset 0 0 0 1px ${theme.palette.common.white}`,
        },
      }
    : undefined),
  ...($clickable && !$selected
    ? {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: $isGreyedOut
            ? theme.palette.grey[200]
            : theme.palette.grey[50],
        },
      }
    : undefined),
}));

interface Props {
  depth?: number;
  scaffoldBlockPxWidth?: number;
  clickable?: boolean;
  selected?: boolean;
  sticky?: boolean;
  top?: number;
  bottom?: number;
  isGreyedOut?: boolean;
  isInput?: boolean;
  isExpandable?: boolean;
  isExpanded?: boolean;
  setIsExpanded?: (newExpanded: boolean) => void;
}

export const VirtualBareTreeItem: FC<Props & BoxProps> = ({
  depth = 0,
  scaffoldBlockPxWidth = TREE_SCAFFOLD_PX_WIDTH,
  clickable = false,
  selected = false,
  sticky = false,
  top,
  bottom,
  isGreyedOut = false,
  isInput = false,
  isExpandable = false,
  isExpanded = false,
  setIsExpanded,
  children,
  ...props
}) => {
  return (
    <ItemWrapper
      $selected={selected}
      $depth={depth}
      $scaffoldBlockPxWidth={scaffoldBlockPxWidth}
      $sticky={sticky}
      $top={top}
      $bottom={bottom}
      {...props}
    >
      <ItemContainer
        $clickable={clickable}
        $selected={selected}
        $isGreyedOut={isGreyedOut}
        $isInput={isInput}
      >
        {isInput ? (
          children
        ) : (
          <>
            {isExpandable && (
              <ExpandWrapper className="rts__expandWrapper">
                <IconButton
                  size="small"
                  aria-label={isExpanded ? "Collapse" : "Expand"}
                  style={{ left: -0.5 * scaffoldBlockPxWidth }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsExpanded?.(!isExpanded);
                  }}
                >
                  {isExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </IconButton>
              </ExpandWrapper>
            )}
            <RowWrapper>{children}</RowWrapper>
          </>
        )}
      </ItemContainer>
      {selected && <TreeSelectedIndicator />}
    </ItemWrapper>
  );
};
