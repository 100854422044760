import { CollapseButton } from "@msys/ui";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { isDescendant } from "react-sortable-tree";
import {
  color,
  cssValue,
  media,
  size,
} from "../../../common/MuiThemeProvider.js";
import { isItemGreyedOut } from "../../features/doc-items/helpers.js";
import { getSubcontractPath } from "../../features/quotes/helpers.js";
import { transientOptions } from "../../styles.js";
import { TreeSelectedIndicator } from "../../trees/components/TreeSelectedIndicator.js";
import { getItemChildren } from "../../trees/helpers.js";
import {
  createRecursiveTryFocusElementFunction,
  isInTestMode,
} from "../../utils.js";
import {
  NodeRendererProps,
  VirtualItem,
  VirtualTreeItemContext,
} from "../types.js";

export const VirtualItemNodeRenderer = <T extends VirtualItem>({
  node,
  canDrag = false,
  toggleChildrenVisibility,
  parentNode,
  draggedNode,
  canDrop = false,
  connectDragSource,
  connectDragPreview,
  didDrop,
  isDragging,
  path,
  treeIndex,
  items,
  allItems,
  rootItem,
  inputComponent: InputComponent,
  rootItemComponent: RootItemComponent,
  itemComponent: ItemComponent,
  selectedItemId,
  lastSelectedItemId,
}: NodeRendererProps<T> & VirtualTreeItemContext<T>) => {
  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  const resetFocus = React.useMemo(
    () =>
      createRecursiveTryFocusElementFunction(
        `input#msys-tree-input-${node.item.type}-${node.item.id}:enabled`
      ),
    [node.item.id, node.item.type]
  );

  const input = node.isInput ? (
    InputComponent ? (
      <InputComponent
        key={`msys-tree-input-${node.item.type}-${node.item.id}`}
        id={`msys-tree-input-${node.item.type}-${node.item.id}`}
        item={node.item}
        nodeRank={node.rank}
        itemChildren={getItemChildren(node.item, allItems ?? items)}
        isRootItem={node.item.isRootItem}
        autoFocus={false} // doesn't work well with virtual tree
        resetFocus={
          //  auto focus does not play nicely with e2e automated tests
          //  the e2e tests will set this sessionStorage as a temporary solution
          isInTestMode() ? undefined : resetFocus
        }
      />
    ) : null
  ) : null;

  const component = !node.isInput ? (
    <div
      className="rst__row"
      style={{
        opacity: isDraggedDescendant ? 0.5 : 1,
      }}
    >
      {node.item.isRootItem && RootItemComponent ? (
        <RootItemComponent
          item={node.item}
          allItems={allItems ?? items}
          isRootItem={node.item.isRootItem}
          depth={0}
          parentItem={(parentNode?.item ?? rootItem)!}
          isGreyedOut={false} // already used it
          isHidden={node.item.isHidden}
          isPriceHidden={node.item.isPriceHidden}
          subcontractPath={getSubcontractPath(node.item)}
        />
      ) : ItemComponent ? (
        <ItemComponent
          item={node.item}
          allItems={allItems ?? items}
          isRootItem={node.item.isRootItem}
          depth={RootItemComponent ? path.length - 1 : path.length}
          parentItem={(parentNode?.item ?? rootItem)!}
          isGreyedOut={false} // already used it
          isHidden={node.item.isHidden}
          isPriceHidden={node.item.isPriceHidden}
          subcontractPath={getSubcontractPath(node.item)}
        />
      ) : null}
    </div>
  ) : null;

  const toggleButton = !node.isInput ? (
    toggleChildrenVisibility && node.item.hasChildren /* FIXME! &&
    !node.item.isItemEliminated &&
    !node.item.decisionOptionElimination*/ ? (
      <StyledExpandWrapper className="rts__expandWrapper">
        <CollapseButton
          aria-label={node.expanded ? "Collapse" : "Expand"}
          isExpanded={node.expanded}
          setIsExpanded={newExpanded =>
            toggleChildrenVisibility({
              node,
              path,
              treeIndex,
            })
          }
        />
      </StyledExpandWrapper>
    ) : null
  ) : null;

  return (
    <StyledContentWrapper
      $clickable={!node.isInput}
      $selected={node.selected}
      $selectedDescendant={
        !node.selected &&
        node.id !== selectedItemId &&
        node.id === lastSelectedItemId
      }
      $isInput={node.isInput}
      $isLandingPadActive={isLandingPadActive}
      $isLandingPadCancel={isLandingPadActive && !canDrop}
      $isGreyedOut={isItemGreyedOut(node.item)}
      $isPreselected={node.item.isParentDecisionPreselected}
    >
      {node.isInput ? (
        input
      ) : (
        <>
          {toggleButton}
          <StyledRowWrapper>
            {connectDragPreview(
              canDrag
                ? connectDragSource(component, { dropEffect: "copy" })
                : component
            )}
          </StyledRowWrapper>
        </>
      )}
      {node.selected && <TreeSelectedIndicator />}
    </StyledContentWrapper>
  );
};

const StyledContentWrapper = styled(Paper, transientOptions)<{
  $selected: boolean;
  $selectedDescendant: boolean;
  $clickable: boolean;
  $isLandingPadActive: boolean;
  $isLandingPadCancel: boolean;
  $isGreyedOut: boolean;
  $isPreselected: boolean;
  $isInput: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ $isGreyedOut, theme }) =>
    $isGreyedOut ? theme.palette.grey[100] : color.white};

  ${({ $selectedDescendant }) =>
    $selectedDescendant
      ? `
        box-shadow: ${cssValue.selectedDescendantBorderShadow};
      `
      : ""}
  ${({ $selected }) =>
    $selected
      ? `
        box-shadow: ${cssValue.selectedBorderShadow};
        @media (${media.minTablet}) {
          width: calc(100% - ${size.treeIndicatorWidth}px);
        }
      `
      : ""}

  ${({ $clickable, $selected, $isGreyedOut, theme }) =>
    $clickable && !$selected
      ? `
        &:hover {
          background-color: ${
            $isGreyedOut ? theme.palette.grey[200] : theme.palette.grey[50]
          };
        }
      `
      : ""}

  ${({ $isInput }) =>
    $isInput
      ? `
        min-width: 150px;
      `
      : ""}

  ${({ $isLandingPadActive }) =>
    $isLandingPadActive
      ? `
          & > * {
            pointer-events: none;
          }
          cursor: move;
          background-color: ${color.blue7} !important;
        `
      : ""}

  ${({ $isLandingPadCancel }) =>
    $isLandingPadCancel
      ? `
          & > * {
            pointer-events: none;
          }
          cursor: move;
          background-color: ${color.redLight} !important;
        `
      : ""}

  .rst__row {
    height: 100%;
    width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  ${({ $isGreyedOut }) =>
    $isGreyedOut
      ? `
        .rts__expandWrapper > button {
          box-shadow: 0 0 0 1px ${color.white};
        }
      `
      : ""}
`;

const StyledExpandWrapper = styled("div")(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  height: 100%;
  width: 14px;
  z-index: 5;

  & > button {
    transform: translateX(-16px);
    & {
      background-color: ${theme.palette.grey[100]};
    }
    &:hover {
      background-color: ${theme.palette.grey[300]};
    }
    &:active {
      background-color: ${theme.palette.grey[400]};
    }
  }
`
);

const StyledRowWrapper = styled("div")(`
  height: ${size.treeItemHeight}px;
  box-sizing: border-box;
  flex-grow: 1;
  min-width: 0;
`);
