/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstallerProjectRouterQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type InstallerProjectRouterQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus } | null } };


export const InstallerProjectRouterDocument = gql`
    query InstallerProjectRouter($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        viewerPermissions
        state
      }
    }
  }
}
    `;

/**
 * __useInstallerProjectRouterQuery__
 *
 * To run a query within a React component, call `useInstallerProjectRouterQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallerProjectRouterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallerProjectRouterQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useInstallerProjectRouterQuery(baseOptions: Apollo.QueryHookOptions<InstallerProjectRouterQuery, InstallerProjectRouterQueryVariables> & ({ variables: InstallerProjectRouterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstallerProjectRouterQuery, InstallerProjectRouterQueryVariables>(InstallerProjectRouterDocument, options);
      }
export function useInstallerProjectRouterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstallerProjectRouterQuery, InstallerProjectRouterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstallerProjectRouterQuery, InstallerProjectRouterQueryVariables>(InstallerProjectRouterDocument, options);
        }
export function useInstallerProjectRouterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InstallerProjectRouterQuery, InstallerProjectRouterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InstallerProjectRouterQuery, InstallerProjectRouterQueryVariables>(InstallerProjectRouterDocument, options);
        }
export type InstallerProjectRouterQueryHookResult = ReturnType<typeof useInstallerProjectRouterQuery>;
export type InstallerProjectRouterLazyQueryHookResult = ReturnType<typeof useInstallerProjectRouterLazyQuery>;
export type InstallerProjectRouterSuspenseQueryHookResult = ReturnType<typeof useInstallerProjectRouterSuspenseQuery>;
export type InstallerProjectRouterQueryResult = Apollo.QueryResult<InstallerProjectRouterQuery, InstallerProjectRouterQueryVariables>;