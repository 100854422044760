import { noop } from "lodash-es";
import React from "react";

type Value = string | null;
type SelectedUserContextType = {
  selectedUserId: Value;
  setSelectedUserId: (value: Value) => void;
};
export const SelectedUserContext = React.createContext<SelectedUserContextType>(
  { selectedUserId: null, setSelectedUserId: noop }
);
