/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InviteOrganisationMemberMutationVariables = Types.Exact<{
  input: Types.AddOrganisationMembershipInvitationInput;
}>;


export type InviteOrganisationMemberMutation = { addOrganisationMembershipInvitation: { __typename: 'AddOrganisationMembershipInvitationResult', invitation: { __typename: 'OrganisationMembershipInvitation', id: string, status: Types.InvitationState, invitationUrl: string } } };


export const InviteOrganisationMemberDocument = gql`
    mutation InviteOrganisationMember($input: AddOrganisationMembershipInvitationInput!) {
  addOrganisationMembershipInvitation(input: $input) {
    invitation {
      id
      status
      invitationUrl
    }
  }
}
    `;
export type InviteOrganisationMemberMutationFn = Apollo.MutationFunction<InviteOrganisationMemberMutation, InviteOrganisationMemberMutationVariables>;

/**
 * __useInviteOrganisationMemberMutation__
 *
 * To run a mutation, you first call `useInviteOrganisationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOrganisationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOrganisationMemberMutation, { data, loading, error }] = useInviteOrganisationMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteOrganisationMemberMutation(baseOptions?: Apollo.MutationHookOptions<InviteOrganisationMemberMutation, InviteOrganisationMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteOrganisationMemberMutation, InviteOrganisationMemberMutationVariables>(InviteOrganisationMemberDocument, options);
      }
export type InviteOrganisationMemberMutationHookResult = ReturnType<typeof useInviteOrganisationMemberMutation>;
export type InviteOrganisationMemberMutationResult = Apollo.MutationResult<InviteOrganisationMemberMutation>;
export type InviteOrganisationMemberMutationOptions = Apollo.BaseMutationOptions<InviteOrganisationMemberMutation, InviteOrganisationMemberMutationVariables>;