import { useApolloClient } from "@apollo/client";
import {
  CardContainer,
  DataGrid,
  InfoLabelMessage,
  ModalOpenButton,
} from "@msys/ui";
import {
  Add as AddIcon,
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { DetachedEmailField } from "../../../commons/form-fields/EmailField.js";
import { ManualSave } from "../../../commons/form-fields/ManualSave.js";
import { PasswordField } from "../../../commons/form-fields/PasswordField.js";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { SwitchField } from "../../../commons/form-fields/SwitchField.js";
import { TextField } from "../../../commons/form-fields/TextField.js";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal.js";
import { useEmailAddressValidation } from "../../email/validateEmailAddress.js";
import { OrganisationEmailTemplateModal } from "../modals/OrganisationEmailTemplateModal.js";
import {
  OrganisationEmailSettingsBox_OrganisationEmailSettingsFragment,
  OrganisationEmailSettingsBox_OrganisationEmailTemplateFragment,
  OrganisationEmailSettingsBox_OrganisationFragment,
  OrganisationEmailTemplates_OrganisationEmailTemplateFragment,
  OrganisationSmtpSettingsFragment,
  useAddOrganisationEmailTemplateMutation,
  useModifyOrganisationEmailTemplateMutation,
  useRemoveOrganisationEmailTemplateMutation,
  useTestOrganisationSmtpSettingsMutation,
  useUpdateOrganisationEmailReplyToAddressMutation,
  useUpdateOrganisationSmtpSettingsMutation,
} from "./OrganisationEmailSettingsBox.generated.js";
import { useEmailTemplateContexts } from "./useEmailTemplateContexts.js";

interface FormValues {
  emailReplyToAddress: string | null;
}

interface Props {
  organisation: OrganisationEmailSettingsBox_OrganisationFragment;
  organisationEmailSettings: OrganisationEmailSettingsBox_OrganisationEmailSettingsFragment;
  organisationEmailTemplates: OrganisationEmailSettingsBox_OrganisationEmailTemplateFragment[];
}

export function OrganisationEmailSettingsBox({
  organisation,
  organisationEmailSettings,
  organisationEmailTemplates,
}: Props) {
  const { t } = useTranslate(["OrganisationSettings"]);

  const isEmailAddressValid = useEmailAddressValidation();

  const client = useApolloClient();
  const [updateReplyToAddress] =
    useUpdateOrganisationEmailReplyToAddressMutation({
      client,
    });

  const handleSubmit = React.useCallback(
    async (values: FormValues) => {
      await updateReplyToAddress({
        variables: {
          input: {
            id: organisation.id,
            replyToAddress: values.emailReplyToAddress,
          },
        },
      });
    },
    [organisation.id, updateReplyToAddress]
  );

  return (
    <CardContainer
      title={t("Email settings", {
        ns: "OrganisationSettings",
      })}
      isExpandable
    >
      <Formik
        initialValues={{
          emailReplyToAddress: organisationEmailSettings.replyToAddress ?? null,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .label(
              t("Reply-to email address", {
                ns: "OrganisationSettings",
              })
            )
            .email()
            .trim(),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack padding={1} spacing={1}>
            <Stack spacing={0.5}>
              <RestrictedByOrganisationPermissionWithDebug
                permission={"MANAGE_ORG"}
              >
                <OrganisationSmtpSettings organisation={organisation} />
              </RestrictedByOrganisationPermissionWithDebug>
              <Typography variant={"h4"}>
                {t("Reply-to email address", {
                  ns: "OrganisationSettings",
                })}
              </Typography>
              <InfoLabelMessage
                message={t(
                  "If you set a custom reply-to email address, replies to your emails will not be visible in MeisterSystems",
                  {
                    ns: "OrganisationSettings",
                  }
                )}
              />
              <DetachedEmailField
                name={"emailReplyToAddress"}
                label={t("Reply-to email address", {
                  ns: "OrganisationSettings",
                })}
                hideLabelInReadView={true}
                validate={isEmailAddressValid}
                isValid={undefined}
              />
            </Stack>

            <Divider />

            <OrganisationEmailTemplates
              organisationId={organisation.id}
              emailTemplates={organisationEmailTemplates}
            />
          </Stack>
          <AutoSave />
        </Form>
      </Formik>
    </CardContainer>
  );
}

type SmtpConfig = {
  host: string;
  port: number;
  encryption: "None" | "SSL/TLS";
  username: string;
  password: string;
};

interface SmtpFormValues {
  smtpHost: string;
  smtpPort: number;
  smtpSecurity: "None" | "SSL/TLS";
  smtpUsername: string;
  smtpPassword: string;
  smtpFromEmail: string;
  smtpEnabled: boolean;
}

function OrganisationSmtpSettings({
  organisation,
}: {
  organisation: OrganisationSmtpSettingsFragment;
}) {
  const { t } = useTranslate(["OrganisationSettings"]);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const [updateSmtpValues] = useUpdateOrganisationSmtpSettingsMutation({
    client,
  });
  const [testOrganisationSmtpSettings] =
    useTestOrganisationSmtpSettingsMutation({
      client,
    });

  const securityOptons = [
    { label: "None", value: "None" },
    { label: "SSL/TLS", value: "SSL/TLS" },
  ];
  const enableOptions = [
    { label: "Off", value: false },
    { label: "On", value: true },
  ];

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        smtpHost: Yup.string()
          .label(t("Smtp Host", { ns: "OrganisationSettings" }))
          .matches(
            /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
            t("Invalid host name", { ns: "OrganisationSettings" })
          )
          .required(),
        smtpPort: Yup.number()
          .label(t("Smtp Port", { ns: "OrganisationSettings" }))
          .required(),
        smtpSecurity: Yup.string()
          .label(t("Smtp Security", { ns: "OrganisationSettings" }))
          .required(),
        smtpUsername: Yup.string()
          .label(t("Smtp Username", { ns: "OrganisationSettings" }))
          .required(),
        smtpPassword: Yup.string()
          .label(t("Smtp Password", { ns: "OrganisationSettings" }))
          .required(),
        smtpFromEmail: Yup.string()
          .label(t("Smtp From Email", { ns: "OrganisationSettings" }))
          .email()
          .required(),
        smtpEnabled: Yup.boolean().label(
          t("Enable Smtp", { ns: "OrganisationSettings" })
        ),
      }),
    [t]
  );

  return (
    <Box p={1}>
      <Typography variant={"h4"}>
        {t("Smtp Settings", { ns: "OrganisationSettings" })}
      </Typography>
      <InfoLabelMessage
        message={t(
          "If you set up smtp settings, all outgoing emails will use that smtp server and come from the set address",
          {
            ns: "OrganisationSettings",
          }
        )}
      />
      <Formik<SmtpFormValues>
        enableReinitialize
        initialValues={{
          smtpHost: organisation?.smtpSettings?.smtpHost ?? "",
          smtpPort: organisation?.smtpSettings?.smtpPort ?? 587,
          smtpSecurity:
            organisation?.smtpSettings?.smtpSecurity == "SSL/TLS"
              ? "SSL/TLS"
              : "None",
          smtpUsername: organisation?.smtpSettings?.smtpUsername ?? "",
          smtpPassword: "",
          smtpFromEmail: organisation?.smtpSettings?.smtpFromEmail ?? "",
          smtpEnabled: organisation?.smtpEnabled ?? false,
        }}
        onSubmit={async values => {
          console.log(values);
          await updateSmtpValues({
            variables: {
              input: {
                smtpSettings: {
                  smtpHost: values.smtpHost ?? "",
                  smtpPort: values.smtpPort ?? 80,
                  smtpSecurity: values.smtpSecurity ?? "SSL/TLS",
                  smtpUsername: values.smtpUsername ?? "",
                  smtpPassword: values.smtpPassword ?? "",
                  smtpFromEmail: values.smtpFromEmail ?? "",
                },
                smtpEnabled: values.smtpEnabled ?? false,
              },
            },
          });
        }}
        validationSchema={validationSchema}
      >
        {formikProps => (
          <Form>
            <Stack spacing={2}>
              <SwitchField
                name="smtpEnabled"
                label={t("Enable Smtp", { ns: "OrganisationSettings" })}
              />
              <TextField
                type="text"
                name={"smtpHost"}
                label={t("Smtp Host", { ns: "OrganisationSettings" })}
              />
              <SelectField
                name="smtpSecurity"
                label={t("Smtp Security", { ns: "OrganisationSettings" })}
                options={securityOptons}
              />
              <TextField
                type="number"
                name={"smtpPort"}
                label={t("Smtp Port", { ns: "OrganisationSettings" })}
              />
              <TextField
                type="text"
                name={"smtpUsername"}
                label={t("Smtp Username", { ns: "OrganisationSettings" })}
              />
              <PasswordField
                name={"smtpPassword"}
                label={t("Smtp Password", { ns: "OrganisationSettings" })}
              />
              <TextField
                type="text"
                name={"smtpFromEmail"}
                label={t("Smtp From Email", { ns: "OrganisationSettings" })}
              />
              <ManualSave onSubmit={formikProps.submitForm} />

              <LoadingButton
                loading={formikProps.isSubmitting}
                onClick={async () => {
                  formikProps.setSubmitting(true);

                  const result = await testOrganisationSmtpSettings({
                    variables: {
                      input: {
                        smtpHost: formikProps.values.smtpHost,
                        smtpPort: formikProps.values.smtpPort,
                        smtpSecurity: formikProps.values.smtpSecurity,
                        smtpUsername: formikProps.values.smtpUsername,
                        smtpPassword: formikProps.values.smtpPassword,
                        smtpFromEmail: formikProps.values.smtpFromEmail,
                      },
                    },
                  });
                  result !== null &&
                    enqueueSnackbar(
                      result.data?.testOrganisationSmtpSettings == "Success"
                        ? "SMTP test succeeded"
                        : result.data?.testOrganisationSmtpSettings,
                      {
                        variant:
                          result.data?.testOrganisationSmtpSettings == "Success"
                            ? "success"
                            : "error",
                      }
                    );
                  console.log(result);
                  formikProps.setSubmitting(false);
                }}
              >
                Test smtp Connection
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function OrganisationEmailTemplates({
  emailTemplates,
}: {
  organisationId: string;
  emailTemplates: OrganisationEmailTemplates_OrganisationEmailTemplateFragment[];
}) {
  const { t } = useTranslate(["OrganisationSettings", "AttachmentField"]);
  const { labels: emailTemplateContextLabels } = useEmailTemplateContexts();

  const client = useApolloClient();
  const [addOrganisationEmailTemplate] =
    useAddOrganisationEmailTemplateMutation({
      client,
      refetchQueries: [namedOperations.Query.OrganisationSettingsIntegrations],
    });
  const [modifyOrganisationEmailTemplate] =
    useModifyOrganisationEmailTemplateMutation({
      client,
    });
  const [removeOrganisationEmailTemplate] =
    useRemoveOrganisationEmailTemplateMutation({
      client,
      refetchQueries: [namedOperations.Query.OrganisationSettingsIntegrations],
    });

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant={"h4"}>
          {t("Email templates", { ns: "OrganisationSettings" })}
        </Typography>
        <ModalOpenButton
          Modal={OrganisationEmailTemplateModal}
          modalProps={{
            title: t("New email template", { ns: "OrganisationSettings" }),
            handleComplete: async (values, handleClose) => {
              await addOrganisationEmailTemplate({
                variables: { input: values },
              });
              handleClose();
            },
          }}
        >
          <IconButton color={"primary"}>
            <AddIcon />
          </IconButton>
        </ModalOpenButton>
      </Stack>
      <DataGrid
        columns={[
          {
            field: "description",
            headerName: t("Template", { ns: "OrganisationSettings" }),
            flex: 1,
            sortable: false,
            valueGetter: ({ row }) => row.description,
          },
          {
            field: "context",
            headerName: t("Context", { ns: "OrganisationSettings" }),
            sortable: false,
            valueGetter: ({ row }) => emailTemplateContextLabels[row.context],
          },
          {
            field: "attachmentCount",
            flex: 0,
            maxWidth: 50,
            align: "right",
            sortable: false,
            renderHeader: () => <AttachFileIcon />,
            headerAlign: "right",
            valueGetter: ({ row }) => row.attachments.length,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0,
            maxWidth: 92,
            sortable: false,
            renderCell: ({ row }) => (
              <Stack direction="row" spacing={0.5}>
                <ModalOpenButton
                  Modal={OrganisationEmailTemplateModal}
                  modalProps={{
                    template: row,
                    title: t("Edit email template", {
                      ns: "OrganisationSettings",
                    }),
                    handleComplete: async (values, handleClose) => {
                      modifyOrganisationEmailTemplate({
                        variables: {
                          templateId: row.id,
                          input: {
                            description: values.description,
                            subject: values.subject,
                            content: values.content,
                            attachments: values.attachments.map(attachment => ({
                              id: attachment.id,
                              url: attachment.url,
                              title: attachment.title,
                              mimeType: attachment.mimeType,
                              group: attachment.group,
                            })),
                          },
                        },
                      });
                      handleClose();
                    },
                  }}
                >
                  <IconButton color="primary" size="small">
                    <EditIcon />
                  </IconButton>
                </ModalOpenButton>
                <ModalOpenButton
                  Modal={ConfirmModal}
                  modalProps={{
                    handleConfirm: async () => {
                      await removeOrganisationEmailTemplate({
                        variables: { templateId: row.id },
                      });
                    },
                  }}
                >
                  <IconButton color="primary" size="small">
                    <DeleteIcon />
                  </IconButton>
                </ModalOpenButton>
              </Stack>
            ),
          },
        ]}
        rows={emailTemplates}
        hideFooter
        paginationMode="client"
        disableColumnMenu
        disableColumnResize
        disableRowSelectionOnClick
        sx={{ "& .MuiDataGrid-row.MuiDataGrid-row": { cursor: "default" } }}
      />
    </Stack>
  );
}
