/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationQuotePdfSettingsBox_SettingsFragment = { __typename: 'OrganisationSetting', id: string, quoteIsSignatureVisible: boolean, quoteVatVisibility: Types.VatVisibility, quoteHeaderVisibility: { __typename: 'QuoteHeaderVisibility', isProjectNumberVisible: boolean, isClientNumberVisible: boolean, isDocumentNumberVisible: boolean, isExternalIdentifierVisible: boolean, isPublishDateVisible: boolean, isContractorNameVisible: boolean, isContractorAddressVisible: boolean, isContractorPhoneVisible: boolean, isContractorEmailVisible: boolean, isContractorContactPersonVisible: boolean, isEndClientVisible: boolean }, quoteProductsVisibility: { __typename: 'QuoteProductsVisibility', showTitle: boolean, showDescription: boolean, showImages: Types.ProductsVisibilityImages } };

export type OrganisationQuoteHeaderVisibilityFragment = { __typename: 'QuoteHeaderVisibility', isProjectNumberVisible: boolean, isClientNumberVisible: boolean, isDocumentNumberVisible: boolean, isExternalIdentifierVisible: boolean, isPublishDateVisible: boolean, isContractorNameVisible: boolean, isContractorAddressVisible: boolean, isContractorPhoneVisible: boolean, isContractorEmailVisible: boolean, isContractorContactPersonVisible: boolean, isEndClientVisible: boolean };

export type OrganisationQuoteProductsVisibilityFragment = { __typename: 'QuoteProductsVisibility', showTitle: boolean, showDescription: boolean, showImages: Types.ProductsVisibilityImages };

export type UpdateOrganisationQuotePdfSettingsMutationVariables = Types.Exact<{
  input: Types.OrganisationSettingsUpdateInput;
}>;


export type UpdateOrganisationQuotePdfSettingsMutation = { updateOrganisationSettings: { __typename: 'OrganisationSettingsUpdateResult', organisationSettings: { __typename: 'OrganisationSetting', id: string, quoteIsSignatureVisible: boolean, quoteVatVisibility: Types.VatVisibility, quoteHeaderVisibility: { __typename: 'QuoteHeaderVisibility', isProjectNumberVisible: boolean, isClientNumberVisible: boolean, isDocumentNumberVisible: boolean, isExternalIdentifierVisible: boolean, isPublishDateVisible: boolean, isContractorNameVisible: boolean, isContractorAddressVisible: boolean, isContractorPhoneVisible: boolean, isContractorEmailVisible: boolean, isContractorContactPersonVisible: boolean, isEndClientVisible: boolean }, quoteProductsVisibility: { __typename: 'QuoteProductsVisibility', showTitle: boolean, showDescription: boolean, showImages: Types.ProductsVisibilityImages } } } };

export const OrganisationQuoteHeaderVisibilityFragmentDoc = gql`
    fragment OrganisationQuoteHeaderVisibility on QuoteHeaderVisibility {
  isProjectNumberVisible
  isClientNumberVisible
  isDocumentNumberVisible
  isExternalIdentifierVisible
  isPublishDateVisible
  isContractorNameVisible
  isContractorAddressVisible
  isContractorPhoneVisible
  isContractorEmailVisible
  isContractorContactPersonVisible
  isEndClientVisible
}
    `;
export const OrganisationQuoteProductsVisibilityFragmentDoc = gql`
    fragment OrganisationQuoteProductsVisibility on QuoteProductsVisibility {
  showTitle
  showDescription
  showImages
}
    `;
export const OrganisationQuotePdfSettingsBox_SettingsFragmentDoc = gql`
    fragment OrganisationQuotePdfSettingsBox_Settings on OrganisationSetting {
  id
  quoteIsSignatureVisible
  quoteVatVisibility
  quoteHeaderVisibility {
    ...OrganisationQuoteHeaderVisibility
  }
  quoteProductsVisibility {
    ...OrganisationQuoteProductsVisibility
  }
}
    ${OrganisationQuoteHeaderVisibilityFragmentDoc}
${OrganisationQuoteProductsVisibilityFragmentDoc}`;
export const UpdateOrganisationQuotePdfSettingsDocument = gql`
    mutation UpdateOrganisationQuotePdfSettings($input: OrganisationSettingsUpdateInput!) {
  updateOrganisationSettings(input: $input) {
    organisationSettings {
      id
      quoteIsSignatureVisible
      quoteVatVisibility
      quoteHeaderVisibility {
        ...OrganisationQuoteHeaderVisibility
      }
      quoteProductsVisibility {
        ...OrganisationQuoteProductsVisibility
      }
    }
  }
}
    ${OrganisationQuoteHeaderVisibilityFragmentDoc}
${OrganisationQuoteProductsVisibilityFragmentDoc}`;
export type UpdateOrganisationQuotePdfSettingsMutationFn = Apollo.MutationFunction<UpdateOrganisationQuotePdfSettingsMutation, UpdateOrganisationQuotePdfSettingsMutationVariables>;

/**
 * __useUpdateOrganisationQuotePdfSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationQuotePdfSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationQuotePdfSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationQuotePdfSettingsMutation, { data, loading, error }] = useUpdateOrganisationQuotePdfSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganisationQuotePdfSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationQuotePdfSettingsMutation, UpdateOrganisationQuotePdfSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationQuotePdfSettingsMutation, UpdateOrganisationQuotePdfSettingsMutationVariables>(UpdateOrganisationQuotePdfSettingsDocument, options);
      }
export type UpdateOrganisationQuotePdfSettingsMutationHookResult = ReturnType<typeof useUpdateOrganisationQuotePdfSettingsMutation>;
export type UpdateOrganisationQuotePdfSettingsMutationResult = Apollo.MutationResult<UpdateOrganisationQuotePdfSettingsMutation>;
export type UpdateOrganisationQuotePdfSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationQuotePdfSettingsMutation, UpdateOrganisationQuotePdfSettingsMutationVariables>;