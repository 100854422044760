import { useApolloClient } from "@apollo/client";
import React from "react";
import { useUrlSearchParams } from "../commons/hooks/useUrlSearchParams.js";
import { REF_TOKEN_URL_SEARCH_PARAM } from "../constants.js";
import { InvitationTokenContext } from "./InvitationTokenContext.js";
import { InvitationTokenData } from "./InvitationTokenData.js";
import { useRefTokenQuery } from "./InvitationTokenProvider.generated.js";

export const InvitationTokenProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { token: urlToken, remove } = useUrlToken();
  const { token, tokenData, isLoading } = useInvitationToken(urlToken);

  return (
    <InvitationTokenContext.Provider
      value={{ token, tokenData, isLoading, remove }}
    >
      {children}
    </InvitationTokenContext.Provider>
  );
};

function useUrlToken(name: string = REF_TOKEN_URL_SEARCH_PARAM) {
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();
  const token = urlSearchParams.get(name);

  const remove = React.useCallback(() => {
    setUrlSearchParams({}, [name]);
  }, [name, setUrlSearchParams]);

  return { token, remove };
}

function useInvitationToken(refToken: string | null) {
  const client = useApolloClient();
  const query = useRefTokenQuery({
    client,
    variables: {
      refToken: refToken!,
    },
    skip: !refToken,
  });

  const tokenData: InvitationTokenData | null = refToken
    ? (query.data?.organisationMembershipInvitation ??
      query.data?.projectContracteeInvitation ??
      query.data?.crmLinkingInvitation ??
      query.data?.referralInfo ??
      null)
    : null;

  const token = tokenData ? refToken : null;

  return {
    token,
    tokenData,
    isLoading: query.loading,
  };
}
