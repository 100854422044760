/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetCustomFieldMutationVariables = Types.Exact<{
  input: Types.SetCustomFieldsInput;
}>;


export type SetCustomFieldMutation = { setCustomFields: { __typename: 'SetCustomFieldsResult', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> } };


export const SetCustomFieldDocument = gql`
    mutation SetCustomField($input: SetCustomFieldsInput!) {
  setCustomFields(input: $input) {
    id
    customFields {
      key
      value
      dataType
      group
    }
  }
}
    `;
export type SetCustomFieldMutationFn = Apollo.MutationFunction<SetCustomFieldMutation, SetCustomFieldMutationVariables>;

/**
 * __useSetCustomFieldMutation__
 *
 * To run a mutation, you first call `useSetCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomFieldMutation, { data, loading, error }] = useSetCustomFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomFieldMutation, SetCustomFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomFieldMutation, SetCustomFieldMutationVariables>(SetCustomFieldDocument, options);
      }
export type SetCustomFieldMutationHookResult = ReturnType<typeof useSetCustomFieldMutation>;
export type SetCustomFieldMutationResult = Apollo.MutationResult<SetCustomFieldMutation>;
export type SetCustomFieldMutationOptions = Apollo.BaseMutationOptions<SetCustomFieldMutation, SetCustomFieldMutationVariables>;