import { ModalOpenButton } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { ButtonCreate } from "../../../commons/button/Button.js";
import { CreateInvoiceModal } from "../CreateInvoiceModal.js";

interface Props {
  projectId: string;
  refetchQueries?: string[];
  Button?: React.ReactElement;
}

export const CreateInvoiceButton = ({
  projectId,
  Button,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate("InvoiceDetailsForm");
  const navigate = useNavigate();

  const button = Button ?? <ButtonCreate title={t("Create Invoice")} />;

  return (
    <ModalOpenButton
      Modal={CreateInvoiceModal}
      modalProps={{
        id: "create-invoice-modal",
        refetchQueries,
        projectId,
        handleComplete: async invoiceId => {
          navigate(`/projects/${projectId}/invoices/outgoing/${invoiceId}`);
        },
      }}
    >
      {button}
    </ModalOpenButton>
  );
};
