/* eslint-disable jsx-a11y/anchor-has-content */
import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  MenuButton,
  MenuItemWithIcon,
  TitleWithPathForPdf,
  useScreenWidth,
} from "@msys/ui";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { PageFullScreenModal } from "../../commons/layout/PageFullScreenModal.js";
import { PageMiddleColumn } from "../../commons/layout/PageMiddleColumn.js";
import { ViewModeEditMenuItem } from "../../commons/ViewModeEditMenuItem.js";
import { ViewMode } from "../../commons/ViewModeMenuItem.js";
import { useDeleteItemMutation } from "../../features/doc-items/hooks/useDeleteItemMutation.js";
import { EmptyStateItem } from "../../features/doc-items/items/EmptyStateItem.js";
import { TaskItem } from "../../features/tasks/TaskItem.js";
import { useProjectTasksItemQuery } from "./ProjectTasksItem.generated.js";

const REFETCH_QUERIES = [
  namedOperations.Query.ProjectTasks,
  namedOperations.Query.ProjectTasksItem,
];
const REFETCH_QUERY = [namedOperations.Query.ProjectTasksItem];

export function ProjectTasksItem({
  projectId,
  pathToDocPage,
  isInitiallyClosed,
}: {
  projectId: string;
  pathToDocPage: string;
  isInitiallyClosed?: boolean;
}) {
  const { docId, itemId } = useParams();
  if (!docId) throw new Error("Doc id is missing");
  if (!itemId) throw new Error("Item id is missing");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate("Global");

  const { expandedItemIds } = useOutletContext<{
    expandedItemIds: string[] | undefined;
  }>();

  const client = useApolloClient();
  const query = useProjectTasksItemQuery({
    client,
    variables: { projectId, docId, itemId },
  });
  const { deleteItem: deleteQuoteItem } =
    useDeleteItemMutation(expandedItemIds);

  const project = getDataOrNull(query.data?.project)?.project;

  const document = project?.document[0];
  const taskItem = document?.item[0]
    ? {
        ...document.item[0],
        docId: document.id,
        docAgreement: document.agreement,
        docViewerPermissions: document.viewerPermissions,
      }
    : null;

  const isManualSave = true;

  const [viewMode, setViewMode] = React.useState<ViewMode>(
    isManualSave ? null : "edit"
  );

  React.useEffect(() => {
    setViewMode(isManualSave ? null : "edit");
  }, [isManualSave, taskItem?.id]);

  if (!document || !taskItem)
    return (
      <EmptyStateItem
        loading={query.loading}
        error={query.error}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      />
    );

  const deleteItem = async () => {
    try {
      await deleteQuoteItem({
        projectId,
        docId: document.id,
        itemId: taskItem.id,
      });
      navigate(pathToDocPage, { replace: true });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const canEdit =
    !taskItem.deletedAt &&
    (taskItem.type === "defect" || taskItem.type === "unpaid");

  const item = (
    <TaskItem
      key={taskItem.id} // force reset forms
      isManualSave={isManualSave}
      viewMode={viewMode}
      setViewMode={setViewMode}
      project={project}
      doc={document}
      item={taskItem}
      pathToDocPage={pathToDocPage}
      onUpdateDataRefetchQueries={REFETCH_QUERY}
      isInitiallyClosed={isInitiallyClosed}
      canEdit={canEdit}
    />
  );

  const title = (
    <TitleWithPathForPdf
      title={taskItem.title}
      pathForPdf={taskItem.pathForPdf}
    />
  );

  const headerActions = (
    <RestrictedByDocumentPermissionWithDebug
      document={document}
      permission="MANAGE_QUOTES"
    >
      <MenuButton>
        {isManualSave && canEdit && (
          <ViewModeEditMenuItem
            viewMode={viewMode}
            onViewModeChange={setViewMode}
          />
        )}
        <MenuItemWithIcon onClick={deleteItem} icon={<DeleteIcon />}>
          {t("Delete")}
        </MenuItemWithIcon>
      </MenuButton>
    </RestrictedByDocumentPermissionWithDebug>
  );

  if (isMinTablet) {
    return (
      <PageMiddleColumn
        key={itemId}
        hasRightBorder
        hasBackground
        isSelected
        title={title}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
        headerActions={headerActions}
      >
        {item}
      </PageMiddleColumn>
    );
  }

  return (
    <PageFullScreenModal
      key={itemId}
      title={title}
      onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      headerActions={headerActions}
    >
      {item}
    </PageFullScreenModal>
  );
}
