import {
  DURATION_INPUT_DEFAULT_VALUE,
  DurationDailyInput,
  useScreenWidth,
} from "@msys/ui";
import { FormControl, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { get } from "lodash-es";
import React, { ChangeEvent, CSSProperties, FC, useState } from "react";
import { browserHasTouch } from "../../featureDetection.js";
import {
  durationInMinutesToTime,
  getSelectedText,
  timeToDurationInMinutes,
} from "../../utils.js";
import { DurationTrackDailyInput } from "../inputs/DurationTrackDailyInput.js";

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  style?: CSSProperties;
  value?: number | null;
  onChange?: (newValue: number) => void;
  size?: React.ComponentProps<typeof TextField>["size"];
}

export const DurationDailyField: FC<Props> = ({
  label,
  name,
  placeholder,
  fullWidth = true,
  disabled = false,
  required = false,
  style,
  value,
  onChange,
  size,
}) => {
  const { values, setFieldValue, errors } =
    useFormikContext<Record<string, unknown>>();
  const [isFocused, setIsFocused] = useState(false);
  const { isMaxTablet } = useScreenWidth();

  const currentValueInMinutes = value ?? get(values, name);

  const currentValueTime =
    typeof currentValueInMinutes === "number"
      ? durationInMinutesToTime(currentValueInMinutes)
      : DURATION_INPUT_DEFAULT_VALUE;

  const handleChangeTime = (
    e: ChangeEvent<HTMLInputElement>,
    newValueTime: string
  ) => {
    if (onChange) onChange(timeToDurationInMinutes(newValueTime));
    else setFieldValue(name, timeToDurationInMinutes(newValueTime));
  };
  const handleChangeInMinutes = (newValueInMinutes: number) => {
    if (onChange) onChange(newValueInMinutes);
    else setFieldValue(name, newValueInMinutes);
  };

  const inputValue = isFocused
    ? currentValueTime
    : typeof currentValueInMinutes === "number"
      ? `${durationInMinutesToTime(currentValueInMinutes)} h`
      : `--:-- h`;

  const error = get(errors, name);

  const input = (
    <TextField
      type="text"
      style={style}
      label={`${label}${label && required ? " *" : ""}`}
      placeholder={placeholder}
      error={!!error}
      value={inputValue}
      disabled={disabled}
      size={size}
      onFocus={e => {
        const wasSelected = getSelectedText() === e.target.value;

        setIsFocused(true);

        if (wasSelected) {
          setTimeout(() => e.target.select());
        }
      }}
      onSelect={e => {
        e.preventDefault(); // prevent context menu from opening (on mobile) when selecting text
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      helperText={error ? error : undefined}
    />
  );

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      error={!!error}
      disabled={disabled}
      style={style}
    >
      {isMaxTablet && browserHasTouch ? (
        <DurationTrackDailyInput
          input={input}
          value={
            typeof currentValueInMinutes === "number"
              ? currentValueInMinutes
              : 0
          }
          onChange={handleChangeInMinutes}
        />
      ) : (
        <DurationDailyInput
          value={inputValue}
          onChange={handleChangeTime}
          enabled={isFocused}
          input={input}
        />
      )}
    </FormControl>
  );
};
