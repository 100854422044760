import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Typography } from "@mui/material";
import React from "react";
import { Stack } from "../../commons/layout/Stack.js";
import { Modal } from "@msys/ui";
import { useConfirmDeletionModalQuery } from "./ConfirmationDeletionModal.generated.js";
import { useTranslate } from "@tolgee/react";

interface Props {
  projectId: string;
  requirementId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: () => Promise<void> | void;
}

export const ConfirmDeletionModal = ({
  projectId,
  requirementId,
  title,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["Requirements", "Global"]);

  const client = useApolloClient();
  const query = useConfirmDeletionModalQuery({
    client,
    variables: { projectId, requirementId },
  });
  const requirement = getDataOrNull(query.data?.requirement)?.requirement;
  const outgoingRequests = requirement?.outgoingRequests ?? [];

  return (
    <Modal
      title={title ?? "Confirm deletion"} // t("Requirements::Confirm deletion")}
      handleClose={handleClose}
      isLoading={query.loading}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Confirm", {
            ns: "Global",
          }),
          buttonProps: {
            type: "submit",
          },
          handleClick: handleComplete,
        },
      ]}
    >
      <Typography variant="h3">
        {t(
          "Please note that any requests for quote linked to this requirement will also be deleted. Are you sure you want to delete this requirement?",
          {
            ns: "Requirements",
          }
        )}
      </Typography>
      {outgoingRequests && outgoingRequests.length > 0 && (
        <Stack paddingY={2} spacing={1} flexDirection="column">
          <Typography>
            {t("This requirement is part of the requests below.", {
              ns: "Requirements",
            })}
          </Typography>
          {outgoingRequests.map(request => (
            <Typography key={request.id} color={"secondary"}>
              {request.title}
            </Typography>
          ))}
        </Stack>
      )}
    </Modal>
  );
};
