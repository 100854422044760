import { StyledComponent } from "@emotion/styled";
import { ListItem } from "@msys/ui";
import { styled } from "@mui/material/styles";
import React from "react";
import { transientOptions } from "../../styles.js";

export const TreeListItem: StyledComponent<
  React.ComponentProps<typeof ListItem> & {
    $canBeFirst?: boolean;
    $canBeLast?: boolean;
  },
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement>,
  {}
> = styled(
  ListItem,
  transientOptions
)<{
  $canBeFirst?: boolean;
  $canBeLast?: boolean;
}>(
  ({ $canBeFirst, $canBeLast }) =>
    `
    position: relative;
  
    .msys-colored-line-s1 {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
  
    &:not(:last-child) {
      .msys-colored-line-s1 {
        border-bottom-left-radius: 0 !important;
      }
    }
  
    &:not(:first-child) {
      .msys-colored-line-s1 {
        border-top-left-radius: 0 !important;
      }
    }
  
    ${
      !$canBeLast
        ? `
            &:last-child {
              .msys-colored-line-s1 {
                border-bottom-left-radius: 0 !important;
              }
            }
          `
        : ""
    }
    ${
      !$canBeFirst
        ? `
            &:first-child {
              .msys-colored-line-s1 {
                border-top-left-radius: 0 !important;
              }
            }
          `
        : ""
    }
  `
);
