import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import moment from "moment";
import { Link } from "react-router-dom";
import { buildDocPath } from "../../../../utils.js";
import { useTemplateDebugModalQuery } from "./TemplateDebugModal.generated.js";

interface Props {
  pathToDocList: string;
  docId: string;
  handleClose: () => void;
}

export function TemplateDebugModal({
  docId,
  pathToDocList,
  handleClose,
}: Props) {
  const client = useApolloClient();
  const query = useTemplateDebugModalQuery({
    client,
    variables: { id: docId },
  });

  const doc = getDataOrNull(query.data?.quoteTemplateLatest)?.quoteTemplate;
  if (!doc) return null;

  return (
    <Modal handleClose={handleClose} isLoading={query.loading} maxWidth="lg">
      <div
        style={{
          border: "1px solid black",
          padding: "1rem",
          marginBottom: "1rem",
        }}
      >
        <h2>linked templates by this</h2>
        <hr />
        {doc.linkedTemplatesByThis?.map(n => {
          return (
            <div key={n.linkingItem.id}>
              <div>
                linkingItem:{" "}
                <strong>
                  <Link
                    to={
                      buildDocPath(
                        `${pathToDocList}/${n.linkingItem.docId}`,
                        n.linkingItemVersionNumber ?? null
                      ) +
                      `${n.linkingItemVersionNumber ? "" : "/edit"}/items/${
                        n.linkingItem.id
                      }`
                    }
                  >
                    {n.linkingItem.pathForPdf} {n.linkingItem.title}
                  </Link>
                </strong>
                , linkedItem:{" "}
                <strong>
                  <Link
                    to={
                      buildDocPath(
                        `${pathToDocList}/${n.linkedItem.docId}`,
                        n.linkedItemVersionNumber ?? null
                      ) +
                      `${n.linkedItemVersionNumber ? "" : "/edit"}/items/${
                        n.linkedItem.id
                      }`
                    }
                  >
                    {n.linkedItem.pathForPdf} {n.linkedItem.title}
                  </Link>
                </strong>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          border: "1px solid black",
          padding: "1rem",
          marginBottom: "1rem",
        }}
      >
        <h2>linking templates to this</h2>
        <hr />
        {doc.linkingTemplatesToThis?.map(n => {
          return (
            <div key={n.linkingItem.id}>
              <div>
                linkingItem:{" "}
                <Link
                  to={
                    buildDocPath(
                      `${pathToDocList}/${n.linkingItem.docId}`,
                      n.linkingItemVersionNumber ?? null
                    ) +
                    `${n.linkingItemVersionNumber ? "" : "/edit"}/items/${
                      n.linkingItem.id
                    }`
                  }
                >
                  {n.linkingItem.pathForPdf} {n.linkingItem.title}
                </Link>
                , linkedItem:{" "}
                <strong>
                  <Link
                    to={
                      buildDocPath(
                        `${pathToDocList}/${n.linkedItem.docId}`,
                        n.linkedItemVersionNumber ?? null
                      ) +
                      `${n.linkedItemVersionNumber ? "" : "/edit"}/items/${
                        n.linkedItem.id
                      }`
                    }
                  >
                    {n.linkedItem.pathForPdf} {n.linkedItem.title}
                  </Link>
                </strong>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          border: "1px solid black",
          padding: "1rem",
          marginBottom: "1rem",
        }}
      >
        <h2>unpublished changes</h2>
        <hr />
        <textarea
          readOnly
          disabled
          style={{ width: "100%", height: 100 }}
          defaultValue={JSON.stringify(doc.unpublishedChanges, null, 2)}
        ></textarea>
      </div>
      <div
        style={{
          border: "1px solid black",
          padding: "1rem",
          marginBottom: "1rem",
        }}
      >
        <h2>published versions</h2>
        <hr />
        <ol style={{ listStyle: "none", padding: 0, margin: 0 }}>
          {doc.publishedVersions?.map(n => {
            return (
              <li key={n.id}>
                <div>versionNumber: {n.versionNumber}</div>
                <div>createdAt: {moment(n.createdAt).toLocaleString()}</div>
                <hr />
              </li>
            );
          })}
        </ol>
      </div>
    </Modal>
  );
}
