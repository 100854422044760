/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type InvoiceAction_InvoiceFragment = { __typename: 'Invoice', id: string, status: Types.InvoiceStatus, paymentTermDuration: number, deliveryOfServiceStart?: any | null, deliveryOfServiceEnd?: any | null, invoiceTexts?: { __typename: 'InvoiceTexts', id: string, opening: string, closing: string } | null, quote: { __typename: 'Quote', id: string } };

export type InvoiceAction_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName> };

export const InvoiceAction_InvoiceFragmentDoc = gql`
    fragment InvoiceAction_Invoice on Invoice {
  id
  status
  invoiceTexts {
    id
    opening
    closing
  }
  quote {
    id
  }
  paymentTermDuration
  deliveryOfServiceStart
  deliveryOfServiceEnd
}
    `;
export const InvoiceAction_ProjectFragmentDoc = gql`
    fragment InvoiceAction_Project on Project {
  id
  viewerPermissions
}
    `;