import { LabeledValue } from "@msys/ui";
import { SvgIcon, Typography } from "@mui/material";
import React from "react";
import { useSalutation } from "../../features/users/useSalutation.js";
import { Salutation } from "../../../clients/graphqlTypes.js";
import { assertNever } from "@msys/common";

interface Props {
  label: string;
  contact: {
    companyOrIndividual: "INDIVIDUAL" | "COMPANY";
    companyName: string;
    salutation: Salutation;
    firstname: string;
    familyname: string;
    contact?:
      | {
          salutation: Salutation;
          firstname: string;
          familyname: string;
        }
      | undefined
      | null;
  };
  align?: React.ComponentProps<typeof Typography>["align"];
  icon?: typeof SvgIcon;
}

export const LabeledContactValue = ({ label, contact, align, icon }: Props) => {
  const { getNameWithSalutation } = useSalutation();

  const value = React.useMemo(() => {
    if (contact.companyOrIndividual === "INDIVIDUAL") {
      return getNameWithSalutation(
        contact.salutation,
        contact.firstname,
        contact.familyname
      );
    } else if (contact.companyOrIndividual === "COMPANY") {
      const contactInfo = contact.contact
        ? getNameWithSalutation(
            contact.contact.salutation,
            contact.contact.firstname,
            contact.contact.familyname
          )
        : "";

      return contact.companyName + (contactInfo ? ", " + contactInfo : "");
    } else {
      assertNever(contact.companyOrIndividual);
    }
  }, [getNameWithSalutation, contact]);

  return (
    <LabeledValue label={label} icon={icon} align={align}>
      {value}
    </LabeledValue>
  );
};
