import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import React from "react";
import { useAllProjectContactsQuery } from "./useAllProjectContacts.generated.js";

export function useAllProjectContacts(projectId: string) {
  const client = useApolloClient();
  const query = useAllProjectContactsQuery({
    client,
    variables: { projectId },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const contacts = React.useMemo(
    () =>
      project
        ? [
            ...project.contractors.flatMap(({ members, ...contractorCrm }) => [
              contractorCrm,
              ...members,
            ]),
            ...(project.invitingContractor
              ? [
                  project.invitingContractor,
                  ...project.invitingContractor.members,
                ]
              : []),
            ...(project.crmOrganisation
              ? [project.crmOrganisation, ...project.crmOrganisation.members]
              : []),
            ...project.crmOrganisationLinks.map(
              ({ crmOrganisation: { members, ...crmOrganisation } }) =>
                crmOrganisation
            ),
            ...project.crmUserLinks.map(link => link.crmUser),
          ]
        : [],
    [project]
  );

  return { contacts, isLoading: query.loading };
}
