import { gql, useApolloClient } from "@apollo/client";
import {
  CardContainer,
  getFormattedPercentage,
  getFormattedPrice,
  underlineDoubleStyle,
} from "@msys/ui";
import { Box, Stack, Typography } from "@mui/material";
import { useTolgee } from "@tolgee/react";
import { BigNumber } from "bignumber.js";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../commons/form-fields/AutoSave.js";
import { FormattedPercentageField } from "../../commons/form-fields/FormattedPercentageField.js";
import { FormattedPriceField } from "../../commons/form-fields/FormattedPriceField.js";
import {
  InvoiceType,
  namedOperations,
  PermissionName,
} from "../../../clients/graphqlTypes.js";
import { ItemCalculationFragment } from "../doc-items/Fragments.generated.js";
import { InvoiceCalculationFragment } from "./Fragments.generated.js";
import { useModifyInvoicePaymentItemMutation } from "./InvoicePaymentItem.generated.js";
import { InvoicePaymentItemDetails_InvoicePaymentItemFragment } from "./Invoices.generated.js";
import { useTranslate } from "@tolgee/react";
import { useInvoiceTypes } from "./hooks/useInvoiceTypes.js";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";

type FormValues = {
  totalPricePercentage: number;
  price: number;
};

interface Props {
  quoteId: string;
  projectId: string;
  invoiceId: string;
  invoiceType: InvoiceType;
  invoiceCalculation: InvoiceCalculationFragment;
  invoiceNonInvoicedQuoteAmount: number;
  paymentItem: InvoicePaymentItemDetails_InvoicePaymentItemFragment;
  quoteCalculation: ItemCalculationFragment;
  quoteDiscountPercentage: number;
  project: { viewerPermissions: PermissionName[] };
}

export const InvoicePaymentItem = ({
  quoteId,
  projectId,
  invoiceId,
  invoiceType,
  invoiceCalculation,
  invoiceNonInvoicedQuoteAmount,
  paymentItem,
  quoteCalculation,
  quoteDiscountPercentage,
  project,
}: Props) => {
  const { t } = useTranslate(["InvoiceDetailsForm", "QuotePriceSummary"]);
  const language = useTolgee(["language"]).getLanguage()!;
  const { enqueueSnackbar } = useSnackbar();

  const { invoicePaymentLabels } = useInvoiceTypes();

  const client = useApolloClient();
  const [modifyInvoicePaymentItem] = useModifyInvoicePaymentItemMutation({
    client,
  });

  const initialValues = React.useMemo(
    (): FormValues => ({
      totalPricePercentage: paymentItem.totalPricePercentage,
      price: paymentItem.price,
    }),
    [paymentItem.price, paymentItem.totalPricePercentage]
  );

  const validationSchema = Yup.object().shape({
    totalPricePercentage: Yup.number()
      .label(
        t("Percentage", {
          ns: "QuotePriceSummary",
        })
      )
      .min(0)
      .max(1),
    price: Yup.number()
      .label(
        t("Amount", {
          ns: "QuotePriceSummary",
        })
      )
      .min(0)
      .max(invoiceNonInvoicedQuoteAmount),
  });

  const showDiscount = quoteDiscountPercentage !== 0;

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyInvoicePaymentItem({
        variables: {
          input: {
            docId: quoteId,
            projectId,
            invoiceId,
            invoicePaymentItemId: paymentItem.id,
            values: {
              price: values.price,
              totalPricePercentage: values.totalPricePercentage,
            },
          },
        },
        refetchQueries: [
          namedOperations.Query.ProjectOutgoingInvoice, // FIXME: why is this needed?
        ],
        awaitRefetchQueries: true,
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <CardContainer
      title={t("Totals", {
        ns: "QuotePriceSummary",
      })}
      isExpandable
      CollapsedHint={
        <Typography variant="h3" component="div">
          {getFormattedPrice(invoiceCalculation?.priceTotal ?? 0, language)}
        </Typography>
      }
    >
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => (
          <Form>
            <Stack direction="column" p={1} spacing={2}>
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography variant="h4" component="div">
                    {t("Quote subtotal", {
                      ns: "QuotePriceSummary",
                    })}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    style={{ textDecoration: "underline" }}
                  >
                    {getFormattedPrice(
                      quoteCalculation.priceSubTotal,
                      language
                    )}
                  </Typography>
                </Stack>

                {showDiscount && (
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <span>
                      {t("Discount", {
                        ns: "QuotePriceSummary",
                      })}{" "}
                      (
                      {getFormattedPercentage(
                        quoteDiscountPercentage,
                        language
                      )}
                      )
                    </span>
                    <span>
                      −{" "}
                      {getFormattedPrice(
                        quoteCalculation.priceSubTotal -
                          quoteCalculation.priceNetTotal,
                        language
                      )}
                    </span>
                  </Stack>
                )}
                {showDiscount && (
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Typography variant="h4" component="div">
                      {t("Quote net price", {
                        ns: "QuotePriceSummary",
                      })}
                    </Typography>
                    <Typography variant="h4" component="div">
                      {getFormattedPrice(
                        quoteCalculation.priceNetTotal,
                        language
                      )}
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    flex={0.6}
                  >
                    <Box flex={1}>{invoicePaymentLabels[invoiceType]}</Box>
                    <Box flex={0}>
                      <RestrictedByProjectPermissionWithDebug
                        project={project}
                        permission="MANAGE_INVOICES"
                        otherwise={
                          <span>
                            {getFormattedPercentage(
                              formikProps.values.totalPricePercentage,
                              language
                            )}
                          </span>
                        }
                      >
                        <FormattedPercentageField
                          disabled={false}
                          label=""
                          name="totalPricePercentage"
                          min={0}
                          max={100}
                          InputProps={{ style: { width: "8ch" } }}
                          size="extra-small"
                          setFieldValue={(name, value) => {
                            const price = new BigNumber(
                              quoteCalculation.priceNetTotal
                            )
                              .multipliedBy(value)
                              .toFixed(2);
                            formikProps.setValues({
                              price: parseFloat(price),
                              totalPricePercentage: value,
                            });
                          }}
                        />
                      </RestrictedByProjectPermissionWithDebug>
                    </Box>
                  </Stack>

                  <Box display="flex" flex={0.4} justifyContent="flex-end">
                    <RestrictedByProjectPermissionWithDebug
                      project={project}
                      permission="MANAGE_INVOICES"
                      otherwise={
                        <span>
                          {getFormattedPrice(
                            formikProps.values.price,
                            language
                          )}
                        </span>
                      }
                    >
                      <FormattedPriceField
                        disabled={false}
                        name="price"
                        label=""
                        type="price"
                        min={0}
                        FormHelperTextProps={{
                          style: { textAlign: "right" },
                        }}
                        style={{ alignItems: "flex-end" }}
                        size="extra-small"
                        setFieldValue={(name, value) => {
                          if (quoteCalculation.priceNetTotal === 0) {
                            formikProps.setFieldValue(name, value);
                            return;
                          }
                          const percentage = new BigNumber(value)
                            .dividedBy(quoteCalculation.priceNetTotal)
                            .toFixed(6);
                          formikProps.setValues({
                            price: value,
                            totalPricePercentage: parseFloat(percentage),
                          });
                        }}
                        InputProps={{ style: { width: "12ch" } }}
                      />
                    </RestrictedByProjectPermissionWithDebug>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <span>
                    {t("VAT", {
                      ns: "QuotePriceSummary",
                    })}{" "}
                    (
                    {getFormattedPercentage(
                      invoiceCalculation.vatRate,
                      language
                    )}
                    )
                  </span>
                  <span>
                    +{" "}
                    {getFormattedPrice(
                      invoiceCalculation.priceVatTotal,
                      language
                    )}
                  </span>
                </Stack>
              </Stack>

              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography variant="h3" component="div">
                  {t("Total", {
                    ns: "QuotePriceSummary",
                  })}
                </Typography>

                <Typography
                  variant="h3"
                  component="div"
                  style={underlineDoubleStyle}
                >
                  {getFormattedPrice(invoiceCalculation.priceTotal, language)}
                </Typography>
              </Stack>

              <AutoSave />
            </Stack>
          </Form>
        )}
      </Formik>
    </CardContainer>
  );
};
