import { getImageUrl } from "@msys/common";
import { ellipsisStyle, TypographyWithMaxNumberOfLines } from "@msys/ui";
import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import defaultImageUrl from "../../assets/images/no-image.png";
import { useTranslate } from "@tolgee/react";
import { isPredefinedTopic, useContentTopics } from "./useContentTopics.js";

export const ArticlePreviewItem = ({
  title,
  group,
  previewImageUrl,
  MenuButton,
  onClick,
}: {
  title: string;
  group: string;
  previewImageUrl?: string;
  MenuButton?: React.ReactNode;
  onClick(): void;
}) => {
  const { t } = useTranslate("Contents");
  const { topicIcons } = useContentTopics();
  const Icon = group && isPredefinedTopic(group) ? topicIcons[group] : null;

  return (
    <ButtonBase
      onClick={onClick}
      sx={theme => ({
        textAlign: "left",
        transition: "background-color 0.2s ease-out",
        backgroundColor: theme.palette.common.white,
        ["&:hover:not(:has(button:hover)):not(:has(a:hover))"]: {
          backgroundColor: theme.palette.grey[100],
        },
        ["&:active:not(:has(button:active)):not(:has(a:active))"]: {
          backgroundColor: theme.palette.grey[200],
        },
        p: 0.5,
        borderRadius: "4px",
        width: "100%",
        display: "flex",
        justifyContent: "stretch",
      })}
      focusRipple
    >
      <Stack spacing={1.5} direction="row" flex={1}>
        <Box
          flexShrink="0"
          flexGrow="0"
          height={84}
          width={120}
          overflow="hidden"
          display="flex"
        >
          <img
            draggable={false}
            src={getImageUrl({
              url: previewImageUrl || defaultImageUrl,
              width: 240,
            })}
            alt={t("Content cover photo")}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "2px",
            }}
          />
        </Box>
        <Stack flex={1} direction="column">
          <Stack
            justifyContent="space-between"
            alignItems="flex-start"
            direction="row"
            spacing={1}
            flex={1}
            minWidth={0}
          >
            <Stack
              flex={1}
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={0.5}
              minWidth={0}
              py={1}
            >
              {group ? (
                <Typography
                  variant="caption"
                  color="warning.main"
                  fontWeight="bold"
                  style={ellipsisStyle}
                  component="div"
                >
                  {Icon ? (
                    <Stack direction="row" spacing={0.5} style={ellipsisStyle}>
                      <Icon fontSize="extra-small" />
                      <span style={ellipsisStyle}>{group}</span>
                    </Stack>
                  ) : (
                    group
                  )}
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  color="grey.600"
                  fontWeight="bold"
                  style={ellipsisStyle}
                  component="div"
                >
                  {t("Other")}
                </Typography>
              )}
              <TypographyWithMaxNumberOfLines variant="h4" $maxLines={2} my={1}>
                {title}
              </TypographyWithMaxNumberOfLines>
            </Stack>
            {MenuButton}
          </Stack>
        </Stack>
      </Stack>
    </ButtonBase>
  );
};
