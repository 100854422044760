import { CardContainer, LabeledTextValue } from "@msys/ui";
import { List } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Stack } from "../../../commons/layout/Stack.js";
import { ProfileListItem } from "../../../commons/ProfileListItem.js";
import { ProductOverview__ProductFragment } from "../Product.generated.js";
import { useTranslate } from "@tolgee/react";
import { ContactsIcon } from "../../crm-persons/ContactsIcon.js";
import { OrganisationAvatar } from "../../organisations/OrganisationAvatar.js";
import { ProductPricing } from "../ProductPricing.js";

interface Props {
  product: ProductOverview__ProductFragment;
  isEditable?: boolean;
}

export const ProductOverviewSupplierBox: FC<Props> = ({
  product,
  isEditable,
}) => {
  const { t } = useTranslate(["ProductOverview", "Global"]); // TODO: adjust

  return (
    // <ShowBox
    //   box={{
    //     name: "project-overview-header",
    //     project,
    //   }}
    // >
    // </ShowBox>
    <CardContainer
      Icon={<ContactsIcon />}
      title={t("Supplier", {
        ns: "ProductOverview",
      })}
      isExpandable
      isInitiallyClosed={false}
    >
      <Stack flexDirection="column" width="100%" spacing={0}>
        <List disablePadding>
          <ProfileListItem
            size="s"
            button
            component={Link}
            to={`/globalsuppliers/${product.supplier.id}`}
            avatar={
              <OrganisationAvatar
                size="s"
                organisationAvatar={product.supplier}
              />
            }
            primary={product.supplier.title}
          />
        </List>
      </Stack>
      <Stack p={1} flexDirection="column">
        <Stack flexDirection="row">
          <LabeledTextValue
            label={t("Article No", { ns: "ProductOverview" })}
            text={product.articleNumber}
            notSetLabel={t("Not set", { ns: "Global" })}
            showMoreLabel={t("Show more", { ns: "Global" })}
            showLessLabel={t("Show less", { ns: "Global" })}
          />
          {product.__typename === "PimSupplierProduct" && (
            <LabeledTextValue
              label={t("Catalogue", { ns: "ProductOverview" })}
              text={product.supplierCatalogue.title}
              notSetLabel={t("Not set", { ns: "Global" })}
              showMoreLabel={t("Show more", { ns: "Global" })}
              showLessLabel={t("Show less", { ns: "Global" })}
            />
          )}
          {product.__typename === "PimCustomSupplierProduct" &&
            product.productGroup && (
              <LabeledTextValue
                label={t("Product group", { ns: "ProductOverview" })}
                text={`${product.productGroup.key} - ${product.productGroup.description}`}
                notSetLabel={t("Not set", { ns: "Global" })}
                showMoreLabel={t("Show more", { ns: "Global" })}
                showLessLabel={t("Show less", { ns: "Global" })}
              />
            )}
          {product.__typename === "PimCustomSupplierProduct" &&
            product.productGroup?.mainProductGroup && (
              <LabeledTextValue
                label={t("Main product group", { ns: "ProductOverview" })}
                text={`${product.productGroup.mainProductGroup.key} - ${product.productGroup.mainProductGroup.description}`}
                notSetLabel={t("Not set", { ns: "Global" })}
                showMoreLabel={t("Show more", { ns: "Global" })}
                showLessLabel={t("Show less", { ns: "Global" })}
              />
            )}
        </Stack>
        <ProductPricing product={product} hideVisibility={true} />
      </Stack>
    </CardContainer>
  );
};
