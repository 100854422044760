import { Modal, useScreenWidth } from "@msys/ui";
import { Alert, DialogContentText } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash-es";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import SignatureCanvas from "react-signature-canvas";
import { useMeasure } from "react-use";
import * as Yup from "yup";
import { useTranslate } from "@tolgee/react";
import { DatePickerField } from "../form-fields/DatePickerField.js";
import { Stack } from "../layout/Stack.js";

interface Props {
  title?: string;
  description?: string;
  defaultPlace?: string;
  defaultDate?: any;
  handleClose: () => void;
  handleComplete: (values: FormValues) => void | Promise<void>;
}

interface FormValues {
  signature: string;
  place: string;
  date: moment.Moment;
}

export function AddSignatureModal({
  title,
  description,
  defaultPlace,
  defaultDate,
  handleClose,
  handleComplete,
}: Props) {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { t } = useTranslate(["SignatureModal", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const [
    canvasWrapperRef,
    { width: canvasWrapperWidth, height: canvasWrapperHeight },
  ] = useMeasure<HTMLDivElement>();
  const signatureRef = React.useRef(null as any);
  const formId = React.useMemo(() => uniqueId(), []);

  const now = React.useMemo(() => moment(), []);
  const { isMaxPhone } = useScreenWidth();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        date: Yup.object()
          .label(
            t("Date", {
              ns: "SignatureModal",
            })
          )
          .required(),
        place: Yup.string()
          .label(
            t("Place", {
              ns: "SignatureModal",
            })
          )
          .required(),
        signature: Yup.string()
          .label(
            t("Signature", {
              ns: "SignatureModal",
            })
          )
          .required(),
      }),
    [t]
  );

  const initialValues: FormValues = {
    date: moment(defaultDate ?? now),
    place: defaultPlace ?? "",
    signature: "",
  };

  return (
    <Modal
      title={
        title ??
        t("Add signature", {
          ns: "SignatureModal",
        })
      }
      dialogProps={{ maxWidth: "sm" }}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Submit", {
            ns: "Global",
          }),
          buttonProps: {
            type: "submit",
            form: formId,
            loading: isSubmitting,
            disabled: isSubmitting,
          },
        },
      ]}
      handleClose={handleClose}
    >
      {description ? (
        <DialogContentText>{description}</DialogContentText>
      ) : null}
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: FormValues) => {
          if (isSubmitting) return;
          setSubmitting(true);
          try {
            await handleComplete(values);
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          setFieldValue,
          values,
          isValid,
          errors,
        }: FormikProps<FormValues>) => (
          <Form id={formId}>
            <Stack flexDirection="column" width="100%" alignItems="stretch">
              <div
                style={{
                  width: "100%",
                  paddingBottom: "50%" /* 2:1 aspect ratio */,
                  position: "relative",
                }}
              >
                <div
                  ref={canvasWrapperRef}
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    border: isMaxPhone ? "2px solid black" : "1px solid black",
                  }}
                >
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    velocityFilterWeight={0.3}
                    canvasProps={{
                      width: canvasWrapperWidth,
                      height: canvasWrapperHeight,
                    }}
                    onEnd={() => {
                      if (signatureRef)
                        setFieldValue(
                          "signature",
                          signatureRef.current
                            .getCanvas()
                            .toDataURL("image/png")
                        );
                    }}
                  />
                </div>
              </div>

              {!isValid && errors.signature && (
                <Alert severity="error" hidden={!errors.signature}>
                  <div>{errors.signature}</div>
                </Alert>
              )}
              <Stack>
                <Field
                  required
                  name="place"
                  label={t("Place", {
                    ns: "SignatureModal",
                  })}
                  component={TextField}
                  disabled={false}
                />
                <DatePickerField
                  required
                  name="date"
                  label={t("Date", {
                    ns: "SignatureModal",
                  })}
                  disabled={true}
                />
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
