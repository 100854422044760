import { useApolloClient } from "@apollo/client";
import {
  usePendingDocSharesBox_AcceptDocShareMutation,
  usePendingDocSharesBox_PendingDocSharesQuery,
} from "./PendingDocSharesBox.generated.js";

export function PendingDocSharesBox() {
  const client = useApolloClient();

  const pendingDocSharesQuery = usePendingDocSharesBox_PendingDocSharesQuery({
    client,
  });
  const [acceptDocShare] = usePendingDocSharesBox_AcceptDocShareMutation({
    client,
  });

  return (
    <div style={{ border: "1px solid black" }}>
      <h1>pending doc sharings</h1>
      <hr />
      <ul>
        {pendingDocSharesQuery.data?.pendingDocShares.map(pds => (
          <li key={pds.id}>
            <div>
              <strong>id:</strong>
              {pds.id}
            </div>
            <div>
              <strong>docTitle:</strong>
              {pds.docTitle}
            </div>
            <div>
              <strong>recipientSystemOrganisationId:</strong>
              {pds.recipientSystemOrganisationId}
            </div>
            <div>
              <strong>recipientSystemOrganisationTitle:</strong>
              {pds.recipientSystemOrganisationTitle}
            </div>
            <div>
              <strong>recipientPermissions:</strong>
              {pds.recipientPermissions.join(",")}
            </div>
            <div>
              <strong>sharingSystemOrganisationId:</strong>
              {pds.sharingSystemOrganisationId}
            </div>
            <div>
              <strong>sharingSystemOrganisationTitle:</strong>
              {pds.sharingSystemOrganisationTitle}
            </div>
            <div>
              <strong>docOwningSystemOrganisationId:</strong>
              {pds.docOwningSystemOrganisationId}
            </div>
            <div>
              <strong>docOwningSystemOrganisationTitle:</strong>
              {pds.docOwningSystemOrganisationTitle}
            </div>
            <button
              type="button"
              onClick={async e => {
                e.preventDefault();

                const acceptInProjectId = prompt("acceptInProjectId");

                if (acceptInProjectId) {
                  await acceptDocShare({
                    variables: {
                      input: {
                        id: pds.id,
                        acceptInProjectId,
                      },
                    },
                  });

                  await client.reFetchObservableQueries();
                }
              }}
            >
              accept
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
