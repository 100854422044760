import { SelectMultiple } from "@msys/ui";
import { FilterProps } from "../../../commons/filters/index.js";
import {
  FilterChipGroup,
  FilterChipGroupProps,
} from "../../../commons/filters/FilterChip.js";
import { ProjectType } from "../../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";
import { useProjectTypes } from "../useProjectTypes.js";

export function FilterProjectType({
  value,
  setValue,
  ...props
}: FilterProps<ProjectType[]>) {
  const { t } = useTranslate("ProjectTypes");
  const { projectTypeOptions } = useProjectTypes();

  return (
    <SelectMultiple
      label={t("Type")}
      options={projectTypeOptions}
      value={Array.isArray(value) ? value : [value]}
      onChange={setValue}
      {...props}
    />
  );
}

export function FilterProjectTypeChips(
  props: Omit<FilterChipGroupProps<ProjectType>, "label" | "getValueLabel">
) {
  const { t } = useTranslate("ProjectTypes");
  const { projectTypeLabels } = useProjectTypes();

  return (
    <FilterChipGroup
      {...props}
      getValueLabel={value => projectTypeLabels[value]}
      label={t("Type")}
    />
  );
}
