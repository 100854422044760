import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ConfirmModalProps } from "../../../commons/modals/ConfirmProcess.js";
import { DocType, ItemType } from "../../../../clients/graphqlTypes.js";
import { CopyItemIntoClipboardMenuItem } from "./CopyItemIntoClipboardMenuItem.js";
import { DeleteItemMenuItem } from "./DeleteItemMenuItem.js";
import { DocItemContextMenuItems_ItemFragment } from "./DocItemContextMenuItems.generated.js";
import { PasteItemFromClipboardMenuItem } from "./PasteItemFromClipboardMenuItem.js";
import { AdditionalItemInput } from "../../../trees-virtual/types.js";
import {
  AddAdditionalItemAboveMenuItem,
  AddAdditionalItemBelowMenuItem,
} from "./AddAdditionalItemMenuItem.js";

interface Props {
  projectId: string | null;
  docId: string;
  docType: DocType;
  pathToDocPage: string;
  item: DocItemContextMenuItems_ItemFragment;
  itemParentType: ItemType | null;
  disabled?: boolean;
  startConfirmProcess: (
    props: ConfirmModalProps
  ) => Promise<boolean | undefined>;
  pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
  expandedItemIds: string[] | undefined;
  onAdditionalItemInputChange: ((input: AdditionalItemInput) => void) | null;
}

export const DocItemContextMenuItems = ({
  projectId,
  docId,
  docType,
  pathToDocPage,
  item,
  itemParentType,
  disabled,
  startConfirmProcess,
  expandedItemIds,
  pasteItem,
  onAdditionalItemInputChange,
}: Props) => {
  const navigate = useNavigate();

  const canEdit = !item.deletedAt;
  const canDelete = !item.deletedAt && !item.isRootItem;

  const isLinkingItem = Boolean(item.linkedQuoteTemplate);

  return (
    <>
      <CopyItemIntoClipboardMenuItem
        itemId={item.id}
        itemType={item.type}
        docId={docId}
        docType={docType}
        projectId={projectId}
        disabled={disabled || !canEdit || isLinkingItem}
      />
      <PasteItemFromClipboardMenuItem
        itemId={item.id}
        docId={docId}
        disabled={disabled || !canEdit}
        pasteItem={pasteItem}
      />
      <DeleteItemMenuItem
        projectId={projectId}
        docId={docId}
        itemId={item.id}
        disabled={disabled || !canDelete}
        startConfirmProcess={startConfirmProcess}
        onItemDeleted={() => {
          navigate(pathToDocPage, { replace: true });
        }}
        expandedItemIds={expandedItemIds}
      />
      {onAdditionalItemInputChange &&
      !item.isRootItem &&
      item.parentId &&
      itemParentType ? (
        <>
          <Divider />
          <AddAdditionalItemAboveMenuItem
            itemId={item.id}
            itemParentId={item.parentId}
            itemParentType={itemParentType}
            itemRank={item.rank}
            disabled={disabled}
            onAdditionalItemInputChange={onAdditionalItemInputChange}
          />
          <AddAdditionalItemBelowMenuItem
            itemId={item.id}
            itemParentId={item.parentId}
            itemParentType={itemParentType}
            itemRank={item.rank}
            disabled={disabled}
            onAdditionalItemInputChange={onAdditionalItemInputChange}
          />
        </>
      ) : null}
    </>
  );
};
