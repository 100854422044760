import { Modal } from "@msys/ui";
import { DialogContentText } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";

interface Props {
  organisationId: string;
  title: string;
  handleClose: () => void;
  isLoading: boolean;
}

export const ModifyOrganisationTextsModal = ({
  organisationId,
  title,
  handleClose,
  isLoading,
  children,
}: React.PropsWithChildren<Props>) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  const viewer = useUserData().currentUser!;

  if (viewer.organisation.id !== organisationId)
    return <div>Not own organisation!</div>;

  return (
    <Modal
      title={title}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Close", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "contained" },
        },
      ]}
      isLoading={isLoading}
    >
      <DialogContentText>
        {t("Add or edit details of your document", {
          ns: "OrganisationSettings",
        })}
      </DialogContentText>
      {children}
    </Modal>
  );
};
