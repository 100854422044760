import React from "react";
import { Switch } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useApolloClient } from "@apollo/client";
import {
  usePublishRequestToMarketplaceMutation,
  useUnpublishRequestToMarketplaceMutation,
} from "./Requests.generated.js";

interface Props {
  projectId: string;
  requestId: string;
  marketplace: boolean;
  canEdit: boolean;
}

export const RequestPublishToMarketplaceSwitch = ({
  projectId,
  requestId,
  marketplace,
  canEdit,
}: Props) => {
  const client = useApolloClient();
  const { t } = useTranslate(["Global", "Requests"]);

  const [publishRequestToMarketplace, { loading: publishLoading }] =
    usePublishRequestToMarketplaceMutation({
      client,
    });

  const [unpublishRequestToMarketplace, { loading: unpublishLoading }] =
    useUnpublishRequestToMarketplaceMutation({ client });

  return (
    <Switch
      label={t("Make visible in the open Marketplace", {
        ns: "Requests",
      })}
      checked={marketplace}
      onChange={async (
        e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
      ) => {
        if (checked) {
          await publishRequestToMarketplace({
            variables: {
              input: { requestId, projectId },
            },
          });
        } else {
          await unpublishRequestToMarketplace({
            variables: {
              input: { requestId, projectId },
            },
          });
        }
      }}
      disabled={!canEdit || publishLoading || unpublishLoading}
    />
  );
};
