import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PageColumn } from "./PageColumn.js";
import { PageSelectedSection } from "./PageSelectedSection.js";

interface Props {
  isSelected?: boolean;
  isAbsolute?: boolean;
  hasBackground?: boolean;
  hasLeftBorder?: boolean;
  hasRightBorder?: boolean;
  hasSelectedBorder?: boolean;
  size?: "sm" | "md" | "lg";
  zIndex?: number;
  title?: React.ReactNode;
  onCloseButtonClick?: () => void;
  headerActions?: React.ReactNode;
  headerButtons?: React.ReactNode;
}

export const PageMiddleColumn = ({
  children,
  isSelected = false,
  isAbsolute,
  hasBackground,
  hasLeftBorder,
  hasRightBorder,
  hasSelectedBorder,
  size,
  zIndex = 20,
  title,
  onCloseButtonClick,
  headerActions,
  headerButtons,
}: React.PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const SelectedWrapper = isSelected ? PageSelectedSection : React.Fragment;

  return (
    <PageColumn
      $hasRightBorder={hasRightBorder}
      $hasLeftBorder={hasLeftBorder}
      $hasBackground={hasBackground}
      $hasSelectedBorder={hasSelectedBorder}
      $isAbsolute={isAbsolute}
      $isSelected={isSelected}
      $size={size}
      $zIndex={zIndex}
      sx={{
        [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
          padding: `0 ${theme.spacing(1)}`,
        },
      }}
    >
      <SelectedWrapper>
        {title ? (
          <Box position="sticky" top={0} zIndex={4}>
            <Box bgcolor={theme.palette.background.default} borderRadius="6px">
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingX={1.5}
                paddingY={1}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Typography variant={"h2"}>{title}</Typography>
                  {headerButtons}
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                  {headerActions}
                  <IconButton
                    style={{
                      width: 40,
                      height: 40,
                      flexShrink: 0,
                      flexGrow: 0,
                    }}
                    aria-label="Close"
                    color="default"
                    onClick={onCloseButtonClick ?? (() => navigate(-1))}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider />
            </Box>
          </Box>
        ) : null}
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          padding={1}
          boxSizing="border-box"
        >
          {children}
        </Box>
      </SelectedWrapper>
    </PageColumn>
  );
};
