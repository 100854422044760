import { CloseFullscreen as CloseFullscreenIcon } from "@mui/icons-material";
import { Fullscreen as FullscreenIcon } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";

interface Props {
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
}

export const FullScreenToggleIconButton = ({
  isFullScreen,
  setIsFullScreen,
}: Props) => {
  const { t } = useTranslate("Global");
  return (
    <Tooltip
      title={isFullScreen ? t("Exit full screen") : t("Enter full screen")}
    >
      <Box display="flex" component="span">
        <IconButton
          color="primary"
          size="small"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setIsFullScreen(!isFullScreen);
          }}
        >
          {isFullScreen ? <CloseFullscreenIcon /> : <FullscreenIcon />}
        </IconButton>
      </Box>
    </Tooltip>
  );
};
