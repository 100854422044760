import {
  CardContainer,
  LabeledValue,
  ModalOpenButton,
  TextWithBreaks,
} from "@msys/ui";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import {
  PermissionName,
  namedOperations,
} from "../../../../clients/graphqlTypes.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { RequestAdditionalInfoModal } from "../modals/RequestAdditionalInfoModal.js";
import { RequestAdditionalInfoModalFragment } from "../modals/RequestAdditionalInfoModal.generated.js";
import { RequestBiddingDetailsBoxFragment } from "./RequestBiddingDetailsBox.generated.js";

export const RequestBiddingDetailsBox = ({
  request,
  project,
}: {
  request: RequestBiddingDetailsBoxFragment &
    RequestAdditionalInfoModalFragment;
  project: { id: string; viewerPermissions: PermissionName[] };
}) => {
  const { t } = useTranslate(["Requests", "Global"]);

  return (
    <CardContainer
      title={t("Bidding details", {
        ns: "Requests",
      })}
      ActionButton={
        request.status === "OPEN" ? (
          <RestrictedByProjectPermissionWithDebug
            permission="SHARE_REQUIREMENTS"
            project={project}
          >
            <ModalOpenButton
              Modal={RequestAdditionalInfoModal}
              modalProps={{
                request,
                projectId: project.id,
                refetchQueries: [namedOperations.Query.ProjectRequest],
              }}
            >
              <IconButton color="primary" size="small">
                <EditIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByProjectPermissionWithDebug>
        ) : undefined
      }
    >
      <Stack flexDirection="column" p={1}>
        <LabeledValue
          label={t("Bidding instructions", {
            ns: "Requests",
          })}
        >
          {request.instructions ? (
            <TextWithBreaks text={request.instructions} />
          ) : (
            <Typography
              variant="body2"
              sx={{ color: theme => theme.palette.grey[600] }}
            >
              {t("Not set", {
                ns: "Global",
              })}
            </Typography>
          )}
        </LabeledValue>
      </Stack>
    </CardContainer>
  );
};
