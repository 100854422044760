import { Alert, AlertTitle } from "@mui/material";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { DiscardPendingChangesButton } from "../buttons/DiscardPendingChangesButton.js";

export const QuoteChangeDeclinedAlert = ({
  projectId,
  quoteId,
  itemId,
  reason,
  refetchQueries,
}: {
  projectId: string;
  quoteId: string;
  itemId: string;
  reason: string;
  refetchQueries?: string[];
}) => {
  const { t } = useTranslate("QuoteEdit");

  return (
    <Alert
      variant="outlined"
      severity="warning"
      action={
        <DiscardPendingChangesButton
          projectId={projectId}
          quoteId={quoteId}
          itemId={itemId}
          refetchQueries={refetchQueries}
          color="inherit"
          size="small"
          variant="text"
          style={{ textAlign: "center" }}
        />
      }
    >
      <AlertTitle>
        {t("The proposed changes have been declined by the client")}
      </AlertTitle>
      {t("Given reason:")}
      <br />
      <q>{reason}</q>
    </Alert>
  );
};
