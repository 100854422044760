import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { BuildingItemLinksFragment } from "../buildings/boxes/BuildingContactsBox.generated.js";
import { CrmCompanyOrPersonSelect } from "../crm-companies/CrmCompanyOrPersonSelect.js";
import {
  CrmCompanyOrPersonSelectDocument,
  CrmCompanyOrPersonSelectQuery,
  CrmCompanyOrPersonSelectQueryVariables,
} from "../crm-companies/CrmCompanyOrPersonSelect.generated.js";
import { CrmContactType } from "../crm/useCrmContactTypes.js";
import { ProjectContactLinksFragment } from "../projects/boxes/ProjectOverviewContactsBox.generated.js";
import { RelationshipRoleSelect } from "./RelationshipRoleSelect.js";

export type ContactType = "user" | "organisation";

type ExistingContactLink =
  | ProjectContactLinksFragment
  | BuildingItemLinksFragment;
type ExistingContactLinks = ExistingContactLink[];

const ALLOWED_CONTACT_TYPES: CrmContactType[] = ["INDIVIDUAL", "COMPANY"];

interface FormValues {
  contactId: string | null;
  role: string | null;
}

interface Props {
  title?: string;
  existingContactLinks?: ExistingContactLinks;
  handleComplete: (
    contactType: ContactType,
    contactId: string,
    role: string
  ) => Promise<void> | void;
  handleClose: () => void;
}

export const AddContactRelationshipModal = ({
  title,
  existingContactLinks = [],
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate([
    "CrmUsers",
    "CrmOrganisations",
    "Global",
    "ProjectCreate",
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const { defineContactType } = useDefineContactType();

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues = {
    contactId: null,
    role: null,
  };

  const validationSchema = Yup.object().shape({
    contactId: Yup.string()
      .nullable()
      .label(t("Contact", { ns: "CrmOrganisations" }))
      .required(),
    role: Yup.string()
      .nullable()
      .label(t("Role", { ns: "CrmUsers" }))
      .required(),
  });

  const handleSubmit = async (values: FormValues) => {
    if (!values.contactId || !values.role) return;

    const contactType = await defineContactType(values.contactId);
    if (!contactType) throw new Error("Cannot define contact type");

    await handleComplete(contactType, values.contactId, values.role);
    handleClose();
    enqueueSnackbar(t("Contact added", { ns: "CrmUsers" }));
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const existingCompanyLinks =
          existingContactLinks.filter(isCrmCompanyLink);
        const existingPersonLinks =
          existingContactLinks.filter(isCrmPersonLink);

        const excludeCrmCompanyIds = formikProps.values.role
          ? existingCompanyLinks
              .filter(link => link.linkAs === formikProps.values.role)
              .map(link => link.crmOrganisation.id)
          : null;

        const excludeCrmPersonIds = formikProps.values.role
          ? existingPersonLinks
              .filter(link => link.linkAs === formikProps.values.role)
              .map(link => link.crmUser.id)
          : null;

        const excludeIds = [
          ...(excludeCrmCompanyIds ?? []),
          ...(excludeCrmPersonIds ?? []),
        ];

        return (
          <Modal
            title={
              title ??
              t("Add contact", {
                ns: "CrmUsers",
              })
            }
            handleClose={handleClose}
            actionButtons={[
              {
                label: t("Cancel", { ns: "Global" }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Add", { ns: "Global" }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  loading: formikProps.isSubmitting,
                  disabled: !formikProps.isValid || !formikProps.dirty,
                },
              },
            ]}
          >
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                <CrmCompanyOrPersonSelect
                  crmCompanyOrPersonId={formikProps.values.contactId}
                  onChange={contactId => {
                    formikProps.setFieldValue("contactId", contactId);
                  }}
                  crmCompanyOrPersonExcludeIds={excludeIds}
                  canCreateNew
                  createNewLabel={t("Add new contact", { ns: "ProjectCreate" })}
                  allowedContactTypesForCreate={ALLOWED_CONTACT_TYPES}
                  error={formikProps.errors?.contactId}
                  disabled={formikProps.isSubmitting}
                />
                <RelationshipRoleSelect
                  value={formikProps.values.role}
                  onChange={role => {
                    formikProps.setFieldValue("role", role);
                  }}
                  error={formikProps.errors?.role}
                  disabled={formikProps.isSubmitting}
                />
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

function useDefineContactType() {
  const client = useApolloClient();

  const defineContactType = async (
    contactId: string
  ): Promise<ContactType | null> => {
    const result = await client.query<
      CrmCompanyOrPersonSelectQuery,
      CrmCompanyOrPersonSelectQueryVariables
    >({
      query: CrmCompanyOrPersonSelectDocument,
      variables: {
        limit: 1,
        filterIds: [contactId],
      },
    });

    const crmCompanies =
      getDataOrNull(result.data?.crmCompanies)?.edges.map(edge => edge.node) ??
      [];
    const crmPersons =
      getDataOrNull(result.data?.crmPersons)?.edges.map(edge => edge.node) ??
      [];

    if (crmCompanies.length > 0) return "organisation";
    if (crmPersons.length > 0) return "user";
    return null;
  };

  return { defineContactType };
}

function isCrmCompanyLink(
  link: ExistingContactLink
): link is Extract<ExistingContactLink, { crmOrganisation: any }> {
  return (
    link.__typename === "ProjectLinkCrmOrganisation" ||
    link.__typename === "QuoteItemLinkCrmOrganisation"
  );
}

function isCrmPersonLink(
  link: ExistingContactLink
): link is Extract<ExistingContactLink, { crmUser: any }> {
  return (
    link.__typename === "ProjectLinkCrmUser" ||
    link.__typename === "QuoteItemLinkCrmUser"
  );
}
