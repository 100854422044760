import { LabeledValueWithButton } from "@msys/ui";
import { Functions as FunctionsIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { FormattedFloatField } from "../../commons/form-fields/FormattedFloatField.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { AttributeExpressionModal } from "./modals/AttributeExpressionModal.js";

type Mode = "FLOAT" | "EXPRESSION";

interface Expression {
  attribute: string;
  expr: string;
  result: number | { __type: "missing-value" };
}

interface Props extends React.ComponentProps<typeof FormattedFloatField> {
  projectId: string | null;
  docId: string;
  itemId: string;
  name: string;
  expression: Expression | undefined;
  hideExpressionIcon?: boolean;
  label?: string;
}

export function FormattedFloatWithExpressionField({
  projectId,
  docId,
  itemId,
  name,
  expression,
  hideExpressionIcon,
  size,
  ...props
}: Props) {
  const formikProps = useFormikContext();
  const value = formikProps.getFieldProps(name).value;

  const [isEditing, setIsEditing] = React.useState(false);
  const [isExpressionModalOpen, setExpressionModalOpen] = React.useState(false);
  const expressionButtonRef = React.useRef<HTMLButtonElement>(null);

  const hasExpressionRefError =
    typeof expression?.result !== "number" &&
    expression?.result.__type === "missing-value";

  if (isEditing) {
    return (
      <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
        <FormattedFloatField
          {...props}
          size={size}
          name={name}
          autoFocus
          onBlur={event => {
            if (event.relatedTarget !== expressionButtonRef.current) {
              setIsEditing(false);
            }
          }}
        />
        <IconButton
          size="extra-small"
          color="secondary"
          onClick={() => {
            setExpressionModalOpen(true);
            setIsEditing(false);
          }}
          ref={expressionButtonRef}
        >
          <FunctionsIcon sx={{ fontSize: "1rem" }} />
        </IconButton>
      </Stack>
    );
  }

  return (
    <>
      <LabeledValueWithButton
        size={size}
        label={props.label ?? ""}
        labelIcon={
          expression && !hideExpressionIcon ? FunctionsIcon : undefined
        }
        onClick={() => {
          if (expression) {
            setExpressionModalOpen(true);
          } else {
            setIsEditing(true);
          }
        }}
      >
        {hasExpressionRefError ? (
          <Typography color="error">{"Reference error"}</Typography>
        ) : (
          value
        )}
      </LabeledValueWithButton>
      {isExpressionModalOpen && (
        <AttributeExpressionModal
          projectId={projectId}
          docId={docId}
          itemId={itemId}
          fieldName={name}
          expression={expression?.expr}
          handleClose={() => {
            setExpressionModalOpen(false);
          }}
        />
      )}
    </>
  );
}
