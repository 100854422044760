import { gql } from "@apollo/client";
import { StatusChip } from "@msys/ui";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";
import { useTranslate } from "@tolgee/react";
import { TemplatesQuoteStatus_TemplateFragment } from "./TemplateQuoteStatus.generated.js";

interface Props {
  doc: TemplatesQuoteStatus_TemplateFragment;
  size?: React.ComponentProps<typeof StatusChip>["size"];
}

export const TemplateQuoteStatusChip = ({ doc, size }: Props) => {
  const viewer = useUserData()?.currentUser;

  const isOwnTemplate =
    doc.owningSystemOrganisationId === viewer?.organisation?.id;
  const isVersion = doc.resolvedAsReadModelVersionNumber !== null;
  const isPublished = doc.hasAnyPublishedVersion;
  const subscribedToTemplate = Boolean(doc.subscribedToTemplate);
  const hasUnpublishedChanges = doc.containsUnpublishedChanged;
  const haveExplicitAccess = doc.accessType === "explicit";

  const { label, color } = useTemplateStatus({
    isOwnTemplate,
    isVersion,
    isPublished,
    hasUnpublishedChanges,
    subscribedToTemplate,
    haveExplicitAccess,
  });

  return <StatusChip label={label} color={color} size={size} />;
};

function useTemplateStatus({
  isOwnTemplate,
  isVersion,
  isPublished,
  hasUnpublishedChanges,
  haveExplicitAccess,
  subscribedToTemplate,
}: {
  isOwnTemplate: boolean;
  isVersion: boolean;
  isPublished: boolean;
  hasUnpublishedChanges: boolean;
  subscribedToTemplate: boolean;
  haveExplicitAccess: boolean;
}) {
  const { t } = useTranslate("Templates");

  const statuses = React.useMemo(
    (): {
      [key: string]: {
        label: string;
        color: React.ComponentProps<typeof StatusChip>["color"];
      };
    } => ({
      draft: {
        label: t("Draft"),
        color: "default" as const,
      },
      published: {
        label: t("Published"),
        color: "success" as const,
      },
      unpublishedChanges: {
        label: t("Unpublished changes"),
        color: "warning" as const,
      },
      store: {
        label: t("Store"),
        color: "secondary" as const,
      },
      subscribed: {
        label: t("Subscribed"),
        color: "secondary" as const,
      },
    }),
    [t]
  );

  return !isOwnTemplate
    ? haveExplicitAccess && subscribedToTemplate
      ? statuses.subscribed
      : statuses.store
    : isVersion
      ? statuses.published
      : !isPublished
        ? statuses.draft
        : hasUnpublishedChanges
          ? statuses.unpublishedChanges
          : statuses.published;
}
