/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllProjectContactsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type AllProjectContactsQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, contractors: Array<{ __typename: 'CrmCompany', id: string, title: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, fullname: string, email: any, crmCompany: { __typename: 'CrmCompany', id: string } }> }>, invitingContractor?: { __typename: 'CrmCompany', id: string, title: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, fullname: string, email: any, crmCompany: { __typename: 'CrmCompany', id: string } }> } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, fullname: string, email: any, crmCompany: { __typename: 'CrmCompany', id: string } }> } | null, crmOrganisationLinks: Array<{ __typename: 'ProjectLinkCrmOrganisation', id: string, crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, fullname: string, email: any, crmCompany: { __typename: 'CrmCompany', id: string } }> } }>, crmUserLinks: Array<{ __typename: 'ProjectLinkCrmUser', id: string, crmUser: { __typename: 'CrmPerson', id: string, fullname: string, email: any, crmCompany: { __typename: 'CrmCompany', id: string } } }> } | null } };

export type AllProjectContacts_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, title: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, fullname: string, email: any, crmCompany: { __typename: 'CrmCompany', id: string } }> };

export type AllProjectContacts_CrmPersonFragment = { __typename: 'CrmPerson', id: string, fullname: string, email: any, crmCompany: { __typename: 'CrmCompany', id: string } };

export const AllProjectContacts_CrmPersonFragmentDoc = gql`
    fragment AllProjectContacts_CrmPerson on CrmPerson {
  id
  fullname
  email
  crmCompany {
    id
  }
}
    `;
export const AllProjectContacts_CrmCompanyFragmentDoc = gql`
    fragment AllProjectContacts_CrmCompany on CrmCompany {
  id
  title
  email
  members {
    id
    ...AllProjectContacts_CrmPerson
  }
}
    ${AllProjectContacts_CrmPersonFragmentDoc}`;
export const AllProjectContactsDocument = gql`
    query AllProjectContacts($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        contractors {
          id
          ...AllProjectContacts_CrmCompany
        }
        invitingContractor {
          id
          ...AllProjectContacts_CrmCompany
        }
        crmOrganisation {
          id
          ...AllProjectContacts_CrmCompany
        }
        crmOrganisationLinks {
          id
          crmOrganisation {
            id
            ...AllProjectContacts_CrmCompany
          }
        }
        crmUserLinks {
          id
          crmUser {
            id
            ...AllProjectContacts_CrmPerson
            crmCompany {
              id
            }
          }
        }
      }
    }
  }
}
    ${AllProjectContacts_CrmCompanyFragmentDoc}
${AllProjectContacts_CrmPersonFragmentDoc}`;

/**
 * __useAllProjectContactsQuery__
 *
 * To run a query within a React component, call `useAllProjectContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectContactsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAllProjectContactsQuery(baseOptions: Apollo.QueryHookOptions<AllProjectContactsQuery, AllProjectContactsQueryVariables> & ({ variables: AllProjectContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllProjectContactsQuery, AllProjectContactsQueryVariables>(AllProjectContactsDocument, options);
      }
export function useAllProjectContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProjectContactsQuery, AllProjectContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllProjectContactsQuery, AllProjectContactsQueryVariables>(AllProjectContactsDocument, options);
        }
export function useAllProjectContactsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllProjectContactsQuery, AllProjectContactsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllProjectContactsQuery, AllProjectContactsQueryVariables>(AllProjectContactsDocument, options);
        }
export type AllProjectContactsQueryHookResult = ReturnType<typeof useAllProjectContactsQuery>;
export type AllProjectContactsLazyQueryHookResult = ReturnType<typeof useAllProjectContactsLazyQuery>;
export type AllProjectContactsSuspenseQueryHookResult = ReturnType<typeof useAllProjectContactsSuspenseQuery>;
export type AllProjectContactsQueryResult = Apollo.QueryResult<AllProjectContactsQuery, AllProjectContactsQueryVariables>;