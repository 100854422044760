import { Capabilities } from "@msys/common";
import { Tabs, type TabOption, type TabsProps } from "@msys/ui";
import { ButtonBase } from "@mui/material";
import React from "react";
import { PermissionName } from "../../clients/graphqlTypes.js";
import { RestrictedByCapabilityWithDebug } from "../auth/RestrictedByCapability.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../auth/RestrictedByOrganisationPermission.js";
import {
  Project,
  RestrictedByProjectPermissionWithDebug,
} from "../auth/RestrictedByProjectPermission.js";
import { useRestrictionFilter } from "../auth/useRestrictionFilter.js";

export interface TabOptionWithRestriction<T> extends TabOption<T> {
  capability?: Capabilities;
  permission?: PermissionName;
  projectPermission?: { name: PermissionName; project: Project };
}

export interface TabPropsWithRestriction<
  T,
  O extends TabOptionWithRestriction<T>,
> extends Omit<TabsProps<T, O>, "options"> {
  options: O[];
}

export const TabsWithRestriction = <T, O extends TabOptionWithRestriction<T>>(
  props: TabPropsWithRestriction<T, O>
) => {
  const restrictionFilter = useRestrictionFilter();

  return (
    <Tabs<T, O>
      {...props}
      tabProps={(option, index) => ({
        component: TabButton,
        index,
        capability: option.capability,
        permission: option.permission,
        projectPermission: option.projectPermission,
      })}
      filterSelectOption={restrictionFilter}
    />
  );
};

const TabButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<{
    capability?: Capabilities;
    permission?: PermissionName;
    projectPermission?: { name: PermissionName; project: Project };
    index?: number;
  }>
>(function TabButtonInner(
  { capability, permission, projectPermission, index, children, ...props },
  ref
) {
  let button = (
    <ButtonBase ref={ref} {...props}>
      {children}
    </ButtonBase>
  );

  if (projectPermission) {
    button = (
      <RestrictedByProjectPermissionWithDebug
        permission={projectPermission.name}
        project={projectPermission.project}
        key={`restricted-by-project-permission-${index}`}
      >
        {button}
      </RestrictedByProjectPermissionWithDebug>
    );
  }
  if (permission) {
    button = (
      <RestrictedByOrganisationPermissionWithDebug
        permission={permission}
        key={`restricted-by-permission-${index}`}
      >
        {button}
      </RestrictedByOrganisationPermissionWithDebug>
    );
  }
  if (capability) {
    button = (
      <RestrictedByCapabilityWithDebug
        capability={capability}
        key={`restricted-by-capability-${index}`}
      >
        {button}
      </RestrictedByCapabilityWithDebug>
    );
  }

  return button;
});
