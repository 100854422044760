import React from "react";
import { useNavigate } from "react-router-dom";
import { SplashScreen } from "../../common/SplashScreen.js";
import { useApolloClient } from "@apollo/client";
import { useSapSalesQuotationQuoteRedirectRouteQuery } from "./SapSalesQuotationQuoteRedirectRoute.generated";

interface Props {
  salesQuotationId: string;
}

export function SapSalesQuotationQuoteRedirectRoute({
  salesQuotationId,
}: Props) {
  const navigate = useNavigate();

  const client = useApolloClient();
  const query = useSapSalesQuotationQuoteRedirectRouteQuery({
    client,
    variables: {
      salesQuotationId,
    },
    fetchPolicy: "cache-first",
  });

  React.useEffect(() => {
    if (!query.error) {
      const docId = query.data?.quoteSapS4HanaDataBySalesQuotationId?.docId;
      const projectId =
        query.data?.quoteSapS4HanaDataBySalesQuotationId?.projectId;
      if (docId && projectId) {
        navigate(`/projects/${projectId}/quotes/${docId}`);
      }
    }
  }, [
    navigate,
    query.data?.quoteSapS4HanaDataBySalesQuotationId?.docId,
    query.data?.quoteSapS4HanaDataBySalesQuotationId?.projectId,
    query.error,
  ]);

  return <SplashScreen />;
}
