/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { CrmEmailListItem_CrmMailMessageFragmentDoc } from '../../features/crm/CrmEmailListItem.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectEmailsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectEmailsQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string } | null }, crmEmailMessages: { __typename: 'CrmEmailMessageConnection', totalCount: number, edges: Array<{ __typename: 'CrmEmailMessageEdge', node: { __typename: 'CrmMailMessage', id: string, threadId: string, createdAt: any, direction: Types.EmailMessageDirection, from: string, to: string, subject: string, attachments: Array<{ __typename: 'CrmMailMessageAttachment', filename: string, mimeType: string, url: string }> } }> } | { __typename: 'MissingPermissions' } };

export type ProjectEmailsSidebar_CrmMailMessageFragment = { __typename: 'CrmMailMessage', id: string, threadId: string, createdAt: any, direction: Types.EmailMessageDirection, from: string, to: string, subject: string, attachments: Array<{ __typename: 'CrmMailMessageAttachment', filename: string, mimeType: string, url: string }> };

export const ProjectEmailsSidebar_CrmMailMessageFragmentDoc = gql`
    fragment ProjectEmailsSidebar_CrmMailMessage on CrmMailMessage {
  id
  threadId
  createdAt
  ...CrmEmailListItem_CrmMailMessage
}
    ${CrmEmailListItem_CrmMailMessageFragmentDoc}`;
export const ProjectEmailsDocument = gql`
    query ProjectEmails($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
      }
    }
  }
  crmEmailMessages(filters: {context: {type: PROJECT, id: $projectId}}) {
    ... on CrmEmailMessageConnection {
      totalCount
      edges {
        node {
          id
          ...ProjectEmailsSidebar_CrmMailMessage
        }
      }
    }
  }
}
    ${ProjectEmailsSidebar_CrmMailMessageFragmentDoc}`;

/**
 * __useProjectEmailsQuery__
 *
 * To run a query within a React component, call `useProjectEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectEmailsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectEmailsQuery(baseOptions: Apollo.QueryHookOptions<ProjectEmailsQuery, ProjectEmailsQueryVariables> & ({ variables: ProjectEmailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectEmailsQuery, ProjectEmailsQueryVariables>(ProjectEmailsDocument, options);
      }
export function useProjectEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectEmailsQuery, ProjectEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectEmailsQuery, ProjectEmailsQueryVariables>(ProjectEmailsDocument, options);
        }
export function useProjectEmailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectEmailsQuery, ProjectEmailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectEmailsQuery, ProjectEmailsQueryVariables>(ProjectEmailsDocument, options);
        }
export type ProjectEmailsQueryHookResult = ReturnType<typeof useProjectEmailsQuery>;
export type ProjectEmailsLazyQueryHookResult = ReturnType<typeof useProjectEmailsLazyQuery>;
export type ProjectEmailsSuspenseQueryHookResult = ReturnType<typeof useProjectEmailsSuspenseQuery>;
export type ProjectEmailsQueryResult = Apollo.QueryResult<ProjectEmailsQuery, ProjectEmailsQueryVariables>;