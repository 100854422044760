/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { TemplateTypeSelect_TemplateTypeFragmentDoc } from './TemplateTypeSelect.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateTypeInput_SearchTemplateTypesQueryVariables = Types.Exact<{
  templateTypeIds: Array<Types.Scalars['ID']['input']>;
}>;


export type TemplateTypeInput_SearchTemplateTypesQuery = { searchTemplateTypes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'SearchTemplateTypesResultConnection', totalCount: number, edges: Array<{ __typename: 'SearchTemplateTypesResultEdge', node: { __typename: 'TemplateType', id: string, key: string, title: string, description: string } }> } };

export type TemplateTypeInput_TemplateTypeFragment = { __typename: 'TemplateType', id: string, key: string, title: string };

export const TemplateTypeInput_TemplateTypeFragmentDoc = gql`
    fragment TemplateTypeInput_TemplateType on TemplateType {
  id
  key
  title
}
    `;
export const TemplateTypeInput_SearchTemplateTypesDocument = gql`
    query TemplateTypeInput_SearchTemplateTypes($templateTypeIds: [ID!]!) {
  searchTemplateTypes(
    limit: 1
    offset: 0
    filter: {onlyTemplateTypeIds: $templateTypeIds}
  ) {
    ... on SearchTemplateTypesResultConnection {
      edges {
        node {
          ...TemplateTypeSelect_TemplateType
        }
      }
      totalCount
    }
  }
}
    ${TemplateTypeSelect_TemplateTypeFragmentDoc}`;

/**
 * __useTemplateTypeInput_SearchTemplateTypesQuery__
 *
 * To run a query within a React component, call `useTemplateTypeInput_SearchTemplateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateTypeInput_SearchTemplateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateTypeInput_SearchTemplateTypesQuery({
 *   variables: {
 *      templateTypeIds: // value for 'templateTypeIds'
 *   },
 * });
 */
export function useTemplateTypeInput_SearchTemplateTypesQuery(baseOptions: Apollo.QueryHookOptions<TemplateTypeInput_SearchTemplateTypesQuery, TemplateTypeInput_SearchTemplateTypesQueryVariables> & ({ variables: TemplateTypeInput_SearchTemplateTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateTypeInput_SearchTemplateTypesQuery, TemplateTypeInput_SearchTemplateTypesQueryVariables>(TemplateTypeInput_SearchTemplateTypesDocument, options);
      }
export function useTemplateTypeInput_SearchTemplateTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateTypeInput_SearchTemplateTypesQuery, TemplateTypeInput_SearchTemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateTypeInput_SearchTemplateTypesQuery, TemplateTypeInput_SearchTemplateTypesQueryVariables>(TemplateTypeInput_SearchTemplateTypesDocument, options);
        }
export function useTemplateTypeInput_SearchTemplateTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TemplateTypeInput_SearchTemplateTypesQuery, TemplateTypeInput_SearchTemplateTypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateTypeInput_SearchTemplateTypesQuery, TemplateTypeInput_SearchTemplateTypesQueryVariables>(TemplateTypeInput_SearchTemplateTypesDocument, options);
        }
export type TemplateTypeInput_SearchTemplateTypesQueryHookResult = ReturnType<typeof useTemplateTypeInput_SearchTemplateTypesQuery>;
export type TemplateTypeInput_SearchTemplateTypesLazyQueryHookResult = ReturnType<typeof useTemplateTypeInput_SearchTemplateTypesLazyQuery>;
export type TemplateTypeInput_SearchTemplateTypesSuspenseQueryHookResult = ReturnType<typeof useTemplateTypeInput_SearchTemplateTypesSuspenseQuery>;
export type TemplateTypeInput_SearchTemplateTypesQueryResult = Apollo.QueryResult<TemplateTypeInput_SearchTemplateTypesQuery, TemplateTypeInput_SearchTemplateTypesQueryVariables>;