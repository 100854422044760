/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateTypeSelectSearchTemplateTypesQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  templateTypeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type TemplateTypeSelectSearchTemplateTypesQuery = { searchTemplateTypes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'SearchTemplateTypesResultConnection', totalCount: number, edges: Array<{ __typename: 'SearchTemplateTypesResultEdge', node: { __typename: 'TemplateType', id: string, key: string, title: string, description: string } }> } };

export type TemplateTypeSelect_TemplateTypeFragment = { __typename: 'TemplateType', id: string, key: string, title: string, description: string };

export const TemplateTypeSelect_TemplateTypeFragmentDoc = gql`
    fragment TemplateTypeSelect_TemplateType on TemplateType {
  id
  key
  title
  description
}
    `;
export const TemplateTypeSelectSearchTemplateTypesDocument = gql`
    query TemplateTypeSelectSearchTemplateTypes($searchTerm: String, $limit: Int!, $offset: Int!, $templateTypeIds: [ID!]) {
  searchTemplateTypes(
    searchTerm: $searchTerm
    limit: $limit
    offset: $offset
    filter: {onlyTemplateTypeIds: $templateTypeIds}
  ) {
    ... on SearchTemplateTypesResultConnection {
      edges {
        node {
          ...TemplateTypeSelect_TemplateType
        }
      }
      totalCount
    }
  }
}
    ${TemplateTypeSelect_TemplateTypeFragmentDoc}`;

/**
 * __useTemplateTypeSelectSearchTemplateTypesQuery__
 *
 * To run a query within a React component, call `useTemplateTypeSelectSearchTemplateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateTypeSelectSearchTemplateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateTypeSelectSearchTemplateTypesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      templateTypeIds: // value for 'templateTypeIds'
 *   },
 * });
 */
export function useTemplateTypeSelectSearchTemplateTypesQuery(baseOptions: Apollo.QueryHookOptions<TemplateTypeSelectSearchTemplateTypesQuery, TemplateTypeSelectSearchTemplateTypesQueryVariables> & ({ variables: TemplateTypeSelectSearchTemplateTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateTypeSelectSearchTemplateTypesQuery, TemplateTypeSelectSearchTemplateTypesQueryVariables>(TemplateTypeSelectSearchTemplateTypesDocument, options);
      }
export function useTemplateTypeSelectSearchTemplateTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateTypeSelectSearchTemplateTypesQuery, TemplateTypeSelectSearchTemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateTypeSelectSearchTemplateTypesQuery, TemplateTypeSelectSearchTemplateTypesQueryVariables>(TemplateTypeSelectSearchTemplateTypesDocument, options);
        }
export function useTemplateTypeSelectSearchTemplateTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TemplateTypeSelectSearchTemplateTypesQuery, TemplateTypeSelectSearchTemplateTypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateTypeSelectSearchTemplateTypesQuery, TemplateTypeSelectSearchTemplateTypesQueryVariables>(TemplateTypeSelectSearchTemplateTypesDocument, options);
        }
export type TemplateTypeSelectSearchTemplateTypesQueryHookResult = ReturnType<typeof useTemplateTypeSelectSearchTemplateTypesQuery>;
export type TemplateTypeSelectSearchTemplateTypesLazyQueryHookResult = ReturnType<typeof useTemplateTypeSelectSearchTemplateTypesLazyQuery>;
export type TemplateTypeSelectSearchTemplateTypesSuspenseQueryHookResult = ReturnType<typeof useTemplateTypeSelectSearchTemplateTypesSuspenseQuery>;
export type TemplateTypeSelectSearchTemplateTypesQueryResult = Apollo.QueryResult<TemplateTypeSelectSearchTemplateTypesQuery, TemplateTypeSelectSearchTemplateTypesQueryVariables>;