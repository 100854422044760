import { LabeledValue, VisibilityIndicatorIcon } from "@msys/ui";
import { Grid, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Props2NonComputed } from "../../../clients/graphqlTypes.js";
import { notSetSymbol } from "../doc-items/helpers.js";
import {
  getEnhancedPropertyLabel,
  getPropValueWithUnitAndAllowedValues,
} from "../doc-items/properties.js";
import { usePropertyHelpers } from "../doc-items/usePropertyHelpers.js";

interface Props {
  properties?: Props2NonComputed[];
  hideVisibility?: boolean;
  showAllowedValues?: boolean;
  columns?: 2 | 1;
}

export const LabeledPropertiesValue = ({
  properties = [],
  hideVisibility,
  showAllowedValues,
  columns = 2,
}: Props) => {
  const { t } = useTranslate(["ItemPropertyField", "Global"]);
  const { getUnitLabel, getBoolLabel, getNumberLabel } = usePropertyHelpers();

  if (properties.length <= 0) {
    return (
      <Stack>
        <Typography variant="caption" color="grey.600">
          {t("Not set", { ns: "Global" })}
        </Typography>
      </Stack>
    );
  }

  return (
    <Grid container columns={columns} spacing={1}>
      {properties.map((property, i) => {
        const { displayedValue, alternativeAllowedValues, unit } =
          getPropValueWithUnitAndAllowedValues(
            property,
            getUnitLabel,
            getBoolLabel,
            getNumberLabel,
            notSetSymbol
          );
        return (
          <Grid item xs={1} key={property.key}>
            <Stack
              direction={"row"}
              spacing={0.5}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <LabeledValue label={getEnhancedPropertyLabel(property)}>
                {showAllowedValues
                  ? renderValueWithAllowedValues({
                      unit,
                      displayedValue,
                      alternativeAllowedValues,
                    })
                  : displayedValue}
              </LabeledValue>
              {!hideVisibility && (
                <VisibilityIndicatorIcon
                  isVisible={property.clientVisibility}
                />
              )}
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

export function renderValueWithAllowedValues({
  unit,
  displayedValue,
  alternativeAllowedValues,
}: {
  unit: string;
  displayedValue: string;
  alternativeAllowedValues: string;
}) {
  return alternativeAllowedValues ? (
    <>
      {!(
        displayedValue === notSetSymbol ||
        displayedValue === `${notSetSymbol}${unit}`
      ) ? (
        <>{displayedValue} </>
      ) : null}
      <Typography
        component="span"
        sx={{ fontStyle: "italic" }}
        variant="inherit"
        color="grey.500"
      >
        {alternativeAllowedValues}
      </Typography>
    </>
  ) : (
    displayedValue
  );
}
