/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { OrganisationScheduleSettingsBox_ViewerOrganisationFragmentDoc, OrganisationScheduleSettingsBox_DefaultsFragmentDoc } from '../../features/organisations/boxes/OrganisationScheduleSettingsBox.generated.js';
import { OrganisationTaskSettingsBox_DefaultsFragmentDoc } from '../../features/organisations/boxes/OrganisationTaskSettingsBox.generated.js';
import { OrganisationProjectSettingsBox_SettingsFragmentDoc } from '../../features/organisations/boxes/OrganisationProjectSettingsBox.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationSettingsPlanningQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganisationSettingsPlanningQuery = { viewer?: { __typename: 'Viewer', id: string, organisation: { __typename: 'ViewerOrganisation', id: string, isCraftsmanOrganisation: boolean, timezone: string } } | null, organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultTaskPhotoIsRequired: boolean, defaultStartWorkDay?: string | null, defaultEndWorkDay?: string | null, defaultProjectDuration: number, defaultTicketDuration: number }, organisationSettings: { __typename: 'OrganisationSetting', id: string, projectCouldBeUnassigned: boolean } };


export const OrganisationSettingsPlanningDocument = gql`
    query OrganisationSettingsPlanning {
  viewer {
    id
    organisation {
      id
      isCraftsmanOrganisation
      ...OrganisationScheduleSettingsBox_ViewerOrganisation
    }
  }
  organisationDefaults {
    ... on OrganisationDefaults {
      id
      ...OrganisationTaskSettingsBox_Defaults
      ...OrganisationScheduleSettingsBox_Defaults
    }
  }
  organisationSettings {
    id
    ...OrganisationProjectSettingsBox_Settings
  }
}
    ${OrganisationScheduleSettingsBox_ViewerOrganisationFragmentDoc}
${OrganisationTaskSettingsBox_DefaultsFragmentDoc}
${OrganisationScheduleSettingsBox_DefaultsFragmentDoc}
${OrganisationProjectSettingsBox_SettingsFragmentDoc}`;

/**
 * __useOrganisationSettingsPlanningQuery__
 *
 * To run a query within a React component, call `useOrganisationSettingsPlanningQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationSettingsPlanningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationSettingsPlanningQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationSettingsPlanningQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationSettingsPlanningQuery, OrganisationSettingsPlanningQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationSettingsPlanningQuery, OrganisationSettingsPlanningQueryVariables>(OrganisationSettingsPlanningDocument, options);
      }
export function useOrganisationSettingsPlanningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationSettingsPlanningQuery, OrganisationSettingsPlanningQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationSettingsPlanningQuery, OrganisationSettingsPlanningQueryVariables>(OrganisationSettingsPlanningDocument, options);
        }
export function useOrganisationSettingsPlanningSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrganisationSettingsPlanningQuery, OrganisationSettingsPlanningQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationSettingsPlanningQuery, OrganisationSettingsPlanningQueryVariables>(OrganisationSettingsPlanningDocument, options);
        }
export type OrganisationSettingsPlanningQueryHookResult = ReturnType<typeof useOrganisationSettingsPlanningQuery>;
export type OrganisationSettingsPlanningLazyQueryHookResult = ReturnType<typeof useOrganisationSettingsPlanningLazyQuery>;
export type OrganisationSettingsPlanningSuspenseQueryHookResult = ReturnType<typeof useOrganisationSettingsPlanningSuspenseQuery>;
export type OrganisationSettingsPlanningQueryResult = Apollo.QueryResult<OrganisationSettingsPlanningQuery, OrganisationSettingsPlanningQueryVariables>;