/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { ContentsFragmentDoc } from '../../contents/Content.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectOverviewActionListBox_ProjectFragment = { __typename: 'Project', id: string, state: Types.ProjectStateMachineStatus, viewerPermissions: Array<Types.PermissionName>, owningSystemOrganisationId: string, canInviteContractee: boolean, earliestPlanSessionDate?: any | null, latestPlanSessionDate?: any | null, incomingQuoteRequests: Array<{ __typename: 'Request', id: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null }>, crmOrganisation?: { __typename: 'CrmCompany', id: string } | null, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type ProjectOverviewActionListBoxQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectOverviewActionListBoxQuery = { projectDocumentsStatus?: { __typename: 'ProjectDocumentsStatus', isAnyOutgoingQuoteItemDone: boolean, areAllOutgoingQuoteItemsDone: boolean } | null };

export const ProjectOverviewActionListBox_ProjectFragmentDoc = gql`
    fragment ProjectOverviewActionListBox_Project on Project {
  id
  state
  viewerPermissions
  owningSystemOrganisationId
  incomingQuoteRequests {
    id
    status
    wonBySystemOrganisationId
  }
  crmOrganisation {
    id
  }
  canInviteContractee
  earliestPlanSessionDate
  latestPlanSessionDate
  ...Contents
}
    ${ContentsFragmentDoc}`;
export const ProjectOverviewActionListBoxDocument = gql`
    query ProjectOverviewActionListBox($projectId: ID!) {
  projectDocumentsStatus(id: $projectId) {
    isAnyOutgoingQuoteItemDone
    areAllOutgoingQuoteItemsDone
  }
}
    `;

/**
 * __useProjectOverviewActionListBoxQuery__
 *
 * To run a query within a React component, call `useProjectOverviewActionListBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewActionListBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOverviewActionListBoxQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectOverviewActionListBoxQuery(baseOptions: Apollo.QueryHookOptions<ProjectOverviewActionListBoxQuery, ProjectOverviewActionListBoxQueryVariables> & ({ variables: ProjectOverviewActionListBoxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOverviewActionListBoxQuery, ProjectOverviewActionListBoxQueryVariables>(ProjectOverviewActionListBoxDocument, options);
      }
export function useProjectOverviewActionListBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOverviewActionListBoxQuery, ProjectOverviewActionListBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOverviewActionListBoxQuery, ProjectOverviewActionListBoxQueryVariables>(ProjectOverviewActionListBoxDocument, options);
        }
export function useProjectOverviewActionListBoxSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectOverviewActionListBoxQuery, ProjectOverviewActionListBoxQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectOverviewActionListBoxQuery, ProjectOverviewActionListBoxQueryVariables>(ProjectOverviewActionListBoxDocument, options);
        }
export type ProjectOverviewActionListBoxQueryHookResult = ReturnType<typeof useProjectOverviewActionListBoxQuery>;
export type ProjectOverviewActionListBoxLazyQueryHookResult = ReturnType<typeof useProjectOverviewActionListBoxLazyQuery>;
export type ProjectOverviewActionListBoxSuspenseQueryHookResult = ReturnType<typeof useProjectOverviewActionListBoxSuspenseQuery>;
export type ProjectOverviewActionListBoxQueryResult = Apollo.QueryResult<ProjectOverviewActionListBoxQuery, ProjectOverviewActionListBoxQueryVariables>;