/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SupplierCatalogueSelect__PimSupplierCatalogueFragment = { __typename: 'PimSupplierCatalogue', id: string, isDefault: boolean, title: string, supplier: { __typename: 'Company', id: string, title: string } };

export type SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  supplierCatalogueId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  limit: Types.Scalars['Int']['input'];
}>;


export type SupplierCatalogueSelect__PimSearchSupplierCataloguesQuery = { pimSearchSupplierCatalogues: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchSupplierCataloguesResult', totalCount: number, supplierCatalogues: Array<{ __typename: 'PimSupplierCatalogue', id: string, isDefault: boolean, title: string, supplier: { __typename: 'Company', id: string, title: string } }> } };

export const SupplierCatalogueSelect__PimSupplierCatalogueFragmentDoc = gql`
    fragment SupplierCatalogueSelect__PimSupplierCatalogue on PimSupplierCatalogue {
  id
  isDefault
  title
  supplier {
    id
    title
  }
}
    `;
export const SupplierCatalogueSelect__PimSearchSupplierCataloguesDocument = gql`
    query SupplierCatalogueSelect__PimSearchSupplierCatalogues($searchTerm: String, $supplierCatalogueId: ID, $limit: Int!) {
  pimSearchSupplierCatalogues(
    supplierCatalogueId: $supplierCatalogueId
    searchTerm: $searchTerm
    filters: {organisationCanWrite: true, organisationIsSubscribed: true}
    limit: $limit
  ) {
    ... on PimSearchSupplierCataloguesResult {
      supplierCatalogues {
        ...SupplierCatalogueSelect__PimSupplierCatalogue
      }
      totalCount
    }
  }
}
    ${SupplierCatalogueSelect__PimSupplierCatalogueFragmentDoc}`;

/**
 * __useSupplierCatalogueSelect__PimSearchSupplierCataloguesQuery__
 *
 * To run a query within a React component, call `useSupplierCatalogueSelect__PimSearchSupplierCataloguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierCatalogueSelect__PimSearchSupplierCataloguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierCatalogueSelect__PimSearchSupplierCataloguesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      supplierCatalogueId: // value for 'supplierCatalogueId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSupplierCatalogueSelect__PimSearchSupplierCataloguesQuery(baseOptions: Apollo.QueryHookOptions<SupplierCatalogueSelect__PimSearchSupplierCataloguesQuery, SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryVariables> & ({ variables: SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierCatalogueSelect__PimSearchSupplierCataloguesQuery, SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryVariables>(SupplierCatalogueSelect__PimSearchSupplierCataloguesDocument, options);
      }
export function useSupplierCatalogueSelect__PimSearchSupplierCataloguesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierCatalogueSelect__PimSearchSupplierCataloguesQuery, SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierCatalogueSelect__PimSearchSupplierCataloguesQuery, SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryVariables>(SupplierCatalogueSelect__PimSearchSupplierCataloguesDocument, options);
        }
export function useSupplierCatalogueSelect__PimSearchSupplierCataloguesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SupplierCatalogueSelect__PimSearchSupplierCataloguesQuery, SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupplierCatalogueSelect__PimSearchSupplierCataloguesQuery, SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryVariables>(SupplierCatalogueSelect__PimSearchSupplierCataloguesDocument, options);
        }
export type SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryHookResult = ReturnType<typeof useSupplierCatalogueSelect__PimSearchSupplierCataloguesQuery>;
export type SupplierCatalogueSelect__PimSearchSupplierCataloguesLazyQueryHookResult = ReturnType<typeof useSupplierCatalogueSelect__PimSearchSupplierCataloguesLazyQuery>;
export type SupplierCatalogueSelect__PimSearchSupplierCataloguesSuspenseQueryHookResult = ReturnType<typeof useSupplierCatalogueSelect__PimSearchSupplierCataloguesSuspenseQuery>;
export type SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryResult = Apollo.QueryResult<SupplierCatalogueSelect__PimSearchSupplierCataloguesQuery, SupplierCatalogueSelect__PimSearchSupplierCataloguesQueryVariables>;