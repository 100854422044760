/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { TodoListItemFragmentDoc } from '../../features/todos/TodoListItem.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TasksListQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sorting: Array<Types.TodoItemsSorting>;
  filterIsDone?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterAssigneeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterTimeBefore?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  filterTimeAfter?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  filterLinkedCrmOrganisationId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterLinkedCrmUserId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterLinkedProjectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type TasksListQuery = { todoItems: { __typename: 'TodoItemsConnection', totalCount: number, edges: Array<{ __typename: 'TodoItemEdge', node: { __typename: 'TodoItemNode', id: string, title: string, description: string, time?: any | null, isDone: boolean, assignee?: { __typename: 'TodoItemUser', id: string, fullname?: string | null } | null, linkedCrmUser?: { __typename: 'TodoItemUser', id: string, fullname?: string | null } | null, linkedCrmOrganisation?: { __typename: 'TodoItemOrganisation', id: string, title: string } | null, linkedProject?: { __typename: 'TodoItemProject', id: string, title: string } | null } }> } };

export type TodoItemFragment = { __typename: 'TodoItemNode', id: string, title: string, description: string, time?: any | null, isDone: boolean, assignee?: { __typename: 'TodoItemUser', id: string, fullname?: string | null } | null, linkedCrmUser?: { __typename: 'TodoItemUser', id: string, fullname?: string | null } | null, linkedCrmOrganisation?: { __typename: 'TodoItemOrganisation', id: string, title: string } | null, linkedProject?: { __typename: 'TodoItemProject', id: string, title: string } | null };

export type AssigneeFilterChipsQueryVariables = Types.Exact<{
  filterAssigneeIds: Array<Types.Scalars['ID']['input']>;
}>;


export type AssigneeFilterChipsQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, fullname: string }> };

export type ContactFilterChipsQueryVariables = Types.Exact<{
  filterLinkedCrmUserIds: Array<Types.Scalars['ID']['input']>;
}>;


export type ContactFilterChipsQuery = { crmPersons: { __typename: 'CrmPersonConnection', edges: Array<{ __typename: 'CrmPersonEdge', node: { __typename: 'CrmPerson', id: string, fullname: string } }> } | { __typename: 'MissingPermissions' } };

export type ClientFilterChipsQueryVariables = Types.Exact<{
  filterLinkedCrmOrganisationIds: Array<Types.Scalars['ID']['input']>;
}>;


export type ClientFilterChipsQuery = { crmCompanies: { __typename: 'CrmCompanyConnection', edges: Array<{ __typename: 'CrmCompanyEdge', node: { __typename: 'CrmCompaniesRecord', id: string, title: string } }> } | { __typename: 'MissingPermissions' } };

export type ProjectFilterChipsQueryVariables = Types.Exact<{
  filterLinkedProjectIds: Array<Types.Scalars['ID']['input']>;
}>;


export type ProjectFilterChipsQuery = { projects: { __typename: 'ProjectConnection', edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, title: string } }> } };

export type TasksListFilterOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TasksListFilterOptionsQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, fullname: string }>, crmPersons: { __typename: 'CrmPersonConnection', edges: Array<{ __typename: 'CrmPersonEdge', node: { __typename: 'CrmPerson', id: string, fullname: string } }> } | { __typename: 'MissingPermissions' }, projects: { __typename: 'ProjectConnection', edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, title: string } }> } };

export type TasksListFilterOptionsAssigneeFragment = { __typename: 'User', id: string, fullname: string };

export type TasksListFilterOptionsCrmUserFragment = { __typename: 'CrmPerson', id: string, fullname: string };

export type TasksListFilterOptionsProjectFragment = { __typename: 'Project', id: string, title: string };

export type ToggleTodoItemDoneMutationVariables = Types.Exact<{
  input: Types.ModifyTodoItemInput;
}>;


export type ToggleTodoItemDoneMutation = { modifyTodoItem: { __typename: 'ModifyTodoItemResult', todoItem: { __typename: 'TodoItem', id: string, isDone: boolean, completedAt?: any | null, completedBy?: { __typename: 'User', id: string, fullname: string } | null } } };

export const TodoItemFragmentDoc = gql`
    fragment TodoItem on TodoItemNode {
  id
  title
  description
  time
  assignee {
    id
    fullname
  }
  isDone
  linkedCrmUser {
    id
    fullname
  }
  linkedCrmOrganisation {
    id
    title
  }
  linkedProject {
    id
    title
  }
}
    `;
export const TasksListFilterOptionsAssigneeFragmentDoc = gql`
    fragment TasksListFilterOptionsAssignee on User {
  id
  fullname
}
    `;
export const TasksListFilterOptionsCrmUserFragmentDoc = gql`
    fragment TasksListFilterOptionsCrmUser on CrmPerson {
  id
  fullname
}
    `;
export const TasksListFilterOptionsProjectFragmentDoc = gql`
    fragment TasksListFilterOptionsProject on Project {
  id
  title
}
    `;
export const TasksListDocument = gql`
    query TasksList($limit: Int!, $offset: Int, $sorting: [TodoItemsSorting!]!, $filterIsDone: Boolean, $filterAssigneeId: ID, $filterTimeBefore: DateTime, $filterTimeAfter: DateTime, $filterLinkedCrmOrganisationId: ID, $filterLinkedCrmUserId: ID, $filterLinkedProjectId: ID, $searchTerm: String) {
  todoItems(
    limit: $limit
    offset: $offset
    sorting: $sorting
    filterIsDone: $filterIsDone
    filterTimeBefore: $filterTimeBefore
    filterTimeAfter: $filterTimeAfter
    filterAssigneeId: $filterAssigneeId
    filterLinkedCrmOrganisationId: $filterLinkedCrmOrganisationId
    filterLinkedCrmUserId: $filterLinkedCrmUserId
    filterLinkedProjectId: $filterLinkedProjectId
    searchTerm: $searchTerm
  ) {
    totalCount
    edges {
      node {
        id
        ...TodoItem
        ...TodoListItem
      }
    }
  }
}
    ${TodoItemFragmentDoc}
${TodoListItemFragmentDoc}`;

/**
 * __useTasksListQuery__
 *
 * To run a query within a React component, call `useTasksListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sorting: // value for 'sorting'
 *      filterIsDone: // value for 'filterIsDone'
 *      filterAssigneeId: // value for 'filterAssigneeId'
 *      filterTimeBefore: // value for 'filterTimeBefore'
 *      filterTimeAfter: // value for 'filterTimeAfter'
 *      filterLinkedCrmOrganisationId: // value for 'filterLinkedCrmOrganisationId'
 *      filterLinkedCrmUserId: // value for 'filterLinkedCrmUserId'
 *      filterLinkedProjectId: // value for 'filterLinkedProjectId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useTasksListQuery(baseOptions: Apollo.QueryHookOptions<TasksListQuery, TasksListQueryVariables> & ({ variables: TasksListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksListQuery, TasksListQueryVariables>(TasksListDocument, options);
      }
export function useTasksListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksListQuery, TasksListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksListQuery, TasksListQueryVariables>(TasksListDocument, options);
        }
export function useTasksListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TasksListQuery, TasksListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TasksListQuery, TasksListQueryVariables>(TasksListDocument, options);
        }
export type TasksListQueryHookResult = ReturnType<typeof useTasksListQuery>;
export type TasksListLazyQueryHookResult = ReturnType<typeof useTasksListLazyQuery>;
export type TasksListSuspenseQueryHookResult = ReturnType<typeof useTasksListSuspenseQuery>;
export type TasksListQueryResult = Apollo.QueryResult<TasksListQuery, TasksListQueryVariables>;
export const AssigneeFilterChipsDocument = gql`
    query AssigneeFilterChips($filterAssigneeIds: [ID!]!) {
  organisationMemberships(filters: {ids: $filterAssigneeIds}) {
    id
    ...TasksListFilterOptionsAssignee
  }
}
    ${TasksListFilterOptionsAssigneeFragmentDoc}`;

/**
 * __useAssigneeFilterChipsQuery__
 *
 * To run a query within a React component, call `useAssigneeFilterChipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssigneeFilterChipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssigneeFilterChipsQuery({
 *   variables: {
 *      filterAssigneeIds: // value for 'filterAssigneeIds'
 *   },
 * });
 */
export function useAssigneeFilterChipsQuery(baseOptions: Apollo.QueryHookOptions<AssigneeFilterChipsQuery, AssigneeFilterChipsQueryVariables> & ({ variables: AssigneeFilterChipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssigneeFilterChipsQuery, AssigneeFilterChipsQueryVariables>(AssigneeFilterChipsDocument, options);
      }
export function useAssigneeFilterChipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssigneeFilterChipsQuery, AssigneeFilterChipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssigneeFilterChipsQuery, AssigneeFilterChipsQueryVariables>(AssigneeFilterChipsDocument, options);
        }
export function useAssigneeFilterChipsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AssigneeFilterChipsQuery, AssigneeFilterChipsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AssigneeFilterChipsQuery, AssigneeFilterChipsQueryVariables>(AssigneeFilterChipsDocument, options);
        }
export type AssigneeFilterChipsQueryHookResult = ReturnType<typeof useAssigneeFilterChipsQuery>;
export type AssigneeFilterChipsLazyQueryHookResult = ReturnType<typeof useAssigneeFilterChipsLazyQuery>;
export type AssigneeFilterChipsSuspenseQueryHookResult = ReturnType<typeof useAssigneeFilterChipsSuspenseQuery>;
export type AssigneeFilterChipsQueryResult = Apollo.QueryResult<AssigneeFilterChipsQuery, AssigneeFilterChipsQueryVariables>;
export const ContactFilterChipsDocument = gql`
    query ContactFilterChips($filterLinkedCrmUserIds: [ID!]!) {
  crmPersons(limit: 0, filters: {ids: $filterLinkedCrmUserIds}) {
    ... on CrmPersonConnection {
      edges {
        node {
          ...TasksListFilterOptionsCrmUser
        }
      }
    }
  }
}
    ${TasksListFilterOptionsCrmUserFragmentDoc}`;

/**
 * __useContactFilterChipsQuery__
 *
 * To run a query within a React component, call `useContactFilterChipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactFilterChipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactFilterChipsQuery({
 *   variables: {
 *      filterLinkedCrmUserIds: // value for 'filterLinkedCrmUserIds'
 *   },
 * });
 */
export function useContactFilterChipsQuery(baseOptions: Apollo.QueryHookOptions<ContactFilterChipsQuery, ContactFilterChipsQueryVariables> & ({ variables: ContactFilterChipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactFilterChipsQuery, ContactFilterChipsQueryVariables>(ContactFilterChipsDocument, options);
      }
export function useContactFilterChipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactFilterChipsQuery, ContactFilterChipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactFilterChipsQuery, ContactFilterChipsQueryVariables>(ContactFilterChipsDocument, options);
        }
export function useContactFilterChipsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ContactFilterChipsQuery, ContactFilterChipsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContactFilterChipsQuery, ContactFilterChipsQueryVariables>(ContactFilterChipsDocument, options);
        }
export type ContactFilterChipsQueryHookResult = ReturnType<typeof useContactFilterChipsQuery>;
export type ContactFilterChipsLazyQueryHookResult = ReturnType<typeof useContactFilterChipsLazyQuery>;
export type ContactFilterChipsSuspenseQueryHookResult = ReturnType<typeof useContactFilterChipsSuspenseQuery>;
export type ContactFilterChipsQueryResult = Apollo.QueryResult<ContactFilterChipsQuery, ContactFilterChipsQueryVariables>;
export const ClientFilterChipsDocument = gql`
    query ClientFilterChips($filterLinkedCrmOrganisationIds: [ID!]!) {
  crmCompanies(limit: 0, filters: {ids: $filterLinkedCrmOrganisationIds}) {
    ... on CrmCompanyConnection {
      edges {
        node {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useClientFilterChipsQuery__
 *
 * To run a query within a React component, call `useClientFilterChipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFilterChipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFilterChipsQuery({
 *   variables: {
 *      filterLinkedCrmOrganisationIds: // value for 'filterLinkedCrmOrganisationIds'
 *   },
 * });
 */
export function useClientFilterChipsQuery(baseOptions: Apollo.QueryHookOptions<ClientFilterChipsQuery, ClientFilterChipsQueryVariables> & ({ variables: ClientFilterChipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientFilterChipsQuery, ClientFilterChipsQueryVariables>(ClientFilterChipsDocument, options);
      }
export function useClientFilterChipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientFilterChipsQuery, ClientFilterChipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientFilterChipsQuery, ClientFilterChipsQueryVariables>(ClientFilterChipsDocument, options);
        }
export function useClientFilterChipsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ClientFilterChipsQuery, ClientFilterChipsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientFilterChipsQuery, ClientFilterChipsQueryVariables>(ClientFilterChipsDocument, options);
        }
export type ClientFilterChipsQueryHookResult = ReturnType<typeof useClientFilterChipsQuery>;
export type ClientFilterChipsLazyQueryHookResult = ReturnType<typeof useClientFilterChipsLazyQuery>;
export type ClientFilterChipsSuspenseQueryHookResult = ReturnType<typeof useClientFilterChipsSuspenseQuery>;
export type ClientFilterChipsQueryResult = Apollo.QueryResult<ClientFilterChipsQuery, ClientFilterChipsQueryVariables>;
export const ProjectFilterChipsDocument = gql`
    query ProjectFilterChips($filterLinkedProjectIds: [ID!]!) {
  projects(limit: 0, filters: {kind: DEFAULT, ids: $filterLinkedProjectIds}) {
    ... on ProjectConnection {
      edges {
        node {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useProjectFilterChipsQuery__
 *
 * To run a query within a React component, call `useProjectFilterChipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFilterChipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFilterChipsQuery({
 *   variables: {
 *      filterLinkedProjectIds: // value for 'filterLinkedProjectIds'
 *   },
 * });
 */
export function useProjectFilterChipsQuery(baseOptions: Apollo.QueryHookOptions<ProjectFilterChipsQuery, ProjectFilterChipsQueryVariables> & ({ variables: ProjectFilterChipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectFilterChipsQuery, ProjectFilterChipsQueryVariables>(ProjectFilterChipsDocument, options);
      }
export function useProjectFilterChipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectFilterChipsQuery, ProjectFilterChipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectFilterChipsQuery, ProjectFilterChipsQueryVariables>(ProjectFilterChipsDocument, options);
        }
export function useProjectFilterChipsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectFilterChipsQuery, ProjectFilterChipsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectFilterChipsQuery, ProjectFilterChipsQueryVariables>(ProjectFilterChipsDocument, options);
        }
export type ProjectFilterChipsQueryHookResult = ReturnType<typeof useProjectFilterChipsQuery>;
export type ProjectFilterChipsLazyQueryHookResult = ReturnType<typeof useProjectFilterChipsLazyQuery>;
export type ProjectFilterChipsSuspenseQueryHookResult = ReturnType<typeof useProjectFilterChipsSuspenseQuery>;
export type ProjectFilterChipsQueryResult = Apollo.QueryResult<ProjectFilterChipsQuery, ProjectFilterChipsQueryVariables>;
export const TasksListFilterOptionsDocument = gql`
    query TasksListFilterOptions {
  organisationMemberships {
    id
    ...TasksListFilterOptionsAssignee
  }
  crmPersons(limit: 0) {
    ... on CrmPersonConnection {
      edges {
        node {
          ...TasksListFilterOptionsCrmUser
        }
      }
    }
  }
  projects(limit: 0, filters: {kind: DEFAULT}) {
    ... on ProjectConnection {
      edges {
        node {
          ...TasksListFilterOptionsProject
        }
      }
    }
  }
}
    ${TasksListFilterOptionsAssigneeFragmentDoc}
${TasksListFilterOptionsCrmUserFragmentDoc}
${TasksListFilterOptionsProjectFragmentDoc}`;

/**
 * __useTasksListFilterOptionsQuery__
 *
 * To run a query within a React component, call `useTasksListFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksListFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksListFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTasksListFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<TasksListFilterOptionsQuery, TasksListFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksListFilterOptionsQuery, TasksListFilterOptionsQueryVariables>(TasksListFilterOptionsDocument, options);
      }
export function useTasksListFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksListFilterOptionsQuery, TasksListFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksListFilterOptionsQuery, TasksListFilterOptionsQueryVariables>(TasksListFilterOptionsDocument, options);
        }
export function useTasksListFilterOptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TasksListFilterOptionsQuery, TasksListFilterOptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TasksListFilterOptionsQuery, TasksListFilterOptionsQueryVariables>(TasksListFilterOptionsDocument, options);
        }
export type TasksListFilterOptionsQueryHookResult = ReturnType<typeof useTasksListFilterOptionsQuery>;
export type TasksListFilterOptionsLazyQueryHookResult = ReturnType<typeof useTasksListFilterOptionsLazyQuery>;
export type TasksListFilterOptionsSuspenseQueryHookResult = ReturnType<typeof useTasksListFilterOptionsSuspenseQuery>;
export type TasksListFilterOptionsQueryResult = Apollo.QueryResult<TasksListFilterOptionsQuery, TasksListFilterOptionsQueryVariables>;
export const ToggleTodoItemDoneDocument = gql`
    mutation ToggleTodoItemDone($input: ModifyTodoItemInput!) {
  modifyTodoItem(input: $input) {
    todoItem {
      id
      isDone
      completedAt
      completedBy {
        id
        fullname
      }
    }
  }
}
    `;
export type ToggleTodoItemDoneMutationFn = Apollo.MutationFunction<ToggleTodoItemDoneMutation, ToggleTodoItemDoneMutationVariables>;

/**
 * __useToggleTodoItemDoneMutation__
 *
 * To run a mutation, you first call `useToggleTodoItemDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTodoItemDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTodoItemDoneMutation, { data, loading, error }] = useToggleTodoItemDoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleTodoItemDoneMutation(baseOptions?: Apollo.MutationHookOptions<ToggleTodoItemDoneMutation, ToggleTodoItemDoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleTodoItemDoneMutation, ToggleTodoItemDoneMutationVariables>(ToggleTodoItemDoneDocument, options);
      }
export type ToggleTodoItemDoneMutationHookResult = ReturnType<typeof useToggleTodoItemDoneMutation>;
export type ToggleTodoItemDoneMutationResult = Apollo.MutationResult<ToggleTodoItemDoneMutation>;
export type ToggleTodoItemDoneMutationOptions = Apollo.BaseMutationOptions<ToggleTodoItemDoneMutation, ToggleTodoItemDoneMutationVariables>;