import { gql, useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { useSetTreeHierarchyMutation } from "./useMoveTreeItem.generated.js";

export const useMoveTreeItem = ({
  projectId,
  docId,
}: {
  projectId: string | null;
  docId: string | null;
}): [
  handleMove: (itemId: string, parentId: string, rank: number) => Promise<void>,
  loading: boolean,
] => {
  const client = useApolloClient();
  const [setTreeHierarchy, { loading: setTreeHierarchyLoading }] =
    useSetTreeHierarchyMutation({ client });

  const handleMoveTreeItem = useCallback(
    async (itemId: string, parentId: string, rank: number) => {
      if (!docId) return;
      await setTreeHierarchy({
        variables: {
          input: {
            docId,
            projectId,
            itemId,
            parentId,
            rank,
          },
        },
      });
    },
    [docId, projectId, setTreeHierarchy]
  );

  return [handleMoveTreeItem, setTreeHierarchyLoading];
};
