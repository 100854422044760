import { useApolloClient } from "@apollo/client";
import { Modal, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Help as HelpIcon } from "@mui/icons-material";
import { Upgrade as UpgradeIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { omit, uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData.js";
import { Attachment } from "../../attachments/helpers.js";
import { SwitchField } from "../../../commons/form-fields/SwitchField.js";
import { TextField } from "../../../commons/form-fields/TextField.js";
import { Tabs } from "@msys/ui";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import {
  OrganisationContractingPdfFragment,
  useExtractOrganisationContractingPdfFormFieldsMutation,
  useModifyOrganisationContractingPdfsMutation,
} from "../sections/OrganisationContractingPdfsSection.generated.js";
import { useTranslate } from "@tolgee/react";
import { AttachmentFileRow } from "../../attachments/AttachmentFileRow.js";
import { AttachmentUploadRow } from "../../attachments/AttachmentUploadRow.js";
import { AvailableContractingPdfReplacementsModal } from "./AvailableContractingPdfReplacementsModal.js";

interface FormValues {
  title: string;
  isEnabled: boolean;
  needInsertPriceTable: boolean;
  startPage: number;
  replacePages: number;
  replacements: {
    formFieldName: string;
    replacementValue: string;
  }[];
}

interface Props {
  organisationId: string;
  title: string;
  handleClose: () => void;
  handleComplete?: (organisationId: string) => Promise<void>;
  contractingPdf: OrganisationContractingPdfFragment;
}

export const ModifyOrganisationContractingPdfModal = ({
  organisationId,
  title,
  handleClose,
  handleComplete,
  contractingPdf,
}: Props) => {
  const { t } = useTranslate([
    "OrganisationSettings",
    "Global",
    "AttachmentField",
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const [tab, setTab] = React.useState<"settings" | "replacements">("settings");

  const viewer = useUserData().currentUser!;

  const client = useApolloClient();

  const [modifyContractingPdf] = useModifyOrganisationContractingPdfsMutation({
    client,
    refetchQueries: [namedOperations.Query.OrganisationSettingsDocuments],
    awaitRefetchQueries: true,
  });

  const [extractFieldKeys, { loading: extracting }] =
    useExtractOrganisationContractingPdfFormFieldsMutation({
      client,
    });

  const handleExtractFieldKeys = async (): Promise<string[]> => {
    const result = await extractFieldKeys({
      variables: {
        input: {
          contractingPdfId: contractingPdf.id,
        },
      },
    });
    return (
      result?.data?.extractOrganisationContractingPdfFormFields
        ?.formFieldKeys ?? []
    );
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyContractingPdf({
        variables: {
          input: {
            contractingPdfId: contractingPdf.id,
            values: {
              title: values.title,
              isEnabled: values.isEnabled,
              needInsertPriceTable: values.needInsertPriceTable,
              startPage: values.startPage,
              replacePages: values.replacePages,
              replacements: values.replacements.map(value =>
                omit(value, "__typename")
              ),
            },
          },
        },
      });
      enqueueSnackbar(
        t("Changes saved", {
          ns: "Global",
        })
      );
      handleComplete?.(organisationId);
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues = React.useMemo(
    (): FormValues => ({
      title: contractingPdf.title,
      isEnabled: contractingPdf.isEnabled,
      needInsertPriceTable: contractingPdf.needInsertPriceTable,
      startPage: contractingPdf.startPage,
      replacePages: contractingPdf.replacePages,
      replacements: contractingPdf.replacements,
    }),
    [contractingPdf]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        replacements: Yup.array().of(
          Yup.object().shape({
            formFieldName: Yup.string()
              .required()
              .label(
                t("Field key", {
                  ns: "OrganisationSettings",
                })
              )
              .test(
                "unique",
                t("Value must be unique", {
                  ns: "Global",
                }),
                function (value: string | undefined) {
                  // @ts-ignore
                  const replacements = (this.from as any)[1].value
                    .replacements as { formFieldName: string }[];
                  const sameValues = replacements.filter(
                    v => v.formFieldName === value
                  );
                  return sameValues.length <= 1;
                }
              ),
            replacementValue: Yup.string().label(
              t("Replacement", {
                ns: "OrganisationSettings",
              })
            ),
          })
        ),
        title: Yup.string()
          .required()
          .label(
            t("Title", {
              ns: "Global",
            })
          ),
        isEnabled: Yup.boolean()
          .required()
          .label(
            t("Enabled", {
              ns: "OrganisationSettings",
            })
          ),
        needInsertPriceTable: Yup.boolean()
          .required()
          .label(
            t("Insert price table", {
              ns: "OrganisationSettings",
            })
          ),
        startPage: Yup.number()
          .min(1)
          .required()
          .label(
            t("Start page", {
              ns: "OrganisationSettings",
            })
          ),
        replacePages: Yup.number()
          .min(0)
          .required()
          .label(
            t("Replace pages", {
              ns: "OrganisationSettings",
            })
          ),
      }),
    [t]
  );

  const onRemoveAttachment = async (attachment: Attachment) => {
    try {
      await modifyContractingPdf({
        variables: {
          input: {
            contractingPdfId: contractingPdf.id,
            values: {
              attachment: null,
            },
          },
        },
      });
      enqueueSnackbar(
        t("File deleted", {
          ns: "AttachmentField",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const onAddAttachment = async (attachment: Attachment) => {
    try {
      await modifyContractingPdf({
        variables: {
          input: {
            contractingPdfId: contractingPdf.id,
            values: {
              attachment: omit(attachment, "id", "__typename", "__type"),
            },
          },
        },
      });
      enqueueSnackbar(
        t("File uploaded", {
          ns: "AttachmentField",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  if (viewer.organisation.id !== organisationId)
    return <div>Not own organisation!</div>;

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formikProps => (
        <Modal
          title={title}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled: !formikProps.isValid || !formikProps.dirty,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
        >
          <Tabs
            value={tab}
            onChange={setTab}
            options={[
              {
                value: "settings",
                label: t("Settings", {
                  ns: "OrganisationSettings",
                }),
              },
              {
                value: "replacements",
                label: t("Replacements", {
                  ns: "OrganisationSettings",
                }),
              },
            ]}
          />

          <Form id={formId}>
            {tab === "settings" && (
              <Stack direction="column" spacing={1} pt={1}>
                <TextField
                  label={t("Title", {
                    ns: "Global",
                  })}
                  name="title"
                />

                <SwitchField
                  label={t("Enabled", {
                    ns: "OrganisationSettings",
                  })}
                  name="isEnabled"
                />

                {formikProps.values.isEnabled && (
                  <>
                    <Box>
                      <AttachmentUploadRow
                        title={t("Template PDF", {
                          ns: "OrganisationSettings",
                        })}
                        accept=".pdf"
                        multiple={false}
                        onComplete={
                          contractingPdf.attachment
                            ? undefined
                            : onAddAttachment
                        }
                      />
                      {contractingPdf.attachment && (
                        <AttachmentFileRow
                          attachment={contractingPdf.attachment}
                          onRemove={onRemoveAttachment}
                        />
                      )}
                    </Box>

                    <SwitchField
                      label={t("Insert price table", {
                        ns: "OrganisationSettings",
                      })}
                      name="needInsertPriceTable"
                    />

                    {formikProps.values.needInsertPriceTable && (
                      <Stack direction="row" spacing={1}>
                        <Box>
                          <Typography gutterBottom>
                            {t("At which position table should be inserted", {
                              ns: "OrganisationSettings",
                            })}
                          </Typography>
                          <TextField
                            name="startPage"
                            label={t("Start page", {
                              ns: "OrganisationSettings",
                            })}
                            inputProps={{ type: "number", min: 1, step: 1 }}
                            type="number"
                          />
                        </Box>
                        <Box>
                          <Typography gutterBottom>
                            {t(
                              "How many pages should be replaces with the table",
                              {
                                ns: "OrganisationSettings",
                              }
                            )}
                          </Typography>
                          <TextField
                            name="replacePages"
                            label={t("Replace pages", {
                              ns: "OrganisationSettings",
                            })}
                            inputProps={{ type: "number", min: 0, step: 1 }}
                            type="number"
                          />
                        </Box>
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
            )}

            {tab === "replacements" && (
              <Stack direction="column" spacing={1} pt={1}>
                <Stack direction="row" spacing={1}>
                  <ModalOpenButton
                    Modal={AvailableContractingPdfReplacementsModal}
                  >
                    <Button
                      size="extra-small"
                      startIcon={<HelpIcon />}
                      variant="outlined"
                      color="secondary"
                    >
                      {t("Available replacements", {
                        ns: "OrganisationSettings",
                      })}
                    </Button>
                  </ModalOpenButton>
                  {contractingPdf.attachment && (
                    <LoadingButton
                      size="extra-small"
                      startIcon={<UpgradeIcon />}
                      variant="outlined"
                      color="secondary"
                      disabled={extracting}
                      loading={extracting}
                      onClick={async () => {
                        const formFields = await handleExtractFieldKeys();
                        const existingFormFields =
                          formikProps.values.replacements.map(
                            replacement => replacement.formFieldName
                          );
                        const newFormFields = formFields.filter(
                          field => !existingFormFields.includes(field)
                        );
                        if (newFormFields.length > 0) {
                          formikProps.setValues({
                            ...formikProps.values,
                            replacements: [
                              ...formikProps.values.replacements,
                              ...newFormFields.map(field => ({
                                formFieldName: field,
                                replacementValue: "",
                              })),
                            ],
                          });
                        }
                      }}
                    >
                      {t("Extract form fields", {
                        ns: "OrganisationSettings",
                      })}
                    </LoadingButton>
                  )}
                </Stack>

                <FieldArray
                  name="replacements"
                  render={arrayHelpers => (
                    <Stack direction="column" spacing={1}>
                      {formikProps.values.replacements.map((value, index) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          key={index}
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <TextField
                            name={`replacements.${index}.formFieldName`}
                            label={t("Field key", {
                              ns: "OrganisationSettings",
                            })}
                          />
                          <TextField
                            multiline
                            name={`replacements.${index}.replacementValue`}
                            label={t("Replacement", {
                              ns: "OrganisationSettings",
                            })}
                          />
                          <Box height="48px" display="flex" alignItems="center">
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Stack>
                      ))}
                      <Button
                        startIcon={<AddIcon />}
                        size="small"
                        variant="text"
                        color="secondary"
                        sx={{ alignSelf: "flex-start" }}
                        onClick={() => {
                          arrayHelpers.push({
                            formFieldName: "",
                            replacementValue: "",
                          });
                        }}
                      >
                        {t("Add value", {
                          ns: "Global",
                        })}
                      </Button>
                    </Stack>
                  )}
                />
              </Stack>
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
