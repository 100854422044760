/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../../doc-items/Fragments.generated.js';
import { TemplatesQuoteStatus_TemplateFragmentDoc } from './TemplateQuoteStatus.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModifyQuoteTemplateDiscountMutationVariables = Types.Exact<{
  input: Types.ModifyQuoteTemplateInput;
}>;


export type ModifyQuoteTemplateDiscountMutation = { modifyQuoteTemplate: { __typename: 'ModifyQuoteTemplateResult', quoteTemplate: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, discountPercentage: number, accessType: Types.TemplateAccessType, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, containsUnpublishedChanged: boolean, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null } } };


export const ModifyQuoteTemplateDiscountDocument = gql`
    mutation ModifyQuoteTemplateDiscount($input: ModifyQuoteTemplateInput!) {
  modifyQuoteTemplate(input: $input) {
    quoteTemplate {
      id
      resolvedAsReadModelVersionNumber
      discountPercentage
      proposedCalculation {
        ...ItemCalculation
      }
      ...TemplatesQuoteStatus_Template
    }
  }
}
    ${ItemCalculationFragmentDoc}
${TemplatesQuoteStatus_TemplateFragmentDoc}`;
export type ModifyQuoteTemplateDiscountMutationFn = Apollo.MutationFunction<ModifyQuoteTemplateDiscountMutation, ModifyQuoteTemplateDiscountMutationVariables>;

/**
 * __useModifyQuoteTemplateDiscountMutation__
 *
 * To run a mutation, you first call `useModifyQuoteTemplateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyQuoteTemplateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyQuoteTemplateDiscountMutation, { data, loading, error }] = useModifyQuoteTemplateDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyQuoteTemplateDiscountMutation(baseOptions?: Apollo.MutationHookOptions<ModifyQuoteTemplateDiscountMutation, ModifyQuoteTemplateDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyQuoteTemplateDiscountMutation, ModifyQuoteTemplateDiscountMutationVariables>(ModifyQuoteTemplateDiscountDocument, options);
      }
export type ModifyQuoteTemplateDiscountMutationHookResult = ReturnType<typeof useModifyQuoteTemplateDiscountMutation>;
export type ModifyQuoteTemplateDiscountMutationResult = Apollo.MutationResult<ModifyQuoteTemplateDiscountMutation>;
export type ModifyQuoteTemplateDiscountMutationOptions = Apollo.BaseMutationOptions<ModifyQuoteTemplateDiscountMutation, ModifyQuoteTemplateDiscountMutationVariables>;