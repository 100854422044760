/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DuplicateQuoteMutationVariables = Types.Exact<{
  input: Types.CopyQuoteIntoNewQuoteInput;
}>;


export type DuplicateQuoteMutation = { copyQuoteIntoNewQuote: { __typename: 'CopyQuoteIntoNewQuoteResult', quote: { __typename: 'Quote', id: string } } };


export const DuplicateQuoteDocument = gql`
    mutation DuplicateQuote($input: CopyQuoteIntoNewQuoteInput!) {
  copyQuoteIntoNewQuote(input: $input) {
    quote {
      id
    }
  }
}
    `;
export type DuplicateQuoteMutationFn = Apollo.MutationFunction<DuplicateQuoteMutation, DuplicateQuoteMutationVariables>;

/**
 * __useDuplicateQuoteMutation__
 *
 * To run a mutation, you first call `useDuplicateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateQuoteMutation, { data, loading, error }] = useDuplicateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateQuoteMutation, DuplicateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateQuoteMutation, DuplicateQuoteMutationVariables>(DuplicateQuoteDocument, options);
      }
export type DuplicateQuoteMutationHookResult = ReturnType<typeof useDuplicateQuoteMutation>;
export type DuplicateQuoteMutationResult = Apollo.MutationResult<DuplicateQuoteMutation>;
export type DuplicateQuoteMutationOptions = Apollo.BaseMutationOptions<DuplicateQuoteMutation, DuplicateQuoteMutationVariables>;