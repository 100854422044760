import {
  FormattedPercentage,
  LabeledSpecializedValue,
  LabeledValue,
} from "@msys/ui";
import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedPriceField } from "../../../commons/form-fields/FormattedPriceField.js";
import { useTranslate } from "@tolgee/react";
import { useVerticals } from "../../verticals/hooks/useVerticals.js";
import { Vertical } from "../../verticals/types.js";

interface Props {
  vertical: Vertical | null;
  isEditable: boolean;
  isWorkRateEditable: boolean;
  workBuyingRate: number;
  workSellingRate: number;
}

export const WorkRatesField = ({
  vertical,
  isEditable,
  isWorkRateEditable,
  workBuyingRate,
  workSellingRate,
}: Props) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);
  const { verticalLabels } = useVerticals();

  const workMargin =
    workBuyingRate !== 0
      ? workSellingRate / workBuyingRate - 1
      : workSellingRate === 0
        ? 0
        : workSellingRate > 0
          ? 1
          : -1;

  return (
    <Stack p={1} direction="column">
      <Typography variant="h4">
        {t("Vertical", {
          ns: "QuoteItem",
        })}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {!vertical
          ? t("Not set", {
              ns: "Global",
            })
          : verticalLabels[vertical]}
      </Typography>
      <Typography variant="h4">
        {t("Work cost", {
          ns: "QuoteItem",
        })}
      </Typography>
      <Grid container columns={16} minWidth={380}>
        <Grid item xs={5}>
          {isEditable && isWorkRateEditable ? (
            <FormattedPriceField
              disabled={false}
              name="workBuyingRate"
              label={t("Work cost", {
                ns: "QuoteItem",
              })}
              type="price"
            />
          ) : (
            <LabeledSpecializedValue
              label={t("Work cost", {
                ns: "QuoteItem",
              })}
              type="price"
              value={workBuyingRate}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          )}
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          +
        </Grid>
        <Grid item xs={5} display="flex" alignItems="center">
          <LabeledValue
            label={t("Work margin", {
              ns: "QuoteItem",
            })}
          >
            <FormattedPercentage value={workMargin} />
          </LabeledValue>
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          =
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {isEditable && isWorkRateEditable ? (
            <FormattedPriceField
              disabled={false}
              name="workSellingRate"
              label={t("Work rate", {
                ns: "QuoteItem",
              })}
              type="price"
            />
          ) : (
            <LabeledSpecializedValue
              labelProps={{ style: { textAlign: "right" } }}
              style={{ textAlign: "right" }}
              label={t("Work rate", {
                ns: "QuoteItem",
              })}
              type="price"
              value={workSellingRate}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};
