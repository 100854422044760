import { getSupportedSystemLocale } from "@msys/tolgee";
import { useTolgee } from "@tolgee/react";
import React from "react";
import { useUserData } from "../../app/auth/useUserData.js";
import { Language, languages } from "./languages.js";

export function useLanguages() {
  const { currentUser: viewer, updateUser } = useUserData();
  const tolgee = useTolgee(["language"]);
  const language = tolgee.getLanguage()!;

  const selectLanguage = React.useCallback(
    async (language: Language) => {
      if (viewer) {
        tolgee.changeLanguage(language.locale);
        await updateUser({
          user: { locale: language.locale },
        });
      } else {
        tolgee.changeLanguage(language.locale);
      }
    },
    [tolgee, updateUser, viewer]
  );

  const currentLanguage = React.useMemo(() => {
    const locale = getSupportedSystemLocale(language);
    return languages[locale];
  }, [language]);

  const languageArray = React.useMemo(() => Object.values(languages), []);

  return { currentLanguage, selectLanguage, languages: languageArray };
}
