import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LoadingSpinner } from "@msys/ui";
import { useParams } from "react-router-dom";
import {
  ProjectStateMachineStatus,
  namedOperations,
} from "../../../clients/graphqlTypes.js";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission.js";
import { useUserData } from "../../auth/useUserData.js";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageGrid } from "../../commons/layout/PageGrid.js";
import { CrmCompanyBuildingsBox } from "../../features/crm-companies/boxes/CrmCompanyBuildingsBox.js";
import { CrmCompanyCommentsBox } from "../../features/crm-companies/boxes/CrmCompanyCommentsBox.js";
import { CrmCompanyCustomFieldsBox } from "../../features/crm-companies/boxes/CrmCompanyCustomFieldsBox.js";
import { CrmCompanyDetailsBox } from "../../features/crm-companies/boxes/CrmCompanyDetailsBox.js";
import { CrmCompanyInvoicesBox } from "../../features/crm-companies/boxes/CrmCompanyInvoicesBox.js";
import { CrmCompanyMembersBox } from "../../features/crm-companies/boxes/CrmCompanyMembersBox.js";
import { CrmCompanyOpportunitiesBox } from "../../features/crm-companies/boxes/CrmCompanyOpportunitiesBox.js";
import { CrmCompanyOwnInformation } from "../../features/crm-companies/boxes/CrmCompanyOwnInformationAtCrmBox.js";
import { CrmCompanyPaymentInformationBox } from "../../features/crm-companies/boxes/CrmCompanyPaymentInformationBox.js";
import { CrmCompanyProjectsBox } from "../../features/crm-companies/boxes/CrmCompanyProjectsBox.js";
import { CrmCompanyQuotesBox } from "../../features/crm-companies/boxes/CrmCompanyQuotesBox.js";
import { CrmCompanyRegistrationDetailsBox } from "../../features/crm-companies/boxes/CrmCompanyRegistrationDetailsBox.js";
import { CrmCompanySupplierCataloguesBox } from "../../features/crm-companies/boxes/CrmCompanySupplierCataloguesBox.js";
import { CrmEmailMessagesBox } from "../../features/crm/CrmEmailMessagesBox.js";
import { PimImportations } from "../../features/pimImportations/PimImportations.js";
import { TodosBox } from "../../features/todos/TodosBox.js";
import { useCrmCompanyProfileQuery } from "./CrmCompanyProfile.generated.js";

interface Props {
  submenuItems: PageTopbarItem[];
}

export function CrmCompanyProfile({ submenuItems }: Props) {
  const { organisationId: crmCompanyId } = useParams();
  if (!crmCompanyId) throw new Error("Organisation id is missing");
  const viewer = useUserData().currentUser!;

  const opportunitiesCurrentStates: ProjectStateMachineStatus[] = [
    "opportunity",
  ];
  const opportunitiesPastStates: ProjectStateMachineStatus[] = [
    "contractee_declined",
    "contractor_declined",
  ];
  const projectsCurrentStates: ProjectStateMachineStatus[] = viewer.organisation
    .isClientOrganisation
    ? ["contracted", "opportunity"]
    : ["contracted"];
  const projectsPastStates: ProjectStateMachineStatus[] = viewer.organisation
    .isClientOrganisation
    ? ["completed", "cancelled", "contractee_declined", "legacy_closed"]
    : ["completed", "cancelled", "legacy_closed"];

  const client = useApolloClient();
  const query = useCrmCompanyProfileQuery({
    client,
    variables: {
      crmCompanyId,
      projectsCurrentStates,
      projectsPastStates,
      opportunitiesCurrentStates,
      opportunitiesPastStates,
    },
  });

  const crmCompany = getDataOrNull(query.data?.crmCompany)?.crmCompany;
  const projectsCurrent =
    getDataOrNull(query.data?.projectsCurrent)?.edges.map(edge => edge.node) ??
    [];
  const projectsCurrentTotalCount =
    getDataOrNull(query.data?.projectsCurrent)?.totalCount ?? 0;
  const projectsPast =
    getDataOrNull(query.data?.projectsPast)?.edges.map(edge => edge.node) ?? [];
  const projectsPastTotalCount =
    getDataOrNull(query.data?.projectsPast)?.totalCount ?? 0;
  const opportunitiesCurrent =
    getDataOrNull(query.data?.opportunitiesCurrent)?.edges.map(
      edge => edge.node
    ) ?? [];
  const opportunitiesCurrentTotalCount =
    getDataOrNull(query.data?.opportunitiesCurrent)?.totalCount ?? 0;
  const opportunitiesPast =
    getDataOrNull(query.data?.opportunitiesPast)?.edges.map(
      edge => edge.node
    ) ?? [];
  const opportunitiesPastTotalCount =
    getDataOrNull(query.data?.opportunitiesPast)?.totalCount ?? 0;
  const building = query.data?.buildings.edges.map(edge => edge.node) ?? [];
  const outgoingInvoices =
    getDataOrNull(query.data?.outgoingInvoices)?.edges.map(edge => edge.node) ??
    [];
  const outgoingInvoicesTotalCount =
    getDataOrNull(query.data?.outgoingInvoices)?.totalCount ?? 0;
  const incomingInvoices =
    getDataOrNull(query.data?.incomingInvoices)?.edges.map(edge => edge.node) ??
    [];
  const incomingInvoicesTotalCount =
    getDataOrNull(query.data?.incomingInvoices)?.totalCount ?? 0;
  const outgoingQuotes =
    getDataOrNull(query.data?.outgoingQuotes)?.edges.map(edge => edge.node) ??
    [];
  const outgoingQuotesTotalCount =
    getDataOrNull(query.data?.outgoingQuotes)?.totalCount ?? 0;
  const incomingQuotes =
    getDataOrNull(query.data?.incomingQuotes)?.edges.map(edge => edge.node) ??
    [];
  const incomingQuotesTotalCount =
    getDataOrNull(query.data?.incomingQuotes)?.totalCount ?? 0;
  const channels = query.data?.crmCompanyChannels ?? [];

  return (
    <Page title={crmCompany?.title} submenuItems={submenuItems}>
      <PageContainer>
        {query.loading && <LoadingSpinner />}
        {crmCompany && (
          <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
            <>
              <CrmCompanyDetailsBox crmCompany={crmCompany} />
              <CrmCompanyCustomFieldsBox crmCompany={crmCompany} />
            </>
            <>
              <CrmCompanyMembersBox crmCompany={crmCompany} />
              {crmCompany.systemTags.includes("SUPPLIER") && (
                <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_CRM">
                  <CrmCompanyOwnInformation crmCompany={crmCompany} />
                </RestrictedByOrganisationPermissionWithDebug>
              )}
              <CrmEmailMessagesBox
                crmCompany={crmCompany}
                refetchQueries={[namedOperations.Query.CrmCompanyProfile]}
              />
            </>
            <>
              <CrmCompanyPaymentInformationBox crmCompany={crmCompany} />
              <CrmCompanyRegistrationDetailsBox crmCompany={crmCompany} />
            </>
            <>
              <CrmCompanyProjectsBox
                key={"projects"}
                crmCompanyId={crmCompanyId}
                projectsCurrent={projectsCurrent}
                projectsCurrentTotalCount={projectsCurrentTotalCount}
                projectsPast={projectsPast}
                projectsPastTotalCount={projectsPastTotalCount}
                projectsCurrentStates={projectsCurrentStates}
                projectsPastStates={projectsPastStates}
                isLoading={query.loading}
              />
              {viewer.organisation.isCraftsmanOrganisation && (
                <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
                  <CrmCompanyOpportunitiesBox
                    key={"opportunities"}
                    crmCompanyId={crmCompanyId}
                    opportunitiesCurrent={opportunitiesCurrent}
                    opportunitiesCurrentTotalCount={
                      opportunitiesCurrentTotalCount
                    }
                    opportunitiesPast={opportunitiesPast}
                    opportunitiesPastTotalCount={opportunitiesPastTotalCount}
                    opportunitiesCurrentStates={opportunitiesCurrentStates}
                    opportunitiesPastStates={opportunitiesPastStates}
                    isLoading={query.loading}
                  />
                </RestrictedByOrganisationPermissionWithDebug>
              )}
              <CrmCompanyBuildingsBox
                key={"buildings"}
                crmCompanyId={crmCompanyId}
                buildings={building}
                isLoading={query.loading}
              />
              <RestrictedByOrganisationPermissionWithDebug permission="READ_QUOTES">
                <CrmCompanyQuotesBox
                  key={"quotes"}
                  crmCompanyId={crmCompanyId}
                  quotesOutgoing={outgoingQuotes}
                  quotesOutgoingTotalCount={outgoingQuotesTotalCount}
                  quotesIncoming={incomingQuotes}
                  quotesIncomingTotalCount={incomingQuotesTotalCount}
                  isLoading={query.loading}
                />
              </RestrictedByOrganisationPermissionWithDebug>
              <RestrictedByOrganisationPermissionWithDebug permission="READ_INVOICES">
                <CrmCompanyInvoicesBox
                  key={"invoices"}
                  crmCompanyId={crmCompanyId}
                  invoicesOutgoing={outgoingInvoices}
                  invoicesOutgoingTotalCount={outgoingInvoicesTotalCount}
                  invoicesIncoming={incomingInvoices}
                  invoicesIncomingTotalCount={incomingInvoicesTotalCount}
                  isLoading={query.loading}
                />
              </RestrictedByOrganisationPermissionWithDebug>
              <TodosBox key={"todos"} linkedCrmOrganisation={crmCompany} />
              <CrmCompanyCommentsBox
                key={"comments"}
                crmCompanyId={crmCompanyId}
                channels={channels}
                isLoading={query.loading}
              />
              {crmCompany.systemTags.includes("SUPPLIER") &&
                crmCompany.linkedSystemOrganisationId && (
                  <RestrictedByCapabilityWithDebug capability="PIM">
                    <PimImportations
                      supplierId={crmCompany.linkedSystemOrganisationId}
                    />
                  </RestrictedByCapabilityWithDebug>
                )}
              {crmCompany.systemTags.includes("SUPPLIER") &&
                crmCompany.linkedSystemOrganisationId && (
                  <RestrictedByCapabilityWithDebug capability="PIM">
                    <CrmCompanySupplierCataloguesBox
                      supplierId={crmCompany.linkedSystemOrganisationId}
                    />
                  </RestrictedByCapabilityWithDebug>
                )}
            </>
          </PageGrid>
        )}
      </PageContainer>
    </Page>
  );
}
