/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { QuotePreviewListItemFragmentDoc } from '../QuotePreviewListItem.generated.js';
import { InviteContractee_ProjectFragmentDoc } from '../../projects/useProjectContracteeInvite.generated.js';
import { InviteContracteeField_ProjectFragmentDoc } from '../form-fields/InviteContracteeField.generated.js';
import { ItemAttachmentFragmentDoc } from '../../attachments/Attachments.generated.js';
import { PdfTemplateSelectModal_OrganisationContractingPdfFragmentDoc } from './PdfTemplateSelectModal.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendQuoteViaEmailModal_QuoteFragment = { __typename: 'Quote', id: string, title: string, createdAt: any, isBinding: boolean, isPublished: boolean, agreement: Types.Agreement, needsAgreement: boolean, expirationDate?: any | null, contractee: { __typename: 'DocActor', id: string, companyName: string }, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export type SendQuoteViaEmailModal_ProjectFragment = { __typename: 'Project', id: string, title: string, canInviteContractee: boolean, viewerPermissions: Array<Types.PermissionName>, crmOrganisation?: { __typename: 'CrmCompany', id: string, members: Array<{ __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string, email: any }> } | null, contracteeInvitations: Array<{ __typename: 'ProjectContracteeInvitation', id: string, contact: { __typename: 'InvitationContact', id: string } }> };

export type SendQuoteViaEmailQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type SendQuoteViaEmailQuery = { quote: { __typename: 'MissingPermissions' } | { __typename: 'QuoteResult', quote?: { __typename: 'Quote', id: string, contractee: { __typename: 'DocActor', id: string, companyName: string, email: string, viewerCrmCompany?: { __typename: 'CrmCompany', id: string, title: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, email: any, fullname: string, firstname: string, familyname: string, crmCompany: { __typename: 'CrmCompany', id: string } }> } | null }, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, isVisibleToOtherSide: boolean, isRootItem: boolean, attachments: Array<{ __typename: 'ItemAttachment', id: string, title: string, mimeType: string, url: string, group: string, clientVisibility: boolean, isProductAttachment: boolean }> }> } | null }, organisationContractingPdfs: { __typename: 'OrganisationContractingPdfsResult', contractingPdfs: Array<{ __typename: 'OrganisationContractingPdf', id: string, title: string }> } };

export const SendQuoteViaEmailModal_QuoteFragmentDoc = gql`
    fragment SendQuoteViaEmailModal_Quote on Quote {
  id
  ...QuotePreviewListItem
}
    ${QuotePreviewListItemFragmentDoc}`;
export const SendQuoteViaEmailModal_ProjectFragmentDoc = gql`
    fragment SendQuoteViaEmailModal_Project on Project {
  id
  ...InviteContractee_Project
  ...InviteContracteeField_Project
}
    ${InviteContractee_ProjectFragmentDoc}
${InviteContracteeField_ProjectFragmentDoc}`;
export const SendQuoteViaEmailDocument = gql`
    query SendQuoteViaEmail($projectId: ID!, $quoteId: ID!) {
  quote(projectId: $projectId, docId: $quoteId) {
    ... on QuoteResult {
      quote {
        id
        contractee {
          id
          companyName
          email
          viewerCrmCompany {
            id
            title
            email
            members {
              id
              email
              fullname
              firstname
              familyname
              crmCompany {
                id
              }
            }
          }
        }
        items {
          id
          originVersionNumber
          isVisibleToOtherSide
          isRootItem
          attachments {
            ...ItemAttachment
          }
        }
      }
    }
  }
  organisationContractingPdfs(filters: {enabled: true}) {
    ... on OrganisationContractingPdfsResult {
      contractingPdfs {
        id
        ...PdfTemplateSelectModal_OrganisationContractingPdf
      }
    }
  }
}
    ${ItemAttachmentFragmentDoc}
${PdfTemplateSelectModal_OrganisationContractingPdfFragmentDoc}`;

/**
 * __useSendQuoteViaEmailQuery__
 *
 * To run a query within a React component, call `useSendQuoteViaEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendQuoteViaEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendQuoteViaEmailQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useSendQuoteViaEmailQuery(baseOptions: Apollo.QueryHookOptions<SendQuoteViaEmailQuery, SendQuoteViaEmailQueryVariables> & ({ variables: SendQuoteViaEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendQuoteViaEmailQuery, SendQuoteViaEmailQueryVariables>(SendQuoteViaEmailDocument, options);
      }
export function useSendQuoteViaEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendQuoteViaEmailQuery, SendQuoteViaEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendQuoteViaEmailQuery, SendQuoteViaEmailQueryVariables>(SendQuoteViaEmailDocument, options);
        }
export function useSendQuoteViaEmailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SendQuoteViaEmailQuery, SendQuoteViaEmailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SendQuoteViaEmailQuery, SendQuoteViaEmailQueryVariables>(SendQuoteViaEmailDocument, options);
        }
export type SendQuoteViaEmailQueryHookResult = ReturnType<typeof useSendQuoteViaEmailQuery>;
export type SendQuoteViaEmailLazyQueryHookResult = ReturnType<typeof useSendQuoteViaEmailLazyQuery>;
export type SendQuoteViaEmailSuspenseQueryHookResult = ReturnType<typeof useSendQuoteViaEmailSuspenseQuery>;
export type SendQuoteViaEmailQueryResult = Apollo.QueryResult<SendQuoteViaEmailQuery, SendQuoteViaEmailQueryVariables>;