/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type TemplateLinkIconButton_ItemFragment = { __typename: 'Item', id: string, linkedQuoteTemplate?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null } | null };

export const TemplateLinkIconButton_ItemFragmentDoc = gql`
    fragment TemplateLinkIconButton_Item on Item {
  id
  linkedQuoteTemplate {
    id
    resolvedAsReadModelVersionNumber
  }
}
    `;