import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import React from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../commons/layout/Page.js";
import { BreadcrumbItem } from "../../commons/layout/PageBreadcrumbs.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageHeader } from "../../commons/layout/PageHeader.js";
import { PageTopbarItem } from "../../commons/layout/PageSubmenu.js";
import { useProjectIncomingInvoiceQuery } from "./ProjectIncomingInvoice.generated.js";
import { useTranslate } from "@tolgee/react";

export const ProjectIncomingInvoice = ({
  projectId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const { invoiceId } = useParams();
  if (!invoiceId) throw new Error("Invoice id is missing");

  const { t } = useTranslate(["Invoices", "SidebarItems"]);

  const client = useApolloClient();
  const query = useProjectIncomingInvoiceQuery({
    client,
    variables: { invoiceId, projectId },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const invoice = getDataOrNull(query.data?.incomingInvoice);

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Incoming invoices", {
          ns: "Invoices",
        }),
        to: `/projects/${projectId}/invoices/incoming`,
      },
      {
        title: invoice?.title ?? "",
        to: `/projects/${projectId}/invoices/invoming/${invoiceId}`,
      },
    ],
    [prefixBreadcrumbs, t, projectId, invoice?.title, invoiceId]
  );

  return (
    <Page
      subtitle={project?.title}
      title={invoice?.title}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
    >
      <PageContainer $fullHeight>
        <iframe
          src={invoice?.file?.url}
          title={"PDF"}
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      </PageContainer>
    </Page>
  );
};
