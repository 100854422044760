import { useFormatting } from "@msys/ui";
import { useFormikContext } from "formik";
import { clamp } from "lodash-es";
import React from "react";
import { FormattedField } from "./FormattedField.js";

export const FormattedPriceField = ({
  name,
  min,
  max,
  multiplier = 1,
  ...props
}: {
  name: string;
  min?: number;
  max?: number;
  multiplier?: number;
} & Omit<
  React.ComponentProps<typeof FormattedField<number>>,
  "getFormattedValue" | "getActualValue" | "getEditableValue"
>) => {
  const { getFieldProps } = useFormikContext<{
    [key: string]: unknown;
  }>();
  const fieldProps = getFieldProps<number>(name);
  const value = fieldProps.value;

  const { getFormattedPrice, getEditableFloat, getFloat } = useFormatting();

  function getFormattedValue(value: number | null | undefined) {
    return getFormattedPrice(value ?? 0);
  }

  function getActualValue(value: string) {
    const newValue = clamp(
      getFloat(value) / multiplier,
      min ?? Number.MIN_SAFE_INTEGER,
      max ?? Number.MAX_SAFE_INTEGER
    );

    return newValue;
  }

  function getEditableValue() {
    return getEditableFloat((value ?? 0) * multiplier);
  }

  return (
    <FormattedField
      {...props}
      name={name}
      getFormattedValue={getFormattedValue}
      getActualValue={getActualValue}
      getEditableValue={getEditableValue}
    />
  );
};

// TODO: can we find a way to merge this with the above component?
export const FormattedPriceFieldNullable = ({
  name,
  min,
  max,
  multiplier = 1,
  ...props
}: {
  name: string;
  min?: number;
  max?: number;
  multiplier?: number;
} & Omit<
  React.ComponentProps<typeof FormattedField<number | null>>,
  "getFormattedValue" | "getActualValue" | "getEditableValue"
>) => {
  const { getFieldProps } = useFormikContext<{
    [key: string]: unknown;
  }>();
  const fieldProps = getFieldProps<number | null>(name);
  const value = fieldProps.value;

  const { getFormattedPrice, getEditableFloat, getFloat } = useFormatting();

  function getFormattedValue(value: number | null | undefined) {
    return getFormattedPrice(typeof value === "number" ? value : undefined);
  }

  function getActualValue(value: string) {
    const newValue =
      value !== ""
        ? clamp(
            getFloat(value, true) / multiplier,
            min ?? Number.MIN_SAFE_INTEGER,
            max ?? Number.MAX_SAFE_INTEGER
          )
        : null;

    return newValue;
  }

  function getEditableValue() {
    return getEditableFloat((value ?? 0) * multiplier);
  }

  return (
    <FormattedField
      {...props}
      name={name}
      getFormattedValue={getFormattedValue}
      getActualValue={getActualValue}
      getEditableValue={getEditableValue}
    />
  );
};
