/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationMembersBoxFragment = { __typename: 'ViewerOrganisation', id: string, title: string, representive?: { __typename: 'User', id: string } | null, memberships: Array<{ __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, email: any, active: boolean, roles: Array<{ __typename: 'OrganisationRole', id: string, internalName: string }>, phones: Array<{ __typename: 'Phone', number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, membershipInvitations: Array<{ __typename: 'OrganisationMembershipInvitation', id: string, status: Types.InvitationState, contact: { __typename: 'InvitationContact', firstname: string, familyname: string }, organisationRoles?: Array<{ __typename: 'OrganisationRole', id: string, internalName: string }> | null }> };

export type OrganisationMembers_ModifyOrganisationMutationVariables = Types.Exact<{
  input: Types.OrganisationUpdateInput;
}>;


export type OrganisationMembers_ModifyOrganisationMutation = { updateOrganisation: { __typename: 'OrganisationUpdateResult', organisation: { __typename: 'ViewerOrganisation', id: string, representive?: { __typename: 'User', id: string } | null } } };

export type CancelOrganisationMembershipInvitationMutationVariables = Types.Exact<{
  organisationMembershipInvitationId: Types.Scalars['ID']['input'];
}>;


export type CancelOrganisationMembershipInvitationMutation = { cancelOrganisationMembershipInvitation: { __typename: 'CancelOrganisationMembershipInvitationResult', invitation: { __typename: 'OrganisationMembershipInvitation', id: string, status: Types.InvitationState } } };

export type OrganisationMemberRowFragment = { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, email: any, active: boolean, roles: Array<{ __typename: 'OrganisationRole', id: string, internalName: string }>, phones: Array<{ __typename: 'Phone', number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export type OrganisationMemberInvitationRowFragment = { __typename: 'OrganisationMembershipInvitation', id: string, status: Types.InvitationState, contact: { __typename: 'InvitationContact', firstname: string, familyname: string }, organisationRoles?: Array<{ __typename: 'OrganisationRole', id: string, internalName: string }> | null };

export const OrganisationMemberRowFragmentDoc = gql`
    fragment OrganisationMemberRow on User {
  id
  firstname
  familyname
  fullname
  email
  roles {
    id
    internalName
  }
  active
  phones {
    number
  }
  avatar {
    ...AttachmentSnapshot
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const OrganisationMemberInvitationRowFragmentDoc = gql`
    fragment OrganisationMemberInvitationRow on OrganisationMembershipInvitation {
  id
  contact {
    firstname
    familyname
  }
  organisationRoles {
    id
    internalName
  }
  status
}
    `;
export const OrganisationMembersBoxFragmentDoc = gql`
    fragment OrganisationMembersBox on ViewerOrganisation {
  id
  title
  representive {
    id
  }
  memberships {
    id
    ...OrganisationMemberRow
  }
  membershipInvitations {
    id
    ...OrganisationMemberInvitationRow
  }
}
    ${OrganisationMemberRowFragmentDoc}
${OrganisationMemberInvitationRowFragmentDoc}`;
export const OrganisationMembers_ModifyOrganisationDocument = gql`
    mutation OrganisationMembers_ModifyOrganisation($input: OrganisationUpdateInput!) {
  updateOrganisation(input: $input) {
    organisation {
      id
      representive {
        id
      }
    }
  }
}
    `;
export type OrganisationMembers_ModifyOrganisationMutationFn = Apollo.MutationFunction<OrganisationMembers_ModifyOrganisationMutation, OrganisationMembers_ModifyOrganisationMutationVariables>;

/**
 * __useOrganisationMembers_ModifyOrganisationMutation__
 *
 * To run a mutation, you first call `useOrganisationMembers_ModifyOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationMembers_ModifyOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationMembersModifyOrganisationMutation, { data, loading, error }] = useOrganisationMembers_ModifyOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationMembers_ModifyOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationMembers_ModifyOrganisationMutation, OrganisationMembers_ModifyOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationMembers_ModifyOrganisationMutation, OrganisationMembers_ModifyOrganisationMutationVariables>(OrganisationMembers_ModifyOrganisationDocument, options);
      }
export type OrganisationMembers_ModifyOrganisationMutationHookResult = ReturnType<typeof useOrganisationMembers_ModifyOrganisationMutation>;
export type OrganisationMembers_ModifyOrganisationMutationResult = Apollo.MutationResult<OrganisationMembers_ModifyOrganisationMutation>;
export type OrganisationMembers_ModifyOrganisationMutationOptions = Apollo.BaseMutationOptions<OrganisationMembers_ModifyOrganisationMutation, OrganisationMembers_ModifyOrganisationMutationVariables>;
export const CancelOrganisationMembershipInvitationDocument = gql`
    mutation CancelOrganisationMembershipInvitation($organisationMembershipInvitationId: ID!) {
  cancelOrganisationMembershipInvitation(
    organisationMembershipInvitationId: $organisationMembershipInvitationId
  ) {
    invitation {
      id
      status
    }
  }
}
    `;
export type CancelOrganisationMembershipInvitationMutationFn = Apollo.MutationFunction<CancelOrganisationMembershipInvitationMutation, CancelOrganisationMembershipInvitationMutationVariables>;

/**
 * __useCancelOrganisationMembershipInvitationMutation__
 *
 * To run a mutation, you first call `useCancelOrganisationMembershipInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrganisationMembershipInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrganisationMembershipInvitationMutation, { data, loading, error }] = useCancelOrganisationMembershipInvitationMutation({
 *   variables: {
 *      organisationMembershipInvitationId: // value for 'organisationMembershipInvitationId'
 *   },
 * });
 */
export function useCancelOrganisationMembershipInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CancelOrganisationMembershipInvitationMutation, CancelOrganisationMembershipInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelOrganisationMembershipInvitationMutation, CancelOrganisationMembershipInvitationMutationVariables>(CancelOrganisationMembershipInvitationDocument, options);
      }
export type CancelOrganisationMembershipInvitationMutationHookResult = ReturnType<typeof useCancelOrganisationMembershipInvitationMutation>;
export type CancelOrganisationMembershipInvitationMutationResult = Apollo.MutationResult<CancelOrganisationMembershipInvitationMutation>;
export type CancelOrganisationMembershipInvitationMutationOptions = Apollo.BaseMutationOptions<CancelOrganisationMembershipInvitationMutation, CancelOrganisationMembershipInvitationMutationVariables>;