import { useApolloClient } from "@apollo/client";
import { decodeGlobalId } from "@msys/common";
import { useLocalStorageAsState } from "@msys/ui";
import React, { useCallback, useEffect, useRef } from "react";
import { useUserData } from "../../../auth/useUserData.js";
import {
  ShopCartFragment,
  useShopSelectedCartLazyQuery,
} from "../Shop.generated.js";

const CART_KEY = "msys-cart";

export function useShop() {
  const viewer = useUserData().currentUser;
  const [internalCartId, setCartId] = useLocalStorageAsState<string | null>(
    CART_KEY + (viewer ? `-${viewer.organisation.id}` : ""),
    null
  );
  const cartId = internalCartId
    ? // fix to not use only numeric cart id
      /^[0-9]+$/.test(decodeGlobalId(internalCartId).id)
      ? null
      : internalCartId
    : null;

  const clearCartId = React.useCallback(() => setCartId(null), [setCartId]);

  return {
    cartId,
    setCartId,
    clearCartId,
  };
}

export const useShopSelectedCart = (): [
  ShopCartFragment | null,
  (newCartId: string) => Promise<ShopCartFragment | null>,
  () => Promise<ShopCartFragment | null>,
] => {
  const { cartId, setCartId } = useShop();

  const resolveRef = useRef<
    ((selectedCart: ShopCartFragment | null) => void) | null
  >(null);

  const client = useApolloClient();

  const [getData, querySelectedCart] = useShopSelectedCartLazyQuery({
    client,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (cartId) {
      // trigger data re-fetching on selected cart change
      getData({ variables: { cartId } });
    }
  }, [getData, cartId]);

  const selectedCart: ShopCartFragment | null =
    querySelectedCart?.data?.cart ?? null;

  useEffect(() => {
    if (!querySelectedCart?.loading) {
      // loading is done - notify saved callback about it and clear it
      if (resolveRef.current) {
        resolveRef.current(selectedCart);
        resolveRef.current = null;
      }
    }
  }, [selectedCart, querySelectedCart?.loading]);

  const setSelectedCartId: (
    newCartId: string
  ) => Promise<ShopCartFragment | null> = useCallback(
    (newCartId: string) =>
      new Promise<ShopCartFragment | null>(resolve => {
        resolveRef.current = resolve;
        if (cartId !== newCartId) {
          setCartId(newCartId);
        } else {
          getData({ variables: { cartId: newCartId } });
        }
      }),
    [cartId, setCartId, getData]
  );

  const refetchSelectedCart = useCallback(
    () =>
      new Promise<ShopCartFragment | null>(resolve => {
        if (!cartId) {
          resolve(null);
          return;
        }
        resolveRef.current = resolve;
        getData({ variables: { cartId } });
      }),
    [cartId, getData]
  );

  return [selectedCart, setSelectedCartId, refetchSelectedCart];
};
