import { CardContainer, useScreenWidth } from "@msys/ui";
import { Box, Chip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { useCategories } from "../../skill-categories/useCategories.js";
import { ModifyOrganisationProfileSkillsetButton } from "../buttons/ModifyOrganisationProfileSkillsetButton.js";
import { OrganisationPublicServicesBox_OrganisationProfileFragment } from "./OrganisationPublicServicesBox.generated.js";

interface Props {
  organisationProfile: OrganisationPublicServicesBox_OrganisationProfileFragment;
  canEdit: boolean;
}

export const OrganisationPublicServicesBox = ({
  organisationProfile,
  canEdit,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["OrganisationPublicProfile", "Global"]);
  const { isMaxPhone } = useScreenWidth();
  const categories = useCategories();

  const skillset = categories.allCategories.filter(c =>
    organisationProfile.skillset.includes(c.value)
  );

  return (
    <CardContainer
      title={t("Services", {
        ns: "OrganisationPublicProfile",
      })}
      itemCount={organisationProfile.skillset.length}
      ActionButton={
        canEdit && (
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
            {viewer.organisation.id === organisationProfile.organisation.id && (
              <ModifyOrganisationProfileSkillsetButton
                slug={organisationProfile.slug}
                skillset={organisationProfile.skillset}
              />
            )}
          </RestrictedByOrganisationPermissionWithDebug>
        )
      }
    >
      {skillset.length > 0 ? (
        <Stack flexWrap="wrap" p={isMaxPhone ? 1 : 2}>
          {skillset.map((category, index) => (
            <Chip
              key={index}
              icon={category.icon}
              size={"small"}
              variant="outlined"
              label={category.name}
            />
          ))}
        </Stack>
      ) : (
        <Box p={isMaxPhone ? 1 : 2}>
          <Typography color="grey.500">
            {t("Not set", { ns: "Global" })}
          </Typography>
        </Box>
      )}
    </CardContainer>
  );
};
