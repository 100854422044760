import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import { Modal } from "@msys/ui";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Replay as ReplayIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { useTranslate } from "@tolgee/react";
import { take } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import { FileInput } from "../../../commons/inputs/FileInput.js";
import { CrmSupplierSelect } from "../../crm/CrmSuppliersSelect.js";
import { use_3d_AddProductDataMutation } from "../Product.generated.js";
import {
  PimGetProductByArticleNumberIdentifierDocument,
  PimGetProductByArticleNumberIdentifierQuery,
  PimGetProductByArticleNumberIdentifierQueryVariables,
} from "../Products.generated.js";
import { use_3d_RequestAddProductDataUrlMutation } from "../_3d_DataUploader.generated.js";

type FileRow = {
  fileName: string;
  modelName: string | null;
  error: string | null;
  file: File | null;
};

type ProductRow = {
  articleNumber: string | null;
  fileName: string | null;
  modelName: string | null;
  error: string | null;
  file: File | null;
};

type MappingRow = { header: string; values: string[]; index: number };

type MappingValue = {
  field: "" | "articleNumber" | "modelName";
  index: number;
};

const parseCsv = async (file: File) => {
  const Papa = await import("papaparse");
  return await new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: false,
      complete: data => {
        resolve(data);
      },
      error(error: Error) {
        reject(error);
      },
    });
  });
};

const readZip = async (file: File): Promise<FileRow> => {
  const zip = await import("@zip.js/zip.js");
  const zipReader = new zip.ZipReader(new zip.BlobReader(file), {
    useWebWorkers: true,
    useCompressionStream: true,
  });

  const errors: string[] = [];
  let modelName: string | null = null;
  try {
    const textWriter = new zip.TextWriter();
    const textWriter2 = new zip.TextWriter();

    const entries = await zipReader.getEntries();

    const infoEntry = entries.find(e => e.filename === "info.json");
    if (infoEntry) {
      const data = await infoEntry.getData?.(textWriter);
      if (data) {
        const info = JSON.parse(data);
        modelName = (info?.itemProductData?.name as string) ?? null;
        if (!modelName) {
          errors.push("No item product data name in info.json");
        }
      } else {
        errors.push("Wrong info.json content");
      }
    } else {
      errors.push("No info.json file found");
    }

    const info2Entry = entries.find(e => e.filename === "info2.json");
    if (info2Entry) {
      const data2 = await info2Entry.getData?.(textWriter2);
      if (data2) {
        const info2 = JSON.parse(data2);
        const modelName2 = (info2?.name as string) ?? null;
        if (modelName2) {
          if (modelName2 !== modelName) {
            errors.push(
              "Item product data name are different in info.json and info2.json"
            );
          }
        } else {
          errors.push("No item product data name in info2.json");
        }
      } else {
        errors.push("Wrong info2.json content");
      }
    } else {
      errors.push("No info2.json file found");
    }
  } catch (e) {
    if (e instanceof Error) errors.push(e.message);
  } finally {
    await zipReader.close();
  }

  return {
    fileName: file.name,
    modelName,
    error: errors.length > 0 ? errors.join("; ") : null,
    file: errors.length > 0 ? null : file,
  };
};

interface Props {
  handleClose(): void;
}

export const Products3dDistributionModal = ({ handleClose }: Props) => {
  const { t } = useTranslate(["Product", "Global", "ProductSearch"]);
  const { enqueueSnackbar } = useSnackbar();

  const [stage, setStage] = React.useState<
    "mappings" | "files" | "products" | "imports"
  >("mappings");

  const [supplierId, setSupplierId] = React.useState<string | null>(null);

  const [mappingsData, setMappingsData] = React.useState<MappingRow[] | null>(
    null
  );
  const [mappingsValue, setMappingsValue] = React.useState<
    Record<string, MappingValue>
  >({});

  const [products, setProducts] = React.useState<ProductRow[]>([]);
  const [productsToImport, setProductsToImport] = React.useState<ProductRow[]>(
    []
  );

  const [files, setFiles] = React.useState<FileRow[]>([]);

  const [isFinished, setIsFinished] = React.useState<boolean>(false);

  return (
    <Modal
      title={t("Product 3D models uploading", {
        ns: "ProductSearch",
      })}
      handleClose={handleClose}
      dialogProps={{
        maxWidth: "md",
      }}
      actionButtons={
        stage === "mappings"
          ? [
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Next", {
                  ns: "Global",
                }),
                handleClick: () => setStage("files"),
              },
            ]
          : stage === "files"
            ? [
                {
                  label: t("Back", {
                    ns: "Global",
                  }),
                  handleClick: () => setStage("mappings"),
                  buttonProps: { variant: "text" },
                },
                {
                  label: t("Next", {
                    ns: "Global",
                  }),
                  handleClick: () => {
                    try {
                      const articleIndex = Object.values(mappingsValue).find(
                        mv => mv.field === "articleNumber"
                      );
                      const modelIndex = Object.values(mappingsValue).find(
                        mv => mv.field === "modelName"
                      );

                      if (!articleIndex)
                        throw new Error("Article number field not provided");

                      if (!modelIndex)
                        throw new Error("Model name field not provided");

                      const articles =
                        mappingsData?.[articleIndex.index]?.values;
                      const models = mappingsData?.[modelIndex.index]?.values;

                      if (!articles)
                        throw new Error("Article numbers not found");
                      if (!models) throw new Error("Model names not found");

                      const products: ProductRow[] = articles
                        .map((article, index) => {
                          const model = models[index];
                          const file = files.find(
                            f =>
                              f.modelName?.trim()?.toLocaleLowerCase() ===
                              model?.trim()?.toLocaleLowerCase()
                          );
                          if (!article) return null;
                          return {
                            articleNumber: article || null,
                            modelName: model || null,
                            fileName: file?.fileName || null,
                            error: !model
                              ? "No model name provided"
                              : !file
                                ? "File with model not found"
                                : file?.error || null,
                            file: file?.file || null,
                          };
                        })
                        .filter(notNull);

                      setProducts(products);
                      setStage("products");
                    } catch (e) {
                      if (e instanceof Error)
                        enqueueSnackbar(e.message, { variant: "error" });
                    }
                  },
                },
              ]
            : stage === "products"
              ? [
                  {
                    label: t("Back", {
                      ns: "Global",
                    }),
                    handleClick: () => setStage("files"),
                    buttonProps: { variant: "text" },
                  },
                  {
                    label: t("Start", {
                      ns: "Global",
                    }),
                    handleClick: () => {
                      setProductsToImport(
                        products.filter(
                          p =>
                            p.articleNumber &&
                            p.modelName &&
                            p.fileName &&
                            p.file
                        )
                      );
                      setStage("imports");
                    },
                  },
                ]
              : stage === "imports"
                ? [
                    {
                      label: t("Done", {
                        ns: "Global",
                      }),
                      handleClick: handleClose,
                      buttonProps: {
                        loading: !isFinished,
                        disabled: !isFinished,
                      },
                    },
                  ]
                : []
      }
    >
      <Stack direction="column" spacing={1}>
        <CrmSupplierSelect
          value={supplierId ?? ""}
          onChange={setSupplierId}
          disabled={stage === "imports" && Boolean(supplierId)}
          useSystemOrganisationId={true}
        />

        {stage === "mappings" && (
          <MappingsStage
            mappingsData={mappingsData}
            setMappingsData={setMappingsData}
            mappingsValue={mappingsValue}
            setMappingsValue={setMappingsValue}
          />
        )}
        {stage === "files" && <FilesStage files={files} setFiles={setFiles} />}
        {stage === "products" && (
          <ProductsStage products={products} setProducts={setProducts} />
        )}
        {stage === "imports" && supplierId ? (
          <ImportsStage
            supplierId={supplierId}
            products={productsToImport}
            handleComplete={() => setIsFinished(true)}
          />
        ) : null}
      </Stack>
    </Modal>
  );
};

function MappingsStage({
  mappingsData,
  setMappingsData,
  mappingsValue,
  setMappingsValue,
}: {
  mappingsData: MappingRow[] | null;
  setMappingsData: React.Dispatch<React.SetStateAction<MappingRow[] | null>>;
  mappingsValue: Record<string, MappingValue>;
  setMappingsValue: React.Dispatch<
    React.SetStateAction<Record<string, MappingValue>>
  >;
}) {
  const { t } = useTranslate(["Product", "Global", "ProductSearch"]);
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = React.useState<"idle" | "loading" | "error">(
    "idle"
  );
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  return (
    <>
      {!mappingsData ? (
        <div>
          <LoadingButton
            color="primary"
            onClick={() => {
              fileInputRef.current!.click();
            }}
            disabled={status === "loading"}
            loading={status === "loading"}
            variant="contained"
          >
            Upload .csv mappings file
          </LoadingButton>
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ overflowX: "initial" }}>
          <Table size="small" stickyHeader>
            <colgroup>
              <col width="25%" />
              <col width="45%" />
              <col width="30%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>{"Column"}</TableCell>
                <TableCell>{"Value"}</TableCell>
                <TableCell>{"Label"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mappingsData.map(mappings => (
                <TableRow key={mappings.index}>
                  <TableCell component="th" scope="row">
                    {mappings.header}
                  </TableCell>
                  <TableCell>
                    {take(mappings.values.filter(Boolean), 5).join(", ")}
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel>{"Label"}</InputLabel>
                      <Select
                        fullWidth
                        value={mappingsValue[mappings.index]?.field || ""}
                        onChange={e => {
                          setMappingsValue(v => ({
                            ...v,
                            [mappings.index]: {
                              index: mappings.index,
                              field: e.target.value as
                                | ""
                                | "articleNumber"
                                | "modelName",
                            },
                          }));
                        }}
                      >
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="articleNumber">
                          Article number
                        </MenuItem>
                        <MenuItem value="modelName">3D model name</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <FileInput
        innerRef={fileInputRef}
        accept={".csv"}
        multiple={false}
        onStart={() => {
          setStatus("loading");
        }}
        onCancel={() => {
          setStatus("idle");
        }}
        onComplete={async (file: File) => {
          try {
            const data = await parseCsv(file);
            const headerRow = (data as any).data[0];
            const dataRows = (data as any).data.slice(1);

            const mappingsData = headerRow.map((header: any, index: number) => {
              return {
                header,
                index,
                values: dataRows.map((row: any) => row?.[index] ?? ""),
              };
            });

            setMappingsData(mappingsData);

            setStatus("idle");
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
            setStatus("error");
            console.error(e);
            Sentry.captureException(e);
          }
        }}
      />
    </>
  );
}

function FilesStage({
  files,
  setFiles,
}: {
  files: FileRow[];
  setFiles: React.Dispatch<React.SetStateAction<FileRow[]>>;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = React.useState<"idle" | "loading" | "error">(
    "idle"
  );
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <div>
        <LoadingButton
          color="primary"
          onClick={() => {
            fileInputRef.current!.click();
          }}
          disabled={status === "loading"}
          loading={status === "loading"}
          variant="contained"
        >
          Upload .zip 3D model files
        </LoadingButton>
      </div>

      {files.length > 0 && (
        <TableContainer component={Paper} sx={{ overflowX: "initial" }}>
          <Table size="small" stickyHeader>
            <colgroup>
              <col width="35%" />
              <col width="35%" />
              <col width="20%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>{"File name"}</TableCell>
                <TableCell>{"Model name"}</TableCell>
                <TableCell>{"Errors"}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {file.fileName}
                  </TableCell>
                  <TableCell>{file.modelName ?? "–"}</TableCell>
                  <TableCell>{file.error ?? "–"}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() =>
                        setFiles(files => files.filter((f, i) => i !== index))
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <FileInput
        innerRef={fileInputRef}
        accept={".zip"}
        multiple={true}
        onStart={() => {
          setStatus("loading");
        }}
        onCancel={() => {
          setStatus("idle");
        }}
        onComplete={async (files: File[]) => {
          try {
            const filesToAdd: FileRow[] = [];
            for (let file of files) {
              const fileToAdd = await readZip(file);
              filesToAdd.push(fileToAdd);
            }
            setFiles(f => [...f, ...filesToAdd]);
            setStatus("idle");
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
            setStatus("error");
            console.error(e);
            Sentry.captureException(e);
          }
        }}
      />
    </>
  );
}

function ProductsStage({
  products,
  setProducts,
}: {
  products: ProductRow[];
  setProducts: React.Dispatch<React.SetStateAction<ProductRow[]>>;
}) {
  return (
    <TableContainer component={Paper} sx={{ overflowX: "initial" }}>
      <Table size="small" stickyHeader>
        <colgroup>
          <col width="25%" />
          <col width="25%" />
          <col width="25%" />
          <col width="15%" />
          <col width="10%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{"File name"}</TableCell>
            <TableCell>{"Model name"}</TableCell>
            <TableCell>{"Zip file"}</TableCell>
            <TableCell>{"Errors"}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {product.articleNumber ?? "–"}
              </TableCell>
              <TableCell>{product.modelName ?? "–"}</TableCell>
              <TableCell>{product.fileName ?? "–"}</TableCell>
              <TableCell>{product.error ?? "–"}</TableCell>
              <TableCell align="right">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() =>
                    setProducts(product =>
                      product.filter((p, i) => i !== index)
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ImportsStage({
  products,
  handleComplete,
  supplierId,
}: {
  products: ProductRow[];
  handleComplete(): void;
  supplierId: string;
}) {
  const client = useApolloClient();
  const [_3d_requestAddProductDataUrlMutation] =
    use_3d_RequestAddProductDataUrlMutation({
      client,
    });
  const [_3d_addProductDataMutation] = use_3d_AddProductDataMutation({
    client,
  });

  const [statuses, setStatuses] = React.useState<
    Record<string, "waiting" | "loading" | "error" | "done">
  >({});

  const [errors, setErrors] = React.useState<Record<string, string | null>>({});

  const lastStatuses = React.useRef(statuses);
  lastStatuses.current = statuses;

  const lastErrors = React.useRef(errors);
  lastErrors.current = errors;

  const lastHandleComplete = React.useRef(handleComplete);
  lastHandleComplete.current = handleComplete;

  const processProduct =
    React.useRef<
      (supplierId: string, product: ProductRow, index: number) => Promise<void>
    >();

  processProduct.current = async (
    supplierId: string,
    product: ProductRow,
    index: number
  ) => {
    try {
      if (lastStatuses.current[index] === "loading") return;
      if (lastErrors.current[index]) setErrors(e => ({ ...e, [index]: null }));
      setStatuses(s => ({ ...s, [index]: "loading" }));

      const { data } = await client.query<
        PimGetProductByArticleNumberIdentifierQuery,
        PimGetProductByArticleNumberIdentifierQueryVariables
      >({
        query: PimGetProductByArticleNumberIdentifierDocument,
        variables: { supplierId, articleNumber: product.articleNumber! },
      });

      const pimProduct = getDataOrNull(data?.pimGetProduct)?.product;
      if (!pimProduct || !pimProduct.id) {
        setErrors(e => ({
          ...e,
          [index]: `Cannot find a product`,
        }));
        setStatuses(s => ({ ...s, [index]: "error" }));
        return;
      }

      const productId = pimProduct.id;

      let urlResult = await _3d_requestAddProductDataUrlMutation({
        variables: {
          input: {
            productId,
            filename: product.fileName!,
          },
        },
      });

      const uploadUrl = urlResult.data?._3d_requestAddProductDataUrl.uploadUrl;

      if (!uploadUrl) {
        setErrors(e => ({
          ...e,
          [index]: `Cannot get upload URL`,
        }));
        setStatuses(s => ({ ...s, [index]: "error" }));
        return;
      }

      let isUploaded = false,
        uploadError: Error | null = null;
      await fetch(uploadUrl, { method: "PUT", body: product.file! })
        .then(() => {
          isUploaded = true;
        })
        .catch(e => {
          uploadError = e;
          isUploaded = false;
        });

      if (!isUploaded) {
        setErrors(e => ({
          ...e,
          [index]: `Cannot upload a .zip file for product: ${
            uploadError?.message ?? "No info"
          }`,
        }));
        setStatuses(s => ({ ...s, [index]: "error" }));
        return;
      }

      const uploadResult = await _3d_addProductDataMutation({
        variables: {
          input: {
            filename: product.fileName!,
            productId,
          },
        },
      });

      if (!uploadResult?.data?._3d_addProductData.product) {
        setErrors(e => ({
          ...e,
          [index]: `Cannot update a product`,
        }));
        setStatuses(s => ({ ...s, [index]: "error" }));
        return;
      }

      setStatuses(s => ({ ...s, [index]: "done" }));
    } catch (e) {
      if (e instanceof Error) {
        setErrors(e => ({
          ...e,
          [index]: e.message ?? "Error while processing",
        }));
        setStatuses(s => ({ ...s, [index]: "error" }));
      }
    } finally {
      setTimeout(() => {
        const doneCount = Object.values(lastStatuses.current).filter(
          s => !(s === "waiting" || s === "loading")
        ).length;
        if (doneCount === products.length) lastHandleComplete.current();
      });
    }
  };

  // Kick-off adding 3d models
  React.useEffect(() => {
    (async () => {
      if (products.length > 0) {
        for (let i = 0; i < products.length; i++) {
          await processProduct.current?.(supplierId, products[i], i);
        }
      } else {
        lastHandleComplete.current();
      }
    })();
  }, [supplierId, products]);

  return (
    <TableContainer component={Paper} sx={{ overflowX: "initial" }}>
      <Table size="small" stickyHeader>
        <colgroup>
          <col width="25%" />
          <col width="25%" />
          <col width="25%" />
          <col width="25%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{"File name"}</TableCell>
            <TableCell>{"Model name"}</TableCell>
            <TableCell>{"Zip file"}</TableCell>
            <TableCell>{"Status"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {product.articleNumber ?? "–"}
              </TableCell>
              <TableCell>{product.modelName ?? "–"}</TableCell>
              <TableCell>{product.fileName ?? "–"}</TableCell>
              <TableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                  <span>
                    {!statuses[index] || statuses[index] === "waiting"
                      ? "Waiting"
                      : statuses[index] === "loading"
                        ? "Processing"
                        : statuses[index] === "done"
                          ? "Complete"
                          : statuses[index] === "error"
                            ? errors[index]
                            : null}
                  </span>
                  {statuses[index] === "error" && (
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() =>
                        processProduct.current?.(supplierId, product, index)
                      }
                    >
                      <ReplayIcon />
                    </IconButton>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
