import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  MediaCardItem,
  MediaListItem,
  useFormatting,
} from "@msys/ui";
import { Box, Grid, Stack, TablePagination, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SearchTemplateTypesSortingColumn } from "../../../../clients/graphqlTypes.js";
import { SwitchCollectionViewButton } from "../../../commons/button/SwitchCollectionViewButton.js";
import { useCardsNumber } from "../../../commons/hooks/useCardsNumber.js";
import { CollectionView } from "../../../commons/hooks/useCollectionView.js";
import { Page, PageTopbarItem } from "../../../commons/layout/Page.js";
import { PageContainer } from "../../../commons/layout/PageContainer.js";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../constants.js";
import { useTemplateTypesList } from "../../../features/templateTypes/useTemplateTypesList.js";
import { useDataGridStateStore } from "../../../features/users/useDataGridStateStore.js";
import {
  TemplateTypesQueryVariables,
  TemplateTypes_TemplateTypeFragment,
  useTemplateTypesQuery,
} from "./TemplateTypes.generated.js";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list", "gallery"];

interface Props {
  submenuItems: PageTopbarItem[];
  pathToDocList: string;
}

export const TemplateTypes = ({ submenuItems, pathToDocList }: Props) => {
  const navigate = useNavigate();

  const { t } = useTranslate(["TemplateTypes", "Templates", "Global"]);
  const { getFormattedDate } = useFormatting();

  const { spacing, sizeMeasureRef, columns } = useCardsNumber();

  const {
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    activeView,
    setActiveView,
    queryBaseVariables,
    toRemoveParams,
  } = useTemplateTypesList<TemplateTypesQueryVariables>({
    activeViewStorageKey: "template-types",
    activeViewInitialDesktopValue: "table",
    activeViewInitialMobileValue: "list",
    initialFilters: {},
  });

  const client = useApolloClient();
  const query = useTemplateTypesQuery({
    client,
    variables: {
      ...queryBaseVariables,
      sorting,
      filter: filters.filter,
      searchTerm: filters.searchTerm,
    },
  });

  const templates = getDataOrNull(
    (query.data ?? query.previousData)?.searchTemplateTypes
  );
  const items = templates?.edges.map(e => e.node);
  const totalCount = templates?.totalCount ?? 0;

  const quoteTemplateColumns = React.useMemo(
    (): GridColDef<TemplateTypes_TemplateTypeFragment>[] => [
      {
        field: "title",
        headerName: t("Title", {
          ns: "Templates",
        }),
        flex: 3,
        minWidth: 150,
        renderCell: ({ row: template }) => template.title,
      },
      {
        field: "createdAt",
        headerName: t("Created", {
          ns: "Templates",
        }),
        width: 120,
        renderCell: ({ row: template }) => getFormattedDate(template.createdAt),
      },
    ],
    [t, getFormattedDate]
  );

  const stateStore = useDataGridStateStore("TemplateTypes");

  const renderItems = (items: TemplateTypes_TemplateTypeFragment[] = []) => {
    switch (activeView) {
      case "table":
        return (
          <DataGrid<TemplateTypes_TemplateTypeFragment>
            stateStore={stateStore}
            loading={query.loading}
            hideFooter={totalCount === 0}
            columns={quoteTemplateColumns}
            rows={items}
            onRowClick={({ row: template }) => {
              return navigate(`${pathToDocList}/${template.id}`);
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={newPaginationModel => {
              setPaginationModel(newPaginationModel);
            }}
            disableColumnFilter
            pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
            rowCount={totalCount}
            sortModel={sorting.map(s => ({
              field: s.column,
              sort: s.direction,
            }))}
            onSortModelChange={newModel => {
              setSorting(
                newModel.map(({ field, sort }) => ({
                  column: field as SearchTemplateTypesSortingColumn,
                  direction: sort ?? "asc",
                }))
              );
            }}
          />
        );
      case "list": {
        if (query.loading) {
          return <LoadingSpinner />;
        }
        if (!query.loading && (!items || items.length === 0))
          return (
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <Typography color="grey.500" variant="body2" textAlign="center">
                {t("There are no template types.", {
                  ns: "TemplateTypes",
                })}
              </Typography>
            </Box>
          );
        return (
          <Stack spacing={1}>
            {items.map((item, index) => {
              return (
                <MediaListItem
                  key={item.id}
                  // @ts-ignore
                  component={Link}
                  to={`${pathToDocList}/${item.id}`}
                  title={item.title}
                  description={item.description}
                />
              );
            })}
            <TablePagination
              component="div"
              count={totalCount}
              page={paginationModel.page}
              onPageChange={(event, newPage) => {
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: newPage,
                });
              }}
              rowsPerPage={paginationModel.pageSize}
              onRowsPerPageChange={event => {
                setPaginationModel({
                  pageSize: parseInt(event.target.value, 10),
                  page: 0,
                });
              }}
              labelRowsPerPage={t("Per page:", {
                ns: "Global",
              })}
            />
          </Stack>
        );
      }
      case "gallery": {
        if (query.loading) {
          return <LoadingSpinner />;
        }
        if (!query.loading && (!items || items.length === 0))
          return (
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <Typography color="grey.500" variant="body2" textAlign="center">
                {t("There are no template types.", {
                  ns: "TemplateTypes",
                })}
              </Typography>
            </Box>
          );
        return (
          <Stack spacing={1} ref={sizeMeasureRef}>
            <Grid container spacing={spacing}>
              {items.map((item, index) => {
                return (
                  <Grid key={item.id} item {...columns}>
                    <MediaCardItem
                      key={item.id}
                      // @ts-ignore
                      component={Link}
                      to={`${pathToDocList}/${item.id}`}
                      title={item.title}
                      description={item.description}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <TablePagination
              component="div"
              count={totalCount}
              page={paginationModel.page}
              onPageChange={(event, newPage) => {
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: newPage,
                });
              }}
              rowsPerPage={paginationModel.pageSize}
              onRowsPerPageChange={event => {
                setPaginationModel({
                  pageSize: parseInt(event.target.value, 10),
                  page: 0,
                });
              }}
              labelRowsPerPage={t("Per page:", {
                ns: "Global",
              })}
            />
          </Stack>
        );
      }
    }
  };

  return (
    <Page
      subtitle={t("Document Templates", {
        ns: "Templates",
      })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <ListHeader
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.searchTerm}
              onChangeSearchTerm={newValue =>
                setFilters(filters => ({
                  ...filters,
                  searchTerm: newValue,
                }))
              }
            />
          }
          mb={2}
        />
        {renderItems(items)}
      </PageContainer>
    </Page>
  );
};
