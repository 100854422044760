/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AgendaPlanSessionFragmentDoc } from '../features/sessions/AgendaBox.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstallerAgendaQueryVariables = Types.Exact<{
  rangeStart?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  rangeEnd?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  userId: Types.Scalars['ID']['input'];
}>;


export type InstallerAgendaQuery = { organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultStartWorkDay?: string | null, defaultEndWorkDay?: string | null }, planSessions: { __typename: 'MissingCapabilities' } | { __typename: 'PlanSessionConnection', edges: Array<{ __typename: 'PlanSessionEdge', node: { __typename: 'PlanSession', id: string, from: any, till: any, isTentative: boolean, project: { __typename: 'Project', id: string, title: string, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } | null }, who: { __typename: 'User', id: string } } }> } };


export const InstallerAgendaDocument = gql`
    query InstallerAgenda($rangeStart: DateTime, $rangeEnd: DateTime, $userId: ID!) {
  organisationDefaults {
    ... on OrganisationDefaults {
      id
      defaultStartWorkDay
      defaultEndWorkDay
    }
  }
  planSessions(
    limit: 0
    filters: {rangeStart: $rangeStart, rangeEnd: $rangeEnd, whoIds: [$userId]}
  ) {
    ... on PlanSessionConnection {
      edges {
        node {
          ...AgendaPlanSession
        }
      }
    }
  }
}
    ${AgendaPlanSessionFragmentDoc}`;

/**
 * __useInstallerAgendaQuery__
 *
 * To run a query within a React component, call `useInstallerAgendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallerAgendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallerAgendaQuery({
 *   variables: {
 *      rangeStart: // value for 'rangeStart'
 *      rangeEnd: // value for 'rangeEnd'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInstallerAgendaQuery(baseOptions: Apollo.QueryHookOptions<InstallerAgendaQuery, InstallerAgendaQueryVariables> & ({ variables: InstallerAgendaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstallerAgendaQuery, InstallerAgendaQueryVariables>(InstallerAgendaDocument, options);
      }
export function useInstallerAgendaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstallerAgendaQuery, InstallerAgendaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstallerAgendaQuery, InstallerAgendaQueryVariables>(InstallerAgendaDocument, options);
        }
export function useInstallerAgendaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InstallerAgendaQuery, InstallerAgendaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InstallerAgendaQuery, InstallerAgendaQueryVariables>(InstallerAgendaDocument, options);
        }
export type InstallerAgendaQueryHookResult = ReturnType<typeof useInstallerAgendaQuery>;
export type InstallerAgendaLazyQueryHookResult = ReturnType<typeof useInstallerAgendaLazyQuery>;
export type InstallerAgendaSuspenseQueryHookResult = ReturnType<typeof useInstallerAgendaSuspenseQuery>;
export type InstallerAgendaQueryResult = Apollo.QueryResult<InstallerAgendaQuery, InstallerAgendaQueryVariables>;