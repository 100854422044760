import { useApolloClient } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import moment from "moment";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability.js";
import { useUserData } from "../../../auth/useUserData.js";
import { ManualSave } from "../../../commons/form-fields/ManualSave.js";
import { Stack } from "../../../commons/layout/Stack.js";
import {
  AccountingBox__OrganisationCountersFragment,
  AccountingBox__OrganisationSettingsFragment,
  useOrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation,
  useOrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation,
} from "./OrganisationAccountingSettingsBox.generated.js";

type Entity =
  | "invoice"
  | "quote"
  | "requirement"
  | "timeReport"
  | "purchaseOrder"
  | "crmOrganisation"
  | "crmUser";

const makeNextNumber = (
  prefix: string,
  minLength: number,
  nextCounter: number
): string => {
  const now = moment();
  return (
    prefix
      .replace(/<YYYY>/gi, now.format("YYYY"))
      .replace(/<YY>/gi, now.format("YY"))
      .replace(/<MM>/gi, now.format("MM"))
      .replace(/<DD>/gi, now.format("DD")) +
    Math.max(nextCounter, 1).toString().padStart(Math.max(minLength, 1), "0")
  );
};

interface FormValues {
  invoiceCounter: number;
  invoiceNumberMinLength: number;
  invoiceNumberPrefix: string;

  timeReportCounter: number;
  timeReportNumberMinLength: number;
  timeReportNumberPrefix: string;

  purchaseOrderCounter: number;
  purchaseOrderNumberMinLength: number;
  purchaseOrderNumberPrefix: string;

  crmOrganisationCounter: number;
  crmOrganisationNumberMinLength: number;
  crmOrganisationNumberPrefix: string;

  crmUserCounter: number;
  crmUserNumberMinLength: number;
  crmUserNumberPrefix: string;

  quoteCounter: number;
  quoteNumberMinLength: number;
  quoteNumberPrefix: string;

  requirementCounter: number;
  requirementNumberMinLength: number;
  requirementNumberPrefix: string;
}

interface Props {
  organisationId: string;
  organisationSettings: AccountingBox__OrganisationSettingsFragment;
  organisationCounters: AccountingBox__OrganisationCountersFragment;
}

export const OrganisationAccountingSettingsBox = ({
  organisationId,
  organisationSettings,
  organisationCounters,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("OrganisationSettings");
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const [
    modifyOrganisationSettings,
    { loading: modifyOrganisationSettingsLoading },
  ] = useOrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation({
    client,
  });
  const [
    modifyOrganisationCounters,
    { loading: modifyOrganisationCountersLoading },
  ] = useOrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation({
    client,
  });

  const validationSchema = Yup.object().shape({
    invoiceCounter: Yup.number().required(),
    invoiceNumberMinLength: Yup.number().required(),
    invoiceNumberPrefix: Yup.string(),

    timeReportCounter: Yup.number().required(),
    timeReportNumberMinLength: Yup.number().required(),
    timeReportNumberPrefix: Yup.string(),

    purchaseOrderCounter: Yup.number().required(),
    purchaseOrderNumberMinLength: Yup.number().required(),
    purchaseOrderNumberPrefix: Yup.string(),

    crmOrganisationCounter: Yup.number().required(),
    crmOrganisationNumberMinLength: Yup.number().required(),
    crmOrganisationNumberPrefix: Yup.string(),

    crmUserCounter: Yup.number().required(),
    crmUserNumberMinLength: Yup.number().required(),
    crmUserNumberPrefix: Yup.string(),

    quoteCounter: Yup.number().required(),
    quoteNumberMinLength: Yup.number().required(),
    quoteNumberPrefix: Yup.string(),

    requirementCounter: Yup.number().required(),
    requirementNumberMinLength: Yup.number().required(),
    requirementNumberPrefix: Yup.string(),
  });

  if (organisationId !== viewer.organisation.id)
    return <div>no permission to access organisationSettings</div>;

  const initialValues: FormValues = {
    invoiceCounter: organisationCounters.invoiceCounter,
    invoiceNumberMinLength: organisationSettings.invoiceNumberMinLength,
    invoiceNumberPrefix: organisationSettings.invoiceNumberPrefix,

    timeReportCounter: organisationCounters.timeReportCounter,
    timeReportNumberMinLength: organisationSettings.timeReportNumberMinLength,
    timeReportNumberPrefix: organisationSettings.timeReportNumberPrefix,

    purchaseOrderCounter: organisationCounters.purchaseOrderCounter,
    purchaseOrderNumberMinLength:
      organisationSettings.purchaseOrderNumberMinLength,
    purchaseOrderNumberPrefix: organisationSettings.purchaseOrderNumberPrefix,

    crmOrganisationCounter: organisationCounters.crmOrganisationCounter,
    crmOrganisationNumberMinLength:
      organisationSettings.crmOrganisationNumberMinLength,
    crmOrganisationNumberPrefix:
      organisationSettings.crmOrganisationNumberPrefix,

    crmUserCounter: organisationCounters.crmUserCounter,
    crmUserNumberMinLength: organisationSettings.crmUserNumberMinLength,
    crmUserNumberPrefix: organisationSettings.crmUserNumberPrefix,

    quoteCounter: organisationCounters.quoteCounter,
    quoteNumberMinLength: organisationSettings.quoteNumberMinLength,
    quoteNumberPrefix: organisationSettings.quoteNumberPrefix,

    requirementCounter: organisationCounters.requirementCounter,
    requirementNumberMinLength: organisationSettings.requirementNumberMinLength,
    requirementNumberPrefix: organisationSettings.requirementNumberPrefix,
  };

  return (
    <CardContainer isExpandable title={t("Accounting settings")}>
      <Box p={1}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async values => {
            try {
              await modifyOrganisationSettings({
                variables: {
                  input: {
                    invoiceNumberMinLength: values.invoiceNumberMinLength,
                    invoiceNumberPrefix: values.invoiceNumberPrefix,

                    timeReportNumberMinLength: values.timeReportNumberMinLength,
                    timeReportNumberPrefix: values.timeReportNumberPrefix,

                    purchaseOrderNumberMinLength:
                      values.purchaseOrderNumberMinLength,
                    purchaseOrderNumberPrefix: values.purchaseOrderNumberPrefix,

                    crmOrganisationNumberMinLength:
                      values.crmOrganisationNumberMinLength,
                    crmOrganisationNumberPrefix:
                      values.crmOrganisationNumberPrefix,

                    crmUserNumberMinLength: values.crmUserNumberMinLength,
                    crmUserNumberPrefix: values.crmUserNumberPrefix,

                    quoteNumberMinLength: values.quoteNumberMinLength,
                    quoteNumberPrefix: values.quoteNumberPrefix,

                    requirementNumberMinLength:
                      values.requirementNumberMinLength,
                    requirementNumberPrefix: values.requirementNumberPrefix,
                  },
                },
              });
              await modifyOrganisationCounters({
                variables: {
                  input: {
                    invoiceCounter: values.invoiceCounter,
                    timeReportCounter: values.timeReportCounter,
                    purchaseOrderCounter: values.purchaseOrderCounter,
                    crmOrganisationCounter: values.crmOrganisationCounter,
                    crmUserCounter: values.crmUserCounter,
                    quoteCounter: values.quoteCounter,
                    requirementCounter: values.requirementCounter,
                  },
                },
              });
            } catch (error) {
              if (error instanceof Error)
                enqueueSnackbar(error.message, { variant: "error" });
            }
          }}
        >
          {({ dirty, handleReset, values }) => (
            <Form>
              <Stack flexDirection="column">
                <Typography color="textSecondary" variant="caption">
                  <div>
                    {"<YYYY>"} – {t("current year")} ({t("long format")})
                  </div>
                  <div>
                    {"<YY>"} – {t("current year")} ({t("short format")})
                  </div>
                  <div>
                    {"<MM>"} – {t("current month")}
                  </div>
                  <div>
                    {"<DD>"} – {t("current day")}
                  </div>
                </Typography>

                <RestrictedByCapabilityWithDebug capability="QUOTING">
                  <AccountSettingsRow
                    title={t("Quote number")}
                    name="quote"
                    values={values}
                  />
                  <Divider />
                </RestrictedByCapabilityWithDebug>

                <RestrictedByCapabilityWithDebug capability="INVOICING">
                  <AccountSettingsRow
                    title={t("Invoice number")}
                    name="invoice"
                    values={values}
                  />
                  <Divider />
                </RestrictedByCapabilityWithDebug>

                <AccountSettingsRow
                  title={t("Requirement number")}
                  name="requirement"
                  values={values}
                />
                <Divider />

                <RestrictedByCapabilityWithDebug capability="ORDERING">
                  <AccountSettingsRow
                    title={t("Purchase order number")}
                    name="purchaseOrder"
                    values={values}
                  />
                  <Divider />
                </RestrictedByCapabilityWithDebug>

                <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
                  <AccountSettingsRow
                    title={t("Time report number")}
                    name="timeReport"
                    values={values}
                  />
                  <Divider />
                </RestrictedByCapabilityWithDebug>

                <AccountSettingsRow
                  title={t("Client number")}
                  name="crmOrganisation"
                  values={values}
                />
                <Divider />
                <AccountSettingsRow
                  title={t("Contact number")}
                  name="crmUser"
                  values={values}
                />
                <ManualSave
                  onCancel={handleReset}
                  disabled={
                    modifyOrganisationSettingsLoading ||
                    modifyOrganisationCountersLoading
                  }
                  disabledCancel={!dirty}
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </CardContainer>
  );
};

const AccountSettingsRow = ({
  title,
  name,
  values,
}: {
  title: string;
  name: Entity;
  values: FormValues;
}) => {
  const { t } = useTranslate("OrganisationSettings");
  return (
    <>
      <Typography>
        {title}:{" "}
        {makeNextNumber(
          values[`${name}NumberPrefix`],
          values[`${name}NumberMinLength`],
          values[`${name}Counter`]
        )}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name={`${name}NumberPrefix`}
            label={t("Prefix")}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={TextField}
            type="number"
            name={`${name}NumberMinLength`}
            label={t("Min. length")}
            min={1}
            max={30}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={TextField}
            type="number"
            name={`${name}Counter`}
            label={t("Next counter")}
            min={1}
          />
        </Grid>
      </Grid>
    </>
  );
};
