import { useApolloClient } from "@apollo/client";
import { SelectMultiple } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { intersection } from "lodash-es";
import React from "react";
import { ProjectStateMachineStatus } from "../../../../clients/graphqlTypes.js";
import { FilterProps } from "../../../commons/filters/index.js";
import {
  FilterChipGroup,
  FilterChipGroupProps,
} from "../../../commons/filters/FilterChip.js";
import { useProjectStates } from "../useProjectStates.js";
import { useProjectPhasesQuery } from "./ProjectPhases.generated.js";

const ALLOWED_STATES: ProjectStateMachineStatus[] = [
  "contracted",
  "opportunity",
];

export function FilterProjectPhasesChips({
  allowedStates,
  ...props
}: Omit<FilterChipGroupProps<string>, "label" | "getValueLabel"> & {
  allowedStates: ProjectStateMachineStatus[];
}) {
  const { t } = useTranslate("Projects");
  const { projectStateLabels } = useProjectStates();
  const states = React.useMemo(
    (): ProjectStateMachineStatus[] =>
      intersection(allowedStates, ALLOWED_STATES),
    [allowedStates]
  );

  const client = useApolloClient();
  const query = useProjectPhasesQuery({
    client,
    variables: { allowedStates: states },
    skip: !states.length,
  });
  const projectPhases = query.data?.organisationSettings.projectPhases ?? [];

  return states.length > 0 && projectPhases.length > 0 ? (
    <FilterChipGroup
      {...props}
      getValueLabel={value => {
        const phase = projectPhases.find(p => p.id === value);
        if (phase) {
          if (states.length > 1)
            return `${phase.name} (${
              projectStateLabels[phase.applicableForState]
            })`;
          return phase.name;
        }
      }}
      // label={t("Phase")}
    />
  ) : null;
}

export const FilterProjectPhases = ({
  value,
  setValue,
  allowedStates,
}: FilterProps<string[]> & { allowedStates: ProjectStateMachineStatus[] }) => {
  const { t } = useTranslate("Projects");
  const { projectStateLabels } = useProjectStates();

  const states = React.useMemo(
    (): ProjectStateMachineStatus[] =>
      intersection(allowedStates, ALLOWED_STATES),
    [allowedStates]
  );

  const client = useApolloClient();
  const query = useProjectPhasesQuery({
    client,
    variables: { allowedStates: states },
    skip: !states.length,
  });
  const projectPhases = query.data?.organisationSettings.projectPhases ?? [];

  return states.length > 0 && projectPhases.length > 0 ? (
    <SelectMultiple
      label={t("Phases")}
      options={states
        .map(state => [
          { groupTitle: projectStateLabels[state] },
          ...projectPhases
            .filter(p => p.applicableForState === state)
            .map(p => ({ value: p.id, label: p.name })),
        ])
        .flat(1)}
      value={value}
      onChange={value => {
        setValue(value);
      }}
    />
  ) : null;
};
