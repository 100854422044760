import { gql } from "@apollo/client";
import {
  CollapseSection,
  LabeledValue,
  LabeledValueWithButton,
  ModalOpenButton,
} from "@msys/ui";
import { Functions as FunctionsIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { getEnhancedPropertyLabel } from "../../doc-items/properties.js";
import {
  Prop2RelatedItemFieldsFragment,
  Props2MappingAllFragment,
} from "../../doc-items/properties.generated.js";
import { PropertyMappingExpressionModal } from "./PropertyMappingExpressionModal.js";

export type Properties = Extract<
  Prop2RelatedItemFieldsFragment,
  { __typename: "Item" }
>["props2"];

interface Props {
  properties: Properties;
  mappings: Props2MappingAllFragment[];
  docId: string;
  itemId: string;
  isEditable: boolean;
}

export function PropertyMappingSection({
  properties,
  mappings,
  docId,
  itemId,
  isEditable,
}: Props) {
  const { t } = useTranslate(["QuoteItem"]);

  if (properties.length === 0) return null;

  return (
    <CollapseSection
      title={t("Properties", {
        ns: "QuoteItem",
      })}
      titleVariant="h4"
    >
      <Grid container spacing={1} columns={2}>
        {properties.map(property => (
          <Grid key={property.key} item xs={1}>
            <PropertyMappingField
              docId={docId}
              itemId={itemId}
              property={property}
              mapping={mappings.find(
                mapping => mapping.to.key === property.key
              )}
              isEditable={isEditable}
            />
          </Grid>
        ))}
      </Grid>
    </CollapseSection>
  );
}

function PropertyMappingField({
  property,
  mapping,
  isEditable,
  ...props
}: {
  property: Properties[number];
  mapping: Props2MappingAllFragment | undefined;
  docId: string;
  itemId: string;
  isEditable: boolean;
}) {
  if (!isEditable) {
    return (
      <LabeledValue
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {mapping?.from.expr ?? "-"}
      </LabeledValue>
    );
  }

  return (
    <ModalOpenButton
      Modal={PropertyMappingExpressionModal}
      modalProps={{
        ...props,
        projectId: null,
        fieldName: property.key,
        expression: mapping?.from.expr,
      }}
    >
      <LabeledValueWithButton
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {mapping?.from.expr ?? "-"}
      </LabeledValueWithButton>
    </ModalOpenButton>
  );
}
