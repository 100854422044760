import { DURATION_INPUT_DEFAULT_VALUE, DurationInput } from "@msys/ui";
import { FormControl, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { get } from "lodash-es";
import React, { ChangeEvent, CSSProperties, FC, useState } from "react";
import {
  durationInMinutesToTime,
  getSelectedText,
  timeToDurationInMinutes,
} from "../../utils.js";

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  style?: CSSProperties;
  value?: number | null;
  onChange?: (e: ChangeEvent<HTMLInputElement>, newValue: number) => void;
  size?: React.ComponentProps<typeof TextField>["size"];
}

export const DurationField: FC<Props> = ({
  label,
  name,
  placeholder,
  fullWidth = true,
  disabled = false,
  required = false,
  style,
  value: valueProp,
  onChange: onChangeProp,
  size,
}) => {
  const { values, setFieldValue, errors } =
    useFormikContext<Record<string, unknown>>();
  const [isFocused, setIsFocused] = useState(false);

  const currentValue = valueProp ?? get(values, name);

  const value =
    typeof currentValue === "number"
      ? durationInMinutesToTime(currentValue)
      : DURATION_INPUT_DEFAULT_VALUE;
  const onChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    if (onChangeProp) {
      onChangeProp(e, timeToDurationInMinutes(value));
    } else {
      setFieldValue(name, timeToDurationInMinutes(value));
    }
  };

  const inputValue = isFocused
    ? value
    : typeof currentValue === "number"
      ? `${durationInMinutesToTime(currentValue)} h`
      : `--:-- h`;

  const error = get(errors, name);

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      error={!!error}
      disabled={disabled}
      style={style}
    >
      <DurationInput
        value={inputValue}
        onChange={onChange}
        enabled={isFocused}
        input={
          <TextField
            type="text"
            error={!!error}
            label={`${label}${label && required ? " *" : ""}`}
            placeholder={placeholder}
            value={inputValue}
            disabled={disabled}
            size={size}
            onFocus={e => {
              const wasSelected = getSelectedText() === e.target.value;

              setIsFocused(true);

              if (wasSelected) {
                setTimeout(() => e.target.select());
              }
            }}
            onSelect={e => {
              e.preventDefault(); // prevent context menu from opening (on mobile) when selecting text
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            helperText={error ? error : undefined}
          />
        }
      />
    </FormControl>
  );
};
