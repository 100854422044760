import {
  ButtonSelectMultiple,
  FormattedPrice,
  FormattedTime,
  ModalOpenButton,
  useLocalStorageAsState,
} from "@msys/ui";
import WizardIcon from "@mui/icons-material/AutoFixHigh";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FindReplace as FindReplaceIcon } from "@mui/icons-material";
import { isEqual } from "lodash-es";
import React from "react";
import {
  Agreement,
  AskWhom,
  ContractType,
  PermissionName,
} from "../../../clients/graphqlTypes.js";
import {
  useWizardApiClient,
  useWizardQuestion,
} from "../../../clients/openapi/client.js";
import { useFeature } from "../../../common/FeatureFlags.js";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import { useAuth } from "../../auth/useAuth.js";
import { useUserData } from "../../auth/useUserData.js";
import { ConfirmModalProps } from "../../commons/modals/ConfirmProcess.js";
import { QUOTE_ITEM_TYPES } from "../../constants.js";
import { isItemVisibleToOtherSide } from "../../trees-virtual/helpers.js";
import {
  AdditionalItemInput,
  TreeViewMode,
  VirtualItem,
} from "../../trees-virtual/types.js";
import { BareTreeItem } from "../../trees/BareTreeItem.js";
import { BareTreeTableItem } from "../../trees/BareTreeTableItem.js";
import { TreeDataContainer } from "../../trees/components/TreeDataContainer.js";
import { TreeRightIcons } from "../../trees/components/TreeRightIcons.js";
import { InputComponentProps, ItemComponentProps } from "../../trees/types.js";
import { assertNever } from "../../utils.js";
import { CreateItemInput } from "../doc-items/CreateItemInput.js";
import { itemHasEstimatedCalculationBox } from "../doc-items/app-conditions/boxes.js";
import { DecisionModalButton } from "../doc-items/buttons/DecisionModalButton.js";
import { TemplatePlaceholderSelectTemplateButton } from "../doc-items/buttons/TemplatePlaceholderSelectTemplateButton.js";
import { DecisionContingencyCheckbox } from "../doc-items/fields/DecisionContingencyCheckbox.js";
import { DecisionOptionRadioOrCheckbox } from "../doc-items/fields/DecisionOptionRadioOrCheckbox.js";
import { hasAnyQuestions } from "../doc-items/helpers.js";
import { DocItemContextMenuItems } from "../doc-items/menus/DocItemContextMenuItems.js";
import {
  EstimatedCalculationEmptyRow,
  EstimatedCalculationRow,
} from "../doc-items/tables/EstimatedCalculationRow.js";
import { QuoteItemCompletionWizardModal } from "../quotes/modals/QuoteItemCompletionWizardModal.js";
import { QuoteRefinementWizardModal } from "../quotes/modals/QuoteRefinementWizardModal.js";
import {
  QuoteTreeItem_Data123Fragment,
  QuoteTreeItem_TreeClientItemFragment,
  QuoteTreeItem_TreeItemFragment,
  QuoteTreeItem_TreeTableItemFragment,
} from "./quote-trees.generated.js";
import { useProjectQuoteContext } from "../../main-routes/projects/ProjectQuoteContext.js";

export function createTreeItemInput<
  Item extends VirtualItem,
  ChildItem extends VirtualItem,
>({
  viewMode,
  quoteId,
  createItem,
  createItemByType,
  createItemFromTemplate,
  createItemsFromTemplates,
  createItemsWithProducts,
  pasteItem,
  isAllowedToPasteItem,
}: {
  viewMode: TreeViewMode;
  quoteId: string;
} & Pick<
  React.ComponentProps<typeof CreateItemInput>,
  | "createItem"
  | "createItemByType"
  | "createItemFromTemplate"
  | "createItemsFromTemplates"
  | "createItemsWithProducts"
  | "pasteItem"
  | "isAllowedToPasteItem"
>) {
  return (itemProps: InputComponentProps<Item, ChildItem>) => (
    <CreateItemInput
      viewMode={viewMode}
      doc={{ id: quoteId, docType: "QUOTE", templateIsDraft: false }}
      documentItemTypes={QUOTE_ITEM_TYPES}
      createItem={createItem}
      createItemByType={createItemByType}
      createItemFromTemplate={createItemFromTemplate}
      createItemsFromTemplates={createItemsFromTemplates}
      createItemsWithProducts={createItemsWithProducts}
      pasteItem={pasteItem}
      isAllowedToPasteItem={isAllowedToPasteItem}
      {...itemProps}
    />
  );
}

export const createTreeItem =
  <T extends QuoteTreeItem_TreeItemFragment>({
    pathToDocPage,
    projectId,
    doc,
    docAgreement,
    startConfirmProcess,
    expandedItemIds,
    setItemExpanded,
    pasteItem,
    isAllowedToPasteItem,
    viewerDecisionRole,
    onAdditionalItemInputChange,
    visibleWizardButtons,
    isNewRefinementWizardEnabled,
  }: {
    pathToDocPage: string;
    projectId: string;
    doc: {
      id: string;
      rootItemId: string;
      viewerPermissions: PermissionName[];
    };
    docAgreement: Agreement;
    startConfirmProcess: (
      props: ConfirmModalProps
    ) => Promise<boolean | undefined>;
    expandedItemIds: string[] | undefined;
    setItemExpanded: (id: string, expanded: boolean) => void;
    pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
    isAllowedToPasteItem: (parentItem: T) => boolean;
    viewerDecisionRole: AskWhom | undefined;
    onAdditionalItemInputChange: ((input: AdditionalItemInput) => void) | null;
    visibleWizardButtons: WizardButtonVisibility[];
    isNewRefinementWizardEnabled: boolean;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item, doc),
      [itemProps.item]
    );

    const IconRightButtons = React.useMemo(
      () => (
        <>
          {itemProps.item.placeholderForTemplateType ? (
            <TemplatePlaceholderSelectTemplateButton
              projectId={projectId}
              docId={doc.id}
              itemId={itemProps.item.id}
              templateTypeName={
                itemProps.item.placeholderForTemplateType.templateType.title
              }
              templateSearchFilterDefaults={
                itemProps.item.templateSearchFilterDefaults
              }
              templateSearchSortingDefaults={
                itemProps.item.templateSearchSortingDefaults
              }
              templateApplicableFor="QUOTE"
              canFinalize={true}
              setItemExpanded={setItemExpanded}
              expandedItemIds={expandedItemIds}
              type="icon"
              color="orange"
              {...(isNewRefinementWizardEnabled
                ? {
                    color: "primary",
                    variant: "outlined",
                    size: "medium",
                    Icon: FindReplaceIcon,
                  }
                : undefined)}
            />
          ) : null}
          <DecisionWizardButtons
            projectId={projectId}
            quote={doc}
            item={itemProps.item}
            viewerDecisionRole={viewerDecisionRole}
            expandedItemIds={expandedItemIds}
            visibleWizardButtons={visibleWizardButtons}
            isNewRefinementWizardEnabled={isNewRefinementWizardEnabled}
          />
          {itemProps.item.decisionIsContingentItem &&
            !itemProps.item.isItemEliminated && (
              <DecisionContingencyCheckbox
                projectId={projectId}
                docId={doc.id}
                itemId={itemProps.item.id}
                item={itemProps.item}
                disabled={itemProps.item.deletedAt !== null}
              />
            )}
          {itemProps.parentItem?.type === "decision" &&
            !itemProps.item.decisionOptionElimination &&
            !itemProps.item.isItemEliminated && (
              <DecisionOptionRadioOrCheckbox
                projectId={projectId}
                docId={doc.id}
                item={itemProps.item}
                disabled={itemProps.item.deletedAt !== null}
                expandedItemIds={expandedItemIds}
              />
            )}
        </>
      ),
      [isNewRefinementWizardEnabled, itemProps.item, itemProps.parentItem?.type]
    );

    const ContextMenu = React.useMemo(
      () => (
        <DocItemContextMenuItems
          pathToDocPage={pathToDocPage}
          docId={doc.id}
          docType="QUOTE"
          projectId={projectId}
          item={itemProps.item}
          itemParentType={itemProps.parentItem?.type ?? null}
          startConfirmProcess={startConfirmProcess}
          expandedItemIds={expandedItemIds}
          pasteItem={isAllowedToPasteItem(itemProps.item) ? pasteItem : null}
          onAdditionalItemInputChange={onAdditionalItemInputChange}
        />
      ),
      [itemProps.item, itemProps.parentItem?.type]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={doc}
          item={itemProps.item}
          isVisibleToOtherSide={isItemVisibleToOtherSide(
            itemProps.item,
            itemProps.allItems
          )}
        />
      ),
      [itemProps.item, itemProps.allItems]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        ContextMenu={ContextMenu}
        RightIcons={RightIcons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        title={
          itemProps.item.pendingChangeAttributes?.title ?? itemProps.item.title
        }
        pathForPdf={
          itemProps.item.pendingChangeAttributes?.pathForPdf ??
          itemProps.item.pathForPdf
        }
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        subcontractPath={itemProps.subcontractPath}
        collapseButton={itemProps.collapseButton}
        onClick={() => {
          setItemExpanded(itemProps.item.id, true);
        }}
        to={`${pathToDocPage}/items/${itemProps.item.id}`}
      />
    );
  };

export const createTreeClientItem =
  <T extends QuoteTreeItem_TreeClientItemFragment>({
    projectId,
    doc,
    docAgreement,
    expandedItemIds,
  }: {
    projectId: string;
    doc: {
      id: string;
      rootItemId: string;
      viewerPermissions: PermissionName[];
    };
    docAgreement: Agreement;
    expandedItemIds: string[] | undefined;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const { hasItemAnyRefinementQuestions, refetchWizardQuestions } =
      useProjectQuoteContext();

    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item, doc),
      [itemProps.item]
    );

    const IconRightButtons = React.useMemo(
      () => (
        <>
          {hasItemAnyRefinementQuestions(itemProps.item.id) && (
            <RefinementWizardButton
              projectId={projectId}
              quote={doc}
              item={itemProps.item}
              refetchAfterApplyItemAction={refetchWizardQuestions}
            />
          )}
          {itemProps.item.decisionIsContingentItem &&
            !itemProps.item.isItemEliminated && (
              <DecisionContingencyCheckbox
                projectId={projectId}
                docId={doc.id}
                itemId={itemProps.item.id}
                item={itemProps.item}
              />
            )}
          {itemProps.parentItem?.type === "decision" &&
            !itemProps.item.decisionOptionElimination &&
            !itemProps.item.isItemEliminated && (
              <DecisionOptionRadioOrCheckbox
                projectId={projectId}
                docId={doc.id}
                item={itemProps.item}
                expandedItemIds={expandedItemIds}
              />
            )}
        </>
      ),
      [
        hasItemAnyRefinementQuestions,
        itemProps.item,
        itemProps.parentItem?.type,
        refetchWizardQuestions,
      ]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={doc}
          item={itemProps.item}
          isVisibleToOtherSide={isItemVisibleToOtherSide(
            itemProps.item,
            itemProps.allItems
          )}
        />
      ),
      [itemProps.item, itemProps.allItems]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        RightIcons={RightIcons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        title={
          itemProps.item.pendingChangeAttributes?.title ?? itemProps.item.title
        }
        pathForPdf={
          itemProps.item.pendingChangeAttributes?.pathForPdf ??
          itemProps.item.pathForPdf
        }
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        subcontractPath={itemProps.subcontractPath}
        collapseButton={itemProps.collapseButton}
      />
    );
  };

export const createTreeTableItem =
  <T extends QuoteTreeItem_TreeTableItemFragment>({
    pathToDocPage,
    projectId,
    doc,
    docAgreement,
    startConfirmProcess,
    navigateToItem,
    expandedItemIds,
    setItemExpanded,
    pasteItem,
    viewerDecisionRole,
    onAdditionalItemInputChange,
    visibleWizardButtons,
    isNewRefinementWizardEnabled,
  }: {
    pathToDocPage: string;
    projectId: string;
    doc: {
      id: string;
      rootItemId: string;
      contractType: ContractType;
      viewerPermissions: PermissionName[];
    };
    docAgreement: Agreement;
    startConfirmProcess: (
      props: ConfirmModalProps
    ) => Promise<boolean | undefined>;
    navigateToItem: (id: string) => void;
    expandedItemIds: string[] | undefined;
    setItemExpanded: (id: string, expanded: boolean) => void;
    pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
    isAllowedToPasteItem: (parentItem: T) => boolean;
    viewerDecisionRole: AskWhom | undefined;
    onAdditionalItemInputChange: ((input: AdditionalItemInput) => void) | null;
    visibleWizardButtons: WizardButtonVisibility[];
    isNewRefinementWizardEnabled: boolean;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item, doc),
      [itemProps.item]
    );

    const IconRightButtons = React.useMemo(
      () => (
        <>
          <DecisionWizardButtons
            projectId={projectId}
            quote={doc}
            item={itemProps.item}
            viewerDecisionRole={viewerDecisionRole}
            expandedItemIds={expandedItemIds}
            visibleWizardButtons={visibleWizardButtons}
            isNewRefinementWizardEnabled={isNewRefinementWizardEnabled}
          />
          {itemProps.item.decisionIsContingentItem &&
            !itemProps.item.isItemEliminated && (
              <DecisionContingencyCheckbox
                projectId={projectId}
                docId={doc.id}
                itemId={itemProps.item.id}
                item={itemProps.item}
              />
            )}
          {itemProps.parentItem?.type === "decision" &&
            !itemProps.item.decisionOptionElimination &&
            !itemProps.item.isItemEliminated && (
              <DecisionOptionRadioOrCheckbox
                projectId={projectId}
                docId={doc.id}
                item={itemProps.item}
                expandedItemIds={expandedItemIds}
              />
            )}
        </>
      ),
      [itemProps.item, itemProps.parentItem]
    );

    const ContextMenu = React.useMemo(
      () => (
        <DocItemContextMenuItems
          pathToDocPage={pathToDocPage}
          docId={doc.id}
          docType="QUOTE"
          projectId={projectId}
          item={itemProps.item}
          itemParentType={itemProps.parentItem?.type ?? null}
          startConfirmProcess={startConfirmProcess}
          expandedItemIds={expandedItemIds}
          pasteItem={pasteItem}
          onAdditionalItemInputChange={onAdditionalItemInputChange}
        />
      ),
      [itemProps.item, itemProps.parentItem?.type]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={doc}
          item={itemProps.item}
          isVisibleToOtherSide={isItemVisibleToOtherSide(
            itemProps.item,
            itemProps.allItems
          )}
        />
      ),
      [itemProps.item, itemProps.allItems]
    );

    const FormRow = React.useMemo(() => {
      const hasEstimatedCalculationBox = itemHasEstimatedCalculationBox(
        itemProps.item
      );
      return hasEstimatedCalculationBox ? (
        <EstimatedCalculationRow
          itemId={itemProps.item.id}
          projectId={projectId}
          docId={doc.id}
          item={itemProps.item}
          contractType={doc.contractType}
        />
      ) : (
        <EstimatedCalculationEmptyRow />
      );
    }, [itemProps.item]);

    return (
      <BareTreeTableItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        FormRow={FormRow}
        IconRightButtons={IconRightButtons}
        ContextMenu={ContextMenu}
        RightIcons={RightIcons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        title={
          itemProps.item.pendingChangeAttributes?.title ?? itemProps.item.title
        }
        pathForPdf={
          itemProps.item.pendingChangeAttributes?.pathForPdf ??
          itemProps.item.pathForPdf
        }
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        subcontractPath={itemProps.subcontractPath}
        collapseButton={itemProps.collapseButton}
        onClick={() => {
          navigateToItem(itemProps.item.id);
          setItemExpanded(itemProps.item.id, true);
        }}
      />
    );
  };

export const getData123 = (
  item: QuoteTreeItem_Data123Fragment,
  doc: {
    viewerPermissions: PermissionName[];
  }
): [JSX.Element | null, JSX.Element | null] => {
  switch (item.type) {
    case "paid": {
      // TODO this is causing issues for deleted items; for now just show "null" as value instead of breaking
      const calculateForQuote = item.proposedCalculation;
      if (!calculateForQuote) return [null, null];

      return [
        <TreeDataContainer key={"left"}>
          <div>{calculateForQuote.quantity}x</div>
          {calculateForQuote.timePerUnit > 0 && (
            <RestrictedByDocumentPermissionWithDebug
              permission="SEE_QUOTES_CALCULATION"
              document={doc}
            >
              {
                <div>
                  <FormattedTime value={calculateForQuote.timePerUnit} />
                </div>
              }
            </RestrictedByDocumentPermissionWithDebug>
          )}
          {calculateForQuote.quantity !== 1 && (
            <RestrictedByDocumentPermissionWithDebug
              permission="READ_QUOTES"
              document={doc}
            >
              <div>
                <FormattedPrice value={calculateForQuote.pricePerUnit} />
              </div>
            </RestrictedByDocumentPermissionWithDebug>
          )}
          <RestrictedByDocumentPermissionWithDebug
            permission="READ_QUOTES"
            document={doc}
          >
            <div style={{ fontWeight: "bold" }}>
              <FormattedPrice value={calculateForQuote.priceSubTotal} />
            </div>
          </RestrictedByDocumentPermissionWithDebug>
        </TreeDataContainer>,
        null,
      ];
    }
    case "section": {
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      if (
        calculateForQuote.timePerUnit === 0 &&
        calculateForQuote.pricePerUnit === 0 &&
        calculateForQuote.quantity === 1
      ) {
        return [null, null];
      }
      return [
        null,
        <TreeDataContainer key={"right"}>
          {calculateForQuote.quantity !== 1 && (
            <>
              <div>{calculateForQuote.quantity}x</div>
              {calculateForQuote.timePerUnit !== 0 && (
                <RestrictedByDocumentPermissionWithDebug
                  permission="SEE_QUOTES_CALCULATION"
                  document={doc}
                >
                  {
                    <div>
                      <FormattedTime value={calculateForQuote.timePerUnit} />
                    </div>
                  }
                </RestrictedByDocumentPermissionWithDebug>
              )}
              {calculateForQuote.pricePerUnit !== 0 && (
                <RestrictedByDocumentPermissionWithDebug
                  permission="READ_QUOTES"
                  document={doc}
                >
                  <div>
                    <FormattedPrice value={calculateForQuote.pricePerUnit} />
                  </div>
                </RestrictedByDocumentPermissionWithDebug>
              )}
            </>
          )}
          <RestrictedByDocumentPermissionWithDebug
            permission="READ_QUOTES"
            document={doc}
          >
            {calculateForQuote.timeTotal !== 0 && (
              <div style={{ fontWeight: "bold" }}>
                <FormattedTime value={calculateForQuote.timeTotal} />
              </div>
            )}
            {calculateForQuote.priceSubTotal !== 0 && (
              <div style={{ fontWeight: "bold" }}>
                <FormattedPrice value={calculateForQuote.priceSubTotal} />
              </div>
            )}
          </RestrictedByDocumentPermissionWithDebug>
        </TreeDataContainer>,
      ];
    }
    case "decision": {
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      if (
        calculateForQuote.timePerUnit === 0 &&
        calculateForQuote.pricePerUnit === 0 &&
        calculateForQuote.quantity === 1
      ) {
        return [null, null];
      }
      return [
        null,
        <TreeDataContainer key={"right"}>
          {calculateForQuote.quantity !== 1 && (
            <>
              <div>{calculateForQuote.quantity}x</div>
              {calculateForQuote.timePerUnit !== 0 && (
                <RestrictedByDocumentPermissionWithDebug
                  permission="SEE_QUOTES_CALCULATION"
                  document={doc}
                >
                  {
                    <div>
                      <FormattedTime value={calculateForQuote.timePerUnit} />
                    </div>
                  }
                </RestrictedByDocumentPermissionWithDebug>
              )}
              {calculateForQuote.pricePerUnit !== 0 && (
                <RestrictedByDocumentPermissionWithDebug
                  permission="READ_QUOTES"
                  document={doc}
                >
                  <div>
                    <FormattedPrice value={calculateForQuote.pricePerUnit} />
                  </div>
                </RestrictedByDocumentPermissionWithDebug>
              )}
            </>
          )}
          <RestrictedByDocumentPermissionWithDebug
            permission="READ_QUOTES"
            document={doc}
          >
            {calculateForQuote.timeTotal !== 0 && (
              <div style={{ fontWeight: "bold" }}>
                <FormattedTime
                  value={calculateForQuote.timeTotal}
                  notSetText=""
                />
              </div>
            )}
            {calculateForQuote.priceSubTotal !== 0 && (
              <div style={{ fontWeight: "bold" }}>
                <FormattedPrice value={calculateForQuote.priceSubTotal} />
              </div>
            )}
          </RestrictedByDocumentPermissionWithDebug>
        </TreeDataContainer>,
      ];
    }
    case "unpaid":
    case "defect": {
      return [null, null];
    }
    default:
      throw assertNever(item.type);
  }
};

const DecisionWizardButtons = ({
  projectId,
  quote,
  item,
  viewerDecisionRole,
  expandedItemIds,
  visibleWizardButtons,
  isNewRefinementWizardEnabled,
}: {
  projectId: string;
  quote: {
    id: string;
    viewerPermissions: PermissionName[];
  };
  item: { id: string } & Parameters<typeof hasAnyQuestions>[0];
  viewerDecisionRole: AskWhom | undefined;
  expandedItemIds: string[] | undefined;
  visibleWizardButtons: WizardButtonVisibility[];
  isNewRefinementWizardEnabled: boolean;
}) => {
  const {
    hasItemAnyQuestions,
    hasItemAnyRefinementQuestions,
    refetchWizardQuestions,
  } = useProjectQuoteContext();

  const showItemQuestionsModalButton =
    visibleWizardButtons.includes("item") && hasItemAnyQuestions(item.id);
  const showRefinementWizardButton =
    visibleWizardButtons.includes("refinement") &&
    hasItemAnyRefinementQuestions(item.id);

  if (isNewRefinementWizardEnabled) {
    return (
      <>
        {showItemQuestionsModalButton ? (
          <ItemWizardButton
            projectId={projectId}
            quote={quote}
            item={item}
            refetchAfterApplyItemAction={refetchWizardQuestions}
          />
        ) : null}
        {showRefinementWizardButton ? (
          <RefinementWizardButton
            projectId={projectId}
            quote={quote}
            item={item}
            refetchAfterApplyItemAction={refetchWizardQuestions}
          />
        ) : null}
      </>
    );
  } else {
    return (
      hasAnyQuestions(item, "QUOTE", viewerDecisionRole) && (
        <DecisionModalButton
          projectId={projectId}
          docType="QUOTE"
          docId={quote.id}
          itemId={item.id}
          canFinalize={true}
          viewerDecisionRole={viewerDecisionRole}
          expandedItemIds={expandedItemIds}
          type="icon"
        />
      )
    );
  }
};

export const ItemWizardButton = ({
  projectId,
  quote,
  item,
  refetchAfterApplyItemAction,
}: {
  projectId: string;
  quote: { id: string; viewerPermissions: PermissionName[] };
  item: { id: string };
  refetchAfterApplyItemAction: () => Promise<any>;
}) => {
  return (
    <RestrictedByDocumentPermissionWithDebug
      permission="MANAGE_QUOTES"
      document={quote}
      key={"decision-wizard"}
    >
      <div
        onMouseDown={e => {
          e.stopPropagation();
        }}
        onTouchStart={e => {
          e.stopPropagation();
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ModalOpenButton
          Modal={QuoteItemCompletionWizardModal}
          modalProps={{
            projectId: projectId,
            quoteId: quote.id,
            itemId: item.id,
            refetchAfterApplyItemAction,
          }}
        >
          <Button
            color={"primary"}
            variant="outlined"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            sx={{ minWidth: "auto", padding: 5 / 8 }}
          >
            <QuestionMarkIcon
              fontSize="small"
              style={{ fontSize: "1.15rem" }}
            />
          </Button>
        </ModalOpenButton>
      </div>
    </RestrictedByDocumentPermissionWithDebug>
  );
};

export const RefinementWizardButton = ({
  projectId,
  quote,
  item,
  refetchAfterApplyItemAction,
}: {
  projectId: string;
  quote: { id: string; viewerPermissions: PermissionName[] };
  item: { id: string };
  refetchAfterApplyItemAction: () => Promise<any>;
}) => {
  return (
    <RestrictedByDocumentPermissionWithDebug
      permission="TAKE_QUOTES_DECISION"
      document={quote}
    >
      <div
        onMouseDown={e => {
          e.stopPropagation();
        }}
        onTouchStart={e => {
          e.stopPropagation();
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ModalOpenButton
          Modal={QuoteRefinementWizardModal}
          modalProps={{
            projectId: projectId,
            quoteId: quote.id,
            startingNode: {
              type: "itemId",
              itemId: item.id,
            },
            refetchAfterApplyItemAction,
          }}
        >
          <Button
            color={"primary"}
            variant="outlined"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            sx={{ minWidth: "auto", padding: 5 / 8 }}
          >
            <WizardIcon fontSize="small" style={{ fontSize: "1.15rem" }} />
          </Button>
        </ModalOpenButton>
      </div>
    </RestrictedByDocumentPermissionWithDebug>
  );
};

type WizardButtonVisibility = "refinement" | "item";

export function useWizardButtonVisibility() {
  const viewer = useUserData().currentUser!;
  const isNewRefinementWizardEnabled =
    viewer.organisation.capabilities.includes("NEW_REFINEMENT_WIZARD");
  const { t } = useTranslate(["QuoteEdit"]);

  const [visibleButtons, setVisibleButtons] = useLocalStorageAsState<
    WizardButtonVisibility[]
  >("quote-table-wizard-button-visibility", ["refinement"]);

  const wizardVisibilityOptions: Array<{
    value: WizardButtonVisibility;
    label: string;
  }> = [
    { value: "refinement", label: t("Refinement", { ns: "QuoteEdit" }) },
    { value: "item", label: t("Item", { ns: "QuoteEdit" }) },
  ];

  const filterButton = isNewRefinementWizardEnabled ? (
    <ButtonSelectMultiple
      label={t("Wizard", { ns: "QuoteEdit" })}
      options={wizardVisibilityOptions}
      value={visibleButtons}
      searchLabel=""
      noOptionsLabel=""
      onChange={value => {
        setVisibleButtons(value);
      }}
    />
  ) : null;

  return { filterButton, visibleButtons };
}
