import {
  Autocomplete,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { Category, useCategories } from "./useCategories.js";

interface Props {
  label: string;
  value: string[];
  onChange: (value: string[]) => void;
  required?: boolean;
  disabled?: boolean;
  touched?: boolean;
  error?: string;
}

export function CategoriesInput({
  label,
  value,
  onChange,
  required,
  disabled,
  touched,
  error,
}: Props) {
  const categories = useCategories();

  return (
    <Autocomplete<Category, true, true, false>
      options={categories.allCategories}
      multiple
      onChange={(_, categories) =>
        onChange(categories.map(category => category.value))
      }
      getOptionLabel={(option: Category) => option.name}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemIcon>{option.icon}</ListItemIcon>
          <ListItemText>{option.name}</ListItemText>
        </ListItem>
      )}
      value={categories.allCategories.filter(c => value.includes(c.value))}
      fullWidth
      disableCloseOnSelect
      openOnFocus
      disabled={disabled}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            icon={option.icon}
            variant="outlined"
            // size="small"
            color="primary"
            label={option.name}
            {...getTagProps({ index })}
            key={option.value}
          />
        ))
      }
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={label}
            disabled={disabled}
            error={touched && !!error}
            helperText={touched && error}
          />
        );
      }}
    />
  );
}
