import { gql } from "@apollo/client";
import { ellipsisStyle, useFormatting } from "@msys/ui";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { CreatedDateLine, MasterLine } from "../../commons/DataItem.js";
import { TodoListItemFragment } from "./TodoListItem.generated.js";

interface Props {
  task: TodoListItemFragment;
  onToggle(task: { id: string; isDone: boolean }): Promise<void>;
}

export const TodoListItem = ({ task, onToggle }: Props) => {
  const { getFormattedDateTime } = useFormatting();
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Checkbox
        checked={task.isDone}
        onChange={async () => {
          await onToggle(task);
        }}
        onClick={event => event.stopPropagation()}
      />
      <Stack direction="column" spacing={0.5} minWidth={0}>
        <Typography variant="body1" component="div">
          {task.title}
        </Typography>
        <Box
          gap={0.5}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          {task.time && (
            <CreatedDateLine
              color={moment().isAfter(task.time) ? "error" : undefined}
            >
              {getFormattedDateTime(task.time)}
            </CreatedDateLine>
          )}
          {task.assignee && <MasterLine>{task.assignee?.fullname}</MasterLine>}
        </Box>
      </Stack>
    </Stack>
  );
};
