import { Box, Container } from "@mui/material";
import React, { FC } from "react";
import { CardContainer } from "@msys/ui";
import { Page } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";

export const ExampleLayoutSm1Column: FC = () => {
  return (
    <Page>
      <PageContainer>
        <Container maxWidth="sm">
          <CardContainer title="Print preview">
            <Box height={1400} />
          </CardContainer>
        </Container>
      </PageContainer>
    </Page>
  );
};
