import { useApolloClient } from "@apollo/client";
import { Box, Divider, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { omit } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { PhoneInput, Salutation } from "../../../clients/graphqlTypes.js";
import { AutoSave } from "../../commons/form-fields/AutoSave.js";
import { DetachedEmailField } from "../../commons/form-fields/EmailField.js";
import { PHONE_REG_EXP } from "../../commons/form-fields/helpers.js";
import { TagsField } from "../../commons/form-fields/TagsField.js";
import { useEmailAddressValidation } from "../email/validateEmailAddress.js";
import { useOrganisationTags } from "../organisations/useOrganisationTags.js";
import { PhonesField } from "../phones/PhonesField.js";
import { defaultPhoneType } from "../phones/usePhoneTypes.js";
import { UserTitleWithNameField } from "../users/UserTitleWithNameField.js";
import { useModifyCrmUserMutation } from "./CrmPerson.generated.js";
import { CrmPersonInformationForm_CrmPersonFragment } from "./CrmPersonInformationForm.generated.js";

interface FormValues {
  title: Salutation;
  firstName: string;
  familyName: string;
  jobTitle: string;
  email: string;
  phones: PhoneInput[];
  notices: string;
  tags: { type: "CUSTOM" | "SYSTEM"; value: string }[];
}

interface Props {
  crmPerson: CrmPersonInformationForm_CrmPersonFragment;
}

export const CrmPersonInformationForm = ({ crmPerson }: Props) => {
  const { t } = useTranslate(["UserProfile", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const isEmailAddressValid = useEmailAddressValidation();

  const client = useApolloClient();
  const [modifyCrmUser] = useModifyCrmUserMutation({ client });

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: crmPerson.title,
      firstName: crmPerson.firstname,
      familyName: crmPerson.familyname,
      email: crmPerson.email,
      jobTitle: crmPerson.jobTitle,
      phones:
        crmPerson.phones?.length > 0 ? crmPerson.phones : [defaultPhoneType],
      notices: crmPerson.notices,
      tags: crmPerson.customTags.map(tag => ({ type: "CUSTOM", value: tag })),
    }),
    [crmPerson]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .label(t("Salutation", { ns: "Global" }))
          .required(),
        firstName: Yup.string().label(t("First name", { ns: "UserProfile" })),
        familyName: Yup.string()
          .label(t("Last name", { ns: "UserProfile" }))
          .required(),
        email: Yup.string()
          .label(t("Email", { ns: "UserProfile" }))
          .email()
          .trim(),
        jobTitle: Yup.string().label(t("Job title", { ns: "UserProfile" })),
        phones: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            main: Yup.boolean(),
            type: Yup.string(),
            number: Yup.string().matches(
              PHONE_REG_EXP,
              t("Phone number is not valid", { ns: "UserProfile" })
            ),
            // .required(t("Phone number is required")),
          })
        ),
        notices: Yup.string().label(t("Notes", { ns: "UserProfile" })),
        tags: Yup.array()
          .of(Yup.string())
          .label(t("Tags", { ns: "UserProfile" })),
      }),
    [t]
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      try {
        await modifyCrmUser({
          variables: {
            users: [
              {
                id: crmPerson.id,
                title: values.title,
                firstname: values.firstName,
                familyname: values.familyName,
                email: values.email.trim(),
                jobTitle: values.jobTitle,
                phones: values.phones
                  .filter(p => p.number.trim())
                  .map(p => omit(p, "__typename", "id")),
                tags: values.tags
                  .filter(tag => tag.type === "CUSTOM")
                  .map(tag => tag.value),
                notices: values.notices,
              },
            ],
          },
        });
      } catch (error) {
        if (error instanceof Error)
          enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [crmPerson, modifyCrmUser, enqueueSnackbar]
  );

  const { customTags } = useOrganisationTags("CRM_PERSON");

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack direction="column" spacing={1}>
            <Box>
              <UserTitleWithNameField disabled={false} />
            </Box>
            <DetachedEmailField
              name="email"
              label={t("Email", {
                ns: "UserProfile",
              })}
              validate={isEmailAddressValid}
              isValid={crmPerson.isEmailValid}
            />
            <Field
              component={TextField}
              name="jobTitle"
              label={t("Job title", {
                ns: "UserProfile",
              })}
              disabled={false}
            />
            <PhonesField
              name="phones"
              buttonLabel={t("Add phone number", {
                ns: "UserProfile",
              })}
              value={formikProps.values.phones}
              disabled={false}
            />
            <Divider />
            <Field
              component={TextField}
              multiline
              name="notices"
              label={t("Notes", { ns: "UserProfile" })}
              disabled={false}
            />
            <TagsField
              inputLabel={t("Tags", { ns: "UserProfile" })}
              name="tags"
              options={customTags}
              // reservedOptions={[]}
              disabled={true}
            />
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
