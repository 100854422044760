/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated.js';
export type AgendaPlanSessionFragment = { __typename: 'PlanSession', id: string, from: any, till: any, isTentative: boolean, project: { __typename: 'Project', id: string, title: string, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } | null }, who: { __typename: 'User', id: string } };

export const AgendaPlanSessionFragmentDoc = gql`
    fragment AgendaPlanSession on PlanSession {
  id
  from
  till
  isTentative
  project {
    id
    title
    building {
      id
      buildingAddress {
        ...AddressDetails__Address
      }
    }
  }
  who {
    id
  }
}
    ${AddressDetails__AddressFragmentDoc}`;