/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type QuoteStatus_QuoteFragment = { __typename: 'Quote', id: string, isPublished: boolean, agreement: Types.Agreement, needsAgreement: boolean, isBinding: boolean };

export type QuoteStatusChip_QuoteFragment = { __typename: 'Quote', id: string, isPublished: boolean, isBinding: boolean, needsAgreement: boolean, agreement: Types.Agreement };

export const QuoteStatus_QuoteFragmentDoc = gql`
    fragment QuoteStatus_Quote on Quote {
  ... on WithPublishingInfo {
    id
    isPublished
  }
  ... on WithAgreementInfo {
    id
    agreement
    needsAgreement
  }
  ... on WithBindingInfo {
    id
    isBinding
  }
}
    `;
export const QuoteStatusChip_QuoteFragmentDoc = gql`
    fragment QuoteStatusChip_Quote on Quote {
  id
  isPublished
  isBinding
  needsAgreement
  agreement
}
    `;