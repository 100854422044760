import { useApolloClient } from "@apollo/client";
import { ModalActions, ModalContent, ModalDialog } from "@msys/ui";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";
import {
  QuoteCreateWizardSection,
  applyItemActionToApplyItemActionInput,
} from "../QuoteCreateWizardSection.js";
import { useQuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation } from "./QuoteItemCompletionWizardModal.generated";

type HandleClose = () => void;

export function QuoteItemCompletionWizardModal({
  projectId,
  quoteId,
  handleClose,
  itemId,
  refetchAfterApplyItemAction,
}: {
  projectId: string;
  quoteId: string;
  handleClose: HandleClose;
  itemId: string;
  refetchAfterApplyItemAction?: () => Promise<void>;
}) {
  const { t } = useTranslate(["Decisions", "Global"]);

  const viewer = useUserData().currentUser!;

  const client = useApolloClient();
  const [applyQuoteItemActionsMutation] =
    useQuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation({ client });

  const [isCompleted, setIsCompleted] = React.useState(false);

  return (
    <ModalDialog>
      {isCompleted ? (
        <>
          <ModalContent>
            <Stack
              spacing={1}
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
            >
              <CheckCircleIcon color="success" sx={{ fontSize: 140 }} />
              <Typography variant={"h2"} color="success.main">
                {t("Your answers have been saved!", {
                  ns: "Decisions",
                })}
              </Typography>
              <Typography textAlign={"center"}>
                {t(
                  "Start the wizard again and you will be able to review any question that hasn't been finalised yet.",
                  { ns: "Decisions" }
                )}
              </Typography>
            </Stack>
          </ModalContent>
          <ModalActions
            actionButtons={[
              {
                label: t("Close", { ns: "Global" }),
                handleClick: handleClose,
                buttonProps: { variant: "contained" },
              },
            ]}
          />
        </>
      ) : (
        <ModalContent>
          <QuoteCreateWizardSection
            wizardConfiguration={{
              showCardImage: viewer.organisation.isCraftsmanOrganisation
                ? "false"
                : "true",
              navigationType: null,
              questionStrategy: {
                type: "item",
                itemId,
              },
              requestInitialAnswers: "false",
              sourceDoc: {
                type: "quote",
                quoteId,
                contractorId: viewer.organisation.id,
              },
              showCloseButton: "true",
            }}
            initialAnswers={[]}
            onAnswer={async answers => {
              const mostRecentAnswer = answers[answers.length - 1];

              await applyQuoteItemActionsMutation({
                variables: {
                  input: {
                    applyItemActions: [
                      applyItemActionToApplyItemActionInput(
                        mostRecentAnswer.answer
                      ),
                    ],
                    projectId,
                    quoteId,
                  },
                },
              });
              await refetchAfterApplyItemAction?.();
            }}
            onComplete={async result => {
              setIsCompleted(true);
            }}
            onClose={handleClose}
          />
        </ModalContent>
      )}
    </ModalDialog>
  );
}
