import { gql } from "@apollo/client";
import { CopyAll as CopyAllIcon } from "@mui/icons-material";
import { Group as GroupIcon } from "@mui/icons-material";
import { Lock as LockIcon } from "@mui/icons-material";
import { Public as PublicIcon } from "@mui/icons-material";
import { Store as StoreIcon } from "@mui/icons-material";
import {
  Stack,
  StackProps,
  SvgIconProps,
  Theme,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import type { TFunction } from "@msys/tolgee";
import { useTranslate } from "@tolgee/react";
import { ViewerShapeFragment } from "../../../auth/UserDataProvider.generated.js";
import { useUserData } from "../../../auth/useUserData.js";
import { TemplateQuotePublishingStatus_QuoteTemplateFragment } from "./TemplateQuotePublishingStatus.generated.js";

const getColor = (
  theme: Theme,
  doc: TemplateQuotePublishingStatus_QuoteTemplateFragment,
  viewer: ViewerShapeFragment | null | undefined
) =>
  doc.owningSystemOrganisationId === viewer?.organisation?.id
    ? !doc.hasAnyPublishedVersion
      ? theme.palette.grey[500]
      : doc.sharedPublicly
        ? theme.palette.primary.main
        : doc.sharedWithOrganisations.length > 0
          ? theme.palette.primary.main
          : theme.palette.grey[500]
    : theme.palette.secondary.main;

const getIcon = (
  doc: TemplateQuotePublishingStatus_QuoteTemplateFragment,
  viewer: ViewerShapeFragment | null | undefined,
  IconProps?: SvgIconProps
) =>
  doc.owningSystemOrganisationId === viewer?.organisation?.id ? (
    !doc.hasAnyPublishedVersion ? (
      <CopyAllIcon {...IconProps} />
    ) : doc.sharedPublicly ? (
      <PublicIcon {...IconProps} />
    ) : doc.sharedWithOrganisations.length > 0 ? (
      <GroupIcon {...IconProps} />
    ) : (
      <LockIcon {...IconProps} />
    )
  ) : (
    <StoreIcon {...IconProps} />
  );

const getLabel = (
  doc: TemplateQuotePublishingStatus_QuoteTemplateFragment,
  viewer: ViewerShapeFragment | null | undefined,
  t: TFunction<"Templates" | "TemplatesSharing">
) =>
  doc.owningSystemOrganisationId === viewer?.organisation?.id
    ? !doc.hasAnyPublishedVersion
      ? t("Draft", {
          ns: "Templates",
        })
      : doc.sharedPublicly
        ? t("Public", {
            ns: "TemplatesSharing",
          })
        : doc.sharedWithOrganisations.length > 0
          ? t("Protected", {
              ns: "TemplatesSharing",
            })
          : t("Private", {
              ns: "TemplatesSharing",
            })
    : t("Store", {
        ns: "Templates",
      });

interface StatusProps extends TypographyProps {
  showOnlyPublishingStatus?: boolean;
  doc: TemplateQuotePublishingStatus_QuoteTemplateFragment;
  IconProps?: SvgIconProps;
}

export const TemplateQuotePublishingStatus = ({
  showOnlyPublishingStatus = false,
  doc,
  IconProps,
  ...rest
}: StatusProps) => {
  const { t } = useTranslate(["Templates", "TemplatesSharing"]);
  const viewer = useUserData()?.currentUser;
  const theme = useTheme();

  if (
    showOnlyPublishingStatus &&
    (doc.owningSystemOrganisationId !== viewer?.organisation?.id ||
      !doc.hasAnyPublishedVersion)
  )
    return null;

  return (
    <Typography
      component={Stack}
      direction="row"
      alignItems="center"
      spacing={0.5}
      color={getColor(theme, doc, viewer)}
      {...rest}
    >
      {getIcon(doc, viewer, IconProps)}
      <span>{getLabel(doc, viewer, t)}</span>
    </Typography>
  );
};

interface IconProps extends StackProps {
  showOnlyPublishingStatus?: boolean;
  doc: TemplateQuotePublishingStatus_QuoteTemplateFragment;
  IconProps?: SvgIconProps;
}

export const TemplateQuotePublishingIcon = ({
  showOnlyPublishingStatus = true,
  doc,
  IconProps,
  ...rest
}: IconProps) => {
  const { t } = useTranslate(["Templates", "TemplatesSharing"]);
  const viewer = useUserData()?.currentUser;
  if (
    showOnlyPublishingStatus &&
    doc.owningSystemOrganisationId !== viewer?.organisation?.id
  )
    return null;
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0}
      sx={theme => ({
        color: getColor(theme, doc, viewer),
      })}
      {...rest}
    >
      <Tooltip title={getLabel(doc, viewer, t)}>
        {getIcon(doc, viewer, IconProps)}
      </Tooltip>
    </Stack>
  );
};
