/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportFromJsonMutationVariables = Types.Exact<{
  input: Types.ImportFromJsonInput;
}>;


export type ImportFromJsonMutation = { importFromJson: { __typename: 'ImportFromJsonResult', ok: boolean } };


export const ImportFromJsonDocument = gql`
    mutation ImportFromJson($input: ImportFromJsonInput!) {
  importFromJson(input: $input) {
    ok
  }
}
    `;
export type ImportFromJsonMutationFn = Apollo.MutationFunction<ImportFromJsonMutation, ImportFromJsonMutationVariables>;

/**
 * __useImportFromJsonMutation__
 *
 * To run a mutation, you first call `useImportFromJsonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportFromJsonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importFromJsonMutation, { data, loading, error }] = useImportFromJsonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportFromJsonMutation(baseOptions?: Apollo.MutationHookOptions<ImportFromJsonMutation, ImportFromJsonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportFromJsonMutation, ImportFromJsonMutationVariables>(ImportFromJsonDocument, options);
      }
export type ImportFromJsonMutationHookResult = ReturnType<typeof useImportFromJsonMutation>;
export type ImportFromJsonMutationResult = Apollo.MutationResult<ImportFromJsonMutation>;
export type ImportFromJsonMutationOptions = Apollo.BaseMutationOptions<ImportFromJsonMutation, ImportFromJsonMutationVariables>;