import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, ListItem } from "@msys/ui";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission.js";
import { CreateOpportunityButton } from "../../../features/opportunities/CreateOpportunityButton.js";
import { OpportunityIcon } from "../../../features/opportunities/OpportunityIcon.js";
import { ProjectListItem } from "../../../features/projects/components/ProjectListItem.js";
import { useDashboardOpportunitiesQuery } from "./DashboardOpportunitesBox.generated.js";

export const DashboardOpportunitiesBox = () => {
  const { t } = useTranslate(["Opportunities", "Global"]);

  const client = useApolloClient();
  const query = useDashboardOpportunitiesQuery({
    client,
    fetchPolicy: "cache-and-network",
  });

  const opportunities =
    getDataOrNull(query.data?.opportunities)?.edges.map(e => e.node) ?? [];
  const opportunitiesTotalCount =
    getDataOrNull(query.data?.opportunities)?.totalCount ?? 0;

  return (
    <CardContainer
      isExpandable
      Icon={<OpportunityIcon />}
      itemCount={opportunitiesTotalCount}
      title={t("Current opportunities", { ns: "Opportunities" })}
      ActionButton={
        <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
          <CreateOpportunityButton
            type="icon"
            size="small"
            pageName="Dashboard"
          />
        </RestrictedByOrganisationPermissionWithDebug>
      }
    >
      {opportunities.length === 0 && (
        <Box p={1} py={3}>
          <Typography align="center" sx={{ color: "grey.500" }} variant="body2">
            {t("There are no items to display", { ns: "Global" })}
          </Typography>
        </Box>
      )}
      {opportunities.length > 0 && (
        <Stack width="100%" direction="column" spacing={0}>
          {opportunities.map(opportunity => (
            <ListItem
              key={opportunity.id}
              component={Link}
              //@ts-ignore
              to={`/projects/${opportunity.id}`}
            >
              <ProjectListItem
                project={opportunity}
                showAssignee
                showStatus={false}
              />
            </ListItem>
          ))}
        </Stack>
      )}
      {opportunities.length > 0 && (
        <Box>
          <Button
            component={Link}
            to={`/opportunities/current`}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See all", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </CardContainer>
  );
};
