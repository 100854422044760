import { gql, useApolloClient } from "@apollo/client";
import {
  DatePicker,
  Modal,
  PriceInput,
  Select,
  useScreenWidth,
} from "@msys/ui";
import { Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { isEqual, noop, omit } from "lodash-es";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { browserHasInputDate } from "../../../featureDetection.js";
import {
  Attachment,
  ProjectType,
  Skill,
} from "../../../../clients/graphqlTypes.js";
import { AddressDetails__AddressFragment } from "../../addresses/Addresses.generated.js";
import {
  RequestProjectInfoModalFragment,
  useModifyRequestProjectInfoMutation,
} from "./RequestProjectInfoModal.generated.js";
import { useTranslate } from "@tolgee/react";
import { AddressComboField } from "../../addresses/AddressComboField.js";
import { useProjectTypes } from "../../projects/useProjectTypes.js";
import { CategoriesInput } from "../../skill-categories/CategoriesInput.js";

interface FormValues {
  title: string;
  description: string;
  categories: Skill[];
  projectType: ProjectType | undefined;
  projectAddress: AddressDetails__AddressFragment | null;
  hideExactAddress: boolean;
  attachments: Attachment[];
  budgetAmount: number;
  earliestStart: Moment | null;
  deadline: Moment | null;
}

interface Props {
  projectId: string;
  address: AddressDetails__AddressFragment | undefined | null;
  request: RequestProjectInfoModalFragment;
  handleClose: () => void;
  refetchQueries?: string[];
}

export function RequestProjectInfoModal({
  address,
  request,
  projectId,
  handleClose,
  refetchQueries,
}: Props) {
  const { t } = useTranslate(["Requests", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const { projectTypeOptions } = useProjectTypes();
  const { isMaxPhone } = useScreenWidth();

  const client = useApolloClient();
  const [modifyRequest, { loading }] = useModifyRequestProjectInfoMutation({
    client,
    refetchQueries,
  });

  const initialValues = React.useMemo<FormValues>(
    () => ({
      title: request.title,
      description: request.description,
      categories: request.categories,
      projectType: request.type,
      projectAddress: request.address ?? null,
      hideExactAddress: !request.marketplaceFullAddress,
      attachments: request.attachments,
      budgetAmount: request.budget?.amount ?? 0,
      earliestStart: request.earliestStart
        ? moment(request.earliestStart)
        : null,
      deadline: request.earliestStart ? moment(request.deadline) : null,
    }),
    [request]
  );

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: noop,
    enableReinitialize: true,
  });

  return (
    <Modal
      title={t("Edit project information", {
        ns: "Requests",
      })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text", disabled: loading },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          handleClick: async () => {
            try {
              await modifyRequest({
                variables: {
                  input: {
                    projectId,
                    requestId: request.id,
                    ...formik.values,
                    projectAddress: isEqual(
                      request.address,
                      formik.values.projectAddress
                    )
                      ? undefined
                      : formik.values.projectAddress,
                    attachments: formik.values.attachments.map(attachment =>
                      omit(attachment, "id", "__typename", "__type")
                    ),
                    budgetAmount: parseFloat(`${formik.values.budgetAmount}`),
                    earliestStart:
                      formik.values.earliestStart?.format("YYYY-MM-DD") ?? null,
                    deadline:
                      formik.values.deadline?.format("YYYY-MM-DD") ?? null,
                  },
                },
              });
              handleClose();
              enqueueSnackbar(
                t("Request updated", {
                  ns: "Requests",
                })
              );
            } catch (e) {
              if (e instanceof Error)
                enqueueSnackbar(e.message, { variant: "error" });
            }
          },
          buttonProps: { loading },
        },
      ]}
    >
      <Stack direction={"column"} spacing={1}>
        <TextField
          type="text"
          name="title"
          label={t("Quote request title", {
            ns: "Requests",
          })}
          value={formik.values.title}
          onChange={formik.handleChange}
        />
        <TextField
          type="text"
          name="description"
          label={t("Project description", {
            ns: "Requests",
          })}
          value={formik.values.description}
          onChange={formik.handleChange}
          multiline
        />
        <CategoriesInput
          label={t("Project categories", {
            ns: "Requests",
          })}
          value={formik.values.categories}
          onChange={skills => formik.setFieldValue("categories", skills)}
        />
        <Stack direction={isMaxPhone ? "column" : "row"} spacing={1}>
          <Box flex={1}>
            <AddressComboField
              label={t("Project location", {
                ns: "Requests",
              })}
              address={formik.values.projectAddress}
              handleChange={address =>
                formik.setFieldValue("projectAddress", address)
              }
            />
          </Box>
        </Stack>
        <Stack direction={"column"} spacing={1}>
          <Stack direction={"row"} spacing={1}>
            <Select
              name="projectType"
              label={t("Project type", {
                ns: "Requests",
              })}
              options={projectTypeOptions}
              value={formik.values.projectType}
              onChange={(_, event) => formik.handleChange(event)}
            />
            <PriceInput
              name="budgetAmount"
              label={t("Budget", {
                ns: "Requests",
              })}
              value={formik.values.budgetAmount ?? 0}
              onChange={value => formik.setFieldValue("budgetAmount", value)}
              showAdornment
              min={0}
            />
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <DatePicker
              browserHasInputDate={browserHasInputDate}
              name="earliestStart"
              label={t("Earliest project start", {
                ns: "Requests",
              })}
              value={formik.values.earliestStart}
              onChange={(e, date) =>
                formik.setFieldValue("earliestStart", date)
              }
            />
            <DatePicker
              browserHasInputDate={browserHasInputDate}
              name="deadline"
              label={t("Work deadline", {
                ns: "Requests",
              })}
              value={formik.values.deadline}
              onChange={(e, date) => formik.setFieldValue("deadline", date)}
            />
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}
