import { NetworkStatus, useApolloClient } from "@apollo/client";
import { assertNever, getDataOrNull, getPictures } from "@msys/common";
import {
  LoadingSpinner,
  MediaListItem,
  Modal,
  ModalContent,
  ModalOpenButton,
  ModalTitle,
  ModalWithSidePanelBaseContent,
  ModalWithSidePanelDialog,
  ModalWithSidePanelSections,
  TitleWithPathForPdf,
  isImageOr3dModel,
  processAttachment,
  useFormatting,
  useLocalStorageAsState,
  useScreenWidth,
  ErrorMessage,
} from "@msys/ui";
import { Check as CheckIcon } from "@mui/icons-material";
import { KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon } from "@mui/icons-material";
import { Star as StarIcon } from "@mui/icons-material";
import { TouchApp as TouchAppIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Formik } from "formik";
import React from "react";
import {
  ApplyItemActionInput,
  AskWhen,
  AskWhom,
  DocType,
  Props2NonComputed,
} from "../../../../clients/graphqlTypes.js";
import { cssValue } from "../../../../common/MuiThemeProvider.js";
import { ButtonCircleWrapper } from "../../../commons/button/ButtonCircleWrapper.js";
import {
  NextButton,
  PreviousAndNextButtons,
  PreviousButton,
} from "../../../commons/button/PreviousAndNextButtons.js";
import { SwitchCollectionViewButton } from "../../../commons/button/SwitchCollectionViewButton.js";
import { CollectionView } from "../../../commons/hooks/useCollectionView.js";
import { PictureGallery } from "../../../commons/images/PictureGallery.js";
import { ArticleTeaserItem } from "../../contents/ArticleTeaserItem.js";
import { ContentsModal } from "../../contents/ContentsModal.js";
import {
  ProductCardItem,
  ProductListItem,
} from "../../products/components/ProductItem.js";
import { definitionsToFormValues } from "../../products/helper.js";
import { DecisionOptionGalleryItem } from "../DecisionOptionGalleryItem.js";
import { DecisionOptionListItem } from "../DecisionOptionListItem.js";
import {
  DecisionOptionsFilterMenu,
  DecisionOptionsType,
} from "../DecisionOptionsTypeTabs.js";
import { WizardPropertyField } from "../WizardPropertyField.js";
import { DecisionContingencySwitch } from "../fields/DecisionContingencySwitch.js";
import {
  getDefaultDecisionQuestion,
  isItemProp2AdmittedToDecisionWizard,
  shouldAskForContingency,
  shouldAskForDecision,
  shouldAskForProductSelection,
  shouldAskForProperties,
  shouldAskQuestions,
} from "../helpers.js";
import { DecisionMutations_ItemFragment } from "../hooks/useDecisionItemMutations.generated.js";
import { isNonComputedProp } from "../properties.js";
import { Props2NonComputedAllFragment } from "../properties.generated.js";
import {
  DecisionModalDecisionStage_ItemFragment,
  DecisionModalDecisionStage_OptionItemFragment,
  DecisionModalProductStage_ItemFragment,
  DecisionModalProductStage_ProductSearchResultFragment,
  DecisionModalPropertiesStage_ItemFragment,
  DecisionModal_DecisionItemFragment,
  DecisionModal_GetState_ItemFragment,
  DecisionModal_OptionItemFragment,
  useDecisionModalProductStageQuery,
  useDecisionModalQuery,
} from "./DecisionModal.generated.js";
import {
  DecisionOptionModal,
  DecisionOptionModalDialogContent,
} from "./DecisionOptionModal.js";
import { noop } from "lodash-es";

const ALLOWED_VIEWS: CollectionView[] = ["list", "gallery"];

const notIncludedStyle: React.CSSProperties = {
  opacity: 0.5,
  pointerEvents: "none",
  cursor: "not-allowed",
};

interface FormValues {
  properties: Props2NonComputedAllFragment[];
}

export type DecisionActions<T extends DecisionMutations_ItemFragment> = {
  canFinalizeContingencyDecision: (item: T) => boolean;
  handleContingencyPreselectionChange: (
    itemId: string,
    preselection: boolean
  ) => Promise<void>;
  handleFinalizeContingencyDecision: (itemId: string) => Promise<void>;
  finalizeContingencyDecisionLoading: boolean;

  isSubitemFinalizeDisabled: (item: T) => boolean;
  canFinalizeSubitemDecision: (item: T) => boolean;
  canForceFinalizeSubitemDecision: (item: T) => boolean;
  handleDecisionPreselectionChange: (
    itemId: string,
    subItemIds: string[]
  ) => Promise<void>;
  handleFinalizeSubitemDecision: (itemId: string) => Promise<void>;
  finalizeSubitemDecisionLoading: boolean;

  handleEliminateOption: (itemId: string) => Promise<void>;
  handleResetEliminateOption: (itemId: string) => Promise<void>;

  // ------
  setPropertyValues: (
    itemId: string,
    properties: Props2NonComputed[]
  ) => Promise<void>;
  enterPropertiesLoading: boolean;
  // ------
  setItemProduct: (
    itemId: string,
    productId: {
      articleNumber: string;
      supplierId: string;
    }
  ) => Promise<void>;
  setItemProductLoading: boolean;
  finalizeProductSelect: (itemId: string) => Promise<void>;
  finalizeProductSelectLoading: boolean;

  isItemGoingToBeRemoved: (item: T) => boolean;
  loading: boolean;
};

interface Props {
  projectId: string | null;
  docType: DocType;
  docId: string;
  itemId: string;
  viewerDecisionRole?: AskWhom;
  decisionContext?: AskWhen;
  itemUuidSeed?: string;
  embeddedMode?: boolean;
  applyItemActions?: Array<ApplyItemActionInput>;
  canFinalize: boolean;
  decisionActions: DecisionActions<DecisionModal_DecisionItemFragment>;
  expandedItemIds: string[] | undefined;
  handleNextItem?: () => Promise<void>;
  handlePreviousItem?: () => Promise<void>;
  handleClose: () => void;
  refetch?: () => Promise<unknown>;
  isOptionHidden?: (item: DecisionModal_OptionItemFragment) => boolean;
  dialogProps?: React.ComponentProps<
    typeof ModalWithSidePanelDialog
  >["dialogProps"];
  contractorId?: string;
  info?: React.ReactNode;
  allowCloseModal?: boolean;
  title?: string;
  fetching?: boolean;
}

export const DecisionModal = ({
  projectId,
  docType,
  docId,
  itemId,
  viewerDecisionRole,
  decisionContext,
  itemUuidSeed = "",
  embeddedMode = false,
  applyItemActions,
  isOptionHidden,
  canFinalize,
  decisionActions,
  expandedItemIds,
  handleClose,
  refetch,
  handleNextItem,
  handlePreviousItem,
  dialogProps,
  contractorId,
  info,
  allowCloseModal = true,
  title: passedTitle,
  fetching = false,
}: Props) => {
  const [activeView, setActiveView] = useLocalStorageAsState<CollectionView>(
    "msys-decisionModalViewType",
    "gallery"
  );

  const client = useApolloClient();
  const query = useDecisionModalQuery({
    client,
    variables: {
      projectId,
      docId,
      itemId,
      itemUuidSeed,
      embeddedMode,
      applyItemActions,
      contractorId,
    },
  });
  const decisionItem = query.data?.wizardItem?.item;

  const {
    canFinalizeContingencyDecision,
    canForceFinalizeSubitemDecision,
    canFinalizeSubitemDecision,
    isSubitemFinalizeDisabled,
    isItemGoingToBeRemoved,
    handleContingencyPreselectionChange,
    handleDecisionPreselectionChange,
    handleEliminateOption,
    handleResetEliminateOption,
    handleFinalizeContingencyDecision,
    handleFinalizeSubitemDecision,
    loading,
    setPropertyValues,
    enterPropertiesLoading,
    setItemProduct,
    setItemProductLoading,
    finalizeProductSelect,
    finalizeProductSelectLoading,
  } = decisionActions;

  const { stage, nextStage, previousStage } = useItemStages(
    decisionItem,
    docType,
    viewerDecisionRole,
    decisionContext
  );

  const onClose = React.useCallback(() => {
    refetch?.();
    handleClose();
  }, [handleClose, refetch]);

  const handlePreviousStep =
    previousStage || handlePreviousItem
      ? async () => {
          if (previousStage) {
            previousStage();
          } else {
            await handlePreviousItem?.();
          }
        }
      : null;

  const handleNextStep =
    nextStage || handleNextItem
      ? async () => {
          if (nextStage) {
            nextStage();
          } else {
            await handleNextItem?.();
          }
        }
      : null;

  const handleFinalizeContingency = async () => {
    if (!decisionItem) throw new Error("Decision item missing!");

    const isItemBeingRemoved = isItemGoingToBeRemoved(decisionItem);

    const next = isItemBeingRemoved ? handleNextItem : handleNextStep;

    if (next) {
      await next(); // we need to call next before, because if finalize will rmeove the item, next will not work any more

      handleFinalizeContingencyDecision(decisionItem.id);
    } else {
      await handleFinalizeContingencyDecision(decisionItem.id);

      onClose();
    }
  };

  const handleFinalizeDecision = async () => {
    if (!decisionItem) throw new Error("Decision item missing!");

    const isItemBeingRemoved = isItemGoingToBeRemoved(decisionItem);

    if (handleNextStep) {
      await handleNextStep(); // we need to call next before, because if finalize will rmeove the item, next will not work any more

      if (canFinalizeContingencyDecision(decisionItem)) {
        await handleFinalizeContingencyDecision(decisionItem.id);
      }

      if (
        !isItemBeingRemoved &&
        decisionItem.type === "decision" &&
        !isSubitemFinalizeDisabled(decisionItem) &&
        canForceFinalizeSubitemDecision(decisionItem)
      ) {
        await handleFinalizeSubitemDecision(decisionItem.id);
      }
    } else {
      if (canFinalizeContingencyDecision(decisionItem)) {
        await handleFinalizeContingencyDecision(decisionItem.id);
      }

      if (
        !isItemBeingRemoved &&
        decisionItem.type === "decision" &&
        !isSubitemFinalizeDisabled(decisionItem) &&
        canForceFinalizeSubitemDecision(decisionItem)
      ) {
        await handleFinalizeSubitemDecision(decisionItem.id);
      }

      onClose();
    }
  };

  const handleFinalizeProductSelect = async () => {
    if (!decisionItem) throw new Error("Decision item missing!");

    const isItemBeingRemoved = isItemGoingToBeRemoved(decisionItem);

    if (handleNextStep) {
      await handleNextStep(); // we need to call next before, because if finalize will rmeove the item, next will not work any more

      if (canFinalizeContingencyDecision(decisionItem)) {
        await handleFinalizeContingencyDecision(decisionItem.id);
      }

      if (!isItemBeingRemoved) {
        await finalizeProductSelect(decisionItem.id);
      }
    } else {
      if (canFinalizeContingencyDecision(decisionItem)) {
        await handleFinalizeContingencyDecision(decisionItem.id);
      }

      if (!isItemBeingRemoved) {
        await finalizeProductSelect(decisionItem.id);
      }

      onClose();
    }
  };

  const title =
    passedTitle ??
    (decisionItem ? (
      <TitleWithPathForPdf
        title={decisionItem.title}
        pathForPdf={decisionItem.pathForPdf}
      />
    ) : null);

  const showContingency = decisionItem
    ? shouldAskForContingency(decisionItem, viewerDecisionRole, decisionContext)
    : false;

  return (
    <ModalWithSidePanelDialog
      fixedHeight
      dialogProps={dialogProps}
      handleClose={allowCloseModal ? onClose : undefined}
    >
      {query.networkStatus === NetworkStatus.error ? (
        <ModalWithSidePanelBaseContent>
          <ErrorMessage message={query.error?.message ?? "Network error"} />
        </ModalWithSidePanelBaseContent>
      ) : query.networkStatus !== NetworkStatus.ready || stage === undefined ? (
        <ModalWithSidePanelBaseContent>
          <LoadingSpinner />
        </ModalWithSidePanelBaseContent>
      ) : decisionItem ? (
        stage === "properties" ? (
          <PropertiesStage
            key={`properties-stage-${decisionItem.id}`}
            title={title}
            commonContent={info}
            decisionItem={decisionItem}
            viewerDecisionRole={viewerDecisionRole}
            decisionContext={decisionContext}
            handleClose={onClose}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
            loading={loading || enterPropertiesLoading || fetching}
            isSubitemFinalizeDisabled={isSubitemFinalizeDisabled(decisionItem)}
            setPropertyValues={setPropertyValues}
            canFinalize={canFinalize}
            showContingency={showContingency}
            handleContingencyPreselectionChange={
              handleContingencyPreselectionChange
            }
            handleFinalizeContingency={handleFinalizeContingency}
            canFinalizeContingencyDecision={canFinalizeContingencyDecision(
              decisionItem
            )}
            expandedItemIds={expandedItemIds}
            nextStage={nextStage}
            allowCloseModal={allowCloseModal}
          />
        ) : stage === "decision" ? (
          <DecisionStage
            key={`decision-stage-${decisionItem.id}`}
            title={title}
            commonContent={info}
            decisionItem={decisionItem}
            isOptionHidden={isOptionHidden}
            canFinalize={canFinalize}
            embeddedMode={embeddedMode}
            activeView={activeView}
            setActiveView={setActiveView}
            handleClose={onClose}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
            loading={loading || fetching}
            isSubitemFinalizeDisabled={isSubitemFinalizeDisabled(decisionItem)}
            canFinalizeContingencyDecision={canFinalizeContingencyDecision(
              decisionItem
            )}
            canFinalizeSubitemDecision={canFinalizeSubitemDecision(
              decisionItem
            )}
            handleDecisionPreselectionChange={handleDecisionPreselectionChange}
            handleEliminateOption={handleEliminateOption}
            handleResetEliminateOption={handleResetEliminateOption}
            handleFinalizeDecision={handleFinalizeDecision}
            showContingency={showContingency}
            handleContingencyPreselectionChange={
              handleContingencyPreselectionChange
            }
            expandedItemIds={expandedItemIds}
            allowCloseModal={allowCloseModal}
          />
        ) : stage === "product" ? (
          <ProductStage
            key={`product-stage-${decisionItem.id}`}
            projectId={projectId}
            docId={docId}
            itemId={itemId}
            title={title}
            commonContent={info}
            decisionItem={decisionItem}
            canFinalize={canFinalize}
            activeView={activeView}
            setActiveView={setActiveView}
            handleClose={onClose}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
            loading={
              setItemProductLoading || finalizeProductSelectLoading || fetching
            }
            setItemProduct={setItemProduct}
            finalizeProductSelect={handleFinalizeProductSelect}
            isSubitemFinalizeDisabled={isSubitemFinalizeDisabled(decisionItem)}
            showContingency={showContingency}
            handleContingencyPreselectionChange={
              handleContingencyPreselectionChange
            }
            expandedItemIds={expandedItemIds}
            allowCloseModal={allowCloseModal}
          />
        ) : (
          assertNever(stage)
        )
      ) : (
        <ModalWithSidePanelBaseContent>
          <ErrorMessage message="No item found" />
        </ModalWithSidePanelBaseContent>
      )}
    </ModalWithSidePanelDialog>
  );
};

type Stage = "properties" | "decision" | "product";

function useItemStages(
  item: DecisionModal_GetState_ItemFragment | undefined,
  docType: DocType,
  viewerDecisionRole?: AskWhom,
  decisionContext?: AskWhen
) {
  const [state, setState] = React.useState<DoublyLinkedListNode<Stage> | null>(
    null
  );

  React.useEffect(() => {
    if (!item || !shouldAskQuestions(item, docType)) {
      setState(null);
    } else {
      const askForContingency = shouldAskForContingency(
        item,
        viewerDecisionRole,
        decisionContext
      );
      const askForDecision = shouldAskForDecision(
        item,
        viewerDecisionRole,
        decisionContext
      );
      const askForProperties = shouldAskForProperties(
        item,
        viewerDecisionRole,
        decisionContext
      );
      const askForProductSelect = shouldAskForProductSelection(
        item,
        viewerDecisionRole,
        decisionContext
      );

      const availableStages = createDoublyLinkedList<Stage>();
      if (askForProperties) {
        availableStages.add("properties");
      }
      if (
        askForDecision ||
        (askForContingency && !askForProperties && !askForProductSelect)
      ) {
        availableStages.add("decision");
      }
      if (askForProductSelect) {
        availableStages.add("product");
      }

      const firstState = availableStages.getHead();
      if (!firstState) {
        // throw new Error("No states found for item"); FIXME
        console.error("No states found for item", item.id);
      }

      setState(firstState);
    }
  }, [item?.id, decisionContext, docType, viewerDecisionRole]);

  const nextStage = React.useCallback(() => {
    if (!state?.next) throw new Error("No next stage available");

    setState(state.next);
  }, [state?.next]);

  const previousStage = React.useCallback(() => {
    if (!state?.previous) throw new Error("No previous stage available");

    setState(state.previous);
  }, [state?.previous]);

  return {
    stage: state?.value,
    nextStage: state?.next ? nextStage : null,
    previousStage: state?.previous ? previousStage : null,
  };
}

type DoublyLinkedListNode<Value> = {
  previous: DoublyLinkedListNode<Value> | null;
  value: Value;
  next: DoublyLinkedListNode<Value> | null;
};
function createDoublyLinkedList<Value>() {
  const nodes: DoublyLinkedListNode<Value>[] = [];

  function add(value: Value) {
    const lastNode = nodes[nodes.length - 1] ?? null;
    const newNode = { previous: lastNode, value, next: null };
    if (lastNode) {
      lastNode.next = newNode;
    }
    nodes.push(newNode);
  }

  function getHead() {
    return nodes[0] ?? null;
  }

  return {
    getHead,
    add,
  };
}

const PropertiesStage = ({
  decisionItem,
  handleClose,
  title,
  commonContent,
  viewerDecisionRole,
  decisionContext,
  setPropertyValues,
  isSubitemFinalizeDisabled,
  loading,
  handlePreviousStep,
  handleNextStep,
  showContingency,
  handleContingencyPreselectionChange,
  canFinalize,
  handleFinalizeContingency,
  canFinalizeContingencyDecision,
  expandedItemIds,
  nextStage,
  allowCloseModal = true,
}: {
  decisionItem: DecisionModalPropertiesStage_ItemFragment;
  handleClose: () => void;
  title: React.ReactNode;
  commonContent: React.ReactNode;
  viewerDecisionRole?: AskWhom;
  decisionContext?: AskWhen;
  isSubitemFinalizeDisabled: boolean;
  setPropertyValues: (
    itemId: string,
    properties: Props2NonComputed[]
  ) => Promise<void>;
  loading: boolean;
  handlePreviousStep: (() => void) | null;
  handleNextStep: (() => void) | null;
  showContingency: boolean;
  handleContingencyPreselectionChange: React.ComponentProps<
    typeof DecisionContingencySwitch
  >["handleContingencyPreselectionChange"];
  canFinalize: boolean;
  canFinalizeContingencyDecision: boolean;
  handleFinalizeContingency: () => Promise<void>;
  expandedItemIds: string[] | undefined;
  nextStage: Function | null;
  allowCloseModal?: boolean;
}) => {
  const { t } = useTranslate(["Decisions", "Global"]);
  const { getFormattedPrice } = useFormatting();

  const saveProperties = async (values: FormValues) => {
    if (!decisionItem) throw new Error("Decision item missing!");

    await setPropertyValues(decisionItem.id, values.properties);
  };

  const initialValues: FormValues = React.useMemo(
    () => ({
      properties: (decisionItem?.props2 ?? [])
        .filter(isNonComputedProp)
        .filter(p =>
          isItemProp2AdmittedToDecisionWizard(
            p,
            viewerDecisionRole,
            decisionContext
          )
        ),
    }),
    [decisionItem?.props2, viewerDecisionRole, decisionContext]
  );

  const groups = React.useMemo(
    () =>
      initialValues.properties.reduce(
        (acc, property, index) => {
          const group = acc.find(g => g.name === property.group);
          if (group) {
            group.properties.push({ property, index });
            return acc;
          }
          return [
            ...acc,
            {
              properties: [{ property, index }],
              name: property.group,
            },
          ];
        },
        [] as {
          name: string;
          properties: {
            property: Props2NonComputedAllFragment;
            index: number;
          }[];
        }[]
      ),
    [initialValues.properties]
  );

  const [groupIndex, setGroupIndex] = React.useState<number>(0);

  const latestGroupIndexRef = React.useRef<number>(groupIndex);
  latestGroupIndexRef.current = groupIndex;

  // set group index to 0 when switching item
  React.useEffect(() => {
    if (latestGroupIndexRef.current !== 0) {
      setGroupIndex(0);
    }
  }, [decisionItem.id]);

  const groupName = groups[groupIndex]?.name || null;
  const properties = groups[groupIndex]?.properties ?? [];

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
    >
      {formikProps => (
        <ModalWithSidePanelSections
          sideContent={null}
          maxWidth={"sm"}
          maxWidthWithSideContent={"lg"}
        >
          <ModalTitle
            title={title}
            headerActions={undefined}
            handleClose={allowCloseModal ? handleClose : undefined}
          />
          <ModalContent>
            <Stack direction="column" spacing={2}>
              {commonContent}
              {decisionItem && showContingency && (
                <>
                  <DecisionContingencySwitch
                    item={decisionItem}
                    loading={loading}
                    handleContingencyPreselectionChange={
                      handleContingencyPreselectionChange
                    }
                    expandedItemIds={expandedItemIds}
                  />
                  {nextStage === null && (
                    <MediaListItem
                      key={decisionItem.id}
                      title={decisionItem.title}
                      description={decisionItem.description}
                      imageUrl={getPictures(decisionItem.attachments)[0]?.url}
                      price={
                        decisionItem.proposedCalculation?.priceTotal
                          ? getFormattedPrice(
                              decisionItem.proposedCalculation.priceTotal
                            )
                          : "–"
                      }
                    />
                  )}
                </>
              )}

              <Box
                sx={isSubitemFinalizeDisabled ? notIncludedStyle : undefined}
              >
                <Stack direction="column" spacing={2}>
                  {groupName && (
                    <Typography variant="h4" color="primary">
                      {groupName}
                    </Typography>
                  )}
                  {properties.map(({ property, index }) => (
                    <WizardPropertyField
                      key={property.key}
                      name={`properties[${index}]`}
                      property={property}
                    />
                  ))}
                </Stack>
              </Box>
            </Stack>
          </ModalContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <PreviousButton
              handleClick={
                groupIndex > 0
                  ? () => setGroupIndex(groupIndex - 1)
                  : handlePreviousStep
                    ? async () => {
                        if (formikProps.dirty) {
                          await saveProperties(formikProps.values);
                        }
                        handlePreviousStep();
                      }
                    : null
              }
              variant="text"
              disabled={loading}
              visibility={
                groupIndex > 0
                  ? undefined
                  : handlePreviousStep
                    ? undefined
                    : "hidden"
              }
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              {showContingency && canFinalize && (
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  startIcon={<CheckIcon />}
                  loading={loading}
                  disabled={loading || !canFinalizeContingencyDecision}
                  onClick={() => {
                    handleFinalizeContingency();
                  }}
                >
                  {t("Finalize", {
                    ns: "Global",
                  })}
                </LoadingButton>
              )}
            </Stack>
            {handleNextStep || groupIndex < groups.length - 1 ? (
              <NextButton
                handleClick={
                  groupIndex < groups.length - 1
                    ? () => setGroupIndex(groupIndex + 1)
                    : handleNextStep
                      ? async () => {
                          if (formikProps.dirty) {
                            await saveProperties(formikProps.values);
                          }
                          handleNextStep();
                        }
                      : null
                }
                disabled={loading}
                variant={"contained"}
              />
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={async () => {
                  if (formikProps.dirty) {
                    await saveProperties(formikProps.values);
                  }
                  handleClose();
                }}
              >
                {t("Done", { ns: "Global" })}
              </Button>
            )}
          </DialogActions>
        </ModalWithSidePanelSections>
      )}
    </Formik>
  );
};

const DecisionStage = ({
  decisionItem,
  canFinalize,
  embeddedMode,
  handleClose,
  title,
  commonContent,
  activeView,
  setActiveView,
  isOptionHidden,
  isSubitemFinalizeDisabled,
  handleDecisionPreselectionChange,
  handleEliminateOption,
  handleResetEliminateOption,
  canFinalizeContingencyDecision,
  canFinalizeSubitemDecision,
  handleFinalizeDecision,
  loading,
  handlePreviousStep,
  handleNextStep,
  showContingency,
  handleContingencyPreselectionChange,
  expandedItemIds,
  allowCloseModal = true,
}: {
  decisionItem: DecisionModalDecisionStage_ItemFragment;
  canFinalize: boolean;
  embeddedMode: boolean;
  handleClose: () => void;
  title: React.ReactNode;
  commonContent: React.ReactNode;
  activeView: CollectionView;
  setActiveView: (view: CollectionView) => void;
  isOptionHidden?: (
    item: DecisionModalDecisionStage_OptionItemFragment
  ) => boolean;
  isSubitemFinalizeDisabled: boolean;
  canFinalizeContingencyDecision: boolean;
  canFinalizeSubitemDecision: boolean;
  handleDecisionPreselectionChange: React.ComponentProps<
    typeof DecisionOptionModalDialogContent
  >["handleDecisionPreselectionChange"] &
    React.ComponentProps<
      typeof DecisionOptionListItem
    >["handleDecisionPreselectionChange"] &
    ((itemId: string, sibItemIds: string[]) => Promise<void>);
  handleEliminateOption: React.ComponentProps<
    typeof DecisionOptionModalDialogContent
  >["eliminateDecisionOption"] &
    React.ComponentProps<
      typeof DecisionOptionListItem
    >["eliminateDecisionOption"];
  handleResetEliminateOption: React.ComponentProps<
    typeof DecisionOptionModalDialogContent
  >["resetEliminateDecisionOption"] &
    React.ComponentProps<
      typeof DecisionOptionListItem
    >["resetEliminateDecisionOption"] &
    React.ComponentProps<
      typeof DecisionOptionListItem
    >["resetEliminateDecisionOption"];
  handleFinalizeDecision: () => Promise<void>;
  loading: boolean;
  handlePreviousStep: (() => void) | null;
  handleNextStep: (() => void) | null;
  showContingency: boolean;
  handleContingencyPreselectionChange: React.ComponentProps<
    typeof DecisionContingencySwitch
  >["handleContingencyPreselectionChange"];
  expandedItemIds: string[] | undefined;
  allowCloseModal?: boolean;
}) => {
  const { t } = useTranslate(["Decisions", "Global"]);
  const { isMinDesktop, isMaxPhone } = useScreenWidth();
  const { getFormattedPrice } = useFormatting();

  const optionItems = decisionItem?.children;

  const [optionsType, setOptionsType] =
    React.useState<DecisionOptionsType>("all");
  const allAvailableOptions =
    optionItems
      ?.filter(i => !i.deletedAt)
      .filter(i => isOptionHidden?.(i) !== true) ?? [];
  const allOptions = allAvailableOptions.filter(
    i => !i.decisionOptionElimination
  );
  const selectedOptions = allAvailableOptions.filter(i =>
    decisionItem?.decisionSubitemsPreselection.includes(i.id)
  );
  const eliminatedOptions = allAvailableOptions.filter(
    i => !!i.decisionOptionElimination
  );
  const options: DecisionModalDecisionStage_OptionItemFragment[] = {
    all: allOptions,
    selected: selectedOptions,
    discarded: eliminatedOptions,
  }[optionsType];

  const noItemsMessage = {
    all: t("You have no options yet", {
      ns: "Decisions",
    }),
    selected: t("You have no selected options yet", {
      ns: "Decisions",
    }),
    discarded: t("You have no discarded options yet", {
      ns: "Decisions",
    }),
  }[optionsType];

  const [selectedItemId, setSelectedItemId] = React.useState<string | null>(
    null
  );
  const selectedOptionItem =
    decisionItem && selectedItemId
      ? (options.find(i => i.id === selectedItemId) ?? null)
      : null;

  const optionContent =
    selectedItemId && selectedOptionItem ? (
      isMinDesktop ? (
        <>
          <ModalTitle
            title=""
            titleProps={{ sx: { marginTop: "40px", opacity: 1 } }}
          />
          <ModalContent>
            <DecisionOptionModalDialogContent
              decisionItem={decisionItem}
              optionItems={options}
              optionItemId={selectedItemId}
              loading={loading}
              handleDecisionPreselectionChange={
                handleDecisionPreselectionChange
              }
              eliminateDecisionOption={handleEliminateOption}
              resetEliminateDecisionOption={handleResetEliminateOption}
              hideDiscard={embeddedMode}
              showItemTitle
              containerStyle={{
                boxShadow: cssValue.selectedBorderShadow,
                borderColor: "transparent",
                paddingTop: "2px",
                paddingBottom: "2px",
              }}
            />
          </ModalContent>
          <DialogActions>
            <Stack direction="row" justifyContent={"flex-end"}>
              <IconButton
                color="primary"
                onClick={() => setSelectedItemId(null)}
              >
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
            </Stack>
          </DialogActions>
        </>
      ) : (
        <DecisionOptionModal
          decisionItem={decisionItem}
          optionItems={optionItems ?? []}
          optionItemId={selectedOptionItem.id}
          handleDecisionPreselectionChange={handleDecisionPreselectionChange}
          eliminateDecisionOption={handleEliminateOption}
          resetEliminateDecisionOption={handleResetEliminateOption}
          hideDiscard={embeddedMode}
          onSelectItem={itemId => setSelectedItemId(itemId)}
          handleClose={() => setSelectedItemId(null)}
        />
      )
    ) : null;

  const articleTeaser = decisionItem?.contents[0];

  return (
    <ModalWithSidePanelSections
      sideContent={optionContent}
      maxWidth={"sm"}
      maxWidthWithSideContent={"lg"}
    >
      <ModalTitle
        title={title}
        headerActions={
          decisionItem?.type === "decision" ? (
            <>
              <DecisionOptionsFilterMenu
                value={optionsType}
                onChange={setOptionsType}
              />
              <SwitchCollectionViewButton
                allowedViews={ALLOWED_VIEWS}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            </>
          ) : undefined
        }
        handleClose={allowCloseModal ? handleClose : undefined}
      />
      <ModalContent>
        <Stack direction="column" spacing={2}>
          {commonContent}
          {decisionItem && showContingency && (
            <DecisionContingencySwitch
              item={decisionItem}
              loading={loading}
              handleContingencyPreselectionChange={
                handleContingencyPreselectionChange
              }
              expandedItemIds={expandedItemIds}
            />
          )}

          {decisionItem && (
            <>
              {articleTeaser && (
                <Box maxWidth="400px">
                  <ModalOpenButton
                    Modal={ContentsModal}
                    modalProps={{
                      itemId: decisionItem.id,
                      content: decisionItem.contents,
                      canEdit: false,
                    }}
                  >
                    <ArticleTeaserItem
                      title={articleTeaser.title}
                      group={articleTeaser.group}
                      previewImageUrl={articleTeaser.previewImageUrl}
                    />
                  </ModalOpenButton>
                </Box>
              )}

              {decisionItem.type === "decision" ? (
                <Stack
                  direction="column"
                  sx={isSubitemFinalizeDisabled ? notIncludedStyle : undefined}
                  spacing={1}
                >
                  <Typography variant="h2" color="textSecondary">
                    {decisionItem.wizardSettings.promptDecision ||
                      getDefaultDecisionQuestion(t, decisionItem)}
                  </Typography>

                  {options.length > 0 ? (
                    activeView === "list" ? (
                      options.map(option => (
                        <DecisionOptionListItem
                          key={option.id}
                          selected={selectedItemId === option.id}
                          layout={"horizontal"}
                          item={option}
                          parentItem={decisionItem}
                          onClick={() => setSelectedItemId(option.id)}
                          onMoreClick={() => setSelectedItemId(option.id)}
                          loading={loading}
                          handleDecisionPreselectionChange={
                            handleDecisionPreselectionChange
                          }
                          eliminateDecisionOption={handleEliminateOption}
                          resetEliminateDecisionOption={
                            handleResetEliminateOption
                          }
                          hideDiscard={embeddedMode}
                        />
                      ))
                    ) : activeView === "gallery" ? (
                      <Box>
                        <Grid
                          container
                          spacing={1}
                          columns={isMaxPhone ? 2 : 3}
                        >
                          {options.map((option, index) => (
                            <Grid item xs={1} key={option.id}>
                              <DecisionOptionGalleryItem
                                item={option}
                                selected={selectedItemId === option.id}
                                behaviour={
                                  decisionItem.decisionBehaviorOfSubitems
                                }
                                togglePreselection={async (itemId, checked) => {
                                  if (
                                    decisionItem.decisionBehaviorOfSubitems ===
                                    "SELECT_ONE"
                                  ) {
                                    if (checked) {
                                      await handleDecisionPreselectionChange(
                                        decisionItem.id,
                                        [itemId]
                                      );
                                    } else {
                                      await handleDecisionPreselectionChange(
                                        decisionItem.id,
                                        []
                                      );
                                    }
                                  } else if (
                                    decisionItem.decisionBehaviorOfSubitems ===
                                    "SELECT_MANY"
                                  ) {
                                    if (checked) {
                                      await handleDecisionPreselectionChange(
                                        decisionItem.id,
                                        [
                                          ...decisionItem.decisionSubitemsPreselection,
                                          itemId,
                                        ]
                                      );
                                    } else {
                                      await handleDecisionPreselectionChange(
                                        decisionItem.id,
                                        decisionItem.decisionSubitemsPreselection.filter(
                                          id => id !== itemId
                                        )
                                      );
                                    }
                                  }
                                }}
                                onClick={() => setSelectedItemId(option.id)}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    ) : null
                  ) : (
                    <Box py={3} px={1}>
                      <Typography variant="body2" color="gray" align="center">
                        {noItemsMessage}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              ) : (
                <MediaListItem
                  key={decisionItem.id}
                  title={decisionItem.title}
                  description={decisionItem.description}
                  imageUrl={getPictures(decisionItem.attachments)[0]?.url}
                  price={
                    decisionItem.proposedCalculation?.priceTotal
                      ? getFormattedPrice(
                          decisionItem.proposedCalculation.priceTotal
                        )
                      : "–"
                  }
                />
              )}
            </>
          )}
        </Stack>
      </ModalContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
        }}
      >
        <PreviousButton
          handleClick={handlePreviousStep}
          variant="text"
          disabled={loading}
          visibility={handlePreviousStep ? undefined : "hidden"}
        />
        <Stack direction="row" alignItems="center" spacing={1}>
          {canFinalize && (
            <LoadingButton
              variant="outlined"
              color="primary"
              startIcon={<CheckIcon />}
              loading={loading}
              disabled={
                !(canFinalizeContingencyDecision || canFinalizeSubitemDecision)
              }
              onClick={handleFinalizeDecision}
            >
              {t("Finalize", {
                ns: "Global",
              })}
            </LoadingButton>
          )}
        </Stack>
        {handleNextStep ? (
          <NextButton
            handleClick={handleNextStep}
            variant={"contained"}
            disabled={loading}
          />
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleClose();
            }}
          >
            {t("Done", { ns: "Global" })}
          </Button>
        )}
      </DialogActions>
    </ModalWithSidePanelSections>
  );
};

const PAGE_SIZE = 24;

const ProductStage = ({
  projectId,
  docId,
  itemId,
  decisionItem,
  canFinalize,
  handleClose,
  title,
  commonContent,
  activeView,
  setActiveView,
  loading,
  setItemProduct,
  finalizeProductSelect,
  handlePreviousStep,
  handleNextStep,
  isSubitemFinalizeDisabled,
  showContingency,
  handleContingencyPreselectionChange,
  expandedItemIds,
  allowCloseModal = true,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  decisionItem: DecisionModalProductStage_ItemFragment;
  canFinalize: boolean;
  handleClose: () => void;
  title: React.ReactNode;
  commonContent: React.ReactNode;
  activeView: CollectionView;
  setActiveView: (view: CollectionView) => void;
  loading: boolean;
  setItemProduct: (
    itemId: string,
    productId: { articleNumber: string; supplierId: string }
  ) => Promise<void>;
  finalizeProductSelect: (itemId: string) => Promise<void>;
  handlePreviousStep: (() => void) | null;
  handleNextStep: (() => void) | null;
  isSubitemFinalizeDisabled: boolean;
  showContingency: boolean;
  handleContingencyPreselectionChange: React.ComponentProps<
    typeof DecisionContingencySwitch
  >["handleContingencyPreselectionChange"];
  expandedItemIds: string[] | undefined;
  allowCloseModal?: boolean;
}) => {
  const { t } = useTranslate(["Decisions", "Global", "ProductSearch"]);
  const { isMinTablet, isMinDesktop } = useScreenWidth();
  const { getFormattedPrice } = useFormatting();
  const theme = useTheme();

  const filters = definitionsToFormValues(
    decisionItem.productSearchFilterDefaults
  );
  const [limit, setLimit] = React.useState(PAGE_SIZE);

  const client = useApolloClient();
  const query = useDecisionModalProductStageQuery({
    client,
    variables: { projectId, docId, itemId, limit, filters },
  });
  const productOptions = React.useMemo(
    () =>
      getDataOrNull(query.data?.pimSearchProductsForDecision)?.edges.map(
        edge => edge.node
      ) ?? [],
    [query.data?.pimSearchProductsForDecision]
  );
  const totalCount = getDataOrNull(
    query.data?.pimSearchProductsForDecision
  )?.totalCount;
  const hasMoreOptions = totalCount
    ? totalCount > productOptions.length
    : false;

  const [selectedProduct, setSelectedProduct] =
    React.useState<DecisionModalProductStage_ProductSearchResultFragment | null>(
      null
    );

  React.useEffect(() => {
    const preselectedProduct = productOptions?.find(
      option =>
        decisionItem.product?.articleNumber &&
        decisionItem.product?.supplierId &&
        option.articleNumber === decisionItem.product.articleNumber &&
        option.supplierId === decisionItem.product.supplierId
    );
    setSelectedProduct(preselectedProduct ?? null);
  }, [
    decisionItem.product?.articleNumber,
    decisionItem.product?.supplierId,
    productOptions,
  ]);

  const [expandedProduct, setExpandedProduct] =
    React.useState<DecisionModalProductStage_ProductSearchResultFragment | null>(
      null
    );

  const expandedProductPictures = React.useMemo(
    () =>
      (expandedProduct?.attachments ?? [])
        .map(processAttachment)
        .filter(isImageOr3dModel),
    [expandedProduct?.attachments]
  );

  const expandedProductDetails = expandedProduct ? (
    <Paper
      sx={{
        textAlign: "left",
        px: 1,
        borderRadius: "4px",
        width: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        backgroundColor: theme => theme.palette.common.white,
        color: "inherit",
      }}
    >
      <Stack spacing={1}>
        <Typography variant="h2">{expandedProduct.texts?.title}</Typography>
        {expandedProductPictures.length > 0 && (
          <PictureGallery
            pictures={expandedProductPictures}
            layout="cols"
            showDelete={false}
            showAdd={false}
            showRotate={false}
            showUpload={false}
            height={
              isMinTablet
                ? theme.layout.galleryHeight.md
                : theme.layout.galleryHeight.sm
            }
            useSlider={false}
          />
        )}
        <Typography>
          {expandedProduct.texts?.extendedDescription ??
            expandedProduct.texts?.description}
        </Typography>
        <Stack
          direction="row"
          minWidth={0}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          position="sticky"
          bottom={0}
          py={1}
          zIndex={10}
        >
          <Box>
            <Typography variant="h2" component="div" fontWeight="bold">
              {getFormattedPrice(
                expandedProduct.pricing?.listPrice ?? undefined
              )}
            </Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            <Button
              color={"success"}
              size="medium"
              variant={
                expandedProduct === selectedProduct ? "contained" : "outlined"
              }
              disabled={loading}
              startIcon={
                isMinTablet ? (
                  <CheckIcon />
                ) : (
                  <Tooltip
                    title={
                      expandedProduct === selectedProduct
                        ? t("Unselect", {
                            ns: "Global",
                          })
                        : t("Select", {
                            ns: "Global",
                          })
                    }
                  >
                    <CheckIcon />
                  </Tooltip>
                )
              }
              onClick={() =>
                setSelectedProduct(
                  expandedProduct === selectedProduct ? null : expandedProduct
                )
              }
              sx={
                isMinTablet
                  ? undefined
                  : {
                      minWidth: "auto",
                      padding: "7px 7px",
                      borderRadius: "50%",
                      [".MuiButton-startIcon"]: {
                        marginLeft: 0,
                        marginRight: 0,
                      },
                      [".MuiSvgIcon-root"]: {
                        fontSize: "1.5rem",
                      },
                      boxShadow: theme => (theme.shadows as string[])[1],
                    }
              }
            >
              {isMinTablet
                ? expandedProduct === selectedProduct
                  ? t("Selected", {
                      ns: "Global",
                    })
                  : t("Select", {
                      ns: "Global",
                    })
                : ""}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  ) : null;

  const sideContent = expandedProduct ? (
    isMinDesktop ? (
      <>
        <ModalTitle
          title=""
          titleProps={{ sx: { marginTop: "40px", opacity: 1 } }}
        />
        <ModalContent>{expandedProductDetails}</ModalContent>
        <DialogActions>
          <Stack direction="row" justifyContent={"flex-end"}>
            <IconButton
              color="primary"
              onClick={() => setExpandedProduct(null)}
            >
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Stack>
        </DialogActions>
      </>
    ) : (
      <Modal
        handleClose={() => setExpandedProduct(null)}
        title={decisionItem.title}
        dialogActions={
          <DialogActions>
            <PreviousAndNextButtons
              onPreviousClick={
                productOptions.length <= 1
                  ? null
                  : () => {
                      const currentProductIndex =
                        productOptions.indexOf(expandedProduct);
                      if (currentProductIndex > 0) {
                        setExpandedProduct(
                          productOptions[currentProductIndex - 1]
                        );
                      } else {
                        setExpandedProduct(
                          productOptions[productOptions.length - 1]
                        );
                      }
                    }
              }
              onNextClick={
                productOptions.length <= 1
                  ? null
                  : () => {
                      const currentProductIndex =
                        productOptions.indexOf(expandedProduct);
                      if (currentProductIndex < productOptions.length - 1) {
                        setExpandedProduct(
                          productOptions[currentProductIndex + 1]
                        );
                      } else {
                        setExpandedProduct(productOptions[0]);
                      }
                    }
              }
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                onClick={() => setExpandedProduct(null)}
                variant="text"
                color="primary"
                disabled={loading}
              >
                {t("Close", {
                  ns: "Global",
                })}
              </Button>
            </Stack>
          </DialogActions>
        }
      >
        {expandedProductDetails}
      </Modal>
    )
  ) : null;

  const ActionButton = React.useCallback(
    ({
      product,
    }: {
      product: DecisionModalProductStage_ProductSearchResultFragment;
    }) => (
      <>
        {product.organisationFavourite && (
          <ButtonCircleWrapper>
            <StarIcon />
          </ButtonCircleWrapper>
        )}
        <ButtonCircleWrapper>
          <IconButton
            color={"primary"}
            size={"small"}
            onClick={async () => {
              await setSelectedProduct(product);
            }}
            title={t("Select", { ns: "ProductSearch" })}
            aria-label={t("Select", { ns: "ProductSearch" })}
          >
            <TouchAppIcon />
          </IconButton>
        </ButtonCircleWrapper>
      </>
    ),
    [setSelectedProduct, t]
  );

  return (
    <ModalWithSidePanelSections
      sideContent={sideContent}
      maxWidth={"sm"}
      maxWidthWithSideContent={"lg"}
    >
      <ModalTitle
        title={title}
        headerActions={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        handleClose={allowCloseModal ? handleClose : undefined}
      />
      <ModalContent>
        <Stack direction="column" spacing={2}>
          {commonContent}
          {decisionItem && showContingency && (
            <DecisionContingencySwitch
              item={decisionItem}
              loading={loading}
              handleContingencyPreselectionChange={
                handleContingencyPreselectionChange
              }
              expandedItemIds={expandedItemIds}
            />
          )}

          <Stack
            direction={"column"}
            spacing={1}
            sx={isSubitemFinalizeDisabled ? notIncludedStyle : undefined}
          >
            <Typography
              variant="h2"
              color="textSecondary"
              position="sticky"
              top={0}
              zIndex={1}
              sx={{ background: theme => theme.palette.background.paper }}
            >
              {decisionItem.wizardSettings.promptPaidAndProduct ||
                t("Select a product for {title}", {
                  ns: "Decisions",
                  title: decisionItem.title,
                })}
            </Typography>
            {productOptions.length > 0 ? (
              activeView === "list" ? (
                <Stack direction="column" spacing={1}>
                  {productOptions.map((product, index) => (
                    <ProductListItem
                      key={product.id}
                      product={product}
                      ActionButton={ActionButton}
                      onClick={() => {
                        setExpandedProduct(product);
                      }}
                      sx={{
                        ...(selectedProduct === product
                          ? {
                              boxShadow: cssValue.preselectedOutlineShadow,
                              borderColor: "transparent",
                            }
                          : undefined),
                      }}
                    />
                  ))}
                </Stack>
              ) : activeView === "gallery" ? (
                <Stack>
                  <Grid container columns={isMinTablet ? 3 : 2} spacing={1}>
                    {productOptions.map((product, index) => (
                      <Grid key={product.id} item xs={1}>
                        <ProductCardItem
                          product={product}
                          ActionButton={ActionButton}
                          onClick={() => {
                            setExpandedProduct(product);
                          }}
                          sx={{
                            ...(selectedProduct === product
                              ? {
                                  boxShadow: cssValue.preselectedOutlineShadow,
                                  borderColor: "transparent",
                                }
                              : undefined),
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              ) : null
            ) : query.loading ? (
              <LoadingSpinner />
            ) : (
              <Box py={3} px={1}>
                <Typography variant="body2" color="gray" align="center">
                  {t("No product options", { ns: "ProductSearch" })}
                </Typography>
              </Box>
            )}
          </Stack>

          {hasMoreOptions && (
            <Button
              color={"secondary"}
              variant={"text"}
              onClick={() => setLimit(limit => limit + PAGE_SIZE)}
            >
              {t("Show more", { ns: "Global" })}
            </Button>
          )}
        </Stack>
      </ModalContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
        }}
      >
        <PreviousButton
          handleClick={
            handlePreviousStep
              ? async () => {
                  if (selectedProduct) {
                    await setItemProduct(decisionItem.id, selectedProduct);
                  }
                  handlePreviousStep();
                }
              : null
          }
          variant="text"
          disabled={loading}
          visibility={handlePreviousStep ? undefined : "hidden"}
        />
        <Stack direction="row" alignItems="center" spacing={1}>
          {canFinalize && (
            <LoadingButton
              variant="outlined"
              color="primary"
              startIcon={<CheckIcon />}
              loading={loading}
              disabled={loading || !selectedProduct}
              onClick={async () => {
                if (!selectedProduct) throw new Error("No product selected");

                if (
                  !decisionItem.product ||
                  selectedProduct.articleNumber !==
                    decisionItem.product.articleNumber ||
                  selectedProduct.supplierId !== decisionItem.product.supplierId
                ) {
                  await setItemProduct(decisionItem.id, selectedProduct);
                }
                await finalizeProductSelect(decisionItem.id);
              }}
            >
              {t("Finalize", {
                ns: "Global",
              })}
            </LoadingButton>
          )}
        </Stack>
        {handleNextStep ? (
          <NextButton
            handleClick={async () => {
              if (selectedProduct) {
                await setItemProduct(decisionItem.id, selectedProduct);
              }
              handleNextStep();
            }}
            variant={"contained"}
            disabled={loading}
          />
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              if (selectedProduct) {
                await setItemProduct(decisionItem.id, selectedProduct);
              }
              handleClose();
            }}
          >
            {t("Done", { ns: "Global" })}
          </Button>
        )}
      </DialogActions>
    </ModalWithSidePanelSections>
  );
};
