/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestQuoteForRequirementQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  requirementId: Types.Scalars['ID']['input'];
}>;


export type RequestQuoteForRequirementQuery = { requirement: { __typename: 'MissingPermissions' } | { __typename: 'RequirementResult', requirement?: { __typename: 'Requirement', id: string, title: string } | null } };


export const RequestQuoteForRequirementDocument = gql`
    query RequestQuoteForRequirement($projectId: ID!, $requirementId: ID!) {
  requirement(projectId: $projectId, docId: $requirementId) {
    ... on RequirementResult {
      requirement {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useRequestQuoteForRequirementQuery__
 *
 * To run a query within a React component, call `useRequestQuoteForRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestQuoteForRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestQuoteForRequirementQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      requirementId: // value for 'requirementId'
 *   },
 * });
 */
export function useRequestQuoteForRequirementQuery(baseOptions: Apollo.QueryHookOptions<RequestQuoteForRequirementQuery, RequestQuoteForRequirementQueryVariables> & ({ variables: RequestQuoteForRequirementQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestQuoteForRequirementQuery, RequestQuoteForRequirementQueryVariables>(RequestQuoteForRequirementDocument, options);
      }
export function useRequestQuoteForRequirementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestQuoteForRequirementQuery, RequestQuoteForRequirementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestQuoteForRequirementQuery, RequestQuoteForRequirementQueryVariables>(RequestQuoteForRequirementDocument, options);
        }
export function useRequestQuoteForRequirementSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RequestQuoteForRequirementQuery, RequestQuoteForRequirementQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequestQuoteForRequirementQuery, RequestQuoteForRequirementQueryVariables>(RequestQuoteForRequirementDocument, options);
        }
export type RequestQuoteForRequirementQueryHookResult = ReturnType<typeof useRequestQuoteForRequirementQuery>;
export type RequestQuoteForRequirementLazyQueryHookResult = ReturnType<typeof useRequestQuoteForRequirementLazyQuery>;
export type RequestQuoteForRequirementSuspenseQueryHookResult = ReturnType<typeof useRequestQuoteForRequirementSuspenseQuery>;
export type RequestQuoteForRequirementQueryResult = Apollo.QueryResult<RequestQuoteForRequirementQuery, RequestQuoteForRequirementQueryVariables>;