import { useApolloClient } from "@apollo/client";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { CircularProgress, Icon } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useGlobalLoadingCounter } from "../../../../common/global-loading-state/useGlobalLoadingCounter.js";
import { useDocExportAsExcelMutation } from "./DocExcelExport.generated.js";

interface Props {
  docId: string;
  projectId: string;
  title?: string;
}

export const DocExportAsExcelMenuItem = React.forwardRef<HTMLLIElement, Props>(
  (props, ref) => {
    const { t } = useTranslate("QuoteItem");

    const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

    const { enqueueSnackbar } = useSnackbar();
    const docExportAsExcel = useDocExportAsExcel();

    const { increment, decrement } = useGlobalLoadingCounter();

    return (
      <MenuItemWithIcon
        ref={ref}
        icon={
          status !== "loading" ? (
            <Icon>
              <FontAwesomeIcon icon={faFileExport} />
            </Icon>
          ) : (
            <CircularProgress size={"1rem"} />
          )
        }
        onClick={async () => {
          try {
            increment();
            setStatus("loading");
            await docExportAsExcel(props.projectId, props.docId);
            setStatus("idle");
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
            Sentry.captureException(e);
            setStatus("error");
          } finally {
            decrement();
          }
        }}
        disabled={status === "loading"}
      >
        {props.title ?? `${t("Export as")} Excel`}
      </MenuItemWithIcon>
    );
  }
);

const useDocExportAsExcel = () => {
  const client = useApolloClient();
  const [docExportAsExcelMutation] = useDocExportAsExcelMutation({
    client,
  });

  return useCallback(
    async (projectId: string, docId: string) => {
      const docExportAsExcelResult = await docExportAsExcelMutation({
        variables: {
          input: { docId, projectId },
        },
      });

      if (docExportAsExcelResult) {
        download(
          docExportAsExcelResult.data?.docExportAsExcel.downloadUrl ?? ""
        );
      }
    },
    [docExportAsExcelMutation]
  );
};

function download(url: string) {
  const elem = window.document.createElement("a");
  elem.href = url;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}
