import { omit } from "lodash-es";
import React, { FC, useMemo } from "react";
import moment from "moment";
import { useTranslate } from "@tolgee/react";
import { useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";
import { ModifyProjectWorkSessionInput } from "../../../../clients/graphqlTypes.js";
import { WorkSession__EntityFragment } from "../../projects/boxes/ProjectOverviewWorkSessionsBox.generated.js";
import {
  useModifyMyProjectWorkSessionMutation,
  useModifyProjectWorkSessionMutation,
} from "../boxes/TasksTimesheetBox.generated.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { Form, Formik, FormikProps } from "formik";
import { TimePickerField } from "../../../commons/form-fields/TimePickerField.js";
import { AutoSave } from "../../../commons/form-fields/AutoSave.js";
import { processWorkSessions } from "../../sessions/helpers.js";
import * as Yup from "yup";
import {
  Project,
  RestrictedByProjectPermissionWithDebug,
} from "../../../auth/RestrictedByProjectPermission.js";

interface Props {
  loading?: boolean;
  projectId: string;
  assigneeId: string;
  date: moment.Moment;
  workSessions: WorkSession__EntityFragment[];
}

export const TasksTimesheetTimeForm = ({
  project,
  ...props
}: Props & { project: Project }) => {
  const client = useApolloClient();
  const [modifySession] = useModifyProjectWorkSessionMutation({ client });
  const [modifyMySession] = useModifyMyProjectWorkSessionMutation({ client });

  return (
    <RestrictedByProjectPermissionWithDebug
      permission="MANAGE_PROJECT"
      project={project}
      otherwise={
        <RestrictedByProjectPermissionWithDebug
          permission="EXECUTE_TASK"
          project={project}
        >
          <TasksTimesheetTimeFormInternal
            {...props}
            modifySession={values =>
              modifyMySession({
                ...values,
                variables: {
                  ...values.variables,
                  input: omit(values.variables.input, "assigneeId"),
                },
              })
            }
          />
        </RestrictedByProjectPermissionWithDebug>
      }
    >
      <TasksTimesheetTimeFormInternal
        {...props}
        modifySession={modifySession}
      />
    </RestrictedByProjectPermissionWithDebug>
  );
};

interface FormValues {
  timeStart: string;
  timeEnd: string;
}

const TIME_FORMAT = "HH:mm";

const TasksTimesheetTimeFormInternal = ({
  loading = false,
  projectId,
  assigneeId,
  date,
  workSessions,
  modifySession,
}: Props & {
  modifySession: (values: {
    variables: {
      input: ModifyProjectWorkSessionInput;
      assigneeId: string;
      date: string;
    };
  }) => Promise<unknown>;
}) => {
  const { t } = useTranslate("WorkSessions");

  const { enqueueSnackbar } = useSnackbar();

  const now = useMemo(() => moment(), []);

  const isPast = useMemo(
    () => moment(date).startOf("day") < moment(now).startOf("day"),
    [now, date]
  );

  const { activeWorkSession, hasActiveBreakSession } = processWorkSessions(
    workSessions,
    isPast
  );

  const validationSchema = Yup.object().shape({
    timeStart: Yup.string().label(t("Work start time")),
    timeEnd: Yup.string()
      .label(t("Work finish time"))
      .when("timeStart", (timeStart: string, schema: any) => {
        return schema
          .test({
            test: (timeEnd: string) => {
              return (
                !timeStart ||
                !timeEnd ||
                moment(timeStart, TIME_FORMAT).valueOf() <
                  moment(timeEnd, TIME_FORMAT).valueOf()
              );
            },
            message: t("Finish time should be after a start time"),
          })
          .test({
            test: (timeEnd: string) =>
              !isPast || !((timeStart && !timeEnd) || (!timeStart && timeEnd)),
            message: t("Start and finish time are required"),
          })
          .test({
            test: (timeEnd: string) => {
              return isPast || !(!timeStart && timeEnd);
            },
            message: t("Start time is required"),
          });
      }),
  });

  const initialValues: FormValues = {
    timeStart:
      activeWorkSession && activeWorkSession.from
        ? moment(activeWorkSession.from).format(TIME_FORMAT)
        : "",
    timeEnd:
      activeWorkSession && activeWorkSession.till
        ? moment(activeWorkSession.till).format(TIME_FORMAT)
        : "",
  };

  const modifyActive = async (
    values: Pick<ModifyProjectWorkSessionInput, "from" | "till">
  ) => {
    try {
      await modifySession({
        variables: {
          input: {
            ...values,
            isBreak: false,
            projectId,
            assigneeId,
            date: date.format("YYYY-MM-DD"),
          },
          assigneeId,
          date: date.format("YYYY-MM-DD"),
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        if (
          (isPast &&
            ((values.timeStart && values.timeEnd) ||
              (!values.timeStart && !values.timeEnd))) ||
          (!isPast &&
            ((values.timeStart && values.timeEnd) ||
              (!values.timeStart && !values.timeEnd) ||
              (values.timeStart && !values.timeEnd)))
        ) {
          await modifyActive({
            from: values.timeStart
              ? moment(date)
                  .set({
                    hours: +values.timeStart.split(":")[0],
                    minutes: +values.timeStart.split(":")[1],
                  })
                  .toISOString(true)
              : null,
            till: values.timeEnd
              ? moment(date)
                  .set({
                    hours: +values.timeEnd.split(":")[0],
                    minutes: +values.timeEnd.split(":")[1],
                  })
                  .toISOString(true)
              : null,
          });
        }
      }}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack flexDirection="column">
            <Stack>
              <TimePickerField
                name="timeStart"
                label={t("Work start time")}
                disabled={loading || hasActiveBreakSession}
              />
              <TimePickerField
                name="timeEnd"
                label={t("Work finish time")}
                disabled={loading || hasActiveBreakSession}
              />
            </Stack>
            <AutoSave enableReinitialize initialValues={initialValues} />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
