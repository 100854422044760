import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete, CardContainer } from "@msys/ui";
import { Divider } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { debounce } from "lodash-es";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { RichTextField } from "../../../commons/form-fields/RichTextField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { ProductTypeSelect } from "../ProductTypeSelect.js";
import {
  ProductEditHeaderBox__PimBrandFragment,
  ProductEditHeaderBox__PimBrandLineFragment,
  useProductEditHeaderBox__PimSearchBrandLinesQuery,
  useProductEditHeaderBox__PimSearchBrandsQuery,
} from "./ProductEditHeaderBox.generated.js";

export interface FormValues {
  texts?: {
    titleText?: string | null;
    descriptionText?: string | null;
    extendedDescriptionText?: string | null;
    dimensionText?: string | null;
    marketingText?: string | null;
  } | null;
  gtin: string | null;
  manufacturerArticleNumber: string | null;
  productType?: { id: string; label: string; key: string } | null;
  branding?: {
    brand: { id: string; title: string };
    brandLine?: {
      id: string;
      title: string;
      brand: { id: string; title: string };
    } | null;
  } | null;
}

export function useValidationSchema() {
  const { t } = useTranslate(["Global", "Product", "QuoteItem"]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      texts: Yup.object().shape({
        titleText: Yup.string()
          .trim()
          .required()
          .label(t("Product title", { ns: "QuoteItem" })),
        descriptionText: Yup.string().nullable(),
        extendedDescriptionText: Yup.string().nullable(),
        dimensionText: Yup.string().nullable(),
        marketingText: Yup.string().nullable(),
      }),
      gtin: Yup.string().nullable(),
      manufacturerArticleNumber: Yup.string().nullable(),
      productType: Yup.object().shape({ id: Yup.string() }).nullable(),
      branding: Yup.object()
        .shape({
          brand: Yup.object().shape({
            id: Yup.string(),
          }),
          brandLine: Yup.object()
            .shape({
              id: Yup.string(),
            })
            .nullable(),
        })
        .nullable(),
    });
  }, [t]);

  return validationSchema;
}

export const ProductEditHeaderBox = () => {
  const { t } = useTranslate(["ProductOverview", "Global", "Product"]);

  const formikProps = useFormikContext<FormValues>();

  const { brands, debouncedRefetch: debouncedRefetchBrands } = useBrands();

  const { brandLines, debouncedRefetch: debouncedRefetchBrandLines } =
    useBrandLines(formikProps.initialValues.branding?.brand?.id ?? null);

  return (
    <CardContainer>
      <Stack p={1} flexDirection="column">
        <Stack flexDirection="column">
          <Field
            component={TextField}
            name="texts.titleText"
            label={t("Title", {
              ns: "ProductOverview",
            })}
            disabled={false}
            required
          />
          <RichTextField
            disabled={false}
            name="texts.descriptionText"
            label={t("Description", {
              ns: "ProductOverview",
            })}
          />
          <RichTextField
            disabled={false}
            name="texts.extendedDescriptionText"
            label={t("Extended Description", {
              ns: "ProductOverview",
            })}
          />
          <RichTextField
            disabled={false}
            name="texts.dimensionText"
            label={t("Dimension Text", {
              ns: "ProductOverview",
            })}
          />
          <RichTextField
            disabled={false}
            name="texts.marketingText"
            label={t("Marketing Text", {
              ns: "ProductOverview",
            })}
          />
          <Divider />

          <Field
            component={TextField}
            name="gtin"
            label={t("Gtin", { ns: "ProductOverview" })}
            disabled={false}
          />

          <Field
            component={TextField}
            name="manufacturerArticleNumber"
            label={t("Manufacturer article number", { ns: "ProductOverview" })}
            disabled={false}
          />

          <ProductTypeSelect
            productTypeId={formikProps.values.productType?.id ?? null}
            onChange={value => formikProps.setFieldValue("productType", value)}
            required={false}
            disabled={false}
          />

          <Autocomplete
            placeholder={t("Type to search", {
              ns: "Global",
            })}
            inputLabel={t("Brand", {
              ns: "Product",
            })}
            options={brands}
            getOptionLabel={option => option.title}
            value={formikProps.values.branding?.brand}
            onChange={value => {
              formikProps.setFieldValue("branding", { brand: value });
              debouncedRefetchBrandLines("", value ? value.id : null);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(_, value, reason) => {
              if (reason === "input") {
                debouncedRefetchBrands(value);
              }
            }}
          />
          <Autocomplete
            placeholder={t("Type to search", {
              ns: "Global",
            })}
            inputLabel={t("Brand line", {
              ns: "Product",
            })}
            options={brandLines}
            getOptionLabel={option => option.title}
            value={formikProps.values.branding?.brandLine}
            onChange={value => {
              formikProps.setValues(values => ({
                ...values,
                branding: !value
                  ? !values.branding
                    ? null
                    : { ...values.branding, brandLine: null }
                  : value.brand.id === values.branding?.brand.id
                    ? { ...values.branding, brandLine: value }
                    : { brand: value.brand, brandLine: value },
              }));

              if (value) {
                debouncedRefetchBrandLines("", value.brand.id);
              }
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(_, value, reason) => {
              if (reason === "input") {
                debouncedRefetchBrandLines(
                  value,
                  formikProps.values.branding?.brand.id ?? null
                );
              }
            }}
          />
        </Stack>
      </Stack>
    </CardContainer>
  );
};

function useBrands() {
  const [isFetching, setIsFetching] = React.useState(false);
  const [brands, setBrands] = React.useState<
    ProductEditHeaderBox__PimBrandFragment[]
  >([]);

  const client = useApolloClient();
  const query = useProductEditHeaderBox__PimSearchBrandsQuery({
    client,
    variables: { limit: 50 },
    fetchPolicy: "cache-and-network",
    skip: true,
  });

  const refetch = React.useCallback(
    async (value: string) => {
      setIsFetching(true);
      const result = await query.refetch({
        searchTerm: value,
      });
      setBrands(getDataOrNull(result.data?.pimSearchBrands)?.brands ?? []);
      setIsFetching(false);
    },
    [query]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    refetch("");
  }, [refetch]);

  return { brands, refetch, debouncedRefetch, isFetching };
}

function useBrandLines(initialBrandId: string | null) {
  const [isFetching, setIsFetching] = React.useState(false);
  const [brandLines, setBrandLines] = React.useState<
    ProductEditHeaderBox__PimBrandLineFragment[]
  >([]);

  const client = useApolloClient();
  const query = useProductEditHeaderBox__PimSearchBrandLinesQuery({
    client,
    variables: { limit: 50 },
    fetchPolicy: "cache-and-network",
    skip: true,
  });

  const refetch = React.useCallback(
    async (value: string, brandId: string | null) => {
      setIsFetching(true);
      const result = await query.refetch({
        searchTerm: value,
        brandIds: brandId ? [brandId] : null,
      });
      setBrandLines(
        getDataOrNull(result.data?.pimSearchBrandLines)?.brandLines ?? []
      );
      setIsFetching(false);
    },
    [query]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    refetch("", initialBrandId);
  }, [refetch]);

  return { brandLines, refetch, debouncedRefetch, isFetching };
}
