import { gql, useApolloClient } from "@apollo/client";
import { ThumbDown as ThumbDownIcon } from "@mui/icons-material";
import { ThumbUp as ThumbUpIcon } from "@mui/icons-material";
import { ThumbUpOutlined as ThumbsUpOutlinedIcon } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import React, { MouseEvent, useCallback } from "react";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission.js";
import {
  useApproveTaskItemMutation,
  useDisapproveTaskItemMutation,
} from "./Tasks.generated.js";
import { ToggleApprovalStatusButton__TaskDocumentItemFragment } from "./ToggleApprovalStatusButton.generated.js";
import { PermissionName } from "../../../clients/graphqlTypes.js";

interface Props {
  docId: string;
  projectId: string;
  itemId: string;
  item: ToggleApprovalStatusButton__TaskDocumentItemFragment;
  docViewerPermissions: Array<PermissionName>;
}

export const ToggleApprovalStatusButton = ({
  projectId,
  docId,
  itemId,
  item,
  docViewerPermissions,
}: Props) => {
  const theme = useTheme();

  const client = useApolloClient();

  const [approveTaskItemMutation, { loading: approveTaskItemLoading }] =
    useApproveTaskItemMutation({ client });

  const [disapproveTaskItemMutation, { loading: disapproveTaskItemLoading }] =
    useDisapproveTaskItemMutation({ client });

  const onClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>, isApproved: boolean) => {
      e.preventDefault();
      e.stopPropagation();

      if (!isApproved) {
        await approveTaskItemMutation({
          variables: {
            input: {
              projectId,
              docId,
              itemId,
            },
          },
        });
      } else {
        await disapproveTaskItemMutation({
          variables: {
            input: {
              projectId,
              docId,
              itemId,
            },
          },
        });
      }
    },
    [
      approveTaskItemMutation,
      disapproveTaskItemMutation,
      projectId,
      docId,
      itemId,
    ]
  );

  const { isApproved, approvalRequired } = item;

  const icon = (
    <>
      {isApproved && (
        <ThumbUpIcon
          htmlColor={theme.palette.success.main}
          style={{ width: 26, height: 26 }}
        />
      )}
      {isApproved === false && (
        <ThumbDownIcon
          htmlColor={theme.palette.error.main}
          style={{ width: 26, height: 26 }}
        />
      )}
      {isApproved === null && approvalRequired && (
        <ThumbsUpOutlinedIcon
          htmlColor={theme.palette.grey[300]}
          style={{ width: 26, height: 26 }}
        />
      )}
    </>
  );
  // if (!viewerCanDoClientInspection) return icon;

  return (
    <RestrictedByDocumentPermissionWithDebug
      document={{ viewerPermissions: docViewerPermissions }}
      permission="DECIDE_QUOTES"
      otherwise={icon}
    >
      <IconButton
        disabled={approveTaskItemLoading || disapproveTaskItemLoading}
        onClick={e => onClick(e, !!isApproved)}
        size="small"
        style={{ width: 40, height: 40 }}
      >
        {icon}
      </IconButton>
    </RestrictedByDocumentPermissionWithDebug>
  );
};
