import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, Tabs, TextWithBreaks } from "@msys/ui";
import { Box, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";
import { PlanningIcon } from "../../../features/planning/PlanningIcon.js";
import { PlanSessionListItem } from "../../../features/sessions/components/PlanSessionListItem.js";
import {
  DashboardPersonalSchedule_PlanSessionFragment,
  useDashboardPersonalScheduleQuery,
} from "./DashboardPersonalScheduleBox.generated.js";

type QuickFilterValue = "TODAY" | "TOMORROW";

export const DashboardPersonalScheduleBox = () => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("Dashboard");

  const client = useApolloClient();

  const [quickFilter, setQuickFilter] =
    React.useState<QuickFilterValue>("TODAY");

  const query = useDashboardPersonalScheduleQuery({
    client,
    variables: {
      rangeStart: todayStart?.toISOString(true),
      rangeEnd: tomorrowEnd?.toISOString(true),
      userId: viewer.id,
    },
  });

  const planSessions =
    getDataOrNull(query.data?.planSessions)?.edges.map(e => e.node) ?? [];
  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);

  const range = getRange(quickFilter);
  const filteredPlanSessions = getFilter(quickFilter)(planSessions);

  return planSessions.length > 0 ? (
    <CardContainer
      isExpandable
      Icon={<PlanningIcon />}
      title={t("My Schedule")}
    >
      <Box width="100%" p={1}>
        <Tabs
          condensed
          value={quickFilter}
          onChange={setQuickFilter}
          options={[
            { value: "TODAY", label: t("Today") },
            { value: "TOMORROW", label: t("Tomorrow") },
          ]}
        />
      </Box>
      {organisationDefaults && (
        <Box>
          {filteredPlanSessions.length ? (
            filteredPlanSessions.map(planSession => (
              <PlanSessionListItem
                key={planSession.id}
                planSession={planSession}
                range={range}
                organisationDefaults={organisationDefaults}
              />
            ))
          ) : (
            <Box
              p={1}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography
                variant="caption"
                component="div"
                align="center"
                color={theme => theme.palette.grey[400]}
              >
                <TextWithBreaks
                  text={t("There are no tasks assigned to you")}
                />
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </CardContainer>
  ) : null;
};

const todayStart = moment().startOf("day");
const todayEnd = moment().endOf("day");
const tomorrowStart = moment().add(1, "days").startOf("day");
const tomorrowEnd = moment().add(1, "days").endOf("day");

const getRange = (
  quickFilter: QuickFilterValue
): [moment.Moment, moment.Moment] => {
  switch (quickFilter) {
    case "TODAY":
      return [todayStart, todayEnd];
    case "TOMORROW":
      return [tomorrowStart, tomorrowEnd];
  }
};
const getFilter =
  (quickFilter: QuickFilterValue) =>
  (
    planSessions: DashboardPersonalSchedule_PlanSessionFragment[]
  ): DashboardPersonalSchedule_PlanSessionFragment[] => {
    const range = getRange(quickFilter);
    return planSessions.filter(
      ps =>
        moment(ps.from) <= range[1] &&
        moment(ps.till) >= range[0] &&
        !ps.isTentative
    );
  };
