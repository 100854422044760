import { ButtonInputWithPopover, useSyncStates } from "@msys/ui";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { FilterProps } from "../../../commons/filters/index.js";

export type ProjectFilterPlanning = "PLANNED" | "UNPLANNED";

export function FilterProjectPlanningForm({
  value: externalValue,
  setValue: setExternalValue,
}: FilterProps<ProjectFilterPlanning[]>) {
  const { t } = useTranslate(["Global", "Projects"]);
  const labels: Record<ProjectFilterPlanning, string> = {
    PLANNED: t("Planned", { ns: "Projects" }),
    UNPLANNED: t("Unplanned", { ns: "Projects" }),
  };
  const options: { value: ProjectFilterPlanning; label: string }[] = [
    { value: "PLANNED", label: labels.PLANNED },
    { value: "UNPLANNED", label: labels.UNPLANNED },
  ];
  const [value, setValue, error] = useSyncStates(
    externalValue,
    setExternalValue,
    value => {
      if (value.length === 0)
        return t("Please select at least one value", { ns: "Global" });
    }
  );
  return (
    <Box p={1}>
      <FormControl
        component="fieldset"
        variant="standard"
        error={Boolean(error)}
      >
        <FormGroup>
          {options.map((option, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  checked={value.includes(option.value)}
                  onChange={(event, checked) => {
                    if (checked) {
                      setValue(Array.from(new Set([...value, option.value])));
                    } else {
                      setValue(value.filter(v => v !== option.value));
                    }
                  }}
                />
              }
              label={<Typography variant="body1">{option.label}</Typography>}
              sx={{ ml: 0, mr: 0 }}
              key={`${option.value}-${index}`}
            />
          ))}
        </FormGroup>
        {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export function FilterProjectPlanningButton(
  props: FilterProps<ProjectFilterPlanning[]>
) {
  const { t } = useTranslate("Projects");
  return (
    <ButtonInputWithPopover
      label={t("Planning")}
      counter={
        props.value && props.value.length > 0 && props.value.length < 2
          ? props.value.length
          : undefined
      }
      content={<FilterProjectPlanningForm {...props} />}
      popoverWidth="md"
    />
  );
}
