import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  TableAggregates,
  Tabs,
  useFormatting,
} from "@msys/ui";
import { Divider, Stack, TablePagination } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  IncomingInvoicesSortBy,
  IncomingInvoicesSorting,
  OutgoingInvoicesSortBy,
  OutgoingInvoicesSorting,
  SortDirection,
} from "../../../clients/graphqlTypes.js";
import { useRestrictionFilter } from "../../auth/useRestrictionFilter.js";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton.js";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView.js";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam.js";
import { Page, PageTopbarItem } from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { TabsWithRestriction } from "../../commons/TabsWithRestriction.js";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants.js";
import { CrmCompanyFilterChip } from "../../features/crm-companies/CrmCompanyFilterChip.js";
import { useInvoiceTypes } from "../../features/invoices/hooks/useInvoiceTypes.js";
import { InvoiceListItem } from "../../features/invoices/InvoiceListItem.js";
import { InvoiceStatusBadge } from "../../features/invoices/InvoiceStatusBadge.js";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore.js";
import {
  DocumentsIncomingInvoicesQueryVariables,
  DocumentsIncomingInvoices_InvoiceFragment,
  DocumentsOutgoingInvoicesQueryVariables,
  DocumentsOutgoingInvoices_InvoiceFragment,
  useDocumentsIncomingInvoicesQuery,
  useDocumentsOutgoingInvoicesQuery,
} from "./Invoices.generated.js";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

type Tab = "INCOMING" | "OUTGOING";

interface Props {
  submenuItems: PageTopbarItem[];
}

export function Invoices({ submenuItems }: Props) {
  const { t } = useTranslate(["Invoices", "SidebarItems", "PageNotFound"]);

  const initialOptions = [
    {
      value: "OUTGOING" as Tab,
      label: t("Outgoing", { ns: "Invoices" }),
      capability: "INVOICING" as const,
    },
    {
      value: "INCOMING" as Tab,
      label: t("Incoming", { ns: "Invoices" }),
    },
  ];
  const restrictionFilter = useRestrictionFilter();
  const options = initialOptions.filter(restrictionFilter);

  const [tab, setTab] = useStateWithUrlParam<Tab>(
    "tab",
    options[0].value,
    true
  );

  const SectionTabs =
    initialOptions.length > 1 ? (
      <TabsWithRestriction
        condensed
        useSelectOnMobile
        options={initialOptions}
        value={tab}
        onChange={tab => {
          setTab(tab);
        }}
      />
    ) : undefined;

  return (
    <Page
      subtitle={t("Documents", {
        ns: "SidebarItems",
      })}
      title={t("Invoices", {
        ns: "Invoices",
      })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        {tab === "OUTGOING" &&
          (options.some(o => o.value === "OUTGOING") ? (
            <OutgoingInvoices SectionTabs={SectionTabs} />
          ) : (
            <ErrorMessage
              message={t("Page Not Found", {
                ns: "PageNotFound",
              })}
            />
          ))}
        {tab === "INCOMING" && <IncomingInvoices SectionTabs={SectionTabs} />}
      </PageContainer>
    </Page>
  );
}

type OutgoingInvoicesQuickFilterValue =
  | "ALL"
  | "DRAFT"
  | "OPEN"
  | "OVERDUE"
  | "CANCELLED"
  | "PAID";
type OutgoingInvoicesQuickFilterSettings = Record<
  OutgoingInvoicesQuickFilterValue,
  {
    label: string;
    disabled?: boolean;
    values: OutgoingInvoicesFilters;
  }
>;
type OutgoingInvoicesFilters = Omit<
  DocumentsOutgoingInvoicesQueryVariables,
  "limit" | "offset" | "sorting" | "invoicingCapability"
>;
const OUTGOING_INVOICE_DEFAULT_SORTING: OutgoingInvoicesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

function OutgoingInvoices({ SectionTabs }: { SectionTabs?: React.ReactNode }) {
  const navigate = useNavigate();

  const { t } = useTranslate([
    "Global",
    "ProjectOverview",
    "Invoices",
    "SidebarItems",
  ]);
  const { getFormattedDate, getFormattedPrice } = useFormatting();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>("invoices", "table", "list");

  const { invoicePaymentLabels } = useInvoiceTypes();

  const quickFilterSettings: OutgoingInvoicesQuickFilterSettings =
    React.useMemo(
      (): OutgoingInvoicesQuickFilterSettings => ({
        ALL: {
          label: t("All", {
            ns: "Global",
          }),
          values: {},
        },
        DRAFT: {
          label: t("Draft", {
            ns: "ProjectOverview",
          }),
          values: {
            filterStatus: ["draft"],
          },
        },
        OPEN: {
          label: t("Open", {
            ns: "ProjectOverview",
          }),
          values: {
            filterStatus: ["open"],
          },
        },
        OVERDUE: {
          label: t("Overdue", {
            ns: "ProjectOverview",
          }),
          values: {
            filterOverdue: true,
            filterStatus: ["open"],
          },
        },
        CANCELLED: {
          label: t("Cancelled", {
            ns: "ProjectOverview",
          }),
          values: {
            filterStatus: ["cancelled"],
          },
        },
        PAID: {
          label: t("Paid", {
            ns: "ProjectOverview",
          }),
          values: {
            filterStatus: ["paid"],
          },
        },
      }),
      [t]
    );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OutgoingInvoicesSorting, OutgoingInvoicesFilters>(
    {},
    OUTGOING_INVOICE_DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const [quickFilter, setQuickFilter] =
    useStateWithUrlParam<OutgoingInvoicesQuickFilterValue>(
      "quickfilter",
      "OPEN",
      toRemoveParams
    );

  const QUERY_BASE_VARIABLES = {
    limit,
    offset,
    sorting: OUTGOING_INVOICE_DEFAULT_SORTING,
  };

  const client = useApolloClient();
  const query = useDocumentsOutgoingInvoicesQuery({
    client,
    variables: {
      ...QUERY_BASE_VARIABLES,
      ...quickFilterSettings[quickFilter].values,
      ...filters,
      sorting,
    },
  });
  const invoices =
    getDataOrNull(
      (query.data ?? query.previousData)?.outgoingInvoices
    )?.edges.map(edge => edge.node) ?? [];
  const total =
    getDataOrNull((query.data ?? query.previousData)?.outgoingInvoices)
      ?.totalCount ?? 0;
  const totalAmountOpen = getDataOrNull(
    (query.data ?? query.previousData)?.outgoingInvoices
  )?.totalAmountOpen;
  const totalAmountOverdue = getDataOrNull(
    (query.data ?? query.previousData)?.outgoingInvoices
  )?.totalAmountOverdue;

  const stateStore = useDataGridStateStore("OutgoingInvoices");

  return (
    <>
      <ListHeader
        // title={null}
        SwitchViewButton={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        QuickFilter={
          <Stack direction="row" spacing={1}>
            {SectionTabs && (
              <>
                {SectionTabs}
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <Tabs
              condensed
              useSelectOnMobile
              options={Object.entries(quickFilterSettings).map(
                ([key, value]) => {
                  return {
                    value: key as OutgoingInvoicesQuickFilterValue,
                    label: value.label,
                    disabled: value.disabled,
                  };
                }
              )}
              value={quickFilter}
              onChange={(newQuickFilter: OutgoingInvoicesQuickFilterValue) => {
                setQuickFilter(newQuickFilter);
              }}
            />
          </Stack>
        }
        FilterButton={undefined}
        SearchField={
          <DebouncedSearchInput
            placeholder={t("Search", {
              ns: "Global",
            })}
            defaultValue={filters.searchTerm}
            onChangeSearchTerm={(newValue: string) => {
              setFilters(filters => ({
                ...filters,
                searchTerm: newValue,
              }));
            }}
          />
        }
        FilterChips={
          filters?.filterByCrmOrganisationId ? (
            <Stack flexWrap="wrap" direction={"row"}>
              <CrmCompanyFilterChip
                label={t("Client name", { ns: "Invoices" })}
                value={filters?.filterByCrmOrganisationId}
                setValue={newValue =>
                  setFilters(filterVariables => ({
                    ...filterVariables,
                    filterByCrmOrganisationId: newValue,
                  }))
                }
              />
            </Stack>
          ) : undefined
        }
        Info={
          quickFilter === "ALL" ||
          quickFilter === "OPEN" ||
          quickFilter === "OVERDUE" ? (
            <TableAggregates
              aggregates={[
                {
                  label: t("Open", {
                    ns: "ProjectOverview",
                  }),
                  value:
                    quickFilter === "ALL" || quickFilter === "OPEN"
                      ? totalAmountOpen
                      : undefined,
                  color: "secondary",
                },
                {
                  label: t("Overdue", {
                    ns: "ProjectOverview",
                  }),
                  value:
                    quickFilter === "ALL" || quickFilter === "OVERDUE"
                      ? totalAmountOverdue
                      : undefined,
                  color: "error",
                },
              ]}
            />
          ) : undefined
        }
        marginBottom={2}
      />
      {(() => {
        switch (activeView) {
          case "table":
            return (
              <DataGrid
                stateStore={stateStore}
                loading={query.loading}
                hideFooter={total === 0}
                columns={
                  [
                    {
                      field: "number",
                      headerName: t("Invoice No", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: true,
                      renderCell: ({ row: item }) => item.number ?? "–",
                    },
                    {
                      field: "type",
                      headerName: t("Type", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) =>
                        invoicePaymentLabels[item.type],
                    },
                    {
                      field: "title",
                      headerName: t("Title", {
                        ns: "Invoices",
                      }),
                      flex: 5,
                      minWidth: 100,
                      sortable: true,
                      renderCell: ({ row: item }) => item.title,
                    },
                    {
                      field: "projectNumber",
                      headerName: t("Project No.", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) => item.projectNumber,
                    },
                    {
                      field: "clientName",
                      headerName: t("Client name", {
                        ns: "Invoices",
                      }),
                      flex: 2,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) => item.clientName,
                    },
                    {
                      field: "status",
                      headerName: t("Status", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) => (
                        <InvoiceStatusBadge status={item.status} />
                      ),
                    },
                    {
                      field: "issueDate",
                      headerName: t("Issue Date", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        invoice.issueDate
                          ? getFormattedDate(invoice.issueDate)
                          : t("Not issued", {
                              ns: "Invoices",
                            }),
                    },
                    {
                      field: "dueDate",
                      headerName: t("Due date", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        invoice.dueDate
                          ? getFormattedDate(invoice.dueDate)
                          : t("Not set", {
                              ns: "Global",
                            }),
                    },
                    {
                      field: "totalNetPrice",
                      headerName: t("Total net", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) =>
                        getFormattedPrice(item.totalNetPrice),
                    },
                    {
                      field: "totalGrossPrice",
                      headerName: t("Total gross", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) =>
                        getFormattedPrice(item.totalGrossPrice),
                    },
                  ] as GridColDef<DocumentsOutgoingInvoices_InvoiceFragment>[]
                }
                rows={invoices}
                onRowClick={({ row: invoice }) =>
                  navigate(
                    `/projects/${invoice.projectId}/invoices/outgoing/${invoice.id}`
                  )
                }
                sortModel={sorting.map(s => ({
                  field: s.column,
                  sort: s.direction,
                }))}
                onSortModelChange={newModel => {
                  setSorting(
                    newModel.map(({ field, sort }) => ({
                      column: field as OutgoingInvoicesSortBy,
                      direction: (sort ?? "asc") as SortDirection,
                    }))
                  );
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={newPaginationModel => {
                  setPaginationModel(newPaginationModel);
                }}
                disableColumnFilter
                pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                rowCount={total}
              />
            );
          case "list":
            if (query.loading) return <LoadingSpinner />;
            if (total === 0)
              return (
                <ErrorMessage
                  message={t("There are no items to display", {
                    ns: "Global",
                  })}
                />
              );
            return (
              <Stack width="100%" direction="column" spacing={1}>
                {invoices.map(invoice => (
                  <CardItem
                    key={invoice.id}
                    //@ts-ignore
                    component={Link}
                    to={`/projects/${invoice.projectId}/invoices/outgoing/${invoice.id}`}
                  >
                    <InvoiceListItem invoice={invoice} />
                  </CardItem>
                ))}
                <TablePagination
                  component="div"
                  count={total}
                  page={paginationModel.page}
                  onPageChange={(event, newPage) => {
                    setPaginationModel({
                      pageSize: paginationModel.pageSize,
                      page: newPage,
                    });
                  }}
                  rowsPerPage={paginationModel.pageSize}
                  onRowsPerPageChange={event => {
                    setPaginationModel({
                      pageSize: parseInt(event.target.value, 10),
                      page: 0,
                    });
                  }}
                  labelRowsPerPage={t("Per page:", { ns: "Global" })}
                />
              </Stack>
            );
        }
      })()}
    </>
  );
}

type IncomingInvoicesQuickFilterValue = "ALL" | "OPEN" | "OVERDUE" | "PAID";
type IncomingInvoicesQuickFilterSettings = Record<
  IncomingInvoicesQuickFilterValue,
  {
    label: string;
    disabled?: boolean;
    values: IncomingInvoicesFilter;
  }
>;
type IncomingInvoicesFilter = Omit<
  DocumentsIncomingInvoicesQueryVariables,
  "limit" | "offset" | "sorting"
>;
const INCOMING_INVOICE_DEFAULT_SORTING: IncomingInvoicesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

function IncomingInvoices({ SectionTabs }: { SectionTabs?: React.ReactNode }) {
  const navigate = useNavigate();

  const { t } = useTranslate([
    "Global",
    "ProjectOverview",
    "Invoices",
    "SidebarItems",
  ]);
  const { getFormattedPrice, getFormattedDate } = useFormatting();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>("invoices", "table", "list");

  const { invoicePaymentLabels } = useInvoiceTypes();

  const quickFilterSettings: IncomingInvoicesQuickFilterSettings =
    React.useMemo(
      (): IncomingInvoicesQuickFilterSettings => ({
        ALL: {
          label: t("All", {
            ns: "Global",
          }),
          values: {},
        },
        OPEN: {
          label: t("Open", {
            ns: "ProjectOverview",
          }),
          values: {
            filterStatus: ["open"],
          },
        },
        OVERDUE: {
          label: t("Overdue", {
            ns: "ProjectOverview",
          }),
          values: {
            filterOverdue: true,
            filterStatus: ["open"],
          },
        },
        PAID: {
          label: t("Paid", {
            ns: "ProjectOverview",
          }),
          values: {
            filterStatus: ["paid"],
          },
        },
      }),
      [t]
    );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<IncomingInvoicesSorting, IncomingInvoicesFilter>(
    {},
    INCOMING_INVOICE_DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const [quickFilter, setQuickFilter] =
    useStateWithUrlParam<IncomingInvoicesQuickFilterValue>(
      "quickfilter",
      "OPEN",
      toRemoveParams
    );

  const QUERY_BASE_VARIABLES = {
    limit,
    offset,
    sorting: INCOMING_INVOICE_DEFAULT_SORTING,
  };

  const client = useApolloClient();
  const query = useDocumentsIncomingInvoicesQuery({
    client,
    variables: {
      ...QUERY_BASE_VARIABLES,
      ...quickFilterSettings[quickFilter].values,
      ...filters,
      sorting,
    },
  });

  const invoices =
    getDataOrNull(
      (query.data ?? query.previousData)?.incomingInvoices
    )?.edges.map(edge => edge.node) ?? [];
  const total =
    getDataOrNull((query.data ?? query.previousData)?.incomingInvoices)
      ?.totalCount ?? 0;
  const totalAmountOpen = getDataOrNull(
    (query.data ?? query.previousData)?.incomingInvoices
  )?.totalAmountOpen;
  const totalAmountOverdue = getDataOrNull(
    (query.data ?? query.previousData)?.incomingInvoices
  )?.totalAmountOverdue;

  const stateStore = useDataGridStateStore("IncomingInvoices");

  return (
    <>
      <ListHeader
        // title={null}
        SwitchViewButton={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        QuickFilter={
          <Stack direction="row" spacing={1}>
            {SectionTabs && (
              <>
                {SectionTabs}
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <Tabs
              condensed
              useSelectOnMobile
              options={Object.entries(quickFilterSettings).map(
                ([key, value]) => {
                  return {
                    value: key as IncomingInvoicesQuickFilterValue,
                    label: value.label,
                    disabled: value.disabled,
                  };
                }
              )}
              value={quickFilter}
              onChange={(newQuickFilter: IncomingInvoicesQuickFilterValue) => {
                setQuickFilter(newQuickFilter);
              }}
            />
          </Stack>
        }
        FilterButton={undefined}
        SearchField={
          <DebouncedSearchInput
            placeholder={t("Search", {
              ns: "Global",
            })}
            defaultValue={filters.searchTerm}
            onChangeSearchTerm={(newValue: string) => {
              setFilters(filters => ({
                ...filters,
                searchTerm: newValue,
              }));
            }}
          />
        }
        FilterChips={
          filters?.filterByCrmOrganisationId ? (
            <Stack flexWrap="wrap" direction={"row"}>
              <CrmCompanyFilterChip
                label={t("Contractor name", { ns: "Invoices" })}
                value={filters?.filterByCrmOrganisationId}
                setValue={newValue =>
                  setFilters(filterVariables => ({
                    ...filterVariables,
                    filterByCrmOrganisationId: newValue,
                  }))
                }
              />
            </Stack>
          ) : undefined
        }
        Info={
          quickFilter === "ALL" ||
          quickFilter === "OPEN" ||
          quickFilter === "OVERDUE" ? (
            <TableAggregates
              aggregates={[
                {
                  label: t("Open", {
                    ns: "ProjectOverview",
                  }),
                  value:
                    quickFilter === "ALL" || quickFilter === "OPEN"
                      ? totalAmountOpen
                      : undefined,
                  color: "secondary",
                },
                {
                  label: t("Overdue", {
                    ns: "ProjectOverview",
                  }),
                  value:
                    quickFilter === "ALL" || quickFilter === "OVERDUE"
                      ? totalAmountOverdue
                      : undefined,
                  color: "error",
                },
              ]}
            />
          ) : undefined
        }
        marginBottom={2}
      />
      {(() => {
        switch (activeView) {
          case "table":
            return (
              <DataGrid
                stateStore={stateStore}
                loading={query.loading}
                hideFooter={total === 0}
                columns={
                  [
                    {
                      field: "number",
                      headerName: t("Invoice No", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: true,
                      renderCell: ({ row: item }) => item.number ?? "-",
                    },
                    {
                      field: "type",
                      headerName: t("Type", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) =>
                        invoicePaymentLabels[item.type],
                    },
                    {
                      field: "title",
                      headerName: t("Title", {
                        ns: "Invoices",
                      }),
                      flex: 5,
                      minWidth: 100,
                      sortable: true,
                      renderCell: ({ row: item }) => item.title,
                    },
                    {
                      field: "projectNumber",
                      headerName: t("Project No.", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) => item.projectNumber,
                    },
                    {
                      field: "contractorName",
                      headerName: t("Contractor name", {
                        ns: "Invoices",
                      }),
                      flex: 2,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) => item.contractorName,
                    },
                    {
                      field: "status",
                      headerName: t("Status", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) => (
                        <InvoiceStatusBadge status={item.status} />
                      ),
                    },
                    {
                      field: "issueDate",
                      headerName: t("Issue Date", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        invoice.issueDate
                          ? getFormattedDate(invoice.issueDate)
                          : t("Not issued", {
                              ns: "Invoices",
                            }),
                    },
                    {
                      field: "dueDate",
                      headerName: t("Due date", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: invoice }) =>
                        invoice.dueDate
                          ? getFormattedDate(invoice.dueDate)
                          : t("Not set", {
                              ns: "Global",
                            }),
                    },
                    {
                      field: "totalNetPrice",
                      headerName: t("Total net", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) =>
                        getFormattedPrice(item.totalNetPrice),
                    },
                    {
                      field: "totalGrossPrice",
                      headerName: t("Total gross", {
                        ns: "Invoices",
                      }),
                      flex: 1,
                      minWidth: 100,
                      sortable: false,
                      renderCell: ({ row: item }) =>
                        getFormattedPrice(item.totalGrossPrice),
                    },
                  ] as GridColDef<DocumentsIncomingInvoices_InvoiceFragment>[]
                }
                rows={invoices}
                onRowClick={({ row: invoice }) =>
                  navigate(
                    `/projects/${invoice.projectId}/invoices/incoming/${invoice.id}`
                  )
                }
                sortModel={sorting.map(s => ({
                  field: s.column,
                  sort: s.direction,
                }))}
                onSortModelChange={newModel => {
                  setSorting(
                    newModel.map(({ field, sort }) => ({
                      column: field as IncomingInvoicesSortBy,
                      direction: (sort ?? "asc") as SortDirection,
                    }))
                  );
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={newPaginationModel => {
                  setPaginationModel(newPaginationModel);
                }}
                disableColumnFilter
                pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                rowCount={total}
              />
            );
          case "list":
            if (query.loading) return <LoadingSpinner />;
            if (total === 0)
              return (
                <ErrorMessage
                  message={t("There are no items to display", {
                    ns: "Global",
                  })}
                />
              );
            return (
              <Stack width="100%" direction="column" spacing={1}>
                {invoices.map(invoice => (
                  <CardItem
                    key={invoice.id}
                    //@ts-ignore
                    component={Link}
                    to={`/projects/${invoice.projectId}/invoices/outgoing/${invoice.id}`}
                  >
                    <InvoiceListItem invoice={invoice} />
                  </CardItem>
                ))}
                <TablePagination
                  component="div"
                  count={total}
                  page={paginationModel.page}
                  onPageChange={(event, newPage) => {
                    setPaginationModel({
                      pageSize: paginationModel.pageSize,
                      page: newPage,
                    });
                  }}
                  rowsPerPage={paginationModel.pageSize}
                  onRowsPerPageChange={event => {
                    setPaginationModel({
                      pageSize: parseInt(event.target.value, 10),
                      page: 0,
                    });
                  }}
                  labelRowsPerPage={t("Per page:", { ns: "Global" })}
                />
              </Stack>
            );
        }
      })()}
    </>
  );
}
