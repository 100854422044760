/* eslint-disable react-hooks/rules-of-hooks */
import { useApolloClient } from "@apollo/client";
import { faFilter, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DEFAULT_END_WORK_DAY,
  DEFAULT_START_WORK_DAY,
  getDataOrNull,
} from "@msys/common";
import {
  ModalOpenButton,
  SearchInput,
  TextWithBreaks,
  ellipsisStyle,
  useScreenWidth,
} from "@msys/ui";
import { LocationOff as LocationOffIcon } from "@mui/icons-material";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  Link as MuiLink,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import {
  DragAndDrop,
  Inject,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ScheduleModel,
  TimelineMonth,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import {
  ActionEventArgs,
  EventRenderedArgs,
  PopupCloseEventArgs,
  PopupOpenEventArgs,
} from "@syncfusion/ej2-schedule/src/schedule/base/interface.js";
import type { TFunction } from "@msys/tolgee";
import { useTranslate } from "@tolgee/react";
import { isEqual, isNil, isUndefined, uniqBy } from "lodash-es";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { Link } from "react-router-dom";
import { useLatest } from "react-use";
import {
  OrganisationRole as GQLOrganisationRole,
  namedOperations,
} from "../../../clients/graphqlTypes.js";
import { color } from "../../../common/MuiThemeProvider.js";
import { ReactComponent as EditCalendarIcon } from "../../assets/icons/edit-calendar.svg";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { useUserData } from "../../auth/useUserData.js";
import { Stack } from "../../commons/layout/Stack.js";
import { PlanningAbsenceFragment } from "../../main-routes/planning/PlanningAbsences.generated.js";
import { compareNumbersFn } from "../../utils.js";
import { useAbsenceScheduleQuery } from "../absences/AbsenceSchedule.generated.js";
import { absenceColors } from "../absences/helpers.js";
import { useAbsenceReasons } from "../absences/useAbsenceReasons.js";
import { AddressDetails__AddressFragment } from "../addresses/Addresses.generated.js";
import { getAddressLabel, getAddressSearchUrl } from "../addresses/helpers.js";
import {
  MapProjectFragment,
  SchedulePlanSessionFragment,
} from "../schedule/Fragments.generated.js";
import {
  useScheduleDeletePlanSessionMutation,
  useScheduleModifyPlanSessionMutation,
} from "../schedule/Schedule.generated.js";
import { useActionBegin } from "../schedule/helpers.js";
import { UserAvatar } from "../users/UserAvatar.js";
import { useOrganisationRoles } from "../users/useRoles.js";
import {
  PlanningResourcesFragment,
  usePlanningSessionsScheduleQuery,
} from "./PlanningSchedule.generated.js";
import { ResourceFilterChips } from "./ResourceFilterChips.js";
import { ResourceFilterModal } from "./ResourceFilterModal.js";
import { MomentRange, isEqualRange } from "./helpers.js";
import { useAvailabilityFilter } from "./useAvailabilityFilter.js";
import { useDistanceToProject } from "./useDistanceToProject.js";
import { useResourceFilter } from "./useResourceFilter.js";

const SCHEDULE_GROUP = { resources: ["Workers"], enableCompactView: false };
const SCHEDULE_STYLE = { flexGrow: 1, flexShrink: 1 };
const TIME_SCALE = { interval: 60 * 24, enable: true };
const INJECT_SERVICES = [TimelineViews, TimelineMonth, DragAndDrop, Resize];

type Session = Pick<
  SchedulePlanSessionFragment,
  "id" | "project" | "who" | "from" | "till" | "isTentative"
> & { distanceToAddress?: number | null };

type PlanningResourceWithDistance = PlanningResourcesFragment & {
  distanceToProject: number | null;
};

interface Props {
  addressId?: string;
  projectId?: string;
  projectAddress?: AddressDetails__AddressFragment;
  pinnedResourceIds?: string[];
  usePinnedResources?: boolean;
  useMaxDistance?: boolean;
  roles: GQLOrganisationRole[] | undefined;
  resources: PlanningResourcesFragment[] | undefined;
  actionBegin?: ScheduleModel["actionBegin"];
  view: "TimelineDay" | "TimelineMonth" | "TimelineWeek";
  activeDates?: MomentRange;
  startWorkDay?: string;
  endWorkDay?: string;
  additionalPlanSessions?: Session[];
  selectedProjectId?: string;
  lockExistingPlanSessions?: boolean;
  onPlanSessionClick?: (planSession: SchedulePlanSessionFragment) => void;
  excludeExistingPlanSessionFn?: (ps: SchedulePlanSessionFragment) => boolean;
  RightButtons?: React.ReactNode;
  onResourceMaxDistanceChange(resourceMaxDistance: number): void;
  onProjectsChange?(projects: MapProjectFragment[]): void;
}

const _PlanningSchedule = ({
  addressId,
  projectId,
  projectAddress,
  pinnedResourceIds,
  usePinnedResources = true,
  useMaxDistance = true,
  resources,
  roles,
  additionalPlanSessions,
  actionBegin,
  onPlanSessionClick,
  lockExistingPlanSessions = false,
  view,
  selectedProjectId,
  activeDates,
  excludeExistingPlanSessionFn,
  startWorkDay = DEFAULT_START_WORK_DAY,
  endWorkDay = DEFAULT_END_WORK_DAY,
  RightButtons,
  onResourceMaxDistanceChange,
  onProjectsChange,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["PlanningModal", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const { isMinTablet } = useScreenWidth();
  const theme = useTheme();

  const onPlanSessionClickLatest = useLatest(onPlanSessionClick);

  const schedulerRef = React.useRef<ScheduleComponent>(null);
  const [range, setRange] = React.useState<MomentRange>([undefined, undefined]);
  const updateRange = React.useCallback(() => {
    if (!schedulerRef.current) return;
    const currentViewDates = schedulerRef.current.getCurrentViewDates();
    const startDate = currentViewDates[0];
    const endDate = currentViewDates[currentViewDates.length - 1];
    setRange([moment(startDate).startOf("day"), moment(endDate).endOf("day")]);
  }, []);

  const {
    resourceList,
    setResourceList,
    resourceOnTop,
    setResourceOnTop,
    resourceOrganisationRoleIds,
    setResourceOrganisationRoleIds,
    resourceAvailability,
    setResourceAvailability,
    resourceMaxDistance,
    setResourceMaxDistance,
  } = useResourceFilter(viewer.organisation.id, projectId);

  const onResourceMaxDistanceChangeLatest = useLatest(
    onResourceMaxDistanceChange
  );
  const onProjectsChangeLatest = useLatest(onProjectsChange);

  React.useEffect(() => {
    onResourceMaxDistanceChangeLatest.current(resourceMaxDistance ?? 0);
  }, [resourceMaxDistance, onResourceMaxDistanceChangeLatest]);

  const client = useApolloClient();
  const query = usePlanningSessionsScheduleQuery({
    client,
    fetchPolicy: "network-only",
    variables: {
      addressId,
      rangeStart: range[0]
        ? moment(range[0]).startOf("day").toISOString(true)
        : undefined,
      rangeEnd: range[1]
        ? moment(range[1]).endOf("day").toISOString(true)
        : undefined,
      ...(resourceList ? { filterByWhoIds: resourceList } : undefined),
      ...(resourceOrganisationRoleIds
        ? { filterByRoleIds: resourceOrganisationRoleIds }
        : undefined),
    },
    skip: !range[0] || !range[1],
  });

  const planSessions: SchedulePlanSessionFragment[] = React.useMemo(
    () =>
      getDataOrNull(query?.data?.planSessions)?.edges.map(e => e.node) ?? [],
    [query?.data?.planSessions]
  );

  const projects = React.useMemo(
    () =>
      uniqBy(
        planSessions.map(ps => ps.project),
        p => p.id
      ),
    [planSessions]
  );

  React.useEffect(() => {
    onProjectsChangeLatest.current?.(projects);
  }, [projects, onProjectsChangeLatest]);

  const absenceQuery = useAbsenceScheduleQuery({
    client,
    fetchPolicy: "network-only",
    variables: {
      rangeStart: range[0] ? moment(range[0]).format("YYYY-MM-DD") : undefined,
      rangeEnd: range[1] ? moment(range[1]).format("YYYY-MM-DD") : undefined,
      ...(resourceList ? { filterByWhoIds: resourceList } : undefined),
      ...(resourceOrganisationRoleIds
        ? { filterByRoleIds: resourceOrganisationRoleIds }
        : undefined),
    },
    skip: !range[0] || !range[1],
  });

  const absences: PlanningAbsenceFragment[] = React.useMemo(
    () =>
      getDataOrNull(absenceQuery?.data?.absences)?.edges.map(e => e.node) ?? [],
    [absenceQuery?.data?.absences]
  );

  const [modifyPlanSession] = useScheduleModifyPlanSessionMutation({ client });
  const [deletePlanSession] = useScheduleDeletePlanSessionMutation({ client });

  const handleChangeAction = async (data: any) => {
    const { id, WhoId, startTime, endTime, isTentative } = data;
    try {
      await modifyPlanSession({
        variables: {
          planSession: {
            id,
            whoId: WhoId,
            from: moment(startTime).toISOString(true),
            till: moment(endTime).toISOString(true),
            isTentative: isTentative,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const handleRemoveAction = async (data: any) => {
    const [{ id }] = data;

    try {
      await deletePlanSession({
        variables: {
          id,
        },
        refetchQueries: [
          namedOperations.Query.EditProjectPlanSessionsModal,
          namedOperations.Query.PlanningSessionsSchedule,
        ],
        awaitRefetchQueries: true,
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const defaultActionBegin = useActionBegin(
    handleChangeAction,
    handleRemoveAction
  );

  const onActionComplete = React.useCallback(
    (args: ActionEventArgs) => {
      if (
        args.requestType === "viewNavigate" ||
        args.requestType === "dateNavigate"
      ) {
        updateRange();
      }
    },
    [updateRange]
  );

  const onCreated = React.useCallback(() => {
    setTimeout(() => {
      updateRange();
    });
  }, [updateRange]);

  React.useEffect(() => {
    if (activeDates && activeDates[0] && activeDates[1]) {
      setTimeout(() => {
        if (!schedulerRef.current) return;
        const currentDates = schedulerRef.current.getCurrentViewDates();

        const dateFrom = currentDates[0];
        const dateTo = currentDates[currentDates.length - 1];

        if (
          !dateFrom ||
          !dateTo ||
          (activeDates[0]?.isSameOrBefore(dateTo) &&
            activeDates[1]?.isSameOrAfter(dateFrom))
        ) {
          // all good - current active dates intersect calendar range
        } else {
          // updating date range
          schedulerRef.current.selectedDate = activeDates[0]!.toDate();
          schedulerRef.current.dataBind();
          setTimeout(() => {
            updateRange();
          }); // .getCurrentViewDates() returns old data without setTimeout
        }
      });
    }
  }, [activeDates, updateRange]);

  const distance = useDistanceToProject(selectedProjectId ?? projectId);
  const resourcesWithDistance: PlanningResourceWithDistance[] = React.useMemo(
    () =>
      resources?.map(r => ({
        ...r,
        distanceToProject: distance?.[r.id] ?? null,
      })) ?? [],

    [resources, distance]
  );

  const [resourceSearch, setResourceSearch] = React.useState<string>("");

  const { absenceReasonLabels } = useAbsenceReasons();

  const scheduleData = React.useMemo(() => {
    const absenceToAppointmentMapper =
      createAbsenceToAppointmentMapper(absenceReasonLabels);
    const sessions = [
      ...(planSessions ?? [])
        .filter(
          ps =>
            !excludeExistingPlanSessionFn || !excludeExistingPlanSessionFn(ps)
        )
        .map(ps =>
          sessionToAppointmentMapper(
            ps,
            lockExistingPlanSessions,
            ps.project.id === selectedProjectId
          )
        ),
      ...(absences ?? []).map(a => absenceToAppointmentMapper(a)),
      ...(additionalPlanSessions ?? [])
        // .filter(session => !excludeIds || !excludeIds.includes(session.id))
        .map(ps => sessionToAppointmentMapper(ps, false, true)),
    ];

    // if (sessions.length === 0) return;

    return sessions;
  }, [
    planSessions,
    absences,
    additionalPlanSessions,
    excludeExistingPlanSessionFn,
    lockExistingPlanSessions,
    selectedProjectId,
    absenceReasonLabels,
  ]);

  const onPopupOpen = React.useCallback((args: PopupOpenEventArgs) => {
    if (!args) return;
    if (!isNil(args.data) && (args.data as any).planSession) {
      onPlanSessionClickLatest.current?.((args.data as any).planSession);
    }
  }, []);

  const QuickInfoTemplate = React.useMemo(
    () => getQuickInfoTemplate(t, viewer.organisation.id),
    [t, viewer.organisation.id]
  );
  const EditorTemplate = React.useMemo(() => getEditorTemplate(t), [t]);
  const ResourceHeaderTemplate = React.useMemo(
    () => getResourceHeaderTemplate(t, theme, isMinTablet),
    [t, theme, isMinTablet]
  );

  const EventTemplate = React.useMemo(() => getEventTemplate(t), [t]);
  const eventSettings = React.useMemo(() => {
    return {
      allowDeleting: true,
      allowAdding: false,
      enableTooltip: true,
      rowAutoHeight: true,
      dataSource: scheduleData,
      fields: {
        id: "id",
        subject: { name: "title" },
        isAllDay: { name: "isAllDay" },
        isReadonly: "isReadonly",
        startTime: { name: "startTime" },
        location: { name: "location" },
        endTime: { name: "endTime" },
      },
      template: EventTemplate,
    };
  }, [EventTemplate, scheduleData]);

  const workHours = React.useMemo(
    () => ({ start: startWorkDay, end: endWorkDay }),
    [startWorkDay, endWorkDay]
  );

  const resourceAvailableUserIds = useAvailabilityFilter(
    selectedProjectId ?? projectId,
    resourceAvailability,
    undefined
  );

  const { getOrganisationRoleTitle } = useOrganisationRoles();

  // We skip `pinnedResourceIds` if it's not needed for workersData to improve memoization
  // Any update of workersData leads to schedule full re-render
  const pinnedIds = React.useMemo(() => {
    if (
      !(usePinnedResources && resourceOnTop) &&
      !resourceList &&
      !resourceAvailableUserIds &&
      !resourceSearch &&
      !resourceOrganisationRoleIds &&
      !resourceMaxDistance
    )
      return undefined;
    return pinnedResourceIds;
  }, [
    resourceMaxDistance,
    resourceOnTop,
    resourceList,
    resourceSearch,
    resourceOrganisationRoleIds,
    resourceAvailableUserIds,
    pinnedResourceIds,
    usePinnedResources,
  ]);

  const workersData = React.useMemo(
    () =>
      (resourcesWithDistance ?? [])
        .filter(r => {
          if (pinnedIds?.includes(r.id)) return true;
          return (
            (!resourceList || resourceList.includes(r.id)) &&
            (!resourceAvailableUserIds ||
              resourceAvailableUserIds.includes(r.id)) &&
            (!resourceSearch ||
              r.fullname
                .toLowerCase()
                .includes(resourceSearch.toLowerCase())) &&
            (!resourceOrganisationRoleIds ||
              r.roles.some(r => resourceOrganisationRoleIds.includes(r.id))) &&
            (!resourceMaxDistance ||
              (r.distanceToProject ?? 0) <= resourceMaxDistance)
          );
        })
        .map(r => ({
          ...r,
          id: r.id,
          color: color.purple,
          isPinned:
            usePinnedResources && resourceOnTop && pinnedIds?.includes(r.id),
          roles: getOrganisationRoleTitle(r.roles),
          distanceToProject: projectAddress
            ? r.homeAddress
              ? (r.distanceToProject ?? null)
              : undefined
            : null,
        }))
        .sort(
          (a, b) =>
            (usePinnedResources && resourceOnTop
              ? +b.isPinned! - +a.isPinned!
              : 0) ||
            ((resourceMaxDistance ?? 0) > 0
              ? compareNumbersFn(a.distanceToProject, b.distanceToProject)
              : 0) ||
            a.familyname.localeCompare(b.familyname)
        ),
    [
      projectAddress,
      resourcesWithDistance,
      resourceMaxDistance,
      resourceOnTop,
      resourceList,
      resourceSearch,
      resourceOrganisationRoleIds,
      resourceAvailableUserIds,
      getOrganisationRoleTitle,
      pinnedIds,
      usePinnedResources,
    ]
  );

  return (
    <Stack flexDirection="column" height="100%" flexGrow={1} flexShrink={1}>
      <Stack
        alignItems="center"
        flexGrow={0}
        flexShrink={0}
        justifyContent="space-between"
      >
        <Stack alignItems="center">
          <SearchInput
            placeholder={t("Search", {
              ns: "Global",
            })}
            searchTerm={resourceSearch}
            onChangeSearchTerm={setResourceSearch}
          />

          <ModalOpenButton
            Modal={ResourceFilterModal}
            modalProps={{
              projectAddress,
              usePinnedResources,
              useMaxDistance,
              resources: resources ?? [],
              roles: roles ?? [],
              resourceOnTop,
              resourceList,
              resourceOrganisationRoleIds,
              resourceAvailability,
              resourceMaxDistance,
              handleComplete: ({
                resourceList: newResourceList,
                resourceOnTop: newResourceOnTop,
                resourceOrganisationRoleIds: newResourceOrganisationRoleIds,
                resourceAvailability: newResourceAvailability,
                resourceMaxDistance: newResourceMaxDistance,
              }) => {
                if (!isEqual(resourceMaxDistance, newResourceMaxDistance))
                  setResourceMaxDistance(newResourceMaxDistance);
                if (!isEqual(resourceList, newResourceList))
                  setResourceList(newResourceList);
                if (!isEqual(resourceOnTop, newResourceOnTop))
                  setResourceOnTop(newResourceOnTop);
                if (
                  !isEqual(
                    resourceOrganisationRoleIds,
                    newResourceOrganisationRoleIds
                  )
                )
                  setResourceOrganisationRoleIds(
                    newResourceOrganisationRoleIds
                  );
                if (
                  !isEqualRange(resourceAvailability, newResourceAvailability)
                )
                  setResourceAvailability(newResourceAvailability);
              },
            }}
          >
            <IconButton color="primary" size="extra-small">
              <Icon fontSize="small">
                <FontAwesomeIcon icon={faFilter} />
              </Icon>
            </IconButton>
          </ModalOpenButton>

          <ResourceFilterChips
            resources={resources ?? []}
            roles={roles ?? []}
            resourceList={resourceList}
            resourceOrganisationRoleIds={resourceOrganisationRoleIds}
            resourceAvailability={resourceAvailability}
            resourceMaxDistance={resourceMaxDistance}
            setResourceList={setResourceList}
            setResourceOrganisationRoleIds={setResourceOrganisationRoleIds}
            setResourceAvailability={setResourceAvailability}
            setResourceMaxDistance={setResourceMaxDistance}
          />
        </Stack>
        {RightButtons && <Stack alignItems="center">{RightButtons}</Stack>}
      </Stack>
      <Box
        flexGrow={1}
        flexShrink={1}
        position="relative"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        alignItems="stretch"
      >
        <MemoizedScheduleComponent
          schedulerRef={schedulerRef}
          startWorkDay={startWorkDay}
          endWorkDay={endWorkDay}
          workHours={workHours}
          view={view}
          onPopupOpen={onPopupOpen}
          onPopupClose={onPopupClose}
          actionBegin={actionBegin ?? defaultActionBegin}
          onActionComplete={onActionComplete}
          onCreated={onCreated}
          eventRendered={eventRendered}
          ResourceHeaderTemplate={ResourceHeaderTemplate}
          QuickInfoTemplate={QuickInfoTemplate}
          EditorTemplate={EditorTemplate}
          eventSettings={eventSettings}
          workersData={workersData}
        />
      </Box>
    </Stack>
  );
};
export const PlanningSchedule = React.memo(_PlanningSchedule);

function sessionToAppointmentMapper(
  ps: Session,
  isReadonly: boolean,
  isSelected: boolean
) {
  const location = ps.project.buildingInfo?.buildingAddress
    ? getAddressLabel(ps.project.buildingInfo?.buildingAddress)
    : undefined;

  return {
    id: ps.id,
    planSession: ps,
    title: ps.project.title,
    description: location ?? "",
    distance: ps.distanceToAddress ?? null,
    backgroundColor: isReadonly
      ? color.grey
      : isSelected
        ? color.purple
        : ps.project.ticket
          ? color.secondary
          : color.primary,
    isAllDay: false,
    ProjectId: ps.project.id,
    WhoId: ps.who.id,
    WhoName: ps.who.fullname,
    project: ps.project,
    location,
    startTime: moment(ps.from).local().toDate(),
    endTime: moment(ps.till).local().toDate(),
    isTentative: ps.isTentative,
    isReadonly,
  };
}

function createAbsenceToAppointmentMapper(
  absenceReasonLabels: ReturnType<
    typeof useAbsenceReasons
  >["absenceReasonLabels"]
) {
  return (a: PlanningAbsenceFragment) => ({
    id: a.id,
    absence: a,
    title: absenceReasonLabels[a.reason],
    description: a.note,
    backgroundColor: absenceColors[a.reason],
    isTentative: false,
    WhoId: a.who.id,
    WhoName: a.who.fullname,
    startTime: moment(a.from).startOf("day").local().toDate(),
    endTime: moment(a.till).endOf("day").local().toDate(),
    location: undefined,
    isAllDay: true,
    isReadonly: true,
  });
}

const eventRendered = (args: EventRenderedArgs) => {
  if (!args) return;
  const data = args.data;
  if (data.backgroundColor) {
    args.element.style.backgroundColor = data.backgroundColor;
  }
  if (!isUndefined(data.isTentative)) {
    args.element.style.opacity = data.isTentative ? "0.3" : "";
  }
};

const onPopupClose = (args: PopupCloseEventArgs) => {
  if (!args) return;

  if (args.type === "Editor" && !isNil(args.data)) {
    const startTime = args.element.querySelector(
      "[id^=edit-event-start-]:not([id$=-label])"
    ) as HTMLInputElement;
    if (startTime) args.data.startTime = startTime.value;

    const endTime = args.element.querySelector(
      "[id^=edit-event-end-]:not([id$=-label])"
    ) as HTMLInputElement;
    if (endTime) args.data.endTime = endTime.value;

    const isTentative = args.element.querySelector(
      "[id^=edit-event-isTentative-]:not([id$=-label])"
    ) as HTMLInputElement;
    if (isTentative) args.data.isTentative = Boolean(isTentative.checked);
  }
};

type ScheduleComponentProps = ScheduleModel;
type ResourceDirectiveProps = React.ComponentProps<typeof ResourceDirective>;

const MemoizedScheduleComponent = React.memo(
  ({
    schedulerRef,
    startWorkDay,
    endWorkDay,
    workHours,
    view,
    onPopupOpen,
    onPopupClose,
    actionBegin,
    ResourceHeaderTemplate,
    onCreated,
    onActionComplete,
    eventRendered,
    QuickInfoTemplate,
    EditorTemplate,
    eventSettings,
    workersData,
  }: {
    schedulerRef: React.LegacyRef<ScheduleComponent>;
    startWorkDay: ScheduleComponentProps["startHour"];
    endWorkDay: ScheduleComponentProps["endHour"];
    workHours: ScheduleComponentProps["workHours"];
    view: ScheduleComponentProps["currentView"];
    onPopupOpen: ScheduleComponentProps["popupOpen"];
    onPopupClose: ScheduleComponentProps["popupClose"];
    actionBegin: ScheduleComponentProps["actionBegin"];
    ResourceHeaderTemplate: ScheduleComponentProps["resourceHeaderTemplate"];
    onCreated: ScheduleComponentProps["created"];
    onActionComplete: ScheduleComponentProps["actionComplete"];
    eventRendered: ScheduleComponentProps["eventRendered"];
    QuickInfoTemplate: ScheduleComponentProps["quickInfoTemplates"];
    EditorTemplate: ScheduleComponentProps["editorTemplate"];
    eventSettings: ScheduleComponentProps["eventSettings"];
    workersData: ResourceDirectiveProps["dataSource"];
  }) => {
    return (
      <ScheduleComponent
        key="calendar-schedule"
        ref={schedulerRef}
        showTimeIndicator={false}
        enablePersistence
        rowAutoHeight
        height="100%"
        width="100%"
        style={SCHEDULE_STYLE}
        startHour={startWorkDay}
        endHour={endWorkDay}
        workHours={workHours}
        group={SCHEDULE_GROUP}
        currentView={view}
        popupOpen={onPopupOpen}
        popupClose={onPopupClose}
        actionBegin={actionBegin}
        resourceHeaderTemplate={ResourceHeaderTemplate}
        created={onCreated}
        actionComplete={onActionComplete}
        eventRendered={eventRendered}
        quickInfoTemplates={QuickInfoTemplate}
        editorTemplate={EditorTemplate}
        eventSettings={eventSettings}
      >
        <Inject services={INJECT_SERVICES} />
        <ResourcesDirective>
          <ResourceDirective
            field="WhoId"
            title="Workers"
            name="Workers"
            allowMultiple={true}
            dataSource={workersData}
            textField="fullname"
            idField="id"
            colorField="color"
          />
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective option="TimelineDay" />
          <ViewDirective
            firstDayOfWeek={1}
            option="TimelineWeek"
            timeScale={TIME_SCALE}
          />
          <ViewDirective option="TimelineMonth" />
        </ViewsDirective>
      </ScheduleComponent>
    );
  }
);

const getEventTemplate =
  (t: TFunction<"PlanningModal" | "Global">) => (props: any) => {
    return (
      <div className="e-inner-wrap">
        <div className="e-subject">{props.title}</div>
        {props.distance ? (
          <Stack
            alignItems="center"
            justifyContent="space-between"
            minWidth={0}
            width="100%"
            style={ellipsisStyle}
          >
            <div
              className="e-description"
              style={{ minWidth: 0, ...ellipsisStyle }}
            >
              <TextWithBreaks text={props.description} />
            </div>
            <div className="e-description">
              {t("{number} km", {
                ns: "Global",
                number: props.distance.toFixed(1),
              })}
            </div>
          </Stack>
        ) : (
          <div className="e-description">
            <TextWithBreaks text={props.description} />
          </div>
        )}
      </div>
    );
  };

const getResourceHeaderTemplate =
  (
    t: TFunction<"PlanningModal" | "Global">,
    theme: Theme,
    isMinTablet: boolean
  ) =>
  (props: any) => {
    return (
      <Stack
        alignItems="center"
        justifyContent="space-between"
        pr={1}
        style={ellipsisStyle}
        minWidth={0}
      >
        <Stack alignItems="center" style={ellipsisStyle} minWidth={0} flex={1}>
          {isMinTablet && (
            <UserAvatar userAvatar={props.resourceData} size="s" />
          )}
          <Stack
            flexDirection="column"
            style={ellipsisStyle}
            flex={1}
            spacing={0.25}
          >
            <Typography variant="body2" style={ellipsisStyle}>
              {props.resourceData.fullname}
            </Typography>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              spacing={0.5}
            >
              <Typography
                style={ellipsisStyle}
                variant="caption"
                color="textSecondary"
              >
                {props.resourceData.roles}
              </Typography>

              {props.resourceData.distanceToProject === undefined ? (
                <Tooltip
                  title={t("No location defined", {
                    ns: "PlanningModal",
                  })}
                  placement="top"
                >
                  <LocationOffIcon
                    fontSize="small"
                    style={{
                      fontSize: "1rem",
                      color: theme.palette.error.main,
                      position: "relative",
                      top: -1,
                    }}
                  />
                </Tooltip>
              ) : props.resourceData.distanceToProject !== null ? (
                <Tooltip
                  title={getAddressLabel(props.resourceData.homeAddress!) ?? ""}
                  placement="top"
                >
                  <Stack
                    alignItems="center"
                    spacing={0}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    <LocationOnIcon
                      fontSize="small"
                      color="secondary"
                      style={{
                        fontSize: "1rem",
                        position: "relative",
                        top: -1,
                      }}
                    />
                    <Typography variant="caption" color="secondary">
                      {t("{number} km", {
                        ns: "Global",

                        number:
                          +props.resourceData.distanceToProject.toFixed(1),
                      })}
                    </Typography>
                  </Stack>
                </Tooltip>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        {props.resourceData.isPinned && (
          <Tooltip
            title={t("Pinned resource", {
              ns: "PlanningModal",
            })}
            placement="top"
          >
            <Icon color={"secondary"} fontSize="small">
              <FontAwesomeIcon icon={faMapPin} />
            </Icon>
          </Tooltip>
        )}
      </Stack>
    );
  };

const getEditorTemplate =
  (t: TFunction<"PlanningModal" | "Global">) => (props: any) => {
    if (!props || !props.planSession) return <div />;
    return (
      <Stack flexDirection="column">
        <TextField
          id={`edit-event-start-${props.id}`}
          label={t("From", {
            ns: "PlanningModal",
          })}
          type="datetime-local"
          defaultValue={
            props.startTime &&
            moment(props.startTime).toISOString(true).split(".").shift()
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id={`edit-event-end-${props.id}`}
          label={t("Until", {
            ns: "PlanningModal",
          })}
          type="datetime-local"
          defaultValue={
            props.endTime &&
            moment(props.endTime).toISOString(true).split(".").shift()
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Stack alignItems={"center"}>
          <Checkbox
            id={`edit-event-isTentative-${props.id}`}
            defaultChecked={!!props.isTentative}
          />
          <Typography>
            {t("Tentative", {
              ns: "PlanningModal",
            })}
          </Typography>
        </Stack>
      </Stack>
    );
  };

const getQuickInfoTemplate = (
  t: TFunction<"PlanningModal" | "Global">,
  viewerOrganisationId: string
) => ({
  content(props: any) {
    return props.planSession ? (
      <div className="e-event-content e-template">
        <div className="e-subject-wrap">
          <div className="e-date-time">
            <div className="e-date-time-icon e-icons"></div>
            <div className="e-date-time-wrapper e-text-ellipsis">
              <div className="e-date-time-details e-text-ellipsis">
                {props.startTime.toLocaleString()} –{" "}
                {props.endTime.toLocaleString()}
              </div>
            </div>
          </div>
          {props.WhoName ? (
            <div className="e-resource">
              <div className="e-resource-icon e-icons"></div>
              <div className="e-resource-details e-text-ellipsis">
                {props.WhoName}
              </div>
            </div>
          ) : (
            ""
          )}
          {props.location ? (
            <div className="e-location">
              <div className="e-location-icon e-icons"></div>
              <div className="e-location-details e-text-ellipsis">
                <MuiLink
                  target="_blank"
                  rel="noreferrer nofollow"
                  href={getAddressSearchUrl(props.location)}
                >
                  {props.location}
                </MuiLink>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="e-description">
            <Button
              component={Link}
              size="small"
              color="primary"
              rel="noopener noreferrer"
              target="_blank"
              to={`/projects/${props.ProjectId}`}
              style={{ textTransform: "none" }}
              startIcon={<OpenInNewIcon fontSize="small" />}
            >
              {t("Details", {
                ns: "PlanningModal",
              })}
            </Button>
            &nbsp;
            <RestrictedByProjectPermissionWithDebug
              permission="MANAGE_PROJECT"
              project={props.project}
            >
              {viewerOrganisationId ===
                props.project.owningSystemOrganisationId && (
                <Button
                  component={Link}
                  size="small"
                  color="primary"
                  rel="noopener noreferrer"
                  target="_blank"
                  to={`/planning/projects/${props.ProjectId}`}
                  style={{ textTransform: "none" }}
                  startIcon={
                    <Icon fontSize="small">
                      <EditCalendarIcon
                        style={{ height: 18, width: 18, display: "flex" }}
                      />
                    </Icon>
                  }
                >
                  {props.project?.planned
                    ? t("Re-schedule", { ns: "PlanningModal" })
                    : t("Schedule", { ns: "PlanningModal" })}
                </Button>
              )}
            </RestrictedByProjectPermissionWithDebug>
          </div>
        </div>
      </div>
    ) : props.absence ? (
      <div className="e-event-content e-template">
        <div className="e-subject-wrap">
          <div className="e-date-time">
            <div className="e-date-time-icon e-icons"></div>
            <div className="e-date-time-wrapper e-text-ellipsis">
              <div className="e-date-time-details e-text-ellipsis">
                {moment(props.startTime).format("L")} –{" "}
                {moment(props.endTime).format("L")}
              </div>
            </div>
          </div>
          {props.WhoName ? (
            <div className="e-resource">
              <div className="e-resource-icon e-icons"></div>
              <div className="e-resource-details e-text-ellipsis">
                {props.WhoName}
              </div>
            </div>
          ) : (
            ""
          )}
          {props.description ? (
            <div className="e-description">
              <TextWithBreaks text={props.description} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    ) : (
      <div />
    );
  },
});
