/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { QuotePreviewListItemFragmentDoc } from '../QuotePreviewListItem.generated.js';
import { InviteContractee_ProjectFragmentDoc } from '../../projects/useProjectContracteeInvite.generated.js';
import { InviteContracteeField_ProjectFragmentDoc } from '../form-fields/InviteContracteeField.generated.js';
export type PublishQuoteAndSendViaEmailModal_QuoteFragment = { __typename: 'Quote', id: string, title: string, createdAt: any, isBinding: boolean, isPublished: boolean, agreement: Types.Agreement, needsAgreement: boolean, expirationDate?: any | null, contractee: { __typename: 'DocActor', id: string, companyName: string }, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export type PublishQuoteAndSendViaEmailModal_ProjectFragment = { __typename: 'Project', id: string, title: string, canInviteContractee: boolean, viewerPermissions: Array<Types.PermissionName>, crmOrganisation?: { __typename: 'CrmCompany', id: string, members: Array<{ __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string, email: any }> } | null, contracteeInvitations: Array<{ __typename: 'ProjectContracteeInvitation', id: string, contact: { __typename: 'InvitationContact', id: string } }> };

export const PublishQuoteAndSendViaEmailModal_QuoteFragmentDoc = gql`
    fragment PublishQuoteAndSendViaEmailModal_Quote on Quote {
  id
  ...QuotePreviewListItem
}
    ${QuotePreviewListItemFragmentDoc}`;
export const PublishQuoteAndSendViaEmailModal_ProjectFragmentDoc = gql`
    fragment PublishQuoteAndSendViaEmailModal_Project on Project {
  id
  ...InviteContractee_Project
  ...InviteContracteeField_Project
}
    ${InviteContractee_ProjectFragmentDoc}
${InviteContracteeField_ProjectFragmentDoc}`;