import { assertNever } from "@msys/common";
import { LoadingSpinner, Modal } from "@msys/ui";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ProcessState } from "../hooks/useDecisionProcess.js";
import { useDefaultDecisionActions } from "../hooks/useDefaultDecisionActions.js";
import { DecisionModal } from "./DecisionModal.js";

interface Props
  extends Omit<React.ComponentProps<typeof DecisionModal>, "itemId"> {
  processState: ProcessState;
}

export const DecisionWizardModal = ({
  projectId,
  docId,
  expandedItemIds,
  processState,
  decisionActions,
  handleClose,
  canFinalize,
  isOptionHidden,
  ...props
}: Props) => {
  const { t } = useTranslate(["Global", "Decisions"]);

  if (processState.status === "idle") {
    return null;
  } else if (processState.status === "starting") {
    return (
      <Modal handleClose={handleClose}>
        <LoadingSpinner />
      </Modal>
    );
  } else if (processState.status === "finished") {
    return (
      <Modal
        handleClose={handleClose}
        actionButtons={[
          {
            label: t("Close", { ns: "Global" }),
            handleClick: handleClose,
            buttonProps: { variant: "contained" },
          },
        ]}
      >
        <Stack
          spacing={1}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
        >
          <CheckCircleIcon color="success" sx={{ fontSize: 140 }} />
          <Typography variant={"h2"} color="success.main">
            {t("Your answers have been saved!", {
              ns: "Decisions",
            })}
          </Typography>
          <Typography textAlign={"center"}>
            {t(
              "Start the wizard again and you will be able to review any question that hasn't been finalised yet.",
              { ns: "Decisions" }
            )}
          </Typography>
        </Stack>
      </Modal>
    );
  } else if (processState.status === "running") {
    return (
      <DecisionModal
        {...props}
        projectId={projectId}
        docId={docId}
        itemId={processState.itemId}
        canFinalize={canFinalize}
        expandedItemIds={expandedItemIds}
        decisionActions={decisionActions}
        handleClose={handleClose}
        isOptionHidden={isOptionHidden}
        fetching={processState.fetching}
      />
    );
  } else {
    assertNever(processState);
  }
};

export const DecisionWizardModalWithDefaultActions = ({
  projectId,
  docId,
  expandedItemIds,
  refetch,
  ...props
}: Omit<
  React.ComponentProps<typeof DecisionWizardModal>,
  "decisionActions" | "canFinalize"
> &
  Pick<Parameters<typeof useDefaultDecisionActions>[0], "refetch">) => {
  const decisionActions = useDefaultDecisionActions({
    projectId,
    docId,
    canFinalize: true,
    expandedItemIds,
    refetch,
  });

  return (
    <DecisionWizardModal
      projectId={projectId}
      docId={docId}
      expandedItemIds={expandedItemIds}
      decisionActions={decisionActions}
      canFinalize={true}
      refetch={decisionActions.refetch}
      {...props}
    />
  );
};
