/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModifyUserOrganisationMembershipMutationVariables = Types.Exact<{
  input: Types.ModifyOrganisationMembershipInput;
}>;


export type ModifyUserOrganisationMembershipMutation = { modifyOrganisationMembership: { __typename: 'ModifyOrganisationMembershipResult', member: { __typename: 'User', id: string, jobTitle: string, email: any, notices: string, active: boolean, roles: Array<{ __typename: 'OrganisationRole', id: string, label: string, internalName: string }>, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, label: string, internalName: string }>, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } } };


export const ModifyUserOrganisationMembershipDocument = gql`
    mutation ModifyUserOrganisationMembership($input: ModifyOrganisationMembershipInput!) {
  modifyOrganisationMembership(input: $input) {
    member {
      id
      roles {
        id
        label
        internalName
      }
      defaultProjectRoles {
        id
        label
        internalName
      }
      jobTitle
      email
      phones {
        id
        main
        type
        number
      }
      notices
      homeAddress {
        ...AddressDetails__Address
      }
      active
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export type ModifyUserOrganisationMembershipMutationFn = Apollo.MutationFunction<ModifyUserOrganisationMembershipMutation, ModifyUserOrganisationMembershipMutationVariables>;

/**
 * __useModifyUserOrganisationMembershipMutation__
 *
 * To run a mutation, you first call `useModifyUserOrganisationMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyUserOrganisationMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyUserOrganisationMembershipMutation, { data, loading, error }] = useModifyUserOrganisationMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyUserOrganisationMembershipMutation(baseOptions?: Apollo.MutationHookOptions<ModifyUserOrganisationMembershipMutation, ModifyUserOrganisationMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyUserOrganisationMembershipMutation, ModifyUserOrganisationMembershipMutationVariables>(ModifyUserOrganisationMembershipDocument, options);
      }
export type ModifyUserOrganisationMembershipMutationHookResult = ReturnType<typeof useModifyUserOrganisationMembershipMutation>;
export type ModifyUserOrganisationMembershipMutationResult = Apollo.MutationResult<ModifyUserOrganisationMembershipMutation>;
export type ModifyUserOrganisationMembershipMutationOptions = Apollo.BaseMutationOptions<ModifyUserOrganisationMembershipMutation, ModifyUserOrganisationMembershipMutationVariables>;