/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated.js';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated.js';
export type BaseDataTableAddressFragment = { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null };

export type SearchGlobalSuppliersRowFragment = { __typename: 'Company', id: string, title: string, description: string, website: string, isMeister: boolean, email: any, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }> };

export type SearchGlobalManufacturersRowFragment = { __typename: 'Company', id: string, title: string, description: string, website: string, isMeister: boolean, email: any, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }> };

export const BaseDataTableAddressFragmentDoc = gql`
    fragment BaseDataTableAddress on Address {
  ...AddressDetails__Address
}
    ${AddressDetails__AddressFragmentDoc}`;
export const SearchGlobalSuppliersRowFragmentDoc = gql`
    fragment SearchGlobalSuppliersRow on Company {
  id
  title
  description
  website
  isMeister
  contactPerson {
    id
    title
    description
    website
    isMeister
    familyname
    firstname
    fullname
    locale
    skillset
  }
  branchAddress {
    ...BaseDataTableAddress
  }
  billingAddress {
    ...BaseDataTableAddress
  }
  logo {
    ...AttachmentSnapshot
  }
  email
  phones {
    id
    main
    type
    number
  }
}
    ${BaseDataTableAddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;
export const SearchGlobalManufacturersRowFragmentDoc = gql`
    fragment SearchGlobalManufacturersRow on Company {
  id
  title
  description
  website
  isMeister
  contactPerson {
    id
    title
    description
    website
    isMeister
    familyname
    firstname
    fullname
    locale
    skillset
  }
  branchAddress {
    ...BaseDataTableAddress
  }
  billingAddress {
    ...BaseDataTableAddress
  }
  logo {
    ...AttachmentSnapshot
  }
  email
  phones {
    id
    main
    type
    number
  }
}
    ${BaseDataTableAddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;