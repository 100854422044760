import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  CollapseSection,
  DataGrid,
  GridColDef,
  GridRowParams,
  GridTreeDataGroupingCell,
  ListHeader,
  LoadingSpinner as LoadingContainer,
  getFormattedDuration,
  getFormattedPercentage,
  getFormattedPrice,
} from "@msys/ui";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { ArrowRight as ArrowRightIcon } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium.js";
import { useTolgee, useTranslate } from "@tolgee/react";
import { isNil } from "lodash-es";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BillOfMaterialsItemType } from "../../../clients/graphqlTypes.js";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability.js";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton.js";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { BillOfMaterialsListItem } from "../../features/bill-of-materials/BillOfMaterialListItem.js";
import { BillOfMaterialsItemFragment } from "../../features/bill-of-materials/BillOfMaterials.generated.js";
import { BillOfMaterialsItemQuantity } from "../../features/bill-of-materials/BillOfMaterialsItemQuantity.js";
import {
  BillOfMaterialsItemStatusLabel,
  useStatusLabels,
} from "../../features/bill-of-materials/BillOfMaterialsItemStatus.js";
import { useBillOfMaterialsFilters } from "../../features/bill-of-materials/useBillOfMaterialsFilters.js";
import { useColumnLabel } from "../../features/bill-of-materials/useColumnLabel.js";
import { useItemGroups } from "../../features/bill-of-materials/useItemGroups.js";
import { useQuantityUnits } from "../../features/doc-items/useQuantityUnits.js";
import {
  BillOfMaterialsExportButton,
  useForceGridApi,
} from "../../features/purchase-orders/buttons/BillOfMaterialsExportButton.js";
import { PurchaseOrderCreateButton } from "../../features/purchase-orders/buttons/PurchaseOrderCreateButton.js";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore.js";
import { useProjectBillOfMaterialsQuery } from "./ProjectBillOfMaterials.generated.js";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
  tabs: React.ReactNode;
}

export function ProjectBillOfMaterials({
  projectId,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
  tabs,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const apiRef = useGridApiRef();
  const language = useTolgee(["language"]).getLanguage()!;

  const { t } = useTranslate(["Materials", "Global"]);
  const { groups } = useItemGroups();
  const { quantityUnitLabels } = useQuantityUnits();
  const { groupLabels } = useItemGroups();

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Bill of materials", { ns: "Materials" }),
        to: `/projects/${projectId}/materials/bill-of-materials`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "project-bill-of-materials",
      "table",
      "list"
    );

  const {
    filters,
    activeQuickFilter,
    quickFilters,
    OpenFiltersButton,
    FilterChips,
    Search,
    MenuButton,
    QuickFilters,
  } = useBillOfMaterialsFilters(projectId);

  const { statusLabels } = useStatusLabels();
  const { columnLabels } = useColumnLabel();

  const handleRowClick = React.useCallback(
    (item: BillOfMaterialsItemFragment) => {
      navigate({
        pathname: `/projects/${projectId}/materials/bill-of-materials/${item.id}`,
        search: location.search,
      });
    },
    [location.search, navigate, projectId]
  );

  const client = useApolloClient();
  const query = useProjectBillOfMaterialsQuery({
    client,
    variables: {
      projectId,
      ...quickFilters,
      ...filters,
    },
    fetchPolicy: "cache-and-network",
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const items = project?.billOfMaterialsItems ?? [];
  const billOfMaterialsExportSettings =
    query.data?.organisationSettings?.billOfMaterialsExportSettings;

  const columns = React.useMemo(
    (): GridColDef<BillOfMaterialsItemFragment>[] => [
      {
        field: "itemStatus",
        headerName: columnLabels["itemStatus"],
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 1,
        minWidth: 100,
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" && (
            <BillOfMaterialsItemStatusLabel status={row.itemStatus} />
          ),
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group" ? statusLabels[row.itemStatus] : "",
      },
      {
        field: "supplier",
        headerName: columnLabels["supplier"],
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 2,
        minWidth: 200,
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" && row.productSupplier?.title,
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group" ? (row.productSupplier?.title ?? "") : "",
      },
      {
        field: "productArticleNumber",
        headerName: columnLabels["productArticleNumber"],
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 1.5,
        minWidth: 150,
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" && row.productArticleNumber,
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group" ? row.productArticleNumber : "",
      },
      {
        field: "productManufacturerArticleNumber",
        headerName: columnLabels["productManufacturerArticleNumber"],
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 1.5,
        minWidth: 150,
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" && row.productManufacturerArticleNumber,
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group" ? row.productManufacturerArticleNumber : "",
      },
      {
        field: "listPrice",
        headerName: columnLabels["listPrice"],
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 1,
        minWidth: 100,
        align: "right",
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" &&
          (!isNil(row.listPrice)
            ? getFormattedPrice(row.listPrice, language)
            : "–"),
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group"
            ? !isNil(row.listPrice)
              ? row.listPrice
              : ""
            : "",
      },
      {
        field: "netPrice",
        headerName: columnLabels["netPrice"],
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 1,
        minWidth: 100,
        align: "right",
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" &&
          (!isNil(row.netPrice)
            ? getFormattedPrice(row.netPrice, language)
            : "–"),
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group"
            ? !isNil(row.netPrice)
              ? row.netPrice
              : ""
            : "",
      },
      {
        field: "discountValue",
        headerName: columnLabels["discountValue"],
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 0.8,
        minWidth: 80,
        align: "right",
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" &&
          (!isNil(row.discountValue) && row.discountValue > 0
            ? getFormattedPercentage(row.discountValue, language)
            : "–"),
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group"
            ? !isNil(row.discountValue) && row.discountValue > 0
              ? getFormattedPercentage(row.discountValue, language)
              : ""
            : "",
      },
      {
        field: "quantityUnit",
        headerName: columnLabels["quantityUnit"],
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 1,
        minWidth: 100,
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" && quantityUnitLabels[row.quantityUnit],
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group" ? quantityUnitLabels[row.quantityUnit] : "",
      },
      {
        field: "quantityRequiredTotal",
        headerName: columnLabels["quantityRequiredTotal"],
        headerAlign: "right",
        align: "right",
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 1,
        minWidth: 100,
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" && (
            <BillOfMaterialsItemQuantity
              item={row}
              showAlerts={activeQuickFilter === undefined}
            />
          ),
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group" ? row.quantityRequired : "",
      },
      {
        field: "quantityOrdered",
        headerName: columnLabels["quantityOrdered"],
        headerAlign: "right",
        align: "right",
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 1,
        minWidth: 100,
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" && row.quantityOrdered,
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group" ? row.quantityOrdered : "",
      },
      {
        field: "installationTime",
        headerName: columnLabels["installationTime"],
        sortable: false,
        groupable: false,
        hideable: true,
        flex: 1,
        minWidth: 100,
        align: "right",
        renderCell: ({ row, rowNode }) =>
          rowNode.type !== "group" &&
          (!isNil(row.minInstallationTime) &&
          !isNil(row.maxInstallationTime) &&
          (row.minInstallationTime > 0 || row.maxInstallationTime > 0)
            ? row.minInstallationTime === row.maxInstallationTime
              ? `${getFormattedDuration(row.minInstallationTime)}h`
              : `${getFormattedDuration(
                  row.minInstallationTime
                )}h–${getFormattedDuration(row.maxInstallationTime)}h`
            : "–"),
        valueGetter: ({ rowNode, row }) =>
          rowNode.type !== "group"
            ? !isNil(row.minInstallationTime) &&
              !isNil(row.maxInstallationTime) &&
              (row.minInstallationTime > 0 || row.maxInstallationTime > 0)
              ? row.minInstallationTime === row.maxInstallationTime
                ? `${getFormattedDuration(row.minInstallationTime)}h`
                : `${getFormattedDuration(
                    row.minInstallationTime
                  )}h–${getFormattedDuration(row.maxInstallationTime)}h`
              : ""
            : "",
      },
    ],
    [
      quantityUnitLabels,
      activeQuickFilter,
      statusLabels,
      language,
      columnLabels,
    ]
  );

  const { forcedCollectionView, onGridApiRef, withGridApi } =
    useForceGridApi(apiRef);

  const stateStore = useDataGridStateStore("ProjectBillOfMaterials");

  return (
    <Page
      title={t("Bill of materials", { ns: "Materials" })}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      aside={<Outlet />}
    >
      <PageContainer $fullHeight>
        <ListHeader
          hideTitleOnMobile={false}
          title={tabs}
          CreateButton={
            <Stack direction="row" spacing={1} alignItems="center">
              {project && billOfMaterialsExportSettings && (
                <BillOfMaterialsExportButton
                  withGridApi={withGridApi}
                  billOfMaterialsExportSettings={billOfMaterialsExportSettings}
                  projectId={projectId}
                  projectTitle={project.title}
                  filters={{
                    ...quickFilters,
                    ...filters,
                  }}
                />
              )}
              {project && (
                <RestrictedByCapabilityWithDebug capability="ORDERING">
                  <RestrictedByProjectPermissionWithDebug
                    permission="MANAGE_QUOTES"
                    project={project}
                  >
                    <PurchaseOrderCreateButton
                      projectId={projectId}
                      handleOrderCreate={orderId => {
                        navigate(
                          `/projects/${projectId}/materials/purchase-orders/${orderId}/edit`
                        );
                      }}
                    />
                  </RestrictedByProjectPermissionWithDebug>
                </RestrictedByCapabilityWithDebug>
              )}
            </Stack>
          }
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          QuickFilter={<QuickFilters activeQuickFilter={activeQuickFilter} />}
          FilterButton={OpenFiltersButton}
          SearchField={Search}
          FilterChips={FilterChips}
          MenuButton={MenuButton}
          mb={2}
        />
        {query.loading && !query.data ? (
          <LoadingContainer />
        ) : (
          (() => {
            switch (forcedCollectionView ?? activeView) {
              case "list": {
                return (
                  <Stack direction="column" spacing={1}>
                    {groups.map(group => (
                      <CollapseSection key={group.id} title={group.label}>
                        <Stack direction="column" spacing={1}>
                          {items
                            ?.filter(item => item.itemType === group.id)
                            .map(item => (
                              <CardItem
                                key={item.id}
                                onClick={() => handleRowClick(item)}
                              >
                                <BillOfMaterialsListItem item={item} />
                              </CardItem>
                            ))}
                        </Stack>
                      </CollapseSection>
                    ))}
                  </Stack>
                );
              }
              case "table": {
                return (
                  <DataGrid
                    stateStore={stateStore}
                    // @ts-ignore
                    apiRef={(ref: GridApiPremium | null) => {
                      if (ref) apiRef.current = ref;
                      onGridApiRef(ref);
                    }}
                    density="compact"
                    disableColumnFilter
                    hideFooter
                    sortingMode={"client"}
                    pagination={false}
                    loading={query.loading}
                    columns={columns}
                    rows={items}
                    isRowSelectable={(params: GridRowParams) => {
                      return !!params.row.id;
                    }}
                    onRowClick={({ row }) => {
                      if (row.id) {
                        handleRowClick(row as BillOfMaterialsItemFragment);
                      }
                    }}
                    disableRowSelectionOnClick
                    treeData
                    getTreeDataPath={row => [row.itemType, row.id]}
                    groupingColDef={
                      {
                        headerName: columnLabels["productTitle"],
                        flex: 3,
                        minWidth: 200,
                        hideable: false,
                        renderCell: params => {
                          if (params.rowNode.type === "group") {
                            return (
                              <GridTreeDataGroupingCell
                                {...params}
                                rowNode={{
                                  ...params.rowNode,
                                  groupingKey:
                                    groupLabels[
                                      params.rowNode
                                        .groupingKey as BillOfMaterialsItemType
                                    ],
                                }}
                              />
                            );
                          }
                          return (
                            <Box marginLeft={"44px"}>{params.row.title}</Box>
                          );
                        },
                        valueGetter: ({ rowNode, row }) =>
                          rowNode.type !== "group"
                            ? row.title
                            : groupLabels[
                                rowNode.groupingKey as BillOfMaterialsItemType
                              ],
                      } as GridColDef<BillOfMaterialsItemFragment>
                    }
                    isGroupExpandedByDefault={() => true}
                    components={{
                      TreeDataCollapseIcon: ArrowDropDownIcon,
                      TreeDataExpandIcon: ArrowRightIcon,
                    }}
                  />
                );
              }
            }
          })()
        )}
      </PageContainer>
    </Page>
  );
}
