import { gql, useApolloClient } from "@apollo/client";
import { CardContainer, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Phone as PhoneIcon } from "@mui/icons-material";
import { IconButton, List } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { groupBy } from "lodash-es";
import React from "react";
import { Link } from "react-router-dom";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal.js";
import { ProfileListItem } from "../../../commons/ProfileListItem.js";
import { AddContactRelationshipModal } from "../../contact-links/AddContactRelationshipModal.js";
import {
  RelationshipRole,
  useRelationshipRoles,
} from "../../contact-links/useRelationshipRoles.js";
import { OrganisationAvatar } from "../../organisations/OrganisationAvatar.js";
import { UserAvatar } from "../../users/UserAvatar.js";
import {
  BuildingItemLinksFragment,
  useBuildingAddCrmOrganisationLinkMutation,
  useBuildingAddCrmUserLinkMutation,
  useBuildingRemoveCrmOrganisationLinkMutation,
  useBuildingRemoveCrmUserLinkMutation,
} from "./BuildingContactsBox.generated.js";

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

interface Props {
  contacts: BuildingItemLinksFragment[];
  buildingId: string;
  itemId: string;
  canEdit?: boolean;
  refetchQueries?: string[];
}

export const BuildingContactsBox = ({
  contacts,
  buildingId,
  itemId,
  canEdit = false,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate("Buildings");
  const { getRoleLabel } = useRelationshipRoles();

  const client = useApolloClient();
  const [addUserLink] = useBuildingAddCrmUserLinkMutation({
    client,
  });
  const [addOrganisationLink] = useBuildingAddCrmOrganisationLinkMutation({
    client,
  });
  const [removeUserLink] = useBuildingRemoveCrmUserLinkMutation({
    client,
    refetchQueries,
  });
  const [removeOrganisationLink] = useBuildingRemoveCrmOrganisationLinkMutation(
    { client, refetchQueries }
  );

  const removeLink = React.useCallback(
    async (contact: BuildingItemLinksFragment) => {
      if (contact.__typename === "QuoteItemLinkCrmUser") {
        await removeUserLink({
          variables: {
            input: {
              crmUserId: contact.crmUser.id,
              docId: buildingId,
              itemId: itemId,
              linkAs: contact.linkAs,
            },
          },
        });
      } else {
        await removeOrganisationLink({
          variables: {
            input: {
              crmOrganisationId: contact.crmOrganisation.id,
              docId: buildingId,
              itemId: itemId,
              linkAs: contact.linkAs,
            },
          },
        });
      }
    },
    [buildingId, removeOrganisationLink, removeUserLink, itemId]
  );

  const groupedContacts = groupBy(contacts, contact =>
    contact.__typename === "QuoteItemLinkCrmUser"
      ? contact.crmUser.id
      : contact.__typename === "QuoteItemLinkCrmOrganisation"
        ? contact.crmOrganisation.id
        : undefined
  );

  return (
    <CardContainer
      title={t("Contacts")}
      itemCount={contacts.length}
      ActionButton={
        canEdit && (
          <ModalOpenButton
            Modal={AddContactRelationshipModal}
            modalProps={{
              title: t("Add contact to building"),
              existingContactLinks: contacts,
              handleComplete: async (contactType, contactId, role) => {
                if (contactType === "user") {
                  await addUserLink({
                    variables: {
                      input: {
                        docId: buildingId,
                        itemId: itemId,
                        crmUserId: contactId,
                        linkAs: role,
                        data: {},
                      },
                    },
                  });
                } else if (contactType === "organisation") {
                  await addOrganisationLink({
                    variables: {
                      input: {
                        docId: buildingId,
                        itemId: itemId,
                        crmOrganisationId: contactId,
                        linkAs: role,
                        data: {},
                      },
                    },
                  });
                } else {
                  throw new Error("Invalid contact id");
                }
              },
            }}
          >
            <IconButton size="small" color="primary">
              <AddIcon />
            </IconButton>
          </ModalOpenButton>
        )
      }
    >
      <List disablePadding>
        {Object.values(groupedContacts).map(contacts => {
          const contact = contacts[0];
          const primaryLabel: string =
            contact.__typename === "QuoteItemLinkCrmUser"
              ? contact.crmUser.fullname
              : contact.crmOrganisation.title;
          const avatar =
            contact.__typename === "QuoteItemLinkCrmUser" ? (
              <UserAvatar userAvatar={contact.crmUser} />
            ) : (
              <OrganisationAvatar
                organisationAvatar={contact.crmOrganisation}
              />
            );

          const phone =
            contact.__typename === "QuoteItemLinkCrmUser"
              ? contact.crmUser.phones[0]?.number
              : contact.crmOrganisation.phones[0]?.number;

          const actionButtons = [];
          if (phone) {
            actionButtons.push(
              <IconButton
                key="phone"
                color="primary"
                component="a"
                href={`tel:${phone}`}
                size="large"
              >
                <PhoneIcon />
              </IconButton>
            );
          }
          if (canEdit) {
            actionButtons.push(
              <ModalOpenButton
                key="delete"
                Modal={ConfirmModal}
                modalProps={{
                  handleConfirm: () => {
                    contacts.forEach(contact => {
                      removeLink(contact);
                    });
                  },
                }}
              >
                <IconButton color="primary" size="large">
                  <DeleteIcon />
                </IconButton>
              </ModalOpenButton>
            );
          }

          const roles = contacts
            .sort((a, b) => a.linkAs.localeCompare(b.linkAs))
            .map(contact => getRoleLabel(contact.linkAs) ?? contact.linkAs)
            .join(", ");
          const link =
            contact.__typename === "QuoteItemLinkCrmUser"
              ? `/crm/users/${contact.crmUser.id}`
              : `/crm/organisations/${contact.crmOrganisation.id}`;

          return (
            <ProfileListItem
              key={contact.id}
              button
              avatar={avatar}
              primary={primaryLabel}
              secondary={roles}
              ActionButtons={actionButtons}
              component={Link}
              to={link}
            />
          );
        })}
      </List>
    </CardContainer>
  );
};
