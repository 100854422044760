/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { InvoiceListItem_OutgoingInvoiceFragmentDoc, InvoiceListItem_IncomingInvoiceFragmentDoc } from '../../features/invoices/InvoiceListItem.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectOutgoingInvoicesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  limit: Types.Scalars['Int']['input'];
}>;


export type ProjectOutgoingInvoicesQuery = { projectOutgoingInvoices: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'ProjectOutgoingInvoiceConnection', totalCount: number, totalAmountOpen: number, totalAmountOverdue: number, edges: Array<{ __typename: 'OutgoingInvoiceEdge', node: { __typename: 'OutgoingInvoice', id: string, number?: string | null, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, dueDate?: any | null, issueDate?: any | null, createdAt: any, totalNetPrice: number, totalGrossPrice: number, pdfUrl?: string | null, clientName: string } }> }, project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName> } | null } };

export type ProjectOutgoingInvoices_InvoiceFragment = { __typename: 'OutgoingInvoice', id: string, number?: string | null, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, dueDate?: any | null, issueDate?: any | null, createdAt: any, totalNetPrice: number, totalGrossPrice: number, pdfUrl?: string | null };

export type ProjectIncomingInvoicesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  limit: Types.Scalars['Int']['input'];
}>;


export type ProjectIncomingInvoicesQuery = { projectIncomingInvoices: { __typename: 'MissingPermissions' } | { __typename: 'ProjectIncomingInvoiceConnection', totalCount: number, totalAmountOpen: number, totalAmountOverdue: number, edges: Array<{ __typename: 'IncomingInvoiceEdge', node: { __typename: 'IncomingInvoice', id: string, number: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, dueDate: any, issueDate?: any | null, createdAt: any, totalNetPrice: number, totalGrossPrice: number, pdfUrl: string, contractorName: string } }> } };

export type ProjectIncomingInvoices_InvoiceFragment = { __typename: 'IncomingInvoice', id: string, number: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, dueDate: any, issueDate?: any | null, createdAt: any, totalNetPrice: number, totalGrossPrice: number, pdfUrl: string };

export const ProjectOutgoingInvoices_InvoiceFragmentDoc = gql`
    fragment ProjectOutgoingInvoices_Invoice on OutgoingInvoice {
  id
  number
  type
  status
  title
  dueDate
  issueDate
  createdAt
  totalNetPrice
  totalGrossPrice
  pdfUrl
}
    `;
export const ProjectIncomingInvoices_InvoiceFragmentDoc = gql`
    fragment ProjectIncomingInvoices_Invoice on IncomingInvoice {
  id
  number
  type
  status
  title
  dueDate
  issueDate
  createdAt
  totalNetPrice
  totalGrossPrice
  pdfUrl
}
    `;
export const ProjectOutgoingInvoicesDocument = gql`
    query ProjectOutgoingInvoices($projectId: ID!, $limit: Int!) {
  projectOutgoingInvoices(limit: $limit, projectId: $projectId) {
    ... on ProjectOutgoingInvoiceConnection {
      edges {
        node {
          id
          ...ProjectOutgoingInvoices_Invoice
          ...InvoiceListItem_OutgoingInvoice
        }
      }
      totalCount
      totalAmountOpen
      totalAmountOverdue
    }
  }
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        viewerPermissions
      }
    }
  }
}
    ${ProjectOutgoingInvoices_InvoiceFragmentDoc}
${InvoiceListItem_OutgoingInvoiceFragmentDoc}`;

/**
 * __useProjectOutgoingInvoicesQuery__
 *
 * To run a query within a React component, call `useProjectOutgoingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOutgoingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOutgoingInvoicesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProjectOutgoingInvoicesQuery(baseOptions: Apollo.QueryHookOptions<ProjectOutgoingInvoicesQuery, ProjectOutgoingInvoicesQueryVariables> & ({ variables: ProjectOutgoingInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOutgoingInvoicesQuery, ProjectOutgoingInvoicesQueryVariables>(ProjectOutgoingInvoicesDocument, options);
      }
export function useProjectOutgoingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOutgoingInvoicesQuery, ProjectOutgoingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOutgoingInvoicesQuery, ProjectOutgoingInvoicesQueryVariables>(ProjectOutgoingInvoicesDocument, options);
        }
export function useProjectOutgoingInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectOutgoingInvoicesQuery, ProjectOutgoingInvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectOutgoingInvoicesQuery, ProjectOutgoingInvoicesQueryVariables>(ProjectOutgoingInvoicesDocument, options);
        }
export type ProjectOutgoingInvoicesQueryHookResult = ReturnType<typeof useProjectOutgoingInvoicesQuery>;
export type ProjectOutgoingInvoicesLazyQueryHookResult = ReturnType<typeof useProjectOutgoingInvoicesLazyQuery>;
export type ProjectOutgoingInvoicesSuspenseQueryHookResult = ReturnType<typeof useProjectOutgoingInvoicesSuspenseQuery>;
export type ProjectOutgoingInvoicesQueryResult = Apollo.QueryResult<ProjectOutgoingInvoicesQuery, ProjectOutgoingInvoicesQueryVariables>;
export const ProjectIncomingInvoicesDocument = gql`
    query ProjectIncomingInvoices($projectId: ID!, $limit: Int!) {
  projectIncomingInvoices(limit: $limit, projectId: $projectId) {
    ... on ProjectIncomingInvoiceConnection {
      edges {
        node {
          id
          ...ProjectIncomingInvoices_Invoice
          ...InvoiceListItem_IncomingInvoice
        }
      }
      totalCount
      totalAmountOpen
      totalAmountOverdue
    }
  }
}
    ${ProjectIncomingInvoices_InvoiceFragmentDoc}
${InvoiceListItem_IncomingInvoiceFragmentDoc}`;

/**
 * __useProjectIncomingInvoicesQuery__
 *
 * To run a query within a React component, call `useProjectIncomingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectIncomingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectIncomingInvoicesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProjectIncomingInvoicesQuery(baseOptions: Apollo.QueryHookOptions<ProjectIncomingInvoicesQuery, ProjectIncomingInvoicesQueryVariables> & ({ variables: ProjectIncomingInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectIncomingInvoicesQuery, ProjectIncomingInvoicesQueryVariables>(ProjectIncomingInvoicesDocument, options);
      }
export function useProjectIncomingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectIncomingInvoicesQuery, ProjectIncomingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectIncomingInvoicesQuery, ProjectIncomingInvoicesQueryVariables>(ProjectIncomingInvoicesDocument, options);
        }
export function useProjectIncomingInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectIncomingInvoicesQuery, ProjectIncomingInvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectIncomingInvoicesQuery, ProjectIncomingInvoicesQueryVariables>(ProjectIncomingInvoicesDocument, options);
        }
export type ProjectIncomingInvoicesQueryHookResult = ReturnType<typeof useProjectIncomingInvoicesQuery>;
export type ProjectIncomingInvoicesLazyQueryHookResult = ReturnType<typeof useProjectIncomingInvoicesLazyQuery>;
export type ProjectIncomingInvoicesSuspenseQueryHookResult = ReturnType<typeof useProjectIncomingInvoicesSuspenseQuery>;
export type ProjectIncomingInvoicesQueryResult = Apollo.QueryResult<ProjectIncomingInvoicesQuery, ProjectIncomingInvoicesQueryVariables>;