import { useApolloClient } from "@apollo/client";
import { getFiles } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Box, Container, Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useParams } from "react-router-dom";
import { BookmarkButton } from "../../commons/button/BookmarkButton.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageSectionHeader } from "../../commons/layout/PageSectionHeader.js";
import { Stack } from "../../commons/layout/Stack.js";
import { FilesBoxTable } from "../../features/attachments/FilesBoxTable.js";
import { Attachment } from "../../features/attachments/helpers.js";
import { ShopCartBox } from "../../features/shop/boxes/ShopCartBox.js";
import { ShopHeaderBox } from "../../features/shop/boxes/ShopHeaderBox.js";
import { useShopSelectedCart } from "../../features/shop/hooks/useShopCart.js";
import { TemplatesQuotePreviewItems } from "../../features/templates/quote/TemplateQuotePreviewItems.js";
import { useShopTemplatesBookmark } from "../../features/templates/quote/useShopTemplatesBookmark.js";
import { useExpandedStoreWithLocalStorage } from "../../trees-virtual/hooks/useExpandedStore.js";
import { useShopPreviewQuery } from "./ShopPreview.generated.js";

interface Props {
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
}

export const ShopPreview = ({ submenuItems, prefixBreadcrumbs }: Props) => {
  const { docId } = useParams();
  if (!docId) throw new Error("Document id is missing");
  const { isMinDesktop, isMaxPhone } = useScreenWidth();
  const [selectedCart, setSelectedCartId, refetchSelectedCart] =
    useShopSelectedCart();
  const { t } = useTranslate(["Shop", "Global"]);

  const client = useApolloClient();
  const query = useShopPreviewQuery({
    client,
    variables: {
      id: docId,
    },
  });

  const doc = query.data?.viewer?.organisation.shoppableTemplate;

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote-template",
    docId
  );
  const { handleBookmarkClick } = useShopTemplatesBookmark();

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: doc?.title ?? "",
        to: `/shop/${docId}`,
      },
    ],
    [doc?.title, docId, prefixBreadcrumbs]
  );

  const attachments: Attachment[] = getFiles(doc?.attachments ?? []);
  const docItems = doc?.items ?? [];

  return (
    <Page
      subtitle={t("Shop", { ns: "Shop" })}
      title={doc?.title ?? ""}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
    >
      <PageContainer>
        {doc && (
          <Container maxWidth="xl">
            <Grid container spacing={isMaxPhone ? 1 : 2}>
              <Grid item xs={12} lg={8} xl={9}>
                <Stack flexDirection="column" spacing={isMaxPhone ? 1 : 2}>
                  <PageSectionHeader
                    title={doc.title}
                    ActionButton={
                      <BookmarkButton
                        isBookmarked={doc.isLikedByMe}
                        handleClick={() =>
                          handleBookmarkClick(docId, doc.isLikedByMe)
                        }
                      />
                    }
                  />
                  <ShopHeaderBox docId={docId} />
                  {!isMinDesktop && (
                    <ShopCartBox
                      title={doc.title}
                      price={doc.proposedCalculation?.priceTotal ?? 0}
                      docId={docId}
                      setCartId={setSelectedCartId}
                      selectedCart={selectedCart}
                      refetchCart={refetchSelectedCart}
                    />
                  )}

                  <TemplatesQuotePreviewItems
                    docId={docId}
                    canEdit={false}
                    allDocItems={docItems}
                    contractType={doc.contractType}
                    isBinding={doc.isBinding}
                    expandedStore={expandedStore}
                  />

                  {!isMinDesktop && (
                    <Box>
                      <FilesBoxTable
                        title={t("Attachments", { ns: "Global" })}
                        attachments={attachments}
                        canEdit={false}
                      />
                    </Box>
                  )}
                </Stack>
              </Grid>
              {isMinDesktop && (
                <Grid item xs={12} lg={4} xl={3}>
                  <Stack
                    flexDirection="column"
                    spacing={isMaxPhone ? 1 : 2}
                    position="sticky"
                    top={0}
                    zIndex={10}
                  >
                    <Box>
                      <ShopCartBox
                        title={doc.title}
                        price={doc.proposedCalculation?.priceTotal ?? 0}
                        docId={docId}
                        setCartId={setSelectedCartId}
                        selectedCart={selectedCart}
                        refetchCart={refetchSelectedCart}
                      />
                    </Box>
                    <Box>
                      <FilesBoxTable
                        title={t("Attachments", {
                          ns: "Global",
                        })}
                        attachments={attachments}
                        canEdit={false}
                      />
                    </Box>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Container>
        )}
      </PageContainer>
    </Page>
  );
};
