import { StyledComponent } from "@emotion/styled";
import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import React from "react";
import SortableTree from "react-sortable-tree";
import { size } from "../../../common/MuiThemeProvider.js";
import { ARROW_COLOR, LINE_COLOR } from "../helpers.js";

export const StyledSortableTree: StyledComponent<
  React.ComponentProps<typeof SortableTree> & MUIStyledCommonProps<Theme>
> = styled(SortableTree)`
  &.rst__tree {
    flex-grow: 1;
    min-height: 0;
  }

  /**
   * Extra class applied to VirtualScroll through className prop
   */
  .rst__virtualScrollOverride {
    overflow: visible !important;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  .ReactVirtualized__Grid {
    outline: none;
  }

  .rst__node {
    min-width: 100%;
    white-space: nowrap;
    text-align: left;
    position: relative;
  }

  .rst__nodeContent {
    box-sizing: border-box;
    padding: ${size.treeSpacing / 2}px 0 ${size.treeSpacing / 2}px 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  /* ==========================================================================
     Scaffold
  
      Line-overlaid blocks used for showing the tree structure
     ========================================================================== */
  .rst__lineBlock,
  .rst__absoluteLineBlock {
    height: 100%;
    position: relative;
    display: inline-block;
  }

  .rst__absoluteLineBlock {
    position: absolute;
    top: 0;
  }

  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    position: absolute;
    content: "";
    background-color: ${LINE_COLOR};
  }

  /**
   * +-----+
   * |     |
   * |  +--+
   * |     |
   * +-----+
   */
  .rst__lineHalfHorizontalRight::before {
    height: 1px;
    top: calc(50%);
    right: 0;
    width: 50%;
  }

  /**
   * +--+--+
   * |  |  |
   * |  |  |
   * |  |  |
   * +--+--+
   */
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    width: 1px;
    left: 50%;
    height: 100%;
    top: -${size.treeSpacing / 2}px;
  }

  /**
   * +-----+
   * |  |  |
   * |  +  |
   * |     |
   * +-----+
   */
  .rst__lineHalfVerticalTop::after {
    height: calc(50% + ${size.treeSpacing / 2}px);
  }

  /**
   * +-----+
   * |     |
   * |  +  |
   * |  |  |
   * +-----+
   */
  .rst__lineHalfVerticalBottom::after {
    top: auto;
    bottom: 0;
    height: 50%;
  }

  /* Highlight line for pointing to dragged row destination
     ========================================================================== */
  /**
   * +--+--+
   * |  |  |
   * |  |  |
   * |  |  |
   * +--+--+
   */
  .rst__highlightLineVertical {
    z-index: 3;
  }
  .rst__highlightLineVertical::before {
    position: absolute;
    content: "";
    background-color: ${ARROW_COLOR};
    width: 4px;
    margin-left: -2px;
    left: 50%;
    top: 0;
    height: 100%;
  }

  /**
   * +-----+
   * |     |
   * |  +--+
   * |  |  |
   * +--+--+
   */
  .rst__highlightTopLeftCorner::before {
    z-index: 3;
    content: "";
    position: absolute;
    border-top: solid 4px ${ARROW_COLOR};
    border-left: solid 4px ${ARROW_COLOR};
    box-sizing: border-box;
    height: calc(50% + 2px);
    top: calc(50% + 2px);
    margin-top: -2px;
    right: 0;
    width: calc(50% + 2px);
  }

  /**
   * +--+--+
   * |  |  |
   * |  |  |
   * |  +->|
   * +-----+
   */
  .rst__highlightBottomLeftCorner {
    z-index: 3;
  }
  .rst__highlightBottomLeftCorner::before {
    content: "";
    position: absolute;
    border-bottom: solid 4px ${ARROW_COLOR};
    border-left: solid 4px ${ARROW_COLOR};
    box-sizing: border-box;
    height: calc(100% + 2px);
    top: 0;
    right: 6px;
    width: calc(50% - 4px);
  }

  .rst__highlightBottomLeftCorner::after {
    content: "";
    position: absolute;
    height: 0;
    right: 0;
    top: 100%;
    margin-top: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${ARROW_COLOR};
  }
`;
