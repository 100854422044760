import React from "react";
import { Capabilities } from "../../clients/graphqlTypes.js";
import { Restricted, RestrictedWithDebug } from "./Restricted.js";
import { useUserData } from "./useUserData.js";

interface Props
  extends Omit<React.ComponentProps<typeof Restricted>, "isRestricted"> {
  capability: Capabilities;
}

export const RestrictedByCapability = ({ capability, ...props }: Props) => {
  const { hasCapability } = useUserData();

  const isCapable = hasCapability(capability);

  return <Restricted isRestricted={!isCapable} {...props} />;
};

export const RestrictedByCapabilityWithDebug = ({
  capability,
  children,
  ...props
}: Props) => {
  const { hasCapability } = useUserData();

  const isCapable = hasCapability(capability);

  return (
    <RestrictedWithDebug
      {...props}
      isRestricted={!isCapable}
      debugLabel={`Capability: ${capability}`}
      debugColor={"#FFA500"}
    >
      {children}
    </RestrictedWithDebug>
  );
};
