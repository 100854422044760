import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import { AutocompleteField } from "../../commons/form-fields/AutocompleteField.js";

interface FormValues {
  reasonCode: string | null;
}

interface Props {
  availableReasons: { value: string; label: string }[];
  defaultReason: string | null;
  isReasonRequired: boolean;
  handleClose: () => void;
  handleComplete: (reasonCode: string | null) => Promise<void> | void;
}

export const S4HanaCancelReasonSelectModal = ({
  availableReasons,
  defaultReason,
  isReasonRequired,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const initialValues: FormValues = {
    reasonCode: defaultReason,
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        reasonCode: Yup.string()
          .label(t("Cancel reason", { ns: "SapS4Hana" }))
          .when([], {
            is: () => isReasonRequired,
            then: schema => schema.required(),
          })
          .nullable(),
      }),
    [isReasonRequired, t]
  );

  const handleSubmit = async (values: FormValues) => {
    if (isReasonRequired && !values.reasonCode) return;
    await handleComplete(values.reasonCode);
    handleClose();
  };

  const availableReasonLabels = React.useMemo(() => {
    return Object.fromEntries(availableReasons.map(r => [r.value, r.label]));
  }, [availableReasons]);

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
    >
      {formikProps => (
        <Modal
          title={t("Select cancel reason", { ns: "SapS4Hana" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Submit", { ns: "Global" }),
              buttonProps: {
                loading: formikProps.isSubmitting,
                form: formId,
                type: "submit",
                disabled: !formikProps.isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <AutocompleteField
                name="reasonCode"
                inputLabel={t("Cancel reason", { ns: "SapS4Hana" })}
                options={availableReasons.map(r => r.value)}
                getOptionLabel={value => {
                  const reasonLabel = availableReasonLabels[value];
                  return reasonLabel ? `${value} - ${reasonLabel}` : value;
                }}
                isRequired={isReasonRequired}
                disableClearable={isReasonRequired}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
