/* eslint-disable react-hooks/rules-of-hooks */
import React, { Ref, useImperativeHandle } from "react";
import { ConfirmModal } from "./ConfirmModal.js";
import { once } from "lodash-es";

export interface ConfirmProcessRef {
  startConfirmProcess: (
    props: ConfirmModalProps
  ) => Promise<boolean | undefined>;
}

export interface ConfirmModalProps
  extends Omit<React.ComponentProps<typeof ConfirmModal>, "handleClose"> {}

const _ConfirmProcess = (_: {}, forwardedRef: Ref<ConfirmProcessRef>) => {
  const [props, setProps] = React.useState<ConfirmModalProps | null>(null);

  const resolveRef = React.useRef<
    ((value: boolean | undefined) => void) | null
  >(null);

  useImperativeHandle(forwardedRef, () => ({
    startConfirmProcess: (props: ConfirmModalProps) =>
      new Promise<boolean | undefined>(resolve => {
        resolveRef.current = once(resolve);
        setProps(props);
      }),
  }));

  return props ? (
    <ConfirmModal
      {...props}
      handleClose={() => {
        setProps(null);
        resolveRef.current?.(undefined);
      }}
      handleCancel={() => {
        resolveRef.current?.(false);
      }}
      handleConfirm={(...args) => {
        props.handleConfirm?.(...args);
        resolveRef.current?.(true);
      }}
    />
  ) : null;
};

export const ConfirmProcess = React.memo(React.forwardRef(_ConfirmProcess));
