import { Box, BoxProps, Chip, ChipProps } from "@mui/material";
import React from "react";
import { PimPropertyTypeFragment } from "./Product.generated.js";

interface Props {
  props: PimPropertyTypeFragment[];
  wrap?: boolean;
  boxProps?: BoxProps;
  highlightedKeys?: string[];
}

export const ProductPropertyTypeChips = ({
  props,
  wrap = true,
  boxProps,
  highlightedKeys,
}: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="baseline"
      flexWrap={wrap ? "wrap" : "nowrap"}
      sx={{ ml: -0.5, mt: -0.25 }}
      {...boxProps}
    >
      {props.map(prop => (
        <ProductPropertyTypeChip
          key={prop.key}
          prop={prop}
          sx={{ ml: 0.5, mt: 0.25 }}
          clickable={false}
          variant={highlightedKeys?.includes(prop.key) ? "filled" : "outlined"}
        />
      ))}
    </Box>
  );
};

function ProductPropertyTypeChip({
  prop,
  ...chipProps
}: { prop: PimPropertyTypeFragment } & ChipProps) {
  return (
    <Chip
      size={"small"}
      variant="outlined"
      color="primary"
      {...chipProps}
      label={`${prop.label}${prop.unit ? ` (${prop.unit})` : ""}`}
    />
  );
}
