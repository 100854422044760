import { gql, useApolloClient } from "@apollo/client";
import { FileInput } from "../../commons/inputs/FileInput.js";
import { useRequestImportFromJsonUrlMutation } from "./ImportFromJsonUploader.generated.js";

export interface UploadFile {
  filename: string;
}

export const DocUploader = (props: {
  innerRef: any;
  onUploadFile: (uploadFile: UploadFile) => void;
  onCancel?: () => void;
  onStart?: () => void;
  onError?: (e: any) => void;
}) => {
  const client = useApolloClient();

  const [requestImportFromJsonUrlMutation] =
    useRequestImportFromJsonUrlMutation({
      client,
    });

  return (
    <FileInput
      innerRef={props.innerRef}
      accept={".json,.JSON"}
      multiple={false}
      onStart={props.onStart}
      onCancel={props.onCancel}
      onComplete={async file => {
        let docImportUrlResult = await requestImportFromJsonUrlMutation({
          variables: {
            input: {
              filename: file.name,
            },
          },
        });

        try {
          await fetch(
            docImportUrlResult.data!.requestImportFromJsonUrl.uploadUrl,
            { method: "PUT", body: file }
          );
          props.onUploadFile({ filename: file.name });
        } catch (e) {
          props.onError?.(e);
          throw e;
        } finally {
          if (props.innerRef.current) props.innerRef.current.value = "";
        }
      }}
    />
  );
};
