import { Select } from "@msys/ui";
import { isEqual } from "lodash-es";
import React from "react";
import {
  OrganisationQuoteTemplatesSortBy,
  OrganisationQuoteTemplatesSorting,
  SortDirection,
} from "../../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

const ALL_QUOTE_TEMPLATES_SORTING_FIELDS: OrganisationQuoteTemplatesSortBy[] = [
  "createdAt",
  "title",
  "totalPrice",
  "discountedPrice",
];

interface Props {
  defaultSorting: OrganisationQuoteTemplatesSorting[];
  sorting: OrganisationQuoteTemplatesSorting;
  setSorting: (value: OrganisationQuoteTemplatesSorting[]) => void;
}

export const QuoteTemplatesSorting = ({
  defaultSorting,
  sorting,
  setSorting,
}: Props) => {
  const { t } = useTranslate(["QuoteTemplatesSorting", "Global"]);

  const sortByOptions = React.useMemo(() => {
    const quoteTemplatesSortingLabels: Record<
      OrganisationQuoteTemplatesSortBy,
      Record<SortDirection, string>
    > = {
      createdAt: {
        asc: t("Created at asc", {
          ns: "QuoteTemplatesSorting",
        }),
        desc: t("Created at desc", {
          ns: "QuoteTemplatesSorting",
        }),
      },
      title: {
        asc: t("Title asc", {
          ns: "QuoteTemplatesSorting",
        }),
        desc: t("Title desc", {
          ns: "QuoteTemplatesSorting",
        }),
      },
      totalPrice: {
        asc: t("Total price asc", {
          ns: "QuoteTemplatesSorting",
        }),
        desc: t("Total price desc", {
          ns: "QuoteTemplatesSorting",
        }),
      },
      discountedPrice: {
        asc: t("Discounted sub total price asc", {
          ns: "QuoteTemplatesSorting",
        }),
        desc: t("Discounted sub total price desc", {
          ns: "QuoteTemplatesSorting",
        }),
      },
    };

    return ALL_QUOTE_TEMPLATES_SORTING_FIELDS.flatMap(value => [
      {
        label: quoteTemplatesSortingLabels[value]["asc"],
        value: { column: value, direction: "asc" as SortDirection },
      },
      {
        label: quoteTemplatesSortingLabels[value]["desc"],
        value: { column: value, direction: "desc" as SortDirection },
      },
    ]);
  }, [t]);

  return (
    <Select
      label={t("Sort by", {
        ns: "Global",
      })}
      options={sortByOptions}
      value={
        sortByOptions.find(option => isEqual(option.value, sorting))?.value
      }
      onChange={value => {
        setSorting(value ? [value] : defaultSorting);
      }}
    />
  );
};
