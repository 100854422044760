import { useApolloClient } from "@apollo/client";
import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import uploadcare from "uploadcare-widget";
import { dataURLtoFile } from "../../attachments/helpers.js";
import { useAcceptQuoteMutation } from "../Quotes.generated.js";
import { AcceptQuoteModal } from "../modals/AcceptQuoteModal.js";

interface Props {
  docId: string;
  projectId: string;
  handleComplete?: () => Promise<unknown> | unknown;
}

export const AcceptQuoteButton = ({
  docId,
  projectId,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["Quote", "Global"]);
  const { isMaxPhone } = useScreenWidth();

  const client = useApolloClient();
  const [acceptQuote] = useAcceptQuoteMutation({ client });

  return (
    <ModalOpenButton
      Modal={AcceptQuoteModal}
      modalProps={{
        docId: docId,
        projectId: projectId,
        handleComplete: async ({ place, date, signature }) => {
          let fileToUpload = dataURLtoFile(signature, "signature");
          let upload = uploadcare.fileFrom("object", fileToUpload);

          await new Promise<void>(resolve => {
            upload.done(async (fileInfo: any) => {
              await acceptQuote({
                variables: {
                  input: {
                    projectId,
                    docId,
                    signature: {
                      place: place,
                      date: date.format("YYYY-MM-DD"),
                      signature: {
                        url: fileInfo.cdnUrl,
                        title: fileInfo.name,
                        mimeType: fileInfo.mimeType,
                      },
                    },
                  },
                },
              });

              resolve();
            });
          });

          await handleComplete?.();
        },
      }}
    >
      <Button color="primary" variant="contained">
        {isMaxPhone
          ? t("Accept", { ns: "Global" })
          : t("Accept Quote", { ns: "Quote" })}
      </Button>
    </ModalOpenButton>
  );
};
