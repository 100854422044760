import { CollapseSection, Modal } from "@msys/ui";
import { DialogContentText, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useDocumentReplacements } from "../../quotes/modals/QuoteTextsAvailableReplacementsModal.js";
import { AvailableReplacementsTable } from "../../documents/AvailableReplacementsTable.js";

export function AvailableContractingPdfReplacementsModal({
  handleClose,
}: {
  handleClose(): void;
}) {
  const { t } = useTranslate(["Global", "OrganisationSettings"]);

  const {
    projectReplacements,
    documentReplacements,
    calculationReplacements,
    actorsReplacements,
  } = useDocumentReplacements();

  return (
    <Modal
      title={t("Using placeholders in texts", {
        ns: "OrganisationSettings",
      })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Close", {
            ns: "Global",
          }),
          handleClick: handleClose,
        },
      ]}
      maxWidth="md"
    >
      <DialogContentText>
        {t(
          "The following placeholders are available for use in every text replacements are listed below:",
          {
            ns: "OrganisationSettings",
          }
        )}
      </DialogContentText>

      <Stack direction="column" spacing={2} pt={2}>
        <CollapseSection
          title={t("Project", { ns: "OrganisationSettings" })}
          isInitiallyExpanded={false}
        >
          <AvailableReplacementsTable replacements={projectReplacements} />
        </CollapseSection>
        <CollapseSection
          title={t("Document", { ns: "OrganisationSettings" })}
          isInitiallyExpanded={false}
        >
          <AvailableReplacementsTable replacements={documentReplacements} />
        </CollapseSection>
        {actorsReplacements.map(({ field, label, replacements }) => (
          <CollapseSection
            key={field}
            title={label}
            isInitiallyExpanded={false}
          >
            <AvailableReplacementsTable replacements={replacements} />
          </CollapseSection>
        ))}
        <CollapseSection
          title={t("Calculation", { ns: "OrganisationSettings" })}
          isInitiallyExpanded={false}
        >
          <AvailableReplacementsTable replacements={calculationReplacements} />
        </CollapseSection>
      </Stack>
    </Modal>
  );
}
