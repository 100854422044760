import { AccountTree as AccountTreeIcon } from "@mui/icons-material";
import { Calculate as CalculateIcon } from "@mui/icons-material";
import { PreviewOutlined as PreviewOutlinedIcon } from "@mui/icons-material";
import { ViewInAr as ViewInArIcon } from "@mui/icons-material";
import {
  SvgIconProps,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";

export type LayoutView = "tree" | "calculation" | "preview" | "3d";

type ViewData = { icon: React.ComponentType<SvgIconProps>; label: string };

interface Props {
  activeView: LayoutView | null;
  setActiveView: (view: LayoutView) => void;
  disabled?: boolean;
}

export const ToggleLayoutViewButtonGroup = ({
  activeView,
  setActiveView,
  disabled,
  children,
}: React.PropsWithChildren<Props>) => {
  if (!children) return null;

  return (
    <ToggleButtonGroup
      disabled={disabled}
      exclusive
      size="extra-small"
      value={activeView}
      onChange={async (e, value) => {
        if (!value) return;
        setActiveView(value as LayoutView);
      }}
    >
      {children}
    </ToggleButtonGroup>
  );
};

export const ToggleLayoutViewButton = ({
  viewType,
  ...props
}: {
  viewType: LayoutView;
}) => {
  const { t } = useTranslate("Global");

  const VIEWS: Record<LayoutView, ViewData> = {
    tree: {
      icon: AccountTreeIcon,
      label: t("Standard view"),
    },
    calculation: {
      icon: CalculateIcon,
      label: t("Calculation view"),
    },
    preview: {
      icon: PreviewOutlinedIcon,
      label: t("Preview"),
    },
    "3d": {
      icon: ViewInArIcon,
      label: t("3D view"),
    },
  };

  const { label, icon: Icon } = VIEWS[viewType];

  return (
    <ToggleButton key={viewType} value={viewType} {...props}>
      <Tooltip title={label}>
        <Icon fontSize="small" />
      </Tooltip>
    </ToggleButton>
  );
};
