/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutationVariables = Types.Exact<{
  input: Types.ApplyQuoteItemActionsInput;
}>;


export type QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation = { applyQuoteItemActions: { __typename: 'ApplyQuoteItemActionsResult', quote: { __typename: 'Quote', id: string } } };


export const QuoteItemCompletionWizardModal_ApplyQuoteItemActionsDocument = gql`
    mutation QuoteItemCompletionWizardModal_applyQuoteItemActions($input: ApplyQuoteItemActionsInput!) {
  applyQuoteItemActions(input: $input) {
    quote {
      id
    }
  }
}
    `;
export type QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutationFn = Apollo.MutationFunction<QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation, QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutationVariables>;

/**
 * __useQuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation__
 *
 * To run a mutation, you first call `useQuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteItemCompletionWizardModalApplyQuoteItemActionsMutation, { data, loading, error }] = useQuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation(baseOptions?: Apollo.MutationHookOptions<QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation, QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation, QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutationVariables>(QuoteItemCompletionWizardModal_ApplyQuoteItemActionsDocument, options);
      }
export type QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutationHookResult = ReturnType<typeof useQuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation>;
export type QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutationResult = Apollo.MutationResult<QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation>;
export type QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutationOptions = Apollo.BaseMutationOptions<QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutation, QuoteItemCompletionWizardModal_ApplyQuoteItemActionsMutationVariables>;