import { Photo } from "@capacitor/camera";
import {
  useElementSize,
  useIconButtonDarkStyles,
  useModalStack,
} from "@msys/ui";
import { Check as CheckIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { History as HistoryIcon } from "@mui/icons-material";
import { Publish as PublishIcon } from "@mui/icons-material";
import { CircularProgress, Dialog, Fab, IconButton } from "@mui/material";
import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { color, px } from "../../../common/MuiThemeProvider.js";
import { useTranslate } from "@tolgee/react";
import { makeStyles, withStyles } from "../../styles.js";

const COLORS = [
  { color: "red", name: "Red" },
  { color: "blue", name: "Blue" },
  { color: "green", name: "Green" },
  { color: "yellow", name: "Yellow" },
  { color: "magenta", name: "Magenta" },
];

const QUALITY = 0.8;

const useStyles = makeStyles()(theme => ({
  imageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& > img": {
      width: "auto",
      height: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
    },
  },
  signatureContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  closeButtonContainer: {
    position: "absolute",
    top: "calc(env(safe-area-inset-top) + 8px)",
    right: 8,
    zIndex: 20,
  },
  submitButtonContainer: {
    position: "absolute",
    bottom: "calc(env(safe-area-inset-bottom) + 8px)",
    right: 8,
    zIndex: 22,
  },
  actionButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    position: "absolute",
    top: "calc(env(safe-area-inset-top) + 8px)",
    left: 8,
    zIndex: 21,
    "& > *": {
      marginBottom: 12,
    },
  },
  colorContainer: {
    borderRadius: "50%",
    width: 24,
    height: 24,
    flexShrink: 0,
    flexGrow: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledFab = withStyles(Fab, theme => ({
  root: {
    "&.Mui-disabled": {
      color: theme.palette.grey[600] + " !important",
      backgroundColor: theme.palette.grey[400] + " !important",
    },
  },
}));

export const getImage = (dataUrl: string): Promise<HTMLImageElement> =>
  new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = dataUrl;
  });

export const combineImages = (
  width: number,
  height: number,
  backgroundDataUrl?: string,
  foregroundDataUrl?: string
): Promise<File> =>
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        if (!ctx) return reject(new Error(`cannot create canvas context`));

        if (backgroundDataUrl) {
          const photoImg: HTMLImageElement = await getImage(backgroundDataUrl);
          ctx.drawImage(photoImg, 0, 0, canvas.width, canvas.height);
        }
        if (foregroundDataUrl) {
          const signatureImg: HTMLImageElement =
            await getImage(foregroundDataUrl);
          ctx.drawImage(signatureImg, 0, 0, canvas.width, canvas.height);
        }

        canvas.toBlob(
          blob => {
            const file = new File(
              [blob!],
              `photo-${new Date().toISOString()}.jpg`,
              {
                type: "image/jpeg",
                lastModified: Date.now(),
              }
            );
            resolve(file);
          },
          "image/jpeg",
          QUALITY
        );
      } catch (e) {
        reject(e);
      }
    })();
  });

interface Props {
  photo: Photo;
  handleClose: () => void;
  handleComplete: (file: File) => void | Promise<void>;
  isLoading: boolean;
}

/**
 * Renders editor modal for provided photo
 */
export const PhotoEditorModal = ({
  photo,
  handleClose,
  handleComplete,
  isLoading,
}: Props) => {
  const { t } = useTranslate("Global");

  const [selectedColor, setSelectedColor] = useState<string>(COLORS[0].color);
  const { classes } = useStyles();
  const { classes: classesIconButtonDark } = useIconButtonDarkStyles();
  const { isTop } = useModalStack();
  const [imgRect, setImgRect] = useState<DOMRect | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const signatureRef = useRef(null as any);
  const signatureHistory = useRef<any[]>([]);

  const imgSize = useElementSize(imgRef);

  const finalizePhoto = async () => {
    // disable drawing on a canvas
    signatureRef.current?.off();

    const width = imgRef.current?.naturalWidth ?? 0;
    const height = imgRef.current?.naturalHeight ?? 0;

    const file = await combineImages(
      width,
      height,
      photo.dataUrl,
      signatureRef.current.getCanvas().toDataURL("image/png")
    );

    handleComplete(file);
  };

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      fullScreen={true}
      onClick={e => e.stopPropagation()}
      style={{ display: isTop ? "block" : "none" }}
      PaperProps={{
        variant: "elevation",
        elevation: 0,
        sx: { background: theme => theme.palette.common.black },
      }}
    >
      <div className={classes.imageContainer}>
        <img
          ref={imgRef}
          alt="Photo"
          src={photo.dataUrl}
          onLoad={() => {
            setImgRect(imgRef.current?.getBoundingClientRect() ?? null);
          }}
        />
        {(imgRect?.width || imgSize.width) &&
        (imgRect?.height || imgSize.height) ? (
          <div className={classes.signatureContainer}>
            <SignatureCanvas
              ref={signatureRef}
              penColor={selectedColor}
              velocityFilterWeight={0.3}
              canvasProps={{
                width: imgRect?.width || imgSize.width,
                height: imgRect?.height || imgSize.height,
              }}
              onEnd={() => {
                signatureHistory.current = signatureRef.current?.toData() ?? [];
              }}
            />
          </div>
        ) : null}
      </div>
      {handleClose && (
        <div className={classes.closeButtonContainer}>
          <IconButton
            classes={classesIconButtonDark}
            style={{ width: 40, height: 40 }}
            aria-label="Close"
            color="default"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <div className={classes.actionButtonsContainer}>
        <IconButton
          classes={classesIconButtonDark}
          style={{ width: 40, height: 40 }}
          aria-label="Undo"
          color="default"
          onClick={() => {
            signatureRef.current?.clear();
            signatureHistory.current.pop();
            if (signatureHistory.current.length > 0) {
              const data = signatureHistory.current;
              if (data && data.length > 0) signatureRef.current?.fromData(data);

              // need to re-select color as well
              // (due to a bug in signature canvas - it uses last color from history)
              setSelectedColor("");
              setTimeout(() => setSelectedColor(selectedColor));
            }
          }}
          size="large"
        >
          <HistoryIcon />
        </IconButton>
        {COLORS.map(c => (
          <IconButton
            key={c.color}
            classes={{ root: classes.colorContainer }}
            style={
              selectedColor === c.color
                ? { boxShadow: `0 0 0 3px ${color.white}` }
                : undefined
            }
            aria-label={c.name}
            color="default"
            onClick={() => setSelectedColor(c.color)}
            size="large"
          >
            <div
              className={classes.colorContainer}
              style={{ backgroundColor: c.color }}
            >
              {selectedColor === c.color && (
                <CheckIcon style={{ fontSize: "16px", color: color.white }} />
              )}
            </div>
          </IconButton>
        ))}
      </div>
      <div className={classes.submitButtonContainer}>
        <StyledFab
          color="primary"
          variant="extended"
          size="medium"
          onClick={finalizePhoto}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={14} style={{ marginRight: px.xs }} />
          ) : (
            <PublishIcon />
          )}
          {isLoading ? t("Uploading") + "…" : t("Upload")}
        </StyledFab>
      </div>
    </Dialog>
  );
};
