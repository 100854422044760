import { useApolloClient } from "@apollo/client";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import {
  PageSubHeader,
  PageSubHeaderInjectedProps,
} from "../../commons/layout/PageSubHeader.js";
import { useHeaderBox_ModifyItemInfoMutation } from "../doc-items/boxes/HeaderBox.generated.js";
import { BuildingMenu } from "./BuildingMenu.js";
import { BuildingSubHeader_BuildingFragment } from "./BuildingSubHeader.generated.js";

interface Props {
  docId: string;
  rootItemId: string;
  doc: BuildingSubHeader_BuildingFragment;
  pathToDoc: string;
  pathToDocList: string;
  pathToDocPage: string;
  onUpdateDataRefetchQueries?: string[];
}

export const BuildingSubHeader = ({
  docId,
  rootItemId,
  doc,
  pathToDoc,
  pathToDocList,
  pathToDocPage,
  onUpdateDataRefetchQueries,
  isHeaderVisible,
  setHeaderVisible,
}: Props & PageSubHeaderInjectedProps) => {
  const { t } = useTranslate(["Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyItemInfo] = useHeaderBox_ModifyItemInfoMutation({
    client,
    refetchQueries: onUpdateDataRefetchQueries,
    awaitRefetchQueries: true,
  });
  const handleTitleChange = async (title: string) => {
    await modifyItemInfo({
      variables: {
        input: {
          itemId: rootItemId,
          docId,
          projectId: null,
          values: {
            title,
          },
        },
      },
    });
  };

  const canEdit = true;

  return (
    <PageSubHeader
      handleTitleChange={
        canEdit
          ? async newValue => {
              await handleTitleChange(newValue);
              enqueueSnackbar(
                t("Title updated", {
                  ns: "Global",
                })
              );
            }
          : undefined
      }
      titleEditTooltip={
        canEdit
          ? t("Rename", {
              ns: "Global",
            })
          : undefined
      }
      title={doc.title}
      isHeaderVisible={isHeaderVisible}
      setHeaderVisible={setHeaderVisible}
      actionMenu={
        <BuildingMenu
          docId={docId}
          pathToDocList={pathToDocList}
          pathToDocPage={pathToDocPage}
        />
      }
    />
  );
};
