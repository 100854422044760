/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { SearchGlobalManufacturersRowFragmentDoc } from '../../features/crm/Crm.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GlobalManufacturersTableQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sorting?: Types.InputMaybe<Array<Types.QuerySearchManufacturersSorting>>;
}>;


export type GlobalManufacturersTableQuery = { searchManufacturers: { __typename: 'ManufacturerSearchResultConnection', totalCount: number, edges: Array<{ __typename: 'ManufacturerSearchResultEdge', node: { __typename: 'ManufacturerSearchResult', organisation: { __typename: 'Company', id: string, title: string, description: string, website: string, isMeister: boolean, email: any, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }> } } }> } };


export const GlobalManufacturersTableDocument = gql`
    query GlobalManufacturersTable($offset: Int, $limit: Int!, $search: String, $sorting: [QuerySearchManufacturersSorting!]) {
  searchManufacturers(
    offset: $offset
    limit: $limit
    sorting: $sorting
    filters: {excludeIfAlreadyCrm: false}
    search: $search
  ) {
    edges {
      node {
        organisation {
          ...SearchGlobalManufacturersRow
        }
      }
    }
    totalCount
  }
}
    ${SearchGlobalManufacturersRowFragmentDoc}`;

/**
 * __useGlobalManufacturersTableQuery__
 *
 * To run a query within a React component, call `useGlobalManufacturersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalManufacturersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalManufacturersTableQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGlobalManufacturersTableQuery(baseOptions: Apollo.QueryHookOptions<GlobalManufacturersTableQuery, GlobalManufacturersTableQueryVariables> & ({ variables: GlobalManufacturersTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalManufacturersTableQuery, GlobalManufacturersTableQueryVariables>(GlobalManufacturersTableDocument, options);
      }
export function useGlobalManufacturersTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalManufacturersTableQuery, GlobalManufacturersTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalManufacturersTableQuery, GlobalManufacturersTableQueryVariables>(GlobalManufacturersTableDocument, options);
        }
export function useGlobalManufacturersTableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GlobalManufacturersTableQuery, GlobalManufacturersTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GlobalManufacturersTableQuery, GlobalManufacturersTableQueryVariables>(GlobalManufacturersTableDocument, options);
        }
export type GlobalManufacturersTableQueryHookResult = ReturnType<typeof useGlobalManufacturersTableQuery>;
export type GlobalManufacturersTableLazyQueryHookResult = ReturnType<typeof useGlobalManufacturersTableLazyQuery>;
export type GlobalManufacturersTableSuspenseQueryHookResult = ReturnType<typeof useGlobalManufacturersTableSuspenseQuery>;
export type GlobalManufacturersTableQueryResult = Apollo.QueryResult<GlobalManufacturersTableQuery, GlobalManufacturersTableQueryVariables>;