/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationInvoiceSettingsForm_DefaultsFragment = { __typename: 'OrganisationDefaults', id: string, defaultInvoiceShowLaborCostSeparately: boolean, defaultInvoicePaymentTermDuration: number };

export type OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutationVariables = Types.Exact<{
  input: Types.OrganisationDefaultsUpdateInput;
}>;


export type OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation = { updateOrganisationDefaults: { __typename: 'OrganisationDefaultsUpdateResult', organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultInvoiceShowLaborCostSeparately: boolean, defaultInvoicePaymentTermDuration: number } } };

export const OrganisationInvoiceSettingsForm_DefaultsFragmentDoc = gql`
    fragment OrganisationInvoiceSettingsForm_Defaults on OrganisationDefaults {
  id
  defaultInvoiceShowLaborCostSeparately
  defaultInvoicePaymentTermDuration
}
    `;
export const OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsDocument = gql`
    mutation OrganisationInvoiceSettingsForm_ModifyOrganisationDefaults($input: OrganisationDefaultsUpdateInput!) {
  updateOrganisationDefaults(input: $input) {
    organisationDefaults {
      id
      ...OrganisationInvoiceSettingsForm_Defaults
    }
  }
}
    ${OrganisationInvoiceSettingsForm_DefaultsFragmentDoc}`;
export type OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutationFn = Apollo.MutationFunction<OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutationVariables>;

/**
 * __useOrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation__
 *
 * To run a mutation, you first call `useOrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationInvoiceSettingsFormModifyOrganisationDefaultsMutation, { data, loading, error }] = useOrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutationVariables>(OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsDocument, options);
      }
export type OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutationHookResult = ReturnType<typeof useOrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation>;
export type OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutationResult = Apollo.MutationResult<OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation>;
export type OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutationOptions = Apollo.BaseMutationOptions<OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationInvoiceSettingsForm_ModifyOrganisationDefaultsMutationVariables>;