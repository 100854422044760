/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated.js';
import { OrganisationMemberListItem__OrganisationMembershipFragmentDoc } from './OrganisationMember.generated.js';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationRouter__OrganisationFragment = { __typename: 'ViewerOrganisation', id: string, title: string, description: string, website: string, isMeister: boolean, email: any, isCraftsmanOrganisation: boolean, isClientOrganisation: boolean, isSupplierOrganisation: boolean, isManufacturerOrganisation: boolean, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export type ModifyOrganisationMutationVariables = Types.Exact<{
  input: Types.OrganisationUpdateInput;
}>;


export type ModifyOrganisationMutation = { updateOrganisation: { __typename: 'OrganisationUpdateResult', organisation: { __typename: 'ViewerOrganisation', id: string, title: string, description: string, website: string, isMeister: boolean, email: any, isCraftsmanOrganisation: boolean, isClientOrganisation: boolean, isSupplierOrganisation: boolean, isManufacturerOrganisation: boolean, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } };

export type OrganisationProductImportsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganisationProductImportsQuery = { viewer?: { __typename: 'Viewer', id: string, organisation: { __typename: 'ViewerOrganisation', id: string } } | null };

export type ModifyOrganisationMembershipMutationVariables = Types.Exact<{
  input: Types.ModifyOrganisationMembershipInput;
}>;


export type ModifyOrganisationMembershipMutation = { modifyOrganisationMembership: { __typename: 'ModifyOrganisationMembershipResult', member: { __typename: 'User', id: string, email: any, active: boolean, organisationId: string, organisationTitle: string, jobTitle: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, roles: Array<{ __typename: 'OrganisationRole', id: string, internalName: string, label: string }>, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, internalName: string, label: string }>, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, phones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } };

export type ModifyOrganisationProfile__AttachmentsMutationVariables = Types.Exact<{
  input: Types.ModifyOrganisationProfileInput;
}>;


export type ModifyOrganisationProfile__AttachmentsMutation = { modifyOrganisationProfile: { __typename: 'ModifyOrganisationProfileResult', profile: { __typename: 'OrganisationProfile', id: string, logo?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null, background?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null } } };

export type ModifyOrganisationProfile__ServicesMutationVariables = Types.Exact<{
  input: Types.ModifyOrganisationProfileInput;
}>;


export type ModifyOrganisationProfile__ServicesMutation = { modifyOrganisationProfile: { __typename: 'ModifyOrganisationProfileResult', profile: { __typename: 'OrganisationProfile', id: string, skillset: Array<Types.Skill> } } };

export type ModifyOrganisationProfile__AboutMutationVariables = Types.Exact<{
  input: Types.ModifyOrganisationProfileInput;
}>;


export type ModifyOrganisationProfile__AboutMutation = { modifyOrganisationProfile: { __typename: 'ModifyOrganisationProfileResult', profile: { __typename: 'OrganisationProfile', id: string, about?: any | null, yearFounded?: number | null, employeesNumber?: number | null, isMeister: boolean } } };

export type ModifyOrganisationProfile__ContactMutationVariables = Types.Exact<{
  input: Types.ModifyOrganisationProfileInput;
}>;


export type ModifyOrganisationProfile__ContactMutation = { modifyOrganisationProfile: { __typename: 'ModifyOrganisationProfileResult', profile: { __typename: 'OrganisationProfile', id: string, contactEmail?: any | null, contactWebsite?: string | null, contactAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, contactPhones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }>, openingHours?: Array<{ __typename: 'OpeningHour', from?: string | null, to?: string | null, isOpen: boolean }> | null } } };

export const OrganisationRouter__OrganisationFragmentDoc = gql`
    fragment OrganisationRouter__Organisation on ViewerOrganisation {
  id
  title
  description
  website
  isMeister
  contactPerson {
    id
    title
    description
    website
    isMeister
    familyname
    firstname
    fullname
    locale
    skillset
  }
  email
  phones {
    id
    main
    type
    number
  }
  logo {
    ...AttachmentSnapshot
  }
  isCraftsmanOrganisation
  isClientOrganisation
  isSupplierOrganisation
  isManufacturerOrganisation
}
    ${AttachmentSnapshotFragmentDoc}`;
export const ModifyOrganisationDocument = gql`
    mutation ModifyOrganisation($input: OrganisationUpdateInput!) {
  updateOrganisation(input: $input) {
    organisation {
      id
      ...OrganisationRouter__Organisation
    }
  }
}
    ${OrganisationRouter__OrganisationFragmentDoc}`;
export type ModifyOrganisationMutationFn = Apollo.MutationFunction<ModifyOrganisationMutation, ModifyOrganisationMutationVariables>;

/**
 * __useModifyOrganisationMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationMutation, { data, loading, error }] = useModifyOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationMutation, ModifyOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationMutation, ModifyOrganisationMutationVariables>(ModifyOrganisationDocument, options);
      }
export type ModifyOrganisationMutationHookResult = ReturnType<typeof useModifyOrganisationMutation>;
export type ModifyOrganisationMutationResult = Apollo.MutationResult<ModifyOrganisationMutation>;
export type ModifyOrganisationMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationMutation, ModifyOrganisationMutationVariables>;
export const OrganisationProductImportsDocument = gql`
    query OrganisationProductImports {
  viewer {
    id
    organisation {
      id
    }
  }
}
    `;

/**
 * __useOrganisationProductImportsQuery__
 *
 * To run a query within a React component, call `useOrganisationProductImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationProductImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationProductImportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationProductImportsQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationProductImportsQuery, OrganisationProductImportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationProductImportsQuery, OrganisationProductImportsQueryVariables>(OrganisationProductImportsDocument, options);
      }
export function useOrganisationProductImportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationProductImportsQuery, OrganisationProductImportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationProductImportsQuery, OrganisationProductImportsQueryVariables>(OrganisationProductImportsDocument, options);
        }
export function useOrganisationProductImportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrganisationProductImportsQuery, OrganisationProductImportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationProductImportsQuery, OrganisationProductImportsQueryVariables>(OrganisationProductImportsDocument, options);
        }
export type OrganisationProductImportsQueryHookResult = ReturnType<typeof useOrganisationProductImportsQuery>;
export type OrganisationProductImportsLazyQueryHookResult = ReturnType<typeof useOrganisationProductImportsLazyQuery>;
export type OrganisationProductImportsSuspenseQueryHookResult = ReturnType<typeof useOrganisationProductImportsSuspenseQuery>;
export type OrganisationProductImportsQueryResult = Apollo.QueryResult<OrganisationProductImportsQuery, OrganisationProductImportsQueryVariables>;
export const ModifyOrganisationMembershipDocument = gql`
    mutation ModifyOrganisationMembership($input: ModifyOrganisationMembershipInput!) {
  modifyOrganisationMembership(input: $input) {
    member {
      id
      ...OrganisationMemberListItem__OrganisationMembership
    }
  }
}
    ${OrganisationMemberListItem__OrganisationMembershipFragmentDoc}`;
export type ModifyOrganisationMembershipMutationFn = Apollo.MutationFunction<ModifyOrganisationMembershipMutation, ModifyOrganisationMembershipMutationVariables>;

/**
 * __useModifyOrganisationMembershipMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationMembershipMutation, { data, loading, error }] = useModifyOrganisationMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationMembershipMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationMembershipMutation, ModifyOrganisationMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationMembershipMutation, ModifyOrganisationMembershipMutationVariables>(ModifyOrganisationMembershipDocument, options);
      }
export type ModifyOrganisationMembershipMutationHookResult = ReturnType<typeof useModifyOrganisationMembershipMutation>;
export type ModifyOrganisationMembershipMutationResult = Apollo.MutationResult<ModifyOrganisationMembershipMutation>;
export type ModifyOrganisationMembershipMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationMembershipMutation, ModifyOrganisationMembershipMutationVariables>;
export const ModifyOrganisationProfile__AttachmentsDocument = gql`
    mutation ModifyOrganisationProfile__Attachments($input: ModifyOrganisationProfileInput!) {
  modifyOrganisationProfile(input: $input) {
    profile {
      id
      logo {
        ...AttachmentSnapshot
      }
      background {
        ...AttachmentSnapshot
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type ModifyOrganisationProfile__AttachmentsMutationFn = Apollo.MutationFunction<ModifyOrganisationProfile__AttachmentsMutation, ModifyOrganisationProfile__AttachmentsMutationVariables>;

/**
 * __useModifyOrganisationProfile__AttachmentsMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationProfile__AttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationProfile__AttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationProfileAttachmentsMutation, { data, loading, error }] = useModifyOrganisationProfile__AttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationProfile__AttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationProfile__AttachmentsMutation, ModifyOrganisationProfile__AttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationProfile__AttachmentsMutation, ModifyOrganisationProfile__AttachmentsMutationVariables>(ModifyOrganisationProfile__AttachmentsDocument, options);
      }
export type ModifyOrganisationProfile__AttachmentsMutationHookResult = ReturnType<typeof useModifyOrganisationProfile__AttachmentsMutation>;
export type ModifyOrganisationProfile__AttachmentsMutationResult = Apollo.MutationResult<ModifyOrganisationProfile__AttachmentsMutation>;
export type ModifyOrganisationProfile__AttachmentsMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationProfile__AttachmentsMutation, ModifyOrganisationProfile__AttachmentsMutationVariables>;
export const ModifyOrganisationProfile__ServicesDocument = gql`
    mutation ModifyOrganisationProfile__Services($input: ModifyOrganisationProfileInput!) {
  modifyOrganisationProfile(input: $input) {
    profile {
      id
      skillset
    }
  }
}
    `;
export type ModifyOrganisationProfile__ServicesMutationFn = Apollo.MutationFunction<ModifyOrganisationProfile__ServicesMutation, ModifyOrganisationProfile__ServicesMutationVariables>;

/**
 * __useModifyOrganisationProfile__ServicesMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationProfile__ServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationProfile__ServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationProfileServicesMutation, { data, loading, error }] = useModifyOrganisationProfile__ServicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationProfile__ServicesMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationProfile__ServicesMutation, ModifyOrganisationProfile__ServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationProfile__ServicesMutation, ModifyOrganisationProfile__ServicesMutationVariables>(ModifyOrganisationProfile__ServicesDocument, options);
      }
export type ModifyOrganisationProfile__ServicesMutationHookResult = ReturnType<typeof useModifyOrganisationProfile__ServicesMutation>;
export type ModifyOrganisationProfile__ServicesMutationResult = Apollo.MutationResult<ModifyOrganisationProfile__ServicesMutation>;
export type ModifyOrganisationProfile__ServicesMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationProfile__ServicesMutation, ModifyOrganisationProfile__ServicesMutationVariables>;
export const ModifyOrganisationProfile__AboutDocument = gql`
    mutation ModifyOrganisationProfile__About($input: ModifyOrganisationProfileInput!) {
  modifyOrganisationProfile(input: $input) {
    profile {
      id
      about
      yearFounded
      employeesNumber
      isMeister
    }
  }
}
    `;
export type ModifyOrganisationProfile__AboutMutationFn = Apollo.MutationFunction<ModifyOrganisationProfile__AboutMutation, ModifyOrganisationProfile__AboutMutationVariables>;

/**
 * __useModifyOrganisationProfile__AboutMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationProfile__AboutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationProfile__AboutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationProfileAboutMutation, { data, loading, error }] = useModifyOrganisationProfile__AboutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationProfile__AboutMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationProfile__AboutMutation, ModifyOrganisationProfile__AboutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationProfile__AboutMutation, ModifyOrganisationProfile__AboutMutationVariables>(ModifyOrganisationProfile__AboutDocument, options);
      }
export type ModifyOrganisationProfile__AboutMutationHookResult = ReturnType<typeof useModifyOrganisationProfile__AboutMutation>;
export type ModifyOrganisationProfile__AboutMutationResult = Apollo.MutationResult<ModifyOrganisationProfile__AboutMutation>;
export type ModifyOrganisationProfile__AboutMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationProfile__AboutMutation, ModifyOrganisationProfile__AboutMutationVariables>;
export const ModifyOrganisationProfile__ContactDocument = gql`
    mutation ModifyOrganisationProfile__Contact($input: ModifyOrganisationProfileInput!) {
  modifyOrganisationProfile(input: $input) {
    profile {
      id
      contactAddress {
        ...AddressDetails__Address
      }
      contactEmail
      contactPhones {
        id
        number
        type
        main
      }
      contactWebsite
      openingHours {
        from
        to
        isOpen
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export type ModifyOrganisationProfile__ContactMutationFn = Apollo.MutationFunction<ModifyOrganisationProfile__ContactMutation, ModifyOrganisationProfile__ContactMutationVariables>;

/**
 * __useModifyOrganisationProfile__ContactMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationProfile__ContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationProfile__ContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationProfileContactMutation, { data, loading, error }] = useModifyOrganisationProfile__ContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationProfile__ContactMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationProfile__ContactMutation, ModifyOrganisationProfile__ContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationProfile__ContactMutation, ModifyOrganisationProfile__ContactMutationVariables>(ModifyOrganisationProfile__ContactDocument, options);
      }
export type ModifyOrganisationProfile__ContactMutationHookResult = ReturnType<typeof useModifyOrganisationProfile__ContactMutation>;
export type ModifyOrganisationProfile__ContactMutationResult = Apollo.MutationResult<ModifyOrganisationProfile__ContactMutation>;
export type ModifyOrganisationProfile__ContactMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationProfile__ContactMutation, ModifyOrganisationProfile__ContactMutationVariables>;