import { AssignmentTurnedInOutlined as UnpaidIcon } from "@mui/icons-material";
import { ErrorOutline as ErrorOutlineIcon } from "@mui/icons-material";
import { Euro as EuroIcon } from "@mui/icons-material";
import { HelpOutline as Decision } from "@mui/icons-material";
import { ReactNode, useMemo } from "react";
import { ReactComponent as SectionIcon } from "../../../assets/icons/icon-section.svg";
import { color, theme } from "../../../../common/MuiThemeProvider.js";
import { ItemType } from "../../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

const TYPES: ItemType[] = ["section", "paid", "unpaid", "defect", "decision"];

export function useItemTypes() {
  const { t } = useTranslate("ItemTree");

  const itemTypeLabels: Record<ItemType, string> = useMemo(
    () => ({
      section: t("Section"),
      paid: t("Paid"),
      unpaid: t("Unpaid"),
      defect: t("Defect"),
      decision: t("Decision"),
    }),
    [t]
  );

  const itemTypeOptions = useMemo(
    () =>
      TYPES.map(itemType => ({
        icon: itemTypeIcons[itemType],
        value: itemType,
        label: itemTypeLabels[itemType],
      })),
    [itemTypeLabels]
  );

  return { itemTypeLabels, itemTypeOptions };
}

export function useItemTypeCreateLabels() {
  const { t } = useTranslate("ItemTree");

  const itemOptionLabels: Record<ItemType, string> = {
    section: t("Add Section"),
    paid: t("Add Paid"),
    unpaid: t("Add Unpaid"),
    defect: t("Add Defect"),
    decision: t("Add Decision"),
  };

  return itemOptionLabels;
}

export const itemTypeIcons: Record<ItemType, ReactNode> = {
  section: (
    <SectionIcon
      color={theme.palette.primary.main}
      style={{ flexGrow: 0, flexShrink: 0 }}
    />
  ),
  paid: <EuroIcon color="primary" style={{ flexGrow: 0, flexShrink: 0 }} />,
  unpaid: (
    <UnpaidIcon
      htmlColor={color.turquoise}
      style={{ flexGrow: 0, flexShrink: 0 }}
    />
  ),
  defect: (
    <ErrorOutlineIcon
      htmlColor={theme.palette.error.main}
      style={{ flexGrow: 0, flexShrink: 0 }}
    />
  ),
  decision: (
    <Decision
      htmlColor={theme.palette.warning.main}
      style={{ flexGrow: 0, flexShrink: 0 }}
    />
  ),
};
