import { LoadingSpinner as LoadingContainer, Modal } from "@msys/ui";
import { DialogContentText } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { v4 } from "uuid";
import { Helmet } from "react-helmet";
import { useM1Integration } from "./useM1Integration.js";

export type Meister1WidgetInputs = {
  salutation?: "MRS" | "MR";
  firstname?: string;
  lastname?: string;
  street?: string;
  street_no?: string;
  email?: string;
  phone?: string;
  zip?: string;
  city?: string;
};

type Meister1WidgetOnCompleteContactData = {
  firstname: string;
  familyname: string;
  email: string;
  phone: string;
  salutation: "MRS" | "MR" | "";
};

export type Meister1State =
  | { type: "webhook-with-invite"; desiredInvitationId: string }
  | {
      type: "existing-project";
      projectId: string;
      requestId: string | null;
      userId: string | null;
    };

interface StartProcessArgs {
  identName: string;
  completeBeforeConfirmPage: boolean;
  meister1State: Meister1State | undefined;
  inputs: Meister1WidgetInputs | undefined;
  onComplete: (
    leadId: number,
    contactData: Meister1WidgetOnCompleteContactData
  ) => Promise<void>;
}

export interface Meister1ProcessRef {
  startProcess: (args: StartProcessArgs) => void;
}

const WEBHOOK_CALLBACK_TIMEOUT_SECONDS = 80;

interface Props {
  forOrganisationId: string;
}

export const Meister1Process = React.forwardRef<Meister1ProcessRef, Props>(
  (props, forwardedRef) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslate(["Global", "M1Calculators"]);
    const { m1PlusConfig } = useM1Integration(props.forOrganisationId);

    const [meister1Widget, setMeister1Widget] = React.useState<{
      identName: string;
      selectorId: string;
      completeBeforeConfirmPage: boolean;
      state: Meister1State | undefined;
      inputs: Meister1WidgetInputs | undefined;
      onComplete: (
        leadId: number,
        contactData: Meister1WidgetOnCompleteContactData
      ) => Promise<void>;
    } | null>(null);
    const [isCompleting, setIsCompleting] = React.useState(false);

    React.useImperativeHandle(forwardedRef, () => ({
      startProcess: ({
        identName,
        completeBeforeConfirmPage,
        meister1State,
        inputs,
        onComplete,
      }: StartProcessArgs) => {
        setMeister1Widget({
          identName,
          selectorId: `meister1-${Date.now()}-${v4()}`,
          completeBeforeConfirmPage,
          state: meister1State,
          inputs,
          onComplete,
        });
      },
    }));

    if (isCompleting) {
      return (
        <Modal handleClose={undefined} maxWidth="xs">
          <DialogContentText align="center">
            {t("Please wait...", { ns: "Global" })}
          </DialogContentText>
          <LoadingContainer padding={0} />
        </Modal>
      );
    }

    return meister1Widget && m1PlusConfig ? (
      <Meister1Widget
        meister1FlowSelectorId={meister1Widget.selectorId}
        flowIdentName={meister1Widget.identName}
        completeBeforeConfirmPage={meister1Widget.completeBeforeConfirmPage}
        inputs={meister1Widget.inputs}
        meister1State={meister1Widget.state}
        flowUrl={m1PlusConfig.flowUrl}
        onComplete={async (leadId, contactData) => {
          setMeister1Widget(null);

          setIsCompleting(true);
          try {
            if (!leadId) {
              throw new NoLeadIdError();
            }

            await Promise.race([
              meister1Widget.onComplete(leadId, contactData),
              new Promise((resolve, reject) => {
                setTimeout(
                  () => reject(new OnCompleteTimeoutError()),
                  WEBHOOK_CALLBACK_TIMEOUT_SECONDS * 1000
                );
              }),
            ]);
          } catch (error) {
            const message =
              error instanceof OnCompleteTimeoutError
                ? t("Quote creation failed due to time out", {
                    ns: "M1Calculators",
                  })
                : error instanceof NoLeadIdError
                  ? t("Quote creation failed", {
                      ns: "M1Calculators",
                    })
                  : t("Quote creation failed", {
                      ns: "M1Calculators",
                    });
            enqueueSnackbar(message, {
              variant: "error",
            });
          } finally {
            setIsCompleting(false);
          }
        }}
        onCancel={() => {
          setMeister1Widget(null);
        }}
      />
    ) : null;
  }
);

class OnCompleteTimeoutError extends Error {
  constructor() {
    super("M1 widget onComplete timeout");
  }
}

class NoLeadIdError extends Error {
  constructor() {
    super("No leadId returned from M1 flow");
  }
}

const Meister1Widget = ({
  meister1FlowSelectorId,
  onCancel,
  onComplete,
  flowIdentName,
  completeBeforeConfirmPage,
  meister1State,
  inputs,
  flowUrl,
}: {
  meister1FlowSelectorId: string;
  flowIdentName: string;
  meister1State: Meister1State | undefined;
  inputs: Meister1WidgetInputs | undefined;
  onCancel: () => void;
  onComplete: (
    leadId: number,
    contactData: Meister1WidgetOnCompleteContactData
  ) => void;
  completeBeforeConfirmPage: boolean;
  flowUrl: string;
}) => {
  const id = `${meister1FlowSelectorId}-${flowIdentName}`;
  const onCompleteRef = React.useRef(onComplete);
  onCompleteRef.current = onComplete;

  React.useEffect(() => {
    const state = (() => {
      // if (!meister1State) return "";

      switch (meister1State?.type) {
        case "webhook-with-invite": {
          return {
            type: "webhook-with-invite",
            desiredInvitationId: meister1State.desiredInvitationId,
          };
        }
        case "existing-project": {
          return {
            type: "existing-project",
            projectId: meister1State.projectId,
            requestId: meister1State.requestId,
            userId: meister1State.userId,
          };
        }
        default: {
          // assertNever(meister1State?);
          return "";
        }
      }
    })();

    const opts = {
      mode: "iframe",
      selector: `#${id}`,
      cookiesAllowed: false,
      completeBeforeConfirmPage,
      inputs,
      state: JSON.stringify(state),
      customStyle: {
        primaryColor: "blue",
        primaryFontColor: "white",
        linkColor: "red",
        textColorLight: "blue",
        font: "12px Arial",
      },
    };

    console.info("meister1.opts", opts);

    setTimeout(() => {
      (window as any).Lokalleads.init(
        flowIdentName,
        opts,
        async (
          res: {
            event: "flow_completed";
            data: {
              id: string;
              leadId: string;
              inputs: Array<{ name: string; values: Array<{ value: string }> }>;
            };
          } | null
        ) => {
          if (res && res.event === "flow_completed") {
            console.info("flow_completed", res);

            const readInputValue = (key: string) =>
              res.data.inputs.find(e => e.name === key)?.values?.[0]?.value ??
              "";

            onCompleteRef.current?.(parseInt(res.data.leadId, 10), {
              salutation: readInputValue("salutation") as "MRS" | "MR" | "",
              firstname: readInputValue("firstname"),
              familyname: readInputValue("lastname"),
              email: readInputValue("email"),
              phone: readInputValue("phone"),
            });
          }
        }
      );
    }, 1000);
  }, [id, flowIdentName, completeBeforeConfirmPage, inputs, meister1State]);

  return (
    <Modal
      handleClose={onCancel}
      dialogProps={{ fullScreen: true }}
      contentProps={{ sx: { padding: "0 !important", overflow: "hidden" } }}
    >
      <Helmet>
        <script type="text/javascript" src={flowUrl} async />
      </Helmet>
      <div
        id={id}
        style={{ height: "100%", width: "100%", overflow: "auto" }}
      />
    </Modal>
  );
};
