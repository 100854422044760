/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type TemplatesQuoteStatus_TemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, accessType: Types.TemplateAccessType, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, containsUnpublishedChanged: boolean, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null };

export const TemplatesQuoteStatus_TemplateFragmentDoc = gql`
    fragment TemplatesQuoteStatus_Template on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  accessType
  subscribedToTemplate {
    subscribedVersionNumber
    updateAvailable
  }
  owningSystemOrganisationId
  hasAnyPublishedVersion
  containsUnpublishedChanged
}
    `;