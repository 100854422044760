import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import { omit } from "lodash-es";
import moment from "moment";
import { Address, namedOperations } from "../../../../clients/graphqlTypes.js";
import { AddSignatureModal } from "../../../commons/modals/AddSignatureModal.js";
import { GetAddressModal } from "../../addresses/GetAddressModal.js";
import { SelectExistingAddressModal } from "../../addresses/SelectExistingAddressModal.js";
import {
  MarkQuoteAsAcceptedModalQueryResult,
  useMarkQuoteAsAcceptedModalQuery,
  useMarkQuoteAsAcceptedModal_SetDocActorAddressMutation,
} from "./MarkQuoteAsAcceptedModal.generated.js";

interface Props {
  projectId: string;
  docId: string;
  title?: string;
  handleClose: () => void;
  handleComplete: (values: {
    signature: string;
    place: string;
    date: moment.Moment;
  }) => Promise<void>;
}

export const MarkQuoteAsAcceptedModal = ({
  projectId,
  docId,
  title,
  handleClose,
  handleComplete,
}: Props) => {
  const client = useApolloClient();

  const { t } = useTranslate([
    "OrganisationProfile",
    "QuoteAccept",
    "SignatureModal",
  ]);

  const query = useMarkQuoteAsAcceptedModalQuery({
    client,
    variables: { projectId, docId },
    fetchPolicy: "no-cache",
  });
  const [setDocActorAddress] =
    useMarkQuoteAsAcceptedModal_SetDocActorAddressMutation({ client });

  const contractor = getDataOrNull(query?.data?.quote)?.quote?.contractor;
  const contractee = getDataOrNull(query?.data?.quote)?.quote?.contractee;
  const contracteeCrmBuildings = contractee?.viewerCrmCompany?.buildings ?? [];
  const projectBuilding = getDataOrNull(query?.data?.project)?.project
    ?.building;
  const projectBuildingAddress = projectBuilding?.buildingAddress;

  const options = contracteeCrmBuildings
    ?.map(building => ({
      buildingId: building.id,
      title: building.title,
      address: building.buildingAddress,
    }))
    .filter(
      (
        option
      ): option is {
        buildingId: string;
        address: Exclude<
          (typeof contracteeCrmBuildings)[number]["buildingAddress"],
          null | undefined
        >;
        title: string;
      } => Boolean(option.address)
    );
  const preselectedOption =
    options.find(option => option.buildingId === projectBuilding?.id) ?? null;

  if (!(contractee && contractor)) return null;

  return !contractee.address ? (
    <SelectExistingAddressModal
      id="contractee-billing-address-modal"
      key={"contracteeAddressModal"}
      title={t("Please provide the contractee branch address", {
        ns: "QuoteAccept",
      })}
      inputLabel={t("Billing address", {
        ns: "OrganisationProfile",
      })}
      options={options}
      preselectedOption={preselectedOption}
      handleComplete={async (address, _handleClose) => {
        await setDocActorAddress({
          variables: {
            input: {
              projectId,
              docId,
              docActorType: "CONTRACTEE",
              docActorId: contractee.id,
              docActor: {
                address: omit(address, "__typename", "id"),
              },
            },
          },
          refetchQueries: [namedOperations.Query.MarkQuoteAsAcceptedModal],
        });
      }}
      handleClose={handleClose}
    />
  ) : !contractor.address ? (
    <GetAddressModal
      key={"contractorAddressModal"}
      title={t("Please provide your branch address", {
        ns: "QuoteAccept",
      })}
      inputLabel={t("Billing address", {
        ns: "OrganisationProfile",
      })}
      handleComplete={async (address, _handleClose) => {
        await setDocActorAddress({
          variables: {
            input: {
              projectId,
              docId,
              docActorType: "CONTRACTOR",
              docActorId: contractor.id,
              docActor: { address },
            },
          },
          refetchQueries: [namedOperations.Query.MarkQuoteAsAcceptedModal],
        });
      }}
      handleClose={handleClose}
    />
  ) : (
    <AddSignatureModal
      title={title}
      handleClose={handleClose}
      handleComplete={handleComplete}
      description={t("Please sign here to accept the quote.", {
        ns: "SignatureModal",
      })}
      defaultPlace={
        projectBuildingAddress?.city ??
        contractee?.address?.city ??
        contractor?.address?.city ??
        ""
      }
    />
  );
};
