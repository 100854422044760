import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useLanguages } from "../../common/translations/useLanguages.js";
import { LanguageSelector } from "../commons/LanguageSelector.js";
import { Page } from "../commons/layout/Page.js";
import { PageContainer } from "../commons/layout/PageContainer.js";
import { LogoutButton } from "./LogoutButton.js";
import { AvailableUser, SelectUserList } from "./SelectUserList.js";
import { useSelectedUser } from "./useSelectedUser.js";

interface Props {
  availableUsers: AvailableUser[];
  availableUsersIsLoading: boolean;
  onMount: () => Promise<unknown>;
}

export const SelectUserPage = ({
  availableUsersIsLoading,
  availableUsers,
  onMount,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const { languages, selectLanguage, currentLanguage } = useLanguages();
  const { setSelectedUserId } = useSelectedUser();

  const selectUser = React.useCallback(
    (user: AvailableUser) => {
      setSelectedUserId(user.id);
    },
    [setSelectedUserId]
  );

  React.useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Page
      hideNavigation
      isTopBarVisible
      topbarItems={
        <>
          <LogoutButton color={"inherit"} />
          <LanguageSelector
            languages={languages}
            selectLanguage={selectLanguage}
            currentLanguage={currentLanguage}
          />
        </>
      }
    >
      <PageContainer $fullHeight>
        <Stack
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
          flex={1}
        >
          <Box>
            <Typography variant="h1" padding={2}>
              {t("Select organisation", { ns: "Global" })}
            </Typography>
            <SelectUserList
              isLoading={availableUsersIsLoading}
              availableUsers={availableUsers}
              selectedUser={null}
              handleSelect={selectUser}
            />
          </Box>
        </Stack>
      </PageContainer>
    </Page>
  );
};
