import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  CollapseSection,
  DurationType,
  Modal,
  ModalOpenButton,
  getDurationInSeconds,
  getNormalizedDuration,
} from "@msys/ui";
import { Edit as EditIcon } from "@mui/icons-material";
import { RequestQuote as RequestQuoteIcon } from "@mui/icons-material";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { isEqual, omit, uniqueId } from "lodash-es";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import {
  ContractType,
  QuoteMaterialMarginRangeInput,
  QuoteMaterialPriceHandling,
  QuoteModifyInput,
  VatRateType,
  VatVisibility,
  namedOperations,
} from "../../../clients/graphqlTypes.js";
import { ContractTypeRadioGroupField } from "../../commons/form-fields/ContractTypeRadioGroupField.js";
import { DatePickerField } from "../../commons/form-fields/DatePickerField.js";
import { FormattedFloatField } from "../../commons/form-fields/FormattedFloatField.js";
import { FormattedPercentageField } from "../../commons/form-fields/FormattedPercentageField.js";
import { RadioGroupField } from "../../commons/form-fields/RadioGroupField.js";
import { SelectField } from "../../commons/form-fields/SelectField.js";
import { SwitchField } from "../../commons/form-fields/SwitchField.js";
import { DURATION_MAX_VALUES } from "../../utils.js";
import {
  VatField,
  VatFieldFormValues,
  getVatValues,
  useVatFieldValidationSchema,
} from "../../vat/form-fields/VatField.js";
import { CustomFieldsBox } from "../custom-fields/CustomFieldsBox.js";
import { EditCustomFieldModal } from "../custom-fields/EditCustomFieldModal.js";
import { MaterialMarginRangesSection } from "../organisations/form-fields/MaterialMarginRangesSection.js";
import { useMaterialMarginRangesFieldValidationSchema } from "../organisations/useMaterialMarginRangesFieldValidationSchema.js";
import { useQuoteMaterialPriceHandlingOptions } from "../organisations/useQuoteMaterialPriceHandlingOptions.js";
import {
  VerticalFormValues,
  VerticalsFormField,
  getVerticalFormValues,
  getVerticalInputValues,
  useVerticalsFieldValidationSchema,
} from "../verticals/form-fields/VerticalsFormField.js";
import {
  useQuoteSettingsModalQuery,
  useUpdateQuoteSettingsMutation,
} from "./QuoteSettingsModal.generated.js";
import {
  QuoteHeaderVisibility,
  QuoteHeaderVisibilityField,
  defaultQuoteHeaderVisibility,
  useQuoteHeaderVisibilityValidationSchema,
} from "./form-fields/QuoteHeaderVisibilityField.js";
import {
  QuoteProductsVisibility,
  QuoteProductsVisibilityField,
  defaultQuoteProductsVisibility,
  useQuoteProductsVisibilityValidationSchema,
} from "./form-fields/QuoteProductsVisibilityField.js";

interface Props {
  projectId: string;
  quoteId: string;
  canEdit: boolean;
  handleClose: () => void;
}

interface FormValues extends VatFieldFormValues {
  showOfferConditions: boolean;
  earliestDate: Moment | null;
  durationAmount: number;
  durationType: DurationType;
  validityDurationAmount: number;
  validityDurationUnit: DurationType;
  contractType: ContractType;
  quoteForRequirementId: string;
  isSignatureVisible: boolean;
  vatVisibility: VatVisibility;
  discountReason: string;
  isDiscountPercentageVisible: boolean;
  defaultMaterialMargin: number;
  materialMarginRanges: QuoteMaterialMarginRangeInput[];
  materialPriceHandling: QuoteMaterialPriceHandling;
  defaultVerticalSettings: VerticalFormValues[];
  workFactor: number;
  materialFactor: number;
  headerVisibility: QuoteHeaderVisibility;
  productsVisibility: QuoteProductsVisibility;
}

export const QuoteSettingsModal = ({
  projectId,
  quoteId,
  canEdit,
  handleClose,
}: Props) => {
  const { t } = useTranslate([
    "QuoteEditFooter",
    "VatVisibility",
    "Global",
    "OrganisationSettings",
  ]);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const query = useQuoteSettingsModalQuery({
    client,
    variables: {
      projectId,
      quoteId,
    },
  });

  const [updateQuoteSettings] = useUpdateQuoteSettingsMutation({ client });

  const quote = getDataOrNull(query.data?.quote)?.quote;
  const requirements =
    getDataOrNull(query.data?.projectRequirements)?.edges?.map(e => e.node) ??
    [];
  const organisationDefaults = query.data?.organisationDefaults;

  const defaultVerticalSetting = React.useMemo(
    () =>
      organisationDefaults?.defaultVerticalSettings.find(
        s => s.vertical === "general"
      ),
    [organisationDefaults]
  );

  const quoteMaterialPriceHandlingOptions =
    useQuoteMaterialPriceHandlingOptions();

  const quoteHeaderVisibilityValidationSchema =
    useQuoteHeaderVisibilityValidationSchema();

  const quoteProductsVisibilityValidationSchema =
    useQuoteProductsVisibilityValidationSchema();

  const validationSchema = Yup.object().shape({
    discountReason: Yup.string()
      .label(
        t("Discount reason", {
          ns: "QuoteEditFooter",
        })
      )
      .max(80),
    isDiscountPercentageVisible: Yup.boolean()
      .label(
        t("Discount percentage amount is visible", {
          ns: "QuoteEditFooter",
        })
      )
      .required(),
    headerVisibility: quoteHeaderVisibilityValidationSchema.required(),
    productsVisibility: quoteProductsVisibilityValidationSchema.required(),
    showOfferConditions: Yup.boolean()
      .label(
        t("Offer conditions are visible", {
          ns: "QuoteEditFooter",
        })
      )
      .required(),
    durationAmount: Yup.number().label(
      t("Work duration", {
        ns: "QuoteEditFooter",
      })
    ),
    durationType: Yup.string()
      .label(
        t("Units", {
          ns: "QuoteEditFooter",
        })
      )
      .oneOf(["days", "weeks", "months", "none"]),
    validityDurationAmount: Yup.number()
      .label(
        t("Validity duration", {
          ns: "QuoteEditFooter",
        })
      )
      .min(1)
      .required(),
    validityDurationUnit: Yup.string()
      .label(
        t("Units", {
          ns: "QuoteEditFooter",
        })
      )
      .oneOf(["days", "weeks", "months"]),
    earliestStart: Yup.date()
      .label(
        t("Earliest work start", {
          ns: "QuoteEditFooter",
        })
      )
      .nullable(),
    contractType: Yup.string()
      .label(
        t("Contract Type", {
          ns: "QuoteEditFooter",
        })
      )
      .required(),
    isSignatureVisible: Yup.boolean()
      .label(
        t("Signature area is visible", {
          ns: "QuoteEditFooter",
        })
      )
      .required(),
    vatVisibility: Yup.string()
      .label(
        t("VAT visibility", {
          ns: "QuoteEditFooter",
        })
      )
      .required(),
    workFactor: Yup.number()
      .label(
        t("Work factor", {
          ns: "QuoteEditFooter",
        })
      )
      .min(0)
      .required(),
    materialFactor: Yup.number()
      .label(
        t("Material factor", {
          ns: "QuoteEditFooter",
        })
      )
      .min(0)
      .required(),
    ...useVatFieldValidationSchema(),
    defaultMaterialMargin: Yup.number()
      .min(0)
      .label(
        t("Default material margin", {
          ns: "OrganisationSettings",
        })
      )
      .required(),
    materialMarginRanges: useMaterialMarginRangesFieldValidationSchema(
      "materialMarginRanges"
    ),
    materialPriceHandling: Yup.string()
      .label("QuoteEditFooter::Material price handling")
      .required(),
    defaultVerticalSettings: useVerticalsFieldValidationSchema(
      "defaultVerticalSettings"
    ),
  });

  const initialValues = React.useMemo((): FormValues => {
    const [durationAmount, durationType] = getNormalizedDuration(
      quote?.duration || 0
    );
    const [validityDurationAmount, validityDurationType] =
      getNormalizedDuration(quote?.validityDuration || 0);
    return {
      durationAmount,
      durationType,
      earliestDate: quote?.earliestDate ? moment(quote.earliestDate) : null,
      contractType: quote?.contractType ?? "fmbp_fr",
      quoteForRequirementId: quote?.quoteForRequirement?.id ?? "",
      isSignatureVisible: quote?.isSignatureVisible ?? true,
      vatVisibility: quote?.vatVisibility ?? "SHOW",
      ...getVatValues(
        quote?.vatInfo?.countryCode,
        quote?.vatInfo?.rateType,
        quote?.vatRate
      ),
      showOfferConditions: quote?.showOfferConditions ?? true,
      validityDurationAmount: validityDurationAmount,
      validityDurationUnit: validityDurationType,
      headerVisibility: quote?.headerVisibility
        ? omit(quote.headerVisibility, "__typename")
        : defaultQuoteHeaderVisibility,
      productsVisibility: quote?.productsVisibility
        ? omit(quote.productsVisibility, "__typename")
        : defaultQuoteProductsVisibility,
      discountReason: quote?.discountReason ?? "",
      isDiscountPercentageVisible: quote?.isDiscountPercentageVisible ?? true,
      defaultMaterialMargin: quote?.defaultMaterialMargin ?? 0.2,
      materialMarginRanges:
        quote?.materialMarginRanges?.map(r => omit(r, "id", "__typename")) ??
        [],
      materialPriceHandling:
        quote?.materialPriceHandling ?? "CALCULATED_SELL_PRICE",
      defaultVerticalSettings: getVerticalFormValues(
        quote?.defaultVerticalSettings ?? []
      ),
      workFactor: quote?.workFactor ?? 1,
      materialFactor: quote?.materialFactor ?? 1,
    };
  }, [quote]);

  const formId = React.useMemo(() => uniqueId(), []);

  const isReadOnly = quote?.agreement === "YES";

  const handleSubmit = async (values: FormValues) => {
    if (!quote) throw new Error("Quote missing");

    const input: QuoteModifyInput = {
      docId: quoteId,
      projectId,
      values: {
        discountReason: values.discountReason,
        isDiscountPercentageVisible: values.isDiscountPercentageVisible,
        headerVisibility: values.headerVisibility,
        productsVisibility: values.productsVisibility,
        showOfferConditions: values.showOfferConditions,
        earliestDate: values.earliestDate?.format("YYYY-MM-DD"),
        duration: getDurationInSeconds({
          durationAmount: values.durationAmount,
          durationType: values.durationType,
        }),
        validityDuration: getDurationInSeconds({
          durationAmount: values.validityDurationAmount,
          durationType: values.validityDurationUnit,
        }),
        quoteForRequirementId: values.quoteForRequirementId
          ? values.quoteForRequirementId
          : undefined,
        contractType: values.contractType,
        isSignatureVisible: values.isSignatureVisible,
        vatVisibility: values.vatVisibility,
        vatRate: values.vatRate,
        vatInfo: {
          countryCode: values.vatInfo.countryCode,
          rateType: values.vatInfo.rateType as VatRateType, // FIXME
        },
        quoteTexts: omit(quote.quoteTexts, ["__typename", "id"]),
        defaultMaterialMargin: values.defaultMaterialMargin,
        materialMarginRanges: values.materialMarginRanges,
        materialPriceHandling: values.materialPriceHandling,
        defaultVerticalSettings: getVerticalInputValues(
          values.defaultVerticalSettings
        ),
        workFactor: values.workFactor,
        materialFactor: values.materialFactor,
      },
    };

    try {
      await updateQuoteSettings({
        variables: {
          input,
          refetchTree:
            initialValues.contractType !== values.contractType ||
            initialValues.workFactor !== values.workFactor ||
            initialValues.materialFactor !== values.materialFactor ||
            initialValues.vatRate !== values.vatRate ||
            !isEqual(
              values.defaultVerticalSettings,
              initialValues.defaultVerticalSettings
            ),
          refetchQuoteText: !!input.values.quoteTexts,
        },
      });
      enqueueSnackbar(t("Settings saved", { ns: "QuoteEditFooter" }));
      handleClose();
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formikProps => (
        <Modal
          title={t("Quote info & settings", {
            ns: "QuoteEditFooter",
          })}
          dialogProps={{ maxWidth: "md" }}
          actionButtons={
            canEdit
              ? [
                  {
                    label: t("Cancel", {
                      ns: "Global",
                    }),
                    handleClick: handleClose,
                    buttonProps: { variant: "text" },
                  },
                  {
                    label: t("Save", {
                      ns: "Global",
                    }),
                    buttonProps: {
                      loading: formikProps.isSubmitting,
                      form: formId,
                      type: "submit",
                      disabled: !formikProps.dirty || !formikProps.isValid,
                    },
                  },
                ]
              : []
          }
          handleClose={handleClose}
        >
          <Form id={formId}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Stack direction="column" spacing={1}>
                  <CardContainer
                    title={t("View settings", {
                      ns: "QuoteEditFooter",
                    })}
                    isExpandable={false}
                    isInitiallyClosed={false}
                    Icon={<VisibilityIcon />}
                  >
                    <Stack direction="column" spacing={1} p={1}>
                      {requirements.length > 0 && (
                        <SelectField
                          name="quoteForRequirementId"
                          label={t("Requirement document", {
                            ns: "QuoteEditFooter",
                          })}
                          options={requirements.map(requirement => ({
                            label: requirement.title,
                            value: requirement.id,
                          }))}
                          disabled={isReadOnly || formikProps.isSubmitting}
                        />
                      )}

                      <QuoteHeaderVisibilityField
                        name="headerVisibility"
                        disabled={isReadOnly || formikProps.isSubmitting}
                      />

                      <QuoteProductsVisibilityField
                        name="productsVisibility"
                        disabled={isReadOnly || formikProps.isSubmitting}
                      />

                      <Typography>
                        {t("VAT visibility", {
                          ns: "QuoteEditFooter",
                        })}
                      </Typography>
                      <RadioGroupField
                        name="vatVisibility"
                        options={[
                          {
                            value: "SHOW",
                            label: t("Show", {
                              ns: "VatVisibility",
                            }),
                          },
                          {
                            value: "SHOW_SMALL",
                            label: t("Show in small print", {
                              ns: "VatVisibility",
                            }),
                          },
                          {
                            value: "HIDE",
                            label: t("Hide", {
                              ns: "VatVisibility",
                            }),
                          },
                        ]}
                        condensed
                        disabled={isReadOnly || formikProps.isSubmitting}
                      />

                      <Box>
                        <Typography>
                          {t("Signature area", {
                            ns: "QuoteEditFooter",
                          })}
                        </Typography>
                        <SwitchField
                          label={t("Signature area is visible", {
                            ns: "QuoteEditFooter",
                          })}
                          name="isSignatureVisible"
                          disabled={isReadOnly || formikProps.isSubmitting}
                        />
                      </Box>
                    </Stack>

                    <Divider />

                    <CollapseSection
                      title={t("Discount settings", {
                        ns: "QuoteEditFooter",
                      })}
                      isInitiallyExpanded={true}
                      px={1}
                    >
                      <Stack direction="column" spacing={1} p={1}>
                        <SwitchField
                          label={t("Discount percentage amount is visible", {
                            ns: "QuoteEditFooter",
                          })}
                          name="isDiscountPercentageVisible"
                          disabled={isReadOnly || formikProps.isSubmitting}
                        />
                        <Field
                          component={TextField}
                          name="discountReason"
                          label={t("Discount reason", {
                            ns: "QuoteEditFooter",
                          })}
                          disabled={isReadOnly || formikProps.isSubmitting}
                          helperText={t(
                            "This will appear in the quote/invoice preview and PDF in the discount line",
                            {
                              ns: "QuoteEditFooter",
                            }
                          )}
                        />
                      </Stack>
                    </CollapseSection>
                  </CardContainer>

                  {quote && (
                    <CustomFieldsBox
                      source={quote}
                      objectType={"Quote"}
                      actionButton={
                        !isReadOnly ? (
                          <ModalOpenButton
                            Modal={EditCustomFieldModal}
                            modalProps={{
                              title: t("Edit quote custom fields", {
                                ns: "QuoteEditFooter",
                              }),
                              objectId: quote.id,
                              objectType: "Quote",
                              customFields: quote.customFields,
                              refetchQueries: [
                                namedOperations.Query.QuoteSettingsModal,
                              ],
                            }}
                          >
                            <IconButton color="secondary" size="small">
                              <EditIcon />
                            </IconButton>
                          </ModalOpenButton>
                        ) : undefined
                      }
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardContainer
                  title={t("Contract settings", {
                    ns: "QuoteEditFooter",
                  })}
                  isExpandable={false}
                  isInitiallyClosed={false}
                  Icon={<RequestQuoteIcon />}
                >
                  <CollapseSection
                    title={t("Contract Type", {
                      ns: "QuoteEditFooter",
                    })}
                    isInitiallyExpanded={true}
                    px={1}
                  >
                    <Stack direction="column" spacing={1} p={1}>
                      <ContractTypeRadioGroupField
                        name="contractType"
                        disabled={isReadOnly || formikProps.isSubmitting}
                      />
                    </Stack>
                  </CollapseSection>

                  <Divider />

                  <CollapseSection
                    title={t("Price calculation settings", {
                      ns: "QuoteEditFooter",
                    })}
                    isInitiallyExpanded={true}
                    px={1}
                  >
                    <Stack spacing={1} p={1}>
                      <Typography>
                        {t("Multiplying factors", {
                          ns: "QuoteEditFooter",
                        })}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <FormattedFloatField
                          name="workFactor"
                          label={t("Work factor", {
                            ns: "QuoteEditFooter",
                          })}
                          type="number"
                          min={0}
                          defaultValue={1}
                          disabled={isReadOnly || formikProps.isSubmitting}
                        />
                        <FormattedFloatField
                          name="materialFactor"
                          label={t("Material factor", {
                            ns: "QuoteEditFooter",
                          })}
                          type="number"
                          min={0}
                          defaultValue={1}
                          disabled={isReadOnly || formikProps.isSubmitting}
                        />
                      </Stack>
                      <Divider />

                      <Typography>
                        {t("Work price settings", {
                          ns: "OrganisationSettings",
                        })}
                      </Typography>
                      <VerticalsFormField
                        name="defaultVerticalSettings"
                        disabled={isReadOnly || formikProps.isSubmitting}
                        defaultValue={defaultVerticalSetting}
                      />
                      <Divider />
                      <Typography>
                        {t("Material price settings", {
                          ns: "OrganisationSettings",
                        })}
                      </Typography>
                      <FormattedPercentageField
                        name="defaultMaterialMargin"
                        label={t("Default material margin", {
                          ns: "OrganisationSettings",
                        })}
                        disabled={isReadOnly || formikProps.isSubmitting}
                      />
                      <MaterialMarginRangesSection
                        title={t("Custom material margin ranges", {
                          ns: "OrganisationSettings",
                        })}
                        value={formikProps.values.materialMarginRanges}
                        defaultMaterialMargin={
                          formikProps.values.defaultMaterialMargin
                        }
                        name="materialMarginRanges"
                        disabled={isReadOnly || formikProps.isSubmitting}
                      />
                      <RadioGroupField
                        label={t("Select material price handling option", {
                          ns: "OrganisationSettings",
                        })}
                        name={"materialPriceHandling"}
                        options={quoteMaterialPriceHandlingOptions}
                        condensed
                        disabled={isReadOnly || formikProps.isSubmitting}
                      />
                    </Stack>
                  </CollapseSection>

                  <Divider />

                  <CollapseSection
                    title={t("Offer conditions", {
                      ns: "QuoteEditFooter",
                    })}
                    isInitiallyExpanded={true}
                    px={1}
                  >
                    <Stack direction="column" spacing={1} p={1}>
                      <SwitchField
                        name="showOfferConditions"
                        label={t("Offer conditions are visible", {
                          ns: "QuoteEditFooter",
                        })}
                        disabled={isReadOnly || formikProps.isSubmitting}
                      />
                      <DatePickerField
                        name="earliestDate"
                        label={t("Earliest work start", {
                          ns: "QuoteEditFooter",
                        })}
                        disabled={
                          isReadOnly ||
                          formikProps.isSubmitting ||
                          formikProps.values.showOfferConditions === false
                        }
                      />

                      <Stack direction={"row"} spacing={1}>
                        <FormattedFloatField
                          name="durationAmount"
                          label={t("Work duration", {
                            ns: "QuoteEditFooter",
                          })}
                          type="float"
                          min={0}
                          max={
                            DURATION_MAX_VALUES[formikProps.values.durationType]
                          }
                          disabled={
                            isReadOnly ||
                            formikProps.isSubmitting ||
                            formikProps.values.showOfferConditions === false
                          }
                        />
                        <SelectField
                          name="durationType"
                          label={t("Units", {
                            ns: "QuoteEditFooter",
                          })}
                          disabled={
                            isReadOnly ||
                            formikProps.isSubmitting ||
                            formikProps.values.showOfferConditions === false
                          }
                          options={[
                            {
                              label: "-",
                              value: "none",
                            },
                            {
                              label: t("Days", {
                                ns: "QuoteEditFooter",
                              }),
                              value: "days",
                            },
                            {
                              label: t("Weeks", {
                                ns: "QuoteEditFooter",
                              }),
                              value: "weeks",
                            },
                            {
                              label: t("Months", {
                                ns: "QuoteEditFooter",
                              }),
                              value: "months",
                            },
                          ]}
                        />
                      </Stack>

                      <Stack direction={"row"} spacing={1}>
                        <Field
                          component={TextField}
                          name="validityDurationAmount"
                          label={t("Validity duration", {
                            ns: "QuoteEditFooter",
                          })}
                          type="number"
                          min={0}
                          max={
                            DURATION_MAX_VALUES[
                              formikProps.values.validityDurationUnit
                            ]
                          }
                          disabled={
                            isReadOnly ||
                            formikProps.isSubmitting ||
                            formikProps.values.showOfferConditions === false
                          }
                        />
                        <SelectField
                          name="validityDurationUnit"
                          label={t("Units", {
                            ns: "QuoteEditFooter",
                          })}
                          disabled={
                            isReadOnly ||
                            formikProps.isSubmitting ||
                            formikProps.values.showOfferConditions === false
                          }
                          options={[
                            {
                              label: t("Days", {
                                ns: "QuoteEditFooter",
                              }),
                              value: "days",
                            },
                            {
                              label: t("Weeks", {
                                ns: "QuoteEditFooter",
                              }),
                              value: "weeks",
                            },
                            {
                              label: t("Months", {
                                ns: "QuoteEditFooter",
                              }),
                              value: "months",
                            },
                          ]}
                        />
                      </Stack>
                    </Stack>
                  </CollapseSection>

                  <Divider />

                  <CollapseSection
                    title={t("VAT settings", {
                      ns: "QuoteEditFooter",
                    })}
                    isInitiallyExpanded={true}
                    px={1}
                  >
                    <Stack direction="column" spacing={1} p={1}>
                      <VatField
                        disabled={isReadOnly || formikProps.isSubmitting}
                      />
                    </Stack>
                  </CollapseSection>
                </CardContainer>
              </Grid>
            </Grid>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
