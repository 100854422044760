import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { DurationCounter, DurationValue, LabeledValue } from "@msys/ui";
import { Pause as PauseIcon } from "@mui/icons-material";
import { PlayArrow as PlayArrowIcon } from "@mui/icons-material";
import { PlayCircleOutline as PlayCircleOutlineIcon } from "@mui/icons-material";
import { Stop as StopIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Card, Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { color } from "../../../../common/MuiThemeProvider.js";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission.js";
import { useUserData } from "../../../auth/useUserData.js";
import { processWorkSessions } from "../../sessions/helpers.js";
import {
  ProjectOverviewWorkSessionsBox_ProjectFragment,
  useFinishProjectWorkSessionMutation,
  useProjectWorkSessionsQuery,
  useStartProjectWorkSessionMutation,
} from "./ProjectOverviewWorkSessionsBox.generated.js";

interface Props {
  project: ProjectOverviewWorkSessionsBox_ProjectFragment;
}

export const ProjectOverviewWorkSessionsBox = ({ project }: Props) => {
  const { t } = useTranslate("WorkSessions");

  const { enqueueSnackbar } = useSnackbar();
  const viewer = useUserData().currentUser!;

  const now = useMemo(() => moment(), []);

  const client = useApolloClient();
  const query = useProjectWorkSessionsQuery({
    client,
    variables: {
      projectId: project.id,
      assigneeId: viewer.id,
      date: now.format("YYYY-MM-DD"),
    },
  });

  const [startSession, { loading: startSessionLoading }] =
    useStartProjectWorkSessionMutation({ client });
  const [finishSession, { loading: finishSessionLoading }] =
    useFinishProjectWorkSessionMutation({ client });

  const workSessions =
    getDataOrNull(query.data?.project)?.project?.workSessions ?? [];

  const start = async (isBreak: boolean) => {
    try {
      await startSession({
        variables: {
          input: { isBreak, projectId: project.id },
          assigneeId: viewer.id,
          date: now.format("YYYY-MM-DD"),
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  const finish = async (isBreak: boolean) => {
    try {
      await finishSession({
        variables: {
          input: { isBreak, projectId: project.id },
          assigneeId: viewer.id,
          date: now.format("YYYY-MM-DD"),
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const {
    activeBreakSession,
    totalBreakDurationMs,
    activeWorkSession,
    activeWorkDurationMs,
    noActiveWorkSession,
    noActiveSession,
  } = processWorkSessions(workSessions);

  return (
    <Card>
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <LabeledValue label={t("Start time")}>
              {activeWorkSession
                ? moment(activeWorkSession.from).format("HH:mm")
                : "–"}
            </LabeledValue>
          </Grid>
          <Grid item xs={3}>
            <LabeledValue label={t("Breaks")}>
              <span
                style={{
                  color: noActiveSession ? color.grey : undefined,
                }}
              >
                {activeBreakSession ? (
                  <DurationCounter
                    from={activeBreakSession.from}
                    deltaMs={totalBreakDurationMs}
                    noNegative
                  />
                ) : totalBreakDurationMs > 0 ? (
                  <DurationValue valueMs={totalBreakDurationMs} noNegative />
                ) : (
                  "–"
                )}
              </span>
            </LabeledValue>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                fontSize: "2rem",
                fontWeight: 400,
                lineHeight: 1,
                color: noActiveWorkSession ? color.grey : undefined,
                textAlign: "right",
              }}
            >
              {activeWorkSession ? (
                activeBreakSession || activeWorkSession.till ? (
                  <DurationValue
                    valueMs={activeWorkDurationMs - totalBreakDurationMs}
                    noNegative
                  />
                ) : (
                  <DurationCounter
                    from={activeWorkSession.from}
                    deltaMs={-totalBreakDurationMs}
                    noNegative
                  />
                )
              ) : (
                <DurationValue valueMs={0} noNegative />
              )}
            </div>
          </Grid>
          {activeBreakSession ? (
            <RestrictedByProjectPermissionWithDebug
              permission="EXECUTE_TASK"
              project={project}
            >
              <Grid item xs={12}>
                <LoadingButton
                  onClick={() => finish(true)}
                  loading={finishSessionLoading}
                  variant="contained"
                  color="secondary"
                  endIcon={<PlayArrowIcon />}
                  fullWidth
                >
                  {t("Resume work")}
                </LoadingButton>
              </Grid>
            </RestrictedByProjectPermissionWithDebug>
          ) : activeWorkSession && !activeWorkSession.till ? (
            <>
              <RestrictedByProjectPermissionWithDebug
                permission="EXECUTE_TASK"
                project={project}
              >
                <Grid item xs={6}>
                  <LoadingButton
                    onClick={() => start(true)}
                    loading={startSessionLoading}
                    variant="outlined"
                    color="secondary"
                    endIcon={<PauseIcon />}
                    fullWidth
                  >
                    {t("Break")}
                  </LoadingButton>
                </Grid>
              </RestrictedByProjectPermissionWithDebug>
              <RestrictedByProjectPermissionWithDebug
                permission="EXECUTE_TASK"
                project={project}
              >
                <Grid item xs={6}>
                  <LoadingButton
                    onClick={() => finish(false)}
                    loading={finishSessionLoading}
                    variant="contained"
                    color="secondary"
                    endIcon={<StopIcon />}
                    fullWidth
                  >
                    {t("Finish")}
                  </LoadingButton>
                </Grid>
              </RestrictedByProjectPermissionWithDebug>
            </>
          ) : activeWorkSession && activeWorkSession.till ? (
            <RestrictedByProjectPermissionWithDebug
              permission="EXECUTE_TASK"
              project={project}
            >
              <Grid item xs={12}>
                <LoadingButton
                  onClick={() => start(false)}
                  loading={startSessionLoading}
                  variant="contained"
                  color="secondary"
                  endIcon={<PlayCircleOutlineIcon />}
                  fullWidth
                >
                  {t("Continue work")}
                </LoadingButton>
              </Grid>
            </RestrictedByProjectPermissionWithDebug>
          ) : (
            <RestrictedByProjectPermissionWithDebug
              permission="EXECUTE_TASK"
              project={project}
            >
              <Grid item xs={12}>
                <LoadingButton
                  onClick={() => start(false)}
                  loading={startSessionLoading}
                  variant="contained"
                  color="secondary"
                  endIcon={<PlayArrowIcon />}
                  fullWidth
                >
                  {t("Start work")}
                </LoadingButton>
              </Grid>
            </RestrictedByProjectPermissionWithDebug>
          )}
        </Grid>
      </Box>
    </Card>
  );
};
