import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  LoadingSpinner,
  isImageOr3dModel,
  processAttachment,
  useScreenWidth,
} from "@msys/ui";
import {
  Box,
  Container,
  Stack as MuiStack,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { partition } from "lodash-es";
import React from "react";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import { Agreement, PermissionName } from "../../../../clients/graphqlTypes.js";
import { useFeature } from "../../../../common/FeatureFlags.js";
import { useUserData } from "../../../auth/useUserData.js";
import { PageNotFound } from "../../../commons/PageNotFound.js";
import { usePageWidth } from "../../../commons/hooks/usePageWidth.js";
import { GalleryRow } from "../../../commons/images/GalleryRow.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../../commons/layout/Page.js";
import { PageColumn } from "../../../commons/layout/PageColumn.js";
import { PageContainer } from "../../../commons/layout/PageContainer.js";
import { Stack } from "../../../commons/layout/Stack.js";
import {
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../../commons/modals/ConfirmProcess.js";
import { QUOTE_TEMPLATE_ITEM_TYPES } from "../../../constants.js";
import { FilesBoxTable } from "../../../features/attachments/FilesBoxTable.js";
import { ProfitabilityBox } from "../../../features/doc-items/boxes/ProfitabilityBox.js";
import {
  rootItemHasCalculationConstraints,
  rootItemHasItemTableConstraints,
} from "../../../features/doc-items/constraints.js";
import { isTreePreviewItemVisible } from "../../../features/doc-items/helpers.js";
import { ClosingTextInput } from "../../../features/quotes/QuoteClosingText.js";
import { OpeningTextInput } from "../../../features/quotes/QuoteOpeningText.js";
import { useQuoteQuickFilters } from "../../../features/quotes/QuoteQuickFilters.js";
import { QuotePreviewSummary } from "../../../features/quotes/preview/QuotePreviewSummary.js";
import { allowHaveTemplateChildren } from "../../../features/templates/helpers.js";
import {
  TemplateQuotePreviewItemDescription,
  templateQuoteItemHasDescription,
} from "../../../features/templates/quote/TemplateQuotePreviewItemDescription.js";
import { TemplatesQuotePreviewItems } from "../../../features/templates/quote/TemplateQuotePreviewItems.js";
import { TemplatesQuotePriceSummaryBox } from "../../../features/templates/quote/TemplatesQuotePriceSummaryBox.js";
import { TemplatesQuoteTemplateAction } from "../../../features/templates/quote/TemplatesQuoteTemplateAction.js";
import { TemplatesQuoteTimeSummaryBox } from "../../../features/templates/quote/TemplatesQuoteTimeSummaryBox.js";
import { createTreeItem } from "../../../features/templates/quote/trees.js";
import { VirtualItemTree } from "../../../trees-virtual/VirtualItemTree.js";
import { VirtualBareTreeStandaloneItem } from "../../../trees-virtual/components/VirtualBareTreeStandaloneItem.js";
import {
  ExpandedStore,
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../../trees-virtual/hooks/useExpandedStore.js";
import { TreeToggleAllExpandedButton } from "../../../trees/components/TreeToggleButton.js";
import { fromVersionNumberParamToVersionNumberInt } from "../../../utils.js";
import {
  TemplateQuoteVersion_ItemFragment,
  useTemplateQuoteVersionPreviewQuery,
  useTemplateQuoteVersionQuery,
} from "./TemplateQuoteVersion.generated.js";

interface Props {
  docId: string;
  pathToDocList: string;
  pathToDoc: string;
  pathToDocPage: string;
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  pageSubHeader: React.ReactElement | null;
}

export const TemplateQuoteVersion = ({
  docId,
  pathToDocList,
  pathToDoc,
  pathToDocPage,
  submenuItems,
  prefixBreadcrumbs,
  pageSubHeader,
}: Props) => {
  const { t } = useTranslate(["Templates"]);

  const match = useMatch(`${pathToDocPage}/items/:itemId`);
  const selectedItemId = match?.params?.itemId ?? null;
  const navigate = useNavigate();
  const { versionNumber: versionNumberFromParam } = useParams();

  const versionNumber = fromVersionNumberParamToVersionNumberInt(
    versionNumberFromParam
  );

  const viewer = useUserData().currentUser!;

  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();

  const { isMinTablet } = useScreenWidth();

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);
  const [previewContainer, setPreviewContainer] =
    React.useState<HTMLDivElement | null>(null);

  const [QuickFiltersTabs, quickFilter] =
    useQuoteQuickFilters<TemplateQuoteVersion_ItemFragment>(false);

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote-template",
    docId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useTemplateQuoteVersionQuery({
    client,
    variables: {
      id: docId,
      versionNumber: versionNumber!,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });

  const templateVersion = getDataOrNull(
    query.data?.quoteTemplateVersion
  )?.quoteTemplate;

  const navigateToItem = React.useCallback(
    (id: string) => {
      return navigate(`${pathToDocPage}/items/${id}`, {
        replace: true,
      });
    },
    [navigate, pathToDocPage]
  );

  const isClientVisibilityEnabled = useFeature("ClientVisibility");

  const viewerPermissions: PermissionName[] = viewer.organisationPermissions;
  const agreement: Agreement = "NONE";

  const isOrganisationOwnTemplate =
    templateVersion?.owningSystemOrganisationId === viewer.organisation.id;

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);

  const TreeItem = React.useMemo(
    () =>
      createTreeItem({
        pathToDocList,
        pathToDocPage,
        projectId: null,
        document: { viewerPermissions },
        docId,
        docAgreement: agreement,
        startConfirmProcess: noopStartConfirmProcess,
        canEdit: false,
        navigateToItem,
        expandedItemIds: expandedStore.expandedItemIds,
        setItemExpanded: expandedStore.setItemExpanded,
        pasteItem: null,
        isAllowedToPasteItem: () => false,
        onAdditionalItemInputChange: null,
      }),
    [
      pathToDocList,
      pathToDocPage,
      viewerPermissions,
      docId,
      navigateToItem,
      expandedStore.expandedItemIds,
      expandedStore.setItemExpanded,
    ]
  );

  const [filteredTreeItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!templateVersion) return [[], undefined, []];
    const allDocItems = templateVersion.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems.filter(
      item => !item.isAnyParentItemEliminated
    );
    const filteredItems = quickFilter(treeItems);
    return [filteredItems, rootItem, allDocItems];
  }, [templateVersion, quickFilter]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  React.useEffect(() => {
    if (query.loading || query.error) return;
    if (
      selectedItemId &&
      !filteredTreeItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredTreeItems,
    pathToDocPage,
    rootItem,
    navigate,
    query.loading,
    query.error,
  ]);

  const filterItem = React.useCallback(
    (i: TemplateQuoteVersion_ItemFragment) =>
      isTreePreviewItemVisible(
        true,
        i,
        allDocItems,
        templateVersion?.isBinding ?? true
      ),
    [allDocItems, templateVersion?.isBinding]
  );

  const isRootSelected = selectedItemId === rootItem?.id;

  return (
    <Page
      title={templateVersion?.title ?? ""}
      subtitle={t("Document Templates", { ns: "Templates" })}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      subHeader={pageSubHeader}
      headerType="template"
      action={
        templateVersion ? (
          <TemplatesQuoteTemplateAction
            doc={templateVersion}
            pathToDoc={pathToDoc}
          />
        ) : undefined
      }
    >
      <PageColumn
        $hasRightBorder
        $hasBackground
        $display="block"
        ref={isMinTablet ? setTreeContainer : undefined}
      >
        {templateVersion && rootItem && (
          <Stack flexDirection={"column"} spacing={2}>
            <Stack width="100%" alignItems="center" spacing={1}>
              <TreeToggleAllExpandedButton
                areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                setAllItemsExpanded={expandedStore.setAllItemsExpanded}
              />
              <QuickFiltersTabs />
            </Stack>

            <Box>
              <VirtualBareTreeStandaloneItem
                item={rootItem}
                items={filteredTreeItems}
                allItems={allDocItems}
                depth={0}
                itemComponent={TreeItem}
                selected={isRootSelected}
                sticky={isRootSelected}
                top={0}
                clickable
                onClick={() => navigateToItem(rootItem.id)}
                to={`${pathToDocPage}/items/${rootItem.id}`}
              />
              <VirtualItemTree<TemplateQuoteVersion_ItemFragment, false>
                docId={null}
                projectId={null}
                items={filteredTreeItems}
                allItems={allDocItems}
                filterItem={isClientVisibilityEnabled ? filterItem : undefined}
                selectedItemId={selectedItemId}
                enableCreating={false}
                enableDragging={false}
                shouldRenderCreateInput={shouldRenderCreateInput}
                allowHaveChildren={allowHaveTemplateChildren}
                documentItemTypes={QUOTE_TEMPLATE_ITEM_TYPES}
                container={treeContainer}
                itemComponent={TreeItem}
                // inputComponent={TreeItemInput}
                expandedStore={expandedStore}
              />
            </Box>

            {rootItemHasCalculationConstraints.includes(rootItem.type) &&
              templateVersion.proposedCalculation && (
                <TemplatesQuoteTimeSummaryBox
                  calculated={templateVersion.proposedCalculation}
                />
              )}

            {rootItemHasCalculationConstraints.includes(rootItem.type) &&
              templateVersion.proposedCalculation && (
                <TemplatesQuotePriceSummaryBox
                  docId={templateVersion.id}
                  discountPercentage={templateVersion.discountPercentage}
                  calculated={templateVersion.proposedCalculation}
                  isReadOnly={true}
                />
              )}

            {rootItemHasCalculationConstraints.includes(rootItem.type) &&
              isOrganisationOwnTemplate &&
              templateVersion.proposedCalculation && (
                <ProfitabilityBox
                  calculated={templateVersion.proposedCalculation}
                />
              )}
          </Stack>
        )}
      </PageColumn>
      <ConfirmProcess ref={confirmProcessRef} />

      <Outlet context={{ expandedItemIds: expandedStore.expandedItemIds }} />

      {templateVersion && isMinOneColumnWithPreview && (
        <PageContainer
          $fullHeight
          onClick={() => {
            if (!isMinTwoColumnsWithPreview)
              navigate(pathToDocPage, { replace: true });
          }}
          ref={setPreviewContainer}
        >
          <Container maxWidth="md">
            <TemplateQuoteVersionPreview
              docId={docId}
              selectedItemId={selectedItemId}
              isOwnTemplate={isOrganisationOwnTemplate}
              navigateToItem={({ id }) => navigateToItem(id)}
              container={previewContainer}
              expandedStore={expandedStore}
            />
          </Container>
        </PageContainer>
      )}
    </Page>
  );
};

function shouldRenderCreateInput() {
  return false;
}

async function noopStartConfirmProcess() {
  return false;
}

interface PreviewProps {
  docId: string;
  selectedItemId?: string | null;
  navigateToItem?: React.ComponentProps<
    typeof TemplatesQuotePreviewItems
  >["navigateToItem"];
  isOwnTemplate?: boolean;
  container?: HTMLElement | null;
  expandedStore: ExpandedStore;
}

export const TemplateQuoteVersionPreview = ({
  docId,
  navigateToItem,
  selectedItemId,
  isOwnTemplate,
  container,
  expandedStore,
}: PreviewProps) => {
  const { versionNumber: versionNumberFromParam } = useParams();
  const versionNumber = fromVersionNumberParamToVersionNumberInt(
    versionNumberFromParam
  );
  if (!versionNumber) throw new Error("Version number missing");

  const { isMinTablet, isMinDesktop } = useScreenWidth();
  const { t } = useTranslate(["Quote", "Global", "Decisions"]);

  const client = useApolloClient();
  const query = useTemplateQuoteVersionPreviewQuery({
    client,
    variables: { id: docId, versionNumber },
  });

  const quoteTemplate = getDataOrNull(
    query.data?.quoteTemplateVersion
  )?.quoteTemplate;
  const rootItem = quoteTemplate?.rootItem;
  const allDocItems = quoteTemplate?.previewItems ?? [];
  const quoteTexts = quoteTemplate?.quoteTexts;

  const [images, files] = partition(
    (rootItem?.attachments ?? [])
      .filter(attachment => attachment.clientVisibility)
      .map(processAttachment),
    isImageOr3dModel
  );

  if (query.loading && !quoteTemplate) return <LoadingSpinner />;
  if (!quoteTemplate || !rootItem) return <PageNotFound />;

  return (
    <MuiStack width="100%" direction="column" spacing={1}>
      <Paper>
        <Box padding={2}>
          <MuiStack direction="column">
            <Typography variant="h2">{quoteTemplate.title}</Typography>
            {quoteTemplate.isBinding === false && (
              <Typography
                fontWeight="bold"
                color="textSecondary"
                component="div"
              >
                {t("Non-Binding Template", { ns: "Quote" })}
              </Typography>
            )}
            {rootItem.decisionIsContingentItem && (
              <Typography
                fontWeight="bold"
                color="textSecondary"
                component="div"
              >
                {t("Optional Template", { ns: "Quote" })}
              </Typography>
            )}
          </MuiStack>
        </Box>
      </Paper>

      {isOwnTemplate && (
        <OpeningTextInput
          canEdit={false}
          content={quoteTexts?.opening}
          showInfo={false}
        />
      )}

      {(images.length > 0 || templateQuoteItemHasDescription(rootItem)) && (
        <Paper>
          <Stack padding={2} flexDirection={"column"} spacing={2}>
            <GalleryRow
              images={images}
              maxItems={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
            />

            <TemplateQuotePreviewItemDescription
              item={rootItem}
              columns={isMinDesktop ? 4 : isMinTablet ? 2 : 1}
            />
          </Stack>
        </Paper>
      )}

      {rootItemHasItemTableConstraints.includes(rootItem.type) && (
        <TemplatesQuotePreviewItems
          canEdit={false}
          docId={docId}
          contractType={quoteTemplate.contractType}
          isBinding={quoteTemplate.isBinding}
          allDocItems={allDocItems}
          navigateToItem={navigateToItem}
          selectedItemId={selectedItemId}
          expandedStore={expandedStore}
          container={container}
        />
      )}

      {rootItemHasCalculationConstraints.includes(rootItem.type) &&
        quoteTemplate.proposedCalculation && (
          <QuotePreviewSummary
            vatVisibility={quoteTemplate.vatVisibility}
            discountPercentage={quoteTemplate.discountPercentage}
            discountReason={undefined}
            isDiscountPercentageVisible={true}
            calculation={quoteTemplate.proposedCalculation}
          />
        )}

      {isOwnTemplate && (
        <ClosingTextInput
          canEdit={false}
          content={quoteTexts?.closing}
          showInfo={false}
        />
      )}

      {files.length > 0 && (
        <FilesBoxTable
          title={t("Attachments", { ns: "Quote" })}
          attachments={files}
          canEdit={false}
          gridColumns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
        />
      )}
    </MuiStack>
  );
};
