/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAllLinksToLatestPublishedVersionMutationVariables = Types.Exact<{
  input: Types.UpdateAllLinkingItemsToTemplateToLatestAvailableVersionInput;
}>;


export type UpdateAllLinksToLatestPublishedVersionMutation = { updateAllLinkingItemsToTemplateToLatestAvailableVersion: { __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResult', templates: Array<{ __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateError', errorMessage: string, template: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string } } | { __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateResultSuccess', publishedNewVersion: boolean, template: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string }, linkingItems: Array<{ __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItem', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, pathForPdf: string, linkedQuoteTemplateAtVersionNumber?: number | null }, result: { __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultSuccess', ok: boolean } | { __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultValidationError', results: Array<{ __typename: 'CompileDocIsolatedExpressionResultDiagnosticAskIf' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf' } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf' }> } }> }> } };


export const UpdateAllLinksToLatestPublishedVersionDocument = gql`
    mutation UpdateAllLinksToLatestPublishedVersion($input: UpdateAllLinkingItemsToTemplateToLatestAvailableVersionInput!) {
  updateAllLinkingItemsToTemplateToLatestAvailableVersion(input: $input) {
    templates {
      ... on UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateResultSuccess {
        template {
          id
          resolvedAsReadModelVersionNumber
          title
        }
        publishedNewVersion
        linkingItems {
          item {
            id
            originVersionNumber
            title
            pathForPdf
            linkedQuoteTemplateAtVersionNumber
          }
          result {
            ... on UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultSuccess {
              ok
            }
            ... on UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultValidationError {
              results {
                __typename
              }
            }
          }
        }
      }
      ... on UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateError {
        template {
          id
          resolvedAsReadModelVersionNumber
          title
        }
        errorMessage
      }
    }
  }
}
    `;
export type UpdateAllLinksToLatestPublishedVersionMutationFn = Apollo.MutationFunction<UpdateAllLinksToLatestPublishedVersionMutation, UpdateAllLinksToLatestPublishedVersionMutationVariables>;

/**
 * __useUpdateAllLinksToLatestPublishedVersionMutation__
 *
 * To run a mutation, you first call `useUpdateAllLinksToLatestPublishedVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllLinksToLatestPublishedVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllLinksToLatestPublishedVersionMutation, { data, loading, error }] = useUpdateAllLinksToLatestPublishedVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAllLinksToLatestPublishedVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAllLinksToLatestPublishedVersionMutation, UpdateAllLinksToLatestPublishedVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAllLinksToLatestPublishedVersionMutation, UpdateAllLinksToLatestPublishedVersionMutationVariables>(UpdateAllLinksToLatestPublishedVersionDocument, options);
      }
export type UpdateAllLinksToLatestPublishedVersionMutationHookResult = ReturnType<typeof useUpdateAllLinksToLatestPublishedVersionMutation>;
export type UpdateAllLinksToLatestPublishedVersionMutationResult = Apollo.MutationResult<UpdateAllLinksToLatestPublishedVersionMutation>;
export type UpdateAllLinksToLatestPublishedVersionMutationOptions = Apollo.BaseMutationOptions<UpdateAllLinksToLatestPublishedVersionMutation, UpdateAllLinksToLatestPublishedVersionMutationVariables>;