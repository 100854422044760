/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { QuoteHeaderVisibilityFragmentDoc, QuoteProductsVisibilityFragmentDoc, DocumentWithItems__DocumentFragmentDoc } from '../Quotes.generated.js';
export type QuotePreviewHeader__QuoteFragment = { __typename: 'Quote', id: string, number: string, publishedAt?: any | null, createdAt: any, owningSystemOrganisationId: string, viewerPermissions: Array<Types.PermissionName>, projectId: string, originalProjectNumber: string, externalIdentifier?: string | null, xId?: string | null, contractor: { __typename: 'DocActor', id: string, companyName: string, email: string, phone: string, address?: { __typename: 'AddressSnapshot', countryCode: string, postalCode: string, city: string, streetLines1: string } | null, contact?: { __typename: 'DocActorContact', fullname: string } | null }, contractee: { __typename: 'DocActor', id: string, identifier?: string | null, companyOrIndividual: Types.DocActorCompanyOrIndividual, companyName: string, firstname: string, familyname: string, contact?: { __typename: 'DocActorContact', fullname: string } | null, address?: { __typename: 'AddressSnapshot', countryCode: string, postalCode: string, city: string, streetLines1: string } | null }, headerVisibility: { __typename: 'QuoteHeaderVisibility', isProjectNumberVisible: boolean, isClientNumberVisible: boolean, isDocumentNumberVisible: boolean, isExternalIdentifierVisible: boolean, isPublishDateVisible: boolean, isContractorNameVisible: boolean, isContractorAddressVisible: boolean, isContractorPhoneVisible: boolean, isContractorEmailVisible: boolean, isContractorContactPersonVisible: boolean, isEndClientVisible: boolean } };

export type QuotePreviewHeader__ProjectFragment = { __typename: 'Project', id: string, number: string };

export const QuotePreviewHeader__QuoteFragmentDoc = gql`
    fragment QuotePreviewHeader__Quote on Quote {
  id
  number
  publishedAt
  createdAt
  owningSystemOrganisationId
  viewerPermissions
  projectId
  originalProjectNumber
  contractor {
    id
    companyName
    email
    phone
    address {
      countryCode
      postalCode
      city
      streetLines1
    }
    contact {
      fullname
    }
  }
  contractee {
    id
    identifier
    companyOrIndividual
    companyName
    firstname
    familyname
    contact {
      fullname
    }
    address {
      countryCode
      postalCode
      city
      streetLines1
    }
  }
  headerVisibility {
    ...QuoteHeaderVisibility
  }
  externalIdentifier
  xId
}
    ${QuoteHeaderVisibilityFragmentDoc}`;
export const QuotePreviewHeader__ProjectFragmentDoc = gql`
    fragment QuotePreviewHeader__Project on Project {
  id
  number
}
    `;