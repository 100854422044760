import { useApolloClient } from "@apollo/client";
import { DataGrid, Modal, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { GroupAdd as GroupAddIcon } from "@mui/icons-material";
import {
  Alert,
  Button,
  ButtonProps,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, Form, Formik } from "formik";
import { uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { RadioGroupField } from "../../../../commons/form-fields/RadioGroupField.js";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../../constants.js";
import { CraftsmenSelectModal } from "../../../craftsman/CraftsmenSelectModal.js";
import { OrganisationAvatar } from "../../../organisations/OrganisationAvatar.js";
import { OrganisationAvatarFragment } from "../../../organisations/OrganisationAvatar.generated.js";
import {
  QuoteTemplateShareButton_QuoteTemplateFragment,
  useShareQuoteTemplatePubliclyMutation,
  useShareQuoteTemplateWithOrganisationsMutation,
} from "./QuoteTemplateShareButton.generated.js";

interface Props extends ButtonProps {
  doc: QuoteTemplateShareButton_QuoteTemplateFragment;
}

export const QuoteTemplateShareButton = ({ doc, ...rest }: Props) => {
  const { t } = useTranslate(["TemplatesSharing", "Global"]);

  return doc.hasAnyPublishedVersion && !doc.sharedPublicly ? (
    <ModalOpenButton
      Modal={QuoteTemplateShareModal}
      modalProps={{
        docId: doc.id,
        doc,
      }}
    >
      <Button
        startIcon={rest.size !== "extra-small" ? <GroupAddIcon /> : undefined}
        variant="contained"
        color="primary"
        {...rest}
      >
        {t("Share", {
          ns: "TemplatesSharing",
        })}
      </Button>
    </ModalOpenButton>
  ) : null;
};

type ShareType = "public" | "protected";

interface FormValues {
  type: ShareType;
  companies: OrganisationAvatarFragment[];
}

const QuoteTemplateShareModal = ({
  docId,
  doc,
  handleComplete,
  handleClose,
}: {
  docId: string;
  doc: QuoteTemplateShareButton_QuoteTemplateFragment;
  handleComplete?: () => void;
  handleClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["TemplatesSharing", "Global"]);

  const client = useApolloClient();

  const [shareWithOrganisations] =
    useShareQuoteTemplateWithOrganisationsMutation({
      client,
    });
  const [sharePublicly] = useShareQuoteTemplatePubliclyMutation({
    client,
  });

  const formId = useMemo(() => uniqueId(), []);

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 10 });

  const initialValues: FormValues = {
    type: "protected",
    companies: [], //doc.sharedWithOrganisations,
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string().required(),
        companies: Yup.array()
          .test(
            "notEmptyInCaseOfProtected",
            t("Should not be empty", {
              ns: "TemplatesSharing",
            }),
            function (companies) {
              const type = this.resolve(Yup.ref("type")) as ShareType;
              if (
                type === "protected" &&
                (!companies || companies.length === 0)
              )
                return false;
              return true;
            }
          )
          .required(),
      }),
    [t]
  );

  const handleSubmit = async (values: FormValues) => {
    try {
      if (values.type === "public") {
        await sharePublicly({
          variables: {
            input: {
              quoteTemplateId: docId,
            },
          },
        });
      } else if (values.type === "protected") {
        await shareWithOrganisations({
          variables: {
            input: {
              quoteTemplateId: docId,
              sharedWithSystemOrganisationIds:
                values.type === "protected"
                  ? values.companies.map(c => c.id)
                  : [],
            },
          },
        });
      } else {
        throw new Error("invalid sharing type");
      }
      enqueueSnackbar(
        t("Sharing settings updated", {
          ns: "TemplatesSharing",
        })
      );
      handleComplete?.();
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting, dirty, isValid, values }) => (
        <Modal
          title={t("Sharing settings", {
            ns: "TemplatesSharing",
          })}
          dialogProps={{ maxWidth: "sm" }}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                loading: isSubmitting,
                form: formId,
                type: "submit",
                disabled: !dirty || !isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={2}>
              <Alert severity="warning" variant="outlined">
                {t(
                  "Once you share the template with an organisation or public, you can’t unshare it",
                  { ns: "TemplatesSharing" }
                )}
              </Alert>
              <RadioGroupField
                name="type"
                options={[
                  {
                    label: t("Public", {
                      ns: "TemplatesSharing",
                    }),
                    subLabel: t("Available to all organisations", {
                      ns: "TemplatesSharing",
                    }),
                    value: "public",
                  },
                  {
                    label: t("Protected", {
                      ns: "TemplatesSharing",
                    }),
                    subLabel: t("Available to selected organisations", {
                      ns: "TemplatesSharing",
                    }),
                    value: "protected",
                  },
                ]}
              />
              {values.type === "protected" && (
                <FieldArray
                  name="companies"
                  render={arrayHelpers => (
                    <>
                      {doc.sharedWithOrganisations.length +
                        values.companies.length >
                        0 && (
                        <DataGrid<
                          OrganisationAvatarFragment & { persisted?: boolean }
                        >
                          sx={{ border: "none" }}
                          density="standard"
                          hideFooter={
                            doc.sharedWithOrganisations.length +
                              values.companies.length ===
                            0
                          }
                          disableColumnFilter
                          columns={[
                            {
                              field: "companyName",
                              headerName: t("Craftsman company", {
                                ns: "TemplatesSharing",
                              }),
                              flex: 1,
                              minWidth: 100,
                              sortable: false,
                              renderCell: ({ row: item }) => (
                                <Stack
                                  alignItems="center"
                                  direction="row"
                                  spacing={1}
                                >
                                  <OrganisationAvatar
                                    size="s"
                                    organisationAvatar={item}
                                  />
                                  <Typography>{item.title}</Typography>
                                </Stack>
                              ),
                            },
                            {
                              field: "actions",
                              headerName: "",
                              flex: 0.1,
                              minWidth: 50,
                              sortable: false,
                              renderCell: ({ row: item }) => {
                                if (item.persisted) return null;

                                return (
                                  <IconButton
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      const index = values.companies.findIndex(
                                        c => c.id === item.id
                                      );
                                      if (index > -1)
                                        arrayHelpers.remove(index);
                                    }}
                                    size="small"
                                    color="primary"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                );
                              },
                            },
                          ]}
                          rows={[
                            ...doc.sharedWithOrganisations.map(org => ({
                              ...org,
                              persisted: true,
                            })),
                            ...values.companies,
                          ]}
                          paginationMode="client"
                          paginationModel={paginationModel}
                          onPaginationModelChange={newPaginationModel => {
                            setPaginationModel(newPaginationModel);
                          }}
                          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                        />
                      )}
                      {values.companies.length === 0 && (
                        <Stack direction="row" justifyContent="center" py={1}>
                          <Typography
                            variant="caption"
                            align="center"
                            sx={{ color: theme => theme.palette.grey[400] }}
                          >
                            {t("You have not added any companies yet.", {
                              ns: "TemplatesSharing",
                            })}
                          </Typography>
                        </Stack>
                      )}
                      <Stack direction="row" justifyContent="center">
                        <ModalOpenButton
                          Modal={CraftsmenSelectModal}
                          modalProps={{
                            loading: isSubmitting,
                            handleComplete: organisations => {
                              for (const organisation of organisations)
                                arrayHelpers.push(organisation);
                            },
                            title: t("Add companies", {
                              ns: "TemplatesSharing",
                            }),
                            omitOrganisationIds: [
                              ...doc.sharedWithOrganisations,
                              ...values.companies,
                            ].map(c => c.id),
                          }}
                        >
                          <Button
                            color="secondary"
                            variant="text"
                            startIcon={<AddIcon />}
                          >
                            {t("Add companies", {
                              ns: "TemplatesSharing",
                            })}
                          </Button>
                        </ModalOpenButton>
                      </Stack>
                    </>
                  )}
                />
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
