import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DebouncedSearchInput,
  MenuButton,
  ModalOpenButton,
  Tabs,
} from "@msys/ui";
import {
  FormControlLabel,
  Link,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { FilterButton } from "../../commons/filters/FilterButton.js";
import { FilterChipGroup } from "../../commons/filters/FilterChip.js";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam.js";
import { useStateWithUrlParams } from "../../commons/hooks/useStateWithUrlParams.js";
import { Stack } from "../../commons/layout/Stack.js";
import { ProjectBillOfMaterialsQueryVariables } from "../../main-routes/projects/ProjectBillOfMaterials.generated.js";
import {
  BillOfMaterialsFiltersModal,
  getQuoteLabel,
} from "./BillOfMaterialsFiltersModal.js";
import { useStatusLabels, useTypeLabels } from "./BillOfMaterialsItemStatus.js";
import { useBillOfMaterialsQuotesQuery } from "./useBillOfMaterialsFilters.generated.js";

export type QuickFilterValue = "ACCEPTED" | "PENDING" | "DRAFT";

type Filters = Omit<ProjectBillOfMaterialsQueryVariables, "projectId">;

export function useBillOfMaterialsFilters(projectId: string) {
  const { t } = useTranslate(["Materials", "Global"]);
  const { statusLabels } = useStatusLabels();
  const { typeLabels } = useTypeLabels();

  const client = useApolloClient();
  const query = useBillOfMaterialsQuotesQuery({
    client,
    variables: {
      projectId,
    },
    fetchPolicy: "cache-and-network",
  });

  const projectOutgoingQuotes =
    getDataOrNull(query.data?.projectOutgoingQuotes)?.edges?.map(e => e.node) ??
    [];

  const quickFilterSettings: Record<
    QuickFilterValue,
    {
      label: string;
      values: Omit<Filters, "aggregateProducts">;
    }
  > = React.useMemo(
    () => ({
      ACCEPTED: {
        label: t("Accepted items", {
          ns: "Materials",
        }),
        values: {
          filterQuoteItemStatus: "ACCEPTED",
        },
      },
      PENDING: {
        label: t("Pending items", {
          ns: "Materials",
        }),
        values: {
          filterQuoteItemStatus: "PENDING",
        },
      },
      DRAFT: {
        label: t("Draft items", {
          ns: "Materials",
        }),
        values: {
          filterQuoteItemStatus: "DRAFT",
        },
      },
    }),
    [t]
  );

  const [filters, setFilters] = useStateWithUrlParams<Filters>("filters", {
    filterType: ["product", "custom_product"],
    aggregateProducts: true,
  });

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<
    QuickFilterValue | undefined
  >("quickfilter", undefined);

  const OpenFiltersButton = (
    <ModalOpenButton
      Modal={BillOfMaterialsFiltersModal}
      modalProps={{
        filters,
        setFilters,
        filterOptions: {
          statuses: ["OPEN", "PARTIAL", "ORDERED"],
          quotes: projectOutgoingQuotes,
          types: ["custom_product", "product", "quote_item"],
        },
        resetFilters: () => setFilters({ aggregateProducts: true }),
      }}
    >
      <FilterButton />
    </ModalOpenButton>
  );

  const FilterChips =
    filters.filterStatus?.length ||
    filters.filterQuoteId?.length ||
    filters.filterType?.length ? (
      <Stack flexWrap="wrap" alignItems="center">
        <FilterChipGroup
          label={t("Status", {
            ns: "Materials",
          })}
          values={filters.filterStatus}
          getValueLabel={value => statusLabels[value]}
          setValues={value =>
            setFilters(state => ({ ...state, filterStatus: value }))
          }
        />
        <FilterChipGroup
          label={t("Type", {
            ns: "Materials",
          })}
          values={filters.filterType}
          getValueLabel={value => typeLabels[value]}
          setValues={value =>
            setFilters(state => ({ ...state, filterType: value }))
          }
        />
        <FilterChipGroup
          label={t("Quote", {
            ns: "Materials",
          })}
          values={filters.filterQuoteId}
          getValueLabel={value =>
            getQuoteLabel(
              projectOutgoingQuotes.find(quote => quote.id === value)!
            )
          }
          setValues={value =>
            setFilters(state => ({ ...state, filterQuoteId: value }))
          }
        />
        <Typography variant="body2">
          <Link
            onClick={() => {
              setFilters(state => ({
                ...state,
                filterQuoteId: undefined,
                filterType: undefined,
                filterStatus: undefined,
              }));
            }}
            color="secondary"
            sx={{ cursor: "pointer" }}
          >
            {t("Clear all", { ns: "Global" })}
          </Link>
        </Typography>
      </Stack>
    ) : undefined;

  const Search = (
    <DebouncedSearchInput
      placeholder={t("Search", {
        ns: "Global",
      })}
      defaultValue={filters.filterSearchTerm}
      onChangeSearchTerm={searchTerm =>
        setFilters(state => ({
          ...state,
          filterSearchTerm: searchTerm || undefined,
        }))
      }
    />
  );

  const _MenuButton = (
    <MenuButton>
      <MenuItem
        disableRipple
        onClick={(event: React.MouseEvent<HTMLLIElement>) => {
          event.stopPropagation();
        }}
      >
        <FormControlLabel
          style={{ marginRight: 0 }}
          control={
            <Switch
              size="small"
              checked={filters.aggregateProducts}
              onChange={() => {
                setFilters(state => ({
                  ...state,
                  aggregateProducts: !state.aggregateProducts,
                }));
              }}
              sx={{ marginLeft: "2px", marginRight: "4px" }}
            />
          }
          label={t("Aggregate products", { ns: "Materials" })}
          onClick={event => event.stopPropagation()}
        />
      </MenuItem>
    </MenuButton>
  );

  const QuickFilters = React.useCallback(
    ({
      activeQuickFilter,
    }: {
      activeQuickFilter: QuickFilterValue | undefined;
    }) => (
      <Tabs
        useSelectOnMobile
        options={Object.entries(quickFilterSettings).map(([key, value]) => {
          return { value: key as QuickFilterValue, label: value.label };
        })}
        value={activeQuickFilter}
        onChange={(newQuickFilter: QuickFilterValue) => {
          setQuickFilter(newQuickFilter);
        }}
        onDeselect={() => {
          setQuickFilter(undefined);
        }}
        notSelectedTitle={t("Not selected", { ns: "Global" })}
      />
    ),
    [quickFilterSettings, setQuickFilter, t]
  );

  return {
    filters,
    setFilters,
    activeQuickFilter: quickFilter,
    quickFilters: quickFilter
      ? quickFilterSettings?.[quickFilter]?.values
      : undefined,
    OpenFiltersButton,
    FilterChips,
    Search,
    MenuButton: _MenuButton,
    QuickFilters,
  };
}
