import { Stack } from "@mui/material";
import type { TFunction } from "@msys/tolgee";
import { isNil } from "lodash-es";
import React from "react";
import { ViewerShapeFragment } from "../../auth/UserDataProvider.generated.js";
import {
  FilterChip,
  FilterChipBoolean,
} from "../../commons/filters/FilterChip.js";
import { FilterPriceRangeChip } from "../../commons/filters/FilterPriceRangeChip.js";
import { FilterCrmCompaniesOrPersonsChips } from "../crm-companies/CrmCompaniesOrPersonsSelect.js";
import { FilterCrmCompanyChips } from "../crm-companies/CrmCompaniesSelect.js";
import { CrmCompanyFilterChip } from "../crm-companies/CrmCompanyFilterChip.js";
import { FilterCustomFieldsChips } from "../custom-fields/filters/index.js";
import { FilterOrganisationUsersChips } from "../organisations/OrganisationUsersSelect.js";
import {
  FilterProjectCategoryChips,
  FilterProjectStateChips,
  FilterProjectTypeChips,
} from "./filters/index.js";
import { FilterAssigneesChips } from "./filters/ProjectAssignees.js";
import { FilterProjectKindChip } from "./filters/ProjectKind.js";
import {
  ProjectFilterKind,
  ProjectStateMachineStatus,
} from "../../../clients/graphqlTypes.js";
import { FilterProjectPhasesChips } from "./filters/ProjectPhases.js";
import { FilterSourcesChips } from "./filters/ProjectSources.js";
import { Filters } from "./ProjectsList.js";

export function renderProjectsFilterChips<F extends Filters>({
  viewer,
  t,
  filters,
  setFilters,
  getFormattedPrice,
  getFormattedFloat,
  allowedStates,
  onKindChange,
  onPlanningChange,
  onStateChange,
  showRelevantToMe,
  showPhasesChip,
}: {
  viewer: ViewerShapeFragment;
  t: TFunction<"Projects" | "Global" | "Tickets" | "Opportunities">;
  filters: F;
  setFilters: React.Dispatch<React.SetStateAction<F>>;
  getFormattedPrice: (value: number | undefined | null) => string | undefined;
  getFormattedFloat: (value: number) => string;
  allowedStates: ProjectStateMachineStatus[];
  showRelevantToMe: boolean;
  showPhasesChip: boolean;
  onKindChange?: (value: ProjectFilterKind | null | undefined) => Partial<F>;
  onPlanningChange?: (value: boolean | null | undefined) => Partial<F>;
  onStateChange?: (
    value: ProjectStateMachineStatus[] | null | undefined
  ) => Partial<F>;
}) {
  return filters?.filterCategories?.length ||
    filters?.filterType?.length ||
    filters?.filterBuildingZip ||
    filters?.filterMinBudget ||
    filters?.filterMaxBudget ||
    filters?.filterWithinRadiusKm ||
    filters?.filterKind ||
    filters?.filterByCustomFields ||
    filters?.filterByCrmOrganisationId ||
    (showPhasesChip && filters?.filterPhaseIds?.length) ||
    filters?.filterByCrmLinkIds?.length ||
    filters?.filterUnassigned ||
    filters?.filterAssigneeIds?.length ||
    filters?.filterBySourceUserIds?.length ||
    filters?.filterBySourceCrmOrganisationIds?.length ||
    filters?.sources?.length ||
    (filters?.filterIncludeState?.length &&
      filters?.filterIncludeState?.length !== allowedStates.length) ||
    !isNil(filters.filterPlanned) ||
    !isNil(filters.filterUrgent) ||
    !isNil(filters.filterOverdue) ||
    (showRelevantToMe &&
      filters.filterRelevantToUserIds &&
      filters.filterRelevantToUserIds.length > 0 &&
      filters.filterRelevantToUserIds.includes(viewer.id)) ? (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      {showRelevantToMe &&
      filters.filterRelevantToUserIds &&
      filters.filterRelevantToUserIds.length > 0 &&
      filters.filterRelevantToUserIds.includes(viewer.id) ? (
        <FilterChip
          onDelete={() => {
            setFilters(filters => {
              let newRelevantIds = [...(filters.filterRelevantToUserIds ?? [])];
              if (newRelevantIds.includes(viewer.id))
                newRelevantIds = newRelevantIds.filter(id => id !== viewer.id);
              return {
                ...filters,
                filterRelevantToUserIds:
                  newRelevantIds.length > 0 ? newRelevantIds : undefined,
              };
            });
          }}
          value={t("Relevant to me", { ns: "Opportunities" })}
        />
      ) : null}
      <FilterProjectCategoryChips
        values={filters?.filterCategories}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterCategories: newValue,
          }))
        }
      />
      <FilterProjectTypeChips
        values={filters?.filterType}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterType: newValue,
          }))
        }
      />

      <FilterChip
        label={t("ZIP", { ns: "Projects" })}
        value={filters?.filterBuildingZip}
        resetValue={""}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterBuildingZip: newValue,
          }))
        }
      />

      {filters?.filterMinBudget || filters?.filterMaxBudget ? (
        <FilterPriceRangeChip
          label={t("Budget", { ns: "Projects" })}
          valueMin={filters?.filterMinBudget ?? null}
          valueMax={filters?.filterMaxBudget}
          onReset={() => {
            setFilters(f => ({
              ...f,
              filterMinBudget: null,
              filterMaxBudget: null,
            }));
          }}
        />
      ) : null}

      <FilterChip
        label={t("Proximity (km)", { ns: "Projects" })}
        value={filters?.filterWithinRadiusKm}
        resetValue={0}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterWithinRadiusKm: newValue,
          }))
        }
        getValueLabel={value => getFormattedFloat(value)}
      />

      {filters.filterUnassigned ? (
        <FilterChip
          value={t("Unassigned", { ns: "Projects" })}
          onDelete={() =>
            setFilters(filters => ({
              ...filters,
              filterUnassigned: undefined,
            }))
          }
        />
      ) : null}

      <FilterAssigneesChips
        values={filters?.filterAssigneeIds}
        setValues={newValue =>
          setFilters(filters => ({
            ...filters,
            filterAssigneeIds: newValue,
          }))
        }
      />

      {!isNil(filters.filterKind) && (
        <FilterProjectKindChip
          value={filters.filterKind}
          setValue={value =>
            setFilters(filters => ({
              ...filters,
              ...(onKindChange?.(value) ?? { filterKind: value }),
            }))
          }
        />
      )}

      {!isNil(filters.filterPlanned) && (
        <FilterChipBoolean
          label={t("Planned", { ns: "Projects" })}
          value={filters.filterPlanned}
          setValue={value =>
            setFilters(filters => ({
              ...filters,
              ...(onPlanningChange?.(value) ?? { filterPlanned: value }),
            }))
          }
        />
      )}

      {!isNil(filters.filterOverdue) && (
        <FilterChipBoolean
          label={t("Overdue", { ns: "Tickets" })}
          value={filters.filterOverdue}
          setValue={value =>
            setFilters(filters => ({
              ...filters,
              filterOverdue: value,
            }))
          }
        />
      )}

      {!isNil(filters.filterUrgent) && (
        <FilterChipBoolean
          label={t("Urgent", { ns: "Tickets" })}
          value={filters.filterUrgent}
          setValue={value =>
            setFilters(filters => ({
              ...filters,
              filterUrgent: value,
            }))
          }
        />
      )}

      <FilterProjectStateChips
        values={filters?.filterIncludeState}
        setValues={newValue =>
          setFilters(filters => ({
            ...filters,
            ...(onStateChange?.(newValue) ?? { filterIncludeState: newValue }),
          }))
        }
        allowedStates={allowedStates}
      />
      {showPhasesChip && (
        <FilterProjectPhasesChips
          values={filters?.filterPhaseIds}
          setValues={newValue =>
            setFilters(filters => ({
              ...filters,
              filterPhaseIds: newValue,
            }))
          }
          allowedStates={allowedStates}
        />
      )}
      <FilterCustomFieldsChips
        values={filters?.filterByCustomFields ?? []}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterByCustomFields: newValue,
          }))
        }
      />
      <CrmCompanyFilterChip
        value={filters?.filterByCrmOrganisationId}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterByCrmOrganisationId: newValue,
          }))
        }
        label={t("Client", { ns: "Projects" })}
      />
      <FilterCrmCompaniesOrPersonsChips
        value={filters?.filterByCrmLinkIds}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterByCrmLinkIds: newValue,
          }))
        }
        label={t("Contact", { ns: "Projects" })}
      />
      <FilterOrganisationUsersChips
        values={filters?.filterBySourceUserIds}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterBySourceUserIds: newValue,
          }))
        }
        label={t("Created by", { ns: "Projects" })}
      />
      <FilterCrmCompanyChips
        value={filters?.filterBySourceCrmOrganisationIds}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterBySourceCrmOrganisationIds: newValue,
          }))
        }
        label={t("Created by", { ns: "Projects" })}
      />
      <FilterSourcesChips
        values={filters?.sources}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            sources: newValue,
          }))
        }
      />
    </Stack>
  ) : undefined;
}
