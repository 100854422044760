/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { PurchaseOrderTitle_OrderFragmentDoc } from '../PurchaseOrderTitle.generated.js';
import { PurchaseOrderStatusBadge_OrderFragmentDoc } from '../PurchaseOrderStatusBadge.generated.js';
export type RelatedOrderItemFragment = { __typename: 'OrderItem', id: string, productTitle: string, quantityOrdered: number, quantityUnit: Types.QuantityUnit, unitPrice: number, order: { __typename: 'Order', id: string, number: string, orderedDate?: any | null, supplier?: { __typename: 'Company', id: string, title: string } | null } };

export const RelatedOrderItemFragmentDoc = gql`
    fragment RelatedOrderItem on OrderItem {
  id
  productTitle
  quantityOrdered
  quantityUnit
  unitPrice
  order {
    id
    number
    orderedDate
    supplier {
      id
      title
    }
    ...PurchaseOrderTitle_Order
    ...PurchaseOrderStatusBadge_Order
  }
}
    ${PurchaseOrderTitle_OrderFragmentDoc}
${PurchaseOrderStatusBadge_OrderFragmentDoc}`;