import { gql, useApolloClient } from "@apollo/client";
import { MenuItemWithIcon } from "@msys/ui";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import { useSupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation } from "./SupplierDiscountGroupVariantDeleteMenuButton.generated.js";

export const SupplierDiscountGroupVariantDeleteMenuButton = ({
  supplierDiscountGroupVariantId,
  refetchQueries,
}: {
  supplierDiscountGroupVariantId: string;
  refetchQueries?: string[];
}) => {
  const { t } = useTranslate(["SupplierDiscountGroups"]);

  const client = useApolloClient();

  const [deleteSupplierDiscountGroupVariantMutation, { loading }] =
    useSupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariantMutation(
      { client, refetchQueries }
    );

  return (
    <MenuItemWithIcon
      icon={<DeleteIcon />}
      onClick={async () => {
        await deleteSupplierDiscountGroupVariantMutation({
          variables: { input: { supplierDiscountGroupVariantId } },
        });
      }}
      disabled={!!loading}
    >
      {t("Delete variant", { ns: "SupplierDiscountGroups" })}
    </MenuItemWithIcon>
  );
};
