import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import { Box, DialogContentText, Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { noop, uniqueId } from "lodash-es";
import moment from "moment";
import { useSnackbar } from "notistack";
import { FC, useMemo } from "react";
import * as Yup from "yup";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField.js";
import { DurationField } from "../../../commons/form-fields/DurationField.js";
import { VirtualBareTreeStandaloneItem } from "../../../trees-virtual/components/VirtualBareTreeStandaloneItem.js";
import { createTreeSimpleItem } from "../../tasks/trees.js";
import {
  useCorrectTaskWorkSessionModalQuery,
  useCorrectWorkSessionMutation,
} from "./CorrectTaskWorkSessionModal.generated.js";

interface FormValues {
  date: moment.Moment;
  duration: number;
}

interface Props {
  projectId: string;
  docId: string;
  itemId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: () => void;
  correctionDate?: any;
  timeTrackingRequired: boolean;
}

export const CorrectTaskWorkSessionModal: FC<Props> = ({
  docId,
  itemId,
  projectId,
  title,
  handleClose,
  handleComplete,
  correctionDate,
  timeTrackingRequired,
}) => {
  const { t } = useTranslate(["Task", "Global"]);
  const client = useApolloClient();

  const { enqueueSnackbar } = useSnackbar();
  const formId = useMemo(() => uniqueId(), []);

  const now = useMemo(() => moment(), []);

  const query = useCorrectTaskWorkSessionModalQuery({
    client,
    variables: { projectId, itemId, docId },
    fetchPolicy: "cache-and-network",
  });
  const quote = getDataOrNull(query.data?.project)?.project?.tasks[0];
  const item = quote?.item[0];
  const workSessionItem = item;
  const allDocItems = quote?.allDocItems;

  const [correctWorkSession, { loading: correctWorkSessionLoading }] =
    useCorrectWorkSessionMutation({
      client,
      refetchQueries: [
        namedOperations.Query.TasksTimesheetTasksForm,
        namedOperations.Query.ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?,
      ],
    });

  const TreeItem = useMemo(
    () =>
      createTreeSimpleItem({
        pathToDocPage: null,
        docAgreement: "YES",
        navigateToItem: noop,
        setItemExpanded: noop,
      }),
    []
  );

  const initialValues: FormValues = {
    date: correctionDate ? moment(correctionDate) : now,
    duration: workSessionItem?.workSessionsDuration ?? 0,
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await correctWorkSession({
        variables: {
          input: {
            itemId,
            duration: values.duration,
            date: values.date.format("YYYY-MM-DD"),
          },
        },
      });
      enqueueSnackbar(
        t("Task work session updated", {
          ns: "Task",
        })
      );
      handleComplete?.();
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = Yup.object().shape({
    date: Yup.object()
      .label(
        t("Date of correction", {
          ns: "Task",
        })
      )
      .required(),
    duration: Yup.number()
      .label(
        t("New total time", {
          ns: "Task",
        })
      )
      .required(),
  });

  return (
    <Modal
      title={
        title ??
        t("Change total time", {
          ns: "Task",
        })
      }
      dialogProps={{ maxWidth: "xs" }}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          buttonProps: {
            type: "submit",
            form: formId,
            disabled: correctWorkSessionLoading,
          },
        },
      ]}
      handleClose={handleClose}
      isLoading={query.loading}
    >
      {timeTrackingRequired && (
        <DialogContentText>
          {t(
            "You are about to edit the total time for this task. A correction work session will be added to the task to account for the difference.",
            {
              ns: "Task",
            }
          )}
        </DialogContentText>
      )}
      {item && allDocItems && (
        <Box mb={3 / 2}>
          <VirtualBareTreeStandaloneItem
            item={{
              ...item,
              docViewerPermissions: quote.viewerPermissions,
              docAgreement: quote.agreement,
            }}
            items={allDocItems.map(item => ({
              ...item,
              docViewerPermissions: quote.viewerPermissions,
              docAgreement: quote.agreement,
            }))}
            depth={0}
            itemComponent={TreeItem}
          />
        </Box>
      )}
      {workSessionItem ? (
        <Formik<FormValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values }: FormikProps<FormValues>) => (
            <Form id={formId}>
              <Grid container spacing={1}>
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <DatePickerField
                    label={t("Date of correction", {
                      ns: "Task",
                    })}
                    name="date"
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <DurationField
                    required
                    name="duration"
                    label={t("New total time", {
                      ns: "Task",
                    })}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : null}
    </Modal>
  );
};
