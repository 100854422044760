/* eslint-disable */
import * as Types from '../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImplicitFlow_CreateNewOrganisationAndUserMutationVariables = Types.Exact<{
  input: Types.CreateOrganisationAndUserInput;
}>;


export type ImplicitFlow_CreateNewOrganisationAndUserMutation = { createOrganisationAndUser: { __typename: 'CreateOrganisationAndUserResult', user: { __typename: 'Viewer', id: string }, organisation: { __typename: 'ViewerOrganisation', id: string } } };

export type ImplicitFlow_AcceptProjectContracteeInvitationMutationVariables = Types.Exact<{
  input: Types.AcceptProjectContracteeInvitationInput;
}>;


export type ImplicitFlow_AcceptProjectContracteeInvitationMutation = { acceptProjectContracteeInvitation: { __typename: 'AcceptProjectContracteeInvitationResult', invitation: { __typename: 'ProjectContracteeInvitation', id: string } } };


export const ImplicitFlow_CreateNewOrganisationAndUserDocument = gql`
    mutation ImplicitFlow_CreateNewOrganisationAndUser($input: CreateOrganisationAndUserInput!) {
  createOrganisationAndUser(input: $input) {
    user {
      id
    }
    organisation {
      id
    }
  }
}
    `;
export type ImplicitFlow_CreateNewOrganisationAndUserMutationFn = Apollo.MutationFunction<ImplicitFlow_CreateNewOrganisationAndUserMutation, ImplicitFlow_CreateNewOrganisationAndUserMutationVariables>;

/**
 * __useImplicitFlow_CreateNewOrganisationAndUserMutation__
 *
 * To run a mutation, you first call `useImplicitFlow_CreateNewOrganisationAndUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImplicitFlow_CreateNewOrganisationAndUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [implicitFlowCreateNewOrganisationAndUserMutation, { data, loading, error }] = useImplicitFlow_CreateNewOrganisationAndUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImplicitFlow_CreateNewOrganisationAndUserMutation(baseOptions?: Apollo.MutationHookOptions<ImplicitFlow_CreateNewOrganisationAndUserMutation, ImplicitFlow_CreateNewOrganisationAndUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImplicitFlow_CreateNewOrganisationAndUserMutation, ImplicitFlow_CreateNewOrganisationAndUserMutationVariables>(ImplicitFlow_CreateNewOrganisationAndUserDocument, options);
      }
export type ImplicitFlow_CreateNewOrganisationAndUserMutationHookResult = ReturnType<typeof useImplicitFlow_CreateNewOrganisationAndUserMutation>;
export type ImplicitFlow_CreateNewOrganisationAndUserMutationResult = Apollo.MutationResult<ImplicitFlow_CreateNewOrganisationAndUserMutation>;
export type ImplicitFlow_CreateNewOrganisationAndUserMutationOptions = Apollo.BaseMutationOptions<ImplicitFlow_CreateNewOrganisationAndUserMutation, ImplicitFlow_CreateNewOrganisationAndUserMutationVariables>;
export const ImplicitFlow_AcceptProjectContracteeInvitationDocument = gql`
    mutation ImplicitFlow_AcceptProjectContracteeInvitation($input: AcceptProjectContracteeInvitationInput!) {
  acceptProjectContracteeInvitation(input: $input) {
    invitation {
      id
    }
  }
}
    `;
export type ImplicitFlow_AcceptProjectContracteeInvitationMutationFn = Apollo.MutationFunction<ImplicitFlow_AcceptProjectContracteeInvitationMutation, ImplicitFlow_AcceptProjectContracteeInvitationMutationVariables>;

/**
 * __useImplicitFlow_AcceptProjectContracteeInvitationMutation__
 *
 * To run a mutation, you first call `useImplicitFlow_AcceptProjectContracteeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImplicitFlow_AcceptProjectContracteeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [implicitFlowAcceptProjectContracteeInvitationMutation, { data, loading, error }] = useImplicitFlow_AcceptProjectContracteeInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImplicitFlow_AcceptProjectContracteeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ImplicitFlow_AcceptProjectContracteeInvitationMutation, ImplicitFlow_AcceptProjectContracteeInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImplicitFlow_AcceptProjectContracteeInvitationMutation, ImplicitFlow_AcceptProjectContracteeInvitationMutationVariables>(ImplicitFlow_AcceptProjectContracteeInvitationDocument, options);
      }
export type ImplicitFlow_AcceptProjectContracteeInvitationMutationHookResult = ReturnType<typeof useImplicitFlow_AcceptProjectContracteeInvitationMutation>;
export type ImplicitFlow_AcceptProjectContracteeInvitationMutationResult = Apollo.MutationResult<ImplicitFlow_AcceptProjectContracteeInvitationMutation>;
export type ImplicitFlow_AcceptProjectContracteeInvitationMutationOptions = Apollo.BaseMutationOptions<ImplicitFlow_AcceptProjectContracteeInvitationMutation, ImplicitFlow_AcceptProjectContracteeInvitationMutationVariables>;