import { useApolloClient } from "@apollo/client";
import { Capacitor } from "@capacitor/core";
import { styled, CircularProgress } from "@mui/material";
import PullToRefresh from "react-simple-pull-to-refresh";

export function PagePullToRefresh({ children }: { children: JSX.Element }) {
  const client = useApolloClient();

  if (!Capacitor.isNativePlatform()) return children;

  return (
    <StyledPullToRefresh
      refreshingContent={
        <CircularProgress
          size={24}
          color="primary"
          sx={{ marginTop: "24px" }}
        />
      }
      pullingContent={<></>}
      onRefresh={client.reFetchObservableQueries}
      canFetchMore={false}
      isPullable={true}
      pullDownThreshold={60}
      fetchMoreThreshold={0}
    >
      {children}
    </StyledPullToRefresh>
  );
}

const StyledPullToRefresh = styled(PullToRefresh)({
  "&.ptr, &.ptr > .ptr__children": {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
});
