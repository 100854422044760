import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { debounce } from "lodash-es";
import React from "react";
import {
  TemplateShareStatus,
  TplApplicableFor,
} from "../../../../clients/graphqlTypes.js";
import { highlightWithBold } from "../../../utils.js";
import { useTemplateQuoteSelectFieldLazyQuery } from "./TemplateQuoteSelectField.generated.js";

interface Value {
  id: string;
  title: string;
  owningSystemOrganisationId: string;
}

interface Props {
  inputLabel: string;
  filterDocumentType: TplApplicableFor;
  filterShareStatus?: TemplateShareStatus;
  value: Value | null;
  onChange: (value: Value | null) => Promise<void> | void;
  required?: boolean;
  disabled?: boolean;
  error?: string;
}

export function TemplateQuoteSelectField({
  inputLabel,
  filterDocumentType,
  filterShareStatus,
  value,
  onChange,
  required,
  disabled,
  error,
}: Props) {
  const { t } = useTranslate(["Global"]);

  const [inputValue, setInputValue] = React.useState("");

  const {
    highlightText,
    quoteTemplates,
    debouncedRefetch,
    loading,
    totalCount,
  } = useTemplates(value, inputValue, setInputValue, {
    filterDocumentType,
    filterShareStatus,
  });

  return (
    <Autocomplete<Value>
      inputLabel={inputLabel}
      placeholder={`${t("Type to search", { ns: "Global" })}...`}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
          debouncedRefetch(value);
          if (value) onChange(null);
        }
      }}
      options={[
        ...quoteTemplates,
        ...(totalCount > quoteTemplates.length
          ? [
              {
                title: t("Type to see more...", { ns: "Global" }),
                id: "__type_to_see_more__" as const,
                owningSystemOrganisationId: "__type_to_see_more__" as const,
              },
            ]
          : []),
      ]}
      renderOption={(props, option) =>
        "__typename" in option ? (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        ) : (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        )
      }
      getOptionDisabled={option => option.id === "__type_to_see_more__"}
      getOptionLabel={option => option.title}
      filterOptions={option => option}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      loading={loading}
      onChange={value => {
        if (value && "__typename" in value) {
          setInputValue(value?.title ?? "");
          onChange(value);
        } else if (!value) {
          setInputValue("");
          onChange(null);
        }
      }}
      required={required}
      disabled={disabled}
      error={error}
    />
  );
}

const LIMIT = 10;

export function useTemplates(
  value: Value | null,
  inputValue: string,
  setInputValue: (value: string) => void,
  filters: {
    filterDocumentType: TplApplicableFor;
    filterShareStatus: TemplateShareStatus | undefined;
  }
) {
  const client = useApolloClient();
  const [fetchQuery, query] = useTemplateQuoteSelectFieldLazyQuery({
    client,
    variables: {
      limit: LIMIT,
      filterApplicableFor: filters.filterDocumentType,
      filterShareStatus: filters.filterShareStatus,
    },
  });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (searchTerm: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          filterApplicableFor: filters.filterDocumentType,
          filterShareStatus: filters.filterShareStatus,
          searchTerm: searchTerm,
        },
      });
    },
    [filters.filterDocumentType, filters.filterShareStatus, fetchQuery]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (value) {
        const result = await fetchQuery({
          variables: {
            limit: 1,
            filterApplicableFor: filters.filterDocumentType,
            filterShareStatus: filters.filterShareStatus,
            searchTerm: null,
            filterIds: [value.id],
          },
        });
        setInputValue(
          getDataOrNull(result.data?.quoteTemplates)?.edges[0].node.title ?? ""
        );
      } else {
        await fetchQuery({
          variables: {
            limit: LIMIT,
            filterApplicableFor: filters.filterDocumentType,
            filterShareStatus: filters.filterShareStatus,
            searchTerm: inputValueRef.current,
            filterIds: null,
          },
        });
      }
    })();
  }, [
    value,
    filters.filterDocumentType,
    filters.filterShareStatus,
    fetchQuery,
    setInputValue,
  ]);

  const quoteTemplates =
    getDataOrNull(
      (query.data ?? query.previousData)?.quoteTemplates
    )?.edges.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.quoteTemplates)
      ?.totalCount ?? 0;

  const highlightText = React.useCallback(
    (text: string | null) =>
      text && query?.variables?.searchTerm
        ? highlightWithBold(text, query.variables.searchTerm)
        : text,
    [query?.variables?.searchTerm]
  );

  return {
    highlightText,
    quoteTemplates,
    refetch,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.searchTerm &&
          query?.variables?.searchTerm !== inputValue
      ),
    totalCount,
  };
}
