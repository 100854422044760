import { OrganisationType } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

export function useOrganisationTypes() {
  const { t } = useTranslate("OrganisationProfile");

  const organisationTypeLabels: Record<OrganisationType, string> = {
    CLIENT: t("Client"),
    CRAFTSMAN: t("Craftsman"),
    SUPPLIER: t("Supplier"),
    MANUFACTURER: t("Manufacturer"),
  };

  return { organisationTypeLabels };
}
