/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type RequestContactBoxFragment = { __typename: 'Request', id: string, requestorDoc: { __typename: 'Requirement', id: string, contractee: { __typename: 'DocActor', id: string, companyOrIndividual: Types.DocActorCompanyOrIndividual, companyName: string, salutation: Types.Salutation, firstname: string, familyname: string, contact?: { __typename: 'DocActorContact', salutation: Types.Salutation, firstname: string, familyname: string } | null } } };

export const RequestContactBoxFragmentDoc = gql`
    fragment RequestContactBox on Request {
  id
  requestorDoc {
    id
    contractee {
      id
      companyOrIndividual
      companyName
      salutation
      firstname
      familyname
      contact {
        salutation
        firstname
        familyname
      }
    }
  }
}
    `;