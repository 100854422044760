/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { ItemAttachmentFragmentDoc } from '../attachments/Attachments.generated.js';
import { IsItemGreyedOut_ItemFragmentDoc } from './helpers.generated.js';
export type DecisionOptionGalleryItem_ItemFragment = { __typename: 'Item', id: string, title: string, pendingChangeAttributes: any, isParentContingencyNotPreselected: boolean, isParentDecisionNotPreselected: boolean, decisionOptionIsPreselected?: boolean | null, attachments: Array<{ __typename: 'ItemAttachment', id: string, title: string, mimeType: string, url: string, group: string, clientVisibility: boolean, isProductAttachment: boolean }>, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null };

export const DecisionOptionGalleryItem_ItemFragmentDoc = gql`
    fragment DecisionOptionGalleryItem_Item on Item {
  id
  title
  pendingChangeAttributes
  attachments {
    ...ItemAttachment
  }
  decisionOptionElimination {
    reason
  }
  ...IsItemGreyedOut_Item
}
    ${ItemAttachmentFragmentDoc}
${IsItemGreyedOut_ItemFragmentDoc}`;