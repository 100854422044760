import { faClone } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { Icon } from "@mui/material";
import React from "react";
import { DocType, ItemType } from "../../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";
import { useDocItemsClipboard } from "../hooks/useDocItemsClipboard.js";

interface Props {
  docId: string;
  docType: DocType;
  projectId: string | null;
  itemId: string;
  itemType: ItemType;
  disabled?: boolean;
}

export const CopyItemIntoClipboardMenuItem = React.forwardRef<
  HTMLLIElement,
  Props
>(({ docId, docType, projectId, itemId, itemType, disabled }, ref) => {
  const { t } = useTranslate("QuoteItem");
  const [, setContent] = useDocItemsClipboard();
  return (
    <MenuItemWithIcon
      ref={ref}
      icon={
        <Icon>
          <FontAwesomeIcon icon={faClone} />
        </Icon>
      }
      disabled={disabled}
      onClick={() => {
        setContent({
          projectId,
          docId,
          docType,
          itemId,
          itemType,
        });
      }}
    >
      {t("Copy item (with subitems)")}
    </MenuItemWithIcon>
  );
});
