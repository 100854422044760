import { useApolloClient } from "@apollo/client";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { CircularProgress, Icon } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { ExportAsGaebType } from "../../../../clients/graphqlTypes.js";
import { useGlobalLoadingCounter } from "../../../../common/global-loading-state/useGlobalLoadingCounter.js";
import { useExportAsGaebMutation } from "./GaebExport.generated.js";

interface Props {
  docId: string;
  projectId: string;
  title?: string;
  type: ExportAsGaebType;
}

export const ExportAsGaebMenuItem = React.forwardRef<HTMLLIElement, Props>(
  (props, ref) => {
    const { t } = useTranslate("QuoteItem");

    const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

    const { enqueueSnackbar } = useSnackbar();
    const exportAsGaeb = useExportAsGaeb();

    const { increment, decrement } = useGlobalLoadingCounter();

    return (
      <MenuItemWithIcon
        ref={ref}
        icon={
          status !== "loading" ? (
            <Icon>
              <FontAwesomeIcon icon={faFileExport} />
            </Icon>
          ) : (
            <CircularProgress size={"1rem"} />
          )
        }
        onClick={async () => {
          try {
            increment();
            setStatus("loading");
            await exportAsGaeb(props.projectId, props.docId, props.type);
            setStatus("idle");
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
            Sentry.captureException(e);
            setStatus("error");
          } finally {
            decrement();
          }
        }}
        disabled={status === "loading"}
      >
        {props.title ?? `${t("Export as")} GAEB ${props.type}`}
      </MenuItemWithIcon>
    );
  }
);

const useExportAsGaeb = () => {
  const client = useApolloClient();
  const [exportAsGaebMutation] = useExportAsGaebMutation({
    client,
  });

  return useCallback(
    async (projectId: string, docId: string, type: ExportAsGaebType) => {
      const exportAsGaebResult = await exportAsGaebMutation({
        variables: {
          input: { docId, projectId, type },
        },
      });

      if (exportAsGaebResult) {
        download(exportAsGaebResult.data?.exportAsGaeb.downloadUrl ?? "");
      }
    },
    [exportAsGaebMutation]
  );
};

function download(url: string) {
  const elem = window.document.createElement("a");
  elem.href = url;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}
