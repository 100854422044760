/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type OrganisationPublicAboutBox_OrganisationProfileFragment = { __typename: 'OrganisationProfile', id: string, about?: any | null, yearFounded?: number | null, employeesNumber?: number | null, isMeister: boolean, slug: string, organisation: { __typename: 'Organisation', id: string } };

export const OrganisationPublicAboutBox_OrganisationProfileFragmentDoc = gql`
    fragment OrganisationPublicAboutBox_OrganisationProfile on OrganisationProfile {
  id
  about
  yearFounded
  employeesNumber
  isMeister
  organisation {
    id
  }
  slug
}
    `;