import { gql } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { Link as MuiLink } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link } from "react-router-dom";
import { RequestOverviewProjectIncomingQuoteModal } from "../../requests/modals/RequestOverviewModal.js";
import { useProjectSources } from "../useProjectSources.js";
import { ProjectSourceEntity_ProjectSourceFragment } from "./ProjectSourceEntity.generated.js";

interface Props {
  projectId: string;
  projectSource: ProjectSourceEntity_ProjectSourceFragment;
}

export const ProjectSourceEntity = ({ projectId, projectSource }: Props) => {
  const { t } = useTranslate(["ProjectSources", "Global"]);
  const { projectSourcePageNameLabels } = useProjectSources();
  switch (projectSource.source) {
    case "Flow3D":
    case "FlowM1":
    case "FlowWizard":
      return (
        <>
          {t("Form submission", { ns: "ProjectSources" })}
          {projectSource.flowTemplateId &&
          projectSource.flowTemplateVersionCounter &&
          projectSource.flowTemplateTitle ? (
            <>
              {" – "}
              <MuiLink
                component={Link}
                to={`/templates/documents/${projectSource.flowTemplateId}/${projectSource.flowTemplateVersionCounter}`}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {projectSource.flowTemplateTitle}
              </MuiLink>
            </>
          ) : null}
        </>
      );
    case "Import":
      return (
        <>
          {projectSource.importFileName ??
            t("File import", { ns: "ProjectSources" })}
        </>
      );
    case "Manual":
      if (!projectSource.manualPageName) throw new Error("No manual page name");
      return <>{projectSourcePageNameLabels[projectSource.manualPageName]}</>;
    case "RequestAutomated":
    case "RequestManual":
      if (!projectSource.requestId) throw new Error("No request id");
      if (!projectSource.requestTitle) throw new Error("No request title");
      return (
        <ModalOpenButton
          Modal={RequestOverviewProjectIncomingQuoteModal}
          modalProps={{
            requestId: projectSource.requestId,
            projectId,
          }}
        >
          <MuiLink
            component={Link}
            to={`/projects/${projectId}`}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {projectSource.requestTitle}
          </MuiLink>
        </ModalOpenButton>
      );
    case "QuoteProductsDistribution":
      return <>{t("Article list", { ns: "ProjectSources" })}</>;
    // if (!projectSource.quoteProductsDistributionId)
    //   throw new Error("No article list id");
    // return (
    //   <MuiLink
    //     component={Link}
    //     to={`/opportunities/article-lists/${projectSource.quoteProductsDistributionId}`}
    //     onClick={e => {
    //       e.stopPropagation();
    //     }}
    //   >
    //     {t("Article list", { ns: "ProjectSources" })}
    //   </MuiLink>
    // );
    case "ContracteeInvitation":
      return <>{t("Contractor", { ns: "ProjectSources" })}</>;
    case "Legacy":
      return <>{"–"}</>;
  }
};
