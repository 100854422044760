import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { replaceInText } from "@msys/textutils";
import { Modal, Select } from "@msys/ui";
import { Send as SendIcon } from "@mui/icons-material";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData.js";
import { EmailField } from "../../../commons/form-fields/EmailField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import {
  useCrmCompanyInviteModalQuery,
  useCrmCompanyInviteModal__ModifyCrmCompanyMutation,
  useSendCrmLinkingInvitationMutation,
} from "./CrmCompanyInviteModal.generated.js";
import { useTranslate } from "@tolgee/react";
import { useEmailAddressValidationSchema } from "../../email/validateEmailAddress.js";
import { useSalutation } from "../../users/useSalutation.js";

interface FormValues {
  email: string;
  message: string;
}

interface Props {
  crmCompanyId: string;
  title?: string;
  refetchQueries: string[];
  handleClose: () => void;
  handleComplete?: () => Promise<void> | void;
}

export const CrmCompanyInviteModal = ({
  crmCompanyId,
  title,
  refetchQueries,
  handleClose,
  handleComplete,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["CrmOrganisations", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const { getNameWithSalutation } = useSalutation();

  const { createEmailAddressValidationSchema } =
    useEmailAddressValidationSchema();

  const [inviteeId, setInviteeId] = React.useState<string | null>(null);

  const client = useApolloClient();
  const query = useCrmCompanyInviteModalQuery({
    client,
    variables: {
      id: crmCompanyId,
    },
  });
  const crmCompany = getDataOrNull(query.data?.crmCompany)?.crmCompany;

  React.useEffect(() => {
    if (crmCompany) {
      const inviteeId =
        crmCompany?.contactPerson?.id ?? crmCompany?.members[0]?.id ?? null;
      setInviteeId(inviteeId);
    }
  }, [crmCompany]);

  const [modifyCrmOrganisation] =
    useCrmCompanyInviteModal__ModifyCrmCompanyMutation({
      client,
    });
  const [sendCrmLinkingInvitation] = useSendCrmLinkingInvitationMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const onSubmit = async (values: FormValues) => {
    if (!crmCompany) return;

    if (!crmCompany?.email) {
      await modifyCrmOrganisation({
        variables: {
          crmCompanies: [
            {
              id: crmCompany.id,
              email: values.email,
            },
          ],
        },
      });
    }

    await sendCrmLinkingInvitation({
      variables: {
        input: {
          crmOrganisationId: crmCompany.id,
          crmPersonId: inviteeId,
          messageBody: values.message,
          messageSubject: t("You are invited on MeisterSystems!", {
            ns: "CrmOrganisations",
          }),
        },
      },
    });

    handleClose();
    await handleComplete?.();
    enqueueSnackbar(
      t("Invitation sent out", {
        ns: "CrmOrganisations",
      })
    );
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: createEmailAddressValidationSchema(
          t("Email address", {
            ns: "CrmOrganisations",
          }),
          true
        ),
        message: Yup.string()
          .label(
            t("Personal message", {
              ns: "CrmOrganisations",
            })
          )
          .required(),
      }),
    [createEmailAddressValidationSchema, t]
  );

  const invitee = crmCompany?.members.find(member => member.id === inviteeId);

  const initialValues = {
    email: (invitee?.email || crmCompany?.email) ?? "",
    message: replaceInText(
      t("Dear [NAME], Join us on MeisterSystems! Kind Regards, {authorName}", {
        ns: "CrmOrganisations",
        authorName: viewer.firstname + " " + viewer.familyname,
      }),
      {
        "[NAME]": invitee
          ? getNameWithSalutation(
              invitee.title || "mr",
              invitee.firstname || "",
              invitee.familyname || ""
            )
          : (crmCompany?.title ?? ""),
      }
    ),
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting }: FormikProps<FormValues>) => (
        <Modal
          id="crm-company-invite-modal"
          title={
            title ??
            t("Invite on MeisterSystems", {
              ns: "CrmOrganisations",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Invite", {
                ns: "CrmOrganisations",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                color: "primary",
                endIcon: <SendIcon />,
                loading: isSubmitting,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack flexDirection="column">
              {crmCompany?.members && crmCompany.members.length > 1 && (
                <Select
                  label={t("Contact", {
                    ns: "CrmOrganisations",
                  })}
                  options={crmCompany?.members.map(member => ({
                    label: member.firstname,
                    value: member.id,
                  }))}
                  value={inviteeId}
                  onChange={value => setInviteeId(value)}
                />
              )}
              <EmailField
                name="email"
                label={t("Email address", {
                  ns: "CrmOrganisations",
                })}
                required
              />
              <Field
                name="message"
                label={t("Personal message", {
                  ns: "CrmOrganisations",
                })}
                required
                component={TextField}
                multiline
                rows={7}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
