import { gql, useApolloClient } from "@apollo/client";
import { FileInput } from "../../../commons/inputs/FileInput.js";
import { useRequestImportGaebUploadUrlMutation } from "./GaebPFileUploader.generated.js";

export const GaebFileUploader = (props: {
  innerRef: any;
  onUploadFile: (file: File) => Promise<void>;
  onCancel?: () => void;
  onStart?: () => void;
  onError?: (e: any) => void;
  type: "p84" | "p94";
}) => {
  const client = useApolloClient();

  const [requestImportUploadUrlMutation] =
    useRequestImportGaebUploadUrlMutation({
      client,
    });

  let acceptType = "";

  if (props.type === "p84") {
    acceptType = ".p84,.P84";
  }
  if (props.type === "p94") {
    acceptType = ".p94,.P94";
  }

  return (
    <FileInput
      innerRef={props.innerRef}
      accept={acceptType}
      multiple={false}
      onStart={props.onStart}
      onCancel={props.onCancel}
      onComplete={async file => {
        const docImportUrlResult = await requestImportUploadUrlMutation({
          variables: {
            input: {
              filename: file.name,
            },
          },
        });

        await fetch(
          docImportUrlResult.data!.requestGaebImportUploadUrl.uploadUrl,
          {
            method: "PUT",
            body: file,
          }
        ).catch(err => {
          console.info(err);
        });

        if (props.innerRef.current) {
          props.innerRef.current.value = "";
        }

        await props.onUploadFile(file);
      }}
    />
  );
};
