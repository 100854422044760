/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCrmPersonMutationVariables = Types.Exact<{
  input: Array<Types.CrmUserCreateInput>;
}>;


export type CreateCrmPersonMutation = { createCrmUser: { __typename: 'CrmUserCreateResult', users: Array<{ __typename: 'CrmPerson', id: string, fullname: string, email: any }> } };


export const CreateCrmPersonDocument = gql`
    mutation CreateCrmPerson($input: [CrmUserCreateInput!]!) {
  createCrmUser(input: $input) {
    users {
      id
      fullname
      email
    }
  }
}
    `;
export type CreateCrmPersonMutationFn = Apollo.MutationFunction<CreateCrmPersonMutation, CreateCrmPersonMutationVariables>;

/**
 * __useCreateCrmPersonMutation__
 *
 * To run a mutation, you first call `useCreateCrmPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCrmPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCrmPersonMutation, { data, loading, error }] = useCreateCrmPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCrmPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreateCrmPersonMutation, CreateCrmPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCrmPersonMutation, CreateCrmPersonMutationVariables>(CreateCrmPersonDocument, options);
      }
export type CreateCrmPersonMutationHookResult = ReturnType<typeof useCreateCrmPersonMutation>;
export type CreateCrmPersonMutationResult = Apollo.MutationResult<CreateCrmPersonMutation>;
export type CreateCrmPersonMutationOptions = Apollo.BaseMutationOptions<CreateCrmPersonMutation, CreateCrmPersonMutationVariables>;