import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useClipboard } from "../commons/hooks/useClipboard.js";
import { useSelectedUser } from "./useSelectedUser.js";

interface Props {
  fullWidth?: boolean;
}

export const SwicthOrganisationButton = ({ fullWidth = false }: Props) => {
  const { t } = useTranslate(["Global"]);
  const [, setClipboard] = useClipboard();
  const { setSelectedUserId } = useSelectedUser();

  return (
    <Button
      variant="outlined"
      color="secondary"
      fullWidth={fullWidth}
      onClick={async () => {
        setClipboard({});
        setSelectedUserId(null);
      }}
    >
      {t("Switch organisation", { ns: "Global" })}
    </Button>
  );
};
