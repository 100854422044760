import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { MenuButton, MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Container,
  Divider,
  IconButton,
  Button as MuiButton,
  Paper,
  SvgIconProps,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { DeliveryAddressType } from "../../../clients/graphqlTypes.js";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission.js";
import { EntityNumber } from "../../commons/EntityNumber.js";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page.js";
import { PageContainer } from "../../commons/layout/PageContainer.js";
import { PageHeader } from "../../commons/layout/PageHeader.js";
import { PageSubHeader } from "../../commons/layout/PageSubHeader.js";
import { Stack } from "../../commons/layout/Stack.js";
import { ConfirmModal } from "../../commons/modals/ConfirmModal.js";
import { AddressWithBreaks } from "../../features/addresses/AddressWithBreaks.js";
import { AddressDetails__AddressFragment } from "../../features/addresses/Addresses.generated.js";
import { ClosingTextInput } from "../../features/purchase-orders/PurchaseOrderClosingText.js";
import { OpeningTextInput } from "../../features/purchase-orders/PurchaseOrderOpeningText.js";
import { SelectSupplierAndDeliveryModal } from "../../features/purchase-orders/PurchaseOrderSelectSupplierAndDeliveryModal.js";
import { PurchaseOrderStatusBadge } from "../../features/purchase-orders/PurchaseOrderStatusBadge.js";
import { PurchaseOrderTitle } from "../../features/purchase-orders/PurchaseOrderTitle.js";
import { IdsConnectOrderButton } from "../../features/purchase-orders/buttons/IdsConnectOrderButton.js";
import { PurchaseOrderDownloadButton } from "../../features/purchase-orders/buttons/PurchaseOrderDownloadButton.js";
import { PurchaseOrderHeader } from "../../features/purchase-orders/preview/PurchaseOrderHeader.js";
import { PurchaseOrderItemsTable } from "../../features/purchase-orders/preview/PurchaseOrderItemsTable.js";
import { PurchaseOrderSum } from "../../features/purchase-orders/preview/PurchaseOrderSum.js";
import { useDeletePurchaseOrderMutation } from "./ProjectPurchaseOrderEdit.generated.js";
import {
  ModifyOrderMutationFn,
  useModifyOrderMutation,
  usePurchaseOrderIdsConnectAvailabilityLazyQuery,
  usePurchaseOrderQuery,
  useSubmitOrderMutation,
} from "./ProjectPurchaseOrderPreview.generated.js";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectPurchaseOrderPreview = ({
  projectId,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: Props) => {
  const { orderId } = useParams();
  if (!orderId) throw new Error("order id is missing");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate(["PurchaseOrders", "Global"]);

  const [isUploadingFile, setIsUploadingFile] = React.useState<boolean>(false);

  const client = useApolloClient();
  const query = usePurchaseOrderQuery({
    client,
    variables: {
      projectId,
      orderId,
    },
    fetchPolicy: "cache-and-network",
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const order = project?.order;
  const contractor = query.data?.viewer?.organisation;

  const [fetchIdsConnectAvailability, idsConnectAvailabilityQuery] =
    usePurchaseOrderIdsConnectAvailabilityLazyQuery({
      client,
    });
  const idsConnectAvailability =
    idsConnectAvailabilityQuery.data?.idsConnectAvailability;

  React.useEffect(() => {
    if (order?.crmSupplier?.linkedSystemOrganisationId) {
      fetchIdsConnectAvailability({
        variables: {
          msOrganisationId: order.crmSupplier.linkedSystemOrganisationId,
        },
      });
    }
  }, [fetchIdsConnectAvailability, order]);

  const [deletePurchaseOrder] = useDeletePurchaseOrderMutation({
    client,
    variables: { orderId },
  });

  const [modifyOrder] = useModifyOrderMutation();
  const [submitOrder, { loading: submitOrderLoading }] =
    useSubmitOrderMutation();

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Purchase Orders", {
          ns: "PurchaseOrders",
        }),
        to: `/projects/${projectId}/materials/purchase-orders`,
      },
      {
        title: order ? <PurchaseOrderTitle order={order} /> : "",
        to: `/projects/${projectId}/materials/purchase-orders/${orderId}`,
      },
    ],
    [prefixBreadcrumbs, t, projectId, order, orderId]
  );

  const Title = React.useMemo(
    () => (order ? <PurchaseOrderTitle order={order} /> : ""),
    [order]
  );

  if (order && !order.crmSupplier) {
    return (
      <Navigate
        to={`/projects/${projectId}/materials/purchase-orders/${orderId}/edit`}
        replace
      />
    );
  }

  const handleSaveOrderText = async (content: any) => {
    try {
      await modifyOrder({
        variables: {
          input: {
            orderId,
            purchaseOrderTexts: content,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const handleSubmitOrder = async () => {
    try {
      await submitOrder({
        variables: {
          orderId,
        },
      });

      enqueueSnackbar(
        t("Document marked as ordered", {
          ns: "PurchaseOrders",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
    }
  };

  const actions = [];

  if (project && order) {
    actions.push(
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_QUOTES"
        project={project}
        key="download-pdf"
      >
        <PurchaseOrderDownloadButton
          orderId={orderId}
          orderFile={order.file}
          projectId={projectId}
        />
      </RestrictedByProjectPermissionWithDebug>
    );
  }
  if (order && !order.orderedDate) {
    actions.push(
      <MenuButton key="more-menu">
        <ModalOpenButton
          Modal={ConfirmModal}
          modalProps={{
            handleConfirm: async () => {
              await deletePurchaseOrder();
              navigate(`/projects/${projectId}/materials/purchase-orders`);
              enqueueSnackbar(
                t("Purchase order deleted", {
                  ns: "PurchaseOrders",
                })
              );
            },
          }}
        >
          <MenuItemWithIcon icon={<DeleteIcon />}>
            {t("Delete", {
              ns: "Global",
            })}
          </MenuItemWithIcon>
        </ModalOpenButton>
      </MenuButton>
    );
  }

  return (
    <Page
      subtitle={project?.title}
      title={Title}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && order ? (
          <PageSubHeader
            title={<PurchaseOrderTitle order={order} />}
            status={<PurchaseOrderStatusBadge order={order} />}
            actionButtons={actions}
            entityNumber={<EntityNumber number={order.number} />}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
          />
        ) : undefined
      }
    >
      <PageContainer>
        <Container maxWidth="md">
          {project && order && contractor && (
            <Stack flexDirection="column" width="100%">
              <PurchaseOrderHeader
                project={project}
                contractor={contractor}
                crmSupplier={order.crmSupplier}
                author={order.author}
                canEdit={!order.orderedDate}
                orderNumber={order.number}
                orderedDate={order.orderedDate}
                ownClientId={order.ownClientId}
                recipientActionButtons={
                  !order.orderedDate && (
                    <EditSupplierAndDeliveryButton
                      projectId={projectId}
                      orderId={orderId}
                      supplierId={order.crmSupplier?.id}
                      deliveryAddress={order.deliveryAddress}
                      deliveryAddressType={order.deliveryAddressType}
                      modifyOrder={modifyOrder}
                      canEditSupplier={true}
                    />
                  )
                }
              />

              <Paper>
                <Box padding={2}>
                  <Typography variant="h2">
                    <PurchaseOrderTitle order={order} />
                  </Typography>
                </Box>
              </Paper>

              <OpeningTextInput
                canEdit={!order.orderedDate}
                content={order.purchaseOrderTexts?.opening}
                onSave={handleSaveOrderText}
                showInfo={false}
              />

              <Alert
                variant="outlined"
                severity="info"
                color="info"
                action={
                  !order.orderedDate && (
                    <EditSupplierAndDeliveryButton
                      projectId={projectId}
                      orderId={orderId}
                      supplierId={order.crmSupplier?.id}
                      deliveryAddress={order.deliveryAddress}
                      deliveryAddressType={order.deliveryAddressType}
                      modifyOrder={modifyOrder}
                      canEditSupplier={false}
                    />
                  )
                }
              >
                <Stack flexDirection="column" spacing={0.5} color="primary">
                  <Typography variant="h3" color="primary">
                    {order.deliveryAddressType === "delivery"
                      ? t("Delivery address", {
                          ns: "PurchaseOrders",
                        })
                      : t("Pick-up location", {
                          ns: "PurchaseOrders",
                        })}
                  </Typography>
                  <Typography color="primary">
                    {order.deliveryAddress ? (
                      <AddressWithBreaks
                        address={order.deliveryAddress}
                        useCountry={false}
                      />
                    ) : (
                      t("Not set", {
                        ns: "Global",
                      })
                    )}
                  </Typography>
                </Stack>
              </Alert>

              <PurchaseOrderItemsTable order={order} />

              <PurchaseOrderSum
                calculation={
                  order.orderedDate
                    ? order.calculationOrdered
                    : order.calculationPrepared
                }
                vatRate={order.vatRate}
              />

              <ClosingTextInput
                canEdit={!order.orderedDate}
                content={order.purchaseOrderTexts?.closing}
                onSave={handleSaveOrderText}
                showInfo={false}
              />

              {!order.orderedDate && (
                <Stack justifyContent="center" mt={1}>
                  <RestrictedByProjectPermissionWithDebug
                    permission="MANAGE_QUOTES"
                    project={project}
                  >
                    <MuiButton
                      variant="outlined"
                      component={Link}
                      to={`/projects/${projectId}/materials/purchase-orders/${order.id}/edit`}
                      endIcon={<EditIcon />}
                    >
                      {t("Edit order", {
                        ns: "PurchaseOrders",
                      })}
                    </MuiButton>
                  </RestrictedByProjectPermissionWithDebug>
                  <RestrictedByProjectPermissionWithDebug
                    permission="MANAGE_QUOTES"
                    project={project}
                  >
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      endIcon={<CheckCircleOutlineIcon />}
                      loading={isUploadingFile || submitOrderLoading}
                      onClick={handleSubmitOrder}
                      disabled={order.items.length === 0}
                    >
                      {t("Mark as ordered", {
                        ns: "PurchaseOrders",
                      })}
                    </LoadingButton>
                  </RestrictedByProjectPermissionWithDebug>
                  {idsConnectAvailability && (
                    <>
                      <Divider orientation="vertical" flexItem />
                      <IdsConnectOrderButton
                        projectId={projectId}
                        orderId={orderId}
                        systemSupplierOrganisationId={
                          order.crmSupplier!.linkedSystemOrganisationId!
                        }
                        clientId={
                          order.crmSupplier?.crmOwningOrganisationClientId ??
                          undefined
                        }
                        idsConnectInfo={idsConnectAvailability}
                      />
                    </>
                  )}
                </Stack>
              )}
            </Stack>
          )}
        </Container>
      </PageContainer>
    </Page>
  );
};

function EditSupplierAndDeliveryButton({
  projectId,
  orderId,
  supplierId,
  deliveryAddress,
  deliveryAddressType,
  modifyOrder,
  fontSize,
  canEditSupplier,
}: {
  projectId: string;
  orderId: string;
  supplierId: string | undefined;
  deliveryAddress: AddressDetails__AddressFragment | undefined | null;
  deliveryAddressType: DeliveryAddressType;
  modifyOrder: ModifyOrderMutationFn;
  fontSize?: SvgIconProps["fontSize"];
  canEditSupplier: boolean;
}) {
  const { t } = useTranslate(["Global", "PurchaseOrders"]);
  return (
    <ModalOpenButton
      Modal={SelectSupplierAndDeliveryModal}
      modalProps={{
        projectId,
        supplierId,
        title: t("Edit pick-up/delivery location", { ns: "PurchaseOrders" }),
        buttonTitle: t("Save", { ns: "Global" }),
        address: deliveryAddress,
        addressType: deliveryAddressType,
        canEditSupplier,
        handleComplete: async (crmSupplierId, address, addressType) => {
          await modifyOrder({
            variables: {
              input: {
                orderId,
                crmSupplierId,
                deliveryAddress: address,
                deliveryAddressType: addressType,
              },
            },
          });
        },
      }}
    >
      <IconButton
        color="secondary"
        size="small"
        style={{ alignSelf: "flex-start" }}
      >
        <EditIcon fontSize={fontSize} />
      </IconButton>
    </ModalOpenButton>
  );
}
