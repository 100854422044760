/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocExportAsExcelMutationVariables = Types.Exact<{
  input: Types.DocExportAsExcelInput;
}>;


export type DocExportAsExcelMutation = { docExportAsExcel: { __typename: 'DocExportAsExcelResult', downloadUrl: string, filename: string } };


export const DocExportAsExcelDocument = gql`
    mutation DocExportAsExcel($input: DocExportAsExcelInput!) {
  docExportAsExcel(input: $input) {
    downloadUrl
    filename
  }
}
    `;
export type DocExportAsExcelMutationFn = Apollo.MutationFunction<DocExportAsExcelMutation, DocExportAsExcelMutationVariables>;

/**
 * __useDocExportAsExcelMutation__
 *
 * To run a mutation, you first call `useDocExportAsExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocExportAsExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [docExportAsExcelMutation, { data, loading, error }] = useDocExportAsExcelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocExportAsExcelMutation(baseOptions?: Apollo.MutationHookOptions<DocExportAsExcelMutation, DocExportAsExcelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocExportAsExcelMutation, DocExportAsExcelMutationVariables>(DocExportAsExcelDocument, options);
      }
export type DocExportAsExcelMutationHookResult = ReturnType<typeof useDocExportAsExcelMutation>;
export type DocExportAsExcelMutationResult = Apollo.MutationResult<DocExportAsExcelMutation>;
export type DocExportAsExcelMutationOptions = Apollo.BaseMutationOptions<DocExportAsExcelMutation, DocExportAsExcelMutationVariables>;