import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import { Alert, AlertTitle, DialogContentText } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { SelectField } from "../../../commons/form-fields/SelectField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { useProjectRoles } from "../../users/useRoles.js";
import {
  useAddProjectMemberFromOrganisationMembersMutation,
  useAddProjectMemberModalQuery,
} from "./AddProjectMemberModal.generated.js";

interface FormValues {
  userId?: string;
  roleId?: string;
}

interface Props {
  projectId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (userId: string) => Promise<void> | void;
  refetchQueries?: string[];
}

export const AddProjectMemberModal = ({
  projectId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["ProjectMembers", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const { getProjectRoleOptions } = useProjectRoles();

  const { data, isLoading } = useData(projectId);

  const project = getDataOrNull(data?.project)?.project;

  const allRoles = project?.roles ?? [];
  const internalStakeholders = project?.internalStakeholders ?? [];

  const roles = getProjectRoleOptions(allRoles);

  const members =
    data?.organisationMemberships
      .filter(e => !internalStakeholders.find(s => s.user.id === e.id))
      .map(e => ({
        label: e.fullname,
        value: e.id,
        roles: e.defaultProjectRoles || [],
      })) ?? [];

  const client = useApolloClient();
  const [addProjectMember] = useAddProjectMemberFromOrganisationMembersMutation(
    {
      client,
    }
  );

  const handleSubmit = async (values: FormValues) => {
    await addProjectMember({
      variables: {
        projectId: projectId,
        userId: values.userId!,
        roleIds: [values.roleId!],
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });
    await handleComplete?.(values.userId!);
    handleClose();
    enqueueSnackbar(
      t("Project member added", {
        ns: "ProjectMembers",
      })
    );
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues = {
    userId: "",
    roleId: "",
  };

  const validationSchema = Yup.object().shape({
    userId: Yup.string()
      .label(
        t("Organisation Member", {
          ns: "ProjectMembers",
        })
      )
      .required(),
    roleId: Yup.string()
      .label(
        t("Project roles", {
          ns: "ProjectMembers",
        })
      )
      .required(),
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({ isValid, setFieldValue, isSubmitting }: FormikProps<FormValues>) => (
        <Modal
          title={
            title ??
            t("Add project member", {
              ns: "ProjectMembers",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Send", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled: !isValid || isLoading,
                loading: isSubmitting,
              },
            },
          ]}
          isLoading={isLoading}
        >
          <Form id={formId}>
            <DialogContentText>
              {t(
                "Here you can add one of your organisation members to the project, and collaborate together on the work!",
                {
                  ns: "ProjectMembers",
                }
              )}
            </DialogContentText>
            <Stack flexDirection="column">
              {members.length === 0 && (
                <Alert severity="warning">
                  <AlertTitle>
                    {t(
                      "All of your organisation members have been added to this project.",
                      {
                        ns: "ProjectMembers",
                      }
                    )}
                  </AlertTitle>
                  {t(
                    "If there is somebody missing, please invite them to your organisation.",
                    {
                      ns: "ProjectMembers",
                    }
                  )}
                </Alert>
              )}
              <SelectField
                disabled={members.length === 0}
                label={t("Organisation Member", {
                  ns: "ProjectMembers",
                })}
                name="userId"
                options={members}
                onChange={e => {
                  const userId = e.target.value;
                  setFieldValue("userId", userId);
                  // set project role for this user
                  setTimeout(() => {
                    let memberRoles: typeof roles = [];
                    const member = members.find(m => m.value === userId);
                    if (member) {
                      memberRoles = roles.filter(r =>
                        member.roles.find(
                          mr => mr.internalName === r.internalName
                        )
                      );
                    }
                    if (memberRoles.length > 0) {
                      setFieldValue("roleId", memberRoles[0].value);
                    } else {
                      setFieldValue("roleId", "");
                    }
                  });
                }}
                required
              />
              <SelectField
                disabled={members.length === 0}
                label={t("Project roles", {
                  ns: "ProjectMembers",
                })}
                name="roleId"
                options={roles}
                required
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

function useData(projectId: string) {
  const client = useApolloClient();
  const query = useAddProjectMemberModalQuery({
    client,
    variables: { projectId },
  });
  return {
    data: query.data,
    refetch: query.refetch,
    isLoading: query.loading,
    error: query.error,
  };
}
