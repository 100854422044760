import { SvgIconProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { LightbulbOutlined as LightbulbOutlinedIcon } from "@mui/icons-material";
import { LiveHelpOutlined as LiveHelpOutlinedIcon } from "@mui/icons-material";
import { WorkspacePremium as WorkspacePremiumIcon } from "@mui/icons-material";
import { EnergySavingsLeaf as EnergySavingsLeafIcon } from "@mui/icons-material";
import { Construction as ConstructionIcon } from "@mui/icons-material";
import { Notes as NotesIcon } from "@mui/icons-material";
import { Hardware as HardwareIcon } from "@mui/icons-material";

export enum PredefinedTopic {
  Manuals = "Manuals",
  Maintenance = "Maintenance",
  SustainabilityInformation = "Sustainability information",
  TechnicalInformation = "Technical information",
  Warranty = "Warranty",
  TipsAndInspirations = "Tips and inspirations",
  FAQ = "FAQ",
}

const PREDEFINED_TOPICS = [
  PredefinedTopic.Manuals,
  PredefinedTopic.Maintenance,
  PredefinedTopic.SustainabilityInformation,
  PredefinedTopic.TechnicalInformation,
  PredefinedTopic.Warranty,
  PredefinedTopic.TipsAndInspirations,
  PredefinedTopic.FAQ,
];

export const isPredefinedTopic = (topic: string): topic is PredefinedTopic =>
  // @ts-ignore
  PREDEFINED_TOPICS.includes(topic);

export function useContentTopics() {
  const { t } = useTranslate("Contents");

  const topicLabels: Record<PredefinedTopic, string> = {
    [PredefinedTopic.Manuals]: t("Manuals"),
    [PredefinedTopic.Maintenance]: t("Maintenance"),
    [PredefinedTopic.SustainabilityInformation]: t(
      "Sustainability information"
    ),
    [PredefinedTopic.TechnicalInformation]: t("Technical information"),
    [PredefinedTopic.Warranty]: t("Warranty"),
    [PredefinedTopic.TipsAndInspirations]: t("Tips and inspirations"),
    [PredefinedTopic.FAQ]: t("FAQ"),
  };

  const topicIcons: Record<PredefinedTopic, React.FC<SvgIconProps>> = {
    [PredefinedTopic.Manuals]: NotesIcon,
    [PredefinedTopic.Maintenance]: ConstructionIcon,
    [PredefinedTopic.SustainabilityInformation]: EnergySavingsLeafIcon,
    [PredefinedTopic.TechnicalInformation]: HardwareIcon,
    [PredefinedTopic.Warranty]: WorkspacePremiumIcon,
    [PredefinedTopic.TipsAndInspirations]: LightbulbOutlinedIcon,
    [PredefinedTopic.FAQ]: LiveHelpOutlinedIcon,
  };

  const topicOptions = PREDEFINED_TOPICS.map(topic => ({
    label: topicLabels[topic],
    value: topic,
  }));

  return { topics: PREDEFINED_TOPICS, topicLabels, topicOptions, topicIcons };
}
