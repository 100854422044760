import { Category as CategoryIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useUserData } from "../../auth/useUserData.js";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { PageTopbarItem } from "../../commons/layout/Page.js";
import { ProductOverview } from "./ProductOverview.js";
import { ProductsCatalogues } from "./ProductsCatalogues.js";
import { SupplierProductEdit } from "./SupplierProductEdit.js";

type PageSubmenuItem = PageTopbarItem;

export const ProductsRoutes = () => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("OrganisationPageTopbar");

  const submenuItems = useMemo(() => {
    if (!viewer.organisation) return [];

    const items: PageSubmenuItem[] = [
      {
        name: "products-catalogues",
        label: t("Product Catalogues"),
        path: `/products/catalogues`,
        icon: <CategoryIcon />,
        permission: "MANAGE_ORG",
        isHidden:
          !viewer.organisation.isCraftsmanOrganisation &&
          !viewer.organisation.isSupplierOrganisation,
      },
    ];
    return items.filter(item => !item.isHidden);
  }, [t, viewer.organisation]);

  return (
    <Routes>
      <Route index element={<Navigate to="catalogues" replace />} />
      <Route path="catalogues">
        <Route
          index
          element={<ProductsCatalogues submenuItems={submenuItems} />}
        />
        <Route
          path="supplier/:supplierId/:articleNumber/overview"
          element={<ProductOverview submenuItems={[]} />}
        />
        <Route
          path="supplier/:supplierId/:articleNumber/edit"
          element={<SupplierProductEdit submenuItems={[]} />}
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
