import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { DocActorType, Salutation } from "../../../clients/graphqlTypes.js";
import { getAddressLabel } from "../addresses/helpers.js";
import { useActorTypes } from "./useActorTypes.js";
import { useSalutation } from "../users/useSalutation.js";

interface Props {
  actorType: DocActorType;
  actor:
    | {
        companyOrIndividual: "COMPANY" | "INDIVIDUAL";
        companyName: string;
        salutation?: Salutation;
        firstname?: string;
        familyname?: string;
        email?: string;
        phone?: string;
        address?: Parameters<typeof getAddressLabel>[0] | null | undefined;
        contact?:
          | {
              salutation?: string;
              firstname?: string;
              familyname?: string;
            }
          | null
          | undefined;
      }
    | undefined
    | null;
}

export const DocumentActorData = ({ actorType, actor }: Props) => {
  const { t } = useTranslate(["Global", "Quote"]);
  const { actorTypeLabels } = useActorTypes();
  const { getNameWithSalutation } = useSalutation();

  if (!actor) {
    return (
      <Stack>
        <Typography variant="h3">{actorTypeLabels[actorType]}</Typography>
        <Typography>{t("Not set", { ns: "Global" })}</Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography variant="h3">{actorTypeLabels[actorType]}</Typography>
      <Typography>
        {actor.companyOrIndividual === "COMPANY"
          ? actor.companyName
          : `${actor.firstname} ${actor.familyname}`}
      </Typography>
      {actor.address && (
        <Typography>{getAddressLabel(actor.address)}</Typography>
      )}
      {actor.phone && <Typography>{actor.phone}</Typography>}
      {actor.email && <Typography>{actor.email}</Typography>}
      {actor.contact && (
        <Typography>
          {`${t("Contact person", { ns: "Quote" })}: ${getNameWithSalutation(
            (actor.contact.salutation ?? "mr") as Salutation,
            actor.contact.firstname ?? "",
            actor.contact.familyname ?? ""
          )}`}
        </Typography>
      )}
    </Stack>
  );
};
