import { CrmOrganisationAbcCategory } from "../../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";

export const useCrmOrganisationAbcCategories = () => {
  const { t } = useTranslate(["OrganisationProfile"]);

  const abcCategoryOptions: {
    label: string;
    value: CrmOrganisationAbcCategory;
  }[] = [
    {
      label: t("none", {
        ns: "OrganisationProfile",
      }),
      value: "none",
    },
    {
      label: t("A", {
        ns: "OrganisationProfile",
      }),
      value: "a",
    },
    {
      label: t("B", {
        ns: "OrganisationProfile",
      }),
      value: "b",
    },
    {
      label: t("C", {
        ns: "OrganisationProfile",
      }),
      value: "c",
    },
  ];

  const abcCategoryLabels: Record<CrmOrganisationAbcCategory, string> = {
    none: t("none", {
      ns: "OrganisationProfile",
    }),
    a: t("A", {
      ns: "OrganisationProfile",
    }),
    b: t("B", {
      ns: "OrganisationProfile",
    }),
    c: t("C", {
      ns: "OrganisationProfile",
    }),
  };

  return { abcCategoryOptions, abcCategoryLabels };
};
