import { last, sortBy, uniqBy } from "lodash-es";
import {
  find,
  getDescendantCount,
  getTreeFromFlatData,
  TreeItem,
} from "react-sortable-tree";
import { color } from "../../common/MuiThemeProvider.js";
import { ItemType } from "../../clients/graphqlTypes.js";
import { getDefaultCreatableSubItemTypes } from "../features/doc-items/constraints.js";
import {
  getAllParentItemIds,
  getParentItem,
  isAnyParentMatching,
} from "../trees/helpers.js";
import { AdditionalItemInput, VirtualItem, VirtualTreeItem } from "./types.js";

export const LINE_COLOR = "#e0e0e0";
export const ARROW_COLOR = color.primary;
export const ROOT_ITEM_ID = "root-synthetic-id";

export const getTreeFromItems = <T extends VirtualItem>(
  items: T[],
  rootItem: T | undefined,
  selectedItem: T | null,
  filterItem: ((item: T) => boolean) | undefined,
  enableCreating: boolean,
  isItemExpanded: (itemId: string, defaultValue?: boolean) => boolean,
  documentItemTypes: ItemType[],
  shouldRenderCreateInput?: (item: T) => boolean,
  additionalItemInput?: AdditionalItemInput | null
): TreeItem[] => {
  const selectedItemParent =
    selectedItem && selectedItem.parentId !== null
      ? getParentItem(selectedItem, items)
      : null;
  const additionalItem = additionalItemInput
    ? (items.find(i => i.id === additionalItemInput.itemId) ?? null)
    : null;
  const additionalItemParent =
    additionalItem && additionalItem.parentId !== null
      ? getParentItem(additionalItem, items)
      : null;

  const { rootItemChildren, selectedItemChildren, selectedItemParentChildren } =
    items.reduce(
      (acc, item) => {
        if (rootItem && item.parentId === rootItem.id)
          acc.rootItemChildren.push(item);
        if (selectedItem && item.parentId === selectedItem.id)
          acc.selectedItemChildren.push(item);
        if (selectedItemParent && item.parentId === selectedItemParent.id)
          acc.selectedItemParentChildren.push(item);
        return acc;
      },
      {
        rootItemChildren: [] as T[],
        selectedItemChildren: [] as T[],
        selectedItemParentChildren: [] as T[],
      }
    );

  const canHaveCreateInput = (
    itemType: ItemType,
    documentItemTypes: ItemType[],
    itemHasLinkedTemplate: boolean
  ): boolean => {
    if (itemHasLinkedTemplate) return false;

    return (
      getDefaultCreatableSubItemTypes(itemType, documentItemTypes).length > 0
    );
  };

  const inputItems: VirtualTreeItem<T>[] = enableCreating
    ? uniqBy(
        [
          // input added to root item
          ...(rootItem &&
          !rootItem.deletedAt &&
          rootItem.id !== ROOT_ITEM_ID &&
          shouldRenderCreateInput?.(rootItem) !== false &&
          canHaveCreateInput(rootItem.type, documentItemTypes, false)
            ? [
                {
                  id: `input-${rootItem.id}-${(last(rootItemChildren)?.rank ?? 0) + 0.5}`,
                  parentId: rootItem.id,
                  type: null,
                  expanded: false,
                  selected: false,
                  isInput: true,
                  item: rootItem,
                  rank: (last(rootItemChildren)?.rank ?? 0) + 0.5,
                },
              ]
            : []),
          // input added to selected item - able to create children
          ...(selectedItem &&
          !selectedItem.deletedAt &&
          selectedItem.id !== rootItem?.id &&
          shouldRenderCreateInput?.(selectedItem) !== false &&
          canHaveCreateInput(
            selectedItem.type,
            documentItemTypes,
            "linkedQuoteTemplate" in selectedItem &&
              Boolean(selectedItem.linkedQuoteTemplate)
          )
            ? [
                {
                  id: `input-${selectedItem.id}-${(last(selectedItemChildren)?.rank ?? 0) + 0.5}`,
                  parentId: selectedItem.id,
                  type: null,
                  expanded: false,
                  selected: false,
                  isInput: true,
                  item: selectedItem,
                  rank: (last(selectedItemChildren)?.rank ?? 0) + 0.5,
                },
              ]
            : []),
          // input added to selected parent item - able to create siblings
          ...(selectedItemParent &&
          !selectedItemParent.deletedAt &&
          selectedItemParent.id !== rootItem?.id &&
          shouldRenderCreateInput?.(selectedItemParent) !== false &&
          canHaveCreateInput(
            selectedItemParent.type,
            documentItemTypes,
            "linkedQuoteTemplate" in selectedItemParent &&
              Boolean(selectedItemParent.linkedQuoteTemplate)
          )
            ? [
                {
                  id: `input-${selectedItemParent.id}-${(last(selectedItemParentChildren)?.rank ?? 0) + 0.5}`,
                  parentId: selectedItemParent.id,
                  type: null,
                  expanded: false,
                  selected: false,
                  isInput: true,
                  item: selectedItemParent,
                  rank: (last(selectedItemParentChildren)?.rank ?? 0) + 0.5,
                },
              ]
            : []),
          ...(additionalItemInput &&
          additionalItem &&
          additionalItemParent &&
          !additionalItemParent.deletedAt &&
          additionalItemParent.id !== ROOT_ITEM_ID &&
          shouldRenderCreateInput?.(additionalItemParent) !== false &&
          canHaveCreateInput(
            additionalItemParent.type,
            documentItemTypes,
            "linkedQuoteTemplate" in additionalItemParent &&
              Boolean(additionalItemParent.linkedQuoteTemplate)
          )
            ? [
                {
                  id: `input-${additionalItemParent.id}-${
                    additionalItem.rank +
                    (additionalItemInput.position === "below" ? 0.5 : -0.5)
                  }`,
                  parentId: additionalItemParent.id,
                  type: null,
                  expanded: false,
                  selected: false,
                  isInput: true,
                  item: additionalItemParent,
                  rank:
                    additionalItem.rank +
                    (additionalItemInput.position === "below" ? 0.5 : -0.5),
                },
              ]
            : []),
        ],
        i => i.id
      )
    : [];

  const treeFlatItems: VirtualTreeItem<T>[] = items
    .filter(item => {
      if (filterItem?.(item) === false) return false;
      return true;
    })
    .map(item => ({
      id: item.id,
      type: item.type,
      rank: item.rank,
      parentId: item.parentId ?? null,
      expanded: isItemExpanded(item.id),
      selected: selectedItem?.id === item.id,
      isInput: false,
      item,
    })) as VirtualTreeItem<T>[];

  return getTreeFromFlatData({
    flatData: sortBy(treeFlatItems.concat(inputItems), n => n.rank),
    getKey: item => item.id,
    getParentKey: item => item.parentId,
    rootKey: rootItem?.id,
  });
};

export const getLastSelectedNode = <T extends VirtualItem>(
  treeData: TreeItem[],
  items: T[],
  selectedItem: T | null
): [number | null, string | null, (string | number)[]] => {
  const selectedItemParentIds = (
    selectedItem ? getAllParentItemIds(selectedItem, items) : []
  ).reduce(
    (acc, id, index) => {
      acc[id] = true;
      return acc;
    },
    {} as { [key: string]: boolean }
  );

  const { matches: matchesSelectedNodes } = find({
    getNodeKey: ({ node }) => node.id,
    treeData,
    searchMethod: ({ node }) =>
      node.id === selectedItem?.id || !!selectedItemParentIds[node.id],
    searchFocusOffset: 0,
    expandAllMatchPaths: false,
    expandFocusMatchPaths: false,
  });

  const selectedNodes = matchesSelectedNodes.filter(m => m.treeIndex !== null);
  const selectedNode = last(matchesSelectedNodes);
  const lastSelectedNode = last(selectedNodes);

  return [
    lastSelectedNode?.treeIndex ?? null,
    lastSelectedNode?.node.id ?? null,
    selectedNode?.path ?? [],
  ];
};

export const isNodePlacedAfterInput = (
  quoteItems: TreeItem[],
  node: TreeItem,
  nextPath: (string | number)[],
  prevPath: (string | number)[]
) => {
  const descendants = getDescendantCount({ node });

  const { matches: matchesInput } = find({
    getNodeKey: ({ node }) => node.id,
    treeData: quoteItems,
    searchMethod: ({ node }) => node.isInput,
    searchFocusOffset: 0,
    expandAllMatchPaths: false,
    expandFocusMatchPaths: false,
  });

  const isMovedDown =
    (prevPath[prevPath.length - 1] as number) <
    (nextPath[nextPath.length - 1] as number);

  const newTreeIndex = nextPath[nextPath.length - 1] as number;

  if (
    matchesInput.some(match => {
      if (match.path.length !== nextPath.length) {
        return false;
      }
      const inputIndex = match.treeIndex;
      if (isMovedDown) {
        return newTreeIndex + descendants === inputIndex;
      } else {
        return newTreeIndex - 1 === inputIndex;
      }
    })
  ) {
    return true;
  }

  return false;
};

export function getNonLeafChildrenIdsRecurively(node: TreeItem): string[] {
  if (node.children === undefined) return [];
  if (typeof node.children === "function") return []; // FIXME

  return [node.id, ...node.children.flatMap(getNonLeafChildrenIdsRecurively)];
}

export const getSyntheticRootItem = (
  //__typename: Exclude<DocItemTypename, "UnnestedQuoteTemplateItem">,
  __typename: "Item",
  syntheticRootItemId: string
): VirtualItem => {
  return {
    __typename,
    id: syntheticRootItemId,
    // docId: "",
    // title: "",
    // description: "",
    type: "section",
    // docType,
    parentId: null,
    deletedAt: null,
    isRootItem: true,
    rank: 0,
    level: 0,
    title: "",
    pathForPdf: "",
    pathSortable: "",
    decisionIsContingentItem: false,
    decisionContingentItemPreselection: null,
    decisionSubitemsPreselection: [],
    decisionBehaviorOfSubitems: "NONE",
    isItemEliminated: false,
    isExplicitHidden: false,
    childrenVisibility: "SHOW_CHILDREN",
    binding: "binding",
    // needsAgreement: false,
    // agreement: "NONE",
    isHidden: false,
    isPriceHidden: false,
    isParentDecisionPreselected: false,
    isParentDecisionNotPreselected: false,
    isParentContingencyNotPreselected: false,
    // pendingChangeAttributes: null,
    // approvalRequired: false,
    // timeTrackingRequired: false,
    // qaApprovalRequired: false,
    // photoApprovalRequired: false,
    // authorOrganisationId: "",
    // originDocId: "",
    // originItemId: "",
    hasChildren: false,
  };
};

export const getOutOfScreen = (
  index: number | null,
  itemHeight: number,
  scrollPosition: number,
  windowHeight: number
) => {
  if (index === null || !windowHeight) {
    return [false, false];
  }
  const aboveScreen = (index + 0.5) * itemHeight < scrollPosition;
  const belowScreen =
    (index + 0.5) * itemHeight > scrollPosition + windowHeight;
  return [aboveScreen, belowScreen];
};

export const isItemVisibleToOtherSide = (
  item: VirtualItem,
  allDocItems: VirtualItem[]
) => {
  return (
    !item.isExplicitHidden &&
    item.type !== "unpaid" &&
    item.binding !== "draft" &&
    !isAnyParentMatching(item, allDocItems, parentItem => {
      return (
        parentItem.isExplicitHidden ||
        parentItem.childrenVisibility === "HIDE_CHILDREN"
      );
    })
  );
};
