import { CardContainer, ellipsisStyle } from "@msys/ui";
import { List, ListItem, ListItemText } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { noop } from "lodash-es";
import React from "react";
import { useUserData } from "../../../auth/useUserData.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { ConfirmModalProps } from "../../../commons/modals/ConfirmProcess.js";
import { BUILDING_ITEM_TYPES } from "../../../constants.js";
import { namedOperations } from "../../../../clients/graphqlTypes.js";
import { BareTreeItem } from "../../../trees/BareTreeItem.js";
import { HeaderBox } from "../../doc-items/boxes/HeaderBox.js";
import {
  SubelementsBox,
  SubitemComponentProps,
} from "../../doc-items/boxes/SubelementsBox.js";
import { BuildingContactsBox } from "../boxes/BuildingContactsBox.js";
import { BuildingProjectsBox } from "../boxes/BuildingProjectsBox.js";
import {
  BuildingItem_BuildingFragment,
  BuildingItem_ChildItemComponentFragment,
  BuildingItem_ChildItemFragment,
  BuildingItem_ItemFragment,
} from "./BuildingItem.generated.js";
import { RulesBox } from "../../doc-items/boxes/RulesBox.js";

interface PlacedItem {
  id: string;
  title: string;
}

interface Props {
  building: BuildingItem_BuildingFragment;
  item: BuildingItem_ItemFragment & {
    placedItemsRequirement: PlacedItem[];
  } & {
    placedItemsQuote: PlacedItem[];
  };
  pathToDocPage: string;
  startConfirmProcess: (
    props: ConfirmModalProps
  ) => Promise<boolean | undefined>;
  expandedItemIds: string[] | undefined;
}

export const BuildingItem = ({ building, item, pathToDocPage }: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("BuildingDetails");

  const placedItemsRequirement = item.placedItemsRequirement;
  const placedItemsQuote = item.placedItemsQuote;

  const projects = item.projectsInBuildingItem;

  const ChildItemComponent = React.useMemo(
    () => createChildItemComponent({ pathToDocPage }),
    [pathToDocPage]
  );

  const canEdit =
    viewer.organisation.id === building.owningSystemOrganisationId ||
    (viewer.organisation.capabilities.includes("QUOTING") &&
      Boolean(building.crmCompanyId));

  return (
    <Stack flexDirection="column">
      <HeaderBox
        projectId={null}
        docId={building.id}
        docType="BUILDING"
        item={item}
        viewMode={canEdit ? "edit" : null}
        setViewMode={noop}
      />

      {!item.isRootItem && item.type !== "unpaid" && (
        <RulesBox
          projectId={null}
          docId={building.id}
          item={item}
          canEdit={canEdit}
        />
      )}

      <CardContainer
        title={t("Placed requirement items")}
        isExpandable
        isInitiallyClosed={placedItemsRequirement.length === 0}
        itemCount={placedItemsRequirement.length}
      >
        <List disablePadding>
          {placedItemsRequirement.map((e, index, array) => {
            // TODO: how can we ensure the correct project?
            // const projectId = e.doc.projectId!;
            return (
              <ListItem
                // component={Link}
                key={e.id}
                divider={index < array.length - 1}
                // button
                // to={`/projects/${projectId}/requirements/${e.doc.id}/edit/items/${e.id}`}
              >
                <ListItemText
                  primary={e.title}
                  primaryTypographyProps={{ style: ellipsisStyle }}
                />
              </ListItem>
            );
          })}
        </List>
      </CardContainer>

      <CardContainer
        title={t("Placed quote items")}
        isExpandable
        isInitiallyClosed={placedItemsQuote.length === 0}
        itemCount={placedItemsQuote.length}
      >
        <List disablePadding>
          {placedItemsQuote.map((e, index, array) => {
            // TODO: how can we ensure the correct project?
            // const projectId = e.doc.projectId!;
            return (
              <ListItem
                // component={Link}
                key={e.id}
                divider={index < array.length - 1}
                // button
                // to={
                //   `/projects/${projectId}/quotes/${e.doc.id}` +
                //   (e.doc.owningSystemOrganisationId === viewer.organisation.id
                //     ? `/edit/items/${e.id}`
                //     : "")
                // }
              >
                <ListItemText
                  primary={e.title}
                  primaryTypographyProps={{ style: ellipsisStyle }}
                />
              </ListItem>
            );
          })}
        </List>
      </CardContainer>

      <BuildingProjectsBox
        projects={projects}
        buildingId={building.id}
        buildingItemId={item.id}
        crmOrganisationId={building.crmCompanyId ?? undefined}
        canAdd={canEdit}
      />

      <BuildingContactsBox
        contacts={item.crmLinks}
        buildingId={building.id}
        itemId={item.id}
        canEdit={canEdit}
        refetchQueries={[namedOperations.Query.BuildingEditItem]}
      />

      <SubelementsBox<BuildingItem_ChildItemFragment>
        projectId={null}
        doc={{ id: building.id, docType: "BUILDING", templateIsDraft: false }}
        item={item}
        subitemComponent={ChildItemComponent}
        pathToDocPage={pathToDocPage}
        documentItemTypes={BUILDING_ITEM_TYPES}
      />
    </Stack>
  );
};

const createChildItemComponent =
  <ChildItem extends BuildingItem_ChildItemComponentFragment>({
    pathToDocPage,
  }: {
    pathToDocPage: string;
  }) =>
  (itemProps: SubitemComponentProps<ChildItem>) => {
    return (
      <BareTreeItem
        docAgreement={"NONE"}
        showPath={!itemProps.item.isDecisionOption}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        onClick={itemProps.onClick}
        subcontractPath={null}
        to={
          itemProps.to !== undefined
            ? itemProps.to
            : `${pathToDocPage}/items/${itemProps.item.id}`
        }
      />
    );
  };
