import { assertNever } from "../../utils.js";
import { CustomFieldFilter } from "../../../clients/graphqlTypes.js";

type Operator = Extract<
  "eq" | "ilike" | "gt" | "gte" | "lt" | "lte",
  keyof CustomFieldFilter
>;

export function getExistingOperator(
  value: CustomFieldFilter | undefined | null,
  defaultOperator: Operator = "eq"
): Operator {
  if (!value) return defaultOperator;

  const existingOperator = Object.keys(value).find(key =>
    ["eq", "ilike", "gt", "gte", "lt", "lte"].includes(key)
  ) as Operator;

  return existingOperator ?? defaultOperator;
}

export function getHumanReadableOperator(
  operator: Operator,
  equalLabel: string = "is"
) {
  // TODO: add translations
  switch (operator) {
    case "eq":
      return equalLabel;
    case "ilike":
      return "contains";
    case "lt":
      return "is less than";
    case "lte":
      return "is less than or equal to";
    case "gt":
      return "is greater than";
    case "gte":
      return "is greater than or equal to";
    default:
      assertNever(operator);
  }
}
