import { gql, useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import { CardContainer, MenuButton, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Email as EmailIcon } from "@mui/icons-material";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { Phone as PhoneIcon } from "@mui/icons-material";
import { IconButton, List, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { differenceWith, groupBy } from "lodash-es";
import React from "react";
import { Link } from "react-router-dom";
import { ProfileListItem } from "../../../commons/ProfileListItem.js";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal.js";
import { AddContactRelationshipModal } from "../../contact-links/AddContactRelationshipModal.js";
import { ContactIcon } from "../../contact-links/ContactIcon.js";
import { useRelationshipRoles } from "../../contact-links/useRelationshipRoles.js";
import { CrmSendEmailModal } from "../../crm/CrmSendEmailModal.js";
import { OrganisationAvatar } from "../../organisations/OrganisationAvatar.js";
import { UserAvatar } from "../../users/UserAvatar.js";
import {
  ProjectContactLinksFragment,
  QuoteItemContactLinksFragment,
  useProjectAddCrmOrganisationLinkMutation,
  useProjectAddCrmUserLinkMutation,
  useProjectRemoveCrmOrganisationLinkMutation,
  useProjectRemoveCrmUserLinkMutation,
} from "./ProjectOverviewContactsBox.generated.js";
import {
  ViewMode,
  ViewModeMenuItem,
} from "../../../commons/ViewModeMenuItem.js";

interface Props {
  projectId: string;
  contacts: ProjectContactLinksFragment[];
  buildingContacts: QuoteItemContactLinksFragment[];
  canEdit?: boolean;
  refetchQueries?: string[];
}

export const ProjectOverviewContactsBox = ({
  contacts,
  buildingContacts,
  projectId,
  canEdit = false,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["CrmUsers", "Global"]);
  const { getRoleLabel } = useRelationshipRoles();

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const client = useApolloClient();
  const [addUserLink] = useProjectAddCrmUserLinkMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const [addOrganisationLink] = useProjectAddCrmOrganisationLinkMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const [removeUserLink] = useProjectRemoveCrmUserLinkMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const [removeOrganisationLink] = useProjectRemoveCrmOrganisationLinkMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const removeLink = React.useCallback(
    async (contact: ProjectContactLinksFragment) => {
      if (contact.__typename === "ProjectLinkCrmUser") {
        await removeUserLink({
          variables: {
            input: {
              crmUserId: contact.crmUser.id,
              projectId,
              linkAs: contact.linkAs,
            },
          },
        });
      } else {
        await removeOrganisationLink({
          variables: {
            input: {
              crmOrganisationId: contact.crmOrganisation.id,
              projectId,
              linkAs: contact.linkAs,
            },
          },
        });
      }
    },
    [removeOrganisationLink, removeUserLink, projectId]
  );

  const addLink = React.useCallback(
    async (contact: QuoteItemContactLinksFragment) => {
      if (contact.__typename === "QuoteItemLinkCrmUser") {
        await addUserLink({
          variables: {
            input: {
              projectId,
              crmUserId: contact.crmUser.id,
              linkAs: contact.linkAs,
              data: {},
            },
          },
        });
      } else if (contact.__typename === "QuoteItemLinkCrmOrganisation") {
        await addOrganisationLink({
          variables: {
            input: {
              projectId,
              crmOrganisationId: contact.crmOrganisation.id,
              linkAs: contact.linkAs,
              data: {},
            },
          },
        });
      } else {
        throw new Error("Invalid contact information");
      }
    },
    [addOrganisationLink, addUserLink, projectId]
  );

  const context = React.useMemo(
    () => ({ type: "PROJECT", id: projectId }) as const,
    [projectId]
  );

  const buildingContactsToShow = differenceWith(
    buildingContacts,
    contacts,
    (bc, c) => {
      return (
        (c.__typename === "ProjectLinkCrmUser" &&
          bc.__typename === "QuoteItemLinkCrmUser" &&
          c.crmUser.id === bc.crmUser.id &&
          c.linkAs === bc.linkAs) ||
        (c.__typename === "ProjectLinkCrmOrganisation" &&
          bc.__typename === "QuoteItemLinkCrmOrganisation" &&
          c.crmOrganisation.id === bc.crmOrganisation.id &&
          c.linkAs === bc.linkAs)
      );
    }
  );

  const groupedContacts = groupBy(contacts, contact =>
    contact.__typename === "ProjectLinkCrmUser"
      ? contact.crmUser.id
      : contact.__typename === "ProjectLinkCrmOrganisation"
        ? contact.crmOrganisation.id
        : undefined
  );
  const groupedBuildingContacts = groupBy(buildingContacts, contact =>
    contact.__typename === "QuoteItemLinkCrmUser"
      ? contact.crmUser.id
      : contact.__typename === "QuoteItemLinkCrmOrganisation"
        ? contact.crmOrganisation.id
        : undefined
  );

  return (
    <CardContainer
      Icon={<ContactIcon />}
      title={t("Contacts", { ns: "CrmUsers" })}
      itemCount={contacts.length + buildingContactsToShow.length}
      ActionButton={
        <Stack direction="row" spacing={1}>
          <MenuButton>
            <ViewModeMenuItem
              viewMode={viewMode}
              onViewModeChange={setViewMode}
              allowedModes={[null, "delete"]}
            />
          </MenuButton>
          {canEdit && (
            <ModalOpenButton
              Modal={AddContactRelationshipModal}
              modalProps={{
                title: t("Add contact to project", {
                  ns: "CrmUsers",
                }),
                existingContactLinks: contacts,
                handleComplete: async (contactType, contactId, role) => {
                  if (contactType === "user") {
                    await addUserLink({
                      variables: {
                        input: {
                          projectId,
                          crmUserId: contactId,
                          linkAs: role,
                          data: {},
                        },
                      },
                    });
                  } else if (contactType === "organisation") {
                    await addOrganisationLink({
                      variables: {
                        input: {
                          projectId,
                          crmOrganisationId: contactId,
                          linkAs: role,
                          data: {},
                        },
                      },
                    });
                  } else {
                    throw new Error("Invalid contact id");
                  }
                },
              }}
            >
              <IconButton size="small" color="primary">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          )}
        </Stack>
      }
    >
      <List disablePadding>
        {Object.values(groupedContacts).map(contacts => {
          const contact = contacts[0];

          const primaryLabel: string =
            contact.__typename === "ProjectLinkCrmUser"
              ? contact.crmUser.fullname
              : contact.crmOrganisation.title;
          const avatar =
            contact.__typename === "ProjectLinkCrmUser" ? (
              <UserAvatar userAvatar={contact.crmUser} />
            ) : (
              <OrganisationAvatar
                organisationAvatar={contact.crmOrganisation}
              />
            );

          const phone =
            contact.__typename === "ProjectLinkCrmUser"
              ? contact.crmUser.phones[0]?.number
              : contact.crmOrganisation.phones[0]?.number;

          const hasEmail =
            contact.__typename === "ProjectLinkCrmOrganisation"
              ? !!contact.crmOrganisation.email
              : contact.__typename === "ProjectLinkCrmUser"
                ? !!contact.crmUser.email
                : assertNever(contact);

          const actionButtons = [];
          if (phone) {
            actionButtons.push(
              <IconButton
                key="phone"
                color="primary"
                component="a"
                href={`tel:${phone}`}
              >
                <PhoneIcon />
              </IconButton>
            );
          }
          if (hasEmail) {
            const recipient =
              contact.__typename === "ProjectLinkCrmOrganisation"
                ? {
                    id: contact.crmOrganisation.id,
                    label: `${contact.crmOrganisation.title} <${contact.crmOrganisation.email}>`,
                    value: contact.crmOrganisation.id,
                    email: contact.crmOrganisation.email,
                    crmCompanyId: contact.crmOrganisation.id,
                    crmPersonId: null,
                  }
                : contact.__typename === "ProjectLinkCrmUser"
                  ? {
                      id: contact.crmUser.id,
                      label: `${contact.crmUser.fullname} <${contact.crmUser.email}>`,
                      value: contact.crmUser.id,
                      email: contact.crmUser.email,
                      crmCompanyId: contact.crmUser.crmCompany.id,
                      crmPersonId: contact.crmUser.id,
                    }
                  : assertNever(contact);

            actionButtons.push(
              <ModalOpenButton
                Modal={CrmSendEmailModal}
                modalProps={{
                  emailTemplateContext: "PROJECT",
                  context,
                  recipientOptions: [recipient],
                  recipient,
                  projectId,
                }}
              >
                <IconButton color="primary">
                  <EmailIcon />
                </IconButton>
              </ModalOpenButton>
            );
          }
          if (canEdit && viewMode === "delete") {
            actionButtons.push(
              <ModalOpenButton
                key="delete"
                Modal={ConfirmModal}
                modalProps={{
                  handleConfirm: async () => {
                    contacts.forEach(contact => {
                      removeLink(contact);
                    });
                  },
                }}
              >
                <IconButton color="primary">
                  <DeleteIcon />
                </IconButton>
              </ModalOpenButton>
            );
          }

          const roles = contacts
            .sort((a, b) => a.linkAs.localeCompare(b.linkAs))
            .map(contact => getRoleLabel(contact.linkAs) ?? contact.linkAs)
            .join(", ");
          const link =
            contacts[0].__typename === "ProjectLinkCrmUser"
              ? `/crm/users/${contacts[0].crmUser.id}`
              : `/crm/organisations/${contacts[0].crmOrganisation.id}`;

          return (
            <ProfileListItem
              button
              key={contact.id}
              avatar={avatar}
              primary={primaryLabel}
              secondary={roles}
              ActionButtons={actionButtons}
              component={Link}
              to={link}
            />
          );
        })}
        {Object.values(groupedBuildingContacts).map(contacts => {
          const contact = contacts[0];
          const primaryLabel: string =
            contact.__typename === "QuoteItemLinkCrmUser"
              ? contact.crmUser.fullname
              : contact.crmOrganisation.title;
          const avatar =
            contact.__typename === "QuoteItemLinkCrmUser" ? (
              <UserAvatar userAvatar={contact.crmUser} />
            ) : (
              <OrganisationAvatar
                organisationAvatar={contact.crmOrganisation}
              />
            );

          const phone =
            contact.__typename === "QuoteItemLinkCrmUser"
              ? contact.crmUser.phones[0]?.number
              : contact.crmOrganisation.phones[0]?.number;

          const actionButtons = [];
          if (phone) {
            actionButtons.push(
              <IconButton
                key="phone"
                color="primary"
                component="a"
                href={`tel:${phone}`}
                size="large"
              >
                <PhoneIcon />
              </IconButton>
            );
          }
          if (canEdit) {
            actionButtons.push(
              <ModalOpenButton
                key="copy"
                Modal={ConfirmModal}
                modalProps={{
                  handleConfirm: async () => {
                    contacts.forEach(contact => {
                      addLink(contact);
                    });
                  },
                }}
              >
                <IconButton color="primary" size="large">
                  <FileCopyIcon />
                </IconButton>
              </ModalOpenButton>
            );
          }

          const roles = contacts
            .sort((a, b) => a.linkAs.localeCompare(b.linkAs))
            .map(contact => getRoleLabel(contact.linkAs) ?? contact.linkAs)
            .join(", ");
          const link =
            contact.__typename === "QuoteItemLinkCrmUser"
              ? `/crm/users/${contact.crmUser.id}`
              : `/crm/organisations/${contact.crmOrganisation.id}`;

          return (
            <ProfileListItem
              button
              key={contact.id}
              avatar={avatar}
              primary={primaryLabel}
              secondary={roles}
              ActionButtons={actionButtons}
              component={Link}
              to={link}
            />
          );
        })}
      </List>
    </CardContainer>
  );
};
