import { gql } from "@apollo/client";
import {
  ErrorMessage,
  MediaCardItem,
  MediaListItem,
  TagChips,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import { Box, Grid, TablePagination } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { BookmarkButton } from "../../../commons/button/BookmarkButton.js";
import { ButtonCircleWrapper } from "../../../commons/button/ButtonCircleWrapper.js";
import { CollectionView } from "../../../commons/hooks/useCollectionView.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { useTranslate } from "@tolgee/react";
import { useShopTemplatesBookmark } from "../../templates/quote/useShopTemplatesBookmark.js";
import { ShopItemsList_ShoppableTemplateFragment } from "./ShopItemsList.generated.js";

interface Props {
  activeView?: CollectionView;
  items: ShopItemsList_ShoppableTemplateFragment[];
  totalCount: number;
  page: number;
  perPage: number;
  setPage: (newPage: number) => void;
  setPerPage: (newPerPage: number) => void;
}

export const ShopItemsList = ({
  activeView = "list",
  items,
  totalCount,
  page,
  perPage,
  setPage,
  setPerPage,
}: Props) => {
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate(["Shop", "Global"]);

  const { getFormattedPrice } = useFormatting();
  const { handleBookmarkClick } = useShopTemplatesBookmark();

  const renderItems = (items: ShopItemsList_ShoppableTemplateFragment[]) => {
    if (items.length === 0)
      return (
        <ErrorMessage
          message={t("No items available in shop", {
            ns: "Shop",
          })}
        />
      );

    switch (activeView) {
      case "list":
        return (
          <Stack flexDirection="column" spacing={1}>
            {items.map((item, index) => (
              <MediaListItem
                key={item.id}
                // @ts-ignore
                component={Link}
                to={`/shop/${item.id}`}
                title={item.title}
                description={item.description}
                price={getFormattedPrice(
                  item.proposedCalculation?.priceTotal || 0
                )}
                imageUrl={item.shopCatalogProductImage?.url}
                ActionButton={
                  <ButtonCircleWrapper>
                    <BookmarkButton
                      isBookmarked={item.isLikedByMe}
                      handleClick={() =>
                        handleBookmarkClick(item.id, item.isLikedByMe)
                      }
                    />
                  </ButtonCircleWrapper>
                }
                Tags={<TagChips tags={item.customTags} wrap={false} />}
              />
            ))}
          </Stack>
        );
      case "gallery":
        return (
          <Grid container spacing={isMinTablet ? 2 : 1}>
            {items.map((item, index) => (
              <Grid key={item.id} item xs={6} md={4} lg={3} xl={3}>
                <MediaCardItem
                  key={item.id}
                  // @ts-ignore
                  component={Link}
                  to={`/shop/${item.id}`}
                  title={item.title}
                  description={item.description}
                  price={getFormattedPrice(
                    item.proposedCalculation?.priceTotal || 0
                  )}
                  imageUrl={item.shopCatalogProductImage?.url}
                  ActionButton={
                    <ButtonCircleWrapper>
                      <BookmarkButton
                        size="medium"
                        isBookmarked={item.isLikedByMe}
                        handleClick={() =>
                          handleBookmarkClick(item.id, item.isLikedByMe)
                        }
                      />
                    </ButtonCircleWrapper>
                  }
                  Tags={<TagChips tags={item.customTags} wrap={true} />}
                />
              </Grid>
            ))}
          </Grid>
        );
    }
  };

  return (
    <>
      {renderItems(items)}
      <Box mt={1}>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={(event, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={perPage}
          labelRowsPerPage={t("Per page:", {
            ns: "Global",
          })}
          onRowsPerPageChange={event => {
            setPerPage(parseInt(event.target.value, 10));
          }}
        />
      </Box>
    </>
  );
};
