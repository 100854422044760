import { css } from "@emotion/react";
import { StyledComponent } from "@emotion/styled";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { MoneyOff as MoneyOffIcon } from "@mui/icons-material";
import { VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { Theme, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import React from "react";
import { Link, To } from "react-router-dom";
import { ReactComponent as FileMoveIcon } from "../assets/icons/icon-file-move.svg";
import { useContextMenu } from "../commons/hooks/useContextMenu.js";
import { color, px, size } from "../../common/MuiThemeProvider.js";
import { Agreement } from "../../clients/graphqlTypes.js";
import { transientOptions } from "../styles.js";
import {
  TreeDataIconsWrapper,
  TreeDataPath,
  TreeDataWrapper,
} from "./components/TreeDataContainer.js";
import { TreeIconLeftContainer } from "./components/TreeIconLeftContainer.js";
import { TreeIconRightContainer } from "./components/TreeIconRightContainer.js";
import { TreeTitle } from "./components/TreeTitle.js";
import { BareTreeItemType, ItemComponentProps } from "./types.js";

interface Props
  extends Omit<
    ItemComponentProps<BareTreeItemType>,
    "parentItem" | "allItems"
  > {
  docAgreement: Agreement;
  IconLeftButtons?: JSX.Element | null;
  IconRightButtons?: JSX.Element | null;
  Data123Left?: JSX.Element | null;
  Data123Right?: JSX.Element | null;
  ContextMenu?: JSX.Element | null;
  LeftStatus?: JSX.Element | null;
  RightNumber?: JSX.Element | null;
  RightIcons?: JSX.Element | null;
  showPath?: boolean;
  title?: string;
  pathForPdf?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  to?: To | null;
}

export const RIGHT_ICON_STYLE: React.CSSProperties = {
  width: 12,
  height: 12,
  fontSize: 12,
};

export const BareTreeItem = React.memo(
  ({
    to,
    onClick,
    depth,
    docAgreement,
    showPath = true,
    title,
    pathForPdf,
    isGreyedOut,
    isHidden,
    isPriceHidden,
    subcontractPath,
    item,
    collapseButton,
    IconLeftButtons,
    IconRightButtons,
    Data123Left,
    Data123Right,
    ContextMenu,
    RightNumber,
    LeftStatus,
    RightIcons,
  }: Props) => {
    const theme = useTheme();

    const [handleClick, menuElement] = useContextMenu<HTMLElement>(ContextMenu);

    const showHelpIcon =
      item.type === "decision" ||
      (item.decisionIsContingentItem && !item.isItemEliminated);

    return (
      <TreeItemContainer
        $docAgreement={docAgreement}
        $isGreyedOut={isGreyedOut}
        $item={item}
        $clickable={Boolean(onClick)}
        asLink={Boolean(to)}
        divProps={!to ? { onClick, onContextMenuCapture: handleClick } : null}
        linkProps={
          to
            ? {
                to: to!,
                onClick,
                onContextMenuCapture: handleClick,
                replace: true,
              }
            : null
        }
      >
        <ColoredLineS1 />

        {collapseButton || showHelpIcon || IconLeftButtons ? (
          <TreeIconLeftContainer>
            {collapseButton}
            {IconLeftButtons}
            {showHelpIcon ? (
              <HelpOutlineIcon
                fontSize="medium"
                style={{ color: theme.palette.warning.main }}
              />
            ) : null}
          </TreeIconLeftContainer>
        ) : null}

        <TreeTextContainer>
          {/*<TreeHint>*/}
          {/*  /!*<div>*!/*/}
          {/*  /!*  {showPath ? <span>{item.pathForPdf}</span> : null}*!/*/}
          {/*  /!*  {LeftStatus ? (*!/*/}
          {/*  /!*    <span className="status">{LeftStatus}</span>*!/*/}
          {/*  /!*  ) : statusLabel ? (*!/*/}
          {/*  /!*    <TreeStatusLabel>{t(statusLabel)}</TreeStatusLabel>*!/*/}
          {/*  /!*  ) : null}*!/*/}
          {/*  /!*</div>*!/*/}
          {/*  /!*<div>*!/*/}
          {/*  /!*  /!*{RightNumber ? (*!/*!/*/}
          {/*  /!*  /!*  <span className="number">{RightNumber}</span>*!/*!/*/}
          {/*  /!*  /!*) : null}*!/*!/*/}
          {/*  /!*  /!*{RightIcons ? <span>{RightIcons}</span> : null}*!/*!/*/}
          {/*  /!*  /!*{subcontractPath && <FileMoveIcon style={RIGHT_ICON_STYLE} />}*!/*!/*/}
          {/*  /!*  /!*{isHidden && <VisibilityOffIcon style={RIGHT_ICON_STYLE} />}*!/*!/*/}
          {/*  /!*  /!*{isPriceHidden && !isHidden && (*!/*!/*/}
          {/*  /!*  /!*  <MoneyOffIcon style={RIGHT_ICON_STYLE} />*!/*!/*/}
          {/*  /!*  /!*)}*!/*!/*/}
          {/*  /!*</div>*!/*/}
          {/*</TreeHint>*/}
          <TreeTitle $bold={item.type === "section"} $depth={depth}>
            {showPath && item.pathForPdf ? (
              <TreeDataPath $depth={depth}>
                {pathForPdf ?? item.pathForPdf}
              </TreeDataPath>
            ) : null}
            {title ?? item.title}
          </TreeTitle>
          <TreeDataWrapper>
            <span>{Data123Left}</span>
            <TreeDataIconsWrapper>
              {RightIcons ? (
                <TreeDataIconsWrapper>{RightIcons}</TreeDataIconsWrapper>
              ) : null}
              {subcontractPath && (
                <span>
                  <FileMoveIcon style={RIGHT_ICON_STYLE} />
                </span>
              )}
              {isHidden && (
                <span>
                  <VisibilityOffIcon style={RIGHT_ICON_STYLE} />
                </span>
              )}
              {isPriceHidden && !isHidden && (
                <span>
                  <MoneyOffIcon style={RIGHT_ICON_STYLE} />
                </span>
              )}
              {Data123Right ? (
                <span style={{ marginLeft: px.s }}>{Data123Right}</span>
              ) : null}
            </TreeDataIconsWrapper>
          </TreeDataWrapper>
        </TreeTextContainer>
        {IconRightButtons ? (
          <TreeIconRightContainer>{IconRightButtons}</TreeIconRightContainer>
        ) : null}
        {menuElement}
      </TreeItemContainer>
    );
  }
);

export const ColoredLineS1: StyledComponent<
  MUIStyledCommonProps<Theme>,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled("div")`
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
  width: 5px;

  pointer-events: none;

  position: absolute;

  left: 0;
  top: 0;
  bottom: 0;
`;

ColoredLineS1.defaultProps = {
  className: "msys-colored-line-s1",
};

const OptionalLink = <AsLink extends boolean>({
  asLink,
  children,
  linkProps,
  divProps,
  ...restProps
}: React.PropsWithChildren<{
  asLink: AsLink;
  linkProps: AsLink extends true ? React.ComponentProps<typeof Link> : null;
  divProps: AsLink extends true
    ? null
    : React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >;
}>) => {
  if (asLink)
    return (
      <Link {...restProps} {...linkProps!} draggable={false}>
        {children}
      </Link>
    );
  return (
    <div {...restProps} {...divProps!}>
      {children}
    </div>
  );
};

const TreeItemContainer = styled(
  OptionalLink,
  transientOptions
)<{
  $isGreyedOut: boolean;
  $docAgreement: Agreement;
  $item: BareTreeItemType;
  $clickable: boolean;
}>(
  ({ theme, $item, $docAgreement, $clickable, $isGreyedOut }) => css`
    // Reset link styles
    text-decoration: none;
    color: ${theme.palette.text.primary};

    background-color: unset;
    border-radius: unset;
    display: flex;
    flex: 1;
    flex-grow: 1;
    position: static;
    padding-left: 6px;
    padding-right: 6px;
    min-height: ${size.treeItemHeight}px;
    max-height: ${size.treeItemHeight}px;
    max-width: 100%;
    user-select: none;
    box-shadow: none;
    min-width: 0;

    ${$clickable ? `cursor: pointer;` : ""}
    ${$isGreyedOut ? `background-color: #F2F2F2;` : ""}
    
    ${$docAgreement !== "NONE" && $item.needsAgreement
      ? `
          .msys-colored-line-s1 {
            margin-right: ${px.xs};
            background-color: ${theme.palette.warning.main};
          }

          .msys-tree-status-label {
            color: ${theme.palette.warning.main};
          }
        `
      : ""}
    
    ${$item.decisionOptionElimination || $item.isItemEliminated
      ? `color: ${theme.palette.grey[600]};`
      : ""}
    
    ${$item.type === "defect"
      ? `
          color: ${color.red};
          .msys-colored-line-s1 {
            margin-right: ${px.xs};
            background-color: ${color.red};
          }
        `
      : ""}

    ${$item.type === "unpaid"
      ? `
          color: ${color.turquoise};
          .msys-colored-line-s1 {
            margin-right: ${px.xs};
            background-color: ${color.turquoise};
          }
        `
      : ""}

    ${$item.deletedAt
      ? ` 
          opacity: 0.4;
          text-decoration: line-through;

          .msys-colored-line-s1 {
            opacity: 1;
            text-decoration: none;
          }
         `
      : ""}

    ${$item.type === "paid" && $item.binding === "draft"
      ? `
          opacity: 0.38;

          .msys-tree-title {
            font-style: italic;
          }
        `
      : ""}
    
    ${$item.type === "paid" && $item.binding === "non_binding"
      ? `
          color: ${color.default};

          .msys-tree-title {
            font-style: italic;
          }
        `
      : ""}
  `
);

const TreeHint = styled("div")(`
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-size: 11px;
  height: 17px;
  overflow: hidden;

  & > div {
    & {
      display: inline-flex;
      margin-top: 4px;
    }
    span + span:not(:empty) {
      margin-left: 4px;
    }
    span + svg:not(:empty) {
      margin-left: 4px;
    }
    span.number {
      display: inline-block;
      position: relative;
    }
    span.status > * {
      font-size: 11px !important;
      line-height: 1.25 !important;
    }
  }
`);

const TreeTextContainer = styled("div")(`
  flex: 1;
  padding: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
`);
