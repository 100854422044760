/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../features/addresses/Addresses.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuildingsQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
}>;


export type BuildingsQuery = { buildings: { __typename: 'BuildingConnection', totalCount: number, edges: Array<{ __typename: 'BuildingEdge', node: { __typename: 'Building', id: string, title: string, createdAt: any, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } }> } };

export type BuildingFragment = { __typename: 'Building', id: string, title: string, createdAt: any, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export const BuildingFragmentDoc = gql`
    fragment Building on Building {
  id
  title
  createdAt
  buildingAddress {
    ...AddressDetails__Address
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export const BuildingsDocument = gql`
    query Buildings($offset: Int, $limit: Int!) {
  buildings(offset: $offset, limit: $limit) {
    edges {
      node {
        id
        ...Building
      }
    }
    totalCount
  }
}
    ${BuildingFragmentDoc}`;

/**
 * __useBuildingsQuery__
 *
 * To run a query within a React component, call `useBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBuildingsQuery(baseOptions: Apollo.QueryHookOptions<BuildingsQuery, BuildingsQueryVariables> & ({ variables: BuildingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingsQuery, BuildingsQueryVariables>(BuildingsDocument, options);
      }
export function useBuildingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingsQuery, BuildingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingsQuery, BuildingsQueryVariables>(BuildingsDocument, options);
        }
export function useBuildingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BuildingsQuery, BuildingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BuildingsQuery, BuildingsQueryVariables>(BuildingsDocument, options);
        }
export type BuildingsQueryHookResult = ReturnType<typeof useBuildingsQuery>;
export type BuildingsLazyQueryHookResult = ReturnType<typeof useBuildingsLazyQuery>;
export type BuildingsSuspenseQueryHookResult = ReturnType<typeof useBuildingsSuspenseQuery>;
export type BuildingsQueryResult = Apollo.QueryResult<BuildingsQuery, BuildingsQueryVariables>;