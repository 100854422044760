import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { omit, uniqueId } from "lodash-es";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import {
  SelectField,
  SelectOption,
} from "../../../commons/form-fields/SelectField.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { Modal } from "@msys/ui";
import { AddressInput } from "../../../../clients/graphqlTypes.js";
import {
  useBuildingCreateModalQuery,
  useBuildingCreate__CreateBuildingMutation,
} from "./BuildingCreateModal.generated.js";
import { useTranslate } from "@tolgee/react";
import { AddressField } from "../../addresses/AddressField.js";

const useData = () => {
  const client = useApolloClient();
  const query = useBuildingCreateModalQuery({
    client,
    variables: {},
  });
  return { data: query.data, isLoading: query.loading, refetch: query.refetch };
};

interface FormValues {
  title: string;
  projectsiteAddress?: AddressInput;
  buildingTemplateId: string;
}

export const BuildingCreateModal: React.FC<{
  id?: string;
  title?: string;
  buildingOwningOrganisationId: string;
  handleComplete?: (buildingId: string, buildingRootItemId: string) => void;
  handleClose: () => void;
  refetchQueries?: string[];
}> = ({
  id,
  title,
  buildingOwningOrganisationId,
  handleClose,
  handleComplete,
  refetchQueries,
}) => {
  const { t } = useTranslate(["BuildingCreate", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [createBuilding] = useBuildingCreate__CreateBuildingMutation({
    client,
  });

  const { data, isLoading } = useData();

  const onSubmit = async (values: FormValues) => {
    if (!values.projectsiteAddress) return;
    const res = await createBuilding({
      variables: {
        input: {
          title: values.title || values.projectsiteAddress!.streetLines1.trim(),
          buildingOwningOrganisationId,
          buildingAddress: {
            ...omit(values.projectsiteAddress, "__typename"),
            location: values.projectsiteAddress.location
              ? omit(values.projectsiteAddress.location, "__typename")
              : null,
          },
          templateQuoteId: values.buildingTemplateId || null,
        },
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });

    if (!res) return;

    enqueueSnackbar(
      t("Building created", {
        ns: "BuildingCreate",
      })
    );
    handleComplete?.(
      res.data!.createBuilding.building.id,
      res.data!.createBuilding.building.rootItem.id
    );
    handleClose();
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().label(t("Title", { ns: "BuildingCreate" })),
        projectsiteAddress: Yup.object()
          .label(t("Address", { ns: "BuildingCreate" }))
          .nullable()
          .required(t("Address is mandatory", { ns: "Global" })),
      }),
    [t]
  );

  const buildingTemplateOptions: SelectOption[] =
    getDataOrNull(data?.quoteTemplates)
      ?.edges.map(e => e.node)
      .map(e => ({
        value: e.id,
        label: e.title,
      })) ?? [];

  return (
    <Formik<FormValues>
      initialValues={{
        title: "",
        buildingTemplateId: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {formikProps => (
        <Modal
          id={id}
          title={
            title ??
            t("Create building", {
              ns: "BuildingCreate",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Create building", {
                ns: "BuildingCreate",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled:
                  !formikProps.dirty || !formikProps.isValid || isLoading,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={isLoading}
        >
          <Form id={formId}>
            <Stack flexDirection="column" spacing={2}>
              <AddressField
                required
                autoFocus
                name="projectsiteAddress"
                label={t("Address", {
                  ns: "BuildingCreate",
                })}
                allowCustom={true}
              />
              <Stack flexDirection="column">
                <Field
                  component={TextField}
                  name="title"
                  label={
                    t("Title", {
                      ns: "BuildingCreate",
                    }) +
                    ` (${t("optional", {
                      ns: "Global",
                    })})`
                  }
                  type="text"
                />
                <SelectField
                  disabled={!buildingTemplateOptions.length}
                  name="buildingTemplateId"
                  label={t("Building Template", {
                    ns: "BuildingCreate",
                  })}
                  options={buildingTemplateOptions}
                />
              </Stack>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
