import { gql } from "@apollo/client";
import { notNull } from "@msys/common";
import { Alert, AlertTitle, Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link } from "react-router-dom";
import { useUserData } from "../../../auth/useUserData.js";
import { WarningsIconButton } from "../../../commons/button/WarningsIconButton.js";
import { TemplateQuoteWarnings_QuoteTemplateFragment } from "./TemplateQuoteWarnings.generated.js";

export const useTemplateQuoteWarnings = () => {
  const { t } = useTranslate(["Global", "Templates"]);
  const viewer = useUserData().currentUser!;

  const getTemplateQuoteWarnings = (
    pathToDoc: string,
    doc: TemplateQuoteWarnings_QuoteTemplateFragment
  ) => {
    const isNewerVersionAvailable =
      !!doc.latestPublishedVersionNumber &&
      !!doc.resolvedAsReadModelVersionNumber &&
      doc.latestPublishedVersionNumber > doc.resolvedAsReadModelVersionNumber;
    const isLatestPublishedVersion =
      !!doc.latestPublishedVersionNumber &&
      !!doc.resolvedAsReadModelVersionNumber &&
      doc.resolvedAsReadModelVersionNumber === doc.latestPublishedVersionNumber;
    const isOwnTemplate =
      doc.owningSystemOrganisationId === viewer.organisation.id;

    const warnings: React.ReactNode[] = [
      isNewerVersionAvailable ? (
        <Alert
          key={"outdated-version"}
          variant="outlined"
          severity="warning"
          action={
            <Button
              component={Link}
              to={`${pathToDoc}/${doc.latestPublishedVersionNumber}`}
              color="inherit"
            >
              {t("View", { ns: "Global" })}
            </Button>
          }
        >
          <AlertTitle>
            {t("Outdated template!", { ns: "Templates" })}
          </AlertTitle>
          {t("There is a newer version of this template available.", {
            ns: "Templates",
          })}
        </Alert>
      ) : null,
      isOwnTemplate &&
      isLatestPublishedVersion &&
      doc.containsUnpublishedChanged &&
      viewer.organisationPermissions.includes("MANAGE_TEMPLATE") ? (
        <Alert
          key={"outdated-version"}
          variant="outlined"
          severity="warning"
          action={
            <Button
              component={Link}
              to={`${pathToDoc}/latest/edit`}
              color="inherit"
            >
              {t("View", {
                ns: "Global",
              })}
            </Button>
          }
        >
          <AlertTitle>
            {t("Unpublished changes pending!", {
              ns: "Templates",
            })}
          </AlertTitle>
          {t(
            "This template contains changes that have not yet been published.",
            {
              ns: "Templates",
            }
          )}
        </Alert>
      ) : null,
    ].filter(notNull);

    return warnings;
  };

  const getTemplateQuoteWarningIcon = (
    pathToDoc: string,
    doc: TemplateQuoteWarnings_QuoteTemplateFragment
  ) => {
    const warnings = getTemplateQuoteWarnings(pathToDoc, doc);
    return warnings.length > 0 ? (
      <WarningsIconButton key={"warnings"} warnings={warnings} />
    ) : null;
  };

  return { getTemplateQuoteWarnings, getTemplateQuoteWarningIcon };
};
