import { gql } from "@apollo/client";
import { useFormatting } from "@msys/ui";
import {
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { CrmEmailListItem_CrmMailMessageFragment } from "./CrmEmailListItem.generated.js";

interface Props {
  email: CrmEmailListItem_CrmMailMessageFragment;
  selected: ListItemButtonProps["selected"];
  isThreadRoot: boolean;
  divider: boolean;
  onClick?: () => void;
}

export const CrmEmailListItem = ({
  email,
  selected,
  isThreadRoot,
  divider,
  onClick,
}: Props) => {
  const { getFormattedDateTime } = useFormatting();
  const { t } = useTranslate(["Email"]);

  return (
    <ListItemButton
      dense
      selected={selected}
      onClick={onClick}
      sx={!isThreadRoot ? { paddingLeft: 4 } : undefined}
      divider={divider}
    >
      <ListItemText
        primary={
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{email.subject}</Typography>
            <Typography variant="body2">
              {getFormattedDateTime(email.createdAt)}
            </Typography>
          </Stack>
        }
        secondary={
          <Stack>
            <Typography variant="caption">
              {t("From", { ns: "Email" })}: {email.from}
            </Typography>
            <Typography variant="caption">
              {t("To", { ns: "Email" })}: {email.to}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
};
