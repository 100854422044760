/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type PurchaseOrderStatusBadge_OrderFragment = { __typename: 'Order', id: string, orderedDate?: any | null };

export type PurchaseOrderStatusBadge_OrderNodeFragment = { __typename: 'OrderNode', id: string, orderedDate?: any | null };

export const PurchaseOrderStatusBadge_OrderFragmentDoc = gql`
    fragment PurchaseOrderStatusBadge_Order on Order {
  id
  orderedDate
}
    `;
export const PurchaseOrderStatusBadge_OrderNodeFragmentDoc = gql`
    fragment PurchaseOrderStatusBadge_OrderNode on OrderNode {
  id
  orderedDate
}
    `;