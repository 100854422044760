import { flatten, uniq } from "lodash-es";
import React from "react";
import { Tabs } from "@msys/ui";
import { ItemBinding, ItemType } from "../../../clients/graphqlTypes.js";
import { useTranslate } from "@tolgee/react";
import { getAllParentItems, isPricedItem } from "../../trees/helpers.js";
import { TreeHelperItem } from "../../trees/types.js";

type QuickFilterValue = "ALL" | "BINDING" | "NON-BINDING" | "DRAFT" | "CHANGE";

interface FilterableItem extends TreeHelperItem {
  type: ItemType;
  binding: ItemBinding;
  needsAgreement?: boolean;
}

export function useQuoteQuickFilters<T extends FilterableItem>(
  hasAgreement = false
): [React.ComponentType, (items: T[]) => T[]] {
  const { t } = useTranslate("QuoteEdit");
  const [quickFilter, setQuickFilter] = React.useState<QuickFilterValue>("ALL");
  const FilterTabs = React.useCallback(() => {
    const options: { value: QuickFilterValue; label: string }[] = [
      { value: "ALL", label: t("All") },
      { value: "BINDING", label: t("Binding") },
      { value: "NON-BINDING", label: t("Non-Binding") },
      { value: "DRAFT", label: t("Draft") },
    ];
    if (hasAgreement) {
      options.push({ value: "CHANGE", label: t("Change") });
    }

    return (
      <Tabs
        condensed
        onChange={setQuickFilter}
        value={quickFilter}
        options={options}
      />
    );
  }, [hasAgreement, quickFilter, setQuickFilter, t]);

  const applyQuickFilter = React.useCallback(
    (allDocItems: T[]) => {
      if (quickFilter !== "ALL") {
        const criteriaItems = allDocItems.filter(item => {
          if (!isPricedItem(item)) {
            return false;
          }
          switch (quickFilter) {
            case "CHANGE":
              return item.needsAgreement;
            case "BINDING":
              return item.binding === "binding";
            case "NON-BINDING":
              return item.binding === "non_binding";
            case "DRAFT":
              return item.binding === "draft";
            default:
              return true;
          }
        });

        return uniq([
          ...criteriaItems,
          ...flatten(
            criteriaItems.map(item => getAllParentItems(item, allDocItems))
          ),
        ]);
      }

      return allDocItems;
    },
    [quickFilter]
  );

  return [FilterTabs, applyQuickFilter];
}
