import { StyledComponent } from "@emotion/styled";
import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import React from "react";
import { transientOptions } from "../../styles.js";

interface Props {
  $display?: React.CSSProperties["display"];
}

export const PageSelectedSection: StyledComponent<
  MUIStyledCommonProps<Theme> & Props,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled(
  "div",
  transientOptions
)<Props>(({ theme, $display = "flex" }) => ({
  boxSizing: "border-box",
  display: $display,
  flexDirection: "column",
  flexGrow: 1,
  flexShrink: 1,
  position: "relative",
  [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: `8px`,
    margin: `${theme.spacing(2)} 0`,
  },
}));
