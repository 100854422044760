/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated.js';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuildingItemLinks_QuoteItemLinkCrmOrganisation_Fragment = { __typename: 'QuoteItemLinkCrmOrganisation', id: string, linkAs: string, crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, phones: Array<{ __typename: 'Phone', number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type BuildingItemLinks_QuoteItemLinkCrmUser_Fragment = { __typename: 'QuoteItemLinkCrmUser', id: string, linkAs: string, crmUser: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type BuildingItemLinksFragment = BuildingItemLinks_QuoteItemLinkCrmOrganisation_Fragment | BuildingItemLinks_QuoteItemLinkCrmUser_Fragment;

export type BuildingAddCrmUserLinkMutationVariables = Types.Exact<{
  input: Types.AddItemLinkCrmUserInput;
}>;


export type BuildingAddCrmUserLinkMutation = { addItemLinkCrmUser: { __typename: 'AddItemLinkCrmUserResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, crmLinks: Array<{ __typename: 'QuoteItemLinkCrmOrganisation', id: string, linkAs: string, crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, phones: Array<{ __typename: 'Phone', number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | { __typename: 'QuoteItemLinkCrmUser', id: string, linkAs: string, crmUser: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> } } };

export type BuildingAddCrmOrganisationLinkMutationVariables = Types.Exact<{
  input: Types.AddItemLinkCrmOrganisationInput;
}>;


export type BuildingAddCrmOrganisationLinkMutation = { addItemLinkCrmOrganisation: { __typename: 'AddItemLinkCrmOrganisationResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, crmLinks: Array<{ __typename: 'QuoteItemLinkCrmOrganisation', id: string, linkAs: string, crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, phones: Array<{ __typename: 'Phone', number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | { __typename: 'QuoteItemLinkCrmUser', id: string, linkAs: string, crmUser: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> } } };

export type BuildingRemoveCrmUserLinkMutationVariables = Types.Exact<{
  input: Types.RemoveItemLinkCrmUserInput;
}>;


export type BuildingRemoveCrmUserLinkMutation = { removeItemLinkCrmUser: boolean };

export type BuildingRemoveCrmOrganisationLinkMutationVariables = Types.Exact<{
  input: Types.RemoveItemLinkCrmOrganisationInput;
}>;


export type BuildingRemoveCrmOrganisationLinkMutation = { removeItemLinkCrmOrganisation: boolean };

export const BuildingItemLinksFragmentDoc = gql`
    fragment BuildingItemLinks on ItemCrmLink {
  ... on QuoteItemLinkCrmUser {
    __typename
    id
    crmUser {
      id
      title
      description
      website
      isMeister
      familyname
      firstname
      fullname
      locale
      skillset
      email
      phones {
        id
        main
        type
        number
      }
      avatar {
        ...AttachmentSnapshot
      }
    }
    linkAs
  }
  ... on QuoteItemLinkCrmOrganisation {
    __typename
    id
    crmOrganisation {
      id
      title
      phones {
        number
      }
      logo {
        ...AttachmentSnapshot
      }
      title
    }
    linkAs
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const BuildingAddCrmUserLinkDocument = gql`
    mutation BuildingAddCrmUserLink($input: AddItemLinkCrmUserInput!) {
  addItemLinkCrmUser(input: $input) {
    item {
      id
      originVersionNumber
      crmLinks {
        ...BuildingItemLinks
      }
    }
  }
}
    ${BuildingItemLinksFragmentDoc}`;
export type BuildingAddCrmUserLinkMutationFn = Apollo.MutationFunction<BuildingAddCrmUserLinkMutation, BuildingAddCrmUserLinkMutationVariables>;

/**
 * __useBuildingAddCrmUserLinkMutation__
 *
 * To run a mutation, you first call `useBuildingAddCrmUserLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingAddCrmUserLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingAddCrmUserLinkMutation, { data, loading, error }] = useBuildingAddCrmUserLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildingAddCrmUserLinkMutation(baseOptions?: Apollo.MutationHookOptions<BuildingAddCrmUserLinkMutation, BuildingAddCrmUserLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingAddCrmUserLinkMutation, BuildingAddCrmUserLinkMutationVariables>(BuildingAddCrmUserLinkDocument, options);
      }
export type BuildingAddCrmUserLinkMutationHookResult = ReturnType<typeof useBuildingAddCrmUserLinkMutation>;
export type BuildingAddCrmUserLinkMutationResult = Apollo.MutationResult<BuildingAddCrmUserLinkMutation>;
export type BuildingAddCrmUserLinkMutationOptions = Apollo.BaseMutationOptions<BuildingAddCrmUserLinkMutation, BuildingAddCrmUserLinkMutationVariables>;
export const BuildingAddCrmOrganisationLinkDocument = gql`
    mutation BuildingAddCrmOrganisationLink($input: AddItemLinkCrmOrganisationInput!) {
  addItemLinkCrmOrganisation(input: $input) {
    item {
      id
      originVersionNumber
      crmLinks {
        ...BuildingItemLinks
      }
    }
  }
}
    ${BuildingItemLinksFragmentDoc}`;
export type BuildingAddCrmOrganisationLinkMutationFn = Apollo.MutationFunction<BuildingAddCrmOrganisationLinkMutation, BuildingAddCrmOrganisationLinkMutationVariables>;

/**
 * __useBuildingAddCrmOrganisationLinkMutation__
 *
 * To run a mutation, you first call `useBuildingAddCrmOrganisationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingAddCrmOrganisationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingAddCrmOrganisationLinkMutation, { data, loading, error }] = useBuildingAddCrmOrganisationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildingAddCrmOrganisationLinkMutation(baseOptions?: Apollo.MutationHookOptions<BuildingAddCrmOrganisationLinkMutation, BuildingAddCrmOrganisationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingAddCrmOrganisationLinkMutation, BuildingAddCrmOrganisationLinkMutationVariables>(BuildingAddCrmOrganisationLinkDocument, options);
      }
export type BuildingAddCrmOrganisationLinkMutationHookResult = ReturnType<typeof useBuildingAddCrmOrganisationLinkMutation>;
export type BuildingAddCrmOrganisationLinkMutationResult = Apollo.MutationResult<BuildingAddCrmOrganisationLinkMutation>;
export type BuildingAddCrmOrganisationLinkMutationOptions = Apollo.BaseMutationOptions<BuildingAddCrmOrganisationLinkMutation, BuildingAddCrmOrganisationLinkMutationVariables>;
export const BuildingRemoveCrmUserLinkDocument = gql`
    mutation BuildingRemoveCrmUserLink($input: RemoveItemLinkCrmUserInput!) {
  removeItemLinkCrmUser(input: $input)
}
    `;
export type BuildingRemoveCrmUserLinkMutationFn = Apollo.MutationFunction<BuildingRemoveCrmUserLinkMutation, BuildingRemoveCrmUserLinkMutationVariables>;

/**
 * __useBuildingRemoveCrmUserLinkMutation__
 *
 * To run a mutation, you first call `useBuildingRemoveCrmUserLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingRemoveCrmUserLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingRemoveCrmUserLinkMutation, { data, loading, error }] = useBuildingRemoveCrmUserLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildingRemoveCrmUserLinkMutation(baseOptions?: Apollo.MutationHookOptions<BuildingRemoveCrmUserLinkMutation, BuildingRemoveCrmUserLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingRemoveCrmUserLinkMutation, BuildingRemoveCrmUserLinkMutationVariables>(BuildingRemoveCrmUserLinkDocument, options);
      }
export type BuildingRemoveCrmUserLinkMutationHookResult = ReturnType<typeof useBuildingRemoveCrmUserLinkMutation>;
export type BuildingRemoveCrmUserLinkMutationResult = Apollo.MutationResult<BuildingRemoveCrmUserLinkMutation>;
export type BuildingRemoveCrmUserLinkMutationOptions = Apollo.BaseMutationOptions<BuildingRemoveCrmUserLinkMutation, BuildingRemoveCrmUserLinkMutationVariables>;
export const BuildingRemoveCrmOrganisationLinkDocument = gql`
    mutation BuildingRemoveCrmOrganisationLink($input: RemoveItemLinkCrmOrganisationInput!) {
  removeItemLinkCrmOrganisation(input: $input)
}
    `;
export type BuildingRemoveCrmOrganisationLinkMutationFn = Apollo.MutationFunction<BuildingRemoveCrmOrganisationLinkMutation, BuildingRemoveCrmOrganisationLinkMutationVariables>;

/**
 * __useBuildingRemoveCrmOrganisationLinkMutation__
 *
 * To run a mutation, you first call `useBuildingRemoveCrmOrganisationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingRemoveCrmOrganisationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingRemoveCrmOrganisationLinkMutation, { data, loading, error }] = useBuildingRemoveCrmOrganisationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildingRemoveCrmOrganisationLinkMutation(baseOptions?: Apollo.MutationHookOptions<BuildingRemoveCrmOrganisationLinkMutation, BuildingRemoveCrmOrganisationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingRemoveCrmOrganisationLinkMutation, BuildingRemoveCrmOrganisationLinkMutationVariables>(BuildingRemoveCrmOrganisationLinkDocument, options);
      }
export type BuildingRemoveCrmOrganisationLinkMutationHookResult = ReturnType<typeof useBuildingRemoveCrmOrganisationLinkMutation>;
export type BuildingRemoveCrmOrganisationLinkMutationResult = Apollo.MutationResult<BuildingRemoveCrmOrganisationLinkMutation>;
export type BuildingRemoveCrmOrganisationLinkMutationOptions = Apollo.BaseMutationOptions<BuildingRemoveCrmOrganisationLinkMutation, BuildingRemoveCrmOrganisationLinkMutationVariables>;