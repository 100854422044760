import { LabeledValue, Modal } from "@msys/ui";
import { Alert, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { capitalize, merge, uniqueId } from "lodash-es";
import React from "react";
import * as Yup from "yup";
import {
  DefineItemProps2Entry,
  Props2,
} from "../../../../clients/graphqlTypes.js";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField.js";
import { FormattedFloatOptionalField } from "../../../commons/form-fields/FormattedFloatOptionalField.js";
import { TextField } from "../../../commons/form-fields/TextField.js";
import {
  AllowedValue,
  getAllowedValues,
  isSameTypeForAllowedValues,
  useAllowedValuesFieldValidationSchema,
} from "../form-fields/PropertyAllowedValuesField.js";
import { PropertyAllowedValuesFieldFromTemplateType } from "../form-fields/PropertyAllowedValuesFieldFromTemplateType.js";
import {
  getPropertyType,
  isArrayType,
  isNonComputedProp,
  isNumberType,
  isTextType,
  PROP_VALUES,
} from "../properties.js";
import { QuestionControlSection } from "../QuestionControlSection.js";
import { usePropertyUnits } from "../usePropertyUnits.js";
import { createPropertyEntry, FormValues } from "./PropertyEditModal.js";
import { v4 } from "uuid";

interface Props {
  projectId: string | null;
  docId: string;
  itemId: string;
  property: Props2;
  templateTypeProperty: Props2;
  handleClose: () => void;
  handleComplete: (changedProperty: DefineItemProps2Entry) => Promise<void>;
}

export function PropertyEditWithTemplateTypeModal({
  projectId,
  docId,
  itemId,
  property,
  templateTypeProperty,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["Global", "QuoteItem"]);
  const { getUnits } = usePropertyUnits();
  const unitsOptions = React.useMemo(() => getUnits(), [getUnits]);
  const [error, setError] = React.useState<Error | null>(null);

  const templateTypeCanHaveRange =
    isNumberType(templateTypeProperty) &&
    isNonComputedProp(templateTypeProperty);

  const templateTypeHasDefinedRange =
    templateTypeCanHaveRange && Boolean(templateTypeProperty.range);

  const propertyHasDefinedRange =
    isNumberType(property) &&
    isNonComputedProp(property) &&
    Boolean(property.range);

  const templateTypeRangeMin = templateTypeHasDefinedRange
    ? (templateTypeProperty.range?.min ?? null)
    : null;
  const templateTypeRangeMax = templateTypeHasDefinedRange
    ? (templateTypeProperty.range?.max ?? null)
    : null;

  const templateTypeHasAllowedValues =
    isNonComputedProp(templateTypeProperty) &&
    ((isTextType(templateTypeProperty) &&
      templateTypeProperty.allowedValuesText.length > 0) ||
      (isNumberType(templateTypeProperty) &&
        templateTypeProperty.allowedValuesNumber.length > 0));

  const propertyHasAllowedValues =
    isNonComputedProp(property) &&
    ((isTextType(property) && property.allowedValuesText.length > 0) ||
      (isNumberType(property) && property.allowedValuesNumber.length > 0));

  const templateTypeAllowedValues = React.useMemo(
    () => getAllowedValues(templateTypeProperty),
    [templateTypeProperty]
  );
  const propertyAllowedValues = React.useMemo(
    () => getAllowedValues(property),
    [property]
  );

  const propertyExcludedAllowedValues: AllowedValue[] = React.useMemo(
    () =>
      isSameTypeForAllowedValues(templateTypeProperty, property)
        ? propertyAllowedValues.filter(
            av => !templateTypeAllowedValues.some(tav => tav.value === av.value)
          )
        : propertyAllowedValues,
    [
      property,
      propertyAllowedValues,
      templateTypeAllowedValues,
      templateTypeProperty,
    ]
  );
  const propertyType = getPropertyType(property);

  const initialValues: FormValues = React.useMemo(
    () => ({
      label: property.label,
      type: getPropertyType(templateTypeProperty),
      defineAllowedValues:
        templateTypeHasAllowedValues || propertyHasAllowedValues,
      allowedValues:
        templateTypeHasAllowedValues &&
        isSameTypeForAllowedValues(templateTypeProperty, property)
          ? templateTypeAllowedValues.filter(tav =>
              propertyAllowedValues.some(av => av.value === tav.value)
            )
          : isNonComputedProp(property)
            ? isTextType(property)
              ? property.allowedValuesText.map(allowedValueText => ({
                  key: v4(),
                  media: allowedValueText.media ?? null,
                  value: allowedValueText.allowedText,
                }))
              : isNumberType(property)
                ? property.allowedValuesNumber.map(allowedValueNumber => ({
                    key: v4(),
                    media: allowedValueNumber.media ?? null,
                    value: allowedValueNumber.allowedNumber,
                  }))
                : []
            : [],
      setRange: templateTypeHasDefinedRange || propertyHasDefinedRange,
      range: templateTypeCanHaveRange
        ? merge(
            { min: null, max: null },
            {
              min:
                isNumberType(property) && isNonComputedProp(property)
                  ? property.range?.min
                  : null,
              max:
                isNumberType(property) && isNonComputedProp(property)
                  ? property.range?.max
                  : null,
            }
          )
        : { min: null, max: null },
      essential: false,
      unit:
        isNumberType(templateTypeProperty) && templateTypeProperty.unit
          ? (unitsOptions.find(
              option => option.key === templateTypeProperty.unit
            ) ?? null)
          : null,
      group: property.group,
      clientVisibility: property.clientVisibility,
      prompt: isNonComputedProp(property) ? property.prompt : "",
      askWhen: isNonComputedProp(property) ? property.askWhen : [],
      askWhom: isNonComputedProp(property) ? property.askWhom : [],
      isArray: isArrayType(templateTypeProperty),
      askIfExpr: "askIfExpr" in property ? property.askIfExpr : "",
    }),
    [
      property,
      propertyAllowedValues,
      templateTypeAllowedValues,
      templateTypeCanHaveRange,
      templateTypeProperty,
      unitsOptions,
      templateTypeHasDefinedRange,
      templateTypeHasAllowedValues,
      propertyHasAllowedValues,
      propertyHasDefinedRange,
    ]
  );

  const allowedValuesSchema = useAllowedValuesFieldValidationSchema();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        label: Yup.string()
          .required()
          .min(1)
          .label(t("Name", { ns: "Global" })),
        type: Yup.string().oneOf(PROP_VALUES).required(),
        setRange: Yup.boolean(),
        range: Yup.object()
          .shape({
            min: Yup.number()
              .label(t("Min", { ns: "QuoteItem" }))
              .nullable(),
            max: Yup.number()
              .label(t("Max", { ns: "QuoteItem" }))
              .nullable(),
          })
          .when("setRange", {
            is: true,
            then: Yup.object().shape({
              min: Yup.number()
                .label(t("Min", { ns: "QuoteItem" }))
                .nullable()
                .when([], {
                  is: () => templateTypeRangeMin !== null,
                  then: schema =>
                    schema.test({
                      test: (min: number | null | undefined) => {
                        return !(min === null || min === undefined);
                      },
                      message: t("Min should be defined", { ns: "Global" }),
                    }),
                })
                .when([], {
                  is: () => templateTypeRangeMin !== null,
                  then: schema => schema.min(templateTypeRangeMin!),
                }),
              max: Yup.number()
                .label(t("Max", { ns: "QuoteItem" }))
                .nullable()
                .when([], {
                  is: () => templateTypeRangeMax !== null,
                  then: schema =>
                    schema.test({
                      test: (max: number | null | undefined) => {
                        return !(max === null || max === undefined);
                      },
                      message: t("Max should be defined", { ns: "Global" }),
                    }),
                })
                .when([], {
                  is: () => templateTypeRangeMax !== null,
                  then: schema => schema.max(templateTypeRangeMax!),
                })
                .when("min", (min: number | null, schema: any) => {
                  return schema
                    .test({
                      test: (max: number | null) => {
                        return !(min === null && max === null);
                      },
                      message: t("Min or max should be defined", {
                        ns: "Global",
                      }),
                    })
                    .test({
                      test: (max: number | null) =>
                        !(min !== null && max !== null && min > max),
                      message: t("Max should be greater or equal than min", {
                        ns: "Global",
                      }),
                    });
                }),
            }),
          }),
        essential: Yup.boolean().required(),
        unit: Yup.object().shape({ key: Yup.string().required() }).nullable(),
        group: Yup.string(),
        prompt: Yup.string(),
        askWhen: Yup.array(Yup.string()),
        askWhom: Yup.array(Yup.string()),
        ...allowedValuesSchema,
      }),
    [t, allowedValuesSchema, templateTypeRangeMin, templateTypeRangeMax]
  );

  const onSubmit = async (values: FormValues) => {
    try {
      await handleComplete(createPropertyEntry(values, property));
      handleClose();
    } catch (e) {
      const error = e instanceof Error ? e : new Error(JSON.stringify(e));
      setError(error);
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Modal
          title={t("Edit property", { ns: "QuoteItem" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Confirm", { ns: "Global" }),
              buttonProps: {
                form: formId,
                type: "submit",
                loading: formikProps.isSubmitting,
                disabled: !formikProps.isValid || !formikProps.dirty,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack spacing={1}>
              {error && <Alert severity="error">{error.message}</Alert>}

              <LabeledValue label={t("Key", { ns: "QuoteItem" })}>
                {property.key}
              </LabeledValue>

              <TextField
                name={"label"}
                label={t("Label", { ns: "Global" })}
                autoFocus
              />

              <LabeledValue label={t("Type", { ns: "QuoteItem" })}>
                {capitalize(formikProps.values.type)}
              </LabeledValue>

              {formikProps.values.type === "NUMBER" && (
                <LabeledValue
                  label={t("Unit", { ns: "QuoteItem" })}
                  notSet={!formikProps.values.unit?.value}
                >
                  {formikProps.values.unit?.value ||
                    t("Not set", { ns: "Global" })}
                </LabeledValue>
              )}

              {formikProps.values.type !== "BOOLEAN" && (
                <CheckboxField
                  name={"isArray"}
                  label={t("Allow multiple values", {
                    ns: "QuoteItem",
                  })}
                  disabled
                />
              )}

              {formikProps.values.type !== "BOOLEAN" &&
                isNonComputedProp(property) && (
                  <PropertyAllowedValuesFieldFromTemplateType
                    type={formikProps.values.type}
                    disabled={
                      (formikProps.values.type === "NUMBER" &&
                        formikProps.values.setRange) ||
                      formikProps.isSubmitting
                    }
                    templateTypeAllowedValues={templateTypeAllowedValues}
                    excludedType={propertyType}
                    excludedAllowedValues={propertyExcludedAllowedValues}
                  />
                )}

              {formikProps.values.type === "NUMBER" &&
                isNonComputedProp(property) && (
                  <CheckboxField
                    name={"setRange"}
                    label={t("Set min and/or max", { ns: "QuoteItem" })}
                    disabled={
                      templateTypeHasDefinedRange ||
                      formikProps.values.defineAllowedValues ||
                      formikProps.isSubmitting
                    }
                  />
                )}
              {formikProps.values.type === "NUMBER" &&
                isNonComputedProp(property) &&
                formikProps.values.setRange && (
                  <Stack direction={"row"} spacing={1} paddingLeft={4} flex={1}>
                    <FormattedFloatOptionalField
                      name={"range.min"}
                      label={t("Min", { ns: "QuoteItem" })}
                    />
                    <FormattedFloatOptionalField
                      name={"range.max"}
                      label={t("Max", { ns: "QuoteItem" })}
                    />
                  </Stack>
                )}
              <TextField name={"group"} label={"Group"} />
              <CheckboxField
                name={`clientVisibility`}
                label={t("Visibility", { ns: "Global" })}
              />
              {isNonComputedProp(property) && (
                <QuestionControlSection isInitiallyExpanded />
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
