import { CardContainer } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { To, useNavigate, useOutletContext } from "react-router-dom";
import { DocType, ItemType } from "../../../../clients/graphqlTypes.js";
import { VirtualBareTreeItem } from "../../../trees-virtual/components/VirtualBareTreeItem.js";
import { InputComponentProps } from "../../../trees/types.js";
import {
  createRecursiveTryFocusElementFunction,
  entriesAmount,
  isInTestMode,
} from "../../../utils.js";
import { getSubcontractPath } from "../../quotes/helpers.js";
import { ChildVisibilityIconButton } from "../buttons/ChildVisibilityIconButton.js";
import { getDefaultCreatableSubItemTypes } from "../constraints.js";
import { isItemGreyedOut } from "../helpers.js";
import {
  SubelementsBox_ChildItemFragment,
  SubelementsBox_ItemFragment,
} from "./SubelementsBox.generated.js";

const QUOTE_TYPES: DocType[] = ["QUOTE", "REQUIREMENT", "TEMPLATE"];

export interface SubitemComponentProps<Item> {
  item: Item;
  isRootItem: boolean;
  depth: number;
  isGreyedOut: boolean;
  isHidden: boolean;
  isPriceHidden: boolean;
  subcontractPath: string | null;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  to?: To | null;
}
export type SubitemComponent<Item> = React.ComponentType<
  SubitemComponentProps<Item>
>;

interface Props<ChildItem extends SubelementsBox_ChildItemFragment> {
  projectId: string | null;
  doc: { id: string; docType: DocType; templateIsDraft: boolean };
  item: SubelementsBox_ItemFragment;
  documentItemTypes: ItemType[];
  pathToDocPage: string;
  canChangeVisibility?: boolean;
  isEditable?: boolean;
  shouldRenderCreateInput?: (item: SubelementsBox_ItemFragment) => boolean;
  shouldRenderItem?: (item: ChildItem) => boolean;

  subitemComponent: SubitemComponent<ChildItem>;
  isInitiallyClosed?: boolean;
}

export const SubelementsBox = <
  ChildItem extends SubelementsBox_ChildItemFragment,
>({
  projectId,
  doc,
  item,
  documentItemTypes,
  pathToDocPage,
  canChangeVisibility = false,
  isEditable = true,
  shouldRenderItem,
  shouldRenderCreateInput,
  subitemComponent: ItemComponent,
  isInitiallyClosed,
}: Props<ChildItem>) => {
  const navigate = useNavigate();
  const { t } = useTranslate("QuoteItem");

  const { expandedItemIds, CreateChildComponent } = useOutletContext<{
    expandedItemIds: string[] | undefined;
    CreateChildComponent: React.ComponentType<
      InputComponentProps<
        SubelementsBox_ItemFragment,
        SubelementsBox_ChildItemFragment
      >
    >;
  }>();

  let itemChildren = item.children;
  if (shouldRenderItem) {
    itemChildren = itemChildren.filter(childItem =>
      shouldRenderItem(childItem as ChildItem)
    );
  }

  const showVisibilityButton =
    isEditable &&
    canChangeVisibility &&
    QUOTE_TYPES.includes(doc.docType) &&
    (item.type === "section" || item.type === "decision") &&
    item.agreement !== "YES";

  const showCreateInput =
    shouldRenderCreateInput?.(item) !== false &&
    !("linkedQuoteTemplate" in item && Boolean(item.linkedQuoteTemplate)) &&
    getDefaultCreatableSubItemTypes(item.type, documentItemTypes).length > 0;

  return (
    <CardContainer
      isExpandable
      isInitiallyClosed={
        isInitiallyClosed ??
        !(itemChildren.length > 0 || (isEditable && showCreateInput))
      }
      itemCount={itemChildren.length}
      title={t("Subelements")}
      ActionButton={
        showVisibilityButton ? (
          <ChildVisibilityIconButton
            projectId={projectId}
            doc={doc}
            item={item}
          />
        ) : null
      }
    >
      {(itemChildren.length > 0 || (isEditable && showCreateInput)) && (
        <Box m={1}>
          {itemChildren.map(child => {
            return (
              <VirtualBareTreeItem
                key={child.id}
                depth={0}
                clickable={true}
                isGreyedOut={isItemGreyedOut(child)}
                isInput={false}
              >
                <ItemComponent
                  item={child as ChildItem}
                  isRootItem={false}
                  depth={entriesAmount(child.pathForPdf, ".")}
                  isGreyedOut={false} // already used it
                  isHidden={child.isHidden}
                  isPriceHidden={child.isPriceHidden}
                  subcontractPath={getSubcontractPath(child)}
                  onClick={() =>
                    navigate(`${pathToDocPage}/items/${child.id}`, {
                      replace: true,
                    })
                  }
                />
              </VirtualBareTreeItem>
            );
          })}
          {isEditable && showCreateInput ? (
            <VirtualBareTreeItem isInput>
              <CreateChildComponent
                id={`msys-subelements-input-${item.type}-${item.id}`}
                item={item}
                itemChildren={item.children}
                isRootItem={item.isRootItem}
                autoFocus={false}
                nodeRank={null}
                resetFocus={
                  //  auto focus does not play nicely with e2e automated tests
                  //  the e2e tests will set this sessionStorage as a temporary solution
                  isInTestMode()
                    ? undefined
                    : createRecursiveTryFocusElementFunction(
                        `input#msys-subelements-input-${item.type}-${item.id}:enabled`
                      )
                }
              />
            </VirtualBareTreeItem>
          ) : null}
        </Box>
      )}
    </CardContainer>
  );
};
