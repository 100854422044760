import { Alert } from "@mui/material";
import { T, useTranslate } from "@tolgee/react";
import { ProjectStatusBadge } from "../../projects/badges/ProjectStatusBadge.js";

export const QuoteDeclinedAlert = ({ reason }: { reason: string }) => {
  const { t } = useTranslate(["Quote", "ProjectState"]);

  return (
    <Alert variant="outlined" severity="warning">
      {(() => {
        switch (reason) {
          case "ProjectStateContractorDeclined":
            return (
              <span>
                {t("Declined", { ns: "Quote" })}:{" "}
                <T
                  ns="ProjectState"
                  keyName="Opportunity marked as {state}"
                  params={{
                    state: () => (
                      <ProjectStatusBadge
                        projectState="contractor_declined"
                        small
                        component="span"
                      />
                    ),
                  }}
                />
              </span>
            );
          case "ProjectStateContracteeDeclined":
            return (
              <span>
                {t("Declined", { ns: "Quote" })}:{" "}
                <T
                  ns="ProjectState"
                  keyName="Opportunity marked as {state}"
                  params={{
                    state: () => (
                      <ProjectStatusBadge
                        projectState="contractee_declined"
                        small
                        component="span"
                      />
                    ),
                  }}
                />
              </span>
            );
          default:
            return `${t("Declined", { ns: "Quote" })}: ${reason}`;
        }
      })()}
    </Alert>
  );
};
