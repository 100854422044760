import { ApolloCache, FetchResult, gql, useApolloClient } from "@apollo/client";
import React from "react";
import {
  ChangeProjectPhaseMutation,
  useChangeProjectPhaseMutation,
} from "./useProjectChangePhase.generated.js";

export function useProjectChangePhase({
  projectId,
  projectPhaseId,
  refetchQueries,
  changeProjectPhaseUpdate,
}: {
  projectId: string;
  projectPhaseId: string;
  refetchQueries?: string[];
  changeProjectPhaseUpdate?(
    oldPhaseId: string,
    newPhaseId: string,
    cache: ApolloCache<any>,
    result: Omit<FetchResult<ChangeProjectPhaseMutation>, "context">
  ): void;
}) {
  const client = useApolloClient();
  const [changePhase, { loading }] = useChangeProjectPhaseMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const handlePhaseChange = async (newPhaseId: string) => {
    await changePhase({
      variables: { input: { projectId, newPhaseId } },
      update: (cache, result) =>
        changeProjectPhaseUpdate?.(projectPhaseId, newPhaseId, cache, result),
    });
  };

  return { handlePhaseChange, loading };
}
