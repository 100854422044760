import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal, ModalOpenButton } from "@msys/ui";
import { Publish as PublishIcon } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConfirmModal } from "../../../../commons/modals/ConfirmModal.js";
import { buildDocPath } from "../../../../utils.js";
import {
  usePublishQuoteTemplateMutation,
  useQuoteTemplatePublishButtonQuery,
} from "./QuoteTemplatePublishButton.generated.js";

interface Props extends ButtonProps {
  docId: string;
  pathToDoc: string;
}

export const QuoteTemplatePublishButton = ({
  docId,
  pathToDoc,
  ...rest
}: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["TemplatesSharing", "Global"]);

  const [error, setError] = React.useState<Error | null>(null);

  const client = useApolloClient();
  const query = useQuoteTemplatePublishButtonQuery({
    client,
    variables: { id: docId },
    fetchPolicy: "no-cache",
  });
  const doc = getDataOrNull(query.data?.quoteTemplateLatest)?.quoteTemplate;
  const anyLinkedTemplateUnpublished = doc?.items.some(
    item =>
      item.linkedQuoteTemplate &&
      item.linkedQuoteTemplate.resolvedAsReadModelVersionNumber === null
  );

  const [publish] = usePublishQuoteTemplateMutation({
    client,
  });

  return (
    <ModalOpenButton
      Modal={ConfirmModal}
      modalProps={{
        title: t("Are you sure you want to publish this template?", {
          ns: "TemplatesSharing",
        }),
        content: error
          ? handleClose => (
              <Modal
                title={t("Publishing failed due to errors in this template", {
                  ns: "TemplatesSharing",
                })}
                handleClose={handleClose}
                actionButtons={[
                  {
                    label: t("Cancel", { ns: "Global" }),
                    handleClick: handleClose,
                    buttonProps: { variant: "text" },
                  },
                  {
                    label: t("Go to Troubleshooting page", {
                      ns: "TemplatesSharing",
                    }),
                    handleClick: async () => {
                      navigate(`${buildDocPath(pathToDoc, null)}/troubleshoot`);
                    },
                  },
                ]}
              ></Modal>
            )
          : undefined,
        text: anyLinkedTemplateUnpublished
          ? t(
              "All linked draft templates will also be published and become available for general usage",
              {
                ns: "TemplatesSharing",
              }
            )
          : t("This template will become available for general usage", {
              ns: "TemplatesSharing",
            }),
        handleConfirm: async () => {
          if (!doc?.id) throw new Error("Doc id missing");

          try {
            const result = await publish({
              variables: {
                input: {
                  docId: doc.id,
                },
              },
            });

            if (
              result.data?.publishTemplateVersion.__typename ===
              "PublishTemplateVersionResultValidationError"
            ) {
              const error = new Error(
                "Publishing failed due to template validation errors"
              );
              console.error(result.data?.publishTemplateVersion);
              setError(error);

              throw error;
            }

            const newVersion =
              result.data?.publishTemplateVersion.quoteTemplate
                .latestPublishedVersionNumber;
            if (!newVersion) throw new Error("New version number is missing");

            enqueueSnackbar(
              newVersion > 1
                ? t("New version of template was published", {
                    ns: "TemplatesSharing",
                  })
                : t("Template published!", { ns: "TemplatesSharing" }),
              {
                action: (
                  <Button
                    component={Link}
                    to={`${pathToDoc}/${newVersion}`}
                    variant={"outlined"}
                    color="inherit"
                    size="small"
                  >
                    {t("Open", { ns: "Global" })}
                  </Button>
                ),
              }
            );
          } catch (error) {
            if (error instanceof Error)
              enqueueSnackbar(error.message, { variant: "error" });
            throw error;
          }
        },
      }}
    >
      <Button
        startIcon={rest.size !== "extra-small" ? <PublishIcon /> : undefined}
        variant="contained"
        color="primary"
        {...rest}
      >
        {t("Publish", {
          ns: "TemplatesSharing",
        })}
      </Button>
    </ModalOpenButton>
  );
};
