import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal, useScreenWidth } from "@msys/ui";
import { Box, Button, DialogActions, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, useFormikContext } from "formik";
import { differenceBy, intersectionBy, isEqual, uniq } from "lodash-es";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLatest, useUpdateEffect } from "react-use";
import * as Yup from "yup";
import { useUserData } from "../../auth/useUserData.js";
import { Stack } from "../../commons/layout/Stack.js";
import {
  namedOperations,
  OrganisationRole as GQLOrganisationRole,
} from "../../../clients/graphqlTypes.js";
import { durationInMinutesToTime } from "../../utils.js";
import { getAddressLabel } from "../addresses/helpers.js";
import { CalendarViewLayout } from "../schedule/CalendarViewLayout.js";
import {
  MapProjectFragment,
  SchedulePlanSessionFragment,
} from "../schedule/Fragments.generated.js";
import { useActionBegin } from "../schedule/helpers.js";
import { EditorView, EditorViewSwitch } from "./EditorViewSwitch.js";
import {
  useEditProjectPlanSessionsModal_OrganisationQuery,
  useEditProjectPlanSessionsModal_ProjectQuery,
  useEditProjectPlanSessionsModal_UpdateMutation,
  useEditProjectPlanSessionsModalQuery,
} from "./EditProjectPlanSessionsModal.generated.js";
import { MomentRange } from "./helpers.js";
import { PlanningMap, PlanningMapRef } from "./PlanningMap.js";
import { PlanningSchedule } from "./PlanningSchedule.js";
import { PlanningResourcesFragment } from "./PlanningSchedule.generated.js";
import {
  createNewSession,
  PlanningSessionsForm,
  PlanningSessionsFormRef,
  PlanSessionForm,
} from "./PlanningSessionsForm.js";

interface FormValues {
  planSessions: PlanSessionForm[];
}

const isEqualSession = (ps1: PlanSessionForm, ps2: PlanSessionForm): boolean =>
  ps1.user?.value === ps2.user?.value &&
  ps1.from.isSame(ps2.from, "minutes") &&
  ps1.till.isSame(ps2.till, "minutes") &&
  ps1.isTentative === ps2.isTentative;

const isEqualMomentRange = (r1: MomentRange, r2: MomentRange) => {
  return (
    ((r1[0] === undefined && r2[0] === undefined) ||
      (r1[0] && r2[0] && r1[0].isSame(r2[0], "minutes"))) &&
    ((r1[1] === undefined && r2[1] === undefined) ||
      (r1[1] && r2[1] && r1[1].isSame(r2[1], "minutes")))
  );
};

const isEqualForm = (
  v1: FormValues | undefined,
  v2: FormValues | undefined
): boolean =>
  !!(
    (!v1 && !v2) ||
    (v1 &&
      v2 &&
      v1.planSessions.length === v2.planSessions.length &&
      v1.planSessions.every(ps1 => {
        const ps2 = v2.planSessions.find(ps => ps.id === ps1.id);
        return ps2 && isEqualSession(ps1, ps2);
      }))
  );

export const EditProjectPlanSessionsModal = ({
  title,
  projectId,
  handleClose,
}: {
  projectId: string;
  title?: string;
  handleClose: () => void;
}) => {
  const viewer = useUserData().currentUser!;
  const { isMinDesktop } = useScreenWidth();
  const { t } = useTranslate(["PlanningModal", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState<EditorView>(EditorView.List);
  const mapRef = useRef<PlanningMapRef | null>(null);
  const formRef = useRef<PlanningSessionsFormRef | null>(null);

  const [totalDuration, setTotalDuration] = React.useState<number>(0);

  const forceSave = useCallback(() => {
    formRef.current?.save();
  }, []);

  const client = useApolloClient();
  const query = useEditProjectPlanSessionsModalQuery({
    client,
    variables: { id: projectId },
    fetchPolicy: "network-only",
  });

  const projectQuery = useEditProjectPlanSessionsModal_ProjectQuery({
    client,
    variables: { id: projectId },
    fetchPolicy: "no-cache",
  });
  const organisationQuery = useEditProjectPlanSessionsModal_OrganisationQuery({
    client,
    fetchPolicy: "no-cache",
  });

  const isLoading =
    (query.loading && !query.data) ||
    (projectQuery.loading && !projectQuery.data) ||
    (organisationQuery.loading && !organisationQuery.data);

  const [updateSessions] = useEditProjectPlanSessionsModal_UpdateMutation({
    client,
  });

  const project = getDataOrNull(projectQuery.data?.project)?.project;
  const organisation = viewer?.organisation;
  const organisationMemberships =
    organisationQuery.data?.organisationMemberships;
  const organisationDefaults = getDataOrNull(
    organisationQuery.data?.organisationDefaults
  );

  const craftsmen = useMemo(
    () =>
      (organisationMemberships ?? [])
        .slice()
        .sort((a, b) => a.familyname.localeCompare(b.familyname)),
    [organisationMemberships]
  );

  const roles = useMemo(() => organisation?.roles ?? [], [organisation?.roles]);

  const projectPlanSessions = useMemo(
    () => getDataOrNull(query.data?.project)?.project?.planSessions ?? [],
    [getDataOrNull(query.data?.project)?.project?.planSessions]
  );

  const mapProjects = useMemo(() => (project ? [project] : []), [project]);

  const now = useMemo(() => moment().add(1, "hours").startOf("hour"), []);

  const initialValuesSetRef = useRef<boolean>(false);

  const initialCenter = useMemo(
    () =>
      project?.building?.buildingAddress &&
      project?.building?.buildingAddress.location
        ? {
            lat: project.building.buildingAddress.location.lat,
            lng: project.building.buildingAddress.location.lng,
          }
        : undefined,
    [project?.building?.buildingAddress]
  );

  const initialValues = useMemo(() => {
    if (isLoading) return { planSessions: [] };
    return {
      planSessions:
        projectPlanSessions.length > 0
          ? projectPlanSessions.map(s => ({
              id: s.id,
              from: moment(s.from),
              till: moment(s.till),
              user: { value: s.who.id, label: s.who.fullname },
              who: s.who,
              isTentative: s.isTentative,
            }))
          : !initialValuesSetRef.current
            ? [
                createNewSession(
                  now,
                  project?.ticket ?? false,
                  organisationDefaults?.defaultProjectDuration || 0,
                  organisationDefaults?.defaultTicketDuration || 0,
                  null,
                  organisationDefaults?.defaultStartWorkDay ?? undefined,
                  organisationDefaults?.defaultEndWorkDay ?? undefined
                ),
              ]
            : [],
    };
  }, [
    isLoading,
    now,
    projectPlanSessions,
    project?.ticket,
    organisationDefaults?.defaultStartWorkDay,
    organisationDefaults?.defaultEndWorkDay,
    organisationDefaults?.defaultProjectDuration,
    organisationDefaults?.defaultTicketDuration,
  ]);

  useEffect(() => {
    if (isLoading) return;
    initialValuesSetRef.current = true;
  }, [isLoading]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        planSessions: Yup.array().of(
          Yup.object()
            .shape({
              user: Yup.object()
                .shape({
                  value: Yup.string().required(),
                  label: Yup.string().required(),
                })
                .nullable(),
              from: Yup.date()
                .label(
                  t("From", {
                    ns: "PlanningModal",
                  })
                )
                .required(),
              till: Yup.date()
                .label(
                  t("Until", {
                    ns: "PlanningModal",
                  })
                )
                .test(
                  "laterThan",
                  t("End date cannot be earlier than start date", {
                    ns: "Global",
                  }),
                  function (till) {
                    const from: Date = this.resolve(Yup.ref("from"));
                    return !till || from < till;
                  }
                )
                .required(),
              isTentative: Yup.boolean().required(),
            })
            .nullable()
        ),
      }),
    [t]
  );

  const filterValues = useCallback((values: FormValues): FormValues => {
    const planSessions = values.planSessions.filter(s => s.user?.value);
    return { planSessions };
  }, []);

  const handleSubmit = async (values: FormValues) => {
    const newValues = filterValues(values);
    const existingValues = filterValues(initialValues);

    try {
      await validationSchema.validate(newValues);

      const addedSessions = differenceBy(
        newValues.planSessions,
        existingValues.planSessions,
        s => s.id
      );

      const deletedSessions = differenceBy(
        existingValues.planSessions,
        newValues.planSessions,
        s => s.id
      );

      const existingSessions = intersectionBy(
        newValues.planSessions,
        existingValues.planSessions,
        s => s.id
      );

      const changedSessions = existingSessions.filter(existingSession => {
        const initialSession = existingValues.planSessions.find(
          s => s.id === existingSession.id
        )!;
        return !isEqualSession(initialSession, existingSession);
      });

      if (
        addedSessions.length > 0 ||
        deletedSessions.length > 0 ||
        changedSessions.length > 0
      ) {
        try {
          await updateSessions({
            variables: {
              input: {
                projectId,
                create: addedSessions.map(session => {
                  const userId = session.user!.value;
                  const roles = craftsmen.find(
                    craftsman => craftsman.id === userId
                  )!.defaultProjectRoles;
                  return {
                    id: session.id,
                    from: session.from.toISOString(true),
                    till: session.till.toISOString(true),
                    isTentative: session.isTentative,
                    projectId,
                    whoId: userId,
                    workload: 1,
                    roleIds:
                      project!.internalStakeholders
                        .find(e => e.user.id === userId)
                        ?.roles.map(e => e.id) ??
                      (roles && roles.length > 0
                        ? roles.map(role => role.id)
                        : [
                            project!.roles.find(
                              e => e.internalName === "PROJECT_MEMBER"
                            )!.id,
                          ]),
                  };
                }),
                modify: changedSessions.map(session => ({
                  id: session.id,
                  from: session.from.toISOString(true),
                  till: session.till.toISOString(true),
                  whoId: session.user!.value,
                  isTentative: session.isTentative,
                })),
                delete: deletedSessions.map(s => s.id),
              },
            },
            refetchQueries: [
              namedOperations.Query.EditProjectPlanSessionsModal,
              namedOperations.Query.PlanningSessionsSchedule,
            ],
            awaitRefetchQueries: true,
          });
        } catch (e) {
          enqueueSnackbar(
            t("Failed to change plan session", {
              ns: "PlanningModal",
            }) + (e instanceof Error ? ": " + e.message : ""),
            {
              variant: "error",
            }
          );
        }
      }
    } catch (e) {
      // just skip validation error - until date if before from
    }
  };

  const [resourceMaxDistance, setResourceMaxDistance] = useState<number>(0);

  const modalTitle =
    title ??
    t("Schedule “{name}”", {
      ns: "PlanningModal",
      name: project?.title ?? "",
    }) +
      (project?.building?.buildingAddress
        ? ` – ${getAddressLabel(project?.building?.buildingAddress)}`
        : "");

  const List = (
    <PlanningSessionsForm
      ref={formRef}
      craftsmen={craftsmen}
      projectIsTicket={project?.ticket ?? false}
      projectDuration={organisationDefaults?.defaultProjectDuration || 0}
      ticketDuration={organisationDefaults?.defaultTicketDuration || 0}
      startWorkDay={organisationDefaults?.defaultStartWorkDay ?? undefined}
      endWorkDay={organisationDefaults?.defaultEndWorkDay ?? undefined}
      initialValues={initialValues}
      filterValues={filterValues}
      isEqualForm={isEqualForm}
      onTotalDurationChange={setTotalDuration}
      showTotalDuration={isMinDesktop}
    />
  );

  const Map = (
    <PlanningMap
      ref={mapRef}
      projects={mapProjects}
      resources={craftsmen}
      maxWidth={9999}
      fitBounds={!project?.building?.buildingAddress?.location}
      initialZoom={12}
      initialCenter={initialCenter}
      initialSelectedBuildingId={project?.building?.id}
      projectAddress={project?.building?.buildingAddress ?? undefined}
      resourceMaxDistance={resourceMaxDistance}
    />
  );

  const Calendar = useMemo(
    () => (
      <EditPlanningSchedule
        projectId={projectId}
        addressId={project?.building?.buildingAddress?.id ?? undefined}
        craftsmen={craftsmen}
        roles={roles}
        project={project}
        startWorkDay={organisationDefaults?.defaultStartWorkDay ?? undefined}
        endWorkDay={organisationDefaults?.defaultEndWorkDay ?? undefined}
        onResourceMaxDistanceChange={setResourceMaxDistance}
        save={forceSave}
      />
    ),
    [
      projectId,
      craftsmen,
      roles,
      project,
      organisationDefaults?.defaultStartWorkDay,
      organisationDefaults?.defaultEndWorkDay,
      setResourceMaxDistance,
      forceSave,
    ]
  );

  return (
    <Modal
      title={modalTitle}
      dialogProps={{
        fullScreen: true,
      }}
      dialogActions={
        <DialogActions
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Box>
            {!isMinDesktop && (
              <Typography>
                {t("Duration", { ns: "PlanningModal" })}:{" "}
                {durationInMinutesToTime(totalDuration)} h
              </Typography>
            )}
          </Box>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {t("Done", { ns: "Global" })}
          </Button>
        </DialogActions>
      }
      handleClose={handleClose}
      isLoading={isLoading}
      alwaysVisible
      headerActions={
        !isMinDesktop ? (
          <EditorViewSwitch value={view} setValue={setView} />
        ) : undefined
      }
    >
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {isMinDesktop ? (
          <CalendarViewLayout
            name={`planSessions-${projectId}`}
            List={
              <Stack
                height="100%"
                flexGrow={1}
                flexShrink={1}
                flexDirection="row"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Form
                  style={{
                    height: "100%",
                    width: 860,
                    flex: 1,
                    overflow: "auto",
                  }}
                >
                  {List}
                </Form>
                <Box height="100%" flexGrow={1} flexShrink={1}>
                  {Map}
                </Box>
              </Stack>
            }
            Calendar={Calendar}
          />
        ) : (
          <Stack
            height="100%"
            flex={1}
            flexDirection="column"
            justifyContent="stretch"
            alignItems="stretch"
          >
            {view === EditorView.List && List}
            {view === EditorView.Map && Map}
            {view === EditorView.Calendar && Calendar}
          </Stack>
        )}
      </Formik>
    </Modal>
  );
};

const getPlanSessions = (
  project: MapProjectFragment | undefined | null,
  values: FormValues
) => {
  return project
    ? values.planSessions
        .filter(ps => ps.who)
        .map(ps => ({
          id: ps.id,
          who: ps.who!,
          project,
          from: ps.from.toISOString(true),
          till: ps.till.toISOString(true),
          isTentative: ps.isTentative,
        }))
    : undefined;
};

const getActiveDates = (
  planSessions:
    | { from: string | moment.Moment; till: string | moment.Moment }[]
    | undefined
): MomentRange => {
  return [
    moment(planSessions?.[0]?.from ?? undefined),
    moment(planSessions?.[(planSessions?.length ?? 0) - 1]?.till ?? undefined),
  ];
};

const EditPlanningSchedule = ({
  addressId,
  roles,
  craftsmen,
  startWorkDay,
  endWorkDay,
  projectId,
  project,
  onResourceMaxDistanceChange,
  save,
}: {
  addressId?: string;
  roles: GQLOrganisationRole[] | undefined;
  craftsmen: PlanningResourcesFragment[] | undefined;
  startWorkDay: string | undefined;
  endWorkDay: string | undefined;
  projectId: string;
  project: MapProjectFragment | undefined | null;
  onResourceMaxDistanceChange: (resourceMaxDistance: number) => void;
  save: () => void;
}) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [additionalPlanSessions, setAdditionalPlanSessions] = useState(
    getPlanSessions(project, values)
  );

  // const pinnedResourceIds: string[] = useMemo(
  //   () => uniq(additionalPlanSessions?.map(ps => ps.who!.id) ?? []),
  //   [additionalPlanSessions]
  // );

  // We need to update pinned ids only on actual change to improve memoization
  const [pinnedResourceIds, setPinnedResourceIds] = useState<string[]>(
    uniq(additionalPlanSessions?.map(ps => ps.who!.id).sort() ?? [])
  );
  useUpdateEffect(() => {
    const newIds: string[] = uniq(
      additionalPlanSessions?.map(ps => ps.who!.id).sort() ?? []
    );
    if (!isEqual(pinnedResourceIds, newIds)) setPinnedResourceIds(newIds);
  }, [additionalPlanSessions]);

  const [activeDates, setActiveDates] = useState<MomentRange>(
    getActiveDates(additionalPlanSessions)
  );

  const savedActiveDates = useRef<MomentRange>(
    getActiveDates(additionalPlanSessions)
  );

  const savedProject = useRef(project);
  const savedValues = useRef(values);

  const valuesLatest = useLatest(values);
  const projectLatest = useLatest(project);

  useUpdateEffect(() => {
    if (
      savedProject.current?.id !== projectLatest.current?.id ||
      !isEqualForm(savedValues.current, valuesLatest.current)
    ) {
      savedProject.current = projectLatest.current;
      savedValues.current = valuesLatest.current;

      const newAdditionalPlanSessions = getPlanSessions(
        projectLatest.current,
        valuesLatest.current
      );

      setAdditionalPlanSessions(newAdditionalPlanSessions);

      const newActiveDates = getActiveDates(newAdditionalPlanSessions);

      if (!isEqualMomentRange(newActiveDates, savedActiveDates.current)) {
        savedActiveDates.current = newActiveDates;
        if (newActiveDates[0] && newActiveDates[1])
          setActiveDates(newActiveDates);
      }
    }
  }, [project, values.planSessions]);

  const handleChangeAction = async (data: any) => {
    const { id, WhoId, startTime, endTime } = data;

    const psIndex = values.planSessions.findIndex(ps => ps.id === id);
    if (psIndex < 0) return;

    const ps = values.planSessions[psIndex]!;
    if (ps.who?.id !== WhoId) {
      const craftsman = (craftsmen ?? []).find(c => c.id === WhoId);
      if (craftsman) {
        setFieldValue(`planSessions[${psIndex}].who`, craftsman);
        setFieldValue(`planSessions[${psIndex}].user`, {
          value: craftsman.id,
          label: craftsman.fullname,
        });
      }
    }
    const newFrom = moment(startTime);
    const newTill = moment(endTime);
    if (!ps.from.isSame(newFrom, "minutes"))
      setFieldValue(`planSessions[${psIndex}].from`, newFrom);
    if (!ps.till.isSame(newTill, "minutes"))
      setFieldValue(`planSessions[${psIndex}].till`, newTill);

    // force trigger save
    save();
  };

  const handleRemoveAction = async (data: any) => {
    const [{ id }] = data;
    const newPlanSessions = values.planSessions.filter(ps => ps.id !== id);
    setFieldValue("planSessions", newPlanSessions);
    // force trigger save
    save();
  };

  const actionBegin = useActionBegin(handleChangeAction, handleRemoveAction);

  // We show planned sessions for selected project as draggable additional sessions, so we need to filter them out from all planned sessions
  const excludeAppointmentFn = useCallback(
    (ps: SchedulePlanSessionFragment) => ps.project.id === project?.id,
    [project?.id]
  );

  return (
    <PlanningSchedule
      key="planning-schedule"
      projectId={projectId}
      addressId={addressId}
      projectAddress={project?.buildingInfo?.buildingAddress ?? undefined}
      pinnedResourceIds={pinnedResourceIds}
      resources={craftsmen}
      roles={roles}
      additionalPlanSessions={additionalPlanSessions}
      excludeExistingPlanSessionFn={excludeAppointmentFn}
      lockExistingPlanSessions
      actionBegin={actionBegin}
      view="TimelineMonth"
      startWorkDay={startWorkDay}
      endWorkDay={endWorkDay}
      activeDates={activeDates}
      onResourceMaxDistanceChange={onResourceMaxDistanceChange}
    />
  );
};
