import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Dashboard as DashboardIcon } from "@mui/icons-material";
import { ViewAgenda as ViewAgendaIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useUserData } from "../auth/useUserData.js";
import { MainMenu, type IMainMenuItem } from "../commons/MainMenu.js";
import { PageNotFound } from "../commons/PageNotFound.js";
import { useBubbleContext } from "../commons/hooks/useBubbleContext.js";
import { PageTopbarItem } from "../commons/layout/Page.js";
import { ChatIcon } from "../features/channels/ChatIcon.js";
import { SelectInfoMessageItem } from "../features/doc-items/items/SelectInfoMessageItem.js";
import { TaskIcon } from "../features/tasks/TaskIcon.js";
import { TimesheetIcon } from "../features/time-reports/TimesheetIcon.js";
import {
  ProjectChatChannel,
  ProjectChats,
} from "../main-routes/projects/ProjectChats.js";
import { ProjectTasks } from "../main-routes/projects/ProjectTasks.js";
import { ProjectTasksItem } from "../main-routes/projects/ProjectTasksItem.js";
import { ProjectTimeReportPreview } from "../main-routes/projects/ProjectTimeReportPreview.js";
import { ProjectTimeReports } from "../main-routes/projects/ProjectTimeReports.js";
import { matchPathPredicate } from "../utils.js";
import { Agenda } from "./Agenda.js";
import { MyUserProfile } from "./MyUserProfile.js";
import { ProjectOverview } from "./ProjectOverview.js";
import { useInstallerProjectRouterQuery } from "./Routes.generated.js";
import { OrganisationAvatar } from "../features/organisations/OrganisationAvatar.js";
import { UserAvatar } from "../features/users/UserAvatar.js";

type PageSubmenuItem = PageTopbarItem;
type MainMenuItem =
  | { name: "agenda" }
  | { name: "myprofile-organisation" }
  | { name: "myprofile-user" };
type AppSidebarItem = MainMenuItem & Omit<IMainMenuItem, "name">;

const AGENDA_PATH = "/agenda";

export const InstallerRoutes = () => {
  const viewer = useUserData().currentUser!;
  const [searchParams] = useSearchParams();
  const { t } = useTranslate("SidebarItems");

  const menuItems: AppSidebarItem[] = React.useMemo(
    () => [
      {
        name: "agenda",
        label: t("Agenda"),
        icon: <ViewAgendaIcon />,
        path: "/agenda",
      },
    ],
    [t]
  );
  const secondaryItems: AppSidebarItem[] = React.useMemo(
    () => [
      {
        name: "myprofile-organisation",
        label: viewer.organisation.title,
        icon: (
          <OrganisationAvatar
            organisationAvatar={viewer.organisation}
            size="s"
            style={{ left: -4, pointerEvents: "none" }}
          />
        ),
      },
      {
        name: "myprofile-user",
        path: "/user",
        label: `${viewer.firstname} ${viewer.familyname}`,
        icon: (
          <UserAvatar
            userAvatar={viewer}
            size="s"
            style={{ left: -4, pointerEvents: "none" }}
          />
        ),
      },
    ],
    [viewer]
  );

  return (
    <>
      <MainMenu primaryItems={menuItems} secondaryItems={secondaryItems} />
      <Routes>
        <Route
          path={"/"}
          element={
            <Navigate
              to={{
                pathname: AGENDA_PATH,
                search: searchParams.toString(),
              }}
              replace
            />
          }
        />
        <Route
          path={"/next"}
          element={
            <Navigate
              to={{
                pathname: AGENDA_PATH,
                search: searchParams.toString(),
              }}
              replace
            />
          }
        />
        <Route path={AGENDA_PATH} element={<Agenda />} />
        <Route path={`/projects/:projectId/*`} element={<ProjectRoutes />} />
        <Route
          path={"/organisation/users/:userId"}
          element={
            <Navigate to="/organisation/users/:userId/profile" replace />
          }
        />
        <Route
          path={`/organisation/users/${viewer.id}/profile`}
          element={<MyUserProfile />}
        />
        <Route path={`/user`} element={<MyUserProfile />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

function ProjectRoutes() {
  const { projectId } = useParams<{ projectId: string }>();
  if (!projectId) throw new Error(" Project id is missing");
  const location = useLocation();

  const { t } = useTranslate("ProjectPageTopbar");
  const { bubble } = useBubbleContext();
  const bubbleAmount = bubble.perProject[projectId] || 0;
  const { isMaxPhone, isMinTablet } = useScreenWidth();

  const client = useApolloClient();
  const { data } = useInstallerProjectRouterQuery({
    client,
    variables: {
      projectId,
    },
  });

  const project = getDataOrNull(data?.project)?.project;

  const submenuItems: PageSubmenuItem[] = React.useMemo(() => {
    if (!project?.viewerPermissions) return [];

    return [
      {
        name: "project-overview",
        label: t("Overview"),
        type: "link",
        path: `/projects/${projectId}/overview`,
        icon: <DashboardIcon />,
        projectPermission: { name: "READ_PROJECT", project },
      },
      {
        name: "project-chats",
        label: t("Chats"),
        type: "link",
        path: `/projects/${projectId}/communication/chats`,
        bubbleAmount,
        icon: <ChatIcon />,
        isInMoreMenu: true,
        projectPermission: { name: "READ_PROJECT", project },
      },
      {
        name: "project-tasks",
        label: t("Tasks"),
        type: "link",
        path: `/projects/${projectId}/tasks/edit#list`,
        icon: <TaskIcon />,
        projectPermission: { name: "READ_PROJECT", project },
      },
      {
        name: "project-timesheet",
        label: t("Timesheet"),
        type: "link",
        path: `/projects/${projectId}/tasks/edit#timesheet`,
        icon: <TimesheetIcon />,
        projectPermission: { name: "READ_PROJECT", project },
      },
    ];
  }, [project, t, projectId, bubbleAmount]);

  const activeItem = submenuItems.find(item =>
    item.activePaths
      ? item.activePaths.some(path => matchPathPredicate(`${path}/*`, location))
      : matchPathPredicate(`${item.path}/*`, location)
  );

  const breadcrumbs = React.useMemo(
    () => [
      {
        title: project?.title ?? "",
        to: `/projects/${projectId}`,
      },
    ],
    [project?.title, projectId]
  );
  return (
    <Routes>
      <Route index element={<Navigate to="overview" replace />} />
      <Route
        path="overview"
        element={
          <ProjectOverview
            projectId={projectId}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      <Route
        path={`communication/chats`}
        element={
          <ProjectChats
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
            tabs={null}
          />
        }
      >
        <Route
          path={`:channelId`}
          element={<ProjectChatChannel projectId={projectId} />}
        />
      </Route>
      <Route path={`tasks`}>
        <Route
          index
          element={<Navigate to={isMaxPhone ? `edit#list` : `edit`} replace />}
        />
        <Route
          path={`edit`}
          element={
            <ProjectTasks
              projectId={projectId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeItem}
            />
          }
        >
          {isMinTablet && <Route index element={<SelectInfoMessageItem />} />}
          <Route path={`:docId`}>
            <Route
              index
              element={
                <ProjectTasksItem
                  projectId={projectId}
                  pathToDocPage={`/projects/${projectId}/tasks/edit`}
                  isInitiallyClosed={true}
                />
              }
            />
            <Route
              path={`items/:itemId`}
              element={
                <ProjectTasksItem
                  projectId={projectId}
                  pathToDocPage={`/projects/${projectId}/tasks/edit`}
                  isInitiallyClosed={true}
                />
              }
            />
          </Route>
        </Route>
        <Route path={`reports`}>
          <Route
            index
            element={
              <ProjectTimeReports
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            }
          />
          <Route
            path={`:reportId`}
            element={
              <ProjectTimeReportPreview
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}
