/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated.js';
export type ProjectOverviewBuildingBox_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, owningSystemOrganisationId: string, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string } | null, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } | null, buildingItem?: { __typename: 'Item', id: string, docId: string, title: string } | null, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string } }> };

export const ProjectOverviewBuildingBox_ProjectFragmentDoc = gql`
    fragment ProjectOverviewBuildingBox_Project on Project {
  id
  viewerPermissions
  owningSystemOrganisationId
  crmOrganisation {
    id
    title
  }
  building {
    id
    buildingAddress {
      id
      ...AddressDetails__Address
    }
  }
  buildingItem {
    id
    docId
    title
  }
  internalStakeholders {
    id
    user {
      id
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;