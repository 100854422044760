import { RadioGroupOption, RadioGroupOptions } from "@msys/ui";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  RadioGroup as MuiRadioGroup,
} from "@mui/material";
import { useField } from "formik";
import React from "react";

interface Props<Value extends string = string> {
  name: string;
  label?: React.ReactNode;
  labelColor?: FormLabelProps["color"];
  disabled?: boolean;
  condensed?: boolean;
  inline?: boolean;
  helperText?: string;
  options: RadioGroupOption<Value>[];
}

export const RadioGroupField = <Value extends string = string>({
  name,
  label,
  labelColor,
  disabled,
  inline = false,
  condensed = true,
  helperText,
  options,
}: Props<Value>) => {
  const [inputProps, metaProps, helperProps] = useField<Value>(name);
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      disabled={disabled}
      error={Boolean(metaProps.error)}
    >
      {label && (
        <FormLabel component="legend" color={labelColor} sx={{ mb: 1 }}>
          {label}
        </FormLabel>
      )}
      <FormGroup>
        <MuiRadioGroup
          name={name}
          value={inputProps.value}
          onChange={(event, value) => helperProps.setValue(value as Value)}
          row={inline}
        >
          <RadioGroupOptions
            disabled={disabled}
            inline={inline}
            condensed={condensed}
            options={options}
          />
        </MuiRadioGroup>
      </FormGroup>
      {(metaProps.error ?? helperText) ? (
        <FormHelperText>{metaProps.error ?? helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
