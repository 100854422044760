import React from "react";
import { Link } from "react-router-dom";

import { gql, useApolloClient } from "@apollo/client";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { CardContainer, ModalOpenButton, Select } from "@msys/ui";
import { useUserData } from "../../../auth/useUserData.js";
import { Stack } from "../../../commons/layout/Stack.js";
import { color } from "../../../../common/MuiThemeProvider.js";
import {
  AssignBuildingButton_ProjectFragment,
  BuildingPlacementBox_ItemFragment,
  BuildingPlacementBox_ProjectFragment,
  useBuildingPlacementBoxSetMutation,
} from "./BuildingPlacementBox.generated.js";
import { useTranslate } from "@tolgee/react";
import { getAllItemChildren } from "../../../trees/helpers.js";
import { ProjectAssignBuildingModal } from "../../projects/modals/ProjectAssignBuildingModal.js";

interface Props {
  project: BuildingPlacementBox_ProjectFragment;
  docId: string;
  item: BuildingPlacementBox_ItemFragment;
  isEditable?: boolean;
}

export const BuildingPlacementBox = ({
  docId,
  item,
  project,
  isEditable = true,
}: Props) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);

  const [isEditing, setIsEditing] = React.useState(false);

  const client = useApolloClient();

  const [_setPlacement] = useBuildingPlacementBoxSetMutation({ client });

  const setPlacement = async (placementId: string | null) => {
    await _setPlacement({
      refetchQueries: ["BuildingPlacementBox"],
      variables: {
        input: {
          projectId: project.id,
          docId,
          itemId: item.id,
          placementId,
        },
      },
    });
  };

  const projectHasBuilding = project?.building ?? false;

  const placements =
    project.buildingItem && project.building
      ? [project.buildingItem].concat(
          getAllItemChildren(project.buildingItem, project.building.items)
        )
      : [];
  const placement = item.placement;

  return (
    <CardContainer
      isExpandable
      title={t("Building placement", {
        ns: "QuoteItem",
      })}
    >
      <Stack flexDirection="column" padding={1}>
        {!projectHasBuilding ? (
          <Box>
            <AssignBuildingButton project={project} />
          </Box>
        ) : (
          <>
            {isEditing ? (
              <Box>
                <Select
                  label={t("Placement", {
                    ns: "QuoteItem",
                  })}
                  value={placement?.id}
                  options={placements.map(e => ({
                    label: `${e.pathForPdf} ${e.title}`,
                    value: e.id,
                  }))}
                  onChange={value => {
                    const nextPlacementId = value;
                    setPlacement(nextPlacementId);
                    setIsEditing(false);
                  }}
                />
              </Box>
            ) : (
              <Stack alignItems="center" justifyContent="space-between">
                {placement ? (
                  <MuiLink
                    component={Link}
                    to={`/buildings/${placement.docId}/edit/items/${placement.id}`}
                  >
                    {placement.pathForPdf} {placement.title}
                  </MuiLink>
                ) : (
                  <Typography style={{ color: color.grey }}>
                    {t("Not set", {
                      ns: "Global",
                    })}
                  </Typography>
                )}

                {placement ? (
                  <Stack spacing={0}>
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => setIsEditing(true)}
                      style={{ width: 36, height: 36 }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setPlacement(null)}
                      size="small"
                      color="secondary"
                      style={{ width: 36, height: 36 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ) : (
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => setIsEditing(true)}
                    style={{ width: 36, height: 36 }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </Stack>
            )}
          </>
        )}
      </Stack>
    </CardContainer>
  );
};

const AssignBuildingButton = ({
  project,
}: {
  project: AssignBuildingButton_ProjectFragment;
}) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("QuoteItem");

  return (
    <ModalOpenButton
      Modal={ProjectAssignBuildingModal}
      modalProps={{
        projectId: project.id,
        buildingOwningOrganisationId:
          // TODO-MODEL2022
          project.crmOrganisation?.id ?? project.owningSystemOrganisationId,
      }}
    >
      <Button
        variant="text"
        color="secondary"
        size="small"
        startIcon={<AddIcon />}
      >
        {t("Assign building to project")}
      </Button>
    </ModalOpenButton>
  );
};
