import { Modal, ModalOpenButton } from "@msys/ui";
import { Add as AddIcon } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { CrmPersonCreateModal } from "./CrmPersonCreateModal.js";
import { CrmPersonSelect } from "./CrmPersonSelect.js";

interface Props {
  title: string;
  crmCompanyId: string;
  handleClose: () => void;
  handleComplete: (crmPersonId: string, handleClose: () => void) => void;
}

export const CrmPersonSelectModal = ({
  title,
  crmCompanyId,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["Global"]);

  const [selectedPersonId, setSelectedPersonId] = React.useState<string | null>(
    null
  );

  return (
    <Modal
      title={title}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Confirm", { ns: "Global" }),
          handleClick: async () => {
            if (!selectedPersonId) throw new Error("No member selected");
            await handleComplete(selectedPersonId, handleClose);
          },
          buttonProps: { disabled: !selectedPersonId },
        },
      ]}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Box flex={1}>
          <CrmPersonSelect
            inputLabel={t("Contact", { ns: "Global" })}
            crmCompanyId={crmCompanyId}
            crmPersonId={selectedPersonId}
            onChange={value => {
              setSelectedPersonId(value);
            }}
          />
        </Box>
        <ModalOpenButton
          Modal={CrmPersonCreateModal}
          modalProps={{
            crmCompanyId,
            handleComplete: async (crmPerson, handleClose) => {
              setSelectedPersonId(crmPerson.id);
              handleClose();
            },
          }}
        >
          <IconButton color="primary">
            <AddIcon />
          </IconButton>
        </ModalOpenButton>
      </Stack>
    </Modal>
  );
};
