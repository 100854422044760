import { notNull } from "@msys/common";
import { StatusChip, StatusLabel } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Project } from "../../../clients/graphqlTypes.js";

interface Props {
  ticket: Pick<Project, "ticket" | "overdue" | "planned" | "urgent">;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: boolean;
}

export const TicketStatusBadge = ({ ticket, align, small }: Props) => {
  // if (!ticket.ticket) return null;
  const labels = [
    ticket.urgent ? (
      <TicketOverdueBadge align={align} small={small} key="overdue" />
    ) : null,
    ticket.overdue ? (
      <TicketOverdueBadge align={align} small={small} key="overdue" />
    ) : null,
    // ticket.planned ? (
    //   <TicketAssignedBadge align={align} small={small} key="planned" />
    // ) : null,
  ].filter(notNull);
  return labels.length ? <>{labels}</> : null;
};

export const TicketOverdueBadge = (props: {
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: boolean;
}) => {
  const { t } = useTranslate("ProjectOverview");

  return (
    <StatusLabel color="error.main" {...props}>
      {t("Overdue")}
    </StatusLabel>
  );
};

export const TicketUrgentBadge = (props: {
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: boolean;
}) => {
  const { t } = useTranslate("ProjectOverview");

  return (
    <StatusLabel color="warning.main" {...props}>
      {t("Urgent")}
    </StatusLabel>
  );
};

export const TicketAssignedBadge = (props: {
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: boolean;
}) => {
  const { t } = useTranslate("Projects");

  return (
    <StatusLabel color="secondary.main" {...props}>
      {t("Planned")}
    </StatusLabel>
  );
};

export const TicketStatusChip = ({
  ticket,
  size,
}: {
  ticket: Pick<Project, "ticket" | "overdue" | "planned" | "urgent">;
  size?: React.ComponentProps<typeof StatusChip>["size"];
}) => {
  const { t } = useTranslate(["ProjectOverview", "Projects"]);
  // if (!ticket.ticket) return null;
  const chips = [
    // ticket.planned ? (
    //   <StatusChip
    //     label={t("Planned", { ns: "Projects" })}
    //     color={"secondary"}
    //     size={size}
    //     key="planned"
    //   />
    // ) : null,
    ticket.urgent ? (
      <StatusChip
        label={t("Urgent", { ns: "ProjectOverview" })}
        color={"warning"}
        size={size}
        key="overdue"
      />
    ) : null,
    ticket.overdue ? (
      <StatusChip
        label={t("Overdue", { ns: "ProjectOverview" })}
        color={"error"}
        size={size}
        key="overdue"
      />
    ) : null,
  ].filter(notNull);

  return chips.length ? <>{chips}</> : null;
};
