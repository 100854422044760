/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectIsOpportunityQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectIsOpportunityQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, state: Types.ProjectStateMachineStatus } | null } };


export const ProjectIsOpportunityDocument = gql`
    query ProjectIsOpportunity($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        state
      }
    }
  }
}
    `;

/**
 * __useProjectIsOpportunityQuery__
 *
 * To run a query within a React component, call `useProjectIsOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectIsOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectIsOpportunityQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectIsOpportunityQuery(baseOptions: Apollo.QueryHookOptions<ProjectIsOpportunityQuery, ProjectIsOpportunityQueryVariables> & ({ variables: ProjectIsOpportunityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectIsOpportunityQuery, ProjectIsOpportunityQueryVariables>(ProjectIsOpportunityDocument, options);
      }
export function useProjectIsOpportunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectIsOpportunityQuery, ProjectIsOpportunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectIsOpportunityQuery, ProjectIsOpportunityQueryVariables>(ProjectIsOpportunityDocument, options);
        }
export function useProjectIsOpportunitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProjectIsOpportunityQuery, ProjectIsOpportunityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectIsOpportunityQuery, ProjectIsOpportunityQueryVariables>(ProjectIsOpportunityDocument, options);
        }
export type ProjectIsOpportunityQueryHookResult = ReturnType<typeof useProjectIsOpportunityQuery>;
export type ProjectIsOpportunityLazyQueryHookResult = ReturnType<typeof useProjectIsOpportunityLazyQuery>;
export type ProjectIsOpportunitySuspenseQueryHookResult = ReturnType<typeof useProjectIsOpportunitySuspenseQuery>;
export type ProjectIsOpportunityQueryResult = Apollo.QueryResult<ProjectIsOpportunityQuery, ProjectIsOpportunityQueryVariables>;