import React from "react";
import { Button } from "@mui/material";
import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { DeclineQuoteModal } from "../modals/DeclineQuoteModal.js";

export const DeclineQuoteButton: React.FC<{
  docId: string;
  projectId: string;
  handleComplete?: () => Promise<unknown> | unknown;
  // doc: Doc;
  // docProject: DocProject2;
}> = ({ docId, projectId, handleComplete }) => {
  const { t } = useTranslate(["Quote", "Global"]);
  const { isMaxPhone } = useScreenWidth();
  return (
    // <ShowAction action={{ name: "decline quote", project: docProject, doc }}>
    // </ShowAction>
    <ModalOpenButton
      Modal={DeclineQuoteModal}
      modalProps={{ projectId, quoteId: docId, handleComplete }}
    >
      <Button color="warning" variant="contained">
        {isMaxPhone
          ? t("Decline", { ns: "Global" })
          : t("Decline Quote", { ns: "Quote" })}
      </Button>
    </ModalOpenButton>
  );
};
