import React from "react";

type ProjectQuoteContextType = {
  projectId: string;
  quoteId: string;
  hasItemAnyQuestions: (itemId: string) => boolean;
  hasItemAnyRefinementQuestions: (itemId: string) => boolean;
  refetchWizardQuestions: () => Promise<any>;
};

export const ProjectQuoteContext = React.createContext<
  ProjectQuoteContextType | undefined
>(undefined);

export const useProjectQuoteContext = () => {
  const context = React.useContext(ProjectQuoteContext);
  if (!context) {
    throw new Error(
      "useProjectQuoteContext must be used within a ProjectQuoteContextProvider"
    );
  }

  return context;
};
