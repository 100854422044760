import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import { AutocompleteMultiple } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { debounce, uniqBy, without } from "lodash-es";
import React from "react";
import { FilterChip } from "../../commons/filters/FilterChip.js";
import { highlightWithBold } from "../../utils.js";
import {
  CrmCompanyListItem,
  CrmCompanySelectEntry,
} from "./CrmCompanySelect.js";
import {
  useCrmCompanySelectLazyQuery,
  useCrmCompanySelectQuery,
} from "./CrmCompanySelect.generated.js";

interface Props {
  crmCompanyIds?: string[] | undefined | null;
  onChange: (crmCompanies: CrmCompanySelectEntry[]) => void;
  filterProjectId?: string | undefined;
  inputLabel: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
}

export const CrmCompaniesSelect = ({
  crmCompanyIds,
  onChange,
  filterProjectId,
  inputLabel,
  placeholder,
  required,
  disabled,
  error,
}: Props) => {
  const { t } = useTranslate(["Global"]);

  const [inputValue, setInputValue] = React.useState("");

  const { highlightText, crmCompanies, debouncedRefetch, loading, totalCount } =
    useCrmCompanies(filterProjectId, crmCompanyIds, inputValue, setInputValue);

  return (
    <AutocompleteMultiple
      placeholder={placeholder ?? t("Type to search", { ns: "Global" })}
      options={[
        ...crmCompanies,
        ...(totalCount > crmCompanies.length
          ? [
              {
                title: t("Type to see more...", { ns: "Global" }),
                id: "__type_to_see_more__" as const,
              },
            ]
          : []),
      ]}
      getOptionDisabled={option => option.id === "__type_to_see_more__"}
      getOptionLabel={option => option.title}
      renderOption={(props, option) =>
        "__typename" in option ? (
          <CrmCompanyListItem
            {...props}
            key={option.id}
            option={option}
            highlightText={highlightText}
          />
        ) : (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        )
      }
      inputLabel={inputLabel}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
          debouncedRefetch(value);
        }
      }}
      onBlur={() => {
        setInputValue("");
      }}
      required={required}
      disabled={disabled}
      value={crmCompanies.filter(c => crmCompanyIds?.includes(c.id))}
      onChange={value => {
        onChange(
          value.filter(
            v => v.id !== "__type_to_see_more__"
          ) as CrmCompanySelectEntry[]
        );
      }}
      loading={loading}
      error={error}
    />
  );
};

const LIMIT = 10;

function useCrmCompanies(
  filterProjectId: string | undefined,
  crmCompanyIds: string[] | undefined | null,
  inputValue: string,
  setInputValue: (value: string) => void
) {
  const client = useApolloClient();
  const [fetchQuery, query] = useCrmCompanySelectLazyQuery({
    client,
    variables: { limit: LIMIT, filterProjectId },
  });
  const [fetchSelectedQuery, selectedQuery] = useCrmCompanySelectLazyQuery({
    client,
    variables: { limit: LIMIT, filterProjectId },
  });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          filterProjectId,
          searchTerm: value,
          filterIds: null,
        },
      });
    },
    [filterProjectId, fetchQuery]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (crmCompanyIds && crmCompanyIds.length > 0) {
        await fetchSelectedQuery({
          variables: {
            limit: crmCompanyIds.length,
            filterProjectId,
            filterIds: crmCompanyIds,
          },
        });
      }
      await fetchQuery({
        variables: {
          limit: LIMIT,
          filterProjectId,
          searchTerm: inputValueRef.current,
          filterIds: null,
          filterExcludeIds:
            crmCompanyIds && crmCompanyIds.length > 0 ? crmCompanyIds : [],
        },
      });
    })();
  }, [crmCompanyIds, filterProjectId, fetchQuery, fetchSelectedQuery]);

  const crmCompanies =
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)?.edges.map(
      edge => edge.node
    ) ?? [];
  const crmSelectedCompanies =
    crmCompanyIds && crmCompanyIds.length > 0
      ? (getDataOrNull(
          (selectedQuery.data ?? selectedQuery.previousData)?.crmCompanies
        )?.edges.map(edge => edge.node) ?? [])
      : [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)
      ?.totalCount ?? 0;

  const highlightText = React.useCallback(
    (text: string | null) =>
      text && query?.variables?.searchTerm
        ? highlightWithBold(text, query.variables.searchTerm)
        : text,
    [query?.variables?.searchTerm]
  );

  return {
    highlightText,
    crmCompanies: uniqBy(
      [
        ...(crmCompanyIds ?? [])
          .map(id => crmSelectedCompanies.find(c => c.id === id) ?? null)
          .filter(notNull),
        ...crmCompanies,
      ],
      c => c.id
    ),
    refetch,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.searchTerm &&
          query?.variables?.searchTerm !== inputValue
      ),
    totalCount,
  };
}

export function FilterCrmCompanyChips({
  label,
  value,
  setValue,
}: {
  label?: string;
  value: string[] | null | undefined;
  setValue: (newValue: string[] | null | undefined) => void;
}) {
  const { t } = useTranslate(["Projects"]);

  const client = useApolloClient();
  const query = useCrmCompanySelectQuery({
    client,
    variables: { limit: value?.length ?? 0, filterIds: value },
    skip: !value || !value.length,
  });

  const crmCompanies = (
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)?.edges?.map(
      e => e.node
    ) ?? []
  ).filter(c => value?.includes(c.id) ?? false);

  if (!crmCompanies.length) return null;

  return (
    <>
      {crmCompanies.map(crmCompany => (
        <FilterChip
          key={crmCompany.id}
          label={label ?? t("CRM contact", { ns: "Projects" })}
          resetValue={null}
          setValue={() => {
            const newValue = without(value, crmCompany.id);
            setValue(newValue.length > 0 ? newValue : undefined);
          }}
          value={crmCompany.title}
        />
      ))}
    </>
  );
}
