import { useApolloClient } from "@apollo/client";
import { Bookmark as BookmarkIcon } from "@mui/icons-material";
import { ShoppingBasket as ShoppingBasketIcon } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageNotFound } from "../../commons/PageNotFound.js";
import { PageTopbarItem } from "../../commons/layout/Page.js";
import { useShopTopbarItemsQuery } from "../../features/shop/Shop.generated.js";
import { Shop } from "./Shop.js";
import { ShopPreview } from "./ShopPreview.js";

type PageSubmenuItem = PageTopbarItem;

export const ShopRoutes = () => {
  const { t } = useTranslate(["Shop", "Global"]);

  const client = useApolloClient();
  const query = useShopTopbarItemsQuery({
    client,
    fetchPolicy: "cache-and-network",
  });

  const savedCount =
    query?.data?.viewer?.organisation.shoppableTemplates.totalCount ?? null;

  const submenuItems: PageSubmenuItem[] = React.useMemo(() => {
    return [
      {
        name: "shop-saved",
        label:
          savedCount !== null
            ? `${t("Saved", {
                ns: "Global",
              })} (${savedCount})`
            : t("Saved", {
                ns: "Global",
              }),
        icon: <BookmarkIcon />,
        path: "/shop/saved",
        float: "right",
      },
      {
        name: "shop-catalog",
        label: t("Shop", {
          ns: "Shop",
        }),
        icon: <ShoppingBasketIcon />,
        path: "/shop",
      },
    ];
  }, [savedCount, t]);

  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("Shop", {
          ns: "Shop",
        }),
        to: `/shop`,
      },
    ],
    [t]
  );

  return (
    <Routes>
      <Route
        index
        element={
          <Shop submenuItems={submenuItems} prefixBreadcrumbs={breadcrumbs} />
        }
      />
      <Route
        path={"saved"}
        element={
          <Shop
            submenuItems={submenuItems}
            prefixBreadcrumbs={breadcrumbs}
            quickFilter="SAVED"
          />
        }
      />
      <Route path=":docId" element={<Navigate to="preview" replace />} />
      <Route
        path={":docId/preview"}
        element={
          <ShopPreview
            submenuItems={submenuItems}
            prefixBreadcrumbs={breadcrumbs}
          />
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
