import { useApolloClient } from "@apollo/client";
import { useScreenWidth } from "@msys/ui";
import { LoadingButton } from "@mui/lab";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useUnpublishQuoteMutation } from "../Quotes.generated.js";

export const UnpublishQuoteButton = ({
  docId,
  projectId,
  handleComplete,
}: {
  docId: string;
  projectId: string;
  handleComplete?: () => Promise<unknown> | unknown;
}) => {
  const { t } = useTranslate(["QuoteEdit", "Global"]);
  const { isMaxPhone } = useScreenWidth();
  const client = useApolloClient();
  const [unpublishQuote, { loading }] = useUnpublishQuoteMutation({
    client,
  });

  return (
    // <ShowAction action={{ name: "unpublish quote", project: docProject, doc }}>
    // </ShowAction>
    <LoadingButton
      loading={loading}
      color="warning"
      onClick={async () => {
        await unpublishQuote({
          variables: {
            input: {
              projectId,
              docId,
            },
          },
        });
        await handleComplete?.();
      }}
      variant="contained"
    >
      {isMaxPhone
        ? t("Unpublish", { ns: "Global" })
        : t("Unpublish doc", { ns: "QuoteEdit" })}
    </LoadingButton>
  );
};
