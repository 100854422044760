import { useApolloClient } from "@apollo/client";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { LoginButton } from "../auth/LoginButton.js";
import { RegisterButton } from "../auth/RegisterButton.js";
import { AvailableUser, SelectUserList } from "../auth/SelectUserList.js";
import { useSelectedUser } from "../auth/useSelectedUser.js";
import { SELECTED_USER_HTTP_HEADER } from "../constants.js";
import { useAcceptCrmLinkingInvitationMutation } from "./CrmLinkingInvitation.generated.js";
import { type RefTokenQuery } from "./InvitationTokenProvider.generated.js";
import { useInvitationToken } from "./useInvitationToken.js";

type Props = {
  invitation: Exclude<RefTokenQuery["crmLinkingInvitation"], null | undefined>;
} & (
  | {
      isAuthenticated: false;
      availableUsers?: void;
      availableUsersIsLoading?: void;
    }
  | {
      isAuthenticated: true;
      availableUsers: AvailableUser[];
      availableUsersIsLoading: boolean;
    }
);

export const CrmLinkingInvitation = ({
  invitation,
  isAuthenticated,
  availableUsers,
  availableUsersIsLoading,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const { setSelectedUserId } = useSelectedUser();
  const { remove: removeInvitationToken } = useInvitationToken();

  const [selectedUser, setSelectedUser] = React.useState<null | AvailableUser>(
    null
  );

  const client = useApolloClient();
  const [acceptCrmLinkingInvitation] = useAcceptCrmLinkingInvitationMutation({
    client,
  });

  return (
    <Stack alignItems="center" spacing={4}>
      {!isAuthenticated ? (
        <Stack spacing={2}>
          <Typography variant="h3">
            {t(
              "Please sign in to connect with {inviterOrgName} on MeisterSystems",
              {
                ns: "Global",
                inviterOrgName: invitation.authorOrganisation.name,
              }
            )}
          </Typography>
          <Stack direction="row" spacing={1}>
            <LoginButton />
            <RegisterButton />
          </Stack>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography variant="h3">
            {t(
              "Please choose the organisation that will work with {inviterOrgName}",
              {
                ns: "Global",
                inviterOrgName: invitation.authorOrganisation.name,
              }
            )}
          </Typography>
          <SelectUserList
            isLoading={availableUsersIsLoading}
            availableUsers={availableUsers}
            selectedUser={selectedUser}
            handleSelect={setSelectedUser}
          />
          <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
            <Button
              color="warning"
              onClick={() => {
                removeInvitationToken();
              }}
            >
              {t("Discard", { ns: "Global" })}
            </Button>
            <Button
              variant="contained"
              color="success"
              disableElevation
              onClick={async () => {
                if (!selectedUser)
                  throw new Error("Need to select user first!");
                await acceptCrmLinkingInvitation({
                  variables: { token: invitation.token },
                  context: {
                    headers: {
                      [SELECTED_USER_HTTP_HEADER]: selectedUser.id,
                    },
                  },
                });
                removeInvitationToken();
                setSelectedUserId(selectedUser.id);
              }}
              disabled={!selectedUser}
            >
              {t("Select", { ns: "Global" })}
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
