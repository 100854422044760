/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type InvoiceSubHeader_InvoiceFragment = { __typename: 'Invoice', id: string, title: string, number?: string | null, status: Types.InvoiceStatus, file?: { __typename: 'AttachmentSnapshot', url: string } | null, quote: { __typename: 'Quote', id: string } };

export type InvoiceSubHeader_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName> };

export const InvoiceSubHeader_InvoiceFragmentDoc = gql`
    fragment InvoiceSubHeader_Invoice on Invoice {
  id
  title
  number
  status
  file {
    url
  }
  quote {
    id
  }
}
    `;
export const InvoiceSubHeader_ProjectFragmentDoc = gql`
    fragment InvoiceSubHeader_Project on Project {
  id
  viewerPermissions
}
    `;