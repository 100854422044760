/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes.js';

import { gql } from '@apollo/client';
export type RequestStatusBadgeFragment = { __typename: 'Request', id: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string };

export const RequestStatusBadgeFragmentDoc = gql`
    fragment RequestStatusBadge on Request {
  id
  status
  wonBySystemOrganisationId
  owningSystemOrganisationId
}
    `;